import {
  UilExpandArrowsAlt,
  UilBoltAlt,
  UilCalendarAlt,
  UilChannel,
  UilCheckCircle,
  UilEnvelopeAlt,
  UilEnvelopeUpload,
  UilFilter,
  UilHourglass,
  UilEnvelopeTimes,
  UilPauseCircle,
  UilClockFive,
  UilPlus,
  UilTimes,
  UilArchive,
  UilUsersAlt,
  UilUserCheck,
  UilUserCircle,
  UilUserPlus,
  UilEdit
} from '@iconscout/react-unicons';
import { UisStar } from '@iconscout/react-unicons-solid';
import Axios from 'axios';
import { Select } from 'components/lib';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Handle, Position } from 'reactflow';
import { useFlowchartData } from './FlowchartContext';
import './nodeTypes.scss';
import Swal from 'sweetalert2';

const customNodeStyles = {
  background: '#f9f9f9',
  padding: 10,
  width: 250,
  cursor: 'default',
  borderRadius: 5,
  border: '1px solid #D7D7D7',
  boxShadow: '-2px 2px 4px 0px rgba(0, 0, 0, 0.05)',
};

function truncText(text) {
  if (text.length > 60) return text.slice(0, 60) + '...';
  else return text;
}

async function fetchLogsCount(type, sequenceID, start, end, nodeID, message) {
  try {
    let dateStr = '';
    let msg = '';
    if (start && end) dateStr = `&date=${start}-${end}`;
    if (message) msg = `&message=${message}`;
    const res = await Axios({
      url: `/api/logs?type=${type}&sequence=${sequenceID}&node=${
        nodeID + dateStr + msg
      }`,
      method: 'get',
    });
    return res?.data?.count;
  } catch (error) {
    console.log('ERROR: ', error);
    return 0;
  }
}

async function fetchTaskCount(
  type,
  sequenceID,
  start,
  end,
  nodeID,
  completed,
  notApproved,
  all
) {
  try {
    let dateStr = '';
    let isComplete = '';
    let isPendingApproval = '';
    let allCount = '';
    if (start && end) dateStr = `&date=${start}-${end}`;
    if (completed) isComplete = `&completed=${completed}`;
    if (notApproved) isPendingApproval = `&notApproved=${notApproved}`;
    if (all) allCount = `&all=${all}`;
    const res = await Axios({
      url: `/api/tasks-count?type=${type}&sequence=${sequenceID}&node=${
        nodeID + dateStr + isComplete + isPendingApproval + allCount
      }`,
      method: 'get',
    });
    return res?.data?.data;
  } catch (error) {
    console.log('ERROR: ', error);
    return 0;
  }
}

function NodeActionHeader(props) {
  return (
    <div
      className='csNodeActionHeader'
      // style={{ float: 'right', marginTop: '-20px', marginRight: '-22px' }}
    >
      <div style={{ display: 'flex' }}>
        {props.onEdit && (
          <span
            className="actionBtn edit"
            onClick={props.onEdit}
            id="edit-node"
          >
            <UilEdit size={16} />
          </span>
        )}
        <span
          className="actionBtn delete"
          onClick={props.onClose}
          id="delete-node"
        >
          <UilTimes size={16} />
        </span>
      </div>
      {/* <Tooltip anchorSelect="#delete-node" place="top">
        Delete
      </Tooltip>
      <Tooltip anchorSelect="#edit-node" place="top">
        Edit
      </Tooltip> */}
    </div>
  );
}

function ConditionalFooter(props) {
  return (
    <div className="conditionalFooterTrueFalse">
      <div className="conditionalFooterItem">
        <b>Yes</b>
      </div>
      <div className="conditionalFooterItem">
        <b>No</b>
      </div>
    </div>
  );
}

function FirstNode({ data }) {
  return (
    <div style={{ ...customNodeStyles, textAlign: 'center' }}>
      <b>New Prospect</b>
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
}

function AddNode({ data, ...rest }) {
  const flowchartData = useFlowchartData();

  const executeFunction = () => {
    flowchartData?.showAddDialog({ ...rest, data });
  }

  return (
    <div>
      <div
        style={{
          background: '#f2f2f2',
          width: 40,
          height: 40,
          border: '3px solid #03A6FF',
          color: '#03A6FF',
          borderRadius: 5,
          cursor: 'pointer',
        }}
        id={data?.addType === 'source' ? 'add-source' : 'add-node'}
        onClick={() => {
          if (flowchartData?.checkRunningSequenceFirstEdit()) {
            return Swal.fire({
              icon: 'warning',
              title: '<h5>Are you sure?</h5>',
              html: `<div>
                <ol style="text-align: left">
                  <li>You're trying to edit a step in a launched sequence.</li>
                  <li style="margin-top: 10px">This might result in leads who have not replied to your email (and are safe to contact) to go through the new step(s) if they have not passed the current step yet.</li>
                  <li style="margin-top: 10px">Also, it may mess up sequence stats.</li>
                </ol>
              </div>`,
              showCancelButton: true,
              confirmButtonText: 'Proceed Anyway',
              cancelButtonText: 'Cancel',
              confirmButtonColor: '#0066ff',
            }).then((result) => {
              if (result.isConfirmed) {
                flowchartData?.setIsFirstEdit(false);
                executeFunction();
              }
            });
          } else executeFunction();
        }}
      >
        <UilPlus style={{ cursor: 'pointer', marginLeft: 5, marginTop: 5 }} size={24} />
        <Handle
          type="target"
          position={data?.addType === 'source' ? Position.Left : Position.Top}
          id={rest?.id + '-tgt'}
        />
      </div>
      <Tooltip
        anchorSelect={data?.addType === 'source' ? '#add-source' : '#add-node'}
        place={data?.addType === 'source' ? 'right' : 'bottom'}
      >
        {data?.addType === 'source' ? 'Add Another Source' : 'Add New Block'}
      </Tooltip>
    </div>
  );
}

function AddSourceNode({ data, ...rest }) {
  const flowchartData = useFlowchartData();

  return (
    <div style={{ ...customNodeStyles, cursor: 'pointer' }} id={'add-source'} className='csFlowchartBlock'>
      <div        
        onClick={() => {
          // flowchartData?.showAddDialog({ ...rest, data });
          flowchartData?.setSelectedNode(null);
          flowchartData?.showAddDialog();
        }}
      >
        <div className='text-center'>
          <UilPlus style={{ margin: "auto" }} size={24} /> 
          <div className='mt-1'>Add Lead Source</div>
          <div className='mt-1' style={{ fontSize: 14 }}>Click to add leads from List or CRM</div>
        </div>
      </div>
      <Tooltip
        anchorSelect={"#add-source"}
        place={"top"}
      >
        Add Another Source
      </Tooltip>
    </div>
  );
}

function SourceConnect({ data, ...rest }) {
  const flowchartData = useFlowchartData();

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      <div>
        <div className='flex ml-2 justify-center'>
          <span className='mt-1'>Sequence Start Point</span>
        </div>
      </div>
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      <Handle type="source" position={Position.Bottom} id={rest?.id + '-src'} />
    </div>
  );
}

function Call({ data, ...rest }) {
  const flowchartData = useFlowchartData();

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      <div style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 900 }}>Call</b>
      </div>
      <div style={{ textAlign: 'center' }}>
        <div>Template</div>
        <Select
          options={[
            { key: 'f', value: 'Template One', label: 'Template One' },
            { key: 'g', value: 'Template Two', label: 'Template Two' },
          ]}
        ></Select>
      </div>
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      <Handle type="source" position={Position.Bottom} id={rest?.id + '-src'} />
    </div>
  );
}

function Email({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);
  const [sent, setSent] = useState(0);
  const [scheduled, setScheduled] = useState(0);
  const [review, setReview] = useState(0);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
      checkEndBlock();
    }
  }, [flowchartData.sequenceData]);

  async function getData() {
    // console.log("TET ", rest?.id)
    setContacts(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id,
        false,
        false,
        true
      )
    );
    setReview(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id,
        false,
        true
      )
    );
    setSent(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id,
        true
      )
    );
    setScheduled(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id
      )
    );
  }

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length > 0) {
      let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
      if (!node || node?.type === 'add') setIsEnd(true);
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode ? 
        flowchartData.aiPersonalized ?
          <NodeActionHeader
            onClose={() => flowchartData.removeNode(rest?.id)}
          />:
          <NodeActionHeader
            onClose={() => flowchartData.removeNode(rest?.id)}
            onEdit={() => flowchartData.setEditNode({ data, ...rest })}
          />
      :null}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 5 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>{' '}
          <br />
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon purple mr-3'>
            <UilEnvelopeAlt
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle'>
            {data?.enabled_az_testing ? "A/Z Testing" : "Email"}
          </div>
          <div className='blockDescription'>
            {data?.enabled_az_testing ? 
              <div>
                {data?.winning_template ?
                  <div className='flex'>
                    <span><UisStar size={18} id="winner" style={{ marginTop: 2, marginRight: 5, cursor: 'pointer', color: '#F9C00C' }} /></span>
                    <span>{data?.winning_template?.label}</span>
                  </div>
                : 
                  <span>{data?.email_template_variants?.length} Templates</span>
                }
              </div>
            :
              <div>
                Template :{' '}
                <span className="textPurple">
                  {truncText(data?.email_templates?.label)}
                </span>
              </div>
            }
            {data?.send_as?.label && (
              <div>
                Type :{' '}
                <span className="textPurple">{data.send_as.label}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center' }}>
          <div className="flex justify-center font-bold">
            <UilEnvelopeUpload
              size={20}
              style={{ marginTop: 3, marginRight: 5 }}
            />{' '}
            Sent : <span className="text-blue-500">{sent}</span>
          </div>
          <div className="flex justify-center font-bold">
            <UilCalendarAlt
              size={20}
              style={{ marginTop: 3, marginRight: 5 }}
            />{' '}
            Scheduled : <span className="text-blue-500">{scheduled}</span>
          </div>
          {contacts > 0 && (
            <span
              className="text-blue-500"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                flowchartData.setEditNode({
                  Leads: contacts,
                  Sent: sent,
                  Scheduled: scheduled,
                  Outbox: review,
                  label: data?.email_templates?.label,
                  node: rest?.id
                });
                flowchartData.setDialogShowing(true);
              }}
            >
              View all Stats
            </span>
          )}
        </div>
      )}
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      {flowchartData.isReportMode ? (
        !isEnd ? (
          <Handle
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src'}
          />
        ) : null
      ) : (
        <Handle
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src'}
        />
      )}
    </div>
  );
}

function Task({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [pending, setPending] = useState(0);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
      checkEndBlock();
    }
  }, [flowchartData.sequenceData]);

  async function getData() {
    setContacts(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id,
        false,
        false,
        true
      )
    );
    setCompleted(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id,
        true
      )
    );
    setPending(
      await fetchTaskCount(
        rest?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        rest?.id
      )
    );
  }

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length > 0) {
      let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
      if (!node || node?.type === 'add') setIsEnd(true);
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 5 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon purple mr-3'>
            <UilCheckCircle
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle'>
            Task
          </div>
          <div className='blockDescription'>
            <div>
              Type :{' '}
              <span className="textPurple">
                {truncText(data?.tasktype?.label)}
              </span>
            </div>
            {data?.task_templates?.label && (
              <div>
                Template :{' '}
                <span className="textPurple">
                  {truncText(data?.task_templates?.label)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center' }}>
          <div className="flex justify-center font-bold">
            <UilCheckCircle
              size={20}
              style={{ marginTop: 3, marginRight: 5 }}
            />{' '}
            Completed: <span className="text-blue-500">{completed}</span>
          </div>
          <div className="flex justify-center font-bold">
            <UilHourglass size={20} style={{ marginTop: 3, marginRight: 5 }} />{' '}
            Pending: <span className="text-blue-500">{pending}</span>
          </div>
        </div>
      )}
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      {flowchartData.isReportMode ? (
        !isEnd ? (
          <Handle
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src'}
          />
        ) : null
      ) : (
        <Handle
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src'}
        />
      )}
    </div>
  );
}

function IfElse({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [isEndLeft, setIsEndLeft] = useState(false);
  const [isEndRight, setIsEndRight] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
      checkEndBlock();
    }
  }, [flowchartData.sequenceData]);

  async function getData() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    for (let nodeID of nodeChildrenIDs) {
      let edge = flowchartData.getParentEdge(nodeID);
      let node = flowchartData.getNodeByID(nodeID);
      if (edge.sourceHandle.includes('left')) {
        setLeft(
          await fetchTaskCount(
            node?.type,
            flowchartData.sequenceData?.id,
            flowchartData.sequenceData?.start,
            flowchartData.sequenceData?.end,
            node?.id,
            false,
            false,
            true
          )
        );
      } else if (edge.sourceHandle.includes('right')) {
        setRight(
          await fetchTaskCount(
            node?.type,
            flowchartData.sequenceData?.id,
            flowchartData.sequenceData?.start,
            flowchartData.sequenceData?.end,
            node?.id,
            false,
            false,
            true
          )
        );
      }
    }
  }

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length !== 2) {
      if (nodeChildrenIDs.length === 0) {
        setIsEndLeft(true);
        setIsEndRight(true);
      } else {
        let edge = flowchartData.getParentEdge(nodeChildrenIDs[0]);
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (edge.sourceHandle.includes('left')) {
          if (!node || node?.type === 'add') {
            setIsEndLeft(true);
            setIsEndRight(true);
          } else setIsEndRight(true);
        } else if (edge.sourceHandle.includes('right')) {
          if (!node || node?.type === 'add') {
            setIsEndRight(true);
            setIsEndLeft(true);
          } else setIsEndLeft(true);
        }
      }
    } else {
      for (let nodeID of nodeChildrenIDs) {
        let edge = flowchartData.getParentEdge(nodeID);
        let node = flowchartData.getNodeByID(nodeID);
        if (
          edge.sourceHandle.includes('left') &&
          (!node || node?.type === 'add')
        )
          setIsEndLeft(true);
        if (
          edge.sourceHandle.includes('right') &&
          (!node || node?.type === 'add')
        )
          setIsEndRight(true);
      }
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.setShowRemoveDialog({ ...rest, data })}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon primary mr-3'>
            <UilFilter
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle mt-1'>
            If/Else
          </div>
          {/* <div className='blockDescription'>
            Condition:{' '}
            <span className="textPrimary">
              {data?.action_conditions?.label}
            </span>
          </div> */}
        </div>
      </div>

      <div style={{ textAlign: 'center', marginBottom: 5 }}>
        {data?.conditions?.map((_andConditions, i) => (
          <>
            {i !== 0 && <b style={{ marginRight: 5 }}>AND</b>}
          
            <div className="csSequencePills primary" key={i}>
              {_andConditions?.conditions?.map((_orCondition, j) => (
                <>
                  {j !== 0 && " | "}
                  <span key={j} style={{ textTransform: 'capitalize' }}>{_orCondition?.type?.replace(/_/g, " ")}</span>
                </>
              ))}
            </div>
          </>
        ))}
      </div>

      {!flowchartData.isReportMode && <div className="flex">
        <div>
          <div className="csSequencePills primary" style={{ padding: '5px 15px' }}>
            Yes
          </div>
        </div>
        <div>
          <div className="csSequencePills primary ml-3" style={{ padding: '5px 15px' }}>
            No: After {data?.delay} {data?.delay_unit?.label.replace(/s\b/g, "(s)")}
          </div>
        </div>
      </div>}

      {flowchartData.isReportMode && (
        <div className="conditionalFooterTrueFalse">
          <div className="conditionalFooterItem">
            <b>
              Yes: <span className="text-blue-500">{left || 0}</span>
            </b>
          </div>
          <div className="conditionalFooterItem">
            <b>
              No: <span className="text-blue-500">{right || 0}</span>
            </b>
          </div>
        </div>
      )}
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      {flowchartData.isReportMode ? (
        !isEndLeft ? (
          <Handle
            style={{ marginLeft: '-35%' }}
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src-left'}
          />
        ) : null
      ) : (
        <Handle
          style={{ marginLeft: '-35%' }}
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src-left'}
        />
      )}
      {flowchartData.isReportMode ? (
        !isEndRight ? (
          <Handle
            style={{ marginLeft: '35%' }}
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src-right'}
          />
        ) : null
      ) : (
        <Handle
          style={{ marginLeft: '35%' }}
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src-right'}
        />
      )}
    </div>
  );
}

function SourceEntryList({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [isEndRight, setIsEndRight] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) checkEndBlock();
  }, [flowchartData.sequenceData]);

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length !== 2) {
      if (nodeChildrenIDs.length === 0) setIsEndRight(true);
      else {
        let edge = flowchartData.getParentEdge(nodeChildrenIDs[0]);
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (edge.sourceHandle.includes('right')) {
          if (!node || node?.type === 'add') setIsEndRight(true);
        }
      }
    } else {
      let edge1 = flowchartData.getParentEdge(nodeChildrenIDs[0]);
      let edge2 = flowchartData.getParentEdge(nodeChildrenIDs[1]);
      if (edge1.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
      if (edge2.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[1]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 5 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads :{' '}
            <span className="text-blue-500">
              {flowchartData.sequenceData?.total}
            </span>
          </b>
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon pink mr-3'>
            <UilUserPlus
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle mt-1'>
            Leads from
          </div>
          <div className='blockDescription'>
            {data?.lists?.value?.length > 1 ? (
              <>
                <span className="textPink">
                  {truncText(
                    data?.lists?.value.map((listLabel) => listLabel.label + ', ')
                  )}
                </span>
              </>
            ) : (
              <>
                <span className="textPink">
                  {truncText(data?.lists?.value[0].label)}
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      {data?.condition && <span className="csAndPill">{data?.condition}</span>}

      <Handle
        type="source"
        position={Position.Bottom}
        id={rest?.id + '-output'}
      />
    </div>
  );
}

function SourceIntegration({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [isEndRight, setIsEndRight] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) checkEndBlock();
  }, [flowchartData.sequenceData]);

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length !== 2) {
      if (nodeChildrenIDs.length === 0) setIsEndRight(true);
      else {
        let edge = flowchartData.getParentEdge(nodeChildrenIDs[0]);
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (edge.sourceHandle.includes('right')) {
          if (!node || node?.type === 'add') setIsEndRight(true);
        }
      }
    } else {
      let edge1 = flowchartData.getParentEdge(nodeChildrenIDs[0]);
      let edge2 = flowchartData.getParentEdge(nodeChildrenIDs[1]);
      if (edge1.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (!node || node.type === 'add') setIsEndRight(true);
      }
      if (edge2.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[1]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon pink mr-3'>
            <UilBoltAlt
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle'>
            Leads from 
          </div>
          <div className='blockDescription'>
            {data?.integrations?.value?.length > 1 ? (
              <>
                <span className="textPink">
                  {data?.integrations?.value.reduce(
                    (listLabel, acc) => acc.label + ', ' + listLabel.label
                  )}
                </span>
              </>
            ) : (
              <>
                <span className="textPink">
                  {data?.integrations?.value[0].label}
                </span>
                {data?.integrations?.value[0].label === "PipeDrive" && <img src={"https://zapier-images.imgix.net/storage/developer/a70589f8db6a514b6820267b506409f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50"} alt={data?.integrations?.value[0].label} width={40} />}
                {(data?.integrations?.value[0].label === "Hubspot" || data?.integrations?.value[0].label === "HubSpot") && <img src={"https://zapier-images.imgix.net/storage/developer/cde9764aa8d19fdd6d591455dbe5a78d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50"} alt={data?.integrations?.value[0].label} width={40} />}
                {data?.integrations?.value[0].label === "Zoho CRM" && <img src={"https://zapier-images.imgix.net/storage/services/8b222e5e2d52e0b827a02ba8c3162de1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50"} alt={data?.integrations?.value[0].label} width={40} />}
              </>
            )}
          </div>
        </div>
      </div>

      {data?.condition && <span className="csAndPill">{data?.condition}</span>}

      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center' }}>
          <b>
            Leads :{' '}
            <span className="text-blue-500">
              {flowchartData.sequenceData?.total}
            </span>
          </b>
        </div>
      )}

    <Handle
      type="source"
      position={Position.Bottom}
      id={rest?.id + '-output'}
    />
    </div>
  );
}

function SourceEntryCRMList({ data, ...rest }) {
  const flowchartData = useFlowchartData();

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      <div style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 900 }}>Contact Added to CRM List</b>
      </div>
      <div style={{ textAlign: 'center' }}>
        <b>Integration Name</b>
        <br />
        {data?.integration?.label}
        <br />
        <b>List</b>
        <br />
        {data?.list?.label}
        {data?.condition && (
          <span className="csAndPill">{data?.condition}</span>
        )}
      </div>

      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center' }}>
          <b>
            Leads :{' '}
            <span className="text-blue-500">
              {flowchartData?.sequenceData?.total || 0}
            </span>
          </b>
        </div>
      )}

      <Handle
        type="source"
        position={Position.Bottom}
        id={rest?.id + '-output'}
      />
    </div>
  );
}

function SourceEntryCondition({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [isEndRight, setIsEndRight] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) checkEndBlock();
  }, [flowchartData.sequenceData]);

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length !== 2) {
      if (nodeChildrenIDs.length === 0) setIsEndRight(true);
      else {
        let edge = flowchartData.getParentEdge(nodeChildrenIDs[0]);
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (edge.sourceHandle.includes('right')) {
          if (!node || node?.type === 'add') setIsEndRight(true);
        }
      }
    } else {
      let edge1 = flowchartData.getParentEdge(nodeChildrenIDs[0]);
      let edge2 = flowchartData.getParentEdge(nodeChildrenIDs[1]);
      if (edge1.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
      if (edge2.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[1]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 5 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads :{' '}
            <span className="text-blue-500">
              {flowchartData.sequenceData?.total}
            </span>
          </b>
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon pink mr-3'>
            <UilUserCheck
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle'>
            Segment by Events in 
          </div>
          <div className='blockDescription'>
            {data?.lists?.value?.length > 1 ? (
              <>
                <span className="textPink">
                  {data?.lists?.value.reduce(
                    (listLabel, acc) => acc.label + ', ' + listLabel.label
                  )}
                </span>
              </>
            ) : (
              <>
                <span className="textPink">
                  {truncText(data?.lists?.value[0].label)}
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data?.conditions?.map((_andConditions, i) => (
          <>
            {i !== 0 && <b style={{ marginRight: 5 }}>AND</b>}
          
            <div className="csSequencePills pink" key={i}>
              {_andConditions?.conditions?.map((_orCondition, j) => (
                <>
                  {j !== 0 && " | "}
                  <span key={j} style={{ textTransform: 'capitalize' }}>{_orCondition?.type.replace(/_/g, " ")}</span>
                </>
              ))}
            </div>
          </>
        ))}
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id={rest?.id + '-output'}
      />
    </div>
  );
}

function SourceEntryAttribute({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [isEndRight, setIsEndRight] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) checkEndBlock();
  }, [flowchartData.sequenceData]);

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length !== 2) {
      if (nodeChildrenIDs.length === 0) setIsEndRight(true);
      else {
        let edge = flowchartData.getParentEdge(nodeChildrenIDs[0]);
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (edge.sourceHandle.includes('right')) {
          if (!node || node?.type === 'add') setIsEndRight(true);
        }
      }
    } else {
      let edge1 = flowchartData.getParentEdge(nodeChildrenIDs[0]);
      let edge2 = flowchartData.getParentEdge(nodeChildrenIDs[1]);
      if (edge1.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
      if (edge2.sourceHandle.includes('right')) {
        let node = flowchartData.getNodeByID(nodeChildrenIDs[1]);
        if (!node || node?.type === 'add') setIsEndRight(true);
      }
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 5 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads :{' '}
            <span className="text-blue-500">
              {flowchartData.sequenceData?.total}
            </span>
          </b>
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon pink mr-3'>
            <UilUsersAlt
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle'>
            Segment of
          </div>
          <div className='blockDescription'>
            <span className="textPink" style={{ marginRight: 5 }}>
              {data?.lists?.value?.label}
            </span>
            that have
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data?.attribute?.map((_attr, i) => (
          <div className="csSequencePills pink mb-1" key={i}>
            {_attr?.list.replace(/_/g, " ")}
          </div>
        ))}
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id={rest?.id + '-output'}
      />
    </div>
  );
}

function Delay({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
      checkEndBlock();
    }
  }, [flowchartData.sequenceData]);

  const getData = async () => {
    let parentNodeId = flowchartData.getParentNodeID(rest?.id);
    let node = flowchartData.getNodeByID(parentNodeId);
    if (node?.type === 'email') {
      let completed = await fetchTaskCount(
        node?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId,
        true
      );
      let bounced = await fetchLogsCount(
        'bounce',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      let unsubscribed = await fetchLogsCount(
        'unsubscribed',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      setContacts(completed - (bounced + unsubscribed));
    } else if (node?.type === 'task')
      setContacts(
        await fetchTaskCount(
          node?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeId,
          true
        )
      );
  };

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length > 0) {
      let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
      if (!node || node?.type === 'add') setIsEnd(true);
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.removeNode(rest?.id)}
          onEdit={() => flowchartData.setEditNode({ data, ...rest })}
        />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>{' '}
          <br />
        </div>
      )}

      <div className='flex p-2'>
        <div className='csSequenceIcon primary mr-3'>
          <UilClockFive
            size={38}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          />
        </div>
        <div>
          <div className='blockTitle mt-1'>
            Delay
          </div>
          <div className='blockDescription'>
            Wait{' '}
            <span className="textPrimary">
              {data?.delay} {data?.delay_unit?.label.replace(/s\b/g, "(s)")}
            </span>
          </div>
        </div>
      </div>
      
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      {flowchartData.isReportMode ? (
        !isEnd ? (
          <Handle
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src'}
          />
        ) : null
      ) : (
        <Handle
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src'}
        />
      )}
    </div>
  );
}

function ABSplit({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(null);
  const [isEndLeft, setIsEndLeft] = useState(false);
  const [isEndRight, setIsEndRight] = useState(false);

  // useEffect(() => {
  //   if (
  //     flowchartData.isReportMode &&
  //     flowchartData.sequenceData?.flowchartState
  //   ) {
  //     let contact = flowchartData.sequenceData?.flowchartState[rest?.id];
  //     if (contact) setContacts(contact);
  //     checkEndBlock();
  //   }
  // }, [flowchartData.sequenceData]);

  async function checkEndBlock() {
    let nodeChildrenIDs = flowchartData.getNodeChildren(rest?.id);
    if (nodeChildrenIDs && nodeChildrenIDs.length !== 2) {
      if (nodeChildrenIDs.length === 0) {
        setIsEndLeft(true);
        setIsEndRight(true);
      } else {
        let edge = flowchartData.getParentEdge(nodeChildrenIDs[0]);
        let node = flowchartData.getNodeByID(nodeChildrenIDs[0]);
        if (edge.sourceHandle.includes('left')) {
          if (!node || node?.type === 'add') {
            setIsEndLeft(true);
            setIsEndRight(true);
          } else setIsEndRight(true);
        } else if (edge.sourceHandle.includes('right')) {
          if (!node || node?.type === 'add') {
            setIsEndRight(true);
            setIsEndLeft(true);
          } else setIsEndLeft(true);
        }
      }
    } else {
      for (let nodeID of nodeChildrenIDs) {
        let edge = flowchartData.getParentEdge(nodeID);
        let node = flowchartData.getNodeByID(nodeID);
        if (
          edge.sourceHandle.includes('left') &&
          (!node || node?.type === 'add')
        )
          setIsEndLeft(true);
        if (
          edge.sourceHandle.includes('right') &&
          (!node || node?.type === 'add')
        )
          setIsEndRight(true);
      }
    }
  }

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader
          onClose={() => flowchartData.setShowRemoveDialog({ ...rest, data })}
        />
      )}

      <div>
        <div className='csSequenceIcon primary' style={{ margin: 'auto', width: 60 }}>
          <UilChannel
            size={38}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          />
        </div>
      </div>

      <div style={{ textAlign: 'center' }} className='blockTitle mt-1'>
        Split 50/50
      </div>

      {flowchartData.isReportMode && (
        <div className="conditionalFooterTrueFalse">
          <div className="conditionalFooterItem">
            <b>
              A
              {/* : <span className="text-blue-500">{contacts?.left || 0}</span> */}
            </b>
          </div>
          <div className="conditionalFooterItem">
            <b>
              B
              {/* : <span className="text-blue-500">{contacts?.right || 0}</span> */}
            </b>
          </div>
        </div>
      )}

      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
      {flowchartData.isReportMode ? (
        !isEndLeft ? (
          <Handle
            style={{ marginLeft: '-20%' }}
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src-left'}
          />
        ) : null
      ) : (
        <Handle
          style={{ marginLeft: '-20%' }}
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src-left'}
        />
      )}
      {flowchartData.isReportMode ? (
        !isEndRight ? (
          <Handle
            style={{ marginLeft: '20%' }}
            type="source"
            position={Position.Bottom}
            id={rest?.id + '-src-right'}
          />
        ) : null
      ) : (
        <Handle
          style={{ marginLeft: '20%' }}
          type="source"
          position={Position.Bottom}
          id={rest?.id + '-src-right'}
        />
      )}
    </div>
  );
}

function PauseCampaign({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
    }
  }, [flowchartData.sequenceData]);

  const getData = async () => {
    let parentNodeId = flowchartData.getParentNodeID(rest?.id);
    let node = flowchartData.getNodeByID(parentNodeId);
    if (node?.type === 'email') {
      let completed = await fetchTaskCount(
        node?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId,
        true,
        false,
        true
      );
      let bounced = await fetchLogsCount(
        'bounce',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      let unsubscribed = await fetchLogsCount(
        'unsubscribed',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      setContacts(completed - (bounced + unsubscribed));
    } else if (node?.type === 'task')
      setContacts(
        await fetchTaskCount(
          node?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeId,
          true
        )
      );
    else if(node?.group === "condition") {
      let parentNodeIdOfCondition = flowchartData.getParentNodeID(node?.id);
      let parentNode = flowchartData.getNodeByID(parentNodeIdOfCondition);
    
      if (parentNode?.type === 'email') {
        let completed = await fetchTaskCount(
          parentNode?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition,
          true,
          false,
          true
        );
        let bounced = await fetchLogsCount(
          'bounce',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        let unsubscribed = await fetchLogsCount(
          'unsubscribed',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        setContacts(completed - (bounced + unsubscribed));
      } else if (parentNode?.type === 'task')
        setContacts(
          await fetchTaskCount(
            parentNode?.type,
            flowchartData.sequenceData?.id,
            flowchartData.sequenceData?.start,
            flowchartData.sequenceData?.end,
            parentNodeIdOfCondition,
            true
          )
        );
    }
  };

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader onClose={() => flowchartData.removeNode(rest?.id)} />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>{' '}
          <br />
        </div>
      )}

      <div className='p-2'>
        <div>
          <div className='csSequenceIcon warning' style={{ margin: 'auto', width: 60 }}>
            <UilPauseCircle
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>

        <div style={{ textAlign: 'center' }} className='blockTitle mt-2'>
          End Sequence
        </div>
      </div>

      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
    </div>
  );
}

function RemoveContact({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
    }
  }, [flowchartData.sequenceData]);

  const getData = async () => {
    let parentNodeId = flowchartData.getParentNodeID(rest?.id);
    let node = flowchartData.getNodeByID(parentNodeId);
    if (node?.type === 'email') {
      let completed = await fetchTaskCount(
        node?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId,
        true,
        false,
        true
      );
      let bounced = await fetchLogsCount(
        'bounce',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      let unsubscribed = await fetchLogsCount(
        'unsubscribed',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      setContacts(completed - (bounced + unsubscribed));
    } else if (node?.type === 'task')
      setContacts(
        await fetchTaskCount(
          node?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeId,
          true
        )
      );
    else if(node?.group === "condition") {
      let parentNodeIdOfCondition = flowchartData.getParentNodeID(node?.id);
      let parentNode = flowchartData.getNodeByID(parentNodeIdOfCondition);
    
      if (parentNode?.type === 'email') {
        let completed = await fetchTaskCount(
          parentNode?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition,
          true,
          false,
          true
        );
        let bounced = await fetchLogsCount(
          'bounce',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        let unsubscribed = await fetchLogsCount(
          'unsubscribed',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        setContacts(completed - (bounced + unsubscribed));
      } else if (parentNode?.type === 'task')
        setContacts(
          await fetchTaskCount(
            parentNode?.type,
            flowchartData.sequenceData?.id,
            flowchartData.sequenceData?.start,
            flowchartData.sequenceData?.end,
            parentNodeIdOfCondition,
            true
          )
        );
    }
  };

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader onClose={() => flowchartData.removeNode(rest?.id)} />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>{' '}
          <br />
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon warning mr-3'>
            <UilArchive
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle'>
            Archive Lead
          </div>
          <div className='blockDescription'>
            <span className="textWarning">
              Archive Lead from List
            </span>
          </div>
        </div>
      </div>

      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
    </div>
  );
}

function UpdateAttribute({ data, ...rest }) {
  const flowchartData = useFlowchartData();

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader onClose={() => flowchartData.removeNode(rest?.id)} />
      )}
      <UilUserCircle
        size={38}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      />
      <div style={{ textAlign: 'center' }}>
        <b style={{ fontWeight: 900 }}>Update Attribute</b>
      </div>
      <div style={{ textAlign: 'center' }}>
        <div>
          <b>
            Attribute: <span className="text-blue-500">{data?.attribute} </span>
          </b>
        </div>
        <div>
          <b>
            Value: <span className="text-blue-500">{data?.value} </span>
          </b>
        </div>
      </div>
      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
    </div>
  );
}

function UnsubscribeContact({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
    }
  }, [flowchartData.sequenceData]);

  const getData = async () => {
    let parentNodeId = flowchartData.getParentNodeID(rest?.id);
    let node = flowchartData.getNodeByID(parentNodeId);
    if (node?.type === 'email') {
      let completed = await fetchTaskCount(
        node?.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId,
        true,
        false,
        true
      );
      let bounced = await fetchLogsCount(
        'bounce',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      let unsubscribed = await fetchLogsCount(
        'unsubscribed',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      setContacts(completed - (bounced + unsubscribed));
    } else if (node?.type === 'task')
      setContacts(
        await fetchTaskCount(
          node?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeId,
          true
        )
      );
    else if(node?.group === "condition") {
      let parentNodeIdOfCondition = flowchartData.getParentNodeID(node?.id);
      let parentNode = flowchartData.getNodeByID(parentNodeIdOfCondition);
    
      if (parentNode?.type === 'email') {
        let completed = await fetchTaskCount(
          parentNode?.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition,
          true,
          false,
          true
        );
        let bounced = await fetchLogsCount(
          'bounce',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        let unsubscribed = await fetchLogsCount(
          'unsubscribed',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        setContacts(completed - (bounced + unsubscribed));
      } else if (parentNode?.type === 'task')
        setContacts(
          await fetchTaskCount(
            parentNode?.type,
            flowchartData.sequenceData?.id,
            flowchartData.sequenceData?.start,
            flowchartData.sequenceData?.end,
            parentNodeIdOfCondition,
            true
          )
        );
    }
  };

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader onClose={() => flowchartData.removeNode(rest?.id)} />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>{' '}
          <br />
        </div>
      )}

      <div className='p-2'>
        <div>
          <div className='csSequenceIcon warning' style={{ margin: 'auto', width: 60 }}>
            <UilEnvelopeTimes
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>

        <div style={{ textAlign: 'center' }} className='blockTitle mt-2'>
          Unsubscribe Lead & Stop Sequences
        </div>
      </div>

      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
    </div>
  );
}

function MoveContactToList({ data, ...rest }) {
  const flowchartData = useFlowchartData();
  const [contacts, setContacts] = useState(0);

  useEffect(() => {
    if (flowchartData.isReportMode) {
      getData();
    }
  }, [flowchartData.sequenceData]);

  const getData = async () => {
    let parentNodeId = flowchartData.getParentNodeID(rest?.id);
    let node = flowchartData.getNodeByID(parentNodeId);
    if (node.type === 'email') {
      let completed = await fetchTaskCount(
        node.type,
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId,
        true,
        false,
        true
      );
      let bounced = await fetchLogsCount(
        'bounce',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      let unsubscribed = await fetchLogsCount(
        'unsubscribed',
        flowchartData.sequenceData?.id,
        flowchartData.sequenceData?.start,
        flowchartData.sequenceData?.end,
        parentNodeId
      );
      setContacts(completed - (bounced + unsubscribed));
    } else if (node.type === 'task')
      setContacts(
        await fetchTaskCount(
          node.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeId,
          true
        )
      );
    else if(node?.group === "condition") {
      let parentNodeIdOfCondition = flowchartData.getParentNodeID(node?.id);
      let parentNode = flowchartData.getNodeByID(parentNodeIdOfCondition);
      
      if (parentNode.type === 'email') {
        let completed = await fetchTaskCount(
          parentNode.type,
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition,
          true,
          false,
          true
        );
        let bounced = await fetchLogsCount(
          'bounce',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        let unsubscribed = await fetchLogsCount(
          'unsubscribed',
          flowchartData.sequenceData?.id,
          flowchartData.sequenceData?.start,
          flowchartData.sequenceData?.end,
          parentNodeIdOfCondition
        );
        setContacts(completed - (bounced + unsubscribed));
      } else if (parentNode.type === 'task')
        setContacts(
          await fetchTaskCount(
            parentNode.type,
            flowchartData.sequenceData?.id,
            flowchartData.sequenceData?.start,
            flowchartData.sequenceData?.end,
            parentNodeIdOfCondition,
            true
          )
        );
    }
  };

  return (
    <div style={{ ...customNodeStyles }} className='csFlowchartBlock'>
      {!flowchartData.isReportMode && (
        <NodeActionHeader onClose={() => flowchartData.removeNode(rest?.id)} />
      )}
      {flowchartData.isReportMode && (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <b
            style={{
              borderBottom: '#75caaa 3px solid',
              padding: 3,
              fontSize: 18,
            }}
          >
            Leads : <span className="text-blue-500">{contacts}</span>
          </b>{' '}
          <br />
        </div>
      )}

      <div className='flex p-2'>
        <div>
          <div className='csSequenceIcon warning mr-3'>
            <UilExpandArrowsAlt
              size={38}
              style={{ marginLeft: 'auto', marginRight: 'auto', transform: 'rotate(45deg)' }}
            />
          </div>
        </div>
        <div>
          <div className='blockTitle mt-1'>
            Move Lead To
          </div>
          <div className='blockDescription'>
            <span className="textWarning">
              {data?.lists?.value[0].label}
            </span>
          </div>
        </div>
      </div>

      <Handle type="target" position={Position.Top} id={rest?.id + '-tgt'} />
    </div>
  );
}

const nodeTypes = {
  start: FirstNode,
  add: AddNode,
  call: Call,
  // Sources
  add_source_block: AddSourceNode,
  source_connect: SourceConnect,
  source_entry_list: SourceEntryList,
  source_entry_crmlist: SourceEntryCRMList,
  source_entry_condition: SourceEntryCondition,
  source_entry_attribute: SourceEntryAttribute,
  source_entry_integration: SourceIntegration,
  // Outreach
  email: Email,
  task: Task,
  // Conditions
  ifelse: IfElse,
  delay: Delay,
  absplit: ABSplit,
  // Actions
  pause_campaign: PauseCampaign,
  remove_contact: RemoveContact,
  update_attribute: UpdateAttribute,
  unsubscribe_contact: UnsubscribeContact,
  move_contact_to_list: MoveContactToList,
};

export default nodeTypes;
