/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  AuthContext,
  Form,
  Button,
  Animate,
  useAPI,
  Event,
  Loader,
} from 'components/lib';
import CustomDialog from 'components/custom/customDialog';
import { Col, Row } from 'react-grid-system';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { UilQuestionCircle } from '@iconscout/react-unicons'
import { Tooltip } from 'react-tooltip';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export function Profile(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [apiKey, setAPIKey] = useState("");
  const [apiKeyAvailable, setAPIKeyAvailable] = useState(false);
  const subscription = useAPI('/api/account/subscription');
  const [isLoading,setIsLoading] = useState(false)
  // context
  const authContext = useContext(AuthContext);

  // fetch
  const user = useAPI('/api/user');

  useEffect(() => {
    if(user.data?.open_ai_api_key) {
      setAPIKey(user.data?.open_ai_api_key)
      if(user.data?.open_ai_api_key !== "") setAPIKeyAvailable(true)
    }
  }, [user])

  async function closeAccount() {
    try {
      setIsOpen(false)
      setIsLoading(true)
      let res = await Axios({
        method: 'DELETE',
        url: '/api/user/delete',
      });
       
      if(res.status=== 200){

        Event('closed_account');
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/signup'
      }
      setIsLoading(false)
    } catch (err) {
      console.log(err.response);
      Toast.fire({icon: 'error', title: err?.response?.data?.msg || 'Something went wrong' })
      setIsOpen(false)
      setIsLoading(false)
    }
  }

  async function saveApiKey(removeKey) {
    if(apiKey.trim() === "" && !removeKey) return Toast.fire({ icon: 'error', title: `API key is required.` });
    try {
      let data = { open_ai_api_key: apiKey }
      if(removeKey) data = {
        remove_key: true,
        open_ai_api_key: ""
      }
      let res = await Axios({
        method: 'POST',
        url: '/api/user/open-ai-api-key',
        data: data,
      });
      if(removeKey) {
        setAPIKeyAvailable(false)
        setAPIKey("")
      } else setAPIKeyAvailable(true)
      Toast.fire({ icon: 'success', title: `API key ${removeKey?"removed":"saved"} successfully.` });
    } catch (err) {
      Toast.fire({ icon: 'error', title: err.response?.data?.message || 'API key is required.' });
    }
  }

  if (user.loading) return <Loader />;
  if(isLoading) return <Loader/>

  return (
    <div>
      <Animate>
        <div class="account-profile">
          <h1 className="heading">Edit Your Account</h1>
          <Row gap={2}>
            <Col md={6}>
              <div className='card'>
                {user?.data && (
                  <Form
                    buttonText="Save"
                    url="/api/user"
                    method="PATCH"
                    data={{
                      name: {
                        label: 'Your Name',
                        type: 'text',
                        required: true,
                        value: user.data.name,
                        errorMessage: 'Please enter your name',
                      },
                      email: {
                        label: 'Email address',
                        type: 'email',
                        required: true,
                        value: user.data.email,
                        errorMessage: 'Please enter your email address',
                      },
                      phone: {
                        label: 'Phone Number',
                        type: "phone",
                        required: true,
                        value: user.data.phone,
                        errorMessage: 'Please enter your phone number'
                      },
                      // ...(user.data.accounts?.length > 1 && {
                      //   default_account: {
                      //     label: 'Default Account',
                      //     type: 'select',
                      //     default: user.data.default_account,
                      //     options: user.data.accounts.map((x) => {
                      //       return {
                      //         value: x.id,
                      //         label: x.name,
                      //       };
                      //     }),
                      //   },
                      // }),
                    }}
                    callback={(res) => {
                      // update the account name
                      if (authContext.user?.accounts?.length > 0) {
                        const accounts = [...authContext.user.accounts];
                        accounts[
                          accounts.findIndex(
                            (x) => x.id === authContext.user.account_id
                          )
                        ].name = res.data.data.account_name;
                        authContext.update({ accounts: accounts });
                      }
                      // update the user name
                      authContext.update({ name: res.data.data.name });
                    }}
                  />
                )}
                <Fragment>
                  <br />
                  {
                    authContext.permission.owner &&
                    <Button
                    color="red"
                    style={{ width: '100%' }}
                    action={() => setIsOpen(true)}
                    text="Delete My Account"
                    variant="secondary red"
                    />
                  }
                </Fragment>
              </div>
            </Col>
            {subscription?.data?.coupons?.length >= 3  && <Col md={6}>
              <div className="card addTopMargin">
                <h1 className="text-2xl text-modal-header padding-left flex gap-2 items-center">Add your own OpenAI API Key <UilQuestionCircle style={{ flexShrink: 0, cursor: 'pointer' }} id='add-open-ai-key' /></h1>
                <Tooltip anchorSelect='#add-open-ai-key'>Once a valid OpenAI Key is added, it will be used for<br /> Personalizing Email Templates & Sequences instead of BlinkGPT AI. <br /> All AI Features also become unlimited in this case.</Tooltip>
                <p className="text-modal-description padding-left">
                  Ensure that your OpenAI account has at least $5 in prepaid credits.
                </p>
                <div className='padding-class'>
                  <label for="api-key" className='relative block text-sm font-semibold mb-1'>Bring your Own OpenAI API Key</label>
                  <input
                    placeholder="Enter your api key"
                    className="relative block w-full p-3 rounded border bordeer-solid border-slate-300 appearance-none"
                    type="text"
                    name="api_key"
                    id="api-key"
                    value={apiKey}
                    onChange={(e) => setAPIKey(e.target.value)}
                  />
                  <button
                    style={{ marginTop: 10, width: '100%' }}
                    className="customButton mt-2 py-2 px-4 primary"
                    onClick={() => saveApiKey()}
                  >
                    Save OpenAI API Key
                  </button>
                  {apiKeyAvailable && <button
                    style={{ marginTop: 10, width: '100%' }}
                    className="customButton mt-2 py-2 px-4 primary red"
                    onClick={() => saveApiKey(true)}
                  >
                    Delete
                  </button>}
                </div>
              </div>
            </Col>}
          </Row>
        </div>
      </Animate>

      {isOpen && (
        <CustomDialog width={200}>
          <h2 className="text-2xl text-modal-header">
            Delete your Account
          </h2>
          <p style={{ paddingLeft: 15 }}>
            Are you sure you want to delete your account? <br />
            This would remove all your data, workspaces including users as well. <br />
            This can not be undone.
          </p>

          <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Col md={6}>
              <Button
                style={{ marginLeft: 15, width: '100%' }}
                color="red"
                variant="primary red"
                text="Delete Account"
                action={closeAccount}
              />
            </Col>
            <Col md={6} className='addTopMargin'>
              <Button
                style={{ width: '100%' }}
                text="Cancel"
                variant="primary"
                action={() => setIsOpen(false)}
              />
            </Col>
          </Row>
        </CustomDialog>
      )}
    </div>
  );
}
