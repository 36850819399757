const integrationsData = [
  {
    "name": "HubSpot",
    "slug": "hubspot",
    "native": true,
    "logo": "https://zapier-images.imgix.net/storage/developer/cde9764aa8d19fdd6d591455dbe5a78d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HubSpot is your all-in-one stop for all of your marketing software needs.",
    "category": "Marketing Automation",
    "title": "HubSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate HubSpot with SalesBlink",
    "metaDescription": "Integrate HubSpot and other Marketing Automation Tools with SalesBlink. Two Way HubSpot Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Pipedrive",
    "slug": "pipedrive",
    "native": true,
    "logo": "https://zapier-images.imgix.net/storage/developer/a70589f8db6a514b6820267b506409f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pipedrive is sales pipeline and CRM software for deal makers. Get super-organized. Close deals in less time. iOS and Android apps let you take your pipeline with you anywhere.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pipedrive Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipedrive with SalesBlink",
    "metaDescription": "Integrate Pipedrive and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pipedrive Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Zoho CRM",
    "slug": "zoho-crm",
    "native": true,
    "logo": "https://zapier-images.imgix.net/storage/services/8b222e5e2d52e0b827a02ba8c3162de1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho CRM is a web-based CRM designed to attract, retain, and satisfy customers to grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zoho CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho CRM with SalesBlink",
    "metaDescription": "Integrate Zoho CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zoho CRM Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Slack",
    "slug": "slack",
    "logo": "https://zapier-images.imgix.net/storage/services/6cf3f5a461feadfba7abc93c4c395b33_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "native": true,
    "appDescription": "Slack is a platform for team communication: everything in one place, instantly searchable, available wherever you go. Offering instant messaging, document sharing and knowledge search for modern teams.",
    "category": "Team Chat",
    "title": "Slack Integration With Cold Email Sales Tool",
    "h1": "Integrate Slack with SalesBlink",
    "metaDescription": "Integrate Slack and other Team Chat Tools with SalesBlink. Two Way Slack Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Microsoft Outlook",
    "slug": "microsoft-outlook",
    "native": true,
    "logo": "https://zapier-images.imgix.net/storage/services/17b8fae71a30cf910b73ed0eda8b1443.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft Outlook is a web-based suite of webmail, contacts, tasks, and calendaring services.",
    "category": "Email",
    "title": "Microsoft Outlook Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Outlook with SalesBlink",
    "metaDescription": "Integrate Microsoft Outlook and other Email Tools with SalesBlink. Two Way Microsoft Outlook Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Gmail",
    "slug": "gmail",
    "native": true,
    "logo": "https://zapier-images.imgix.net/storage/services/1afcb319c029ec5da10efb593b7159c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "One of the most popular email services, Gmail keeps track of all your emails with threaded conversations, tags, and Google-powered search to find any message you need.",
    "category": "Email",
    "title": "Gmail Integration With Cold Email Sales Tool",
    "h1": "Integrate Gmail with SalesBlink",
    "metaDescription": "Integrate Gmail and other Email Tools with SalesBlink. Two Way Gmail Integration for all your data related to sales & cold email.",
  },

  {
    "name": "Google Calendar",
    "slug": "google-calendar",
    "native": true,
    "logo": "https://zapier-images.imgix.net/storage/services/5e4971d60629bca0548ded987b9ddc06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
    "category": "Calendar",
    "title": "Google Calendar Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Calendar with SalesBlink",
    "metaDescription": "Integrate Google Calendar and other Calendar Tools with SalesBlink. Two Way Google Calendar Integration for all your data related to sales & cold email."
  },
  {
    "name": "Zapier",
    "slug": "zapier",
    "logo": "https://zapier-images.imgix.net/storage/services/9103b01cf64908114323789f4480455d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zapier is an online automation tool that connects your favorite apps, such as CRM's and over 7,000 others. By allowing apps to communicate with one another, Zapier enables you to automate repetitive tasks without needing to code or rely on developers to build the integration. The service provides a user-friendly interface to create 'Zaps' — automated workflows that connect apps and perform tasks automatically.",
    "category": "Developer Tools",
    "title": "Zapier Integration With Cold Email Sales Tool",
    "h1": "Integrate Zapier with SalesBlink",
    "metaDescription": "Integrate Zapier with SalesBlink. Two Way Zapier Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Make",
    "slug": "make",
    "native": true,
    "logo": "https://res.cloudinary.com/dotrlsog9/image/upload/v1712871877/Integration%20Images.-%20Used%20in%20SalesBlink/make_mjnqzi.png",
    "appDescription": "Use the Make to create a delayed trigger or stay in the know about new supported apps, triggers and actions.",
    "category": "Developer Tools",
    "title": "Make Integration With Cold Email Sales Tool",
    "h1": "Integrate Make with SalesBlink",
    "metaDescription": "Integrate Make with SalesBlink. Two Way Make Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Pabbly Connect",
    "slug": "pabbly",
    "native": true,
    "logo": "https://res.cloudinary.com/dotrlsog9/image/upload/v1712871878/Integration%20Images.-%20Used%20in%20SalesBlink/pabbly-connect_prmwgq.webp",
    "appDescription": "Pabbly Connect. The one app to rule them all! Use the Zapier app to create a delayed trigger or stay in the know about new supported apps, triggers and actions.",
    "category": "Developer Tools",
    "title": "Pabbly Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Pabbly Connect with SalesBlink",
    "metaDescription": "Integrate Pabbly Connect and with SalesBlink. Two Way Pabbly Connect Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Integrately",
    "slug": "integrately",
    "native": true,
    "logo": "https://res.cloudinary.com/dotrlsog9/image/upload/v1712872551/Integration%20Images.-%20Used%20in%20SalesBlink/integrately_hzfx20.png",
    "appDescription": "Use the Integrately to create a delayed trigger or stay in the know about new supported apps, triggers and actions.",
    "category": "Developer Tools",
    "title": "Integrately Integration With Cold Email Sales Tool",
    "h1": "Integrate Integrately with SalesBlink",
    "metaDescription": "Integrate Integrately with SalesBlink. Two Integrately Make Integration for all your data related to sales & cold email.",
  },
  {
    "name": "Boost.space",
    "slug": "boost.space",
    "native": true,
    "logo": "https://boost.space/wp-content/uploads/2023/03/BS-fav-general.png",
    "appDescription": "Centralize data and automate business processes with SalesBlink integrations.",
    "category": "Developer Tools",
    "title": "Boost.space Integration With Cold Email Sales Tool",
    "h1": "Integrate Boost.space with SalesBlink",
    "metaDescription": "Integrate Boost.space with SalesBlink.",
  },
  {
    "name": "Google Sheets",
    "slug": "google-sheets",
    "logo": "https://zapier-images.imgix.net/storage/services/8913a06feb7556d01285c052e4ad59d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create, edit, and share spreadsheets wherever you are with Google Sheets, and get automated insights from your data.",
    "category": "Google",
    "title": "Google Sheets Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Sheets with SalesBlink",
    "metaDescription": "Integrate Google Sheets and other Google Tools with SalesBlink. Two Way Google Sheets Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Mailchimp",
    "slug": "mailchimp",
    "logo": "https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Share your ideas with Mailchimp email newsletters—then use its landing page and form builders to grow your lists and take marketing further with drip and transactional emails.",
    "category": "Email Newsletters",
    "title": "Mailchimp Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailchimp with SalesBlink",
    "metaDescription": "Integrate Mailchimp and other Email Newsletters Tools with SalesBlink. Two Way Mailchimp Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Drive",
    "slug": "google-drive",
    "logo": "https://zapier-images.imgix.net/storage/services/a5b8a9920e9dae8a73711590e7090d3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Drive is Google's file sync app that lets you store all of your files online alongside your Google Docs documents, and keep them synced with all of your devices.",
    "category": "File Management & Storage",
    "title": "Google Drive Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Drive with SalesBlink",
    "metaDescription": "Integrate Google Drive and other File Management & Storage Tools with SalesBlink. Two Way Google Drive Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Facebook Lead Ads",
    "slug": "facebook-lead-ads",
    "logo": "https://zapier-images.imgix.net/storage/services/f407c31b217aac6e0cd4171092d53a8c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook lead ads make signing up for business information easy for people and more valuable for businesses. The Facebook lead ad app is useful for marketers who want to automate actions on their leads.",
    "category": "Ads & Conversion",
    "title": "Facebook Lead Ads Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Lead Ads with SalesBlink",
    "metaDescription": "Integrate Facebook Lead Ads and other Ads & Conversion Tools with SalesBlink. Two Way Facebook Lead Ads Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Twitter",
    "slug": "twitter",
    "logo": "https://zapier-images.imgix.net/storage/services/580b3c79622bbff634f0d764e9f813a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
    "category": "Social Media Accounts",
    "title": "Twitter Integration With Cold Email Sales Tool",
    "h1": "Integrate Twitter with SalesBlink",
    "metaDescription": "Integrate Twitter and other Social Media Accounts Tools with SalesBlink. Two Way Twitter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notion",
    "slug": "notion",
    "logo": "https://zapier-images.imgix.net/storage/services/0de44c7d5f0046873886168b9b498f66_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A new tool that blends your everyday work apps into one. It's the all-in-one workspace for you and your team.",
    "category": "Task Management",
    "title": "Notion Integration With Cold Email Sales Tool",
    "h1": "Integrate Notion with SalesBlink",
    "metaDescription": "Integrate Notion and other Task Management Tools with SalesBlink. Two Way Notion Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Trello",
    "slug": "trello",
    "logo": "https://zapier-images.imgix.net/storage/services/da3ff465abd3a3e1b687c52ff803af74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trello is a team collaboration tool that lets you organize anything and everything to keep your projects on task.",
    "category": "Project Management",
    "title": "Trello Integration With Cold Email Sales Tool",
    "h1": "Integrate Trello with SalesBlink",
    "metaDescription": "Integrate Trello and other Project Management Tools with SalesBlink. Two Way Trello Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Calendly",
    "slug": "calendly",
    "logo": "https://zapier-images.imgix.net/storage/services/33464c48a26a29dd29977ffb16bcca53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Calendly is an elegant and simple scheduling tool for businesses that eliminates email back and forth. It helps save time so that businesses can provide great service and increase sales.",
    "category": "Scheduling & Booking",
    "title": "Calendly Integration With Cold Email Sales Tool",
    "h1": "Integrate Calendly with SalesBlink",
    "metaDescription": "Integrate Calendly and other Scheduling & Booking Tools with SalesBlink. Two Way Calendly Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Discord",
    "slug": "discord",
    "logo": "https://zapier-images.imgix.net/storage/services/ca03beabe94d8f97ba6fbf75cbb695c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Discord is an all-in-one voice and text chat for gamers.",
    "category": "Team Chat",
    "title": "Discord Integration With Cold Email Sales Tool",
    "h1": "Integrate Discord with SalesBlink",
    "metaDescription": "Integrate Discord and other Team Chat Tools with SalesBlink. Two Way Discord Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Forms",
    "slug": "google-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82ee79efa898e62c2c8b71187e09fb91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Forms is an easy way to collect data from the web with a simple UI and powerful editor. Works hand-in-hand with Google Sheets!",
    "category": "Forms & Surveys",
    "title": "Google Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Forms with SalesBlink",
    "metaDescription": "Integrate Google Forms and other Forms & Surveys Tools with SalesBlink. Two Way Google Forms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Airtable",
    "slug": "airtable",
    "logo": "https://zapier-images.imgix.net/storage/developer/c6c8c5e300ef0da0e47b3084e5522f20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Organize anything with Airtable, a modern database created for everyone. Airtable is a fast and flexible way to create tables to keep track of anything, from sales leads to vacation planning to inventory management.",
    "category": "Databases",
    "title": "Airtable Integration With Cold Email Sales Tool",
    "h1": "Integrate Airtable with SalesBlink",
    "metaDescription": "Integrate Airtable and other Databases Tools with SalesBlink. Two Way Airtable Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Typeform",
    "slug": "typeform",
    "logo": "https://zapier-images.imgix.net/storage/services/065860c1e1210fdf4040105024099b0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Typeform helps you ask awesomely online! If you ever need to run a survey, questionnaire, form, contest etc. Typeform will help you achieve it beautifully across all devices, every time, using its next generation platform.",
    "category": "Forms & Surveys",
    "title": "Typeform Integration With Cold Email Sales Tool",
    "h1": "Integrate Typeform with SalesBlink",
    "metaDescription": "Integrate Typeform and other Forms & Surveys Tools with SalesBlink. Two Way Typeform Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ActiveCampaign",
    "slug": "activecampaign",
    "logo": "https://zapier-images.imgix.net/storage/developer/d3f66e256c0d227d06b0686d724badad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ActiveCampaign is a customer experience automation (CXA) platform that helps businesses meaningfully engage customers. Access pre-built automations that combine transactional email and email marketing, marketing automation, ecommerce marketing, and CRM for powerful segmentation and personalization across social, email, messaging, chat, and text.",
    "category": "Marketing Automation",
    "title": "ActiveCampaign Integration With Cold Email Sales Tool",
    "h1": "Integrate ActiveCampaign with SalesBlink",
    "metaDescription": "Integrate ActiveCampaign and other Marketing Automation Tools with SalesBlink. Two Way ActiveCampaign Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Stripe",
    "slug": "stripe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27f5433f521806493e2c38a304e1786e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stripe is a developer-friendly way to accept payments online and in mobile apps. Stripe's suite of APIs powers commerce for thousands of companies of all sizes, processing billions of dollars for businesses each year.",
    "category": "Payment Processing",
    "title": "Stripe Integration With Cold Email Sales Tool",
    "h1": "Integrate Stripe with SalesBlink",
    "metaDescription": "Integrate Stripe and other Payment Processing Tools with SalesBlink. Two Way Stripe Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Facebook Pages",
    "slug": "facebook-pages",
    "logo": "https://zapier-images.imgix.net/storage/services/f407c31b217aac6e0cd4171092d53a8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook Pages lets you connect with your customers, fans and followers on the world's largest social network. Share relevant content to engage people who Like your page, create events to build and grow your community, and run a Facebook ad campaign by targeting location, age and more.",
    "category": "Social Media Accounts",
    "title": "Facebook Pages Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Pages with SalesBlink",
    "metaDescription": "Integrate Facebook Pages and other Social Media Accounts Tools with SalesBlink. Two Way Facebook Pages Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Asana",
    "slug": "asana",
    "logo": "https://zapier-images.imgix.net/storage/services/8a7b28de359dd07048ac9153f797fa5d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Asana is a leading work management platform that helps teams orchestrate their work, from daily tasks to strategic initiatives. With Asana, organizations have a living system of clarity where everyone can see, discuss, and execute their team’s priorities.",
    "category": "Project Management",
    "title": "Asana Integration With Cold Email Sales Tool",
    "h1": "Integrate Asana with SalesBlink",
    "metaDescription": "Integrate Asana and other Project Management Tools with SalesBlink. Two Way Asana Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Salesforce",
    "slug": "salesforce",
    "logo": "https://zapier-images.imgix.net/storage/services/1a4435539b4764e6ded3d368cabab387.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesforce is a leading enterprise customer relationship manager (CRM) application.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salesforce Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesforce with SalesBlink",
    "metaDescription": "Integrate Salesforce and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salesforce Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Webflow",
    "slug": "webflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/dba91ada3f465bc866a1c4431236d9f2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Design and build professional websites with a CMS from scratch online with Webflow.",
    "category": "Website Builders",
    "title": "Webflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Webflow with SalesBlink",
    "metaDescription": "Integrate Webflow and other Website Builders Tools with SalesBlink. Two Way Webflow Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Gravity Forms",
    "slug": "gravity-forms",
    "logo": "https://zapier-images.imgix.net/storage/services/3d15efa7393283182b8539dbb4cfc720.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gravity Forms is a premier WordPress plugin that makes it easy to create powerful forms on the fly.",
    "category": "Forms & Surveys",
    "title": "Gravity Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Gravity Forms with SalesBlink",
    "metaDescription": "Integrate Gravity Forms and other Forms & Surveys Tools with SalesBlink. Two Way Gravity Forms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "YouTube",
    "slug": "youtube",
    "logo": "https://zapier-images.imgix.net/storage/services/7633e61620664fb3a71911729ebce5ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YouTube allows billions of people to discover, watch and share originally-created videos. YouTube provides a forum for people to connect, inform, and inspire others across the globe and acts as a distribution platform for original content creators and advertisers large and small.",
    "category": "Google",
    "title": "YouTube Integration With Cold Email Sales Tool",
    "h1": "Integrate YouTube with SalesBlink",
    "metaDescription": "Integrate YouTube and other Google Tools with SalesBlink. Two Way YouTube Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Jotform",
    "slug": "jotform",
    "logo": "https://zapier-images.imgix.net/storage/developer/213e7440accf056022f04633557b80ee_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The newly redesigned Jotform lets you build forms faster than ever, with powerful widgets and a responsive editor that works offline—without even needing to sign up for an account.",
    "category": "Forms & Surveys",
    "title": "Jotform Integration With Cold Email Sales Tool",
    "h1": "Integrate Jotform with SalesBlink",
    "metaDescription": "Integrate Jotform and other Forms & Surveys Tools with SalesBlink. Two Way Jotform Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Shopify",
    "slug": "shopify",
    "logo": "https://zapier-images.imgix.net/storage/services/4da9d3e3f93cd522f85e1b0695341f89.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shopify is a simple way to create an online store to list products, collect credit card payments, and ship your goods.",
    "category": "eCommerce",
    "title": "Shopify Integration With Cold Email Sales Tool",
    "h1": "Integrate Shopify with SalesBlink",
    "metaDescription": "Integrate Shopify and other eCommerce Tools with SalesBlink. Two Way Shopify Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ClickUp",
    "slug": "clickup",
    "logo": "https://zapier-images.imgix.net/storage/services/000c15b838d86d80869cff5938fa76f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickUp is a productivity platform that allows you to manage your work and personal tasks in a beautifully intuitive environment.",
    "category": "Project Management",
    "title": "ClickUp Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickUp with SalesBlink",
    "metaDescription": "Integrate ClickUp and other Project Management Tools with SalesBlink. Two Way ClickUp Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Contacts",
    "slug": "google-contacts",
    "logo": "https://zapier-images.imgix.net/storage/services/1508661b55cd3c5ad1787303b0f58c99.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Contacts, the address book built into Gmail, lets you keep track of all your contacts, see when you last interacted with them, and more.",
    "category": "Contact Management",
    "title": "Google Contacts Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Contacts with SalesBlink",
    "metaDescription": "Integrate Google Contacts and other Contact Management Tools with SalesBlink. Two Way Google Contacts Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "monday.com",
    "slug": "monday",
    "logo": "https://zapier-images.imgix.net/storage/developer/2663f19cb1a591e113356c9ba376a567.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "monday.com helps you move projects forward fast, letting everyone know what's been done on a task—and what needs finished right now.",
    "category": "Project Management",
    "title": "monday.com Integration With Cold Email Sales Tool",
    "h1": "Integrate monday.com with SalesBlink",
    "metaDescription": "Integrate monday.com and other Project Management Tools with SalesBlink. Two Way monday.com Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Squarespace",
    "slug": "squarespace",
    "logo": "https://zapier-images.imgix.net/storage/developer/5175c576e6c37b8986843d42bc89c244.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Squarespace is a website builder, blogging platform, hosting service, commerce platform, and domain name registrar.",
    "category": "Forms & Surveys",
    "title": "Squarespace Integration With Cold Email Sales Tool",
    "h1": "Integrate Squarespace with SalesBlink",
    "metaDescription": "Integrate Squarespace and other Forms & Surveys Tools with SalesBlink. Two Way Squarespace Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoom",
    "slug": "zoom",
    "logo": "https://zapier-images.imgix.net/storage/developer/472b2b8aba2dc38d4ef605c955341739_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoom brings teams together to get more done in a frictionless environment. Zoom's reliable, video-first unified communications platform provides video meetings, voice, webinars, and chat across desktops, phones, mobile devices, and conference systems",
    "category": "Video Conferencing",
    "title": "Zoom Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoom with SalesBlink",
    "metaDescription": "Integrate Zoom and other Video Conferencing Tools with SalesBlink. Two Way Zoom Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OpenAI (GPT-3, DALL·E, Whisper)",
    "slug": "openai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a86f51fcd659c4b311c82ba31a176e4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "From the makers of ChatGPT, the OpenAI integration for GPT-3, DALL-E, or Whisper. Build AI powered workflows with OpenAI’s powerful models. \n\nAn optimized ChatGPT integration is available here: https://zapier.com/apps/chatgpt/integrations",
    "category": "AI Tools",
    "title": "OpenAI (GPT-3, DALL·E, Whisper) Integration With Cold Email Sales Tool",
    "h1": "Integrate OpenAI (GPT-3, DALL·E, Whisper) with SalesBlink",
    "metaDescription": "Integrate OpenAI (GPT-3, DALL·E, Whisper) and other AI Tools Tools with SalesBlink. Two Way OpenAI (GPT-3, DALL·E, Whisper) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instagram",
    "slug": "instagram",
    "logo": "https://zapier-images.imgix.net/storage/developer/30129480e943e5b3e334394168ae16a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instagram is a social network which is a fast, beautiful and fun way to share your photos with friends and family.",
    "category": "Social Media Accounts",
    "title": "Instagram Integration With Cold Email Sales Tool",
    "h1": "Integrate Instagram with SalesBlink",
    "metaDescription": "Integrate Instagram and other Social Media Accounts Tools with SalesBlink. Two Way Instagram Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Docs",
    "slug": "google-docs",
    "logo": "https://zapier-images.imgix.net/storage/services/ae42824b58d556d36b5e5b217377fc5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Docs is an online word processor that lets you create and format text documents. Collaboratively edit documents with other people in real time.\n\nWe also support Google Sheets!",
    "category": "Documents",
    "title": "Google Docs Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Docs with SalesBlink",
    "metaDescription": "Integrate Google Docs and other Documents Tools with SalesBlink. Two Way Google Docs Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Acuity Scheduling",
    "slug": "acuity-scheduling",
    "logo": "https://zapier-images.imgix.net/storage/developer/74916f3dde56f4725a8d8df4b514eaea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easy online appointment scheduling software. Save time by having clients book appointments, complete custom intake forms, and pay in advance 24/7 anywhere in the world.",
    "category": "Scheduling & Booking",
    "title": "Acuity Scheduling Integration With Cold Email Sales Tool",
    "h1": "Integrate Acuity Scheduling with SalesBlink",
    "metaDescription": "Integrate Acuity Scheduling and other Scheduling & Booking Tools with SalesBlink. Two Way Acuity Scheduling Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ClickFunnels",
    "slug": "clickfunnels",
    "logo": "https://zapier-images.imgix.net/storage/developer/60ef679f70fc7d8f15b9251b40ec5232.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create beautiful landing pages and complete marketing funnels.",
    "category": "Marketing Automation",
    "title": "ClickFunnels Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickFunnels with SalesBlink",
    "metaDescription": "Integrate ClickFunnels and other Marketing Automation Tools with SalesBlink. Two Way ClickFunnels Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LeadConnector",
    "slug": "leadconnector",
    "logo": "https://zapier-images.imgix.net/storage/services/99776e66344c67ffbf47b24633e809e3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadConnector is a platform for marketing agencies to manage marketing automation to customer communications and beyond.",
    "category": "Marketing Automation",
    "title": "LeadConnector Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadConnector with SalesBlink",
    "metaDescription": "Integrate LeadConnector and other Marketing Automation Tools with SalesBlink. Two Way LeadConnector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kajabi",
    "slug": "kajabi",
    "logo": "https://zapier-images.imgix.net/storage/developer/b02459589d2b18bc525defb06ad4d99f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kajabi is an all in one platform for selling, marketing, and delivering your online content.",
    "category": "eCommerce",
    "title": "Kajabi Integration With Cold Email Sales Tool",
    "h1": "Integrate Kajabi with SalesBlink",
    "metaDescription": "Integrate Kajabi and other eCommerce Tools with SalesBlink. Two Way Kajabi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Microsoft Excel",
    "slug": "excel",
    "logo": "https://zapier-images.imgix.net/storage/services/296388d714e0dcd78105c9b165ca751e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft's Excel is a spreadsheet application used by millions of users across the world. It is part of the Office ecosystem and is considered the industry standard for spreadsheets. \n\nOnly files on OneDrive for Business (available with Office 365 and Sharepoint server) are supported at this time with Zapier.",
    "category": "Microsoft",
    "title": "Microsoft Excel Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Excel with SalesBlink",
    "metaDescription": "Integrate Microsoft Excel and other Microsoft Tools with SalesBlink. Two Way Microsoft Excel Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Todoist",
    "slug": "todoist",
    "logo": "https://zapier-images.imgix.net/storage/developer/965784dd0a38a1e338b61b8b89ceaf78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Managing millions of tasks, Todoist is an online task management app and todo list. It has Web, iPhone, Android, Chrome, Firefox, Outlook flavors (and more!).",
    "category": "Task Management",
    "title": "Todoist Integration With Cold Email Sales Tool",
    "h1": "Integrate Todoist with SalesBlink",
    "metaDescription": "Integrate Todoist and other Task Management Tools with SalesBlink. Two Way Todoist Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "WooCommerce",
    "slug": "woocommerce",
    "logo": "https://zapier-images.imgix.net/storage/developer/114e2646f703010f9740fed6672a62cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WooCommerce is the eCommerce platform for WordPress. This connection requires a paid extension.",
    "category": "eCommerce",
    "title": "WooCommerce Integration With Cold Email Sales Tool",
    "h1": "Integrate WooCommerce with SalesBlink",
    "metaDescription": "Integrate WooCommerce and other eCommerce Tools with SalesBlink. Two Way WooCommerce Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Tasks",
    "slug": "google-tasks",
    "logo": "https://zapier-images.imgix.net/storage/developer/09d7059874ac55089ed9bfcc7a287165.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Tasks is a very simple task list. Works inside Gmail, Android, and Calendar seamlessly.",
    "category": "Google",
    "title": "Google Tasks Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Tasks with SalesBlink",
    "metaDescription": "Integrate Google Tasks and other Google Tools with SalesBlink. Two Way Google Tasks Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Flodesk",
    "slug": "flodesk",
    "logo": "https://zapier-images.imgix.net/storage/services/4ef48934c387980a8d448feca9ab4d63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beginners and experts use Flodesk to create stunning emails that grow their business. Meet the most intuitive way to send emails and build workflows that convert.",
    "category": "Email Newsletters",
    "title": "Flodesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Flodesk with SalesBlink",
    "metaDescription": "Integrate Flodesk and other Email Newsletters Tools with SalesBlink. Two Way Flodesk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Dropbox",
    "slug": "dropbox",
    "logo": "https://zapier-images.imgix.net/storage/services/13ed79eef97afd56b212ece05251b2de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dropbox lets you store your files online, sync them to all your devices, and share them easily. Get started for free, then upgrade for more space and security features.",
    "category": "File Management & Storage",
    "title": "Dropbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Dropbox with SalesBlink",
    "metaDescription": "Integrate Dropbox and other File Management & Storage Tools with SalesBlink. Two Way Dropbox Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ConvertKit",
    "slug": "convertkit",
    "logo": "https://zapier-images.imgix.net/storage/services/a1a7697d691ddd7434a69e16e672b495.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConvertKit is an email marketing platform for professional bloggers. Easily create email sequences, tag subscribers, grow your list, and automate your email marketing.",
    "category": "Drip Emails",
    "title": "ConvertKit Integration With Cold Email Sales Tool",
    "h1": "Integrate ConvertKit with SalesBlink",
    "metaDescription": "Integrate ConvertKit and other Drip Emails Tools with SalesBlink. Two Way ConvertKit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Klaviyo",
    "slug": "klaviyo",
    "logo": "https://zapier-images.imgix.net/storage/services/07923b5b312f4e8d861556a6e71f3d70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Klaviyo is an email marketing platform. Powered by data, built for ecommerce and web businesses.",
    "category": "Marketing Automation",
    "title": "Klaviyo Integration With Cold Email Sales Tool",
    "h1": "Integrate Klaviyo with SalesBlink",
    "metaDescription": "Integrate Klaviyo and other Marketing Automation Tools with SalesBlink. Two Way Klaviyo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Wix Automations",
    "slug": "wix-automations",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06f14d0c25520b2bd1ba003831e6b811.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wix Automations uses actions visitors do on your site, like submit a form, sign up, or purchase, to trigger actions like sending an email or a chat message  & more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wix Automations Integration With Cold Email Sales Tool",
    "h1": "Integrate Wix Automations with SalesBlink",
    "metaDescription": "Integrate Wix Automations and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wix Automations Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickBooks Online",
    "slug": "quickbooks",
    "logo": "https://zapier-images.imgix.net/storage/services/469ac865c30640b422480397b4c1f001.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickBooks Online is the web version of the popular accounting packages QuickBooks. Use any Zapier integration to connect QuickBooks Online to hundreds of other apps.",
    "category": "Accounting",
    "title": "QuickBooks Online Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickBooks Online with SalesBlink",
    "metaDescription": "Integrate QuickBooks Online and other Accounting Tools with SalesBlink. Two Way QuickBooks Online Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Twilio",
    "slug": "twilio",
    "logo": "https://zapier-images.imgix.net/storage/services/304e0410159880592f94af8c8772642d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy tool for developers to send and receive SMS and voice calls.",
    "category": "Phone & SMS",
    "title": "Twilio Integration With Cold Email Sales Tool",
    "h1": "Integrate Twilio with SalesBlink",
    "metaDescription": "Integrate Twilio and other Phone & SMS Tools with SalesBlink. Two Way Twilio Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Sendinblue",
    "slug": "sendinblue",
    "logo": "https://zapier-images.imgix.net/storage/services/adbc91a16e5fdbf038f9532334e50bc6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendinblue powers advanced email marketing and automation. Send newsletter emails, manage your transactional emails, and send SMS on this all-in-one platform.",
    "category": "Email Newsletters",
    "title": "Sendinblue Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendinblue with SalesBlink",
    "metaDescription": "Integrate Sendinblue and other Email Newsletters Tools with SalesBlink. Two Way Sendinblue Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Ads",
    "slug": "google-ads",
    "logo": "https://zapier-images.imgix.net/storage/services/4058ec8b47ad751cbd39bd686cf4eab7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Ads (formerly Google AdWords) is an online advertising platform developed by Google, where advertisers pay to display brief advertisements, service offerings, product listings, video content, and generate mobile application installs within the Google ad network to web users.",
    "category": "Ads & Conversion",
    "title": "Google Ads Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Ads with SalesBlink",
    "metaDescription": "Integrate Google Ads and other Ads & Conversion Tools with SalesBlink. Two Way Google Ads Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "WordPress",
    "slug": "wordpress",
    "logo": "https://zapier-images.imgix.net/storage/services/082ad2b5fd42bbe2319312a8ccad248d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WordPress is web software you can use to create a beautiful website or blog. Nearly 20% of the top 10 million websites and over 60 million people have chosen WordPress to power the place on the web they call \"home\".",
    "category": "Website Builders",
    "title": "WordPress Integration With Cold Email Sales Tool",
    "h1": "Integrate WordPress with SalesBlink",
    "metaDescription": "Integrate WordPress and other Website Builders Tools with SalesBlink. Two Way WordPress Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Jira Software Cloud",
    "slug": "jira-software-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea4a5ee38c7a088e243745dd0b851784.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jira Software Cloud is a bug and issue tracking tool that allows software developers to manage product development. This integration connects to cloud-hosted instances of Jira.",
    "category": "Project Management",
    "title": "Jira Software Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Jira Software Cloud with SalesBlink",
    "metaDescription": "Integrate Jira Software Cloud and other Project Management Tools with SalesBlink. Two Way Jira Software Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailerLite Classic",
    "slug": "mailerlite-classic",
    "logo": "https://zapier-images.imgix.net/storage/services/e3d6a44ac12d67db5fc50d1baf6c59a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use MailerLite Classic to stay connected with your customers through email marketing. Create websites, landing pages, and forms to grow your audience.",
    "category": "Email Newsletters",
    "title": "MailerLite Classic Integration With Cold Email Sales Tool",
    "h1": "Integrate MailerLite Classic with SalesBlink",
    "metaDescription": "Integrate MailerLite Classic and other Email Newsletters Tools with SalesBlink. Two Way MailerLite Classic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Microsoft To Do",
    "slug": "microsoft-todo",
    "logo": "https://zapier-images.imgix.net/storage/services/3588dbd8934cefe869f0db3727208374.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft To Do is an intelligent task management app that makes it easy to plan and manage your day. With its intelligent Suggestions, To Do removes all the clutter and empowers you to focus on what's important, when it's important.",
    "category": "Microsoft",
    "title": "Microsoft To Do Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft To Do with SalesBlink",
    "metaDescription": "Integrate Microsoft To Do and other Microsoft Tools with SalesBlink. Two Way Microsoft To Do Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Constant Contact",
    "slug": "constant-contact",
    "logo": "https://zapier-images.imgix.net/storage/services/ef2025bfbcf90efed14b97c583a7f3f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Constant Contact can help grow your business with an email marketing tool that’s affordable, powerful, and easy to use.",
    "category": "Email Newsletters",
    "title": "Constant Contact Integration With Cold Email Sales Tool",
    "h1": "Integrate Constant Contact with SalesBlink",
    "metaDescription": "Integrate Constant Contact and other Email Newsletters Tools with SalesBlink. Two Way Constant Contact Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zendesk",
    "slug": "zendesk",
    "logo": "https://zapier-images.imgix.net/storage/services/45e89018e756b043d806701f17dc2632.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zendesk is a web-based help desk support tool that makes it simple to provide great customer support.",
    "category": "Customer Support",
    "title": "Zendesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Zendesk with SalesBlink",
    "metaDescription": "Integrate Zendesk and other Customer Support Tools with SalesBlink. Two Way Zendesk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LinkedIn Ads",
    "slug": "linkedin-ads",
    "logo": "https://zapier-images.imgix.net/storage/developer/82e76b99c17c252653cee811b4e46123.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LinkedIn Ads allows marketers to collect even more quality leads from their ads and events on LinkedIn with seamless pre-filled forms.",
    "category": "Ads & Conversion",
    "title": "LinkedIn Ads Integration With Cold Email Sales Tool",
    "h1": "Integrate LinkedIn Ads with SalesBlink",
    "metaDescription": "Integrate LinkedIn Ads and other Ads & Conversion Tools with SalesBlink. Two Way LinkedIn Ads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xero",
    "slug": "xero",
    "logo": "https://zapier-images.imgix.net/storage/services/ef74f1fda109e37e63797cfdf182721b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xero is an accounting app that lets you track businesses expenses and accounting with intuitive mobile apps and web interfaces for point-of-sale, ecommerce, time-tracking, and more.",
    "category": "Accounting",
    "title": "Xero Integration With Cold Email Sales Tool",
    "h1": "Integrate Xero with SalesBlink",
    "metaDescription": "Integrate Xero and other Accounting Tools with SalesBlink. Two Way Xero Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Unbounce",
    "slug": "unbounce",
    "logo": "https://zapier-images.imgix.net/storage/services/606c1e825057f5bf9c13aab28c0b50d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unbounce is a landing page platform that helps you convert more visitors into leads, sales, and customers. Unbounce Conversion Intelligence™ is AI powered-functionality that works with your marketing intuition to ensure the best campaign performance every time.",
    "category": "Forms & Surveys",
    "title": "Unbounce Integration With Cold Email Sales Tool",
    "h1": "Integrate Unbounce with SalesBlink",
    "metaDescription": "Integrate Unbounce and other Forms & Surveys Tools with SalesBlink. Two Way Unbounce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Eventbrite",
    "slug": "eventbrite",
    "logo": "https://zapier-images.imgix.net/storage/services/54c3ff835973c144dd5069c8ba3a5b0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventbrite is an event management tool that makes it simple to create, manage, promote, and sell out your event.",
    "category": "Event Management",
    "title": "Eventbrite Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventbrite with SalesBlink",
    "metaDescription": "Integrate Eventbrite and other Event Management Tools with SalesBlink. Two Way Eventbrite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Dubsado",
    "slug": "dubsado",
    "logo": "https://zapier-images.imgix.net/storage/developer/6999e9c98aad5f45b4af824c6273ff1b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "​Dubsado is a business management suite for the creative industry professionals. Sign contracts, manage tasks, and get paid.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Dubsado Integration With Cold Email Sales Tool",
    "h1": "Integrate Dubsado with SalesBlink",
    "metaDescription": "Integrate Dubsado and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Dubsado Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Any.do Personal",
    "slug": "any-do",
    "logo": "https://zapier-images.imgix.net/storage/services/61b852266b12ca1eb1394f2dfeca7a0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Any.do is a simple & powerful way to manage Projects, To-do lists & Reminders. Available on Web, iPhone, Android, Mac, Windows & More.",
    "category": "Task Management",
    "title": "Any.do Personal Integration With Cold Email Sales Tool",
    "h1": "Integrate Any.do Personal with SalesBlink",
    "metaDescription": "Integrate Any.do Personal and other Task Management Tools with SalesBlink. Two Way Any.do Personal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Microsoft Teams",
    "slug": "microsoft-teams",
    "logo": "https://zapier-images.imgix.net/storage/services/22d6681f64c79dd71a34066afe1c54e2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft Teams is the hub for teamwork in Office 365 that integrates all the people, content, and tools your team needs to be more engaged and effective.",
    "category": "Microsoft",
    "title": "Microsoft Teams Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Teams with SalesBlink",
    "metaDescription": "Integrate Microsoft Teams and other Microsoft Tools with SalesBlink. Two Way Microsoft Teams Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "WPForms",
    "slug": "wpforms",
    "logo": "https://zapier-images.imgix.net/storage/developer/b0904faae8a75c3ca219f9bf92635922.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WPForms is a drag and drop online form builder for WordPress that allows you to easily create contact forms, email subscription forms, billing forms, donation forms, online order forms, and more.",
    "category": "Forms & Surveys",
    "title": "WPForms Integration With Cold Email Sales Tool",
    "h1": "Integrate WPForms with SalesBlink",
    "metaDescription": "Integrate WPForms and other Forms & Surveys Tools with SalesBlink. Two Way WPForms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LinkedIn",
    "slug": "linkedin",
    "logo": "https://zapier-images.imgix.net/storage/developer/82e76b99c17c252653cee811b4e46123_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LinkedIn is the world's largest social network for professionals. Manage your professional identity. Build and engage with your professional network. Access knowledge, insights and opportunities.",
    "category": "Microsoft",
    "title": "LinkedIn Integration With Cold Email Sales Tool",
    "h1": "Integrate LinkedIn with SalesBlink",
    "metaDescription": "Integrate LinkedIn and other Microsoft Tools with SalesBlink. Two Way LinkedIn Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Teachable",
    "slug": "teachable",
    "logo": "https://zapier-images.imgix.net/storage/developer/124120738ab08e69982bfe413eaadb15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teachable allows you to create and sell online courses without any technical knowledge from your own website.",
    "category": "Online Courses",
    "title": "Teachable Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachable with SalesBlink",
    "metaDescription": "Integrate Teachable and other Online Courses Tools with SalesBlink. Two Way Teachable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Facebook Conversions",
    "slug": "facebook-conversions",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e384c792ed7db69ad442b2130fbbd4f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook’s Conversions lets you send information about important customer events to Facebook Server-Side API directly from your website’s server, CRM or other customer information databases. This information is useful to create powerful ads that yield better conversions.",
    "category": "Ads & Conversion",
    "title": "Facebook Conversions Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Conversions with SalesBlink",
    "metaDescription": "Integrate Facebook Conversions and other Ads & Conversion Tools with SalesBlink. Two Way Facebook Conversions Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Wave",
    "slug": "wave",
    "logo": "https://zapier-images.imgix.net/storage/services/cbc390bd0139b3113e485e9cd2c9d6f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wave's invoicing and accounting software is completely free, and has no usage limits or restrictions. Optional Payment Processing and Payroll services are fully integrated to create a seamless platform for your business finances.",
    "category": "Accounting",
    "title": "Wave Integration With Cold Email Sales Tool",
    "h1": "Integrate Wave with SalesBlink",
    "metaDescription": "Integrate Wave and other Accounting Tools with SalesBlink. Two Way Wave Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Keap Max Classic",
    "slug": "keap-max-classic",
    "logo": "https://zapier-images.imgix.net/storage/services/6b64306dd8249dc80c127c578091afc4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keap Max Classic is all-in-one marketing and sales automation software for small businesses. It effortlessly combines CRM, email marketing and e-commerce.",
    "category": "Marketing Automation",
    "title": "Keap Max Classic Integration With Cold Email Sales Tool",
    "h1": "Integrate Keap Max Classic with SalesBlink",
    "metaDescription": "Integrate Keap Max Classic and other Marketing Automation Tools with SalesBlink. Two Way Keap Max Classic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Follow Up Boss",
    "slug": "follow-up-boss",
    "logo": "https://zapier-images.imgix.net/storage/developer/7d618d29e284e975633b532ef3ae9323_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple lead management software for real estate agents and brokers. Follow Up Boss takes all your leads and puts them in one simple system automatically. Helps you to respond fast and convert more leads into closings.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Follow Up Boss Integration With Cold Email Sales Tool",
    "h1": "Integrate Follow Up Boss with SalesBlink",
    "metaDescription": "Integrate Follow Up Boss and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Follow Up Boss Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Thinkific",
    "slug": "thinkific",
    "logo": "https://zapier-images.imgix.net/storage/developer/bf6ec88bd8e251507a3bcdb44f020a8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The all-in-one platform to create, market & sell your online courses. Deliver beautiful courses on your own site, with no technical or design experience required!",
    "category": "Online Courses",
    "title": "Thinkific Integration With Cold Email Sales Tool",
    "h1": "Integrate Thinkific with SalesBlink",
    "metaDescription": "Integrate Thinkific and other Online Courses Tools with SalesBlink. Two Way Thinkific Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Google My Business",
    "slug": "google-my-business",
    "logo": "https://zapier-images.imgix.net/storage/services/70f9cc2e47719b8fc1e71ec44e3b5772.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google My Business launched as a way of giving business owners more control of what shows in the search results when someone searches a given business name.",
    "category": "Google",
    "title": "Google My Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Google My Business with SalesBlink",
    "metaDescription": "Integrate Google My Business and other Google Tools with SalesBlink. Two Way Google My Business Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ThriveCart",
    "slug": "thrivecart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a091a0452483c1dd27c78693e2517685.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ThriveCart is the no. 1 cart software that grows your income from existing traffic.",
    "category": "eCommerce",
    "title": "ThriveCart Integration With Cold Email Sales Tool",
    "h1": "Integrate ThriveCart with SalesBlink",
    "metaDescription": "Integrate ThriveCart and other eCommerce Tools with SalesBlink. Two Way ThriveCart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Intercom",
    "slug": "intercom",
    "logo": "https://zapier-images.imgix.net/storage/services/355a21cce3c24ba339538173ede2ee44.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intercom is the Engagement OS, an open channel between customer and business that is in the product, in the moment, and on their terms. We break down silos, unifying data and systems to create an ongoing dialogue, so companies can make the most of every opportunity.",
    "category": "Customer Support",
    "title": "Intercom Integration With Cold Email Sales Tool",
    "h1": "Integrate Intercom with SalesBlink",
    "metaDescription": "Integrate Intercom and other Customer Support Tools with SalesBlink. Two Way Intercom Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Square",
    "slug": "square",
    "logo": "https://zapier-images.imgix.net/storage/services/d352f2084361e81f67d7c6ae3e1f52cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Square creates tools that help sellers of all sizes start, run and grow their businesses. Square’s point-of-sale service offers tools for every part of running a business, from accepting credit cards and tracking inventory to real-time analytics and invoicing.",
    "category": "eCommerce",
    "title": "Square Integration With Cold Email Sales Tool",
    "h1": "Integrate Square with SalesBlink",
    "metaDescription": "Integrate Square and other eCommerce Tools with SalesBlink. Two Way Square Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Instagram for Business",
    "slug": "instagram-for-business",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05b95c4605f4627b3c58fdd004bc6a42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instagram for Business allows you to gain insights on your followers. Instagram keeps rolling out tools to help business owners succeed on the photo-sharing social media platform.",
    "category": "Social Media Accounts",
    "title": "Instagram for Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Instagram for Business with SalesBlink",
    "metaDescription": "Integrate Instagram for Business and other Social Media Accounts Tools with SalesBlink. Two Way Instagram for Business Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "DocuSign",
    "slug": "docusign",
    "logo": "https://zapier-images.imgix.net/storage/services/8b4a1cdb476943811b60b1adaf85fb4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docusign is an electronic signature technology and digital transaction management service for facilitating electronic exchanges of signed documents.",
    "category": "Signatures",
    "title": "DocuSign Integration With Cold Email Sales Tool",
    "h1": "Integrate DocuSign with SalesBlink",
    "metaDescription": "Integrate DocuSign and other Signatures Tools with SalesBlink. Two Way DocuSign Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Wufoo",
    "slug": "wufoo",
    "logo": "https://zapier-images.imgix.net/storage/services/7df95849379828699d44e060fc412661.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wufoo is an easy way to create and manage HTML forms.",
    "category": "Forms & Surveys",
    "title": "Wufoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Wufoo with SalesBlink",
    "metaDescription": "Integrate Wufoo and other Forms & Surveys Tools with SalesBlink. Two Way Wufoo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "MailerLite",
    "slug": "mailerlite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20c1ffbb1909e6010b46b1c43f9f280b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MailerLite’s super-fast email marketing platform helps you grow your business online using innovative email designs, automations, email segmentation and intuitive no-code tools to create websites, landing pages, and signup forms.",
    "category": "Marketing Automation",
    "title": "MailerLite Integration With Cold Email Sales Tool",
    "h1": "Integrate MailerLite with SalesBlink",
    "metaDescription": "Integrate MailerLite and other Marketing Automation Tools with SalesBlink. Two Way MailerLite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "PandaDoc",
    "slug": "pandadoc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb10395b10c929d98939f011872096ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PandaDoc is an all-in-one software that streamlines your sales workflows. Create, send, track, and eSign client-facing documents designed to win more business.",
    "category": "Documents",
    "title": "PandaDoc Integration With Cold Email Sales Tool",
    "h1": "Integrate PandaDoc with SalesBlink",
    "metaDescription": "Integrate PandaDoc and other Documents Tools with SalesBlink. Two Way PandaDoc Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "GitHub",
    "slug": "github",
    "logo": "https://zapier-images.imgix.net/storage/services/c63f7c57dc0afb733535a5adccce4d01.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GitHub is the perfect place to share code and work collaboratively on private and open source software.",
    "category": "Developer Tools",
    "title": "GitHub Integration With Cold Email Sales Tool",
    "h1": "Integrate GitHub with SalesBlink",
    "metaDescription": "Integrate GitHub and other Developer Tools Tools with SalesBlink. Two Way GitHub Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ClickSend SMS",
    "slug": "clicksend",
    "logo": "https://zapier-images.imgix.net/storage/developer/dd5f4022fda4a5caed8ae27548b27566_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickSend is a cloud-based service that lets you send and receive SMS, Email, Voice, Fax and Letters worldwide.",
    "category": "Phone & SMS",
    "title": "ClickSend SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickSend SMS with SalesBlink",
    "metaDescription": "Integrate ClickSend SMS and other Phone & SMS Tools with SalesBlink. Two Way ClickSend SMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cognito Forms",
    "slug": "cognito-forms",
    "logo": "https://zapier-images.imgix.net/storage/services/8cb5117df433463e59ca368732335ea2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cognito Forms is an easy-to-use form builder that allows users to quickly build a form, include it on their website, and start capturing entries without having to do any development.",
    "category": "Forms & Surveys",
    "title": "Cognito Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Cognito Forms with SalesBlink",
    "metaDescription": "Integrate Cognito Forms and other Forms & Surveys Tools with SalesBlink. Two Way Cognito Forms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OneDrive",
    "slug": "onedrive",
    "logo": "https://zapier-images.imgix.net/storage/services/e67867e9f6f5c089e243dad54467ad33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneDrive, Microsoft's file sync app formerly known as SkyDrive, lets you store your files online, edit Office documents in the free Office Web Apps, and easily access your online files from your PC.",
    "category": "File Management & Storage",
    "title": "OneDrive Integration With Cold Email Sales Tool",
    "h1": "Integrate OneDrive with SalesBlink",
    "metaDescription": "Integrate OneDrive and other File Management & Storage Tools with SalesBlink. Two Way OneDrive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Microsoft Office 365",
    "slug": "office-365",
    "logo": "https://zapier-images.imgix.net/storage/developer/69d896f7745e4dcbc9c58403ba58d1e7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft Office 365 Business is a complete office suite in the cloud. It provides all the standard office products like Excel, Outlook, Word, SharePoint and more, but available from any internet enabled computer!\n\nZapier does not currently support Outlook.com addresses or Home 365 accounts, only business or Enterprise.",
    "category": "Email",
    "title": "Microsoft Office 365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Office 365 with SalesBlink",
    "metaDescription": "Integrate Microsoft Office 365 and other Email Tools with SalesBlink. Two Way Microsoft Office 365 Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Smartsheet",
    "slug": "smartsheet",
    "logo": "https://zapier-images.imgix.net/storage/developer/98ea2f13f32c720e38e66de6a4f3ed7e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smartsheet is a leading cloud-based platform for work execution, enabling teams and organizations to plan, capture, manage, automate, and report on work at scale, resulting in more efficient processes and better business outcomes.",
    "category": "Spreadsheets",
    "title": "Smartsheet Integration With Cold Email Sales Tool",
    "h1": "Integrate Smartsheet with SalesBlink",
    "metaDescription": "Integrate Smartsheet and other Spreadsheets Tools with SalesBlink. Two Way Smartsheet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "PayPal",
    "slug": "paypal",
    "logo": "https://zapier-images.imgix.net/storage/services/b364fde42f6ef5e115a5d92f17c01390.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PayPal is a fast, safe way to send money, make an online payment, receive money or set up a merchant account. With over 150 million active registered accounts, PayPal helps people and businesses receive and make payments in more than 100 currencies in 203 markets.",
    "category": "Payment Processing",
    "title": "PayPal Integration With Cold Email Sales Tool",
    "h1": "Integrate PayPal with SalesBlink",
    "metaDescription": "Integrate PayPal and other Payment Processing Tools with SalesBlink. Two Way PayPal Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Toggl",
    "slug": "toggl",
    "logo": "https://zapier-images.imgix.net/storage/services/fc1fea4b46a1f2e08afc9d636aab8562.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "One of the simplest ways to keep track of your time.",
    "category": "Time Tracking Software",
    "title": "Toggl Integration With Cold Email Sales Tool",
    "h1": "Integrate Toggl with SalesBlink",
    "metaDescription": "Integrate Toggl and other Time Tracking Software Tools with SalesBlink. Two Way Toggl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Strava",
    "slug": "strava",
    "logo": "https://zapier-images.imgix.net/storage/developer/0bff80269156ba892a084e0fcf8eb841.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Strava is a fitness tracking application.",
    "category": "Fitness",
    "title": "Strava Integration With Cold Email Sales Tool",
    "h1": "Integrate Strava with SalesBlink",
    "metaDescription": "Integrate Strava and other Fitness Tools with SalesBlink. Two Way Strava Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Evernote",
    "slug": "evernote",
    "logo": "https://zapier-images.imgix.net/storage/services/a0593c9367f0399018187eed64c9da46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evernote is a powerful note taking application that makes it easy to capture ideas, images, contacts, and anything else you need to remember. Bring your life's work together in one digital workspace—available on all major mobile platforms and devices.\n\nUsing Evernote for work? See Evernote Business.",
    "category": "Notes",
    "title": "Evernote Integration With Cold Email Sales Tool",
    "h1": "Integrate Evernote with SalesBlink",
    "metaDescription": "Integrate Evernote and other Notes Tools with SalesBlink. Two Way Evernote Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Close",
    "slug": "close",
    "logo": "https://zapier-images.imgix.net/storage/developer/d7849f5da486662ee9a92216a07baf64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Close is a sales productivity platform built to help you close more deals. With built-in calling, SMS, and email—your team can communicate with prospects and customers all in one place. It’s an \"all-in-one\" platform which you can use standalone without needing any other sales emails tools or calling products.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Close Integration With Cold Email Sales Tool",
    "h1": "Integrate Close with SalesBlink",
    "metaDescription": "Integrate Close and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Close Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Facebook Messenger",
    "slug": "facebook-messenger",
    "logo": "https://zapier-images.imgix.net/storage/services/b774b845456ff3f8a1dc5967e42c3ae0_12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook Messenger is a messaging app and platform. Users can send messages and exchange photos, videos, stickers, audio, and other files. This app on Zapier works for conversations that are sent to and from Facebook pages and does not yet support direct messages to a user.",
    "category": "Social Media Marketing",
    "title": "Facebook Messenger Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Messenger with SalesBlink",
    "metaDescription": "Integrate Facebook Messenger and other Social Media Marketing Tools with SalesBlink. Two Way Facebook Messenger Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Podio",
    "slug": "podio",
    "logo": "https://zapier-images.imgix.net/storage/services/bef9e7cdae86d07912e3fc86808f5d11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Podio is a collaborative work platform that's perfect for managing projects, teams, and anything else in your business that needs flexible apps that work the way you do.",
    "category": "Project Management",
    "title": "Podio Integration With Cold Email Sales Tool",
    "h1": "Integrate Podio with SalesBlink",
    "metaDescription": "Integrate Podio and other Project Management Tools with SalesBlink. Two Way Podio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OnceHub",
    "slug": "oncehub",
    "logo": "https://zapier-images.imgix.net/storage/services/72444733a3781782829e900c39d208ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Capture, qualify, and engage with inbound leads using ScheduleOnce, ChatOnce, FormOnce and SubmitOnce.",
    "category": "Scheduling & Booking",
    "title": "OnceHub Integration With Cold Email Sales Tool",
    "h1": "Integrate OnceHub with SalesBlink",
    "metaDescription": "Integrate OnceHub and other Scheduling & Booking Tools with SalesBlink. Two Way OnceHub Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SurveyMonkey",
    "slug": "surveymonkey",
    "logo": "https://zapier-images.imgix.net/storage/developer/68a4237fdc1dda16ef3c85a6a61c755b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveyMonkey is the easiest way to create surveys and get answers. SurveyMonkey lets you whip up a survey quickly and get targeted answers from the audience you want to ask.",
    "category": "Forms & Surveys",
    "title": "SurveyMonkey Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveyMonkey with SalesBlink",
    "metaDescription": "Integrate SurveyMonkey and other Forms & Surveys Tools with SalesBlink. Two Way SurveyMonkey Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Reddit",
    "slug": "reddit",
    "logo": "https://zapier-images.imgix.net/storage/developer/a1d9df1be222898ea41a84acbd00bb5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reddit, stylized as reddit, is a social news and entertainment website where registered users submit content in the form of links or text posts.",
    "category": "Social Media Accounts",
    "title": "Reddit Integration With Cold Email Sales Tool",
    "h1": "Integrate Reddit with SalesBlink",
    "metaDescription": "Integrate Reddit and other Social Media Accounts Tools with SalesBlink. Two Way Reddit Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "TickTick",
    "slug": "ticktick",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f046549a790a796a7ea76fa5165e322.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TickTick is a simple and effective to-do list and task manager app with seamless cloud synchronization across all your devices.",
    "category": "Task Management",
    "title": "TickTick Integration With Cold Email Sales Tool",
    "h1": "Integrate TickTick with SalesBlink",
    "metaDescription": "Integrate TickTick and other Task Management Tools with SalesBlink. Two Way TickTick Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "TikTok Lead Generation",
    "slug": "tiktok-lead-generation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b05015a22b1b3431ca673a6a8f6cc6a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TikTok's Lead Generation enables businesses of all sizes to create seamless interactions to reach prospects in order to convert them into potential customers.",
    "category": "Ads & Conversion",
    "title": "TikTok Lead Generation Integration With Cold Email Sales Tool",
    "h1": "Integrate TikTok Lead Generation with SalesBlink",
    "metaDescription": "Integrate TikTok Lead Generation and other Ads & Conversion Tools with SalesBlink. Two Way TikTok Lead Generation Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "AWeber",
    "slug": "aweber",
    "logo": "https://zapier-images.imgix.net/storage/services/b4ad75b0b5ceb6e9ac768e7809ea4001.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AWeber provides professional email marketing software and services. AWeber's easy signup forms and autoresponders make it easy for you to stay in touch with your customers.",
    "category": "Email Newsletters",
    "title": "AWeber Integration With Cold Email Sales Tool",
    "h1": "Integrate AWeber with SalesBlink",
    "metaDescription": "Integrate AWeber and other Email Newsletters Tools with SalesBlink. Two Way AWeber Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ChatGPT",
    "slug": "chatgpt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a8f13d0fe5c9b2bbe7a7191a433ac1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatGPT is an AI tool built by OpenAI that enables conversational automation.\n\nA more powerful OpenAI integration is available here: https://zapier.com/apps/openai/integrations",
    "category": "AI Tools",
    "title": "ChatGPT Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatGPT with SalesBlink",
    "metaDescription": "Integrate ChatGPT and other AI Tools Tools with SalesBlink. Two Way ChatGPT Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Keap",
    "slug": "keap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b64306dd8249dc80c127c578091afc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keap is your all-in-one CRM, sales and marketing automation software that helps organize leads, land the job, get paid, and keep growing.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Keap Integration With Cold Email Sales Tool",
    "h1": "Integrate Keap with SalesBlink",
    "metaDescription": "Integrate Keap and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Keap Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MySQL",
    "slug": "mysql",
    "logo": "https://zapier-images.imgix.net/storage/services/c23b6298d70e5cc925a8e795e48b6898.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MySQL is a premier database used in millions of production environments around the world. Easily plug your MySQL database into any web service.",
    "category": "Databases",
    "title": "MySQL Integration With Cold Email Sales Tool",
    "h1": "Integrate MySQL with SalesBlink",
    "metaDescription": "Integrate MySQL and other Databases Tools with SalesBlink. Two Way MySQL Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Chat",
    "slug": "google-chat",
    "logo": "https://zapier-images.imgix.net/storage/services/0514c369aee0a25e322a9301b4ef8102.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Chat is a communication software developed by Google built for teams that provides direct messages and team chat rooms, along with a group messaging function that allows Google Drive content sharing.",
    "category": "Google",
    "title": "Google Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Chat with SalesBlink",
    "metaDescription": "Integrate Google Chat and other Google Tools with SalesBlink. Two Way Google Chat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pocket",
    "slug": "pocket",
    "logo": "https://zapier-images.imgix.net/storage/services/2405c839aa8598c56dc295500d7c2822.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pocket is the best way to save articles, videos and links to view on any device, any time.",
    "category": "Bookmark Managers",
    "title": "Pocket Integration With Cold Email Sales Tool",
    "h1": "Integrate Pocket with SalesBlink",
    "metaDescription": "Integrate Pocket and other Bookmark Managers Tools with SalesBlink. Two Way Pocket Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Google Analytics",
    "slug": "google-analytics",
    "logo": "https://zapier-images.imgix.net/storage/services/8e760f76ff742d22fb037a3ed49e009d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Analytics is an industry standard freemium web and mobile analytics package.",
    "category": "Ads & Conversion",
    "title": "Google Analytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Analytics with SalesBlink",
    "metaDescription": "Integrate Google Analytics and other Ads & Conversion Tools with SalesBlink. Two Way Google Analytics Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "kvCORE",
    "slug": "kvcore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5cbac3be3bab521f58d67ee50ab394c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KvCORE is the next gen solution for modern brokerages, agents, and teams to run their entire business on one platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "kvCORE Integration With Cold Email Sales Tool",
    "h1": "Integrate kvCORE with SalesBlink",
    "metaDescription": "Integrate kvCORE and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way kvCORE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ninja Forms",
    "slug": "ninjaforms",
    "logo": "https://zapier-images.imgix.net/storage/developer/fb253200ee5941935e26908500c465e6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ninja Forms is the easiest and most flexible WordPress Form builder.",
    "category": "Forms & Surveys",
    "title": "Ninja Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Ninja Forms with SalesBlink",
    "metaDescription": "Integrate Ninja Forms and other Forms & Surveys Tools with SalesBlink. Two Way Ninja Forms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Freshdesk",
    "slug": "freshdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/628f919761dc861bb72cc622b7324d05.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshdesk is an online helpdesk software with multiple channel customer service to support customers across email, phone, chat, web, Twitter and more.",
    "category": "Customer Support",
    "title": "Freshdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshdesk with SalesBlink",
    "metaDescription": "Integrate Freshdesk and other Customer Support Tools with SalesBlink. Two Way Freshdesk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Mighty Networks",
    "slug": "mightynetworks",
    "logo": "https://zapier-images.imgix.net/storage/services/2acb01a8452b7ad9d7301f0d6a0ce4d8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mighty Networks is a platform that offers anyone the opportunity to create their own community and start getting paid to support their work. Your members can message, meet, and go deeper around the topics and interests they care about most.",
    "category": "Website Builders",
    "title": "Mighty Networks Integration With Cold Email Sales Tool",
    "h1": "Integrate Mighty Networks with SalesBlink",
    "metaDescription": "Integrate Mighty Networks and other Website Builders Tools with SalesBlink. Two Way Mighty Networks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Basecamp 3",
    "slug": "basecamp3",
    "logo": "https://zapier-images.imgix.net/storage/services/666ce73ae4b8fb7730e6e096cd46c8ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Basecamp’s unique blend of tools is everything any team needs to stay on the same page about whatever they’re working on.",
    "category": "Project Management",
    "title": "Basecamp 3 Integration With Cold Email Sales Tool",
    "h1": "Integrate Basecamp 3 with SalesBlink",
    "metaDescription": "Integrate Basecamp 3 and other Project Management Tools with SalesBlink. Two Way Basecamp 3 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Netlify",
    "slug": "netlify",
    "logo": "https://zapier-images.imgix.net/storage/services/1759ac684e0d5dfd06585a8bfd323336.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Netlify is an all-in-one workflow to build, deploy, and manage modern web projects.",
    "category": "Website Builders",
    "title": "Netlify Integration With Cold Email Sales Tool",
    "h1": "Integrate Netlify with SalesBlink",
    "metaDescription": "Integrate Netlify and other Website Builders Tools with SalesBlink. Two Way Netlify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Leadpages",
    "slug": "leadpages",
    "logo": "https://zapier-images.imgix.net/storage/services/ea426392b7fbcdb81cb1a958eeb5528b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadpages lets you build beautiful, high-converting landing pages, sign-up forms, and other conversion assets.",
    "category": "Forms & Surveys",
    "title": "Leadpages Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadpages with SalesBlink",
    "metaDescription": "Integrate Leadpages and other Forms & Surveys Tools with SalesBlink. Two Way Leadpages Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Manychat",
    "slug": "manychat",
    "logo": "https://zapier-images.imgix.net/storage/services/f767ab34d75595fb9579616f845b429c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manychat is an all-in-one Messenger Marketing platform. Create your Facebook Messenger bot for marketing, sales and support with our simple drag'n'drop builder. Effectively promote with built-in tools for growing audience. Start today – it's easy and free.",
    "category": "Social Media Marketing",
    "title": "Manychat Integration With Cold Email Sales Tool",
    "h1": "Integrate Manychat with SalesBlink",
    "metaDescription": "Integrate Manychat and other Social Media Marketing Tools with SalesBlink. Two Way Manychat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Slides",
    "slug": "google-slides",
    "logo": "https://zapier-images.imgix.net/storage/developer/d7cd7cd9ffcf2dab9f0333f7d7f61e45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Slides allows you to create, edit, and share presentations with anyone, online, for free.",
    "category": "Documents",
    "title": "Google Slides Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Slides with SalesBlink",
    "metaDescription": "Integrate Google Slides and other Documents Tools with SalesBlink. Two Way Google Slides Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Chatwork",
    "slug": "chatwork",
    "logo": "https://zapier-images.imgix.net/storage/developer/1fa9126574218ee878a2d694b4076026.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keep your team focused and working together with Chatwork's team chat, tasks, video chats, and more in one place.",
    "category": "Team Chat",
    "title": "Chatwork Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatwork with SalesBlink",
    "metaDescription": "Integrate Chatwork and other Team Chat Tools with SalesBlink. Two Way Chatwork Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CallRail",
    "slug": "callrail",
    "logo": "https://zapier-images.imgix.net/storage/services/f9def5f20c0b6efc36e7f593ceb7572c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallRail is an analytics platform designed for those who rely on quality calls and inbound leads to measure success.",
    "category": "Call Tracking",
    "title": "CallRail Integration With Cold Email Sales Tool",
    "h1": "Integrate CallRail with SalesBlink",
    "metaDescription": "Integrate CallRail and other Call Tracking Tools with SalesBlink. Two Way CallRail Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ShipStation",
    "slug": "shipstation",
    "logo": "https://zapier-images.imgix.net/storage/developer/9e81a64085ba208588750f9568906eb7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShipStation helps eCommerce retailers organize, process, and fulfill their orders from all the most popular marketplaces and shopping carts using the top shipping carriers.",
    "category": "eCommerce",
    "title": "ShipStation Integration With Cold Email Sales Tool",
    "h1": "Integrate ShipStation with SalesBlink",
    "metaDescription": "Integrate ShipStation and other eCommerce Tools with SalesBlink. Two Way ShipStation Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Kartra",
    "slug": "kartra",
    "logo": "https://zapier-images.imgix.net/storage/developer/170bd7561fa366b32703a9b3e146faac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kartra is an all-in-one solution to manage your online business: checkout, automated email campaigns, site builder, membership portals, etc.",
    "category": "Marketing Automation",
    "title": "Kartra Integration With Cold Email Sales Tool",
    "h1": "Integrate Kartra with SalesBlink",
    "metaDescription": "Integrate Kartra and other Marketing Automation Tools with SalesBlink. Two Way Kartra Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Tally",
    "slug": "tally",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b71a501fa573a4abdf73b3dfe5427756.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The simplest way to create beautiful forms & surveys, for free.",
    "category": "Forms & Surveys",
    "title": "Tally Integration With Cold Email Sales Tool",
    "h1": "Integrate Tally with SalesBlink",
    "metaDescription": "Integrate Tally and other Forms & Surveys Tools with SalesBlink. Two Way Tally Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Facebook Offline Conversions",
    "slug": "facebook-offline-conversions",
    "logo": "https://zapier-images.imgix.net/storage/developer/f407c31b217aac6e0cd4171092d53a8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With offline conversion measurement on Facebook, you can track when events occur in your physical retail store and other offline channels (ex: email marketing and CRM apps) after people see or engage with your Facebook ad.\n\n.",
    "category": "Ads & Conversion",
    "title": "Facebook Offline Conversions Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Offline Conversions with SalesBlink",
    "metaDescription": "Integrate Facebook Offline Conversions and other Ads & Conversion Tools with SalesBlink. Two Way Facebook Offline Conversions Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "GetResponse",
    "slug": "getresponse",
    "logo": "https://zapier-images.imgix.net/storage/services/54c51903959aae70ce340d9ec6f1f9a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With an all-in-one suite of online marketing tools, GetResponse is the perfect solution to help you grow your business.",
    "category": "Marketing Automation",
    "title": "GetResponse Integration With Cold Email Sales Tool",
    "h1": "Integrate GetResponse with SalesBlink",
    "metaDescription": "Integrate GetResponse and other Marketing Automation Tools with SalesBlink. Two Way GetResponse Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Circle",
    "slug": "circle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c785a61a4498829c28a5c88eada65fcc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A modern community platform for creators.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Circle Integration With Cold Email Sales Tool",
    "h1": "Integrate Circle with SalesBlink",
    "metaDescription": "Integrate Circle and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Circle Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Trainerize",
    "slug": "trainerize",
    "logo": "https://zapier-images.imgix.net/storage/developer/8786135950f06b04da71344b87fd95a1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trainerize is a powerful personal training software to grow your business and reach more clients with online personal training, meal planning, in-app communication and workout tracking.",
    "category": "Fitness",
    "title": "Trainerize Integration With Cold Email Sales Tool",
    "h1": "Integrate Trainerize with SalesBlink",
    "metaDescription": "Integrate Trainerize and other Fitness Tools with SalesBlink. Two Way Trainerize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HoneyBook",
    "slug": "honeybook",
    "logo": "https://zapier-images.imgix.net/storage/services/0a6a3a34423163a3221ce29f33378a27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HoneyBook is the leading client-flow platform independent businesses use to showcase services, book clients, manage projects, and get paid.",
    "category": "CRM (Customer Relationship Management)",
    "title": "HoneyBook Integration With Cold Email Sales Tool",
    "h1": "Integrate HoneyBook with SalesBlink",
    "metaDescription": "Integrate HoneyBook and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way HoneyBook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RingCentral",
    "slug": "ringcentral",
    "logo": "https://zapier-images.imgix.net/storage/services/1c800f273ffd5164fc4032e8698153c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RingCentral provides cloud-based communication system to modern businesses of all sizes. The system enables companies with voice, SMS, video, Fax, and team collaboration services on all major desktop and mobile platforms. All devices, services, and users can be managed through our PBX and administrative capability designed for Enterprise IT.",
    "category": "Phone & SMS",
    "title": "RingCentral Integration With Cold Email Sales Tool",
    "h1": "Integrate RingCentral with SalesBlink",
    "metaDescription": "Integrate RingCentral and other Phone & SMS Tools with SalesBlink. Two Way RingCentral Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ghost",
    "slug": "ghost",
    "logo": "https://zapier-images.imgix.net/storage/services/1ed972661456417064a99e414fbcf0c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A professional creator platform for publishing content, sending newsletters, and running a subscription business.",
    "category": "Website Builders",
    "title": "Ghost Integration With Cold Email Sales Tool",
    "h1": "Integrate Ghost with SalesBlink",
    "metaDescription": "Integrate Ghost and other Website Builders Tools with SalesBlink. Two Way Ghost Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "RD Station",
    "slug": "rd-station",
    "logo": "https://zapier-images.imgix.net/storage/developer/296615bf728093324a3b8b714173cb37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RD Station is a complete digital marketing platform to help you generate more results in traffic generation, leads, sales for your business, and to build a solid digital marketing presence.\n\nThis app is available only in Portuguese.",
    "category": "Marketing Automation",
    "title": "RD Station Integration With Cold Email Sales Tool",
    "h1": "Integrate RD Station with SalesBlink",
    "metaDescription": "Integrate RD Station and other Marketing Automation Tools with SalesBlink. Two Way RD Station Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ecwid by Lightspeed",
    "slug": "ecwid",
    "logo": "https://zapier-images.imgix.net/storage/services/5cd3e303d4064cb3c62f3c2af5866a98.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ecwid by Lightspeed is a cloud online store builder which lets you instantly launch an online store on any website, Facebook page, or multiple sites simultaneously.",
    "category": "eCommerce",
    "title": "Ecwid by Lightspeed Integration With Cold Email Sales Tool",
    "h1": "Integrate Ecwid by Lightspeed with SalesBlink",
    "metaDescription": "Integrate Ecwid by Lightspeed and other eCommerce Tools with SalesBlink. Two Way Ecwid by Lightspeed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Facebook Custom Audiences",
    "slug": "facebook-custom-audiences",
    "logo": "https://zapier-images.imgix.net/storage/services/f407c31b217aac6e0cd4171092d53a8c_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook Custom Audiences makes signing up for business information easy for people and more valuable for businesses.",
    "category": "Ads & Conversion",
    "title": "Facebook Custom Audiences Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Custom Audiences with SalesBlink",
    "metaDescription": "Integrate Facebook Custom Audiences and other Ads & Conversion Tools with SalesBlink. Two Way Facebook Custom Audiences Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pipefy",
    "slug": "pipefy",
    "logo": "https://zapier-images.imgix.net/storage/services/ba42dc889253d04da501a4fa4ea66133.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The easy button for processes and workflows\nEasily organize and run all your processes in one place, leaving the inefficient patchwork of apps, forms, spreadsheets and e-mail threads forever in the past",
    "category": "Project Management",
    "title": "Pipefy Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipefy with SalesBlink",
    "metaDescription": "Integrate Pipefy and other Project Management Tools with SalesBlink. Two Way Pipefy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Mailparser",
    "slug": "mailparser",
    "logo": "https://zapier-images.imgix.net/storage/developer/41c4b56d695fedc68d91755c7f166c7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send emails to Mailparser, get structured data in return. Leads, Contact Forms, eCommerce, Shipping Notes, Attachments, Customer Info & more.",
    "category": "Email",
    "title": "Mailparser Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailparser with SalesBlink",
    "metaDescription": "Integrate Mailparser and other Email Tools with SalesBlink. Two Way Mailparser Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Facebook Groups",
    "slug": "facebook-groups",
    "logo": "https://zapier-images.imgix.net/storage/services/f407c31b217aac6e0cd4171092d53a8c_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Groups provide a space to communicate about shared interests with certain people. You can create a group for anything — your family reunion, your after-work sports team, your book club — and customize the group's privacy settings depending on who you want to be able to join and see the group.",
    "category": "Social Media Accounts",
    "title": "Facebook Groups Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Groups with SalesBlink",
    "metaDescription": "Integrate Facebook Groups and other Social Media Accounts Tools with SalesBlink. Two Way Facebook Groups Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Paperform",
    "slug": "paperform",
    "logo": "https://zapier-images.imgix.net/storage/services/9d8fbcdab89ea723d94772ff0170a99a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A digital Swiss Army Knife: take payments, create forms, manage bookings and put manual processes on autopilot. Where imagination takes form.",
    "category": "Forms & Surveys",
    "title": "Paperform Integration With Cold Email Sales Tool",
    "h1": "Integrate Paperform with SalesBlink",
    "metaDescription": "Integrate Paperform and other Forms & Surveys Tools with SalesBlink. Two Way Paperform Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Campaign Monitor",
    "slug": "campaign-monitor",
    "logo": "https://zapier-images.imgix.net/storage/developer/2808fc5749049848d5980cfec02066a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Campaign Monitor is an email marketing tool built for designers. Campaign Monitor makes it easy to send beautiful emails, manage lists and subscribers, and track the results of your campaigns.",
    "category": "Email Newsletters",
    "title": "Campaign Monitor Integration With Cold Email Sales Tool",
    "h1": "Integrate Campaign Monitor with SalesBlink",
    "metaDescription": "Integrate Campaign Monitor and other Email Newsletters Tools with SalesBlink. Two Way Campaign Monitor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Streak",
    "slug": "streak",
    "logo": "https://zapier-images.imgix.net/storage/developer/b5b4f510c6b0e0b975fb1214caae6476.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Streak is a flexible CRM and process management system that lives inside your Gmail inbox.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Streak Integration With Cold Email Sales Tool",
    "h1": "Integrate Streak with SalesBlink",
    "metaDescription": "Integrate Streak and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Streak Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Things",
    "slug": "things",
    "logo": "https://zapier-images.imgix.net/storage/developer/f68266f33699007caf5d940307c2b31b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Things is an award-winning personal task manager for Mac and iOS that helps you achieve your goals.",
    "category": "Task Management",
    "title": "Things Integration With Cold Email Sales Tool",
    "h1": "Integrate Things with SalesBlink",
    "metaDescription": "Integrate Things and other Task Management Tools with SalesBlink. Two Way Things Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendGrid",
    "slug": "sendgrid",
    "logo": "https://zapier-images.imgix.net/storage/developer/a37a5b5ab495b5531dd406d40baa8a5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendGrid's cloud-based email infrastructure relieves businesses of the cost and complexity of maintaining custom email systems",
    "category": "Transactional Email",
    "title": "SendGrid Integration With Cold Email Sales Tool",
    "h1": "Integrate SendGrid with SalesBlink",
    "metaDescription": "Integrate SendGrid and other Transactional Email Tools with SalesBlink. Two Way SendGrid Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Formstack",
    "slug": "formstack",
    "logo": "https://zapier-images.imgix.net/storage/services/b97efaa88e53498fd91b67a09cfdfcca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formstack is one of the easiest ways to build custom forms that let you collect data your way.",
    "category": "Forms & Surveys",
    "title": "Formstack Integration With Cold Email Sales Tool",
    "h1": "Integrate Formstack with SalesBlink",
    "metaDescription": "Integrate Formstack and other Forms & Surveys Tools with SalesBlink. Two Way Formstack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OpenPhone",
    "slug": "openphone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee05750d4e5ef328974d872b27746cff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OpenPhone is a new phone for business. It comes with powerful calling, messaging, and a lightweight CRM.",
    "category": "Phone & SMS",
    "title": "OpenPhone Integration With Cold Email Sales Tool",
    "h1": "Integrate OpenPhone with SalesBlink",
    "metaDescription": "Integrate OpenPhone and other Phone & SMS Tools with SalesBlink. Two Way OpenPhone Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ontraport",
    "slug": "ontraport",
    "logo": "https://zapier-images.imgix.net/storage/developer/71fca392ac1180272ad6298f7dc08dbf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An all-in-one business and marketing platform designed for information marketers, experts, and coaches.",
    "category": "Marketing Automation",
    "title": "Ontraport Integration With Cold Email Sales Tool",
    "h1": "Integrate Ontraport with SalesBlink",
    "metaDescription": "Integrate Ontraport and other Marketing Automation Tools with SalesBlink. Two Way Ontraport Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pushover",
    "slug": "pushover",
    "logo": "https://zapier-images.imgix.net/storage/developer/6486b202bcb0bccbbe7e978256b1112b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pushover is a simple push notification service to instantly send alerts to Android and iOS devices.",
    "category": "Notifications",
    "title": "Pushover Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushover with SalesBlink",
    "metaDescription": "Integrate Pushover and other Notifications Tools with SalesBlink. Two Way Pushover Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Formidable Forms",
    "slug": "formidable",
    "logo": "https://zapier-images.imgix.net/storage/developer/51ae0de9afbda5c800432bda0c4003d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formidable Forms is a WordPress plugin that helps you create beautiful drag and drop forms with custom styling. You and your visitors can submit and edit entries and posts from the front-end of your site. When you have data, display it with graphs and custom views.",
    "category": "Forms & Surveys",
    "title": "Formidable Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Formidable Forms with SalesBlink",
    "metaDescription": "Integrate Formidable Forms and other Forms & Surveys Tools with SalesBlink. Two Way Formidable Forms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Spotify",
    "slug": "spotify",
    "logo": "https://zapier-images.imgix.net/storage/developer/7a34cf4279c6f8f8c97a1250ccce40a4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spotify is a music streaming service that provides digital content from record labels and media companies.",
    "category": "Video & Audio",
    "title": "Spotify Integration With Cold Email Sales Tool",
    "h1": "Integrate Spotify with SalesBlink",
    "metaDescription": "Integrate Spotify and other Video & Audio Tools with SalesBlink. Two Way Spotify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Forms",
    "slug": "zoho-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer/880374b6ca9e1bd0f311eb37a81812c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Forms is a free online form builder that lets you create custom forms, receive responses by email, and collaborate with your team.",
    "category": "Forms & Surveys",
    "title": "Zoho Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Forms with SalesBlink",
    "metaDescription": "Integrate Zoho Forms and other Forms & Surveys Tools with SalesBlink. Two Way Zoho Forms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Twitch",
    "slug": "twitch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e84b3a120a3a4b6e58f577a60cf763f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twitch is a live streaming video platform.",
    "category": "Gaming",
    "title": "Twitch Integration With Cold Email Sales Tool",
    "h1": "Integrate Twitch with SalesBlink",
    "metaDescription": "Integrate Twitch and other Gaming Tools with SalesBlink. Two Way Twitch Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "WebinarJam / EverWebinar",
    "slug": "webinarjam",
    "logo": "https://zapier-images.imgix.net/storage/services/4c49548efe2181d5f9c0d8fe38bc622d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WebinarJam is a webinar and live broadcasting program allowing for live and prerecorded events.",
    "category": "Webinars",
    "title": "WebinarJam / EverWebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate WebinarJam / EverWebinar with SalesBlink",
    "metaDescription": "Integrate WebinarJam / EverWebinar and other Webinars Tools with SalesBlink. Two Way WebinarJam / EverWebinar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Systeme.io",
    "slug": "systeme",
    "logo": "https://zapier-images.imgix.net/storage/services/54d4a386f1dcecaeb4c07ada1cc03824.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Systeme.io allows you to build sales funnels, send emails and automate your entire business.",
    "category": "Marketing Automation",
    "title": "Systeme.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Systeme.io with SalesBlink",
    "metaDescription": "Integrate Systeme.io and other Marketing Automation Tools with SalesBlink. Two Way Systeme.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clockify",
    "slug": "clockify",
    "logo": "https://zapier-images.imgix.net/storage/services/d4f339dd8a7875796e95df97520f8469.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clockify is a completely free time tracking software for teams. It's a simple time tracker and timesheet app for tracking work hours on projects.",
    "category": "Time Tracking Software",
    "title": "Clockify Integration With Cold Email Sales Tool",
    "h1": "Integrate Clockify with SalesBlink",
    "metaDescription": "Integrate Clockify and other Time Tracking Software Tools with SalesBlink. Two Way Clockify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Perspective",
    "slug": "perspective",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b279b17718378752d1bfc1403cc33929_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Perspective is a mobile funnel builder which lets you create beautiful mobile experiences.",
    "category": "Marketing Automation",
    "title": "Perspective Integration With Cold Email Sales Tool",
    "h1": "Integrate Perspective with SalesBlink",
    "metaDescription": "Integrate Perspective and other Marketing Automation Tools with SalesBlink. Two Way Perspective Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Gumroad",
    "slug": "gumroad",
    "logo": "https://zapier-images.imgix.net/storage/services/4349282b14304522392e86f2237886a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gumroad allows all types of creators to sell their work (music, art, books, films, etc) directly to their audiences.",
    "category": "eCommerce",
    "title": "Gumroad Integration With Cold Email Sales Tool",
    "h1": "Integrate Gumroad with SalesBlink",
    "metaDescription": "Integrate Gumroad and other eCommerce Tools with SalesBlink. Two Way Gumroad Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Buffer",
    "slug": "buffer",
    "logo": "https://zapier-images.imgix.net/storage/developer/87153c7bef8efb74301fd2f5e0b679d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your social media marketing together with Buffer's tools to schedule posts, analyze performance, and publish to a half-dozen social networks from one app.",
    "category": "Social Media Marketing",
    "title": "Buffer Integration With Cold Email Sales Tool",
    "h1": "Integrate Buffer with SalesBlink",
    "metaDescription": "Integrate Buffer and other Social Media Marketing Tools with SalesBlink. Two Way Buffer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "LearnDash",
    "slug": "learndash",
    "logo": "https://zapier-images.imgix.net/storage/services/cb0c0814c88304ee717aecf4cd5d33cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LearnDash is a learning management system plugin built for WordPress.",
    "category": "Online Courses",
    "title": "LearnDash Integration With Cold Email Sales Tool",
    "h1": "Integrate LearnDash with SalesBlink",
    "metaDescription": "Integrate LearnDash and other Online Courses Tools with SalesBlink. Two Way LearnDash Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Firebase / Firestore",
    "slug": "firebase",
    "logo": "https://zapier-images.imgix.net/storage/services/44b31b544b9ca3c736788200b4fd120d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Firebase Realtime Database and Google Cloud Firestore are flexible databases that give you the ability to easily store and sync app data at global scale.",
    "category": "Databases",
    "title": "Firebase / Firestore Integration With Cold Email Sales Tool",
    "h1": "Integrate Firebase / Firestore with SalesBlink",
    "metaDescription": "Integrate Firebase / Firestore and other Databases Tools with SalesBlink. Two Way Firebase / Firestore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drip",
    "slug": "drip",
    "logo": "https://zapier-images.imgix.net/storage/services/47997379884e6b35789d6c097ce75e5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your ecommerce marketing at scale. Drip is your ecommerce marketing engine. Build personalized customer experiences, drive more revenue, and take your marketing strategy to the next level with Drip.",
    "category": "Drip Emails",
    "title": "Drip Integration With Cold Email Sales Tool",
    "h1": "Integrate Drip with SalesBlink",
    "metaDescription": "Integrate Drip and other Drip Emails Tools with SalesBlink. Two Way Drip Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Podia",
    "slug": "podia",
    "logo": "https://zapier-images.imgix.net/storage/services/ce1ca92ef014b0db88389c0da99e8184.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Podia lets anyone sell memberships, online courses and digital downloads online.",
    "category": "Online Courses",
    "title": "Podia Integration With Cold Email Sales Tool",
    "h1": "Integrate Podia with SalesBlink",
    "metaDescription": "Integrate Podia and other Online Courses Tools with SalesBlink. Two Way Podia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tumblr",
    "slug": "tumblr",
    "logo": "https://zapier-images.imgix.net/storage/developer/11aef07e5cc45209f3e8ea44d1ca5a6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tumblr is a microblogging platform and social networking website which allows users to post multimedia and other content to a short-form blog.",
    "category": "Website Builders",
    "title": "Tumblr Integration With Cold Email Sales Tool",
    "h1": "Integrate Tumblr with SalesBlink",
    "metaDescription": "Integrate Tumblr and other Website Builders Tools with SalesBlink. Two Way Tumblr Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Interact",
    "slug": "interact",
    "logo": "https://zapier-images.imgix.net/storage/developer/c994d53028b1038608bbb39d50d36ce0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create quizzes to generate leads with Interact. Send those leads directly into your marketing automation or CRM platform.",
    "category": "Forms & Surveys",
    "title": "Interact Integration With Cold Email Sales Tool",
    "h1": "Integrate Interact with SalesBlink",
    "metaDescription": "Integrate Interact and other Forms & Surveys Tools with SalesBlink. Two Way Interact Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Mail",
    "slug": "zoho-mail",
    "logo": "https://zapier-images.imgix.net/storage/services/f3c8b68e73fb95435ac1031e34da7178.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Mail is a collaborative business communication platform for the modern workforce. It is a blend of classic email and the modern collaboration tools using comments, likes and sharing.",
    "category": "Email",
    "title": "Zoho Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Mail with SalesBlink",
    "metaDescription": "Integrate Zoho Mail and other Email Tools with SalesBlink. Two Way Zoho Mail Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "PostgreSQL",
    "slug": "postgresql",
    "logo": "https://zapier-images.imgix.net/storage/services/3b77c88b983c09b5612c31311e34ed23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PostgreSQL is a powerful, open-source database engine with a uniquely advanced query optimizer and dozens of built in features, making it an easy choice for production databases.",
    "category": "Databases",
    "title": "PostgreSQL Integration With Cold Email Sales Tool",
    "h1": "Integrate PostgreSQL with SalesBlink",
    "metaDescription": "Integrate PostgreSQL and other Databases Tools with SalesBlink. Two Way PostgreSQL Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Bark",
    "slug": "bark",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eefc733cd2dd7904704207bf09d6a64a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bark is a world-leading services marketplace with over 5m customers in 6 countries across the globe.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bark Integration With Cold Email Sales Tool",
    "h1": "Integrate Bark with SalesBlink",
    "metaDescription": "Integrate Bark and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailjet",
    "slug": "mailjet",
    "logo": "https://zapier-images.imgix.net/storage/developer/0effc4cf3d9994a7c6e053982f17d8cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailjet is an all-in-one solution to send, track and deliver transactional, notification and marketing emails.  Engage, analyze and react with your client base through email. Get started by using the Mailjet zap to synchronise contacts from your favorite eCommerce, CRM or other SaaS  tools to your Mailjet contact lists. For users on API version 3 or higher.",
    "category": "Email Newsletters",
    "title": "Mailjet Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailjet with SalesBlink",
    "metaDescription": "Integrate Mailjet and other Email Newsletters Tools with SalesBlink. Two Way Mailjet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Chargebee",
    "slug": "chargebee",
    "logo": "https://zapier-images.imgix.net/storage/services/1653db468d1d4f2ddc2881aae1c0038a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chargebee is a subscription management and recurring billing solution used by businesses to customize subscription billing workflows.",
    "category": "Payment Processing",
    "title": "Chargebee Integration With Cold Email Sales Tool",
    "h1": "Integrate Chargebee with SalesBlink",
    "metaDescription": "Integrate Chargebee and other Payment Processing Tools with SalesBlink. Two Way Chargebee Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SimpleTexting",
    "slug": "simpletexting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9aa21d4a46f5d1563065a21982a2672_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleTexting is a text messaging platform that helps you reach your customers using one of the most effective channels: their text inbox. Send promotions, alerts, polls or reminders to your entire list in a matter of seconds.",
    "category": "Phone & SMS",
    "title": "SimpleTexting Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleTexting with SalesBlink",
    "metaDescription": "Integrate SimpleTexting and other Phone & SMS Tools with SalesBlink. Two Way SimpleTexting Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SQL Server",
    "slug": "sql-server",
    "logo": "https://zapier-images.imgix.net/storage/services/0401f13c0793fb7fefd20e84b93f8d96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SQL server (or MS SQL) is a top of the line database geared for the cloud",
    "category": "Databases",
    "title": "SQL Server Integration With Cold Email Sales Tool",
    "h1": "Integrate SQL Server with SalesBlink",
    "metaDescription": "Integrate SQL Server and other Databases Tools with SalesBlink. Two Way SQL Server Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneNote",
    "slug": "onenote",
    "logo": "https://zapier-images.imgix.net/storage/developer/e951e0105790e43b117e04b7bba34601.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft OneNote is a note-taking app that makes it easy to capture and save ideas, images, audio recordings, and anything else you need to remember.",
    "category": "Microsoft",
    "title": "OneNote Integration With Cold Email Sales Tool",
    "h1": "Integrate OneNote with SalesBlink",
    "metaDescription": "Integrate OneNote and other Microsoft Tools with SalesBlink. Two Way OneNote Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Adalo",
    "slug": "adalo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6f5e2ac20d776faab2b0d1ca68ce4db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adalo is a platform where anyone can create apps without code.",
    "category": "App Builder",
    "title": "Adalo Integration With Cold Email Sales Tool",
    "h1": "Integrate Adalo with SalesBlink",
    "metaDescription": "Integrate Adalo and other App Builder Tools with SalesBlink. Two Way Adalo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Copper",
    "slug": "copper",
    "logo": "https://zapier-images.imgix.net/storage/developer/ee5639541837566725dfb217a7e027af_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Copper is a new kind of productivity CRM that's designed to do all your busywork, so you can focus on building long-lasting business relationships",
    "category": "CRM (Customer Relationship Management)",
    "title": "Copper Integration With Cold Email Sales Tool",
    "h1": "Integrate Copper with SalesBlink",
    "metaDescription": "Integrate Copper and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Copper Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "TextMagic SMS",
    "slug": "textmagic",
    "logo": "https://zapier-images.imgix.net/storage/developer/6e9c42460b33a44311c3ec7336a47ffd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextMagic is a business text-messaging service for sending notifications, alerts, reminders, confirmations and SMS marketing campaigns.",
    "category": "Phone & SMS",
    "title": "TextMagic SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate TextMagic SMS with SalesBlink",
    "metaDescription": "Integrate TextMagic SMS and other Phone & SMS Tools with SalesBlink. Two Way TextMagic SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Feedly",
    "slug": "feedly",
    "logo": "https://zapier-images.imgix.net/storage/developer/d053dfcc6326a696800019e836969ac2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The best way to follow your favorite blogs, news sites, YouTube shows, podcasts, tumblr blogs, magazines, eBay listings, Hulu shows and more. feedly delivers all your favorite news in one place—and it is blazing fast.",
    "category": "Social Media Accounts",
    "title": "Feedly Integration With Cold Email Sales Tool",
    "h1": "Integrate Feedly with SalesBlink",
    "metaDescription": "Integrate Feedly and other Social Media Accounts Tools with SalesBlink. Two Way Feedly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pinterest",
    "slug": "pinterest",
    "logo": "https://zapier-images.imgix.net/storage/developer/e15bb249be46b70e5cd011a8731e1e93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pinterest is the world’s catalog of ideas that more than 100 million people around the world use every month to get more creative in their everyday lives. Save images and videos (\"Pins\") to curated collections (\"Boards\") from any website, or upload your own. Build your online presence and grow your audience by sharing your visual catalog, and track what your audience thinks through their Likes and repins.",
    "category": "Social Media Accounts",
    "title": "Pinterest Integration With Cold Email Sales Tool",
    "h1": "Integrate Pinterest with SalesBlink",
    "metaDescription": "Integrate Pinterest and other Social Media Accounts Tools with SalesBlink. Two Way Pinterest Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clio",
    "slug": "clio",
    "logo": "https://zapier-images.imgix.net/storage/services/4272c5e2ab94f0fe57fc3623f2940ad8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clio is a practice management platform that helps lawyers work more efficiently by making it easy to do tasks like tracking time, managing matters, and generating invoices.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clio Integration With Cold Email Sales Tool",
    "h1": "Integrate Clio with SalesBlink",
    "metaDescription": "Integrate Clio and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clio Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mailgun",
    "slug": "mailgun",
    "logo": "https://zapier-images.imgix.net/storage/developer/93785f2c2d2a689da9cf6f960df97238.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailgun is a tool with powerful APIs that allow you to send, receive, track and store email effortlessly. Except, with Zapier you don't have to write any code!",
    "category": "Transactional Email",
    "title": "Mailgun Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailgun with SalesBlink",
    "metaDescription": "Integrate Mailgun and other Transactional Email Tools with SalesBlink. Two Way Mailgun Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pardot",
    "slug": "pardot",
    "logo": "https://zapier-images.imgix.net/storage/developer/3759d6489c3f9344057a5c3e750c3411.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pardot is no-hassle marketing automation that makes sales and marketing teams best friends. Increase marketing accountability, gain greater insight into ROI, and much more.",
    "category": "Marketing Automation",
    "title": "Pardot Integration With Cold Email Sales Tool",
    "h1": "Integrate Pardot with SalesBlink",
    "metaDescription": "Integrate Pardot and other Marketing Automation Tools with SalesBlink. Two Way Pardot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YouCanBook.Me",
    "slug": "youcanbookme",
    "logo": "https://zapier-images.imgix.net/storage/developer/9336c0b89d9c29a6202346ddd98a79e7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Take bookings online. YouCanBook.me links to your calendar to allow your customers to book your services via the web.",
    "category": "Scheduling & Booking",
    "title": "YouCanBook.Me Integration With Cold Email Sales Tool",
    "h1": "Integrate YouCanBook.Me with SalesBlink",
    "metaDescription": "Integrate YouCanBook.Me and other Scheduling & Booking Tools with SalesBlink. Two Way YouCanBook.Me Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "eBay",
    "slug": "ebay",
    "logo": "https://zapier-images.imgix.net/storage/services/84c647027dbb9ddff799ca5ece59f05f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eBay is a premier auction site that allows anyone to buy and sell goods, from individuals to retailers.",
    "category": "eCommerce",
    "title": "eBay Integration With Cold Email Sales Tool",
    "h1": "Integrate eBay with SalesBlink",
    "metaDescription": "Integrate eBay and other eCommerce Tools with SalesBlink. Two Way eBay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chime",
    "slug": "chime",
    "logo": "https://zapier-images.imgix.net/storage/developer/cf0f97a0737ce7c3e6d9c596c5eada4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chime is a product of the technology company Chime Technologies, which offers a complete tech solution for real estate agents.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Chime Integration With Cold Email Sales Tool",
    "h1": "Integrate Chime with SalesBlink",
    "metaDescription": "Integrate Chime and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Chime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Linear",
    "slug": "linear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/703339d83e11ba76a226c86175413468.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Linear helps you manage software development processes.",
    "category": "Team Collaboration",
    "title": "Linear Integration With Cold Email Sales Tool",
    "h1": "Integrate Linear with SalesBlink",
    "metaDescription": "Integrate Linear and other Team Collaboration Tools with SalesBlink. Two Way Linear Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Jobber",
    "slug": "jobber",
    "logo": "https://zapier-images.imgix.net/storage/services/c5d6e99fb9d0bbbd164b35b959542392.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jobber is the command centre for home service businesses. Our easy-to-use app powers sales, operations, and customer service—all in one place.",
    "category": "Scheduling & Booking",
    "title": "Jobber Integration With Cold Email Sales Tool",
    "h1": "Integrate Jobber with SalesBlink",
    "metaDescription": "Integrate Jobber and other Scheduling & Booking Tools with SalesBlink. Two Way Jobber Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Coda",
    "slug": "coda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/467b4e6c51ae25c9e272b4e63e1abf9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coda is a new type of document that blends the flexibility of documents, the power of spreadsheets, and the utility of apps into a single new canvas.",
    "category": "Documents",
    "title": "Coda Integration With Cold Email Sales Tool",
    "h1": "Integrate Coda with SalesBlink",
    "metaDescription": "Integrate Coda and other Documents Tools with SalesBlink. Two Way Coda Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Vimeo",
    "slug": "vimeo",
    "logo": "https://zapier-images.imgix.net/storage/developer/94fc1c00a8192fc59c6738693389f872.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vimeo is a great tool for storing and distributing video content. It comes with powerful privacy and group features.",
    "category": "Video & Audio",
    "title": "Vimeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Vimeo with SalesBlink",
    "metaDescription": "Integrate Vimeo and other Video & Audio Tools with SalesBlink. Two Way Vimeo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PDF.co",
    "slug": "pdfco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57c67151e9fcae1a85978a71320fa5ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PDF.co is a set of tools for PDF file and data extraction. Get CSV out of pdf, spreadshets, barcodes, invoices. Also can split pdf, merge pdf, convert pdf.",
    "category": "Documents",
    "title": "PDF.co Integration With Cold Email Sales Tool",
    "h1": "Integrate PDF.co with SalesBlink",
    "metaDescription": "Integrate PDF.co and other Documents Tools with SalesBlink. Two Way PDF.co Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Help Scout",
    "slug": "help-scout",
    "logo": "https://zapier-images.imgix.net/storage/developer/8ee292892a13d343037a96ae7fd33720_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Help Scout is the ideal small business help desk. You can deliver great email support and get setup in minutes, without any of the typical help desk complexities.",
    "category": "Customer Support",
    "title": "Help Scout Integration With Cold Email Sales Tool",
    "h1": "Integrate Help Scout with SalesBlink",
    "metaDescription": "Integrate Help Scout and other Customer Support Tools with SalesBlink. Two Way Help Scout Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SamCart",
    "slug": "samcart",
    "logo": "https://zapier-images.imgix.net/storage/developer/65750cda4cbce9ef0335b0c3be7799cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SamCart is an online shopping cart that helps you sell things online.",
    "category": "eCommerce",
    "title": "SamCart Integration With Cold Email Sales Tool",
    "h1": "Integrate SamCart with SalesBlink",
    "metaDescription": "Integrate SamCart and other eCommerce Tools with SalesBlink. Two Way SamCart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BambooHR",
    "slug": "bamboohr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f332de2ae2ed1cf4464b9dbfeb12c021.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BambooHR is an online human resources software service for small and midsized businesses.",
    "category": "HR Talent & Recruitment",
    "title": "BambooHR Integration With Cold Email Sales Tool",
    "h1": "Integrate BambooHR with SalesBlink",
    "metaDescription": "Integrate BambooHR and other HR Talent & Recruitment Tools with SalesBlink. Two Way BambooHR Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "lemlist",
    "slug": "lemlist",
    "logo": "https://zapier-images.imgix.net/storage/services/3ee796183a6943d416d9c6999a52d22d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "lemlist is an outreach email platform with personalized images and videos.",
    "category": "Drip Emails",
    "title": "lemlist Integration With Cold Email Sales Tool",
    "h1": "Integrate lemlist with SalesBlink",
    "metaDescription": "Integrate lemlist and other Drip Emails Tools with SalesBlink. Two Way lemlist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OneSignal",
    "slug": "onesignal",
    "logo": "https://zapier-images.imgix.net/storage/developer/0ed7c0fa13351f1741c935b5746f57d8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneSignal is a simple and smart service that send push notifications across all platforms. OneSignal features reliable delivery of millions of notifications, segmentation and targeting, automated delivery, localization, support for all major app development tools, and real time analytics.",
    "category": "Notifications",
    "title": "OneSignal Integration With Cold Email Sales Tool",
    "h1": "Integrate OneSignal with SalesBlink",
    "metaDescription": "Integrate OneSignal and other Notifications Tools with SalesBlink. Two Way OneSignal Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "lexoffice",
    "slug": "lexoffice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e92f39dd433f07c42bd1ae440351e19a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "lexoffice is a cloud app for german accounting. Writing offers or invoices, paying your bills or preparing your tax return is easier than ever with lexoffice.",
    "category": "Accounting",
    "title": "lexoffice Integration With Cold Email Sales Tool",
    "h1": "Integrate lexoffice with SalesBlink",
    "metaDescription": "Integrate lexoffice and other Accounting Tools with SalesBlink. Two Way lexoffice Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ignition",
    "slug": "ignition",
    "logo": "https://zapier-images.imgix.net/storage/services/ed7d2da389d1ca88bfcb773445284830.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ignition makes it simple to engage clients, get paid and run your business on autopilot. From impressive online proposals to automated engagement letters and payments, we free you up to focus on what matters most.",
    "category": "Proposal & Invoice Management",
    "title": "Ignition Integration With Cold Email Sales Tool",
    "h1": "Integrate Ignition with SalesBlink",
    "metaDescription": "Integrate Ignition and other Proposal & Invoice Management Tools with SalesBlink. Two Way Ignition Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VideoAsk",
    "slug": "videoask",
    "logo": "https://zapier-images.imgix.net/storage/services/25c8bb24dac145dfac5ac4f9c816bd10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VideoAsk is a business tool to help your business have meaningful interactions using the proximity of video.",
    "category": "Forms & Surveys",
    "title": "VideoAsk Integration With Cold Email Sales Tool",
    "h1": "Integrate VideoAsk with SalesBlink",
    "metaDescription": "Integrate VideoAsk and other Forms & Surveys Tools with SalesBlink. Two Way VideoAsk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Books",
    "slug": "zoho-books",
    "logo": "https://zapier-images.imgix.net/storage/developer/652f0a797d166a02037d84f688457512_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Books is cloud-based accounting software in which you can record, audit and analyze all financial transactions easily. With secure data storage, easy navigation and customizable features, Zoho Books provide a head start in accounting for small businesses.",
    "category": "Accounting",
    "title": "Zoho Books Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Books with SalesBlink",
    "metaDescription": "Integrate Zoho Books and other Accounting Tools with SalesBlink. Two Way Zoho Books Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Tidio",
    "slug": "tidio",
    "logo": "https://zapier-images.imgix.net/storage/developer/1c4c384a66254fe239eeaf6a694480cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tidio uniquely merges live chat, Bots, and Marketing Automation in one place to let you easily communicate with your customers.",
    "category": "Customer Support",
    "title": "Tidio Integration With Cold Email Sales Tool",
    "h1": "Integrate Tidio with SalesBlink",
    "metaDescription": "Integrate Tidio and other Customer Support Tools with SalesBlink. Two Way Tidio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FreshBooks",
    "slug": "freshbooks",
    "logo": "https://zapier-images.imgix.net/storage/services/b1273a3795d2341291cbf556530afced.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreshBooks is a cloud accounting software designed exclusively for self-employed professionals and their teams. Send invoices, track expenses, manage your time, and collaborate on projects.",
    "category": "Accounting",
    "title": "FreshBooks Integration With Cold Email Sales Tool",
    "h1": "Integrate FreshBooks with SalesBlink",
    "metaDescription": "Integrate FreshBooks and other Accounting Tools with SalesBlink. Two Way FreshBooks Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Microsoft Dynamics 365 CRM",
    "slug": "microsoft-dynamics-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/47648cb213e43a4c2f57e9e0bdf9699f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft Dynamics is built for any device, anywhere. Built for you. For Dynamics Version 9 and Dynamics 365.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Microsoft Dynamics 365 CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Dynamics 365 CRM with SalesBlink",
    "metaDescription": "Integrate Microsoft Dynamics 365 CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Microsoft Dynamics 365 CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bonjoro",
    "slug": "bonjoro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c2487bc7a6ff76042202543eb60b217_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bonjoro lets you send personalised welcome videos to your customers.",
    "category": "Customer Appreciation",
    "title": "Bonjoro Integration With Cold Email Sales Tool",
    "h1": "Integrate Bonjoro with SalesBlink",
    "metaDescription": "Integrate Bonjoro and other Customer Appreciation Tools with SalesBlink. Two Way Bonjoro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon S3",
    "slug": "amazon-s3",
    "logo": "https://zapier-images.imgix.net/storage/services/148f220f00f8765bf6ddb97266727c33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Simple Storage Service is a fully redundant data storage system. It makes it easy to store and retrieve any amount of data, anytime, from anywhere.",
    "category": "Amazon",
    "title": "Amazon S3 Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon S3 with SalesBlink",
    "metaDescription": "Integrate Amazon S3 and other Amazon Tools with SalesBlink. Two Way Amazon S3 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SharpSpring",
    "slug": "sharpspring",
    "logo": "https://zapier-images.imgix.net/storage/developer/03fba39555a641d219d5d9041ba3fdb7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powerful, affordable marketing tools for marketing agencies and SMBs. Features include email automation, lead capture, and a built in CRM system.",
    "category": "Marketing Automation",
    "title": "SharpSpring Integration With Cold Email Sales Tool",
    "h1": "Integrate SharpSpring with SalesBlink",
    "metaDescription": "Integrate SharpSpring and other Marketing Automation Tools with SalesBlink. Two Way SharpSpring Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Box",
    "slug": "box",
    "logo": "https://zapier-images.imgix.net/storage/services/3c3623c6d6bf18a053d7e0db73649703.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Box lets you keep all your businesses files in one place for simple online collaboration.",
    "category": "File Management & Storage",
    "title": "Box Integration With Cold Email Sales Tool",
    "h1": "Integrate Box with SalesBlink",
    "metaDescription": "Integrate Box and other File Management & Storage Tools with SalesBlink. Two Way Box Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Bitly",
    "slug": "bitly",
    "logo": "https://zapier-images.imgix.net/storage/developer/4c7b638cf98b722a309cf10fca143f86_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bitly is a link management and shortener platform.",
    "category": "URL Shortener",
    "title": "Bitly Integration With Cold Email Sales Tool",
    "h1": "Integrate Bitly with SalesBlink",
    "metaDescription": "Integrate Bitly and other URL Shortener Tools with SalesBlink. Two Way Bitly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Insightly",
    "slug": "insightly",
    "logo": "https://zapier-images.imgix.net/storage/developer/eb24c41eeaabc53f66829604ab3e28cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Insightly is a CRM and project management app that helps small to medium businesses manage contacts, track relationships, monitor sales pipeline, organize projects and more. It keeps all your critical business info on all your devices, so your company sells more and satisfies customers better.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Insightly Integration With Cold Email Sales Tool",
    "h1": "Integrate Insightly with SalesBlink",
    "metaDescription": "Integrate Insightly and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Insightly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MemberPress",
    "slug": "memberpress",
    "logo": "https://zapier-images.imgix.net/storage/services/ae7b30b04d334e15f8f8cade96a8aaa0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MemberPress is an “all-in-one” membership and LMS plugin for WordPress that will help you generate recurring membership revenue from content like blogs, newsletters, online courses, digital downloads, podcasts, videos, and more.",
    "category": "WordPress",
    "title": "MemberPress Integration With Cold Email Sales Tool",
    "h1": "Integrate MemberPress with SalesBlink",
    "metaDescription": "Integrate MemberPress and other WordPress Tools with SalesBlink. Two Way MemberPress Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Bubble",
    "slug": "bubble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f34aa95d848d5418eff70244e2d8c6e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bubble is a visual programming platform to build web apps without code.",
    "category": "App Builder",
    "title": "Bubble Integration With Cold Email Sales Tool",
    "h1": "Integrate Bubble with SalesBlink",
    "metaDescription": "Integrate Bubble and other App Builder Tools with SalesBlink. Two Way Bubble Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "17hats",
    "slug": "17hats",
    "logo": "https://zapier-images.imgix.net/storage/services/c45c7386602eaa55988b9e7713e48b34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "17hats is a premiere business platform that provides you with a suite of business solutions to help organize, systemize, and grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "17hats Integration With Cold Email Sales Tool",
    "h1": "Integrate 17hats with SalesBlink",
    "metaDescription": "Integrate 17hats and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 17hats Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zendesk Sell",
    "slug": "zendesk-sell",
    "logo": "https://zapier-images.imgix.net/storage/services/ca50307ec6e473caf0ec17101858adab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zendesk Sell (formerly Base) is a sales automation tool to enhance productivity, processes, and pipeline visibility for sales teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zendesk Sell Integration With Cold Email Sales Tool",
    "h1": "Integrate Zendesk Sell with SalesBlink",
    "metaDescription": "Integrate Zendesk Sell and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zendesk Sell Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "tawk.to",
    "slug": "tawk-to",
    "logo": "https://zapier-images.imgix.net/storage/services/fbdbee19139d02b2c71422b8f4ef6b55_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "tawk.to is a free live chat app that lets you monitor and chat with visitors on your website or from a free customizable page.",
    "category": "Customer Support",
    "title": "tawk.to Integration With Cold Email Sales Tool",
    "h1": "Integrate tawk.to with SalesBlink",
    "metaDescription": "Integrate tawk.to and other Customer Support Tools with SalesBlink. Two Way tawk.to Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Productboard",
    "slug": "productboard",
    "logo": "https://zapier-images.imgix.net/storage/services/270b400648bbda23a11fd7e46b91b2fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Productboard is beautiful, simple, and powerful product management. Product leaders can easily centralize user and market research, capture and organize feature ideas, prioritize against clear strategic objectives and keep everyone on the same page thanks to delightful, interactive roadmaps.",
    "category": "Product Management",
    "title": "Productboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Productboard with SalesBlink",
    "metaDescription": "Integrate Productboard and other Product Management Tools with SalesBlink. Two Way Productboard Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Glide",
    "slug": "glide-apps",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b11212d087201e6630a6879e365aad6c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Glide is an app builder that turns Google Sheets into easy-to-use apps without code.",
    "category": "App Builder",
    "title": "Glide Integration With Cold Email Sales Tool",
    "h1": "Integrate Glide with SalesBlink",
    "metaDescription": "Integrate Glide and other App Builder Tools with SalesBlink. Two Way Glide Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Kommo",
    "slug": "kommo",
    "logo": "https://zapier-images.imgix.net/storage/services/9a8072bcfad2a9e4f21bba493f13e7d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kommo is a messaging-powered CRM that helps you engage with your customers on a whole new level. It's a versatile, cloud-based sales automation tool that enables you to manage leads and monitor your sales pipeline in only a few clicks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kommo Integration With Cold Email Sales Tool",
    "h1": "Integrate Kommo with SalesBlink",
    "metaDescription": "Integrate Kommo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kommo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Jira Service Management",
    "slug": "jira-service-management",
    "logo": "https://zapier-images.imgix.net/storage/services/07d11aa2eaa1dd2b35baa0c7ebe269bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jira Service Management is help desk software that enables support agents to receive, track, manage and resolve requests from help seekers.",
    "category": "Customer Support",
    "title": "Jira Service Management Integration With Cold Email Sales Tool",
    "h1": "Integrate Jira Service Management with SalesBlink",
    "metaDescription": "Integrate Jira Service Management and other Customer Support Tools with SalesBlink. Two Way Jira Service Management Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SignNow",
    "slug": "signnow",
    "logo": "https://zapier-images.imgix.net/storage/services/07742796428210bbc8aa5e4977ee9b89.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignNow provides a secure and easy electronic signature solution that enables you to sign, send and manage documents anywhere while using any device.",
    "category": "Signatures",
    "title": "SignNow Integration With Cold Email Sales Tool",
    "h1": "Integrate SignNow with SalesBlink",
    "metaDescription": "Integrate SignNow and other Signatures Tools with SalesBlink. Two Way SignNow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Medium",
    "slug": "medium",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f1e14b2d1e209eea4008ff6e509af30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Medium is a publishing platform where people can read important, insightful stories on the topics that matter most to them and share ideas with the world.",
    "category": "Social Media Accounts",
    "title": "Medium Integration With Cold Email Sales Tool",
    "h1": "Integrate Medium with SalesBlink",
    "metaDescription": "Integrate Medium and other Social Media Accounts Tools with SalesBlink. Two Way Medium Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Apollo",
    "slug": "apollo",
    "logo": "https://zapier-images.imgix.net/storage/services/c3ace7d26096bdfe844466b4e03d4209.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apollo is a unified engagement platform that is the foundation for your entire end-to-end sales strategy. Teams get access to a database of over 200 million contacts, as well as a host of different engagement and analytics tools.",
    "category": "Sales & CRM",
    "title": "Apollo Integration With Cold Email Sales Tool",
    "h1": "Integrate Apollo with SalesBlink",
    "metaDescription": "Integrate Apollo and other Sales & CRM Tools with SalesBlink. Two Way Apollo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Customer.io",
    "slug": "customerio",
    "logo": "https://zapier-images.imgix.net/storage/developer/1618cfb9e32b3b3198d76fef05c761fa_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Customer.io you can send email, SMS, and push based on what people do or don't do in your app.",
    "category": "Marketing Automation",
    "title": "Customer.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Customer.io with SalesBlink",
    "metaDescription": "Integrate Customer.io and other Marketing Automation Tools with SalesBlink. Two Way Customer.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Credit Repair Cloud",
    "slug": "credit-repair-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/3bba769ba2b4b030110e04a91caff2a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud-based Credit Repair Software and CRM. For mortgage brokers and entrepreneurs. Help your clients or start a credit repair business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Credit Repair Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Credit Repair Cloud with SalesBlink",
    "metaDescription": "Integrate Credit Repair Cloud and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Credit Repair Cloud Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mojo",
    "slug": "mojo",
    "logo": "https://zapier-images.imgix.net/storage/developer/5a314b8ce57b01ea6c147a03542cbaeb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mojo is a Real Estate prospecting solution which includes an easy to use CRM, Power Dialer and Real Estate lead service.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mojo Integration With Cold Email Sales Tool",
    "h1": "Integrate Mojo with SalesBlink",
    "metaDescription": "Integrate Mojo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mojo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LiveChat",
    "slug": "livechat",
    "logo": "https://zapier-images.imgix.net/storage/developer/f124a26a98b5f32270259618f0e6d9f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LiveChat is the faster way to contact your customers. It is a web-based chat tool that lets you chat with your website visitors.",
    "category": "Customer Support",
    "title": "LiveChat Integration With Cold Email Sales Tool",
    "h1": "Integrate LiveChat with SalesBlink",
    "metaDescription": "Integrate LiveChat and other Customer Support Tools with SalesBlink. Two Way LiveChat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Instapage",
    "slug": "instapage",
    "logo": "https://zapier-images.imgix.net/storage/services/6bfae1f4a118bda99815edf37dde3ae7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instapage is the leader in post-click optimization and maximizes conversions for advertisers and marketers by enabling them to create, optimize, and personalize landing pages at scale.",
    "category": "Forms & Surveys",
    "title": "Instapage Integration With Cold Email Sales Tool",
    "h1": "Integrate Instapage with SalesBlink",
    "metaDescription": "Integrate Instapage and other Forms & Surveys Tools with SalesBlink. Two Way Instapage Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LearnWorlds",
    "slug": "learnworlds",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b644fd45fd944a6cf66f2e379c10626_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LearnWorlds is a premium learning platform via which you sell online courses under your own website.",
    "category": "Online Courses",
    "title": "LearnWorlds Integration With Cold Email Sales Tool",
    "h1": "Integrate LearnWorlds with SalesBlink",
    "metaDescription": "Integrate LearnWorlds and other Online Courses Tools with SalesBlink. Two Way LearnWorlds Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Wealthbox CRM",
    "slug": "wealthbox",
    "logo": "https://zapier-images.imgix.net/storage/services/d30b61a9b64d1cfd1077681aebb72689.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wealthbox is a web-based CRM solution that allows financial advisors to manage their client relationships with ease.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wealthbox CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Wealthbox CRM with SalesBlink",
    "metaDescription": "Integrate Wealthbox CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wealthbox CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoTo Webinar",
    "slug": "gotowebinar",
    "logo": "https://zapier-images.imgix.net/storage/developer/265ab25ff8f73ad42445e69e256d0ee8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoTo Webinar by LogMeIn makes it easy to set up and deliver an online video and audio conference. Conduct do-it-yourself webinars with up to 1000 people – all for one flat rate. Extend your reach, expand your audience, increase your influence. Webinars work.",
    "category": "Webinars",
    "title": "GoTo Webinar Integration With Cold Email Sales Tool",
    "h1": "Integrate GoTo Webinar with SalesBlink",
    "metaDescription": "Integrate GoTo Webinar and other Webinars Tools with SalesBlink. Two Way GoTo Webinar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Livestorm",
    "slug": "livestorm",
    "logo": "https://zapier-images.imgix.net/storage/services/15c16769a8fc7952aca4743675e6753e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Livestorm is an end-to-end engagement platform that enables you to connect, engage, and capture actionable insights in one place.",
    "category": "Webinars",
    "title": "Livestorm Integration With Cold Email Sales Tool",
    "h1": "Integrate Livestorm with SalesBlink",
    "metaDescription": "Integrate Livestorm and other Webinars Tools with SalesBlink. Two Way Livestorm Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OmniFocus",
    "slug": "omnifocus",
    "logo": "https://zapier-images.imgix.net/storage/developer/b1faf7705662edb176ffd032a45d8ef3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OmniFocus is an incredible task management platform for Mac®, iPad®, and iPhone®.",
    "category": "Task Management",
    "title": "OmniFocus Integration With Cold Email Sales Tool",
    "h1": "Integrate OmniFocus with SalesBlink",
    "metaDescription": "Integrate OmniFocus and other Task Management Tools with SalesBlink. Two Way OmniFocus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teamwork",
    "slug": "teamwork",
    "logo": "https://zapier-images.imgix.net/storage/services/cec9eece0ab5c7b99976f0effc38e584.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamwork is an easy-to-use online teamwork & project management software application that helps managers, staff and clients work together more productively online.",
    "category": "Project Management",
    "title": "Teamwork Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamwork with SalesBlink",
    "metaDescription": "Integrate Teamwork and other Project Management Tools with SalesBlink. Two Way Teamwork Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Redtail CRM",
    "slug": "redtailcrm",
    "logo": "https://zapier-images.imgix.net/storage/developer/cfc3ec1648238cd494ed4605fc00cc11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Redtail CRM is cost-effective, easy to use, cloud based solution for today’s financial professional. Packed with powerful features for activity management, office automation, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Redtail CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Redtail CRM with SalesBlink",
    "metaDescription": "Integrate Redtail CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Redtail CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Campaigns",
    "slug": "zoho-campaigns",
    "logo": "https://zapier-images.imgix.net/storage/developer/02e8b20739c3cb7425015ed243c191a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Campaigns lets you send email and social media campaigns to your subscribers.",
    "category": "Marketing Automation",
    "title": "Zoho Campaigns Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Campaigns with SalesBlink",
    "metaDescription": "Integrate Zoho Campaigns and other Marketing Automation Tools with SalesBlink. Two Way Zoho Campaigns Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Process Street",
    "slug": "process-street",
    "logo": "https://zapier-images.imgix.net/storage/developer/31b8d7acde04569129edf1bd1bcd6c11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Process Street is a modern process management platform for teams. We help teams share their core processes, then transform them into powerful no-code workflows.",
    "category": "Project Management",
    "title": "Process Street Integration With Cold Email Sales Tool",
    "h1": "Integrate Process Street with SalesBlink",
    "metaDescription": "Integrate Process Street and other Project Management Tools with SalesBlink. Two Way Process Street Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SignRequest",
    "slug": "signrequest",
    "logo": "https://zapier-images.imgix.net/storage/developer/e9769a2749f2db807b6bf8698a509d74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignRequest is an electronic (digital) signature solution to get documents signed. Easy, fast, secure and legally binding.",
    "category": "Signatures",
    "title": "SignRequest Integration With Cold Email Sales Tool",
    "h1": "Integrate SignRequest with SalesBlink",
    "metaDescription": "Integrate SignRequest and other Signatures Tools with SalesBlink. Two Way SignRequest Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zillow Tech Connect",
    "slug": "zillow",
    "logo": "https://zapier-images.imgix.net/storage/services/54c56bbb5c7279f57155ecd0b6c2ad1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Zillow Tech Connect app allows agents to send leads to tools like CRMs and marketing automation software. It works for Zillow, Trulia, HotPads, RealEstate.com, and StreetEasy. It is also known as Zillow Premier Agent.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zillow Tech Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Zillow Tech Connect with SalesBlink",
    "metaDescription": "Integrate Zillow Tech Connect and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zillow Tech Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Streamtime",
    "slug": "streamtime",
    "logo": "https://zapier-images.imgix.net/storage/developer/ebfe7780da1e92bec207f2c3463a48bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intuitive project management, time tracking with auto-populated to-do lists, deep analytics, team scheduling and much more.",
    "category": "Project Management",
    "title": "Streamtime Integration With Cold Email Sales Tool",
    "h1": "Integrate Streamtime with SalesBlink",
    "metaDescription": "Integrate Streamtime and other Project Management Tools with SalesBlink. Two Way Streamtime Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Bitrix24 CRM",
    "slug": "bitrix24-crm-new",
    "logo": "https://zapier-images.imgix.net/storage/services/79b64e85aae4b26b16797d94bc34222e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bitrix24 is a free CRM, project management and collaboration platform used by 10 million teams and available in 18 different languages.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bitrix24 CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Bitrix24 CRM with SalesBlink",
    "metaDescription": "Integrate Bitrix24 CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bitrix24 CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trustpilot",
    "slug": "trustpilot",
    "logo": "https://zapier-images.imgix.net/storage/developer/c619b2cd4fa407a515c17b3d92d984df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trustpilot is the world's most powerful review platform, helping your business collect and easily manage customer reviews. Trustpilot gives people a place to share and discover reviews of businesses, while giving every business the tools to turn consumer feedback into business results.",
    "category": "Reviews",
    "title": "Trustpilot Integration With Cold Email Sales Tool",
    "h1": "Integrate Trustpilot with SalesBlink",
    "metaDescription": "Integrate Trustpilot and other Reviews Tools with SalesBlink. Two Way Trustpilot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Marketo",
    "slug": "marketo",
    "logo": "https://zapier-images.imgix.net/storage/developer/bec09826ed23eb439c1d9f47d2f5fab7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marketo's marketing automation software helps marketers engage customers and prospects.",
    "category": "Marketing Automation",
    "title": "Marketo Integration With Cold Email Sales Tool",
    "h1": "Integrate Marketo with SalesBlink",
    "metaDescription": "Integrate Marketo and other Marketing Automation Tools with SalesBlink. Two Way Marketo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Demio",
    "slug": "demio",
    "logo": "https://zapier-images.imgix.net/storage/developer/1a7f5f0fdd34fac72e4b5047342fe3ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A smart webinar platform built for inbound marketing  and sales.",
    "category": "Webinars",
    "title": "Demio Integration With Cold Email Sales Tool",
    "h1": "Integrate Demio with SalesBlink",
    "metaDescription": "Integrate Demio and other Webinars Tools with SalesBlink. Two Way Demio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Patreon",
    "slug": "patreon",
    "logo": "https://zapier-images.imgix.net/storage/developer/feec5ab9e3b5e3eee166d3553952b510_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Patreon was built to power membership businesses for creators and strives to support creators who wish to gain consistent, sustainable income through their work. Patreon also empowers creators to retain direct contact with their most passionate fans and own creative control of their work.",
    "category": "Fundraising",
    "title": "Patreon Integration With Cold Email Sales Tool",
    "h1": "Integrate Patreon with SalesBlink",
    "metaDescription": "Integrate Patreon and other Fundraising Tools with SalesBlink. Two Way Patreon Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Bookings",
    "slug": "zoho-bookings",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b4cd5183845ec878a22d59f42e2939c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Bookings is an appointment scheduler that lets you find the perfect time with your customers. Book quality meetings and increase sales.",
    "category": "Scheduling & Booking",
    "title": "Zoho Bookings Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Bookings with SalesBlink",
    "metaDescription": "Integrate Zoho Bookings and other Scheduling & Booking Tools with SalesBlink. Two Way Zoho Bookings Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Keller Williams Command",
    "slug": "kwcommand",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/802925d9decdf60abb7165a6b3a9efdd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keller Williams Command is a dashboard that allows you to optimize, manage, and run your business with simplicity and insight.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Keller Williams Command Integration With Cold Email Sales Tool",
    "h1": "Integrate Keller Williams Command with SalesBlink",
    "metaDescription": "Integrate Keller Williams Command and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Keller Williams Command Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailchimp Transactional",
    "slug": "mandrill",
    "logo": "https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailchimp Transactional is simple, powerful transactional email.",
    "category": "Transactional Email",
    "title": "Mailchimp Transactional Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailchimp Transactional with SalesBlink",
    "metaDescription": "Integrate Mailchimp Transactional and other Transactional Email Tools with SalesBlink. Two Way Mailchimp Transactional Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Capsule CRM",
    "slug": "capsulecrm",
    "logo": "https://zapier-images.imgix.net/storage/services/be28c1dd251bfeb76afe26d4e2e071aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Capsule is an online CRM for managing contacts, tasks and sales opportunities. Add tasks, track communication logs, and close deals in your pipeline faster with Capsule.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Capsule CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Capsule CRM with SalesBlink",
    "metaDescription": "Integrate Capsule CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Capsule CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Miro",
    "slug": "miro",
    "logo": "https://zapier-images.imgix.net/storage/services/c9ff916cf9e886f80b2a4a4ff35b9195.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Miro (formerly RealtimeBoard) is an intuitive visual collaboration and whiteboarding platform for cross-functional teams.",
    "category": "Team Collaboration",
    "title": "Miro Integration With Cold Email Sales Tool",
    "h1": "Integrate Miro with SalesBlink",
    "metaDescription": "Integrate Miro and other Team Collaboration Tools with SalesBlink. Two Way Miro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Knack",
    "slug": "knack",
    "logo": "https://zapier-images.imgix.net/storage/developer/f8c5e49767878040038511dc7d583ede.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Knack is the easy way to build online databases and applications.",
    "category": "Databases",
    "title": "Knack Integration With Cold Email Sales Tool",
    "h1": "Integrate Knack with SalesBlink",
    "metaDescription": "Integrate Knack and other Databases Tools with SalesBlink. Two Way Knack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Digistore24",
    "slug": "digistore24",
    "logo": "https://zapier-images.imgix.net/storage/developer/471640da554e6e3d6432630c61a2b2c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digistore24 is a highly automated Internet Marketing platform for selling Digital information products and seminars.",
    "category": "Marketing Automation",
    "title": "Digistore24 Integration With Cold Email Sales Tool",
    "h1": "Integrate Digistore24 with SalesBlink",
    "metaDescription": "Integrate Digistore24 and other Marketing Automation Tools with SalesBlink. Two Way Digistore24 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Raindrop.io",
    "slug": "raindropio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3ea10d2c06c32f774884b303e054f48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Raindrop.io is the best way to save articles, videos and links to view on any device, any time.",
    "category": "Bookmark Managers",
    "title": "Raindrop.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Raindrop.io with SalesBlink",
    "metaDescription": "Integrate Raindrop.io and other Bookmark Managers Tools with SalesBlink. Two Way Raindrop.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "KlickTipp",
    "slug": "klicktipp",
    "logo": "https://zapier-images.imgix.net/storage/services/3f1b2b03dc26fdbc52bc41f8fd9a37de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KlickTipp is an email marketing service provider. On our easy to use platform, you can segment your subscribers by tags and send highly relevant emails or even SMS to them. Keep track of your success by analysing real-time data like Conversions, Opens, Clicks and more.",
    "category": "Marketing Automation",
    "title": "KlickTipp Integration With Cold Email Sales Tool",
    "h1": "Integrate KlickTipp with SalesBlink",
    "metaDescription": "Integrate KlickTipp and other Marketing Automation Tools with SalesBlink. Two Way KlickTipp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TidyCal",
    "slug": "tidycal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7a8c67d61b330ee38e1da73a8f22af99.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TidyCal is simple and easy calendar management and booking tool.",
    "category": "Calendar",
    "title": "TidyCal Integration With Cold Email Sales Tool",
    "h1": "Integrate TidyCal with SalesBlink",
    "metaDescription": "Integrate TidyCal and other Calendar Tools with SalesBlink. Two Way TidyCal Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Front",
    "slug": "front-app",
    "logo": "https://zapier-images.imgix.net/storage/developer/13215ffc90cd78348c417badb0edc481.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Front is the shared inbox for teams that brings your email, communication channels, and apps into one platform for collaboration.",
    "category": "Email",
    "title": "Front Integration With Cold Email Sales Tool",
    "h1": "Integrate Front with SalesBlink",
    "metaDescription": "Integrate Front and other Email Tools with SalesBlink. Two Way Front Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ServiceM8",
    "slug": "servicem8",
    "logo": "https://zapier-images.imgix.net/storage/developer/b7b0a12ee5cb3bee578523f4afd2ab9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceM8 is a field service app and small business management platform for your computer, iPhone and iPad. Track staff locations, navigate to jobs, and control your estimates, invoicing, scheduling, safety reports, clients & more.",
    "category": "Scheduling & Booking",
    "title": "ServiceM8 Integration With Cold Email Sales Tool",
    "h1": "Integrate ServiceM8 with SalesBlink",
    "metaDescription": "Integrate ServiceM8 and other Scheduling & Booking Tools with SalesBlink. Two Way ServiceM8 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BoomTown",
    "slug": "boomtown",
    "logo": "https://zapier-images.imgix.net/storage/services/bfc9aad59d4b81762e1d8cc9e08002ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BoomTown is a software platform designed to help real estate professionals generate leads, manage contacts, and run their business better.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BoomTown Integration With Cold Email Sales Tool",
    "h1": "Integrate BoomTown with SalesBlink",
    "metaDescription": "Integrate BoomTown and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BoomTown Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshsales Suite",
    "slug": "freshsales-suite",
    "logo": "https://zapier-images.imgix.net/storage/services/412bd81963accc5ed79adacab8453430.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshsales Suite is an online, cloud-based CRM with integrated phone and email, user behavior tracking, lead scoring and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Freshsales Suite Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshsales Suite with SalesBlink",
    "metaDescription": "Integrate Freshsales Suite and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Freshsales Suite Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Stan",
    "slug": "stan-creator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8883aa28ff5121ee8197cc6d1875d5b0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Designed for Creators to set up their Stan Automations",
    "category": "App Builder",
    "title": "Stan Integration With Cold Email Sales Tool",
    "h1": "Integrate Stan with SalesBlink",
    "metaDescription": "Integrate Stan and other App Builder Tools with SalesBlink. Two Way Stan Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Bigin by Zoho CRM",
    "slug": "bigin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e36d3e56c05a109fc4fba67bad4d3ce_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bigin is a sales pipeline management software that lets you manage deals, track activities and keep all the interactions with your contacts at one place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bigin by Zoho CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Bigin by Zoho CRM with SalesBlink",
    "metaDescription": "Integrate Bigin by Zoho CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bigin by Zoho CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Brivity",
    "slug": "brivity",
    "logo": "https://zapier-images.imgix.net/storage/developer/630a79c5c077ad1d4577aaac067c043e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brivity is a cloud-based CRM platform for real estate professionals & brokerages.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Brivity Integration With Cold Email Sales Tool",
    "h1": "Integrate Brivity with SalesBlink",
    "metaDescription": "Integrate Brivity and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Brivity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deadline Funnel",
    "slug": "deadline-funnel",
    "logo": "https://zapier-images.imgix.net/storage/developer/0f3af0252b9af8150003631c74f71aa2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gives your prospects a unique, authentic deadline so they have a reason to buy now",
    "category": "eCommerce",
    "title": "Deadline Funnel Integration With Cold Email Sales Tool",
    "h1": "Integrate Deadline Funnel with SalesBlink",
    "metaDescription": "Integrate Deadline Funnel and other eCommerce Tools with SalesBlink. Two Way Deadline Funnel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobNimbus",
    "slug": "jobnimbus",
    "logo": "https://zapier-images.imgix.net/storage/services/bfd86d62a8dfad03e288b7c20d033724.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobNimbus CRM + Project Management for the service industry.",
    "category": "CRM (Customer Relationship Management)",
    "title": "JobNimbus Integration With Cold Email Sales Tool",
    "h1": "Integrate JobNimbus with SalesBlink",
    "metaDescription": "Integrate JobNimbus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way JobNimbus Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Proposify",
    "slug": "proposify",
    "logo": "https://zapier-images.imgix.net/storage/developer/5f6541806a59e9c7dc59aa4d36e61c91_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Streamline your proposals in the cloud with designer-friendly proposal software.",
    "category": "Proposal & Invoice Management",
    "title": "Proposify Integration With Cold Email Sales Tool",
    "h1": "Integrate Proposify with SalesBlink",
    "metaDescription": "Integrate Proposify and other Proposal & Invoice Management Tools with SalesBlink. Two Way Proposify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Practice Better",
    "slug": "practice-better",
    "logo": "https://zapier-images.imgix.net/storage/services/ec554ed54478a4edfefd0a192e3e90d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Practice Better is a comprehensive solution to help you manage your Health & Wellness practice and the entire client journey with ease, saving you both time and money!",
    "category": "Scheduling & Booking",
    "title": "Practice Better Integration With Cold Email Sales Tool",
    "h1": "Integrate Practice Better with SalesBlink",
    "metaDescription": "Integrate Practice Better and other Scheduling & Booking Tools with SalesBlink. Two Way Practice Better Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formstack Documents",
    "slug": "formstack-documents",
    "logo": "https://zapier-images.imgix.net/storage/developer/e1d44525da3ae1af4ac48d3a43562521.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Introducing Formstack Documents, formerly WebMerge. Automatically generate PDF and Word DOCX documents merged with data from online web services. Contracts, invoices, applications, tickets, and more - all created dynamically, saving you time and money.",
    "category": "Documents",
    "title": "Formstack Documents Integration With Cold Email Sales Tool",
    "h1": "Integrate Formstack Documents with SalesBlink",
    "metaDescription": "Integrate Formstack Documents and other Documents Tools with SalesBlink. Two Way Formstack Documents Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Meet",
    "slug": "google-meet",
    "logo": "https://zapier-images.imgix.net/storage/services/609ac1b46d8872e71f11c40d7fe19186.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Meet is a video conferencing app. Using your browser, share your video, desktop, and presentations with teammates and customers.",
    "category": "Google",
    "title": "Google Meet Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Meet with SalesBlink",
    "metaDescription": "Integrate Google Meet and other Google Tools with SalesBlink. Two Way Google Meet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Housecall Pro",
    "slug": "housecall-pro",
    "logo": "https://zapier-images.imgix.net/storage/services/05cb9380fae3f5e52b9945f150800d44.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Housecall Pro is a top rated software to run your home service business. We make it easy to schedule, dispatch, estimate, invoice, accept credit cards and get booked online by customers.",
    "category": "Scheduling & Booking",
    "title": "Housecall Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Housecall Pro with SalesBlink",
    "metaDescription": "Integrate Housecall Pro and other Scheduling & Booking Tools with SalesBlink. Two Way Housecall Pro Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Teamleader Focus",
    "slug": "teamleader",
    "logo": "https://zapier-images.imgix.net/storage/services/6437f432fe88c8f7c9b0d1cfc7517467.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamleader Focus incorporates CRM, project management and invoicing into one simple and intelligent tool that helps businesses work smarter.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Teamleader Focus Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamleader Focus with SalesBlink",
    "metaDescription": "Integrate Teamleader Focus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Teamleader Focus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PagerDuty",
    "slug": "pagerduty",
    "logo": "https://zapier-images.imgix.net/storage/developer/2de36913e798cfabab53fccec13d9d46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "In an always-on world, teams trust PagerDuty to help them deliver a perfect digital experience to their customers, every time. PagerDuty is the central nervous system for a company's digital operations. PagerDuty identifies issues and opportunities in real time and brings together the right people to respond to problems faster and prevent them in the future. From digital disruptors to Fortune 500 companies, over 12,000 businesses rely on PagerDuty to help them continually improve their digital operations-so their teams can spend less time reacting to incidents and more time building for the future.",
    "category": "Server Monitoring",
    "title": "PagerDuty Integration With Cold Email Sales Tool",
    "h1": "Integrate PagerDuty with SalesBlink",
    "metaDescription": "Integrate PagerDuty and other Server Monitoring Tools with SalesBlink. Two Way PagerDuty Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BotConversa",
    "slug": "botconversa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/578614a5a1ee3fe99eb40d3de3b2739e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BotConversa is a Chatbot builder for WhatsApp. We’ve made it simples for small to big Size businesses to automate their WhatsApp conversations and create a true lead generation machine.",
    "category": "Marketing Automation",
    "title": "BotConversa Integration With Cold Email Sales Tool",
    "h1": "Integrate BotConversa with SalesBlink",
    "metaDescription": "Integrate BotConversa and other Marketing Automation Tools with SalesBlink. Two Way BotConversa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Passion.io",
    "slug": "passion-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd5ab5f7feed8763eaf43153b53a69a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Passion.io is a platform that turns your content or skills into your own mobile apps that sell.",
    "category": "App Builder",
    "title": "Passion.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Passion.io with SalesBlink",
    "metaDescription": "Integrate Passion.io and other App Builder Tools with SalesBlink. Two Way Passion.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MeisterTask",
    "slug": "meistertask",
    "logo": "https://zapier-images.imgix.net/storage/developer/d7d6bc4f40aa371775e00f46497bcc47_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MeisterTask is an incredibly intuitive online task manager that uses smart integrations and task automations to make your team more productive.",
    "category": "Project Management",
    "title": "MeisterTask Integration With Cold Email Sales Tool",
    "h1": "Integrate MeisterTask with SalesBlink",
    "metaDescription": "Integrate MeisterTask and other Project Management Tools with SalesBlink. Two Way MeisterTask Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Autopilot Journeys",
    "slug": "autopilot",
    "logo": "https://zapier-images.imgix.net/storage/developer/ac9b3d2d4b2ea8244b4d6334bdaed751.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Autopilot Journeys is visual marketing software for automating customer journeys, to help companies acquire and nurture leads through email, web, SMS and direct mail.",
    "category": "Marketing Automation",
    "title": "Autopilot Journeys Integration With Cold Email Sales Tool",
    "h1": "Integrate Autopilot Journeys with SalesBlink",
    "metaDescription": "Integrate Autopilot Journeys and other Marketing Automation Tools with SalesBlink. Two Way Autopilot Journeys Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Book Like A Boss",
    "slug": "book-like-a-boss",
    "logo": "https://zapier-images.imgix.net/storage/services/e877659765ec181792273178628e6464.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Book Like A Boss is a powerful scheduling platform that allows you to take appointments, sell your services, and manage your business.",
    "category": "Scheduling & Booking",
    "title": "Book Like A Boss Integration With Cold Email Sales Tool",
    "h1": "Integrate Book Like A Boss with SalesBlink",
    "metaDescription": "Integrate Book Like A Boss and other Scheduling & Booking Tools with SalesBlink. Two Way Book Like A Boss Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Agile CRM",
    "slug": "agile-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/4c0d2b3023b1d1520cbfb1ee698807e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agile CRM offers contact management, marketing automation, telephony, analytics, web engagement, real-time alerts and social suite. Track, nurture, and sell like a pro.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agile CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Agile CRM with SalesBlink",
    "metaDescription": "Integrate Agile CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agile CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Instapaper",
    "slug": "instapaper",
    "logo": "https://zapier-images.imgix.net/storage/developer/9d02eda3a38e6b5bc33b5b657c20e364.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instapaper saves web pages to read later on your iPhone, iPad, Android, computer, or Kindle.",
    "category": "Bookmark Managers",
    "title": "Instapaper Integration With Cold Email Sales Tool",
    "h1": "Integrate Instapaper with SalesBlink",
    "metaDescription": "Integrate Instapaper and other Bookmark Managers Tools with SalesBlink. Two Way Instapaper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formsite",
    "slug": "formsite",
    "logo": "https://zapier-images.imgix.net/storage/developer/192330502e1975da63ac2083180885bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formsite enables non-technical users to build professional quality web forms and surveys with no HTML or coding experience.",
    "category": "Forms & Surveys",
    "title": "Formsite Integration With Cold Email Sales Tool",
    "h1": "Integrate Formsite with SalesBlink",
    "metaDescription": "Integrate Formsite and other Forms & Surveys Tools with SalesBlink. Two Way Formsite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "123FormBuilder",
    "slug": "123formbuilder",
    "logo": "https://zapier-images.imgix.net/storage/developer/0584e76a060ece26192175cc192cb58f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "123FormBuilder is an easy way to create and use free html forms for your website using their online contact form generator!",
    "category": "Forms & Surveys",
    "title": "123FormBuilder Integration With Cold Email Sales Tool",
    "h1": "Integrate 123FormBuilder with SalesBlink",
    "metaDescription": "Integrate 123FormBuilder and other Forms & Surveys Tools with SalesBlink. Two Way 123FormBuilder Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ticket Tailor",
    "slug": "ticket-tailor",
    "logo": "https://zapier-images.imgix.net/storage/developer/64ef21e27cf819a84e6eb70ebc287528.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ticket Tailor is an easy way to sell tickets online for events and attractions. We don't charge any per-ticket fees, and we let you integrate the booking process in to your website.",
    "category": "Event Management",
    "title": "Ticket Tailor Integration With Cold Email Sales Tool",
    "h1": "Integrate Ticket Tailor with SalesBlink",
    "metaDescription": "Integrate Ticket Tailor and other Event Management Tools with SalesBlink. Two Way Ticket Tailor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SendFox",
    "slug": "sendfox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bfe7243fe829744fa617dfe1a92c2f7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendFox is an email marketing tool built for content creators.",
    "category": "Email Newsletters",
    "title": "SendFox Integration With Cold Email Sales Tool",
    "h1": "Integrate SendFox with SalesBlink",
    "metaDescription": "Integrate SendFox and other Email Newsletters Tools with SalesBlink. Two Way SendFox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Quotient",
    "slug": "quotient",
    "logo": "https://zapier-images.imgix.net/storage/developer/857a9e42ae17f27365d46b3383035e2f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quotient is online quoting that enables your customer to pick-and-choose, ask a question, and ultimately accept your offer.",
    "category": "Proposal & Invoice Management",
    "title": "Quotient Integration With Cold Email Sales Tool",
    "h1": "Integrate Quotient with SalesBlink",
    "metaDescription": "Integrate Quotient and other Proposal & Invoice Management Tools with SalesBlink. Two Way Quotient Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Woodpecker.co",
    "slug": "woodpecker",
    "logo": "https://zapier-images.imgix.net/storage/developer/aeeecd4c0022a17e793c9526fcca6b45_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Woodpecker.co helps B2B companies directly contact prospective clients by automated sending of personalized sales emails and follow-ups. Send emails and follow-up sequences automatically from your mailbox and have all the replies detected. Grow your business within the Predictable Revenue methodology by Aaron Ross in outbound, and nurture your inbound leads more personally to build valuable B2B relations.",
    "category": "Drip Emails",
    "title": "Woodpecker.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Woodpecker.co with SalesBlink",
    "metaDescription": "Integrate Woodpecker.co and other Drip Emails Tools with SalesBlink. Two Way Woodpecker.co Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "elopage",
    "slug": "elopage",
    "logo": "https://zapier-images.imgix.net/storage/developer/65c61c5687c62e592a38de3629f156a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elopage is a SaaS-Platform for managing, selling and scaling digital products, online courses, membership plans and e-tickets.",
    "category": "eCommerce",
    "title": "elopage Integration With Cold Email Sales Tool",
    "h1": "Integrate elopage with SalesBlink",
    "metaDescription": "Integrate elopage and other eCommerce Tools with SalesBlink. Two Way elopage Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Giphy",
    "slug": "giphy",
    "logo": "https://zapier-images.imgix.net/storage/developer/828964c39c1357cb74d7056a7f4c19d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Giphy is the best way to search, share, and discover GIFs on the Internet.",
    "category": "Images & Design",
    "title": "Giphy Integration With Cold Email Sales Tool",
    "h1": "Integrate Giphy with SalesBlink",
    "metaDescription": "Integrate Giphy and other Images & Design Tools with SalesBlink. Two Way Giphy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Salla",
    "slug": "salla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ebc657a34f34c395e085c835ec9eafc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salla is an e-commerce platform witch allows merchants to establish, operate, and manage their online stores with no technical hassle. It also allows them to enable (or disable) the connection with any logistic company and/or payment gateway with a click of a button.",
    "category": "eCommerce",
    "title": "Salla Integration With Cold Email Sales Tool",
    "h1": "Integrate Salla with SalesBlink",
    "metaDescription": "Integrate Salla and other eCommerce Tools with SalesBlink. Two Way Salla Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Omnisend",
    "slug": "omnisend",
    "logo": "https://zapier-images.imgix.net/storage/developer/9bef1d0fc95c045441b002e330438245.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omnisend is a marketing automation platform built for growing ecommerce businesses that have graduated their basic email marketing tools.",
    "category": "Marketing Automation",
    "title": "Omnisend Integration With Cold Email Sales Tool",
    "h1": "Integrate Omnisend with SalesBlink",
    "metaDescription": "Integrate Omnisend and other Marketing Automation Tools with SalesBlink. Two Way Omnisend Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CloudConvert",
    "slug": "cloudconvert",
    "logo": "https://zapier-images.imgix.net/storage/developer/4d4104ab84653209e77056478ca21223.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudConvert converts files between more than 200 supported formats: documents, image, spreadsheet, presentation, audio, video and more!",
    "category": "File Management & Storage",
    "title": "CloudConvert Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudConvert with SalesBlink",
    "metaDescription": "Integrate CloudConvert and other File Management & Storage Tools with SalesBlink. Two Way CloudConvert Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Amazon Seller Central",
    "slug": "amazon-seller-central",
    "logo": "https://zapier-images.imgix.net/storage/services/e3fa2d980e4f3ce38769a185caacd3aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Seller Central enables both individuals and businesses to sell their products and inventory on Amazon.com (US accounts only).",
    "category": "Amazon",
    "title": "Amazon Seller Central Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Seller Central with SalesBlink",
    "metaDescription": "Integrate Amazon Seller Central and other Amazon Tools with SalesBlink. Two Way Amazon Seller Central Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CompanyCam",
    "slug": "companycam",
    "logo": "https://zapier-images.imgix.net/storage/services/73e3326faaf3792851927ead8d65ad50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CompanyCam is an app built for contractors and trades professionals to capture and organize important job site information, including photos and documents. Connecting CompanyCam with other software and CRMs allows users to keep tabs on progress and improve their processes.",
    "category": "Images & Design",
    "title": "CompanyCam Integration With Cold Email Sales Tool",
    "h1": "Integrate CompanyCam with SalesBlink",
    "metaDescription": "Integrate CompanyCam and other Images & Design Tools with SalesBlink. Two Way CompanyCam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MemberSpace",
    "slug": "memberspace",
    "logo": "https://zapier-images.imgix.net/storage/developer/72118491e754a863994067d9ccf63b2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MemberSpace allows you to turn any part of your website into members-only with just a few clicks.",
    "category": "Payment Processing",
    "title": "MemberSpace Integration With Cold Email Sales Tool",
    "h1": "Integrate MemberSpace with SalesBlink",
    "metaDescription": "Integrate MemberSpace and other Payment Processing Tools with SalesBlink. Two Way MemberSpace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Azure DevOps",
    "slug": "azure-devops",
    "logo": "https://zapier-images.imgix.net/storage/developer/c0406ca422240916d98dc61fc5acb6dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Azure DevOps is where teams manage, develop, and deliver software in the cloud. Azure DevOps provides free, private Git project hosting for up to 5 users, project and test management tools, build and CI, and team collaboration. Connect using the development tools of your choice, including Visual Studio, Eclipse, Xcode, and more.",
    "category": "Developer Tools",
    "title": "Azure DevOps Integration With Cold Email Sales Tool",
    "h1": "Integrate Azure DevOps with SalesBlink",
    "metaDescription": "Integrate Azure DevOps and other Developer Tools Tools with SalesBlink. Two Way Azure DevOps Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Wrike",
    "slug": "wrike",
    "logo": "https://zapier-images.imgix.net/storage/developer/0e0b83eb6d754ef1c2c39c882282f69b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wrike is a flexible project management and collaboration platform that enables you to organize your projects, tasks, plans and discussions. It helps you sync your team’s efforts and track your work progress in real time.",
    "category": "Project Management",
    "title": "Wrike Integration With Cold Email Sales Tool",
    "h1": "Integrate Wrike with SalesBlink",
    "metaDescription": "Integrate Wrike and other Project Management Tools with SalesBlink. Two Way Wrike Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Google BigQuery",
    "slug": "google-bigquery",
    "logo": "https://zapier-images.imgix.net/storage/services/8d2fe52ddbf8b9441bfcad4a38deaab5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BigQuery is Google's serverless and highly scalable enterprise data warehouse, designed to make all your data analysts productive.",
    "category": "Databases",
    "title": "Google BigQuery Integration With Cold Email Sales Tool",
    "h1": "Integrate Google BigQuery with SalesBlink",
    "metaDescription": "Integrate Google BigQuery and other Databases Tools with SalesBlink. Two Way Google BigQuery Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Duda",
    "slug": "duda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ec2d47bdac764e9b85dd093b03cce7d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Duda is a website builder platform for web professionals and digital marketing agencies.",
    "category": "Website Builders",
    "title": "Duda Integration With Cold Email Sales Tool",
    "h1": "Integrate Duda with SalesBlink",
    "metaDescription": "Integrate Duda and other Website Builders Tools with SalesBlink. Two Way Duda Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Landbot",
    "slug": "landbot",
    "logo": "https://zapier-images.imgix.net/storage/developer/c310bcd6df1783f54947e944e8991be7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Landbot is a tool to create Conversational Experiences that live on a website. Build your own in minutes without coding and engage more.",
    "category": "Marketing Automation",
    "title": "Landbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Landbot with SalesBlink",
    "metaDescription": "Integrate Landbot and other Marketing Automation Tools with SalesBlink. Two Way Landbot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "OptinMonster",
    "slug": "optinmonster",
    "logo": "https://zapier-images.imgix.net/storage/services/98f143bd58dd05b591f18fa7862f560f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powerful lead generation software that converts abandoning visitors into subscribers with dynamic marketing tools and Exit Intent® technology.",
    "category": "Forms & Surveys",
    "title": "OptinMonster Integration With Cold Email Sales Tool",
    "h1": "Integrate OptinMonster with SalesBlink",
    "metaDescription": "Integrate OptinMonster and other Forms & Surveys Tools with SalesBlink. Two Way OptinMonster Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Delighted",
    "slug": "delighted",
    "logo": "https://zapier-images.imgix.net/storage/services/4ef7aa295b3803713d2e5456e0132856.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Delighted is the easiest and most beautiful way to measure customer happiness.",
    "category": "Forms & Surveys",
    "title": "Delighted Integration With Cold Email Sales Tool",
    "h1": "Integrate Delighted with SalesBlink",
    "metaDescription": "Integrate Delighted and other Forms & Surveys Tools with SalesBlink. Two Way Delighted Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Hotjar",
    "slug": "hotjar",
    "logo": "https://zapier-images.imgix.net/storage/services/517f022479163259d192181405b3cddc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Traditional web analytics tools help you analyze traffic data. But numbers alone can’t tell you what users really do on your site — Hotjar will.",
    "category": "Analytics",
    "title": "Hotjar Integration With Cold Email Sales Tool",
    "h1": "Integrate Hotjar with SalesBlink",
    "metaDescription": "Integrate Hotjar and other Analytics Tools with SalesBlink. Two Way Hotjar Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "GitLab",
    "slug": "gitlab",
    "logo": "https://zapier-images.imgix.net/storage/services/307fa4f7f37831684fe3e3ee33ab97d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GitLab is an open source code collaboration tool with a rich feature set including management of repositories, reviewing tools, issue tracking, activity feeds and much more.",
    "category": "Developer Tools",
    "title": "GitLab Integration With Cold Email Sales Tool",
    "h1": "Integrate GitLab with SalesBlink",
    "metaDescription": "Integrate GitLab and other Developer Tools Tools with SalesBlink. Two Way GitLab Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "WATI",
    "slug": "wati",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/58699a06bf521c71343a09cb2e764250_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WATI is an end-to-end customer communication platform on WhatsApp with Team Inbox, No Code Chatbot Builder, Broadcasts & more.",
    "category": "Communication",
    "title": "WATI Integration With Cold Email Sales Tool",
    "h1": "Integrate WATI with SalesBlink",
    "metaDescription": "Integrate WATI and other Communication Tools with SalesBlink. Two Way WATI Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Desk",
    "slug": "zoho-desk",
    "logo": "https://zapier-images.imgix.net/storage/developer/30ba0f708961c84e803b231c5648e4f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Desk is web-based customer service software designed to help you focus more on creating customer happiness every day.",
    "category": "Customer Support",
    "title": "Zoho Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Desk with SalesBlink",
    "metaDescription": "Integrate Zoho Desk and other Customer Support Tools with SalesBlink. Two Way Zoho Desk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Donorbox",
    "slug": "donorbox",
    "logo": "https://zapier-images.imgix.net/storage/developer/3200410ea097cc3fe899f3716ebcbc6c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Donorbox is a powerful fundraising platform powering 50000+ organizations, it is simple to setup and attracts more recurring donors.",
    "category": "Fundraising",
    "title": "Donorbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Donorbox with SalesBlink",
    "metaDescription": "Integrate Donorbox and other Fundraising Tools with SalesBlink. Two Way Donorbox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Razorpay",
    "slug": "razorpay-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/babb9f8f56fd01aef5f473c56ce6e801.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Razorpay is India’s first full-stack financial solutions company. We enable businesses to accept and disburse payments, raise capital and park money.",
    "category": "Payment Processing",
    "title": "Razorpay Integration With Cold Email Sales Tool",
    "h1": "Integrate Razorpay with SalesBlink",
    "metaDescription": "Integrate Razorpay and other Payment Processing Tools with SalesBlink. Two Way Razorpay Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CINC",
    "slug": "cinc",
    "logo": "https://zapier-images.imgix.net/storage/developer/a2eb7765cb2085abcc4dc59d193bd0e4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CINC is a CRM for top-producing real estate brokers and agents, providing an integrated buyer lead generation and management solution.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CINC Integration With Cold Email Sales Tool",
    "h1": "Integrate CINC with SalesBlink",
    "metaDescription": "Integrate CINC and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CINC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instantly",
    "slug": "instantly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c435b55eafedd653fe85a0490e0f9f68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instantly is an email automation platform that helps scale outreach campaigns.",
    "category": "Email",
    "title": "Instantly Integration With Cold Email Sales Tool",
    "h1": "Integrate Instantly with SalesBlink",
    "metaDescription": "Integrate Instantly and other Email Tools with SalesBlink. Two Way Instantly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "POWR Form Builder",
    "slug": "powr-form-builder",
    "logo": "https://zapier-images.imgix.net/storage/developer/67779093d53bb6e56ebe86f394f45c90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "POWR Form Builder is an easy way to collect information, grow your email list, and take payments online on all devices and platforms.",
    "category": "eCommerce",
    "title": "POWR Form Builder Integration With Cold Email Sales Tool",
    "h1": "Integrate POWR Form Builder with SalesBlink",
    "metaDescription": "Integrate POWR Form Builder and other eCommerce Tools with SalesBlink. Two Way POWR Form Builder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MOCO",
    "slug": "moco",
    "logo": "https://zapier-images.imgix.net/storage/developer/186586919399c92817ac6965b02e50cf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MOCO (MObile COmpany) is a lean cloud software made for small medium-sized agency and service businesses. Including time tracking, billing, resource planning, sales-funnel and CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MOCO Integration With Cold Email Sales Tool",
    "h1": "Integrate MOCO with SalesBlink",
    "metaDescription": "Integrate MOCO and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MOCO Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Sierra Interactive",
    "slug": "sierra-interactive",
    "logo": "https://zapier-images.imgix.net/storage/developer/7c1c10f273184785ebabaf63dec963af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sierra Interactive is an all-in-one real estate platform that helps you close more deals and grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sierra Interactive Integration With Cold Email Sales Tool",
    "h1": "Integrate Sierra Interactive with SalesBlink",
    "metaDescription": "Integrate Sierra Interactive and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sierra Interactive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AgencyZoom",
    "slug": "agencyzoom-ca67272",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62860a25c72ed9af304ce0804e68d5f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AgencyZoom is a sales and automation software specifically made for P&C insurance agents.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AgencyZoom Integration With Cold Email Sales Tool",
    "h1": "Integrate AgencyZoom with SalesBlink",
    "metaDescription": "Integrate AgencyZoom and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AgencyZoom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendy",
    "slug": "sendy",
    "logo": "https://zapier-images.imgix.net/storage/developer/242e928e4b24ea7d0823b105c3e2bcb0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendy is a self hosted email newsletter application that lets you send newsletters via Amazon Simple Email Service (SES).",
    "category": "Email Newsletters",
    "title": "Sendy Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendy with SalesBlink",
    "metaDescription": "Integrate Sendy and other Email Newsletters Tools with SalesBlink. Two Way Sendy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Kintone",
    "slug": "kintone",
    "logo": "https://zapier-images.imgix.net/storage/developer/73d920e3b8adb8e98a9d6835ebd3a1b4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "kintone is a fully integrated social collaboration and customizable business application cloud platform that allows internal and external teams to solve business process inefficiencies through database and work flow-driven custom apps, communication and collaboration. kintone can be used for custom CRM, Project Mangement, SCM and much more.",
    "category": "App Builder",
    "title": "Kintone Integration With Cold Email Sales Tool",
    "h1": "Integrate Kintone with SalesBlink",
    "metaDescription": "Integrate Kintone and other App Builder Tools with SalesBlink. Two Way Kintone Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Hotmart",
    "slug": "hotmart-7006",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c9e523bc85ac8faaf78749ade26ae90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hotmart is an All-in-One solution to take your online business global.",
    "category": "Online Courses",
    "title": "Hotmart Integration With Cold Email Sales Tool",
    "h1": "Integrate Hotmart with SalesBlink",
    "metaDescription": "Integrate Hotmart and other Online Courses Tools with SalesBlink. Two Way Hotmart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SafetyCulture",
    "slug": "safetyculture",
    "logo": "https://zapier-images.imgix.net/storage/services/6115589459edfea80f7a02ba03a1daae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SafetyCulture (formerly iAuditor) is a business platform to conduct inspections, capture issues, manage tasks, communicate clearly and train working teams.",
    "category": "Product Management",
    "title": "SafetyCulture Integration With Cold Email Sales Tool",
    "h1": "Integrate SafetyCulture with SalesBlink",
    "metaDescription": "Integrate SafetyCulture and other Product Management Tools with SalesBlink. Two Way SafetyCulture Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bexio",
    "slug": "bexio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6c98939a10836a16f0844f1eb15e6a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bexio is a business platform for offering accounting, invoices, payroll, and more for small companies in Switzerland.",
    "category": "Accounting",
    "title": "bexio Integration With Cold Email Sales Tool",
    "h1": "Integrate bexio with SalesBlink",
    "metaDescription": "Integrate bexio and other Accounting Tools with SalesBlink. Two Way bexio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DocSend",
    "slug": "docsend",
    "logo": "https://zapier-images.imgix.net/storage/services/461918a7367cf1c6833f005e98edbb53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocSend tells you how prospects engage with your sales material after you send it. Know when to follow up, who to follow up with, and what to focus on, enabling you to do business faster.",
    "category": "Ads & Conversion",
    "title": "DocSend Integration With Cold Email Sales Tool",
    "h1": "Integrate DocSend with SalesBlink",
    "metaDescription": "Integrate DocSend and other Ads & Conversion Tools with SalesBlink. Two Way DocSend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BigCommerce",
    "slug": "bigcommerce",
    "logo": "https://zapier-images.imgix.net/storage/developer/b3c44e0b8a68d87d8bf98e467d05b044.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BigCommerce is an e-commerce platform that provides an all-encompassing solution for a business' online store. If you're trying to sell physical goods online, you'll want to check out Bigcommerce.",
    "category": "eCommerce",
    "title": "BigCommerce Integration With Cold Email Sales Tool",
    "h1": "Integrate BigCommerce with SalesBlink",
    "metaDescription": "Integrate BigCommerce and other eCommerce Tools with SalesBlink. Two Way BigCommerce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "JustCall",
    "slug": "justcall",
    "logo": "https://zapier-images.imgix.net/storage/services/ce248bc0134747c73a505cad40e17416.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JustCall helps sales and support teams to connect easily with their leads & customers. We provide a cloud based service that allows you to make calls and send text messages.",
    "category": "Phone & SMS",
    "title": "JustCall Integration With Cold Email Sales Tool",
    "h1": "Integrate JustCall with SalesBlink",
    "metaDescription": "Integrate JustCall and other Phone & SMS Tools with SalesBlink. Two Way JustCall Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Proof",
    "slug": "proof",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e75a27def5a5bd616e3148abf13efc5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Proof is a marketing platform that displays recent signups, purchases, and other activity on your website.",
    "category": "Marketing Automation",
    "title": "Proof Integration With Cold Email Sales Tool",
    "h1": "Integrate Proof with SalesBlink",
    "metaDescription": "Integrate Proof and other Marketing Automation Tools with SalesBlink. Two Way Proof Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "pdfFiller",
    "slug": "pdffiller",
    "logo": "https://zapier-images.imgix.net/storage/services/7f0a9332ba48c69e924b8680447f9260.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "pdfFiller is an online PDF editor, form builder and eSignature solution that makes it fast, easy and secure to manage your documents on any computer or mobile device.",
    "category": "Documents",
    "title": "pdfFiller Integration With Cold Email Sales Tool",
    "h1": "Integrate pdfFiller with SalesBlink",
    "metaDescription": "Integrate pdfFiller and other Documents Tools with SalesBlink. Two Way pdfFiller Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "LionDesk",
    "slug": "liondesk-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/2b64f8810fd63410426ceee1f5fa3277_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LionDesk is the hub for Sales Professionals to help manage clients, transactions, automate marketing and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LionDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate LionDesk with SalesBlink",
    "metaDescription": "Integrate LionDesk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LionDesk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Mautic",
    "slug": "mautic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f5f2c99ed2bdc8620592f3eb891560a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mautic is a open source marketing automation software which helps marketers to communicate with their audience, giving a personalised message at the right time.",
    "category": "Marketing Automation",
    "title": "Mautic Integration With Cold Email Sales Tool",
    "h1": "Integrate Mautic with SalesBlink",
    "metaDescription": "Integrate Mautic and other Marketing Automation Tools with SalesBlink. Two Way Mautic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Quick Base",
    "slug": "quickbase",
    "logo": "https://zapier-images.imgix.net/storage/services/3d348ba36ddb1a656e434ccbb984e3c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quick Base is a tool to help you turn ideas about better ways to work into apps that make your team more efficient, informed and productive.",
    "category": "Databases",
    "title": "Quick Base Integration With Cold Email Sales Tool",
    "h1": "Integrate Quick Base with SalesBlink",
    "metaDescription": "Integrate Quick Base and other Databases Tools with SalesBlink. Two Way Quick Base Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Outgrow",
    "slug": "outgrow",
    "logo": "https://zapier-images.imgix.net/storage/developer/2f48188e1a0cb878939d3c46634ac67d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outgrow allows you to easily build interactive content like quizzes, calculators, polls, surveys/forms, assessments, chatbots, contests.",
    "category": "Forms & Surveys",
    "title": "Outgrow Integration With Cold Email Sales Tool",
    "h1": "Integrate Outgrow with SalesBlink",
    "metaDescription": "Integrate Outgrow and other Forms & Surveys Tools with SalesBlink. Two Way Outgrow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Real Geeks",
    "slug": "real-geeks",
    "logo": "https://zapier-images.imgix.net/storage/developer/941eb0828a5197be22b2c0eabb0100c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real Geeks is a real estate sales and marketing solution including all the tools you need  - Lead Generation, Cultivation, IDX Website, CRM and marketing services.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Real Geeks Integration With Cold Email Sales Tool",
    "h1": "Integrate Real Geeks with SalesBlink",
    "metaDescription": "Integrate Real Geeks and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Real Geeks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ConnectWise Manage",
    "slug": "connectwise-manage",
    "logo": "https://zapier-images.imgix.net/storage/developer/eeb998d8051056e0b4a02f530c656ea8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConnectWise Manage is a business management platform designed to successfully run and grow your technology business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ConnectWise Manage Integration With Cold Email Sales Tool",
    "h1": "Integrate ConnectWise Manage with SalesBlink",
    "metaDescription": "Integrate ConnectWise Manage and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ConnectWise Manage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimplyBook.me",
    "slug": "simplybook",
    "logo": "https://zapier-images.imgix.net/storage/services/943bde599533468a82e32ed87e0d79bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimplyBook.me is online appointment and scheduling software, that allows to accept bookings 24/7 and offers variety of flexible features.",
    "category": "Scheduling & Booking",
    "title": "SimplyBook.me Integration With Cold Email Sales Tool",
    "h1": "Integrate SimplyBook.me with SalesBlink",
    "metaDescription": "Integrate SimplyBook.me and other Scheduling & Booking Tools with SalesBlink. Two Way SimplyBook.me Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Holded",
    "slug": "holded",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/817fc6a74039699a082b1bd0c98df4cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The cloud-based tool that has everything you need to manage your company — wherever you are, whenever you need.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Holded Integration With Cold Email Sales Tool",
    "h1": "Integrate Holded with SalesBlink",
    "metaDescription": "Integrate Holded and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Holded Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Setmore Appointments",
    "slug": "setmore-appointment",
    "logo": "https://zapier-images.imgix.net/storage/services/9cf2c5d850cadebe51bdbd335cda8d14.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Setmore helps small businesses book appointments online and grow their business every day.",
    "category": "Scheduling & Booking",
    "title": "Setmore Appointments Integration With Cold Email Sales Tool",
    "h1": "Integrate Setmore Appointments with SalesBlink",
    "metaDescription": "Integrate Setmore Appointments and other Scheduling & Booking Tools with SalesBlink. Two Way Setmore Appointments Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshsales Classic",
    "slug": "freshsales-classic",
    "logo": "https://zapier-images.imgix.net/storage/services/f5564d4517e796ae2f1868d5515707ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshsales Classic is an online, cloud-based CRM with integrated phone and email, user behavior tracking, lead scoring and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Freshsales Classic Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshsales Classic with SalesBlink",
    "metaDescription": "Integrate Freshsales Classic and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Freshsales Classic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slybroadcast",
    "slug": "slybroadcast",
    "logo": "https://zapier-images.imgix.net/storage/developer/fcb6fe5e93805b5cf846bcb2972fe582.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Slybroadcast is an application that allows you to send a voice message directly to the voice-mailboxes of thousands of phones, within minutes.",
    "category": "Phone & SMS",
    "title": "Slybroadcast Integration With Cold Email Sales Tool",
    "h1": "Integrate Slybroadcast with SalesBlink",
    "metaDescription": "Integrate Slybroadcast and other Phone & SMS Tools with SalesBlink. Two Way Slybroadcast Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EmailOctopus",
    "slug": "emailoctopus",
    "logo": "https://zapier-images.imgix.net/storage/services/a66a92675ce76650996892460222486e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A cheaper way to send your email marketing campaigns.",
    "category": "Email Newsletters",
    "title": "EmailOctopus Integration With Cold Email Sales Tool",
    "h1": "Integrate EmailOctopus with SalesBlink",
    "metaDescription": "Integrate EmailOctopus and other Email Newsletters Tools with SalesBlink. Two Way EmailOctopus Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Akiflow",
    "slug": "akiflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b0fd16ebdf7435711b0a60eb0f20c46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Akiflow is an app that allows you to collect tasks from multiple sources and plan them.",
    "category": "Productivity",
    "title": "Akiflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Akiflow with SalesBlink",
    "metaDescription": "Integrate Akiflow and other Productivity Tools with SalesBlink. Two Way Akiflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesmsg",
    "slug": "salesmsg",
    "logo": "https://zapier-images.imgix.net/storage/services/0c07ed9998bba113836597ba8aa10b29.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesmsg is a two-way text messaging software to allows you to send and receive text messages online from real local phone numbers.",
    "category": "Phone & SMS",
    "title": "Salesmsg Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesmsg with SalesBlink",
    "metaDescription": "Integrate Salesmsg and other Phone & SMS Tools with SalesBlink. Two Way Salesmsg Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "NiceJob",
    "slug": "nicejob",
    "logo": "https://zapier-images.imgix.net/storage/developer/5d5884db81b4cd12f0d45910a351ce19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NiceJob is a platform that helps you get more customer reviews and build a great reputation online.",
    "category": "Marketing Automation",
    "title": "NiceJob Integration With Cold Email Sales Tool",
    "h1": "Integrate NiceJob with SalesBlink",
    "metaDescription": "Integrate NiceJob and other Marketing Automation Tools with SalesBlink. Two Way NiceJob Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WhatConverts",
    "slug": "whatconverts",
    "logo": "https://zapier-images.imgix.net/storage/services/ca8763a76b92c013b3348e2e949ffe06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WhatConverts tracks what marketing channels generate conversions from phone calls, web forms, chat, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "WhatConverts Integration With Cold Email Sales Tool",
    "h1": "Integrate WhatConverts with SalesBlink",
    "metaDescription": "Integrate WhatConverts and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way WhatConverts Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Harvest",
    "slug": "harvest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68a58cace89cf0a07ebfe7ae964c2b80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple time tracking software and powerful reporting that helps your team thrive.",
    "category": "Time Tracking Software",
    "title": "Harvest Integration With Cold Email Sales Tool",
    "h1": "Integrate Harvest with SalesBlink",
    "metaDescription": "Integrate Harvest and other Time Tracking Software Tools with SalesBlink. Two Way Harvest Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SendPulse",
    "slug": "sendpulse",
    "logo": "https://zapier-images.imgix.net/storage/developer/10541fd81f996da9beb328b7b4db270e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendPulse is an Integrated messaging platform providing user communication on all devices with Emails, SMS, Web Push, SMTP and more.",
    "category": "Email Newsletters",
    "title": "SendPulse Integration With Cold Email Sales Tool",
    "h1": "Integrate SendPulse with SalesBlink",
    "metaDescription": "Integrate SendPulse and other Email Newsletters Tools with SalesBlink. Two Way SendPulse Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Big Cartel",
    "slug": "bigcartel",
    "logo": "https://zapier-images.imgix.net/storage/developer/4d7fdea2a52680c7aecbfb94d9cb687b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We make simple tools to help creative people build unique online stores.",
    "category": "eCommerce",
    "title": "Big Cartel Integration With Cold Email Sales Tool",
    "h1": "Integrate Big Cartel with SalesBlink",
    "metaDescription": "Integrate Big Cartel and other eCommerce Tools with SalesBlink. Two Way Big Cartel Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PDFMonkey",
    "slug": "pdfmonkey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee227833dd7983b2b4c27f5332f75e3c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PDFMonkey is an app that automates PDF generation, provides a dashboard to manage templates, and provides a simple API to generate documents.",
    "category": "Documents",
    "title": "PDFMonkey Integration With Cold Email Sales Tool",
    "h1": "Integrate PDFMonkey with SalesBlink",
    "metaDescription": "Integrate PDFMonkey and other Documents Tools with SalesBlink. Two Way PDFMonkey Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "eSignatures.io",
    "slug": "esignaturesio",
    "logo": "https://zapier-images.imgix.net/storage/developer/e84a96202f29ae4b06d92caaadae75d3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ESignatures.io is a tool used for signing mobile friendly contracts over the web.",
    "category": "Signatures",
    "title": "eSignatures.io Integration With Cold Email Sales Tool",
    "h1": "Integrate eSignatures.io with SalesBlink",
    "metaDescription": "Integrate eSignatures.io and other Signatures Tools with SalesBlink. Two Way eSignatures.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Poptin",
    "slug": "poptin",
    "logo": "https://zapier-images.imgix.net/storage/developer/a8ff8b58b2821e45729bcbf38ebfd768.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Poptin is a free lead capture platform that lets you create engaging web and mobile popups and improve your website's conversion rate.",
    "category": "Ads & Conversion",
    "title": "Poptin Integration With Cold Email Sales Tool",
    "h1": "Integrate Poptin with SalesBlink",
    "metaDescription": "Integrate Poptin and other Ads & Conversion Tools with SalesBlink. Two Way Poptin Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Uscreen",
    "slug": "uscreen",
    "logo": "https://zapier-images.imgix.net/storage/developer/ca1e2833093b9bb3d8a701f96fd6dea2_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uscreen is a fully hosted video platform enabling you to sell and distribute your videos online.",
    "category": "Video & Audio",
    "title": "Uscreen Integration With Cold Email Sales Tool",
    "h1": "Integrate Uscreen with SalesBlink",
    "metaDescription": "Integrate Uscreen and other Video & Audio Tools with SalesBlink. Two Way Uscreen Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Loomly",
    "slug": "loomly",
    "logo": "https://zapier-images.imgix.net/storage/services/255f8fdc405aa5edd6914cfd6a7ae300.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loomly is the Brand Success Platform that empowers marketing teams to grow successful brands online through collaboration, publishing & analytics features.",
    "category": "Social Media Marketing",
    "title": "Loomly Integration With Cold Email Sales Tool",
    "h1": "Integrate Loomly with SalesBlink",
    "metaDescription": "Integrate Loomly and other Social Media Marketing Tools with SalesBlink. Two Way Loomly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RescueTime",
    "slug": "rescuetime",
    "logo": "https://zapier-images.imgix.net/storage/developer/43e07e70e6839dc447c9ad2bd5971630.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RescueTime helps you understand how you spend your time on the computer by automatically keeping a log of the time you spend on different applications and websites. Having an accurate idea of how you time is spent makes it easy to stay productive and balanced.",
    "category": "Time Tracking Software",
    "title": "RescueTime Integration With Cold Email Sales Tool",
    "h1": "Integrate RescueTime with SalesBlink",
    "metaDescription": "Integrate RescueTime and other Time Tracking Software Tools with SalesBlink. Two Way RescueTime Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Reply",
    "slug": "reply",
    "logo": "https://zapier-images.imgix.net/storage/developer/430150a468bed6904374de7f9ea2a0c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reply is a sales automation platform that puts outreach on autopilot, while still making each communication personal.",
    "category": "Marketing Automation",
    "title": "Reply Integration With Cold Email Sales Tool",
    "h1": "Integrate Reply with SalesBlink",
    "metaDescription": "Integrate Reply and other Marketing Automation Tools with SalesBlink. Two Way Reply Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Jira Software Server",
    "slug": "jira-software-server",
    "logo": "https://zapier-images.imgix.net/storage/developer/ea4a5ee38c7a088e243745dd0b851784.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jira is a bug and issue tracking tool that allows software developers to manage product development and build better software. This integration connects to self-hosted instances of Jira.",
    "category": "Project Management",
    "title": "Jira Software Server Integration With Cold Email Sales Tool",
    "h1": "Integrate Jira Software Server with SalesBlink",
    "metaDescription": "Integrate Jira Software Server and other Project Management Tools with SalesBlink. Two Way Jira Software Server Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mixpanel",
    "slug": "mixpanel",
    "logo": "https://zapier-images.imgix.net/storage/services/785f1b175e2861a0b71ea6f63281142e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mixpanel provides product analytics for understanding user behavior in apps & websites. Build better products faster with instant insights.",
    "category": "Ads & Conversion",
    "title": "Mixpanel Integration With Cold Email Sales Tool",
    "h1": "Integrate Mixpanel with SalesBlink",
    "metaDescription": "Integrate Mixpanel and other Ads & Conversion Tools with SalesBlink. Two Way Mixpanel Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Heyflow",
    "slug": "heyflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/697414f02f1b9f05cfe543e3d558981d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Heyflow is a no-code app to build, design, and integrate workflows people love engaging with.",
    "category": "Website & App Building",
    "title": "Heyflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Heyflow with SalesBlink",
    "metaDescription": "Integrate Heyflow and other Website & App Building Tools with SalesBlink. Two Way Heyflow Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pushbullet",
    "slug": "pushbullet",
    "logo": "https://zapier-images.imgix.net/storage/developer/d289b95436d2e5d9d4623741c0a818b4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pushbullet makes it easy to push notifications to your smartphone and computer from the web. These notifications are smart too: if you receive a notification with a link, tapping on the notification will take you right to it!",
    "category": "Notifications",
    "title": "Pushbullet Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushbullet with SalesBlink",
    "metaDescription": "Integrate Pushbullet and other Notifications Tools with SalesBlink. Two Way Pushbullet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ServiceTitan",
    "slug": "servicetitan",
    "logo": "https://zapier-images.imgix.net/storage/services/eeb79a5f267706f656d35ba4d1144bb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceTitan is a mobile, cloud-based software platform that helps home services companies streamline operations, improve customer service, and increase sales.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ServiceTitan Integration With Cold Email Sales Tool",
    "h1": "Integrate ServiceTitan with SalesBlink",
    "metaDescription": "Integrate ServiceTitan and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ServiceTitan Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ActiveCampaign Postmark",
    "slug": "postmark",
    "logo": "https://zapier-images.imgix.net/storage/services/e1cd4dba89396528988daf12ba63d35d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Postmark is specifically built to deliver your app's emails to the inbox, fast. Instantly send and receive emails with zero maintenance. Our infrastructure combined with proper content and delivery practices means higher response rates from your customers.",
    "category": "Transactional Email",
    "title": "ActiveCampaign Postmark Integration With Cold Email Sales Tool",
    "h1": "Integrate ActiveCampaign Postmark with SalesBlink",
    "metaDescription": "Integrate ActiveCampaign Postmark and other Transactional Email Tools with SalesBlink. Two Way ActiveCampaign Postmark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docparser",
    "slug": "docparser",
    "logo": "https://zapier-images.imgix.net/storage/developer/ca439d506eb20f596d9bf205e51ba673.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docparser converts your PDF documents into structured and easy-to-handle data. With Docparser you can pull out specific data fields (e.g. Purchase Order Number, Date, Shipping Address, ...) and tabular data from your documents.",
    "category": "Documents",
    "title": "Docparser Integration With Cold Email Sales Tool",
    "h1": "Integrate Docparser with SalesBlink",
    "metaDescription": "Integrate Docparser and other Documents Tools with SalesBlink. Two Way Docparser Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Recruitee",
    "slug": "recruitee",
    "logo": "https://zapier-images.imgix.net/storage/services/ee43b32a54941d1fbff568dbc94e476c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recruitee is an all-in-one hiring platform for teams of all sizes, that includes employer branding, job promoting, talent sourcing, and applicant tracking.",
    "category": "HR Talent & Recruitment",
    "title": "Recruitee Integration With Cold Email Sales Tool",
    "h1": "Integrate Recruitee with SalesBlink",
    "metaDescription": "Integrate Recruitee and other HR Talent & Recruitment Tools with SalesBlink. Two Way Recruitee Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "AccuLynx",
    "slug": "acculynx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f313ad34dd58d8bd7ba5a07833b964c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccuLynx is a cloud based roofing software application designed to help contractors manage and grow their business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AccuLynx Integration With Cold Email Sales Tool",
    "h1": "Integrate AccuLynx with SalesBlink",
    "metaDescription": "Integrate AccuLynx and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AccuLynx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bloomerang",
    "slug": "bloomerang",
    "logo": "https://zapier-images.imgix.net/storage/services/a5834b0d197469376890267e16211f51.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bloomerang is a simple, cloud-based donor database solution that pools together the latest in best practices for donor engagement and retention.",
    "category": "Fundraising",
    "title": "Bloomerang Integration With Cold Email Sales Tool",
    "h1": "Integrate Bloomerang with SalesBlink",
    "metaDescription": "Integrate Bloomerang and other Fundraising Tools with SalesBlink. Two Way Bloomerang Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Luxury Presence",
    "slug": "luxury-presence",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d7bb4b9fc60df72b5cf230e8262e255.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Luxury Presence is a software company designed for real estate agents.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Luxury Presence Integration With Cold Email Sales Tool",
    "h1": "Integrate Luxury Presence with SalesBlink",
    "metaDescription": "Integrate Luxury Presence and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Luxury Presence Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Daylite",
    "slug": "daylite",
    "logo": "https://zapier-images.imgix.net/storage/developer/7535d065cbaa9c8357e88c0c096c9ad2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Daylite is a CRM for macOS & iOS that helps you and your team manage more clients, close more deals, and finish more projects. It works with many of the built-in Apple features you know and love like Apple Mail, Contacts, Calendar, Siri, and more!",
    "category": "CRM (Customer Relationship Management)",
    "title": "Daylite Integration With Cold Email Sales Tool",
    "h1": "Integrate Daylite with SalesBlink",
    "metaDescription": "Integrate Daylite and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Daylite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BombBomb.com",
    "slug": "bombbombcom",
    "logo": "https://zapier-images.imgix.net/storage/developer/8cce373674c5a5b5ac71bb9d19bcf9d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate with the BombBomb email marketing service.",
    "category": "Drip Emails",
    "title": "BombBomb.com Integration With Cold Email Sales Tool",
    "h1": "Integrate BombBomb.com with SalesBlink",
    "metaDescription": "Integrate BombBomb.com and other Drip Emails Tools with SalesBlink. Two Way BombBomb.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tilda Publishing",
    "slug": "tilda-publishing",
    "logo": "https://zapier-images.imgix.net/storage/developer/d1eb26496bf15b603ed0c732dbbad5f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tilda is a website builder that lets you create websites, online stores, and blogs without any code. Add data capturing forms on a website and gather leads to any service.",
    "category": "Website Builders",
    "title": "Tilda Publishing Integration With Cold Email Sales Tool",
    "h1": "Integrate Tilda Publishing with SalesBlink",
    "metaDescription": "Integrate Tilda Publishing and other Website Builders Tools with SalesBlink. Two Way Tilda Publishing Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Burst SMS",
    "slug": "burst-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer/760f0f1b579352f72553f3993bdbefef_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Burst SMS is an online text messaging application. Use our API to integrate text message alerts into your work flows. Great for CRM automation systems, notifications and authentication.",
    "category": "Phone & SMS",
    "title": "Burst SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Burst SMS with SalesBlink",
    "metaDescription": "Integrate Burst SMS and other Phone & SMS Tools with SalesBlink. Two Way Burst SMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Dovetail",
    "slug": "dovetail",
    "logo": "https://zapier-images.imgix.net/storage/services/5735da56f0057db9e43da783bb7b36e3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dovetail lets you organize user research data, analyze it together, and share insights with your team. Understand your customers with Dovetail and build better things.",
    "category": "Project Management",
    "title": "Dovetail Integration With Cold Email Sales Tool",
    "h1": "Integrate Dovetail with SalesBlink",
    "metaDescription": "Integrate Dovetail and other Project Management Tools with SalesBlink. Two Way Dovetail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Creator",
    "slug": "zoho-creator",
    "logo": "https://zapier-images.imgix.net/storage/developer/96eb2c546433d211438cd6b73db23296_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Creator is an online database builder. Its easy drag-n-drop interface lets you create custom applications for every data collection need, define workflow and also configure business rules that are unique to your business.",
    "category": "App Builder",
    "title": "Zoho Creator Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Creator with SalesBlink",
    "metaDescription": "Integrate Zoho Creator and other App Builder Tools with SalesBlink. Two Way Zoho Creator Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Mem",
    "slug": "mem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f12b15819f0acb81ae0d98733216d19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mem is a tool to capture, share, and access information from anywhere.",
    "category": "AI Tools",
    "title": "Mem Integration With Cold Email Sales Tool",
    "h1": "Integrate Mem with SalesBlink",
    "metaDescription": "Integrate Mem and other AI Tools Tools with SalesBlink. Two Way Mem Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Lawmatics",
    "slug": "lawmatics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f4ce6de9f773591ddffe30af2d2dd7e3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lawmatics is the #1 Intake and Marketing Automation platform for the modern Law Firm.",
    "category": "Marketing Automation",
    "title": "Lawmatics Integration With Cold Email Sales Tool",
    "h1": "Integrate Lawmatics with SalesBlink",
    "metaDescription": "Integrate Lawmatics and other Marketing Automation Tools with SalesBlink. Two Way Lawmatics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Memberstack",
    "slug": "memberstack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85e62ab343ca4edaa7e8a033d9de8ca5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple Auth & Payments for Webflow Developers.",
    "category": "Developer Tools",
    "title": "Memberstack Integration With Cold Email Sales Tool",
    "h1": "Integrate Memberstack with SalesBlink",
    "metaDescription": "Integrate Memberstack and other Developer Tools Tools with SalesBlink. Two Way Memberstack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Microsoft Exchange",
    "slug": "microsoft-exchange",
    "logo": "https://zapier-images.imgix.net/storage/developer/09717af1fbd8a62b7240efc8ab6a9667.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft Exchange Server is a mail server and calendaring server developed by Microsoft.",
    "category": "Email",
    "title": "Microsoft Exchange Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Exchange with SalesBlink",
    "metaDescription": "Integrate Microsoft Exchange and other Email Tools with SalesBlink. Two Way Microsoft Exchange Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ScoreApp",
    "slug": "scoreapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4887a53be0ffa0d2105944c84779765d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ScoreApp is a quiz builder and lead generation tool for small businesses.",
    "category": "Marketing",
    "title": "ScoreApp Integration With Cold Email Sales Tool",
    "h1": "Integrate ScoreApp with SalesBlink",
    "metaDescription": "Integrate ScoreApp and other Marketing Tools with SalesBlink. Two Way ScoreApp Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Facebook Conversions (for Business admins)",
    "slug": "facebook-conversions-business",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e384c792ed7db69ad442b2130fbbd4f5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook’s Conversions lets you send information about important customer events to Facebook Server-Side API directly from your website’s server, CRM or other customer information databases. This information is useful to create powerful ads that yield better conversions.",
    "category": "Ads & Conversion",
    "title": "Facebook Conversions (for Business admins) Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Conversions (for Business admins) with SalesBlink",
    "metaDescription": "Integrate Facebook Conversions (for Business admins) and other Ads & Conversion Tools with SalesBlink. Two Way Facebook Conversions (for Business admins) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Invoice",
    "slug": "zoho-invoice",
    "logo": "https://zapier-images.imgix.net/storage/developer/f2fdca9e47b5b2c764c4cbc57d3f237b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Invoice is an easy invoice software meant for small businesses and freelancers. Accept payments online, automate payment reminders and recurring billing.",
    "category": "Proposal & Invoice Management",
    "title": "Zoho Invoice Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Invoice with SalesBlink",
    "metaDescription": "Integrate Zoho Invoice and other Proposal & Invoice Management Tools with SalesBlink. Two Way Zoho Invoice Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Thrive Themes",
    "slug": "thrive-themes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f9008c85db492adf3902a91fee7ce2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thrive Themes is a company that provides marketing focused themes and plugins for building a conversion focused website on WordPress",
    "category": "Website Builders",
    "title": "Thrive Themes Integration With Cold Email Sales Tool",
    "h1": "Integrate Thrive Themes with SalesBlink",
    "metaDescription": "Integrate Thrive Themes and other Website Builders Tools with SalesBlink. Two Way Thrive Themes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "involve.me",
    "slug": "involveme",
    "logo": "https://zapier-images.imgix.net/storage/developer/5e234247eeb4f0e3139e4955bf0550d8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "involve.me is a customer experience platform. Everything you need to capture leads, collect feedback & activate your audience.",
    "category": "Forms & Surveys",
    "title": "involve.me Integration With Cold Email Sales Tool",
    "h1": "Integrate involve.me with SalesBlink",
    "metaDescription": "Integrate involve.me and other Forms & Surveys Tools with SalesBlink. Two Way involve.me Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "TeamUp",
    "slug": "teamup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8284195bfd495bae739a80088f6f57df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamUp manages fitness businesses around the world. This integration is for businesses only.",
    "category": "Fitness",
    "title": "TeamUp Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamUp with SalesBlink",
    "metaDescription": "Integrate TeamUp and other Fitness Tools with SalesBlink. Two Way TeamUp Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "FareHarbor",
    "slug": "fareharbor",
    "logo": "https://zapier-images.imgix.net/storage/services/feadf51255511d7e156f8fb4d5b348ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FareHarbor is the leading global online reservation platform for the tour and activity industry. FareHarbor empowers tour operators to better serve their customers while increasing online bookings and revenue.",
    "category": "Scheduling & Booking",
    "title": "FareHarbor Integration With Cold Email Sales Tool",
    "h1": "Integrate FareHarbor with SalesBlink",
    "metaDescription": "Integrate FareHarbor and other Scheduling & Booking Tools with SalesBlink. Two Way FareHarbor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "HTML/CSS to Image",
    "slug": "htmlcss-to-image",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ea28f8e2d0e12b63c98a16fe3e09054.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HTML/CSS to Image is a tool for converting code into an image (jpg, png or webp). Images render exactly like they do in Google Chrome.",
    "category": "Developer Tools",
    "title": "HTML/CSS to Image Integration With Cold Email Sales Tool",
    "h1": "Integrate HTML/CSS to Image with SalesBlink",
    "metaDescription": "Integrate HTML/CSS to Image and other Developer Tools Tools with SalesBlink. Two Way HTML/CSS to Image Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clearbit",
    "slug": "clearbit",
    "logo": "https://zapier-images.imgix.net/storage/developer/7e5711e45d33a3c05e63f04bfcd0280a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clearbit is a data API that lets you enrich your person and company records with social, demographic, and firmographic data.",
    "category": "Contact Management",
    "title": "Clearbit Integration With Cold Email Sales Tool",
    "h1": "Integrate Clearbit with SalesBlink",
    "metaDescription": "Integrate Clearbit and other Contact Management Tools with SalesBlink. Two Way Clearbit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Salesmate",
    "slug": "salesmate",
    "logo": "https://zapier-images.imgix.net/storage/developer/030da36d7d5b50090a16a61635c6f270.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesmate is an All-in-one Sales Management, Automation, and Acceleration Software for Teams of All Sizes & Shapes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salesmate Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesmate with SalesBlink",
    "metaDescription": "Integrate Salesmate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salesmate Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SOLAPI",
    "slug": "solapi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e241ebce96c9caed00deac1c881f14d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SOLAPI is a Korean version of Twilio providing services for SMS and local messenger like Kakao Talk.",
    "category": "Phone & SMS",
    "title": "SOLAPI Integration With Cold Email Sales Tool",
    "h1": "Integrate SOLAPI with SalesBlink",
    "metaDescription": "Integrate SOLAPI and other Phone & SMS Tools with SalesBlink. Two Way SOLAPI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GoCanvas",
    "slug": "gocanvas",
    "logo": "https://zapier-images.imgix.net/storage/developer/d595811ca753b65b61d862ef9e309d0c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoCanvas provides a simple, drag and drop tool for creating your own mobile forms (called GoCanvas Apps) that can be filled out by your employees on their mobile devices. Customize your own mobile inspections, work orders, time cards, invoices, waivers and much more. Instantly transmit your data to customers and colleagues to keep your business moving at the speed of digital.",
    "category": "Forms & Surveys",
    "title": "GoCanvas Integration With Cold Email Sales Tool",
    "h1": "Integrate GoCanvas with SalesBlink",
    "metaDescription": "Integrate GoCanvas and other Forms & Surveys Tools with SalesBlink. Two Way GoCanvas Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Moneybird",
    "slug": "moneybird",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/183267e75303f3334fb5f86afe35e88b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moneybird provides cloud accounting for SME's in Holland.",
    "category": "Accounting",
    "title": "Moneybird Integration With Cold Email Sales Tool",
    "h1": "Integrate Moneybird with SalesBlink",
    "metaDescription": "Integrate Moneybird and other Accounting Tools with SalesBlink. Two Way Moneybird Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Nutshell",
    "slug": "nutshell-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/96c0a2ff1ca63cb1cbf38ce6c21f44a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nutshell is an affordable, easy-to-use CRM that helps small-business sales teams win more deals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Nutshell Integration With Cold Email Sales Tool",
    "h1": "Integrate Nutshell with SalesBlink",
    "metaDescription": "Integrate Nutshell and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Nutshell Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "REsimpli 3.0",
    "slug": "resimpli-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a6e595a5800b5361c777b67e7e7a727_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "REsimpli is simple real estate investment software that requires NO customization. It empowers you with real-time data to help you make better decisions which means higher net profit.",
    "category": "CRM (Customer Relationship Management)",
    "title": "REsimpli 3.0 Integration With Cold Email Sales Tool",
    "h1": "Integrate REsimpli 3.0 with SalesBlink",
    "metaDescription": "Integrate REsimpli 3.0 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way REsimpli 3.0 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sumo",
    "slug": "sumo",
    "logo": "https://zapier-images.imgix.net/storage/developer/7aa39763154c074909d6d924587be046.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tools to grow Your website’s traffic, including email list builders, sharing buttons, and heat maps. Sumo works with any website.",
    "category": "Ads & Conversion",
    "title": "Sumo Integration With Cold Email Sales Tool",
    "h1": "Integrate Sumo with SalesBlink",
    "metaDescription": "Integrate Sumo and other Ads & Conversion Tools with SalesBlink. Two Way Sumo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magento 2.X",
    "slug": "magento-v2",
    "logo": "https://zapier-images.imgix.net/storage/services/f01c4b266815314b2fc02299a779a2e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magento 2.X is the premier open source e-commerce app used by millions of customers each and every day.",
    "category": "eCommerce",
    "title": "Magento 2.X Integration With Cold Email Sales Tool",
    "h1": "Integrate Magento 2.X with SalesBlink",
    "metaDescription": "Integrate Magento 2.X and other eCommerce Tools with SalesBlink. Two Way Magento 2.X Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planning Center",
    "slug": "planning-center",
    "logo": "https://zapier-images.imgix.net/storage/services/ab0d4af785ee264bd5ac148651c12043.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planning Center is a church management system that helps manage people, services, donations, registrations, and more.",
    "category": "Fundraising",
    "title": "Planning Center Integration With Cold Email Sales Tool",
    "h1": "Integrate Planning Center with SalesBlink",
    "metaDescription": "Integrate Planning Center and other Fundraising Tools with SalesBlink. Two Way Planning Center Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Searchie",
    "slug": "searchie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c26ff8b4ed265165b7e07cd5e91b211.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Searchie transforms your video and audio content into a powerful search experience.",
    "category": "Video & Audio",
    "title": "Searchie Integration With Cold Email Sales Tool",
    "h1": "Integrate Searchie with SalesBlink",
    "metaDescription": "Integrate Searchie and other Video & Audio Tools with SalesBlink. Two Way Searchie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Popl",
    "slug": "popl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20ea6e20524c4d886531d805900cf542.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Popl is a digital business card platform that allows you to share contact info and capture leads.",
    "category": "Contact Management",
    "title": "Popl Integration With Cold Email Sales Tool",
    "h1": "Integrate Popl with SalesBlink",
    "metaDescription": "Integrate Popl and other Contact Management Tools with SalesBlink. Two Way Popl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Better Uptime",
    "slug": "better-uptime-ca87275",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8186bc999f123fb03c63852f9429460.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Better Uptime is a tool to provide reliable uptime monitoring with voice calls and beautiful on-call scheduling. Configure everything in just 3 minutes, free plan included!",
    "category": "Server Monitoring",
    "title": "Better Uptime Integration With Cold Email Sales Tool",
    "h1": "Integrate Better Uptime with SalesBlink",
    "metaDescription": "Integrate Better Uptime and other Server Monitoring Tools with SalesBlink. Two Way Better Uptime Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Dealfront",
    "slug": "dealfront",
    "logo": "https://zapier-images.imgix.net/storage/services/cd07877d32173c72c07579faa14a8a1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect Zapier with Dealfront, the go-to-market platform for Europe that gives businesses everything they need to win leads and close deals.",
    "category": "Sales & CRM",
    "title": "Dealfront Integration With Cold Email Sales Tool",
    "h1": "Integrate Dealfront with SalesBlink",
    "metaDescription": "Integrate Dealfront and other Sales & CRM Tools with SalesBlink. Two Way Dealfront Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "vcita",
    "slug": "vcita",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87b749e0941a2ff6e9516858e870c9c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vCita is a client engagement solution for small businesses, including lead generation, online scheduling, document sharing, online payments and contact management. vCita is available on desktop, tablet and mobile, easily integrating with any website and online platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "vcita Integration With Cold Email Sales Tool",
    "h1": "Integrate vcita with SalesBlink",
    "metaDescription": "Integrate vcita and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way vcita Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dialpad",
    "slug": "dialpad",
    "logo": "https://zapier-images.imgix.net/storage/services/7092d202bef292ecd2c7ddefbb0fc6ac_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dialpad is a business communications platform that's built to grow with your team, no matter where in the world they are. Offering voice, messaging, video, and more, Dialpad helps teams stay connected across all their existing devices.",
    "category": "Phone & SMS",
    "title": "Dialpad Integration With Cold Email Sales Tool",
    "h1": "Integrate Dialpad with SalesBlink",
    "metaDescription": "Integrate Dialpad and other Phone & SMS Tools with SalesBlink. Two Way Dialpad Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Fellow",
    "slug": "fellow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/44db8b35057f00158afc1aa952474f44_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fellow helps managers and their teams have the best 1‑on‑1s, team meetings, and so much more...",
    "category": "Team Collaboration",
    "title": "Fellow Integration With Cold Email Sales Tool",
    "h1": "Integrate Fellow with SalesBlink",
    "metaDescription": "Integrate Fellow and other Team Collaboration Tools with SalesBlink. Two Way Fellow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WP All Export Pro",
    "slug": "wp-all-export-pro",
    "logo": "https://zapier-images.imgix.net/storage/services/b045be7e0ecbdc62510dc272552cc49d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WP All Export turns your WordPress data into a fully customizable CSV or XML file. Edit exported WordPress posts and re-import them with WP All Import, easily migrate WooCommerce products from one site to another, and send your WordPress CSV exports anywhere you like. WP All Export makes WordPress exports easy.",
    "category": "Website Builders",
    "title": "WP All Export Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate WP All Export Pro with SalesBlink",
    "metaDescription": "Integrate WP All Export Pro and other Website Builders Tools with SalesBlink. Two Way WP All Export Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Better Proposals",
    "slug": "better-proposals",
    "logo": "https://zapier-images.imgix.net/storage/developer/bbef53121293d28c20803ae9af20913a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Better Proposals is a simple, web-based proposal creation tool that lets you send professionally designed proposals.",
    "category": "Proposal & Invoice Management",
    "title": "Better Proposals Integration With Cold Email Sales Tool",
    "h1": "Integrate Better Proposals with SalesBlink",
    "metaDescription": "Integrate Better Proposals and other Proposal & Invoice Management Tools with SalesBlink. Two Way Better Proposals Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Affinity",
    "slug": "affinity",
    "logo": "https://zapier-images.imgix.net/storage/services/67667fb4295433a924e69153bb0792f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Affinity is a collective intelligence CRM, helping you keep track of the people and organizations in your network.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Affinity Integration With Cold Email Sales Tool",
    "h1": "Integrate Affinity with SalesBlink",
    "metaDescription": "Integrate Affinity and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Affinity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Givebutter",
    "slug": "givebutter",
    "logo": "https://zapier-images.imgix.net/storage/services/b0c137ba70ddac3acd98216f62b10576.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Givebutter is a fundraising platform that helps you raise money online with donation forms, team fundraising campaigns, and ticketing.",
    "category": "Fundraising",
    "title": "Givebutter Integration With Cold Email Sales Tool",
    "h1": "Integrate Givebutter with SalesBlink",
    "metaDescription": "Integrate Givebutter and other Fundraising Tools with SalesBlink. Two Way Givebutter Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Fireflies.ai",
    "slug": "fireflies",
    "logo": "https://zapier-images.imgix.net/storage/developer/d88308a209f9b4ebaca28cae353ac754.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatic notes from every meeting. Fireflies dials into your call to take notes. Automate actions by setting custom commands and alerts.",
    "category": "Transcription",
    "title": "Fireflies.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Fireflies.ai with SalesBlink",
    "metaDescription": "Integrate Fireflies.ai and other Transcription Tools with SalesBlink. Two Way Fireflies.ai Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Swipe Pages",
    "slug": "swipe-pages",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f203f15d09e45691cdc918501c122fff_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swipe Pages helps you create & publish landing pages without code.",
    "category": "Forms & Surveys",
    "title": "Swipe Pages Integration With Cold Email Sales Tool",
    "h1": "Integrate Swipe Pages with SalesBlink",
    "metaDescription": "Integrate Swipe Pages and other Forms & Surveys Tools with SalesBlink. Two Way Swipe Pages Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "NeverBounce",
    "slug": "neverbounce",
    "logo": "https://zapier-images.imgix.net/storage/developer/0f5b601c6560a9ee3c598e9aec78b238.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NeverBounce is an email verification service that improves deliverability and helps businesses adhere to strict deliverability guidelines.",
    "category": "Email",
    "title": "NeverBounce Integration With Cold Email Sales Tool",
    "h1": "Integrate NeverBounce with SalesBlink",
    "metaDescription": "Integrate NeverBounce and other Email Tools with SalesBlink. Two Way NeverBounce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "zBuyer.com",
    "slug": "zbuyer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c2a43e84440bf97b5767935c3e0ec92_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "zBuyer.com Provides Home Buyer and Seller Leads to Real Estate Professionals.",
    "category": "Product Management",
    "title": "zBuyer.com Integration With Cold Email Sales Tool",
    "h1": "Integrate zBuyer.com with SalesBlink",
    "metaDescription": "Integrate zBuyer.com and other Product Management Tools with SalesBlink. Two Way zBuyer.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drift",
    "slug": "drift",
    "logo": "https://zapier-images.imgix.net/storage/developer/10a43fb42dbe9c1ff6cba2dfefdaebbb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drift is a messaging app that makes it easy for businesses to talk to their website visitors and customers in real-time, from anywhere.",
    "category": "Customer Support",
    "title": "Drift Integration With Cold Email Sales Tool",
    "h1": "Integrate Drift with SalesBlink",
    "metaDescription": "Integrate Drift and other Customer Support Tools with SalesBlink. Two Way Drift Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SEMrush",
    "slug": "semrush",
    "logo": "https://zapier-images.imgix.net/storage/services/2823136afe1de8f9eae305a0da5e01dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SEMrush is an all-in-one marketing toolkit for digital marketing professionals.",
    "category": "Analytics",
    "title": "SEMrush Integration With Cold Email Sales Tool",
    "h1": "Integrate SEMrush with SalesBlink",
    "metaDescription": "Integrate SEMrush and other Analytics Tools with SalesBlink. Two Way SEMrush Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Databox",
    "slug": "databox",
    "logo": "https://zapier-images.imgix.net/storage/developer/5440f64542925612e1ba89bd4eb16978.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Databox is a business analytics platform built to help you understand what's going on with your business and keep KPIs from cloud services, spreadsheets, databases in one place.",
    "category": "Dashboards",
    "title": "Databox Integration With Cold Email Sales Tool",
    "h1": "Integrate Databox with SalesBlink",
    "metaDescription": "Integrate Databox and other Dashboards Tools with SalesBlink. Two Way Databox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Responder (רב מסר)",
    "slug": "responder",
    "logo": "https://zapier-images.imgix.net/storage/developer/7af6945efa4eb701a9e6d40b09a1e202.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Responder (רב מסר) is a leading Israeli email autoresponder platform, and is only in Hebrew. Capture leads, segment them to lists based on interest and action - and keep in touch with them via newsletters and segmented offers.",
    "category": "Drip Emails",
    "title": "Responder (רב מסר) Integration With Cold Email Sales Tool",
    "h1": "Integrate Responder (רב מסר) with SalesBlink",
    "metaDescription": "Integrate Responder (רב מסר) and other Drip Emails Tools with SalesBlink. Two Way Responder (רב מסר) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Thryv",
    "slug": "thryv",
    "logo": "https://zapier-images.imgix.net/storage/developer/9b2a8d8c44f0ee70ac5fd48f14ad7358.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thryv is an all-in-one management software built for small business. It integrates essential business functions like payments, marketing, and CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Thryv Integration With Cold Email Sales Tool",
    "h1": "Integrate Thryv with SalesBlink",
    "metaDescription": "Integrate Thryv and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Thryv Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Runkeeper",
    "slug": "runkeeper",
    "logo": "https://zapier-images.imgix.net/storage/developer/8fc8fdeffc3a515155acaffb2b17c052.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Runkeeper lets you track your runs and walks from your mobile device and share the activity with your friends, helping you stick to fitness goals with plans and an encouraging community.",
    "category": "Fitness",
    "title": "Runkeeper Integration With Cold Email Sales Tool",
    "h1": "Integrate Runkeeper with SalesBlink",
    "metaDescription": "Integrate Runkeeper and other Fitness Tools with SalesBlink. Two Way Runkeeper Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MemberVault",
    "slug": "membervault",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd012befdec4a185394e8f9a7ae80cef_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MemberVault is a Relationship Marketing Platform that allows users to host courses, membership and other digital products in one place.",
    "category": "Online Courses",
    "title": "MemberVault Integration With Cold Email Sales Tool",
    "h1": "Integrate MemberVault with SalesBlink",
    "metaDescription": "Integrate MemberVault and other Online Courses Tools with SalesBlink. Two Way MemberVault Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Beamer",
    "slug": "beamer",
    "logo": "https://zapier-images.imgix.net/storage/developer/938b259e68044a1828d99c9cbe8552e6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beamer is an easy to use newsfeed and changelog for your website or app. Use it to announce news and features, and improve user engagement.",
    "category": "Product Management",
    "title": "Beamer Integration With Cold Email Sales Tool",
    "h1": "Integrate Beamer with SalesBlink",
    "metaDescription": "Integrate Beamer and other Product Management Tools with SalesBlink. Two Way Beamer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EasyWebinar",
    "slug": "easywebinar",
    "logo": "https://zapier-images.imgix.net/storage/services/05a2ca6ae98a8d9a93f28c8a09748f1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyWebinar is a webinar software program that allows you to go live or use our automated feature to show a pre-recorded video as your webinar. Connect any webinar in EasyWebinar, live or automated, to your favorite autoresponder.",
    "category": "Webinars",
    "title": "EasyWebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyWebinar with SalesBlink",
    "metaDescription": "Integrate EasyWebinar and other Webinars Tools with SalesBlink. Two Way EasyWebinar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TalentLMS",
    "slug": "talentlms",
    "logo": "https://zapier-images.imgix.net/storage/developer/2970f5eb89f15f52c8026d565220ba29.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A super-easy, cloud-based learning platform that helps growing organizations build courses to train their people and customers",
    "category": "Online Courses",
    "title": "TalentLMS Integration With Cold Email Sales Tool",
    "h1": "Integrate TalentLMS with SalesBlink",
    "metaDescription": "Integrate TalentLMS and other Online Courses Tools with SalesBlink. Two Way TalentLMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SuiteDash",
    "slug": "suitedash",
    "logo": "https://zapier-images.imgix.net/storage/developer/98ee452d2c99d310512fc4577cb051d9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SuiteDash is a multi-tool business management platform that enables collaboration & communication both with your team, and with your clients.",
    "category": "Project Management",
    "title": "SuiteDash Integration With Cold Email Sales Tool",
    "h1": "Integrate SuiteDash with SalesBlink",
    "metaDescription": "Integrate SuiteDash and other Project Management Tools with SalesBlink. Two Way SuiteDash Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Grow",
    "slug": "grow",
    "logo": "https://zapier-images.imgix.net/storage/services/c1e745ebeddffcafe70a915e29f48e42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow helps publishers build their mailing list.",
    "category": "App Families",
    "title": "Grow Integration With Cold Email Sales Tool",
    "h1": "Integrate Grow with SalesBlink",
    "metaDescription": "Integrate Grow and other App Families Tools with SalesBlink. Two Way Grow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EZ Texting",
    "slug": "ez-texting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4cfa661b79baf655866cfc63cf7c83b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EZ Texting is a text marketing platform to send and receive SMS & MMS via web and mobile apps. Use it for group texts or 1:1 messages.",
    "category": "Phone & SMS",
    "title": "EZ Texting Integration With Cold Email Sales Tool",
    "h1": "Integrate EZ Texting with SalesBlink",
    "metaDescription": "Integrate EZ Texting and other Phone & SMS Tools with SalesBlink. Two Way EZ Texting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appointlet",
    "slug": "appointlet",
    "logo": "https://zapier-images.imgix.net/storage/services/6268ffa9ec2d92cc1b3321473f25948d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appointlet makes it easy to schedule & prepare for meetings with your customers.",
    "category": "Scheduling & Booking",
    "title": "Appointlet Integration With Cold Email Sales Tool",
    "h1": "Integrate Appointlet with SalesBlink",
    "metaDescription": "Integrate Appointlet and other Scheduling & Booking Tools with SalesBlink. Two Way Appointlet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Accredible Certificates",
    "slug": "accredible",
    "logo": "https://zapier-images.imgix.net/storage/developer/35f9c7e9be58f4efe7e837d32711d43b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily create and manage digital certificates & open badges for your events or courses. Let your alumni market your organisation.",
    "category": "Online Courses",
    "title": "Accredible Certificates Integration With Cold Email Sales Tool",
    "h1": "Integrate Accredible Certificates with SalesBlink",
    "metaDescription": "Integrate Accredible Certificates and other Online Courses Tools with SalesBlink. Two Way Accredible Certificates Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebinarGeek",
    "slug": "webinargeek",
    "logo": "https://zapier-images.imgix.net/storage/developer/98efa756897662d1456498d6911ce205.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WebinarGeek is easy-to-use webinar software with marketing tools and analytics.",
    "category": "Webinars",
    "title": "WebinarGeek Integration With Cold Email Sales Tool",
    "h1": "Integrate WebinarGeek with SalesBlink",
    "metaDescription": "Integrate WebinarGeek and other Webinars Tools with SalesBlink. Two Way WebinarGeek Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "simPRO",
    "slug": "simpro",
    "logo": "https://zapier-images.imgix.net/storage/services/1674adea5f6962107cbf9ef0b3f9c92e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "simPRO is a field service and job management software package. Optimize workflow processes & field service operations for trade businesses.",
    "category": "Project Management",
    "title": "simPRO Integration With Cold Email Sales Tool",
    "h1": "Integrate simPRO with SalesBlink",
    "metaDescription": "Integrate simPRO and other Project Management Tools with SalesBlink. Two Way simPRO Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Skedda",
    "slug": "skedda",
    "logo": "https://zapier-images.imgix.net/storage/services/cf8a95c1adafe3f01fb603689e3b2f71.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The elegant booking app for scheduling the use of spaces at venues, offices, clinics, studios and more.",
    "category": "Scheduling & Booking",
    "title": "Skedda Integration With Cold Email Sales Tool",
    "h1": "Integrate Skedda with SalesBlink",
    "metaDescription": "Integrate Skedda and other Scheduling & Booking Tools with SalesBlink. Two Way Skedda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReCharge",
    "slug": "recharge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b40a6b3be715be0825066e07cd920c09.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReCharge is a Shopify app that lets you easily sell subscriptions on your Shopify store.",
    "category": "eCommerce",
    "title": "ReCharge Integration With Cold Email Sales Tool",
    "h1": "Integrate ReCharge with SalesBlink",
    "metaDescription": "Integrate ReCharge and other eCommerce Tools with SalesBlink. Two Way ReCharge Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MapMyFitness",
    "slug": "mapmyfitness",
    "logo": "https://zapier-images.imgix.net/storage/developer/5892e0c37c26869de7447e42f9d72665.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Your workout, your device, anywhere, anytime. Plan, track, analyze and share your journey with MapMyFitness.",
    "category": "Fitness",
    "title": "MapMyFitness Integration With Cold Email Sales Tool",
    "h1": "Integrate MapMyFitness with SalesBlink",
    "metaDescription": "Integrate MapMyFitness and other Fitness Tools with SalesBlink. Two Way MapMyFitness Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sellsy",
    "slug": "sellsy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f0bcec42eb20162a22a32b3ce6983d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sellsy is a single tool that tracks all your customer cycles.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sellsy Integration With Cold Email Sales Tool",
    "h1": "Integrate Sellsy with SalesBlink",
    "metaDescription": "Integrate Sellsy and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sellsy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Little Green Light",
    "slug": "little-green-light",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/722813fcb6193d5332daed9be869f494.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Little Green Light helps nonprofits run campaigns, manage events, and maintain better constituent records, even from remote locations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Little Green Light Integration With Cold Email Sales Tool",
    "h1": "Integrate Little Green Light with SalesBlink",
    "metaDescription": "Integrate Little Green Light and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Little Green Light Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "leadPops",
    "slug": "leadpops-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f4fe6a9c6e5bd7a3f00fe13bb54e53b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "leadPops develops lead generation technology and marketing solutions for mortgage, real estate, and insurance pros.",
    "category": "Marketing",
    "title": "leadPops Integration With Cold Email Sales Tool",
    "h1": "Integrate leadPops with SalesBlink",
    "metaDescription": "Integrate leadPops and other Marketing Tools with SalesBlink. Two Way leadPops Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simplero",
    "slug": "simplero",
    "logo": "https://zapier-images.imgix.net/storage/developer/53abed8cad376ceb461deff4bacfe436.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrated software for info marketing and online courses. Email marketing, billing, membership sites, and content delivery, all in one easy-to-use and affordable package. With the best support in the business.",
    "category": "Online Courses",
    "title": "Simplero Integration With Cold Email Sales Tool",
    "h1": "Integrate Simplero with SalesBlink",
    "metaDescription": "Integrate Simplero and other Online Courses Tools with SalesBlink. Two Way Simplero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zenler",
    "slug": "zenler",
    "logo": "https://zapier-images.imgix.net/storage/developer/b10952141d11e44a740bca937cf485af_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenler is a super easy platform that lets you start your own branded website. It allows you to make money teaching your own courses online.",
    "category": "Website Builders",
    "title": "Zenler Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenler with SalesBlink",
    "metaDescription": "Integrate Zenler and other Website Builders Tools with SalesBlink. Two Way Zenler Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Shippo",
    "slug": "shippo",
    "logo": "https://zapier-images.imgix.net/storage/developer/5b90b5fdaff07c7a4dccd55df135dd1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shippo provides a multi-carrier API and dashboard to help merchants and platforms get real-time rates, print labels, automate international paperwork, track packages and facilitate returns. Shippo provides the platform to help businesses succeed through shipping.",
    "category": "eCommerce",
    "title": "Shippo Integration With Cold Email Sales Tool",
    "h1": "Integrate Shippo with SalesBlink",
    "metaDescription": "Integrate Shippo and other eCommerce Tools with SalesBlink. Two Way Shippo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Freshservice",
    "slug": "freshservice",
    "logo": "https://zapier-images.imgix.net/storage/developer/389a855c09f84660f25fadb9d430b005.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshservice is an online, cloud-based IT Helpdesk which is ITIL ready.",
    "category": "Server Monitoring",
    "title": "Freshservice Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshservice with SalesBlink",
    "metaDescription": "Integrate Freshservice and other Server Monitoring Tools with SalesBlink. Two Way Freshservice Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Hello Audio",
    "slug": "hello-audio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84f42f48f8b7164f2dd9e8d4079a5eba_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelloAudio.fm is the audio host for digital marketers and course creators to reach their audience where they’re already listening.",
    "category": "Video & Audio",
    "title": "Hello Audio Integration With Cold Email Sales Tool",
    "h1": "Integrate Hello Audio with SalesBlink",
    "metaDescription": "Integrate Hello Audio and other Video & Audio Tools with SalesBlink. Two Way Hello Audio Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "noCRM.io",
    "slug": "nocrm-io",
    "logo": "https://zapier-images.imgix.net/storage/services/5129d9a376274c895abdc4904ec38a72_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "noCRM.io helps you to track and close deals without wasting time filling out forms. Boost productivity and manage your sales cycle from end-to-end",
    "category": "CRM (Customer Relationship Management)",
    "title": "noCRM.io Integration With Cold Email Sales Tool",
    "h1": "Integrate noCRM.io with SalesBlink",
    "metaDescription": "Integrate noCRM.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way noCRM.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Realvolve",
    "slug": "realvolve",
    "logo": "https://zapier-images.imgix.net/storage/developer/c8cea3e8c781bd2a0c068b0059e5f299.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The real estate CRM that works the way you do. Manage people, listings, transactions and activities with powerful workflows.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Realvolve Integration With Cold Email Sales Tool",
    "h1": "Integrate Realvolve with SalesBlink",
    "metaDescription": "Integrate Realvolve and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Realvolve Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Autotask",
    "slug": "autotask",
    "logo": "https://zapier-images.imgix.net/storage/developer/65f99f8b49e4b610953997f5ceb7de2f.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Autotask provides an IT business management solution that combines service desk, CRM, projects, time and expense, billing and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Autotask Integration With Cold Email Sales Tool",
    "h1": "Integrate Autotask with SalesBlink",
    "metaDescription": "Integrate Autotask and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Autotask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qwilr",
    "slug": "qwilr",
    "logo": "https://zapier-images.imgix.net/storage/developer/e133fbefa253ebc60455b54c4cd6937d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qwilr is reimagining the way you communicate with your clients. We help you make beautiful, intuitive sales and marketing documents that look impressive and integrate seamlessly with your business.",
    "category": "Proposal & Invoice Management",
    "title": "Qwilr Integration With Cold Email Sales Tool",
    "h1": "Integrate Qwilr with SalesBlink",
    "metaDescription": "Integrate Qwilr and other Proposal & Invoice Management Tools with SalesBlink. Two Way Qwilr Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Axonaut",
    "slug": "axonaut",
    "logo": "https://zapier-images.imgix.net/storage/services/efbebd0c3b86eb10c14473b020cbabf6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Axonaut is a CRM and more: follow your customer from before the first contact, beginning at the marketing campaign, though negotiations, billing, bank reconciliation.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Axonaut Integration With Cold Email Sales Tool",
    "h1": "Integrate Axonaut with SalesBlink",
    "metaDescription": "Integrate Axonaut and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Axonaut Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Sprout Studio",
    "slug": "sprout-studio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b7fef0a92f5f09b78e896f086f0c81c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sprout Studio is a studio management suite for professional wedding and portrait photographers.",
    "category": "Images & Design",
    "title": "Sprout Studio Integration With Cold Email Sales Tool",
    "h1": "Integrate Sprout Studio with SalesBlink",
    "metaDescription": "Integrate Sprout Studio and other Images & Design Tools with SalesBlink. Two Way Sprout Studio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JANDI",
    "slug": "jandi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7788d1003c56e7170e3b82afd9894645.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JANDI is a team communication tool for businesses.",
    "category": "Team Chat",
    "title": "JANDI Integration With Cold Email Sales Tool",
    "h1": "Integrate JANDI with SalesBlink",
    "metaDescription": "Integrate JANDI and other Team Chat Tools with SalesBlink. Two Way JANDI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Frame.io",
    "slug": "frameio",
    "logo": "https://zapier-images.imgix.net/storage/services/a01a8866b7960d0e2d2cf605b8b0db95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Frame.io is a video review and collaboration platform. Share video projects with your team and clients for fast feedback and approval.",
    "category": "Team Collaboration",
    "title": "Frame.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Frame.io with SalesBlink",
    "metaDescription": "Integrate Frame.io and other Team Collaboration Tools with SalesBlink. Two Way Frame.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Dropbox Sign",
    "slug": "hellosign",
    "logo": "https://zapier-images.imgix.net/storage/services/4be4eceb4511e9f9a031659e37529782.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dropbox Sign is a brand new way to collect signatures and send important documents from an easy web interface. Just upload a PDF or Word Document and make your templates, and send them to anyone!",
    "category": "Signatures",
    "title": "Dropbox Sign Integration With Cold Email Sales Tool",
    "h1": "Integrate Dropbox Sign with SalesBlink",
    "metaDescription": "Integrate Dropbox Sign and other Signatures Tools with SalesBlink. Two Way Dropbox Sign Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "PomoDoneApp",
    "slug": "pomodoneapp",
    "logo": "https://zapier-images.imgix.net/storage/developer/ab1502ac2d6219d21b97ed008f13fab2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pomodone app enhances your task management by using the Pomodoro technique to boost your efficiency with controlled chunks of productivity. Prioritize your tasks, gain focus on them, and get more  work done.",
    "category": "Time Tracking Software",
    "title": "PomoDoneApp Integration With Cold Email Sales Tool",
    "h1": "Integrate PomoDoneApp with SalesBlink",
    "metaDescription": "Integrate PomoDoneApp and other Time Tracking Software Tools with SalesBlink. Two Way PomoDoneApp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zoho Cliq",
    "slug": "zoho-cliq",
    "logo": "https://zapier-images.imgix.net/storage/developer/3139a5277b7da6fb55d3bef8209cfa79.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Cliq is a real time messaging software built to provide enhanced productivity and team collaboration.",
    "category": "Website Builders",
    "title": "Zoho Cliq Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Cliq with SalesBlink",
    "metaDescription": "Integrate Zoho Cliq and other Website Builders Tools with SalesBlink. Two Way Zoho Cliq Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BookingKoala",
    "slug": "bookingkoala",
    "logo": "https://zapier-images.imgix.net/storage/developer/1c2075c074c3e547b22b36c6e219ba66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BookingKoala is a booking platform for service businesses such as cleaning, dog walking, spa, and tutoring services.",
    "category": "Scheduling & Booking",
    "title": "BookingKoala Integration With Cold Email Sales Tool",
    "h1": "Integrate BookingKoala with SalesBlink",
    "metaDescription": "Integrate BookingKoala and other Scheduling & Booking Tools with SalesBlink. Two Way BookingKoala Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "You Need A Budget",
    "slug": "you-need-a-budget",
    "logo": "https://zapier-images.imgix.net/storage/services/263a3c8cc65abca4b2146f1024ff5eb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "You Need A Budget is a tool to help you gain total control of your money, living paycheck to paycheck, get out of debt, and save more money.",
    "category": "Accounting",
    "title": "You Need A Budget Integration With Cold Email Sales Tool",
    "h1": "Integrate You Need A Budget with SalesBlink",
    "metaDescription": "Integrate You Need A Budget and other Accounting Tools with SalesBlink. Two Way You Need A Budget Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qualtrics",
    "slug": "qualtrics",
    "logo": "https://zapier-images.imgix.net/storage/services/64d02d2b153bb6b4716c73e1ff242a3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qualtrics is a research and experience platform making sophisticated research simple and empowering users to capture customer, product, brand & employee experience insights in one place.",
    "category": "Forms & Surveys",
    "title": "Qualtrics Integration With Cold Email Sales Tool",
    "h1": "Integrate Qualtrics with SalesBlink",
    "metaDescription": "Integrate Qualtrics and other Forms & Surveys Tools with SalesBlink. Two Way Qualtrics Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cliengo",
    "slug": "cliengo",
    "logo": "https://zapier-images.imgix.net/storage/services/0a20ebf5a59f79691c3388ed99a11d86.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cliengo is a Chatbot that converts your visitors into leads, automatically 24/7.",
    "category": "Contact Management",
    "title": "Cliengo Integration With Cold Email Sales Tool",
    "h1": "Integrate Cliengo with SalesBlink",
    "metaDescription": "Integrate Cliengo and other Contact Management Tools with SalesBlink. Two Way Cliengo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Parseur",
    "slug": "parseur",
    "logo": "https://zapier-images.imgix.net/storage/developer/fc52dd5d7cdcbdc08480f6e04c80f8c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send documents such as emails, PDFs and spreadsheets to your custom Parseur mailbox and extract any data.",
    "category": "Documents",
    "title": "Parseur Integration With Cold Email Sales Tool",
    "h1": "Integrate Parseur with SalesBlink",
    "metaDescription": "Integrate Parseur and other Documents Tools with SalesBlink. Two Way Parseur Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Splitwise",
    "slug": "splitwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e477bc94cc487d1f5e467dfa82120cc6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Splitwise is an app for splitting bills and expenses with friends.",
    "category": "Accounting",
    "title": "Splitwise Integration With Cold Email Sales Tool",
    "h1": "Integrate Splitwise with SalesBlink",
    "metaDescription": "Integrate Splitwise and other Accounting Tools with SalesBlink. Two Way Splitwise Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Seamless AI",
    "slug": "seamless-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9549830ee8ecc065402f35470cbbf038_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seamless AI is a real-time B2B Sales search engine to find leads using the power of AI.",
    "category": "AI Tools",
    "title": "Seamless AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Seamless AI with SalesBlink",
    "metaDescription": "Integrate Seamless AI and other AI Tools Tools with SalesBlink. Two Way Seamless AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Iterable",
    "slug": "iterable",
    "logo": "https://zapier-images.imgix.net/storage/services/8a756d9d970001e17b1d263eab7d1072.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Iterable is the growth marketing platform that empowers you to deliver completely seamless, personalized customer experiences.",
    "category": "Email Newsletters",
    "title": "Iterable Integration With Cold Email Sales Tool",
    "h1": "Integrate Iterable with SalesBlink",
    "metaDescription": "Integrate Iterable and other Email Newsletters Tools with SalesBlink. Two Way Iterable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Mattermost",
    "slug": "mattermost",
    "logo": "https://zapier-images.imgix.net/storage/developer/dc58aa280c0daafb8e6509a6d760b6cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mattermost offers modern team communication, behind your firewall. Share messages and files across phones and PCs, with archiving and instant search.",
    "category": "Team Chat",
    "title": "Mattermost Integration With Cold Email Sales Tool",
    "h1": "Integrate Mattermost with SalesBlink",
    "metaDescription": "Integrate Mattermost and other Team Chat Tools with SalesBlink. Two Way Mattermost Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Groups",
    "slug": "google-groups",
    "logo": "https://zapier-images.imgix.net/storage/services/55bc0a4b5377943d5f277325884f7968.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Groups allows you to create and participate in online forums and email-based groups with a rich experience for community conversations.",
    "category": "Google",
    "title": "Google Groups Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Groups with SalesBlink",
    "metaDescription": "Integrate Google Groups and other Google Tools with SalesBlink. Two Way Google Groups Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "dotdigital",
    "slug": "dotdigital",
    "logo": "https://zapier-images.imgix.net/storage/services/14c7401ae9cb12d81e81d390303cbd76.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "dotdigital (formerly dotmailer) Engagement Cloud is an engagement platform that helps businesses engage customers across all touchpoints. Users can leverage data, surface powerful insights, and automate the customer journey using email, SMS, and more.",
    "category": "Marketing Automation",
    "title": "dotdigital Integration With Cold Email Sales Tool",
    "h1": "Integrate dotdigital with SalesBlink",
    "metaDescription": "Integrate dotdigital and other Marketing Automation Tools with SalesBlink. Two Way dotdigital Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Alchemer",
    "slug": "surveygizmo",
    "logo": "https://zapier-images.imgix.net/storage/developer/e0468eef6b04af1933ceca629da303b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build surveys, online forms, evaluations, and event registrations quickly, easily, and professionally.",
    "category": "Forms & Surveys",
    "title": "Alchemer Integration With Cold Email Sales Tool",
    "h1": "Integrate Alchemer with SalesBlink",
    "metaDescription": "Integrate Alchemer and other Forms & Surveys Tools with SalesBlink. Two Way Alchemer Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Surefire CRM",
    "slug": "surefire",
    "logo": "https://zapier-images.imgix.net/storage/developer/29022ef8c059a53c2573c2786da801cf_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Surefire CRM is a multi-faceted mortgage industry CRM marketing platform that offers personalized database marketing with unique content.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Surefire CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Surefire CRM with SalesBlink",
    "metaDescription": "Integrate Surefire CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Surefire CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Limo Anywhere",
    "slug": "limo-anywhere",
    "logo": "https://zapier-images.imgix.net/storage/developer/d2dacd31938ccc2038d7d97199d6193a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Limo Anywhere is a mobile app platform in the limousine and livery industry, with over 4,000 happy customers across the globe.",
    "category": "Scheduling & Booking",
    "title": "Limo Anywhere Integration With Cold Email Sales Tool",
    "h1": "Integrate Limo Anywhere with SalesBlink",
    "metaDescription": "Integrate Limo Anywhere and other Scheduling & Booking Tools with SalesBlink. Two Way Limo Anywhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshping",
    "slug": "freshping",
    "logo": "https://zapier-images.imgix.net/storage/developer/898454fde66fc2e634b745809aa0cbfe_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshping is an uptime monitoring and status pages tool. You can monitor 50 URLs at 1-minute intervals and get alerts via email, Slack, or SMS when it is down. You'll also get 5 public status pages, multi-user logins (up to 30 users), global latency, and Apdex reporting.",
    "category": "Server Monitoring",
    "title": "Freshping Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshping with SalesBlink",
    "metaDescription": "Integrate Freshping and other Server Monitoring Tools with SalesBlink. Two Way Freshping Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Teamup Calendar",
    "slug": "teamup-calendar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aee5816ecd0c654f3757064e4e5e2eb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamup is a calendar tool for groups and businesses. Teamup makes it easy to organize teams, schedule jobs, manage availability of people and resources, and share with any users with customizable access control.",
    "category": "Team Collaboration",
    "title": "Teamup Calendar Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamup Calendar with SalesBlink",
    "metaDescription": "Integrate Teamup Calendar and other Team Collaboration Tools with SalesBlink. Two Way Teamup Calendar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WishList Member",
    "slug": "wishlist-member",
    "logo": "https://zapier-images.imgix.net/storage/developer/e317a97d3a3c924c996daed1809df457.PNG?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WishList Member is a powerful & easy to use plugin for running a membership site with WordPress. (Requires WishList Member 3.0 or higher).",
    "category": "Marketing Automation",
    "title": "WishList Member Integration With Cold Email Sales Tool",
    "h1": "Integrate WishList Member with SalesBlink",
    "metaDescription": "Integrate WishList Member and other Marketing Automation Tools with SalesBlink. Two Way WishList Member Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Instamojo",
    "slug": "instamojo",
    "logo": "https://zapier-images.imgix.net/storage/developer/f0baccaa48c08bf2b9992d3440ccd869_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instamojo is a payments solution that allows businesses to collect payments from their customers with ease.",
    "category": "Payment Processing",
    "title": "Instamojo Integration With Cold Email Sales Tool",
    "h1": "Integrate Instamojo with SalesBlink",
    "metaDescription": "Integrate Instamojo and other Payment Processing Tools with SalesBlink. Two Way Instamojo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CallTrackingMetrics",
    "slug": "calltrackingmetrics",
    "logo": "https://zapier-images.imgix.net/storage/developer/cd170c0b261fff0b86d46fff4318dd62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallTrackingMetrics is the only digital platform that uses call tracking intelligence to inform contact center automation—resulting in a more personalized customer experience. Discover which marketing campaigns are generating leads and conversions, and use that data to automate call flows and power your contact center.",
    "category": "Call Tracking",
    "title": "CallTrackingMetrics Integration With Cold Email Sales Tool",
    "h1": "Integrate CallTrackingMetrics with SalesBlink",
    "metaDescription": "Integrate CallTrackingMetrics and other Call Tracking Tools with SalesBlink. Two Way CallTrackingMetrics Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Landingi",
    "slug": "landingi",
    "logo": "https://zapier-images.imgix.net/storage/services/662ea3120100998b16fff3b2ee5b364a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Landing Page Builder for non-programmers. With Landingi you don’t need any programming skills to create and optimize your own custom landing pages.",
    "category": "Website Builders",
    "title": "Landingi Integration With Cold Email Sales Tool",
    "h1": "Integrate Landingi with SalesBlink",
    "metaDescription": "Integrate Landingi and other Website Builders Tools with SalesBlink. Two Way Landingi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Contacts+",
    "slug": "contacts-plus",
    "logo": "https://zapier-images.imgix.net/storage/services/52c96b5e108f90d650d2e95023b8f83f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contacts+, formerly known as FullContact, is a modern contact management app to keep your contacts up-to-date across all leading platforms.",
    "category": "Contact Management",
    "title": "Contacts+ Integration With Cold Email Sales Tool",
    "h1": "Integrate Contacts+ with SalesBlink",
    "metaDescription": "Integrate Contacts+ and other Contact Management Tools with SalesBlink. Two Way Contacts+ Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "forms.app",
    "slug": "formsapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40d9adc0b51759f23e80c5761af0b670.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "forms.app is a platform where you can build forms, share them and get responses. You can create sales order form and collect payment, too.",
    "category": "Forms & Surveys",
    "title": "forms.app Integration With Cold Email Sales Tool",
    "h1": "Integrate forms.app with SalesBlink",
    "metaDescription": "Integrate forms.app and other Forms & Surveys Tools with SalesBlink. Two Way forms.app Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Shortcut",
    "slug": "shortcut",
    "logo": "https://zapier-images.imgix.net/storage/services/372cec5c51a5e315570e91aab4ce0488.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shortcut helps modern software teams work collaboratively and ship quickly. Plan, build, and measure success with Shortcut.",
    "category": "Product Management",
    "title": "Shortcut Integration With Cold Email Sales Tool",
    "h1": "Integrate Shortcut with SalesBlink",
    "metaDescription": "Integrate Shortcut and other Product Management Tools with SalesBlink. Two Way Shortcut Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "FunnelCockpit",
    "slug": "funnelcockpit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ff0777961807fa01b2a7e3d3c0660d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FunnelCockpit is your all-in-one marketing solution.",
    "category": "Contact Management",
    "title": "FunnelCockpit Integration With Cold Email Sales Tool",
    "h1": "Integrate FunnelCockpit with SalesBlink",
    "metaDescription": "Integrate FunnelCockpit and other Contact Management Tools with SalesBlink. Two Way FunnelCockpit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Recurly",
    "slug": "recurly",
    "logo": "https://zapier-images.imgix.net/storage/services/219951a210fbe582395deb3b28c90d85.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recurly is a subscription management platform delivering a competitive advantage for leading brands worldwide. Trusted by Sling TV, BarkBox, Asana, and thousands more to drive recurring revenue.",
    "category": "Payment Processing",
    "title": "Recurly Integration With Cold Email Sales Tool",
    "h1": "Integrate Recurly with SalesBlink",
    "metaDescription": "Integrate Recurly and other Payment Processing Tools with SalesBlink. Two Way Recurly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Heartbeat",
    "slug": "heartbeat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1df6abc4c07b8a2fd751be1a88c7e573.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Heartbeat is a platform for online communities, where members can talk with each other, read content, and attend events all in the same place.",
    "category": "Communication",
    "title": "Heartbeat Integration With Cold Email Sales Tool",
    "h1": "Integrate Heartbeat with SalesBlink",
    "metaDescription": "Integrate Heartbeat and other Communication Tools with SalesBlink. Two Way Heartbeat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Wachete",
    "slug": "wachete",
    "logo": "https://zapier-images.imgix.net/storage/developer/d06e764d8619b23ccc078082e66b206d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Select web page or part of web page and we will collect text from it for you.\nBased on rules you define we will send you notification when text of web changes, or contains some particular word.",
    "category": "Documents",
    "title": "Wachete Integration With Cold Email Sales Tool",
    "h1": "Integrate Wachete with SalesBlink",
    "metaDescription": "Integrate Wachete and other Documents Tools with SalesBlink. Two Way Wachete Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Handwrytten",
    "slug": "handwrytten",
    "logo": "https://zapier-images.imgix.net/storage/developer/345ec1c41c653c2fdf7ccd4722e84187.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handwrytten is a way to automatically send real cards and notes written in pen and ink.",
    "category": "Customer Appreciation",
    "title": "Handwrytten Integration With Cold Email Sales Tool",
    "h1": "Integrate Handwrytten with SalesBlink",
    "metaDescription": "Integrate Handwrytten and other Customer Appreciation Tools with SalesBlink. Two Way Handwrytten Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EasyFTP",
    "slug": "easyftp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c52d04136d365e76a715c26b2e7342e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyFTP enables you to upload files in a FTP or SFTP server.",
    "category": "File Management & Storage",
    "title": "EasyFTP Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyFTP with SalesBlink",
    "metaDescription": "Integrate EasyFTP and other File Management & Storage Tools with SalesBlink. Two Way EasyFTP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textedly",
    "slug": "textedly",
    "logo": "https://zapier-images.imgix.net/storage/services/7415dd39ade5e10a1ecbc223dc5d17ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Textedly is a SMS and MMS text messaging platform serving thousands of companies. Textedly is the easiest way to instantly send 10 or 100,000 bulk SMS and MMS mobile text messages.",
    "category": "Phone & SMS",
    "title": "Textedly Integration With Cold Email Sales Tool",
    "h1": "Integrate Textedly with SalesBlink",
    "metaDescription": "Integrate Textedly and other Phone & SMS Tools with SalesBlink. Two Way Textedly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RocketReach",
    "slug": "rocketreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c5db1a3dfe89f101db1ee19cd6b1f2b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RocketReach helps you find email, phone and social links for over 250 million professionals, across 6 million companies, worldwide.",
    "category": "Email",
    "title": "RocketReach Integration With Cold Email Sales Tool",
    "h1": "Integrate RocketReach with SalesBlink",
    "metaDescription": "Integrate RocketReach and other Email Tools with SalesBlink. Two Way RocketReach Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Crisp",
    "slug": "crisp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf14f27848cdab2b8bf0f91573e53a06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crisp is a messaging app that unifies your user communication from Crisp Live Chat, Email, Messenger, Twitter, and SMS into one platform",
    "category": "Customer Support",
    "title": "Crisp Integration With Cold Email Sales Tool",
    "h1": "Integrate Crisp with SalesBlink",
    "metaDescription": "Integrate Crisp and other Customer Support Tools with SalesBlink. Two Way Crisp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Chili Piper",
    "slug": "chili-piper",
    "logo": "https://zapier-images.imgix.net/storage/services/dbc4b20fce9452eff3c5fdde8e4cf2c7_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chili Piper helps businesses help their buyers. Book or connect your prospects in real time from web forms.",
    "category": "Calendar",
    "title": "Chili Piper Integration With Cold Email Sales Tool",
    "h1": "Integrate Chili Piper with SalesBlink",
    "metaDescription": "Integrate Chili Piper and other Calendar Tools with SalesBlink. Two Way Chili Piper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quaderno",
    "slug": "quaderno",
    "logo": "https://zapier-images.imgix.net/storage/developer/9b9cb545978743bad7b18145b7e792a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quaderno is a tax software that handles sales tax, VAT, and GST for your online business. Automatically calculates tax, sends receipts and invoices, and provides instant multi-channel tax reports for your sales around the world.",
    "category": "Accounting",
    "title": "Quaderno Integration With Cold Email Sales Tool",
    "h1": "Integrate Quaderno with SalesBlink",
    "metaDescription": "Integrate Quaderno and other Accounting Tools with SalesBlink. Two Way Quaderno Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "iHomefinder",
    "slug": "ihomefinder",
    "logo": "https://zapier-images.imgix.net/storage/developer/f3d1d751ba8948ca496dd33a68041c0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IHomefinder is a leading national provider of real estate search technology and websites. Using the Internet Data eXchange (IDX) standard, iHomefinder powers thousands of real estate websites. We partner with a wide array of web designers to integrate our technology into real estate websites.",
    "category": "CRM (Customer Relationship Management)",
    "title": "iHomefinder Integration With Cold Email Sales Tool",
    "h1": "Integrate iHomefinder with SalesBlink",
    "metaDescription": "Integrate iHomefinder and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way iHomefinder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AWS Lambda",
    "slug": "aws-lambda",
    "logo": "https://zapier-images.imgix.net/storage/developer/9e5d4603975b5382952af253c12017fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AWS Lambda is a compute service that runs your code in response to events and automatically manages the compute resources for you, making it easy to build applications that respond quickly to new information.",
    "category": "Amazon",
    "title": "AWS Lambda Integration With Cold Email Sales Tool",
    "h1": "Integrate AWS Lambda with SalesBlink",
    "metaDescription": "Integrate AWS Lambda and other Amazon Tools with SalesBlink. Two Way AWS Lambda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClickBank",
    "slug": "clickbank",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7aa19846659fdd911a311bd9f375422c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickBank is a global digital retailer and leading affiliate marketplace. Our ecosystem enables our clients to launch, scale and support physical and digital products and connect with affiliates to promote them. We empower entrepreneurs with the freedom to work and live across the globe, fuel their purpose and live the life of their dreams.",
    "category": "eCommerce",
    "title": "ClickBank Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickBank with SalesBlink",
    "metaDescription": "Integrate ClickBank and other eCommerce Tools with SalesBlink. Two Way ClickBank Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Sakari SMS",
    "slug": "sakari-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9912680d8711873c0bea44e4d7a6906b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sakari is a platform for sending and receiving SMS messages. Our platform is optimized for sending customized messages in bulk allowing recipients to feel more engaged with your business.",
    "category": "Phone & SMS",
    "title": "Sakari SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Sakari SMS with SalesBlink",
    "metaDescription": "Integrate Sakari SMS and other Phone & SMS Tools with SalesBlink. Two Way Sakari SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FastField Mobile Forms",
    "slug": "fastfield",
    "logo": "https://zapier-images.imgix.net/storage/developer/7c442ca8198d7f30bce8efb874a4d69c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FastField Mobile Forms is a flexible platform to efficiently capture custom form data using smartphones and tablets.",
    "category": "Forms & Surveys",
    "title": "FastField Mobile Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate FastField Mobile Forms with SalesBlink",
    "metaDescription": "Integrate FastField Mobile Forms and other Forms & Surveys Tools with SalesBlink. Two Way FastField Mobile Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appcues",
    "slug": "appcues",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c39877fc3c6182f699c3f318ef5421e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appcues is a product-led growth platform. Build exceptional user experiences, code free.",
    "category": "Product Management",
    "title": "Appcues Integration With Cold Email Sales Tool",
    "h1": "Integrate Appcues with SalesBlink",
    "metaDescription": "Integrate Appcues and other Product Management Tools with SalesBlink. Two Way Appcues Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Alfred",
    "slug": "meet-alfred",
    "logo": "https://zapier-images.imgix.net/storage/services/9d1a6e950ad6f26ff6c91363a2d77dd3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alfred is an automation software & CRM for Linkedin and Gmail.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Alfred Integration With Cold Email Sales Tool",
    "h1": "Integrate Alfred with SalesBlink",
    "metaDescription": "Integrate Alfred and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Alfred Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CoSchedule",
    "slug": "coschedule",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d32c776599a401d9120a770590123c96_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CoSchedule is mission control for your entire marketing strategy.",
    "category": "Scheduling & Booking",
    "title": "CoSchedule Integration With Cold Email Sales Tool",
    "h1": "Integrate CoSchedule with SalesBlink",
    "metaDescription": "Integrate CoSchedule and other Scheduling & Booking Tools with SalesBlink. Two Way CoSchedule Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ConvertAPI",
    "slug": "convertapi",
    "logo": "https://zapier-images.imgix.net/storage/services/026088faed891af90e4dff8d49695076.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConvertAPI is a file conversion service which supports many file conversions from numerous sources and file manipulations such as merging, encrypting, splitting, repairing, and decrypting PDFs.",
    "category": "File Management & Storage",
    "title": "ConvertAPI Integration With Cold Email Sales Tool",
    "h1": "Integrate ConvertAPI with SalesBlink",
    "metaDescription": "Integrate ConvertAPI and other File Management & Storage Tools with SalesBlink. Two Way ConvertAPI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Confluence Cloud",
    "slug": "confluence-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf8ccda1a6d1ccd180870b5bba3c29cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Confluence is where you create, organize and discuss work with your team.\n\nThis integration connects to cloud-hosted instances of Confluence. Use the 'Confluence Server' app for server-hosted instances.",
    "category": "Team Collaboration",
    "title": "Confluence Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Confluence Cloud with SalesBlink",
    "metaDescription": "Integrate Confluence Cloud and other Team Collaboration Tools with SalesBlink. Two Way Confluence Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emma",
    "slug": "emma",
    "logo": "https://zapier-images.imgix.net/storage/developer/745db695687d2a6398dced110f355f3b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your business with smart, stylish email marketing campaigns from Emma.",
    "category": "Email Newsletters",
    "title": "Emma Integration With Cold Email Sales Tool",
    "h1": "Integrate Emma with SalesBlink",
    "metaDescription": "Integrate Emma and other Email Newsletters Tools with SalesBlink. Two Way Emma Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Routine",
    "slug": "routine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e400774ef0f40ed2505c4539247b232_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tasks, notes and calendar all in one blazingly fast productivity app.",
    "category": "Productivity",
    "title": "Routine Integration With Cold Email Sales Tool",
    "h1": "Integrate Routine with SalesBlink",
    "metaDescription": "Integrate Routine and other Productivity Tools with SalesBlink. Two Way Routine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rebrandly",
    "slug": "rebrandly",
    "logo": "https://zapier-images.imgix.net/storage/developer/82cc2726900d4e5ce2db62c9546fdd50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rebrandly is the easiest way to create and share branded links (short URLs using your own domain name).",
    "category": "URL Shortener",
    "title": "Rebrandly Integration With Cold Email Sales Tool",
    "h1": "Integrate Rebrandly with SalesBlink",
    "metaDescription": "Integrate Rebrandly and other URL Shortener Tools with SalesBlink. Two Way Rebrandly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ZenMaid",
    "slug": "zenmaid-nWD",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/483bc4ba558e6438c5ffa63e54923a97.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZenMaid is a simple scheduling software for maid services - including automated communication, GPS clock times, payroll, reporting and much more.",
    "category": "Scheduling & Booking",
    "title": "ZenMaid Integration With Cold Email Sales Tool",
    "h1": "Integrate ZenMaid with SalesBlink",
    "metaDescription": "Integrate ZenMaid and other Scheduling & Booking Tools with SalesBlink. Two Way ZenMaid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "respond.io",
    "slug": "respondio",
    "logo": "https://zapier-images.imgix.net/storage/services/da71028bd770e7f56f7446d57531aa67.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "respond.io unifies customer communication across instant messaging, web chat, and email for organizations that market, sell, and support.",
    "category": "CRM (Customer Relationship Management)",
    "title": "respond.io Integration With Cold Email Sales Tool",
    "h1": "Integrate respond.io with SalesBlink",
    "metaDescription": "Integrate respond.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way respond.io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Moosend",
    "slug": "moosend",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1a1b354d84b07597bf51b09b5452d5bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moosend is an email marketing & automations platform that helps you ease the burden of sending your email campaigns and managing your mailing lists.",
    "category": "Marketing Automation",
    "title": "Moosend Integration With Cold Email Sales Tool",
    "h1": "Integrate Moosend with SalesBlink",
    "metaDescription": "Integrate Moosend and other Marketing Automation Tools with SalesBlink. Two Way Moosend Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Heymarket SMS",
    "slug": "heymarket",
    "logo": "https://zapier-images.imgix.net/storage/services/f2016b2af40f8b2d6a71aba0f5359d21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Heymarket SMS is a business texting with customers and employees. Your team gets shared inboxes, templates, and workflows to message quickly and securely from all the major messaging apps in one place.",
    "category": "Phone & SMS",
    "title": "Heymarket SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Heymarket SMS with SalesBlink",
    "metaDescription": "Integrate Heymarket SMS and other Phone & SMS Tools with SalesBlink. Two Way Heymarket SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MoonClerk",
    "slug": "moonclerk",
    "logo": "https://zapier-images.imgix.net/storage/developer/d8a797e7061958c8023ba09c0c8328c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MoonClerk lets anyone accept recurring payments and one-time payments quickly and easily without any coding.",
    "category": "Payment Processing",
    "title": "MoonClerk Integration With Cold Email Sales Tool",
    "h1": "Integrate MoonClerk with SalesBlink",
    "metaDescription": "Integrate MoonClerk and other Payment Processing Tools with SalesBlink. Two Way MoonClerk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wodify Core",
    "slug": "wodify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91d8ec79629efc2d30aa03c2bdbce319.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wodify Core software helps athletes track performance while allowing box owners to manage memberships, sell gear, and build community in their affiliate box.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wodify Core Integration With Cold Email Sales Tool",
    "h1": "Integrate Wodify Core with SalesBlink",
    "metaDescription": "Integrate Wodify Core and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wodify Core Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xodo Sign",
    "slug": "xodosign",
    "logo": "https://zapier-images.imgix.net/storage/services/52aa50be35a3f6c64bbf044d715f61a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xodo Sign is a tool used to sign legally binding documents online on any device",
    "category": "Signatures",
    "title": "Xodo Sign Integration With Cold Email Sales Tool",
    "h1": "Integrate Xodo Sign with SalesBlink",
    "metaDescription": "Integrate Xodo Sign and other Signatures Tools with SalesBlink. Two Way Xodo Sign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Skool",
    "slug": "skool",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b86319caa96966631c79e85303f12a90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Skool is a simple to use community platform that allows you to put your community, course, and event schedule together in one place.",
    "category": "Education",
    "title": "Skool Integration With Cold Email Sales Tool",
    "h1": "Integrate Skool with SalesBlink",
    "metaDescription": "Integrate Skool and other Education Tools with SalesBlink. Two Way Skool Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "GrooveFunnels",
    "slug": "groovefunnels",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1af2a696661358ee7810639563675eba_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A complete digital products and services online sales system.",
    "category": "Marketing",
    "title": "GrooveFunnels Integration With Cold Email Sales Tool",
    "h1": "Integrate GrooveFunnels with SalesBlink",
    "metaDescription": "Integrate GrooveFunnels and other Marketing Tools with SalesBlink. Two Way GrooveFunnels Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NationBuilder",
    "slug": "nationbuilder",
    "logo": "https://zapier-images.imgix.net/storage/services/1b076b739e3bcffb5f9562515687bee9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NationBuilder is designed to power your organizations, movements, and campaigns.",
    "category": "CRM (Customer Relationship Management)",
    "title": "NationBuilder Integration With Cold Email Sales Tool",
    "h1": "Integrate NationBuilder with SalesBlink",
    "metaDescription": "Integrate NationBuilder and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way NationBuilder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZeroBounce",
    "slug": "zerobounce",
    "logo": "https://zapier-images.imgix.net/storage/services/cae504af4a152fca4e307f2a782769cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZeroBounce is an email validation service. By removing bounces and spam traps, we help businesses across the globe reach humans, not bots.",
    "category": "Email",
    "title": "ZeroBounce Integration With Cold Email Sales Tool",
    "h1": "Integrate ZeroBounce with SalesBlink",
    "metaDescription": "Integrate ZeroBounce and other Email Tools with SalesBlink. Two Way ZeroBounce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Booqable",
    "slug": "booqable",
    "logo": "https://zapier-images.imgix.net/storage/services/3f689aca366daaeefb5799b860db6614.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Booqable is cloud-based rental software to manage your business and accept online bookings.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Booqable Integration With Cold Email Sales Tool",
    "h1": "Integrate Booqable with SalesBlink",
    "metaDescription": "Integrate Booqable and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Booqable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Short.io",
    "slug": "short-io",
    "logo": "https://zapier-images.imgix.net/storage/developer/e983994ba803fab43c3f59f33de53bfe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Short.io is a white label link shortener, which allows you to create short links on your own domain.",
    "category": "URL Shortener",
    "title": "Short.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Short.io with SalesBlink",
    "metaDescription": "Integrate Short.io and other URL Shortener Tools with SalesBlink. Two Way Short.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LeadSquared",
    "slug": "leadsquared",
    "logo": "https://zapier-images.imgix.net/storage/developer/411351e18cabe3a6e4ed7de175954acc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadSquared is a marketing automation and CRM solution that helps small to medium-sized businesses drive revenue by aligning their marketing and sales activities. Some key features include: Lead Capture Automation, Landing Pages, Email and Drip Campaigns, Lead & List Management, Tasks & Reminders, API & Connectors and Marketing, Sales and Revenue Analytics.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadSquared Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadSquared with SalesBlink",
    "metaDescription": "Integrate LeadSquared and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadSquared Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "JobAdder",
    "slug": "jobadder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6410c87b37d4c4529d1727b6dfa8d6e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobAdder is the ATS and CRM recruiters love to use. Increase your productivity, growth and ROI by improving your user adoption rate.",
    "category": "CRM (Customer Relationship Management)",
    "title": "JobAdder Integration With Cold Email Sales Tool",
    "h1": "Integrate JobAdder with SalesBlink",
    "metaDescription": "Integrate JobAdder and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way JobAdder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Caspio Cloud Database",
    "slug": "caspio-cloud-database",
    "logo": "https://zapier-images.imgix.net/storage/developer/3367a851594d6e44eb5a1d331978a809.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Caspio is a cloud platform for creating custom database applications fast and without coding.",
    "category": "Databases",
    "title": "Caspio Cloud Database Integration With Cold Email Sales Tool",
    "h1": "Integrate Caspio Cloud Database with SalesBlink",
    "metaDescription": "Integrate Caspio Cloud Database and other Databases Tools with SalesBlink. Two Way Caspio Cloud Database Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zengine",
    "slug": "zengine",
    "logo": "https://zapier-images.imgix.net/storage/developer/3ac7cef6618a737a53d4286790e00537_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zengine by WizeHive is a flexible solution for managing grants, scholarships, fellowships, and more through the entire program lifecycle—providing tailored workflows, engaging experiences, and impact analysis to mission-driven organizations.",
    "category": "App Builder",
    "title": "Zengine Integration With Cold Email Sales Tool",
    "h1": "Integrate Zengine with SalesBlink",
    "metaDescription": "Integrate Zengine and other App Builder Tools with SalesBlink. Two Way Zengine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Syncro",
    "slug": "syncro",
    "logo": "https://zapier-images.imgix.net/storage/services/4130fc2f16b5507111687c01e15e1d03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Syncro is a platform to manage your IT Consulting / Managed Services business with CRM, Ticketing, full-featured RMM (remote machine management) and an emphasis on great communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Syncro Integration With Cold Email Sales Tool",
    "h1": "Integrate Syncro with SalesBlink",
    "metaDescription": "Integrate Syncro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Syncro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Hunter",
    "slug": "hunter",
    "logo": "https://zapier-images.imgix.net/storage/developer/3ccf84bf195febc3837e3082683df882.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hunter lets you find professional email addresses in seconds and connect with the people that matter for your business.",
    "category": "Contact Management",
    "title": "Hunter Integration With Cold Email Sales Tool",
    "h1": "Integrate Hunter with SalesBlink",
    "metaDescription": "Integrate Hunter and other Contact Management Tools with SalesBlink. Two Way Hunter Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "WooSender",
    "slug": "woosender",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2567d79de2738596920e65b7bab07cf1_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WooSender is a communication tool that helps you start, converse, and book more appointments on autopilot for your business through SMS, email, live chat, phone calls, and more.",
    "category": "Marketing",
    "title": "WooSender Integration With Cold Email Sales Tool",
    "h1": "Integrate WooSender with SalesBlink",
    "metaDescription": "Integrate WooSender and other Marketing Tools with SalesBlink. Two Way WooSender Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudinary",
    "slug": "cloudinary",
    "logo": "https://zapier-images.imgix.net/storage/services/83fd9301ea17bb2af6b92e841b282201.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudinary makes it easy to upload, store, manage, manipulate and deliver images and videos efficiently and effectively.",
    "category": "Developer Tools",
    "title": "Cloudinary Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudinary with SalesBlink",
    "metaDescription": "Integrate Cloudinary and other Developer Tools Tools with SalesBlink. Two Way Cloudinary Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CleverReach",
    "slug": "cleverreach",
    "logo": "https://zapier-images.imgix.net/storage/services/284f30672417fb24ac6296db56bd2781.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CleverReach is an email marketing solution that lets you easily create newsletters online and send them to your customers.",
    "category": "Email Newsletters",
    "title": "CleverReach Integration With Cold Email Sales Tool",
    "h1": "Integrate CleverReach with SalesBlink",
    "metaDescription": "Integrate CleverReach and other Email Newsletters Tools with SalesBlink. Two Way CleverReach Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Highrise",
    "slug": "highrise",
    "logo": "https://zapier-images.imgix.net/storage/services/5a9934ab37edddbd5e2ab6080ab7d9b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Highrise CRM helps you manage your contacts, keep track of who said what when, schedule follow-ups, set reminders, and convert leads into done deals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Highrise Integration With Cold Email Sales Tool",
    "h1": "Integrate Highrise with SalesBlink",
    "metaDescription": "Integrate Highrise and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Highrise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scoro",
    "slug": "scoro",
    "logo": "https://zapier-images.imgix.net/storage/services/d144e3cc67545dd28d8863cb8f9d47ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scoro is a comprehensive work management software – an all-in-one solution to track your time, manage projects and bill for your work",
    "category": "Project Management",
    "title": "Scoro Integration With Cold Email Sales Tool",
    "h1": "Integrate Scoro with SalesBlink",
    "metaDescription": "Integrate Scoro and other Project Management Tools with SalesBlink. Two Way Scoro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workable",
    "slug": "workable",
    "logo": "https://zapier-images.imgix.net/storage/developer/11da7c79f8ba6d25c242c964c1861aa8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workable is a beautifully simple tool that helps you advertise jobs, screen candidates and accelerate your company's hiring process.",
    "category": "HR Talent & Recruitment",
    "title": "Workable Integration With Cold Email Sales Tool",
    "h1": "Integrate Workable with SalesBlink",
    "metaDescription": "Integrate Workable and other HR Talent & Recruitment Tools with SalesBlink. Two Way Workable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Launch27",
    "slug": "launch27",
    "logo": "https://zapier-images.imgix.net/storage/developer/6e5a99ec88e86930b8ae18cb677aad94.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Launch27 is an online booking software for local service businesses.",
    "category": "Scheduling & Booking",
    "title": "Launch27 Integration With Cold Email Sales Tool",
    "h1": "Integrate Launch27 with SalesBlink",
    "metaDescription": "Integrate Launch27 and other Scheduling & Booking Tools with SalesBlink. Two Way Launch27 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Call Tools PCC",
    "slug": "call-tools-pcc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1be8049c7e942893981ada69824820f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Call Tools is a multi-channel cloud contact center and hosted pbx with a data centric focus.",
    "category": "Phone & SMS",
    "title": "Call Tools PCC Integration With Cold Email Sales Tool",
    "h1": "Integrate Call Tools PCC with SalesBlink",
    "metaDescription": "Integrate Call Tools PCC and other Phone & SMS Tools with SalesBlink. Two Way Call Tools PCC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Subscriptions",
    "slug": "zoho-subscriptions",
    "logo": "https://zapier-images.imgix.net/storage/services/dbe911dee5b068a0f1616ee64662d242.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Subscriptions is a recurring billing and subscription management app that helps manage the entire life cycle of your customers - from billing and charging to managing their subscriptions.",
    "category": "Payment Processing",
    "title": "Zoho Subscriptions Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Subscriptions with SalesBlink",
    "metaDescription": "Integrate Zoho Subscriptions and other Payment Processing Tools with SalesBlink. Two Way Zoho Subscriptions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloze",
    "slug": "cloze",
    "logo": "https://zapier-images.imgix.net/storage/developer/f293e8262aa3a2e0f762a1f1673bf7a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloze is like a personal assistant for your professional relationships that is always prompting you at the right moment, and remembering what you don’t.  It’s the no-work way to see everything about your contacts in one place.  Email, phone calls, meetings, notes, follow-ups and social.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Cloze Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloze with SalesBlink",
    "metaDescription": "Integrate Cloze and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Cloze Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "StealthSeminar",
    "slug": "stealthseminar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e96df9a73e3a918fdab40ffaf6d6cbf8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StealthSeminar is a webinar platform to run pre-recorded webinars as if they are live to better leverage time and avoid tech failures.",
    "category": "Marketing",
    "title": "StealthSeminar Integration With Cold Email Sales Tool",
    "h1": "Integrate StealthSeminar with SalesBlink",
    "metaDescription": "Integrate StealthSeminar and other Marketing Tools with SalesBlink. Two Way StealthSeminar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Revolut Business",
    "slug": "revolut-for-business",
    "logo": "https://zapier-images.imgix.net/storage/services/2501c95148f49c2905ce356ab2a3fddd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revolut Business is a business account platform with free international payments, prepaid business cards, open API, and apps marketplace.",
    "category": "Payment Processing",
    "title": "Revolut Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Revolut Business with SalesBlink",
    "metaDescription": "Integrate Revolut Business and other Payment Processing Tools with SalesBlink. Two Way Revolut Business Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Docupilot",
    "slug": "docupilot",
    "logo": "https://zapier-images.imgix.net/storage/developer/63ddad8f1edadca942a36a462ee593c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docupilot is a document automation tool to generate PDFs, DOCX files, contracts, invoices and more with data from online services.",
    "category": "Documents",
    "title": "Docupilot Integration With Cold Email Sales Tool",
    "h1": "Integrate Docupilot with SalesBlink",
    "metaDescription": "Integrate Docupilot and other Documents Tools with SalesBlink. Two Way Docupilot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Smoove",
    "slug": "smoove",
    "logo": "https://zapier-images.imgix.net/storage/developer/0ee1718df2498ddd36ab60dec7b1d46a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smoove is an all-in-one tool for marketing processes, including email and SMS marketing, landing page creation, marketing automation, and more.",
    "category": "Marketing Automation",
    "title": "Smoove Integration With Cold Email Sales Tool",
    "h1": "Integrate Smoove with SalesBlink",
    "metaDescription": "Integrate Smoove and other Marketing Automation Tools with SalesBlink. Two Way Smoove Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Odoo CRM",
    "slug": "odoo",
    "logo": "https://zapier-images.imgix.net/storage/services/714c4c70487f63bb10b34332095b04b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Odoo is a powerful open source platform with dozens of apps to help you grow your business. Available apps range from CRM to eCommerce, and from accounting to manufacturing and project management. This Odoo CRM integration allows users to connect Odoo's CRM product to other apps. This integration works with instances that are cloud hosted at \"odoo.com.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Odoo CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Odoo CRM with SalesBlink",
    "metaDescription": "Integrate Odoo CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Odoo CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadSimple",
    "slug": "leadsimple",
    "logo": "https://zapier-images.imgix.net/storage/developer/5c3cb27c13e96d096fbf4ec02be296ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadSimple is the CRM that property management and real estate companies use to scale and grow.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadSimple Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadSimple with SalesBlink",
    "metaDescription": "Integrate LeadSimple and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadSimple Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bucket.io 2.0",
    "slug": "bucketio-2",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd15941cfcfc13f02b29707cbdefdafc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bucket.io is a revolutionary segmentation technology about to transform the way marketers communicate online.",
    "category": "Marketing",
    "title": "Bucket.io 2.0 Integration With Cold Email Sales Tool",
    "h1": "Integrate Bucket.io 2.0 with SalesBlink",
    "metaDescription": "Integrate Bucket.io 2.0 and other Marketing Tools with SalesBlink. Two Way Bucket.io 2.0 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EZLynx",
    "slug": "ezlynx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f762904d3411328aa7aac61f94e5605f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EZLynx provides innovative insurance software that helps streamline your agency’s workflow.",
    "category": "CRM (Customer Relationship Management)",
    "title": "EZLynx Integration With Cold Email Sales Tool",
    "h1": "Integrate EZLynx with SalesBlink",
    "metaDescription": "Integrate EZLynx and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way EZLynx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Google Assistant",
    "slug": "google-assistant",
    "logo": "https://zapier-images.imgix.net/storage/services/e2ff2fe83e32e79bcd0dad67228f342e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Assistant is an artificial intelligence-powered virtual assistant developed by Google. You can use this Google Assistant integration to manage information in other apps using simple voice commands.",
    "category": "AI Tools",
    "title": "Google Assistant Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Assistant with SalesBlink",
    "metaDescription": "Integrate Google Assistant and other AI Tools Tools with SalesBlink. Two Way Google Assistant Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "10to8 Online Booking",
    "slug": "10to8",
    "logo": "https://zapier-images.imgix.net/storage/developer/4c5e002b48582a703b7926103d6259d5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "10to8 is an online booking, appointment management and customer communication platform for small businesses to large enterprises. It can generate more business for you online, help you organise your time, and make sure your customers know when and where they should be.",
    "category": "Scheduling & Booking",
    "title": "10to8 Online Booking Integration With Cold Email Sales Tool",
    "h1": "Integrate 10to8 Online Booking with SalesBlink",
    "metaDescription": "Integrate 10to8 Online Booking and other Scheduling & Booking Tools with SalesBlink. Two Way 10to8 Online Booking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Sheet",
    "slug": "zoho-sheet",
    "logo": "https://zapier-images.imgix.net/storage/developer/5f24c9a6da9b407d183edc3a4f699dea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Sheet is a cloud-based spreadsheet software that allows you to create, edit, share and collaborate on spreadsheets in real time.",
    "category": "Spreadsheets",
    "title": "Zoho Sheet Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Sheet with SalesBlink",
    "metaDescription": "Integrate Zoho Sheet and other Spreadsheets Tools with SalesBlink. Two Way Zoho Sheet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clientify",
    "slug": "clientify",
    "logo": "https://zapier-images.imgix.net/storage/services/9500ed0f01a60bfa672661ff90075aa0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clientify is a marketing and sales automation platform to easily grow your business.\nIt includes all the marketing tools, in just one place",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clientify Integration With Cold Email Sales Tool",
    "h1": "Integrate Clientify with SalesBlink",
    "metaDescription": "Integrate Clientify and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clientify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invoice Ninja",
    "slug": "invoice-ninja",
    "logo": "https://zapier-images.imgix.net/storage/services/0f9aff01367f0a0c69773d25ca16ef35.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create. Send. Get Paid. Invoice Ninja is a free, code-available suite of apps to invoice clients, accept payments, track expenses & time billable-tasks. Designed for freelancers and small to medium-sized businesses to increase efficiency, Invoice Ninja will help you get paid!",
    "category": "Proposal & Invoice Management",
    "title": "Invoice Ninja Integration With Cold Email Sales Tool",
    "h1": "Integrate Invoice Ninja with SalesBlink",
    "metaDescription": "Integrate Invoice Ninja and other Proposal & Invoice Management Tools with SalesBlink. Two Way Invoice Ninja Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Moskit",
    "slug": "moskit",
    "logo": "https://zapier-images.imgix.net/storage/developer/8227ada3b04a68f3cbcf439a5f1951ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moskit CRM works hard for our customers to achieve new goals and have impactful results with contact management, deals and opportunities.  O Moskit CRM trabalha duro para que os clientes conquistem novos objetivos e tenham um resultado de impacto com a gestão de contatos, empresas e oportunidades.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Moskit Integration With Cold Email Sales Tool",
    "h1": "Integrate Moskit with SalesBlink",
    "metaDescription": "Integrate Moskit and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Moskit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Redmine",
    "slug": "redmine",
    "logo": "https://zapier-images.imgix.net/storage/services/cd06f9fc7a1f50c1715ef012ea897715.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Redmine is a flexible project management web application built with Ruby on Rails.",
    "category": "Project Management",
    "title": "Redmine Integration With Cold Email Sales Tool",
    "h1": "Integrate Redmine with SalesBlink",
    "metaDescription": "Integrate Redmine and other Project Management Tools with SalesBlink. Two Way Redmine Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Luxafor",
    "slug": "luxafor",
    "logo": "https://zapier-images.imgix.net/storage/developer/e427a11abe92b04af263411d85c340b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Luxafor Flag, Luxafor Orb and Luxafor Bluetooth - productivity tools that make it easy to stay focused on tasks at hand.Connect, customize and automate.",
    "category": "Devices",
    "title": "Luxafor Integration With Cold Email Sales Tool",
    "h1": "Integrate Luxafor with SalesBlink",
    "metaDescription": "Integrate Luxafor and other Devices Tools with SalesBlink. Two Way Luxafor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Glip",
    "slug": "glip",
    "logo": "https://zapier-images.imgix.net/storage/developer/d0c71db66b92e32bcd02c7922d49a390.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real-time messaging with integrated task management, video conferencing, shared calendars and more.",
    "category": "Team Chat",
    "title": "Glip Integration With Cold Email Sales Tool",
    "h1": "Integrate Glip with SalesBlink",
    "metaDescription": "Integrate Glip and other Team Chat Tools with SalesBlink. Two Way Glip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ko-fi",
    "slug": "ko-fi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6cc1b98de5c3606c4ea44162daee048.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ko-fi helps creators fund their passions with support from people who love what they do.",
    "category": "Social Media Accounts",
    "title": "Ko-fi Integration With Cold Email Sales Tool",
    "h1": "Integrate Ko-fi with SalesBlink",
    "metaDescription": "Integrate Ko-fi and other Social Media Accounts Tools with SalesBlink. Two Way Ko-fi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ortto",
    "slug": "ortto",
    "logo": "https://zapier-images.imgix.net/storage/services/d478b2179da5f7daab4742c4d4e42073.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your brand faster with a customer data and marketing automation platform designed for businesses who sell online.",
    "category": "Marketing",
    "title": "Ortto Integration With Cold Email Sales Tool",
    "h1": "Integrate Ortto with SalesBlink",
    "metaDescription": "Integrate Ortto and other Marketing Tools with SalesBlink. Two Way Ortto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rezdy",
    "slug": "rezdy",
    "logo": "https://zapier-images.imgix.net/storage/developer/8e935a59cebc2b8d38cda640bbff3487_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rezdy is a cloud-based reservation software and booking system, designed especially for tour, activity, shuttle bus, rental and sightseeing operators.",
    "category": "Scheduling & Booking",
    "title": "Rezdy Integration With Cold Email Sales Tool",
    "h1": "Integrate Rezdy with SalesBlink",
    "metaDescription": "Integrate Rezdy and other Scheduling & Booking Tools with SalesBlink. Two Way Rezdy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailUp",
    "slug": "mailup",
    "logo": "https://zapier-images.imgix.net/storage/developer/234653f68d86be1ad7d8a3538141a68a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MailUp is your complete email delivery solution. Send newsletters, create blog digests from RSS feeds, send automated messages when certain conditions occur. Advanced email marketing tools made easy.",
    "category": "Drip Emails",
    "title": "MailUp Integration With Cold Email Sales Tool",
    "h1": "Integrate MailUp with SalesBlink",
    "metaDescription": "Integrate MailUp and other Drip Emails Tools with SalesBlink. Two Way MailUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Braintree",
    "slug": "braintree",
    "logo": "https://zapier-images.imgix.net/storage/services/e8039e6400c52274f12fd1806d4ace6c.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With a first class API, great rates and instant approval, Braintree is the easiest way to accept payments anywhere online and via a mobile app.",
    "category": "Payment Processing",
    "title": "Braintree Integration With Cold Email Sales Tool",
    "h1": "Integrate Braintree with SalesBlink",
    "metaDescription": "Integrate Braintree and other Payment Processing Tools with SalesBlink. Two Way Braintree Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "RepairShopr",
    "slug": "repairshopr",
    "logo": "https://zapier-images.imgix.net/storage/developer/0821ef0481aba1843cdc6fe632572fdc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RepairShopr is a platform to manage a service or repair business with job tracking (ticketing) and an emphasis on great communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "RepairShopr Integration With Cold Email Sales Tool",
    "h1": "Integrate RepairShopr with SalesBlink",
    "metaDescription": "Integrate RepairShopr and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way RepairShopr Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Ninox",
    "slug": "ninox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d19b817615904bd96025937eb88fe290.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ninox is a low code database that empowers your team to quickly build custom business applications.",
    "category": "Databases",
    "title": "Ninox Integration With Cold Email Sales Tool",
    "h1": "Integrate Ninox with SalesBlink",
    "metaDescription": "Integrate Ninox and other Databases Tools with SalesBlink. Two Way Ninox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Memberspot",
    "slug": "memberspot",
    "logo": "https://zapier-images.imgix.net/storage/services/e43593fb64fbb5963282bb99f40104cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Memberspot is an online course and video-hosting platform.",
    "category": "Online Courses",
    "title": "Memberspot Integration With Cold Email Sales Tool",
    "h1": "Integrate Memberspot with SalesBlink",
    "metaDescription": "Integrate Memberspot and other Online Courses Tools with SalesBlink. Two Way Memberspot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Amazon SNS",
    "slug": "amazon-sns",
    "logo": "https://zapier-images.imgix.net/storage/services/4dbcca7d3eb1ceff95275d3930feb460.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Simple Notification Service is a flexible, and fast messaging service which is fully managed. SNS is a simple and cost-effective method to message both distributed services and human recipients.",
    "category": "Amazon",
    "title": "Amazon SNS Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon SNS with SalesBlink",
    "metaDescription": "Integrate Amazon SNS and other Amazon Tools with SalesBlink. Two Way Amazon SNS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "iContact",
    "slug": "icontact",
    "logo": "https://zapier-images.imgix.net/storage/services/fceb694672831599b4be34e757860d65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iContact is an easy-to-use email marketing platform that will help you start growing your business and stay connected with your customers.",
    "category": "Email Newsletters",
    "title": "iContact Integration With Cold Email Sales Tool",
    "h1": "Integrate iContact with SalesBlink",
    "metaDescription": "Integrate iContact and other Email Newsletters Tools with SalesBlink. Two Way iContact Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Getform",
    "slug": "getform",
    "logo": "https://zapier-images.imgix.net/storage/services/c5fe8f994a81f407a90ff8f82bb7e38e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Getform is a form backend management tool that lets you handle your web forms & submissions easily",
    "category": "Forms & Surveys",
    "title": "Getform Integration With Cold Email Sales Tool",
    "h1": "Integrate Getform with SalesBlink",
    "metaDescription": "Integrate Getform and other Forms & Surveys Tools with SalesBlink. Two Way Getform Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Listings To Leads",
    "slug": "listings-to-leads",
    "logo": "https://zapier-images.imgix.net/storage/services/79684d17d72068892d0e4c748d35a433.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Listings To Leads is an online marketing and lead generation platform for real estate agents and brokers.",
    "category": "Ads & Conversion",
    "title": "Listings To Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Listings To Leads with SalesBlink",
    "metaDescription": "Integrate Listings To Leads and other Ads & Conversion Tools with SalesBlink. Two Way Listings To Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Basecamp 2",
    "slug": "basecamp2",
    "logo": "https://zapier-images.imgix.net/storage/services/be7e9ab77293a5f77811b74aef431779.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Basecamp 2 lets you manage your projects all on one page, is super fast, and keeps you up to date in real-time.",
    "category": "Project Management",
    "title": "Basecamp 2 Integration With Cold Email Sales Tool",
    "h1": "Integrate Basecamp 2 with SalesBlink",
    "metaDescription": "Integrate Basecamp 2 and other Project Management Tools with SalesBlink. Two Way Basecamp 2 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Humanitix",
    "slug": "humanitix",
    "logo": "https://zapier-images.imgix.net/storage/services/d8b0fe7a2e2d67085efcd11f4697d7d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Humanitix is a not-for-profit ticketing platform, in which all booking fees are donated to a charity of the event organiser's choice.",
    "category": "Event Management",
    "title": "Humanitix Integration With Cold Email Sales Tool",
    "h1": "Integrate Humanitix with SalesBlink",
    "metaDescription": "Integrate Humanitix and other Event Management Tools with SalesBlink. Two Way Humanitix Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Qonto",
    "slug": "qonto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/380376a09e741a1ddc197dae14db4d5b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qonto is bank built to make finances easier for businesses.",
    "category": "Payment Processing",
    "title": "Qonto Integration With Cold Email Sales Tool",
    "h1": "Integrate Qonto with SalesBlink",
    "metaDescription": "Integrate Qonto and other Payment Processing Tools with SalesBlink. Two Way Qonto Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "OpsGenie",
    "slug": "opsgenie",
    "logo": "https://zapier-images.imgix.net/storage/services/db8140276a1e2a66c1215f1353f81000.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OpsGenie extends IT Operations Management to mobile devices. OpsGenie gets alerts from IT management systems and notify the users via iPhone/Android push notifications, email, text messages and voice to text phone calls. OpsGenie automates the notification process, empowering users to receive alert notifications and rapidly respond to them directly from OpsGenie apps.",
    "category": "Server Monitoring",
    "title": "OpsGenie Integration With Cold Email Sales Tool",
    "h1": "Integrate OpsGenie with SalesBlink",
    "metaDescription": "Integrate OpsGenie and other Server Monitoring Tools with SalesBlink. Two Way OpsGenie Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Bannerbear",
    "slug": "bannerbear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/494b322443db6e2fd2b154405becea62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bannerbear is a service that automatically generates variations of graphic templates.",
    "category": "Images & Design",
    "title": "Bannerbear Integration With Cold Email Sales Tool",
    "h1": "Integrate Bannerbear with SalesBlink",
    "metaDescription": "Integrate Bannerbear and other Images & Design Tools with SalesBlink. Two Way Bannerbear Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Wootric by InMoment",
    "slug": "wootric",
    "logo": "https://zapier-images.imgix.net/storage/services/ab003f812414d90376cab9c1a94e09bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wootric by InMoment collects and manages customer feedback using proven CX metrics (Net Promoter Score® (NPS), CSAT, and Customer Effort Score (CES). Survey in-app, via mobile, email & SMS, track ongoing results, follow up with customers, and quickly surface insights in an easy-to-use dashboard.",
    "category": "Forms & Surveys",
    "title": "Wootric by InMoment Integration With Cold Email Sales Tool",
    "h1": "Integrate Wootric by InMoment with SalesBlink",
    "metaDescription": "Integrate Wootric by InMoment and other Forms & Surveys Tools with SalesBlink. Two Way Wootric by InMoment Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pendo",
    "slug": "pendo-legacy",
    "logo": "https://zapier-images.imgix.net/storage/developer/e2816b288602a0eca0fbdae6687f0cbd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pendo is an extension of your product that captures user behavior, gathers feedback, and guides users.",
    "category": "Analytics",
    "title": "Pendo Integration With Cold Email Sales Tool",
    "h1": "Integrate Pendo with SalesBlink",
    "metaDescription": "Integrate Pendo and other Analytics Tools with SalesBlink. Two Way Pendo Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pure Chat",
    "slug": "pure-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer/79e4e33fe32d82d5a458191c1af21734_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate more leads and drive sales with Pure Chat. Add live chat software to your website to engage visitors and customers! Get started in 3 mins today for free!",
    "category": "Customer Support",
    "title": "Pure Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Pure Chat with SalesBlink",
    "metaDescription": "Integrate Pure Chat and other Customer Support Tools with SalesBlink. Two Way Pure Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RealScout",
    "slug": "realscout",
    "logo": "https://zapier-images.imgix.net/storage/services/187afb40b07a1454be75aff786e5eede.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RealScout is a collaborative listing alert platform that delivers automated, high-performing listing alerts to your clients based on their unique home search preferences.",
    "category": "CRM (Customer Relationship Management)",
    "title": "RealScout Integration With Cold Email Sales Tool",
    "h1": "Integrate RealScout with SalesBlink",
    "metaDescription": "Integrate RealScout and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way RealScout Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lodgify",
    "slug": "lodgify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1e4688723426050d087b578df1d913b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lodgify is a vacation rental software that allows vacation rental managers to create their own website and accept online bookings.",
    "category": "Scheduling & Booking",
    "title": "Lodgify Integration With Cold Email Sales Tool",
    "h1": "Integrate Lodgify with SalesBlink",
    "metaDescription": "Integrate Lodgify and other Scheduling & Booking Tools with SalesBlink. Two Way Lodgify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Citrix ShareFile",
    "slug": "citrix-sharefile",
    "logo": "https://zapier-images.imgix.net/storage/developer/5683736c3a6270fc4b05833efcd97c1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Citrix ShareFile is an enterprise \"follow-me\" data solution that makes it easy for users to store, sync and share data securely  from any device, anywhere!",
    "category": "File Management & Storage",
    "title": "Citrix ShareFile Integration With Cold Email Sales Tool",
    "h1": "Integrate Citrix ShareFile with SalesBlink",
    "metaDescription": "Integrate Citrix ShareFile and other File Management & Storage Tools with SalesBlink. Two Way Citrix ShareFile Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Browse AI",
    "slug": "browse-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/04fd5c8d22e4b537634c44aeac888f3c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Browse AI is a web automation tool that lets you extract and monitor data from any website with no code.",
    "category": "Marketing Automation",
    "title": "Browse AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Browse AI with SalesBlink",
    "metaDescription": "Integrate Browse AI and other Marketing Automation Tools with SalesBlink. Two Way Browse AI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EngageBay Marketing",
    "slug": "engagebay-marketing",
    "logo": "https://zapier-images.imgix.net/storage/developer/655bf8d8d9461edf47c5e023d9909de0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EngageBay is a simple, affordable all-in-one marketing and sales software built for small businesses.",
    "category": "Marketing Automation",
    "title": "EngageBay Marketing Integration With Cold Email Sales Tool",
    "h1": "Integrate EngageBay Marketing with SalesBlink",
    "metaDescription": "Integrate EngageBay Marketing and other Marketing Automation Tools with SalesBlink. Two Way EngageBay Marketing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Karbon",
    "slug": "karbon",
    "logo": "https://zapier-images.imgix.net/storage/services/014e073079901ad5e5d73608d34494d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Karbon is a workstream collaboration platform combining email, discussions, tasks & powerful workflows to give your team everything it needs to get work done.",
    "category": "Project Management",
    "title": "Karbon Integration With Cold Email Sales Tool",
    "h1": "Integrate Karbon with SalesBlink",
    "metaDescription": "Integrate Karbon and other Project Management Tools with SalesBlink. Two Way Karbon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Curb Hero",
    "slug": "curb-hero",
    "logo": "https://zapier-images.imgix.net/storage/services/f3e1ffaef61a25c32b747963c20b9553.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Curb Hero (formerly Block Party) is a simple, yet powerful, digital Open House sign-in app + property marketing tool used by thousands of real estate agents and mortgage professionals. It's proven to save time and get better quality lead data. \nUsing Curb Hero subscription-free app, Realtors can quickly create a digital open house sign-in that is a showcase for their brand. Their logo, photos, color palette, lead questions, and follow-up messaging are all customizable. Curb Hero's Zapier integration can be used to unlock infinite possibilities with the data collected at open houses or in property marketing!",
    "category": "CRM (Customer Relationship Management)",
    "title": "Curb Hero Integration With Cold Email Sales Tool",
    "h1": "Integrate Curb Hero with SalesBlink",
    "metaDescription": "Integrate Curb Hero and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Curb Hero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dext",
    "slug": "dext",
    "logo": "https://zapier-images.imgix.net/storage/services/d2587b7121bdce656ad6e6cdf0964c05.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dext makes accountants and the businesses you look after more productive and profitable through better data and insights.",
    "category": "Accounting",
    "title": "Dext Integration With Cold Email Sales Tool",
    "h1": "Integrate Dext with SalesBlink",
    "metaDescription": "Integrate Dext and other Accounting Tools with SalesBlink. Two Way Dext Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "VBOUT",
    "slug": "vbout",
    "logo": "https://zapier-images.imgix.net/storage/developer/e856e515adf7d48dd82d9fb5b741d50f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VBOUT is a marketing platform which helps you optimize the customer journey with powerful email, social media and lead intelligence tools.",
    "category": "Marketing Automation",
    "title": "VBOUT Integration With Cold Email Sales Tool",
    "h1": "Integrate VBOUT with SalesBlink",
    "metaDescription": "Integrate VBOUT and other Marketing Automation Tools with SalesBlink. Two Way VBOUT Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "PhoneBurner",
    "slug": "phoneburner",
    "logo": "https://zapier-images.imgix.net/storage/developer/4a23ddc57148a34849a45dd3f6835600_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PhoneBurner is an outbound sales dialing platform that increases live client interactions and overall team productivity and transparency.",
    "category": "Phone & SMS",
    "title": "PhoneBurner Integration With Cold Email Sales Tool",
    "h1": "Integrate PhoneBurner with SalesBlink",
    "metaDescription": "Integrate PhoneBurner and other Phone & SMS Tools with SalesBlink. Two Way PhoneBurner Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "HL Pro Tools",
    "slug": "hl-pro-tools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/acfb9901e4fa59ae8da7e027eef1bca1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HL Pro Tools helps you take full advantage of HighLevel with advanced automations to make your life and agency easier.",
    "category": "Marketing Automation",
    "title": "HL Pro Tools Integration With Cold Email Sales Tool",
    "h1": "Integrate HL Pro Tools with SalesBlink",
    "metaDescription": "Integrate HL Pro Tools and other Marketing Automation Tools with SalesBlink. Two Way HL Pro Tools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Odoo ERP Self Hosted",
    "slug": "openerp",
    "logo": "https://zapier-images.imgix.net/storage/services/714c4c70487f63bb10b34332095b04b1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Odoo ERP Self Hosted (formerly known as OpenERP) is a powerful open source platform with dozens of apps to help you grow your business. Available apps range from CRM to eCommerce, and from accounting to manufacturing and project management. This Odoo ERP Self Hosted integration works with all of Odoo ERP's products. It works with instances that are self-hosted.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Odoo ERP Self Hosted Integration With Cold Email Sales Tool",
    "h1": "Integrate Odoo ERP Self Hosted with SalesBlink",
    "metaDescription": "Integrate Odoo ERP Self Hosted and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Odoo ERP Self Hosted Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wistia",
    "slug": "wistia",
    "logo": "https://zapier-images.imgix.net/storage/developer/fd6e266b406bceaa0f9cfa626853aebb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wistia makes it easy to track and measure video engagement online. Upload your own videos to Wistia's professional video hosting services and let them take care of the rest.",
    "category": "Video & Audio",
    "title": "Wistia Integration With Cold Email Sales Tool",
    "h1": "Integrate Wistia with SalesBlink",
    "metaDescription": "Integrate Wistia and other Video & Audio Tools with SalesBlink. Two Way Wistia Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Snov.io",
    "slug": "snovio",
    "logo": "https://zapier-images.imgix.net/storage/services/f2f06f729349391e3a64cde0f73d06c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snov.io is an easy and powerful CRM. Find leads, manage your database, and send converting automated emails.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Snov.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Snov.io with SalesBlink",
    "metaDescription": "Integrate Snov.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Snov.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Nifty",
    "slug": "nifty-pm",
    "logo": "https://zapier-images.imgix.net/storage/services/e88871f554f5f806f9af67fe2fd31dfd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nifty helps you communicate with your team members and manage your projects — all in one place. Project management has never been easier, fun, and more efficient.",
    "category": "Project Management",
    "title": "Nifty Integration With Cold Email Sales Tool",
    "h1": "Integrate Nifty with SalesBlink",
    "metaDescription": "Integrate Nifty and other Project Management Tools with SalesBlink. Two Way Nifty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Recruit CRM",
    "slug": "recruitcrm",
    "logo": "https://zapier-images.imgix.net/storage/services/730b9a6eb7da74fe343d17d4de885ad4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recruit CRM is an all-in-one ATS + CRM for recruitment agencies.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Recruit CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Recruit CRM with SalesBlink",
    "metaDescription": "Integrate Recruit CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Recruit CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Birdeye",
    "slug": "birdeye",
    "logo": "https://zapier-images.imgix.net/storage/services/f023cbd94eb42e7ef5f363ad5faa4cf1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Birdeye is an all-in-one customer experience platform. Over 80,000 businesses use Birdeye every day to attract new leads with Listings, Reviews and Referrals, convert them into customers with Webchat and Payments, and delight those customers with Surveys, Ticketing, and Insights - all in one place.",
    "category": "Reviews",
    "title": "Birdeye Integration With Cold Email Sales Tool",
    "h1": "Integrate Birdeye with SalesBlink",
    "metaDescription": "Integrate Birdeye and other Reviews Tools with SalesBlink. Two Way Birdeye Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apify",
    "slug": "apify",
    "logo": "https://zapier-images.imgix.net/storage/developer/a6a56fa5267df7a6add8a58fa05fb991_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apify is a serverless computing platform built for web automation. Turn any website into an API in a few minutes.",
    "category": "Developer Tools",
    "title": "Apify Integration With Cold Email Sales Tool",
    "h1": "Integrate Apify with SalesBlink",
    "metaDescription": "Integrate Apify and other Developer Tools Tools with SalesBlink. Two Way Apify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Vybit Notifications",
    "slug": "vybit-notifications",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c68a4ebfa4d49dcfaf3898c2c2d2df68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vybit sends distinct, personalized sound notifications that you create by recording your own sounds or choosing from thousands of available sounds. With Vybit, you can create the sounds you like so you know exactly what each notification means to you.",
    "category": "Notifications",
    "title": "Vybit Notifications Integration With Cold Email Sales Tool",
    "h1": "Integrate Vybit Notifications with SalesBlink",
    "metaDescription": "Integrate Vybit Notifications and other Notifications Tools with SalesBlink. Two Way Vybit Notifications Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PhantomBuster",
    "slug": "phantombuster",
    "logo": "https://zapier-images.imgix.net/storage/services/0186fa9fbc6f2899109d37f2b3ad46b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PhantomBuster is an automation tool for teams to extract lists of leads, enrich data and emails as well as send outreach campaigns.",
    "category": "Marketing Automation",
    "title": "PhantomBuster Integration With Cold Email Sales Tool",
    "h1": "Integrate PhantomBuster with SalesBlink",
    "metaDescription": "Integrate PhantomBuster and other Marketing Automation Tools with SalesBlink. Two Way PhantomBuster Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Bloom Growth",
    "slug": "bloom-growth",
    "logo": "https://zapier-images.imgix.net/storage/services/ebabb6d2ebba4f883f7244f80b24c48b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Increase productivity and collaboration with prebuilt— and customizable— Weekly Meeting agenda, with easy access to transformational tools for your business plan, quarterly goals and org chart.",
    "category": "Productivity",
    "title": "Bloom Growth Integration With Cold Email Sales Tool",
    "h1": "Integrate Bloom Growth with SalesBlink",
    "metaDescription": "Integrate Bloom Growth and other Productivity Tools with SalesBlink. Two Way Bloom Growth Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bunq",
    "slug": "bunq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3603fe9160bd1ae964cc4bda4822aecc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bunq is a mobile bank, built from a clean slate, dedicated to your wants and needs. Get the freedom to spend, save, move and invest your money whichever way you like.",
    "category": "Accounting",
    "title": "bunq Integration With Cold Email Sales Tool",
    "h1": "Integrate bunq with SalesBlink",
    "metaDescription": "Integrate bunq and other Accounting Tools with SalesBlink. Two Way bunq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Encharge",
    "slug": "encharge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e060c92cef9297b610bb6eeaafd9ef1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Encharge is marketing automation built for SaaS companies. We help our users create personalized customer journeys to increase revenue.",
    "category": "Marketing",
    "title": "Encharge Integration With Cold Email Sales Tool",
    "h1": "Integrate Encharge with SalesBlink",
    "metaDescription": "Integrate Encharge and other Marketing Tools with SalesBlink. Two Way Encharge Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "thanks.io",
    "slug": "thanksio",
    "logo": "https://zapier-images.imgix.net/storage/developer/4e73685eab0451704fb7d9ae1afb3abb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thanks.io is a way to automatically send ‘handwritten’ postcards, letters, notecards, and giftcards at scale for as low as $.59 per card.",
    "category": "Printing",
    "title": "thanks.io Integration With Cold Email Sales Tool",
    "h1": "Integrate thanks.io with SalesBlink",
    "metaDescription": "Integrate thanks.io and other Printing Tools with SalesBlink. Two Way thanks.io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Alegra",
    "slug": "alegra",
    "logo": "https://zapier-images.imgix.net/storage/developer/d5779924e472a6d6d2554707363a3dc2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alegra is an accounting and billing app designed for Latin American managers.",
    "category": "Accounting",
    "title": "Alegra Integration With Cold Email Sales Tool",
    "h1": "Integrate Alegra with SalesBlink",
    "metaDescription": "Integrate Alegra and other Accounting Tools with SalesBlink. Two Way Alegra Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Telnyx",
    "slug": "telnyx",
    "logo": "https://zapier-images.imgix.net/storage/services/dbb4370989d643764687b608073d0828.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Telnyx is a full-featured global communications platform with an SMS API built for reliable, enterprise-grade messaging on a secure network.",
    "category": "Phone & SMS",
    "title": "Telnyx Integration With Cold Email Sales Tool",
    "h1": "Integrate Telnyx with SalesBlink",
    "metaDescription": "Integrate Telnyx and other Phone & SMS Tools with SalesBlink. Two Way Telnyx Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Hostaway",
    "slug": "hostaway",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6531572283fafc9d19e6f4a20a8ddc01.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hostaway is a property management system with a built-in channel manager software that lets you manage hundreds of short-term rentals in real-time in one easy-to-use dashboard and mobile app.",
    "category": "Scheduling & Booking",
    "title": "Hostaway Integration With Cold Email Sales Tool",
    "h1": "Integrate Hostaway with SalesBlink",
    "metaDescription": "Integrate Hostaway and other Scheduling & Booking Tools with SalesBlink. Two Way Hostaway Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Journey",
    "slug": "journey",
    "logo": "https://zapier-images.imgix.net/storage/developer/15bd6b096706846da13f44458f299d90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Journey is a multi-platform journal app that keeps your private memories forever.",
    "category": "Notes",
    "title": "Journey Integration With Cold Email Sales Tool",
    "h1": "Integrate Journey with SalesBlink",
    "metaDescription": "Integrate Journey and other Notes Tools with SalesBlink. Two Way Journey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendOwl",
    "slug": "sendowl",
    "logo": "https://zapier-images.imgix.net/storage/services/0eddc64fcdcbc8e7d7d1bbae16dcee30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendOwl's mission is to increase digital product sales globally. Sell your digital products how and where you want.",
    "category": "eCommerce",
    "title": "SendOwl Integration With Cold Email Sales Tool",
    "h1": "Integrate SendOwl with SalesBlink",
    "metaDescription": "Integrate SendOwl and other eCommerce Tools with SalesBlink. Two Way SendOwl Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Personio",
    "slug": "personio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e80e7048543c7560f672a92f20fb765c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Personio is a holistic HR software for companies from 10 - 2000 employees.",
    "category": "Human Resources",
    "title": "Personio Integration With Cold Email Sales Tool",
    "h1": "Integrate Personio with SalesBlink",
    "metaDescription": "Integrate Personio and other Human Resources Tools with SalesBlink. Two Way Personio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Jotform Enterprise",
    "slug": "jotform-enterprise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ada94456510feeb9b25a2a1fefd7e403.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jotform Enterprise is a digital workplace productivity tool that provides a powerful ROI across your entire organization.",
    "category": "Forms & Surveys",
    "title": "Jotform Enterprise Integration With Cold Email Sales Tool",
    "h1": "Integrate Jotform Enterprise with SalesBlink",
    "metaDescription": "Integrate Jotform Enterprise and other Forms & Surveys Tools with SalesBlink. Two Way Jotform Enterprise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onepage",
    "slug": "onepage",
    "logo": "https://zapier-images.imgix.net/storage/services/5ee1cd7b1189de507d8c83d2c5830178.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onepage helps you build landing pages in minutes.",
    "category": "App Builder",
    "title": "Onepage Integration With Cold Email Sales Tool",
    "h1": "Integrate Onepage with SalesBlink",
    "metaDescription": "Integrate Onepage and other App Builder Tools with SalesBlink. Two Way Onepage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "beehiiv",
    "slug": "beehiiv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6270ecf5253d2fdeb303ebfdc6bcbd14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A newsletter platform built by newsletter people",
    "category": "Marketing Automation",
    "title": "beehiiv Integration With Cold Email Sales Tool",
    "h1": "Integrate beehiiv with SalesBlink",
    "metaDescription": "Integrate beehiiv and other Marketing Automation Tools with SalesBlink. Two Way beehiiv Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SurveySparrow",
    "slug": "surveysparrow",
    "logo": "https://zapier-images.imgix.net/storage/services/d39d172e60d2b315b2457d7897f2806c.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveySparrow lets you create and share highly engaging, mobile-first surveys that offer a chat-like experience.",
    "category": "Forms & Surveys",
    "title": "SurveySparrow Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveySparrow with SalesBlink",
    "metaDescription": "Integrate SurveySparrow and other Forms & Surveys Tools with SalesBlink. Two Way SurveySparrow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Tave",
    "slug": "tave",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/34d4dce4e212007bfa5c28613857eee5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Táve is a studio management tool designed to organize and automate creative businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tave Integration With Cold Email Sales Tool",
    "h1": "Integrate Tave with SalesBlink",
    "metaDescription": "Integrate Tave and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tave Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hatch",
    "slug": "hatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/877609ae8c7a67a49724c112e07ee14c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hatch is a conversation messaging platform that allows companies to send text, emails, and voicemails to their customers to set appointments and close more sales.",
    "category": "Phone & SMS",
    "title": "Hatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Hatch with SalesBlink",
    "metaDescription": "Integrate Hatch and other Phone & SMS Tools with SalesBlink. Two Way Hatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Printavo",
    "slug": "printavo",
    "logo": "https://zapier-images.imgix.net/storage/developer/51a0b5cb65490c7474f610644b7ad63c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Printavo is a simple tool for print, embroidery, promotional, digital, large format and sign shops to manage their business.",
    "category": "Printing",
    "title": "Printavo Integration With Cold Email Sales Tool",
    "h1": "Integrate Printavo with SalesBlink",
    "metaDescription": "Integrate Printavo and other Printing Tools with SalesBlink. Two Way Printavo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ChatBot",
    "slug": "chatbot",
    "logo": "https://zapier-images.imgix.net/storage/services/99f3129ccffd9581a76cd80b92529b6a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatBot is a universal and comprehensive framework for building modern AI chatbots. It can be used by total beginners as well as advanced developers.",
    "category": "AI Tools",
    "title": "ChatBot Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatBot with SalesBlink",
    "metaDescription": "Integrate ChatBot and other AI Tools Tools with SalesBlink. Two Way ChatBot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Inoreader",
    "slug": "inoreader",
    "logo": "https://zapier-images.imgix.net/storage/services/18c592f144baaf8874306a91fa7daf8e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inoreader is a news reader designed to save you time. Harness the power of RSS to automate content discovery, collection, and distribution.",
    "category": "Website Builders",
    "title": "Inoreader Integration With Cold Email Sales Tool",
    "h1": "Integrate Inoreader with SalesBlink",
    "metaDescription": "Integrate Inoreader and other Website Builders Tools with SalesBlink. Two Way Inoreader Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Project Broadcast",
    "slug": "project-broadcast",
    "logo": "https://zapier-images.imgix.net/storage/services/06664a952919b140e02b65b53e7051ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Project Broadcast is a robust business texting platform that allows you to communicate with your customers with ease while maintaining that personal connection.",
    "category": "Phone & SMS",
    "title": "Project Broadcast Integration With Cold Email Sales Tool",
    "h1": "Integrate Project Broadcast with SalesBlink",
    "metaDescription": "Integrate Project Broadcast and other Phone & SMS Tools with SalesBlink. Two Way Project Broadcast Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ConvertFlow",
    "slug": "convertflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/d65aba762f16e93edabf31aec9bd4460.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConvertFlow is an all-in-one platform for converting visitors. Create, personalize and launch forms, popups, surveys and landing pages, without coding.",
    "category": "Forms & Surveys",
    "title": "ConvertFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate ConvertFlow with SalesBlink",
    "metaDescription": "Integrate ConvertFlow and other Forms & Surveys Tools with SalesBlink. Two Way ConvertFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Projects",
    "slug": "zoho-projects",
    "logo": "https://zapier-images.imgix.net/storage/developer/9bbd70911f81b75e3d6788a0aea4b105.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Projects is an online project management app that helps you plan your work and keep track of your progress. It also lets the people in the project communicate easily, discuss ideas, and stay updated. This lets you deliver quality results on time.",
    "category": "Project Management",
    "title": "Zoho Projects Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Projects with SalesBlink",
    "metaDescription": "Integrate Zoho Projects and other Project Management Tools with SalesBlink. Two Way Zoho Projects Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Hootsuite",
    "slug": "hootsuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24eafa907ca31680d0b7ab2c7b01f5ab_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hootsuite is the leading social media dashboard allowing you to manage multiple networks and profiles and measure your campaign results.",
    "category": "Social Media Marketing",
    "title": "Hootsuite Integration With Cold Email Sales Tool",
    "h1": "Integrate Hootsuite with SalesBlink",
    "metaDescription": "Integrate Hootsuite and other Social Media Marketing Tools with SalesBlink. Two Way Hootsuite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "FreeAgent",
    "slug": "freeagent",
    "logo": "https://zapier-images.imgix.net/storage/services/09e20e3b045064f9adce8b3257739951.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreeAgent is accounting software simplified. It provides a simplified way for freelancers and small business owners to manage their books and invoicing.",
    "category": "Accounting",
    "title": "FreeAgent Integration With Cold Email Sales Tool",
    "h1": "Integrate FreeAgent with SalesBlink",
    "metaDescription": "Integrate FreeAgent and other Accounting Tools with SalesBlink. Two Way FreeAgent Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Recruit",
    "slug": "zoho-recruit",
    "logo": "https://zapier-images.imgix.net/storage/developer/ef4fe134afe0ca5def0d9fb2ff5faeb1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The all-in-one applicant tracking system for the modern recruiter. Share your job openings with the world! Publish job openings to your company's website and the most popular job boards to increase exposure and start sourcing top talent.",
    "category": "HR Talent & Recruitment",
    "title": "Zoho Recruit Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Recruit with SalesBlink",
    "metaDescription": "Integrate Zoho Recruit and other HR Talent & Recruitment Tools with SalesBlink. Two Way Zoho Recruit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SugarCRM 7+",
    "slug": "sugarcrm7",
    "logo": "https://zapier-images.imgix.net/storage/services/8c4d3b83b54f883b75ed7b55fd25c77a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SugarCRM is an online CRM platform that helps you manage your sales, marketing, and customer support better.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SugarCRM 7+ Integration With Cold Email Sales Tool",
    "h1": "Integrate SugarCRM 7+ with SalesBlink",
    "metaDescription": "Integrate SugarCRM 7+ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SugarCRM 7+ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crowdcast",
    "slug": "crowdcast",
    "logo": "https://zapier-images.imgix.net/storage/services/ce046c83853a57268c9ba5f628174feb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crowdcast is a live video platform for hosting webinars, livestreams, workshops, interviews and online summits. Offering ticketing for paid live events, detailed analytics and integrations with CRM and marketing platforms.",
    "category": "Webinars",
    "title": "Crowdcast Integration With Cold Email Sales Tool",
    "h1": "Integrate Crowdcast with SalesBlink",
    "metaDescription": "Integrate Crowdcast and other Webinars Tools with SalesBlink. Two Way Crowdcast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailMunch",
    "slug": "mailmunch",
    "logo": "https://zapier-images.imgix.net/storage/developer/3db637081b9b47fba7ace02689211cd6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MailMunch captures leads from your website and converts visitors into life-long readers, email subscribers and customers.",
    "category": "Marketing Automation",
    "title": "MailMunch Integration With Cold Email Sales Tool",
    "h1": "Integrate MailMunch with SalesBlink",
    "metaDescription": "Integrate MailMunch and other Marketing Automation Tools with SalesBlink. Two Way MailMunch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Infinity",
    "slug": "infinity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9b8cd3707d4c8a3026cd7d9e3fd24bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infinity is a flexible project management platform that lets you organize anything, your way.",
    "category": "Project Management",
    "title": "Infinity Integration With Cold Email Sales Tool",
    "h1": "Integrate Infinity with SalesBlink",
    "metaDescription": "Integrate Infinity and other Project Management Tools with SalesBlink. Two Way Infinity Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SetSchedule",
    "slug": "setschedule",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b40538422cf70195b57d8a842a68073.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SetSchedule is a revolutionary real estate data platform that allows you to grow your business with leads and tools made for you.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SetSchedule Integration With Cold Email Sales Tool",
    "h1": "Integrate SetSchedule with SalesBlink",
    "metaDescription": "Integrate SetSchedule and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SetSchedule Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Donately",
    "slug": "donately",
    "logo": "https://zapier-images.imgix.net/storage/developer/b55f37be2096f15792b76e9a000b1cd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Donately makes online donations dead simple and provides a space for non-profits to manage their donors and online giving activity with ease.",
    "category": "Fundraising",
    "title": "Donately Integration With Cold Email Sales Tool",
    "h1": "Integrate Donately with SalesBlink",
    "metaDescription": "Integrate Donately and other Fundraising Tools with SalesBlink. Two Way Donately Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "BatchLeads",
    "slug": "batchleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f2ed9a8aec49d1fdc63b56e1f0e54b90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BatchLeads is a All-In-One platform for real estate professionals",
    "category": "CRM (Customer Relationship Management)",
    "title": "BatchLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate BatchLeads with SalesBlink",
    "metaDescription": "Integrate BatchLeads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BatchLeads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tapfiliate",
    "slug": "tapfiliate",
    "logo": "https://zapier-images.imgix.net/storage/developer/91ea0168bebc54f21077e09b39f32c5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tapfiliate is cloud based affiliate tracking software. It enables you to create, track and optimize your own affiliate programs.",
    "category": "eCommerce",
    "title": "Tapfiliate Integration With Cold Email Sales Tool",
    "h1": "Integrate Tapfiliate with SalesBlink",
    "metaDescription": "Integrate Tapfiliate and other eCommerce Tools with SalesBlink. Two Way Tapfiliate Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Fomo",
    "slug": "fomo",
    "logo": "https://zapier-images.imgix.net/storage/developer/ee11f50e4521d92ede5d56623e2441bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fomo is a social proof marketing platform that streams recent customer behaviors on your website to build trust and increase conversions.",
    "category": "Marketing Automation",
    "title": "Fomo Integration With Cold Email Sales Tool",
    "h1": "Integrate Fomo with SalesBlink",
    "metaDescription": "Integrate Fomo and other Marketing Automation Tools with SalesBlink. Two Way Fomo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Formspark",
    "slug": "formspark",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b18903467fe8a596b2f168f1dec35afa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formspark is a simple & powerful form solution for developers.",
    "category": "Forms & Surveys",
    "title": "Formspark Integration With Cold Email Sales Tool",
    "h1": "Integrate Formspark with SalesBlink",
    "metaDescription": "Integrate Formspark and other Forms & Surveys Tools with SalesBlink. Two Way Formspark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ecologi",
    "slug": "ecologi",
    "logo": "https://zapier-images.imgix.net/storage/services/b4f635f2759138cb860a4b9675f6f354.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Give back to the planet by planting trees.",
    "category": "Fundraising",
    "title": "Ecologi Integration With Cold Email Sales Tool",
    "h1": "Integrate Ecologi with SalesBlink",
    "metaDescription": "Integrate Ecologi and other Fundraising Tools with SalesBlink. Two Way Ecologi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "RegFox",
    "slug": "regfox",
    "logo": "https://zapier-images.imgix.net/storage/services/75a4d6c966c6c443ba026aaf547cb0d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RegFox is a premier registration platform to ensure the building, marketing and taking in registrants for your event all goes smoothly.",
    "category": "Event Management",
    "title": "RegFox Integration With Cold Email Sales Tool",
    "h1": "Integrate RegFox with SalesBlink",
    "metaDescription": "Integrate RegFox and other Event Management Tools with SalesBlink. Two Way RegFox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Vitally",
    "slug": "vitally",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/736d1a6c39f96479dccd2fced4323e83.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vitally is a customer success platform for high-growth B2B SaaS teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vitally Integration With Cold Email Sales Tool",
    "h1": "Integrate Vitally with SalesBlink",
    "metaDescription": "Integrate Vitally and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vitally Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Actionstep",
    "slug": "actionstep",
    "logo": "https://zapier-images.imgix.net/storage/developer/27dd2f324d7e62189b1989e77adee88e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Actionstep is a Legal Practice Management Solution for Law firms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Actionstep Integration With Cold Email Sales Tool",
    "h1": "Integrate Actionstep with SalesBlink",
    "metaDescription": "Integrate Actionstep and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Actionstep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "G2 Crowd",
    "slug": "g2-crowd",
    "logo": "https://zapier-images.imgix.net/storage/developer/24ab935dd1b78f8c4ec5b0635cd9cd57.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "G2 Crowd is a business review platform, that makes business purchasing decisions transparent and easy.",
    "category": "Reviews",
    "title": "G2 Crowd Integration With Cold Email Sales Tool",
    "h1": "Integrate G2 Crowd with SalesBlink",
    "metaDescription": "Integrate G2 Crowd and other Reviews Tools with SalesBlink. Two Way G2 Crowd Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "The Customer Factor",
    "slug": "the-customer-factor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/26e8f772c4ec160ed7b1eef91ac7632e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Customer Factor is a software where users can register, store their contacts, their appointments, invoices, etc.,",
    "category": "Databases",
    "title": "The Customer Factor Integration With Cold Email Sales Tool",
    "h1": "Integrate The Customer Factor with SalesBlink",
    "metaDescription": "Integrate The Customer Factor and other Databases Tools with SalesBlink. Two Way The Customer Factor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textline",
    "slug": "textline",
    "logo": "https://zapier-images.imgix.net/storage/developer/c232f346f107865d5dd4fa50bafa88d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Textline is a text messaging platform for your business. Share one or more phone numbers for receiving, managing, & responding to messages.",
    "category": "Customer Support",
    "title": "Textline Integration With Cold Email Sales Tool",
    "h1": "Integrate Textline with SalesBlink",
    "metaDescription": "Integrate Textline and other Customer Support Tools with SalesBlink. Two Way Textline Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Salesflare",
    "slug": "salesflare",
    "logo": "https://zapier-images.imgix.net/storage/developer/fa429f743410129200b90e4745e68d43.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesflare is an intelligent CRM startups and small businesses love to use. It's a zero-input sales pipeline tool that thinks and works for its user, not the other way around.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salesflare Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesflare with SalesBlink",
    "metaDescription": "Integrate Salesflare and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salesflare Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SMSAPI",
    "slug": "smsapi",
    "logo": "https://zapier-images.imgix.net/storage/developer/77c757876d7127b91297acd641d582bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSAPI allows you to send text messages automatically. Messages can be fully personalized. You can set a custom sender name up to 11 characters long.",
    "category": "Phone & SMS",
    "title": "SMSAPI Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSAPI with SalesBlink",
    "metaDescription": "Integrate SMSAPI and other Phone & SMS Tools with SalesBlink. Two Way SMSAPI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "AgencyBloc",
    "slug": "agencybloc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4da73fdadf4b756b5db7b64d5aca9f0_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AgencyBloc is an agency management system for L&H insurance agencies that includes CRM, commissions processing, sales, and automation tools.",
    "category": "Sales & CRM",
    "title": "AgencyBloc Integration With Cold Email Sales Tool",
    "h1": "Integrate AgencyBloc with SalesBlink",
    "metaDescription": "Integrate AgencyBloc and other Sales & CRM Tools with SalesBlink. Two Way AgencyBloc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paystack",
    "slug": "paystack",
    "logo": "https://zapier-images.imgix.net/storage/services/e36dd1e2f18466c6cfa56349f9d5f82e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paystack helps African merchants accept one-time and recurring payments online with a modern, safe, and secure payment gateway.",
    "category": "Payment Processing",
    "title": "Paystack Integration With Cold Email Sales Tool",
    "h1": "Integrate Paystack with SalesBlink",
    "metaDescription": "Integrate Paystack and other Payment Processing Tools with SalesBlink. Two Way Paystack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "WaiverForever",
    "slug": "waiverforever",
    "logo": "https://zapier-images.imgix.net/storage/developer/df448d2fc56301947dc53cd2c1ebc57d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WaiverForever is an electronic waiver signing solution. Small businesses can enjoy the full benefit of having an automated software system.",
    "category": "Documents",
    "title": "WaiverForever Integration With Cold Email Sales Tool",
    "h1": "Integrate WaiverForever with SalesBlink",
    "metaDescription": "Integrate WaiverForever and other Documents Tools with SalesBlink. Two Way WaiverForever Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "IDX Leads",
    "slug": "idx-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer/29966e10c5f19ac96cdb1d67a9e56362.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IDX Leads is a system for real estate lead capture. Leverage your MLS/IDX listings to collect qualified leads from high quality traffic.",
    "category": "CRM (Customer Relationship Management)",
    "title": "IDX Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate IDX Leads with SalesBlink",
    "metaDescription": "Integrate IDX Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way IDX Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Luma",
    "slug": "luma",
    "logo": "https://zapier-images.imgix.net/storage/services/4fc1fddc0dfe027484a24bab21913ee5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Luma is an event management app that gives you the tools to host delightful events.",
    "category": "Event Management",
    "title": "Luma Integration With Cold Email Sales Tool",
    "h1": "Integrate Luma with SalesBlink",
    "metaDescription": "Integrate Luma and other Event Management Tools with SalesBlink. Two Way Luma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teamwork Desk",
    "slug": "teamwork-desk",
    "logo": "https://zapier-images.imgix.net/storage/developer/869f3ba0a83780a1bc9e8b45a9935f81_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamwork Desk makes it easy for your team to handle any customer support queries that come their way.",
    "category": "Customer Support",
    "title": "Teamwork Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamwork Desk with SalesBlink",
    "metaDescription": "Integrate Teamwork Desk and other Customer Support Tools with SalesBlink. Two Way Teamwork Desk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "OfficeRnD",
    "slug": "officernd",
    "logo": "https://zapier-images.imgix.net/storage/services/4e2e54f573475dab8a8b05748de58266.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OfficeRnD is a workspace management platform helping Coworking Spaces and Business Centers automate operations and improve members experience. The platform acts like a central hub that connects to and manages all systems already in place, saving space operators time and allowing them to focus only on the highest leverage activities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OfficeRnD Integration With Cold Email Sales Tool",
    "h1": "Integrate OfficeRnD with SalesBlink",
    "metaDescription": "Integrate OfficeRnD and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OfficeRnD Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pipeline CRM",
    "slug": "pipelinedeals",
    "logo": "https://zapier-images.imgix.net/storage/developer/3e157483873179faf4a12e46623091cd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple and useful CRM software in the cloud. Sell more today with sales tracking software. Our CRM software will help organize, track, and manage your deals, leads, and contacts.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pipeline CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipeline CRM with SalesBlink",
    "metaDescription": "Integrate Pipeline CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pipeline CRM Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "OnePageCRM",
    "slug": "onepage-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/8694f3202a36cbe151a43c0c253a9641.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnePageCRM converts the complexity of CRM into a simple to-do list. Built using GTD (Getting Things Done) productivity principles, its streamlined approach to sales helps you convert leads to customers, reach targets and grow your business fast. By focusing on that one Next Action, your sales team are organized and empowered to move a deal forward. OnePageCRM strives to offer a product that is as easy to use as email and helps your company achieve zero admin.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OnePageCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate OnePageCRM with SalesBlink",
    "metaDescription": "Integrate OnePageCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OnePageCRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GetAccept",
    "slug": "getaccept",
    "logo": "https://zapier-images.imgix.net/storage/developer/93ee5cc151ec49a1e0d5b2de5d53cadb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetAccept is a cloud solution where you design, send, track and promote your proposals to win and get more deals signed digitally.",
    "category": "Signatures",
    "title": "GetAccept Integration With Cold Email Sales Tool",
    "h1": "Integrate GetAccept with SalesBlink",
    "metaDescription": "Integrate GetAccept and other Signatures Tools with SalesBlink. Two Way GetAccept Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ArtiBot.ai",
    "slug": "artibotai",
    "logo": "https://zapier-images.imgix.net/storage/developer/ac4479d719f4098bb63f3ec893047c5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ArtiBot is an intelligent chat bot that enables you to capture leads automatically.",
    "category": "Customer Support",
    "title": "ArtiBot.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate ArtiBot.ai with SalesBlink",
    "metaDescription": "Integrate ArtiBot.ai and other Customer Support Tools with SalesBlink. Two Way ArtiBot.ai Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Memberful",
    "slug": "memberful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ecce54efeaca202ce2b921aa176c1e72.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Memberful makes it easy to sell memberships to your audience.",
    "category": "eCommerce",
    "title": "Memberful Integration With Cold Email Sales Tool",
    "h1": "Integrate Memberful with SalesBlink",
    "metaDescription": "Integrate Memberful and other eCommerce Tools with SalesBlink. Two Way Memberful Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "TrustPulse",
    "slug": "trustpulse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9d3afac9f88f99695bc3e6548a59d9b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrustPulse is a Social Proof tool used to show the activity of real users on your site.",
    "category": "Marketing",
    "title": "TrustPulse Integration With Cold Email Sales Tool",
    "h1": "Integrate TrustPulse with SalesBlink",
    "metaDescription": "Integrate TrustPulse and other Marketing Tools with SalesBlink. Two Way TrustPulse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ActiveTrail",
    "slug": "activetrail",
    "logo": "https://zapier-images.imgix.net/storage/developer/49108dc9b7b785099efb5bad3138b04a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ActiveTrail is a marketing automation platform designed to help you maintain active relationships with customers, prospects, and user communities.",
    "category": "Marketing Automation",
    "title": "ActiveTrail Integration With Cold Email Sales Tool",
    "h1": "Integrate ActiveTrail with SalesBlink",
    "metaDescription": "Integrate ActiveTrail and other Marketing Automation Tools with SalesBlink. Two Way ActiveTrail Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Aloware",
    "slug": "aloware",
    "logo": "https://zapier-images.imgix.net/storage/developer/76e73b999cdaf0663131b72f2a12f55d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aloware is a Cloud-based contact center software that offers call, text, and workflow automation and is fit for any team.",
    "category": "Phone & SMS",
    "title": "Aloware Integration With Cold Email Sales Tool",
    "h1": "Integrate Aloware with SalesBlink",
    "metaDescription": "Integrate Aloware and other Phone & SMS Tools with SalesBlink. Two Way Aloware Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TaxDome",
    "slug": "taxdome",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/78a00ed4c855915d9121ee1ff54256e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TaxDome is an all-in-one solution for tax and accounting professionals to manage their business.",
    "category": "Accounting",
    "title": "TaxDome Integration With Cold Email Sales Tool",
    "h1": "Integrate TaxDome with SalesBlink",
    "metaDescription": "Integrate TaxDome and other Accounting Tools with SalesBlink. Two Way TaxDome Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelloAsso",
    "slug": "helloasso",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46962ca0579455fd8417035804e3f097.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelloAsso is a web platform that lets French non-profit organisations manage their campaigns and collect funds. It offers various tools to manage memberships, fundraisings, crowdfundings, e-shops, events & ticketing, all free of charge.",
    "category": "Payment Processing",
    "title": "HelloAsso Integration With Cold Email Sales Tool",
    "h1": "Integrate HelloAsso with SalesBlink",
    "metaDescription": "Integrate HelloAsso and other Payment Processing Tools with SalesBlink. Two Way HelloAsso Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Forms for Slack",
    "slug": "forms-for-slack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9db7aaaad8ab8d7ae14ecfc6feb7c4fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Forms for Slack lets you manage and submit forms within Slack.",
    "category": "Forms & Surveys",
    "title": "Forms for Slack Integration With Cold Email Sales Tool",
    "h1": "Integrate Forms for Slack with SalesBlink",
    "metaDescription": "Integrate Forms for Slack and other Forms & Surveys Tools with SalesBlink. Two Way Forms for Slack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quentn",
    "slug": "quentn",
    "logo": "https://zapier-images.imgix.net/storage/developer/d8ae937941e7ae6de80a4a240012f756.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quentn is a marketing automation tool that helps you to create and organize marketing processes.",
    "category": "Marketing Automation",
    "title": "Quentn Integration With Cold Email Sales Tool",
    "h1": "Integrate Quentn with SalesBlink",
    "metaDescription": "Integrate Quentn and other Marketing Automation Tools with SalesBlink. Two Way Quentn Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Wise Agent CRM",
    "slug": "wise-agent-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/f636348624013f39d77c1b3cb9ed72eb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wise Agent CRM helps Realtor's become pros by automating their leads, responses, appointments, and transactions.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wise Agent CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Wise Agent CRM with SalesBlink",
    "metaDescription": "Integrate Wise Agent CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wise Agent CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignWell",
    "slug": "signwell",
    "logo": "https://zapier-images.imgix.net/storage/services/304fbb8c25ae6a6362df152b5107b8e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignWell is an electronic signature tool for legally binding e-signatures and faster document signing.",
    "category": "Signatures",
    "title": "SignWell Integration With Cold Email Sales Tool",
    "h1": "Integrate SignWell with SalesBlink",
    "metaDescription": "Integrate SignWell and other Signatures Tools with SalesBlink. Two Way SignWell Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ProveSource",
    "slug": "provesource",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7d951072cafffa78829140d15816dc8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProveSource is a social proof platform that lets you show recent activity and reviews taken by your visitors on your website to build trust and increase conversions",
    "category": "Marketing",
    "title": "ProveSource Integration With Cold Email Sales Tool",
    "h1": "Integrate ProveSource with SalesBlink",
    "metaDescription": "Integrate ProveSource and other Marketing Tools with SalesBlink. Two Way ProveSource Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Onfleet",
    "slug": "onfleet",
    "logo": "https://zapier-images.imgix.net/storage/services/c3854278ac9bdb3cda89198a19fa3e7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onfleet transforms cumbersome last-mile logistics into delightful delivery management. Intuitive smartphone apps, powerful web dashboard and automatic customer notifications handle the heavy lifting so you can focus on your customer.",
    "category": "Product Management",
    "title": "Onfleet Integration With Cold Email Sales Tool",
    "h1": "Integrate Onfleet with SalesBlink",
    "metaDescription": "Integrate Onfleet and other Product Management Tools with SalesBlink. Two Way Onfleet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Chargify",
    "slug": "chargify",
    "logo": "https://zapier-images.imgix.net/storage/services/bf8d8c95a138c3d3cb7c2d307b976583.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chargify is a Recurring Billing and Subscription Management tool platform that's designed to remove billing bottlenecks and give teams the speed and flexibility to grow faster.",
    "category": "Payment Processing",
    "title": "Chargify Integration With Cold Email Sales Tool",
    "h1": "Integrate Chargify with SalesBlink",
    "metaDescription": "Integrate Chargify and other Payment Processing Tools with SalesBlink. Two Way Chargify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pointerpro",
    "slug": "pointerpro",
    "logo": "https://zapier-images.imgix.net/storage/services/0ae62f718d2f5fafa948fca538c067ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pointerpro is an easy-to-use tool to create fun, mobile and interactive surveys & quizzes.",
    "category": "Forms & Surveys",
    "title": "Pointerpro Integration With Cold Email Sales Tool",
    "h1": "Integrate Pointerpro with SalesBlink",
    "metaDescription": "Integrate Pointerpro and other Forms & Surveys Tools with SalesBlink. Two Way Pointerpro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Cloudbeds",
    "slug": "cloudbeds",
    "logo": "https://zapier-images.imgix.net/storage/developer/dbc61c5cba37d697deb595dfb173be0e_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudbeds is a hospitality management software platform with a property management system (PMS), channel manager, and booking engine.",
    "category": "Scheduling & Booking",
    "title": "Cloudbeds Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudbeds with SalesBlink",
    "metaDescription": "Integrate Cloudbeds and other Scheduling & Booking Tools with SalesBlink. Two Way Cloudbeds Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scoop.it",
    "slug": "scoopit",
    "logo": "https://zapier-images.imgix.net/storage/developer/bc5f6cba94df57dc22d9fd2fb7bd14f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "You are the content you publish.\nDiscover, curate and publish great content to get visibility online.",
    "category": "Social Media Marketing",
    "title": "Scoop.it Integration With Cold Email Sales Tool",
    "h1": "Integrate Scoop.it with SalesBlink",
    "metaDescription": "Integrate Scoop.it and other Social Media Marketing Tools with SalesBlink. Two Way Scoop.it Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Basin",
    "slug": "basin",
    "logo": "https://zapier-images.imgix.net/storage/developer/061efde931be90f4ad2948ec3d7d3996.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Basin is a simple form backend that allows you to collect submission data without writing a single line of code.",
    "category": "Forms & Surveys",
    "title": "Basin Integration With Cold Email Sales Tool",
    "h1": "Integrate Basin with SalesBlink",
    "metaDescription": "Integrate Basin and other Forms & Surveys Tools with SalesBlink. Two Way Basin Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Amazon SES",
    "slug": "amazon-ses",
    "logo": "https://zapier-images.imgix.net/storage/services/e177d9fca02e35cd16cf37278835fc14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Simple Email Service is a scalable and cost-effective email sending service tailored for both bulk and transactional for both businesses and developers.",
    "category": "Amazon",
    "title": "Amazon SES Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon SES with SalesBlink",
    "metaDescription": "Integrate Amazon SES and other Amazon Tools with SalesBlink. Two Way Amazon SES Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zoho Inventory",
    "slug": "zoho-inventory",
    "logo": "https://zapier-images.imgix.net/storage/developer/04d7173531359f31d18b5cc6cde2b2eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Inventory is inventory management software for growing businesses to increase the sales and keep track of every unit with powerful stock management, order fulfillment, and inventory control.",
    "category": "eCommerce",
    "title": "Zoho Inventory Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Inventory with SalesBlink",
    "metaDescription": "Integrate Zoho Inventory and other eCommerce Tools with SalesBlink. Two Way Zoho Inventory Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Uplisting",
    "slug": "uplisting",
    "logo": "https://zapier-images.imgix.net/storage/services/1527a8806e16461f88bb82d281965d21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uplisting is a platform for short-term rental property managers to optimise bookings and manage operations across multiple channels and multiple people.",
    "category": "Scheduling & Booking",
    "title": "Uplisting Integration With Cold Email Sales Tool",
    "h1": "Integrate Uplisting with SalesBlink",
    "metaDescription": "Integrate Uplisting and other Scheduling & Booking Tools with SalesBlink. Two Way Uplisting Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "AddEvent",
    "slug": "addevent",
    "logo": "https://zapier-images.imgix.net/storage/services/180a4dd4f6861e83ac2b20c73f910cef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AddEvent is an add to calendar service on the internet. We handle millions of events every year for businesses around the world.",
    "category": "Event Management",
    "title": "AddEvent Integration With Cold Email Sales Tool",
    "h1": "Integrate AddEvent with SalesBlink",
    "metaDescription": "Integrate AddEvent and other Event Management Tools with SalesBlink. Two Way AddEvent Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "folk",
    "slug": "folk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbb26b1e29ee172c9ca8dbc4877e671a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "folk is the next generation CRM for teams",
    "category": "CRM (Customer Relationship Management)",
    "title": "folk Integration With Cold Email Sales Tool",
    "h1": "Integrate folk with SalesBlink",
    "metaDescription": "Integrate folk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way folk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GoProposal",
    "slug": "goproposal",
    "logo": "https://zapier-images.imgix.net/storage/services/c295c50fa7cf773d02af89b233038184.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoProposal enables accountants to price consistently & eliminate scope creep.",
    "category": "Proposal & Invoice Management",
    "title": "GoProposal Integration With Cold Email Sales Tool",
    "h1": "Integrate GoProposal with SalesBlink",
    "metaDescription": "Integrate GoProposal and other Proposal & Invoice Management Tools with SalesBlink. Two Way GoProposal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Guesty",
    "slug": "guesty",
    "logo": "https://zapier-images.imgix.net/storage/services/46a98aa8207514b61426bdbde930a88b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Guesty is an end-to-end solution that simplifies the complex operational needs of short-term rental management companies.",
    "category": "Scheduling & Booking",
    "title": "Guesty Integration With Cold Email Sales Tool",
    "h1": "Integrate Guesty with SalesBlink",
    "metaDescription": "Integrate Guesty and other Scheduling & Booking Tools with SalesBlink. Two Way Guesty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paid Memberships Pro",
    "slug": "paid-memberships-pro-wordpress-p",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b1eb7b7fb97eb6000fd06e059f58542.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PMPro plugin helps you start, manage, & grow a membership site on WordPress. Protect content, register users, & accept recurring payments.",
    "category": "eCommerce",
    "title": "Paid Memberships Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Paid Memberships Pro with SalesBlink",
    "metaDescription": "Integrate Paid Memberships Pro and other eCommerce Tools with SalesBlink. Two Way Paid Memberships Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Finmo",
    "slug": "finmo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb11028bc5b0c79be924f9f7d7008930.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Finmo manages the end to end mortgage process.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Finmo Integration With Cold Email Sales Tool",
    "h1": "Integrate Finmo with SalesBlink",
    "metaDescription": "Integrate Finmo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Finmo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MessageBird",
    "slug": "messagebird",
    "logo": "https://zapier-images.imgix.net/storage/developer/13c1832bd352ae605dac38d80a9b5902_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send SMS and voice messages to any country. Easily done via our website, email or your own software (API). No setup fees. No monthly fees.",
    "category": "Phone & SMS",
    "title": "MessageBird Integration With Cold Email Sales Tool",
    "h1": "Integrate MessageBird with SalesBlink",
    "metaDescription": "Integrate MessageBird and other Phone & SMS Tools with SalesBlink. Two Way MessageBird Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Bullhorn CRM",
    "slug": "bullhorn-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/96a6fd304b1c67e92b529be17959bc22.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bullhorn provides cloud-based CRM solutions for companies in service-based industries. Its data capture and customer insight technology puts the most up-to-date and powerful information at users' fingertips to give them everything they need to win customers and keep them happy.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bullhorn CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Bullhorn CRM with SalesBlink",
    "metaDescription": "Integrate Bullhorn CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bullhorn CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LassoCRM",
    "slug": "lassocrm",
    "logo": "https://zapier-images.imgix.net/storage/services/01e74e15cbdc21f0a1823d7b2e2c3c6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LassoCRM is a CRM that empowers home selling teams by taking the guess work out of reporting, sales and the marketing process.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LassoCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate LassoCRM with SalesBlink",
    "metaDescription": "Integrate LassoCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LassoCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Sign",
    "slug": "zoho-sign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30c858a7d486c743f7a5d2d421df56b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Sign is a complete digital signature app that allows you to securely sign, send, and manage business documents online. Build automated electronic signature workflows to close deals quickly.",
    "category": "Signatures",
    "title": "Zoho Sign Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Sign with SalesBlink",
    "metaDescription": "Integrate Zoho Sign and other Signatures Tools with SalesBlink. Two Way Zoho Sign Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "PracticePanther Legal Software",
    "slug": "practicepanther-legal-software",
    "logo": "https://zapier-images.imgix.net/storage/developer/865864f58d40ad6e99b407c03d1506c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PracticePanther is a practice management software used by numerous legal practitioners worldwide to automate their firms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PracticePanther Legal Software Integration With Cold Email Sales Tool",
    "h1": "Integrate PracticePanther Legal Software with SalesBlink",
    "metaDescription": "Integrate PracticePanther Legal Software and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PracticePanther Legal Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vtiger CRM",
    "slug": "vtiger",
    "logo": "https://zapier-images.imgix.net/storage/developer/e8bb0b9f39875d84d3b69668ba7bb424.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vtiger CRM is more than just a CRM. At its heart, Vtiger CRM enables salespersons​ and support staff do more through an easy but powerful interface. With built-in solutions that range from email marketing to activity and project management and beyond, Vtiger CRM provides an integrated solution to drive business growth.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vtiger CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Vtiger CRM with SalesBlink",
    "metaDescription": "Integrate Vtiger CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vtiger CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Customer Fields",
    "slug": "customer-fields",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9626ec90e07f6eedddf406136810a600_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Customer Fields provides a way to collect and store custom data for Shopify customers",
    "category": "eCommerce",
    "title": "Customer Fields Integration With Cold Email Sales Tool",
    "h1": "Integrate Customer Fields with SalesBlink",
    "metaDescription": "Integrate Customer Fields and other eCommerce Tools with SalesBlink. Two Way Customer Fields Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formaloo",
    "slug": "formaloo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ee4ae634f4661a4b4124e4dc6467b17_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formaloo is a Customer Analytics & Customer Data Platform that translates your data into clear actions.",
    "category": "Forms & Surveys",
    "title": "Formaloo Integration With Cold Email Sales Tool",
    "h1": "Integrate Formaloo with SalesBlink",
    "metaDescription": "Integrate Formaloo and other Forms & Surveys Tools with SalesBlink. Two Way Formaloo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pennylane",
    "slug": "pennylane",
    "logo": "https://zapier-images.imgix.net/storage/services/f26598dcd5b72d3293bc81c80fd02497.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pennylane centralizes all your cash flows and connects with your financial tools to ease and automate collaboration with your accountant.",
    "category": "Accounting",
    "title": "Pennylane Integration With Cold Email Sales Tool",
    "h1": "Integrate Pennylane with SalesBlink",
    "metaDescription": "Integrate Pennylane and other Accounting Tools with SalesBlink. Two Way Pennylane Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Lob",
    "slug": "lob",
    "logo": "https://zapier-images.imgix.net/storage/developer/00adce704ef55538f4e91ff4bdf3e96c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lob makes it easy to seamlessly print and mail letters, postcards, photos, posters and more via a web API.",
    "category": "Printing",
    "title": "Lob Integration With Cold Email Sales Tool",
    "h1": "Integrate Lob with SalesBlink",
    "metaDescription": "Integrate Lob and other Printing Tools with SalesBlink. Two Way Lob Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Payhip",
    "slug": "payhip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84ed8852824086c0426a5e21c284bc42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payhip is an e-commerce platform that enables anyone to sell digital products or memberships directly to their fans and followers.",
    "category": "Commerce",
    "title": "Payhip Integration With Cold Email Sales Tool",
    "h1": "Integrate Payhip with SalesBlink",
    "metaDescription": "Integrate Payhip and other Commerce Tools with SalesBlink. Two Way Payhip Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Submittable",
    "slug": "submittable",
    "logo": "https://zapier-images.imgix.net/storage/developer/628341c9bb45b224fb5d8f06e85c66fd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Submittable brings your team together so you can collect, collaborate, and manage any application and review process, exactly the way you want.",
    "category": "Forms & Surveys",
    "title": "Submittable Integration With Cold Email Sales Tool",
    "h1": "Integrate Submittable with SalesBlink",
    "metaDescription": "Integrate Submittable and other Forms & Surveys Tools with SalesBlink. Two Way Submittable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taskade",
    "slug": "taskade",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a68efcdfccb6e2fbb9d5acd6812113e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taskade is a collaboration platform for remote teams to organize and manage projects.",
    "category": "Task Management",
    "title": "Taskade Integration With Cold Email Sales Tool",
    "h1": "Integrate Taskade with SalesBlink",
    "metaDescription": "Integrate Taskade and other Task Management Tools with SalesBlink. Two Way Taskade Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mobile Text Alerts",
    "slug": "mobile-text-alerts",
    "logo": "https://zapier-images.imgix.net/storage/services/73bb979e198ebfad9a80712c140fa2bd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobile Text Alerts allows you send mass SMS text messages to your group.",
    "category": "Phone & SMS",
    "title": "Mobile Text Alerts Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobile Text Alerts with SalesBlink",
    "metaDescription": "Integrate Mobile Text Alerts and other Phone & SMS Tools with SalesBlink. Two Way Mobile Text Alerts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Action Network",
    "slug": "actionnetwork",
    "logo": "https://zapier-images.imgix.net/storage/services/14b6df26f53d502637e5d4f3b3f58def.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Action Network is an online organizing tool for progressives, offering actions such as fundraising, petitions, and letters to elected officials, a built-in mass email tool to keep in touch with your activists, and more.",
    "category": "Email Newsletters",
    "title": "Action Network Integration With Cold Email Sales Tool",
    "h1": "Integrate Action Network with SalesBlink",
    "metaDescription": "Integrate Action Network and other Email Newsletters Tools with SalesBlink. Two Way Action Network Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Mailshake",
    "slug": "mailshake",
    "logo": "https://zapier-images.imgix.net/storage/developer/149cdd20ff05db1b13c6686bc4e2f073.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailshake is a simple outreach tool that works with any Google account.",
    "category": "Drip Emails",
    "title": "Mailshake Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailshake with SalesBlink",
    "metaDescription": "Integrate Mailshake and other Drip Emails Tools with SalesBlink. Two Way Mailshake Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Remember The Milk",
    "slug": "remember-the-milk",
    "logo": "https://zapier-images.imgix.net/storage/developer/7ca248d10ae180ca1428b40e6aa4c057.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Remember The Milk is the smart to-do app for busy people.",
    "category": "Task Management",
    "title": "Remember The Milk Integration With Cold Email Sales Tool",
    "h1": "Integrate Remember The Milk with SalesBlink",
    "metaDescription": "Integrate Remember The Milk and other Task Management Tools with SalesBlink. Two Way Remember The Milk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Privyr",
    "slug": "privyr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2c6e1b193d5d3794e42ef0976af6cad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Privyr is a mobile CRM built for sales professionals who run their businesses from their phone. It works with WhatsApp, iMessage, SMS, email, and other popular chat apps.",
    "category": "Contact Management",
    "title": "Privyr Integration With Cold Email Sales Tool",
    "h1": "Integrate Privyr with SalesBlink",
    "metaDescription": "Integrate Privyr and other Contact Management Tools with SalesBlink. Two Way Privyr Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "eWebinar",
    "slug": "ewebinar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f76759054208d7820d6d117e1237bef_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eWebinar turns any video into an interactive, automated webinar that you can set on a recurring schedule. We save you from doing the same webinar over and over again.",
    "category": "Webinars",
    "title": "eWebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate eWebinar with SalesBlink",
    "metaDescription": "Integrate eWebinar and other Webinars Tools with SalesBlink. Two Way eWebinar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LaGrowthMachine",
    "slug": "lagrowthmachine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce948d5d5d758a6dcdedfd53e1691619.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LaGrowthMachine let Sales team get more meetings. Create multi-channel workflows to engage with your leads on LinkedIn, Twitter & by Email.",
    "category": "Marketing Automation",
    "title": "LaGrowthMachine Integration With Cold Email Sales Tool",
    "h1": "Integrate LaGrowthMachine with SalesBlink",
    "metaDescription": "Integrate LaGrowthMachine and other Marketing Automation Tools with SalesBlink. Two Way LaGrowthMachine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OrbisX",
    "slug": "orbisx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84cf557f0787a9c2989c85987e210667.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OrbisX is an all-in-one CRM solution for detailers, tinters and automotive service providers",
    "category": "CRM (Customer Relationship Management)",
    "title": "OrbisX Integration With Cold Email Sales Tool",
    "h1": "Integrate OrbisX with SalesBlink",
    "metaDescription": "Integrate OrbisX and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OrbisX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wispform",
    "slug": "wispform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b024035f26c7e7060a252c6d7ff77501.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wispform helps anyone in the world to create beautiful, conversational forms and surveys. Wispform is the perfect solution for contact forms, registration forms, interactive quizzes, and much more.",
    "category": "Forms & Surveys",
    "title": "Wispform Integration With Cold Email Sales Tool",
    "h1": "Integrate Wispform with SalesBlink",
    "metaDescription": "Integrate Wispform and other Forms & Surveys Tools with SalesBlink. Two Way Wispform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Checkfront",
    "slug": "checkfront",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f95ce32c2250482868a72704d04aca34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Checkfront is the leading online booking management platform for tour and activity, rental, and accommodation operators worldwide.",
    "category": "Scheduling & Booking",
    "title": "Checkfront Integration With Cold Email Sales Tool",
    "h1": "Integrate Checkfront with SalesBlink",
    "metaDescription": "Integrate Checkfront and other Scheduling & Booking Tools with SalesBlink. Two Way Checkfront Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Urable",
    "slug": "urable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/abf17269159c50e659a4c088faa1ae42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Urable is an application designed for automotive businesses that specialize in detailing, paint protection film, vinyl wrapping, tinting, and ceramic coating. As a cloud-based application with dedicated mobile apps, it allows you to run your business from multiple devices in real-time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Urable Integration With Cold Email Sales Tool",
    "h1": "Integrate Urable with SalesBlink",
    "metaDescription": "Integrate Urable and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Urable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workiz",
    "slug": "workiz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa1aa5d0f8a6a598f01d982e79eaab5d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workiz makes it easier than ever for service business owners to run their business, save time, make their team more efficient and their business more profitable.",
    "category": "Scheduling & Booking",
    "title": "Workiz Integration With Cold Email Sales Tool",
    "h1": "Integrate Workiz with SalesBlink",
    "metaDescription": "Integrate Workiz and other Scheduling & Booking Tools with SalesBlink. Two Way Workiz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fello Connect",
    "slug": "fello-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dcbd2fdb660394daf2761fb2a7f7d8d4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iBuying For Every Agent. Fello creates a connected future. With Fello, agents can provide sellers with an instant offer option and stay at the center of the relationship.",
    "category": "Sales & CRM",
    "title": "Fello Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Fello Connect with SalesBlink",
    "metaDescription": "Integrate Fello Connect and other Sales & CRM Tools with SalesBlink. Two Way Fello Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DisputeFox",
    "slug": "disputefox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4d21a373dc5d46f22cab0d8e53d5588.PNG?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DisputeFox was designed from the ground up to be the World's Most Advanced All-in-One Credit Dispute Software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DisputeFox Integration With Cold Email Sales Tool",
    "h1": "Integrate DisputeFox with SalesBlink",
    "metaDescription": "Integrate DisputeFox and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DisputeFox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Builderall Mailingboss",
    "slug": "builderall-mailingboss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3accd1c591791b2d72c722ad72c020e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Builderall Mailingboss is an Autoresponder and Email Marketing Platform. With Mailingboss you can capture and send unlimited emails with superior delivery and open rates.",
    "category": "Email Newsletters",
    "title": "Builderall Mailingboss Integration With Cold Email Sales Tool",
    "h1": "Integrate Builderall Mailingboss with SalesBlink",
    "metaDescription": "Integrate Builderall Mailingboss and other Email Newsletters Tools with SalesBlink. Two Way Builderall Mailingboss Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pushpress",
    "slug": "pushpress",
    "logo": "https://zapier-images.imgix.net/storage/developer/3fed5c0cedb23598b37aebe56639098e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PushPress is a boutique fitness business management platform focused on saving our clients time, money, and headaches.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pushpress Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushpress with SalesBlink",
    "metaDescription": "Integrate Pushpress and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pushpress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trengo",
    "slug": "trengo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef0f08bd8234544dceb7a357ce1f1f18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trengo is a team collaboration tool to manage conversations from channels such as web chat, email and WhatsApp via one inbox.",
    "category": "Customer Support",
    "title": "Trengo Integration With Cold Email Sales Tool",
    "h1": "Integrate Trengo with SalesBlink",
    "metaDescription": "Integrate Trengo and other Customer Support Tools with SalesBlink. Two Way Trengo Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Viral Loops",
    "slug": "viral-loops",
    "logo": "https://zapier-images.imgix.net/storage/services/b1fb216ddc9bc2a6e50405e2165ffb66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Viral Loops is a referral marketing platform for busy marketers and founders.",
    "category": "Marketing Automation",
    "title": "Viral Loops Integration With Cold Email Sales Tool",
    "h1": "Integrate Viral Loops with SalesBlink",
    "metaDescription": "Integrate Viral Loops and other Marketing Automation Tools with SalesBlink. Two Way Viral Loops Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "FacturaDirecta",
    "slug": "facturadirecta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0150bf3fca18aa0274b5f18f05016fe7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FacturaDirecta is an easy accounting software for small business and freelancers.",
    "category": "Accounting",
    "title": "FacturaDirecta Integration With Cold Email Sales Tool",
    "h1": "Integrate FacturaDirecta with SalesBlink",
    "metaDescription": "Integrate FacturaDirecta and other Accounting Tools with SalesBlink. Two Way FacturaDirecta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Keystone Academic Solutions",
    "slug": "keystone-academic-solutions",
    "logo": "https://zapier-images.imgix.net/storage/developer/8b0d80fa6999a20030a43755d136bd85_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keystone Academic Solutions is a platform for higher education marketing and student enrollment.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Keystone Academic Solutions Integration With Cold Email Sales Tool",
    "h1": "Integrate Keystone Academic Solutions with SalesBlink",
    "metaDescription": "Integrate Keystone Academic Solutions and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Keystone Academic Solutions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NetHunt CRM",
    "slug": "nethunt-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/7b04730f929634b98b9f14ca4634f028_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NetHunt CRM is a customer relationship management system for Gmail and Google Apps.",
    "category": "CRM (Customer Relationship Management)",
    "title": "NetHunt CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate NetHunt CRM with SalesBlink",
    "metaDescription": "Integrate NetHunt CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way NetHunt CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EasyCSV",
    "slug": "easycsv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cb0fa5e4ebcd638b81571cabf681727e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyCSV enables your users or team members to import CSV files and Google Sheets into your app or any public api.",
    "category": "Spreadsheets",
    "title": "EasyCSV Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyCSV with SalesBlink",
    "metaDescription": "Integrate EasyCSV and other Spreadsheets Tools with SalesBlink. Two Way EasyCSV Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BugHerd",
    "slug": "bugherd",
    "logo": "https://zapier-images.imgix.net/storage/developer/e570e2914f55c0293d6eee1e7bd7e6ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A visual feedback tool for websites. It's like using sticky-notes to pin client feedback and track bugs directly on a page.",
    "category": "Developer Tools",
    "title": "BugHerd Integration With Cold Email Sales Tool",
    "h1": "Integrate BugHerd with SalesBlink",
    "metaDescription": "Integrate BugHerd and other Developer Tools Tools with SalesBlink. Two Way BugHerd Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SlickText",
    "slug": "slicktext",
    "logo": "https://zapier-images.imgix.net/storage/services/8ab936eee5779eb978902938789c276a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SlickText provides businesses and organizations with SMS marketing and mass text messaging solutions.",
    "category": "Phone & SMS",
    "title": "SlickText Integration With Cold Email Sales Tool",
    "h1": "Integrate SlickText with SalesBlink",
    "metaDescription": "Integrate SlickText and other Phone & SMS Tools with SalesBlink. Two Way SlickText Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PayKickstart",
    "slug": "paykickstart",
    "logo": "https://zapier-images.imgix.net/storage/services/aad9fa9b9173fbf47d86323ce328edc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PayKickstart is a shopping cart and affiliate management platform, empowering online entrepreneurs and digital publishers with the tools to sell their products online.",
    "category": "eCommerce",
    "title": "PayKickstart Integration With Cold Email Sales Tool",
    "h1": "Integrate PayKickstart with SalesBlink",
    "metaDescription": "Integrate PayKickstart and other eCommerce Tools with SalesBlink. Two Way PayKickstart Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Content Snare",
    "slug": "content-snare",
    "logo": "https://zapier-images.imgix.net/storage/services/8b0f81f08dc417cf3050c6cf2eb70676.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Content Snare is a tool that helps collect content and files from clients on time without email",
    "category": "Forms & Surveys",
    "title": "Content Snare Integration With Cold Email Sales Tool",
    "h1": "Integrate Content Snare with SalesBlink",
    "metaDescription": "Integrate Content Snare and other Forms & Surveys Tools with SalesBlink. Two Way Content Snare Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "MobileMonkey",
    "slug": "mobilemonkey",
    "logo": "https://zapier-images.imgix.net/storage/services/240c59cb33fe0f27b099e87cfae9fc59.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MobileMonkey is a Chatbot and Messenger Marketing platform. Use dynamic conversations to power your brand's presence where your customers communicate: on Facebook Messenger and other leading chat platforms.",
    "category": "Marketing Automation",
    "title": "MobileMonkey Integration With Cold Email Sales Tool",
    "h1": "Integrate MobileMonkey with SalesBlink",
    "metaDescription": "Integrate MobileMonkey and other Marketing Automation Tools with SalesBlink. Two Way MobileMonkey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoTo Connect",
    "slug": "go-to-connect",
    "logo": "https://zapier-images.imgix.net/storage/services/136685379ba6a5b14c1f2a074caa5641.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoTo Connect is a fully integrated business communications solution, with calls, meetings and messaging unified on one platform.",
    "category": "Communication",
    "title": "GoTo Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate GoTo Connect with SalesBlink",
    "metaDescription": "Integrate GoTo Connect and other Communication Tools with SalesBlink. Two Way GoTo Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Acumbamail",
    "slug": "acumbamail",
    "logo": "https://zapier-images.imgix.net/storage/services/3f683f31f0a9c2658803bf5f4501f982.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Acumbamail is a simple and effective Email Marketing tool for everyone.",
    "category": "Email Newsletters",
    "title": "Acumbamail Integration With Cold Email Sales Tool",
    "h1": "Integrate Acumbamail with SalesBlink",
    "metaDescription": "Integrate Acumbamail and other Email Newsletters Tools with SalesBlink. Two Way Acumbamail Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Service Provider Pro",
    "slug": "service-provider-pro",
    "logo": "https://zapier-images.imgix.net/storage/services/d4d32be82ef1a95a7a4d8a6d0e5dd906.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SPP is a shopping cart and project management software for selling productized services.",
    "category": "Project Management",
    "title": "Service Provider Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Service Provider Pro with SalesBlink",
    "metaDescription": "Integrate Service Provider Pro and other Project Management Tools with SalesBlink. Two Way Service Provider Pro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zipwhip",
    "slug": "zipwhip",
    "logo": "https://zapier-images.imgix.net/storage/developer/5d64990895debf46d9fe7aa75221af95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zipwhip is a text messaging platform which enables you to send and receive texts to and from an existing landline or toll free number.",
    "category": "Phone & SMS",
    "title": "Zipwhip Integration With Cold Email Sales Tool",
    "h1": "Integrate Zipwhip with SalesBlink",
    "metaDescription": "Integrate Zipwhip and other Phone & SMS Tools with SalesBlink. Two Way Zipwhip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BigMarker",
    "slug": "bigmarker",
    "logo": "https://zapier-images.imgix.net/storage/developer/6a84d74ce9a076a80d9e5ed5d393d26b_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A platform to host webinars, online workshops, and stream events.",
    "category": "Webinars",
    "title": "BigMarker Integration With Cold Email Sales Tool",
    "h1": "Integrate BigMarker with SalesBlink",
    "metaDescription": "Integrate BigMarker and other Webinars Tools with SalesBlink. Two Way BigMarker Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "NeonCRM",
    "slug": "neoncrm",
    "logo": "https://zapier-images.imgix.net/storage/developer/a9aff86749a0dd3ad371d7c72b19e113.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Neon is a CRM built by and for people who run nonprofits. Our nonprofit solutions grow communities, inspire audiences and scale your impact.",
    "category": "CRM (Customer Relationship Management)",
    "title": "NeonCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate NeonCRM with SalesBlink",
    "metaDescription": "Integrate NeonCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way NeonCRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Popupsmart",
    "slug": "popupsmart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cc25b5285661e11954a4103af88d26d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Popupsmart is an easy and powerful way to generate leads and rapid growth.",
    "category": "eCommerce",
    "title": "Popupsmart Integration With Cold Email Sales Tool",
    "h1": "Integrate Popupsmart with SalesBlink",
    "metaDescription": "Integrate Popupsmart and other eCommerce Tools with SalesBlink. Two Way Popupsmart Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Method CRM",
    "slug": "method-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/1de15425577dd98cae0f8a5b7fe9c781.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Method CRM™ is a QuickBooks CRM that streamlines your business from lead to repeat and everything in between.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Method CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Method CRM with SalesBlink",
    "metaDescription": "Integrate Method CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Method CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadQuizzes 3",
    "slug": "leadquizzes3",
    "logo": "https://zapier-images.imgix.net/storage/developer/1fbdc3ef43b1d30abafc0942fd6e79e4_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadQuizzes 3 allows you to build interactive content to generate leads, collect data and personalize your marketing.",
    "category": "Forms & Surveys",
    "title": "LeadQuizzes 3 Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadQuizzes 3 with SalesBlink",
    "metaDescription": "Integrate LeadQuizzes 3 and other Forms & Surveys Tools with SalesBlink. Two Way LeadQuizzes 3 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Canny",
    "slug": "canny",
    "logo": "https://zapier-images.imgix.net/storage/developer/243675ab8777ce6e95542c89caec544c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canny is a user feedback tool that lets you keep track of all of your user feedback in one organized place.",
    "category": "Product Management",
    "title": "Canny Integration With Cold Email Sales Tool",
    "h1": "Integrate Canny with SalesBlink",
    "metaDescription": "Integrate Canny and other Product Management Tools with SalesBlink. Two Way Canny Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Rex",
    "slug": "rex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7ba62c34eafcfc7bfcbae0a9231b970.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thousands of agencies across Aus, NZ and UK are powered by Rex's cloud and mobile real estate platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Rex Integration With Cold Email Sales Tool",
    "h1": "Integrate Rex with SalesBlink",
    "metaDescription": "Integrate Rex and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Rex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AdRoll",
    "slug": "adroll",
    "logo": "https://zapier-images.imgix.net/storage/developer/fe17f37c26c520c41b52c3e4fa2cd400.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AdRoll helps you advertise to potential customers (website visitors or email addresses) and make them come to your site and convert!",
    "category": "Ads & Conversion",
    "title": "AdRoll Integration With Cold Email Sales Tool",
    "h1": "Integrate AdRoll with SalesBlink",
    "metaDescription": "Integrate AdRoll and other Ads & Conversion Tools with SalesBlink. Two Way AdRoll Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Filevine",
    "slug": "filevine",
    "logo": "https://zapier-images.imgix.net/storage/services/8337f86efab8426f69f61da42f2e3ab9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Filevine is a modern case management system for law firms.",
    "category": "Project Management",
    "title": "Filevine Integration With Cold Email Sales Tool",
    "h1": "Integrate Filevine with SalesBlink",
    "metaDescription": "Integrate Filevine and other Project Management Tools with SalesBlink. Two Way Filevine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StreetText",
    "slug": "street-text",
    "logo": "https://zapier-images.imgix.net/storage/developer/c2987dce6e791c3376ed8d26ceef1011.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead generation funnel automation + flexible smart forms.",
    "category": "Marketing Automation",
    "title": "StreetText Integration With Cold Email Sales Tool",
    "h1": "Integrate StreetText with SalesBlink",
    "metaDescription": "Integrate StreetText and other Marketing Automation Tools with SalesBlink. Two Way StreetText Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobProgress",
    "slug": "jobprogress",
    "logo": "https://zapier-images.imgix.net/storage/services/86f3ee01bcf1b55968aed99a4a209dad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobProgress is a business productivity platform for all home improvement contractors",
    "category": "CRM (Customer Relationship Management)",
    "title": "JobProgress Integration With Cold Email Sales Tool",
    "h1": "Integrate JobProgress with SalesBlink",
    "metaDescription": "Integrate JobProgress and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way JobProgress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outseta",
    "slug": "outseta",
    "logo": "https://zapier-images.imgix.net/storage/services/562874b7fae1cfcaf1cfd443ff0f663a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outseta is a technology stack for early stage SaaS companies. The platform fully integrates CRM, subscription billing & management, and customer success tools (email, chat, help desk).",
    "category": "CRM (Customer Relationship Management)",
    "title": "Outseta Integration With Cold Email Sales Tool",
    "h1": "Integrate Outseta with SalesBlink",
    "metaDescription": "Integrate Outseta and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Outseta Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DEAR Inventory",
    "slug": "dear-inventory",
    "logo": "https://zapier-images.imgix.net/storage/services/8b06b1bd9ba89a3ab3bb742a74f2b064.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DEAR is an all in one holistic solution for Inventory Management, POS & B2B eCommerce. Easily manage all your inventory, purchases, sales, suppliers and customers in one easy to use system.",
    "category": "Accounting",
    "title": "DEAR Inventory Integration With Cold Email Sales Tool",
    "h1": "Integrate DEAR Inventory with SalesBlink",
    "metaDescription": "Integrate DEAR Inventory and other Accounting Tools with SalesBlink. Two Way DEAR Inventory Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SatisMeter",
    "slug": "satismeter",
    "logo": "https://zapier-images.imgix.net/storage/services/7ac97e3fd5eac7814cf61de8230657e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SatisMeter is a customer feedback tool that measures customer satisfaction (NPS) and monitors your product performance.",
    "category": "Forms & Surveys",
    "title": "SatisMeter Integration With Cold Email Sales Tool",
    "h1": "Integrate SatisMeter with SalesBlink",
    "metaDescription": "Integrate SatisMeter and other Forms & Surveys Tools with SalesBlink. Two Way SatisMeter Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Adafruit IO",
    "slug": "adafruit-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49c38e3f75247b407cd3ad1cf28b0b6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adafruit IO is a cloud service used primarily for storing and retrieving data but does much more.",
    "category": "Devices",
    "title": "Adafruit IO Integration With Cold Email Sales Tool",
    "h1": "Integrate Adafruit IO with SalesBlink",
    "metaDescription": "Integrate Adafruit IO and other Devices Tools with SalesBlink. Two Way Adafruit IO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mad Mimi",
    "slug": "mad-mimi",
    "logo": "https://zapier-images.imgix.net/storage/developer/48e54afd38e1cdf9cf44409a1dd233a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mad Mimi is the easiest and simplest way to create, send, share and track email newsletters.",
    "category": "Email Newsletters",
    "title": "Mad Mimi Integration With Cold Email Sales Tool",
    "h1": "Integrate Mad Mimi with SalesBlink",
    "metaDescription": "Integrate Mad Mimi and other Email Newsletters Tools with SalesBlink. Two Way Mad Mimi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sender",
    "slug": "sender",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c45a9ae9df127c2daf4f27fcca1a0ecf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sender is an amazing email marketing application.",
    "category": "Email Newsletters",
    "title": "Sender Integration With Cold Email Sales Tool",
    "h1": "Integrate Sender with SalesBlink",
    "metaDescription": "Integrate Sender and other Email Newsletters Tools with SalesBlink. Two Way Sender Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Attentive",
    "slug": "attentive",
    "logo": "https://zapier-images.imgix.net/storage/services/a8a555d4546128d6173c4462079ddc71_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Attentive® is the leader in conversational commerce, reinventing business to consumer communication. Our SMS-first software platform helps everyone from entrepreneurs to enterprises strengthen relationships with their consumers in a new way.",
    "category": "Marketing",
    "title": "Attentive Integration With Cold Email Sales Tool",
    "h1": "Integrate Attentive with SalesBlink",
    "metaDescription": "Integrate Attentive and other Marketing Tools with SalesBlink. Two Way Attentive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BatchDialer",
    "slug": "batchdialer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b7acab1bbdc6d59c99385363cafb0ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BatchDialer is a predictve dialing software for sales teams",
    "category": "CRM (Customer Relationship Management)",
    "title": "BatchDialer Integration With Cold Email Sales Tool",
    "h1": "Integrate BatchDialer with SalesBlink",
    "metaDescription": "Integrate BatchDialer and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BatchDialer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mercury Integrations",
    "slug": "mercury-integrations",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f1a1621fcdb7df41c1d6c76f2e5add7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mercury is an online financial platform that helps startups grow and manage their capital.",
    "category": "Accounting",
    "title": "Mercury Integrations Integration With Cold Email Sales Tool",
    "h1": "Integrate Mercury Integrations with SalesBlink",
    "metaDescription": "Integrate Mercury Integrations and other Accounting Tools with SalesBlink. Two Way Mercury Integrations Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Canopy",
    "slug": "canopy",
    "logo": "https://zapier-images.imgix.net/storage/services/b58e56f51fe5d9c92df2ff57e1850a03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canopy provides a practice efficiency suite that simplifies your practice so you can help more clients. Each of our products automates busywork and connects your entire practice so you can focus on what’s important.",
    "category": "Accounting",
    "title": "Canopy Integration With Cold Email Sales Tool",
    "h1": "Integrate Canopy with SalesBlink",
    "metaDescription": "Integrate Canopy and other Accounting Tools with SalesBlink. Two Way Canopy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "inSided",
    "slug": "insided",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b6191b79775b1da7a0de850925774f9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "inSided offers a customer success community platform for B2B software companies. We scale customer success by increasing self service and engagement with customers at crucial phases of their journey.",
    "category": "Customer Support",
    "title": "inSided Integration With Cold Email Sales Tool",
    "h1": "Integrate inSided with SalesBlink",
    "metaDescription": "Integrate inSided and other Customer Support Tools with SalesBlink. Two Way inSided Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Getsitecontrol",
    "slug": "getsitecontrol",
    "logo": "https://zapier-images.imgix.net/storage/services/e3c625e94cdf13df6b5ee7deedcad6e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Getsitecontrol is a website popup builder for ecommerce. It’s designed to help merchants promote special offers, upsell and cross-sell products, collect emails, conduct surveys, prevent cart abandonment — all without having to hire a developer.",
    "category": "Forms & Surveys",
    "title": "Getsitecontrol Integration With Cold Email Sales Tool",
    "h1": "Integrate Getsitecontrol with SalesBlink",
    "metaDescription": "Integrate Getsitecontrol and other Forms & Surveys Tools with SalesBlink. Two Way Getsitecontrol Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SavvyCal",
    "slug": "savvycal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48be17346b505ce9f6f0a18de63d1d92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SavvyCal is scheduling software that makes it easy for both parties to find the best time to meet.",
    "category": "Scheduling & Booking",
    "title": "SavvyCal Integration With Cold Email Sales Tool",
    "h1": "Integrate SavvyCal with SalesBlink",
    "metaDescription": "Integrate SavvyCal and other Scheduling & Booking Tools with SalesBlink. Two Way SavvyCal Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pivotal Tracker",
    "slug": "pivotal-tracker",
    "logo": "https://zapier-images.imgix.net/storage/services/84172dde558de382873cf72a00c92818.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pivotal Tracker is a collaborative, project management tool for developers and agile product management teams.",
    "category": "Project Management",
    "title": "Pivotal Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate Pivotal Tracker with SalesBlink",
    "metaDescription": "Integrate Pivotal Tracker and other Project Management Tools with SalesBlink. Two Way Pivotal Tracker Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Service Fusion",
    "slug": "service-fusion",
    "logo": "https://zapier-images.imgix.net/storage/services/42e83b668b18ec7a875dd9a9ea403fc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Service Fusion is a field service management software for service contractors. With this application, you create new customers, retrieve job and estimate history, technician information and much more.",
    "category": "eCommerce",
    "title": "Service Fusion Integration With Cold Email Sales Tool",
    "h1": "Integrate Service Fusion with SalesBlink",
    "metaDescription": "Integrate Service Fusion and other eCommerce Tools with SalesBlink. Two Way Service Fusion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadinfo",
    "slug": "leadinfo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d494280705ef9d2b9d799653e2893596.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Identify B2B website visitors, simple and realtime. Free 14-day trial and no credit card required. Inbox with no complicated statistics, view your visitors as you view your e-mail.",
    "category": "Marketing",
    "title": "Leadinfo Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadinfo with SalesBlink",
    "metaDescription": "Integrate Leadinfo and other Marketing Tools with SalesBlink. Two Way Leadinfo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Chatfuel",
    "slug": "chatfuel",
    "logo": "https://zapier-images.imgix.net/storage/services/6ab28121a4f5c52f2ee9aba35e8a6705.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chatfuel allows anyone to create their own bots on Facebook Messenger for free, without coding.  Using this app you'll be able to automatically update content in your bot.",
    "category": "Social Media Marketing",
    "title": "Chatfuel Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatfuel with SalesBlink",
    "metaDescription": "Integrate Chatfuel and other Social Media Marketing Tools with SalesBlink. Two Way Chatfuel Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Arlo",
    "slug": "arlo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed3fff7df423e13626ab8f2572745f83.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Arlo is a complete training management solution to promote, sell, and deliver courses and events.",
    "category": "Event Management",
    "title": "Arlo Integration With Cold Email Sales Tool",
    "h1": "Integrate Arlo with SalesBlink",
    "metaDescription": "Integrate Arlo and other Event Management Tools with SalesBlink. Two Way Arlo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Dotloop",
    "slug": "dotloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f59b3a05d2744244af23d6e6ca4f590.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dotloop is the complete real estate transaction management solution that empowers agents and brokers to get deals done.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Dotloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Dotloop with SalesBlink",
    "metaDescription": "Integrate Dotloop and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Dotloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickEmailVerification",
    "slug": "quickemailverification",
    "logo": "https://zapier-images.imgix.net/storage/developer/8610f4283da510bd2cd92a6388771a3b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickEmailVerification verifies emails in real-time at the entry point and prevents unreachable and low-quality emails from getting into your lists.",
    "category": "Email",
    "title": "QuickEmailVerification Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickEmailVerification with SalesBlink",
    "metaDescription": "Integrate QuickEmailVerification and other Email Tools with SalesBlink. Two Way QuickEmailVerification Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Nimble",
    "slug": "nimble",
    "logo": "https://zapier-images.imgix.net/storage/services/fd6409c1c08d2221666ff928a2ae145d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Social CRM Simplified. With Nimble’s social CRM, easily manage all your contacts, communications, activities and sales in one single place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Nimble Integration With Cold Email Sales Tool",
    "h1": "Integrate Nimble with SalesBlink",
    "metaDescription": "Integrate Nimble and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Nimble Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Survicate",
    "slug": "survicate",
    "logo": "https://zapier-images.imgix.net/storage/services/94046cb85554c3a65c788c12a05e0f34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Survicate is a customer feedback software that offers versatile survey solutions and a platform for cross-channel feedback management.",
    "category": "Forms & Surveys",
    "title": "Survicate Integration With Cold Email Sales Tool",
    "h1": "Integrate Survicate with SalesBlink",
    "metaDescription": "Integrate Survicate and other Forms & Surveys Tools with SalesBlink. Two Way Survicate Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BizConnect",
    "slug": "bizconnect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5def01e0e56606a3ce3bcdae6dea73b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BizConnect is an application for business card scanning and contact management which comes with attractive CRM features.",
    "category": "Productivity",
    "title": "BizConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate BizConnect with SalesBlink",
    "metaDescription": "Integrate BizConnect and other Productivity Tools with SalesBlink. Two Way BizConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GetProspect",
    "slug": "getprospect",
    "logo": "https://zapier-images.imgix.net/storage/services/ce834f8724d0e8d4b1f8bcde876b4086.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetProspect is a lead generation tool with email finder.",
    "category": "Contact Management",
    "title": "GetProspect Integration With Cold Email Sales Tool",
    "h1": "Integrate GetProspect with SalesBlink",
    "metaDescription": "Integrate GetProspect and other Contact Management Tools with SalesBlink. Two Way GetProspect Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zenkit",
    "slug": "zenkit",
    "logo": "https://zapier-images.imgix.net/storage/developer/ba35d4a5385b8c925e9092a8c9fc5291.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenkit is a flexible workspace you can use to organize the things you care about, in the way that suits you best.",
    "category": "Task Management",
    "title": "Zenkit Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenkit with SalesBlink",
    "metaDescription": "Integrate Zenkit and other Task Management Tools with SalesBlink. Two Way Zenkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Guru",
    "slug": "guru",
    "logo": "https://zapier-images.imgix.net/storage/services/a062cb3a3edf15dd2535015bc727894c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Guru is a collaborative knowledge management solution where answers find you—everywhere, every time. Guru connects teams and people to one another and surfaces the know-how of those who know best, and turns collections of information into useful knowledge. By delivering the right knowledge at the right time, Guru reduces new-hire ramp time, drives amazing customer experiences, and makes teams more collaborative and productive.",
    "category": "Team Collaboration",
    "title": "Guru Integration With Cold Email Sales Tool",
    "h1": "Integrate Guru with SalesBlink",
    "metaDescription": "Integrate Guru and other Team Collaboration Tools with SalesBlink. Two Way Guru Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Transistor.fm",
    "slug": "transistorfm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c945300c424cad59b484e2e5cc6bdbbb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transistor provides podcast hosting and analytics for thousands of organizations, brands, and creatives around the world.",
    "category": "Video & Audio",
    "title": "Transistor.fm Integration With Cold Email Sales Tool",
    "h1": "Integrate Transistor.fm with SalesBlink",
    "metaDescription": "Integrate Transistor.fm and other Video & Audio Tools with SalesBlink. Two Way Transistor.fm Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Wisepops",
    "slug": "wisepops",
    "logo": "https://zapier-images.imgix.net/storage/services/b15dd3bbc995ce886b2c042927913124.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wisepops is an on-site marketing platform used to grow your email list and boost sales. It allows you to display a message to any segment of your visitors using popups, sign-up bars, exit-intent popups, and on-site messaging without any dev needed.",
    "category": "Forms & Surveys",
    "title": "Wisepops Integration With Cold Email Sales Tool",
    "h1": "Integrate Wisepops with SalesBlink",
    "metaDescription": "Integrate Wisepops and other Forms & Surveys Tools with SalesBlink. Two Way Wisepops Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Smith.ai",
    "slug": "smithai-zapier-app-da100556",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2804bbe71bc9cd513655de93036bba68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smith.ai is a call answering & intake service for small & solo businesses. Our live, North America-based virtual receptionists capture & qualify leads, book new clients, and build better relationships with your existing clients.",
    "category": "Phone & SMS",
    "title": "Smith.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Smith.ai with SalesBlink",
    "metaDescription": "Integrate Smith.ai and other Phone & SMS Tools with SalesBlink. Two Way Smith.ai Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CloudSpot",
    "slug": "cloudspot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8182bf4ffbdbe30b02ba6eec956f1830.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudSpot is the fastest and easiest way for photographers to deliver, share, and sell their work!",
    "category": "File Management & Storage",
    "title": "CloudSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudSpot with SalesBlink",
    "metaDescription": "Integrate CloudSpot and other File Management & Storage Tools with SalesBlink. Two Way CloudSpot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Twist",
    "slug": "twist",
    "logo": "https://zapier-images.imgix.net/storage/services/a4216759c5e794f69a23fa4c005f776c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twist keeps your conversations on-topic and in one place. For teams who want to make work calmer, more organized, and more productive.",
    "category": "Team Chat",
    "title": "Twist Integration With Cold Email Sales Tool",
    "h1": "Integrate Twist with SalesBlink",
    "metaDescription": "Integrate Twist and other Team Chat Tools with SalesBlink. Two Way Twist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Aisle Planner",
    "slug": "aisle-planner",
    "logo": "https://zapier-images.imgix.net/storage/services/184e1a32af797eca3f0baca80f72a08b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aisle Planner started with the idea that there was a better way to plan weddings - that wedding professionals could have all of the wedding planning and client management tools they need, all in one reliable place. With everything from lead management and booking tools to checklists, day-of timelines and a guest manager, Aisle Planner has what wedding industry pros need to book clients quickly and manage the million and one details that come with the job.",
    "category": "Project Management",
    "title": "Aisle Planner Integration With Cold Email Sales Tool",
    "h1": "Integrate Aisle Planner with SalesBlink",
    "metaDescription": "Integrate Aisle Planner and other Project Management Tools with SalesBlink. Two Way Aisle Planner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WizeHire",
    "slug": "wizehire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcca022550c39c6dd3384b81ecb11c0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WizeHire helps small businesses find and hire the best talent by transforming the recruiting process.",
    "category": "HR Talent & Recruitment",
    "title": "WizeHire Integration With Cold Email Sales Tool",
    "h1": "Integrate WizeHire with SalesBlink",
    "metaDescription": "Integrate WizeHire and other HR Talent & Recruitment Tools with SalesBlink. Two Way WizeHire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Discourse",
    "slug": "discourse",
    "logo": "https://zapier-images.imgix.net/storage/services/df239233c57e7a982cea996436e651b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Discourse is an open source discussion platform used as a mailing list, discussion forum, long-form chat room, and more.",
    "category": "Social Media Accounts",
    "title": "Discourse Integration With Cold Email Sales Tool",
    "h1": "Integrate Discourse with SalesBlink",
    "metaDescription": "Integrate Discourse and other Social Media Accounts Tools with SalesBlink. Two Way Discourse Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "RevenueHunt",
    "slug": "revenuehunt",
    "logo": "https://zapier-images.imgix.net/storage/services/3460a3b255fa45c9d272bd703fd16380.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A product recommendation quiz for your eCommerce store.",
    "category": "eCommerce",
    "title": "RevenueHunt Integration With Cold Email Sales Tool",
    "h1": "Integrate RevenueHunt with SalesBlink",
    "metaDescription": "Integrate RevenueHunt and other eCommerce Tools with SalesBlink. Two Way RevenueHunt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobTread",
    "slug": "jobtread",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19bc918a4d6d5b694993233ae68a7e3c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobTread is construction estimating & project management software that will help you keep your budget and jobs on track.",
    "category": "Project Management",
    "title": "JobTread Integration With Cold Email Sales Tool",
    "h1": "Integrate JobTread with SalesBlink",
    "metaDescription": "Integrate JobTread and other Project Management Tools with SalesBlink. Two Way JobTread Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Act!",
    "slug": "act-premium",
    "logo": "https://zapier-images.imgix.net/storage/developer/5a890bce75098892cfa2a99903260ee7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collaborate with your team to stay up-to-date on the latest contacts and automate best practice sales processes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Act! Integration With Cold Email Sales Tool",
    "h1": "Integrate Act! with SalesBlink",
    "metaDescription": "Integrate Act! and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Act! Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho WorkDrive",
    "slug": "zoho-workdrive",
    "logo": "https://zapier-images.imgix.net/storage/developer/8cb8eb3a593ebf713d02af4407930c47.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho WorkDrive is an online team file manager. It gives you a secure, shared workspace, new ways to share files, and simplifies team management.",
    "category": "File Management & Storage",
    "title": "Zoho WorkDrive Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho WorkDrive with SalesBlink",
    "metaDescription": "Integrate Zoho WorkDrive and other File Management & Storage Tools with SalesBlink. Two Way Zoho WorkDrive Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Hibob",
    "slug": "hibob",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd7773ff8a0212314b801a202143886b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hibob was founded to modernize HR tech. Hibob's intuitive and data-driven platform, bob, was built for the way people work today: globally, remotely, and collaboratively. Use it for everything from onboarding and compensation management, to culture and people analytics.",
    "category": "HR Talent & Recruitment",
    "title": "Hibob Integration With Cold Email Sales Tool",
    "h1": "Integrate Hibob with SalesBlink",
    "metaDescription": "Integrate Hibob and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hibob Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Billsby",
    "slug": "billsby",
    "logo": "https://zapier-images.imgix.net/storage/services/d9600a822de2172581a693b41d007d58.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Billsby is a billing service you can rely on for easy and accurate billing whatever your subscription business is.",
    "category": "Payment Processing",
    "title": "Billsby Integration With Cold Email Sales Tool",
    "h1": "Integrate Billsby with SalesBlink",
    "metaDescription": "Integrate Billsby and other Payment Processing Tools with SalesBlink. Two Way Billsby Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Timely Time Tracking",
    "slug": "timely-time-tracking",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/396784d1860427b15383008d61f2a5b7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timely Time Tracking automates company time tracking, so everyone can focus on the work that matters.",
    "category": "Time Tracking Software",
    "title": "Timely Time Tracking Integration With Cold Email Sales Tool",
    "h1": "Integrate Timely Time Tracking with SalesBlink",
    "metaDescription": "Integrate Timely Time Tracking and other Time Tracking Software Tools with SalesBlink. Two Way Timely Time Tracking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zid",
    "slug": "zid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5801dc4b2b04230074be307e61374919_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zid is an e-commerce solution that is designed to empower retailers to start and scale their business using the power of eCommerce by providing them with the following :\n**E-Store:**\n**Ecosystem**\n**Empowerment**\n**Community**",
    "category": "eCommerce",
    "title": "Zid Integration With Cold Email Sales Tool",
    "h1": "Integrate Zid with SalesBlink",
    "metaDescription": "Integrate Zid and other eCommerce Tools with SalesBlink. Two Way Zid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MaintainX",
    "slug": "maintainx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82d83bbe68143be8c0d88e74fa92aca2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MaintainX helps you track your reactive maintenance, preventive maintenance, and control the daily operations of your business.",
    "category": "Task Management",
    "title": "MaintainX Integration With Cold Email Sales Tool",
    "h1": "Integrate MaintainX with SalesBlink",
    "metaDescription": "Integrate MaintainX and other Task Management Tools with SalesBlink. Two Way MaintainX Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SkedPal",
    "slug": "skedpal",
    "logo": "https://zapier-images.imgix.net/storage/services/e2f2c6ced7bfb9926eb43b3bbe507a20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SkedPal is a smart calendar app that schedules your to-do's based on your commitments and preferences.",
    "category": "Task Management",
    "title": "SkedPal Integration With Cold Email Sales Tool",
    "h1": "Integrate SkedPal with SalesBlink",
    "metaDescription": "Integrate SkedPal and other Task Management Tools with SalesBlink. Two Way SkedPal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BaseLinker",
    "slug": "baselinker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4bd8c14deb91c759e4221b10587a0c0e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BaseLinker is a cloud-based system integrating 500+ marketplaces, stores, carriers, accounting and many more. Handle orders 10x faster!",
    "category": "Commerce",
    "title": "BaseLinker Integration With Cold Email Sales Tool",
    "h1": "Integrate BaseLinker with SalesBlink",
    "metaDescription": "Integrate BaseLinker and other Commerce Tools with SalesBlink. Two Way BaseLinker Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Aesthetic Record EMR",
    "slug": "aesthetic-record-emr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1f8a06afc297f6c6668134123d50736.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aesthetic Record EMR is A Complete Practice Management & EMR Solution in the Industry. Software Designed To Make Growing Your Aesthetic Practice Easy.",
    "category": "Contact Management",
    "title": "Aesthetic Record EMR Integration With Cold Email Sales Tool",
    "h1": "Integrate Aesthetic Record EMR with SalesBlink",
    "metaDescription": "Integrate Aesthetic Record EMR and other Contact Management Tools with SalesBlink. Two Way Aesthetic Record EMR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PartnerStack",
    "slug": "partnerstack",
    "logo": "https://zapier-images.imgix.net/storage/services/feed11ab40f52c4625223bd95f2198ff_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PartnerStack (formerly GrowSumo) is a growth automation platform that helps your company automatically manage and reward partners for promoting your business. Fully equip your partners for marketing, referral, and sales success with custom referral links and company pages. Engage partners through automated email campaigns and challenges, and then track partner performance with detailed analytics. Use the centralized dashboard to support all of your partner channels, including reseller, marketing, and referral partner programs. You can even ensure global compliance by funnelling all rewards through PartnerStack.",
    "category": "Marketing Automation",
    "title": "PartnerStack Integration With Cold Email Sales Tool",
    "h1": "Integrate PartnerStack with SalesBlink",
    "metaDescription": "Integrate PartnerStack and other Marketing Automation Tools with SalesBlink. Two Way PartnerStack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gong",
    "slug": "gong",
    "logo": "https://zapier-images.imgix.net/storage/services/6ec110948d88c0d28dec1c24bdc14578.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gong has helped thousands of sales pros have better sales conversations every day.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Gong Integration With Cold Email Sales Tool",
    "h1": "Integrate Gong with SalesBlink",
    "metaDescription": "Integrate Gong and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Gong Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SmartSuite",
    "slug": "smartsuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/587a1d4ad310f746cefb0a68b1312ba0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartSuite is transforming how organizations get work done by providing a collaborative Work Management platform that enables teams to plan, track and manage workflows - whether it be a project, an ongoing process or routine everyday tasks.",
    "category": "Productivity",
    "title": "SmartSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartSuite with SalesBlink",
    "metaDescription": "Integrate SmartSuite and other Productivity Tools with SalesBlink. Two Way SmartSuite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Spotio",
    "slug": "spotio2",
    "logo": "https://zapier-images.imgix.net/storage/developer/fdee8f2f572c8d37ad89904270495490.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SPOTIO is a field sales enablement tool used by field sales reps and teams to increase efficiency, productivity and of course revenue.",
    "category": "Sales & CRM",
    "title": "Spotio Integration With Cold Email Sales Tool",
    "h1": "Integrate Spotio with SalesBlink",
    "metaDescription": "Integrate Spotio and other Sales & CRM Tools with SalesBlink. Two Way Spotio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Breezy HR",
    "slug": "breezy-hr",
    "logo": "https://zapier-images.imgix.net/storage/developer/e1b56d27d3a4ab5cbf907ef8cab0b5a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A uniquely simple, visual recruiting tool and applicant tracking system.",
    "category": "HR Talent & Recruitment",
    "title": "Breezy HR Integration With Cold Email Sales Tool",
    "h1": "Integrate Breezy HR with SalesBlink",
    "metaDescription": "Integrate Breezy HR and other HR Talent & Recruitment Tools with SalesBlink. Two Way Breezy HR Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "BONZO",
    "slug": "bonzo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05772993bbdb47b60a70a3214243faf7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bonzo is a multi-channel communication tool that keeps you top of mind.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BONZO Integration With Cold Email Sales Tool",
    "h1": "Integrate BONZO with SalesBlink",
    "metaDescription": "Integrate BONZO and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BONZO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MarketSharp",
    "slug": "marketsharp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00b9a17bc8480211d1206e9b3855a0d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MarketSharp is a CRM program for contractors, remodelers, and home improvement professionals. It helps companies organize their leads, customers, and jobs, as well as automate their business processes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MarketSharp Integration With Cold Email Sales Tool",
    "h1": "Integrate MarketSharp with SalesBlink",
    "metaDescription": "Integrate MarketSharp and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MarketSharp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pike13",
    "slug": "pike13",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61da081a9e12a193e24d200f58f95443.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The mobile-friendly Pike13 platform allows class-based businesses like strength and conditioning gyms, yoga studios, and pilates studios to manage everything from clients and schedules to billing and payroll.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pike13 Integration With Cold Email Sales Tool",
    "h1": "Integrate Pike13 with SalesBlink",
    "metaDescription": "Integrate Pike13 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pike13 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octoparse",
    "slug": "octoparse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28dfe18190b5396f78f2fba4517a36db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octoparse is a web-scraping tool to extract unstructured data from websites and organize in structured formats.",
    "category": "Support",
    "title": "Octoparse Integration With Cold Email Sales Tool",
    "h1": "Integrate Octoparse with SalesBlink",
    "metaDescription": "Integrate Octoparse and other Support Tools with SalesBlink. Two Way Octoparse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Greenhouse",
    "slug": "greenhouse",
    "logo": "https://zapier-images.imgix.net/storage/services/a089e0eaf009d853700c6fa8c9e40157.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Greenhouse is a hiring software company. We create the technology, know-how and support for your business' hiring needs.",
    "category": "HR Talent & Recruitment",
    "title": "Greenhouse Integration With Cold Email Sales Tool",
    "h1": "Integrate Greenhouse with SalesBlink",
    "metaDescription": "Integrate Greenhouse and other HR Talent & Recruitment Tools with SalesBlink. Two Way Greenhouse Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Agendor",
    "slug": "agendor",
    "logo": "https://zapier-images.imgix.net/storage/services/0b5580278bdc897045fef3afad6a5260_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agendor is a sales improvement platform with web and mobile version designed for Brazilian companies with long sales cycles.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agendor Integration With Cold Email Sales Tool",
    "h1": "Integrate Agendor with SalesBlink",
    "metaDescription": "Integrate Agendor and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agendor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Podium",
    "slug": "podium",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3ce60d53a0deac0c096872bd971d11c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Podium is a messaging platform that helps you get more reviews, collect payments, send SMS campaigns, and centralize your communications",
    "category": "Communication",
    "title": "Podium Integration With Cold Email Sales Tool",
    "h1": "Integrate Podium with SalesBlink",
    "metaDescription": "Integrate Podium and other Communication Tools with SalesBlink. Two Way Podium Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rocket.Chat",
    "slug": "rocketchat",
    "logo": "https://zapier-images.imgix.net/storage/services/97f9ac840ce989b9907d5963b912dd38.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rocket.Chat is an Open Source team communication platform: features from team chat to helpdesk live-chat, video conferencing and many more.",
    "category": "Team Chat",
    "title": "Rocket.Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Rocket.Chat with SalesBlink",
    "metaDescription": "Integrate Rocket.Chat and other Team Chat Tools with SalesBlink. Two Way Rocket.Chat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Envoy for Visitors",
    "slug": "envoy",
    "logo": "https://zapier-images.imgix.net/storage/services/1130a1b78d6080bed182138450b8d9e4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Envoy for Visitors is a fully customizable iPad app that replaces your visitor sign-in book. We provide seamless visitor management, from pre-registration and host notifications, to NDA signing and secure cloud-based data storage.",
    "category": "Event Management",
    "title": "Envoy for Visitors Integration With Cold Email Sales Tool",
    "h1": "Integrate Envoy for Visitors with SalesBlink",
    "metaDescription": "Integrate Envoy for Visitors and other Event Management Tools with SalesBlink. Two Way Envoy for Visitors Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PT Distinction",
    "slug": "pt-distinction",
    "logo": "https://zapier-images.imgix.net/storage/developer/40fd78ce0d61b41513c49fd0ccb4d5e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PT Distinction enables overworked personal trainers to train more clients, take more time off, and get even better results.",
    "category": "Fitness",
    "title": "PT Distinction Integration With Cold Email Sales Tool",
    "h1": "Integrate PT Distinction with SalesBlink",
    "metaDescription": "Integrate PT Distinction and other Fitness Tools with SalesBlink. Two Way PT Distinction Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nudgify",
    "slug": "nudgify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ef10ba0b1c873655ca9dd7c67dc334e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nudgify is a Social Proof App and FOMO App that helps you build Trust and Increase Conversions on Shopify, WooCommerce and more.",
    "category": "Marketing",
    "title": "Nudgify Integration With Cold Email Sales Tool",
    "h1": "Integrate Nudgify with SalesBlink",
    "metaDescription": "Integrate Nudgify and other Marketing Tools with SalesBlink. Two Way Nudgify Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Dropcontact",
    "slug": "dropcontact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a0c59f2e43c5fe97cc1f4b3462344400.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dropcontact is a B2B 100% GDPR compliant solution to enrich your CRM's data",
    "category": "Contact Management",
    "title": "Dropcontact Integration With Cold Email Sales Tool",
    "h1": "Integrate Dropcontact with SalesBlink",
    "metaDescription": "Integrate Dropcontact and other Contact Management Tools with SalesBlink. Two Way Dropcontact Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Beekeeper",
    "slug": "beekeeper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24d949b9ed44bcc232d30bc34b1efa45_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beekeeper is a mobile-first communication platform built for frontline employees that reaches every shift, location, and language through real-time messaging, targeted streams, and automated workflows.",
    "category": "Team Collaboration",
    "title": "Beekeeper Integration With Cold Email Sales Tool",
    "h1": "Integrate Beekeeper with SalesBlink",
    "metaDescription": "Integrate Beekeeper and other Team Collaboration Tools with SalesBlink. Two Way Beekeeper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mallabe Currencies",
    "slug": "mallabe-currencies",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6fb8f61e77e919a7851b0287f3f15210.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mallabe is an automation toolchain that allows you to convert / exchange currencies on the fly.\n\nTake a look at other mallabe Integrations:\n- mallabe Image Processing - for image processing",
    "category": "Accounting",
    "title": "mallabe Currencies Integration With Cold Email Sales Tool",
    "h1": "Integrate mallabe Currencies with SalesBlink",
    "metaDescription": "Integrate mallabe Currencies and other Accounting Tools with SalesBlink. Two Way mallabe Currencies Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zoho Analytics",
    "slug": "zoho-analytics",
    "logo": "https://zapier-images.imgix.net/storage/services/665661295888ed5098b01f7a0dcaacdd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Analytics is a self-service BI that lets you create visually appealing data visualizations and insightful dashboards.",
    "category": "Analytics",
    "title": "Zoho Analytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Analytics with SalesBlink",
    "metaDescription": "Integrate Zoho Analytics and other Analytics Tools with SalesBlink. Two Way Zoho Analytics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Steam",
    "slug": "steam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74e95576c85dc26018a567d004233d07_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Steam is a digital distribution platform developed by Valve Corporation for purchasing and playing video games.",
    "category": "Gaming",
    "title": "Steam Integration With Cold Email Sales Tool",
    "h1": "Integrate Steam with SalesBlink",
    "metaDescription": "Integrate Steam and other Gaming Tools with SalesBlink. Two Way Steam Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "InvestorLift",
    "slug": "investorlift",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb378645a6030b208dbc92acf7828f65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InvestorLift's software powers the most profitable real estate investing businesses in America.",
    "category": "Sales & CRM",
    "title": "InvestorLift Integration With Cold Email Sales Tool",
    "h1": "Integrate InvestorLift with SalesBlink",
    "metaDescription": "Integrate InvestorLift and other Sales & CRM Tools with SalesBlink. Two Way InvestorLift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CraftMyPDF.com",
    "slug": "craftmypdfcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/006128dee29a85cb012a4c66110d7c78_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CraftMyPDF.com allows you to create PDF documents from templates with a simple to use template editor and API.",
    "category": "Content & Files",
    "title": "CraftMyPDF.com Integration With Cold Email Sales Tool",
    "h1": "Integrate CraftMyPDF.com with SalesBlink",
    "metaDescription": "Integrate CraftMyPDF.com and other Content & Files Tools with SalesBlink. Two Way CraftMyPDF.com Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "AgentLocator",
    "slug": "agent-locator",
    "logo": "https://zapier-images.imgix.net/storage/services/14b6c37c2436c348f3fdc3536b198f8e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AgentLocator connects agents with buyers and sellers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AgentLocator Integration With Cold Email Sales Tool",
    "h1": "Integrate AgentLocator with SalesBlink",
    "metaDescription": "Integrate AgentLocator and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AgentLocator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ragic",
    "slug": "ragic",
    "logo": "https://zapier-images.imgix.net/storage/developer/abb62f74f78d39a7596e986655b1f8b0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ragic is an online database builder for creating real-world business applications using a simple Excel-like interface.",
    "category": "App Builder",
    "title": "Ragic Integration With Cold Email Sales Tool",
    "h1": "Integrate Ragic with SalesBlink",
    "metaDescription": "Integrate Ragic and other App Builder Tools with SalesBlink. Two Way Ragic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Amazon Alexa",
    "slug": "amazon-alexa",
    "logo": "https://zapier-images.imgix.net/storage/services/86c691535a876cc14a59ed7216b80ae5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Alexa is used to set up your Alexa-enabled devices, listen to music, create shopping lists, get news updates, and much more.",
    "category": "Amazon",
    "title": "Amazon Alexa Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Alexa with SalesBlink",
    "metaDescription": "Integrate Amazon Alexa and other Amazon Tools with SalesBlink. Two Way Amazon Alexa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Design Pickle",
    "slug": "design-pickle",
    "logo": "https://zapier-images.imgix.net/storage/services/6d27a36c1339b6f96d51f785be61f1f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Design Pickle is an unlimited design service that lets you outsource your design needs for a flat monthly fee.",
    "category": "Images & Design",
    "title": "Design Pickle Integration With Cold Email Sales Tool",
    "h1": "Integrate Design Pickle with SalesBlink",
    "metaDescription": "Integrate Design Pickle and other Images & Design Tools with SalesBlink. Two Way Design Pickle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lark",
    "slug": "lark",
    "logo": "https://zapier-images.imgix.net/storage/services/5876a57334d0761d20a821d84a5e86ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lark is the next-generation collaboration suite that enables teams to do their best work together. It integrates messaging, video conferencing, schedule management, collaborative documents, email and a variety of workplace applications into a single, mobile-first platform.",
    "category": "Productivity",
    "title": "Lark Integration With Cold Email Sales Tool",
    "h1": "Integrate Lark with SalesBlink",
    "metaDescription": "Integrate Lark and other Productivity Tools with SalesBlink. Two Way Lark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Accelo",
    "slug": "accelo",
    "logo": "https://zapier-images.imgix.net/storage/developer/93a23c7cd310288cd40df929988cb9b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Accelo manages all your client work in one place, making your business more profitable and you less stressed.",
    "category": "Project Management",
    "title": "Accelo Integration With Cold Email Sales Tool",
    "h1": "Integrate Accelo with SalesBlink",
    "metaDescription": "Integrate Accelo and other Project Management Tools with SalesBlink. Two Way Accelo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TimeRex",
    "slug": "timerex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9c2e971a5d8e3d36f8132f00835dca7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeRex is a schedule automation tool that saves you from the hassle of figuring out schedules, arranging meetings and keeping your calendar updated.",
    "category": "Calendar",
    "title": "TimeRex Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeRex with SalesBlink",
    "metaDescription": "Integrate TimeRex and other Calendar Tools with SalesBlink. Two Way TimeRex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xperiencify",
    "slug": "xperiencify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/095115e0c5d632df57d42fff44f44305.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xperiencify is an online course platform that builds 8 psychological triggers into your course.",
    "category": "Online Courses",
    "title": "Xperiencify Integration With Cold Email Sales Tool",
    "h1": "Integrate Xperiencify with SalesBlink",
    "metaDescription": "Integrate Xperiencify and other Online Courses Tools with SalesBlink. Two Way Xperiencify Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Skipio",
    "slug": "skipio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c24c4bf2382a9256eee6ae3ff9118c9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Skipio is a conversational business texting platform that helps you automate sales and customer communication.",
    "category": "Marketing",
    "title": "Skipio Integration With Cold Email Sales Tool",
    "h1": "Integrate Skipio with SalesBlink",
    "metaDescription": "Integrate Skipio and other Marketing Tools with SalesBlink. Two Way Skipio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buy Me a Coffee",
    "slug": "buy-me-a-coffee",
    "logo": "https://zapier-images.imgix.net/storage/developer/e9306ad830b3e8a5ec597e80f4342bfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A free, fast, and beautiful way to fund your creativity. It only takes a minute to start your page and has all the features to build your creative business.",
    "category": "Website Builders",
    "title": "Buy Me a Coffee Integration With Cold Email Sales Tool",
    "h1": "Integrate Buy Me a Coffee with SalesBlink",
    "metaDescription": "Integrate Buy Me a Coffee and other Website Builders Tools with SalesBlink. Two Way Buy Me a Coffee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gist",
    "slug": "gist",
    "logo": "https://zapier-images.imgix.net/storage/developer/2954c8b1468bddcd66f5fc7b3df7a4fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gist is an all-in-one email marketing automation, live chat & help desk platform.",
    "category": "Customer Support",
    "title": "Gist Integration With Cold Email Sales Tool",
    "h1": "Integrate Gist with SalesBlink",
    "metaDescription": "Integrate Gist and other Customer Support Tools with SalesBlink. Two Way Gist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "GymMaster",
    "slug": "gymmaster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a185994be0c33789c6e4c1ae100dee63_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GymMaster is an advanced membership management system helping simplify business for thousands of fitness clubs around the world.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GymMaster Integration With Cold Email Sales Tool",
    "h1": "Integrate GymMaster with SalesBlink",
    "metaDescription": "Integrate GymMaster and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GymMaster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trint",
    "slug": "trint",
    "logo": "https://zapier-images.imgix.net/storage/services/ac41a1f0d19f33c298722e44aae7ff0c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trint is an automated transcription (speech to text) service that allows users to upload audio and video media and receive a searchable, publishable transcript.",
    "category": "Transcription",
    "title": "Trint Integration With Cold Email Sales Tool",
    "h1": "Integrate Trint with SalesBlink",
    "metaDescription": "Integrate Trint and other Transcription Tools with SalesBlink. Two Way Trint Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ClockShark",
    "slug": "clockshark",
    "logo": "https://zapier-images.imgix.net/storage/services/16377c5c13c68f688acab3fca1c94054.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClockShark is a powerful and easy to use mobile time tracking app.",
    "category": "Time Tracking Software",
    "title": "ClockShark Integration With Cold Email Sales Tool",
    "h1": "Integrate ClockShark with SalesBlink",
    "metaDescription": "Integrate ClockShark and other Time Tracking Software Tools with SalesBlink. Two Way ClockShark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesforce Essentials",
    "slug": "salesforce-essentials",
    "logo": "https://zapier-images.imgix.net/storage/services/e9ceeab3a1ea86aacbc446e59c66b04a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesforce Essentials allows you to manage every customer conversation from anywhere. It's the fastest and easiest way to get started with CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salesforce Essentials Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesforce Essentials with SalesBlink",
    "metaDescription": "Integrate Salesforce Essentials and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salesforce Essentials Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebinarKit",
    "slug": "webinarkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbe4ed8d062acd71b11b17a07b41f303.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WebinarKit is a beautiful, modern, automated webinar platform that helps you get more sales.",
    "category": "Marketing",
    "title": "WebinarKit Integration With Cold Email Sales Tool",
    "h1": "Integrate WebinarKit with SalesBlink",
    "metaDescription": "Integrate WebinarKit and other Marketing Tools with SalesBlink. Two Way WebinarKit Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Umso",
    "slug": "umso",
    "logo": "https://zapier-images.imgix.net/storage/services/0d1ade0fe169feec667dd6f8a8b6690b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Umso is a website builder & platform for startups.",
    "category": "Website Builders",
    "title": "Umso Integration With Cold Email Sales Tool",
    "h1": "Integrate Umso with SalesBlink",
    "metaDescription": "Integrate Umso and other Website Builders Tools with SalesBlink. Two Way Umso Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "StockTwits",
    "slug": "stocktwits",
    "logo": "https://zapier-images.imgix.net/storage/developer/2ba0cb561679e5b388f82351a6f76cac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StockTwits is a financial communications platform for the investing community. You can easily learn from other stock traders and get new ideas.",
    "category": "Social Media Accounts",
    "title": "StockTwits Integration With Cold Email Sales Tool",
    "h1": "Integrate StockTwits with SalesBlink",
    "metaDescription": "Integrate StockTwits and other Social Media Accounts Tools with SalesBlink. Two Way StockTwits Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instagram Lead Ads",
    "slug": "instagram-lead-ads",
    "logo": "https://zapier-images.imgix.net/storage/services/30129480e943e5b3e334394168ae16a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instagram lead ads make signing up for business information easy for people and more valuable for businesses. The Instagram lead ads app is useful for marketers who want to automate actions on their leads.",
    "category": "Ads & Conversion",
    "title": "Instagram Lead Ads Integration With Cold Email Sales Tool",
    "h1": "Integrate Instagram Lead Ads with SalesBlink",
    "metaDescription": "Integrate Instagram Lead Ads and other Ads & Conversion Tools with SalesBlink. Two Way Instagram Lead Ads Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Untappd",
    "slug": "untappd",
    "logo": "https://zapier-images.imgix.net/storage/developer/89f207daa0a7c8142212760917d4bb7e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Untappd is a social network that lets users share and rate the beers they drink, also allowing breweries track reviews of their products.",
    "category": "Lifestyle & Entertainment",
    "title": "Untappd Integration With Cold Email Sales Tool",
    "h1": "Integrate Untappd with SalesBlink",
    "metaDescription": "Integrate Untappd and other Lifestyle & Entertainment Tools with SalesBlink. Two Way Untappd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailerSend",
    "slug": "mailersend",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfc2f02f871f2862c9d78991cbbae8ae_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MailerSend is a powerful transactional email system built for developers but designed so non-tech teams can contribute without coding.",
    "category": "Transactional Email",
    "title": "MailerSend Integration With Cold Email Sales Tool",
    "h1": "Integrate MailerSend with SalesBlink",
    "metaDescription": "Integrate MailerSend and other Transactional Email Tools with SalesBlink. Two Way MailerSend Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Judge.me",
    "slug": "judgeme",
    "logo": "https://zapier-images.imgix.net/storage/services/2a141390bbe502b1848b324e1a477ce7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Judge.me helps you collect and display product reviews, site reviews and Q&A in your e-commerce store (Shopify, WooCommerce and BigCommerce).",
    "category": "eCommerce",
    "title": "Judge.me Integration With Cold Email Sales Tool",
    "h1": "Integrate Judge.me with SalesBlink",
    "metaDescription": "Integrate Judge.me and other eCommerce Tools with SalesBlink. Two Way Judge.me Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SalesRabbit",
    "slug": "salesrabbit",
    "logo": "https://zapier-images.imgix.net/storage/developer/7a02365640c1bd33e24aab8d91fa84cb_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesRabbit is a sales enablement app that helps sales teams assign areas, track progress, and constantly get better at their craft.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesRabbit Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesRabbit with SalesBlink",
    "metaDescription": "Integrate SalesRabbit and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesRabbit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textlocal",
    "slug": "textlocal",
    "logo": "https://zapier-images.imgix.net/storage/developer/63b99be9016cf56b47a470465f5d3715.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A great tool for sending and receiving SMS texts. Allows you to send SMS texts to thousands of customers at once with no fuss. You can also include attachments, surveys and vouchers/tickets, all within a single SMS message.",
    "category": "Phone & SMS",
    "title": "Textlocal Integration With Cold Email Sales Tool",
    "h1": "Integrate Textlocal with SalesBlink",
    "metaDescription": "Integrate Textlocal and other Phone & SMS Tools with SalesBlink. Two Way Textlocal Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Livespace",
    "slug": "livespace",
    "logo": "https://zapier-images.imgix.net/storage/services/4a31d992da09c47c9e04d58fc40bbe1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Livespace is a sales CRM to help organise your teams,ensuring everyone knows the next step to winning their deals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Livespace Integration With Cold Email Sales Tool",
    "h1": "Integrate Livespace with SalesBlink",
    "metaDescription": "Integrate Livespace and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Livespace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avochato",
    "slug": "avochato",
    "logo": "https://zapier-images.imgix.net/storage/developer/c9bc41192f147f529c7a1f7a494e6ac2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Avochato is the text messaging solution for sales, marketing, and customer support teams.",
    "category": "Phone & SMS",
    "title": "Avochato Integration With Cold Email Sales Tool",
    "h1": "Integrate Avochato with SalesBlink",
    "metaDescription": "Integrate Avochato and other Phone & SMS Tools with SalesBlink. Two Way Avochato Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Bmby",
    "slug": "bmby-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/8450e28e0bb891f3bbf3a0b4ef70dd70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bmby is an all-inclusive system for managing all the marketing, sales, customer relations, and customer service.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bmby Integration With Cold Email Sales Tool",
    "h1": "Integrate Bmby with SalesBlink",
    "metaDescription": "Integrate Bmby and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bmby Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vidalytics",
    "slug": "vidalytics",
    "logo": "https://zapier-images.imgix.net/storage/developer/d345570ca796e3b5eed49c8d3d128231.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video hosting & analytics for marketers. See conversions in videos, mobile auto-play, in video Call-to-Actions, custom pause screens & more.",
    "category": "Video & Audio",
    "title": "Vidalytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Vidalytics with SalesBlink",
    "metaDescription": "Integrate Vidalytics and other Video & Audio Tools with SalesBlink. Two Way Vidalytics Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Active Collab",
    "slug": "activecollab",
    "logo": "https://zapier-images.imgix.net/storage/services/c8ba821d19d809a03f6a7c481130d09b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Active Collab is a popular, all-in-one tool for project management, team collaboration and billing. Available both in cloud and self hosted versions.",
    "category": "Project Management",
    "title": "Active Collab Integration With Cold Email Sales Tool",
    "h1": "Integrate Active Collab with SalesBlink",
    "metaDescription": "Integrate Active Collab and other Project Management Tools with SalesBlink. Two Way Active Collab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plezi",
    "slug": "plezi",
    "logo": "https://zapier-images.imgix.net/storage/developer/47bd989109d3dd2fc606211bbf748f9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plezi helps fast growing B2B companies to generate more leads thanks to their content.",
    "category": "Marketing Automation",
    "title": "Plezi Integration With Cold Email Sales Tool",
    "h1": "Integrate Plezi with SalesBlink",
    "metaDescription": "Integrate Plezi and other Marketing Automation Tools with SalesBlink. Two Way Plezi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MessageMedia SMS",
    "slug": "messagemedia",
    "logo": "https://zapier-images.imgix.net/storage/services/350f3c8e41274ddf2583c1e6834d9664.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MessageMedia SMS is a leading provider of SMS for business. Our reliable service includes reporting, 24/7 support, and competitive pricing.",
    "category": "Phone & SMS",
    "title": "MessageMedia SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate MessageMedia SMS with SalesBlink",
    "metaDescription": "Integrate MessageMedia SMS and other Phone & SMS Tools with SalesBlink. Two Way MessageMedia SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appointy",
    "slug": "appointy",
    "logo": "https://zapier-images.imgix.net/storage/developer/44133830f283dc7c4816d4fb0c4fb07f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appointy is an online scheduling system that automates booking, payments and reminders. It allows customers to view real time availability, book instantly, and pay easily for their next appointment, class or event.",
    "category": "Scheduling & Booking",
    "title": "Appointy Integration With Cold Email Sales Tool",
    "h1": "Integrate Appointy with SalesBlink",
    "metaDescription": "Integrate Appointy and other Scheduling & Booking Tools with SalesBlink. Two Way Appointy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazing Marvin",
    "slug": "amazing-marvin",
    "logo": "https://zapier-images.imgix.net/storage/services/6ae8654b6087859ee1d28ada55422df6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazing Marvin is a customizable task manager that is designed using behavioral psychology to help you get more done while feeling less stressed.",
    "category": "Task Management",
    "title": "Amazing Marvin Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazing Marvin with SalesBlink",
    "metaDescription": "Integrate Amazing Marvin and other Task Management Tools with SalesBlink. Two Way Amazing Marvin Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Bettermode",
    "slug": "bettermode",
    "logo": "https://zapier-images.imgix.net/storage/services/af9496fff9b7e53eb643e2835cb48526.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A powerful community platform, integrated into your product.",
    "category": "Social Media Accounts",
    "title": "Bettermode Integration With Cold Email Sales Tool",
    "h1": "Integrate Bettermode with SalesBlink",
    "metaDescription": "Integrate Bettermode and other Social Media Accounts Tools with SalesBlink. Two Way Bettermode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon SQS",
    "slug": "amazon-sqs",
    "logo": "https://zapier-images.imgix.net/storage/services/341c23cdd869b140d67386a3fda6ad99.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Simple Queue Service is a simple but powerful services for generating and consuming messages. It can help decouple systems for improved scalability and robustness.",
    "category": "Amazon",
    "title": "Amazon SQS Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon SQS with SalesBlink",
    "metaDescription": "Integrate Amazon SQS and other Amazon Tools with SalesBlink. Two Way Amazon SQS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QRTIGER QR Code",
    "slug": "qr-code-generator-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40d4d0916af5e2fd00f107bd1257b75e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QR Code Generator - Custom QR Code Maker and Creator with logo",
    "category": "Marketing",
    "title": "QRTIGER QR Code Integration With Cold Email Sales Tool",
    "h1": "Integrate QRTIGER QR Code with SalesBlink",
    "metaDescription": "Integrate QRTIGER QR Code and other Marketing Tools with SalesBlink. Two Way QRTIGER QR Code Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Klenty",
    "slug": "klenty",
    "logo": "https://zapier-images.imgix.net/storage/developer/db7f7f9592b0dd1d8a772e840b4397d2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Klenty is a sales engagement and lead generation tool that helps you send personalized emails and automated followups at scale.",
    "category": "Email",
    "title": "Klenty Integration With Cold Email Sales Tool",
    "h1": "Integrate Klenty with SalesBlink",
    "metaDescription": "Integrate Klenty and other Email Tools with SalesBlink. Two Way Klenty Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Horoshop",
    "slug": "horoshop",
    "logo": "https://zapier-images.imgix.net/storage/developer/aa31226c6ce0cf03437ae60a5879ab83_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Horoshop is an E-commerce platform that lets you to create powerful Online shops within a week.",
    "category": "eCommerce",
    "title": "Horoshop Integration With Cold Email Sales Tool",
    "h1": "Integrate Horoshop with SalesBlink",
    "metaDescription": "Integrate Horoshop and other eCommerce Tools with SalesBlink. Two Way Horoshop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teachery",
    "slug": "teachery",
    "logo": "https://zapier-images.imgix.net/storage/services/7dd0bb1c1ec46bf4a5b8a182f2e7f394.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teachery is an online course building platform. Create and sell beautiful online courses in minutes with no transaction fees!",
    "category": "Online Courses",
    "title": "Teachery Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachery with SalesBlink",
    "metaDescription": "Integrate Teachery and other Online Courses Tools with SalesBlink. Two Way Teachery Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Attio",
    "slug": "attio",
    "logo": "https://zapier-images.imgix.net/storage/services/fef89954993f8ee313da7ccd50a1eff4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Attio is the CRM of the future: data-driven, completely customizable and intuitively collaborative.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Attio Integration With Cold Email Sales Tool",
    "h1": "Integrate Attio with SalesBlink",
    "metaDescription": "Integrate Attio and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Attio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PropertyRadar",
    "slug": "propertyradar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ed750b1042d0565feec47e48e617fd8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PropertyRadar is the property data and owner information platform for discovering new customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PropertyRadar Integration With Cold Email Sales Tool",
    "h1": "Integrate PropertyRadar with SalesBlink",
    "metaDescription": "Integrate PropertyRadar and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PropertyRadar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jibble",
    "slug": "jibble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f5b2a8f594c30c46a4046e2e79b62ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jibble is a free time clock app that enables you to track when your staff are at work for attendance,\npayroll and projects. This app connects with Jibble 2 only.",
    "category": "Time Tracking Software",
    "title": "Jibble Integration With Cold Email Sales Tool",
    "h1": "Integrate Jibble with SalesBlink",
    "metaDescription": "Integrate Jibble and other Time Tracking Software Tools with SalesBlink. Two Way Jibble Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PayPro",
    "slug": "paypro",
    "logo": "https://zapier-images.imgix.net/storage/developer/847ba074b95724fbc1ca4124e175c6ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PayPro is a payment service provider combined with affiliate marketing services. We make it as easy as possible to sell your products.",
    "category": "Payment Processing",
    "title": "PayPro Integration With Cold Email Sales Tool",
    "h1": "Integrate PayPro with SalesBlink",
    "metaDescription": "Integrate PayPro and other Payment Processing Tools with SalesBlink. Two Way PayPro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freedcamp",
    "slug": "freedcamp",
    "logo": "https://zapier-images.imgix.net/storage/developer/e1d103359cdcd72039e46e82f576d40e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freedcamp is a project management tool equipt with all the tools you need to work with your team and organize any project.",
    "category": "Project Management",
    "title": "Freedcamp Integration With Cold Email Sales Tool",
    "h1": "Integrate Freedcamp with SalesBlink",
    "metaDescription": "Integrate Freedcamp and other Project Management Tools with SalesBlink. Two Way Freedcamp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Flock",
    "slug": "flock",
    "logo": "https://zapier-images.imgix.net/storage/developer/2b28e97447637d4e24cb591254387ef7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flock is a communication app for teams that speeds up and simplifies your work life. It lets you connect with your team, get on video calls, manage projects with to-dos, polls and reminders. All of this and more over an interface that's polished-to-perfection!",
    "category": "Team Chat",
    "title": "Flock Integration With Cold Email Sales Tool",
    "h1": "Integrate Flock with SalesBlink",
    "metaDescription": "Integrate Flock and other Team Chat Tools with SalesBlink. Two Way Flock Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Client Dispute Manager",
    "slug": "client-dispute",
    "logo": "https://zapier-images.imgix.net/storage/services/2ce54732502a1200b936e1a62bfc5fb5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Client Dispute Manager is an online Credit Repair Business Software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Client Dispute Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Client Dispute Manager with SalesBlink",
    "metaDescription": "Integrate Client Dispute Manager and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Client Dispute Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spark Membership",
    "slug": "spark-membership",
    "logo": "https://zapier-images.imgix.net/storage/developer/1db89d689221a1930e500969cc7a71d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spark Membership is an online CRM and marketing automation platform for Martial Arts, Kickboxing, Gyms and MMA studios.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Spark Membership Integration With Cold Email Sales Tool",
    "h1": "Integrate Spark Membership with SalesBlink",
    "metaDescription": "Integrate Spark Membership and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Spark Membership Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AskNicely",
    "slug": "asknicely",
    "logo": "https://zapier-images.imgix.net/storage/developer/623c412527858e3bb2e0a3d2bd774490.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AskNicely is a simple way to measure and improve customer happiness using the Net Promoter Score® (NPS) framework.",
    "category": "Forms & Surveys",
    "title": "AskNicely Integration With Cold Email Sales Tool",
    "h1": "Integrate AskNicely with SalesBlink",
    "metaDescription": "Integrate AskNicely and other Forms & Surveys Tools with SalesBlink. Two Way AskNicely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Raisely",
    "slug": "raisely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dee6a0e6e9f24632a712850b83a430ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Raisely is an online fundraising platform to help charities raise money online and nurture their supporters.",
    "category": "Fundraising",
    "title": "Raisely Integration With Cold Email Sales Tool",
    "h1": "Integrate Raisely with SalesBlink",
    "metaDescription": "Integrate Raisely and other Fundraising Tools with SalesBlink. Two Way Raisely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BrokerEngine",
    "slug": "brokerengine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db2f3d520c86d151956d65cc329c2c63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrokerEngine is the first and only mortgage broker software guaranteed to grow your revenue while working fewer hours, with push-button automation and workflows that free you up to focus on your highest-value activities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BrokerEngine Integration With Cold Email Sales Tool",
    "h1": "Integrate BrokerEngine with SalesBlink",
    "metaDescription": "Integrate BrokerEngine and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BrokerEngine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpLaunch",
    "slug": "uplaunch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4efc912607717bdd48053f6ac22c9097.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpLaunch is a CRM and marketing automation platform built specifically for the fitness industry.",
    "category": "Marketing Automation",
    "title": "UpLaunch Integration With Cold Email Sales Tool",
    "h1": "Integrate UpLaunch with SalesBlink",
    "metaDescription": "Integrate UpLaunch and other Marketing Automation Tools with SalesBlink. Two Way UpLaunch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Descript",
    "slug": "descript",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b6f761362e9a0abc3af47144281b306e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Descript is a tool for getting accurate, fast, and affordable automatic transcriptions of your audio and video media. Once your media is transcribed, you can also edit the text - media word processing.",
    "category": "AI Tools",
    "title": "Descript Integration With Cold Email Sales Tool",
    "h1": "Integrate Descript with SalesBlink",
    "metaDescription": "Integrate Descript and other AI Tools Tools with SalesBlink. Two Way Descript Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Referral Rock",
    "slug": "referral-rock",
    "logo": "https://zapier-images.imgix.net/storage/developer/eb0fb99f4cf744a66635708b0a23e40f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Referral Rock helps businesses to design, track and manage referral programs.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Referral Rock Integration With Cold Email Sales Tool",
    "h1": "Integrate Referral Rock with SalesBlink",
    "metaDescription": "Integrate Referral Rock and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Referral Rock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SuperSaaS",
    "slug": "supersaas",
    "logo": "https://zapier-images.imgix.net/storage/developer/68f7dbc14771fec514af01ff6bb81468.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online appointment scheduling for any type of business. Flexible and affordable booking software that can be integrated into any site.",
    "category": "Scheduling & Booking",
    "title": "SuperSaaS Integration With Cold Email Sales Tool",
    "h1": "Integrate SuperSaaS with SalesBlink",
    "metaDescription": "Integrate SuperSaaS and other Scheduling & Booking Tools with SalesBlink. Two Way SuperSaaS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Timeular",
    "slug": "timeular",
    "logo": "https://zapier-images.imgix.net/storage/services/585ec16cf87b0b8e8fef5e80047acf93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timeular - Effortless time tracking to help you lead a more productive life.",
    "category": "Productivity",
    "title": "Timeular Integration With Cold Email Sales Tool",
    "h1": "Integrate Timeular with SalesBlink",
    "metaDescription": "Integrate Timeular and other Productivity Tools with SalesBlink. Two Way Timeular Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Referrizer",
    "slug": "referrizer",
    "logo": "https://zapier-images.imgix.net/storage/services/dd06bfb024cb3ea5b7e8dfaa2503d074.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Referrizer combines CRM and all aspects of marketing automation into a single and easy-to-use platform, making customer acquisition and retention easy.",
    "category": "Marketing Automation",
    "title": "Referrizer Integration With Cold Email Sales Tool",
    "h1": "Integrate Referrizer with SalesBlink",
    "metaDescription": "Integrate Referrizer and other Marketing Automation Tools with SalesBlink. Two Way Referrizer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JivoChat",
    "slug": "jivochat",
    "logo": "https://zapier-images.imgix.net/storage/developer/47e9cc742efb49c6084f49496ca218f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JivoChat offers live chats on your website, chats with clients on Facebook, Emails, and calls in any country.",
    "category": "CRM (Customer Relationship Management)",
    "title": "JivoChat Integration With Cold Email Sales Tool",
    "h1": "Integrate JivoChat with SalesBlink",
    "metaDescription": "Integrate JivoChat and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way JivoChat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Maropost Commerce Cloud",
    "slug": "maropost-commerce-cloud",
    "logo": "https://zapier-images.imgix.net/storage/services/212c8cbff6b2c930a5cf32677a99882f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build an online store, process sales at your bricks-and-mortar store, and showcase your products through eBay and social media, all from one platform.",
    "category": "eCommerce",
    "title": "Maropost Commerce Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Maropost Commerce Cloud with SalesBlink",
    "metaDescription": "Integrate Maropost Commerce Cloud and other eCommerce Tools with SalesBlink. Two Way Maropost Commerce Cloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Benchmark Email",
    "slug": "benchmark-email",
    "logo": "https://zapier-images.imgix.net/storage/developer/b90022a2762814e9f5b92c29ba12720a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Benchmark is an international, easy-to-use email marketing and event management tool. Affordable, friendly and stylish.",
    "category": "Email Newsletters",
    "title": "Benchmark Email Integration With Cold Email Sales Tool",
    "h1": "Integrate Benchmark Email with SalesBlink",
    "metaDescription": "Integrate Benchmark Email and other Email Newsletters Tools with SalesBlink. Two Way Benchmark Email Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mailbox Power",
    "slug": "mailbox-power",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70b6fab97d51253dba064c2ca3ee4558.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailbox Power enables you to engage your customers and stay top of mind with your clients by sending personalized cards and gifts.",
    "category": "Marketing",
    "title": "Mailbox Power Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailbox Power with SalesBlink",
    "metaDescription": "Integrate Mailbox Power and other Marketing Tools with SalesBlink. Two Way Mailbox Power Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pixifi",
    "slug": "pixifi",
    "logo": "https://zapier-images.imgix.net/storage/developer/59bdf83775d7b0d134467ecad516fe03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pixifi is a business management app for creative professionals.",
    "category": "Event Management",
    "title": "Pixifi Integration With Cold Email Sales Tool",
    "h1": "Integrate Pixifi with SalesBlink",
    "metaDescription": "Integrate Pixifi and other Event Management Tools with SalesBlink. Two Way Pixifi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outplay",
    "slug": "outplay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70185eaafab1e8c3791dc169a79038d4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outplay is a sales engagement tool which helps in adding a personalized touch to the Emails, Calls and Social Actions.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Outplay Integration With Cold Email Sales Tool",
    "h1": "Integrate Outplay with SalesBlink",
    "metaDescription": "Integrate Outplay and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Outplay Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Google Tables",
    "slug": "google-tables",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b6d9fd6ec9ff78a0233980d6e91019ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Tables helps teams easily track & automate tasks, enabling them to save time and work smarter.",
    "category": "Databases",
    "title": "Google Tables Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Tables with SalesBlink",
    "metaDescription": "Integrate Google Tables and other Databases Tools with SalesBlink. Two Way Google Tables Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PreciseFP",
    "slug": "precisefp",
    "logo": "https://zapier-images.imgix.net/storage/developer/0ea419be24f5e1c1094d7d125f2cbf8d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PreciseFP is a data management platform that helps with your data-gathering process and automates your administrative work.",
    "category": "Forms & Surveys",
    "title": "PreciseFP Integration With Cold Email Sales Tool",
    "h1": "Integrate PreciseFP with SalesBlink",
    "metaDescription": "Integrate PreciseFP and other Forms & Surveys Tools with SalesBlink. Two Way PreciseFP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kiflo PRM",
    "slug": "kiflo-prm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b5ad759e03451ffa66de2a335694eda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kiflo PRM helps companies manage their partner relationships & accelerate revenue through indirect sales channels",
    "category": "Sales & CRM",
    "title": "Kiflo PRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Kiflo PRM with SalesBlink",
    "metaDescription": "Integrate Kiflo PRM and other Sales & CRM Tools with SalesBlink. Two Way Kiflo PRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mews",
    "slug": "mews",
    "logo": "https://zapier-images.imgix.net/storage/services/1820a1590c19636c62d672128fadda50.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mews is an innovative hospitality cloud that empowers the modern hotelier to improve performance, maximize revenue, and provide remarkable guest experiences.",
    "category": "Product Management",
    "title": "Mews Integration With Cold Email Sales Tool",
    "h1": "Integrate Mews with SalesBlink",
    "metaDescription": "Integrate Mews and other Product Management Tools with SalesBlink. Two Way Mews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Payfunnels",
    "slug": "payfunnels",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7642cb4ea78530350d9e656771d53e97_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payfunnels is a payment processing platform accepting recurring and one-time online payments, and even setting up payment plans to expire after a certain number of payments.",
    "category": "Payment Processing",
    "title": "Payfunnels Integration With Cold Email Sales Tool",
    "h1": "Integrate Payfunnels with SalesBlink",
    "metaDescription": "Integrate Payfunnels and other Payment Processing Tools with SalesBlink. Two Way Payfunnels Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LendingPad",
    "slug": "lendingpad",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03c5d4f783a4db1cf535c6782e60299c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LendingPad is a loan origination system to help streamline origination tasks, while strengthening communication and lowering the total cost of lending.",
    "category": "eCommerce",
    "title": "LendingPad Integration With Cold Email Sales Tool",
    "h1": "Integrate LendingPad with SalesBlink",
    "metaDescription": "Integrate LendingPad and other eCommerce Tools with SalesBlink. Two Way LendingPad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boulevard",
    "slug": "boulevard",
    "logo": "https://zapier-images.imgix.net/storage/services/237b6341693ed5464a16d5fde42cd9a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boulevard is the first client experience platform purpose-built to accelerate sales and eliminate stress for self-care, appointment-based businesses.",
    "category": "Scheduling & Booking",
    "title": "Boulevard Integration With Cold Email Sales Tool",
    "h1": "Integrate Boulevard with SalesBlink",
    "metaDescription": "Integrate Boulevard and other Scheduling & Booking Tools with SalesBlink. Two Way Boulevard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mercury Connect",
    "slug": "mercury",
    "logo": "https://zapier-images.imgix.net/storage/developer/06b769272c6cc4acb2c84d71823f8cbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mercury Connect is a CRM platform that lets you track people & opportunities, loan calculations, compare products, and manage compliance.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mercury Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Mercury Connect with SalesBlink",
    "metaDescription": "Integrate Mercury Connect and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mercury Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Picky Assist",
    "slug": "picky-assist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8620a5afb32ca856a761886aef88fce0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Picky Assist helps to connect your existing android mobile phone as a gateway to automate SMS & WhatsApp or Business can connect Official WhatsApp Business Account to interconnect with other apps",
    "category": "Notifications",
    "title": "Picky Assist Integration With Cold Email Sales Tool",
    "h1": "Integrate Picky Assist with SalesBlink",
    "metaDescription": "Integrate Picky Assist and other Notifications Tools with SalesBlink. Two Way Picky Assist Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Order Desk",
    "slug": "order-desk",
    "logo": "https://zapier-images.imgix.net/storage/developer/ff1ea609f85e831cb3996e2b87fd3c7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Order Desk's order management software allows you to customize your order management workflow.",
    "category": "eCommerce",
    "title": "Order Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate Order Desk with SalesBlink",
    "metaDescription": "Integrate Order Desk and other eCommerce Tools with SalesBlink. Two Way Order Desk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pinboard",
    "slug": "pinboard",
    "logo": "https://zapier-images.imgix.net/storage/developer/be6a90cd5893eae9a9c63fc96c63c1d4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pinboard is a fast, no-nonsense bookmarking site. No ads, no fluff.",
    "category": "Bookmark Managers",
    "title": "Pinboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Pinboard with SalesBlink",
    "metaDescription": "Integrate Pinboard and other Bookmark Managers Tools with SalesBlink. Two Way Pinboard Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sansan",
    "slug": "sansan",
    "logo": "https://zapier-images.imgix.net/storage/developer/a29450f0e1e0f9013c459c95157266d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sansan is a cloud based contact management system. Digitize your business cards with 99.9% accuracy and share the contact information with your colleagues. Save time and increase your business opportunities.",
    "category": "Contact Management",
    "title": "Sansan Integration With Cold Email Sales Tool",
    "h1": "Integrate Sansan with SalesBlink",
    "metaDescription": "Integrate Sansan and other Contact Management Tools with SalesBlink. Two Way Sansan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IXACT Contact CRM",
    "slug": "ixact-contact-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49d68022a3d502890340eefc492ba94f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IXACT Contact is a real estate CRM that helps agents stay organized and build relationships with clients, prospects and referral sources.",
    "category": "CRM (Customer Relationship Management)",
    "title": "IXACT Contact CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate IXACT Contact CRM with SalesBlink",
    "metaDescription": "Integrate IXACT Contact CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way IXACT Contact CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadsHook",
    "slug": "leadshook",
    "logo": "https://zapier-images.imgix.net/storage/services/d4f0c93f16ee0dae85b3654298b7e7f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadsHook is an app for creating personalized marketing including lead magnets and sales letters using decision trees.",
    "category": "Marketing Automation",
    "title": "LeadsHook Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadsHook with SalesBlink",
    "metaDescription": "Integrate LeadsHook and other Marketing Automation Tools with SalesBlink. Two Way LeadsHook Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Senta",
    "slug": "senta",
    "logo": "https://zapier-images.imgix.net/storage/services/0bc36fe2d646094563a900a165ff860b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Senta is a cloud practice management software including marketing, workflow, email automation, secure documents and insights for accountants and bookkeepers.",
    "category": "Accounting",
    "title": "Senta Integration With Cold Email Sales Tool",
    "h1": "Integrate Senta with SalesBlink",
    "metaDescription": "Integrate Senta and other Accounting Tools with SalesBlink. Two Way Senta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kixie",
    "slug": "kixie",
    "logo": "https://zapier-images.imgix.net/storage/developer/db388522e15cd9e63f2b06ea8db7a372.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seamless Integrated Voice\nPowerful voice and software tools for sales and customer service teams.",
    "category": "Phone & SMS",
    "title": "Kixie Integration With Cold Email Sales Tool",
    "h1": "Integrate Kixie with SalesBlink",
    "metaDescription": "Integrate Kixie and other Phone & SMS Tools with SalesBlink. Two Way Kixie Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ClickMeeting",
    "slug": "clickmeeting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b051531d23a2197f5357e4705dc060b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video conferencing software for product demos, training sessions, online courses, business meetings and webinars.",
    "category": "Video Conferencing",
    "title": "ClickMeeting Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickMeeting with SalesBlink",
    "metaDescription": "Integrate ClickMeeting and other Video Conferencing Tools with SalesBlink. Two Way ClickMeeting Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "AidaForm",
    "slug": "aidaform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/829bc9569fffc22589b54317ddd10b2f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AidaForm is a SaaS solution allowing you to create and publish online forms, Instagram Bio links, and landing pages. Collect and analyze response data, process and share the data with other services and tools.",
    "category": "Forms & Surveys",
    "title": "AidaForm Integration With Cold Email Sales Tool",
    "h1": "Integrate AidaForm with SalesBlink",
    "metaDescription": "Integrate AidaForm and other Forms & Surveys Tools with SalesBlink. Two Way AidaForm Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Quiz Maker",
    "slug": "quiz-maker",
    "logo": "https://zapier-images.imgix.net/storage/developer/130987d8b6824a9f011e1de158c1ed68_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quiz Maker is a service for creating and publishing quizzes, surveys, trivia, polls, online forms, personality tests and more.",
    "category": "Forms & Surveys",
    "title": "Quiz Maker Integration With Cold Email Sales Tool",
    "h1": "Integrate Quiz Maker with SalesBlink",
    "metaDescription": "Integrate Quiz Maker and other Forms & Surveys Tools with SalesBlink. Two Way Quiz Maker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nanonets OCR",
    "slug": "nanonets-ocr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e210f1ed1c7c0f0826efed002cd2d20_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nanonets OCR provides intelligent information extraction on any document or image",
    "category": "Developer Tools",
    "title": "Nanonets OCR Integration With Cold Email Sales Tool",
    "h1": "Integrate Nanonets OCR with SalesBlink",
    "metaDescription": "Integrate Nanonets OCR and other Developer Tools Tools with SalesBlink. Two Way Nanonets OCR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SugarCRM 6.2-5",
    "slug": "sugarcrm",
    "logo": "https://zapier-images.imgix.net/storage/services/aa190013edbbbbec1110ab5948f3ad9f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your sales, marketing, and customer support better with SugarCRM's online CRM software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SugarCRM 6.2-5 Integration With Cold Email Sales Tool",
    "h1": "Integrate SugarCRM 6.2-5 with SalesBlink",
    "metaDescription": "Integrate SugarCRM 6.2-5 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SugarCRM 6.2-5 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zenvia Conversion",
    "slug": "zenvia-conversion",
    "logo": "https://zapier-images.imgix.net/storage/services/7fc13805e2cfd0324828a4cd80a25a8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenvia Conversion helps you respond to WhatsApp, Instagram and Facebook Messenger through a centralized account and transform your sales processes in the messaging era.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zenvia Conversion Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenvia Conversion with SalesBlink",
    "metaDescription": "Integrate Zenvia Conversion and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zenvia Conversion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Missive",
    "slug": "missive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bc8ebbd135435719c8ecefdc6e150fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Email, group chat and tasks for productive teams. One app for all your internal and external communication.",
    "category": "Customer Support",
    "title": "Missive Integration With Cold Email Sales Tool",
    "h1": "Integrate Missive with SalesBlink",
    "metaDescription": "Integrate Missive and other Customer Support Tools with SalesBlink. Two Way Missive Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Callingly",
    "slug": "callingly",
    "logo": "https://zapier-images.imgix.net/storage/developer/3e1a8773e57f26db91478fa8f5766e06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Callingly is a call automation platform that gets salespeople on the phone with incoming online leads.",
    "category": "Phone & SMS",
    "title": "Callingly Integration With Cold Email Sales Tool",
    "h1": "Integrate Callingly with SalesBlink",
    "metaDescription": "Integrate Callingly and other Phone & SMS Tools with SalesBlink. Two Way Callingly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SendJim",
    "slug": "sendjim",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0f8f39d50327159dba66d37a5ec298e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendJim is a way to quickly send custom gifts, postcards, and more to your customers.",
    "category": "Marketing",
    "title": "SendJim Integration With Cold Email Sales Tool",
    "h1": "Integrate SendJim with SalesBlink",
    "metaDescription": "Integrate SendJim and other Marketing Tools with SalesBlink. Two Way SendJim Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magento",
    "slug": "magento",
    "logo": "https://zapier-images.imgix.net/storage/services/a6a5f122a12ab5e87161f859c33ea103.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magento is the premier open source e-commerce app used by millions of customers each and every day.",
    "category": "eCommerce",
    "title": "Magento Integration With Cold Email Sales Tool",
    "h1": "Integrate Magento with SalesBlink",
    "metaDescription": "Integrate Magento and other eCommerce Tools with SalesBlink. Two Way Magento Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whova",
    "slug": "whova",
    "logo": "https://zapier-images.imgix.net/storage/services/589ba9a7349440756ae62b25f257f262.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whova is an all-in-one event management solution that makes events modern and trendy, attracts and engages attendees effectively, and helps event organizers save time when managing event logistics.",
    "category": "Event Management",
    "title": "Whova Integration With Cold Email Sales Tool",
    "h1": "Integrate Whova with SalesBlink",
    "metaDescription": "Integrate Whova and other Event Management Tools with SalesBlink. Two Way Whova Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rev",
    "slug": "rev",
    "logo": "https://zapier-images.imgix.net/storage/developer/d999d26c412c23cd8a1e890320ad876f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rev is an audio transcription service that uses human beings to transcribe English audio or video into text.",
    "category": "Transcription",
    "title": "Rev Integration With Cold Email Sales Tool",
    "h1": "Integrate Rev with SalesBlink",
    "metaDescription": "Integrate Rev and other Transcription Tools with SalesBlink. Two Way Rev Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Phone.com",
    "slug": "phone-com",
    "logo": "https://zapier-images.imgix.net/storage/services/887be53e24e5b23b2b61203017496474_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Phone.com app allows you to send and receive SMS, integrate CRM, get events on calls and call logs and generate calls.",
    "category": "Phone & SMS",
    "title": "Phone.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Phone.com with SalesBlink",
    "metaDescription": "Integrate Phone.com and other Phone & SMS Tools with SalesBlink. Two Way Phone.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phaxio",
    "slug": "phaxio",
    "logo": "https://zapier-images.imgix.net/storage/developer/f6d838569fac46bb5f895265e8746012.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Super simple fax API for developers.  Send and receive faxes, and pay as you go.",
    "category": "Fax",
    "title": "Phaxio Integration With Cold Email Sales Tool",
    "h1": "Integrate Phaxio with SalesBlink",
    "metaDescription": "Integrate Phaxio and other Fax Tools with SalesBlink. Two Way Phaxio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GatherUp",
    "slug": "gatherup",
    "logo": "https://zapier-images.imgix.net/storage/developer/c8ab5798bf7b7d8194e4427207e52f21_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GatherUp is an automated customer feedback and online review platform.",
    "category": "Forms & Surveys",
    "title": "GatherUp Integration With Cold Email Sales Tool",
    "h1": "Integrate GatherUp with SalesBlink",
    "metaDescription": "Integrate GatherUp and other Forms & Surveys Tools with SalesBlink. Two Way GatherUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invision Community",
    "slug": "invision-community",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bcb656f7ccfd8c509e10437b7eddc0b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Invision Community is an independent community platform.",
    "category": "Website Builders",
    "title": "Invision Community Integration With Cold Email Sales Tool",
    "h1": "Integrate Invision Community with SalesBlink",
    "metaDescription": "Integrate Invision Community and other Website Builders Tools with SalesBlink. Two Way Invision Community Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Compass",
    "slug": "compass-da26590",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9635635a84fd1d0f247fd33c11cef657.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Compass CRM empowers Compass agents to manage their business end-to-end in one platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Compass Integration With Cold Email Sales Tool",
    "h1": "Integrate Compass with SalesBlink",
    "metaDescription": "Integrate Compass and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Compass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jumpseller",
    "slug": "jumpseller",
    "logo": "https://zapier-images.imgix.net/storage/services/d3820f13e8aafcfa314dc7500b83b57c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jumpseller is an e-commerce platform for fast-growing businesses! A simple solution for creating your Online Store.",
    "category": "eCommerce",
    "title": "Jumpseller Integration With Cold Email Sales Tool",
    "h1": "Integrate Jumpseller with SalesBlink",
    "metaDescription": "Integrate Jumpseller and other eCommerce Tools with SalesBlink. Two Way Jumpseller Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "AnswerConnect",
    "slug": "answerconnect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d08b11a51bab1b71097162ed74ee214c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AnswerConnect is a 24*7 live answering service that accepts inbound customer calls your business receives, assists your customers and helps them schedule appointments with your business.",
    "category": "Customer Support",
    "title": "AnswerConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate AnswerConnect with SalesBlink",
    "metaDescription": "Integrate AnswerConnect and other Customer Support Tools with SalesBlink. Two Way AnswerConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GymSales",
    "slug": "gymsales",
    "logo": "https://zapier-images.imgix.net/storage/developer/7bc910e4831060a3c6c5be58a26cbae3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GymSales is a prospect management system designed for Fitness Businesses.",
    "category": "Fitness",
    "title": "GymSales Integration With Cold Email Sales Tool",
    "h1": "Integrate GymSales with SalesBlink",
    "metaDescription": "Integrate GymSales and other Fitness Tools with SalesBlink. Two Way GymSales Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tint Wiz",
    "slug": "tint-wiz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d322796997892e5e91f97bb08ad23d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tint Wiz is an all-in-one CRM app for window tinters",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tint Wiz Integration With Cold Email Sales Tool",
    "h1": "Integrate Tint Wiz with SalesBlink",
    "metaDescription": "Integrate Tint Wiz and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tint Wiz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Synthesia",
    "slug": "synthesia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5dec96b5411a5fe06004ab66b89ba2d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video drives action. Now you can automatically enrich all your transactional emails with personalized videos from Synthesia.",
    "category": "AI Tools",
    "title": "Synthesia Integration With Cold Email Sales Tool",
    "h1": "Integrate Synthesia with SalesBlink",
    "metaDescription": "Integrate Synthesia and other AI Tools Tools with SalesBlink. Two Way Synthesia Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Chiirp",
    "slug": "chiirp",
    "logo": "https://zapier-images.imgix.net/storage/developer/bad563227ebf03945d00be0617206715.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chiirp helps you Capture, Connect & Close easier and faster using automated text messaging.",
    "category": "Contact Management",
    "title": "Chiirp Integration With Cold Email Sales Tool",
    "h1": "Integrate Chiirp with SalesBlink",
    "metaDescription": "Integrate Chiirp and other Contact Management Tools with SalesBlink. Two Way Chiirp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bonsai",
    "slug": "bonsai",
    "logo": "https://zapier-images.imgix.net/storage/services/fa66678f43c8b40acecb3e503930dd1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bonsai is the only tool you need to run your business. Manage your clients, deliver great work, get paid, and track your finances.",
    "category": "Forms & Surveys",
    "title": "Bonsai Integration With Cold Email Sales Tool",
    "h1": "Integrate Bonsai with SalesBlink",
    "metaDescription": "Integrate Bonsai and other Forms & Surveys Tools with SalesBlink. Two Way Bonsai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DealMachine",
    "slug": "dealmachine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70a77de1f5f6725ec0a70dcd68532adf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DealMachine intelligently automates your REI marketing process. Simply take a picture of a property with your smartphone and DealMachine looks up the owner and sends highly personalized mail.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DealMachine Integration With Cold Email Sales Tool",
    "h1": "Integrate DealMachine with SalesBlink",
    "metaDescription": "Integrate DealMachine and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DealMachine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Act-On",
    "slug": "act-on",
    "logo": "https://zapier-images.imgix.net/storage/developer/16f1dc69afe6883e614b0031eaf9424c.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Act-On's growth marketing platform enables marketers to grow their business, deliver exceptional brand experiences, and drive customer engagement with their offerings across the customer life cycle-for every customer, every time.",
    "category": "Marketing Automation",
    "title": "Act-On Integration With Cold Email Sales Tool",
    "h1": "Integrate Act-On with SalesBlink",
    "metaDescription": "Integrate Act-On and other Marketing Automation Tools with SalesBlink. Two Way Act-On Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Usersnap",
    "slug": "usersnap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59136a4a4381e0859f7af6aa63e48a82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect and streamline visual bug reports and customer feedback for your digital product from development to production.",
    "category": "Product Management",
    "title": "Usersnap Integration With Cold Email Sales Tool",
    "h1": "Integrate Usersnap with SalesBlink",
    "metaDescription": "Integrate Usersnap and other Product Management Tools with SalesBlink. Two Way Usersnap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ON24",
    "slug": "on24",
    "logo": "https://zapier-images.imgix.net/storage/developer/76fdf20c8c952c9491147000ad33a805.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ON24 is a webinar-based marketing platform that drives demand generation and customer engagement.",
    "category": "Webinars",
    "title": "ON24 Integration With Cold Email Sales Tool",
    "h1": "Integrate ON24 with SalesBlink",
    "metaDescription": "Integrate ON24 and other Webinars Tools with SalesBlink. Two Way ON24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Placetel",
    "slug": "placetel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff2832830da98ba07acad85a3ced6cb1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The PBX from the cloud. Modern business telephony with more than\n150 telephone system functions.",
    "category": "Call Tracking",
    "title": "Placetel Integration With Cold Email Sales Tool",
    "h1": "Integrate Placetel with SalesBlink",
    "metaDescription": "Integrate Placetel and other Call Tracking Tools with SalesBlink. Two Way Placetel Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Eventix",
    "slug": "eventix",
    "logo": "https://zapier-images.imgix.net/storage/services/a1792be4a123f356357f254f92d26f69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventix is an easy tool for event organisers to sell tickets online.",
    "category": "Marketing Automation",
    "title": "Eventix Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventix with SalesBlink",
    "metaDescription": "Integrate Eventix and other Marketing Automation Tools with SalesBlink. Two Way Eventix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Disciple",
    "slug": "disciple",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3e982423a336180e18be26a00b9a6179.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Disciple empowers brands and creators to build independent communities of true value. We encourage hosts to build their communities on a platform that champions members and their own values and mission.",
    "category": "Website Builders",
    "title": "Disciple Integration With Cold Email Sales Tool",
    "h1": "Integrate Disciple with SalesBlink",
    "metaDescription": "Integrate Disciple and other Website Builders Tools with SalesBlink. Two Way Disciple Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Steady",
    "slug": "steady",
    "logo": "https://zapier-images.imgix.net/storage/services/97615e21fc0a04ff814ff452bf3d5b03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Steady is a subscription platform for blogs, journalism, podcasts and other content creators.",
    "category": "Proposal & Invoice Management",
    "title": "Steady Integration With Cold Email Sales Tool",
    "h1": "Integrate Steady with SalesBlink",
    "metaDescription": "Integrate Steady and other Proposal & Invoice Management Tools with SalesBlink. Two Way Steady Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Timely",
    "slug": "timely",
    "logo": "https://zapier-images.imgix.net/storage/services/36f8190c88ddfe35ffc2668a04324507.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timely is appointment software. Designed to manage every aspect of your business. Clients, schedule, staff, stock, sales, reporting & more.",
    "category": "Fitness",
    "title": "Timely Integration With Cold Email Sales Tool",
    "h1": "Integrate Timely with SalesBlink",
    "metaDescription": "Integrate Timely and other Fitness Tools with SalesBlink. Two Way Timely Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Recruiterflow",
    "slug": "recruiterflow",
    "logo": "https://zapier-images.imgix.net/storage/services/6094878a90d58317ae5f1a2caee60e6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recruiterflow is a modern recruiting software that helps ambitious recruiting and staffing businesses to be productive.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Recruiterflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Recruiterflow with SalesBlink",
    "metaDescription": "Integrate Recruiterflow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Recruiterflow Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Refersion",
    "slug": "refersion",
    "logo": "https://zapier-images.imgix.net/storage/services/dc6ca0b3076090bec1fa610e1b1a916c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Refersion is a tracking platform helping merchants start, manage, and grow affiliate networks. This integration is for merchant accounts.",
    "category": "eCommerce",
    "title": "Refersion Integration With Cold Email Sales Tool",
    "h1": "Integrate Refersion with SalesBlink",
    "metaDescription": "Integrate Refersion and other eCommerce Tools with SalesBlink. Two Way Refersion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Showcase IDX",
    "slug": "showcase-idx",
    "logo": "https://zapier-images.imgix.net/storage/developer/e57e4cbfe27bffbba9803c6b6b516530_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Showcase IDX is a real estate search plugin for WordPress, optimized for lead generation.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Showcase IDX Integration With Cold Email Sales Tool",
    "h1": "Integrate Showcase IDX with SalesBlink",
    "metaDescription": "Integrate Showcase IDX and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Showcase IDX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hubstaff",
    "slug": "hubstaff",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c58561e642a8c9e574bb72a312cc8ad5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hubstaff is a time tracking tool that helps distributed teams boost productivity while streamlining admin tasks like timesheets, to-do and project management, and payroll.",
    "category": "Productivity",
    "title": "Hubstaff Integration With Cold Email Sales Tool",
    "h1": "Integrate Hubstaff with SalesBlink",
    "metaDescription": "Integrate Hubstaff and other Productivity Tools with SalesBlink. Two Way Hubstaff Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Hnry",
    "slug": "hnry",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4d28fe6271e64eb97d74c10a368517b7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hnry is a service for contractors, freelancers, sole traders, and self-employed individuals that takes away all the hassle of tax and compliance as well as providing you with the necessary tools to manage any additional financial admin.",
    "category": "Accounting",
    "title": "Hnry Integration With Cold Email Sales Tool",
    "h1": "Integrate Hnry with SalesBlink",
    "metaDescription": "Integrate Hnry and other Accounting Tools with SalesBlink. Two Way Hnry Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lawcus",
    "slug": "lawcus",
    "logo": "https://zapier-images.imgix.net/storage/services/c58179204b141e8faecff9f4187ad13e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lawcus is a practice management tool that enables lawyers to organize matters and automate consistent processes to help deliver more with transparency and less effort.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Lawcus Integration With Cold Email Sales Tool",
    "h1": "Integrate Lawcus with SalesBlink",
    "metaDescription": "Integrate Lawcus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Lawcus Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TrainHeroic",
    "slug": "trainheroic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20463f8327a7e26abb6bcc19863ff800.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrainHeroic is a training tool to help coaches and athletes achieve their fitness goals.",
    "category": "Fitness",
    "title": "TrainHeroic Integration With Cold Email Sales Tool",
    "h1": "Integrate TrainHeroic with SalesBlink",
    "metaDescription": "Integrate TrainHeroic and other Fitness Tools with SalesBlink. Two Way TrainHeroic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aryeo",
    "slug": "aryeo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25cf213d6123aefff7ed16084578cc91_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Industry Leading Real Estate Content Platform. Manage, share, and collaborate on the content created to market properties.",
    "category": "Productivity",
    "title": "Aryeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Aryeo with SalesBlink",
    "metaDescription": "Integrate Aryeo and other Productivity Tools with SalesBlink. Two Way Aryeo Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mollie",
    "slug": "mollie-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b212303f36ea787e9ae7a30164487717.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mollie is a licensed business specializing in processing online payments on behalf of merchants.",
    "category": "Payment Processing",
    "title": "Mollie Integration With Cold Email Sales Tool",
    "h1": "Integrate Mollie with SalesBlink",
    "metaDescription": "Integrate Mollie and other Payment Processing Tools with SalesBlink. Two Way Mollie Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "HiHello",
    "slug": "hihello",
    "logo": "https://zapier-images.imgix.net/storage/services/80bb828a15d1b43ff611b18bd61af859.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HiHello is a free end-to-end contact manager designed to help you curate and grow your professional relationships.",
    "category": "Contact Management",
    "title": "HiHello Integration With Cold Email Sales Tool",
    "h1": "Integrate HiHello with SalesBlink",
    "metaDescription": "Integrate HiHello and other Contact Management Tools with SalesBlink. Two Way HiHello Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formcarry",
    "slug": "formcarry",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e2766bc7d2d00068f3a630aad26cb2e_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formcarry is a form processing API, you point your form to us, we notify you every time a form filled.",
    "category": "Forms & Surveys",
    "title": "Formcarry Integration With Cold Email Sales Tool",
    "h1": "Integrate Formcarry with SalesBlink",
    "metaDescription": "Integrate Formcarry and other Forms & Surveys Tools with SalesBlink. Two Way Formcarry Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SegMetrics",
    "slug": "segmetrics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59ac2efc15326c0d6a4ee821d27f02b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SegMetrics gives you clarity on where your leads come from, how they act, and how much your marketing is really worth.",
    "category": "Analytics",
    "title": "SegMetrics Integration With Cold Email Sales Tool",
    "h1": "Integrate SegMetrics with SalesBlink",
    "metaDescription": "Integrate SegMetrics and other Analytics Tools with SalesBlink. Two Way SegMetrics Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Toodledo",
    "slug": "toodledo",
    "logo": "https://zapier-images.imgix.net/storage/developer/f98bb2d31ced88dc5d095ac74d379444.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Toodledo is one of the most popular online productivity tools available today. Use it to organize your life into list and notes.",
    "category": "Task Management",
    "title": "Toodledo Integration With Cold Email Sales Tool",
    "h1": "Integrate Toodledo with SalesBlink",
    "metaDescription": "Integrate Toodledo and other Task Management Tools with SalesBlink. Two Way Toodledo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Vincere",
    "slug": "vincere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/576b5eca994a64dfd420e2789cbaf43e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vincere is an out-of-the-box platform for the front/middle/back office operations of ambitious recruitment firms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vincere Integration With Cold Email Sales Tool",
    "h1": "Integrate Vincere with SalesBlink",
    "metaDescription": "Integrate Vincere and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vincere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Covve Scan",
    "slug": "covve-scan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c16772a79a2ca9354a97d928c186816c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Covve Scan is an app that scans and accurately reads business cards using Covve’s AI powered business card reader.",
    "category": "Productivity",
    "title": "Covve Scan Integration With Cold Email Sales Tool",
    "h1": "Integrate Covve Scan with SalesBlink",
    "metaDescription": "Integrate Covve Scan and other Productivity Tools with SalesBlink. Two Way Covve Scan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FreedomSoft",
    "slug": "freedomsoft",
    "logo": "https://zapier-images.imgix.net/storage/developer/757177beea3f5dbbcb679e3ba618f8cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreedomSoft is an all-in-one lead generation and real-estate automation system.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FreedomSoft Integration With Cold Email Sales Tool",
    "h1": "Integrate FreedomSoft with SalesBlink",
    "metaDescription": "Integrate FreedomSoft and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FreedomSoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ezeep Blue printing",
    "slug": "ezeep-blue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a285e9481f89c21c0546d5567c49c8c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ezeep Blue printing automates printing of labels, checks, packing slips, reports and all other purposes.",
    "category": "Printing",
    "title": "ezeep Blue printing Integration With Cold Email Sales Tool",
    "h1": "Integrate ezeep Blue printing with SalesBlink",
    "metaDescription": "Integrate ezeep Blue printing and other Printing Tools with SalesBlink. Two Way ezeep Blue printing Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Canopy Connect",
    "slug": "canopy-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5260faaa381326e827501ce6e20bf7da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canopy Connect is a 1-click solution that enables you to instantly receive Dec Pages and insurance information from your prospects.",
    "category": "Website & App Building",
    "title": "Canopy Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Canopy Connect with SalesBlink",
    "metaDescription": "Integrate Canopy Connect and other Website & App Building Tools with SalesBlink. Two Way Canopy Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convertri",
    "slug": "convertri",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec33cf0903da69b331a4fa5bf5cd6e63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convertri is a complete sales funnel and website builder, focused on building the fastest pages you’ve ever seen.",
    "category": "Website Builders",
    "title": "Convertri Integration With Cold Email Sales Tool",
    "h1": "Integrate Convertri with SalesBlink",
    "metaDescription": "Integrate Convertri and other Website Builders Tools with SalesBlink. Two Way Convertri Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Cal.com",
    "slug": "calcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5744bd0cc90eab0d651a15f8ce2a4b0e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scheduling infrastructure for absolutely everyone.",
    "category": "Scheduling & Booking",
    "title": "Cal.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Cal.com with SalesBlink",
    "metaDescription": "Integrate Cal.com and other Scheduling & Booking Tools with SalesBlink. Two Way Cal.com Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Chatter",
    "slug": "chatter",
    "logo": "https://zapier-images.imgix.net/storage/developer/73b24d6aa9dc23063018b64a5e43248b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chatter makes business processes social. Collaborate in real time, in context, from anywhere.",
    "category": "Team Collaboration",
    "title": "Chatter Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatter with SalesBlink",
    "metaDescription": "Integrate Chatter and other Team Collaboration Tools with SalesBlink. Two Way Chatter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Katana",
    "slug": "katana-mrp",
    "logo": "https://zapier-images.imgix.net/storage/services/8f21fae4d1424074b1d270a00ddb32f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Katana gives thousands of manufacturers a live look at their business. Manage all the moving parts of your business and unite the apps and services you use in one visual platform.",
    "category": "eCommerce",
    "title": "Katana Integration With Cold Email Sales Tool",
    "h1": "Integrate Katana with SalesBlink",
    "metaDescription": "Integrate Katana and other eCommerce Tools with SalesBlink. Two Way Katana Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailtrack",
    "slug": "mailtrackio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/97e748ae963ed067bd7ab38c78e5e114.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailtrack is an email tracking extension for Gmail. With Mailtrack, you can see how people interact with the emails you send from Gmail. Track if your emails have been opened, links have been clicked, and much more!",
    "category": "Email",
    "title": "Mailtrack Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailtrack with SalesBlink",
    "metaDescription": "Integrate Mailtrack and other Email Tools with SalesBlink. Two Way Mailtrack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crmble",
    "slug": "crmble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4861fe0678e08b0093c25a7197c24de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feed your Trello Crmble Cards on your boards!",
    "category": "CRM (Customer Relationship Management)",
    "title": "Crmble Integration With Cold Email Sales Tool",
    "h1": "Integrate Crmble with SalesBlink",
    "metaDescription": "Integrate Crmble and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Crmble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VanillaSoft",
    "slug": "vanillasoft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fbf6f2df34481bfc07c8a3961d7bfc5d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VanillaSoft is a CRM app that combines the best of CRM, Lead Management and Telemarketing applications into one of the most capable phone sales environments.",
    "category": "CRM (Customer Relationship Management)",
    "title": "VanillaSoft Integration With Cold Email Sales Tool",
    "h1": "Integrate VanillaSoft with SalesBlink",
    "metaDescription": "Integrate VanillaSoft and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way VanillaSoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intaker",
    "slug": "intaker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4956b10597b4367858c56f0ff3093cf1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intaker is a website chat, two-way texting, follow-up automation, client intake and lead management tool built for forward looking law firms",
    "category": "Dashboards",
    "title": "Intaker Integration With Cold Email Sales Tool",
    "h1": "Integrate Intaker with SalesBlink",
    "metaDescription": "Integrate Intaker and other Dashboards Tools with SalesBlink. Two Way Intaker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pixie",
    "slug": "pixie",
    "logo": "https://zapier-images.imgix.net/storage/services/98055313c3fceb27c1698758b732662f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pixie is practice management for accountants and bookkeepers. Improve visibility, collaboration and automation for your team and clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pixie Integration With Cold Email Sales Tool",
    "h1": "Integrate Pixie with SalesBlink",
    "metaDescription": "Integrate Pixie and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pixie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dux-Soup",
    "slug": "dux-soup",
    "logo": "https://zapier-images.imgix.net/storage/services/279aee24a431238cd36f71fefc46a9c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dux-Soup is LinkedIn automation software to find leads and engage with prospects.",
    "category": "Marketing Automation",
    "title": "Dux-Soup Integration With Cold Email Sales Tool",
    "h1": "Integrate Dux-Soup with SalesBlink",
    "metaDescription": "Integrate Dux-Soup and other Marketing Automation Tools with SalesBlink. Two Way Dux-Soup Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cvent",
    "slug": "cvent",
    "logo": "https://zapier-images.imgix.net/storage/services/0ecc8abc42e8922e1c409a83bb14fa90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cvent is a modern online management product for event, survey, and email marketing management.",
    "category": "Event Management",
    "title": "Cvent Integration With Cold Email Sales Tool",
    "h1": "Integrate Cvent with SalesBlink",
    "metaDescription": "Integrate Cvent and other Event Management Tools with SalesBlink. Two Way Cvent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pushcut",
    "slug": "pushcut",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f142d928eaa27a51e051d916ac33c549.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pushcut is an app for iOS that lets you create smart notifications to kick off shortcuts, URLs, and online automations.",
    "category": "Notifications",
    "title": "Pushcut Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushcut with SalesBlink",
    "metaDescription": "Integrate Pushcut and other Notifications Tools with SalesBlink. Two Way Pushcut Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Credly",
    "slug": "credlys-acclaim-platform",
    "logo": "https://zapier-images.imgix.net/storage/services/85556346581cd95b77356c1465c62939.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Credly is the world's largest network of digital credentials. The most respected brands use Credly to issue badges, certificates, and certifications to millions of professionals every year.",
    "category": "Online Courses",
    "title": "Credly Integration With Cold Email Sales Tool",
    "h1": "Integrate Credly with SalesBlink",
    "metaDescription": "Integrate Credly and other Online Courses Tools with SalesBlink. Two Way Credly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superchat",
    "slug": "superchat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/12f30e30993471ae93e8fecc3150bafe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Superchat is a messaging platform that combines relevant communication channels, streamlines customer support and makes messenger marketing accessible to any business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Superchat Integration With Cold Email Sales Tool",
    "h1": "Integrate Superchat with SalesBlink",
    "metaDescription": "Integrate Superchat and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Superchat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Stacker",
    "slug": "stacker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9eb18b7e5d5bf042082677ad826ae7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stacker gives you the simplicity of a spreadsheet but the power of a development team.",
    "category": "App Builder",
    "title": "Stacker Integration With Cold Email Sales Tool",
    "h1": "Integrate Stacker with SalesBlink",
    "metaDescription": "Integrate Stacker and other App Builder Tools with SalesBlink. Two Way Stacker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DigiMember",
    "slug": "digimember",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d47856e6cda10c0c68e4416460cf9ed5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DigiMember is a powerful membership plugin for WordPress, that enables you to build your very own automated member area within minutes.",
    "category": "Online Courses",
    "title": "DigiMember Integration With Cold Email Sales Tool",
    "h1": "Integrate DigiMember with SalesBlink",
    "metaDescription": "Integrate DigiMember and other Online Courses Tools with SalesBlink. Two Way DigiMember Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Retention.com",
    "slug": "retention",
    "logo": "https://zapier-images.imgix.net/storage/services/0e67fb9de27a9a4b1e22e0fab9c8c9e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Retention.com helps you discover your audience's untapped potential by turning website browsers into buyers.",
    "category": "Marketing",
    "title": "Retention.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Retention.com with SalesBlink",
    "metaDescription": "Integrate Retention.com and other Marketing Tools with SalesBlink. Two Way Retention.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Better Agency",
    "slug": "better-agency",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5d93a65cddd5651aa7b3e9c0311a71b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Better Agency is a CRM Marketing Automation Tool for Insurance Agencies",
    "category": "Marketing",
    "title": "Better Agency Integration With Cold Email Sales Tool",
    "h1": "Integrate Better Agency with SalesBlink",
    "metaDescription": "Integrate Better Agency and other Marketing Tools with SalesBlink. Two Way Better Agency Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parsio",
    "slug": "parsio",
    "logo": "https://zapier-images.imgix.net/storage/services/009aeec00b30d92cf93f8469627885d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parsio is a tool to extract & export any data from your emails: order info, customer details etc.",
    "category": "Email",
    "title": "Parsio Integration With Cold Email Sales Tool",
    "h1": "Integrate Parsio with SalesBlink",
    "metaDescription": "Integrate Parsio and other Email Tools with SalesBlink. Two Way Parsio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Urlooker",
    "slug": "urlooker",
    "logo": "https://zapier-images.imgix.net/storage/developer/fb1ef583fab2a5d8aa9873780673218d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Urlooker is a tool for monitoring changes on web pages. Just select a page area, and you'll receive notifications about changed content.",
    "category": "Notifications",
    "title": "Urlooker Integration With Cold Email Sales Tool",
    "h1": "Integrate Urlooker with SalesBlink",
    "metaDescription": "Integrate Urlooker and other Notifications Tools with SalesBlink. Two Way Urlooker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickMail.io",
    "slug": "quickmail",
    "logo": "https://zapier-images.imgix.net/storage/services/3e02f88d439cb4911b62b9de533588ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your business fast with personalized cold emails at scale.",
    "category": "Drip Emails",
    "title": "QuickMail.io Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickMail.io with SalesBlink",
    "metaDescription": "Integrate QuickMail.io and other Drip Emails Tools with SalesBlink. Two Way QuickMail.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FLG",
    "slug": "flg",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6500ec9725b35dfd35739b65fac2adfe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A complete CRM platform focused on automated workflow to manage leads & control processes - nothing missed, no opportunity left behind.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FLG Integration With Cold Email Sales Tool",
    "h1": "Integrate FLG with SalesBlink",
    "metaDescription": "Integrate FLG and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FLG Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialBee",
    "slug": "socialbee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea771064066cc3a39c0cf73d31b558c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Social Media on Autopilot\n\nAll-in-One Tools and Services for Content Posting & Recycling, Follower Growth, Engagement, and More",
    "category": "Social Media Accounts",
    "title": "SocialBee Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialBee with SalesBlink",
    "metaDescription": "Integrate SocialBee and other Social Media Accounts Tools with SalesBlink. Two Way SocialBee Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Paymo",
    "slug": "paymo",
    "logo": "https://zapier-images.imgix.net/storage/developer/d3853d49e457a26406a8b247c41f0be0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple online project management application that bundles task management, time tracking and invoicing",
    "category": "Project Management",
    "title": "Paymo Integration With Cold Email Sales Tool",
    "h1": "Integrate Paymo with SalesBlink",
    "metaDescription": "Integrate Paymo and other Project Management Tools with SalesBlink. Two Way Paymo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "UpViral",
    "slug": "upviral",
    "logo": "https://zapier-images.imgix.net/storage/developer/4d468f66d0f55bf4eb2ce86897c7297e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpViral is a referral platform to run viral giveaways and sweepstakes.",
    "category": "Ads & Conversion",
    "title": "UpViral Integration With Cold Email Sales Tool",
    "h1": "Integrate UpViral with SalesBlink",
    "metaDescription": "Integrate UpViral and other Ads & Conversion Tools with SalesBlink. Two Way UpViral Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Push by Techulus",
    "slug": "push-by-techulus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/93d06411fe458ac318f64181cbcac223.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Push by Techulus allows you to get custom real-time notifications on your iOS & Android devices.",
    "category": "Notifications",
    "title": "Push by Techulus Integration With Cold Email Sales Tool",
    "h1": "Integrate Push by Techulus with SalesBlink",
    "metaDescription": "Integrate Push by Techulus and other Notifications Tools with SalesBlink. Two Way Push by Techulus Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "APITemplate.io",
    "slug": "apitemplateio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7903b6f0c702a0784a06fdf19c729031.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "APITemplate.io automates Image generation and PDF generation by using Image editor, WYSIWYG, Markdown or HTML template",
    "category": "Images & Design",
    "title": "APITemplate.io Integration With Cold Email Sales Tool",
    "h1": "Integrate APITemplate.io with SalesBlink",
    "metaDescription": "Integrate APITemplate.io and other Images & Design Tools with SalesBlink. Two Way APITemplate.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Float",
    "slug": "float",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3bc6810f5af6db396c7619068506fb82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Float is the fast and friendly resource scheduling app. Keep track of who's working on what and when.",
    "category": "Scheduling & Booking",
    "title": "Float Integration With Cold Email Sales Tool",
    "h1": "Integrate Float with SalesBlink",
    "metaDescription": "Integrate Float and other Scheduling & Booking Tools with SalesBlink. Two Way Float Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Geckoboard",
    "slug": "geckoboard",
    "logo": "https://zapier-images.imgix.net/storage/services/4bdbd3bcd7e5011198d57e139c518b80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Geckoboard: TV dashboards that make key business data visible for your team",
    "category": "Dashboards",
    "title": "Geckoboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Geckoboard with SalesBlink",
    "metaDescription": "Integrate Geckoboard and other Dashboards Tools with SalesBlink. Two Way Geckoboard Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SolarWinds Service Desk",
    "slug": "samanage",
    "logo": "https://zapier-images.imgix.net/storage/developer/07efae70c4a8afc298926b5c9d7a7af9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Modern IT management. Cloud-based IT service desk and asset management solution you'll love to use.",
    "category": "Customer Support",
    "title": "SolarWinds Service Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate SolarWinds Service Desk with SalesBlink",
    "metaDescription": "Integrate SolarWinds Service Desk and other Customer Support Tools with SalesBlink. Two Way SolarWinds Service Desk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Simplicate",
    "slug": "simplicate",
    "logo": "https://zapier-images.imgix.net/storage/services/0b32be67b144eb6fd1a1d7fce4d8e8dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one CRM and project software: CRM, sales, project management, planning, time registration & invoicing.",
    "category": "Project Management",
    "title": "Simplicate Integration With Cold Email Sales Tool",
    "h1": "Integrate Simplicate with SalesBlink",
    "metaDescription": "Integrate Simplicate and other Project Management Tools with SalesBlink. Two Way Simplicate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Weather",
    "slug": "the-weather",
    "logo": "https://zapier-images.imgix.net/storage/services/b49872aa12c4d26db81e354a30bb3aeb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trigger off weather conditions in your local area. (Powered by https://openweathermap.org)",
    "category": "News & Lifestyle",
    "title": "The Weather Integration With Cold Email Sales Tool",
    "h1": "Integrate The Weather with SalesBlink",
    "metaDescription": "Integrate The Weather and other News & Lifestyle Tools with SalesBlink. Two Way The Weather Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sellfy",
    "slug": "sellfy",
    "logo": "https://zapier-images.imgix.net/storage/developer/8551f023119c31f4eb24fad1f677c143.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sellfy is an e-commerce platform for selling music, videos, e-books and anything else digital.",
    "category": "eCommerce",
    "title": "Sellfy Integration With Cold Email Sales Tool",
    "h1": "Integrate Sellfy with SalesBlink",
    "metaDescription": "Integrate Sellfy and other eCommerce Tools with SalesBlink. Two Way Sellfy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Productive.io",
    "slug": "productiveio",
    "logo": "https://zapier-images.imgix.net/storage/services/cab7476baa69744892b1f1fca9eb6f89.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Productive is a project management tool that includes everything you need to run a profitable agency.",
    "category": "Project Management",
    "title": "Productive.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Productive.io with SalesBlink",
    "metaDescription": "Integrate Productive.io and other Project Management Tools with SalesBlink. Two Way Productive.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hive",
    "slug": "hive",
    "logo": "https://zapier-images.imgix.net/storage/developer/92a473a3b5d253346ff48562746a9345.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hive provides simple automated workflow for teams. It brings all your different tools onto one dashboard and makes it easy to see progress and bottlenecks.",
    "category": "Team Collaboration",
    "title": "Hive Integration With Cold Email Sales Tool",
    "h1": "Integrate Hive with SalesBlink",
    "metaDescription": "Integrate Hive and other Team Collaboration Tools with SalesBlink. Two Way Hive Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Employee Advocacy by Sprout Social",
    "slug": "employee-advocacy",
    "logo": "https://zapier-images.imgix.net/storage/services/a2aa48f8e24e8e8c43682b3dd02d5a81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Employee Advocacy by Sprout Social is a communication platform built for employee advocacy that helps brands amplify their reach on social media.",
    "category": "Social Media Marketing",
    "title": "Employee Advocacy by Sprout Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Employee Advocacy by Sprout Social with SalesBlink",
    "metaDescription": "Integrate Employee Advocacy by Sprout Social and other Social Media Marketing Tools with SalesBlink. Two Way Employee Advocacy by Sprout Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BookFunnel",
    "slug": "bookfunnel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/066093f405e7fedbe0cd0b0972f3626c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BookFunnel is an ebook and audiobook delivery service for authors and publishers.",
    "category": "Content & Files",
    "title": "BookFunnel Integration With Cold Email Sales Tool",
    "h1": "Integrate BookFunnel with SalesBlink",
    "metaDescription": "Integrate BookFunnel and other Content & Files Tools with SalesBlink. Two Way BookFunnel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Runrun.it",
    "slug": "runrunit",
    "logo": "https://zapier-images.imgix.net/storage/developer/5ced435698973bf4e6d1d7d1ad71eebb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Runrun.it is a solution for task, time, and performance management. \nSimple, powerful and tightly integrated.",
    "category": "Project Management",
    "title": "Runrun.it Integration With Cold Email Sales Tool",
    "h1": "Integrate Runrun.it with SalesBlink",
    "metaDescription": "Integrate Runrun.it and other Project Management Tools with SalesBlink. Two Way Runrun.it Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coassemble",
    "slug": "coassemble",
    "logo": "https://zapier-images.imgix.net/storage/services/0591161b6e9a3833019405e2b57194d8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coassemble is a friendly yet powerful cloud-based platform that simplifies all aspects of online training.",
    "category": "Online Courses",
    "title": "Coassemble Integration With Cold Email Sales Tool",
    "h1": "Integrate Coassemble with SalesBlink",
    "metaDescription": "Integrate Coassemble and other Online Courses Tools with SalesBlink. Two Way Coassemble Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SignUpGenius",
    "slug": "signupgenius",
    "logo": "https://zapier-images.imgix.net/storage/developer/727a8faef0e09a7a10d6674c7a291cea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignUpGenius is an organizational tool that takes away the hassle of coordinating groups so your organization can focus on its impact on your community.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SignUpGenius Integration With Cold Email Sales Tool",
    "h1": "Integrate SignUpGenius with SalesBlink",
    "metaDescription": "Integrate SignUpGenius and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SignUpGenius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Less Annoying CRM",
    "slug": "less-annoying-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74658f0e84ecd3d7d76ee5bd1e1bbb64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Less Annoying CRM is a small business CRM with easy-to-use features at an affordable price. Manage all leads, notes, and tasks in one place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Less Annoying CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Less Annoying CRM with SalesBlink",
    "metaDescription": "Integrate Less Annoying CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Less Annoying CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Growbots",
    "slug": "growbots",
    "logo": "https://zapier-images.imgix.net/storage/services/967e1079c36a3c61349111b1fd12717d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Growbots is an all-in-one AI tool for automated outbound sales, combining scalable prospecting and targeted email outreach.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Growbots Integration With Cold Email Sales Tool",
    "h1": "Integrate Growbots with SalesBlink",
    "metaDescription": "Integrate Growbots and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Growbots Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zulip",
    "slug": "zulip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/739f92420b08a0f38c1ca0a8964da611.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zulip provides a rich chat interface for real-time and asynchronous team communication.",
    "category": "Team Chat",
    "title": "Zulip Integration With Cold Email Sales Tool",
    "h1": "Integrate Zulip with SalesBlink",
    "metaDescription": "Integrate Zulip and other Team Chat Tools with SalesBlink. Two Way Zulip Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Airmeet",
    "slug": "airmeet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b6aca9de5b1e807a3defe0414adcffac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Airmeet is an all-in-one platform for communities & organizations to host immersive events and build real connections, online.",
    "category": "Event Management",
    "title": "Airmeet Integration With Cold Email Sales Tool",
    "h1": "Integrate Airmeet with SalesBlink",
    "metaDescription": "Integrate Airmeet and other Event Management Tools with SalesBlink. Two Way Airmeet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CloudTalk",
    "slug": "cloudtalk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8eab2ae5692f91cc832a20e6be79671.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudTalk help modern sales and customer service teams provide better phone support and close more deals.",
    "category": "Phone & SMS",
    "title": "CloudTalk Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudTalk with SalesBlink",
    "metaDescription": "Integrate CloudTalk and other Phone & SMS Tools with SalesBlink. Two Way CloudTalk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Softr",
    "slug": "softr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/828c0a5a42375951dc3890bc9247cb63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Softr is a no-code platform to build customer portals and internal tools powered by Airtable or Google Sheets.",
    "category": "App Builder",
    "title": "Softr Integration With Cold Email Sales Tool",
    "h1": "Integrate Softr with SalesBlink",
    "metaDescription": "Integrate Softr and other App Builder Tools with SalesBlink. Two Way Softr Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Userback",
    "slug": "userback",
    "logo": "https://zapier-images.imgix.net/storage/services/6d399e094ddebe84874e5cf25518e0de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect fast visual feedback from any website, design or application with video and annotated screenshots.",
    "category": "Productivity",
    "title": "Userback Integration With Cold Email Sales Tool",
    "h1": "Integrate Userback with SalesBlink",
    "metaDescription": "Integrate Userback and other Productivity Tools with SalesBlink. Two Way Userback Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "AccountancyManager",
    "slug": "accountancymanager",
    "logo": "https://zapier-images.imgix.net/storage/services/c8ef8fc742e38303f1405c63b8659395.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccountancyManager is a practice management software designed by accountants and used by accountants, bookkeepers, and payroll businesses across the UK.",
    "category": "Accounting",
    "title": "AccountancyManager Integration With Cold Email Sales Tool",
    "h1": "Integrate AccountancyManager with SalesBlink",
    "metaDescription": "Integrate AccountancyManager and other Accounting Tools with SalesBlink. Two Way AccountancyManager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AppSheet",
    "slug": "appsheet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ea07c41abdab3e2e7c76b4742a0c419.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AppSheet is a no-code app development platform that lets you build desktop & mobile apps for your business from spreadsheet data.",
    "category": "App Builder",
    "title": "AppSheet Integration With Cold Email Sales Tool",
    "h1": "Integrate AppSheet with SalesBlink",
    "metaDescription": "Integrate AppSheet and other App Builder Tools with SalesBlink. Two Way AppSheet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "KingSumo",
    "slug": "kingsumo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/918b0fc887f126ba0542921e23bc9c03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KingSumo is a giveaway platform to grow your audience.",
    "category": "Marketing",
    "title": "KingSumo Integration With Cold Email Sales Tool",
    "h1": "Integrate KingSumo with SalesBlink",
    "metaDescription": "Integrate KingSumo and other Marketing Tools with SalesBlink. Two Way KingSumo Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Hello Bar",
    "slug": "hello-bar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8599083885b69874e66b817c25f4f25_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert visitors into customers with Hello Bar",
    "category": "Marketing Automation",
    "title": "Hello Bar Integration With Cold Email Sales Tool",
    "h1": "Integrate Hello Bar with SalesBlink",
    "metaDescription": "Integrate Hello Bar and other Marketing Automation Tools with SalesBlink. Two Way Hello Bar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SuperPhone",
    "slug": "superphone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb3c9787aa7f1206800a6d40e2ff7d40_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SuperPhone is a text marketing platform with broadcasts and 1-to-1 chat to help grow your text marketing channel.",
    "category": "Phone & SMS",
    "title": "SuperPhone Integration With Cold Email Sales Tool",
    "h1": "Integrate SuperPhone with SalesBlink",
    "metaDescription": "Integrate SuperPhone and other Phone & SMS Tools with SalesBlink. Two Way SuperPhone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coupon Carrier",
    "slug": "coupon-carrier",
    "logo": "https://zapier-images.imgix.net/storage/services/3b696dfcbd2b3f38669fde51677bb096.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coupon Carrier is a coupon and code distribution service to help you generate and distribute your unique codes. You can integrate with several different email service providers to monitor for new subscribers or events.",
    "category": "eCommerce",
    "title": "Coupon Carrier Integration With Cold Email Sales Tool",
    "h1": "Integrate Coupon Carrier with SalesBlink",
    "metaDescription": "Integrate Coupon Carrier and other eCommerce Tools with SalesBlink. Two Way Coupon Carrier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deputy",
    "slug": "deputy",
    "logo": "https://zapier-images.imgix.net/storage/services/c4bf55b302e68e9e18864f008c50c7ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deputy is an all-in-one employee scheduling, timesheets, tasking and communication platform. Used by thousands of businesses around the world it will improve profitability, staff culture and operational accountability.",
    "category": "Scheduling & Booking",
    "title": "Deputy Integration With Cold Email Sales Tool",
    "h1": "Integrate Deputy with SalesBlink",
    "metaDescription": "Integrate Deputy and other Scheduling & Booking Tools with SalesBlink. Two Way Deputy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Roofr",
    "slug": "roofr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68822cae1ac9daed1e49e37e082b15f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We’re Roofr: The all-in-one sales platform designed for roofers, by roofers. From accurate $10 roof measurement reports to project-winning proposals, with Roofr you can close your next deal in minutes.",
    "category": "Sales & CRM",
    "title": "Roofr Integration With Cold Email Sales Tool",
    "h1": "Integrate Roofr with SalesBlink",
    "metaDescription": "Integrate Roofr and other Sales & CRM Tools with SalesBlink. Two Way Roofr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fattura24",
    "slug": "fattura24",
    "logo": "https://zapier-images.imgix.net/storage/developer/4bf435089f0effe0deb61ae5930ce9af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fattura24 is an application available for web and mobile that helps entrepreneurs send invoices, track expenses, manage their clients and do many other things.",
    "category": "Accounting",
    "title": "Fattura24 Integration With Cold Email Sales Tool",
    "h1": "Integrate Fattura24 with SalesBlink",
    "metaDescription": "Integrate Fattura24 and other Accounting Tools with SalesBlink. Two Way Fattura24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orbit",
    "slug": "orbit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a7f53e5741e06e2b0943082caa7b39a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your community and prove ROI with Orbit, the community experience platform for delivering stellar member experience.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Orbit Integration With Cold Email Sales Tool",
    "h1": "Integrate Orbit with SalesBlink",
    "metaDescription": "Integrate Orbit and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Orbit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Shift4Shop",
    "slug": "shift4shop",
    "logo": "https://zapier-images.imgix.net/storage/services/44a50c4e41b2dec29604eaf4ac94a1df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shift4Shop is an all-in-one eCommerce platform for online retailers of any size. It includes a full range of website building and business management features.",
    "category": "eCommerce",
    "title": "Shift4Shop Integration With Cold Email Sales Tool",
    "h1": "Integrate Shift4Shop with SalesBlink",
    "metaDescription": "Integrate Shift4Shop and other eCommerce Tools with SalesBlink. Two Way Shift4Shop Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Wicked Reports",
    "slug": "wicked-reports",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2091e494e6ba2608162bc2fbc476631c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wicked Reports is marketing automation that lets you send contacts, orders & visitors clicks from your CRM or Shopping cart into your Wicked Reports account.",
    "category": "Marketing Automation",
    "title": "Wicked Reports Integration With Cold Email Sales Tool",
    "h1": "Integrate Wicked Reports with SalesBlink",
    "metaDescription": "Integrate Wicked Reports and other Marketing Automation Tools with SalesBlink. Two Way Wicked Reports Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gift Up!",
    "slug": "gift-up",
    "logo": "https://zapier-images.imgix.net/storage/services/7485cfd5feb37033e4e01e39a21c3add.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gift Up! is a digital gift card system for your website or Facebook page.",
    "category": "eCommerce",
    "title": "Gift Up! Integration With Cold Email Sales Tool",
    "h1": "Integrate Gift Up! with SalesBlink",
    "metaDescription": "Integrate Gift Up! and other eCommerce Tools with SalesBlink. Two Way Gift Up! Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verse",
    "slug": "agentology",
    "logo": "https://zapier-images.imgix.net/storage/services/454a4a208d2d56102076702a84daaa7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verse is a lead conversion platform that helps businesses quickly qualify leads through 2-way text conversations driven by powerful AI.",
    "category": "Sales & CRM",
    "title": "Verse Integration With Cold Email Sales Tool",
    "h1": "Integrate Verse with SalesBlink",
    "metaDescription": "Integrate Verse and other Sales & CRM Tools with SalesBlink. Two Way Verse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChartMogul",
    "slug": "chartmogul",
    "logo": "https://zapier-images.imgix.net/storage/developer/7de8463194fc2997c5b073e3af57eb6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChartMogul helps you measure, understand and grow your subscription business.",
    "category": "Ads & Conversion",
    "title": "ChartMogul Integration With Cold Email Sales Tool",
    "h1": "Integrate ChartMogul with SalesBlink",
    "metaDescription": "Integrate ChartMogul and other Ads & Conversion Tools with SalesBlink. Two Way ChartMogul Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Top Producer",
    "slug": "top-producer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9dfb6954d83cc02ade822a0e55fb6570.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Top Producer is a real estate CRM that simplifies the follow-up process so you can focus on what matters most—building lifelong relationships with your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Top Producer Integration With Cold Email Sales Tool",
    "h1": "Integrate Top Producer with SalesBlink",
    "metaDescription": "Integrate Top Producer and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Top Producer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GenerateBanners.com",
    "slug": "generatebannerscom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c844adb12b9f9badd2f0c105d556b2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple and efficient way to generate your social media images. Create beautiful open graph banners and increase the click-through rate to your website.",
    "category": "Images & Design",
    "title": "GenerateBanners.com Integration With Cold Email Sales Tool",
    "h1": "Integrate GenerateBanners.com with SalesBlink",
    "metaDescription": "Integrate GenerateBanners.com and other Images & Design Tools with SalesBlink. Two Way GenerateBanners.com Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zoho Connect",
    "slug": "zoho-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer/f2effc04dd155ea84064497a413800f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "From ideas to execution, Zoho Connect is your organization's private social network that redefines the way your employees and teams share information and collaborate with each other.",
    "category": "Team Chat",
    "title": "Zoho Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Connect with SalesBlink",
    "metaDescription": "Integrate Zoho Connect and other Team Chat Tools with SalesBlink. Two Way Zoho Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plecto",
    "slug": "plecto",
    "logo": "https://zapier-images.imgix.net/storage/services/f553eb3996c66c957cc6eb7a3d2b6de1.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helps your sales and customer service employees reach their targets every month.",
    "category": "Dashboards",
    "title": "Plecto Integration With Cold Email Sales Tool",
    "h1": "Integrate Plecto with SalesBlink",
    "metaDescription": "Integrate Plecto and other Dashboards Tools with SalesBlink. Two Way Plecto Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "BenchmarkONE",
    "slug": "benchmarkone",
    "logo": "https://zapier-images.imgix.net/storage/services/dafe9455d72f2f0897b1548b588714f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keep tabs on your contacts, automatically follow up on them, and track your marketing with smart insights in BenchmarkONE's simple CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BenchmarkONE Integration With Cold Email Sales Tool",
    "h1": "Integrate BenchmarkONE with SalesBlink",
    "metaDescription": "Integrate BenchmarkONE and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BenchmarkONE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SuperOffice CRM",
    "slug": "superofficecrm",
    "logo": "https://zapier-images.imgix.net/storage/services/544ca94560d2083155aa9e70083b4510.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SuperOffice CRM software helps businesses collaborate internally and improve their sales, marketing and service processes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SuperOffice CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate SuperOffice CRM with SalesBlink",
    "metaDescription": "Integrate SuperOffice CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SuperOffice CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SumoQuote",
    "slug": "sumoquote",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3b33887b65dff9cc49c23ab6d3082c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SumoQuote is a better way to build beautiful proposals so you can win more work.",
    "category": "Proposal & Invoice Management",
    "title": "SumoQuote Integration With Cold Email Sales Tool",
    "h1": "Integrate SumoQuote with SalesBlink",
    "metaDescription": "Integrate SumoQuote and other Proposal & Invoice Management Tools with SalesBlink. Two Way SumoQuote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mention",
    "slug": "mention",
    "logo": "https://zapier-images.imgix.net/storage/developer/600f9f67899413ceec0477cdc8985fc9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monitor any keyword in real-time and receive filtered, organized and prioritized information",
    "category": "Reviews",
    "title": "Mention Integration With Cold Email Sales Tool",
    "h1": "Integrate Mention with SalesBlink",
    "metaDescription": "Integrate Mention and other Reviews Tools with SalesBlink. Two Way Mention Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AppFollow",
    "slug": "appfollow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa85115765e0eb4fd79e31ab5a2164b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appfollow is a one-stop platform for app analytics, app reviews management, and app store optimization. Get reviews from the App Store, Google Play to monitor and analyse them.",
    "category": "Reviews",
    "title": "AppFollow Integration With Cold Email Sales Tool",
    "h1": "Integrate AppFollow with SalesBlink",
    "metaDescription": "Integrate AppFollow and other Reviews Tools with SalesBlink. Two Way AppFollow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kanbanize",
    "slug": "kanbanize",
    "logo": "https://zapier-images.imgix.net/storage/developer/732f98039a4b17a3cbb428808e10eaf3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kanbanize is a lean management platform for Portfolio Kanban implementation that helps teams achieve workflow transparency on a global level.",
    "category": "Project Management",
    "title": "Kanbanize Integration With Cold Email Sales Tool",
    "h1": "Integrate Kanbanize with SalesBlink",
    "metaDescription": "Integrate Kanbanize and other Project Management Tools with SalesBlink. Two Way Kanbanize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Assessment Generator",
    "slug": "assessment-generator",
    "logo": "https://zapier-images.imgix.net/storage/services/c302efd5073aaace22a7bd550a19e448.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily create assessments, questionnaires, and quizzes that you can add to any website to engage users.",
    "category": "Forms & Surveys",
    "title": "Assessment Generator Integration With Cold Email Sales Tool",
    "h1": "Integrate Assessment Generator with SalesBlink",
    "metaDescription": "Integrate Assessment Generator and other Forms & Surveys Tools with SalesBlink. Two Way Assessment Generator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ConvertCalculator",
    "slug": "convertcalculator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc668abd6b868a3a55ca09419e31b636.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConvertCalculator is the versatile calculator builder for your website that boosts sales and reduces hassle. Let your users calculate a price quote, savings, ROI, mortgage payments or anything else that needs calculation.",
    "category": "Forms & Surveys",
    "title": "ConvertCalculator Integration With Cold Email Sales Tool",
    "h1": "Integrate ConvertCalculator with SalesBlink",
    "metaDescription": "Integrate ConvertCalculator and other Forms & Surveys Tools with SalesBlink. Two Way ConvertCalculator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sympla",
    "slug": "sympla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f18b4fa7d4e7ce3555679ec38918771.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sympla is a platform for events that provides solutions to manage each step of their lifetime.",
    "category": "Event Management",
    "title": "Sympla Integration With Cold Email Sales Tool",
    "h1": "Integrate Sympla with SalesBlink",
    "metaDescription": "Integrate Sympla and other Event Management Tools with SalesBlink. Two Way Sympla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paperbell",
    "slug": "paperbell",
    "logo": "https://zapier-images.imgix.net/storage/services/51a8215de50ff3486809aba6116a3fd7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paperbell is the easiest way to sell coaching online – whether you’re an experienced coach, or you’re just getting started.",
    "category": "Calendar",
    "title": "Paperbell Integration With Cold Email Sales Tool",
    "h1": "Integrate Paperbell with SalesBlink",
    "metaDescription": "Integrate Paperbell and other Calendar Tools with SalesBlink. Two Way Paperbell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Signaturit",
    "slug": "signaturit",
    "logo": "https://zapier-images.imgix.net/storage/developer/11496f0260e112a66050a3f0b048fed9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Signaturit allows to send and sign documents online with legally binding electronic signatures.",
    "category": "Signatures",
    "title": "Signaturit Integration With Cold Email Sales Tool",
    "h1": "Integrate Signaturit with SalesBlink",
    "metaDescription": "Integrate Signaturit and other Signatures Tools with SalesBlink. Two Way Signaturit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Thankster",
    "slug": "thankster",
    "logo": "https://zapier-images.imgix.net/storage/developer/3321ece645f2634fba977bf1619ac01c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Creates and mails cards and notes with authentic handwriting, requiring only address information and message text.  Great for CRM, marketing automation, lead gen, and more.",
    "category": "Customer Appreciation",
    "title": "Thankster Integration With Cold Email Sales Tool",
    "h1": "Integrate Thankster with SalesBlink",
    "metaDescription": "Integrate Thankster and other Customer Appreciation Tools with SalesBlink. Two Way Thankster Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "The Receptionist",
    "slug": "the-receptionist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/708c14b9c3dddf8ad27c483a48f61301.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Receptionist helps streamline office entrance areas and automates the job of connecting visitors to the people they are there to see.",
    "category": "Devices",
    "title": "The Receptionist Integration With Cold Email Sales Tool",
    "h1": "Integrate The Receptionist with SalesBlink",
    "metaDescription": "Integrate The Receptionist and other Devices Tools with SalesBlink. Two Way The Receptionist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vonage Business Communications",
    "slug": "vonage-business-communications",
    "logo": "https://zapier-images.imgix.net/storage/services/dc588122fc1bd7f7fb99e7c4eb26bba5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Vonage Business Communications desktop and mobile applications are flexible cloud solutions that offer advanced features like click-to-call and call recording to help businesses of any size stay connected, productive, and compliant.",
    "category": "Phone & SMS",
    "title": "Vonage Business Communications Integration With Cold Email Sales Tool",
    "h1": "Integrate Vonage Business Communications with SalesBlink",
    "metaDescription": "Integrate Vonage Business Communications and other Phone & SMS Tools with SalesBlink. Two Way Vonage Business Communications Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invoiced",
    "slug": "invoiced",
    "logo": "https://zapier-images.imgix.net/storage/developer/d7e4a1759f2f4b8b413fbd4e3ba1d092.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Invoiced is autopilot for accounts receivable.",
    "category": "Accounting",
    "title": "Invoiced Integration With Cold Email Sales Tool",
    "h1": "Integrate Invoiced with SalesBlink",
    "metaDescription": "Integrate Invoiced and other Accounting Tools with SalesBlink. Two Way Invoiced Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TimelinesAI",
    "slug": "timelinesai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5eb790d77177a0837a7c1d4cc07ff23a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimelinesAI allows you to share access to your personal or business Whatsapp account, integrate it with other applications and build automations for your business and marketing flows.",
    "category": "Marketing Automation",
    "title": "TimelinesAI Integration With Cold Email Sales Tool",
    "h1": "Integrate TimelinesAI with SalesBlink",
    "metaDescription": "Integrate TimelinesAI and other Marketing Automation Tools with SalesBlink. Two Way TimelinesAI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Samdock",
    "slug": "samdock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ca799ba2bb7d3bcca2f8caea53edc91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Samdock is a simple CRM system for small businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Samdock Integration With Cold Email Sales Tool",
    "h1": "Integrate Samdock with SalesBlink",
    "metaDescription": "Integrate Samdock and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Samdock Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Happy Scribe",
    "slug": "happy-scribe",
    "logo": "https://zapier-images.imgix.net/storage/services/ea8c87764c3cdb64ba498da6893f84ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video & Audio to Text in minutes! All Formats of Audio & Video Accepted. Available in +119 languages. Export in Word, PDF, TXT, SRT, VTT, STL, XML, Premiere & AVID.",
    "category": "Transcription",
    "title": "Happy Scribe Integration With Cold Email Sales Tool",
    "h1": "Integrate Happy Scribe with SalesBlink",
    "metaDescription": "Integrate Happy Scribe and other Transcription Tools with SalesBlink. Two Way Happy Scribe Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "zcal",
    "slug": "zcal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6010bcc90cf2380f846c88dc7d21fc8f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "zcal is the free scheduling platform that helps you build stronger relationships",
    "category": "Scheduling & Booking",
    "title": "zcal Integration With Cold Email Sales Tool",
    "h1": "Integrate zcal with SalesBlink",
    "metaDescription": "Integrate zcal and other Scheduling & Booking Tools with SalesBlink. Two Way zcal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Harness",
    "slug": "harness",
    "logo": "https://zapier-images.imgix.net/storage/services/d4dc33baa65d3335f179c27a1316b019.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Raise More + Stress Less with the Harness Fundraising Platform - Nonprofit Industry Leader in Recurring Giving and Donor Experience Automation Tools",
    "category": "Fundraising",
    "title": "Harness Integration With Cold Email Sales Tool",
    "h1": "Integrate Harness with SalesBlink",
    "metaDescription": "Integrate Harness and other Fundraising Tools with SalesBlink. Two Way Harness Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clubworx",
    "slug": "clubworx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4353879d225f0353a83435dbfb6e603b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clubworx is a gym management application.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clubworx Integration With Cold Email Sales Tool",
    "h1": "Integrate Clubworx with SalesBlink",
    "metaDescription": "Integrate Clubworx and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clubworx Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Simplesat",
    "slug": "simplesat",
    "logo": "https://zapier-images.imgix.net/storage/services/25590e3295cfd422b85d7e6798b68a54.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simplesat is a survey tool that makes it easy for any business to collect, analyze and publish customer feedback.",
    "category": "Forms & Surveys",
    "title": "Simplesat Integration With Cold Email Sales Tool",
    "h1": "Integrate Simplesat with SalesBlink",
    "metaDescription": "Integrate Simplesat and other Forms & Surveys Tools with SalesBlink. Two Way Simplesat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Rise",
    "slug": "rise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c4abc56172fffb21c56006f8a08df892.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rise is an all-in-one system that makes training easy to create, enjoyable to take, and simple to manage.",
    "category": "Online Courses",
    "title": "Rise Integration With Cold Email Sales Tool",
    "h1": "Integrate Rise with SalesBlink",
    "metaDescription": "Integrate Rise and other Online Courses Tools with SalesBlink. Two Way Rise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Waitwhile",
    "slug": "waitwhile",
    "logo": "https://zapier-images.imgix.net/storage/services/6b4da38a514a8eac739364a530ccae38.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Waitwhile is a leading waitlist and scheduling service that helps you improve your lines and create a radically better wait experience for your customers.",
    "category": "Scheduling & Booking",
    "title": "Waitwhile Integration With Cold Email Sales Tool",
    "h1": "Integrate Waitwhile with SalesBlink",
    "metaDescription": "Integrate Waitwhile and other Scheduling & Booking Tools with SalesBlink. Two Way Waitwhile Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Visma eAccounting",
    "slug": "visma-eaccounting",
    "logo": "https://zapier-images.imgix.net/storage/services/bb0e8c2e7954c7b679b1c14637070854.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Visma eAccounting is a financial software for small and medium sized businesses.",
    "category": "Accounting",
    "title": "Visma eAccounting Integration With Cold Email Sales Tool",
    "h1": "Integrate Visma eAccounting with SalesBlink",
    "metaDescription": "Integrate Visma eAccounting and other Accounting Tools with SalesBlink. Two Way Visma eAccounting Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Bizzabo",
    "slug": "bizzabo",
    "logo": "https://zapier-images.imgix.net/storage/services/6666039967e61a726e9cd0578791c04f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bizzabo is an event success platform that helps organizers create successful events by empowering them to build amazing websites, sell tickets, grow communities, go mobile and maximize their events experiences and results.",
    "category": "Event Management",
    "title": "Bizzabo Integration With Cold Email Sales Tool",
    "h1": "Integrate Bizzabo with SalesBlink",
    "metaDescription": "Integrate Bizzabo and other Event Management Tools with SalesBlink. Two Way Bizzabo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Userflow",
    "slug": "userflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87feec0eadbc4f021d926d0602664f3c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Userflow is a user onboarding platform, which lets your whole team build customized in-app tours, checklists and surveys, without code.",
    "category": "Customer Support",
    "title": "Userflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Userflow with SalesBlink",
    "metaDescription": "Integrate Userflow and other Customer Support Tools with SalesBlink. Two Way Userflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "crmgrow",
    "slug": "crmgrow",
    "logo": "https://zapier-images.imgix.net/storage/services/f9548f2f264334bca86509bbb1436c24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "crmgrow is a CRM platform with various tracking abilities such as video, screen, and user activity tracking.",
    "category": "CRM (Customer Relationship Management)",
    "title": "crmgrow Integration With Cold Email Sales Tool",
    "h1": "Integrate crmgrow with SalesBlink",
    "metaDescription": "Integrate crmgrow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way crmgrow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spoki",
    "slug": "spoki",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/04a5ce0f288fa0079fac157239c1e9ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spoki allows you to automate and use Whatsapp in your communication and marketing strategy. You can interact with your customers in a completely new way, adding buttons on your pre-approved template messages.",
    "category": "Communication",
    "title": "Spoki Integration With Cold Email Sales Tool",
    "h1": "Integrate Spoki with SalesBlink",
    "metaDescription": "Integrate Spoki and other Communication Tools with SalesBlink. Two Way Spoki Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grain",
    "slug": "grain",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a55f6f85595f80f9e42239f1a880899.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Record, transcribe, & share video highlights your team will actually watch.",
    "category": "Productivity",
    "title": "Grain Integration With Cold Email Sales Tool",
    "h1": "Integrate Grain with SalesBlink",
    "metaDescription": "Integrate Grain and other Productivity Tools with SalesBlink. Two Way Grain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zakeke",
    "slug": "zakeke-product-customizer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3dc5093a3b8507cc398d060f28d1906.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zakeke is a SaaS tool empowering retailers to offer their customers live product personalization  on their websites.",
    "category": "eCommerce",
    "title": "Zakeke Integration With Cold Email Sales Tool",
    "h1": "Integrate Zakeke with SalesBlink",
    "metaDescription": "Integrate Zakeke and other eCommerce Tools with SalesBlink. Two Way Zakeke Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pathwright",
    "slug": "pathwright",
    "logo": "https://zapier-images.imgix.net/storage/services/55e61dd8b3ee1db08b567165d05b08b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Design step-by-step learning paths for anyone. With Pathwright you can create learning paths that guide students, employees, or anyone in the world to a new level of skill.",
    "category": "Online Courses",
    "title": "Pathwright Integration With Cold Email Sales Tool",
    "h1": "Integrate Pathwright with SalesBlink",
    "metaDescription": "Integrate Pathwright and other Online Courses Tools with SalesBlink. Two Way Pathwright Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Modern Events Calendar (MEC)",
    "slug": "modern-events-calendar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7465341f4c527f66b78b0ceb3bd8983c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Modern Events Calendar is a responsive, mobile-friendly, FREE, and comprehensive events management plugin which is extremely user-friendly and well-designed for displaying the events calendar on the websites, ever easier.",
    "category": "Event Management",
    "title": "Modern Events Calendar (MEC) Integration With Cold Email Sales Tool",
    "h1": "Integrate Modern Events Calendar (MEC) with SalesBlink",
    "metaDescription": "Integrate Modern Events Calendar (MEC) and other Event Management Tools with SalesBlink. Two Way Modern Events Calendar (MEC) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "webCRM",
    "slug": "webcrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e76bfd0e61ada4da00c091e86061ac9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "webCRM helps your sales team with the daily workflow and overview.",
    "category": "CRM (Customer Relationship Management)",
    "title": "webCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate webCRM with SalesBlink",
    "metaDescription": "Integrate webCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way webCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vonage SMS API",
    "slug": "vonage-sms-api",
    "logo": "https://zapier-images.imgix.net/storage/services/4301738ebfef106596ab2de14c68a75c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Vonage SMS API platform (formerly Nexmo), allows you to send and receive SMS messages globally.",
    "category": "Phone & SMS",
    "title": "Vonage SMS API Integration With Cold Email Sales Tool",
    "h1": "Integrate Vonage SMS API with SalesBlink",
    "metaDescription": "Integrate Vonage SMS API and other Phone & SMS Tools with SalesBlink. Two Way Vonage SMS API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FireText",
    "slug": "firetext",
    "logo": "https://zapier-images.imgix.net/storage/developer/aef0ef4b2c75ab3d187f1dbfc5711fc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FireText makes effective SMS marketing easy indeed - whether you're brand new to text message marketing or a seasoned hand.",
    "category": "Phone & SMS",
    "title": "FireText Integration With Cold Email Sales Tool",
    "h1": "Integrate FireText with SalesBlink",
    "metaDescription": "Integrate FireText and other Phone & SMS Tools with SalesBlink. Two Way FireText Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Novo",
    "slug": "novo",
    "logo": "https://zapier-images.imgix.net/storage/services/ec83221520be08de057de29fad09e4b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Novo provides small businesses a better, more connected business bank account.",
    "category": "Accounting",
    "title": "Novo Integration With Cold Email Sales Tool",
    "h1": "Integrate Novo with SalesBlink",
    "metaDescription": "Integrate Novo and other Accounting Tools with SalesBlink. Two Way Novo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoCardless",
    "slug": "gocardless",
    "logo": "https://zapier-images.imgix.net/storage/services/a583119f64f17ecfc4ea1dfd5c65c227.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoCardless is a payments platform helping businesses collect payments directly from customer bank accounts.",
    "category": "Payment Processing",
    "title": "GoCardless Integration With Cold Email Sales Tool",
    "h1": "Integrate GoCardless with SalesBlink",
    "metaDescription": "Integrate GoCardless and other Payment Processing Tools with SalesBlink. Two Way GoCardless Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Riddle Quiz Maker",
    "slug": "riddle-quiz-maker",
    "logo": "https://zapier-images.imgix.net/storage/services/b573032a4380c1ff90e660c350950825.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Riddle is a quiz marketing platform with built-in lead generation forms. In addition to quizzes you can also create polls, survey, personality tests, interactive stories and more.",
    "category": "Forms & Surveys",
    "title": "Riddle Quiz Maker Integration With Cold Email Sales Tool",
    "h1": "Integrate Riddle Quiz Maker with SalesBlink",
    "metaDescription": "Integrate Riddle Quiz Maker and other Forms & Surveys Tools with SalesBlink. Two Way Riddle Quiz Maker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trainual",
    "slug": "trainual",
    "logo": "https://zapier-images.imgix.net/storage/services/da1a49d78986116dc93d89b5aaa8b62f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trainual is a simple tool for building the how-to guide for your business.",
    "category": "Online Courses",
    "title": "Trainual Integration With Cold Email Sales Tool",
    "h1": "Integrate Trainual with SalesBlink",
    "metaDescription": "Integrate Trainual and other Online Courses Tools with SalesBlink. Two Way Trainual Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Text Request",
    "slug": "text-request",
    "logo": "https://zapier-images.imgix.net/storage/services/b6c24c489f1c421bcb4599c2a2a4f773.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text Request is a business texting platform that helps your company communicate via SMS.",
    "category": "Phone & SMS",
    "title": "Text Request Integration With Cold Email Sales Tool",
    "h1": "Integrate Text Request with SalesBlink",
    "metaDescription": "Integrate Text Request and other Phone & SMS Tools with SalesBlink. Two Way Text Request Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hostfully",
    "slug": "hostfully",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/72954cf6418c18df5087e46f792a6b45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hostfully is a complete, end-to-end property management platform that helps vacation rental PMs boost revenues.",
    "category": "Scheduling & Booking",
    "title": "Hostfully Integration With Cold Email Sales Tool",
    "h1": "Integrate Hostfully with SalesBlink",
    "metaDescription": "Integrate Hostfully and other Scheduling & Booking Tools with SalesBlink. Two Way Hostfully Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salsa Engage",
    "slug": "salsa-engage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d60bf2ea9b5cc407f5451c99b3a123c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salsa Engage is a purpose-built marketing automation, fundraising, advocacy, and peer-to-peer online software for nonprofits.",
    "category": "Fundraising",
    "title": "Salsa Engage Integration With Cold Email Sales Tool",
    "h1": "Integrate Salsa Engage with SalesBlink",
    "metaDescription": "Integrate Salsa Engage and other Fundraising Tools with SalesBlink. Two Way Salsa Engage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TikTok Conversions",
    "slug": "tiktok-conversion",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b05015a22b1b3431ca673a6a8f6cc6a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send important customer events directly from your server or CRM to TikTok Event API, allowing you to better optimize for ad performance.",
    "category": "Ads & Conversion",
    "title": "TikTok Conversions Integration With Cold Email Sales Tool",
    "h1": "Integrate TikTok Conversions with SalesBlink",
    "metaDescription": "Integrate TikTok Conversions and other Ads & Conversion Tools with SalesBlink. Two Way TikTok Conversions Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ServiceTrade",
    "slug": "servicetrade",
    "logo": "https://zapier-images.imgix.net/storage/developer/869f8c66b2496ce9abe9c7cbfcb533a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceTrade is a customer service and sales application for commercial service contractors. Convert more of your contract work into repair revenue. Educate customers about the work you did for them with media-rich online service reports. Manage your service operations and connect your customer service data with other business applications.",
    "category": "Scheduling & Booking",
    "title": "ServiceTrade Integration With Cold Email Sales Tool",
    "h1": "Integrate ServiceTrade with SalesBlink",
    "metaDescription": "Integrate ServiceTrade and other Scheduling & Booking Tools with SalesBlink. Two Way ServiceTrade Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoodBarber",
    "slug": "goodbarber",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e48fc9d1f7f05a99c473671046a2d42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoodBarber is a no-code app building platform to create iOS and Android native apps.",
    "category": "App Builder",
    "title": "GoodBarber Integration With Cold Email Sales Tool",
    "h1": "Integrate GoodBarber with SalesBlink",
    "metaDescription": "Integrate GoodBarber and other App Builder Tools with SalesBlink. Two Way GoodBarber Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Dance Studio Pro",
    "slug": "dance-studio-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a53fb1a136fb3217c587ea513dfa5b6a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dance Studio Pro is the world's best dance studio software",
    "category": "CRM (Customer Relationship Management)",
    "title": "Dance Studio Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Dance Studio Pro with SalesBlink",
    "metaDescription": "Integrate Dance Studio Pro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Dance Studio Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grade.us",
    "slug": "grade-us",
    "logo": "https://zapier-images.imgix.net/storage/services/4063155676a87460ca7666ce2764a40b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grade.us is the simplest tool for businesses to gather, monitor, and manage online reviews.",
    "category": "Marketing",
    "title": "Grade.us Integration With Cold Email Sales Tool",
    "h1": "Integrate Grade.us with SalesBlink",
    "metaDescription": "Integrate Grade.us and other Marketing Tools with SalesBlink. Two Way Grade.us Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PDF4me",
    "slug": "pdf4me",
    "logo": "https://zapier-images.imgix.net/storage/developer/7bb6e45c39d8b917cd50c122b17f0edf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PDF4me is a tool for pdf conversion, splitting, merging, make them PDF/A compliant, protect documents etc.",
    "category": "Documents",
    "title": "PDF4me Integration With Cold Email Sales Tool",
    "h1": "Integrate PDF4me with SalesBlink",
    "metaDescription": "Integrate PDF4me and other Documents Tools with SalesBlink. Two Way PDF4me Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TeamGantt",
    "slug": "teamgantt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec9e603bfeaa4a8338d97919330e2ba2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamGantt is an intuitive project planning & management service.",
    "category": "Project Management",
    "title": "TeamGantt Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamGantt with SalesBlink",
    "metaDescription": "Integrate TeamGantt and other Project Management Tools with SalesBlink. Two Way TeamGantt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avaza",
    "slug": "avaza",
    "logo": "https://zapier-images.imgix.net/storage/developer/30aff3e4898dee15bda1f9d89989b770.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Avaza is software designed to run your client focused business. It includes modules for project & task management, timesheets, expense reporting, quotes & invoicing.",
    "category": "Project Management",
    "title": "Avaza Integration With Cold Email Sales Tool",
    "h1": "Integrate Avaza with SalesBlink",
    "metaDescription": "Integrate Avaza and other Project Management Tools with SalesBlink. Two Way Avaza Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Amazon DynamoDB",
    "slug": "dynamodb",
    "logo": "https://zapier-images.imgix.net/storage/services/2d6115168232f9a49767bc7049ba4246.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon's DynamoDB is a fast NoSQL database service. It is fully managed, simple and cost-effective. It can store and retrieve any amount of data and serve any level of request traffic.",
    "category": "Amazon",
    "title": "Amazon DynamoDB Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon DynamoDB with SalesBlink",
    "metaDescription": "Integrate Amazon DynamoDB and other Amazon Tools with SalesBlink. Two Way Amazon DynamoDB Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CallFire",
    "slug": "callfire",
    "logo": "https://zapier-images.imgix.net/storage/developer/3ab7fe0ea98c7d27b672758363a3b808.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallFire is a cloud telephony platform. Easily fire off an SMS or Call. You can trigger events when your phone number is called or texted as well.",
    "category": "Phone & SMS",
    "title": "CallFire Integration With Cold Email Sales Tool",
    "h1": "Integrate CallFire with SalesBlink",
    "metaDescription": "Integrate CallFire and other Phone & SMS Tools with SalesBlink. Two Way CallFire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drop Cowboy",
    "slug": "drop-cowboy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b32106dd5c9b0110e7623df383d3a855.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drop Cowboy is a Ringless Voicemail and SMS Marketing platform built to automate communication processes for the modern sales and support team.",
    "category": "Marketing",
    "title": "Drop Cowboy Integration With Cold Email Sales Tool",
    "h1": "Integrate Drop Cowboy with SalesBlink",
    "metaDescription": "Integrate Drop Cowboy and other Marketing Tools with SalesBlink. Two Way Drop Cowboy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Collect.chat",
    "slug": "collect-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74d7b6e8cd7a423027f1b6beab988b4e.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect.chat helps you make interactive chatbots for your website. Easily collect data, leads and feedbacks from your visitors on auto-pilot.",
    "category": "Forms & Surveys",
    "title": "Collect.chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Collect.chat with SalesBlink",
    "metaDescription": "Integrate Collect.chat and other Forms & Surveys Tools with SalesBlink. Two Way Collect.chat Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Doppler",
    "slug": "doppler",
    "logo": "https://zapier-images.imgix.net/storage/developer/b048c39c706af7f4f82d26926b9fc80c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Doppler is an email marketing tool that allows you to reach your clients in a simple, fast and effective way.",
    "category": "Email Newsletters",
    "title": "Doppler Integration With Cold Email Sales Tool",
    "h1": "Integrate Doppler with SalesBlink",
    "metaDescription": "Integrate Doppler and other Email Newsletters Tools with SalesBlink. Two Way Doppler Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "E-goi",
    "slug": "e-goi",
    "logo": "https://zapier-images.imgix.net/storage/services/20b22d4a14804d5518f2ce329b035c02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "E-goi is a Multichannel Marketing Automation Platform, including email marketing services, SMS, Voice Broadcast, Push Notifications, Web Push and Forms to Capture, Automate, Communicate, Analyse and Generate more Sales.",
    "category": "Marketing Automation",
    "title": "E-goi Integration With Cold Email Sales Tool",
    "h1": "Integrate E-goi with SalesBlink",
    "metaDescription": "Integrate E-goi and other Marketing Automation Tools with SalesBlink. Two Way E-goi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Captivate",
    "slug": "captivate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61902fa0963722e0d0f27a85aed816ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Captivate is a growth-oriented podcast host.",
    "category": "Product Management",
    "title": "Captivate Integration With Cold Email Sales Tool",
    "h1": "Integrate Captivate with SalesBlink",
    "metaDescription": "Integrate Captivate and other Product Management Tools with SalesBlink. Two Way Captivate Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GivingFuel",
    "slug": "givingfuel",
    "logo": "https://zapier-images.imgix.net/storage/services/86ea69dc2411e300699f33bb13c6b039.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GivingFuel helps people and organizations fundraise to change the world. We are the world’s simplest and most affordable donation platform",
    "category": "Fundraising",
    "title": "GivingFuel Integration With Cold Email Sales Tool",
    "h1": "Integrate GivingFuel with SalesBlink",
    "metaDescription": "Integrate GivingFuel and other Fundraising Tools with SalesBlink. Two Way GivingFuel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reamaze",
    "slug": "reamaze",
    "logo": "https://zapier-images.imgix.net/storage/developer/d6ad5bbab17a2c190a441fab0f6443cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reamaze is an all-in-one helpdesk for chat, social media, SMS, and email right from your site or app.",
    "category": "Customer Support",
    "title": "Reamaze Integration With Cold Email Sales Tool",
    "h1": "Integrate Reamaze with SalesBlink",
    "metaDescription": "Integrate Reamaze and other Customer Support Tools with SalesBlink. Two Way Reamaze Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Device Magic",
    "slug": "devicemagic",
    "logo": "https://zapier-images.imgix.net/storage/services/4bb05cf64060d184f221fd25eb1fb614.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build custom forms to capture important data through your mobile device with Device Magic. Save time, get better data, and automate your business.",
    "category": "Forms & Surveys",
    "title": "Device Magic Integration With Cold Email Sales Tool",
    "h1": "Integrate Device Magic with SalesBlink",
    "metaDescription": "Integrate Device Magic and other Forms & Surveys Tools with SalesBlink. Two Way Device Magic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ryver",
    "slug": "ryver",
    "logo": "https://zapier-images.imgix.net/storage/developer/4eb00bd45151c73a3a98408bfcbb9f84.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ryver organizes all your collaboration in one app with team chat, task management, and voice and video calls.",
    "category": "Team Collaboration",
    "title": "Ryver Integration With Cold Email Sales Tool",
    "h1": "Integrate Ryver with SalesBlink",
    "metaDescription": "Integrate Ryver and other Team Collaboration Tools with SalesBlink. Two Way Ryver Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "97Display",
    "slug": "97display",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ccb5cbaa0e8e71680d153318c20f1852.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "97 Display Lead Generating Websites for Martial Arts, Fitness Businesses, and Gyms.",
    "category": "Forms & Surveys",
    "title": "97Display Integration With Cold Email Sales Tool",
    "h1": "Integrate 97Display with SalesBlink",
    "metaDescription": "Integrate 97Display and other Forms & Surveys Tools with SalesBlink. Two Way 97Display Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Giftbit",
    "slug": "giftbit",
    "logo": "https://zapier-images.imgix.net/storage/services/3a7a9608d6d3e9397f9e62b70b0e02ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Giftbit lets you send and track digital gift card rewards from 125+ brands.",
    "category": "eCommerce",
    "title": "Giftbit Integration With Cold Email Sales Tool",
    "h1": "Integrate Giftbit with SalesBlink",
    "metaDescription": "Integrate Giftbit and other eCommerce Tools with SalesBlink. Two Way Giftbit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crelate",
    "slug": "crelate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e708cf9b23c49a2540e6ebb5bd83074.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crelate is modern Talent Relationship Management platform paired with simple, flexible Applicant Tracking and delivered with white-glove service.",
    "category": "HR Talent & Recruitment",
    "title": "Crelate Integration With Cold Email Sales Tool",
    "h1": "Integrate Crelate with SalesBlink",
    "metaDescription": "Integrate Crelate and other HR Talent & Recruitment Tools with SalesBlink. Two Way Crelate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beeminder",
    "slug": "beeminder",
    "logo": "https://zapier-images.imgix.net/storage/developer/d4c4cebc09d55a9518a224ac7125683d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beeminder is a goal-tracking tool with teeth. Use Zapier to send Beeminder data and let Beeminder plot your progress on a Yellow Brick Road to your goal. Keep all your datapoints on the road and Beeminder will always be free. Go off the road and you (literally) pay the price.",
    "category": "Time Tracking Software",
    "title": "Beeminder Integration With Cold Email Sales Tool",
    "h1": "Integrate Beeminder with SalesBlink",
    "metaDescription": "Integrate Beeminder and other Time Tracking Software Tools with SalesBlink. Two Way Beeminder Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SocialPilot",
    "slug": "socialpilot",
    "logo": "https://zapier-images.imgix.net/storage/services/fbb20304951b3308cdd73cb9601bf648.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocialPilot is a social media marketing tool which lets you create and schedules social media posts for your social media profiles.",
    "category": "Social Media Marketing",
    "title": "SocialPilot Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialPilot with SalesBlink",
    "metaDescription": "Integrate SocialPilot and other Social Media Marketing Tools with SalesBlink. Two Way SocialPilot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instabot: Chatbot Platform",
    "slug": "instabot",
    "logo": "https://zapier-images.imgix.net/storage/developer/af9a0d1248198b47877a48770a9e7666_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instabot is a conversion chatbot for your website, mobile app & emails.",
    "category": "Marketing Automation",
    "title": "Instabot: Chatbot Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Instabot: Chatbot Platform with SalesBlink",
    "metaDescription": "Integrate Instabot: Chatbot Platform and other Marketing Automation Tools with SalesBlink. Two Way Instabot: Chatbot Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plutio",
    "slug": "plutio",
    "logo": "https://zapier-images.imgix.net/storage/services/6d8967604f93dcde2b57a0f1e7be1e39.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plutio allows you to manage projects and tasks, create proposals and contracts, send invoices and get paid online, collaborate with your clients and team, plus so much more.",
    "category": "Project Management",
    "title": "Plutio Integration With Cold Email Sales Tool",
    "h1": "Integrate Plutio with SalesBlink",
    "metaDescription": "Integrate Plutio and other Project Management Tools with SalesBlink. Two Way Plutio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Sendlane",
    "slug": "sendlane",
    "logo": "https://zapier-images.imgix.net/storage/developer/95ed58871f6f37d513a867475777806f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendlane is an email automation platform that allows you to connect and communicate with your subscribers and customers.",
    "category": "Email Newsletters",
    "title": "Sendlane Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendlane with SalesBlink",
    "metaDescription": "Integrate Sendlane and other Email Newsletters Tools with SalesBlink. Two Way Sendlane Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SquadCast",
    "slug": "squadcast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0384fa2159f40fa94122c0505a7665c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SquadCast is the cloud recording studio where you can connect and record audio and video to create content audiences love.",
    "category": "Video & Audio",
    "title": "SquadCast Integration With Cold Email Sales Tool",
    "h1": "Integrate SquadCast with SalesBlink",
    "metaDescription": "Integrate SquadCast and other Video & Audio Tools with SalesBlink. Two Way SquadCast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeagueApps",
    "slug": "leagueapps",
    "logo": "https://zapier-images.imgix.net/storage/developer/5dd9ad592d1c09cba35a44c8598a2a50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeagueApps equips sports organizers, coaches, parents, and athletes with the tools they need to make sports happen. Our sports management software takes the headache out of everything from registration to schedule management, giving organizers the freedom to focus on what matters.",
    "category": "Scheduling & Booking",
    "title": "LeagueApps Integration With Cold Email Sales Tool",
    "h1": "Integrate LeagueApps with SalesBlink",
    "metaDescription": "Integrate LeagueApps and other Scheduling & Booking Tools with SalesBlink. Two Way LeagueApps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Procore",
    "slug": "procore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/279508a9dc8c7311d505587d7045e481.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Procore is a construction project management platform built in the cloud.",
    "category": "Project Management",
    "title": "Procore Integration With Cold Email Sales Tool",
    "h1": "Integrate Procore with SalesBlink",
    "metaDescription": "Integrate Procore and other Project Management Tools with SalesBlink. Two Way Procore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Arbox",
    "slug": "arbox",
    "logo": "https://zapier-images.imgix.net/storage/services/0336eaac6a14930c7be9996ff21e5f1f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Arbox is a sleek and easy-to-use fitness management platform offering a suite of tools for gyms and fitness studios.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Arbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Arbox with SalesBlink",
    "metaDescription": "Integrate Arbox and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Arbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Updown.io",
    "slug": "updownio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a71ad4542de7e1528651c601c4e63035.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "updown.io is an online service that checks your website's status by periodically sending an HTTP request. It then notifies you by Slack, Telegram, email or SMS when your website is not responding correctly, your SSL certificate is invalid or about to expire, and more.",
    "category": "Server Monitoring",
    "title": "Updown.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Updown.io with SalesBlink",
    "metaDescription": "Integrate Updown.io and other Server Monitoring Tools with SalesBlink. Two Way Updown.io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "IRIS KashFlow",
    "slug": "iris-kashflow",
    "logo": "https://zapier-images.imgix.net/storage/services/7c69eea27f809edb5508cd29f2d8d473.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IRIS KashFlow is an easy-to-use accounting and bookkeeping software allowing you to run your business from anywhere at any time whilst staying compliant.",
    "category": "Accounting",
    "title": "IRIS KashFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate IRIS KashFlow with SalesBlink",
    "metaDescription": "Integrate IRIS KashFlow and other Accounting Tools with SalesBlink. Two Way IRIS KashFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BodyGraph",
    "slug": "bodygraph",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d6e480212ca6041aba54f368ed873ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BodyGraph Chart gives you all the tools you need to create your Human Design Chart and integrate it into your website.",
    "category": "Education",
    "title": "BodyGraph Integration With Cold Email Sales Tool",
    "h1": "Integrate BodyGraph with SalesBlink",
    "metaDescription": "Integrate BodyGraph and other Education Tools with SalesBlink. Two Way BodyGraph Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AlterEstate",
    "slug": "alterestate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b01bff3a84eb5c871960477b1a3c49c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AlterEstate is a software for Real Estate Agencies to help them connect and build strong relationships with their clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AlterEstate Integration With Cold Email Sales Tool",
    "h1": "Integrate AlterEstate with SalesBlink",
    "metaDescription": "Integrate AlterEstate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AlterEstate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Height",
    "slug": "height",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f76d29beaafa0ce35c5309f75765e00.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Height is a project management software for teams to collaborate faster with tasks, chat, and flexible workflows.",
    "category": "Project Management",
    "title": "Height Integration With Cold Email Sales Tool",
    "h1": "Integrate Height with SalesBlink",
    "metaDescription": "Integrate Height and other Project Management Tools with SalesBlink. Two Way Height Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zenoti",
    "slug": "zenoti",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21f2ed3c5605337c1132a848ff197aab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenoti provides an all-in-one, cloud-based software solution for the spa, salon and med spa industry.",
    "category": "Marketing Automation",
    "title": "Zenoti Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenoti with SalesBlink",
    "metaDescription": "Integrate Zenoti and other Marketing Automation Tools with SalesBlink. Two Way Zenoti Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho People",
    "slug": "zoho-people",
    "logo": "https://zapier-images.imgix.net/storage/developer/1d743a8037dcd15556ff55b08dc95552.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho People is an online HR management software which helps you automate all your HR processes efficiently.",
    "category": "HR Talent & Recruitment",
    "title": "Zoho People Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho People with SalesBlink",
    "metaDescription": "Integrate Zoho People and other HR Talent & Recruitment Tools with SalesBlink. Two Way Zoho People Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Wizebank",
    "slug": "wizebank",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08f4dc972c7af6273a898a508415088d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wizebank gives your customers the freedom to pay over time while you get paid upfront. It’s a win-win.",
    "category": "eCommerce",
    "title": "Wizebank Integration With Cold Email Sales Tool",
    "h1": "Integrate Wizebank with SalesBlink",
    "metaDescription": "Integrate Wizebank and other eCommerce Tools with SalesBlink. Two Way Wizebank Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ServiceNow",
    "slug": "servicenow",
    "logo": "https://zapier-images.imgix.net/storage/services/863c7af821d78ce71995196152757923.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceNow specializes in IT services management (ITSM), IT operations management (ITOM) and IT business management (ITBM).",
    "category": "Developer Tools",
    "title": "ServiceNow Integration With Cold Email Sales Tool",
    "h1": "Integrate ServiceNow with SalesBlink",
    "metaDescription": "Integrate ServiceNow and other Developer Tools Tools with SalesBlink. Two Way ServiceNow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Flic",
    "slug": "flic",
    "logo": "https://zapier-images.imgix.net/storage/developer/3a271fdb7806c6f196568bc0d6c319fa_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flic is a smart button that acts like a shortcut for your everyday life.",
    "category": "Devices",
    "title": "Flic Integration With Cold Email Sales Tool",
    "h1": "Integrate Flic with SalesBlink",
    "metaDescription": "Integrate Flic and other Devices Tools with SalesBlink. Two Way Flic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RepairDesk",
    "slug": "repairdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/78ea60a62cb28d24ab868be897883bad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RepairDesk is an all-in-one repair shop management platform that helps cell phone repair shops easily manage repair jobs, inventory, customers, and much more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "RepairDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate RepairDesk with SalesBlink",
    "metaDescription": "Integrate RepairDesk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way RepairDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flokzu",
    "slug": "flokzu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2a88a4fcab5b28741c4222445bf0488a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flokzu is a powerful cloud BPM suite, made agile & friendly! Unlock your growth through workflow automation and document management.",
    "category": "Task Management",
    "title": "Flokzu Integration With Cold Email Sales Tool",
    "h1": "Integrate Flokzu with SalesBlink",
    "metaDescription": "Integrate Flokzu and other Task Management Tools with SalesBlink. Two Way Flokzu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Disqus",
    "slug": "disqus",
    "logo": "https://zapier-images.imgix.net/storage/developer/1a46bba4b2ef13932bbc45430d26325d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "If you’re passionate about writing and sharing, Disqus helps you build a community of active readers and commenters. If you’re a brand building an audience for content, advertising with Disqus connects you to geeks geeking out, about stuff that matters to you.",
    "category": "Website Builders",
    "title": "Disqus Integration With Cold Email Sales Tool",
    "h1": "Integrate Disqus with SalesBlink",
    "metaDescription": "Integrate Disqus and other Website Builders Tools with SalesBlink. Two Way Disqus Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Vision6",
    "slug": "vision6",
    "logo": "https://zapier-images.imgix.net/storage/developer/af351454fbd96e2a305494280c2a2655.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your business with powerful email and SMS marketing by using Vision6. Trusted by thousands of small to large businesses, agencies, government and financial institutions, and nonprofit organizations.",
    "category": "Marketing Automation",
    "title": "Vision6 Integration With Cold Email Sales Tool",
    "h1": "Integrate Vision6 with SalesBlink",
    "metaDescription": "Integrate Vision6 and other Marketing Automation Tools with SalesBlink. Two Way Vision6 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Egnyte",
    "slug": "egnyte",
    "logo": "https://zapier-images.imgix.net/storage/developer/da3325591dd6988c6f25cb32b0e48af0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Egnyte enables users to easily and securely share files on-premises and in the cloud. Egnyte’s award-winning platform integrates with any cloud, storage, device and business application to enable customers to optimize their collaboration environments through secure access, centralized control and unified visibility.",
    "category": "File Management & Storage",
    "title": "Egnyte Integration With Cold Email Sales Tool",
    "h1": "Integrate Egnyte with SalesBlink",
    "metaDescription": "Integrate Egnyte and other File Management & Storage Tools with SalesBlink. Two Way Egnyte Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Tokko Broker",
    "slug": "tokko-broker",
    "logo": "https://zapier-images.imgix.net/storage/services/27f6397f05ca2dda1f1d32f9ecf74af1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tokko Broker is a real estate app that lets you sync your listings on real estate portals and your website automatically. You can also receive and follow up on your leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tokko Broker Integration With Cold Email Sales Tool",
    "h1": "Integrate Tokko Broker with SalesBlink",
    "metaDescription": "Integrate Tokko Broker and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tokko Broker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Albacross",
    "slug": "albacross",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c77df476eb14a01902dc0ba8f50f240_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Albacross will show you exactly which companies have visited your website and what they are interested in. Turn unknown visitors into qualified leads with contact information.",
    "category": "Marketing Automation",
    "title": "Albacross Integration With Cold Email Sales Tool",
    "h1": "Integrate Albacross with SalesBlink",
    "metaDescription": "Integrate Albacross and other Marketing Automation Tools with SalesBlink. Two Way Albacross Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mailmodo",
    "slug": "mailmodo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/255cb88f35190ed96170e495d1275b45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailmodo is an email marketing tool which helps businesses get higher conversions from their emails",
    "category": "Marketing",
    "title": "Mailmodo Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailmodo with SalesBlink",
    "metaDescription": "Integrate Mailmodo and other Marketing Tools with SalesBlink. Two Way Mailmodo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Lemon Squeezy",
    "slug": "lemon-squeezy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/037bce6d24420e3c267dafa43094c8dd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payments, tax & subscriptions for software companies",
    "category": "eCommerce",
    "title": "Lemon Squeezy Integration With Cold Email Sales Tool",
    "h1": "Integrate Lemon Squeezy with SalesBlink",
    "metaDescription": "Integrate Lemon Squeezy and other eCommerce Tools with SalesBlink. Two Way Lemon Squeezy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "DropFunnels",
    "slug": "dropfunnels",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8e7b4472f202b4d5568c7f736c8e650d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DropFunnels application allows an authenticated user to transfer their data regarding leads, memberships, and orders from DropFunnels to any other platform.",
    "category": "Website Builders",
    "title": "DropFunnels Integration With Cold Email Sales Tool",
    "h1": "Integrate DropFunnels with SalesBlink",
    "metaDescription": "Integrate DropFunnels and other Website Builders Tools with SalesBlink. Two Way DropFunnels Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Tookan",
    "slug": "tookan",
    "logo": "https://zapier-images.imgix.net/storage/services/6c26885fdcaf5bedf53ffbcda97d6094.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Schedule, dispatch and track your fleet with an intuitive & powerful on-demand delivery management platform.",
    "category": "Scheduling & Booking",
    "title": "Tookan Integration With Cold Email Sales Tool",
    "h1": "Integrate Tookan with SalesBlink",
    "metaDescription": "Integrate Tookan and other Scheduling & Booking Tools with SalesBlink. Two Way Tookan Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Feefo",
    "slug": "feefo",
    "logo": "https://zapier-images.imgix.net/storage/developer/96f91c4f5f46f0c86e54cd97d050d244_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feefo is a globally recognised platform used by thousands of brands to collect genuine, verified customer feedback.",
    "category": "Forms & Surveys",
    "title": "Feefo Integration With Cold Email Sales Tool",
    "h1": "Integrate Feefo with SalesBlink",
    "metaDescription": "Integrate Feefo and other Forms & Surveys Tools with SalesBlink. Two Way Feefo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpKeep",
    "slug": "upkeep",
    "logo": "https://zapier-images.imgix.net/storage/developer/1d44c47aee21537291aadae6bf235bae_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpKeep is a maintenance management application for facility management teams. Create work orders, prioritize requests, and collaborate with your team.",
    "category": "Project Management",
    "title": "UpKeep Integration With Cold Email Sales Tool",
    "h1": "Integrate UpKeep with SalesBlink",
    "metaDescription": "Integrate UpKeep and other Project Management Tools with SalesBlink. Two Way UpKeep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Optix",
    "slug": "optix",
    "logo": "https://zapier-images.imgix.net/storage/developer/5d95404eca4d3a22c7309766f3315d1e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Optix is a platform for on-demand work and meeting spaces.",
    "category": "Scheduling & Booking",
    "title": "Optix Integration With Cold Email Sales Tool",
    "h1": "Integrate Optix with SalesBlink",
    "metaDescription": "Integrate Optix and other Scheduling & Booking Tools with SalesBlink. Two Way Optix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Skilljar",
    "slug": "skilljar",
    "logo": "https://zapier-images.imgix.net/storage/developer/11b7a7a9c3b3fc2448a1ade2b0d39bc5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Skilljar's learning management system (LMS) enables businesses to create, deliver, and track online training courses.",
    "category": "Online Courses",
    "title": "Skilljar Integration With Cold Email Sales Tool",
    "h1": "Integrate Skilljar with SalesBlink",
    "metaDescription": "Integrate Skilljar and other Online Courses Tools with SalesBlink. Two Way Skilljar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teachworks",
    "slug": "teachworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9288acbd6f77f0495fe0f25907fdf50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teachworks is a simple, easy-to-use system that education companies use for managing teachers, students, schedules, billing and more.",
    "category": "Online Courses",
    "title": "Teachworks Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachworks with SalesBlink",
    "metaDescription": "Integrate Teachworks and other Online Courses Tools with SalesBlink. Two Way Teachworks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snitcher",
    "slug": "snitcher",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d291565a8e6230019935f64e51f70f73_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn IP Addresses Into Company Data - we identify your anonymous website visitors.",
    "category": "Sales & CRM",
    "title": "Snitcher Integration With Cold Email Sales Tool",
    "h1": "Integrate Snitcher with SalesBlink",
    "metaDescription": "Integrate Snitcher and other Sales & CRM Tools with SalesBlink. Two Way Snitcher Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BulkSMS.com",
    "slug": "bulksmscom",
    "logo": "https://zapier-images.imgix.net/storage/services/6db1cf51c1e566bdd71ca1ce6c3a9317.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BulkSMS.com is a leading SMS messaging service provider offering two-way SMS communication services straight from your internet enabled computer.",
    "category": "Phone & SMS",
    "title": "BulkSMS.com Integration With Cold Email Sales Tool",
    "h1": "Integrate BulkSMS.com with SalesBlink",
    "metaDescription": "Integrate BulkSMS.com and other Phone & SMS Tools with SalesBlink. Two Way BulkSMS.com Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "AMcards.com",
    "slug": "amcardscom-da102553",
    "logo": "https://zapier-images.imgix.net/storage/developer/4dc72fc88ef26874b3b91b8eeb03df88.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send real personalized greeting cards and gifts in seconds.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AMcards.com Integration With Cold Email Sales Tool",
    "h1": "Integrate AMcards.com with SalesBlink",
    "metaDescription": "Integrate AMcards.com and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AMcards.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Groove",
    "slug": "groove",
    "logo": "https://zapier-images.imgix.net/storage/services/828224d51826ca2d678e0ac9bf5343d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The simple helpdesk for SaaS and eCommerce businesses.",
    "category": "Customer Support",
    "title": "Groove Integration With Cold Email Sales Tool",
    "h1": "Integrate Groove with SalesBlink",
    "metaDescription": "Integrate Groove and other Customer Support Tools with SalesBlink. Two Way Groove Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Reflect",
    "slug": "reflect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd17b13bbcb8da468689385d985ef10f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reflect is a note-taking app that helps you keep track of your life.",
    "category": "Notes",
    "title": "Reflect Integration With Cold Email Sales Tool",
    "h1": "Integrate Reflect with SalesBlink",
    "metaDescription": "Integrate Reflect and other Notes Tools with SalesBlink. Two Way Reflect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nusii Proposals",
    "slug": "nusii-proposals",
    "logo": "https://zapier-images.imgix.net/storage/developer/c6ed094d6caf0f4f368270d9a4ee0124.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nusii is an online proposal tool that helps you create, track and manage proposals for your creative business.",
    "category": "Proposal & Invoice Management",
    "title": "Nusii Proposals Integration With Cold Email Sales Tool",
    "h1": "Integrate Nusii Proposals with SalesBlink",
    "metaDescription": "Integrate Nusii Proposals and other Proposal & Invoice Management Tools with SalesBlink. Two Way Nusii Proposals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rocketlane",
    "slug": "rocketlane",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4db0e5aaa04e7040b1eab82c4c4fef71_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rocketlane is a collaborative customer onboarding platform that helps you shorten time to value and deliver a delightful onboarding experience.",
    "category": "Project Management",
    "title": "Rocketlane Integration With Cold Email Sales Tool",
    "h1": "Integrate Rocketlane with SalesBlink",
    "metaDescription": "Integrate Rocketlane and other Project Management Tools with SalesBlink. Two Way Rocketlane Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pikaso",
    "slug": "pikaso",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/60bbc02067c4e2ed7f30cf61b4f04268.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pikaso is a tool that lets you screentshot tweets.",
    "category": "Images & Design",
    "title": "Pikaso Integration With Cold Email Sales Tool",
    "h1": "Integrate Pikaso with SalesBlink",
    "metaDescription": "Integrate Pikaso and other Images & Design Tools with SalesBlink. Two Way Pikaso Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PassKit Membership",
    "slug": "passkit-membership",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b41e0e4d0ef9f804b5b17e79e92b70d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PassKit Membership lets you manage your membership programs and passes for Apple Wallet and Google Pay",
    "category": "Marketing Automation",
    "title": "PassKit Membership Integration With Cold Email Sales Tool",
    "h1": "Integrate PassKit Membership with SalesBlink",
    "metaDescription": "Integrate PassKit Membership and other Marketing Automation Tools with SalesBlink. Two Way PassKit Membership Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Personal.ai",
    "slug": "personalai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf4b26a188ad206c42297b7d29770bea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Personal.ai is your personal AI that lets you speak, write or upload insights, information and experiences into it so you can recall your memories exactly when you need them.",
    "category": "Productivity",
    "title": "Personal.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Personal.ai with SalesBlink",
    "metaDescription": "Integrate Personal.ai and other Productivity Tools with SalesBlink. Two Way Personal.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IP2Location",
    "slug": "ip2location",
    "logo": "https://zapier-images.imgix.net/storage/developer/715af9a94c9d497c17bfc4fd9765c507.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IP2Location is a non-intrusive geo IP solution to help you to identify a visitor's geographical location.",
    "category": "Developer Tools",
    "title": "IP2Location Integration With Cold Email Sales Tool",
    "h1": "Integrate IP2Location with SalesBlink",
    "metaDescription": "Integrate IP2Location and other Developer Tools Tools with SalesBlink. Two Way IP2Location Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wishpond",
    "slug": "wishpond",
    "logo": "https://zapier-images.imgix.net/storage/developer/73a96d11c174816e3461c7bc9b18c127_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wishpond is a lead generation and marketing automation platform that helps customers to manage and nurture their leads.",
    "category": "Website Builders",
    "title": "Wishpond Integration With Cold Email Sales Tool",
    "h1": "Integrate Wishpond with SalesBlink",
    "metaDescription": "Integrate Wishpond and other Website Builders Tools with SalesBlink. Two Way Wishpond Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Webinaris",
    "slug": "webinaris",
    "logo": "https://zapier-images.imgix.net/storage/developer/2d4869d82614f8000681e42e89710d6a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webinaris is an all-in-one Webinar platform for hosting live and automated webinars with a marketing focus.",
    "category": "Webinars",
    "title": "Webinaris Integration With Cold Email Sales Tool",
    "h1": "Integrate Webinaris with SalesBlink",
    "metaDescription": "Integrate Webinaris and other Webinars Tools with SalesBlink. Two Way Webinaris Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Everhour",
    "slug": "everhour",
    "logo": "https://zapier-images.imgix.net/storage/services/ab1f4bed1c8eac516f021db8f9c28d90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Everhour is a time tracking software equipped with budgeting, forward resource planning, expenses tracking, visual dashboards and flexible reports.",
    "category": "Time Tracking Software",
    "title": "Everhour Integration With Cold Email Sales Tool",
    "h1": "Integrate Everhour with SalesBlink",
    "metaDescription": "Integrate Everhour and other Time Tracking Software Tools with SalesBlink. Two Way Everhour Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Maropost Marketing Cloud",
    "slug": "maropost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf515f46100d8aa6a58ad4f40324a25b_2.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maropost Marketing Cloud is the multi-channel marketing platform to automate all your marketing needs",
    "category": "Marketing",
    "title": "Maropost Marketing Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Maropost Marketing Cloud with SalesBlink",
    "metaDescription": "Integrate Maropost Marketing Cloud and other Marketing Tools with SalesBlink. Two Way Maropost Marketing Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kustomer",
    "slug": "kustomer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0661674724b0cd0eb45808f31e7821a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kustomer is a powerful omnichannel customer service solution.",
    "category": "Customer Support",
    "title": "Kustomer Integration With Cold Email Sales Tool",
    "h1": "Integrate Kustomer with SalesBlink",
    "metaDescription": "Integrate Kustomer and other Customer Support Tools with SalesBlink. Two Way Kustomer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Regiondo",
    "slug": "regiondo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/55a8bcadcbb1dd319fbe2ffef20c77ce_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Regiondo is an​ all-in-one booking solution for the leisure industry.",
    "category": "Scheduling & Booking",
    "title": "Regiondo Integration With Cold Email Sales Tool",
    "h1": "Integrate Regiondo with SalesBlink",
    "metaDescription": "Integrate Regiondo and other Scheduling & Booking Tools with SalesBlink. Two Way Regiondo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GreenRope",
    "slug": "greenrope",
    "logo": "https://zapier-images.imgix.net/storage/services/5ec013e745dbde0fbb4a8002e3cc71fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Small business marketing software providing powerful and easy to use CRM, email marketing, and social media integration.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GreenRope Integration With Cold Email Sales Tool",
    "h1": "Integrate GreenRope with SalesBlink",
    "metaDescription": "Integrate GreenRope and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GreenRope Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eTermin",
    "slug": "etermin",
    "logo": "https://zapier-images.imgix.net/storage/developer/b2b82de934dfd77824b221442f8abd98.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eTermin allows your customers to access all available appointments online and book one directly - 7 days a week, around the clock.",
    "category": "Scheduling & Booking",
    "title": "eTermin Integration With Cold Email Sales Tool",
    "h1": "Integrate eTermin with SalesBlink",
    "metaDescription": "Integrate eTermin and other Scheduling & Booking Tools with SalesBlink. Two Way eTermin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salestrekker",
    "slug": "salestrekker",
    "logo": "https://zapier-images.imgix.net/storage/services/9ae1764dd24dcc423cf7ee24d38d4668.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salestrekker is CRM software for finance professionals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salestrekker Integration With Cold Email Sales Tool",
    "h1": "Integrate Salestrekker with SalesBlink",
    "metaDescription": "Integrate Salestrekker and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salestrekker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cobot",
    "slug": "cobot",
    "logo": "https://zapier-images.imgix.net/storage/developer/732ae4d0250de12387b77595e3984dfc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cobot is a management software for coworking spaces and shared offices. Cobot helps you with billing, invoicing, bookings and member management so that you have more time for your coworkers.",
    "category": "Scheduling & Booking",
    "title": "Cobot Integration With Cold Email Sales Tool",
    "h1": "Integrate Cobot with SalesBlink",
    "metaDescription": "Integrate Cobot and other Scheduling & Booking Tools with SalesBlink. Two Way Cobot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Join It",
    "slug": "join-it",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/da1e1ee5df0e7b704d5562c427d2bd4b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Join It provides simple membership management for growing organizations",
    "category": "CRM (Customer Relationship Management)",
    "title": "Join It Integration With Cold Email Sales Tool",
    "h1": "Integrate Join It with SalesBlink",
    "metaDescription": "Integrate Join It and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Join It Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InforUMobile",
    "slug": "inforumobile",
    "logo": "https://zapier-images.imgix.net/storage/developer/5eb3ec15f67b07cef281f63563b72b66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InforUMobile is multi channel marketing software offering SMS, email marketing, landing pages, surveys and Facebook advertising.",
    "category": "Marketing Automation",
    "title": "InforUMobile Integration With Cold Email Sales Tool",
    "h1": "Integrate InforUMobile with SalesBlink",
    "metaDescription": "Integrate InforUMobile and other Marketing Automation Tools with SalesBlink. Two Way InforUMobile Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Earth Class Mail",
    "slug": "earth-class-mail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/991d4676121241b2b750035ecaac27c5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Earth Class Mail is a fully digital mailroom for business. Use a virtual address from our vast network to get us your mail. We'll scan your mail items, make them digital so you can take action.",
    "category": "Documents",
    "title": "Earth Class Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate Earth Class Mail with SalesBlink",
    "metaDescription": "Integrate Earth Class Mail and other Documents Tools with SalesBlink. Two Way Earth Class Mail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convenia",
    "slug": "convenia",
    "logo": "https://zapier-images.imgix.net/storage/developer/9a708b10bc403ef2df3765f32b31eb54_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convenia is a cloud people management software for small business that increase its efficiency and eliminating operational labor.",
    "category": "HR Talent & Recruitment",
    "title": "Convenia Integration With Cold Email Sales Tool",
    "h1": "Integrate Convenia with SalesBlink",
    "metaDescription": "Integrate Convenia and other HR Talent & Recruitment Tools with SalesBlink. Two Way Convenia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Overloop",
    "slug": "overloop",
    "logo": "https://zapier-images.imgix.net/storage/services/e09b1a77c616e16989bb7a960edab866.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Conversations are the blood of your growth. Our CRM is designed to help you start more conversations and close more deals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Overloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Overloop with SalesBlink",
    "metaDescription": "Integrate Overloop and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Overloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StatusCake",
    "slug": "statuscake",
    "logo": "https://zapier-images.imgix.net/storage/services/141de43daf842536fbfbd9c743db7342.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StatusCake checks your website for uptime and performance",
    "category": "Developer Tools",
    "title": "StatusCake Integration With Cold Email Sales Tool",
    "h1": "Integrate StatusCake with SalesBlink",
    "metaDescription": "Integrate StatusCake and other Developer Tools Tools with SalesBlink. Two Way StatusCake Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teamwork CRM",
    "slug": "teamwork-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/5d91466636932db8edb86a701e5a1188.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamwork CRM helps you to develop your sales process, plan and manage your company’s growth and bring clarity to a chaotic pipeline.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Teamwork CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamwork CRM with SalesBlink",
    "metaDescription": "Integrate Teamwork CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Teamwork CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EveryAction",
    "slug": "everyaction",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7d7b51bb28a9926ecbe6d49e0fc3c8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EveryAction provides nonprofits with customer relationship management, donor management, and fundraising software tools.",
    "category": "Contact Management",
    "title": "EveryAction Integration With Cold Email Sales Tool",
    "h1": "Integrate EveryAction with SalesBlink",
    "metaDescription": "Integrate EveryAction and other Contact Management Tools with SalesBlink. Two Way EveryAction Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Totango",
    "slug": "totango",
    "logo": "https://zapier-images.imgix.net/storage/developer/96a08869becd322b366b85b92bd122c7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Totango is a Customer Engagement Management platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Totango Integration With Cold Email Sales Tool",
    "h1": "Integrate Totango with SalesBlink",
    "metaDescription": "Integrate Totango and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Totango Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Morgen",
    "slug": "morgen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ecad7183cfaf7a3ca6e652895c167a48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Morgen is an all-in-one app for your time management",
    "category": "Calendar",
    "title": "Morgen Integration With Cold Email Sales Tool",
    "h1": "Integrate Morgen with SalesBlink",
    "metaDescription": "Integrate Morgen and other Calendar Tools with SalesBlink. Two Way Morgen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesViewer",
    "slug": "salesviewer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8f56404331383944c28f3a7bb298982.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesViewer is a tool to identify your anonymous website visitors and redefines the way you think about new business development.",
    "category": "Marketing",
    "title": "SalesViewer Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesViewer with SalesBlink",
    "metaDescription": "Integrate SalesViewer and other Marketing Tools with SalesBlink. Two Way SalesViewer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shape",
    "slug": "shape",
    "logo": "https://zapier-images.imgix.net/storage/services/a18a5762a2643ae951a742232e55099b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shape is a CRM, sales, and marketing automation platform to enhance productivity and streamline your sales process.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Shape Integration With Cold Email Sales Tool",
    "h1": "Integrate Shape with SalesBlink",
    "metaDescription": "Integrate Shape and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Shape Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Laposta",
    "slug": "laposta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0409e6ae42f6890f9e61c95dc716a5df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send easy professional newsletters with Laposta.",
    "category": "Email Newsletters",
    "title": "Laposta Integration With Cold Email Sales Tool",
    "h1": "Integrate Laposta with SalesBlink",
    "metaDescription": "Integrate Laposta and other Email Newsletters Tools with SalesBlink. Two Way Laposta Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Writesonic",
    "slug": "writesonic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e5c3ed9d0bab575c3a46484de5871a7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Writesonic uses AI to generate high-quality, SEO-friendly content. The company has a team of linguistic experts and AI developers.",
    "category": "AI Tools",
    "title": "Writesonic Integration With Cold Email Sales Tool",
    "h1": "Integrate Writesonic with SalesBlink",
    "metaDescription": "Integrate Writesonic and other AI Tools Tools with SalesBlink. Two Way Writesonic Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SMSFactor",
    "slug": "sms-factor",
    "logo": "https://zapier-images.imgix.net/storage/services/966303eb09b636d3ec48c5e664a52767.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSFactor is an online platform allowing you to send professional SMS to your clients/patients.",
    "category": "Phone & SMS",
    "title": "SMSFactor Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSFactor with SalesBlink",
    "metaDescription": "Integrate SMSFactor and other Phone & SMS Tools with SalesBlink. Two Way SMSFactor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Infobip",
    "slug": "infobip",
    "logo": "https://zapier-images.imgix.net/storage/developer/a6ddd877ddaa82a1fcf13123d502842d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infobip offers omnichannel engagement solutions for businesses to simplify customer communication, enhance CX, and grow securely and reliably.",
    "category": "Phone & SMS",
    "title": "Infobip Integration With Cold Email Sales Tool",
    "h1": "Integrate Infobip with SalesBlink",
    "metaDescription": "Integrate Infobip and other Phone & SMS Tools with SalesBlink. Two Way Infobip Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Base64 Encoder/Decoder",
    "slug": "base64-encoderdecoder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2696e7c8d3bc43ed5d5d22c510497b49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily convert any file type to a Base64 encoded string and vice versa using our free online converter.",
    "category": "Productivity",
    "title": "Base64 Encoder/Decoder Integration With Cold Email Sales Tool",
    "h1": "Integrate Base64 Encoder/Decoder with SalesBlink",
    "metaDescription": "Integrate Base64 Encoder/Decoder and other Productivity Tools with SalesBlink. Two Way Base64 Encoder/Decoder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Placid",
    "slug": "placid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8d7779ce1ed3cdfce5da9ebb394b7a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Placid lets you automatically create images from templates",
    "category": "Images & Design",
    "title": "Placid Integration With Cold Email Sales Tool",
    "h1": "Integrate Placid with SalesBlink",
    "metaDescription": "Integrate Placid and other Images & Design Tools with SalesBlink. Two Way Placid Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "User.com",
    "slug": "userengage",
    "logo": "https://zapier-images.imgix.net/storage/developer/a0c71844a85684742e91f2cc03aad53c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UserEngage is a marketing automation system that connects all data about your customers with all the communication channels.",
    "category": "Marketing Automation",
    "title": "User.com Integration With Cold Email Sales Tool",
    "h1": "Integrate User.com with SalesBlink",
    "metaDescription": "Integrate User.com and other Marketing Automation Tools with SalesBlink. Two Way User.com Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Corsizio",
    "slug": "corsizio",
    "logo": "https://zapier-images.imgix.net/storage/services/237bba21f88cda1df7e73b2d457a977f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Corsizio is a platform that enables you to facilitate and manage attendee online registrations and payments for your classes, courses, workshops, training, and similar events.",
    "category": "Event Management",
    "title": "Corsizio Integration With Cold Email Sales Tool",
    "h1": "Integrate Corsizio with SalesBlink",
    "metaDescription": "Integrate Corsizio and other Event Management Tools with SalesBlink. Two Way Corsizio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pendo Feedback",
    "slug": "pendo",
    "logo": "https://zapier-images.imgix.net/storage/developer/3829108fe8c711f0f91e75012c3dfb56.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pendo Feedback is a platform that helps SaaS companies manage product feedback and requests from their customers and team.",
    "category": "Product Management",
    "title": "Pendo Feedback Integration With Cold Email Sales Tool",
    "h1": "Integrate Pendo Feedback with SalesBlink",
    "metaDescription": "Integrate Pendo Feedback and other Product Management Tools with SalesBlink. Two Way Pendo Feedback Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TicketSpice",
    "slug": "ticketspice",
    "logo": "https://zapier-images.imgix.net/storage/services/ed660fa9a16b2c8cf7c8f3f142fe4120.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sell tickets online for your event, recurring event or multi-day event with TicketSpice ticketing system.",
    "category": "Event Management",
    "title": "TicketSpice Integration With Cold Email Sales Tool",
    "h1": "Integrate TicketSpice with SalesBlink",
    "metaDescription": "Integrate TicketSpice and other Event Management Tools with SalesBlink. Two Way TicketSpice Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SmartEmailing",
    "slug": "smartemailing",
    "logo": "https://zapier-images.imgix.net/storage/developer/d069019d2770ceb136dcb28a4f8af052.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartEmailing is an e-marketing automation tool in the Czech Republic and the Slovak. With SmartEmailing you can create newsletters, web forms, A/B tests, autoresponders and much more!",
    "category": "Email",
    "title": "SmartEmailing Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartEmailing with SalesBlink",
    "metaDescription": "Integrate SmartEmailing and other Email Tools with SalesBlink. Two Way SmartEmailing Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "FormKeep",
    "slug": "formkeep",
    "logo": "https://zapier-images.imgix.net/storage/developer/8b04a1d9ce4c95519d7c01de529032f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Form endpoints for designers and developers. You control the form look and feel, and FormKeep handles the data.",
    "category": "Forms & Surveys",
    "title": "FormKeep Integration With Cold Email Sales Tool",
    "h1": "Integrate FormKeep with SalesBlink",
    "metaDescription": "Integrate FormKeep and other Forms & Surveys Tools with SalesBlink. Two Way FormKeep Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "awork",
    "slug": "awork",
    "logo": "https://zapier-images.imgix.net/storage/services/99d8d0c1a191d60013383e26427271fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intelligent projects, tasks and time tracking for your project business.",
    "category": "Team Collaboration",
    "title": "awork Integration With Cold Email Sales Tool",
    "h1": "Integrate awork with SalesBlink",
    "metaDescription": "Integrate awork and other Team Collaboration Tools with SalesBlink. Two Way awork Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LeadDyno",
    "slug": "leaddyno",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c535bed2f5ee57c2cc535bd9cde8d49f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadDyno is an affiliate and affiliate program management platform.",
    "category": "eCommerce",
    "title": "LeadDyno Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadDyno with SalesBlink",
    "metaDescription": "Integrate LeadDyno and other eCommerce Tools with SalesBlink. Two Way LeadDyno Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WeTravel",
    "slug": "wetravel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dad8b83275327921f5474cff45f0e342.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WeTravel is the leading global payment & booking platform for multi-day tour operators and group travel companies.",
    "category": "Payment Processing",
    "title": "WeTravel Integration With Cold Email Sales Tool",
    "h1": "Integrate WeTravel with SalesBlink",
    "metaDescription": "Integrate WeTravel and other Payment Processing Tools with SalesBlink. Two Way WeTravel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hopin",
    "slug": "hopin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73f2bf30d6b9c59b40f268a79f30140b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hopin helps with redemption & registration for events.",
    "category": "Event Management",
    "title": "Hopin Integration With Cold Email Sales Tool",
    "h1": "Integrate Hopin with SalesBlink",
    "metaDescription": "Integrate Hopin and other Event Management Tools with SalesBlink. Two Way Hopin Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Contactout",
    "slug": "contactout",
    "logo": "https://zapier-images.imgix.net/storage/services/7dceb669b222327c9ad7211da28dd7b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Find anyone's personal email & phone number. Hire talent 10x faster with the most powerful sourcing platform available",
    "category": "Contact Management",
    "title": "Contactout Integration With Cold Email Sales Tool",
    "h1": "Integrate Contactout with SalesBlink",
    "metaDescription": "Integrate Contactout and other Contact Management Tools with SalesBlink. Two Way Contactout Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GetScreenshot",
    "slug": "getscreenshot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d938b113c653609dfab41af2846159c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetScreenshot allows you to take custom screenshots of live websites. GetScreenshot can send the resulting screenshots to your email address or it can pass it to other Actions.",
    "category": "Video & Audio",
    "title": "GetScreenshot Integration With Cold Email Sales Tool",
    "h1": "Integrate GetScreenshot with SalesBlink",
    "metaDescription": "Integrate GetScreenshot and other Video & Audio Tools with SalesBlink. Two Way GetScreenshot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kanban Tool",
    "slug": "kanbantool",
    "logo": "https://zapier-images.imgix.net/storage/services/93b8c386d57c4e4a3e198ec5f0726b7d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kanban Tool is a smart visual project management application with real-time collaboration that allows teams to get work done faster. Kanban Tool helps companies visualize workflow, analyze and improve business processes and reduce waste.",
    "category": "Project Management",
    "title": "Kanban Tool Integration With Cold Email Sales Tool",
    "h1": "Integrate Kanban Tool with SalesBlink",
    "metaDescription": "Integrate Kanban Tool and other Project Management Tools with SalesBlink. Two Way Kanban Tool Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Call Loop",
    "slug": "call-loop",
    "logo": "https://zapier-images.imgix.net/storage/developer/54c567aa4a05aa4cb29bd90442cc1fc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Call Loop lets you automatically send customized & personalized voice and sms text messages to your contacts.",
    "category": "Phone & SMS",
    "title": "Call Loop Integration With Cold Email Sales Tool",
    "h1": "Integrate Call Loop with SalesBlink",
    "metaDescription": "Integrate Call Loop and other Phone & SMS Tools with SalesBlink. Two Way Call Loop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Funnelforms",
    "slug": "funnelforms",
    "logo": "https://zapier-images.imgix.net/storage/services/35dbdfd42714f80d46c71ea5219d7936.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funnelforms is a form builder tool that lets you create innovative forms and optimize your lead generation, recruiting process, price calculation, appointment booking and product configuration.",
    "category": "Contact Management",
    "title": "Funnelforms Integration With Cold Email Sales Tool",
    "h1": "Integrate Funnelforms with SalesBlink",
    "metaDescription": "Integrate Funnelforms and other Contact Management Tools with SalesBlink. Two Way Funnelforms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Demand IQ",
    "slug": "demand-iq",
    "logo": "https://zapier-images.imgix.net/storage/services/3a39c02bcb27cd3401937d9b69cd3a55.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stella by Demand IQ is a lead generation tool that helps solar companies convert clicks into sales opportunities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Demand IQ Integration With Cold Email Sales Tool",
    "h1": "Integrate Demand IQ with SalesBlink",
    "metaDescription": "Integrate Demand IQ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Demand IQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Postscript",
    "slug": "postscript-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e384313d0fe02f9d1ed3db56048cb19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Postscript is a platform for SMS marketing for Shopify stores.",
    "category": "Phone & SMS",
    "title": "Postscript Integration With Cold Email Sales Tool",
    "h1": "Integrate Postscript with SalesBlink",
    "metaDescription": "Integrate Postscript and other Phone & SMS Tools with SalesBlink. Two Way Postscript Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ServeManager",
    "slug": "servemanager",
    "logo": "https://zapier-images.imgix.net/storage/developer/7a651e273d84620df708f368213212de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServeManager provides process servers with the tools they need run their business, while giving their law firm clients a dashboard to track the status and activity associated with service of process.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ServeManager Integration With Cold Email Sales Tool",
    "h1": "Integrate ServeManager with SalesBlink",
    "metaDescription": "Integrate ServeManager and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ServeManager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudCart",
    "slug": "cloudcart",
    "logo": "https://zapier-images.imgix.net/storage/developer/4b043f6f6b91417a1a9f61efb4b028c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudCart is a professional all-in-one eCommerce platform with everything you need to Sell Online. An easy to use eCommerce platform that lets you start and grow your online business. It comes with free professional mobile optimized themes for high conversion rate.",
    "category": "eCommerce",
    "title": "CloudCart Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudCart with SalesBlink",
    "metaDescription": "Integrate CloudCart and other eCommerce Tools with SalesBlink. Two Way CloudCart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coderbyte",
    "slug": "coderbyte",
    "logo": "https://zapier-images.imgix.net/storage/services/d46001786cae17cdf439460b37333d24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coderbyte is the #1 coding assessment platform for qualifying engineering talent in your pipeline.",
    "category": "Developer Tools",
    "title": "Coderbyte Integration With Cold Email Sales Tool",
    "h1": "Integrate Coderbyte with SalesBlink",
    "metaDescription": "Integrate Coderbyte and other Developer Tools Tools with SalesBlink. Two Way Coderbyte Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GTR",
    "slug": "gtr",
    "logo": "https://zapier-images.imgix.net/storage/services/469bf6a85740c4380549e49d6bb51551.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GTR manages, connects and automates leads to your CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GTR Integration With Cold Email Sales Tool",
    "h1": "Integrate GTR with SalesBlink",
    "metaDescription": "Integrate GTR and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GTR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "B1G1",
    "slug": "b1g1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df09be03ad9d11f697a5f5c5a496772c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Embed giving into the heart of your business model and activities through B1G1’s giving platform",
    "category": "Fundraising",
    "title": "B1G1 Integration With Cold Email Sales Tool",
    "h1": "Integrate B1G1 with SalesBlink",
    "metaDescription": "Integrate B1G1 and other Fundraising Tools with SalesBlink. Two Way B1G1 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrackVia",
    "slug": "trackvia",
    "logo": "https://zapier-images.imgix.net/storage/developer/6d52ab70b4cf9e6aaa32492de094956e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrackVia is a low-code, mobile workflow management platform that can be quickly customized to digitize and streamline your enterprise operations, processes and data.",
    "category": "Databases",
    "title": "TrackVia Integration With Cold Email Sales Tool",
    "h1": "Integrate TrackVia with SalesBlink",
    "metaDescription": "Integrate TrackVia and other Databases Tools with SalesBlink. Two Way TrackVia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "axiom.ai",
    "slug": "axiomai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8cd9cbb570683ffcd22d5583a7e0190d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "axiom.ai is a no-code RPA solution for automating user interface tasks in a web browser.",
    "category": "Productivity",
    "title": "axiom.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate axiom.ai with SalesBlink",
    "metaDescription": "Integrate axiom.ai and other Productivity Tools with SalesBlink. Two Way axiom.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DNSFilter",
    "slug": "dnsfilter",
    "logo": "https://zapier-images.imgix.net/storage/services/bc2c0afb03f913c14d2530f1c6e65cd7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DNSFilter is a cloud-based, AI-driven content filtering and threat protection service.",
    "category": "Security & Identity Tools",
    "title": "DNSFilter Integration With Cold Email Sales Tool",
    "h1": "Integrate DNSFilter with SalesBlink",
    "metaDescription": "Integrate DNSFilter and other Security & Identity Tools Tools with SalesBlink. Two Way DNSFilter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trafft",
    "slug": "trafft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd71a6ed570e724e535f8383fcfc6d97.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trafft is a booking software made for businesses and individuals for scheduling appointments, managing staff, and their calendars.",
    "category": "Scheduling & Booking",
    "title": "Trafft Integration With Cold Email Sales Tool",
    "h1": "Integrate Trafft with SalesBlink",
    "metaDescription": "Integrate Trafft and other Scheduling & Booking Tools with SalesBlink. Two Way Trafft Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "BrightHR",
    "slug": "brighthr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e3eaeaec9b123f77d2193b7bfe35115_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrightHR is a people management platform that saves you time, money, and effort whether you're managing shift workers at a shop or full-time staff at a large company.",
    "category": "Human Resources",
    "title": "BrightHR Integration With Cold Email Sales Tool",
    "h1": "Integrate BrightHR with SalesBlink",
    "metaDescription": "Integrate BrightHR and other Human Resources Tools with SalesBlink. Two Way BrightHR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Willo",
    "slug": "willo",
    "logo": "https://zapier-images.imgix.net/storage/services/2f1b3397bf1af4df390d956b22007334_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Willo helps you get answers to your questions faster with video interviewing. Works beautifully across all devices, everywhere, every time.",
    "category": "Forms & Surveys",
    "title": "Willo Integration With Cold Email Sales Tool",
    "h1": "Integrate Willo with SalesBlink",
    "metaDescription": "Integrate Willo and other Forms & Surveys Tools with SalesBlink. Two Way Willo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Factorial",
    "slug": "factorial",
    "logo": "https://zapier-images.imgix.net/storage/services/7943b82d69a26afd8768de3e158970db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Factorial is an HR platform for SMEs.",
    "category": "HR Talent & Recruitment",
    "title": "Factorial Integration With Cold Email Sales Tool",
    "h1": "Integrate Factorial with SalesBlink",
    "metaDescription": "Integrate Factorial and other HR Talent & Recruitment Tools with SalesBlink. Two Way Factorial Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Resource Guru",
    "slug": "resource-guru",
    "logo": "https://zapier-images.imgix.net/storage/services/edd34e9177828fa1afd7d1297cd37ca8.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The fast, simple way to schedule people, equipment and other resources online.",
    "category": "Scheduling & Booking",
    "title": "Resource Guru Integration With Cold Email Sales Tool",
    "h1": "Integrate Resource Guru with SalesBlink",
    "metaDescription": "Integrate Resource Guru and other Scheduling & Booking Tools with SalesBlink. Two Way Resource Guru Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Plivo",
    "slug": "plivo",
    "logo": "https://zapier-images.imgix.net/storage/developer/5bc6bf2010e33165dab604fbd8fb2cd3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plivo is an easy-to-use tool that lets you send & receive SMS and voice calls.",
    "category": "Phone & SMS",
    "title": "Plivo Integration With Cold Email Sales Tool",
    "h1": "Integrate Plivo with SalesBlink",
    "metaDescription": "Integrate Plivo and other Phone & SMS Tools with SalesBlink. Two Way Plivo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "umsatz.io",
    "slug": "umsatzio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6f7b882c685bcd8104c59080a873014.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "umsatz.io is a CRM system that helps businesses to make more sales.",
    "category": "Sales & CRM",
    "title": "umsatz.io Integration With Cold Email Sales Tool",
    "h1": "Integrate umsatz.io with SalesBlink",
    "metaDescription": "Integrate umsatz.io and other Sales & CRM Tools with SalesBlink. Two Way umsatz.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Text In Church",
    "slug": "text-in-church",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ea9e0bca0e7a4dcede00b270531a193.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text In Church is a communication platform that allows you to easily engage your church’s members and guests through text messages, emails, and automated campaigns.",
    "category": "Marketing",
    "title": "Text In Church Integration With Cold Email Sales Tool",
    "h1": "Integrate Text In Church with SalesBlink",
    "metaDescription": "Integrate Text In Church and other Marketing Tools with SalesBlink. Two Way Text In Church Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sharetribe Flex",
    "slug": "sharetribe-flex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1cf30dad2da534bbca531a29a1b63c35.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sharetribe Flex is a headless marketplace solution. It lets you build a unique online marketplace 90% faster than coding from scratch.",
    "category": "eCommerce",
    "title": "Sharetribe Flex Integration With Cold Email Sales Tool",
    "h1": "Integrate Sharetribe Flex with SalesBlink",
    "metaDescription": "Integrate Sharetribe Flex and other eCommerce Tools with SalesBlink. Two Way Sharetribe Flex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Knowify",
    "slug": "knowify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d93d0cefdaf5d6f3b464f7b291ec8f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Knowify is a job costing and project management platform for contractors.",
    "category": "Project Management",
    "title": "Knowify Integration With Cold Email Sales Tool",
    "h1": "Integrate Knowify with SalesBlink",
    "metaDescription": "Integrate Knowify and other Project Management Tools with SalesBlink. Two Way Knowify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuestionScout",
    "slug": "questionscout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7aa51413210d9f08cbf101bb8a56409.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuestionScout is the only tool you need to build forms and surveys in just minutes and start collecting data.",
    "category": "Forms & Surveys",
    "title": "QuestionScout Integration With Cold Email Sales Tool",
    "h1": "Integrate QuestionScout with SalesBlink",
    "metaDescription": "Integrate QuestionScout and other Forms & Surveys Tools with SalesBlink. Two Way QuestionScout Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SyndicationPro",
    "slug": "syndicationpro",
    "logo": "https://zapier-images.imgix.net/storage/services/cc8a0ffa2e07f28e9f2aa3436efabf78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SyndicationPro is a robust and easy to use CRM, Investor Portal and Investment Management software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SyndicationPro Integration With Cold Email Sales Tool",
    "h1": "Integrate SyndicationPro with SalesBlink",
    "metaDescription": "Integrate SyndicationPro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SyndicationPro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mallabe",
    "slug": "mallabee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0dcd7b2cc3a177ef7426a02ec99f8613.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mallabe is an automation toolchain that allows you to process images and resize them on the fly.",
    "category": "Images & Design",
    "title": "mallabe Integration With Cold Email Sales Tool",
    "h1": "Integrate mallabe with SalesBlink",
    "metaDescription": "Integrate mallabe and other Images & Design Tools with SalesBlink. Two Way mallabe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spotler Mail+",
    "slug": "spotler",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa0bdba2ce8745cd9a83f6abdd695529_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spotler Mail+ develops and delivers software for professional email marketing automation.",
    "category": "Email Newsletters",
    "title": "Spotler Mail+ Integration With Cold Email Sales Tool",
    "h1": "Integrate Spotler Mail+ with SalesBlink",
    "metaDescription": "Integrate Spotler Mail+ and other Email Newsletters Tools with SalesBlink. Two Way Spotler Mail+ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LightSpeed VT",
    "slug": "lightspeed-vt",
    "logo": "https://zapier-images.imgix.net/storage/developer/c6cd28420db2427eeb8f24f2a36775e0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LightSpeed VT is an enterprise-ready, e-learning solution that uses interactive video, automated reports, and custom courseware to train your people better.",
    "category": "Online Courses",
    "title": "LightSpeed VT Integration With Cold Email Sales Tool",
    "h1": "Integrate LightSpeed VT with SalesBlink",
    "metaDescription": "Integrate LightSpeed VT and other Online Courses Tools with SalesBlink. Two Way LightSpeed VT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobilo Card",
    "slug": "mobilo-card",
    "logo": "https://zapier-images.imgix.net/storage/services/e06fbe89e47c17691ae15bb756459956.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Smart Business Card. Share your contact details with a tap and turn your meetings into leads. The only digital business card with data analytics and link tracking.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mobilo Card Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobilo Card with SalesBlink",
    "metaDescription": "Integrate Mobilo Card and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mobilo Card Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chatra",
    "slug": "chatra",
    "logo": "https://zapier-images.imgix.net/storage/developer/98d754d4011f49d591028b12da521010.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chatra is a live chat software that helps to increase revenue and collect feedback providing an easy way for website owners to talk to visitors in real time.",
    "category": "Customer Support",
    "title": "Chatra Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatra with SalesBlink",
    "metaDescription": "Integrate Chatra and other Customer Support Tools with SalesBlink. Two Way Chatra Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Planview AgilePlace",
    "slug": "planview-leankit",
    "logo": "https://zapier-images.imgix.net/storage/services/022d5697a64c485cc8654336e538bd13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Push new Cards, Card moves, and Card updates to your instance of Planview AgilePlace.",
    "category": "Project Management",
    "title": "Planview AgilePlace Integration With Cold Email Sales Tool",
    "h1": "Integrate Planview AgilePlace with SalesBlink",
    "metaDescription": "Integrate Planview AgilePlace and other Project Management Tools with SalesBlink. Two Way Planview AgilePlace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vouch",
    "slug": "vouch",
    "logo": "https://zapier-images.imgix.net/storage/services/ffef3d88b8c1bcb629a5890891e900e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vouch is the easiest way to capture authentic video content within your workflow.",
    "category": "Video & Audio",
    "title": "Vouch Integration With Cold Email Sales Tool",
    "h1": "Integrate Vouch with SalesBlink",
    "metaDescription": "Integrate Vouch and other Video & Audio Tools with SalesBlink. Two Way Vouch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anedot",
    "slug": "anedot",
    "logo": "https://zapier-images.imgix.net/storage/services/949f7654ef2edd0e56ba6fbcc949ee4e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Better tools for better fundraising. Anedot empowers ministry, education, and politics to raise more money with less effort.",
    "category": "Fundraising",
    "title": "Anedot Integration With Cold Email Sales Tool",
    "h1": "Integrate Anedot with SalesBlink",
    "metaDescription": "Integrate Anedot and other Fundraising Tools with SalesBlink. Two Way Anedot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stannp",
    "slug": "stannp",
    "logo": "https://zapier-images.imgix.net/storage/developer/a0ea5d8862a4a8de94aa314abc08a859.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stannp is a service for posting real, physical direct mail such as postcards or letters to recipients' mail boxes, anywhere in the world.",
    "category": "Marketing Automation",
    "title": "Stannp Integration With Cold Email Sales Tool",
    "h1": "Integrate Stannp with SalesBlink",
    "metaDescription": "Integrate Stannp and other Marketing Automation Tools with SalesBlink. Two Way Stannp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zoho Notebook",
    "slug": "zoho-notebook",
    "logo": "https://zapier-images.imgix.net/storage/developer/e6f4bc3af079a9a693c44a1f5f3427b8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notebook is a beautifuly simple note taking app. Take notes, make lists, record audio, capture moments and sync your notes across devices.",
    "category": "Notes",
    "title": "Zoho Notebook Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Notebook with SalesBlink",
    "metaDescription": "Integrate Zoho Notebook and other Notes Tools with SalesBlink. Two Way Zoho Notebook Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DonorDock",
    "slug": "donordock",
    "logo": "https://zapier-images.imgix.net/storage/developer/05880ca6b419085a379ab4ade926ca82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DonorDock is a CRM designed for small nonprofits that helps you raise more money in less time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DonorDock Integration With Cold Email Sales Tool",
    "h1": "Integrate DonorDock with SalesBlink",
    "metaDescription": "Integrate DonorDock and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DonorDock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "smartercontact",
    "slug": "smartercontact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7a130a4b060a37c36862e346c210d05a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smarter Contact is an SMS provider that helps professionals close more deals.  In addition, the software serves as an efficient and flexible CRM that allows clients to track their customers from initial contact to close!",
    "category": "Phone & SMS",
    "title": "smartercontact Integration With Cold Email Sales Tool",
    "h1": "Integrate smartercontact with SalesBlink",
    "metaDescription": "Integrate smartercontact and other Phone & SMS Tools with SalesBlink. Two Way smartercontact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Robly",
    "slug": "robly",
    "logo": "https://zapier-images.imgix.net/storage/developer/07c6aecd086e1d61c841e669e0787fe2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Robly is an email marketing solution designed for beginners and experts alike.",
    "category": "Marketing Automation",
    "title": "Robly Integration With Cold Email Sales Tool",
    "h1": "Integrate Robly with SalesBlink",
    "metaDescription": "Integrate Robly and other Marketing Automation Tools with SalesBlink. Two Way Robly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Plumsail Documents",
    "slug": "plumsail-documents",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b93d960ee083bf742b5d2ec93715c458.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate documents from DOCX, XLSX, PPTX, HTML templates and populate fillable PDF forms. Automate your contracts, invoices, reports, tickets and more.",
    "category": "Documents",
    "title": "Plumsail Documents Integration With Cold Email Sales Tool",
    "h1": "Integrate Plumsail Documents with SalesBlink",
    "metaDescription": "Integrate Plumsail Documents and other Documents Tools with SalesBlink. Two Way Plumsail Documents Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Documint",
    "slug": "documint",
    "logo": "https://zapier-images.imgix.net/storage/services/adc45b585fdc6e831c28597bda9aaf0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Documint is a document creation service which cuts transaction document creation time down from minutes or hours to seconds.",
    "category": "Documents",
    "title": "Documint Integration With Cold Email Sales Tool",
    "h1": "Integrate Documint with SalesBlink",
    "metaDescription": "Integrate Documint and other Documents Tools with SalesBlink. Two Way Documint Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BirdSend",
    "slug": "birdsend",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ebc0ad59837e0303be6a9be43fb408b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BirdSend is an email marketing tool exclusively for content creators only.",
    "category": "Drip Emails",
    "title": "BirdSend Integration With Cold Email Sales Tool",
    "h1": "Integrate BirdSend with SalesBlink",
    "metaDescription": "Integrate BirdSend and other Drip Emails Tools with SalesBlink. Two Way BirdSend Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mural",
    "slug": "mural",
    "logo": "https://zapier-images.imgix.net/storage/services/74d6bb1ce60f04f52878d6e6278e1903.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mural is a digital workspace for visual collaboration. Enables innovative teams to think and collaborate visually to solve important problems.",
    "category": "Team Collaboration",
    "title": "Mural Integration With Cold Email Sales Tool",
    "h1": "Integrate Mural with SalesBlink",
    "metaDescription": "Integrate Mural and other Team Collaboration Tools with SalesBlink. Two Way Mural Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Simpletix",
    "slug": "simpletix",
    "logo": "https://zapier-images.imgix.net/storage/developer/84f5a5a8ba6592931fe81b52a529f742.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleTix is an e-commerce company that offers turn-key solutions for event websites and online ticketing.",
    "category": "Event Management",
    "title": "Simpletix Integration With Cold Email Sales Tool",
    "h1": "Integrate Simpletix with SalesBlink",
    "metaDescription": "Integrate Simpletix and other Event Management Tools with SalesBlink. Two Way Simpletix Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Flickr",
    "slug": "flickr",
    "logo": "https://zapier-images.imgix.net/storage/developer/8db522ba135c4e0e3cf56c761bad4bfe_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flickr is a photo sharing and social media platform that allows you to host images online, and check out other users' images.",
    "category": "Images & Design",
    "title": "Flickr Integration With Cold Email Sales Tool",
    "h1": "Integrate Flickr with SalesBlink",
    "metaDescription": "Integrate Flickr and other Images & Design Tools with SalesBlink. Two Way Flickr Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SiteCapture",
    "slug": "sitecapture",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b82514f88b96af72f82f88755d84815c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SiteCapture is a field reporting tool to collect photos and data from a job site.  SiteCapture is used in home service industries such as solar to improve time and accuracy of site survey and system installations.  Take photos and sync in real time for the most advanced technology in field services.",
    "category": "Project Management",
    "title": "SiteCapture Integration With Cold Email Sales Tool",
    "h1": "Integrate SiteCapture with SalesBlink",
    "metaDescription": "Integrate SiteCapture and other Project Management Tools with SalesBlink. Two Way SiteCapture Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "fastbill",
    "slug": "fastbill",
    "logo": "https://zapier-images.imgix.net/storage/services/581b4f15f3e7d659670b357a0627591e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FastBill is a cloud app for German invoicing & accounting. Writing offers and invoices, paying your bills or preparing your tax return is easier than ever with FastBill.",
    "category": "Accounting",
    "title": "fastbill Integration With Cold Email Sales Tool",
    "h1": "Integrate fastbill with SalesBlink",
    "metaDescription": "Integrate fastbill and other Accounting Tools with SalesBlink. Two Way fastbill Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Stax",
    "slug": "stax",
    "logo": "https://zapier-images.imgix.net/storage/developer/21bc5ce21b8e8f5a28aafeff6a38ef4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stax makes it easy to accept payments in-person and online and provides businesses with the tools they need to grow.",
    "category": "Payment Processing",
    "title": "Stax Integration With Cold Email Sales Tool",
    "h1": "Integrate Stax with SalesBlink",
    "metaDescription": "Integrate Stax and other Payment Processing Tools with SalesBlink. Two Way Stax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easyship",
    "slug": "easyship",
    "logo": "https://zapier-images.imgix.net/storage/services/d985d1832edcd453d46a85587e427767.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easyship is a shipping platform that allows eCommerce merchants to connect to 250+ courier solutions to help them grow internationally",
    "category": "eCommerce",
    "title": "Easyship Integration With Cold Email Sales Tool",
    "h1": "Integrate Easyship with SalesBlink",
    "metaDescription": "Integrate Easyship and other eCommerce Tools with SalesBlink. Two Way Easyship Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "POWR Popup",
    "slug": "powr-popup",
    "logo": "https://zapier-images.imgix.net/storage/services/5d5e3b5fae241cb3f591189cc757f189.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "POWR Popup is the easy way to collect email subscribers, reduce cart abandonment and boost sales on any website.",
    "category": "Ads & Conversion",
    "title": "POWR Popup Integration With Cold Email Sales Tool",
    "h1": "Integrate POWR Popup with SalesBlink",
    "metaDescription": "Integrate POWR Popup and other Ads & Conversion Tools with SalesBlink. Two Way POWR Popup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moxo",
    "slug": "moxo",
    "logo": "https://zapier-images.imgix.net/storage/services/dd7592bd9b226a204f425bdd3d8ee8ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moxo is a OneStop Client Interaction Hub.",
    "category": "Team Collaboration",
    "title": "Moxo Integration With Cold Email Sales Tool",
    "h1": "Integrate Moxo with SalesBlink",
    "metaDescription": "Integrate Moxo and other Team Collaboration Tools with SalesBlink. Two Way Moxo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Uploadcare",
    "slug": "uploadcare",
    "logo": "https://zapier-images.imgix.net/storage/developer/23a6c6eb31c9f33fd7a4229c51c2ea8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "File system as a service to handle your web and mobile app uploads.",
    "category": "File Management & Storage",
    "title": "Uploadcare Integration With Cold Email Sales Tool",
    "h1": "Integrate Uploadcare with SalesBlink",
    "metaDescription": "Integrate Uploadcare and other File Management & Storage Tools with SalesBlink. Two Way Uploadcare Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Unbiased Pro",
    "slug": "unbiased-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/af688a63d0bb14730bc68f8c26f43f06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unbiased Pro is a UK growth platform for financial advisers, mortgage brokers, and accountants.",
    "category": "Marketing",
    "title": "Unbiased Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Unbiased Pro with SalesBlink",
    "metaDescription": "Integrate Unbiased Pro and other Marketing Tools with SalesBlink. Two Way Unbiased Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HappyFox",
    "slug": "happyfox",
    "logo": "https://zapier-images.imgix.net/storage/developer/e9c2274eec4b915ca3c3ffe9e6c583e1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HappyFox is a customer support, ticket support software that ensures lowest response time for all your customers queries. A really simple help desk!",
    "category": "Customer Support",
    "title": "HappyFox Integration With Cold Email Sales Tool",
    "h1": "Integrate HappyFox with SalesBlink",
    "metaDescription": "Integrate HappyFox and other Customer Support Tools with SalesBlink. Two Way HappyFox Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Revv",
    "slug": "revv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b7615fcf6b9327517ea30dda48e1d54.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revv is a donation platform that will help your cause, non-profit, or political campaign raise more money.",
    "category": "Fundraising",
    "title": "Revv Integration With Cold Email Sales Tool",
    "h1": "Integrate Revv with SalesBlink",
    "metaDescription": "Integrate Revv and other Fundraising Tools with SalesBlink. Two Way Revv Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Olark",
    "slug": "olark",
    "logo": "https://zapier-images.imgix.net/storage/developer/017a96df38d9e5444a451f497d20e9b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Olark makes it easy to add live chat to your website and provide instantaneous support to your users and get valuable feedback from them about your site.",
    "category": "Customer Support",
    "title": "Olark Integration With Cold Email Sales Tool",
    "h1": "Integrate Olark with SalesBlink",
    "metaDescription": "Integrate Olark and other Customer Support Tools with SalesBlink. Two Way Olark Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Morningstar AdviserLogic",
    "slug": "adviserlogic",
    "logo": "https://zapier-images.imgix.net/storage/developer/02d1904f0f90f5877c6510aa0cdf0d95_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Morningstar AdviserLogic is a financial planning and business management solution that positions advisers to engage and impress clients while efficiently running their business and helping them meet compliance obligations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Morningstar AdviserLogic Integration With Cold Email Sales Tool",
    "h1": "Integrate Morningstar AdviserLogic with SalesBlink",
    "metaDescription": "Integrate Morningstar AdviserLogic and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Morningstar AdviserLogic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PersistIQ",
    "slug": "persistiq",
    "logo": "https://zapier-images.imgix.net/storage/developer/1877463e302c318fad7a5ea006f3d661_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PersistIQ is software for effective outbound sales that combines communication and workflow into one easy to use system.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PersistIQ Integration With Cold Email Sales Tool",
    "h1": "Integrate PersistIQ with SalesBlink",
    "metaDescription": "Integrate PersistIQ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PersistIQ Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LocaliQ",
    "slug": "localiq",
    "logo": "https://zapier-images.imgix.net/storage/services/988f4b2bde0169f6712a2e23f10057c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Local IQ provides digital marketing solutions to help you grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LocaliQ Integration With Cold Email Sales Tool",
    "h1": "Integrate LocaliQ with SalesBlink",
    "metaDescription": "Integrate LocaliQ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LocaliQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "People Data Labs",
    "slug": "people-data-labs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f44e73c34bfaaa7ce946809fe944b49a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "People Data Labs is a data API that lets you enrich your person and company records with resume, contact, social, and demographic information.",
    "category": "Contact Management",
    "title": "People Data Labs Integration With Cold Email Sales Tool",
    "h1": "Integrate People Data Labs with SalesBlink",
    "metaDescription": "Integrate People Data Labs and other Contact Management Tools with SalesBlink. Two Way People Data Labs Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Dubb",
    "slug": "dubb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d4c5bfa28dbf5494f354adf47111212.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dubb is a video communication platform that lets you create and share trackable screen and camera videos to help you streamline your communication and grow your organization.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Dubb Integration With Cold Email Sales Tool",
    "h1": "Integrate Dubb with SalesBlink",
    "metaDescription": "Integrate Dubb and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Dubb Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EasyMe",
    "slug": "easyme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4190e7ed71804496e4689032e2545f3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyMe is an all-in-one booking, payment, marketing and online course platform for knowledge workers.",
    "category": "Marketing Automation",
    "title": "EasyMe Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyMe with SalesBlink",
    "metaDescription": "Integrate EasyMe and other Marketing Automation Tools with SalesBlink. Two Way EasyMe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evoliz",
    "slug": "evoliz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28f3b36507394f083b344ea49bbf8bb5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evoliz is an invoicing and management software designed for small businesses but also their accountants.",
    "category": "Accounting",
    "title": "Evoliz Integration With Cold Email Sales Tool",
    "h1": "Integrate Evoliz with SalesBlink",
    "metaDescription": "Integrate Evoliz and other Accounting Tools with SalesBlink. Two Way Evoliz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quipu",
    "slug": "quipuapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d23a3e3742b30414005089086308474b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quipu is an accounting app that makes accounting for small businesses and freelancers easier, automated and paperless.",
    "category": "Accounting",
    "title": "Quipu Integration With Cold Email Sales Tool",
    "h1": "Integrate Quipu with SalesBlink",
    "metaDescription": "Integrate Quipu and other Accounting Tools with SalesBlink. Two Way Quipu Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GetLeadForms",
    "slug": "getleadforms",
    "logo": "https://zapier-images.imgix.net/storage/services/2f961e673c1db1d6b7c00565a4d9ab3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "High converting, interactive lead forms that are designed to convert more website visitors into leads (no coding required).",
    "category": "Ads & Conversion",
    "title": "GetLeadForms Integration With Cold Email Sales Tool",
    "h1": "Integrate GetLeadForms with SalesBlink",
    "metaDescription": "Integrate GetLeadForms and other Ads & Conversion Tools with SalesBlink. Two Way GetLeadForms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "DigitalOcean",
    "slug": "digital-ocean",
    "logo": "https://zapier-images.imgix.net/storage/developer/f1ce9f60f6740b7862d589a7f755ad19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DigitalOcean is a cloud computing platform to quickly deploy virtual machines—with your custom code, or with a one-click app—manage storage, balance loads, and run it all from one place.",
    "category": "Website Builders",
    "title": "DigitalOcean Integration With Cold Email Sales Tool",
    "h1": "Integrate DigitalOcean with SalesBlink",
    "metaDescription": "Integrate DigitalOcean and other Website Builders Tools with SalesBlink. Two Way DigitalOcean Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Xola",
    "slug": "xola",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fae511c04b0e3b6f9dbd51ce9ee7098b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xola is a powerful booking system built for tour and activity operators. Easily book customers and accept payments for online, phone, in-person, or reseller reservations. Ignite your growth with Xola's cart abandonment recovery, automated guest communication, gifts, and more.",
    "category": "eCommerce",
    "title": "Xola Integration With Cold Email Sales Tool",
    "h1": "Integrate Xola with SalesBlink",
    "metaDescription": "Integrate Xola and other eCommerce Tools with SalesBlink. Two Way Xola Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Billetto",
    "slug": "billetto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef7c686d7e75637e4f9ea5b2a4436cbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Billetto offers smart self-service plug-and-play ticketing solution for event organisers to sell tickets and promote events online.",
    "category": "Event Management",
    "title": "Billetto Integration With Cold Email Sales Tool",
    "h1": "Integrate Billetto with SalesBlink",
    "metaDescription": "Integrate Billetto and other Event Management Tools with SalesBlink. Two Way Billetto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sawyer Tools",
    "slug": "sawyer-tools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a424fba439c55460cfb6b387f2a8c34c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sawyer is a children's activity booking platform that makes running your business super easy",
    "category": "Scheduling & Booking",
    "title": "Sawyer Tools Integration With Cold Email Sales Tool",
    "h1": "Integrate Sawyer Tools with SalesBlink",
    "metaDescription": "Integrate Sawyer Tools and other Scheduling & Booking Tools with SalesBlink. Two Way Sawyer Tools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apptoto",
    "slug": "apptoto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79d7692883e1c15949ca14a814416751.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "For appointment-based businesses, Apptoto offers automated SMS text, email or voice reminders and online scheduling",
    "category": "Calendar",
    "title": "Apptoto Integration With Cold Email Sales Tool",
    "h1": "Integrate Apptoto with SalesBlink",
    "metaDescription": "Integrate Apptoto and other Calendar Tools with SalesBlink. Two Way Apptoto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Growform",
    "slug": "growform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a9aabd27c20b6fd153ee2d179ebea82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Growform is a form builder that gets you more leads. Build beautiful forms that look great on desktop and mobile devices.",
    "category": "Forms & Surveys",
    "title": "Growform Integration With Cold Email Sales Tool",
    "h1": "Integrate Growform with SalesBlink",
    "metaDescription": "Integrate Growform and other Forms & Surveys Tools with SalesBlink. Two Way Growform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KickoffLabs",
    "slug": "kickofflabs",
    "logo": "https://zapier-images.imgix.net/storage/services/ad5807393094f8b82161cf2bf1986c15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KickoffLabs allows you to create giveaway campaigns in minutes!",
    "category": "Forms & Surveys",
    "title": "KickoffLabs Integration With Cold Email Sales Tool",
    "h1": "Integrate KickoffLabs with SalesBlink",
    "metaDescription": "Integrate KickoffLabs and other Forms & Surveys Tools with SalesBlink. Two Way KickoffLabs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wheelbase",
    "slug": "wheelbase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/52fb86bfd3d6822c0181539857fad157_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one rentals suite that combines rental management software with $1M in insurance coverage.",
    "category": "Scheduling & Booking",
    "title": "Wheelbase Integration With Cold Email Sales Tool",
    "h1": "Integrate Wheelbase with SalesBlink",
    "metaDescription": "Integrate Wheelbase and other Scheduling & Booking Tools with SalesBlink. Two Way Wheelbase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datadog",
    "slug": "datadog",
    "logo": "https://zapier-images.imgix.net/storage/developer/f321b136f2b0fe0a859cc69a8c3c4c22.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datadog is a service for IT, Operations and Development teams who write and run applications at scale, and want to turn the massive amounts of data produced by their apps, tools and services into actionable insight.",
    "category": "Server Monitoring",
    "title": "Datadog Integration With Cold Email Sales Tool",
    "h1": "Integrate Datadog with SalesBlink",
    "metaDescription": "Integrate Datadog and other Server Monitoring Tools with SalesBlink. Two Way Datadog Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Appbot",
    "slug": "appbot",
    "logo": "https://zapier-images.imgix.net/storage/services/2748dd77680b6bb1464e5f4d7e6664b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appbot offers App review monitoring, sentiment, text analysis & reply tools for product, tech & support teams. Also supports Amazon products.",
    "category": "Reviews",
    "title": "Appbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Appbot with SalesBlink",
    "metaDescription": "Integrate Appbot and other Reviews Tools with SalesBlink. Two Way Appbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Curated",
    "slug": "curated",
    "logo": "https://zapier-images.imgix.net/storage/developer/a9ecba4c0f64696ac5233cec4251cc4f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Curated is the quickest and easiest way to publish a digest newsletter by email and also on the web. Collect links, pick the best, add your commentary and publish it to your subscribers.",
    "category": "Email Newsletters",
    "title": "Curated Integration With Cold Email Sales Tool",
    "h1": "Integrate Curated with SalesBlink",
    "metaDescription": "Integrate Curated and other Email Newsletters Tools with SalesBlink. Two Way Curated Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Virtuous CRM",
    "slug": "virtuous-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c311c3823c0b24a5b6abb3301f0b2a23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Virtuous CRM is a nonprofit CRM & marketing automation platform helping build better donor relationships.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Virtuous CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtuous CRM with SalesBlink",
    "metaDescription": "Integrate Virtuous CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Virtuous CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Typebot",
    "slug": "typebot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b0bf1665b2c9314b4187e7f4206f7315.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert 4x more with beautiful conversational forms. Embed them directly in your applications without a line of code.",
    "category": "App Builder",
    "title": "Typebot Integration With Cold Email Sales Tool",
    "h1": "Integrate Typebot with SalesBlink",
    "metaDescription": "Integrate Typebot and other App Builder Tools with SalesBlink. Two Way Typebot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Textiful",
    "slug": "textiful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf2a6d55aac2b71b58d489d8721b71f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Textiful is a \"text to join\" service that captures email addresses and other information at live events using SMS text messages.",
    "category": "Contact Management",
    "title": "Textiful Integration With Cold Email Sales Tool",
    "h1": "Integrate Textiful with SalesBlink",
    "metaDescription": "Integrate Textiful and other Contact Management Tools with SalesBlink. Two Way Textiful Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planyo Online Booking",
    "slug": "planyo-online-booking",
    "logo": "https://zapier-images.imgix.net/storage/developer/8b299221927fe94fb595fc1830f4ea56.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planyo is an online reservation system which can be used by any business taking bookings. It's used, amongst others, by businesses offering vacation properties, car rentals, hotels, driving schools, yacht charters, doctors and therapists, conference rooms, tennis courts, car parks, event tickets or any other bookings you could imagine.",
    "category": "Scheduling & Booking",
    "title": "Planyo Online Booking Integration With Cold Email Sales Tool",
    "h1": "Integrate Planyo Online Booking with SalesBlink",
    "metaDescription": "Integrate Planyo Online Booking and other Scheduling & Booking Tools with SalesBlink. Two Way Planyo Online Booking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loyverse",
    "slug": "loyverse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0144004f2e64427e0841e90d0e534354.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Free Point of Sale and Inventory Management Software. Turn your smartphone or tablet into a powerful POS. Manage sales, inventory and employees with ease.",
    "category": "Sales & CRM",
    "title": "Loyverse Integration With Cold Email Sales Tool",
    "h1": "Integrate Loyverse with SalesBlink",
    "metaDescription": "Integrate Loyverse and other Sales & CRM Tools with SalesBlink. Two Way Loyverse Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Cyfe",
    "slug": "cyfe",
    "logo": "https://zapier-images.imgix.net/storage/services/c9f63029de101430574d8a913070af9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cyfe is an all-in-one business dashboard app that helps you easily monitor all your business data from one place (e.g. social media, analytics, marketing, sales, support, infrastructure, etc).",
    "category": "Dashboards",
    "title": "Cyfe Integration With Cold Email Sales Tool",
    "h1": "Integrate Cyfe with SalesBlink",
    "metaDescription": "Integrate Cyfe and other Dashboards Tools with SalesBlink. Two Way Cyfe Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "DripJobs",
    "slug": "dripjobs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19711e0b082436b4946da75608b0b95a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DripJobs is a Customer Resource Management system designed to help funnel Leads through a strategic process to gain more Jobs and increase revenue.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DripJobs Integration With Cold Email Sales Tool",
    "h1": "Integrate DripJobs with SalesBlink",
    "metaDescription": "Integrate DripJobs and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DripJobs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iNCOM Canada",
    "slug": "incom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/537957aac612517dbe7110ab9b391e2b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iNCOM is a real estate website and marketing solution including all the tools you need - Lead Generation, Cultivation, IDX Website, CRM, and marketing services.",
    "category": "CRM (Customer Relationship Management)",
    "title": "iNCOM Canada Integration With Cold Email Sales Tool",
    "h1": "Integrate iNCOM Canada with SalesBlink",
    "metaDescription": "Integrate iNCOM Canada and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way iNCOM Canada Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HeySummit",
    "slug": "heysummit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c5aad0f8f5eb4aa5a570e1b96122b862.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HeySummit is a platform to help you increase engagement, conversions and revenue for your online summits.",
    "category": "Event Management",
    "title": "HeySummit Integration With Cold Email Sales Tool",
    "h1": "Integrate HeySummit with SalesBlink",
    "metaDescription": "Integrate HeySummit and other Event Management Tools with SalesBlink. Two Way HeySummit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Yousign",
    "slug": "yousign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/359f0696d5c91c65c6ff6abb41119427.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yousign: the easy-to-use, legally binding eSignature solution for all your documents.",
    "category": "Documents",
    "title": "Yousign Integration With Cold Email Sales Tool",
    "h1": "Integrate Yousign with SalesBlink",
    "metaDescription": "Integrate Yousign and other Documents Tools with SalesBlink. Two Way Yousign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebinarNinja",
    "slug": "webinarninja",
    "logo": "https://zapier-images.imgix.net/storage/developer/06c246e6b6f60a4bf4ade6a35f935b76.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WebinarNinja easily allows you to host, share and attend webinars with no worries.",
    "category": "Webinars",
    "title": "WebinarNinja Integration With Cold Email Sales Tool",
    "h1": "Integrate WebinarNinja with SalesBlink",
    "metaDescription": "Integrate WebinarNinja and other Webinars Tools with SalesBlink. Two Way WebinarNinja Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Enormail",
    "slug": "enormail",
    "logo": "https://zapier-images.imgix.net/storage/developer/38c972091888f8a4f3ecf20483974f91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Enormail is an email marketing tool that makes it a breeze to grow your list and send automated campaigns.",
    "category": "Email Newsletters",
    "title": "Enormail Integration With Cold Email Sales Tool",
    "h1": "Integrate Enormail with SalesBlink",
    "metaDescription": "Integrate Enormail and other Email Newsletters Tools with SalesBlink. Two Way Enormail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignalWire",
    "slug": "signalwire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/835349ec886691c7cae2d3646106ee48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignalWire is an elastic cloud telecommunications platform that allows you to make calls, send text messages, create faxes and conduct video conferencing across the globe.",
    "category": "Phone & SMS",
    "title": "SignalWire Integration With Cold Email Sales Tool",
    "h1": "Integrate SignalWire with SalesBlink",
    "metaDescription": "Integrate SignalWire and other Phone & SMS Tools with SalesBlink. Two Way SignalWire Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "MEETOVO",
    "slug": "meetovo",
    "logo": "https://zapier-images.imgix.net/storage/services/482e7af77df2735d651945f5a374bfd8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MEETOVO makes it very easy to create funnels to receive requests and applicants.",
    "category": "Marketing Automation",
    "title": "MEETOVO Integration With Cold Email Sales Tool",
    "h1": "Integrate MEETOVO with SalesBlink",
    "metaDescription": "Integrate MEETOVO and other Marketing Automation Tools with SalesBlink. Two Way MEETOVO Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Fibery",
    "slug": "fibery",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ca97f299f04639085a6adbda3e0b37b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fibery is the first work management software that fits and grows with your company.",
    "category": "Project Management",
    "title": "Fibery Integration With Cold Email Sales Tool",
    "h1": "Integrate Fibery with SalesBlink",
    "metaDescription": "Integrate Fibery and other Project Management Tools with SalesBlink. Two Way Fibery Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Swit",
    "slug": "swit",
    "logo": "https://zapier-images.imgix.net/storage/services/f5e3d89a50668f095119172e9dcbd44f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swit is one work suite for Chat and Project Management. With Swit, you can replace other team collaboration tools.",
    "category": "Team Collaboration",
    "title": "Swit Integration With Cold Email Sales Tool",
    "h1": "Integrate Swit with SalesBlink",
    "metaDescription": "Integrate Swit and other Team Collaboration Tools with SalesBlink. Two Way Swit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LearnUpon",
    "slug": "learnupon",
    "logo": "https://zapier-images.imgix.net/storage/services/08b62b08f549f31866e578113f0a169f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LearnUpon is an enterprise learning management system (LMS) that fuels business’s employee, partner, and customer success through training.",
    "category": "Online Courses",
    "title": "LearnUpon Integration With Cold Email Sales Tool",
    "h1": "Integrate LearnUpon with SalesBlink",
    "metaDescription": "Integrate LearnUpon and other Online Courses Tools with SalesBlink. Two Way LearnUpon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chekkit",
    "slug": "chekkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86259c3800e78466de5ea02d3e41cc82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chekkit is a review management and messaging tool for local business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Chekkit Integration With Cold Email Sales Tool",
    "h1": "Integrate Chekkit with SalesBlink",
    "metaDescription": "Integrate Chekkit and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Chekkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beambox",
    "slug": "beambox-da86912",
    "logo": "https://zapier-images.imgix.net/storage/developer/0aea63b337ded99e2c215a8fd8fddb91_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beambox uses your WiFi to connect, capture and keep more customers by combining a perfect WiFi experience with an all-in-one marketing platform.",
    "category": "Marketing",
    "title": "Beambox Integration With Cold Email Sales Tool",
    "h1": "Integrate Beambox with SalesBlink",
    "metaDescription": "Integrate Beambox and other Marketing Tools with SalesBlink. Two Way Beambox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Encircle",
    "slug": "encircle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4651ca35b216e050dd8640d60fc53cc9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Encircle is the leading provider of real-time documentation and productivity tools for the insurance and restoration industries.",
    "category": "Content & Files",
    "title": "Encircle Integration With Cold Email Sales Tool",
    "h1": "Integrate Encircle with SalesBlink",
    "metaDescription": "Integrate Encircle and other Content & Files Tools with SalesBlink. Two Way Encircle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Focuster",
    "slug": "focuster",
    "logo": "https://zapier-images.imgix.net/storage/developer/2b50db8ce00b993246c967513302107f.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Focuster is a focus management tool for professionals and entrepreneurs that turns your todo list into an actionable schedule in your calendar, gives you smart reminders when you have free time to work on your most important priorities, and moves work forward until you get it done.",
    "category": "Task Management",
    "title": "Focuster Integration With Cold Email Sales Tool",
    "h1": "Integrate Focuster with SalesBlink",
    "metaDescription": "Integrate Focuster and other Task Management Tools with SalesBlink. Two Way Focuster Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SmartReach",
    "slug": "smartreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed22938ec7dbc62a454f27af6c36f6d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartReach.io is a sales automation platform that helps you automate sending cold-emails and follow-ups from your mailbox and running email outreach campaigns with built-in tools.",
    "category": "Email",
    "title": "SmartReach Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartReach with SalesBlink",
    "metaDescription": "Integrate SmartReach and other Email Tools with SalesBlink. Two Way SmartReach Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Clearstream",
    "slug": "clearstream-da108960",
    "logo": "https://zapier-images.imgix.net/storage/services/72a2c36e95a40fcbc70d00e8cab22dd9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clearstream is a two-way SMS communication tool for churches and non-profits.",
    "category": "Communication",
    "title": "Clearstream Integration With Cold Email Sales Tool",
    "h1": "Integrate Clearstream with SalesBlink",
    "metaDescription": "Integrate Clearstream and other Communication Tools with SalesBlink. Two Way Clearstream Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SleekFlow",
    "slug": "sleekflow",
    "logo": "https://zapier-images.imgix.net/storage/services/ad75fec47a15f359d0490d2e748ca1a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SleekFlow is your centralised messaging platform with customers, enabling your sales, support and marketing teams to do their best work - so you can create an experience you customers will never forget.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SleekFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate SleekFlow with SalesBlink",
    "metaDescription": "Integrate SleekFlow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SleekFlow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BoothBook",
    "slug": "boothbook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e318aa21a1a7df7bc77c6a5f3a703160.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BoothBook is a booking system and CRM for event rental businesses. Use Zapier to integrate data from Bookings, Leads and Payments.",
    "category": "Scheduling & Booking",
    "title": "BoothBook Integration With Cold Email Sales Tool",
    "h1": "Integrate BoothBook with SalesBlink",
    "metaDescription": "Integrate BoothBook and other Scheduling & Booking Tools with SalesBlink. Two Way BoothBook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FormCrafts",
    "slug": "formcrafts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1a6aa6638eeceef08905ea58c66b8ebe_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create intuitive forms that do everything.",
    "category": "Forms & Surveys",
    "title": "FormCrafts Integration With Cold Email Sales Tool",
    "h1": "Integrate FormCrafts with SalesBlink",
    "metaDescription": "Integrate FormCrafts and other Forms & Surveys Tools with SalesBlink. Two Way FormCrafts Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SentryKit",
    "slug": "sentrykit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c9bb5a116ff60d167bc384307f4c1f6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SentryKit is a listing monitoring and alerts service for Amazon sellers.",
    "category": "eCommerce",
    "title": "SentryKit Integration With Cold Email Sales Tool",
    "h1": "Integrate SentryKit with SalesBlink",
    "metaDescription": "Integrate SentryKit and other eCommerce Tools with SalesBlink. Two Way SentryKit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gavel",
    "slug": "documate",
    "logo": "https://zapier-images.imgix.net/storage/services/8e97e114ed30b37b8105da825606e3a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gavel is a no-code platform for document automation and building expert systems.",
    "category": "File Management & Storage",
    "title": "Gavel Integration With Cold Email Sales Tool",
    "h1": "Integrate Gavel with SalesBlink",
    "metaDescription": "Integrate Gavel and other File Management & Storage Tools with SalesBlink. Two Way Gavel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nicereply",
    "slug": "nicereply",
    "logo": "https://zapier-images.imgix.net/storage/developer/ad98c33a792d960c600a2ff9ba4925af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Improve customer service and customer experience with Nicereply - online customer satisfaction survey software, including CSAT, NPS & CES 2.0.",
    "category": "Forms & Surveys",
    "title": "Nicereply Integration With Cold Email Sales Tool",
    "h1": "Integrate Nicereply with SalesBlink",
    "metaDescription": "Integrate Nicereply and other Forms & Surveys Tools with SalesBlink. Two Way Nicereply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Arthur Online",
    "slug": "arthur-online",
    "logo": "https://zapier-images.imgix.net/storage/services/30478cdbe82b65e208c78b3c5cf8f18a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Arthur Online is a property management solution like no other. It brings together in one place property managers, tenants, contractors, agents and owners. Available on your phone, tablet, and computer, Arthur Online is accessible anywhere at any time giving you freedom and flexibility in how you manage your properties.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Arthur Online Integration With Cold Email Sales Tool",
    "h1": "Integrate Arthur Online with SalesBlink",
    "metaDescription": "Integrate Arthur Online and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Arthur Online Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "More Trees",
    "slug": "more-trees",
    "logo": "https://zapier-images.imgix.net/storage/services/15cf9346bdf6a6fc16c5610b28484682.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "More Trees is a platform that provides you with an easy way to offset your carbon by automatically planting trees.",
    "category": "Customer Appreciation",
    "title": "More Trees Integration With Cold Email Sales Tool",
    "h1": "Integrate More Trees with SalesBlink",
    "metaDescription": "Integrate More Trees and other Customer Appreciation Tools with SalesBlink. Two Way More Trees Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qgiv",
    "slug": "qgiv",
    "logo": "https://zapier-images.imgix.net/storage/services/1eb68fba77f62580075cf0072ef55f68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qgiv is an online fundraising platform offering donation forms, event registrations, peer-to-peer events, text fundraising, and auctions.",
    "category": "Fundraising",
    "title": "Qgiv Integration With Cold Email Sales Tool",
    "h1": "Integrate Qgiv with SalesBlink",
    "metaDescription": "Integrate Qgiv and other Fundraising Tools with SalesBlink. Two Way Qgiv Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PTminder",
    "slug": "ptminder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/34470a091567f456769a3fe1df88d5a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PTminder is a business management platform designed for Personal Trainers",
    "category": "Scheduling & Booking",
    "title": "PTminder Integration With Cold Email Sales Tool",
    "h1": "Integrate PTminder with SalesBlink",
    "metaDescription": "Integrate PTminder and other Scheduling & Booking Tools with SalesBlink. Two Way PTminder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickFile",
    "slug": "quickfile",
    "logo": "https://zapier-images.imgix.net/storage/developer/aaeb769d7cf52ecf1c4a91faf0ae1c73.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UK based cloud accounting software. Manage your accounts and bookkeeping in the cloud. QuickFile is simple to use, fast and free!",
    "category": "Accounting",
    "title": "QuickFile Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickFile with SalesBlink",
    "metaDescription": "Integrate QuickFile and other Accounting Tools with SalesBlink. Two Way QuickFile Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "IRIS CRM",
    "slug": "iris-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/fd6697a4cf3279dc36219b16075fed3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IRIS CRM is a payments platform for tracking leads, merchants and calculating residuals for processors such as First Data, TSYS and WorldPay.",
    "category": "Payment Processing",
    "title": "IRIS CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate IRIS CRM with SalesBlink",
    "metaDescription": "Integrate IRIS CRM and other Payment Processing Tools with SalesBlink. Two Way IRIS CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Badger Maps",
    "slug": "converted-da-106926",
    "logo": "https://zapier-images.imgix.net/storage/developer/91db409db2e7e7cf63e98eee10fd6fa5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Badger Maps is a sales productivity and planning app for outside salespeople.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Badger Maps Integration With Cold Email Sales Tool",
    "h1": "Integrate Badger Maps with SalesBlink",
    "metaDescription": "Integrate Badger Maps and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Badger Maps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elfsight",
    "slug": "elfsight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c213724ba115ff1e81eec3fad1cb4d55.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elfsight Forms is an easy way to create contact, feedback, survey, subscription and any other kind of form.",
    "category": "Forms & Surveys",
    "title": "Elfsight Integration With Cold Email Sales Tool",
    "h1": "Integrate Elfsight with SalesBlink",
    "metaDescription": "Integrate Elfsight and other Forms & Surveys Tools with SalesBlink. Two Way Elfsight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KanbanFlow",
    "slug": "kanbanflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/13a4fe433ca3d042a963af5c4c83f0df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KanbanFlow is a Lean project management tool for you and your team. Its intuitive user interface will get you up and running in a few minutes. It has a built-in task timer with support for the Pomodoro technique.",
    "category": "Project Management",
    "title": "KanbanFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate KanbanFlow with SalesBlink",
    "metaDescription": "Integrate KanbanFlow and other Project Management Tools with SalesBlink. Two Way KanbanFlow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "TweetPik",
    "slug": "tweetpik",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9d877050308451baf0982559677de9d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tweetpik is a free, simple tool that makes it easy to capture and share professional screenshots of your tweets.",
    "category": "Social Media Marketing",
    "title": "TweetPik Integration With Cold Email Sales Tool",
    "h1": "Integrate TweetPik with SalesBlink",
    "metaDescription": "Integrate TweetPik and other Social Media Marketing Tools with SalesBlink. Two Way TweetPik Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Builder Prime",
    "slug": "builder-prime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2173ad9992bb5dcdf85bfd8b52cf2a90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Builder Prime is a CRM, estimating, scheduling, and production management app for contractors",
    "category": "CRM (Customer Relationship Management)",
    "title": "Builder Prime Integration With Cold Email Sales Tool",
    "h1": "Integrate Builder Prime with SalesBlink",
    "metaDescription": "Integrate Builder Prime and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Builder Prime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planfix",
    "slug": "planfix",
    "logo": "https://zapier-images.imgix.net/storage/services/04fdbd28554dc73356b9f30c0719840c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planfix is a platform for creating Enterprise Management Systems and allows you to configure various business processes of the company without programming.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Planfix Integration With Cold Email Sales Tool",
    "h1": "Integrate Planfix with SalesBlink",
    "metaDescription": "Integrate Planfix and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Planfix Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Convert Forms",
    "slug": "convert-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer/d636e44264b8310e6e4168274df7a74c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert Forms is a user-friendly form builder for Joomla that allows you to easily create perfect contact forms, newsletter opt-in forms and any kind of forms so you can collect the data and registrations in your Joomla website.",
    "category": "Forms & Surveys",
    "title": "Convert Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Convert Forms with SalesBlink",
    "metaDescription": "Integrate Convert Forms and other Forms & Surveys Tools with SalesBlink. Two Way Convert Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Track-POD",
    "slug": "track-pod",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/221e3073a696775883c297c0afd0ba72.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Track-POD is a delivery software featuring an electronic proof of delivery app, solution for efficient performance monitoring and delivery confirmation. This unique paperless delivery system also works as a route planning software for arranging multiple deliveries with ease.",
    "category": "Product Management",
    "title": "Track-POD Integration With Cold Email Sales Tool",
    "h1": "Integrate Track-POD with SalesBlink",
    "metaDescription": "Integrate Track-POD and other Product Management Tools with SalesBlink. Two Way Track-POD Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QCommission",
    "slug": "qcommission",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b9b557a4c88f00091e60951f2006321.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QCommission is a sales commission tool to calculate your sales people commission accurately and reduces errors related to manual methods.",
    "category": "Accounting",
    "title": "QCommission Integration With Cold Email Sales Tool",
    "h1": "Integrate QCommission with SalesBlink",
    "metaDescription": "Integrate QCommission and other Accounting Tools with SalesBlink. Two Way QCommission Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flutterwave",
    "slug": "flutterwave",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e4aa1af5a42d06c15a58a4afc779271_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flutterwave is a payment gateway that allows businesses, developers, individual, and organisations to collect one-time and recurring payments, disburse payments, perform customer KYC and sell bill services.",
    "category": "Payment Processing",
    "title": "Flutterwave Integration With Cold Email Sales Tool",
    "h1": "Integrate Flutterwave with SalesBlink",
    "metaDescription": "Integrate Flutterwave and other Payment Processing Tools with SalesBlink. Two Way Flutterwave Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SweepBright",
    "slug": "sweepbright",
    "logo": "https://zapier-images.imgix.net/storage/developer/728458e9411137ea49abb694c28bb0bc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SweepBright lets real estate professionals organize and promote listings, match them with leads, and close the deal—all from their phone.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SweepBright Integration With Cold Email Sales Tool",
    "h1": "Integrate SweepBright with SalesBlink",
    "metaDescription": "Integrate SweepBright and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SweepBright Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reform",
    "slug": "reform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a5e901d519c08197d98f0ae724f4be8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reform is a forms app providing clean-looking, on-brand forms. No code required.",
    "category": "Forms & Surveys",
    "title": "Reform Integration With Cold Email Sales Tool",
    "h1": "Integrate Reform with SalesBlink",
    "metaDescription": "Integrate Reform and other Forms & Surveys Tools with SalesBlink. Two Way Reform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Redbooth",
    "slug": "redbooth",
    "logo": "https://zapier-images.imgix.net/storage/developer/e50571c04c0dbb2c1b370512af8cc29c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Redbooth is project management software for highly productive teams, including marketing departments, creative agencies, and more. Built for collaboration on complex projects, and optimized for doing great work.",
    "category": "Project Management",
    "title": "Redbooth Integration With Cold Email Sales Tool",
    "h1": "Integrate Redbooth with SalesBlink",
    "metaDescription": "Integrate Redbooth and other Project Management Tools with SalesBlink. Two Way Redbooth Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ManageEngine ServiceDesk Plus Cloud",
    "slug": "manageengine-servicedesk-cloud",
    "logo": "https://zapier-images.imgix.net/storage/services/5fa4f0f8c2615743ddbc4c8a5c8e2371.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ManageEngine ServiceDesk Plus is a ITIL® compliant comprehensive help desk software with integrated asset and project management capabilities.",
    "category": "Developer Tools",
    "title": "ManageEngine ServiceDesk Plus Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate ManageEngine ServiceDesk Plus Cloud with SalesBlink",
    "metaDescription": "Integrate ManageEngine ServiceDesk Plus Cloud and other Developer Tools Tools with SalesBlink. Two Way ManageEngine ServiceDesk Plus Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Targetprocess",
    "slug": "targetprocess",
    "logo": "https://zapier-images.imgix.net/storage/developer/fdfbcfb8b5d82d20f9a26ab23db4579c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Targetprocess is a Visual Management Software.",
    "category": "Project Management",
    "title": "Targetprocess Integration With Cold Email Sales Tool",
    "h1": "Integrate Targetprocess with SalesBlink",
    "metaDescription": "Integrate Targetprocess and other Project Management Tools with SalesBlink. Two Way Targetprocess Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesQL",
    "slug": "salesql",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5831eef64550c45e34eae0a073fab9d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesQL allows you to enrich social network profiles with contact information, track leads, make notes and save prospects into projects.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesQL Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesQL with SalesBlink",
    "metaDescription": "Integrate SalesQL and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesQL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vibo",
    "slug": "vibo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cec40134fdb1d243689de11c41c45d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vibo is a music manager tool for events.",
    "category": "Video & Audio",
    "title": "Vibo Integration With Cold Email Sales Tool",
    "h1": "Integrate Vibo with SalesBlink",
    "metaDescription": "Integrate Vibo and other Video & Audio Tools with SalesBlink. Two Way Vibo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshcaller",
    "slug": "freshcaller",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/111dd7d15430d770eab9a5132dfd5cc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshcaller is a cloud-based phone system for businesses of all sizes.",
    "category": "Phone & SMS",
    "title": "Freshcaller Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshcaller with SalesBlink",
    "metaDescription": "Integrate Freshcaller and other Phone & SMS Tools with SalesBlink. Two Way Freshcaller Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Check Cherry",
    "slug": "check-cherry",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c58cfe241bbdb11cad103d3e4107ff2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Check Cherry is the all-in-one platform to manage your Photobooth, Photography Studio, Mobile DJ business and more.  Includes online booking, beautiful proposals, and a customer portal.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Check Cherry Integration With Cold Email Sales Tool",
    "h1": "Integrate Check Cherry with SalesBlink",
    "metaDescription": "Integrate Check Cherry and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Check Cherry Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CRM PipeRun",
    "slug": "crm-piperun",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3419b2fe9d537b59e1752b52602f8c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CRM PipeRun is a Sales Funnel CRM and Management System. From the generated lead to the signed contract, all in a single system.",
    "category": "Sales & CRM",
    "title": "CRM PipeRun Integration With Cold Email Sales Tool",
    "h1": "Integrate CRM PipeRun with SalesBlink",
    "metaDescription": "Integrate CRM PipeRun and other Sales & CRM Tools with SalesBlink. Two Way CRM PipeRun Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sertifier",
    "slug": "sertifier",
    "logo": "https://zapier-images.imgix.net/storage/services/e20abbb34c22396b28fad422a4a89647.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sertifier is a full-stack solution that enables its users to design, send and store smart certificates using blockchain.",
    "category": "Online Courses",
    "title": "Sertifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Sertifier with SalesBlink",
    "metaDescription": "Integrate Sertifier and other Online Courses Tools with SalesBlink. Two Way Sertifier Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Yammer",
    "slug": "yammer",
    "logo": "https://zapier-images.imgix.net/storage/services/b017774faddfb882acefabea1e76d59b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yammer makes it easy to communicate within your company at the speed of light with your own internal social networks where employees can connect and build meaningful business relationships.",
    "category": "Microsoft",
    "title": "Yammer Integration With Cold Email Sales Tool",
    "h1": "Integrate Yammer with SalesBlink",
    "metaDescription": "Integrate Yammer and other Microsoft Tools with SalesBlink. Two Way Yammer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CallPage",
    "slug": "callpage-da108946",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c59d170a7d0a4d5d48af68e593e7b34f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallPage is an easy-to-install widget for your website which offers real-time free callback to engaged visitors.",
    "category": "Marketing",
    "title": "CallPage Integration With Cold Email Sales Tool",
    "h1": "Integrate CallPage with SalesBlink",
    "metaDescription": "Integrate CallPage and other Marketing Tools with SalesBlink. Two Way CallPage Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TimeCamp",
    "slug": "timecamp",
    "logo": "https://zapier-images.imgix.net/storage/services/b6488444714aa9e7cf90635ec161b286.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeCamp helps you and your team to track time. Automatic time-tracking software that tracks websites and applications usage, projects and helps you understand how your time is spent.",
    "category": "Time Tracking Software",
    "title": "TimeCamp Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeCamp with SalesBlink",
    "metaDescription": "Integrate TimeCamp and other Time Tracking Software Tools with SalesBlink. Two Way TimeCamp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Cyberimpact",
    "slug": "cyberimpact",
    "logo": "https://zapier-images.imgix.net/storage/developer/7a4f3d69a0e4c485f109eef870daf18a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cyberimpact is a CASL-Compliant email marketing service that makes it easy to create and send beautiful and efficient newsletter campaigns.",
    "category": "Email Newsletters",
    "title": "Cyberimpact Integration With Cold Email Sales Tool",
    "h1": "Integrate Cyberimpact with SalesBlink",
    "metaDescription": "Integrate Cyberimpact and other Email Newsletters Tools with SalesBlink. Two Way Cyberimpact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sage Accounting",
    "slug": "sage-accounting",
    "logo": "https://zapier-images.imgix.net/storage/services/7c9c4903a4900ed8636e167cc0002214.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Award winning accounting from the leader in financial accounting software. Manage invoicing, cash flow, tax, payments and more from any device through the cloud.",
    "category": "Accounting",
    "title": "Sage Accounting Integration With Cold Email Sales Tool",
    "h1": "Integrate Sage Accounting with SalesBlink",
    "metaDescription": "Integrate Sage Accounting and other Accounting Tools with SalesBlink. Two Way Sage Accounting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Referral Factory",
    "slug": "referral-factory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b010852c4ccb6c71953d94d75533cc6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Easy Way To Build A Referral Program",
    "category": "Marketing Automation",
    "title": "Referral Factory Integration With Cold Email Sales Tool",
    "h1": "Integrate Referral Factory with SalesBlink",
    "metaDescription": "Integrate Referral Factory and other Marketing Automation Tools with SalesBlink. Two Way Referral Factory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kickbox",
    "slug": "kickbox",
    "logo": "https://zapier-images.imgix.net/storage/developer/4a899912308509547e9a8e181f080201_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kickbox is an email verification platform that can help identify deliverable, invalid, or otherwise risky email addresses. Using Kickbox you can ensure you only ever send emails to real addresses - reduce bounce rates and improve inbox placement.",
    "category": "Email",
    "title": "Kickbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Kickbox with SalesBlink",
    "metaDescription": "Integrate Kickbox and other Email Tools with SalesBlink. Two Way Kickbox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Timing",
    "slug": "timing",
    "logo": "https://zapier-images.imgix.net/storage/services/4e73ee1715bd468803b1eb017886641c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timing is an automatic time tracking app for macOS. It also offers a web service to manage your tasks on the go, retrieve data via the API, and connect to other services.",
    "category": "Time Tracking Software",
    "title": "Timing Integration With Cold Email Sales Tool",
    "h1": "Integrate Timing with SalesBlink",
    "metaDescription": "Integrate Timing and other Time Tracking Software Tools with SalesBlink. Two Way Timing Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SalesUp!",
    "slug": "salesup",
    "logo": "https://zapier-images.imgix.net/storage/developer/b2b32330d34e99b791dd22c27cc57d17.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesUp! is a platform to easy organize and create relationships with your customers, to better track leads and increase your sales.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesUp! Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesUp! with SalesBlink",
    "metaDescription": "Integrate SalesUp! and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesUp! Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyCRM Sync",
    "slug": "mycrm-sync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/812c0168bf6334e8bbae907304a23981_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyCRM puts everything you need at your fingertips so you can make the most of your limited and valuable time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MyCRM Sync Integration With Cold Email Sales Tool",
    "h1": "Integrate MyCRM Sync with SalesBlink",
    "metaDescription": "Integrate MyCRM Sync and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MyCRM Sync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Go1",
    "slug": "go1",
    "logo": "https://zapier-images.imgix.net/storage/services/33651aa84d6ecaa60a1f4d4c8f8d39c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Go1 is a leading edtech company with a mission to unlock positive potential through a love for learning. Find exactly what you're looking for with our comprehensive learning and development library featuring hundreds of thousands of resources from the world's best providers.",
    "category": "HR Talent & Recruitment",
    "title": "Go1 Integration With Cold Email Sales Tool",
    "h1": "Integrate Go1 with SalesBlink",
    "metaDescription": "Integrate Go1 and other HR Talent & Recruitment Tools with SalesBlink. Two Way Go1 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Okta",
    "slug": "okta",
    "logo": "https://zapier-images.imgix.net/storage/services/624385542d6319d31c82fe195b5b62a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Okta helps companies manage and secure user authentication into modern applications, and for developers to build identity controls into applications, website web services and into devices.",
    "category": "Security & Identity Tools",
    "title": "Okta Integration With Cold Email Sales Tool",
    "h1": "Integrate Okta with SalesBlink",
    "metaDescription": "Integrate Okta and other Security & Identity Tools Tools with SalesBlink. Two Way Okta Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Investment Dominator",
    "slug": "investment-dominator",
    "logo": "https://zapier-images.imgix.net/storage/developer/05a0b1175e19221fd811275e4a0f346d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Investment Dominator CRM is a complete real estate investment deal management and automation system for land, home, and note investors.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Investment Dominator Integration With Cold Email Sales Tool",
    "h1": "Integrate Investment Dominator with SalesBlink",
    "metaDescription": "Integrate Investment Dominator and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Investment Dominator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Toky",
    "slug": "toky",
    "logo": "https://zapier-images.imgix.net/storage/developer/a7470e7c781b38f0da6d6e31bfece476_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Toky is a cloud telephony service that helps you to start your business call center, make and receive calls, send SMS and much more.",
    "category": "Phone & SMS",
    "title": "Toky Integration With Cold Email Sales Tool",
    "h1": "Integrate Toky with SalesBlink",
    "metaDescription": "Integrate Toky and other Phone & SMS Tools with SalesBlink. Two Way Toky Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Retently",
    "slug": "retently",
    "logo": "https://zapier-images.imgix.net/storage/developer/fc981ab623edf239769c856651c6bbac_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Retently helps you measure customer satisfaction via NPS, CSAT, and CES surveys. Collect, analyze, and act on valuable customer feedback.",
    "category": "Forms & Surveys",
    "title": "Retently Integration With Cold Email Sales Tool",
    "h1": "Integrate Retently with SalesBlink",
    "metaDescription": "Integrate Retently and other Forms & Surveys Tools with SalesBlink. Two Way Retently Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CalendarHero",
    "slug": "zoomai",
    "logo": "https://zapier-images.imgix.net/storage/services/0233df3839d60c7cad0360e6ecf40bc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CalendarHero is the smarter, faster way to schedule all of your meetings",
    "category": "Scheduling & Booking",
    "title": "CalendarHero Integration With Cold Email Sales Tool",
    "h1": "Integrate CalendarHero with SalesBlink",
    "metaDescription": "Integrate CalendarHero and other Scheduling & Booking Tools with SalesBlink. Two Way CalendarHero Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Docketwise",
    "slug": "docketwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8318171deaac811b67684303a2fea5f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docketwise is a practice management and forms software for immigration lawyers and law firms.",
    "category": "Forms & Surveys",
    "title": "Docketwise Integration With Cold Email Sales Tool",
    "h1": "Integrate Docketwise with SalesBlink",
    "metaDescription": "Integrate Docketwise and other Forms & Surveys Tools with SalesBlink. Two Way Docketwise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HappyForms",
    "slug": "happyforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/45d3c355a3241631c56e82f47144059a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HappyForms helps you to grow customer interactions through better forms, sign-ups, surveys, polls and checkouts for WordPress.",
    "category": "Forms & Surveys",
    "title": "HappyForms Integration With Cold Email Sales Tool",
    "h1": "Integrate HappyForms with SalesBlink",
    "metaDescription": "Integrate HappyForms and other Forms & Surveys Tools with SalesBlink. Two Way HappyForms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Tars",
    "slug": "tars",
    "logo": "https://zapier-images.imgix.net/storage/services/11c00b0a0eea490388b2b91e0cd24c7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tars is a chatbot and conversational landing page builder which helps marketers increase conversion rate in their paid ad campaigns.",
    "category": "Marketing Automation",
    "title": "Tars Integration With Cold Email Sales Tool",
    "h1": "Integrate Tars with SalesBlink",
    "metaDescription": "Integrate Tars and other Marketing Automation Tools with SalesBlink. Two Way Tars Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Contractor Foreman",
    "slug": "contractor-foreman",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a1e4f3b53fa1e863509d1921e3bf9a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contractor Foreman is the most affordable all-in-one construction management software for contractors and is trusted by contractors in more than 75 countries. Recently named a Top App by DailyReporter.com, Capterra, SoftwareAdvice and ConstrucTECH.",
    "category": "Contact Management",
    "title": "Contractor Foreman Integration With Cold Email Sales Tool",
    "h1": "Integrate Contractor Foreman with SalesBlink",
    "metaDescription": "Integrate Contractor Foreman and other Contact Management Tools with SalesBlink. Two Way Contractor Foreman Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Starshipit",
    "slug": "starshipit",
    "logo": "https://zapier-images.imgix.net/storage/services/074bc6265bf8672a1d724b3ca136eb48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Starshipit is an integrated shipping and tracking software allowing you to generate shipments in bulk, send out customised notifications to your customers, and track shipments.",
    "category": "eCommerce",
    "title": "Starshipit Integration With Cold Email Sales Tool",
    "h1": "Integrate Starshipit with SalesBlink",
    "metaDescription": "Integrate Starshipit and other eCommerce Tools with SalesBlink. Two Way Starshipit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelpDesk",
    "slug": "helpdesk",
    "logo": "https://zapier-images.imgix.net/storage/services/128cded33591bc9535d18dba32397b2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelpDesk is a ticketing software for effortless customer support",
    "category": "Customer Support",
    "title": "HelpDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate HelpDesk with SalesBlink",
    "metaDescription": "Integrate HelpDesk and other Customer Support Tools with SalesBlink. Two Way HelpDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CASEpeer",
    "slug": "casepeer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3c6c3b165a49f5c47cda52b2a454a9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Case and practice management software for personal injury attorneys",
    "category": "CRM (Customer Relationship Management)",
    "title": "CASEpeer Integration With Cold Email Sales Tool",
    "h1": "Integrate CASEpeer with SalesBlink",
    "metaDescription": "Integrate CASEpeer and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CASEpeer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fortnox",
    "slug": "fortnox-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e5d65b865aef666c5c99850e162a24c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fortnox is a cloud-based platform that meets the needs of small businesses and accounting agencies for programs and services that can manage their finances efficiently.",
    "category": "Accounting",
    "title": "Fortnox Integration With Cold Email Sales Tool",
    "h1": "Integrate Fortnox with SalesBlink",
    "metaDescription": "Integrate Fortnox and other Accounting Tools with SalesBlink. Two Way Fortnox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Enalyzer",
    "slug": "enalyzer",
    "logo": "https://zapier-images.imgix.net/storage/developer/9faeddafa9626516850e1bd4c3507127.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy and free tool for creating surveys and analyzing results.",
    "category": "Forms & Surveys",
    "title": "Enalyzer Integration With Cold Email Sales Tool",
    "h1": "Integrate Enalyzer with SalesBlink",
    "metaDescription": "Integrate Enalyzer and other Forms & Surveys Tools with SalesBlink. Two Way Enalyzer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zadarma",
    "slug": "zadarma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c337f9852d4334ec13c21c0b57499d61_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zadarma is a VOIP client for support and sales teams that helps agents increase their productivity.",
    "category": "Call Tracking",
    "title": "Zadarma Integration With Cold Email Sales Tool",
    "h1": "Integrate Zadarma with SalesBlink",
    "metaDescription": "Integrate Zadarma and other Call Tracking Tools with SalesBlink. Two Way Zadarma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CYPHER LEARNING",
    "slug": "cypher-learning",
    "logo": "https://zapier-images.imgix.net/storage/services/aebb4ce79138f9cfa06d89d88d729d13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CYPHER LEARNING is a leading provider of intelligent learning platforms (ILP) for schools, universities, organizations, and entrepreneurs around the world.",
    "category": "Online Courses",
    "title": "CYPHER LEARNING Integration With Cold Email Sales Tool",
    "h1": "Integrate CYPHER LEARNING with SalesBlink",
    "metaDescription": "Integrate CYPHER LEARNING and other Online Courses Tools with SalesBlink. Two Way CYPHER LEARNING Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wheel of Popups",
    "slug": "wheel-of-popups",
    "logo": "https://zapier-images.imgix.net/storage/developer/8c2396c24d3e552c051f011f5b665ff6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wheel of Popups offers small discounts to your website visitors for giving you their email address through a gamified pop-up.",
    "category": "Email Newsletters",
    "title": "Wheel of Popups Integration With Cold Email Sales Tool",
    "h1": "Integrate Wheel of Popups with SalesBlink",
    "metaDescription": "Integrate Wheel of Popups and other Email Newsletters Tools with SalesBlink. Two Way Wheel of Popups Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tolstoy",
    "slug": "tolstoy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef15beb6b11f8e0d4650ec9a019bae25.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tolstoy is a video marketing platform for lead engagement.",
    "category": "Marketing",
    "title": "Tolstoy Integration With Cold Email Sales Tool",
    "h1": "Integrate Tolstoy with SalesBlink",
    "metaDescription": "Integrate Tolstoy and other Marketing Tools with SalesBlink. Two Way Tolstoy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "myRealPage",
    "slug": "myrealpage",
    "logo": "https://zapier-images.imgix.net/storage/services/ec85e8a1b2461471676cf2c2f3b29bda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myRealPage offers website solutions for agents, teams & brokers seeking superior online marketing.",
    "category": "CRM (Customer Relationship Management)",
    "title": "myRealPage Integration With Cold Email Sales Tool",
    "h1": "Integrate myRealPage with SalesBlink",
    "metaDescription": "Integrate myRealPage and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way myRealPage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextIt",
    "slug": "textit",
    "logo": "https://zapier-images.imgix.net/storage/developer/b7802eec48d5640f58927c142bfc461c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextIt provides a way of automating conversational interactions over SMS, messaging bots and phone calls.",
    "category": "Phone & SMS",
    "title": "TextIt Integration With Cold Email Sales Tool",
    "h1": "Integrate TextIt with SalesBlink",
    "metaDescription": "Integrate TextIt and other Phone & SMS Tools with SalesBlink. Two Way TextIt Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sonix",
    "slug": "sonix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00aa3d996de5c85e080c34b81ec11843.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sonix is an automated transcription service. We quickly and accurately convert audio and video files to text. We also provide rich editing tools so you can easily edit, annotate, and export your transcripts.",
    "category": "Transcription",
    "title": "Sonix Integration With Cold Email Sales Tool",
    "h1": "Integrate Sonix with SalesBlink",
    "metaDescription": "Integrate Sonix and other Transcription Tools with SalesBlink. Two Way Sonix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tidbyt",
    "slug": "tidbyt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89a2a065f1c0a35ab8bd5197438d4471.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tidbyt allows you to see what matters. Send notifications, install apps, and update installations all on a physical desktop display.",
    "category": "Internet of Things",
    "title": "Tidbyt Integration With Cold Email Sales Tool",
    "h1": "Integrate Tidbyt with SalesBlink",
    "metaDescription": "Integrate Tidbyt and other Internet of Things Tools with SalesBlink. Two Way Tidbyt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clientjoy",
    "slug": "clientjoy",
    "logo": "https://zapier-images.imgix.net/storage/services/ae1d9a35854893b18dd5436f67bf4d7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clientjoy is Client Life-Cycle Management Software for Modern Agencies that helps you manage Leads, Proposals, Clients, Invoicing and Revenues under a single roof.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clientjoy Integration With Cold Email Sales Tool",
    "h1": "Integrate Clientjoy with SalesBlink",
    "metaDescription": "Integrate Clientjoy and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clientjoy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Expensify",
    "slug": "expensify",
    "logo": "https://zapier-images.imgix.net/storage/services/67d65de1d9b4a7d21aff1b6a49fae0fd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Expensify is an application that makes it easy to import expenses directly from a credit card to create expense reports quickly and easily.",
    "category": "Accounting",
    "title": "Expensify Integration With Cold Email Sales Tool",
    "h1": "Integrate Expensify with SalesBlink",
    "metaDescription": "Integrate Expensify and other Accounting Tools with SalesBlink. Two Way Expensify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CalorieLeads",
    "slug": "calorieleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/12d7356a2c59193525e09804237b7322.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build a Custom Calorie Calculator For Your Fitness Business.",
    "category": "Fitness",
    "title": "CalorieLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate CalorieLeads with SalesBlink",
    "metaDescription": "Integrate CalorieLeads and other Fitness Tools with SalesBlink. Two Way CalorieLeads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paperless Pipeline",
    "slug": "paperless-pipeline",
    "logo": "https://zapier-images.imgix.net/storage/developer/a585d03c73b9ca9cdc101d5f5a29f0f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paperless Pipeline is a real estate transaction and commission management system for agents & brokers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Paperless Pipeline Integration With Cold Email Sales Tool",
    "h1": "Integrate Paperless Pipeline with SalesBlink",
    "metaDescription": "Integrate Paperless Pipeline and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Paperless Pipeline Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contractbook",
    "slug": "contractbook",
    "logo": "https://zapier-images.imgix.net/storage/services/45a9b8685e4dff61236e5b4ddc31c917.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contractbook is an easy signing and simple contract management tool for modern businesses.",
    "category": "Signatures",
    "title": "Contractbook Integration With Cold Email Sales Tool",
    "h1": "Integrate Contractbook with SalesBlink",
    "metaDescription": "Integrate Contractbook and other Signatures Tools with SalesBlink. Two Way Contractbook Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MindManager",
    "slug": "mindmanager-enterprise",
    "logo": "https://zapier-images.imgix.net/storage/developer/3dc6378fe299600ac5f3964eb28983f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MindManager is productivity software for visualizing and managing complex information, such as detailed project plans.  MindManager mind maps, flow charts, Gantt charts, and other diagrams help users more accurately define and validate project schedules, dependencies, workflows and resources.",
    "category": "Documents",
    "title": "MindManager Integration With Cold Email Sales Tool",
    "h1": "Integrate MindManager with SalesBlink",
    "metaDescription": "Integrate MindManager and other Documents Tools with SalesBlink. Two Way MindManager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "New Relic",
    "slug": "new-relic",
    "logo": "https://zapier-images.imgix.net/storage/developer/244b51cd822ac80993c8c8e0074176c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "New Relic provides SaaS Application Performance Management for Ruby, PHP, .Net, Java, Python, Node.js, Android, and iOS Apps.",
    "category": "Server Monitoring",
    "title": "New Relic Integration With Cold Email Sales Tool",
    "h1": "Integrate New Relic with SalesBlink",
    "metaDescription": "Integrate New Relic and other Server Monitoring Tools with SalesBlink. Two Way New Relic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Shipday",
    "slug": "shipday",
    "logo": "https://zapier-images.imgix.net/storage/services/2a694e616d2c8c9e0eea1bb185ccde6f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shipday is a free dispatch and delivery tracking system with driver App.",
    "category": "Dashboards",
    "title": "Shipday Integration With Cold Email Sales Tool",
    "h1": "Integrate Shipday with SalesBlink",
    "metaDescription": "Integrate Shipday and other Dashboards Tools with SalesBlink. Two Way Shipday Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Metricool",
    "slug": "metricool",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/71ec3c16b12a6f4f22d0e7669d53cca0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Metricool is an all-in-one social media platform for analyzing, managing, and growing your socials.",
    "category": "Social Media Marketing",
    "title": "Metricool Integration With Cold Email Sales Tool",
    "h1": "Integrate Metricool with SalesBlink",
    "metaDescription": "Integrate Metricool and other Social Media Marketing Tools with SalesBlink. Two Way Metricool Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "roam-bot",
    "slug": "roam-bot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/29554059594483c31a07d1d575a8032b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Roam-Bot connects everything to and from RoamResearch through online plateforms",
    "category": "Productivity",
    "title": "roam-bot Integration With Cold Email Sales Tool",
    "h1": "Integrate roam-bot with SalesBlink",
    "metaDescription": "Integrate roam-bot and other Productivity Tools with SalesBlink. Two Way roam-bot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "fieldd",
    "slug": "fieldd",
    "logo": "https://zapier-images.imgix.net/storage/services/4f4e0ba499d1da8df855d78232b5d35e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fieldd enables companies to take control of their operations with our leading field service management software.",
    "category": "Scheduling & Booking",
    "title": "fieldd Integration With Cold Email Sales Tool",
    "h1": "Integrate fieldd with SalesBlink",
    "metaDescription": "Integrate fieldd and other Scheduling & Booking Tools with SalesBlink. Two Way fieldd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Solve CRM",
    "slug": "solve360",
    "logo": "https://zapier-images.imgix.net/storage/services/f0b28ebda08ad15290e9394dee0b79c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Solve is a productivity CRM for teams to manage customer workflows. Simplify all record keeping, scheduling, communication and information sharing - especially when mobile.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Solve CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Solve CRM with SalesBlink",
    "metaDescription": "Integrate Solve CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Solve CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "rasa.io",
    "slug": "rasaio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05d7b3587cc11645c2c07b40bfae088e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sends a personalized newsletter based on individual interests and behavior",
    "category": "Email Newsletters",
    "title": "rasa.io Integration With Cold Email Sales Tool",
    "h1": "Integrate rasa.io with SalesBlink",
    "metaDescription": "Integrate rasa.io and other Email Newsletters Tools with SalesBlink. Two Way rasa.io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SOS Inventory",
    "slug": "sos-inventory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb4eecb2ee03e5a6f3261d3c6ed48cc5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SOS Inventory is an app that tracks inventory, provides order management and manufacturing tracking and integrates with QuickBooks online.",
    "category": "Sales & CRM",
    "title": "SOS Inventory Integration With Cold Email Sales Tool",
    "h1": "Integrate SOS Inventory with SalesBlink",
    "metaDescription": "Integrate SOS Inventory and other Sales & CRM Tools with SalesBlink. Two Way SOS Inventory Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Groundwork",
    "slug": "groundwork",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f131a9237b7ff3ef8e6628c0101e8b30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Groundwork is a virtual sales tool for contractors so they can stop wasting time on bad leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Groundwork Integration With Cold Email Sales Tool",
    "h1": "Integrate Groundwork with SalesBlink",
    "metaDescription": "Integrate Groundwork and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Groundwork Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quire",
    "slug": "quire",
    "logo": "https://zapier-images.imgix.net/storage/services/918c6d811c75f4a76c22ea7c94f45d3a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quire is a new-generation collaborative platform to organize and manage from small enterprise projects to large-scale initiatives.",
    "category": "Project Management",
    "title": "Quire Integration With Cold Email Sales Tool",
    "h1": "Integrate Quire with SalesBlink",
    "metaDescription": "Integrate Quire and other Project Management Tools with SalesBlink. Two Way Quire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Systemate",
    "slug": "systemate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cbfbd29a01bd4fc74b929bc54a6fb59e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Systemate is an all-in-one system for real estate investors",
    "category": "Contact Management",
    "title": "Systemate Integration With Cold Email Sales Tool",
    "h1": "Integrate Systemate with SalesBlink",
    "metaDescription": "Integrate Systemate and other Contact Management Tools with SalesBlink. Two Way Systemate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dribbble",
    "slug": "dribbble",
    "logo": "https://zapier-images.imgix.net/storage/developer/574d86388a0dfc90ed0a762ce7d369cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dribbble is a place to show and tell, promote, discover, and explore design.",
    "category": "Social Media Accounts",
    "title": "Dribbble Integration With Cold Email Sales Tool",
    "h1": "Integrate Dribbble with SalesBlink",
    "metaDescription": "Integrate Dribbble and other Social Media Accounts Tools with SalesBlink. Two Way Dribbble Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MightyForms",
    "slug": "mightyforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f1ac88866e3d3c9c38ad0ba4f31f2d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MightyForms is a forms app providing powerful web forms to supercharge your business.",
    "category": "Forms & Surveys",
    "title": "MightyForms Integration With Cold Email Sales Tool",
    "h1": "Integrate MightyForms with SalesBlink",
    "metaDescription": "Integrate MightyForms and other Forms & Surveys Tools with SalesBlink. Two Way MightyForms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "remove.bg",
    "slug": "removebg",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b41e5296df407354347f6d1f5195e37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "remove.bg removes the background from any image 100% automatically.",
    "category": "Images & Design",
    "title": "remove.bg Integration With Cold Email Sales Tool",
    "h1": "Integrate remove.bg with SalesBlink",
    "metaDescription": "Integrate remove.bg and other Images & Design Tools with SalesBlink. Two Way remove.bg Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cloud Attract",
    "slug": "cloud-attract",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bdb707f1e9bd81bd4de958b42dff8dce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud Attract is a lead capture tool to attract buyers and sellers with beautiful landing pages that automatically respond with a home valuation, CMA, or listing alerts.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Cloud Attract Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloud Attract with SalesBlink",
    "metaDescription": "Integrate Cloud Attract and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Cloud Attract Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WS Form",
    "slug": "ws-form",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe1beae0494c3a1457527c2f424f9c87.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WS Form is a form builder for Wordpress. Build forms in a single click or use the unique drag and drop editor to create forms in seconds.",
    "category": "Forms & Surveys",
    "title": "WS Form Integration With Cold Email Sales Tool",
    "h1": "Integrate WS Form with SalesBlink",
    "metaDescription": "Integrate WS Form and other Forms & Surveys Tools with SalesBlink. Two Way WS Form Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Endorsal",
    "slug": "endorsal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f72e0c88f6aacac066f73a221c9f85b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Endorsal fully automates the collection and display of testimonials & reviews, boosting social proof on autopilot.",
    "category": "Marketing Automation",
    "title": "Endorsal Integration With Cold Email Sales Tool",
    "h1": "Integrate Endorsal with SalesBlink",
    "metaDescription": "Integrate Endorsal and other Marketing Automation Tools with SalesBlink. Two Way Endorsal Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Force24",
    "slug": "force24",
    "logo": "https://zapier-images.imgix.net/storage/services/915bc68967d5938d0439e970c35a846a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Force24 is a UK-based provider of enterprise-level marketing automation. We help to move prospects through their lifecycle, automating their journey. It means speaking to the right person, at the right time, using the right marketing channel.",
    "category": "Marketing Automation",
    "title": "Force24 Integration With Cold Email Sales Tool",
    "h1": "Integrate Force24 with SalesBlink",
    "metaDescription": "Integrate Force24 and other Marketing Automation Tools with SalesBlink. Two Way Force24 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Moneypenny",
    "slug": "moneypenny-ca138431",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cb727222b5f63a504fc889d7bf180e92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moneypenny gives you dedicated people to manage your calls, live chat & more. This application allows you to connect to Moneypenny’s service.",
    "category": "Communication",
    "title": "Moneypenny Integration With Cold Email Sales Tool",
    "h1": "Integrate Moneypenny with SalesBlink",
    "metaDescription": "Integrate Moneypenny and other Communication Tools with SalesBlink. Two Way Moneypenny Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gymdesk",
    "slug": "gymdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b3417418cc8d99d1ac51954001ceb647.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gymdesk provides modern management software to gyms, martial arts schools, yoga studios and other membership businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Gymdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Gymdesk with SalesBlink",
    "metaDescription": "Integrate Gymdesk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Gymdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LIME Go",
    "slug": "lime-go",
    "logo": "https://zapier-images.imgix.net/storage/developer/702a1ae8b2b921254ad98960b6262357_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LIME Go is a smart CRM-system with all Nordic companies and decision makers already included and always kept up to date.\nLIME Go helps you find and win deals, from first contact to signed contract.",
    "category": "Sales & CRM",
    "title": "LIME Go Integration With Cold Email Sales Tool",
    "h1": "Integrate LIME Go with SalesBlink",
    "metaDescription": "Integrate LIME Go and other Sales & CRM Tools with SalesBlink. Two Way LIME Go Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bind ERP",
    "slug": "bind-erp",
    "logo": "https://zapier-images.imgix.net/storage/developer/edfa1fe758f1e6832c0544fa0291282d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bind ERP is a management system that allows SMBs to take control of their business in an easy, accessible manner.",
    "category": "Accounting",
    "title": "Bind ERP Integration With Cold Email Sales Tool",
    "h1": "Integrate Bind ERP with SalesBlink",
    "metaDescription": "Integrate Bind ERP and other Accounting Tools with SalesBlink. Two Way Bind ERP Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Escala",
    "slug": "escala",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4a2ce109676683577a9c613884a7963.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Escala is the all-in-one marketing and automation platform to accelerate your sales",
    "category": "CRM (Customer Relationship Management)",
    "title": "Escala Integration With Cold Email Sales Tool",
    "h1": "Integrate Escala with SalesBlink",
    "metaDescription": "Integrate Escala and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Escala Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HOVER",
    "slug": "hover",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ecc67083f1fa4475e141d605e2602eba_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HOVER uses ground-level photography to create an accurate 3D model and measurements of any property.",
    "category": "Project Management",
    "title": "HOVER Integration With Cold Email Sales Tool",
    "h1": "Integrate HOVER with SalesBlink",
    "metaDescription": "Integrate HOVER and other Project Management Tools with SalesBlink. Two Way HOVER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tango Card",
    "slug": "tango-card",
    "logo": "https://zapier-images.imgix.net/storage/developer/8ccccb3a4d0c72f4867951fe6212d595.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tango Card allows you to send gift cards for 100+ brands.",
    "category": "Customer Appreciation",
    "title": "Tango Card Integration With Cold Email Sales Tool",
    "h1": "Integrate Tango Card with SalesBlink",
    "metaDescription": "Integrate Tango Card and other Customer Appreciation Tools with SalesBlink. Two Way Tango Card Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unisender",
    "slug": "unisender",
    "logo": "https://zapier-images.imgix.net/storage/developer/2175baffe40e8d9a2a99ea9af3eca67f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UniSender is one of the leading email marketing service providers in the Eastern Europe, provides Email and SMS deliveries.",
    "category": "Email Newsletters",
    "title": "Unisender Integration With Cold Email Sales Tool",
    "h1": "Integrate Unisender with SalesBlink",
    "metaDescription": "Integrate Unisender and other Email Newsletters Tools with SalesBlink. Two Way Unisender Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PeerBoard",
    "slug": "peerboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1194c893304a9c0c3750a081fabe470b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PeerBoard is a plug and play community platform that enables your members to share knowledge and help each other.",
    "category": "Communication",
    "title": "PeerBoard Integration With Cold Email Sales Tool",
    "h1": "Integrate PeerBoard with SalesBlink",
    "metaDescription": "Integrate PeerBoard and other Communication Tools with SalesBlink. Two Way PeerBoard Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Realeflow",
    "slug": "realeflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5315d8f963bafe7bfd1c4631c28661af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Realeflow is a real estate investor CRM + marketing automation tools, all-in-one platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Realeflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Realeflow with SalesBlink",
    "metaDescription": "Integrate Realeflow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Realeflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MRPeasy",
    "slug": "mrpeasy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5abf2f99043649ebd0e1f349554332ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MRPeasy is a cloud-based MRP system for small manufacturers\n(10 - 200 employees)",
    "category": "Product Management",
    "title": "MRPeasy Integration With Cold Email Sales Tool",
    "h1": "Integrate MRPeasy with SalesBlink",
    "metaDescription": "Integrate MRPeasy and other Product Management Tools with SalesBlink. Two Way MRPeasy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gripp",
    "slug": "gripp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54e30212ffb0da051645c90fd21f716b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gripp is a team collaboration tool that lets you organize anything and everything to keep your projects on task.",
    "category": "Project Management",
    "title": "Gripp Integration With Cold Email Sales Tool",
    "h1": "Integrate Gripp with SalesBlink",
    "metaDescription": "Integrate Gripp and other Project Management Tools with SalesBlink. Two Way Gripp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CommCare",
    "slug": "commcare",
    "logo": "https://zapier-images.imgix.net/storage/developer/4a558b5f427fa3b51e4c66d41e2d7b37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CommCare is an an open source platform that lets you make free, customized mobile applications for your mobile workforce or yourself.",
    "category": "Forms & Surveys",
    "title": "CommCare Integration With Cold Email Sales Tool",
    "h1": "Integrate CommCare with SalesBlink",
    "metaDescription": "Integrate CommCare and other Forms & Surveys Tools with SalesBlink. Two Way CommCare Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SeaTable",
    "slug": "seatable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4df43b8a326d68b8bce1fa3363892683.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SeaTable is a web based table-database hybrid. SeaTable is a flexible tool for your team, complete overview and better collaboration. SeaTable is the building block for your ideas.",
    "category": "Databases",
    "title": "SeaTable Integration With Cold Email Sales Tool",
    "h1": "Integrate SeaTable with SalesBlink",
    "metaDescription": "Integrate SeaTable and other Databases Tools with SalesBlink. Two Way SeaTable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Copilot",
    "slug": "copilot",
    "logo": "https://zapier-images.imgix.net/storage/services/a4ab15a98b89021b79f3b14fedcba9c5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create a client portal to combine messaging, payments, and other modules to offer clients a branded, streamlined experience.",
    "category": "Project Management",
    "title": "Copilot Integration With Cold Email Sales Tool",
    "h1": "Integrate Copilot with SalesBlink",
    "metaDescription": "Integrate Copilot and other Project Management Tools with SalesBlink. Two Way Copilot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CATS",
    "slug": "cats",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73df4d1d586a4dc0629b2ec72c7c7079.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CATS is an applicant tracking system. Manage the entire tracking process from posting jobs to leading job boards and social networks to generating customized reports. Collect applications with the hosted career portal and find the right candidates with searching capabilities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CATS Integration With Cold Email Sales Tool",
    "h1": "Integrate CATS with SalesBlink",
    "metaDescription": "Integrate CATS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CATS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ActiveDEMAND",
    "slug": "activedemand",
    "logo": "https://zapier-images.imgix.net/storage/developer/d6460149405baa6c6ef8cf2f0f55cc3c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ActiveDEMAND is an integrated marketing platform that helps companies drive demand, improve conversion velocity, and demonstrate value delivered.",
    "category": "Marketing Automation",
    "title": "ActiveDEMAND Integration With Cold Email Sales Tool",
    "h1": "Integrate ActiveDEMAND with SalesBlink",
    "metaDescription": "Integrate ActiveDEMAND and other Marketing Automation Tools with SalesBlink. Two Way ActiveDEMAND Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Higher Logic Vanilla",
    "slug": "vanilla",
    "logo": "https://zapier-images.imgix.net/storage/services/261502e0f5491a65f398fae03d2f770a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Community software that powers connection between a brand and their customers to build loyalty, improve support, and increase customer lifetime value.",
    "category": "Customer Support",
    "title": "Higher Logic Vanilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Higher Logic Vanilla with SalesBlink",
    "metaDescription": "Integrate Higher Logic Vanilla and other Customer Support Tools with SalesBlink. Two Way Higher Logic Vanilla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BookingSync",
    "slug": "bookingsync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e8bb0d8a175e6a90aa472644ccac22e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BookingSync is a property management system for vacation and short terms rentals: centralize everything in one place to save time and book more.",
    "category": "Scheduling & Booking",
    "title": "BookingSync Integration With Cold Email Sales Tool",
    "h1": "Integrate BookingSync with SalesBlink",
    "metaDescription": "Integrate BookingSync and other Scheduling & Booking Tools with SalesBlink. Two Way BookingSync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jetpack Workflow",
    "slug": "jetpack-workflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5f8122bb975de83d1f422b5a627f870.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workflow Software Built To Help Your Growing Firm",
    "category": "Project Management",
    "title": "Jetpack Workflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Jetpack Workflow with SalesBlink",
    "metaDescription": "Integrate Jetpack Workflow and other Project Management Tools with SalesBlink. Two Way Jetpack Workflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Telzio",
    "slug": "telzio",
    "logo": "https://zapier-images.imgix.net/storage/services/8ced02ed0b5d5169d5a44283f06f67f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Telzio provides companies with high-quality, feature-rich phone systems, and integrates teams under a seamless, cloud platform.",
    "category": "Phone & SMS",
    "title": "Telzio Integration With Cold Email Sales Tool",
    "h1": "Integrate Telzio with SalesBlink",
    "metaDescription": "Integrate Telzio and other Phone & SMS Tools with SalesBlink. Two Way Telzio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loopy Loyalty",
    "slug": "loopy-loyalty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/954e9deb6fe89c19e9f9f7b8ae8cd50d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loopy Loyalty is a platform for businesses to create and manage digital loyalty stamp cards in Apple Wallet and Android Pay.",
    "category": "Marketing Automation",
    "title": "Loopy Loyalty Integration With Cold Email Sales Tool",
    "h1": "Integrate Loopy Loyalty with SalesBlink",
    "metaDescription": "Integrate Loopy Loyalty and other Marketing Automation Tools with SalesBlink. Two Way Loopy Loyalty Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Heights Platform",
    "slug": "heights-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer/00239ad34a8d8b79d8fddb94e7a692c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Heights Platform is online course building software that helps you host and build the most powerful online school to sell your courses and digital products.",
    "category": "eCommerce",
    "title": "Heights Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Heights Platform with SalesBlink",
    "metaDescription": "Integrate Heights Platform and other eCommerce Tools with SalesBlink. Two Way Heights Platform Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Juro",
    "slug": "juro-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a34fb8b70d8dce2a3ad916237b60fc3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Juro is an all-in-one contract automation platform that helps legal and business users to agree & manage contracts in one unified workspace.",
    "category": "Documents",
    "title": "Juro Integration With Cold Email Sales Tool",
    "h1": "Integrate Juro with SalesBlink",
    "metaDescription": "Integrate Juro and other Documents Tools with SalesBlink. Two Way Juro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chanty",
    "slug": "chanty",
    "logo": "https://zapier-images.imgix.net/storage/services/ad0aca607ad0290def37fe9c1fed8788.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chanty is a simple AI-powered team chat to make your team super productive. Applets let you automate tasks, free time from switching between multiple apps, and accumulate collaborative efforts in a single place.",
    "category": "Team Chat",
    "title": "Chanty Integration With Cold Email Sales Tool",
    "h1": "Integrate Chanty with SalesBlink",
    "metaDescription": "Integrate Chanty and other Team Chat Tools with SalesBlink. Two Way Chanty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upvoty",
    "slug": "upvoty",
    "logo": "https://zapier-images.imgix.net/storage/services/d4bc0d295c5404a912c26b4e42099e0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upvoty is a user feedback tool with feature voting. Turn user feedback into actionable product optimizations!",
    "category": "Forms & Surveys",
    "title": "Upvoty Integration With Cold Email Sales Tool",
    "h1": "Integrate Upvoty with SalesBlink",
    "metaDescription": "Integrate Upvoty and other Forms & Surveys Tools with SalesBlink. Two Way Upvoty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unicorn Platform",
    "slug": "unicorn-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb51e11718ca3be6f9e3543f4700be7c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unicorn Platform is a drag & drop website and blog builder for startups, mobile apps, and SaaS.",
    "category": "Website Builders",
    "title": "Unicorn Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Unicorn Platform with SalesBlink",
    "metaDescription": "Integrate Unicorn Platform and other Website Builders Tools with SalesBlink. Two Way Unicorn Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSGlobal",
    "slug": "smsglobal",
    "logo": "https://zapier-images.imgix.net/storage/services/a99b6de26beb7397a7462003f855c91b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Power up business communication with SMSGlobal’s leading SMS solutions, premium support, and wholesale SMS pricing.",
    "category": "Phone & SMS",
    "title": "SMSGlobal Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSGlobal with SalesBlink",
    "metaDescription": "Integrate SMSGlobal and other Phone & SMS Tools with SalesBlink. Two Way SMSGlobal Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Weezevent",
    "slug": "weezevent-da56471",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9df37d94924a601ae9a86a33d5278d4c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Weezevent provides more than 200,000 event organisers and leisure venues with innovative technological solutions for ticketing and cashless payment.",
    "category": "Event Management",
    "title": "Weezevent Integration With Cold Email Sales Tool",
    "h1": "Integrate Weezevent with SalesBlink",
    "metaDescription": "Integrate Weezevent and other Event Management Tools with SalesBlink. Two Way Weezevent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Testimonial",
    "slug": "testimonial",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4eabfb8dd6dc2a4cfc4cf1f40f641f2f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Testimonial makes it easy for businesses to collect testimonials.",
    "category": "Marketing",
    "title": "Testimonial Integration With Cold Email Sales Tool",
    "h1": "Integrate Testimonial with SalesBlink",
    "metaDescription": "Integrate Testimonial and other Marketing Tools with SalesBlink. Two Way Testimonial Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sarbacane",
    "slug": "sarbacane",
    "logo": "https://zapier-images.imgix.net/storage/developer/b127efb49dd3120edf707b3e12d00e14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sarbacane is a solution for managing, creating and sending email and SMS campaigns, as well as setting up intelligent, automated marketing workflows.",
    "category": "Email Newsletters",
    "title": "Sarbacane Integration With Cold Email Sales Tool",
    "h1": "Integrate Sarbacane with SalesBlink",
    "metaDescription": "Integrate Sarbacane and other Email Newsletters Tools with SalesBlink. Two Way Sarbacane Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DataScope Forms",
    "slug": "datascope-forms",
    "logo": "https://zapier-images.imgix.net/storage/services/ca00df8a4b991d9be76b48693df35da8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DataScope Forms is a mobile solution to automate your operation. Collect data offline, manage field teams and share business insights.",
    "category": "Forms & Surveys",
    "title": "DataScope Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate DataScope Forms with SalesBlink",
    "metaDescription": "Integrate DataScope Forms and other Forms & Surveys Tools with SalesBlink. Two Way DataScope Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elastic Email",
    "slug": "elasticemail",
    "logo": "https://zapier-images.imgix.net/storage/services/dfd4cfdb6f5ecdc6c52074951ce5b646.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elastic Email is an all-in-one email delivery platform. Offering an SMTP Relay, robust HTTP API, and a User Interface that has a complete suite of tools and features for managing contacts, templates, campaigns, and reports. Specializing in delivering transactional and marketing email for businesses of any size.",
    "category": "Email",
    "title": "Elastic Email Integration With Cold Email Sales Tool",
    "h1": "Integrate Elastic Email with SalesBlink",
    "metaDescription": "Integrate Elastic Email and other Email Tools with SalesBlink. Two Way Elastic Email Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Communi App",
    "slug": "communi-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bed0ae3553c1d2baf25fb163a4f6e874_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Communi is an app for all communities are looking for ways to better connect and integrate new people.",
    "category": "Social Media Accounts",
    "title": "Communi App Integration With Cold Email Sales Tool",
    "h1": "Integrate Communi App with SalesBlink",
    "metaDescription": "Integrate Communi App and other Social Media Accounts Tools with SalesBlink. Two Way Communi App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RevBoss",
    "slug": "revboss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a83baad1dce340f5e737d650d6c959cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RevBoss is a simple tool to help sales teams to find prospects, build pipeline, and grow faster.",
    "category": "CRM (Customer Relationship Management)",
    "title": "RevBoss Integration With Cold Email Sales Tool",
    "h1": "Integrate RevBoss with SalesBlink",
    "metaDescription": "Integrate RevBoss and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way RevBoss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spiro",
    "slug": "spiro",
    "logo": "https://zapier-images.imgix.net/storage/developer/9f0e8205ce37f34f5ed03cb28922214f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spiro is an AI-Powered CRM that learns your sales process to increase effectiveness and deliver more accurate business insights.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Spiro Integration With Cold Email Sales Tool",
    "h1": "Integrate Spiro with SalesBlink",
    "metaDescription": "Integrate Spiro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Spiro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FreeAgent CRM",
    "slug": "freeagent-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/123487a41f5f519cb2e44be67b59de1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreeAgent CRM is a sales, marketing and customer service platform for businesses big-and-small who care about their customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FreeAgent CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate FreeAgent CRM with SalesBlink",
    "metaDescription": "Integrate FreeAgent CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FreeAgent CRM Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Sendmsg (שלח מסר)",
    "slug": "sendmsg",
    "logo": "https://zapier-images.imgix.net/storage/developer/28d9583922e5ff51470323827ec4ae01_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendmsg is an all-in-one marketing platform for newsletters providing email automation, landing pages, SMS, digital courses and more.",
    "category": "Marketing Automation",
    "title": "Sendmsg (שלח מסר) Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendmsg (שלח מסר) with SalesBlink",
    "metaDescription": "Integrate Sendmsg (שלח מסר) and other Marketing Automation Tools with SalesBlink. Two Way Sendmsg (שלח מסר) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omnify",
    "slug": "omnify",
    "logo": "https://zapier-images.imgix.net/storage/developer/f04c5efdc1e6d4e018f639331f8144af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omnify is a scheduling & business management platform which enables you to sell various services from a single platform.",
    "category": "Scheduling & Booking",
    "title": "Omnify Integration With Cold Email Sales Tool",
    "h1": "Integrate Omnify with SalesBlink",
    "metaDescription": "Integrate Omnify and other Scheduling & Booking Tools with SalesBlink. Two Way Omnify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OBVIO",
    "slug": "obvio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ecb369fbf961c1716804694663689163.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OBVIO is a virtual event platform, allowing our users to host virtual events for hundreds of attendees, all the way up to hundreds of thousands.",
    "category": "Event Management",
    "title": "OBVIO Integration With Cold Email Sales Tool",
    "h1": "Integrate OBVIO with SalesBlink",
    "metaDescription": "Integrate OBVIO and other Event Management Tools with SalesBlink. Two Way OBVIO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JVZoo",
    "slug": "jvzoo",
    "logo": "https://zapier-images.imgix.net/storage/developer/9d575b77b06c94eca5ba280bc59ba5a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JVZoo is a software service that serves both vendors and affiliate marketers for processing online payments.",
    "category": "Marketing Automation",
    "title": "JVZoo Integration With Cold Email Sales Tool",
    "h1": "Integrate JVZoo with SalesBlink",
    "metaDescription": "Integrate JVZoo and other Marketing Automation Tools with SalesBlink. Two Way JVZoo Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pagico",
    "slug": "pagico",
    "logo": "https://zapier-images.imgix.net/storage/developer/c2f802d61e98b745cb40107a0b17b644.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pagico is a powerful productivity suite that helps you manage projects, contacts, daily tasks and files in one place. Available for Mac, Windows, Linux, iOS and Android, Pagico is your best cross-platform information management platform.",
    "category": "Project Management",
    "title": "Pagico Integration With Cold Email Sales Tool",
    "h1": "Integrate Pagico with SalesBlink",
    "metaDescription": "Integrate Pagico and other Project Management Tools with SalesBlink. Two Way Pagico Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rossum",
    "slug": "rossum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/41c150cdd2c4e55bf4f62a98261ab0c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rossum is cloud-based app that uses artificial intelligence to replicate the human approach to data extraction from business documents.",
    "category": "Databases",
    "title": "Rossum Integration With Cold Email Sales Tool",
    "h1": "Integrate Rossum with SalesBlink",
    "metaDescription": "Integrate Rossum and other Databases Tools with SalesBlink. Two Way Rossum Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Splash",
    "slug": "splash",
    "logo": "https://zapier-images.imgix.net/storage/developer/f0c44d3b463f99d8248e6f42ca51b916.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Splash is an event marketing automation platform that empowers brands to efficiently scale event programs, reach and connect with target audiences in-person, and connect event-driven engagement to business results.",
    "category": "Event Management",
    "title": "Splash Integration With Cold Email Sales Tool",
    "h1": "Integrate Splash with SalesBlink",
    "metaDescription": "Integrate Splash and other Event Management Tools with SalesBlink. Two Way Splash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InfluencerSoft",
    "slug": "influencersoft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/56c83c9ca224fb0b1131c58255ed4810_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InfluencerSoft is a platform for selling digital products & courses online.",
    "category": "Education",
    "title": "InfluencerSoft Integration With Cold Email Sales Tool",
    "h1": "Integrate InfluencerSoft with SalesBlink",
    "metaDescription": "Integrate InfluencerSoft and other Education Tools with SalesBlink. Two Way InfluencerSoft Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Affiliatly",
    "slug": "affiliatly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59d48b4293cb26519396f6cafe081c71.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Affiliatly is an affiliate tracking software for e-commerce store owners.",
    "category": "Marketing",
    "title": "Affiliatly Integration With Cold Email Sales Tool",
    "h1": "Integrate Affiliatly with SalesBlink",
    "metaDescription": "Integrate Affiliatly and other Marketing Tools with SalesBlink. Two Way Affiliatly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProfitWell",
    "slug": "profitwell",
    "logo": "https://zapier-images.imgix.net/storage/developer/d882fb7a9cf9b787f9ba99bad42bfbcd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProfitWell provides free subscription metrics to help you identify opportunities and then tools to help you reduce churn, optimize pricing, and grow your subscription business end-to-end.",
    "category": "Dashboards",
    "title": "ProfitWell Integration With Cold Email Sales Tool",
    "h1": "Integrate ProfitWell with SalesBlink",
    "metaDescription": "Integrate ProfitWell and other Dashboards Tools with SalesBlink. Two Way ProfitWell Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CircleLoop",
    "slug": "circleloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd9e3c9cc88b7c8c740d126ee909cf00.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CircleLoop is a cloud-based business phone system, which allows you to make and receive calls on any device, anywhere.",
    "category": "Phone & SMS",
    "title": "CircleLoop Integration With Cold Email Sales Tool",
    "h1": "Integrate CircleLoop with SalesBlink",
    "metaDescription": "Integrate CircleLoop and other Phone & SMS Tools with SalesBlink. Two Way CircleLoop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Woobox",
    "slug": "woobox",
    "logo": "https://zapier-images.imgix.net/storage/developer/65a456d9d66d0ee74ea01abfbaeb8d24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Woobox is a page builder to create forms, hashtag contests, giveaways, polls, coupons, & quizzes for leads, email signups, and engagement.",
    "category": "Forms & Surveys",
    "title": "Woobox Integration With Cold Email Sales Tool",
    "h1": "Integrate Woobox with SalesBlink",
    "metaDescription": "Integrate Woobox and other Forms & Surveys Tools with SalesBlink. Two Way Woobox Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Exercise.com",
    "slug": "exercisecom",
    "logo": "https://zapier-images.imgix.net/storage/services/4d16eceba7df8f8a2ecce082fc74b489.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Exercise.com is a platform offering tools to help fitness trainers connect with clients online.",
    "category": "Fitness",
    "title": "Exercise.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Exercise.com with SalesBlink",
    "metaDescription": "Integrate Exercise.com and other Fitness Tools with SalesBlink. Two Way Exercise.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Direct Mail",
    "slug": "direct-mail",
    "logo": "https://zapier-images.imgix.net/storage/services/a090bd8cb3296ab92d268de6cb754f5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Direct Mail is a native OS X app for creating, sending, and tracking email campaigns. Includes tools for building mailing list sign-up forms, autoresponders, dynamic content, and more. Works offline and online, including cloud collaboration features.",
    "category": "Email Newsletters",
    "title": "Direct Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate Direct Mail with SalesBlink",
    "metaDescription": "Integrate Direct Mail and other Email Newsletters Tools with SalesBlink. Two Way Direct Mail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FullStory",
    "slug": "fullstory",
    "logo": "https://zapier-images.imgix.net/storage/services/d3f71174109d1a3b7bfa7c9605fb086b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FullStory is a digital experience analytics platform that uses session replay and other tools to give you valuable insights into your user's experiences.",
    "category": "Analytics",
    "title": "FullStory Integration With Cold Email Sales Tool",
    "h1": "Integrate FullStory with SalesBlink",
    "metaDescription": "Integrate FullStory and other Analytics Tools with SalesBlink. Two Way FullStory Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "PagePixels Screenshots",
    "slug": "pagepixels-screenshots",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2fe046716d4482f387b0217bc480ea41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PagePixels provides free, immediate screenshots, scheduled screenshots, and multi-step screenshots (complete forms, login to websites, etc).",
    "category": "Images & Design",
    "title": "PagePixels Screenshots Integration With Cold Email Sales Tool",
    "h1": "Integrate PagePixels Screenshots with SalesBlink",
    "metaDescription": "Integrate PagePixels Screenshots and other Images & Design Tools with SalesBlink. Two Way PagePixels Screenshots Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "REI Pebble",
    "slug": "rei-pebble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/550de9a5e64f631e7fdc5723e6b0710e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Streamline & simplify your land Investing with REI Pebble.",
    "category": "CRM (Customer Relationship Management)",
    "title": "REI Pebble Integration With Cold Email Sales Tool",
    "h1": "Integrate REI Pebble with SalesBlink",
    "metaDescription": "Integrate REI Pebble and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way REI Pebble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProWorkflow",
    "slug": "proworkflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/54956fc3e43828dec564c958865dae7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Interact with Companies, Contacts, Projects, Tasks, Time, Quotes & Invoices. Take full control of integrating with ProWorkflow to build a better team.",
    "category": "Project Management",
    "title": "ProWorkflow Integration With Cold Email Sales Tool",
    "h1": "Integrate ProWorkflow with SalesBlink",
    "metaDescription": "Integrate ProWorkflow and other Project Management Tools with SalesBlink. Two Way ProWorkflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Real Phone Validation",
    "slug": "real-phone-validation-da92196",
    "logo": "https://zapier-images.imgix.net/storage/developer/7be04852820fefaadf3fed7695e8b077.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RealPhoneValidation provides the data behind the number. Connected or disconnected, landline or cell, carrier ID, do not call, and more.",
    "category": "Phone & SMS",
    "title": "Real Phone Validation Integration With Cold Email Sales Tool",
    "h1": "Integrate Real Phone Validation with SalesBlink",
    "metaDescription": "Integrate Real Phone Validation and other Phone & SMS Tools with SalesBlink. Two Way Real Phone Validation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bonusly",
    "slug": "bonusly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/252505df0c3feb9948625334a9ce14f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bonusly is a fun, personal recognition and rewards program that enriches your company culture, improves employee engagement, and celebrates your core values. With Bonusly, everyone in your organization can publicly recognize everyone else by giving small bonuses that add up to meaningful rewards.",
    "category": "Team Collaboration",
    "title": "Bonusly Integration With Cold Email Sales Tool",
    "h1": "Integrate Bonusly with SalesBlink",
    "metaDescription": "Integrate Bonusly and other Team Collaboration Tools with SalesBlink. Two Way Bonusly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Agent Legend",
    "slug": "agentlegend",
    "logo": "https://zapier-images.imgix.net/storage/developer/457984ad28a216fcea42957bed9107c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agent Legend is an automated, multi-channel marketing tool.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agent Legend Integration With Cold Email Sales Tool",
    "h1": "Integrate Agent Legend with SalesBlink",
    "metaDescription": "Integrate Agent Legend and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agent Legend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Typefully",
    "slug": "typefully",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db869ecd94411f45f7cada00dcfedefb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Typefully is the app for Twitter creators to write, schedule, publish, and analyze threads.",
    "category": "Social Media Accounts",
    "title": "Typefully Integration With Cold Email Sales Tool",
    "h1": "Integrate Typefully with SalesBlink",
    "metaDescription": "Integrate Typefully and other Social Media Accounts Tools with SalesBlink. Two Way Typefully Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upnify",
    "slug": "upnify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/739d4286087c2279f0d0979f9057068c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upnify is a CRM platform designed for sales teams that provides email templates, sales reports, automated lead assignment, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Upnify Integration With Cold Email Sales Tool",
    "h1": "Integrate Upnify with SalesBlink",
    "metaDescription": "Integrate Upnify and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Upnify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KiSSFLOW",
    "slug": "kissflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/1684a25ef034f6baaefaaffca69cb4fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KiSSFLOW is a Business Process Workflow Automation Software. KiSSFLOW empowers normal users to build workflow automation on their own on a self-service basis without requiring sophisticated technical knowledge.  KiSSFLOW is used by department heads of finance, operations and human resources to streamline their functions.",
    "category": "Product Management",
    "title": "KiSSFLOW Integration With Cold Email Sales Tool",
    "h1": "Integrate KiSSFLOW with SalesBlink",
    "metaDescription": "Integrate KiSSFLOW and other Product Management Tools with SalesBlink. Two Way KiSSFLOW Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Prefinery",
    "slug": "prefinery",
    "logo": "https://zapier-images.imgix.net/storage/services/4033788da7a75e1ffaa25ed3f3b389e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "More than just a landing page, Prefinery is a complete beta management launch platform that encourages social sharing and helps you learn from your users.",
    "category": "Marketing Automation",
    "title": "Prefinery Integration With Cold Email Sales Tool",
    "h1": "Integrate Prefinery with SalesBlink",
    "metaDescription": "Integrate Prefinery and other Marketing Automation Tools with SalesBlink. Two Way Prefinery Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Signpost",
    "slug": "signpost",
    "logo": "https://zapier-images.imgix.net/storage/services/0edb127eb1058d23677e71f2f35042dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Signpost is a complete and automated solution to get more and better reviews, win new customers, and increase repeat business.",
    "category": "Marketing",
    "title": "Signpost Integration With Cold Email Sales Tool",
    "h1": "Integrate Signpost with SalesBlink",
    "metaDescription": "Integrate Signpost and other Marketing Tools with SalesBlink. Two Way Signpost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trevor.io",
    "slug": "trevor",
    "logo": "https://zapier-images.imgix.net/storage/services/5f8fe2a1d2f17963069f972f846b05d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trevor.io is a data playground connecting directly to your database to empower your team to query live data, build reports, and power dashboards with or without any coding.",
    "category": "Databases",
    "title": "Trevor.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Trevor.io with SalesBlink",
    "metaDescription": "Integrate Trevor.io and other Databases Tools with SalesBlink. Two Way Trevor.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gender-API.com",
    "slug": "gender-api-ca",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b147dd3b5a5be89ad7817c1358649a74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gender API is an API which returns if a first name (like Mike, Barbara, Sam) or a full name (like Vanessa Campbell) is either male or female.",
    "category": "Analytics",
    "title": "Gender-API.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Gender-API.com with SalesBlink",
    "metaDescription": "Integrate Gender-API.com and other Analytics Tools with SalesBlink. Two Way Gender-API.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GorillaDesk",
    "slug": "gorilladesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2a6487316a747ce5817b2b317a3b869.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GorillaDesk is a field service management system combining scheduling, invoicing, and many more features in one application.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GorillaDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate GorillaDesk with SalesBlink",
    "metaDescription": "Integrate GorillaDesk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GorillaDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Geekbot",
    "slug": "geekbot-da51177",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ba061cb61b47631a1185c421dffadad2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Geekbot runs asynchronous standups, retrospectives, and surveys — enabling easy, effective, and transparent collaboration across time zones",
    "category": "Productivity",
    "title": "Geekbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Geekbot with SalesBlink",
    "metaDescription": "Integrate Geekbot and other Productivity Tools with SalesBlink. Two Way Geekbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Firepoint CRM",
    "slug": "firepoint-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59f558666ccca1d190bb0a87ca43d4c2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Firepoint CRM is a customer relationship management tool designed specifically for real estate teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Firepoint CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Firepoint CRM with SalesBlink",
    "metaDescription": "Integrate Firepoint CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Firepoint CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datananas",
    "slug": "datananas",
    "logo": "https://zapier-images.imgix.net/storage/services/e6381a0eb2c0ac889a49327a5e89a26f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datananas is a Lead Relationship Management SaaS-based platform to send targeted, personalized and automatized emails scenarios to your Leads.",
    "category": "Email",
    "title": "Datananas Integration With Cold Email Sales Tool",
    "h1": "Integrate Datananas with SalesBlink",
    "metaDescription": "Integrate Datananas and other Email Tools with SalesBlink. Two Way Datananas Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "phonetonote",
    "slug": "phonetonote",
    "logo": "https://zapier-images.imgix.net/storage/services/d6f50242150c3c19b83f24dee15dedf9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobile quick capture as a service.",
    "category": "Notes",
    "title": "phonetonote Integration With Cold Email Sales Tool",
    "h1": "Integrate phonetonote with SalesBlink",
    "metaDescription": "Integrate phonetonote and other Notes Tools with SalesBlink. Two Way phonetonote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bookly",
    "slug": "bookly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65b7f03e9afb29a6738fe35938582695.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bookly is a free scheduling plugin for WordPress that allows accepting online bookings on your website. Join more than 40,000 businesses all around the world that have already automated their online booking system!",
    "category": "Scheduling & Booking",
    "title": "Bookly Integration With Cold Email Sales Tool",
    "h1": "Integrate Bookly with SalesBlink",
    "metaDescription": "Integrate Bookly and other Scheduling & Booking Tools with SalesBlink. Two Way Bookly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon EC2",
    "slug": "amazon-ec2",
    "logo": "https://zapier-images.imgix.net/storage/developer/43b918a367ce303f0394b292e3fa8f96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Elastic Compute Cloud (Amazon EC2) is a web service that provides resizable compute capacity in the cloud. It is designed to make web-scale cloud computing easier for developers.",
    "category": "Amazon",
    "title": "Amazon EC2 Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon EC2 with SalesBlink",
    "metaDescription": "Integrate Amazon EC2 and other Amazon Tools with SalesBlink. Two Way Amazon EC2 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moverbase",
    "slug": "moverbase",
    "logo": "https://zapier-images.imgix.net/storage/developer/c6917003bb1f7a7b503c06ac17b74cc0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moverbase is moving company software that manages the entire operations of a moving company. It is 100% cloud-based and works on any computer, tablet or mobile phone.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Moverbase Integration With Cold Email Sales Tool",
    "h1": "Integrate Moverbase with SalesBlink",
    "metaDescription": "Integrate Moverbase and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Moverbase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeBounce",
    "slug": "debounce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9a2122a5bd8c37f86723c5e2e4b982d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeBounce email verification service allows you to validate your email addresses quickly and remove bounce, spam-trap, disposable and deactivated emails from your database.",
    "category": "Email Newsletters",
    "title": "DeBounce Integration With Cold Email Sales Tool",
    "h1": "Integrate DeBounce with SalesBlink",
    "metaDescription": "Integrate DeBounce and other Email Newsletters Tools with SalesBlink. Two Way DeBounce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ChargeOver",
    "slug": "chargeover",
    "logo": "https://zapier-images.imgix.net/storage/services/1e01e86e5cbd4c52d3ed2c84ce276a6a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Go beyond recurring and subscription billing with ChargeOver. Simplify how you manage billing workflows, increase customer satisfaction with easy no-code pages, and stay organized with reports and data!",
    "category": "Payment Processing",
    "title": "ChargeOver Integration With Cold Email Sales Tool",
    "h1": "Integrate ChargeOver with SalesBlink",
    "metaDescription": "Integrate ChargeOver and other Payment Processing Tools with SalesBlink. Two Way ChargeOver Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Smart Sender",
    "slug": "smart-sender",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf170c5ea4d68c63f313f4a88b75b1a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart Sender chat bot platform for Telegram, Viber, WhatsApp, Instagram Direct, Facebook Messenger, Skype, Vkontakte, Kik, Line, WeChat",
    "category": "Marketing",
    "title": "Smart Sender Integration With Cold Email Sales Tool",
    "h1": "Integrate Smart Sender with SalesBlink",
    "metaDescription": "Integrate Smart Sender and other Marketing Tools with SalesBlink. Two Way Smart Sender Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpGuard",
    "slug": "upguard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fed4087e6474130cf1be05cda700b2cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpGuard is a cyber vendor risk management and digital risk protection service platform.",
    "category": "Security & Identity Tools",
    "title": "UpGuard Integration With Cold Email Sales Tool",
    "h1": "Integrate UpGuard with SalesBlink",
    "metaDescription": "Integrate UpGuard and other Security & Identity Tools Tools with SalesBlink. Two Way UpGuard Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "E-junkie",
    "slug": "e-junkie",
    "logo": "https://zapier-images.imgix.net/storage/developer/f1d2fb8cf9f0890a723e14646ca7002f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "E-junkie is a powerful ecommerce solution for shopping carts and buy now buttons that let you sell downloads and tangible goods anywhere on the web.",
    "category": "eCommerce",
    "title": "E-junkie Integration With Cold Email Sales Tool",
    "h1": "Integrate E-junkie with SalesBlink",
    "metaDescription": "Integrate E-junkie and other eCommerce Tools with SalesBlink. Two Way E-junkie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mocean API",
    "slug": "mocean-api",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cfdea3c8cfc262e432923c6d59f9e43.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Micro Ocean Technologies Sdn Bhd (Mocean) was formed by a team of professionals with extensive technical knowledge and experience in the telecommunications industry. Our core expertise is in Mobile Messaging Solutions and Technologies.",
    "category": "Communication",
    "title": "Mocean API Integration With Cold Email Sales Tool",
    "h1": "Integrate Mocean API with SalesBlink",
    "metaDescription": "Integrate Mocean API and other Communication Tools with SalesBlink. Two Way Mocean API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Maximizer CRM",
    "slug": "maximizer",
    "logo": "https://zapier-images.imgix.net/storage/developer/1356c9785782b598be47a5213e7fbd15_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maximizer has fully integrated sales, marketing and customer service modules making it easy to gain deep customer insight and drive retention and loyalty within a single, affordable CRM solution.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Maximizer CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Maximizer CRM with SalesBlink",
    "metaDescription": "Integrate Maximizer CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Maximizer CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LiveAgent",
    "slug": "liveagent",
    "logo": "https://zapier-images.imgix.net/storage/developer/f512533e682a5f25224ceddfd9d69129.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LiveAgent is the only customer support app you will ever need. Helpdesk, Ticketing, Automation, Live Chat, Call Center, FAQs, Support Portal, Facebook and Twitter monitoring... Plus, it works well with others like MailChimp, Highrise, AWeber, Google Analytics and 50+ more",
    "category": "Customer Support",
    "title": "LiveAgent Integration With Cold Email Sales Tool",
    "h1": "Integrate LiveAgent with SalesBlink",
    "metaDescription": "Integrate LiveAgent and other Customer Support Tools with SalesBlink. Two Way LiveAgent Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "DialMyCalls",
    "slug": "dialmycalls",
    "logo": "https://zapier-images.imgix.net/storage/developer/7d19e05ff7b33d9c4d1dd4847e739424.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DialMyCalls provides calling and texting to customers around the world.  We offer reporting for all broadcasts as well as a contact management system.",
    "category": "Phone & SMS",
    "title": "DialMyCalls Integration With Cold Email Sales Tool",
    "h1": "Integrate DialMyCalls with SalesBlink",
    "metaDescription": "Integrate DialMyCalls and other Phone & SMS Tools with SalesBlink. Two Way DialMyCalls Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adversus Dialer",
    "slug": "adversus-dialer",
    "logo": "https://zapier-images.imgix.net/storage/developer/daaf2c690ec16f921b7dc03d85b88914.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A web-based auto dialer solution used by professionals to streamline outbound outreach by phone.",
    "category": "Phone & SMS",
    "title": "Adversus Dialer Integration With Cold Email Sales Tool",
    "h1": "Integrate Adversus Dialer with SalesBlink",
    "metaDescription": "Integrate Adversus Dialer and other Phone & SMS Tools with SalesBlink. Two Way Adversus Dialer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gurucan",
    "slug": "gurucan",
    "logo": "https://zapier-images.imgix.net/storage/services/04a56be3729ef5503921cdb8508c6cc1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gurucan is an all-in-one online course platform.",
    "category": "Education",
    "title": "Gurucan Integration With Cold Email Sales Tool",
    "h1": "Integrate Gurucan with SalesBlink",
    "metaDescription": "Integrate Gurucan and other Education Tools with SalesBlink. Two Way Gurucan Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "FileInvite",
    "slug": "fileinvite",
    "logo": "https://zapier-images.imgix.net/storage/developer/eabce886d4c87107edf4f5b65e579011.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FileInvite helps you collect documentation. Request files from clients, and the work to chase and collect them is fully automated for you.",
    "category": "Documents",
    "title": "FileInvite Integration With Cold Email Sales Tool",
    "h1": "Integrate FileInvite with SalesBlink",
    "metaDescription": "Integrate FileInvite and other Documents Tools with SalesBlink. Two Way FileInvite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phone Validator",
    "slug": "phone-validator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7516b05c20de53d5a1224680f64bbb9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use the official API of the popular phone line-type identification website, phonevalidator.com. Determine if any  North American-based phone number is a landline, cell, VOIP or toll-free.",
    "category": "Phone & SMS",
    "title": "Phone Validator Integration With Cold Email Sales Tool",
    "h1": "Integrate Phone Validator with SalesBlink",
    "metaDescription": "Integrate Phone Validator and other Phone & SMS Tools with SalesBlink. Two Way Phone Validator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ponto",
    "slug": "ponto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/078cc33772e1e2ca99b36d3cedfe22b4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ponto lets companies automate their finances by offering an API on top of 150+ European banks. Ponto is an Isabel Group product licensed in Belgium.",
    "category": "Accounting",
    "title": "Ponto Integration With Cold Email Sales Tool",
    "h1": "Integrate Ponto with SalesBlink",
    "metaDescription": "Integrate Ponto and other Accounting Tools with SalesBlink. Two Way Ponto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crove",
    "slug": "crove",
    "logo": "https://zapier-images.imgix.net/storage/services/9d0a7f594992793b61b5bac1fdbc6635.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crove allows you to create repetitive business documents as intelligent templates that use variables and logical conditions.",
    "category": "Documents",
    "title": "Crove Integration With Cold Email Sales Tool",
    "h1": "Integrate Crove with SalesBlink",
    "metaDescription": "Integrate Crove and other Documents Tools with SalesBlink. Two Way Crove Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Walcu CRM",
    "slug": "walcu-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94f311cfe152c9e8b4dbcabce2dc7cdf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Walcu CRM is a CRM specifically tailored for car dealerships.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Walcu CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Walcu CRM with SalesBlink",
    "metaDescription": "Integrate Walcu CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Walcu CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "timetonic",
    "slug": "timetonic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/77425b232b7d9989ca1f5f92453fd4ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeTonic is a smart team communication & organisation solution for the best managers",
    "category": "Databases",
    "title": "timetonic Integration With Cold Email Sales Tool",
    "h1": "Integrate timetonic with SalesBlink",
    "metaDescription": "Integrate timetonic and other Databases Tools with SalesBlink. Two Way timetonic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TIMIFY",
    "slug": "timify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e17701f22c2c9ca895cf96ca24ccc980_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TIMIFY is an online scheduling and resource management tool for all kind of small businesses and large enterprises.",
    "category": "Calendar",
    "title": "TIMIFY Integration With Cold Email Sales Tool",
    "h1": "Integrate TIMIFY with SalesBlink",
    "metaDescription": "Integrate TIMIFY and other Calendar Tools with SalesBlink. Two Way TIMIFY Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Luna",
    "slug": "luna",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7228536a37d426d4dd5270f93f4c2df7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Luna is a sales tool that helps you get your outbound sales work done in minutes.",
    "category": "Sales & CRM",
    "title": "Luna Integration With Cold Email Sales Tool",
    "h1": "Integrate Luna with SalesBlink",
    "metaDescription": "Integrate Luna and other Sales & CRM Tools with SalesBlink. Two Way Luna Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "My Hours",
    "slug": "my-hours",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c997cbef6a31f36815ba70c54e6d228.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "My Hours is a simple and free time tracker, with insightful reports and invoicing features. Unlimited projects, tasks and team members.",
    "category": "Time Tracking Software",
    "title": "My Hours Integration With Cold Email Sales Tool",
    "h1": "Integrate My Hours with SalesBlink",
    "metaDescription": "Integrate My Hours and other Time Tracking Software Tools with SalesBlink. Two Way My Hours Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Libeo",
    "slug": "libeo",
    "logo": "https://zapier-images.imgix.net/storage/services/da6146f81eceec9b99946630b22194aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Libeo is the first platform to manage and pay supplier invoices in one-click for SMBs and chartered accountants in Europe.",
    "category": "Payment Processing",
    "title": "Libeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Libeo with SalesBlink",
    "metaDescription": "Integrate Libeo and other Payment Processing Tools with SalesBlink. Two Way Libeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BHuman",
    "slug": "bhuman",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/202430b53e16924259389446c77177bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BHuman allows you to send AI generated personalized videos at a scale that look and feel completely real.",
    "category": "Video & Audio",
    "title": "BHuman Integration With Cold Email Sales Tool",
    "h1": "Integrate BHuman with SalesBlink",
    "metaDescription": "Integrate BHuman and other Video & Audio Tools with SalesBlink. Two Way BHuman Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Detrack",
    "slug": "detrack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4204b95b2ad89a822b9c2812b9958212_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Detrack is a live delivery tracking system that offers a robust Proof of Delivery system to last-mile couriers and logistics.",
    "category": "Scheduling & Booking",
    "title": "Detrack Integration With Cold Email Sales Tool",
    "h1": "Integrate Detrack with SalesBlink",
    "metaDescription": "Integrate Detrack and other Scheduling & Booking Tools with SalesBlink. Two Way Detrack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Quip",
    "slug": "quip",
    "logo": "https://zapier-images.imgix.net/storage/services/960998871b7e186706d12638dcdbcc00.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quip is a modern productivity suite that enables you and your team to collaborate on any device. It works across iPhone, iPad, Android phones and tablets, and the desktop web. Quip has a simple and elegant interface that combines documents and messages into a single chat-like “thread” of updates, making collaboration immediate and easy. You can share documents, tables, checklists, and more so multiple people can edit and discuss together in a single, shared workspace.",
    "category": "Documents",
    "title": "Quip Integration With Cold Email Sales Tool",
    "h1": "Integrate Quip with SalesBlink",
    "metaDescription": "Integrate Quip and other Documents Tools with SalesBlink. Two Way Quip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Revnue",
    "slug": "revnue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/32a58750fe0db516b4c12d966dea17c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revnue is a digital contract management platform that provides insights across all your contracts, suppliers, customers, assets, and services.",
    "category": "Documents",
    "title": "Revnue Integration With Cold Email Sales Tool",
    "h1": "Integrate Revnue with SalesBlink",
    "metaDescription": "Integrate Revnue and other Documents Tools with SalesBlink. Two Way Revnue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Site24x7",
    "slug": "site24x7",
    "logo": "https://zapier-images.imgix.net/storage/developer/1f984b72d61979fe5ceacdfa53ef12ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Site24x7 alerts when downtime is detected.",
    "category": "Server Monitoring",
    "title": "Site24x7 Integration With Cold Email Sales Tool",
    "h1": "Integrate Site24x7 with SalesBlink",
    "metaDescription": "Integrate Site24x7 and other Server Monitoring Tools with SalesBlink. Two Way Site24x7 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CoachAccountable",
    "slug": "coachaccountable",
    "logo": "https://zapier-images.imgix.net/storage/developer/64ccbf361bc514e1810896ed7a7bf503_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CoachAccountable is a platform to support and structure coaching relationships between coaches and their clients.",
    "category": "Online Courses",
    "title": "CoachAccountable Integration With Cold Email Sales Tool",
    "h1": "Integrate CoachAccountable with SalesBlink",
    "metaDescription": "Integrate CoachAccountable and other Online Courses Tools with SalesBlink. Two Way CoachAccountable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emailable",
    "slug": "emailable",
    "logo": "https://zapier-images.imgix.net/storage/services/fd916caee7037a2ce42703442cafe645.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emailable is an email checker that helps to improve your deliverability and email marketing campaign ROI.",
    "category": "Marketing Automation",
    "title": "Emailable Integration With Cold Email Sales Tool",
    "h1": "Integrate Emailable with SalesBlink",
    "metaDescription": "Integrate Emailable and other Marketing Automation Tools with SalesBlink. Two Way Emailable Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "eSputnik",
    "slug": "esputnik",
    "logo": "https://zapier-images.imgix.net/storage/developer/97f12c2f3416bd86a4acde1e84175b4f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ESputnik is a marketing automation service made to embody the toughest ideas of enterprise level solutions in very clear and efficient way for everyone.",
    "category": "Marketing Automation",
    "title": "eSputnik Integration With Cold Email Sales Tool",
    "h1": "Integrate eSputnik with SalesBlink",
    "metaDescription": "Integrate eSputnik and other Marketing Automation Tools with SalesBlink. Two Way eSputnik Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vero",
    "slug": "vero",
    "logo": "https://zapier-images.imgix.net/storage/services/079bbda1b74bb2cd4fb2e2884d8d4f92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vero is an event-based messaging platform. Increase conversions and customer satisfaction by sending more targeted emails.",
    "category": "Email",
    "title": "Vero Integration With Cold Email Sales Tool",
    "h1": "Integrate Vero with SalesBlink",
    "metaDescription": "Integrate Vero and other Email Tools with SalesBlink. Two Way Vero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "TrueReview",
    "slug": "truereview",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a179cea7eec92ab6edfd6f9155c9e2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrueReview makes it easy for businesses to request reviews for any website, receive direct customer feedback and improve online reviews!",
    "category": "Marketing Automation",
    "title": "TrueReview Integration With Cold Email Sales Tool",
    "h1": "Integrate TrueReview with SalesBlink",
    "metaDescription": "Integrate TrueReview and other Marketing Automation Tools with SalesBlink. Two Way TrueReview Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Community",
    "slug": "community",
    "logo": "https://zapier-images.imgix.net/storage/services/0ccbf9e0fa46eefe3539c50871cc5ff1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Community is a platform for SMS with Enterprise-grade power and everyday easy of use. Grow your list, send personalized messages, and enrich your Zero Party Data with ease and trust.",
    "category": "Communication",
    "title": "Community Integration With Cold Email Sales Tool",
    "h1": "Integrate Community with SalesBlink",
    "metaDescription": "Integrate Community and other Communication Tools with SalesBlink. Two Way Community Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KonnektiveCRM",
    "slug": "konnektivecrm",
    "logo": "https://zapier-images.imgix.net/storage/developer/3b633e064a1c39e76c9f32f2997f37ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Konnektive CRM is a customer transaction and campaign management platform that helps marketers dominate.",
    "category": "CRM (Customer Relationship Management)",
    "title": "KonnektiveCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate KonnektiveCRM with SalesBlink",
    "metaDescription": "Integrate KonnektiveCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way KonnektiveCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadOwl",
    "slug": "leadowl",
    "logo": "https://zapier-images.imgix.net/storage/developer/71d378454a9e982a2392c90295510b73.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadOwl is a smart phone & web app that allows you and your team to connect funnels, get reminders, and follow up with prospects instantly.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadOwl Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadOwl with SalesBlink",
    "metaDescription": "Integrate LeadOwl and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadOwl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoIPstudio",
    "slug": "voipstudio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5e5e4265b892caae0686f5f82991fcd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoIPstudio is a VoIP phone service that offers businesses of all sizes a scalable communication solution. Our business phone systems are inexpensive, reliable, easy-to-use, offers a full suite of PBX features and is fully integrated for CRM.",
    "category": "Phone & SMS",
    "title": "VoIPstudio Integration With Cold Email Sales Tool",
    "h1": "Integrate VoIPstudio with SalesBlink",
    "metaDescription": "Integrate VoIPstudio and other Phone & SMS Tools with SalesBlink. Two Way VoIPstudio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stonly",
    "slug": "stonly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b0b82e8ecf19f6a50058f2a1e6aff1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stonly is a tool for making interactive guides for customers and users. Deliver personalized, adaptive onboarding, product tours, surveys, and support and guidance throughout your app.",
    "category": "Customer Support",
    "title": "Stonly Integration With Cold Email Sales Tool",
    "h1": "Integrate Stonly with SalesBlink",
    "metaDescription": "Integrate Stonly and other Customer Support Tools with SalesBlink. Two Way Stonly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Visual Visitor",
    "slug": "visual-visitor",
    "logo": "https://zapier-images.imgix.net/storage/services/f073949519ad9020011727214dff1030.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Visual Visitor is a website lead generation system that identifies companies visiting your website. Much like caller-id for your website.",
    "category": "Website Builders",
    "title": "Visual Visitor Integration With Cold Email Sales Tool",
    "h1": "Integrate Visual Visitor with SalesBlink",
    "metaDescription": "Integrate Visual Visitor and other Website Builders Tools with SalesBlink. Two Way Visual Visitor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Keygen",
    "slug": "keygen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/755b9382fa8acb744a9d6e6cdda7da42_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keygen is a key generation service that makes it easy for businesses to license and distribute their software.",
    "category": "Developer Tools",
    "title": "Keygen Integration With Cold Email Sales Tool",
    "h1": "Integrate Keygen with SalesBlink",
    "metaDescription": "Integrate Keygen and other Developer Tools Tools with SalesBlink. Two Way Keygen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Badgr",
    "slug": "badgr",
    "logo": "https://zapier-images.imgix.net/storage/services/2ff513ba98accb606ccbecfeef8f00c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Badgr makes it easy to collect and award digital badges and micro-credentials! Whether you are an educator, employer, or program administrator secure, portable, and shareable digital credentials are a great way to recognize accomplishments while engaging your audience.",
    "category": "Customer Appreciation",
    "title": "Badgr Integration With Cold Email Sales Tool",
    "h1": "Integrate Badgr with SalesBlink",
    "metaDescription": "Integrate Badgr and other Customer Appreciation Tools with SalesBlink. Two Way Badgr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EventTemple",
    "slug": "eventtemple",
    "logo": "https://zapier-images.imgix.net/storage/developer/dacea40d99dc950c2fb4b50f851b1504.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Event Temple is an all in one event management software used by hotels, wedding venues, clubs, restaurants, and conference centers.",
    "category": "Event Management",
    "title": "EventTemple Integration With Cold Email Sales Tool",
    "h1": "Integrate EventTemple with SalesBlink",
    "metaDescription": "Integrate EventTemple and other Event Management Tools with SalesBlink. Two Way EventTemple Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lead Prosper",
    "slug": "lead-prosper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20642981a533fdcdc1b889ce7cfaca70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead Prosper is a Powerful Lead Delivery Software. Incredibly Easy to Use. Analyze, validate, filter, route and manage your leads.",
    "category": "Sales & CRM",
    "title": "Lead Prosper Integration With Cold Email Sales Tool",
    "h1": "Integrate Lead Prosper with SalesBlink",
    "metaDescription": "Integrate Lead Prosper and other Sales & CRM Tools with SalesBlink. Two Way Lead Prosper Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "magicplan",
    "slug": "magicplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aafbad8043092e3a2819097112f3204c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "magicplan lets you instantly create and share floor plans, field reports, and estimates with one easy-to-use application.",
    "category": "Images & Design",
    "title": "magicplan Integration With Cold Email Sales Tool",
    "h1": "Integrate magicplan with SalesBlink",
    "metaDescription": "Integrate magicplan and other Images & Design Tools with SalesBlink. Two Way magicplan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AnswerForce",
    "slug": "answerforce",
    "logo": "https://zapier-images.imgix.net/storage/services/ea4ad2c469a1622c88683737d7696021.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AnswerForce is a live answering service that helps small businesses stay connected with customers during business and off-hours with solutions like customer support, lead capture, appointment setting and more. The AnswerForce app lets you view the transcript of your customer calls, organize internal messages and supports a wide range of integrations with various other business tools.",
    "category": "Customer Support",
    "title": "AnswerForce Integration With Cold Email Sales Tool",
    "h1": "Integrate AnswerForce with SalesBlink",
    "metaDescription": "Integrate AnswerForce and other Customer Support Tools with SalesBlink. Two Way AnswerForce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bento",
    "slug": "bento",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1efa9f095eb61557791e6d1bbc28ce0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bento is an email marketing, tracking, and conversation platform. Powered by data, built for ecommerce and SaaS",
    "category": "Marketing Automation",
    "title": "Bento Integration With Cold Email Sales Tool",
    "h1": "Integrate Bento with SalesBlink",
    "metaDescription": "Integrate Bento and other Marketing Automation Tools with SalesBlink. Two Way Bento Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Halo Service Solutions",
    "slug": "halo-service-solutions",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eab506e95381c7cc2afed31f6aab6b30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Halo Service Solutions is a UK based company, which provides Service Desk, ITSM and PSA software solutions.",
    "category": "Project Management",
    "title": "Halo Service Solutions Integration With Cold Email Sales Tool",
    "h1": "Integrate Halo Service Solutions with SalesBlink",
    "metaDescription": "Integrate Halo Service Solutions and other Project Management Tools with SalesBlink. Two Way Halo Service Solutions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swapcard",
    "slug": "swapcard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/988450b429aa8b1df7ea7ccbfaa8845d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swapcard is an event platform for virtual, hybrid and in-person events.",
    "category": "Event Management",
    "title": "Swapcard Integration With Cold Email Sales Tool",
    "h1": "Integrate Swapcard with SalesBlink",
    "metaDescription": "Integrate Swapcard and other Event Management Tools with SalesBlink. Two Way Swapcard Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CodeREADr",
    "slug": "codereadr",
    "logo": "https://zapier-images.imgix.net/storage/developer/182bcd2555e269e1305cfd4751c82c2b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Codereadr is a barcode scanner app for iOS and Android OS devices to control event access, track attendance and track asset/inventory.",
    "category": "Event Management",
    "title": "CodeREADr Integration With Cold Email Sales Tool",
    "h1": "Integrate CodeREADr with SalesBlink",
    "metaDescription": "Integrate CodeREADr and other Event Management Tools with SalesBlink. Two Way CodeREADr Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Zoho Checkout",
    "slug": "zoho-checkout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e77ffb1ed9804999c5d578b3d5837930.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Checkout allows you to accept recurring payments and one-time payments with personalized payment pages with zero coding.",
    "category": "Payment Processing",
    "title": "Zoho Checkout Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Checkout with SalesBlink",
    "metaDescription": "Integrate Zoho Checkout and other Payment Processing Tools with SalesBlink. Two Way Zoho Checkout Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PostHog",
    "slug": "posthog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5f03149294b4830495f3e3a481bd7ca_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PostHog is robust, open-source product analytics letting you keep your data at bay.",
    "category": "Analytics",
    "title": "PostHog Integration With Cold Email Sales Tool",
    "h1": "Integrate PostHog with SalesBlink",
    "metaDescription": "Integrate PostHog and other Analytics Tools with SalesBlink. Two Way PostHog Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Billetweb",
    "slug": "billetweb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99f4cbcaa5e9d94f260a034c63ad4cac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Billetweb is an event management tool that makes it simple to create, manage, promote, and sell out your event.",
    "category": "Event Management",
    "title": "Billetweb Integration With Cold Email Sales Tool",
    "h1": "Integrate Billetweb with SalesBlink",
    "metaDescription": "Integrate Billetweb and other Event Management Tools with SalesBlink. Two Way Billetweb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendX",
    "slug": "sendx",
    "logo": "https://zapier-images.imgix.net/storage/developer/8bb3fa9e8f34d0a417abd3ac6655d43e_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendX is a lead generation and email marketing automation platform to grow your web business. It is marketing for non-marketers.",
    "category": "Email Newsletters",
    "title": "SendX Integration With Cold Email Sales Tool",
    "h1": "Integrate SendX with SalesBlink",
    "metaDescription": "Integrate SendX and other Email Newsletters Tools with SalesBlink. Two Way SendX Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Evernote Business",
    "slug": "evernote-business",
    "logo": "https://zapier-images.imgix.net/storage/services/a0593c9367f0399018187eed64c9da46_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evernote Business brings the note-taking features of Evernote to your team. Capture and remember ideas and more, then collaborate on them with anyone in your company.\n\nUsing Evernote on your own? Check out Evernote Personal.",
    "category": "Notes",
    "title": "Evernote Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Evernote Business with SalesBlink",
    "metaDescription": "Integrate Evernote Business and other Notes Tools with SalesBlink. Two Way Evernote Business Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Meetime",
    "slug": "meetime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96f8c0c009adfc2e24500c977f6f224e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meetime Inside Sales Platform.\nWith Meetime you can create Workflows for sales prospects. Meetime organizes the activities of your sales development representatives based on the cadences you set up, according to the type of clients you want to reach. We also integrate with your CRM to keep your data always on sync.",
    "category": "Contact Management",
    "title": "Meetime Integration With Cold Email Sales Tool",
    "h1": "Integrate Meetime with SalesBlink",
    "metaDescription": "Integrate Meetime and other Contact Management Tools with SalesBlink. Two Way Meetime Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Perfit",
    "slug": "perfit",
    "logo": "https://zapier-images.imgix.net/storage/developer/8e44faa3635c59a7b2172a4a8c253345.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Perfit lets you send beautiful responsive emails, manage your lists, and track the results of your campaigns.",
    "category": "Email",
    "title": "Perfit Integration With Cold Email Sales Tool",
    "h1": "Integrate Perfit with SalesBlink",
    "metaDescription": "Integrate Perfit and other Email Tools with SalesBlink. Two Way Perfit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Minut Smart Home Sensor",
    "slug": "minut-point",
    "logo": "https://zapier-images.imgix.net/storage/services/ea248b65b95a98759fa9ae47c9abcce2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Protect your home, your neighbors' peace and quiet and your guests' privacy with Minut. Get real-time home analytics on noise, motion, temperature, humidity in your property, from anywhere in the world.",
    "category": "Devices",
    "title": "Minut Smart Home Sensor Integration With Cold Email Sales Tool",
    "h1": "Integrate Minut Smart Home Sensor with SalesBlink",
    "metaDescription": "Integrate Minut Smart Home Sensor and other Devices Tools with SalesBlink. Two Way Minut Smart Home Sensor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Universe",
    "slug": "universe",
    "logo": "https://zapier-images.imgix.net/storage/developer/ade08957b091e82db452f25e34d4f97d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Universe is a DIY ticketing platform that provides everything event organizers need to sell tickets and manage small and medium-sized events.",
    "category": "Event Management",
    "title": "Universe Integration With Cold Email Sales Tool",
    "h1": "Integrate Universe with SalesBlink",
    "metaDescription": "Integrate Universe and other Event Management Tools with SalesBlink. Two Way Universe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myHomeIQ",
    "slug": "myhomeiq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cb16b1b90190632fdf041fcd84fb73b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myHomeIQ connects homeowners with real estate, mortgage, and home service professionals",
    "category": "Marketing",
    "title": "myHomeIQ Integration With Cold Email Sales Tool",
    "h1": "Integrate myHomeIQ with SalesBlink",
    "metaDescription": "Integrate myHomeIQ and other Marketing Tools with SalesBlink. Two Way myHomeIQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CRM in Cloud",
    "slug": "crm-in-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b66f626cdf808901bd642caccbd03285_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CRM in Cloud offers contact management, marketing automation, quote generation, analytics, telephony integration, sales force management with a modern and easy to use interface.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CRM in Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate CRM in Cloud with SalesBlink",
    "metaDescription": "Integrate CRM in Cloud and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CRM in Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Funnelish",
    "slug": "funnelish",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/81cdc0c68525057c8278c7b72ab58f1d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funnelish™ is a platform to build/optimise high performance eCommerce sales funnels.",
    "category": "eCommerce",
    "title": "Funnelish Integration With Cold Email Sales Tool",
    "h1": "Integrate Funnelish with SalesBlink",
    "metaDescription": "Integrate Funnelish and other eCommerce Tools with SalesBlink. Two Way Funnelish Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Spark Hire",
    "slug": "spark-hire",
    "logo": "https://zapier-images.imgix.net/storage/developer/f94efb6516c5fd135ddba0a756f7cbec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spark Hire is a video interviewing platform that helps you hire better candidates in a fraction of the time.",
    "category": "HR Talent & Recruitment",
    "title": "Spark Hire Integration With Cold Email Sales Tool",
    "h1": "Integrate Spark Hire with SalesBlink",
    "metaDescription": "Integrate Spark Hire and other HR Talent & Recruitment Tools with SalesBlink. Two Way Spark Hire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oneflow",
    "slug": "oneflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4c8b308deb0b622613aa20c8b308dbf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Oneflow is a digital platform that automates entire contract process from creating, signing and sending.",
    "category": "Signatures",
    "title": "Oneflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Oneflow with SalesBlink",
    "metaDescription": "Integrate Oneflow and other Signatures Tools with SalesBlink. Two Way Oneflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadGen App",
    "slug": "leadgen",
    "logo": "https://zapier-images.imgix.net/storage/services/7caf7c9ee8c2b8aece77ca53592d640c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadGen App helps you build high-converting online forms for website and landing pages without any coding skills. Create engaging forms that help you attract more quality leads and gather valuable data.",
    "category": "Forms & Surveys",
    "title": "LeadGen App Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadGen App with SalesBlink",
    "metaDescription": "Integrate LeadGen App and other Forms & Surveys Tools with SalesBlink. Two Way LeadGen App Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Swell",
    "slug": "swell",
    "logo": "https://zapier-images.imgix.net/storage/services/9623ef5382b3f26f5297edc4d52c1516.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swell helps your business generate new reviews and generate feedback from customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Swell Integration With Cold Email Sales Tool",
    "h1": "Integrate Swell with SalesBlink",
    "metaDescription": "Integrate Swell and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Swell Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mightycause",
    "slug": "mightycause",
    "logo": "https://zapier-images.imgix.net/storage/developer/3de0850d95d2e57f0603a56edb99b2ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mightycause is a fundraising software for the modern nonprofit.",
    "category": "Fundraising",
    "title": "Mightycause Integration With Cold Email Sales Tool",
    "h1": "Integrate Mightycause with SalesBlink",
    "metaDescription": "Integrate Mightycause and other Fundraising Tools with SalesBlink. Two Way Mightycause Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iubenda",
    "slug": "iubenda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f17d9b4663f3107a732a1a9409db5ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With the iubenda Consent Solution you can record and manage GDPR consent and privacy preferences for each of your users, integrating your consent collection forms, your legal documents and includes a user-friendly dashboard for reviewing consent records of your activities.",
    "category": "Forms & Surveys",
    "title": "iubenda Integration With Cold Email Sales Tool",
    "h1": "Integrate iubenda with SalesBlink",
    "metaDescription": "Integrate iubenda and other Forms & Surveys Tools with SalesBlink. Two Way iubenda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Habitify",
    "slug": "habitify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6082dd934ec908fcd4d63fca8dec4b5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Habitify is a habit tracker that helps you form habits that actually stick. It’s designed to motivate you every day and reward you with beautiful streaks.",
    "category": "Task Management",
    "title": "Habitify Integration With Cold Email Sales Tool",
    "h1": "Integrate Habitify with SalesBlink",
    "metaDescription": "Integrate Habitify and other Task Management Tools with SalesBlink. Two Way Habitify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teamtailor",
    "slug": "teamtailor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/994b4dd6144bac93d38f9efd96add64d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamtailor's intuitive automation helps users manage candidates and the entire recruitment process, as well as schedule and keep track of activities, book interviews and make notes about candidates.",
    "category": "HR Talent & Recruitment",
    "title": "Teamtailor Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamtailor with SalesBlink",
    "metaDescription": "Integrate Teamtailor and other HR Talent & Recruitment Tools with SalesBlink. Two Way Teamtailor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eduzz",
    "slug": "eduzz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0d9d94d7579c548be6060bff2f6ffc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eduzz is a complete solution for selling online courses.",
    "category": "Online Courses",
    "title": "Eduzz Integration With Cold Email Sales Tool",
    "h1": "Integrate Eduzz with SalesBlink",
    "metaDescription": "Integrate Eduzz and other Online Courses Tools with SalesBlink. Two Way Eduzz Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Fulcrum",
    "slug": "fulcrum",
    "logo": "https://zapier-images.imgix.net/storage/developer/74b4174e83b4324850518e9aeafb4cfb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fulcrum is a mobile data collection tool that allows users to easily design custom forms and quickly conduct field data collection on Android and iOS. Fulcrum works in connected and disconnected environments.",
    "category": "Forms & Surveys",
    "title": "Fulcrum Integration With Cold Email Sales Tool",
    "h1": "Integrate Fulcrum with SalesBlink",
    "metaDescription": "Integrate Fulcrum and other Forms & Surveys Tools with SalesBlink. Two Way Fulcrum Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "InvestorFuse",
    "slug": "investorfuse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b827705733c126a501fefc9cf80fe555.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InvestorFuse is a lead conversion system for Real Estate Investors.",
    "category": "Marketing Automation",
    "title": "InvestorFuse Integration With Cold Email Sales Tool",
    "h1": "Integrate InvestorFuse with SalesBlink",
    "metaDescription": "Integrate InvestorFuse and other Marketing Automation Tools with SalesBlink. Two Way InvestorFuse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clickatell",
    "slug": "clickatell",
    "logo": "https://zapier-images.imgix.net/storage/developer/16543a7a6f686214dd2064b3d96e68fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send SMS messages (up to 765 characters) through your Clickatell account to alert mobile users on various triggers OR execute actions based on receiving an SMS in your Clickatell two way account.",
    "category": "Phone & SMS",
    "title": "Clickatell Integration With Cold Email Sales Tool",
    "h1": "Integrate Clickatell with SalesBlink",
    "metaDescription": "Integrate Clickatell and other Phone & SMS Tools with SalesBlink. Two Way Clickatell Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Visitor Queue",
    "slug": "visitor-queue",
    "logo": "https://zapier-images.imgix.net/storage/services/24e3248c41f3ac8906eaa7f5375ab34f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Visitor Queue is a B2B lead generation software that allows you to identify the businesses that have visited your website.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Visitor Queue Integration With Cold Email Sales Tool",
    "h1": "Integrate Visitor Queue with SalesBlink",
    "metaDescription": "Integrate Visitor Queue and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Visitor Queue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SproutVideo",
    "slug": "sproutvideo",
    "logo": "https://zapier-images.imgix.net/storage/services/3e4880dd55db932b9723608152201274.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SproutVideo is a video hosting platform with powerful video marketing, privacy, and analytical tools that take the guesswork out of sharing videos online.",
    "category": "Video & Audio",
    "title": "SproutVideo Integration With Cold Email Sales Tool",
    "h1": "Integrate SproutVideo with SalesBlink",
    "metaDescription": "Integrate SproutVideo and other Video & Audio Tools with SalesBlink. Two Way SproutVideo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PEP Cloud",
    "slug": "pep-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/e0f391f44e91a3903a019d4408f3d457.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PEP Cloud is a sales, estimating, and business management tool for everyone, but especially painting contractors.",
    "category": "Project Management",
    "title": "PEP Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate PEP Cloud with SalesBlink",
    "metaDescription": "Integrate PEP Cloud and other Project Management Tools with SalesBlink. Two Way PEP Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "roomvu",
    "slug": "roomvu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5f468292cdb17ba03c5db8a09aac5be.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Branded Real Estate Videos Localized to Your Market",
    "category": "Marketing Automation",
    "title": "roomvu Integration With Cold Email Sales Tool",
    "h1": "Integrate roomvu with SalesBlink",
    "metaDescription": "Integrate roomvu and other Marketing Automation Tools with SalesBlink. Two Way roomvu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bucket.io",
    "slug": "bucketio",
    "logo": "https://zapier-images.imgix.net/storage/developer/cbe51baee8db292e3888e374a7eec34d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bucket.io is a revolutionary segmentation technology about to transform the way marketers communicate online.",
    "category": "Marketing Automation",
    "title": "Bucket.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Bucket.io with SalesBlink",
    "metaDescription": "Integrate Bucket.io and other Marketing Automation Tools with SalesBlink. Two Way Bucket.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planbox Innovation Central",
    "slug": "planbox-innovation-central",
    "logo": "https://zapier-images.imgix.net/storage/services/11319170478e3335271f948beec93f3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planbox Innovation Central is a best-in-class workflow-driven online portal and tool for your employees, customers, partners and community to collaborate, discover, evaluate, manage, and develop creative ideas and actionable solutions.",
    "category": "Team Collaboration",
    "title": "Planbox Innovation Central Integration With Cold Email Sales Tool",
    "h1": "Integrate Planbox Innovation Central with SalesBlink",
    "metaDescription": "Integrate Planbox Innovation Central and other Team Collaboration Tools with SalesBlink. Two Way Planbox Innovation Central Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vidyard",
    "slug": "vidyard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/382e69646d34ac0c188daa103e9f7f81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vidyard is a video platform that makes it easy to add video into your emails, website, and everywhere else.",
    "category": "Video & Audio",
    "title": "Vidyard Integration With Cold Email Sales Tool",
    "h1": "Integrate Vidyard with SalesBlink",
    "metaDescription": "Integrate Vidyard and other Video & Audio Tools with SalesBlink. Two Way Vidyard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smith.ai Chat",
    "slug": "smithai-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1136e8e14f2c29443032f6561976e96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smith.ai Chat is a live chat + AI chatbot service. Capture more quality leads for your businesses via web chat, text message, and Facebook.",
    "category": "Customer Support",
    "title": "Smith.ai Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Smith.ai Chat with SalesBlink",
    "metaDescription": "Integrate Smith.ai Chat and other Customer Support Tools with SalesBlink. Two Way Smith.ai Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zomentum",
    "slug": "zomentum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4768781cad0b3dc784a330d2ee076fed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zomentum is a sales funnel management tool built for MSPs",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zomentum Integration With Cold Email Sales Tool",
    "h1": "Integrate Zomentum with SalesBlink",
    "metaDescription": "Integrate Zomentum and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zomentum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upsales",
    "slug": "upsales",
    "logo": "https://zapier-images.imgix.net/storage/services/70aaf94f27a63717c991e37218dfb5a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The revenue engine for fast-growing businesses.\n You need to move fast and sell more. You need a revenue engine with CRM and Marketing Automation that can handle it. Unite sales and marketing with one platform for the whole customer journey.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Upsales Integration With Cold Email Sales Tool",
    "h1": "Integrate Upsales with SalesBlink",
    "metaDescription": "Integrate Upsales and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Upsales Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Didacte",
    "slug": "didacte",
    "logo": "https://zapier-images.imgix.net/storage/services/f702e4951c20a639f74367639d5ee072_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Didacte is your strategic partner to create, distribute and sell your courses on an easy-to-use system, both for you and your students.",
    "category": "Online Courses",
    "title": "Didacte Integration With Cold Email Sales Tool",
    "h1": "Integrate Didacte with SalesBlink",
    "metaDescription": "Integrate Didacte and other Online Courses Tools with SalesBlink. Two Way Didacte Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LiveWebinar",
    "slug": "livewebinar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/834a22fa8c4de9f7870573cd96092c46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LiveWebinar is an advanced, customisable meetings & webinar platform used for trainings, presentations and team meetings.",
    "category": "Team Collaboration",
    "title": "LiveWebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate LiveWebinar with SalesBlink",
    "metaDescription": "Integrate LiveWebinar and other Team Collaboration Tools with SalesBlink. Two Way LiveWebinar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Beaconstac",
    "slug": "beaconstac",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ddc85678f8feaa7f9ccdf122c9c70bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beaconstac enables proximity marketing using beacons, geofences, NFC tags, and QR codes",
    "category": "Marketing",
    "title": "Beaconstac Integration With Cold Email Sales Tool",
    "h1": "Integrate Beaconstac with SalesBlink",
    "metaDescription": "Integrate Beaconstac and other Marketing Tools with SalesBlink. Two Way Beaconstac Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Auphonic",
    "slug": "auphonic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13f83d4d60429c623c3251ca02f6a734.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Auphonic is an automatic audio post production web service for podcasts, broadcasters, radio shows, movies, screencasts and more.",
    "category": "Video & Audio",
    "title": "Auphonic Integration With Cold Email Sales Tool",
    "h1": "Integrate Auphonic with SalesBlink",
    "metaDescription": "Integrate Auphonic and other Video & Audio Tools with SalesBlink. Two Way Auphonic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shop-Ware",
    "slug": "shop-ware",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/abf1945db2377b46328cdf4369518dc1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shop-Ware is a cloud-based auto-shop management system to help run your repair shop at its fullest potential.",
    "category": "Proposal & Invoice Management",
    "title": "Shop-Ware Integration With Cold Email Sales Tool",
    "h1": "Integrate Shop-Ware with SalesBlink",
    "metaDescription": "Integrate Shop-Ware and other Proposal & Invoice Management Tools with SalesBlink. Two Way Shop-Ware Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CallHub",
    "slug": "callhub",
    "logo": "https://zapier-images.imgix.net/storage/developer/740eac16a9c4108a1b8b49163a5c805a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallHub sets up complete telephony for marketing and sales teams with no hardware. Voice broadcasting, SMS Marketing and Call Center software for 200+ countries. It has delivered millions of messages for 300+ customers.",
    "category": "Phone & SMS",
    "title": "CallHub Integration With Cold Email Sales Tool",
    "h1": "Integrate CallHub with SalesBlink",
    "metaDescription": "Integrate CallHub and other Phone & SMS Tools with SalesBlink. Two Way CallHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formlets",
    "slug": "formlets",
    "logo": "https://zapier-images.imgix.net/storage/developer/4bc0a2817c0616097662c83cd55b69f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formlets is a powerful easy-to-use online form builder for your website. No coding, easy to integrate, highly secure and many more.",
    "category": "Forms & Surveys",
    "title": "Formlets Integration With Cold Email Sales Tool",
    "h1": "Integrate Formlets with SalesBlink",
    "metaDescription": "Integrate Formlets and other Forms & Surveys Tools with SalesBlink. Two Way Formlets Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "MiiTel",
    "slug": "miitel",
    "logo": "https://zapier-images.imgix.net/storage/services/3488efb33fdbfc89b7b5a2ab8ecf710e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MiiTel is an AI-powered IP phone.  With MiiTel, you will be to accomplish the following:  \"Increase your sales\", \"Achieve self-coaching\", and \"Can make calls from home\".",
    "category": "CRM (Customer Relationship Management)",
    "title": "MiiTel Integration With Cold Email Sales Tool",
    "h1": "Integrate MiiTel with SalesBlink",
    "metaDescription": "Integrate MiiTel and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MiiTel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Certifier",
    "slug": "certifier",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ece08ad380c4acbead3cd54451266a57_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Professional Certificate Maker - create and send certificates",
    "category": "Online Courses",
    "title": "Certifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Certifier with SalesBlink",
    "metaDescription": "Integrate Certifier and other Online Courses Tools with SalesBlink. Two Way Certifier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OwnerRez",
    "slug": "ownerrez",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ac76593b12339afd9b44e8ef3d16e65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OwnerRez is internationally recognized as a leader in the vacation rental industry for channel management, CRM, PM, accounting, messaging and websites.",
    "category": "Scheduling & Booking",
    "title": "OwnerRez Integration With Cold Email Sales Tool",
    "h1": "Integrate OwnerRez with SalesBlink",
    "metaDescription": "Integrate OwnerRez and other Scheduling & Booking Tools with SalesBlink. Two Way OwnerRez Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiver",
    "slug": "hiver",
    "logo": "https://zapier-images.imgix.net/storage/services/d4e157292465eb90d5d2fe9735986eb5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hiver is a Gmail-based helpdesk that helps customer service teams easily access, assign and track customer emails on shared mailboxes.",
    "category": "Email",
    "title": "Hiver Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiver with SalesBlink",
    "metaDescription": "Integrate Hiver and other Email Tools with SalesBlink. Two Way Hiver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChangeTower",
    "slug": "changetower",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed106d012cc78c15d1fd497da44eee90_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChangeTower is a website monitoring and archiving platform that detects and tracks critical changes and sends you detailed alerts. Monitor for visual, content and code changes. Stay effortlessly informed about about updates that matter to you across the web.",
    "category": "Notifications",
    "title": "ChangeTower Integration With Cold Email Sales Tool",
    "h1": "Integrate ChangeTower with SalesBlink",
    "metaDescription": "Integrate ChangeTower and other Notifications Tools with SalesBlink. Two Way ChangeTower Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BoondManager",
    "slug": "boondmanager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b220812901d915e551892a54b9d5635a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BoondManager is an ERP made for Consultancy and Services company. It helps you manage your recruitment, HR, CRM, Projects, Timesheets, Expenses, Leaves Balance and Billing!",
    "category": "CRM (Customer Relationship Management)",
    "title": "BoondManager Integration With Cold Email Sales Tool",
    "h1": "Integrate BoondManager with SalesBlink",
    "metaDescription": "Integrate BoondManager and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BoondManager Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SmartMatchApp",
    "slug": "smartmatchapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57a48ccaab2d79f9836a2c92cad130db_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartMatchApp is a matchmaking and membership CRM to organize and optimize the process of matching, introducing, and connecting people.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SmartMatchApp Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartMatchApp with SalesBlink",
    "metaDescription": "Integrate SmartMatchApp and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SmartMatchApp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jenkins",
    "slug": "jenkins",
    "logo": "https://zapier-images.imgix.net/storage/developer/c152d4d93c7fce4a651f5f31cdb1c394.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "jenkins is a continuous integration server that monitors executions of repeated jobs, such as building a software project or jobs run by cron.",
    "category": "Developer Tools",
    "title": "Jenkins Integration With Cold Email Sales Tool",
    "h1": "Integrate Jenkins with SalesBlink",
    "metaDescription": "Integrate Jenkins and other Developer Tools Tools with SalesBlink. Two Way Jenkins Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Scrive",
    "slug": "scrive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57b1df460e64ca85da32dcb4f4ae8374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scrive is a eSigning service that lets you sign anywhere and anytime to speed up and streamline your contract flows.",
    "category": "Signatures",
    "title": "Scrive Integration With Cold Email Sales Tool",
    "h1": "Integrate Scrive with SalesBlink",
    "metaDescription": "Integrate Scrive and other Signatures Tools with SalesBlink. Two Way Scrive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hot Prospector",
    "slug": "hot-prospector",
    "logo": "https://zapier-images.imgix.net/storage/services/18fa9deea16d06d1d61b25d3b6698195.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hot Prospector is ideal for anyone who has an existing database of past customers or is looking to bring in a rush of brand new prospects.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Hot Prospector Integration With Cold Email Sales Tool",
    "h1": "Integrate Hot Prospector with SalesBlink",
    "metaDescription": "Integrate Hot Prospector and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Hot Prospector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sortd for Gmail",
    "slug": "sortd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c23a79eb4609b4eff5d61a98dad8e70f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sortd allows you to organise email & teamwork in Gmail. Collaborate around Tasks, Emails, Meetings and Projects is made easy with customised boards and workflows",
    "category": "Team Collaboration",
    "title": "Sortd for Gmail Integration With Cold Email Sales Tool",
    "h1": "Integrate Sortd for Gmail with SalesBlink",
    "metaDescription": "Integrate Sortd for Gmail and other Team Collaboration Tools with SalesBlink. Two Way Sortd for Gmail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MoreApp",
    "slug": "moreapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05942fb06f4b2956818f877665ef1031.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MoreApp is a tool to automate work processes. Save time with Digital Work Orders, Inspections, Reports, and more!",
    "category": "Forms & Surveys",
    "title": "MoreApp Integration With Cold Email Sales Tool",
    "h1": "Integrate MoreApp with SalesBlink",
    "metaDescription": "Integrate MoreApp and other Forms & Surveys Tools with SalesBlink. Two Way MoreApp Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "WHMCS",
    "slug": "whmcs",
    "logo": "https://zapier-images.imgix.net/storage/developer/371d711a541c3ac05f030f870a3c5aec.gif?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WHMCS is a complete client management package, supporting billing & support solutions for any online business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "WHMCS Integration With Cold Email Sales Tool",
    "h1": "Integrate WHMCS with SalesBlink",
    "metaDescription": "Integrate WHMCS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way WHMCS Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Local Line",
    "slug": "local-line",
    "logo": "https://zapier-images.imgix.net/storage/services/22db8bef86366bb5229b24f40aa9e98e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Local Line is an eCommerce tool for farmers. Connect with your customers online, on the farm, and at the market.",
    "category": "Commerce",
    "title": "Local Line Integration With Cold Email Sales Tool",
    "h1": "Integrate Local Line with SalesBlink",
    "metaDescription": "Integrate Local Line and other Commerce Tools with SalesBlink. Two Way Local Line Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Temi",
    "slug": "temi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f6918c8748f3f18544150e837f5a071.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Temi is an audio transcription service that uses computers to transcribe English audio or video into text. We also offer rich editing tools that you can use to annotate and edit your transcripts.",
    "category": "Transcription",
    "title": "Temi Integration With Cold Email Sales Tool",
    "h1": "Integrate Temi with SalesBlink",
    "metaDescription": "Integrate Temi and other Transcription Tools with SalesBlink. Two Way Temi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Bloobirds",
    "slug": "bloobirds",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd56dc47a810bfe2ca84e1c604da84a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bloobirds is a Lead Management System that helps Sales team to apply an effective sales methodology.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bloobirds Integration With Cold Email Sales Tool",
    "h1": "Integrate Bloobirds with SalesBlink",
    "metaDescription": "Integrate Bloobirds and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bloobirds Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Manifestly Checklists",
    "slug": "manifestly",
    "logo": "https://zapier-images.imgix.net/storage/developer/5c6f159850671513cf8b6f3285649cbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manifestly Checklists software improves collaboration and accountability in your organization's recurring processes.",
    "category": "Product Management",
    "title": "Manifestly Checklists Integration With Cold Email Sales Tool",
    "h1": "Integrate Manifestly Checklists with SalesBlink",
    "metaDescription": "Integrate Manifestly Checklists and other Product Management Tools with SalesBlink. Two Way Manifestly Checklists Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iZooto",
    "slug": "izooto",
    "logo": "https://zapier-images.imgix.net/storage/developer/ffab4fe644f42f81366ffbebdfe6cc0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IZooto is a web push notification platform which enables website owners to re-engage and re-target visitors through web push notifications.",
    "category": "Notifications",
    "title": "iZooto Integration With Cold Email Sales Tool",
    "h1": "Integrate iZooto with SalesBlink",
    "metaDescription": "Integrate iZooto and other Notifications Tools with SalesBlink. Two Way iZooto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sprout",
    "slug": "sprout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d24035d258ad389b7728871a6aff24d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sprout is a marketing CRM that allows you to manage your contacts, integrations, email & sms marketing and all your automation workflows through a single easy to use platform.",
    "category": "Marketing",
    "title": "Sprout Integration With Cold Email Sales Tool",
    "h1": "Integrate Sprout with SalesBlink",
    "metaDescription": "Integrate Sprout and other Marketing Tools with SalesBlink. Two Way Sprout Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sprig",
    "slug": "sprig",
    "logo": "https://zapier-images.imgix.net/storage/services/450f076c58e20aa43b17790bbd848f45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ensure user research happens early and often throughout the product development process with Sprig's contextual research platform.",
    "category": "Forms & Surveys",
    "title": "Sprig Integration With Cold Email Sales Tool",
    "h1": "Integrate Sprig with SalesBlink",
    "metaDescription": "Integrate Sprig and other Forms & Surveys Tools with SalesBlink. Two Way Sprig Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AnnounceKit",
    "slug": "announcekit",
    "logo": "https://zapier-images.imgix.net/storage/services/ba51ae5a3d969cc11cd1aa173f801a5c.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AnnounceKit is an app that allows you to create beautiful newsfeeds and widgets for your website.",
    "category": "Product Management",
    "title": "AnnounceKit Integration With Cold Email Sales Tool",
    "h1": "Integrate AnnounceKit with SalesBlink",
    "metaDescription": "Integrate AnnounceKit and other Product Management Tools with SalesBlink. Two Way AnnounceKit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TrainerCentral",
    "slug": "trainercentral",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f7ee625968b44037ec1efa0d3f4a892.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrainerCentral is an all-in-one training platform to build a successful business by inspiring your learners from across the globe.",
    "category": "Online Courses",
    "title": "TrainerCentral Integration With Cold Email Sales Tool",
    "h1": "Integrate TrainerCentral with SalesBlink",
    "metaDescription": "Integrate TrainerCentral and other Online Courses Tools with SalesBlink. Two Way TrainerCentral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon CloudWatch",
    "slug": "amazon-cloudwatch",
    "logo": "https://zapier-images.imgix.net/storage/developer/da5df087b48e798fca1006d085246bad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon CloudWatch is a monitoring service for AWS cloud resources and the applications you run on AWS.",
    "category": "Amazon",
    "title": "Amazon CloudWatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon CloudWatch with SalesBlink",
    "metaDescription": "Integrate Amazon CloudWatch and other Amazon Tools with SalesBlink. Two Way Amazon CloudWatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mindee OCR",
    "slug": "mindee-ocr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d47e0944fda7d1c2283d849f617daa9_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mindee OCR extracts data from the documents you use most. Included in this package are Receipt, Invoice and Passport text extraction APIs.",
    "category": "Accounting",
    "title": "Mindee OCR Integration With Cold Email Sales Tool",
    "h1": "Integrate Mindee OCR with SalesBlink",
    "metaDescription": "Integrate Mindee OCR and other Accounting Tools with SalesBlink. Two Way Mindee OCR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "inFlow Inventory",
    "slug": "inflow-inventory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df222572a3ac8468c7e116df78628c5c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get an unfair advantage with inFlow Inventory management software. A complete system to run your small business used by 1000's.",
    "category": "eCommerce",
    "title": "inFlow Inventory Integration With Cold Email Sales Tool",
    "h1": "Integrate inFlow Inventory with SalesBlink",
    "metaDescription": "Integrate inFlow Inventory and other eCommerce Tools with SalesBlink. Two Way inFlow Inventory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContentCal",
    "slug": "contentcal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/10d04c3402b70fed8f6da5cf4d8fa5d8.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContentCal is a visual calendar for planning and auto-publishing your social media content.",
    "category": "Social Media Marketing",
    "title": "ContentCal Integration With Cold Email Sales Tool",
    "h1": "Integrate ContentCal with SalesBlink",
    "metaDescription": "Integrate ContentCal and other Social Media Marketing Tools with SalesBlink. Two Way ContentCal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Serene",
    "slug": "serene",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/01ba13c992f09d1796df106bfbb09fa6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Block distracting websites & apps, organised your day around one goal that matters, and focus on what matters with Serene, the macOS app for laser-focus.",
    "category": "Time Tracking Software",
    "title": "Serene Integration With Cold Email Sales Tool",
    "h1": "Integrate Serene with SalesBlink",
    "metaDescription": "Integrate Serene and other Time Tracking Software Tools with SalesBlink. Two Way Serene Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Occasion",
    "slug": "occasion",
    "logo": "https://zapier-images.imgix.net/storage/developer/78e4af73f9ec303c887c88f1cf7783f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Occasion is a marketing and scheduling platform that allows group experience providers to acquire and re-engage customers.",
    "category": "Scheduling & Booking",
    "title": "Occasion Integration With Cold Email Sales Tool",
    "h1": "Integrate Occasion with SalesBlink",
    "metaDescription": "Integrate Occasion and other Scheduling & Booking Tools with SalesBlink. Two Way Occasion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verified Reviews",
    "slug": "verified-reviews",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5a18734067e9d34bd976a2644b3984b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verified Reviews is a solution specialising in gathering customer reviews, allowing customers to provide opinions of products they have bought and their overall shopping experience.",
    "category": "eCommerce",
    "title": "Verified Reviews Integration With Cold Email Sales Tool",
    "h1": "Integrate Verified Reviews with SalesBlink",
    "metaDescription": "Integrate Verified Reviews and other eCommerce Tools with SalesBlink. Two Way Verified Reviews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upwork",
    "slug": "upwork",
    "logo": "https://zapier-images.imgix.net/storage/services/e87d488951a24d798954f66a3be57993.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upwork is a global freelancing platform where businesses and independent professionals connect and collaborate remotely.",
    "category": "Team Collaboration",
    "title": "Upwork Integration With Cold Email Sales Tool",
    "h1": "Integrate Upwork with SalesBlink",
    "metaDescription": "Integrate Upwork and other Team Collaboration Tools with SalesBlink. Two Way Upwork Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aha!",
    "slug": "aha",
    "logo": "https://zapier-images.imgix.net/storage/developer/75ff4bc16572f24922e0bbf6554d4821.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aha! Roadmaps is the complete product management suite - set strategy, capture ideas, score features, and share visual plans. It includes Aha! Ideas Essentials for crowdsourcing feedback. More than 5,000 companies trust our cloud-based software to build lovable products and be happy doing it.",
    "category": "Product Management",
    "title": "Aha! Integration With Cold Email Sales Tool",
    "h1": "Integrate Aha! with SalesBlink",
    "metaDescription": "Integrate Aha! and other Product Management Tools with SalesBlink. Two Way Aha! Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Kunversion+",
    "slug": "kunversion",
    "logo": "https://zapier-images.imgix.net/storage/developer/0740c46d39b5d85fd3fde98901c83ec3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kunversion+ is a full CRM suite of tools that helps generate and manage real estate leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kunversion+ Integration With Cold Email Sales Tool",
    "h1": "Integrate Kunversion+ with SalesBlink",
    "metaDescription": "Integrate Kunversion+ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kunversion+ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tadabase",
    "slug": "tadabase",
    "logo": "https://zapier-images.imgix.net/storage/services/fa0483239a0972285ab994a49860d381.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tadabase empowers every business to automate their workflow and streamline operations on a single platform without writing a single line of code.",
    "category": "App Builder",
    "title": "Tadabase Integration With Cold Email Sales Tool",
    "h1": "Integrate Tadabase with SalesBlink",
    "metaDescription": "Integrate Tadabase and other App Builder Tools with SalesBlink. Two Way Tadabase Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CandidateZip Resume/Job Parser",
    "slug": "candidatezip",
    "logo": "https://zapier-images.imgix.net/storage/developer/6db0b2d28937bd1c5270cb70c05ec97a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CandidateZip is cloud based resume/CV or Job Parser which converts resumes/CV and jobs to standard fields. This extract contact/location, experience, education, salary, skills, and others fields from resumes/CV.",
    "category": "HR Talent & Recruitment",
    "title": "CandidateZip Resume/Job Parser Integration With Cold Email Sales Tool",
    "h1": "Integrate CandidateZip Resume/Job Parser with SalesBlink",
    "metaDescription": "Integrate CandidateZip Resume/Job Parser and other HR Talent & Recruitment Tools with SalesBlink. Two Way CandidateZip Resume/Job Parser Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workbooks CRM",
    "slug": "workbooks",
    "logo": "https://zapier-images.imgix.net/storage/services/fb20f68c050d74bab27afa09e7f868a9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workbooks CRM helps businesses run better by joining up marketing, sales, finance and service teams with a single cloud-based solution.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Workbooks CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Workbooks CRM with SalesBlink",
    "metaDescription": "Integrate Workbooks CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Workbooks CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spreadsheet.com",
    "slug": "spreadsheetcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1ead3136bfb5730876a2e428224670a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spreadsheet.com is the all-in-one spreadsheet for teams",
    "category": "Project Management",
    "title": "Spreadsheet.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Spreadsheet.com with SalesBlink",
    "metaDescription": "Integrate Spreadsheet.com and other Project Management Tools with SalesBlink. Two Way Spreadsheet.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FieldPulse",
    "slug": "fieldpulse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bed09c889f17582a2306a82da5f00333.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FieldPulse is service business and contractor software that serves as the hub for managing your business in the office and on the go.",
    "category": "Project Management",
    "title": "FieldPulse Integration With Cold Email Sales Tool",
    "h1": "Integrate FieldPulse with SalesBlink",
    "metaDescription": "Integrate FieldPulse and other Project Management Tools with SalesBlink. Two Way FieldPulse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EMnify",
    "slug": "emnify",
    "logo": "https://zapier-images.imgix.net/storage/services/92ab660ee071f0c8512ccab3a0f2f986.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EMnify is a cloud communication platform for IoT that transforms complex global cellular connectivity into an easy to consume cloud resource.",
    "category": "Internet of Things",
    "title": "EMnify Integration With Cold Email Sales Tool",
    "h1": "Integrate EMnify with SalesBlink",
    "metaDescription": "Integrate EMnify and other Internet of Things Tools with SalesBlink. Two Way EMnify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wisernotify",
    "slug": "wisernotify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a69baf04c489be664441ff5ed3ee731d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wisernotify is a social proof & FOMO marketing app that allows you to automate visitors' actions into social proof notifications on your website that build trust and increase conversions rate.",
    "category": "Notifications",
    "title": "Wisernotify Integration With Cold Email Sales Tool",
    "h1": "Integrate Wisernotify with SalesBlink",
    "metaDescription": "Integrate Wisernotify and other Notifications Tools with SalesBlink. Two Way Wisernotify Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Qwary",
    "slug": "qwary",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c446101da8e69f6dba02e95f20d65e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online survey product to create stunning surveys, generate actionable insights.",
    "category": "Forms & Surveys",
    "title": "Qwary Integration With Cold Email Sales Tool",
    "h1": "Integrate Qwary with SalesBlink",
    "metaDescription": "Integrate Qwary and other Forms & Surveys Tools with SalesBlink. Two Way Qwary Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "PlatoForms",
    "slug": "platoforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e2fb6989ad6947053214ac259c3a3a23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlatoForms converts any PDF to an online web form to let your clients fill out PDF forms in the browser.",
    "category": "Forms & Surveys",
    "title": "PlatoForms Integration With Cold Email Sales Tool",
    "h1": "Integrate PlatoForms with SalesBlink",
    "metaDescription": "Integrate PlatoForms and other Forms & Surveys Tools with SalesBlink. Two Way PlatoForms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Details Flowers",
    "slug": "details-flowers",
    "logo": "https://zapier-images.imgix.net/storage/services/1e4728410ab334e6f4280575d93c34b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Details Flowers is an all-in-one solution for florists and event designers to manage their events.",
    "category": "Event Management",
    "title": "Details Flowers Integration With Cold Email Sales Tool",
    "h1": "Integrate Details Flowers with SalesBlink",
    "metaDescription": "Integrate Details Flowers and other Event Management Tools with SalesBlink. Two Way Details Flowers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clearout",
    "slug": "clearout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5a8dfe1e68c5479daafaec64524b9af_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clearout is an email validation and verification service that helps you eliminate bounces, prevent fraudulent signups, and protect your brand's reputation.",
    "category": "Email",
    "title": "Clearout Integration With Cold Email Sales Tool",
    "h1": "Integrate Clearout with SalesBlink",
    "metaDescription": "Integrate Clearout and other Email Tools with SalesBlink. Two Way Clearout Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Jasper",
    "slug": "jasper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43d24f9018f727b6dcf73557cda6b5a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jasper is the leader in generative AI for marketing teams by helping them create quality content for blog posts, social media posts, web pages, and more.",
    "category": "AI Tools",
    "title": "Jasper Integration With Cold Email Sales Tool",
    "h1": "Integrate Jasper with SalesBlink",
    "metaDescription": "Integrate Jasper and other AI Tools Tools with SalesBlink. Two Way Jasper Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Relate",
    "slug": "relate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e80e5b18a4a9681e7058ee9f397446c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Relate is a simple collaborative sales and CRM software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Relate Integration With Cold Email Sales Tool",
    "h1": "Integrate Relate with SalesBlink",
    "metaDescription": "Integrate Relate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Relate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AgentFire",
    "slug": "agentfire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/56c8eff67bea1e0dda20becac0126834.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AgentFire is a revolutionary real estate website and marketing platform that helps agents maximize digital opportunities.",
    "category": "Marketing Automation",
    "title": "AgentFire Integration With Cold Email Sales Tool",
    "h1": "Integrate AgentFire with SalesBlink",
    "metaDescription": "Integrate AgentFire and other Marketing Automation Tools with SalesBlink. Two Way AgentFire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PostGrid Print & Mail",
    "slug": "postgrid-print-mail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/044fc7e08b643df893c78cd190f1360c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PostGrid's Print & Mail REST API allows you to send personalized letters, checks, or postcards on demand and at scale.",
    "category": "Marketing Automation",
    "title": "PostGrid Print & Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate PostGrid Print & Mail with SalesBlink",
    "metaDescription": "Integrate PostGrid Print & Mail and other Marketing Automation Tools with SalesBlink. Two Way PostGrid Print & Mail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadDelta",
    "slug": "leaddelta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9eacad3ecda4e7d1515449805ac3e08e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadDelta is a productivity app that helps you to organize your LinkedIn connections better and save time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadDelta Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadDelta with SalesBlink",
    "metaDescription": "Integrate LeadDelta and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadDelta Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Project.co",
    "slug": "projectco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf069c873f5312d7f6053636ce509ce6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Project.co is a project management software tool for service businesses. Collaborate with your clients and team so all your project information is in one place.",
    "category": "Project Management",
    "title": "Project.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Project.co with SalesBlink",
    "metaDescription": "Integrate Project.co and other Project Management Tools with SalesBlink. Two Way Project.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gmelius",
    "slug": "gmelius",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c76dfac7c24bc723aa40abb5abab6743.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gmelius is a team collaboration platform to share emails, manage projects, and automate daily workflows right from Gmail and your other favorite apps.",
    "category": "Team Collaboration",
    "title": "Gmelius Integration With Cold Email Sales Tool",
    "h1": "Integrate Gmelius with SalesBlink",
    "metaDescription": "Integrate Gmelius and other Team Collaboration Tools with SalesBlink. Two Way Gmelius Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Refiner",
    "slug": "refiner",
    "logo": "https://zapier-images.imgix.net/storage/services/e27ae3ed73d672fc0392352ea7758ce8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Refiner is a customer survey tool for data driven SaaS teams.",
    "category": "Forms & Surveys",
    "title": "Refiner Integration With Cold Email Sales Tool",
    "h1": "Integrate Refiner with SalesBlink",
    "metaDescription": "Integrate Refiner and other Forms & Surveys Tools with SalesBlink. Two Way Refiner Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Docamatic",
    "slug": "docamatic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/835df87d8896481ba68fb8b53fc279b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docamatic allows you to generate PDF documents.",
    "category": "Documents",
    "title": "Docamatic Integration With Cold Email Sales Tool",
    "h1": "Integrate Docamatic with SalesBlink",
    "metaDescription": "Integrate Docamatic and other Documents Tools with SalesBlink. Two Way Docamatic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kayako",
    "slug": "kayako",
    "logo": "https://zapier-images.imgix.net/storage/developer/6239ab6be46821571c99924e371659d2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kayako offers a multi-channel (email, social media, and live chat) customer support platform with customizable engagement workflows.",
    "category": "Customer Support",
    "title": "Kayako Integration With Cold Email Sales Tool",
    "h1": "Integrate Kayako with SalesBlink",
    "metaDescription": "Integrate Kayako and other Customer Support Tools with SalesBlink. Two Way Kayako Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "LearningSuite",
    "slug": "learningsuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94dbfe43eb561e9eb7d0e2cbe0b7bc24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learningsuite is an E-Learning Plattform",
    "category": "Education",
    "title": "LearningSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate LearningSuite with SalesBlink",
    "metaDescription": "Integrate LearningSuite and other Education Tools with SalesBlink. Two Way LearningSuite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Ora",
    "slug": "ora",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/571356a20c5b8c8c3a5a75857548a763.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ora is an agile task management and team collaboration software.",
    "category": "Project Management",
    "title": "Ora Integration With Cold Email Sales Tool",
    "h1": "Integrate Ora with SalesBlink",
    "metaDescription": "Integrate Ora and other Project Management Tools with SalesBlink. Two Way Ora Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Surefire Local Platform",
    "slug": "surefire-local-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/44c0142e713a5470409769383c10f05c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one marketing platform helping local businesses manage their leads, online reviews, business listings ads and local search results all in one place.",
    "category": "Marketing",
    "title": "Surefire Local Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Surefire Local Platform with SalesBlink",
    "metaDescription": "Integrate Surefire Local Platform and other Marketing Tools with SalesBlink. Two Way Surefire Local Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "freee",
    "slug": "freee",
    "logo": "https://zapier-images.imgix.net/storage/services/0c53395f38d6bfa57cb18c0a70d0f94d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "freee provides cloud-based accounting & HR software for SMBs in Japan to automate and simplify back-office tasks.",
    "category": "Accounting",
    "title": "freee Integration With Cold Email Sales Tool",
    "h1": "Integrate freee with SalesBlink",
    "metaDescription": "Integrate freee and other Accounting Tools with SalesBlink. Two Way freee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sera Systems",
    "slug": "sera-systems",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e195d8716c25c04f3636b6c229a638bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sera is the new era of field services software that includes automated dispatch scheduling, improved efficiency while providing a delightful experience to business owners, technicians and customers service teams.",
    "category": "Productivity",
    "title": "Sera Systems Integration With Cold Email Sales Tool",
    "h1": "Integrate Sera Systems with SalesBlink",
    "metaDescription": "Integrate Sera Systems and other Productivity Tools with SalesBlink. Two Way Sera Systems Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoodealio",
    "slug": "zoodealio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f88d2e8f1a42f0fde7928c3c571f42ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoodealio is marketing automation where every agent is an iBuyer.",
    "category": "Marketing Automation",
    "title": "Zoodealio Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoodealio with SalesBlink",
    "metaDescription": "Integrate Zoodealio and other Marketing Automation Tools with SalesBlink. Two Way Zoodealio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lead Generated",
    "slug": "lead-generated",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6f03cb4daf9982b0f7b3c4758120048.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead Generated is an app to submit and manage leads at https://app.leadgenerated.com",
    "category": "CRM (Customer Relationship Management)",
    "title": "Lead Generated Integration With Cold Email Sales Tool",
    "h1": "Integrate Lead Generated with SalesBlink",
    "metaDescription": "Integrate Lead Generated and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Lead Generated Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ometria",
    "slug": "ometria",
    "logo": "https://zapier-images.imgix.net/storage/services/e7033051705cc1dd2254049f9bde456d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ometria is a customer marketing platform built for retailers.",
    "category": "Marketing Automation",
    "title": "Ometria Integration With Cold Email Sales Tool",
    "h1": "Integrate Ometria with SalesBlink",
    "metaDescription": "Integrate Ometria and other Marketing Automation Tools with SalesBlink. Two Way Ometria Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrackingTime",
    "slug": "trackingtime",
    "logo": "https://zapier-images.imgix.net/storage/services/4dd3492d68f53fc4ac381c565173dbea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Time Tracking app that helps to manage projects, track working times and measure productivity.",
    "category": "Time Tracking Software",
    "title": "TrackingTime Integration With Cold Email Sales Tool",
    "h1": "Integrate TrackingTime with SalesBlink",
    "metaDescription": "Integrate TrackingTime and other Time Tracking Software Tools with SalesBlink. Two Way TrackingTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Transloadit",
    "slug": "transloadit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f417a68db7d8ff08ad3515f31344b210.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transloadit is a file encoding service that helps you handle file uploads, resize, crop and watermark images, make GIFs, transcode your videos, extract thumbnails, generate audio waveforms, and so much more. In short, Transloadit is a Swiss Army Knife for your files.",
    "category": "Images & Design",
    "title": "Transloadit Integration With Cold Email Sales Tool",
    "h1": "Integrate Transloadit with SalesBlink",
    "metaDescription": "Integrate Transloadit and other Images & Design Tools with SalesBlink. Two Way Transloadit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Mojo Helpdesk",
    "slug": "mojo-helpdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/69f2cad9dfdeeed26b49f0bb747224a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Help desk software that lets you centralize, assign, and track customer support requests as well as internal help desk tickets.",
    "category": "Customer Support",
    "title": "Mojo Helpdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Mojo Helpdesk with SalesBlink",
    "metaDescription": "Integrate Mojo Helpdesk and other Customer Support Tools with SalesBlink. Two Way Mojo Helpdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moxie",
    "slug": "moxie",
    "logo": "https://zapier-images.imgix.net/storage/services/9be96b4093dabee3a3c6cdede7e015f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moxie is a single digital workspace with all the tools needed to start, manage and grow a freelancing business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Moxie Integration With Cold Email Sales Tool",
    "h1": "Integrate Moxie with SalesBlink",
    "metaDescription": "Integrate Moxie and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Moxie Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "rezora",
    "slug": "rezora",
    "logo": "https://zapier-images.imgix.net/storage/developer/86136213a4a09cdf0bee40ef92df5b0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rezora is a digital marketing platform for real estate marketing managers and agents to market their companies, themselves and listings.",
    "category": "Marketing Automation",
    "title": "rezora Integration With Cold Email Sales Tool",
    "h1": "Integrate rezora with SalesBlink",
    "metaDescription": "Integrate rezora and other Marketing Automation Tools with SalesBlink. Two Way rezora Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Files.com",
    "slug": "filesdotcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c4d1a4a6b0b24ef77dadea5df2248a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Files.com is Smart Cloud Storage for modern teams. With Files.com, your team can collaborate, automate, and get things done -- without compromising security.",
    "category": "File Management & Storage",
    "title": "Files.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Files.com with SalesBlink",
    "metaDescription": "Integrate Files.com and other File Management & Storage Tools with SalesBlink. Two Way Files.com Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Novocall",
    "slug": "novocall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/534d84c13ee9023bccaedcee51034ac8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Novocall is a callback app that make it easy for businesses communicate with their website visitors through calls and scheduling.",
    "category": "Call Tracking",
    "title": "Novocall Integration With Cold Email Sales Tool",
    "h1": "Integrate Novocall with SalesBlink",
    "metaDescription": "Integrate Novocall and other Call Tracking Tools with SalesBlink. Two Way Novocall Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "My Web Audit",
    "slug": "my-web-audit-da105289",
    "logo": "https://zapier-images.imgix.net/storage/developer/50861f8166e58b29f5164856000f859f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "My Web Audit is an audit and reporting tool to help agencies generate more leads and close more deals.",
    "category": "Marketing Automation",
    "title": "My Web Audit Integration With Cold Email Sales Tool",
    "h1": "Integrate My Web Audit with SalesBlink",
    "metaDescription": "Integrate My Web Audit and other Marketing Automation Tools with SalesBlink. Two Way My Web Audit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IPQualityScore",
    "slug": "ipqualityscore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5755afd8e6d7f4a18ec77404f03e0110.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IPQualityScore prevents fraud across any industry, catching high risk users & transactions in real-time without impacting your legitimate audience.",
    "category": "Security & Identity Tools",
    "title": "IPQualityScore Integration With Cold Email Sales Tool",
    "h1": "Integrate IPQualityScore with SalesBlink",
    "metaDescription": "Integrate IPQualityScore and other Security & Identity Tools Tools with SalesBlink. Two Way IPQualityScore Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Blink",
    "slug": "blink",
    "logo": "https://zapier-images.imgix.net/storage/services/b5aa646148486ae25dd9ce13bba33ed1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blink is a workplace messaging platform that uses bots, apps and messaging to simplify work.",
    "category": "Team Chat",
    "title": "Blink Integration With Cold Email Sales Tool",
    "h1": "Integrate Blink with SalesBlink",
    "metaDescription": "Integrate Blink and other Team Chat Tools with SalesBlink. Two Way Blink Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Mayar",
    "slug": "mayar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d9babf2867c6385a60ef95a8f2f6aa6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mayar is a no-code checkout, payment and billing platform for business and entrepreneur",
    "category": "Payment Processing",
    "title": "Mayar Integration With Cold Email Sales Tool",
    "h1": "Integrate Mayar with SalesBlink",
    "metaDescription": "Integrate Mayar and other Payment Processing Tools with SalesBlink. Two Way Mayar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flocksy",
    "slug": "flocksy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/864701cb096fa41be689d51d4afbb7d8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flocksy provides a wide range of marketing focused creative services for a flat monthly payment.",
    "category": "Marketing",
    "title": "Flocksy Integration With Cold Email Sales Tool",
    "h1": "Integrate Flocksy with SalesBlink",
    "metaDescription": "Integrate Flocksy and other Marketing Tools with SalesBlink. Two Way Flocksy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Geneva",
    "slug": "geneva",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/262c244ba45af002036736ee66d02dba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Geneva gives you everything you need to keep your groups, clubs, and communities organized and connected.",
    "category": "Communication",
    "title": "Geneva Integration With Cold Email Sales Tool",
    "h1": "Integrate Geneva with SalesBlink",
    "metaDescription": "Integrate Geneva and other Communication Tools with SalesBlink. Two Way Geneva Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BoldLeads",
    "slug": "boldleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1a2954917bc7a592de6f1bd19922e1b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BoldLeads is a system for real estate agents to generate buyer and seller leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BoldLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate BoldLeads with SalesBlink",
    "metaDescription": "Integrate BoldLeads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BoldLeads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TeamBuildr",
    "slug": "teambuildr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b000a2fa48b4466768673cebfa1e1e28_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamBuildr is a Strength & Conditioning software used by coaches & trainers around the world to create & distribute workout programs to athletes.",
    "category": "Lifestyle & Entertainment",
    "title": "TeamBuildr Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamBuildr with SalesBlink",
    "metaDescription": "Integrate TeamBuildr and other Lifestyle & Entertainment Tools with SalesBlink. Two Way TeamBuildr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ruler Analytics",
    "slug": "ruler-analytics",
    "logo": "https://zapier-images.imgix.net/storage/developer/702b2e113c394a503e075901bdb33a41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ruler Analytics is a visitor level analytics product that tracks customer journeys, conversions, phone calls & companies looking at your site.",
    "category": "Call Tracking",
    "title": "Ruler Analytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Ruler Analytics with SalesBlink",
    "metaDescription": "Integrate Ruler Analytics and other Call Tracking Tools with SalesBlink. Two Way Ruler Analytics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LegitFit",
    "slug": "legitfit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eafe617dbf02d17ff0510167f433d66c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LegitFit is a Fitness and Wellness Business Management Software that focuses on the 20% your business needs",
    "category": "Fitness",
    "title": "LegitFit Integration With Cold Email Sales Tool",
    "h1": "Integrate LegitFit with SalesBlink",
    "metaDescription": "Integrate LegitFit and other Fitness Tools with SalesBlink. Two Way LegitFit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Worksuite",
    "slug": "worksuite",
    "logo": "https://zapier-images.imgix.net/storage/services/27b8a7a10a7dbaafff3a269b7ffc67ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Worksuite is one platform to hire, onboard, manage, and pay your freelancers and contractors.",
    "category": "HR Talent & Recruitment",
    "title": "Worksuite Integration With Cold Email Sales Tool",
    "h1": "Integrate Worksuite with SalesBlink",
    "metaDescription": "Integrate Worksuite and other HR Talent & Recruitment Tools with SalesBlink. Two Way Worksuite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pensight",
    "slug": "pensight-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0079239ea408e79da69dc97b4d5ec3ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pensight is a knowledge commerce platform for independent online educators",
    "category": "Communication",
    "title": "Pensight Integration With Cold Email Sales Tool",
    "h1": "Integrate Pensight with SalesBlink",
    "metaDescription": "Integrate Pensight and other Communication Tools with SalesBlink. Two Way Pensight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EmbedSocial",
    "slug": "embedsocial",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4d1a84ef6233bb51075e0e27603dc5cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EmbedSocial helps you manage your online reviews and other user generated content.",
    "category": "eCommerce",
    "title": "EmbedSocial Integration With Cold Email Sales Tool",
    "h1": "Integrate EmbedSocial with SalesBlink",
    "metaDescription": "Integrate EmbedSocial and other eCommerce Tools with SalesBlink. Two Way EmbedSocial Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Week Plan",
    "slug": "week-plan",
    "logo": "https://zapier-images.imgix.net/storage/developer/bebac50cf353321217ea215d69d21b0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online weekly planner that helps you focus on what matters rather than just on getting things done.",
    "category": "Task Management",
    "title": "Week Plan Integration With Cold Email Sales Tool",
    "h1": "Integrate Week Plan with SalesBlink",
    "metaDescription": "Integrate Week Plan and other Task Management Tools with SalesBlink. Two Way Week Plan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fiverr Workspace",
    "slug": "fiverr-workspace",
    "logo": "https://zapier-images.imgix.net/storage/services/ee4016792fa408a6fb1dc991d7787ac5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fiverr Workspace is freelancing software that helps you run your business from proposal to payment.",
    "category": "Proposal & Invoice Management",
    "title": "Fiverr Workspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Fiverr Workspace with SalesBlink",
    "metaDescription": "Integrate Fiverr Workspace and other Proposal & Invoice Management Tools with SalesBlink. Two Way Fiverr Workspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mavenlink",
    "slug": "mavenlink",
    "logo": "https://zapier-images.imgix.net/storage/developer/7fa8a31d0083f46d1e663e94b3d211f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mavenlink’s project collaboration suite allows you to manage your business relationships, share files, and track project activity online from anywhere in the world.  Within a project workspace in Mavenlink, you can agree on budget & schedule, track time, send invoices, get paid via PayPal, and complete work.",
    "category": "Project Management",
    "title": "Mavenlink Integration With Cold Email Sales Tool",
    "h1": "Integrate Mavenlink with SalesBlink",
    "metaDescription": "Integrate Mavenlink and other Project Management Tools with SalesBlink. Two Way Mavenlink Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DPD",
    "slug": "dpd",
    "logo": "https://zapier-images.imgix.net/storage/developer/098683c0afcdf19775e2e43cdde40342.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DPD (Digital Product Delivery) is an easy to use digital publishing platform for selling and delivering downloadable content.",
    "category": "eCommerce",
    "title": "DPD Integration With Cold Email Sales Tool",
    "h1": "Integrate DPD with SalesBlink",
    "metaDescription": "Integrate DPD and other eCommerce Tools with SalesBlink. Two Way DPD Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "andcards",
    "slug": "andcards",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91c52aaf1f4735431498819c71be3ee0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "andcards is a member-first coworking space software to organize bookings, services and communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "andcards Integration With Cold Email Sales Tool",
    "h1": "Integrate andcards with SalesBlink",
    "metaDescription": "Integrate andcards and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way andcards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mastermind",
    "slug": "mastermind",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87d53bc78942eb334594106b4b4d8cdf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mastermind app is a online course creation and hosting platform where our users that are subscribed to our platform can create and sell their online courses.",
    "category": "Education",
    "title": "Mastermind Integration With Cold Email Sales Tool",
    "h1": "Integrate Mastermind with SalesBlink",
    "metaDescription": "Integrate Mastermind and other Education Tools with SalesBlink. Two Way Mastermind Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SightMill",
    "slug": "sightmill",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3e79af3976eb760d0009f92f367e3923.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gather customer feedback or employee feedback via email, on your website, by SMS, or at events with SightMill NPS (Net Promoter Score) survey software. Use NPS surveys to understand how to improve customer experience of your products and service.",
    "category": "Forms & Surveys",
    "title": "SightMill Integration With Cold Email Sales Tool",
    "h1": "Integrate SightMill with SalesBlink",
    "metaDescription": "Integrate SightMill and other Forms & Surveys Tools with SalesBlink. Two Way SightMill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AvenueHQ",
    "slug": "avenuehq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/860363e738389030f282d06a3d459680.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A dashboard for an AvenueHQ user's contacts, marketing details and website stats.",
    "category": "Marketing",
    "title": "AvenueHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate AvenueHQ with SalesBlink",
    "metaDescription": "Integrate AvenueHQ and other Marketing Tools with SalesBlink. Two Way AvenueHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Switchboard Canvas",
    "slug": "switchboard-canvas",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40a7566ea20c78693a9b95482fe008b3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Switchboard Canvas lets you create images using an API.",
    "category": "Images & Design",
    "title": "Switchboard Canvas Integration With Cold Email Sales Tool",
    "h1": "Integrate Switchboard Canvas with SalesBlink",
    "metaDescription": "Integrate Switchboard Canvas and other Images & Design Tools with SalesBlink. Two Way Switchboard Canvas Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Parserr",
    "slug": "parserr",
    "logo": "https://zapier-images.imgix.net/storage/developer/9816ee15bc0f3259c0c788fea7979d21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parserr allows you to turn incoming emails into useful data to use in various other 3rd party systems.",
    "category": "Email",
    "title": "Parserr Integration With Cold Email Sales Tool",
    "h1": "Integrate Parserr with SalesBlink",
    "metaDescription": "Integrate Parserr and other Email Tools with SalesBlink. Two Way Parserr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hippo Video",
    "slug": "hippo-video",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f87bdf278fafa04547de1c9894f123f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hippo Video is an Interactive Video CX platform that enables sales reps create & share personalized videos at scale to increase engagement.",
    "category": "Sales & CRM",
    "title": "Hippo Video Integration With Cold Email Sales Tool",
    "h1": "Integrate Hippo Video with SalesBlink",
    "metaDescription": "Integrate Hippo Video and other Sales & CRM Tools with SalesBlink. Two Way Hippo Video Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "CONSENSUS",
    "slug": "consensus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4915afdb90c81ad648747f528e4a5424.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Consensus uses interactive video demos to provide a personalized demo experience on-demand, branching, summarizing, and expounding according to the responses of each prospect.",
    "category": "Marketing",
    "title": "CONSENSUS Integration With Cold Email Sales Tool",
    "h1": "Integrate CONSENSUS with SalesBlink",
    "metaDescription": "Integrate CONSENSUS and other Marketing Tools with SalesBlink. Two Way CONSENSUS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vocal Video",
    "slug": "vocal-video",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ca20e6f4c045fe1c82c4b0a2774a69fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vocal Video helps get amazing video testimonials from anyone, anywhere. Automagically.",
    "category": "Marketing",
    "title": "Vocal Video Integration With Cold Email Sales Tool",
    "h1": "Integrate Vocal Video with SalesBlink",
    "metaDescription": "Integrate Vocal Video and other Marketing Tools with SalesBlink. Two Way Vocal Video Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CompanyHub",
    "slug": "companyhub",
    "logo": "https://zapier-images.imgix.net/storage/developer/539c6ed2d2871d78f4f778fbf4d42072.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CompanyHub is a highly customizable CRM that you can customize, write custom logic, and create automated alerts for and more without a single line of code.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CompanyHub Integration With Cold Email Sales Tool",
    "h1": "Integrate CompanyHub with SalesBlink",
    "metaDescription": "Integrate CompanyHub and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CompanyHub Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "E-Cinch",
    "slug": "e-cinch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f8494cda59d46fafa95888303dac97ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "E-Cinch is an eCommerce platform that enables, both retail and service businesses, to suggestively sell bundles & party packages online.",
    "category": "eCommerce",
    "title": "E-Cinch Integration With Cold Email Sales Tool",
    "h1": "Integrate E-Cinch with SalesBlink",
    "metaDescription": "Integrate E-Cinch and other eCommerce Tools with SalesBlink. Two Way E-Cinch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "COR",
    "slug": "cor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3d0a9375b629ce9c0b647d7cd7f91d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "COR is the all-in-one management solution for creative agencies that automate timesheets, through AI, to predict profitability, reduce work overload and improve client engagements.",
    "category": "Project Management",
    "title": "COR Integration With Cold Email Sales Tool",
    "h1": "Integrate COR with SalesBlink",
    "metaDescription": "Integrate COR and other Project Management Tools with SalesBlink. Two Way COR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amilia",
    "slug": "amilia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28deeef29b6c03e2a21f76a73a459820.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whether you're just starting out or looking to scale, Amilia's Recreation Management Software helps you sell, manage, & grow your business.",
    "category": "eCommerce",
    "title": "Amilia Integration With Cold Email Sales Tool",
    "h1": "Integrate Amilia with SalesBlink",
    "metaDescription": "Integrate Amilia and other eCommerce Tools with SalesBlink. Two Way Amilia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendSafely",
    "slug": "sendsafely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/049ddfe2d4fc353ac49ff62145109a1c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendSafely is an end-to-end encrypted file transfer platform that lets you securely attach any file to any platform.",
    "category": "File Management & Storage",
    "title": "SendSafely Integration With Cold Email Sales Tool",
    "h1": "Integrate SendSafely with SalesBlink",
    "metaDescription": "Integrate SendSafely and other File Management & Storage Tools with SalesBlink. Two Way SendSafely Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Kaspr",
    "slug": "kaspr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e60fa34217481f02e9bc5e1a1ca93c93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kaspr helps you find emails and phone numbers through Linkedin. Create your own contact list with relevant contant information and boost your sales !",
    "category": "App Builder",
    "title": "Kaspr Integration With Cold Email Sales Tool",
    "h1": "Integrate Kaspr with SalesBlink",
    "metaDescription": "Integrate Kaspr and other App Builder Tools with SalesBlink. Two Way Kaspr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WaniKani",
    "slug": "wanikani",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/057653cc151ef3e6c2081c99b90c23df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WaniKani is an application that helps people learn Japanese kanji and associated vocabulary.",
    "category": "Online Courses",
    "title": "WaniKani Integration With Cold Email Sales Tool",
    "h1": "Integrate WaniKani with SalesBlink",
    "metaDescription": "Integrate WaniKani and other Online Courses Tools with SalesBlink. Two Way WaniKani Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stackby",
    "slug": "stackby",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b9d019273bf726d30dbbd1448d6a2aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stackby brings the best of spreadsheets, databases and business APIs in a flexible collaboration platform.",
    "category": "Databases",
    "title": "Stackby Integration With Cold Email Sales Tool",
    "h1": "Integrate Stackby with SalesBlink",
    "metaDescription": "Integrate Stackby and other Databases Tools with SalesBlink. Two Way Stackby Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Harvestr",
    "slug": "harvestr",
    "logo": "https://zapier-images.imgix.net/storage/developer/8a6390282d0a0aca9bc065baf8bc19c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Harvestr is a product management tool that lets you centralize user feedback, prioritize features and communicate on your roadmap.",
    "category": "Product Management",
    "title": "Harvestr Integration With Cold Email Sales Tool",
    "h1": "Integrate Harvestr with SalesBlink",
    "metaDescription": "Integrate Harvestr and other Product Management Tools with SalesBlink. Two Way Harvestr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dashly",
    "slug": "dashly",
    "logo": "https://zapier-images.imgix.net/storage/services/8629af50406d0a6de0003cf72b56ec20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dashly turns visitors into customers with data-driven conversation and marketing.",
    "category": "Marketing Automation",
    "title": "Dashly Integration With Cold Email Sales Tool",
    "h1": "Integrate Dashly with SalesBlink",
    "metaDescription": "Integrate Dashly and other Marketing Automation Tools with SalesBlink. Two Way Dashly Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Dispatch",
    "slug": "dispatch",
    "logo": "https://zapier-images.imgix.net/storage/developer/d7d481e14c42de377293cc36d5985ae0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deliver exceptional customer experiences. Book, track and manage with the world's most powerful on-demand services platform.",
    "category": "Scheduling & Booking",
    "title": "Dispatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Dispatch with SalesBlink",
    "metaDescription": "Integrate Dispatch and other Scheduling & Booking Tools with SalesBlink. Two Way Dispatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planning Pod",
    "slug": "planning-pod",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a8b32102d0fb79a7ae8f5c2a4c05394.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planning Pod is an event management platform designed for event venues and planning professionals to keep you and your team organized and to centralize your event details.",
    "category": "Event Management",
    "title": "Planning Pod Integration With Cold Email Sales Tool",
    "h1": "Integrate Planning Pod with SalesBlink",
    "metaDescription": "Integrate Planning Pod and other Event Management Tools with SalesBlink. Two Way Planning Pod Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agent 3000",
    "slug": "agent",
    "logo": "https://zapier-images.imgix.net/storage/developer/429724e7d3dc320284febad96e22b86b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agent 3000 provides a Website, IDX, CRM and all the necessary marketing tools a REALTOR needs.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agent 3000 Integration With Cold Email Sales Tool",
    "h1": "Integrate Agent 3000 with SalesBlink",
    "metaDescription": "Integrate Agent 3000 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agent 3000 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myInterview",
    "slug": "myinterview",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5ec48b328cc51fe6afef96052307e19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myInterview is a video screening tool enabling businesses of all sizes to hire a suitable candidate easily. Reveal the personality behind the paper with myInterview and connect it to your favourite apps!",
    "category": "HR Talent & Recruitment",
    "title": "myInterview Integration With Cold Email Sales Tool",
    "h1": "Integrate myInterview with SalesBlink",
    "metaDescription": "Integrate myInterview and other HR Talent & Recruitment Tools with SalesBlink. Two Way myInterview Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TRIGGERcmd",
    "slug": "triggercmd",
    "logo": "https://zapier-images.imgix.net/storage/developer/792af74f96d571f098650e131e37bee5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TRIGGERcmd allows you to run commands on your computers remotely.",
    "category": "Developer Tools",
    "title": "TRIGGERcmd Integration With Cold Email Sales Tool",
    "h1": "Integrate TRIGGERcmd with SalesBlink",
    "metaDescription": "Integrate TRIGGERcmd and other Developer Tools Tools with SalesBlink. Two Way TRIGGERcmd Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Document360",
    "slug": "document360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a069a1b49fe1fcf83f588ae716fbd07.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Document360 is a cloud-based help desk solution that enables users in businesses across various industries to create, collaborate and publish self-service knowledge bases for their products.",
    "category": "Customer Support",
    "title": "Document360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Document360 with SalesBlink",
    "metaDescription": "Integrate Document360 and other Customer Support Tools with SalesBlink. Two Way Document360 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Uptics",
    "slug": "uptics",
    "logo": "https://zapier-images.imgix.net/storage/services/0564a35a3d442cbe5d5f2df6a99461fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The modern, all-in-one, sales software for SMBs that adds the gift of automation.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Uptics Integration With Cold Email Sales Tool",
    "h1": "Integrate Uptics with SalesBlink",
    "metaDescription": "Integrate Uptics and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Uptics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventzilla",
    "slug": "eventzilla",
    "logo": "https://zapier-images.imgix.net/storage/developer/a8007b51b5e783cc2087c0ef8d27f69c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventzilla is a do-it-yourself event ticketing and marketing platform that helps event organizers to sell tickets online, process credit card payments and promote events across social media channels.",
    "category": "Event Management",
    "title": "Eventzilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventzilla with SalesBlink",
    "metaDescription": "Integrate Eventzilla and other Event Management Tools with SalesBlink. Two Way Eventzilla Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Nomics Crypto",
    "slug": "nomics-crypto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b44edbeaf1882da3766271a98c7b617a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nomics crypto is a cryptocurrency market data provider. Get current & historical crypto price and exchange data for Bitcoin, Ethereum, etc.",
    "category": "News & Lifestyle",
    "title": "Nomics Crypto Integration With Cold Email Sales Tool",
    "h1": "Integrate Nomics Crypto with SalesBlink",
    "metaDescription": "Integrate Nomics Crypto and other News & Lifestyle Tools with SalesBlink. Two Way Nomics Crypto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoSquared",
    "slug": "gosquared",
    "logo": "https://zapier-images.imgix.net/storage/developer/cf4f73f4856f643d3c4406125834ca57.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoSquared is a customer analytics platform that brings together all your customer information in one place. GoSquared enables teams to easily search, discover and take action on their customer data, enabling them to acquire more customers, provide better support, and build a better product.",
    "category": "Marketing Automation",
    "title": "GoSquared Integration With Cold Email Sales Tool",
    "h1": "Integrate GoSquared with SalesBlink",
    "metaDescription": "Integrate GoSquared and other Marketing Automation Tools with SalesBlink. Two Way GoSquared Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "MeetEdgar",
    "slug": "edgar",
    "logo": "https://zapier-images.imgix.net/storage/developer/17cfb124de14af297a281def38cfcf04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MeetEdgar is a social media scheduling tool that catalogues your posts in a library and publishes them on a recurring schedule.",
    "category": "Social Media Marketing",
    "title": "MeetEdgar Integration With Cold Email Sales Tool",
    "h1": "Integrate MeetEdgar with SalesBlink",
    "metaDescription": "Integrate MeetEdgar and other Social Media Marketing Tools with SalesBlink. Two Way MeetEdgar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SparkPost",
    "slug": "sparkpost",
    "logo": "https://zapier-images.imgix.net/storage/developer/91d473d29bcb5f4cf6b4fc215dd24fd5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SparkPost by Message Systems is the enterprise-grade, developer-friendly email delivery service. It puts the same deliverability, scalability and speed of the world’s leading email platform in the hands of developers at companies of all sizes in an easily accessible cloud solution.",
    "category": "Transactional Email",
    "title": "SparkPost Integration With Cold Email Sales Tool",
    "h1": "Integrate SparkPost with SalesBlink",
    "metaDescription": "Integrate SparkPost and other Transactional Email Tools with SalesBlink. Two Way SparkPost Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Continually",
    "slug": "continually",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e0b0c36bb84ce1eef10dfcb33463b3dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Continually lets you add chatbots and live chat to your website so you can respond to visitors 24/7 with friendly conversational messages.",
    "category": "Support",
    "title": "Continually Integration With Cold Email Sales Tool",
    "h1": "Integrate Continually with SalesBlink",
    "metaDescription": "Integrate Continually and other Support Tools with SalesBlink. Two Way Continually Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Greetly",
    "slug": "greetly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ac171194a85aacc66d70f830df8d22ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Greetly is a digital receptionist app that greets visitors to your workplace.",
    "category": "Forms & Surveys",
    "title": "Greetly Integration With Cold Email Sales Tool",
    "h1": "Integrate Greetly with SalesBlink",
    "metaDescription": "Integrate Greetly and other Forms & Surveys Tools with SalesBlink. Two Way Greetly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MeetFox",
    "slug": "meetfox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9435bebe861a3e02db1a5ce38164b3fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MeetFox is an easy-to-use meeting tool with online scheduling, seamless video calls and immediate payments all-in-one. Helping you monetize your time while providing a great user experience for your clients.",
    "category": "Scheduling & Booking",
    "title": "MeetFox Integration With Cold Email Sales Tool",
    "h1": "Integrate MeetFox with SalesBlink",
    "metaDescription": "Integrate MeetFox and other Scheduling & Booking Tools with SalesBlink. Two Way MeetFox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Painel do Corretor",
    "slug": "painel-do-corretor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a4898f14c5514b86a1c69b51341b86a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Panel do Corretor is the best CRM and quotation app to insurance brokers!",
    "category": "CRM (Customer Relationship Management)",
    "title": "Painel do Corretor Integration With Cold Email Sales Tool",
    "h1": "Integrate Painel do Corretor with SalesBlink",
    "metaDescription": "Integrate Painel do Corretor and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Painel do Corretor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Das Keyboard 5Q",
    "slug": "das-keyboard-5q",
    "logo": "https://zapier-images.imgix.net/storage/developer/f8905d824c7250b7edfa004820e0bdee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Das Keyboard 5Q is a cloud-connected, open API RGB mechanical keyboard that allows each key to be color-controlled over the Internet.",
    "category": "Gaming",
    "title": "Das Keyboard 5Q Integration With Cold Email Sales Tool",
    "h1": "Integrate Das Keyboard 5Q with SalesBlink",
    "metaDescription": "Integrate Das Keyboard 5Q and other Gaming Tools with SalesBlink. Two Way Das Keyboard 5Q Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eva Gifting Assistant (EvaBot)",
    "slug": "eva-gifting-assistant",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e1b6ee902b83ac1f84ff9bdd2bf1224.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eva helps you easily send personalized client gifts for closings, birthdays, house-anniversaries. Connect with a CRM to automate client gifting today.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Eva Gifting Assistant (EvaBot) Integration With Cold Email Sales Tool",
    "h1": "Integrate Eva Gifting Assistant (EvaBot) with SalesBlink",
    "metaDescription": "Integrate Eva Gifting Assistant (EvaBot) and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Eva Gifting Assistant (EvaBot) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ArcSite",
    "slug": "arcsite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb943ecb394822df811ab2eaf89e5508_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ArcSite is the all-in-one drawing & mobile sales platform for service professionals including mobile drawing, estimation, and takeoff tools.",
    "category": "Proposal & Invoice Management",
    "title": "ArcSite Integration With Cold Email Sales Tool",
    "h1": "Integrate ArcSite with SalesBlink",
    "metaDescription": "Integrate ArcSite and other Proposal & Invoice Management Tools with SalesBlink. Two Way ArcSite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Repsly",
    "slug": "repsly",
    "logo": "https://zapier-images.imgix.net/storage/services/4b847c9dabb65492c15b795ab59f0b18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Repsly's retail execution platform provides data-driven merchandising, field sales, and promotion execution solutions to help CPG brands and merchandisers maximize the impact of their field teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Repsly Integration With Cold Email Sales Tool",
    "h1": "Integrate Repsly with SalesBlink",
    "metaDescription": "Integrate Repsly and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Repsly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SwagUp",
    "slug": "swagup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6572b7507ef96e0d75d6ec67b48b2fce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SwagUp makes it simple to create high quality branded swag for companies to give as gifts to employees and/or clients. This app is meant to automate operations using SwagUp API.",
    "category": "eCommerce",
    "title": "SwagUp Integration With Cold Email Sales Tool",
    "h1": "Integrate SwagUp with SalesBlink",
    "metaDescription": "Integrate SwagUp and other eCommerce Tools with SalesBlink. Two Way SwagUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octopush SMS",
    "slug": "octopush-sms",
    "logo": "https://zapier-images.imgix.net/storage/services/f634a69861d968c0d5aeda4b7f4d7667.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octopush SMS allows you to automatically send SMS to over 200 countries.",
    "category": "Phone & SMS",
    "title": "Octopush SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Octopush SMS with SalesBlink",
    "metaDescription": "Integrate Octopush SMS and other Phone & SMS Tools with SalesBlink. Two Way Octopush SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FeedBlitz",
    "slug": "feedblitz",
    "logo": "https://zapier-images.imgix.net/storage/developer/26ea8e5a5023f26af031f169814a784e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FeedBlitz is an all-in-one service combining email marketing, blog newsletter subscriptions, and RSS feed management.",
    "category": "Marketing Automation",
    "title": "FeedBlitz Integration With Cold Email Sales Tool",
    "h1": "Integrate FeedBlitz with SalesBlink",
    "metaDescription": "Integrate FeedBlitz and other Marketing Automation Tools with SalesBlink. Two Way FeedBlitz Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Gupshup",
    "slug": "gupshup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/169e9afc54799deb23c2dafa5a10aa64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gupshup is a Conversational Messaging Platform that helps businesses engage with customers across 30+ messaging channels across commerce, marketing, and support.",
    "category": "Communication",
    "title": "Gupshup Integration With Cold Email Sales Tool",
    "h1": "Integrate Gupshup with SalesBlink",
    "metaDescription": "Integrate Gupshup and other Communication Tools with SalesBlink. Two Way Gupshup Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Boostapp",
    "slug": "boostapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57523d46c31c66b8139f8963db95d3f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boostapp is a leading all-in-one stop platform for manage memberships, sales and marketing.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Boostapp Integration With Cold Email Sales Tool",
    "h1": "Integrate Boostapp with SalesBlink",
    "metaDescription": "Integrate Boostapp and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Boostapp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ahead",
    "slug": "ahead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cff71232d8d2f8df081645070c05c4da_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ahead is the intelligent information hub that drives engagement and communication.​",
    "category": "Documents",
    "title": "ahead Integration With Cold Email Sales Tool",
    "h1": "Integrate ahead with SalesBlink",
    "metaDescription": "Integrate ahead and other Documents Tools with SalesBlink. Two Way ahead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commissionly",
    "slug": "commissionly",
    "logo": "https://zapier-images.imgix.net/storage/developer/f6df4ef1bd1532ef7525a5bf6dc0909c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Commissionly is the cloud based sales commission software focused solely on the small to medium business sector.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Commissionly Integration With Cold Email Sales Tool",
    "h1": "Integrate Commissionly with SalesBlink",
    "metaDescription": "Integrate Commissionly and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Commissionly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickBooks Commerce",
    "slug": "quickbooks-commerce",
    "logo": "https://zapier-images.imgix.net/storage/services/bba0ffdc260ef23e78fd9d277e07648b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your multi-channel and wholesale business in one place with QuickBooks Commerce. Increase operational efficiency with supply chain, reporting, and accounting automation.",
    "category": "eCommerce",
    "title": "QuickBooks Commerce Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickBooks Commerce with SalesBlink",
    "metaDescription": "Integrate QuickBooks Commerce and other eCommerce Tools with SalesBlink. Two Way QuickBooks Commerce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Favro",
    "slug": "favro",
    "logo": "https://zapier-images.imgix.net/storage/developer/860500d74976b72a44b896e2d9c74785.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plan a wedding, build an award winning video game or even send a rocket into space. Favro helps you manage everything from the smallest task, to the most advanced project.",
    "category": "Project Management",
    "title": "Favro Integration With Cold Email Sales Tool",
    "h1": "Integrate Favro with SalesBlink",
    "metaDescription": "Integrate Favro and other Project Management Tools with SalesBlink. Two Way Favro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Listrak",
    "slug": "listrak",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a23d0461860552f260625cc99b63bb0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate with Listrak to collect, unify, and interpret customer data to deliver personalized marketing that engages your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Listrak Integration With Cold Email Sales Tool",
    "h1": "Integrate Listrak with SalesBlink",
    "metaDescription": "Integrate Listrak and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Listrak Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Giggio",
    "slug": "giggio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c69a288656560c5e39fb22315a4544cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Giggio is a web-based system for entertainers to manage their bookings, invoices and payments.",
    "category": "Contact Management",
    "title": "Giggio Integration With Cold Email Sales Tool",
    "h1": "Integrate Giggio with SalesBlink",
    "metaDescription": "Integrate Giggio and other Contact Management Tools with SalesBlink. Two Way Giggio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "New York Times",
    "slug": "new-york-times",
    "logo": "https://zapier-images.imgix.net/storage/developer/8b878829e779f4f49619d04eeb0217d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The New York Times is an American daily newspaper which features top articles across many sections, including politics, technology, and opinion.",
    "category": "News & Lifestyle",
    "title": "New York Times Integration With Cold Email Sales Tool",
    "h1": "Integrate New York Times with SalesBlink",
    "metaDescription": "Integrate New York Times and other News & Lifestyle Tools with SalesBlink. Two Way New York Times Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RepCard",
    "slug": "repcard",
    "logo": "https://zapier-images.imgix.net/storage/services/4abbebd3a38fe9c5e1f95e06a1b99f5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RepCard is a sales tool used to facilitate automated follow up with leads that will drive reviews and referrals back to the sales person.",
    "category": "Sales & CRM",
    "title": "RepCard Integration With Cold Email Sales Tool",
    "h1": "Integrate RepCard with SalesBlink",
    "metaDescription": "Integrate RepCard and other Sales & CRM Tools with SalesBlink. Two Way RepCard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Basecamp Classic",
    "slug": "basecamp-classic",
    "logo": "https://zapier-images.imgix.net/storage/services/b7858c9dccfa1a86811e383fbd6ebddb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Basecamp Classic is a popular project management application. The classic version of Basecamp let's you keep track of multiple projects, milestones, todo items and more. An easy way to consolidate your work and keep your inbox clean.",
    "category": "Project Management",
    "title": "Basecamp Classic Integration With Cold Email Sales Tool",
    "h1": "Integrate Basecamp Classic with SalesBlink",
    "metaDescription": "Integrate Basecamp Classic and other Project Management Tools with SalesBlink. Two Way Basecamp Classic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commusoft",
    "slug": "commusoft",
    "logo": "https://zapier-images.imgix.net/storage/developer/a8301e95c2da260fc26019fda2033592.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Commusoft is job management software.",
    "category": "Task Management",
    "title": "Commusoft Integration With Cold Email Sales Tool",
    "h1": "Integrate Commusoft with SalesBlink",
    "metaDescription": "Integrate Commusoft and other Task Management Tools with SalesBlink. Two Way Commusoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Roll",
    "slug": "roll",
    "logo": "https://zapier-images.imgix.net/storage/developer/3ceab500a31481a738129d3296188402.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Roll is a platform with everything your business needs in one place. Manage projects, track sales, understand and grow your business.",
    "category": "Project Management",
    "title": "Roll Integration With Cold Email Sales Tool",
    "h1": "Integrate Roll with SalesBlink",
    "metaDescription": "Integrate Roll and other Project Management Tools with SalesBlink. Two Way Roll Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Urlbox",
    "slug": "urlbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95a42b8d6e2409ce874a04f948e7bc27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Urlbox is a screenshot API that allows you to generate images from a URL or HTML",
    "category": "Images & Design",
    "title": "Urlbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Urlbox with SalesBlink",
    "metaDescription": "Integrate Urlbox and other Images & Design Tools with SalesBlink. Two Way Urlbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Custify",
    "slug": "custify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bcdeb9424ac24a1d0dfe2edc7525c96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Custify is a customer success platform that helps you better meet customers' needs, reducing churn and increasing lifetime value.",
    "category": "Customer Appreciation",
    "title": "Custify Integration With Cold Email Sales Tool",
    "h1": "Integrate Custify with SalesBlink",
    "metaDescription": "Integrate Custify and other Customer Appreciation Tools with SalesBlink. Two Way Custify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Leadfox",
    "slug": "leadfox",
    "logo": "https://zapier-images.imgix.net/storage/services/e420a8f27726f949e3ba556888996ff8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadfox is lead management web software that allows you to increase sales.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Leadfox Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadfox with SalesBlink",
    "metaDescription": "Integrate Leadfox and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Leadfox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Social Intents",
    "slug": "social-intents",
    "logo": "https://zapier-images.imgix.net/storage/developer/c08ca962a5658ed2dba4061d974be3fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Social Intents lets you live chat with your customers in real-time to provide exceptional service.",
    "category": "Customer Support",
    "title": "Social Intents Integration With Cold Email Sales Tool",
    "h1": "Integrate Social Intents with SalesBlink",
    "metaDescription": "Integrate Social Intents and other Customer Support Tools with SalesBlink. Two Way Social Intents Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zip Archive API",
    "slug": "archive-api",
    "logo": "https://zapier-images.imgix.net/storage/services/3e48a0975e1b5d326805158595be14f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Compress & extract ZIP archives. Zip multiple files into a single archive for mailing or storage. Secure your files with a password at ease.",
    "category": "Content & Files",
    "title": "Zip Archive API Integration With Cold Email Sales Tool",
    "h1": "Integrate Zip Archive API with SalesBlink",
    "metaDescription": "Integrate Zip Archive API and other Content & Files Tools with SalesBlink. Two Way Zip Archive API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Churn Buster",
    "slug": "churn-buster-da107864",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d48d8bcfae228b951026074f75a8928_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Churn Buster is a payment recovery app that connects to your payment processor, monitors for failed payments, and then starts campaigns in response to failed payments.",
    "category": "Payment Processing",
    "title": "Churn Buster Integration With Cold Email Sales Tool",
    "h1": "Integrate Churn Buster with SalesBlink",
    "metaDescription": "Integrate Churn Buster and other Payment Processing Tools with SalesBlink. Two Way Churn Buster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "touchSMS",
    "slug": "touchsms",
    "logo": "https://zapier-images.imgix.net/storage/services/8c5166f5fcff99ad74d9bb37a8e389d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "touchSMS is a simple app to send and receive SMS from any application.",
    "category": "Phone & SMS",
    "title": "touchSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate touchSMS with SalesBlink",
    "metaDescription": "Integrate touchSMS and other Phone & SMS Tools with SalesBlink. Two Way touchSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pingdom",
    "slug": "pingdom",
    "logo": "https://zapier-images.imgix.net/storage/developer/9494da2a6b97de081f88822a1a8b3bb1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pingdom website monitoring reports the uptime, downtime and performance of your website, network and server and alerts you of errors.",
    "category": "Server Monitoring",
    "title": "Pingdom Integration With Cold Email Sales Tool",
    "h1": "Integrate Pingdom with SalesBlink",
    "metaDescription": "Integrate Pingdom and other Server Monitoring Tools with SalesBlink. Two Way Pingdom Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Coach Catalyst",
    "slug": "coach-catalyst",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b77a3ca67f59fe25da38b2bbb4f79c3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coach Catalyst is a coaching platform that helps you keep clients accountable.",
    "category": "Forms & Surveys",
    "title": "Coach Catalyst Integration With Cold Email Sales Tool",
    "h1": "Integrate Coach Catalyst with SalesBlink",
    "metaDescription": "Integrate Coach Catalyst and other Forms & Surveys Tools with SalesBlink. Two Way Coach Catalyst Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelloLeads",
    "slug": "helloleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f8fae4dfde79d62a1f2f30d8cb465618.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelloLeads offers sales CRM software to manage leads, improve customer relationships & more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "HelloLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate HelloLeads with SalesBlink",
    "metaDescription": "Integrate HelloLeads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way HelloLeads Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Yodel.io Phone System",
    "slug": "yodel",
    "logo": "https://zapier-images.imgix.net/storage/developer/955d8503d9dc0e3e1869b0bae0a8ae6c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yodel.io is a new kind of business phone. Get a number and start answering calls or automatically respond with our voice bot functionality.",
    "category": "Phone & SMS",
    "title": "Yodel.io Phone System Integration With Cold Email Sales Tool",
    "h1": "Integrate Yodel.io Phone System with SalesBlink",
    "metaDescription": "Integrate Yodel.io Phone System and other Phone & SMS Tools with SalesBlink. Two Way Yodel.io Phone System Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VerifyPass",
    "slug": "verifypass",
    "logo": "https://zapier-images.imgix.net/storage/developer/ccfff6447482bd184998f9a33ba0c700.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VerifyPass is a simple website addon allowing e-commerce companies to create exclusive offers for Military, First Responders, and more.",
    "category": "eCommerce",
    "title": "VerifyPass Integration With Cold Email Sales Tool",
    "h1": "Integrate VerifyPass with SalesBlink",
    "metaDescription": "Integrate VerifyPass and other eCommerce Tools with SalesBlink. Two Way VerifyPass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coopel",
    "slug": "coopel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/960f6b199126fc2fe78a3429b7bbdc55_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coopel is a robotic process automation (RPA) tool that allows anyone to easily automate processes. The tool can be used on either the cloud or locally.",
    "category": "App Builder",
    "title": "Coopel Integration With Cold Email Sales Tool",
    "h1": "Integrate Coopel with SalesBlink",
    "metaDescription": "Integrate Coopel and other App Builder Tools with SalesBlink. Two Way Coopel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hireflix",
    "slug": "hireflix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4cfd147740e2d9aa57ddd38c8b62bf91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hireflix is a video interview platform. We make the hiring process less painful by enabling companies to pre-record interview questions and invite their candidates to record the responses when it suits them best.",
    "category": "HR Talent & Recruitment",
    "title": "Hireflix Integration With Cold Email Sales Tool",
    "h1": "Integrate Hireflix with SalesBlink",
    "metaDescription": "Integrate Hireflix and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hireflix Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Klipfolio",
    "slug": "klipfolio",
    "logo": "https://zapier-images.imgix.net/storage/developer/8f31c2804f651a2fd8e96ecdf1941c4c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Klipfolio makes it easy and affordable to build and share real-time dashboards. We connect to 400+ data sources and have 100+ pre-built visualizations to help you get started in minutes. Combine data from multiple data sources into a single visualization to build the metrics you need to run your business.",
    "category": "Dashboards",
    "title": "Klipfolio Integration With Cold Email Sales Tool",
    "h1": "Integrate Klipfolio with SalesBlink",
    "metaDescription": "Integrate Klipfolio and other Dashboards Tools with SalesBlink. Two Way Klipfolio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chaport",
    "slug": "chaport",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5cf2c40d18e382833358f5325deb7e86_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chaport is a live chat for websites designed as a modern messenger.",
    "category": "Customer Support",
    "title": "Chaport Integration With Cold Email Sales Tool",
    "h1": "Integrate Chaport with SalesBlink",
    "metaDescription": "Integrate Chaport and other Customer Support Tools with SalesBlink. Two Way Chaport Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Relatel",
    "slug": "relatel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57b72c3688ddf14570c069e3cc3d0e24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create interesting integrations by using your Relatel events for actions.",
    "category": "Communication",
    "title": "Relatel Integration With Cold Email Sales Tool",
    "h1": "Integrate Relatel with SalesBlink",
    "metaDescription": "Integrate Relatel and other Communication Tools with SalesBlink. Two Way Relatel Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Nozbe Personal",
    "slug": "nozbe",
    "logo": "https://zapier-images.imgix.net/storage/developer/0babff9e309ab7cae16b4e3f3e7a31fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nozbe is an online to-do list style project management tool for your team.",
    "category": "Task Management",
    "title": "Nozbe Personal Integration With Cold Email Sales Tool",
    "h1": "Integrate Nozbe Personal with SalesBlink",
    "metaDescription": "Integrate Nozbe Personal and other Task Management Tools with SalesBlink. Two Way Nozbe Personal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ISN",
    "slug": "isn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/989a00d3b138658e88be74dfe4425f22.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Inspection Support Network is the industry’s most trusted home inspection software solution.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ISN Integration With Cold Email Sales Tool",
    "h1": "Integrate ISN with SalesBlink",
    "metaDescription": "Integrate ISN and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ISN Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trustmary",
    "slug": "trustmary",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/31e7426d07a57f8a24ffea3ed65eae82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trustmary helps you gather social proof and use it on your website to generate more leads. Collect NPS, video and text reviews from your customers. Use the social proof you have gathered in different ways on your website.",
    "category": "Ads & Conversion",
    "title": "Trustmary Integration With Cold Email Sales Tool",
    "h1": "Integrate Trustmary with SalesBlink",
    "metaDescription": "Integrate Trustmary and other Ads & Conversion Tools with SalesBlink. Two Way Trustmary Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Planports",
    "slug": "planports",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7fdd31f3a59d7cc61ebc84f53864f4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planports combines CRM and Business Management tools like proposals, orders, stock management and accounting",
    "category": "CRM (Customer Relationship Management)",
    "title": "Planports Integration With Cold Email Sales Tool",
    "h1": "Integrate Planports with SalesBlink",
    "metaDescription": "Integrate Planports and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Planports Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clicksign",
    "slug": "clicksign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/15496a033d8b847593e09741d70269f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clicksign is an electronic document signing platform that meets the requirements of integrity, authenticity and non-repudiation of Brazilian legislation.",
    "category": "Proposal & Invoice Management",
    "title": "Clicksign Integration With Cold Email Sales Tool",
    "h1": "Integrate Clicksign with SalesBlink",
    "metaDescription": "Integrate Clicksign and other Proposal & Invoice Management Tools with SalesBlink. Two Way Clicksign Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zoho Writer",
    "slug": "zoho-writer",
    "logo": "https://zapier-images.imgix.net/storage/developer/56c8f93bf5abf98d64012ff76b81a5cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Writer is a powerful word processor made for collaborative work.",
    "category": "Documents",
    "title": "Zoho Writer Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Writer with SalesBlink",
    "metaDescription": "Integrate Zoho Writer and other Documents Tools with SalesBlink. Two Way Zoho Writer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Veeqo",
    "slug": "veeqo",
    "logo": "https://zapier-images.imgix.net/storage/developer/18dfb023e7cd9a390a4b33b09d1f69dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Veeqo is a complete solution for multichannel retailers; keep inventory levels accurate, streamline operations and ship orders on time.",
    "category": "eCommerce",
    "title": "Veeqo Integration With Cold Email Sales Tool",
    "h1": "Integrate Veeqo with SalesBlink",
    "metaDescription": "Integrate Veeqo and other eCommerce Tools with SalesBlink. Two Way Veeqo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "10,000ft",
    "slug": "10000ft",
    "logo": "https://zapier-images.imgix.net/storage/developer/82fe60bf68f0fa78d9d7a2fdb37328b9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "10,000ft Plans is a resource management and project planning tool to help companies manage their business, teams, and projects.",
    "category": "Project Management",
    "title": "10,000ft Integration With Cold Email Sales Tool",
    "h1": "Integrate 10,000ft with SalesBlink",
    "metaDescription": "Integrate 10,000ft and other Project Management Tools with SalesBlink. Two Way 10,000ft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boast",
    "slug": "boast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c11c91baa2ec2b531aa3eb48ec6ea6a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boast is the easiest way to collect, manage, and display testimonials for your business",
    "category": "Marketing",
    "title": "Boast Integration With Cold Email Sales Tool",
    "h1": "Integrate Boast with SalesBlink",
    "metaDescription": "Integrate Boast and other Marketing Tools with SalesBlink. Two Way Boast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ME-QR QR Code",
    "slug": "me-qr-qr-code",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/945519311e0be0249e6b393042308227.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily generate, manage and statistically track your QR codes",
    "category": "Marketing",
    "title": "ME-QR QR Code Integration With Cold Email Sales Tool",
    "h1": "Integrate ME-QR QR Code with SalesBlink",
    "metaDescription": "Integrate ME-QR QR Code and other Marketing Tools with SalesBlink. Two Way ME-QR QR Code Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Marvelous",
    "slug": "marvelous",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/510bb2e4032edca66abe3f3f8bf1a2ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marvelous is an online teaching platform where you can create and sell beautiful online courses, memberships, and group coaching programs.",
    "category": "Online Courses",
    "title": "Marvelous Integration With Cold Email Sales Tool",
    "h1": "Integrate Marvelous with SalesBlink",
    "metaDescription": "Integrate Marvelous and other Online Courses Tools with SalesBlink. Two Way Marvelous Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lead Forensics",
    "slug": "lead-forensics",
    "logo": "https://zapier-images.imgix.net/storage/developer/d82a01415bb15b08e9803d5b8d461580.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead Forensics is software for turbo-charged lead generation.",
    "category": "Marketing",
    "title": "Lead Forensics Integration With Cold Email Sales Tool",
    "h1": "Integrate Lead Forensics with SalesBlink",
    "metaDescription": "Integrate Lead Forensics and other Marketing Tools with SalesBlink. Two Way Lead Forensics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Soundwise",
    "slug": "soundwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7552654a8fcaa7d4109634b32a70938a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Soundwise is a platform for selling audio courses, audiobooks, premium podcasts and other audio programs.",
    "category": "Online Courses",
    "title": "Soundwise Integration With Cold Email Sales Tool",
    "h1": "Integrate Soundwise with SalesBlink",
    "metaDescription": "Integrate Soundwise and other Online Courses Tools with SalesBlink. Two Way Soundwise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PlanSo Forms for WordPress",
    "slug": "planso",
    "logo": "https://zapier-images.imgix.net/storage/developer/6a5f21e35573be3bb4d3bbd861184b65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlanSo Forms is a form management and creation tool. It can be used directly or via wordpress plugin.",
    "category": "Forms & Surveys",
    "title": "PlanSo Forms for WordPress Integration With Cold Email Sales Tool",
    "h1": "Integrate PlanSo Forms for WordPress with SalesBlink",
    "metaDescription": "Integrate PlanSo Forms for WordPress and other Forms & Surveys Tools with SalesBlink. Two Way PlanSo Forms for WordPress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "edoobox",
    "slug": "edoobox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b0f8e561ced48f4194dfa8494f60c449_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "edoobox is a user-friendly online booking system for courses, seminars and events with a guided process from the announcement, booking / registration to the payment of the offer.",
    "category": "Accounting",
    "title": "edoobox Integration With Cold Email Sales Tool",
    "h1": "Integrate edoobox with SalesBlink",
    "metaDescription": "Integrate edoobox and other Accounting Tools with SalesBlink. Two Way edoobox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SerwerSMS",
    "slug": "serwersms",
    "logo": "https://zapier-images.imgix.net/storage/developer/54df685f87d99f1d3143f9f83c5b2a50_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SerwerSMS is a Polish mobile marketing platform which provides bulk SMS, MMS & VMS VOICE services.",
    "category": "Phone & SMS",
    "title": "SerwerSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate SerwerSMS with SalesBlink",
    "metaDescription": "Integrate SerwerSMS and other Phone & SMS Tools with SalesBlink. Two Way SerwerSMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Maroo",
    "slug": "maroo",
    "logo": "https://zapier-images.imgix.net/storage/services/a9642c41211be7b3c519641c62488fd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maroo is a payments platform for the weddings & events industry.",
    "category": "Payment Processing",
    "title": "Maroo Integration With Cold Email Sales Tool",
    "h1": "Integrate Maroo with SalesBlink",
    "metaDescription": "Integrate Maroo and other Payment Processing Tools with SalesBlink. Two Way Maroo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "smashleads",
    "slug": "smashleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/243fca3b7fb671535dc92409c4ceeb96_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "smashleads is a tool which makes it possible to optimize the leadgeneration by interactive content and artificial intelligence.",
    "category": "Marketing",
    "title": "smashleads Integration With Cold Email Sales Tool",
    "h1": "Integrate smashleads with SalesBlink",
    "metaDescription": "Integrate smashleads and other Marketing Tools with SalesBlink. Two Way smashleads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "fitDEGREE",
    "slug": "fitdegree",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79d6020674a4873c5c9d2d8fee32692f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "fitDEGREE makes simple fitness management software.",
    "category": "Fitness",
    "title": "fitDEGREE Integration With Cold Email Sales Tool",
    "h1": "Integrate fitDEGREE with SalesBlink",
    "metaDescription": "Integrate fitDEGREE and other Fitness Tools with SalesBlink. Two Way fitDEGREE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Newsletter2Go",
    "slug": "newsletter2go",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5674b642e0ee54d9436079a838ecb1cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create professional emails without the hassle. Launch email campaigns that drive revenue, strengthen customer relationships and scale your business.",
    "category": "",
    "title": "Newsletter2Go Integration With Cold Email Sales Tool",
    "h1": "Integrate Newsletter2Go with SalesBlink",
    "metaDescription": "Integrate Newsletter2Go and other  Tools with SalesBlink. Two Way Newsletter2Go Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TinyEmail",
    "slug": "tinyemail",
    "logo": "https://zapier-images.imgix.net/storage/services/477f3e0ef9b8ba0f764d73193cf67f5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily connect your brand, send interactive personalized emails, automate your messaging and drive more sales. TinyEmail lets you spend time delivering the perfect message by automating everything else.",
    "category": "Marketing Automation",
    "title": "TinyEmail Integration With Cold Email Sales Tool",
    "h1": "Integrate TinyEmail with SalesBlink",
    "metaDescription": "Integrate TinyEmail and other Marketing Automation Tools with SalesBlink. Two Way TinyEmail Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Solo",
    "slug": "solo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f3a2929d6341a97be44cc6637c29039.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SOLO solar proposal software allows your sales reps to request and receive a proposal in minutes while in the customer’s home.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Solo Integration With Cold Email Sales Tool",
    "h1": "Integrate Solo with SalesBlink",
    "metaDescription": "Integrate Solo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Solo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "youengage",
    "slug": "youengage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ccd3ce486c38adf1153839b026124a20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create interactive experiences that convert. Engage and get feedback using quizzes, surveys, polls, calculators, forms, and live Q&A",
    "category": "Forms & Surveys",
    "title": "youengage Integration With Cold Email Sales Tool",
    "h1": "Integrate youengage with SalesBlink",
    "metaDescription": "Integrate youengage and other Forms & Surveys Tools with SalesBlink. Two Way youengage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Focus Commit",
    "slug": "focus-commit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfffc6cf4f7b3cb3aa6a2eb02979213f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Focus Commit is a productivity app on Windows that uses Pomodoro techniques, along with Kanban board and Getting Things Done method to help user stay productive and meet their goals.",
    "category": "Productivity",
    "title": "Focus Commit Integration With Cold Email Sales Tool",
    "h1": "Integrate Focus Commit with SalesBlink",
    "metaDescription": "Integrate Focus Commit and other Productivity Tools with SalesBlink. Two Way Focus Commit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Baserow",
    "slug": "baserow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea1acda91be6c944954169f2f9842ac4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Baserow is an open source no-code database tool and Airtable alternative.",
    "category": "Databases",
    "title": "Baserow Integration With Cold Email Sales Tool",
    "h1": "Integrate Baserow with SalesBlink",
    "metaDescription": "Integrate Baserow and other Databases Tools with SalesBlink. Two Way Baserow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "HelpCrunch",
    "slug": "helpcrunch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e3391186d2d4641d062a3bc2d69a58b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelpCrunch is a customer communication platform offering live chat for web and mobile apps, knowledge base, and email automation software.",
    "category": "Customer Support",
    "title": "HelpCrunch Integration With Cold Email Sales Tool",
    "h1": "Integrate HelpCrunch with SalesBlink",
    "metaDescription": "Integrate HelpCrunch and other Customer Support Tools with SalesBlink. Two Way HelpCrunch Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Altos Research",
    "slug": "altos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/627ddf45a544421f1bd7bde5e3b73b0a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Altos tracks over 100 million properties across the U.S. and emails customized reports to contacts every week.  Altos reports generate leads as people interact with them.",
    "category": "Marketing",
    "title": "Altos Research Integration With Cold Email Sales Tool",
    "h1": "Integrate Altos Research with SalesBlink",
    "metaDescription": "Integrate Altos Research and other Marketing Tools with SalesBlink. Two Way Altos Research Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimpleCert",
    "slug": "simplecert",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6fff2f2198d2891eaec499468d90cac7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The SimpleCert application allows for the automatic creation of contacts and certificates within the SimpleCert framework.",
    "category": "Online Courses",
    "title": "SimpleCert Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleCert with SalesBlink",
    "metaDescription": "Integrate SimpleCert and other Online Courses Tools with SalesBlink. Two Way SimpleCert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Checkout Page",
    "slug": "checkout-page",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6c14eaea51b7484525b55a081c62017.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Start selling on your website within minutes with a beautiful checkout experience that works on any device and for any customer.",
    "category": "Commerce",
    "title": "Checkout Page Integration With Cold Email Sales Tool",
    "h1": "Integrate Checkout Page with SalesBlink",
    "metaDescription": "Integrate Checkout Page and other Commerce Tools with SalesBlink. Two Way Checkout Page Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Samsara",
    "slug": "samsara",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/569000de28a4b2d1031f53af985993c9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Samsara is a cloud-connected sensor data platform for connected operations.",
    "category": "Devices",
    "title": "Samsara Integration With Cold Email Sales Tool",
    "h1": "Integrate Samsara with SalesBlink",
    "metaDescription": "Integrate Samsara and other Devices Tools with SalesBlink. Two Way Samsara Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CheckMarket",
    "slug": "checkmarket",
    "logo": "https://zapier-images.imgix.net/storage/developer/accd959628cff306608340e09d6f1b89_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CheckMarket is an enterprise web-based survey tool. There are many low cost (free) survey tools out there. What makes CheckMarket different is its focus on enterprise users and their special needs.",
    "category": "Forms & Surveys",
    "title": "CheckMarket Integration With Cold Email Sales Tool",
    "h1": "Integrate CheckMarket with SalesBlink",
    "metaDescription": "Integrate CheckMarket and other Forms & Surveys Tools with SalesBlink. Two Way CheckMarket Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Synchroteam",
    "slug": "synchroteam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d12f837d2a1f340ee2281757a43c63ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Synchroteam is a Cloud-based Field Service Management application",
    "category": "Scheduling & Booking",
    "title": "Synchroteam Integration With Cold Email Sales Tool",
    "h1": "Integrate Synchroteam with SalesBlink",
    "metaDescription": "Integrate Synchroteam and other Scheduling & Booking Tools with SalesBlink. Two Way Synchroteam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Payhere",
    "slug": "payhere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c2fe3ab2a388300efccb2c494bda211.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payhere gives services businesses an online storefront with integrated payments",
    "category": "Payment Processing",
    "title": "Payhere Integration With Cold Email Sales Tool",
    "h1": "Integrate Payhere with SalesBlink",
    "metaDescription": "Integrate Payhere and other Payment Processing Tools with SalesBlink. Two Way Payhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voodoo SMS",
    "slug": "voodoo-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer/df9248ec12872dcea53a4d4a26c96ae4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Voodoo SMS is an SMS platform that allows you to send text messages to your customers quickly and easily.",
    "category": "Phone & SMS",
    "title": "Voodoo SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Voodoo SMS with SalesBlink",
    "metaDescription": "Integrate Voodoo SMS and other Phone & SMS Tools with SalesBlink. Two Way Voodoo SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReputationLync",
    "slug": "reputationlync-da78768",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95bf8a31c76e4f63f025a5216b50e109_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReputationLync effortlessly automates the process of managing your online reputation.",
    "category": "Reviews",
    "title": "ReputationLync Integration With Cold Email Sales Tool",
    "h1": "Integrate ReputationLync with SalesBlink",
    "metaDescription": "Integrate ReputationLync and other Reviews Tools with SalesBlink. Two Way ReputationLync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quora Lead Gen Forms",
    "slug": "quora-lead-gen-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59f5abf5ea4a42597319a35eb0101099.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quora Lead Gen Forms help businesses and Quora users exchange valuable information with each other through pre-filled forms.",
    "category": "Analytics",
    "title": "Quora Lead Gen Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Quora Lead Gen Forms with SalesBlink",
    "metaDescription": "Integrate Quora Lead Gen Forms and other Analytics Tools with SalesBlink. Two Way Quora Lead Gen Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Celoxis",
    "slug": "celoxis",
    "logo": "https://zapier-images.imgix.net/storage/services/59c5cd54ff39f18ffde1dd0993afc280.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Celoxis is an all-in-one platform for project management, work collaboration and workflow automation.",
    "category": "Project Management",
    "title": "Celoxis Integration With Cold Email Sales Tool",
    "h1": "Integrate Celoxis with SalesBlink",
    "metaDescription": "Integrate Celoxis and other Project Management Tools with SalesBlink. Two Way Celoxis Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "46elks",
    "slug": "46elks",
    "logo": "https://zapier-images.imgix.net/storage/developer/29ff68f72911438e4996dc02df415be5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send and receive SMS from custom sender IDs.",
    "category": "Phone & SMS",
    "title": "46elks Integration With Cold Email Sales Tool",
    "h1": "Integrate 46elks with SalesBlink",
    "metaDescription": "Integrate 46elks and other Phone & SMS Tools with SalesBlink. Two Way 46elks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shopia",
    "slug": "shopia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4be9a16288225a5418214835ead1dc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Content scheduling for blog & social media.",
    "category": "Marketing Automation",
    "title": "Shopia Integration With Cold Email Sales Tool",
    "h1": "Integrate Shopia with SalesBlink",
    "metaDescription": "Integrate Shopia and other Marketing Automation Tools with SalesBlink. Two Way Shopia Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Survey",
    "slug": "zoho-survey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d06aa4d816e06ba636debb1ec675958.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Survey lets you craft and design highly engaging surveys and questionnaires to effectively reach your targeted audience.",
    "category": "Forms & Surveys",
    "title": "Zoho Survey Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Survey with SalesBlink",
    "metaDescription": "Integrate Zoho Survey and other Forms & Surveys Tools with SalesBlink. Two Way Zoho Survey Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ShippyPro",
    "slug": "shippypro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59e36904211271e02f251669261ed7f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShippyPro is the One-Stop Shipping Solution that allows you to create Shipping Labels, Track Packages and Manage Returns.",
    "category": "eCommerce",
    "title": "ShippyPro Integration With Cold Email Sales Tool",
    "h1": "Integrate ShippyPro with SalesBlink",
    "metaDescription": "Integrate ShippyPro and other eCommerce Tools with SalesBlink. Two Way ShippyPro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "pabblyConnect": true
  },
  {
    "name": "Really Simple Systems CRM",
    "slug": "really-simple-systems-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/a9f1a46dadadfcef5e4a434b5ba772f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Really Simple Systems CRM provides everything that a small business needs to automate their sales, marketing and customer services.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Really Simple Systems CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Really Simple Systems CRM with SalesBlink",
    "metaDescription": "Integrate Really Simple Systems CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Really Simple Systems CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BTCPay Server",
    "slug": "btcpay-server",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8efc3e7289d4490aa685581e8f32920.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Start Accepting Bitcoin Payments With 0% Fees & No Third-party",
    "category": "Payment Processing",
    "title": "BTCPay Server Integration With Cold Email Sales Tool",
    "h1": "Integrate BTCPay Server with SalesBlink",
    "metaDescription": "Integrate BTCPay Server and other Payment Processing Tools with SalesBlink. Two Way BTCPay Server Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GMass",
    "slug": "gmass",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f0d556e17764ef115b91997616fe93d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GMass is an email outreach tool for Gmail.",
    "category": "Email",
    "title": "GMass Integration With Cold Email Sales Tool",
    "h1": "Integrate GMass with SalesBlink",
    "metaDescription": "Integrate GMass and other Email Tools with SalesBlink. Two Way GMass Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "AutoRemote",
    "slug": "autoremote",
    "logo": "https://zapier-images.imgix.net/storage/developer/783e5b61182621b9b69c3ac1aec14e63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Control your phone remotely with Tasker and AutoRemote.",
    "category": "Notifications",
    "title": "AutoRemote Integration With Cold Email Sales Tool",
    "h1": "Integrate AutoRemote with SalesBlink",
    "metaDescription": "Integrate AutoRemote and other Notifications Tools with SalesBlink. Two Way AutoRemote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "airfocus",
    "slug": "airfocus",
    "logo": "https://zapier-images.imgix.net/storage/services/653abd2e432a54c41a564c16e3bd0ace.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use airfocus to prioritize your projects and features to build more effective roadmaps. Get everyone aligned and the right stuff done.",
    "category": "Product Management",
    "title": "airfocus Integration With Cold Email Sales Tool",
    "h1": "Integrate airfocus with SalesBlink",
    "metaDescription": "Integrate airfocus and other Product Management Tools with SalesBlink. Two Way airfocus Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "hellonext",
    "slug": "hellonext",
    "logo": "https://zapier-images.imgix.net/storage/services/d5d81aed5838e287e213e59fa46568a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gather feedback from your users in one place and analyze the next thing to build. Listen not only to your guts but also to your user voices.",
    "category": "Developer Tools",
    "title": "hellonext Integration With Cold Email Sales Tool",
    "h1": "Integrate hellonext with SalesBlink",
    "metaDescription": "Integrate hellonext and other Developer Tools Tools with SalesBlink. Two Way hellonext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ATS Anywhere",
    "slug": "ats-anywhere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f3d3c6babf87acb99e0743b668553ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ATS Anywhere allows API usage of our applicant tracking system, which companies can use to post jobs online, manage applicants and hire great employees.",
    "category": "HR Talent & Recruitment",
    "title": "ATS Anywhere Integration With Cold Email Sales Tool",
    "h1": "Integrate ATS Anywhere with SalesBlink",
    "metaDescription": "Integrate ATS Anywhere and other HR Talent & Recruitment Tools with SalesBlink. Two Way ATS Anywhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Postpone for Reddit",
    "slug": "postpone-for-reddit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e30e4c602ea6f4e98e01689209c1111e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Postpone is a Reddit post scheduler and manager for content creators.",
    "category": "Social Media Marketing",
    "title": "Postpone for Reddit Integration With Cold Email Sales Tool",
    "h1": "Integrate Postpone for Reddit with SalesBlink",
    "metaDescription": "Integrate Postpone for Reddit and other Social Media Marketing Tools with SalesBlink. Two Way Postpone for Reddit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatDaddy",
    "slug": "chatdaddy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ad3a88ef3f786120ef6e896c3be117d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatDaddy is the ultimate WhatsApp automation & marketing tool",
    "category": "Marketing Automation",
    "title": "ChatDaddy Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatDaddy with SalesBlink",
    "metaDescription": "Integrate ChatDaddy and other Marketing Automation Tools with SalesBlink. Two Way ChatDaddy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SMS Partner",
    "slug": "sms-partner",
    "logo": "https://zapier-images.imgix.net/storage/developer/141fef95a71652d4467a50fcbe521113.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The \"SMS Partner\" module proposes to effect SMS mailings automatically. You create your account, you integrate your automation and go!",
    "category": "Phone & SMS",
    "title": "SMS Partner Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Partner with SalesBlink",
    "metaDescription": "Integrate SMS Partner and other Phone & SMS Tools with SalesBlink. Two Way SMS Partner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Schedule It",
    "slug": "schedule-it",
    "logo": "https://zapier-images.imgix.net/storage/developer/3e74a6ac1a7151e0614d0c322f60ceaf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Schedule it - A software tool which helps you schedule your resources, teams, equipment, rooms, and projects all in one place.",
    "category": "Scheduling & Booking",
    "title": "Schedule It Integration With Cold Email Sales Tool",
    "h1": "Integrate Schedule It with SalesBlink",
    "metaDescription": "Integrate Schedule It and other Scheduling & Booking Tools with SalesBlink. Two Way Schedule It Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lucky Orange",
    "slug": "lucky-orange",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0abd4945716cd443d11da757f3f14d14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Improve your website's conversion rate using a collection of tools including Dynamic Heatmaps, Session Recordings and Live Chat.",
    "category": "Analytics",
    "title": "Lucky Orange Integration With Cold Email Sales Tool",
    "h1": "Integrate Lucky Orange with SalesBlink",
    "metaDescription": "Integrate Lucky Orange and other Analytics Tools with SalesBlink. Two Way Lucky Orange Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SEOHealth",
    "slug": "seohealth",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef37bb8fe8a6f8ef3313213feb978f02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SEOHealth by Hike allows you to enter a website's URL and retrieve a detailed SEO performance report.",
    "category": "Marketing",
    "title": "SEOHealth Integration With Cold Email Sales Tool",
    "h1": "Integrate SEOHealth with SalesBlink",
    "metaDescription": "Integrate SEOHealth and other Marketing Tools with SalesBlink. Two Way SEOHealth Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Doodle",
    "slug": "doodle",
    "logo": "https://zapier-images.imgix.net/storage/services/f9565cae9c82390f4def557d7721f270.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Doodle is the easiest way to find the right time for anything. Schedule faster, get the right people together, and manage your time at work better.",
    "category": "Scheduling & Booking",
    "title": "Doodle Integration With Cold Email Sales Tool",
    "h1": "Integrate Doodle with SalesBlink",
    "metaDescription": "Integrate Doodle and other Scheduling & Booking Tools with SalesBlink. Two Way Doodle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leap AI",
    "slug": "leap-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3d97fa0bb673c8fa8352f1077074a24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leap into an AI platform that enables easily adding image or text generation capabilities to apps or workflows.",
    "category": "AI Tools",
    "title": "Leap AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Leap AI with SalesBlink",
    "metaDescription": "Integrate Leap AI and other AI Tools Tools with SalesBlink. Two Way Leap AI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "GoFormz",
    "slug": "goformz",
    "logo": "https://zapier-images.imgix.net/storage/developer/99e5749aa6b63ebd0fa0c50f999dbd32.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoFormz gives you a complete mobile forms solution that lets you capture data electronically with mobile forms that look exactly like your current paper forms.",
    "category": "Forms & Surveys",
    "title": "GoFormz Integration With Cold Email Sales Tool",
    "h1": "Integrate GoFormz with SalesBlink",
    "metaDescription": "Integrate GoFormz and other Forms & Surveys Tools with SalesBlink. Two Way GoFormz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taplio",
    "slug": "taplio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5cf645012003542056de315f35e701d6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drive more business from LinkedIn. In 10min a day.",
    "category": "Social Media Marketing",
    "title": "Taplio Integration With Cold Email Sales Tool",
    "h1": "Integrate Taplio with SalesBlink",
    "metaDescription": "Integrate Taplio and other Social Media Marketing Tools with SalesBlink. Two Way Taplio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Selar",
    "slug": "selar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/611c6d2a085482850985940e18af3cc1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Selar is an ecommerce tool creatives & entrepreneurs use to sell their content, products and services across borders without any hassle.",
    "category": "eCommerce",
    "title": "Selar Integration With Cold Email Sales Tool",
    "h1": "Integrate Selar with SalesBlink",
    "metaDescription": "Integrate Selar and other eCommerce Tools with SalesBlink. Two Way Selar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ezekia",
    "slug": "ezekia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d19f60fc386272729b1139e1ba4eb05.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ezekia is a platform for executive search firms to manage their assignments and business development campaigns.",
    "category": "HR Talent & Recruitment",
    "title": "Ezekia Integration With Cold Email Sales Tool",
    "h1": "Integrate Ezekia with SalesBlink",
    "metaDescription": "Integrate Ezekia and other HR Talent & Recruitment Tools with SalesBlink. Two Way Ezekia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadSigma",
    "slug": "leadsigma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aed53b0a4f940fc6f8737b5151fa3eb8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadSigma is a tool that allows you to easily track and follow up with leads.",
    "category": "Sales & CRM",
    "title": "LeadSigma Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadSigma with SalesBlink",
    "metaDescription": "Integrate LeadSigma and other Sales & CRM Tools with SalesBlink. Two Way LeadSigma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Join By Text",
    "slug": "join-by-text",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/32b845910fc6dc3323ac083860499396.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Join By Text lets people quickly and easily sign up for your email list from their phones – increasing leads and opportunities for engagement.",
    "category": "Email Newsletters",
    "title": "Join By Text Integration With Cold Email Sales Tool",
    "h1": "Integrate Join By Text with SalesBlink",
    "metaDescription": "Integrate Join By Text and other Email Newsletters Tools with SalesBlink. Two Way Join By Text Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chirply.io",
    "slug": "chirply",
    "logo": "https://zapier-images.imgix.net/storage/developer/9ab5ae5e9d179597fb2ff2a0b4758c6e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chirply.io is an app which allows you to easily manage text message, pre-recorded, and live phone calls from your browser.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Chirply.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Chirply.io with SalesBlink",
    "metaDescription": "Integrate Chirply.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Chirply.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agency Elephant",
    "slug": "agency-elephant",
    "logo": "https://zapier-images.imgix.net/storage/services/3d7c7cf6f8588e460a769f27fab7877f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agency Elephant is a sales automation tool that makes outbound prospecting and leads follow up easy for the salespeople.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agency Elephant Integration With Cold Email Sales Tool",
    "h1": "Integrate Agency Elephant with SalesBlink",
    "metaDescription": "Integrate Agency Elephant and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agency Elephant Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nozbe",
    "slug": "nozbe-teams",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/572190af9aab5bb6b29a44fb284f7ffd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nozbe is an organizational tool for busy professionals and small teams that helps them to get stuff done in work and in private life.",
    "category": "Project Management",
    "title": "Nozbe Integration With Cold Email Sales Tool",
    "h1": "Integrate Nozbe with SalesBlink",
    "metaDescription": "Integrate Nozbe and other Project Management Tools with SalesBlink. Two Way Nozbe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WinSMS",
    "slug": "winsms",
    "logo": "https://zapier-images.imgix.net/storage/developer/6067a51d39ae34b2635efee9c487b2f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WinSMS is a bulk SMS gateway providing two way communication for SMS messaging.",
    "category": "Phone & SMS",
    "title": "WinSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate WinSMS with SalesBlink",
    "metaDescription": "Integrate WinSMS and other Phone & SMS Tools with SalesBlink. Two Way WinSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GetMyInvoices",
    "slug": "getmyinvoices",
    "logo": "https://zapier-images.imgix.net/storage/services/edb2c27b773d847acb21f8241955fec9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetMyInvoices is a digital invoice management software that simplifies and speeds up preparatory accounting. The solution automatically retrieves invoices and other documents from thousands of sources (10,000 online portals, email postboxes, invoicing tools etc.)",
    "category": "Accounting",
    "title": "GetMyInvoices Integration With Cold Email Sales Tool",
    "h1": "Integrate GetMyInvoices with SalesBlink",
    "metaDescription": "Integrate GetMyInvoices and other Accounting Tools with SalesBlink. Two Way GetMyInvoices Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Payaca",
    "slug": "payaca",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4fdd13e1a11153232527fafc0272c12b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send professional quotes and invoices, manage customers and pricing, and take payment.",
    "category": "Sales & CRM",
    "title": "Payaca Integration With Cold Email Sales Tool",
    "h1": "Integrate Payaca with SalesBlink",
    "metaDescription": "Integrate Payaca and other Sales & CRM Tools with SalesBlink. Two Way Payaca Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Product Hunt",
    "slug": "product-hunt",
    "logo": "https://zapier-images.imgix.net/storage/developer/956222d713379993139cdeb38ac3ca7f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Product Hunt surfaces the best new products, every day. Discover the latest mobile apps, websites, hardware projects, and tech creations that product enthusiasts are talking about.",
    "category": "Social Media Accounts",
    "title": "Product Hunt Integration With Cold Email Sales Tool",
    "h1": "Integrate Product Hunt with SalesBlink",
    "metaDescription": "Integrate Product Hunt and other Social Media Accounts Tools with SalesBlink. Two Way Product Hunt Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Helpwise",
    "slug": "helpwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6969f5966eddee57ad99fcee20729c9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helpwise is a tool to share team inbox among the team members and track all the emails.",
    "category": "Email",
    "title": "Helpwise Integration With Cold Email Sales Tool",
    "h1": "Integrate Helpwise with SalesBlink",
    "metaDescription": "Integrate Helpwise and other Email Tools with SalesBlink. Two Way Helpwise Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "eSign Genie",
    "slug": "esign-genie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b68493a36ca728cf6ce7e2f78a465154.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eSign Genie is an easy, cost-effective, comprehensive and legally binding end-to-end esignature solution with Templates, Bulk Signing, Online Forms, APIs.",
    "category": "Signatures",
    "title": "eSign Genie Integration With Cold Email Sales Tool",
    "h1": "Integrate eSign Genie with SalesBlink",
    "metaDescription": "Integrate eSign Genie and other Signatures Tools with SalesBlink. Two Way eSign Genie Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Confluence Server",
    "slug": "confluence",
    "logo": "https://zapier-images.imgix.net/storage/services/74af8f5da4330bc8e5305a8850ccb382.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Confluence is where you create, organize and discuss work with your team. \n\nThis integration connects to server-hosted instances of Confluence. Use the 'Confluence Cloud' app for server-hosted instances.",
    "category": "Team Collaboration",
    "title": "Confluence Server Integration With Cold Email Sales Tool",
    "h1": "Integrate Confluence Server with SalesBlink",
    "metaDescription": "Integrate Confluence Server and other Team Collaboration Tools with SalesBlink. Two Way Confluence Server Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS Alert",
    "slug": "sms-alert",
    "logo": "https://zapier-images.imgix.net/storage/developer/8300b9d6e673b9fda0c7f3c99f0a70aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMS Alert is a enterprise grade transactional and promotional SMS platform.",
    "category": "Phone & SMS",
    "title": "SMS Alert Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Alert with SalesBlink",
    "metaDescription": "Integrate SMS Alert and other Phone & SMS Tools with SalesBlink. Two Way SMS Alert Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "D7 SMS",
    "slug": "d7sms",
    "logo": "https://zapier-images.imgix.net/storage/developer/7cfbb53a62b6f1f6ff9a566727d77cb3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use D7 SMS to reach your customers over D7's own connectivity to global mobile networks.",
    "category": "Phone & SMS",
    "title": "D7 SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate D7 SMS with SalesBlink",
    "metaDescription": "Integrate D7 SMS and other Phone & SMS Tools with SalesBlink. Two Way D7 SMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Super Dispatch",
    "slug": "super-dispatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f9bb573765680fe9129f720d1c1215a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Super Dispatch platform is a better and a faster way to move cars for Carriers, Brokers, and Shippers.",
    "category": "Dashboards",
    "title": "Super Dispatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Super Dispatch with SalesBlink",
    "metaDescription": "Integrate Super Dispatch and other Dashboards Tools with SalesBlink. Two Way Super Dispatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EasyPractice",
    "slug": "easypractice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/80372c0e26c55066611a6eab95a5fb8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyPractice is a platform for therapists and clinicians which handles your calendar, SMS reminders, online booking, journals, invoicing, payments and so much more.",
    "category": "Event Management",
    "title": "EasyPractice Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyPractice with SalesBlink",
    "metaDescription": "Integrate EasyPractice and other Event Management Tools with SalesBlink. Two Way EasyPractice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Placester",
    "slug": "placester",
    "logo": "https://zapier-images.imgix.net/storage/services/33b39512a7f37d11fbe10d1384013026.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Placester is a real estate platform which enables real estate agents and brokers to easily create personalized websites, as well as manage leads and their activities.",
    "category": "Website Builders",
    "title": "Placester Integration With Cold Email Sales Tool",
    "h1": "Integrate Placester with SalesBlink",
    "metaDescription": "Integrate Placester and other Website Builders Tools with SalesBlink. Two Way Placester Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Radius CRM",
    "slug": "radius-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/833cfd5e81f991172e90eb7ed6f65250_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead Management & CRM Software for Insurance Agents to Market to Leads, Speak to Clients, and Track Commissions, all with automation.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Radius CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Radius CRM with SalesBlink",
    "metaDescription": "Integrate Radius CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Radius CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SATORI",
    "slug": "satori",
    "logo": "https://zapier-images.imgix.net/storage/services/41413f81bab68232d2a8b7a45ba67a19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SATORI is a marketing automation tool which helps marketers who want to increase their sales with more efficient processes.",
    "category": "Marketing Automation",
    "title": "SATORI Integration With Cold Email Sales Tool",
    "h1": "Integrate SATORI with SalesBlink",
    "metaDescription": "Integrate SATORI and other Marketing Automation Tools with SalesBlink. Two Way SATORI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BotStar",
    "slug": "botstar",
    "logo": "https://zapier-images.imgix.net/storage/services/878afbc1ad4d3c19e59b77ec6220dd5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BotStar is a chatbot development platform that lets you design and build your chatbots with minimal development and a visual editor.",
    "category": "Customer Support",
    "title": "BotStar Integration With Cold Email Sales Tool",
    "h1": "Integrate BotStar with SalesBlink",
    "metaDescription": "Integrate BotStar and other Customer Support Tools with SalesBlink. Two Way BotStar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "RightSignature",
    "slug": "rightsignature",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f84ea15eb5940e7c2fc2a0627ebe8a27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RightSignature provides a service for easy online document signing.",
    "category": "Signatures",
    "title": "RightSignature Integration With Cold Email Sales Tool",
    "h1": "Integrate RightSignature with SalesBlink",
    "metaDescription": "Integrate RightSignature and other Signatures Tools with SalesBlink. Two Way RightSignature Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pluvo",
    "slug": "pluvo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6bc983cb9add2078ecb643fac43b9c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pluvo is an all-in-one learning platform that lets you create beautiful online courses that leave an unforgettable impression. For every budget and every learning challenge.",
    "category": "Online Courses",
    "title": "Pluvo Integration With Cold Email Sales Tool",
    "h1": "Integrate Pluvo with SalesBlink",
    "metaDescription": "Integrate Pluvo and other Online Courses Tools with SalesBlink. Two Way Pluvo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gleam.io",
    "slug": "gleamio-da71130",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b41e3a747fb9b8a30a92e4419bce6654.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marketing apps to help you grow your business.",
    "category": "Marketing",
    "title": "Gleam.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Gleam.io with SalesBlink",
    "metaDescription": "Integrate Gleam.io and other Marketing Tools with SalesBlink. Two Way Gleam.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smshosting",
    "slug": "smshosting",
    "logo": "https://zapier-images.imgix.net/storage/developer/c7ce55530f124a46a672b5121cc3c05c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smshosting is a professional tool to send SMS, push notifications, and to create mobile landing pages.",
    "category": "Phone & SMS",
    "title": "Smshosting Integration With Cold Email Sales Tool",
    "h1": "Integrate Smshosting with SalesBlink",
    "metaDescription": "Integrate Smshosting and other Phone & SMS Tools with SalesBlink. Two Way Smshosting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HotspotSystem",
    "slug": "hotspotsystem",
    "logo": "https://zapier-images.imgix.net/storage/developer/d67dcf62ffb4d6526fc09849926d536f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Extract data of your hotspot users, using the cloud based hotspot management service of hotspotsystem.com.",
    "category": "CRM (Customer Relationship Management)",
    "title": "HotspotSystem Integration With Cold Email Sales Tool",
    "h1": "Integrate HotspotSystem with SalesBlink",
    "metaDescription": "Integrate HotspotSystem and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way HotspotSystem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Joonbot",
    "slug": "joonbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c73d7e1ae50249c909ffebce5ebf0aa2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Joonbot is a no-coding chatbot builder for marketing and customer support.",
    "category": "Marketing",
    "title": "Joonbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Joonbot with SalesBlink",
    "metaDescription": "Integrate Joonbot and other Marketing Tools with SalesBlink. Two Way Joonbot Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SurveyCTO",
    "slug": "surveycto",
    "logo": "https://zapier-images.imgix.net/storage/developer/9a9b96d91bbd751f1d3a024a095caf8a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveyCTO is a platform for digital data collection, optimized for ease-of-use, data security, and use in offline (field) settings.",
    "category": "Forms & Surveys",
    "title": "SurveyCTO Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveyCTO with SalesBlink",
    "metaDescription": "Integrate SurveyCTO and other Forms & Surveys Tools with SalesBlink. Two Way SurveyCTO Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Fillout",
    "slug": "fillout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ccc80ac88c859c56d14bdc4ad7790272.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create powerful forms, surveys and quizzes your audience will answer with Fillout. Store responses directly where you need them.",
    "category": "Forms & Surveys",
    "title": "Fillout Integration With Cold Email Sales Tool",
    "h1": "Integrate Fillout with SalesBlink",
    "metaDescription": "Integrate Fillout and other Forms & Surveys Tools with SalesBlink. Two Way Fillout Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "EFC Aquila",
    "slug": "efc-aquila",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39dfbbac86d4f508d843b5b24f427830.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EFC Aquila is a fully featured CRM and Billing platform for Martial Arts schools, Leisure clubs and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "EFC Aquila Integration With Cold Email Sales Tool",
    "h1": "Integrate EFC Aquila with SalesBlink",
    "metaDescription": "Integrate EFC Aquila and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way EFC Aquila Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Noysi",
    "slug": "noysi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3efe55ac96571bada63eb4c4d0cc1e8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Noysi is an Intelligent Communication Services Platform. Noysi unifies the tools needed to increase efficiency in companies.",
    "category": "Team Collaboration",
    "title": "Noysi Integration With Cold Email Sales Tool",
    "h1": "Integrate Noysi with SalesBlink",
    "metaDescription": "Integrate Noysi and other Team Collaboration Tools with SalesBlink. Two Way Noysi Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Skubana",
    "slug": "skubana",
    "logo": "https://zapier-images.imgix.net/storage/developer/e9ef770736969a585d8d6dc48c942c9f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Skubana is a cloud based, all-in-one operations software to handle and automate order management, inventory, analytics, POs, & accounting.",
    "category": "eCommerce",
    "title": "Skubana Integration With Cold Email Sales Tool",
    "h1": "Integrate Skubana with SalesBlink",
    "metaDescription": "Integrate Skubana and other eCommerce Tools with SalesBlink. Two Way Skubana Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "inMobile - SMS gateway",
    "slug": "inmobile-sms-gateway",
    "logo": "https://zapier-images.imgix.net/storage/services/3f0900c9ec7d3f827dacd289aa3d45ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "inMobile is a powerful SMS gateway. inMobile makes it easy for you to send personalized SMS messages, manage lists and subscribers.",
    "category": "Phone & SMS",
    "title": "inMobile - SMS gateway Integration With Cold Email Sales Tool",
    "h1": "Integrate inMobile - SMS gateway with SalesBlink",
    "metaDescription": "Integrate inMobile - SMS gateway and other Phone & SMS Tools with SalesBlink. Two Way inMobile - SMS gateway Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Billit",
    "slug": "billit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02c92dabcd6d6b7ea27633a4cd528dfc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Billit is an accounting app for entrepreneurs, with the support of the accountants.",
    "category": "Accounting",
    "title": "Billit Integration With Cold Email Sales Tool",
    "h1": "Integrate Billit with SalesBlink",
    "metaDescription": "Integrate Billit and other Accounting Tools with SalesBlink. Two Way Billit Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Perfex CRM",
    "slug": "perfex-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e3994cc09b469d16423da97f56bd14cb_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Perfex CRM is a Customer Relationship Management software designed for small businesses and freelancers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Perfex CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Perfex CRM with SalesBlink",
    "metaDescription": "Integrate Perfex CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Perfex CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "pabblyConnect": true
  },
  {
    "name": "Packlink PRO",
    "slug": "packlink-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25196174f50bb2c889bd1a03c42128e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Packlink Pro is a professional shipping platform that allows you to automate your shipment process.",
    "category": "eCommerce",
    "title": "Packlink PRO Integration With Cold Email Sales Tool",
    "h1": "Integrate Packlink PRO with SalesBlink",
    "metaDescription": "Integrate Packlink PRO and other eCommerce Tools with SalesBlink. Two Way Packlink PRO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProAbono",
    "slug": "proabono-da76382",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/540e44572f3f94efa8478caee689e334.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Subscription Billing Made Easy. Subscription Billing As-a-Service, for the New Generation of Services.",
    "category": "Payment Processing",
    "title": "ProAbono Integration With Cold Email Sales Tool",
    "h1": "Integrate ProAbono with SalesBlink",
    "metaDescription": "Integrate ProAbono and other Payment Processing Tools with SalesBlink. Two Way ProAbono Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "InvoiceXpress",
    "slug": "invoicexpress",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c376588e1654bbfec7d2f0f278b6f0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InvoiceXpress is online invoicing software that helps all businesses issue invoices in seconds, hassle free.",
    "category": "Accounting",
    "title": "InvoiceXpress Integration With Cold Email Sales Tool",
    "h1": "Integrate InvoiceXpress with SalesBlink",
    "metaDescription": "Integrate InvoiceXpress and other Accounting Tools with SalesBlink. Two Way InvoiceXpress Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Amazon Honeycode",
    "slug": "amazon-honeycode",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a867aaf8e9a631474d9d361ada5c1e48_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Amazon Honeycode to build mobile & web apps that solve your team's business needs—without programming.",
    "category": "Amazon",
    "title": "Amazon Honeycode Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Honeycode with SalesBlink",
    "metaDescription": "Integrate Amazon Honeycode and other Amazon Tools with SalesBlink. Two Way Amazon Honeycode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Posh",
    "slug": "posh",
    "logo": "https://zapier-images.imgix.net/storage/services/ed70928179106cfc9a58ab297fd82c0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Posh is a virtual receptionist service.",
    "category": "Communication",
    "title": "Posh Integration With Cold Email Sales Tool",
    "h1": "Integrate Posh with SalesBlink",
    "metaDescription": "Integrate Posh and other Communication Tools with SalesBlink. Two Way Posh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "timeBuzzer",
    "slug": "timebuzzer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee20c39f75629f84c7bc07cd001c2ed5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "timeBuzzer is a simple time tracker and timesheet app to track hours worked across projects in real time.",
    "category": "Time Tracking Software",
    "title": "timeBuzzer Integration With Cold Email Sales Tool",
    "h1": "Integrate timeBuzzer with SalesBlink",
    "metaDescription": "Integrate timeBuzzer and other Time Tracking Software Tools with SalesBlink. Two Way timeBuzzer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Form-Data",
    "slug": "form-data",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1dbd03c90b3937a6b485b38562a4005b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Form-Data is a forms backend service that provides an endpoint to which you can point your forms and collect submissions",
    "category": "Forms & Surveys",
    "title": "Form-Data Integration With Cold Email Sales Tool",
    "h1": "Integrate Form-Data with SalesBlink",
    "metaDescription": "Integrate Form-Data and other Forms & Surveys Tools with SalesBlink. Two Way Form-Data Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyCashflow",
    "slug": "mycashflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad06a62e0cb8d0e674082514ba18db46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyCashflow is a SaaS ecommerce platform that lets you easily create your own online store and grow it into a successful online business.",
    "category": "eCommerce",
    "title": "MyCashflow Integration With Cold Email Sales Tool",
    "h1": "Integrate MyCashflow with SalesBlink",
    "metaDescription": "Integrate MyCashflow and other eCommerce Tools with SalesBlink. Two Way MyCashflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kissflow Coral",
    "slug": "kissflow-coral",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03b0c938d2d98fe6f8424302c0fe2677_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kissflow Digital Workplace is a powerful work management solution that helps organisations optimize, manage, and track every type of work.",
    "category": "App Builder",
    "title": "Kissflow Coral Integration With Cold Email Sales Tool",
    "h1": "Integrate Kissflow Coral with SalesBlink",
    "metaDescription": "Integrate Kissflow Coral and other App Builder Tools with SalesBlink. Two Way Kissflow Coral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flashyapp",
    "slug": "flashyapp-da85055",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13b3610f1e6f794c65f339e343910e4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one marketing platform for marketing automation, sending email campaigns, sms campaigns and a lot more.",
    "category": "Website & App Building",
    "title": "Flashyapp Integration With Cold Email Sales Tool",
    "h1": "Integrate Flashyapp with SalesBlink",
    "metaDescription": "Integrate Flashyapp and other Website & App Building Tools with SalesBlink. Two Way Flashyapp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Growth-X",
    "slug": "growth-x",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd886dee5d944c37662198c4f28841b7_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Growth-X is a tool for an automatic lead generation in LinkedIn",
    "category": "Marketing",
    "title": "Growth-X Integration With Cold Email Sales Tool",
    "h1": "Integrate Growth-X with SalesBlink",
    "metaDescription": "Integrate Growth-X and other Marketing Tools with SalesBlink. Two Way Growth-X Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moodle",
    "slug": "moodle",
    "logo": "https://zapier-images.imgix.net/storage/services/44fa0bc12310172df31ea77f905767c4_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moodle is a free and open-source learning management system that allows educators, of any kind, to create a private space online, filled with tools that easily create courses and activities, all optimised for collaborative learning.",
    "category": "Online Courses",
    "title": "Moodle Integration With Cold Email Sales Tool",
    "h1": "Integrate Moodle with SalesBlink",
    "metaDescription": "Integrate Moodle and other Online Courses Tools with SalesBlink. Two Way Moodle Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "FYI",
    "slug": "fyi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ba105766f3ee9bc3d4f135218faa610.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FYI is a document management service for professionals",
    "category": "Accounting",
    "title": "FYI Integration With Cold Email Sales Tool",
    "h1": "Integrate FYI with SalesBlink",
    "metaDescription": "Integrate FYI and other Accounting Tools with SalesBlink. Two Way FYI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProdPad",
    "slug": "prodpad",
    "logo": "https://zapier-images.imgix.net/storage/developer/9c71918cb9a8c28d107a195a23115df5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProdPad is a tool that helps you achieve better products through better product management.",
    "category": "Product Management",
    "title": "ProdPad Integration With Cold Email Sales Tool",
    "h1": "Integrate ProdPad with SalesBlink",
    "metaDescription": "Integrate ProdPad and other Product Management Tools with SalesBlink. Two Way ProdPad Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Event Tickets",
    "slug": "event-tickets",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/becfcb4d5263843fc42a497b7faf8654.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sell Tickets and Manage Registration on WordPress.",
    "category": "Event Management",
    "title": "Event Tickets Integration With Cold Email Sales Tool",
    "h1": "Integrate Event Tickets with SalesBlink",
    "metaDescription": "Integrate Event Tickets and other Event Management Tools with SalesBlink. Two Way Event Tickets Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sprintful",
    "slug": "sprintful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54e1ece2f9c80561e33f3d66726d4b2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sprintful is the most flexible, comprehensive scheduling solution. Create an on-brand web page for instant, hassle-free scheduling under your own domain name.",
    "category": "Scheduling & Booking",
    "title": "Sprintful Integration With Cold Email Sales Tool",
    "h1": "Integrate Sprintful with SalesBlink",
    "metaDescription": "Integrate Sprintful and other Scheduling & Booking Tools with SalesBlink. Two Way Sprintful Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brandfolder",
    "slug": "brandfolder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/329cf4a8a11019dfe738c7a5d3e35860_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Store, share, and showcase your assets in one place with Brandfolder, the world's most powerfully simple digital asset management software.",
    "category": "Marketing",
    "title": "Brandfolder Integration With Cold Email Sales Tool",
    "h1": "Integrate Brandfolder with SalesBlink",
    "metaDescription": "Integrate Brandfolder and other Marketing Tools with SalesBlink. Two Way Brandfolder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leap",
    "slug": "leap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e7266b6079ab114fb61f32d3266d746.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leap is a digital estimating, contracting, and communication tool designed specifically for the home services space.",
    "category": "Sales & CRM",
    "title": "Leap Integration With Cold Email Sales Tool",
    "h1": "Integrate Leap with SalesBlink",
    "metaDescription": "Integrate Leap and other Sales & CRM Tools with SalesBlink. Two Way Leap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agiled",
    "slug": "agiled",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d253da55ae61780bfdd4d3caeb99dbc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agiled is an all in one business management platform designed to help you manage employees, projects , tasks, track your time, create professional contracts, track expenses and send professional invoices that get you paid faster.",
    "category": "Project Management",
    "title": "Agiled Integration With Cold Email Sales Tool",
    "h1": "Integrate Agiled with SalesBlink",
    "metaDescription": "Integrate Agiled and other Project Management Tools with SalesBlink. Two Way Agiled Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Uteach",
    "slug": "uteach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/01c72fdb638621d87f611a6c33a5aec1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uteach is a platform to create professional websites with e-learning automation tools within minutes.\nUteach allows users to create a website, sell video courses, webinars, live lessons, organize exams, and give online certificates.",
    "category": "Website & App Building",
    "title": "Uteach Integration With Cold Email Sales Tool",
    "h1": "Integrate Uteach with SalesBlink",
    "metaDescription": "Integrate Uteach and other Website & App Building Tools with SalesBlink. Two Way Uteach Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BDX Leads",
    "slug": "bdx-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b2c4748cd4a9869e975fd1b07b10fc2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The BDX Leads app allows BDX listings clients to automatically port their BDX leads into any number of popular CRM systems without any coding.  This setup can be completed in as little as 30 minutes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BDX Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate BDX Leads with SalesBlink",
    "metaDescription": "Integrate BDX Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BDX Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Passcreator",
    "slug": "passcreator",
    "logo": "https://zapier-images.imgix.net/storage/services/48b2fc6eec20628bf441b86e32d2d8e1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create digital Wallet passes using our visual editor. Distribute store cards, coupons, event tickets and membership cards to your customers.",
    "category": "Developer Tools",
    "title": "Passcreator Integration With Cold Email Sales Tool",
    "h1": "Integrate Passcreator with SalesBlink",
    "metaDescription": "Integrate Passcreator and other Developer Tools Tools with SalesBlink. Two Way Passcreator Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Spacio",
    "slug": "spacio",
    "logo": "https://zapier-images.imgix.net/storage/developer/29303639b2397ad9cf03dda1960d82f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spacio is a solution which replaces clipboards and unreadable handwriting with a professional sign-in system tailored for open houses.",
    "category": "Forms & Surveys",
    "title": "Spacio Integration With Cold Email Sales Tool",
    "h1": "Integrate Spacio with SalesBlink",
    "metaDescription": "Integrate Spacio and other Forms & Surveys Tools with SalesBlink. Two Way Spacio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Switchy.io",
    "slug": "switchyio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9ce50317b6a17719c98e648746b36fcf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Switchy is a plug & play SaaS that enables brands to engage their community through the links they share, turning them into marketing levers.",
    "category": "URL Shortener",
    "title": "Switchy.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Switchy.io with SalesBlink",
    "metaDescription": "Integrate Switchy.io and other URL Shortener Tools with SalesBlink. Two Way Switchy.io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "CS-Cart",
    "slug": "cs-cart",
    "logo": "https://zapier-images.imgix.net/storage/services/7f6c371fb0ad3788e600aadc83cde349.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marketplace software trusted by 1300+ marketplaces.",
    "category": "eCommerce",
    "title": "CS-Cart Integration With Cold Email Sales Tool",
    "h1": "Integrate CS-Cart with SalesBlink",
    "metaDescription": "Integrate CS-Cart and other eCommerce Tools with SalesBlink. Two Way CS-Cart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Nearby Now",
    "slug": "nearbynow",
    "logo": "https://zapier-images.imgix.net/storage/developer/044b2069822c498ff9a91c09bf7509fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nearby Now's powerful marketing platform drives hyperlocal results for your own website by helping you secure more reviews and create rich local checkins.",
    "category": "Marketing Automation",
    "title": "Nearby Now Integration With Cold Email Sales Tool",
    "h1": "Integrate Nearby Now with SalesBlink",
    "metaDescription": "Integrate Nearby Now and other Marketing Automation Tools with SalesBlink. Two Way Nearby Now Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Provely",
    "slug": "provely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d1d9d9a0584e5501702e2bbd37b9919_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instantly Skyrocket Sales and Signups by Adding Real-Time Social Proof, Scarcity, Urgency, and Credibility to Your Website.",
    "category": "Marketing",
    "title": "Provely Integration With Cold Email Sales Tool",
    "h1": "Integrate Provely with SalesBlink",
    "metaDescription": "Integrate Provely and other Marketing Tools with SalesBlink. Two Way Provely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AgentMarketing Leads",
    "slug": "agentmarketing-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer/7c1597e25606b56a37456609703f0160_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AgentMarketing is a suite of marketing tools for real estate that helps source and manage qualified leads from high quality traffic.",
    "category": "Marketing Automation",
    "title": "AgentMarketing Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate AgentMarketing Leads with SalesBlink",
    "metaDescription": "Integrate AgentMarketing Leads and other Marketing Automation Tools with SalesBlink. Two Way AgentMarketing Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReviewTrackers",
    "slug": "reviewtrackers",
    "logo": "https://zapier-images.imgix.net/storage/developer/3b4d7aa7a4baabbaeb1a47039aae143e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use customer feedback from 100+ reviews sites to act today and predict tomorrow.",
    "category": "Marketing Automation",
    "title": "ReviewTrackers Integration With Cold Email Sales Tool",
    "h1": "Integrate ReviewTrackers with SalesBlink",
    "metaDescription": "Integrate ReviewTrackers and other Marketing Automation Tools with SalesBlink. Two Way ReviewTrackers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TRYTN",
    "slug": "trytn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9510f4753cc56a7f27cdcb4d970f100d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TRYTN is an online booking and centralized reservation management system for tour and activity businesses. The simple, intuitive interface means setup can be completed in minutes.",
    "category": "Scheduling & Booking",
    "title": "TRYTN Integration With Cold Email Sales Tool",
    "h1": "Integrate TRYTN with SalesBlink",
    "metaDescription": "Integrate TRYTN and other Scheduling & Booking Tools with SalesBlink. Two Way TRYTN Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Novi AMS",
    "slug": "novi-ams",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f060061ff785a4c6b90050fb322e42d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Novi AMS is association management software for organizations that use QuickBooks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Novi AMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Novi AMS with SalesBlink",
    "metaDescription": "Integrate Novi AMS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Novi AMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ulama",
    "slug": "ulama",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7c0b1d354c548451b0adf6dc98c46e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ulama is an e-learning platform based on community between students that allow entrepreneur to increase customer LTV",
    "category": "Education",
    "title": "Ulama Integration With Cold Email Sales Tool",
    "h1": "Integrate Ulama with SalesBlink",
    "metaDescription": "Integrate Ulama and other Education Tools with SalesBlink. Two Way Ulama Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MightyCall",
    "slug": "mightycall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65010159f329779941cdf2e3b91d7b02_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MightyCall is a cloud-based virtual telephony system for small and medium businesses.",
    "category": "Communication",
    "title": "MightyCall Integration With Cold Email Sales Tool",
    "h1": "Integrate MightyCall with SalesBlink",
    "metaDescription": "Integrate MightyCall and other Communication Tools with SalesBlink. Two Way MightyCall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formilla",
    "slug": "formilla-da47618",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39e51667cdc76456502a96b6d5ee3c5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formilla.com is a leading provider of customer messaging software using a combination of Live Chat, Email, and In-app messaging tools in one powerful platform.",
    "category": "Customer Support",
    "title": "Formilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Formilla with SalesBlink",
    "metaDescription": "Integrate Formilla and other Customer Support Tools with SalesBlink. Two Way Formilla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Myphoner",
    "slug": "myphoner",
    "logo": "https://zapier-images.imgix.net/storage/developer/a35b18f7f49e038d36d9311e8ee81ac5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Myphoner is a cold calling tool used for lead management and lead tracking, with the focus of creating a superior workflow for the sales agent.",
    "category": "Call Tracking",
    "title": "Myphoner Integration With Cold Email Sales Tool",
    "h1": "Integrate Myphoner with SalesBlink",
    "metaDescription": "Integrate Myphoner and other Call Tracking Tools with SalesBlink. Two Way Myphoner Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "People HR",
    "slug": "people-hr",
    "logo": "https://zapier-images.imgix.net/storage/developer/bd67e7b4f52d0705059866c42546e353.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "People delivers an HR administration solution for HR professionals working in small and mid-sized companies.  People HR constructs insightful graphic reports, offers expert “follow me” guidance, and engages people on missions through high-impact tasks.",
    "category": "HR Talent & Recruitment",
    "title": "People HR Integration With Cold Email Sales Tool",
    "h1": "Integrate People HR with SalesBlink",
    "metaDescription": "Integrate People HR and other HR Talent & Recruitment Tools with SalesBlink. Two Way People HR Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BirdSeed",
    "slug": "birdseed",
    "logo": "https://zapier-images.imgix.net/storage/services/755ce087fe51eda3c7d70cdfa7aadef7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BirdSeed is an all-in-one website sales center with 12 powerful tools including live chat, meeting scheduling, FAQ, testimonials and more.",
    "category": "Customer Support",
    "title": "BirdSeed Integration With Cold Email Sales Tool",
    "h1": "Integrate BirdSeed with SalesBlink",
    "metaDescription": "Integrate BirdSeed and other Customer Support Tools with SalesBlink. Two Way BirdSeed Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SellerCloud",
    "slug": "sellercloud",
    "logo": "https://zapier-images.imgix.net/storage/services/35c6b0b51fe7610a0cb813fab6298731.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SellerCloud is an ecommerce solution helping online retailers up efficiency & grow business.",
    "category": "eCommerce",
    "title": "SellerCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate SellerCloud with SalesBlink",
    "metaDescription": "Integrate SellerCloud and other eCommerce Tools with SalesBlink. Two Way SellerCloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ToneDen",
    "slug": "toneden",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3dd17c87c1f852804905c862c1f29f20_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ToneDen is a friendly social marketing platform that gives you the power to reach and sell to the people who matter.",
    "category": "Marketing Automation",
    "title": "ToneDen Integration With Cold Email Sales Tool",
    "h1": "Integrate ToneDen with SalesBlink",
    "metaDescription": "Integrate ToneDen and other Marketing Automation Tools with SalesBlink. Two Way ToneDen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InvestNext",
    "slug": "investnext",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ba95f9123d8963f2b4c6dc76f5eaa4f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InvestNext is a workspace to manage the entire lifecycle of your real estate syndication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "InvestNext Integration With Cold Email Sales Tool",
    "h1": "Integrate InvestNext with SalesBlink",
    "metaDescription": "Integrate InvestNext and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way InvestNext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Print Autopilot",
    "slug": "print-autopilot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1fa67f26603233f86b90629c84a3e251.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The easy way to automate your printing needs",
    "category": "Printing",
    "title": "Print Autopilot Integration With Cold Email Sales Tool",
    "h1": "Integrate Print Autopilot with SalesBlink",
    "metaDescription": "Integrate Print Autopilot and other Printing Tools with SalesBlink. Two Way Print Autopilot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TimeHero",
    "slug": "timehero",
    "logo": "https://zapier-images.imgix.net/storage/developer/3aa5992c2103bcb9d465054eb5e8300a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeHero automates project planning, completion, and reporting. Get peace of mind that everything is on track and taken care of.",
    "category": "Project Management",
    "title": "TimeHero Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeHero with SalesBlink",
    "metaDescription": "Integrate TimeHero and other Project Management Tools with SalesBlink. Two Way TimeHero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Solargraf",
    "slug": "solargraf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d9a2088573b1325bc7b048855bd9a84.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Solargraf is the #1 tool for solar panel installers. Enter any address, draw and send a professional estimate under 1 minute. Easy to use on all devices.",
    "category": "Proposal & Invoice Management",
    "title": "Solargraf Integration With Cold Email Sales Tool",
    "h1": "Integrate Solargraf with SalesBlink",
    "metaDescription": "Integrate Solargraf and other Proposal & Invoice Management Tools with SalesBlink. Two Way Solargraf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "sms77",
    "slug": "sms77",
    "logo": "https://zapier-images.imgix.net/storage/developer/66a39cceede9dc260f41d5580010d533.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sms77 allows you to send a SMS to any mobile phone worldwide.",
    "category": "Phone & SMS",
    "title": "sms77 Integration With Cold Email Sales Tool",
    "h1": "Integrate sms77 with SalesBlink",
    "metaDescription": "Integrate sms77 and other Phone & SMS Tools with SalesBlink. Two Way sms77 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scarlett Network",
    "slug": "scarlett-network",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/324ade0c30c17ae4bf644f4f4f9fb00a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scarlett is a broker in a box software as a service solution tailored for mortgage brokers and lenders. Some its features include; point of sale, Compliance and Payroll",
    "category": "CRM (Customer Relationship Management)",
    "title": "Scarlett Network Integration With Cold Email Sales Tool",
    "h1": "Integrate Scarlett Network with SalesBlink",
    "metaDescription": "Integrate Scarlett Network and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Scarlett Network Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snowflake",
    "slug": "snowflake",
    "logo": "https://zapier-images.imgix.net/storage/services/2410881b323452a119929a0b61b89de6_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snowflake allows corporate users to store and analyze data using cloud-based hardware and software.",
    "category": "Databases",
    "title": "Snowflake Integration With Cold Email Sales Tool",
    "h1": "Integrate Snowflake with SalesBlink",
    "metaDescription": "Integrate Snowflake and other Databases Tools with SalesBlink. Two Way Snowflake Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GatherContent",
    "slug": "gathercontent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/627dfdc03eb7204ef35e7aad56da84a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GatherContent is a Content Operations Platform that brings people, process & content together—to save businesses time & improve content quality.",
    "category": "Developer Tools",
    "title": "GatherContent Integration With Cold Email Sales Tool",
    "h1": "Integrate GatherContent with SalesBlink",
    "metaDescription": "Integrate GatherContent and other Developer Tools Tools with SalesBlink. Two Way GatherContent Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Grab Your Reviews",
    "slug": "grab-your-reviews",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/732bdc19544b7534785beaefdc14c55e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grab Your Reviews is a SaaS platform for complete online review management to generate more referrals for your business/service.",
    "category": "Reviews",
    "title": "Grab Your Reviews Integration With Cold Email Sales Tool",
    "h1": "Integrate Grab Your Reviews with SalesBlink",
    "metaDescription": "Integrate Grab Your Reviews and other Reviews Tools with SalesBlink. Two Way Grab Your Reviews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "shipcloud",
    "slug": "shipcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/2c57a134f71ed1c88f34a4d344e10f61.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shipcloud is a shipping service provider for creating shipping labels as well as tracking shipments.",
    "category": "eCommerce",
    "title": "shipcloud Integration With Cold Email Sales Tool",
    "h1": "Integrate shipcloud with SalesBlink",
    "metaDescription": "Integrate shipcloud and other eCommerce Tools with SalesBlink. Two Way shipcloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Status Hero",
    "slug": "status-hero",
    "logo": "https://zapier-images.imgix.net/storage/services/28b32cfdc02c5e1c93e46dd4d1d6728d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Status Hero is a lightweight tool for replacing standups and tracking check-ins, goals, and activity from your team.",
    "category": "Project Management",
    "title": "Status Hero Integration With Cold Email Sales Tool",
    "h1": "Integrate Status Hero with SalesBlink",
    "metaDescription": "Integrate Status Hero and other Project Management Tools with SalesBlink. Two Way Status Hero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cling",
    "slug": "cling",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b73efd4e00f8412eae55af84b0c140e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cling is a mini CRM that helps you win more deals by sending outstanding proposals and get paid faster!",
    "category": "Proposal & Invoice Management",
    "title": "Cling Integration With Cold Email Sales Tool",
    "h1": "Integrate Cling with SalesBlink",
    "metaDescription": "Integrate Cling and other Proposal & Invoice Management Tools with SalesBlink. Two Way Cling Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Threads",
    "slug": "threads",
    "logo": "https://zapier-images.imgix.net/storage/services/dc21dddbedd414c43dd8d57c8d1cb98d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Threads is a modern forum for work designed to help teams inform, discuss, and make decisions at scale.",
    "category": "Team Collaboration",
    "title": "Threads Integration With Cold Email Sales Tool",
    "h1": "Integrate Threads with SalesBlink",
    "metaDescription": "Integrate Threads and other Team Collaboration Tools with SalesBlink. Two Way Threads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xama Hub",
    "slug": "xama-hub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4729b1526cc63908f76eb76d9b342fa4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xama Hub enables the streamlined onboarding of customers through the automation of identity verification and anti money laundering checks.",
    "category": "Accounting",
    "title": "Xama Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate Xama Hub with SalesBlink",
    "metaDescription": "Integrate Xama Hub and other Accounting Tools with SalesBlink. Two Way Xama Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CentrixOne",
    "slug": "centrixone-da104369",
    "logo": "https://zapier-images.imgix.net/storage/developer/145fb0f826f7b14931c13b8c942d3fc5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CentrixOne is a full featured yet easy-to-use CRM, e-mail and marketing automation platform for SMB’s.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CentrixOne Integration With Cold Email Sales Tool",
    "h1": "Integrate CentrixOne with SalesBlink",
    "metaDescription": "Integrate CentrixOne and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CentrixOne Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loop Returns",
    "slug": "loopreturns",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/69b3a35d82762a60cb56527767a357a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loop Returns is the exchange-first returns platform for scaling Shopify brands.\n**You will need Loop Plus or the Loop Integrations Add-On**",
    "category": "eCommerce",
    "title": "Loop Returns Integration With Cold Email Sales Tool",
    "h1": "Integrate Loop Returns with SalesBlink",
    "metaDescription": "Integrate Loop Returns and other eCommerce Tools with SalesBlink. Two Way Loop Returns Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Speak Ai",
    "slug": "speak-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8601a4e0a94d0ec5a25a9ddccf671e74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Speak Ai is a tool to analyze audio and video to get insights extraction and transcriptions.",
    "category": "Transcription",
    "title": "Speak Ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Speak Ai with SalesBlink",
    "metaDescription": "Integrate Speak Ai and other Transcription Tools with SalesBlink. Two Way Speak Ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kennected",
    "slug": "kennected",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8856d5cf490dda97d945a241bd2f1e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kennected is a LinkedIn Automation tool used to generate leads",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kennected Integration With Cold Email Sales Tool",
    "h1": "Integrate Kennected with SalesBlink",
    "metaDescription": "Integrate Kennected and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kennected Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Daily AI Mortgage",
    "slug": "dailyai-mortgage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/370b095639ea8f19674ca7fcbb7a4c94.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Daily AI Mortgage helps loan officers convert more leads and manage transactions.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Daily AI Mortgage Integration With Cold Email Sales Tool",
    "h1": "Integrate Daily AI Mortgage with SalesBlink",
    "metaDescription": "Integrate Daily AI Mortgage and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Daily AI Mortgage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrowSurf",
    "slug": "growsurf",
    "logo": "https://zapier-images.imgix.net/storage/services/d4c0376bc3151bb2808909e1d0fc0124.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowSurf is referral software for B2C and B2B tech companies.",
    "category": "Marketing Automation",
    "title": "GrowSurf Integration With Cold Email Sales Tool",
    "h1": "Integrate GrowSurf with SalesBlink",
    "metaDescription": "Integrate GrowSurf and other Marketing Automation Tools with SalesBlink. Two Way GrowSurf Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pappers",
    "slug": "pappers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19cc9cb5ccb1f84612a38f9b9bed3982.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Toute l'information des entreprises\nGratuite, intelligente, complète",
    "category": "Developer Tools",
    "title": "Pappers Integration With Cold Email Sales Tool",
    "h1": "Integrate Pappers with SalesBlink",
    "metaDescription": "Integrate Pappers and other Developer Tools Tools with SalesBlink. Two Way Pappers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rotessa",
    "slug": "rotessa",
    "logo": "https://zapier-images.imgix.net/storage/services/98b1e044fab084fc8980d424aea06140.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rotessa is a payment processing tool that lets you collect ACH and EFT payments in the United States and Canada",
    "category": "Payment Processing",
    "title": "Rotessa Integration With Cold Email Sales Tool",
    "h1": "Integrate Rotessa with SalesBlink",
    "metaDescription": "Integrate Rotessa and other Payment Processing Tools with SalesBlink. Two Way Rotessa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bidsketch",
    "slug": "bidsketch",
    "logo": "https://zapier-images.imgix.net/storage/developer/c8676a3043c3cad2935bb693c5a57a1f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bidsketch helps you create professional proposals in minutes.",
    "category": "Proposal & Invoice Management",
    "title": "Bidsketch Integration With Cold Email Sales Tool",
    "h1": "Integrate Bidsketch with SalesBlink",
    "metaDescription": "Integrate Bidsketch and other Proposal & Invoice Management Tools with SalesBlink. Two Way Bidsketch Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Trail",
    "slug": "trail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0cb953e75b8679d431172aecdcc6cf2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trail is a checklist and compliance app to guide hospitality teams through their day",
    "category": "Task Management",
    "title": "Trail Integration With Cold Email Sales Tool",
    "h1": "Integrate Trail with SalesBlink",
    "metaDescription": "Integrate Trail and other Task Management Tools with SalesBlink. Two Way Trail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dinamize",
    "slug": "dinamize",
    "logo": "https://zapier-images.imgix.net/storage/developer/1be7c1f4eca195e262bacfcd7f495140.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dinamize has email and inbound marketing solutions, where you can manage your contacts and perform various actions using different features.",
    "category": "Email Newsletters",
    "title": "Dinamize Integration With Cold Email Sales Tool",
    "h1": "Integrate Dinamize with SalesBlink",
    "metaDescription": "Integrate Dinamize and other Email Newsletters Tools with SalesBlink. Two Way Dinamize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AnywhereWorks",
    "slug": "anywhereworks",
    "logo": "https://zapier-images.imgix.net/storage/developer/b2d9d13bd4aaa6ec9834980467cb7757_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AnywhereWorks is an online platform that makes team collaboration and communication simple for your business. Built to empower teams to connect from anywhere, your team can collaborate via audio/video calls, status feed updates, group and 1:1 chats, and file sharing features.",
    "category": "Team Chat",
    "title": "AnywhereWorks Integration With Cold Email Sales Tool",
    "h1": "Integrate AnywhereWorks with SalesBlink",
    "metaDescription": "Integrate AnywhereWorks and other Team Chat Tools with SalesBlink. Two Way AnywhereWorks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DailyBot",
    "slug": "dailybot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b9ded174699f4769f2ada6b69e5d5c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DailyBot is your team's daily assistant. Put your stand-up meetings, status reports and check-ins on cruise control, save time and increase productivity.",
    "category": "Project Management",
    "title": "DailyBot Integration With Cold Email Sales Tool",
    "h1": "Integrate DailyBot with SalesBlink",
    "metaDescription": "Integrate DailyBot and other Project Management Tools with SalesBlink. Two Way DailyBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TheGenie Marketing Hub",
    "slug": "thegenie-marketing-hub",
    "logo": "https://zapier-images.imgix.net/storage/services/4fa5e0be74e953f1abd112f8c79df831.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TheGenie Marketing Hub is a Real Estate Marketing Platform.",
    "category": "Ads & Conversion",
    "title": "TheGenie Marketing Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate TheGenie Marketing Hub with SalesBlink",
    "metaDescription": "Integrate TheGenie Marketing Hub and other Ads & Conversion Tools with SalesBlink. Two Way TheGenie Marketing Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailerCheck",
    "slug": "mailercheck",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/36826755b8020b2586e250f8ab95a2c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MailerCheck is an easy-to-use email and campaign analysis tool. Anyone using an email service provider can keep their email lists clean and their campaigns deliverable.",
    "category": "Email",
    "title": "MailerCheck Integration With Cold Email Sales Tool",
    "h1": "Integrate MailerCheck with SalesBlink",
    "metaDescription": "Integrate MailerCheck and other Email Tools with SalesBlink. Two Way MailerCheck Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Well World",
    "slug": "well-world",
    "logo": "https://zapier-images.imgix.net/storage/developer/c374289542b1d87878399d8ae2c05ddc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Well World puts an Integrative Health Practitioner in Your Pocket. View custom plans and share your nutrition, exercise, sleep and meditation data with your practitioner.",
    "category": "Fitness",
    "title": "Well World Integration With Cold Email Sales Tool",
    "h1": "Integrate Well World with SalesBlink",
    "metaDescription": "Integrate Well World and other Fitness Tools with SalesBlink. Two Way Well World Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Honeycommb",
    "slug": "honeycommb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ed96d213ed6e346148e470fd6e91322.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Launch a social network you own & control with the most complete white-label social network platform in the world",
    "category": "Social Media Accounts",
    "title": "Honeycommb Integration With Cold Email Sales Tool",
    "h1": "Integrate Honeycommb with SalesBlink",
    "metaDescription": "Integrate Honeycommb and other Social Media Accounts Tools with SalesBlink. Two Way Honeycommb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignUpAnywhere",
    "slug": "signupanywhere",
    "logo": "https://zapier-images.imgix.net/storage/developer/d2951c44be091452cc0afcf2eeafa92a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignUpAnywhere allows you and your team to capture email addresses and leads even when you’re offline.",
    "category": "Forms & Surveys",
    "title": "SignUpAnywhere Integration With Cold Email Sales Tool",
    "h1": "Integrate SignUpAnywhere with SalesBlink",
    "metaDescription": "Integrate SignUpAnywhere and other Forms & Surveys Tools with SalesBlink. Two Way SignUpAnywhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mfr - field service management",
    "slug": "mfr-field-service-management-ca1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ceeb85eddc2f6b9b87dfa79908dc5dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Field Service Management platform for dispatching efficiently technicians. This platform helps customers to provide the best service onsite.",
    "category": "Scheduling & Booking",
    "title": "mfr - field service management Integration With Cold Email Sales Tool",
    "h1": "Integrate mfr - field service management with SalesBlink",
    "metaDescription": "Integrate mfr - field service management and other Scheduling & Booking Tools with SalesBlink. Two Way mfr - field service management Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drupal",
    "slug": "drupal",
    "logo": "https://zapier-images.imgix.net/storage/services/98de014958d0ab8bc053615c12ccb852.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drupal is an open source content management platform powering millions of websites and applications.",
    "category": "Website Builders",
    "title": "Drupal Integration With Cold Email Sales Tool",
    "h1": "Integrate Drupal with SalesBlink",
    "metaDescription": "Integrate Drupal and other Website Builders Tools with SalesBlink. Two Way Drupal Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Creatomate",
    "slug": "creatomate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ec0d4e7e6c97aa70a27a609d5fc4c1b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Creatomate lets you automatically create videos & banners from templates",
    "category": "Video & Audio",
    "title": "Creatomate Integration With Cold Email Sales Tool",
    "h1": "Integrate Creatomate with SalesBlink",
    "metaDescription": "Integrate Creatomate and other Video & Audio Tools with SalesBlink. Two Way Creatomate Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "INBOX",
    "slug": "inbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/12524d55d7aeaf043246b66fe4e24da3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "INBOX offers an email marketing platform that is easy to use for small businesses and organizations.",
    "category": "Email",
    "title": "INBOX Integration With Cold Email Sales Tool",
    "h1": "Integrate INBOX with SalesBlink",
    "metaDescription": "Integrate INBOX and other Email Tools with SalesBlink. Two Way INBOX Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "B2B Wave",
    "slug": "b2b-wave",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/756c8f62eb738879cab5de49359e6011.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "B2B Wave is the easiest cloud B2B eCommerce solution for distributors, wholesalers, and manufacturers",
    "category": "eCommerce",
    "title": "B2B Wave Integration With Cold Email Sales Tool",
    "h1": "Integrate B2B Wave with SalesBlink",
    "metaDescription": "Integrate B2B Wave and other eCommerce Tools with SalesBlink. Two Way B2B Wave Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iMIS (iAppConnector)",
    "slug": "iappconnector-for-imis",
    "logo": "https://zapier-images.imgix.net/storage/services/0ca440b066ce70899f984bb3cafac01b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iMIS is a leading Engagement Management System for associations and non-profits",
    "category": "Databases",
    "title": "iMIS (iAppConnector) Integration With Cold Email Sales Tool",
    "h1": "Integrate iMIS (iAppConnector) with SalesBlink",
    "metaDescription": "Integrate iMIS (iAppConnector) and other Databases Tools with SalesBlink. Two Way iMIS (iAppConnector) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Delivra",
    "slug": "delivra",
    "logo": "https://zapier-images.imgix.net/storage/services/7498be942129e0b898350e1b14ab5474.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Delivra is a robust multi-channel marketing automation platform used to keep your prospects informed through their customer journey.  With intuitive data management and easy personalization tools, we make it easy to acquire, manage, and nurture leads through that other crucial period – after they’re your customer!",
    "category": "Marketing Automation",
    "title": "Delivra Integration With Cold Email Sales Tool",
    "h1": "Integrate Delivra with SalesBlink",
    "metaDescription": "Integrate Delivra and other Marketing Automation Tools with SalesBlink. Two Way Delivra Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Formium",
    "slug": "formium",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20e6b8d4515c7e1ed6f1db25a2a3d3f2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formik is an all-in-one workflow for building forms your users will actually love.",
    "category": "Forms & Surveys",
    "title": "Formium Integration With Cold Email Sales Tool",
    "h1": "Integrate Formium with SalesBlink",
    "metaDescription": "Integrate Formium and other Forms & Surveys Tools with SalesBlink. Two Way Formium Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Paythen",
    "slug": "paythen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/700e771d8d606e470593785487485900_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paythen lets businesses offer payment plans, one time payments, or a choice of both to their customers in under 60 seconds.",
    "category": "Payment Processing",
    "title": "Paythen Integration With Cold Email Sales Tool",
    "h1": "Integrate Paythen with SalesBlink",
    "metaDescription": "Integrate Paythen and other Payment Processing Tools with SalesBlink. Two Way Paythen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Asset Panda",
    "slug": "asset-panda",
    "logo": "https://zapier-images.imgix.net/storage/services/c5d69972481c0a09ae8a1a3f68281b87.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Asset Panda tracks and manages assets with reporting, alerts, workflows, and iOS/Android/desktop apps to track anything, anywhere, anytime.",
    "category": "eCommerce",
    "title": "Asset Panda Integration With Cold Email Sales Tool",
    "h1": "Integrate Asset Panda with SalesBlink",
    "metaDescription": "Integrate Asset Panda and other eCommerce Tools with SalesBlink. Two Way Asset Panda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cogsworth",
    "slug": "cogsworth",
    "logo": "https://zapier-images.imgix.net/storage/services/56a9a485aa95b61c437b673f2d6965b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cogsworth is an appointment and meeting scheduling app that allows people to book in time with you and your business when you are available. Cogsworth syncs with your calendar and pulls out your availability and also pushes your bookings back to your calendar.",
    "category": "Calendar",
    "title": "Cogsworth Integration With Cold Email Sales Tool",
    "h1": "Integrate Cogsworth with SalesBlink",
    "metaDescription": "Integrate Cogsworth and other Calendar Tools with SalesBlink. Two Way Cogsworth Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Printfection",
    "slug": "printfection",
    "logo": "https://zapier-images.imgix.net/storage/services/8c210dfd904fb29990392c672eab3668.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We help businesses grow their brand and customer engagement through the use of swag marketing.",
    "category": "Customer Appreciation",
    "title": "Printfection Integration With Cold Email Sales Tool",
    "h1": "Integrate Printfection with SalesBlink",
    "metaDescription": "Integrate Printfection and other Customer Appreciation Tools with SalesBlink. Two Way Printfection Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MOBIT",
    "slug": "mobit",
    "logo": "https://zapier-images.imgix.net/storage/developer/cc8b1911c854ade527bc4b06b4b252fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MOBIT is a mobile marketing automation platform. Create SMS and MMS campaigns with dynamic and intelligent mobile pages.",
    "category": "Marketing Automation",
    "title": "MOBIT Integration With Cold Email Sales Tool",
    "h1": "Integrate MOBIT with SalesBlink",
    "metaDescription": "Integrate MOBIT and other Marketing Automation Tools with SalesBlink. Two Way MOBIT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NiftyImages",
    "slug": "niftyimages",
    "logo": "https://zapier-images.imgix.net/storage/developer/9817608bb7ea914f99911a72144a0b73.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NiftyImages is an email marketing tool that lets you personalize and change images so each recipient sees the most relevant message.",
    "category": "Marketing Automation",
    "title": "NiftyImages Integration With Cold Email Sales Tool",
    "h1": "Integrate NiftyImages with SalesBlink",
    "metaDescription": "Integrate NiftyImages and other Marketing Automation Tools with SalesBlink. Two Way NiftyImages Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Neos",
    "slug": "neos",
    "logo": "https://zapier-images.imgix.net/storage/services/9043766721f0530f2074f5e064f44d79.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Neos is a cloud-based platform that helps law firms resolve more cases and improve overall efficiency.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Neos Integration With Cold Email Sales Tool",
    "h1": "Integrate Neos with SalesBlink",
    "metaDescription": "Integrate Neos and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Neos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AppDrag",
    "slug": "appdrag",
    "logo": "https://zapier-images.imgix.net/storage/services/6b7f707cc293485e5b023b9ed4d05524.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AppDrag is a website and web app builder.",
    "category": "App Builder",
    "title": "AppDrag Integration With Cold Email Sales Tool",
    "h1": "Integrate AppDrag with SalesBlink",
    "metaDescription": "Integrate AppDrag and other App Builder Tools with SalesBlink. Two Way AppDrag Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AroFlo",
    "slug": "aroflo-hmac",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f327780f1159c1646377a53fdc51e6c9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AroFlo is a job management system for trade/service based businesses.",
    "category": "Task Management",
    "title": "AroFlo Integration With Cold Email Sales Tool",
    "h1": "Integrate AroFlo with SalesBlink",
    "metaDescription": "Integrate AroFlo and other Task Management Tools with SalesBlink. Two Way AroFlo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Casebook",
    "slug": "casebook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/136d1a07774c6ef546b6d9a8ca4c6b0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Casebook is a platform for human and social services.",
    "category": "Productivity",
    "title": "Casebook Integration With Cold Email Sales Tool",
    "h1": "Integrate Casebook with SalesBlink",
    "metaDescription": "Integrate Casebook and other Productivity Tools with SalesBlink. Two Way Casebook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CosmoLex",
    "slug": "cosmolex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fdcc2708c17a645076fcfabf5a32522a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CosmoLex is law practice management software with built-in accounting, timekeeping, invoicing, document/email management, and matter workflows.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CosmoLex Integration With Cold Email Sales Tool",
    "h1": "Integrate CosmoLex with SalesBlink",
    "metaDescription": "Integrate CosmoLex and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CosmoLex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Feedier",
    "slug": "feedier",
    "logo": "https://zapier-images.imgix.net/storage/services/34b8b4e8cfbe2b8fa92027be29423d13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feedier lets you collect valuable feedback from your users by providing an enjoyable & rewarding experience.",
    "category": "Forms & Surveys",
    "title": "Feedier Integration With Cold Email Sales Tool",
    "h1": "Integrate Feedier with SalesBlink",
    "metaDescription": "Integrate Feedier and other Forms & Surveys Tools with SalesBlink. Two Way Feedier Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Fintoc",
    "slug": "fintoc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/10b2da8b75efdaeb9b07d1e4abdce4ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fintoc is an API that lets you connect with bank accounts in LATAM",
    "category": "Payment Processing",
    "title": "Fintoc Integration With Cold Email Sales Tool",
    "h1": "Integrate Fintoc with SalesBlink",
    "metaDescription": "Integrate Fintoc and other Payment Processing Tools with SalesBlink. Two Way Fintoc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mentortools",
    "slug": "mentortools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95dde9a4c2b4d5e497828bf71e4252c8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mentortools is an e-learning and online education platform that enables everyone to create online membership areas with multiple payment options.",
    "category": "Education",
    "title": "Mentortools Integration With Cold Email Sales Tool",
    "h1": "Integrate Mentortools with SalesBlink",
    "metaDescription": "Integrate Mentortools and other Education Tools with SalesBlink. Two Way Mentortools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Award Force",
    "slug": "award-force",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e40f6c8bbbc0cb41d4bb2680a539078e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Award Force is an awards management software for organisers of awards, grants, scholarships, and pitch competitions to manage entry, judging and award of results online.",
    "category": "Forms & Surveys",
    "title": "Award Force Integration With Cold Email Sales Tool",
    "h1": "Integrate Award Force with SalesBlink",
    "metaDescription": "Integrate Award Force and other Forms & Surveys Tools with SalesBlink. Two Way Award Force Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Formitable",
    "slug": "formitable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/574f73cecd2c187c5193aa430d5b4178.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create unique restaurant experiences, manage reservations easily and book a full house with Formitable.",
    "category": "Scheduling & Booking",
    "title": "Formitable Integration With Cold Email Sales Tool",
    "h1": "Integrate Formitable with SalesBlink",
    "metaDescription": "Integrate Formitable and other Scheduling & Booking Tools with SalesBlink. Two Way Formitable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pipeliner Cloud",
    "slug": "pipeliner-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/10a616b987876ad0c27f7dda930b46a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Next generation sales CRM software designed to empower sales teams and grow profitable customer relationships.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pipeliner Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipeliner Cloud with SalesBlink",
    "metaDescription": "Integrate Pipeliner Cloud and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pipeliner Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qoyod",
    "slug": "qoyod",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23c04fee217a1cd74fa9d466a933ea75.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qoyod is an accounting tool focused on supporting small and medium size businesses",
    "category": "Accounting",
    "title": "Qoyod Integration With Cold Email Sales Tool",
    "h1": "Integrate Qoyod with SalesBlink",
    "metaDescription": "Integrate Qoyod and other Accounting Tools with SalesBlink. Two Way Qoyod Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octane AI",
    "slug": "octane-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79f4af26e4fc5aa4b963332febb8a504.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octane AI is the zero-party data marketing platform for Shopify merchants.",
    "category": "Marketing Automation",
    "title": "Octane AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Octane AI with SalesBlink",
    "metaDescription": "Integrate Octane AI and other Marketing Automation Tools with SalesBlink. Two Way Octane AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YouLi",
    "slug": "youli",
    "logo": "https://zapier-images.imgix.net/storage/services/42ac53ad352f27e397b5b94a2843856e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YouLi is a group travel management platform that combines itinerary management, room inventory management, deck plans, “no-code” website + booking pages to ensure everyone involved in your group trip or retreat is on the same page. Manage registrations, collect payments, collect legal forms, assign tasks and share documents all in one place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "YouLi Integration With Cold Email Sales Tool",
    "h1": "Integrate YouLi with SalesBlink",
    "metaDescription": "Integrate YouLi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way YouLi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Acelle Mail",
    "slug": "acelle-mail",
    "logo": "https://zapier-images.imgix.net/storage/services/52e44b7f2e42119f2908de35a7dbe60a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Acelle Mail is a self hosted, open source, full-featured and easy to use email marketing web application written in PHP / Laravel 5 that lets you send high-volume marketing emails via your own server or through other email service (SMTP) providers.",
    "category": "Marketing Automation",
    "title": "Acelle Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate Acelle Mail with SalesBlink",
    "metaDescription": "Integrate Acelle Mail and other Marketing Automation Tools with SalesBlink. Two Way Acelle Mail Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Zenvia",
    "slug": "zenvia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e35b6c9266165d57daf7d6f7483dc52.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenvia is a communication platform that simplifies the relationship between companies and consumers.",
    "category": "Phone & SMS",
    "title": "Zenvia Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenvia with SalesBlink",
    "metaDescription": "Integrate Zenvia and other Phone & SMS Tools with SalesBlink. Two Way Zenvia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContentFly",
    "slug": "contentfly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/52e94c2c0ef0a16d16495b173632ffbf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContentFly is a fast and simple content writing service.",
    "category": "Marketing",
    "title": "ContentFly Integration With Cold Email Sales Tool",
    "h1": "Integrate ContentFly with SalesBlink",
    "metaDescription": "Integrate ContentFly and other Marketing Tools with SalesBlink. Two Way ContentFly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paycove",
    "slug": "paycove",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2255472d77fef875cc16329acc61a713.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paycove is a quoting, invoicing, and payments platform.",
    "category": "Proposal & Invoice Management",
    "title": "Paycove Integration With Cold Email Sales Tool",
    "h1": "Integrate Paycove with SalesBlink",
    "metaDescription": "Integrate Paycove and other Proposal & Invoice Management Tools with SalesBlink. Two Way Paycove Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Edusign",
    "slug": "edusign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ec49f41db102b940d29a916a6ab1fca_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital attendance sheets. Sign online and generate certified documents in seconds.",
    "category": "Signatures",
    "title": "Edusign Integration With Cold Email Sales Tool",
    "h1": "Integrate Edusign with SalesBlink",
    "metaDescription": "Integrate Edusign and other Signatures Tools with SalesBlink. Two Way Edusign Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "HirePOS",
    "slug": "hirepos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/668d64cc531c9f4ee217c1f2c2381ae9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HirePOS® is a modern web application that assists with all aspects of hire rental businesses from start to finish.",
    "category": "Accounting",
    "title": "HirePOS Integration With Cold Email Sales Tool",
    "h1": "Integrate HirePOS with SalesBlink",
    "metaDescription": "Integrate HirePOS and other Accounting Tools with SalesBlink. Two Way HirePOS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS Masivos",
    "slug": "sms-masivos",
    "logo": "https://zapier-images.imgix.net/storage/developer/83c5cb57541bb821b9d494872b1cac80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMS Masivos is a leading platform for sending bulk SMS messages towards all Latin American countries.",
    "category": "Phone & SMS",
    "title": "SMS Masivos Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Masivos with SalesBlink",
    "metaDescription": "Integrate SMS Masivos and other Phone & SMS Tools with SalesBlink. Two Way SMS Masivos Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "VirtualPBX",
    "slug": "virtualpbx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82699639de06c7a00a0d67d0a5ad9027.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VirtualPBX delivers leading edge VoIP phone systems for businesses of all sizes, backed by award-winning, 24/7 support.",
    "category": "Call Tracking",
    "title": "VirtualPBX Integration With Cold Email Sales Tool",
    "h1": "Integrate VirtualPBX with SalesBlink",
    "metaDescription": "Integrate VirtualPBX and other Call Tracking Tools with SalesBlink. Two Way VirtualPBX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Noticeable",
    "slug": "noticeable",
    "logo": "https://zapier-images.imgix.net/storage/services/eac4d13408893363e3e027828784b681.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Noticeable is an all-in-one solution to update your users effectively. Use it to engage your customers or your team with regular updates, get powerful feedback, and measure satisfaction.",
    "category": "Product Management",
    "title": "Noticeable Integration With Cold Email Sales Tool",
    "h1": "Integrate Noticeable with SalesBlink",
    "metaDescription": "Integrate Noticeable and other Product Management Tools with SalesBlink. Two Way Noticeable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "LiquidPlanner Classic",
    "slug": "liquid-planner-classic",
    "logo": "https://zapier-images.imgix.net/storage/developer/4f1d639b092136b34aa6838e04d8ebed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LiquidPlanner Classic, online project management with predictive scheduling that updates automatically when things change.",
    "category": "Project Management",
    "title": "LiquidPlanner Classic Integration With Cold Email Sales Tool",
    "h1": "Integrate LiquidPlanner Classic with SalesBlink",
    "metaDescription": "Integrate LiquidPlanner Classic and other Project Management Tools with SalesBlink. Two Way LiquidPlanner Classic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shotstack",
    "slug": "shotstack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a01bf1dd5f6d71a9b5cfa3e969444438.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shotstack is a service that allows you to edit videos, images and audio at scale.",
    "category": "Video & Audio",
    "title": "Shotstack Integration With Cold Email Sales Tool",
    "h1": "Integrate Shotstack with SalesBlink",
    "metaDescription": "Integrate Shotstack and other Video & Audio Tools with SalesBlink. Two Way Shotstack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Lead Updates",
    "slug": "lead-updates",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/38ed9ed80037bff30550b0fc1636fc7f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powerful Deal-Closing Sales Engagement Software. Maximize your reach with our scalable, multi-channel engagement platform driven by real data, customized strategy, and full automation.",
    "category": "Marketing Automation",
    "title": "Lead Updates Integration With Cold Email Sales Tool",
    "h1": "Integrate Lead Updates with SalesBlink",
    "metaDescription": "Integrate Lead Updates and other Marketing Automation Tools with SalesBlink. Two Way Lead Updates Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Iterate",
    "slug": "iterate",
    "logo": "https://zapier-images.imgix.net/storage/developer/669d272cfdac19ef3da6fddf021e65b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Iterate is a survey tool that helps you get answers to everything you need to build better products and make better decisions.",
    "category": "Forms & Surveys",
    "title": "Iterate Integration With Cold Email Sales Tool",
    "h1": "Integrate Iterate with SalesBlink",
    "metaDescription": "Integrate Iterate and other Forms & Surveys Tools with SalesBlink. Two Way Iterate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TicketCo",
    "slug": "ticketco",
    "logo": "https://zapier-images.imgix.net/storage/developer/ee3307e33fff611152e304746f073c81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TicketCo is a self service event tool that helps you mange your event ticketing and sales.",
    "category": "Event Management",
    "title": "TicketCo Integration With Cold Email Sales Tool",
    "h1": "Integrate TicketCo with SalesBlink",
    "metaDescription": "Integrate TicketCo and other Event Management Tools with SalesBlink. Two Way TicketCo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Foleon",
    "slug": "foleon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa1924b18ce0c54ca1e65134c5ca6793.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Foleon is a content creation platform that empowers business teams to create engaging and intelligent content experiences at scale.",
    "category": "Documents",
    "title": "Foleon Integration With Cold Email Sales Tool",
    "h1": "Integrate Foleon with SalesBlink",
    "metaDescription": "Integrate Foleon and other Documents Tools with SalesBlink. Two Way Foleon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Survey Booker",
    "slug": "survey-booker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea0ef53c5a7c5f0ed5c5fee346bd3746_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Survey Booker is the CRM system for Surveyors to help you grow sales, save time and manage your customers.",
    "category": "Sales & CRM",
    "title": "Survey Booker Integration With Cold Email Sales Tool",
    "h1": "Integrate Survey Booker with SalesBlink",
    "metaDescription": "Integrate Survey Booker and other Sales & CRM Tools with SalesBlink. Two Way Survey Booker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OpenCRM",
    "slug": "opencrm",
    "logo": "https://zapier-images.imgix.net/storage/developer/c86aff399d2a7b2cbb655076a5d97014.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OpenCRM is a UK CRM system that helps you manage sales, customers, contacts, finances, projects, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OpenCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate OpenCRM with SalesBlink",
    "metaDescription": "Integrate OpenCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OpenCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "360dialog",
    "slug": "360dialog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aec250693d7f6a455e90b3709f4c23a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "360dialog provides connection to the official WhatsApp Business API to send and receive WhatsApp messages",
    "category": "Marketing Automation",
    "title": "360dialog Integration With Cold Email Sales Tool",
    "h1": "Integrate 360dialog with SalesBlink",
    "metaDescription": "Integrate 360dialog and other Marketing Automation Tools with SalesBlink. Two Way 360dialog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Monetizze",
    "slug": "monetizze",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ec745ba7dc7b6c8014e927ead3623e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monetizze is a payment method with affiliate platform. There you can be a productor or affiliate, and sell info products or physical products.",
    "category": "Payment Processing",
    "title": "Monetizze Integration With Cold Email Sales Tool",
    "h1": "Integrate Monetizze with SalesBlink",
    "metaDescription": "Integrate Monetizze and other Payment Processing Tools with SalesBlink. Two Way Monetizze Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProductPlan",
    "slug": "productplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73f82a614002ec1e1c4b8eb61a24d181.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProductPlan is a roadmap platform that aligns your team so you can build what matters.",
    "category": "Product Management",
    "title": "ProductPlan Integration With Cold Email Sales Tool",
    "h1": "Integrate ProductPlan with SalesBlink",
    "metaDescription": "Integrate ProductPlan and other Product Management Tools with SalesBlink. Two Way ProductPlan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "idloom-events",
    "slug": "idloom-events",
    "logo": "https://zapier-images.imgix.net/storage/developer/45ed35117875bc779a754a335e4b666b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Idloom-events is a complete and professional tool that manages and automates all key aspects of event management.",
    "category": "Event Management",
    "title": "idloom-events Integration With Cold Email Sales Tool",
    "h1": "Integrate idloom-events with SalesBlink",
    "metaDescription": "Integrate idloom-events and other Event Management Tools with SalesBlink. Two Way idloom-events Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LAHAR",
    "slug": "lahar",
    "logo": "https://zapier-images.imgix.net/storage/developer/49af8712e08d688336f11276aee16a66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LAHAR is digital software that facilitates digital marketing tasks for small and medium-sized businesses and provides a complete package of tools for creating leads and creating relationships that deliver results.",
    "category": "Marketing Automation",
    "title": "LAHAR Integration With Cold Email Sales Tool",
    "h1": "Integrate LAHAR with SalesBlink",
    "metaDescription": "Integrate LAHAR and other Marketing Automation Tools with SalesBlink. Two Way LAHAR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HomeActions",
    "slug": "homeactions",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6769237cc9b9ed833bc9d0d1a650b78a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HomeActions is a leading provider of email newsletters for real estate professionals.  We deliver a simple, effective way for Real Estate professionals to consistently reach their entire group of contacts, keep referrals flowing and increase opportunities for sales.",
    "category": "Email Newsletters",
    "title": "HomeActions Integration With Cold Email Sales Tool",
    "h1": "Integrate HomeActions with SalesBlink",
    "metaDescription": "Integrate HomeActions and other Email Newsletters Tools with SalesBlink. Two Way HomeActions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Humi",
    "slug": "humi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94c9ba42007d95f239382c30fa31c0e9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Humi is the all-in-one HRIS software you’ll look forward to using every day.",
    "category": "Human Resources",
    "title": "Humi Integration With Cold Email Sales Tool",
    "h1": "Integrate Humi with SalesBlink",
    "metaDescription": "Integrate Humi and other Human Resources Tools with SalesBlink. Two Way Humi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Userlist",
    "slug": "userlist",
    "logo": "https://zapier-images.imgix.net/storage/services/0f7df998c3dc8c772e779376620c0c3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Userlist is an email automation tool tailored to SaaS needs: behavior-based campaigns, segmentation, and full support for company accounts.",
    "category": "Marketing",
    "title": "Userlist Integration With Cold Email Sales Tool",
    "h1": "Integrate Userlist with SalesBlink",
    "metaDescription": "Integrate Userlist and other Marketing Tools with SalesBlink. Two Way Userlist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flexmail",
    "slug": "flexmail",
    "logo": "https://zapier-images.imgix.net/storage/services/27ab835b376288c4b387032903605ae5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Flexmail to keep in touch with subscribers, leads and customers through email marketing. Send better emails through data, powerful targeting and automation.",
    "category": "Email Newsletters",
    "title": "Flexmail Integration With Cold Email Sales Tool",
    "h1": "Integrate Flexmail with SalesBlink",
    "metaDescription": "Integrate Flexmail and other Email Newsletters Tools with SalesBlink. Two Way Flexmail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Campaigner",
    "slug": "campaigner",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb137f81460883fed16a857f5cb3118f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Campaigner is a powerful multi-channel marketing platform that has robust features to deliver email and SMS campaigns that help you drive sales.",
    "category": "Marketing",
    "title": "Campaigner Integration With Cold Email Sales Tool",
    "h1": "Integrate Campaigner with SalesBlink",
    "metaDescription": "Integrate Campaigner and other Marketing Tools with SalesBlink. Two Way Campaigner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brex",
    "slug": "brex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8707adf364fa1a1040855d5d153ccff5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handle your company’s deposits, payments, credit cards, spend\nmanagement, and accounting in one place. Brex makes it refreshingly\neasy to see how your money’s being used and control how it’s spent.",
    "category": "Accounting",
    "title": "Brex Integration With Cold Email Sales Tool",
    "h1": "Integrate Brex with SalesBlink",
    "metaDescription": "Integrate Brex and other Accounting Tools with SalesBlink. Two Way Brex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Proofer",
    "slug": "proofer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6d1ed787fb5fa3ef24e1acb496e2a11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Proofer is a Shopify app that lets you manage your custom order proofs",
    "category": "File Management & Storage",
    "title": "Proofer Integration With Cold Email Sales Tool",
    "h1": "Integrate Proofer with SalesBlink",
    "metaDescription": "Integrate Proofer and other File Management & Storage Tools with SalesBlink. Two Way Proofer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KwesForms",
    "slug": "kwes",
    "logo": "https://zapier-images.imgix.net/storage/services/0a77169c7cfaf9c638680084b07c50a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KwesForms is a complete form service for developers. Comes with form building components, validation, spam protection, and emails.",
    "category": "Forms & Surveys",
    "title": "KwesForms Integration With Cold Email Sales Tool",
    "h1": "Integrate KwesForms with SalesBlink",
    "metaDescription": "Integrate KwesForms and other Forms & Surveys Tools with SalesBlink. Two Way KwesForms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CalendarApp",
    "slug": "calendarapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84445ba0598e38007857f6c9f62598b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create a booking calendar for your website with only a few clicks. Manage all incoming requests and bookings for your period of time and point in time services.",
    "category": "Calendar",
    "title": "CalendarApp Integration With Cold Email Sales Tool",
    "h1": "Integrate CalendarApp with SalesBlink",
    "metaDescription": "Integrate CalendarApp and other Calendar Tools with SalesBlink. Two Way CalendarApp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DriverReach",
    "slug": "driverreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/deeb855678a27d3131c170eb09e60821.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DriverReach is a Recruiting Management System for hiring CDL drivers. Use the DriverReach Action to send driver lead info to DriverReach.",
    "category": "HR Talent & Recruitment",
    "title": "DriverReach Integration With Cold Email Sales Tool",
    "h1": "Integrate DriverReach with SalesBlink",
    "metaDescription": "Integrate DriverReach and other HR Talent & Recruitment Tools with SalesBlink. Two Way DriverReach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ForceManager",
    "slug": "forcemanager",
    "logo": "https://zapier-images.imgix.net/storage/developer/4c5f6b40ecc77bae7642db60594ffcfb_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ForceManager is a mobile CRM that allows you to measure, analyse and improve your sales team’s performance.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ForceManager Integration With Cold Email Sales Tool",
    "h1": "Integrate ForceManager with SalesBlink",
    "metaDescription": "Integrate ForceManager and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ForceManager Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Interseller",
    "slug": "interseller",
    "logo": "https://zapier-images.imgix.net/storage/developer/ab553b29af469eca6c7b9efc622ccc6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Interseller helps you find contacts' email addresses, send them messages and push them into any CRM or ATS with one-click.",
    "category": "Contact Management",
    "title": "Interseller Integration With Cold Email Sales Tool",
    "h1": "Integrate Interseller with SalesBlink",
    "metaDescription": "Integrate Interseller and other Contact Management Tools with SalesBlink. Two Way Interseller Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Spot-Hit",
    "slug": "spot-hit",
    "logo": "https://zapier-images.imgix.net/storage/services/68f8464532ef6f407dda3fc4ed234d49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SPOT HIT is the french leading plateform to manage all your campaigns (SMS, Rich SMS, email)",
    "category": "Phone & SMS",
    "title": "Spot-Hit Integration With Cold Email Sales Tool",
    "h1": "Integrate Spot-Hit with SalesBlink",
    "metaDescription": "Integrate Spot-Hit and other Phone & SMS Tools with SalesBlink. Two Way Spot-Hit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Makeswift",
    "slug": "makeswift",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/364343b7568140f3d08452e88cf34915_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Makeswift is a no-code website builder, thoughtfully designed to put you in flow and give you full creative control.",
    "category": "Website & App Building",
    "title": "Makeswift Integration With Cold Email Sales Tool",
    "h1": "Integrate Makeswift with SalesBlink",
    "metaDescription": "Integrate Makeswift and other Website & App Building Tools with SalesBlink. Two Way Makeswift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Foxit eSign",
    "slug": "foxit-esign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4e26e40760c743066d13b90fd04ea7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Foxit eSign is an end-to-end cloud-based esignature solution that is cost-effective, comprehensive, and legally binding with Templates, Bulk Signing, Online Forms, APIs. Foxit eSign also offers convenience of signing from within Foxit Desktop and Cloud PDF.",
    "category": "Signatures",
    "title": "Foxit eSign Integration With Cold Email Sales Tool",
    "h1": "Integrate Foxit eSign with SalesBlink",
    "metaDescription": "Integrate Foxit eSign and other Signatures Tools with SalesBlink. Two Way Foxit eSign Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "FileStack",
    "slug": "filestack",
    "logo": "https://zapier-images.imgix.net/storage/developer/e21c9e9decfe1006e59dfa667a97da3f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Super API for End User Content. Upload and store files, seamlessly apply transformations, & deliver via powerful CDN",
    "category": "File Management & Storage",
    "title": "FileStack Integration With Cold Email Sales Tool",
    "h1": "Integrate FileStack with SalesBlink",
    "metaDescription": "Integrate FileStack and other File Management & Storage Tools with SalesBlink. Two Way FileStack Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BigMailer",
    "slug": "bigmailer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/88b729baf5d8097594bb16eb9e38ea69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BigMailer is email marketing software for agencies and developers.",
    "category": "Email",
    "title": "BigMailer Integration With Cold Email Sales Tool",
    "h1": "Integrate BigMailer with SalesBlink",
    "metaDescription": "Integrate BigMailer and other Email Tools with SalesBlink. Two Way BigMailer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Azure Web Apps",
    "slug": "windows-azure-web-sites",
    "logo": "https://zapier-images.imgix.net/storage/developer/3a43f2ce2ccccea008321a1189d04d14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Windows Azure App Service is a highly scalable website hosting solution with flexible development options including integration with Git, Mercurial and Dropbox.",
    "category": "Microsoft",
    "title": "Azure Web Apps Integration With Cold Email Sales Tool",
    "h1": "Integrate Azure Web Apps with SalesBlink",
    "metaDescription": "Integrate Azure Web Apps and other Microsoft Tools with SalesBlink. Two Way Azure Web Apps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DoorLoop",
    "slug": "doorloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a60d9d7f0f3b7f71770f12f71b7cf9c4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DoorLoop is a powerful software for managing all your properties. Market units, screen prospects, collect rent, handle bookkeeping, & more.",
    "category": "Productivity",
    "title": "DoorLoop Integration With Cold Email Sales Tool",
    "h1": "Integrate DoorLoop with SalesBlink",
    "metaDescription": "Integrate DoorLoop and other Productivity Tools with SalesBlink. Two Way DoorLoop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rock",
    "slug": "rock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b724d87c58d9e4bd5ac482345210dded.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Purpose-built for remote teams. Messaging, tasks, notes, Google Drive, and Zoom together.",
    "category": "Productivity",
    "title": "Rock Integration With Cold Email Sales Tool",
    "h1": "Integrate Rock with SalesBlink",
    "metaDescription": "Integrate Rock and other Productivity Tools with SalesBlink. Two Way Rock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailercloud",
    "slug": "mailercloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/26a5ac4906566fe5ea015a66f0c7af92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailercloud is a cloud-based email marketing platform that allows businesses to launch email marketing campaigns without having any technical expertise.",
    "category": "Email Newsletters",
    "title": "Mailercloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailercloud with SalesBlink",
    "metaDescription": "Integrate Mailercloud and other Email Newsletters Tools with SalesBlink. Two Way Mailercloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Noloco",
    "slug": "noloco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43944f6f21195ab61349495922c4912b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Noloco is a no-code client portal builder, you can create the perfect experience for your clients and your team built around your existing workflows.",
    "category": "App Builder",
    "title": "Noloco Integration With Cold Email Sales Tool",
    "h1": "Integrate Noloco with SalesBlink",
    "metaDescription": "Integrate Noloco and other App Builder Tools with SalesBlink. Two Way Noloco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Autoklose",
    "slug": "autoklose",
    "logo": "https://zapier-images.imgix.net/storage/developer/f8a37a40ce60043d8a60b57f6df0f235.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Autoklose is a sales platform that automates email followup and outreach to boost your team’s productivity and performance.",
    "category": "Email",
    "title": "Autoklose Integration With Cold Email Sales Tool",
    "h1": "Integrate Autoklose with SalesBlink",
    "metaDescription": "Integrate Autoklose and other Email Tools with SalesBlink. Two Way Autoklose Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Appointedd",
    "slug": "appointedd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f759d90827253741e713232c819b2af_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appointedd is the world’s smartest online booking system for all your business needs.",
    "category": "Scheduling & Booking",
    "title": "Appointedd Integration With Cold Email Sales Tool",
    "h1": "Integrate Appointedd with SalesBlink",
    "metaDescription": "Integrate Appointedd and other Scheduling & Booking Tools with SalesBlink. Two Way Appointedd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workamajig",
    "slug": "workamajig",
    "logo": "https://zapier-images.imgix.net/storage/developer/61ac74a84798f8848cf28232ace14ad4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workamajig Creative Manager now allows you to add contacts, companies, opportunities and activities to the CRM module.",
    "category": "Project Management",
    "title": "Workamajig Integration With Cold Email Sales Tool",
    "h1": "Integrate Workamajig with SalesBlink",
    "metaDescription": "Integrate Workamajig and other Project Management Tools with SalesBlink. Two Way Workamajig Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "On2Air",
    "slug": "on2air",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8c7cfe4f4abedf159c2494e3a0b2b4d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "On2Air is the complete suite of products to be efficient and maximize platforms such as Airtable.",
    "category": "Databases",
    "title": "On2Air Integration With Cold Email Sales Tool",
    "h1": "Integrate On2Air with SalesBlink",
    "metaDescription": "Integrate On2Air and other Databases Tools with SalesBlink. Two Way On2Air Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kirim.Email",
    "slug": "kirimemail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/868cb69b35c00fc94d55b16aa4249edd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kirim.Email is an email marketing solution for any size of business, currently operated in the Indonesian and ASEAN market. Send your newsletter easily with KIRIM.EMAIL.",
    "category": "Email Newsletters",
    "title": "Kirim.Email Integration With Cold Email Sales Tool",
    "h1": "Integrate Kirim.Email with SalesBlink",
    "metaDescription": "Integrate Kirim.Email and other Email Newsletters Tools with SalesBlink. Two Way Kirim.Email Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "XING Events",
    "slug": "xing-events",
    "logo": "https://zapier-images.imgix.net/storage/developer/b9c5349d5e74f700b862d7026186c7f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "XING Events is an event management tool that lets event organizers sell tickets online.",
    "category": "Event Management",
    "title": "XING Events Integration With Cold Email Sales Tool",
    "h1": "Integrate XING Events with SalesBlink",
    "metaDescription": "Integrate XING Events and other Event Management Tools with SalesBlink. Two Way XING Events Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReferralHero",
    "slug": "referralhero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/136030eea85d4cd491dad81ee1209a68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReferralHero is a powerful referral marketing platform to build any type of referral program.",
    "category": "Marketing Automation",
    "title": "ReferralHero Integration With Cold Email Sales Tool",
    "h1": "Integrate ReferralHero with SalesBlink",
    "metaDescription": "Integrate ReferralHero and other Marketing Automation Tools with SalesBlink. Two Way ReferralHero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "StoryChief",
    "slug": "storychief",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff2441c64fa58414d0728c614c22fdde.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoryChief is a Content Marketing Software for startups, SEO marketers and editorial teams who want to increase their reach.",
    "category": "Marketing Automation",
    "title": "StoryChief Integration With Cold Email Sales Tool",
    "h1": "Integrate StoryChief with SalesBlink",
    "metaDescription": "Integrate StoryChief and other Marketing Automation Tools with SalesBlink. Two Way StoryChief Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Hype",
    "slug": "pico",
    "logo": "https://zapier-images.imgix.net/storage/services/37848e55e75c8087704236fa9d46c5f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build a website in minutes, send marketing blasts, and earn revenue from subscriptions and tips – all in one place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Hype Integration With Cold Email Sales Tool",
    "h1": "Integrate Hype with SalesBlink",
    "metaDescription": "Integrate Hype and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Hype Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bookafy",
    "slug": "bookafy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9ef28b5cd2bb70715559148f88940949_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bookafy is an easy tool to share your calendar for booking appointments, demos and group interviews.",
    "category": "Scheduling & Booking",
    "title": "Bookafy Integration With Cold Email Sales Tool",
    "h1": "Integrate Bookafy with SalesBlink",
    "metaDescription": "Integrate Bookafy and other Scheduling & Booking Tools with SalesBlink. Two Way Bookafy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Createsend",
    "slug": "createsend",
    "logo": "https://zapier-images.imgix.net/storage/developer/d1241331cb8caa7b3b1645a48d496f0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Createsend is an email marketing tool built for designers. Createsend makes it easy to send beautiful emails, manage lists and subscribers, and track the results of your campaigns.",
    "category": "Email Newsletters",
    "title": "Createsend Integration With Cold Email Sales Tool",
    "h1": "Integrate Createsend with SalesBlink",
    "metaDescription": "Integrate Createsend and other Email Newsletters Tools with SalesBlink. Two Way Createsend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WaiverFile",
    "slug": "waiverfile",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bcc041c6b9c3cc4b172bd3731675f0e2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WaiverFile lets your customers sign waivers from a computer, tablet or mobile device. From your website or at a kiosk, completing the forms is quick and easy.",
    "category": "Forms & Surveys",
    "title": "WaiverFile Integration With Cold Email Sales Tool",
    "h1": "Integrate WaiverFile with SalesBlink",
    "metaDescription": "Integrate WaiverFile and other Forms & Surveys Tools with SalesBlink. Two Way WaiverFile Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GoVisually",
    "slug": "govisually",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7a532dc344a3d06ebba2b46371bec1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoVisually is the simplest online proofing solution to get visual feedback directly on Designs, PDFs & Video content.",
    "category": "App Builder",
    "title": "GoVisually Integration With Cold Email Sales Tool",
    "h1": "Integrate GoVisually with SalesBlink",
    "metaDescription": "Integrate GoVisually and other App Builder Tools with SalesBlink. Two Way GoVisually Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "intelliHR",
    "slug": "intellihr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c53a459faeab9d09902a1e66f7994751_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one people management system with configurable HR automation and analytics",
    "category": "HR Talent & Recruitment",
    "title": "intelliHR Integration With Cold Email Sales Tool",
    "h1": "Integrate intelliHR with SalesBlink",
    "metaDescription": "Integrate intelliHR and other HR Talent & Recruitment Tools with SalesBlink. Two Way intelliHR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Walla Form",
    "slug": "walla-form",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9c89eaa41da86afcece5e5500ee297ae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Walla is an all-in-one form tool to create, share, deploy and analyze online forms.",
    "category": "Forms & Surveys",
    "title": "Walla Form Integration With Cold Email Sales Tool",
    "h1": "Integrate Walla Form with SalesBlink",
    "metaDescription": "Integrate Walla Form and other Forms & Surveys Tools with SalesBlink. Two Way Walla Form Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobiniti SMS",
    "slug": "mobiniti-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer/0d6b63d68a1f1d00eb73a341fa4380fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobiniti SMS is a tool to integrate SMS with your business applications.",
    "category": "Phone & SMS",
    "title": "Mobiniti SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobiniti SMS with SalesBlink",
    "metaDescription": "Integrate Mobiniti SMS and other Phone & SMS Tools with SalesBlink. Two Way Mobiniti SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FormCan",
    "slug": "formcan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6aecda92d3a6b8d45e43053c5dccbc81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FormCan is the next generation of online form builders with stunning form builders, live previews and a huge range of gorgeous templates and themes.",
    "category": "Forms & Surveys",
    "title": "FormCan Integration With Cold Email Sales Tool",
    "h1": "Integrate FormCan with SalesBlink",
    "metaDescription": "Integrate FormCan and other Forms & Surveys Tools with SalesBlink. Two Way FormCan Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "BannerSeason",
    "slug": "bannerseason",
    "logo": "https://zapier-images.imgix.net/storage/developer/9a765666d6ebbc9135d8c21c8bd1df2a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BannerSeason is a platform that enables you to Send gifts to WOW your customers with real personalized tangible cards and gifts in the mail.",
    "category": "Marketing Automation",
    "title": "BannerSeason Integration With Cold Email Sales Tool",
    "h1": "Integrate BannerSeason with SalesBlink",
    "metaDescription": "Integrate BannerSeason and other Marketing Automation Tools with SalesBlink. Two Way BannerSeason Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convoso",
    "slug": "convoso",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9bb7afb5e9ee8cb340a74952da5dc7c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convoso is a cloud-based call center software for sales and lead generation teams.",
    "category": "Sales & CRM",
    "title": "Convoso Integration With Cold Email Sales Tool",
    "h1": "Integrate Convoso with SalesBlink",
    "metaDescription": "Integrate Convoso and other Sales & CRM Tools with SalesBlink. Two Way Convoso Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PageProof",
    "slug": "pageproof",
    "logo": "https://zapier-images.imgix.net/storage/developer/7d136e582c6b37ab9198df9c583811c4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PageProof is a review and approval platform for all of your marketing content, including artwork, audio, video, HTML websites & banners.",
    "category": "Documents",
    "title": "PageProof Integration With Cold Email Sales Tool",
    "h1": "Integrate PageProof with SalesBlink",
    "metaDescription": "Integrate PageProof and other Documents Tools with SalesBlink. Two Way PageProof Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Re:plain",
    "slug": "replain",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f02abc0eabe11bef44fe93786b1f00ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Re:plain is a live chat platform for your messenger. Send messages from your website directly to Facebook Messenger or Telegram and back. \nCurrently only Telegram is supported in the integration.",
    "category": "Customer Support",
    "title": "Re:plain Integration With Cold Email Sales Tool",
    "h1": "Integrate Re:plain with SalesBlink",
    "metaDescription": "Integrate Re:plain and other Customer Support Tools with SalesBlink. Two Way Re:plain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Revaluate",
    "slug": "revaluate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f6046d4391bd135c342af7ce781ac92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revaluate is an artificial intelligence platform for the real estate and mortgage industries that helps agents and loan officers know when they contacts are likely to move.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Revaluate Integration With Cold Email Sales Tool",
    "h1": "Integrate Revaluate with SalesBlink",
    "metaDescription": "Integrate Revaluate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Revaluate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coworks",
    "slug": "coworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87bbadfa24c0602f41d04783b9b3f9b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coworks is a coworking management platform solution for automating running a coworking space.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Coworks Integration With Cold Email Sales Tool",
    "h1": "Integrate Coworks with SalesBlink",
    "metaDescription": "Integrate Coworks and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Coworks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invoco",
    "slug": "invoco",
    "logo": "https://zapier-images.imgix.net/storage/developer/2ad52d2f00edcaea9c7e8be7a5ac54bc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Invoco is an easy to use call tracking and hosted telephony platform.",
    "category": "Phone & SMS",
    "title": "Invoco Integration With Cold Email Sales Tool",
    "h1": "Integrate Invoco with SalesBlink",
    "metaDescription": "Integrate Invoco and other Phone & SMS Tools with SalesBlink. Two Way Invoco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FAX.PLUS",
    "slug": "fax-plus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c59d340bd4b8436122791cb3ce3517e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FAX.PLUS is a secure and reliable online fax service that enables enterprises and small to mid-size businesses to send & receive fax online.",
    "category": "Fax",
    "title": "FAX.PLUS Integration With Cold Email Sales Tool",
    "h1": "Integrate FAX.PLUS with SalesBlink",
    "metaDescription": "Integrate FAX.PLUS and other Fax Tools with SalesBlink. Two Way FAX.PLUS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flowdash",
    "slug": "flowdash",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16d21e9daf13ec0b9303996535f8b532.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The most flexible platform for fast-growing companies to manage, track & complete their work.",
    "category": "Task Management",
    "title": "Flowdash Integration With Cold Email Sales Tool",
    "h1": "Integrate Flowdash with SalesBlink",
    "metaDescription": "Integrate Flowdash and other Task Management Tools with SalesBlink. Two Way Flowdash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fresh Proposals",
    "slug": "fresh-proposals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a71dd5b2da87bcc66809da9c09372739.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreshProposals is  a Proposal management software to organize proposals and keep track of them. Allows end users to sign or reject proposal",
    "category": "Proposal & Invoice Management",
    "title": "Fresh Proposals Integration With Cold Email Sales Tool",
    "h1": "Integrate Fresh Proposals with SalesBlink",
    "metaDescription": "Integrate Fresh Proposals and other Proposal & Invoice Management Tools with SalesBlink. Two Way Fresh Proposals Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Tallyfy",
    "slug": "tallyfy",
    "logo": "https://zapier-images.imgix.net/storage/developer/06c7cd3c077b1d97aa14eb37ec2f1b1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tallyfy is a workflow platform that eliminates chaos from your repeatable processes - freeing up your time.",
    "category": "Task Management",
    "title": "Tallyfy Integration With Cold Email Sales Tool",
    "h1": "Integrate Tallyfy with SalesBlink",
    "metaDescription": "Integrate Tallyfy and other Task Management Tools with SalesBlink. Two Way Tallyfy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SMS.to",
    "slug": "smsto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8149c6c55066925ad534da72f0665023_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMS.to is a SMS Marketing tool to organize contact lists and send campaigns and messages.",
    "category": "Phone & SMS",
    "title": "SMS.to Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS.to with SalesBlink",
    "metaDescription": "Integrate SMS.to and other Phone & SMS Tools with SalesBlink. Two Way SMS.to Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outbound by Enreach",
    "slug": "outbound-by-enreach",
    "logo": "https://zapier-images.imgix.net/storage/services/e9dc355f428ed6e9b54d3cd92cd50ad8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outbound by Enreach is a cloud-based solution that helps sales organizations call leads, prospects or customers as smart and efficiently as possible.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Outbound by Enreach Integration With Cold Email Sales Tool",
    "h1": "Integrate Outbound by Enreach with SalesBlink",
    "metaDescription": "Integrate Outbound by Enreach and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Outbound by Enreach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSLink",
    "slug": "smslink",
    "logo": "https://zapier-images.imgix.net/storage/developer/effd2e083fd1daba972873cb33e03a3c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSLink is a SMS provider that provides integrated services such as SMS Marketing, SMS Gateway, Mail to SMS and SMS Alerts.",
    "category": "Phone & SMS",
    "title": "SMSLink Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSLink with SalesBlink",
    "metaDescription": "Integrate SMSLink and other Phone & SMS Tools with SalesBlink. Two Way SMSLink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beacon",
    "slug": "beacon",
    "logo": "https://zapier-images.imgix.net/storage/services/c30cbfa8d0ed4e270fc801f9fde03908.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beacon is the modern CRM for charities. It's easy to use, cloud-based, and flexible enough for anything.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Beacon Integration With Cold Email Sales Tool",
    "h1": "Integrate Beacon with SalesBlink",
    "metaDescription": "Integrate Beacon and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Beacon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eduflow",
    "slug": "eduflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e7a6627edc365f13b4e87958a44dd644.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learning experiences made easy, social and interactive",
    "category": "Online Courses",
    "title": "Eduflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Eduflow with SalesBlink",
    "metaDescription": "Integrate Eduflow and other Online Courses Tools with SalesBlink. Two Way Eduflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Norby",
    "slug": "norby",
    "logo": "https://zapier-images.imgix.net/storage/services/a820c95d3253d5e15d79775e667f73c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build your brand, grow your community, and activate your audience through Norby, your all-in-one marketing tool.",
    "category": "Event Management",
    "title": "Norby Integration With Cold Email Sales Tool",
    "h1": "Integrate Norby with SalesBlink",
    "metaDescription": "Integrate Norby and other Event Management Tools with SalesBlink. Two Way Norby Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadferno",
    "slug": "leadferno",
    "logo": "https://zapier-images.imgix.net/storage/services/a406187318525935bdfde7277397ae16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text with customers right from your website using a powerful messaging inbox – so your team can increase conversions, close leads faster and delight your customers.",
    "category": "Communication",
    "title": "Leadferno Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadferno with SalesBlink",
    "metaDescription": "Integrate Leadferno and other Communication Tools with SalesBlink. Two Way Leadferno Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartOffice",
    "slug": "smartoffice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/271d28c907522dfba2144aea3b7bc9cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartOffice, powered by Ebix, is the premier CRM solution for the financial services industry. We help insurance agents and financial advisors manage and better understand their existing clients while attracting new ones.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SmartOffice Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartOffice with SalesBlink",
    "metaDescription": "Integrate SmartOffice and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SmartOffice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saasu",
    "slug": "saasu",
    "logo": "https://zapier-images.imgix.net/storage/developer/c2ca20aa43e9852864c82e0519591478.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fast, simple online accounting for small business that includes cashflow, invoicing, inventory, payroll and support on all plans.",
    "category": "Accounting",
    "title": "Saasu Integration With Cold Email Sales Tool",
    "h1": "Integrate Saasu with SalesBlink",
    "metaDescription": "Integrate Saasu and other Accounting Tools with SalesBlink. Two Way Saasu Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Humanity",
    "slug": "humanity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b34cf71cbce485b639f0593ac031646.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TCP Software's dynamic scheduling tool, Humanity, simplifies day-to-day employee management processes. With Humanity Scheduling, your organization can easily and accurately create error-free, dynamic schedules for your staff by location, department, position, and/or skills—with real-time availability conflict checking.",
    "category": "Scheduling & Booking",
    "title": "Humanity Integration With Cold Email Sales Tool",
    "h1": "Integrate Humanity with SalesBlink",
    "metaDescription": "Integrate Humanity and other Scheduling & Booking Tools with SalesBlink. Two Way Humanity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ViralSweep",
    "slug": "viralsweep",
    "logo": "https://zapier-images.imgix.net/storage/developer/eb10b09dfeeba9995768e87aa7bb0b7a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A platform for businesses to build and run social promotions.",
    "category": "eCommerce",
    "title": "ViralSweep Integration With Cold Email Sales Tool",
    "h1": "Integrate ViralSweep with SalesBlink",
    "metaDescription": "Integrate ViralSweep and other eCommerce Tools with SalesBlink. Two Way ViralSweep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TOPdesk",
    "slug": "topdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/567a0c88dd93b9634ab39964167784ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get service departments working together. Easily connect with customers, and exceed their expectations every single time. All thanks to TOPdesk’s Enterprise Service Management Platform.",
    "category": "IT Operations",
    "title": "TOPdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate TOPdesk with SalesBlink",
    "metaDescription": "Integrate TOPdesk and other IT Operations Tools with SalesBlink. Two Way TOPdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RSVPify",
    "slug": "rsvpify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dffd1e28cd54bec332d122b9976bbdf3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RSVPify is a powerful, end-to-end event management and online ticketing platform.",
    "category": "Event Management",
    "title": "RSVPify Integration With Cold Email Sales Tool",
    "h1": "Integrate RSVPify with SalesBlink",
    "metaDescription": "Integrate RSVPify and other Event Management Tools with SalesBlink. Two Way RSVPify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtualbadge.io",
    "slug": "virtualbadgeio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d75f9c92c92c85d44552f26adb92c04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Virtualbadge.io is a digital certification software that lets you issue certificates for your clients.",
    "category": "Education",
    "title": "Virtualbadge.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtualbadge.io with SalesBlink",
    "metaDescription": "Integrate Virtualbadge.io and other Education Tools with SalesBlink. Two Way Virtualbadge.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dialfire",
    "slug": "dialfire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/812d378f778aeeefafb8c9f98b2f6dc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dialfire is a complete solution for contact centres that includes dialing, campaign management, controlling and statistics.",
    "category": "Contact Management",
    "title": "Dialfire Integration With Cold Email Sales Tool",
    "h1": "Integrate Dialfire with SalesBlink",
    "metaDescription": "Integrate Dialfire and other Contact Management Tools with SalesBlink. Two Way Dialfire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prospero",
    "slug": "prospero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7d1bf88bcd3d37a2c087e2487093f252.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create proposals with ease.\nImpress your clients and win more projects.",
    "category": "Proposal & Invoice Management",
    "title": "Prospero Integration With Cold Email Sales Tool",
    "h1": "Integrate Prospero with SalesBlink",
    "metaDescription": "Integrate Prospero and other Proposal & Invoice Management Tools with SalesBlink. Two Way Prospero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Buying Buddy",
    "slug": "buying-buddy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/235d3bb1913ac542897fa541dd41380d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Buying Buddy is a real estate sales and marketing solution with IDX, lead capture and CRM for Agents and Teams.",
    "category": "Contact Management",
    "title": "Buying Buddy Integration With Cold Email Sales Tool",
    "h1": "Integrate Buying Buddy with SalesBlink",
    "metaDescription": "Integrate Buying Buddy and other Contact Management Tools with SalesBlink. Two Way Buying Buddy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Super Receptionist",
    "slug": "super-receptionist",
    "logo": "https://zapier-images.imgix.net/storage/developer/9c230b5afaa985a08cd508d4a97936c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Super Receptionist is a call centre solution that lets you set up a call handling process for customer care, sales and other departments.",
    "category": "Phone & SMS",
    "title": "Super Receptionist Integration With Cold Email Sales Tool",
    "h1": "Integrate Super Receptionist with SalesBlink",
    "metaDescription": "Integrate Super Receptionist and other Phone & SMS Tools with SalesBlink. Two Way Super Receptionist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anvil",
    "slug": "anvil",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b497636f974561e6fb3202bbd334f98a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anvil lets you automate your paperwork and business processes: Data-capture, PDF preparation, and e-sign working seamlessly together.",
    "category": "Signatures",
    "title": "Anvil Integration With Cold Email Sales Tool",
    "h1": "Integrate Anvil with SalesBlink",
    "metaDescription": "Integrate Anvil and other Signatures Tools with SalesBlink. Two Way Anvil Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Speak2Leads",
    "slug": "speak2leads",
    "logo": "https://zapier-images.imgix.net/storage/services/01950dbe28f29ae68365b8bea16599f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Speak2Leads converts your new inbound leads into phone calls to your reps to help them respond quickly and persistently and make more sales.",
    "category": "Phone & SMS",
    "title": "Speak2Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Speak2Leads with SalesBlink",
    "metaDescription": "Integrate Speak2Leads and other Phone & SMS Tools with SalesBlink. Two Way Speak2Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadSnap",
    "slug": "leadsnap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc3897d006fcf32ffa4fc38918c67330.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead Snap is an App to submit and manage leads at https://app.leadsnap.com",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadSnap Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadSnap with SalesBlink",
    "metaDescription": "Integrate LeadSnap and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadSnap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Keeper",
    "slug": "keeper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/60d8dacc7cae0f13572692a2eff0a19f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keeper is an app to run your bookkeeping business: communicate with clients, catch coding errors, track KPIs, and manage your work.",
    "category": "Business Intelligence",
    "title": "Keeper Integration With Cold Email Sales Tool",
    "h1": "Integrate Keeper with SalesBlink",
    "metaDescription": "Integrate Keeper and other Business Intelligence Tools with SalesBlink. Two Way Keeper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FindThatLead",
    "slug": "findthatlead",
    "logo": "https://zapier-images.imgix.net/storage/services/8dd72fa3e87cd7ec478412736d40ea3c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FindThatLead is a tool to automate your lead generation and increase your sales",
    "category": "CRM (Customer Relationship Management)",
    "title": "FindThatLead Integration With Cold Email Sales Tool",
    "h1": "Integrate FindThatLead with SalesBlink",
    "metaDescription": "Integrate FindThatLead and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FindThatLead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Metatask",
    "slug": "metatask",
    "logo": "https://zapier-images.imgix.net/storage/developer/ca2c63f6e8315535690b0daacf3703d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Metatask is a simple tool to organize and control internal processes that lets normal users quickly build and execute business workflows.",
    "category": "Task Management",
    "title": "Metatask Integration With Cold Email Sales Tool",
    "h1": "Integrate Metatask with SalesBlink",
    "metaDescription": "Integrate Metatask and other Task Management Tools with SalesBlink. Two Way Metatask Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Gainsight",
    "slug": "gainsight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f8b077ecf96f56c096226bd29ef4a292.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gainsight™ is a CRM that helps businesses build deep and lasting relationships with their customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Gainsight Integration With Cold Email Sales Tool",
    "h1": "Integrate Gainsight with SalesBlink",
    "metaDescription": "Integrate Gainsight and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Gainsight Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ClearoutPhone",
    "slug": "clearoutphone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c74ae5548bbb30d2cf5a808e6d76e7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClearoutPhone is a global phone validator covering 240+ countries with carrier and line type lookup.",
    "category": "Phone & SMS",
    "title": "ClearoutPhone Integration With Cold Email Sales Tool",
    "h1": "Integrate ClearoutPhone with SalesBlink",
    "metaDescription": "Integrate ClearoutPhone and other Phone & SMS Tools with SalesBlink. Two Way ClearoutPhone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loanzify",
    "slug": "loanzify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c93f521b7a875c15686963513a42a63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loanzify Digital POS platform is a fully customizable loan and client management tool.",
    "category": "Marketing Automation",
    "title": "Loanzify Integration With Cold Email Sales Tool",
    "h1": "Integrate Loanzify with SalesBlink",
    "metaDescription": "Integrate Loanzify and other Marketing Automation Tools with SalesBlink. Two Way Loanzify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bkper",
    "slug": "bkper",
    "logo": "https://zapier-images.imgix.net/storage/developer/6dcef6a35c3cc11bf9e1492f286cec15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keep track of earnings, expenses and account balances, in a centralized and collaborative way.",
    "category": "Accounting",
    "title": "Bkper Integration With Cold Email Sales Tool",
    "h1": "Integrate Bkper with SalesBlink",
    "metaDescription": "Integrate Bkper and other Accounting Tools with SalesBlink. Two Way Bkper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lessonly",
    "slug": "lessonly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e87ad9e06195c1430f153f2dc715d42.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lessonly is a powerfully simple training software that helps customer service and sales teams do better work.",
    "category": "Online Courses",
    "title": "Lessonly Integration With Cold Email Sales Tool",
    "h1": "Integrate Lessonly with SalesBlink",
    "metaDescription": "Integrate Lessonly and other Online Courses Tools with SalesBlink. Two Way Lessonly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "allmysms",
    "slug": "allmysms",
    "logo": "https://zapier-images.imgix.net/storage/developer/f0347a57916ef889a723dad2f294c43a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Allmysms is a tool that lets you send SMS to your customers.",
    "category": "Phone & SMS",
    "title": "allmysms Integration With Cold Email Sales Tool",
    "h1": "Integrate allmysms with SalesBlink",
    "metaDescription": "Integrate allmysms and other Phone & SMS Tools with SalesBlink. Two Way allmysms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Invoice2go",
    "slug": "invoice2go",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eaab31fe8b2b38ee1cfcee951314e709.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Invoice2go is the all-in-one tool that helps you run your small business. Manage customer relationships, send invoices, accept payments, improve cash flow, create an online presence, and much more.",
    "category": "Proposal & Invoice Management",
    "title": "Invoice2go Integration With Cold Email Sales Tool",
    "h1": "Integrate Invoice2go with SalesBlink",
    "metaDescription": "Integrate Invoice2go and other Proposal & Invoice Management Tools with SalesBlink. Two Way Invoice2go Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brick FTP",
    "slug": "brick-ftp",
    "logo": "https://zapier-images.imgix.net/storage/developer/90daeadf92e7a5b80c1645680afbf91b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get your own server to send and receive files to and from your clients, vendors and co-workers. Supports any browser, operating system, or FTP program. Flexible permissions and military-strength encryption protect your files.",
    "category": "File Management & Storage",
    "title": "Brick FTP Integration With Cold Email Sales Tool",
    "h1": "Integrate Brick FTP with SalesBlink",
    "metaDescription": "Integrate Brick FTP and other File Management & Storage Tools with SalesBlink. Two Way Brick FTP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "REIPro",
    "slug": "reipro",
    "logo": "https://zapier-images.imgix.net/storage/services/76a009974bbc183f981b33755626b8e5.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "REIPro is the All-In-One Real Estate Investor Software CRM, Lead Generation, Deal Analyzer and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "REIPro Integration With Cold Email Sales Tool",
    "h1": "Integrate REIPro with SalesBlink",
    "metaDescription": "Integrate REIPro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way REIPro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Audienceful",
    "slug": "audienceful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a014d783bc76e4425fda4f77af168d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Audienceful is lightweight email marketing that feels like using your favorite notes app. Newsletters, drip sequences, multiple audiences and more.",
    "category": "Email Newsletters",
    "title": "Audienceful Integration With Cold Email Sales Tool",
    "h1": "Integrate Audienceful with SalesBlink",
    "metaDescription": "Integrate Audienceful and other Email Newsletters Tools with SalesBlink. Two Way Audienceful Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ning",
    "slug": "ning",
    "logo": "https://zapier-images.imgix.net/storage/services/1d30f99f029f3f6fdc5d1be92f59b3b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ning is a SaaS platform for creating websites with great social integrations.",
    "category": "Social Media Accounts",
    "title": "Ning Integration With Cold Email Sales Tool",
    "h1": "Integrate Ning with SalesBlink",
    "metaDescription": "Integrate Ning and other Social Media Accounts Tools with SalesBlink. Two Way Ning Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Realty.com",
    "slug": "realtycom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d008f99e19314649adc377bc67766b84.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Realty.com is a popular real estate portal focused on delivering quality real estate leads to Agents, Lenders & Brokers.",
    "category": "Contact Management",
    "title": "Realty.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Realty.com with SalesBlink",
    "metaDescription": "Integrate Realty.com and other Contact Management Tools with SalesBlink. Two Way Realty.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eworks Manager",
    "slug": "eworks-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3cd833585b1c3ba2dbd1a8f9e94a375.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eworks Manager is an award-winning Job Management software that allows your business to manage jobs and projects from Quote through to invoice",
    "category": "Project Management",
    "title": "Eworks Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Eworks Manager with SalesBlink",
    "metaDescription": "Integrate Eworks Manager and other Project Management Tools with SalesBlink. Two Way Eworks Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brillium",
    "slug": "brillium",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5753ca5fea1520d69f507ba968d72b21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A powerful assessment platform for creating and delivering exams, quizzes, certifications, and surveys across desktop and mobile platforms. Easy-to-use authoring, detailed reporting, and built-in integration with leading learning and talent management platforms.",
    "category": "HR Talent & Recruitment",
    "title": "Brillium Integration With Cold Email Sales Tool",
    "h1": "Integrate Brillium with SalesBlink",
    "metaDescription": "Integrate Brillium and other HR Talent & Recruitment Tools with SalesBlink. Two Way Brillium Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hoops",
    "slug": "hoops-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d97283c7f36d85bb4d0d3837710d3460_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hoops is powerful software that streamlines the management and workflow of printing and promotional product businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Hoops Integration With Cold Email Sales Tool",
    "h1": "Integrate Hoops with SalesBlink",
    "metaDescription": "Integrate Hoops and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Hoops Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pipefile",
    "slug": "pipefile",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/233c40c9056d2ed00a100e6bc40e1797.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pipefile streamlines the collection of personal, financial, and otherwise sensitive documents to keep your business moving",
    "category": "Content & Files",
    "title": "Pipefile Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipefile with SalesBlink",
    "metaDescription": "Integrate Pipefile and other Content & Files Tools with SalesBlink. Two Way Pipefile Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Symphony",
    "slug": "symphony",
    "logo": "https://zapier-images.imgix.net/storage/developer/a391f1a21dda62b43b673fbf536861fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Symphony is a communications platform that securely connects markets and individuals, promoting collaboration and increasing productivity while maintaining organizational compliance.",
    "category": "Team Chat",
    "title": "Symphony Integration With Cold Email Sales Tool",
    "h1": "Integrate Symphony with SalesBlink",
    "metaDescription": "Integrate Symphony and other Team Chat Tools with SalesBlink. Two Way Symphony Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Typedream",
    "slug": "typedream",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/58e66f3d737d16aa9fd8ce69a1347295.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Typedream is a website builder with a simple interface designed for the no-code community.",
    "category": "Website & App Building",
    "title": "Typedream Integration With Cold Email Sales Tool",
    "h1": "Integrate Typedream with SalesBlink",
    "metaDescription": "Integrate Typedream and other Website & App Building Tools with SalesBlink. Two Way Typedream Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coupontools",
    "slug": "coupontools",
    "logo": "https://zapier-images.imgix.net/storage/services/1b89667bab613901419459aa1531513f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coupontools is an easy solution for the creation, distribution and validation of digital coupons.",
    "category": "Marketing",
    "title": "Coupontools Integration With Cold Email Sales Tool",
    "h1": "Integrate Coupontools with SalesBlink",
    "metaDescription": "Integrate Coupontools and other Marketing Tools with SalesBlink. Two Way Coupontools Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Any.do Workspace",
    "slug": "anydo-workspace",
    "logo": "https://zapier-images.imgix.net/storage/services/61b852266b12ca1eb1394f2dfeca7a0b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Any.do is a productivity app to help you organize your tasks, to-do lists, and schedule in one place as well as collaborate with your team.",
    "category": "Project Management",
    "title": "Any.do Workspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Any.do Workspace with SalesBlink",
    "metaDescription": "Integrate Any.do Workspace and other Project Management Tools with SalesBlink. Two Way Any.do Workspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadCars",
    "slug": "leadcars",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/401209e004448ef67f922edae0dd16b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadCars is a lead management application for the automotive industry in Spain, Portugal, and soon in other countries.",
    "category": "Marketing",
    "title": "LeadCars Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadCars with SalesBlink",
    "metaDescription": "Integrate LeadCars and other Marketing Tools with SalesBlink. Two Way LeadCars Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Siteglide",
    "slug": "siteglide",
    "logo": "https://zapier-images.imgix.net/storage/services/5d5389fa4cbcaa61b29f182d9e39472f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Siteglide is a Digital Experience Platform (DXP) that helps Agencies build & manage Websites, Portals, Marketplaces & Bespoke Applications.",
    "category": "Website Builders",
    "title": "Siteglide Integration With Cold Email Sales Tool",
    "h1": "Integrate Siteglide with SalesBlink",
    "metaDescription": "Integrate Siteglide and other Website Builders Tools with SalesBlink. Two Way Siteglide Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Blinq",
    "slug": "blinq",
    "logo": "https://zapier-images.imgix.net/storage/services/e2eb83031e02f3a552f73ad123ee29c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blinq digital business cards allow you to instantly share who you are, with anyone, wherever you go.",
    "category": "Contact Management",
    "title": "Blinq Integration With Cold Email Sales Tool",
    "h1": "Integrate Blinq with SalesBlink",
    "metaDescription": "Integrate Blinq and other Contact Management Tools with SalesBlink. Two Way Blinq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Timesheet",
    "slug": "timesheet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a70467a7d61c04cc12797fdc1b3cad12_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timesheet is a mobile Time Tracker which allows you the recording of your working hours with a simple push of a button.",
    "category": "Time Tracking Software",
    "title": "Timesheet Integration With Cold Email Sales Tool",
    "h1": "Integrate Timesheet with SalesBlink",
    "metaDescription": "Integrate Timesheet and other Time Tracking Software Tools with SalesBlink. Two Way Timesheet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orca Scan",
    "slug": "orca-scan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03fcb9a8e67b9f308ce0af650f85befd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Orca Scan is a no-code barcode system; add/remove fields to build the barcode system you want.",
    "category": "Content & Files",
    "title": "Orca Scan Integration With Cold Email Sales Tool",
    "h1": "Integrate Orca Scan with SalesBlink",
    "metaDescription": "Integrate Orca Scan and other Content & Files Tools with SalesBlink. Two Way Orca Scan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Byteplant Address Validator",
    "slug": "byteplant-address-validator-ca20",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c457adc4b23f5adf21729438f7d4d4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Byteplant Address-Validator verifies addresses from 240 countries world-wide in real-time - see if an address is deliverable or use the suggested address corrections the service provides",
    "category": "eCommerce",
    "title": "Byteplant Address Validator Integration With Cold Email Sales Tool",
    "h1": "Integrate Byteplant Address Validator with SalesBlink",
    "metaDescription": "Integrate Byteplant Address Validator and other eCommerce Tools with SalesBlink. Two Way Byteplant Address Validator Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CampLegal",
    "slug": "camplegal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02696a556cff0d9f89bc7a15d4e7a079.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CampLegal is a practice management software for LawFirms.",
    "category": "Contact Management",
    "title": "CampLegal Integration With Cold Email Sales Tool",
    "h1": "Integrate CampLegal with SalesBlink",
    "metaDescription": "Integrate CampLegal and other Contact Management Tools with SalesBlink. Two Way CampLegal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dakno",
    "slug": "dakno",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f7653b904c1b699eea86f9607a98cc9.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dakno Marketing builds lead capturing tools to assist those in the real estate industry.",
    "category": "Website Builders",
    "title": "Dakno Integration With Cold Email Sales Tool",
    "h1": "Integrate Dakno with SalesBlink",
    "metaDescription": "Integrate Dakno and other Website Builders Tools with SalesBlink. Two Way Dakno Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chatlio",
    "slug": "chatlio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cbf12a67111007434ddf9a16b4aaf34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chatlio is a live chat tool to chat with visitors directly from Slack.",
    "category": "Customer Support",
    "title": "Chatlio Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatlio with SalesBlink",
    "metaDescription": "Integrate Chatlio and other Customer Support Tools with SalesBlink. Two Way Chatlio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BulkGate",
    "slug": "bulkgate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/12e3c094662f84bdd9a9659156588034.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BulkGate is a messaging platform that enables companies and individuals to spread their message to anyone, anywhere in the world with personalized bulk SMS and SMS notifications.",
    "category": "Phone & SMS",
    "title": "BulkGate Integration With Cold Email Sales Tool",
    "h1": "Integrate BulkGate with SalesBlink",
    "metaDescription": "Integrate BulkGate and other Phone & SMS Tools with SalesBlink. Two Way BulkGate Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Knorish",
    "slug": "knorish",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19edf5d61cbf95473cfb5302e0aeea28.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Knorish is a tool for creating & selling online courses using powerful sales funnels & automation.",
    "category": "Education",
    "title": "Knorish Integration With Cold Email Sales Tool",
    "h1": "Integrate Knorish with SalesBlink",
    "metaDescription": "Integrate Knorish and other Education Tools with SalesBlink. Two Way Knorish Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "GoodDay.work",
    "slug": "gooddaywork",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a7c48610727125a7e1c8176d9d2adee1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoodDay is a modern work management platform that brings together the best tools for high-level planning, project and product management, task organization and productivity growth based on transparency, agility, and motivation.",
    "category": "Project Management",
    "title": "GoodDay.work Integration With Cold Email Sales Tool",
    "h1": "Integrate GoodDay.work with SalesBlink",
    "metaDescription": "Integrate GoodDay.work and other Project Management Tools with SalesBlink. Two Way GoodDay.work Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apotheo",
    "slug": "apotheo",
    "logo": "https://zapier-images.imgix.net/storage/services/cd810f2340fce3bfb4e25b79f1b108df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apotheo is a client management tool for evidence based nutrition coaches.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Apotheo Integration With Cold Email Sales Tool",
    "h1": "Integrate Apotheo with SalesBlink",
    "metaDescription": "Integrate Apotheo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Apotheo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Influitive",
    "slug": "influitive",
    "logo": "https://zapier-images.imgix.net/storage/services/ae230e8c905c3f3a4d631b6607c37656_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Influitive is an app that helps you motivate and mobilize your most valued customers and advocates. Give your advocates challenges so they can help your business. Track their progress, and reward them for their efforts.",
    "category": "Marketing Automation",
    "title": "Influitive Integration With Cold Email Sales Tool",
    "h1": "Integrate Influitive with SalesBlink",
    "metaDescription": "Integrate Influitive and other Marketing Automation Tools with SalesBlink. Two Way Influitive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dixa",
    "slug": "dixa",
    "logo": "https://zapier-images.imgix.net/storage/services/f37951728bdf2b7f10f13ab62c8e3a38.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dixa is omnichannel customer service software that unifies voice, email, chat, and messaging apps into one single platform.",
    "category": "Customer Support",
    "title": "Dixa Integration With Cold Email Sales Tool",
    "h1": "Integrate Dixa with SalesBlink",
    "metaDescription": "Integrate Dixa and other Customer Support Tools with SalesBlink. Two Way Dixa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WhatTime",
    "slug": "whattime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b72b7620f3267b41fcaf7cfc6c451d90_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WhatTime is a free online meeting scheduling tool.",
    "category": "Scheduling & Booking",
    "title": "WhatTime Integration With Cold Email Sales Tool",
    "h1": "Integrate WhatTime with SalesBlink",
    "metaDescription": "Integrate WhatTime and other Scheduling & Booking Tools with SalesBlink. Two Way WhatTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WorkRamp",
    "slug": "workramp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68219969ce3c8b5f9cc86d58125777c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WorkRamp is the All-in-One Platform that powers learning as a growth engine for the modern enterprise.",
    "category": "Education",
    "title": "WorkRamp Integration With Cold Email Sales Tool",
    "h1": "Integrate WorkRamp with SalesBlink",
    "metaDescription": "Integrate WorkRamp and other Education Tools with SalesBlink. Two Way WorkRamp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Club OS",
    "slug": "club-os",
    "logo": "https://zapier-images.imgix.net/storage/services/c5239e054fb3eef5f5d9119cba760ff9_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The gym and studio management software you need to sell more memberships and keep more members.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Club OS Integration With Cold Email Sales Tool",
    "h1": "Integrate Club OS with SalesBlink",
    "metaDescription": "Integrate Club OS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Club OS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Walla",
    "slug": "walla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e944c8594bc1bed57b90518d5fd55e7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Walla is studio management software for the boutique fitness industry, allowing the modern studio to handle booking, billing, clients, reporting, and virtual offerings.",
    "category": "Scheduling & Booking",
    "title": "Walla Integration With Cold Email Sales Tool",
    "h1": "Integrate Walla with SalesBlink",
    "metaDescription": "Integrate Walla and other Scheduling & Booking Tools with SalesBlink. Two Way Walla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outscraper",
    "slug": "outscraper-ca1391",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eec10a714f621c23f4f22138d1a92dfa_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outscraper is a service that allows you to scraper the data and reviews from Google Maps in real-time. Use simple queries and extract any amount of local businesses into JSON.",
    "category": "Databases",
    "title": "Outscraper Integration With Cold Email Sales Tool",
    "h1": "Integrate Outscraper with SalesBlink",
    "metaDescription": "Integrate Outscraper and other Databases Tools with SalesBlink. Two Way Outscraper Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Assignar",
    "slug": "assignar",
    "logo": "https://zapier-images.imgix.net/storage/services/fe4b9e3335544993d63ec006b5ee6572.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Assignar is a workforce and asset management platform for construction sub contractor and self-perform general contractors.",
    "category": "Scheduling & Booking",
    "title": "Assignar Integration With Cold Email Sales Tool",
    "h1": "Integrate Assignar with SalesBlink",
    "metaDescription": "Integrate Assignar and other Scheduling & Booking Tools with SalesBlink. Two Way Assignar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bambelo",
    "slug": "bambelo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd577021c52e3a577cdf3062f9f252a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bambelo is a platform which helps connect consumers with the right partners for their services.",
    "category": "Marketing Automation",
    "title": "Bambelo Integration With Cold Email Sales Tool",
    "h1": "Integrate Bambelo with SalesBlink",
    "metaDescription": "Integrate Bambelo and other Marketing Automation Tools with SalesBlink. Two Way Bambelo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Postalytics",
    "slug": "postalytics",
    "logo": "https://zapier-images.imgix.net/storage/developer/236fdd59a82201e32d8b2b0dfec3d777.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Postalytics turns direct mail into a digital channel providing tools to build, send and track postcards and letters.",
    "category": "Marketing Automation",
    "title": "Postalytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Postalytics with SalesBlink",
    "metaDescription": "Integrate Postalytics and other Marketing Automation Tools with SalesBlink. Two Way Postalytics Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CoPilot AI",
    "slug": "copilot-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ebac05f8c382437ad4659e1cbaac347_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CoPilot AI is a contact management solution for building your sales pipeline and uncovering hidden revenue with social lead generation.",
    "category": "Contact Management",
    "title": "CoPilot AI Integration With Cold Email Sales Tool",
    "h1": "Integrate CoPilot AI with SalesBlink",
    "metaDescription": "Integrate CoPilot AI and other Contact Management Tools with SalesBlink. Two Way CoPilot AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lunatask",
    "slug": "lunatask",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3017fff4c25a0913edf9207e3d3c432.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lunatask is an all-in-one privacy-focused todo list, notebook, habit and mood tracker, and pomodoro timer.",
    "category": "Productivity",
    "title": "Lunatask Integration With Cold Email Sales Tool",
    "h1": "Integrate Lunatask with SalesBlink",
    "metaDescription": "Integrate Lunatask and other Productivity Tools with SalesBlink. Two Way Lunatask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Studiocart",
    "slug": "studiocart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/114581f8f2ab6268f6762efce123c3d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily create beautiful, customizable checkout pages and automated sales flows from your own WordPress site.",
    "category": "eCommerce",
    "title": "Studiocart Integration With Cold Email Sales Tool",
    "h1": "Integrate Studiocart with SalesBlink",
    "metaDescription": "Integrate Studiocart and other eCommerce Tools with SalesBlink. Two Way Studiocart Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Portals by Helium10",
    "slug": "helium10-portals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6a357c6110b820cb3756a93d0fb16f5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Portals allows you to create your own custom landing pages to capture leads, increase sales, and grow your Amazon business.",
    "category": "eCommerce",
    "title": "Portals by Helium10 Integration With Cold Email Sales Tool",
    "h1": "Integrate Portals by Helium10 with SalesBlink",
    "metaDescription": "Integrate Portals by Helium10 and other eCommerce Tools with SalesBlink. Two Way Portals by Helium10 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Remarkety",
    "slug": "remarkety",
    "logo": "https://zapier-images.imgix.net/storage/developer/5c1e8afd3d2539bd7cd9ed72ce9d53ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Remarkety is an email marketing automation tool built only for eCommerce!\nIncrease loyalty and LTV with data-driven, automated campaigns.",
    "category": "Marketing Automation",
    "title": "Remarkety Integration With Cold Email Sales Tool",
    "h1": "Integrate Remarkety with SalesBlink",
    "metaDescription": "Integrate Remarkety and other Marketing Automation Tools with SalesBlink. Two Way Remarkety Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TestGorilla",
    "slug": "testgorilla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a0d1f0517b4fd5cd58f331be865d117.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TestGorilla provides fast, unbiased, affordable screen testing for job candidates.",
    "category": "HR Talent & Recruitment",
    "title": "TestGorilla Integration With Cold Email Sales Tool",
    "h1": "Integrate TestGorilla with SalesBlink",
    "metaDescription": "Integrate TestGorilla and other HR Talent & Recruitment Tools with SalesBlink. Two Way TestGorilla Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Funnelking",
    "slug": "funnelking",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f23345c55a74c17d6a7a13b61ee38408_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funnelking is an all-in-one software for small and medium-sized companies to successfully digitize their design, marketing, and sales.",
    "category": "Website Builders",
    "title": "Funnelking Integration With Cold Email Sales Tool",
    "h1": "Integrate Funnelking with SalesBlink",
    "metaDescription": "Integrate Funnelking and other Website Builders Tools with SalesBlink. Two Way Funnelking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bCast",
    "slug": "bcast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/013e7cbd0c251afa9a01a23d811e89aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bCast is a podcast hosting, distribution, and analytics platform designed to transform listeners into leads.",
    "category": "Marketing Automation",
    "title": "bCast Integration With Cold Email Sales Tool",
    "h1": "Integrate bCast with SalesBlink",
    "metaDescription": "Integrate bCast and other Marketing Automation Tools with SalesBlink. Two Way bCast Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Namely",
    "slug": "namely",
    "logo": "https://zapier-images.imgix.net/storage/services/0b0a7586ae122e01d4539039f04771df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Namely is an easy-to-use Human Resources software platform that integrates personnel info, payroll and benefits administration, and goal-setting/performance management into an intuitive user interface.",
    "category": "HR Talent & Recruitment",
    "title": "Namely Integration With Cold Email Sales Tool",
    "h1": "Integrate Namely with SalesBlink",
    "metaDescription": "Integrate Namely and other HR Talent & Recruitment Tools with SalesBlink. Two Way Namely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clarity CRM",
    "slug": "clarity-crm-da93679",
    "logo": "https://zapier-images.imgix.net/storage/developer/fbd0771c44d1b5eb45d0cb8fce211582.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clarity CRM is a customer relationship management tool that lets you to manage account, contacts and build invoices and orders.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clarity CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Clarity CRM with SalesBlink",
    "metaDescription": "Integrate Clarity CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clarity CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FreshLearn",
    "slug": "freshlearn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7698534d3793703463f1168d61bc0acc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreshLearn is a learning platform that lets you build your own online schools to create and sell courses as well as LMS for your organizations.",
    "category": "Online Courses",
    "title": "FreshLearn Integration With Cold Email Sales Tool",
    "h1": "Integrate FreshLearn with SalesBlink",
    "metaDescription": "Integrate FreshLearn and other Online Courses Tools with SalesBlink. Two Way FreshLearn Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "PrintJobManager",
    "slug": "pjm-da93749",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1df57143bf19d33f21daad3f48e4e976.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aleyant PrintJobManager is simplified MIS/ERP solution. It provides job pricing, management, inventory tracking, and job tracking.",
    "category": "Printing",
    "title": "PrintJobManager Integration With Cold Email Sales Tool",
    "h1": "Integrate PrintJobManager with SalesBlink",
    "metaDescription": "Integrate PrintJobManager and other Printing Tools with SalesBlink. Two Way PrintJobManager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SaaSquatch",
    "slug": "saasquatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e3bd89ff1c3c9992898df35589a94afb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SaaSquatch gives companies a new lever for growth using referral and loyalty programs. Quickly launch and optimize programs that drive customer acquisition and reducing churn.",
    "category": "Marketing",
    "title": "SaaSquatch Integration With Cold Email Sales Tool",
    "h1": "Integrate SaaSquatch with SalesBlink",
    "metaDescription": "Integrate SaaSquatch and other Marketing Tools with SalesBlink. Two Way SaaSquatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DynaPictures",
    "slug": "dynapictures",
    "logo": "https://zapier-images.imgix.net/storage/services/1418f55ae7a5b16908324f2f614a311e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Design image templates and auto-generate personalized images via API.",
    "category": "Marketing Automation",
    "title": "DynaPictures Integration With Cold Email Sales Tool",
    "h1": "Integrate DynaPictures with SalesBlink",
    "metaDescription": "Integrate DynaPictures and other Marketing Automation Tools with SalesBlink. Two Way DynaPictures Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pleo",
    "slug": "pleo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a44b8ba1b9223d4ca62ba13a602d7e8f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pleo offers smart company cards that automate expense reports and simplify company spending.",
    "category": "Accounting",
    "title": "Pleo Integration With Cold Email Sales Tool",
    "h1": "Integrate Pleo with SalesBlink",
    "metaDescription": "Integrate Pleo and other Accounting Tools with SalesBlink. Two Way Pleo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Real Estate Webmasters",
    "slug": "real-estate-webmasters",
    "logo": "https://zapier-images.imgix.net/storage/developer/ad1de2a627ba57aa64dc780299634235.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real Estate Webmasters is a real estate Software-as-a-Service CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Real Estate Webmasters Integration With Cold Email Sales Tool",
    "h1": "Integrate Real Estate Webmasters with SalesBlink",
    "metaDescription": "Integrate Real Estate Webmasters and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Real Estate Webmasters Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tracker",
    "slug": "tracker-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d7a569991fdd782daefcfd15f623221.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tracker helps recruitment and staffing firms build better relationships, workflows, and revenue with our integrated Recruitment ATS and CRM",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate Tracker with SalesBlink",
    "metaDescription": "Integrate Tracker and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tracker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formitize",
    "slug": "formitize",
    "logo": "https://zapier-images.imgix.net/storage/developer/60defff2bb28a1213d2ec0c17f778ee8.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formitize is an all around customizable solution for moving from paper based forms to electronic based solutions. The wide variety of uses include from dispatching jobs, to house inspections, sales and even call-quality assurance.",
    "category": "Forms & Surveys",
    "title": "Formitize Integration With Cold Email Sales Tool",
    "h1": "Integrate Formitize with SalesBlink",
    "metaDescription": "Integrate Formitize and other Forms & Surveys Tools with SalesBlink. Two Way Formitize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blueshift",
    "slug": "blueshift",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4edc4e066900ca68e3a03c342b559152.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blueshift is a CDP + Marketing Automation platform that empowers you to create the omnichannel experiences your customers expect.",
    "category": "Marketing Automation",
    "title": "Blueshift Integration With Cold Email Sales Tool",
    "h1": "Integrate Blueshift with SalesBlink",
    "metaDescription": "Integrate Blueshift and other Marketing Automation Tools with SalesBlink. Two Way Blueshift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocketManager",
    "slug": "docketmanager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c115822b36d5b89fa5b01e002617c2ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocketManager is a complete print business management system offering a fully integrated cloud based MIS and Web to Print platform.",
    "category": "Printing",
    "title": "DocketManager Integration With Cold Email Sales Tool",
    "h1": "Integrate DocketManager with SalesBlink",
    "metaDescription": "Integrate DocketManager and other Printing Tools with SalesBlink. Two Way DocketManager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smart Alto",
    "slug": "smart-alto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ef6fa091f29abcb887e4f3311611c91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart Alto is a messaging platform that responds to your leads within 2 minutes and nurtures past clients monthly",
    "category": "CRM (Customer Relationship Management)",
    "title": "Smart Alto Integration With Cold Email Sales Tool",
    "h1": "Integrate Smart Alto with SalesBlink",
    "metaDescription": "Integrate Smart Alto and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Smart Alto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tovuti LMS",
    "slug": "tovuti",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b470a852e1243ac1d50426de374efdc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tovuti is an all-in-one, cloud-based learning management system. Tovuti gives you all the tools you need to create, deliver and track the effectiveness of your eLearning programs.",
    "category": "HR Talent & Recruitment",
    "title": "Tovuti LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Tovuti LMS with SalesBlink",
    "metaDescription": "Integrate Tovuti LMS and other HR Talent & Recruitment Tools with SalesBlink. Two Way Tovuti LMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Channel.io",
    "slug": "channelio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b570d792b463419788b378dbdf315d8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Channel.io is an all-in-one tool to chat with customers and teammates, create chat bot for automation, and get insight about your business.",
    "category": "Customer Support",
    "title": "Channel.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Channel.io with SalesBlink",
    "metaDescription": "Integrate Channel.io and other Customer Support Tools with SalesBlink. Two Way Channel.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Web Shop Manager",
    "slug": "web-shop-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd0737a2ebffb6f6c1d83d9310fd4abc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Web Shop Manager is an eCommerce platform which specializes in the Storage, Searching, and Display of complex product catalogs.",
    "category": "eCommerce",
    "title": "Web Shop Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Web Shop Manager with SalesBlink",
    "metaDescription": "Integrate Web Shop Manager and other eCommerce Tools with SalesBlink. Two Way Web Shop Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JetBrains Space",
    "slug": "jetbrains-space",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f4b7b44975484f1f98e1fd720278d9b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JetBrains Space is an all-in-one collaboration solution for creative teams that includes chats, blogs, meetings, VCS, code review, CI/CD, package repositories, and issues.",
    "category": "Team Collaboration",
    "title": "JetBrains Space Integration With Cold Email Sales Tool",
    "h1": "Integrate JetBrains Space with SalesBlink",
    "metaDescription": "Integrate JetBrains Space and other Team Collaboration Tools with SalesBlink. Two Way JetBrains Space Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spoke",
    "slug": "spoke-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e35922c45c2e66e4c2c55a1d0d332bb5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spoke is the best way to record, edit and share all your conversations.",
    "category": "Video Conferencing",
    "title": "Spoke Integration With Cold Email Sales Tool",
    "h1": "Integrate Spoke with SalesBlink",
    "metaDescription": "Integrate Spoke and other Video Conferencing Tools with SalesBlink. Two Way Spoke Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Membrain",
    "slug": "membrain",
    "logo": "https://zapier-images.imgix.net/storage/services/791f5b08468a8d6babdf9ad9474fc4da_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Membrain's software and services make it easy for sales teams to execute a sales strategy to consistently reach sales targets.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Membrain Integration With Cold Email Sales Tool",
    "h1": "Integrate Membrain with SalesBlink",
    "metaDescription": "Integrate Membrain and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Membrain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Woodpecker",
    "slug": "woodpecker-2",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/45447d35cee2df7dc8af5bb8e29b9e63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Woodpecker is a document automation platform that allows you to automatically prepare & populate frequently used documents for new recipients without ever having to open Microsoft Word!",
    "category": "Documents",
    "title": "Woodpecker Integration With Cold Email Sales Tool",
    "h1": "Integrate Woodpecker with SalesBlink",
    "metaDescription": "Integrate Woodpecker and other Documents Tools with SalesBlink. Two Way Woodpecker Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Clay",
    "slug": "clay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c637763114b939554ce93b81a779590_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clay is a simple spreadsheet interface, yet powerful automation platform that allows you to build internal tooling and automations to streamline your marketing, sales, and recruiting.",
    "category": "Spreadsheets",
    "title": "Clay Integration With Cold Email Sales Tool",
    "h1": "Integrate Clay with SalesBlink",
    "metaDescription": "Integrate Clay and other Spreadsheets Tools with SalesBlink. Two Way Clay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Daily.co",
    "slug": "dailyco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cae4e0606d5c3d2ea3e5b66f5ab4a765.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Daily.co lets you add video calls to any web page or app.",
    "category": "Video Conferencing",
    "title": "Daily.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Daily.co with SalesBlink",
    "metaDescription": "Integrate Daily.co and other Video Conferencing Tools with SalesBlink. Two Way Daily.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convolo Leads",
    "slug": "convolo-leads",
    "logo": "https://zapier-images.imgix.net/storage/services/c98a2545ef3ae9aba3706b9499c5d7d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convolo Leads is a lead-to-call automation tool that connects a phone call between your sales people and your new leads within 30 seconds after they showed interest.",
    "category": "Forms & Surveys",
    "title": "Convolo Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Convolo Leads with SalesBlink",
    "metaDescription": "Integrate Convolo Leads and other Forms & Surveys Tools with SalesBlink. Two Way Convolo Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InvGate Service Desk",
    "slug": "invgate-service-desk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/737338065411e4e3591450b745d48f1f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InvGate Service Desk is a ticketing solution with ITIL-ready functionalities to organize work based on priority, SLA & urgency, create automated workflows, integrate with CMDB and more.",
    "category": "Customer Support",
    "title": "InvGate Service Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate InvGate Service Desk with SalesBlink",
    "metaDescription": "Integrate InvGate Service Desk and other Customer Support Tools with SalesBlink. Two Way InvGate Service Desk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snipfeed",
    "slug": "snipfeed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0a0e1ca8823478812c894a594700060.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monetization platform for content creators",
    "category": "Sales & CRM",
    "title": "Snipfeed Integration With Cold Email Sales Tool",
    "h1": "Integrate Snipfeed with SalesBlink",
    "metaDescription": "Integrate Snipfeed and other Sales & CRM Tools with SalesBlink. Two Way Snipfeed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kommunicate",
    "slug": "kommunicate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b17d8b9badafeecfb84e7f3f32409f49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kommunicate is a human + bot hybrid customer support software for growing businesses to build long-lasting customer relationships that drive growth.",
    "category": "Customer Support",
    "title": "Kommunicate Integration With Cold Email Sales Tool",
    "h1": "Integrate Kommunicate with SalesBlink",
    "metaDescription": "Integrate Kommunicate and other Customer Support Tools with SalesBlink. Two Way Kommunicate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drag'n Survey",
    "slug": "dragn-survey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c3b224a5475f35aa5bc9b36a22b4dd7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drag'n Survey make it easy to build powerful surveys to get and analyze the information you need.",
    "category": "Forms & Surveys",
    "title": "Drag'n Survey Integration With Cold Email Sales Tool",
    "h1": "Integrate Drag'n Survey with SalesBlink",
    "metaDescription": "Integrate Drag'n Survey and other Forms & Surveys Tools with SalesBlink. Two Way Drag'n Survey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CheckoutJoy",
    "slug": "checkoutjoy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6f1e9ab1b8487ab39c87ad33d6818bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CheckoutJoy is an eCommerce platform that helps e-learning businesses worldwide to increase revenue and save costs by offering advanced checkout experiences.",
    "category": "Payment Processing",
    "title": "CheckoutJoy Integration With Cold Email Sales Tool",
    "h1": "Integrate CheckoutJoy with SalesBlink",
    "metaDescription": "Integrate CheckoutJoy and other Payment Processing Tools with SalesBlink. Two Way CheckoutJoy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Seller.Tools",
    "slug": "sellertools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68826e926cd14c86cf1fe48b0605972c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A full suite of Amazon FBA tools leveraging data through sources such as Seller Central to capture visibility and automate Amazon success.",
    "category": "eCommerce",
    "title": "Seller.Tools Integration With Cold Email Sales Tool",
    "h1": "Integrate Seller.Tools with SalesBlink",
    "metaDescription": "Integrate Seller.Tools and other eCommerce Tools with SalesBlink. Two Way Seller.Tools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tellephant",
    "slug": "tellephant",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91c4a8e132f4b319b56ecb641751e50c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tellephant allows you to send WhatsApp messages to a phone number.",
    "category": "Communication",
    "title": "Tellephant Integration With Cold Email Sales Tool",
    "h1": "Integrate Tellephant with SalesBlink",
    "metaDescription": "Integrate Tellephant and other Communication Tools with SalesBlink. Two Way Tellephant Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PitBullTax Software",
    "slug": "pitbulltax",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d64f9c32427adb3066066fe521cbdb0f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PitBullTax is a revolutionary web-based software designed for CPAs, Attorneys, Enrolled Agents and other tax professionals involved in IRS Tax Resolutions.",
    "category": "",
    "title": "PitBullTax Software Integration With Cold Email Sales Tool",
    "h1": "Integrate PitBullTax Software with SalesBlink",
    "metaDescription": "Integrate PitBullTax Software and other  Tools with SalesBlink. Two Way PitBullTax Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuestionPro",
    "slug": "questionpro",
    "logo": "https://zapier-images.imgix.net/storage/developer/6a311e7c0dd56c3e404a8188e81bf028_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuestionPro is an online survey platform that allows users to generate the insights they need to make better business decisions.",
    "category": "Forms & Surveys",
    "title": "QuestionPro Integration With Cold Email Sales Tool",
    "h1": "Integrate QuestionPro with SalesBlink",
    "metaDescription": "Integrate QuestionPro and other Forms & Surveys Tools with SalesBlink. Two Way QuestionPro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PCRecruiter",
    "slug": "pcrecruiter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/81b320936a2acb9bc0967f1c3e308cac_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PCRecruiter is a CRM that serves customers worldwide doing permanent placement, 3rd party recruitment, corporate direct hiring, and staffing/contracting.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PCRecruiter Integration With Cold Email Sales Tool",
    "h1": "Integrate PCRecruiter with SalesBlink",
    "metaDescription": "Integrate PCRecruiter and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PCRecruiter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ResponseSuite",
    "slug": "responsesuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ed2219f48977b6bf20281a8f623267d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ResponseSuite uses the intelligence of surveys to get more of your customers buying more of your products, more often.",
    "category": "Forms & Surveys",
    "title": "ResponseSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate ResponseSuite with SalesBlink",
    "metaDescription": "Integrate ResponseSuite and other Forms & Surveys Tools with SalesBlink. Two Way ResponseSuite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Instant Offer Engine",
    "slug": "instant-offer-enginetm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae0c59eb18580025d5b7c2bad7191460.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powers Instant Cash Offers on REI websites",
    "category": "Ads & Conversion",
    "title": "Instant Offer Engine Integration With Cold Email Sales Tool",
    "h1": "Integrate Instant Offer Engine with SalesBlink",
    "metaDescription": "Integrate Instant Offer Engine and other Ads & Conversion Tools with SalesBlink. Two Way Instant Offer Engine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PeopleForce",
    "slug": "peopleforce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c70b6e0560d230f373d0d2f6e90f810.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HRM solution for small to medium sized businesses",
    "category": "HR Talent & Recruitment",
    "title": "PeopleForce Integration With Cold Email Sales Tool",
    "h1": "Integrate PeopleForce with SalesBlink",
    "metaDescription": "Integrate PeopleForce and other HR Talent & Recruitment Tools with SalesBlink. Two Way PeopleForce Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Castos",
    "slug": "castos",
    "logo": "https://zapier-images.imgix.net/storage/services/d3af68d3b016f90d6566acb412c4eaf5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Podcast hosting and analytics that is the easiest way to create a podcast and share your voice with the world.",
    "category": "Content & Files",
    "title": "Castos Integration With Cold Email Sales Tool",
    "h1": "Integrate Castos with SalesBlink",
    "metaDescription": "Integrate Castos and other Content & Files Tools with SalesBlink. Two Way Castos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chorus by ZoomInfo",
    "slug": "chorus-by-zoominfo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2373949cd4937dc6f016c8061cd7451a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chorus is an AI-powered Conversation Intelligence platform that captures and analyzes customer engagements so your team hits their number.",
    "category": "Sales & CRM",
    "title": "Chorus by ZoomInfo Integration With Cold Email Sales Tool",
    "h1": "Integrate Chorus by ZoomInfo with SalesBlink",
    "metaDescription": "Integrate Chorus by ZoomInfo and other Sales & CRM Tools with SalesBlink. Two Way Chorus by ZoomInfo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DatHuis",
    "slug": "dathuis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7354148b08497bb7761cd47e937b3269.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DatHuis is a marketing automation platform for professionals working in residental real estate. With DatHuis you can generate leads and turn leads into customers.",
    "category": "Marketing Automation",
    "title": "DatHuis Integration With Cold Email Sales Tool",
    "h1": "Integrate DatHuis with SalesBlink",
    "metaDescription": "Integrate DatHuis and other Marketing Automation Tools with SalesBlink. Two Way DatHuis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProAgentWebsites.com",
    "slug": "proagentwebsites",
    "logo": "https://zapier-images.imgix.net/storage/developer/9cf48047680e1c63b5f1fc61919686be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProAgentWebsites.com is an integrated Real Estate Website, CRM and Marketing Tools.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ProAgentWebsites.com Integration With Cold Email Sales Tool",
    "h1": "Integrate ProAgentWebsites.com with SalesBlink",
    "metaDescription": "Integrate ProAgentWebsites.com and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ProAgentWebsites.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gleap",
    "slug": "gleap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5c390994eab19eb7498a3904140c0a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gleap is an All-in-one customer feedback tool for websites and apps.",
    "category": "Developer Tools",
    "title": "Gleap Integration With Cold Email Sales Tool",
    "h1": "Integrate Gleap with SalesBlink",
    "metaDescription": "Integrate Gleap and other Developer Tools Tools with SalesBlink. Two Way Gleap Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "VerticalResponse",
    "slug": "verticalresponse",
    "logo": "https://zapier-images.imgix.net/storage/developer/6b114b58f4639fe3b0482ea3dc58d3f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VerticalResponse helps you connect with your customers and grow your business through email and social media marketing. With our drag and drop email creator, integrations with Facebook and Twitter, and free templates optimized for mobile phones, you can do all your marketing from one account!",
    "category": "Email Newsletters",
    "title": "VerticalResponse Integration With Cold Email Sales Tool",
    "h1": "Integrate VerticalResponse with SalesBlink",
    "metaDescription": "Integrate VerticalResponse and other Email Newsletters Tools with SalesBlink. Two Way VerticalResponse Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "JW Player",
    "slug": "jw-player",
    "logo": "https://zapier-images.imgix.net/storage/services/1c3bdf8c4771b3493a8bd8d3bfa8492e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JW Player helps you grow your business with its flexible platform of video services, powered by billions of signals from across our vast network.",
    "category": "Video & Audio",
    "title": "JW Player Integration With Cold Email Sales Tool",
    "h1": "Integrate JW Player with SalesBlink",
    "metaDescription": "Integrate JW Player and other Video & Audio Tools with SalesBlink. Two Way JW Player Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Case Status",
    "slug": "case-status",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3891335780be2531af105a2c57ebf50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Case Status is a client management platform that allows firms to communicate with the clients via text, app, or web through a single platform.",
    "category": "App Builder",
    "title": "Case Status Integration With Cold Email Sales Tool",
    "h1": "Integrate Case Status with SalesBlink",
    "metaDescription": "Integrate Case Status and other App Builder Tools with SalesBlink. Two Way Case Status Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dr.Works",
    "slug": "drworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8034a92167a7acf7193bd3fb71d4bf3a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dr.Works is schedule adjustment service. Automatically complete the issuance of the Zoom conference URL and pre-sharing at the same time as adjusting.",
    "category": "Scheduling & Booking",
    "title": "Dr.Works Integration With Cold Email Sales Tool",
    "h1": "Integrate Dr.Works with SalesBlink",
    "metaDescription": "Integrate Dr.Works and other Scheduling & Booking Tools with SalesBlink. Two Way Dr.Works Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TRAFFIT",
    "slug": "traffit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ed521a5355dfa7fc328939b866ae2eb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Traffit is an ATS that lets you build talent pools and manage candidates, as well as boost collaboration with your team and Hiring Managers.",
    "category": "HR Talent & Recruitment",
    "title": "TRAFFIT Integration With Cold Email Sales Tool",
    "h1": "Integrate TRAFFIT with SalesBlink",
    "metaDescription": "Integrate TRAFFIT and other HR Talent & Recruitment Tools with SalesBlink. Two Way TRAFFIT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sogolytics",
    "slug": "sogolytics",
    "logo": "https://zapier-images.imgix.net/storage/services/1f417b2c878fa9ffd6503686c7d69f48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our powerful and easy-to-use research platform allows users to create, distribute, and analyze professional surveys, polls, and assessments.",
    "category": "Forms & Surveys",
    "title": "Sogolytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Sogolytics with SalesBlink",
    "metaDescription": "Integrate Sogolytics and other Forms & Surveys Tools with SalesBlink. Two Way Sogolytics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wasi",
    "slug": "wasi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/560e3321f9cb7e0af6d8062f52df8f20_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wasi is a real estate platform for real estate professionals. It easily combines CRM, websites, reporting, automation, and sharing properties with your clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wasi Integration With Cold Email Sales Tool",
    "h1": "Integrate Wasi with SalesBlink",
    "metaDescription": "Integrate Wasi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wasi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intermedia AnyMeeting",
    "slug": "anymeeting",
    "logo": "https://zapier-images.imgix.net/storage/developer/34a1c4006d10e5dfb1436be870b90c6f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hold online meetings and webinars to connect with sales prospects, clients and new potential leads.",
    "category": "Video Conferencing",
    "title": "Intermedia AnyMeeting Integration With Cold Email Sales Tool",
    "h1": "Integrate Intermedia AnyMeeting with SalesBlink",
    "metaDescription": "Integrate Intermedia AnyMeeting and other Video Conferencing Tools with SalesBlink. Two Way Intermedia AnyMeeting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AppointmentCore",
    "slug": "appointmentcore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce9336cf0afd87e12fd21cc571f7bfe3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AppointmentCore's integration allows you to trigger actions in your trusted systems whenever prospects or customers book appointments with your AppointmentCore account!",
    "category": "Scheduling & Booking",
    "title": "AppointmentCore Integration With Cold Email Sales Tool",
    "h1": "Integrate AppointmentCore with SalesBlink",
    "metaDescription": "Integrate AppointmentCore and other Scheduling & Booking Tools with SalesBlink. Two Way AppointmentCore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Envoy for Desks",
    "slug": "envoy-for-desks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e4e0b5b7976b25d5cb5322b3518b0da_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Empower employees to reserve a desk to collaborate safely on-site.",
    "category": "Scheduling & Booking",
    "title": "Envoy for Desks Integration With Cold Email Sales Tool",
    "h1": "Integrate Envoy for Desks with SalesBlink",
    "metaDescription": "Integrate Envoy for Desks and other Scheduling & Booking Tools with SalesBlink. Two Way Envoy for Desks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instiller",
    "slug": "instiller",
    "logo": "https://zapier-images.imgix.net/storage/developer/f722174f5e022f651a479783a0fa9765.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instiller makes it easy to create and send beautifully designed email campaigns that display perfectly on both mobile and desktop.",
    "category": "Email",
    "title": "Instiller Integration With Cold Email Sales Tool",
    "h1": "Integrate Instiller with SalesBlink",
    "metaDescription": "Integrate Instiller and other Email Tools with SalesBlink. Two Way Instiller Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "cituro",
    "slug": "cituro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/082fd8523099e3761cfde9a638216b28.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "cituro is a powerful yet simple online scheduling software for businesses across industries. It optimizes scheduling processes and at the same time offers great service and increase sales.",
    "category": "Scheduling & Booking",
    "title": "cituro Integration With Cold Email Sales Tool",
    "h1": "Integrate cituro with SalesBlink",
    "metaDescription": "Integrate cituro and other Scheduling & Booking Tools with SalesBlink. Two Way cituro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Survey Funnel",
    "slug": "survey-funnel",
    "logo": "https://zapier-images.imgix.net/storage/developer/b15955404b1ad0fddafbf4d07979647f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Survey Funnel creates automated survey funnels for your marketing so that\nyou can segment leads as they opt in and send their data to your CRM.",
    "category": "Marketing Automation",
    "title": "Survey Funnel Integration With Cold Email Sales Tool",
    "h1": "Integrate Survey Funnel with SalesBlink",
    "metaDescription": "Integrate Survey Funnel and other Marketing Automation Tools with SalesBlink. Two Way Survey Funnel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Out of Office Assistant for Jira",
    "slug": "out-of-office-assistant",
    "logo": "https://zapier-images.imgix.net/storage/services/f7fc44a087dfe5b84d7aa06ab6d2af34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Out of Office Assistant is an app within the Jira Cloud environment to automatically inform your colleagues if they assigned you issues in your absence, and reassign automatically if coverers are selected.",
    "category": "Productivity",
    "title": "Out of Office Assistant for Jira Integration With Cold Email Sales Tool",
    "h1": "Integrate Out of Office Assistant for Jira with SalesBlink",
    "metaDescription": "Integrate Out of Office Assistant for Jira and other Productivity Tools with SalesBlink. Two Way Out of Office Assistant for Jira Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zenegy",
    "slug": "zenegy",
    "logo": "https://zapier-images.imgix.net/storage/developer/c57e8c6d0073d7e98c1e0804ba1d0ddd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenegy is a complete salary system with advanced features such as time, vacation, material and mileage registration.",
    "category": "HR Talent & Recruitment",
    "title": "Zenegy Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenegy with SalesBlink",
    "metaDescription": "Integrate Zenegy and other HR Talent & Recruitment Tools with SalesBlink. Two Way Zenegy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lnk.Bio",
    "slug": "lnkbio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18fbf7a520b4de27c9adf2bd91178639.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your Lnk.Bio experience by automatically publishing new links based on your existing triggers.",
    "category": "Social Media Marketing",
    "title": "Lnk.Bio Integration With Cold Email Sales Tool",
    "h1": "Integrate Lnk.Bio with SalesBlink",
    "metaDescription": "Integrate Lnk.Bio and other Social Media Marketing Tools with SalesBlink. Two Way Lnk.Bio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Endear",
    "slug": "endear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b395985f4b8080b227ab78db529227a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Endear is a CRM, messaging, and sales tracking platform for driving retail sales.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Endear Integration With Cold Email Sales Tool",
    "h1": "Integrate Endear with SalesBlink",
    "metaDescription": "Integrate Endear and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Endear Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LaunchDarkly",
    "slug": "launchdarkly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c4ba99c0f3e9df3051ee8f84c50ab2c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LaunchDarkly allows you to innovate faster, deploy fearlessly, and make each release a masterpiece.",
    "category": "Developer Tools",
    "title": "LaunchDarkly Integration With Cold Email Sales Tool",
    "h1": "Integrate LaunchDarkly with SalesBlink",
    "metaDescription": "Integrate LaunchDarkly and other Developer Tools Tools with SalesBlink. Two Way LaunchDarkly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesScreen",
    "slug": "salesscreen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c79ae2267f253b2948ecf5ff55e72ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesScreen is a sales performance management tool that helps teams to be more focused, motivated and productive.",
    "category": "Dashboards",
    "title": "SalesScreen Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesScreen with SalesBlink",
    "metaDescription": "Integrate SalesScreen and other Dashboards Tools with SalesBlink. Two Way SalesScreen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kitchen.co",
    "slug": "kitchenco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50451c0dbf2ef477dafd418cb1a26b5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kitchen.co is the smarter, easier, more efficient way to manage client requests in your agency.",
    "category": "Project Management",
    "title": "Kitchen.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Kitchen.co with SalesBlink",
    "metaDescription": "Integrate Kitchen.co and other Project Management Tools with SalesBlink. Two Way Kitchen.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lime Cellular",
    "slug": "lime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1ec6bf71d4fb8cac24245711a6fc69b_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Provides the power to add text message marketing services to businesses.",
    "category": "Phone & SMS",
    "title": "Lime Cellular Integration With Cold Email Sales Tool",
    "h1": "Integrate Lime Cellular with SalesBlink",
    "metaDescription": "Integrate Lime Cellular and other Phone & SMS Tools with SalesBlink. Two Way Lime Cellular Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gust",
    "slug": "gust-for-accelerators",
    "logo": "https://zapier-images.imgix.net/storage/developer/79b42c1de9812ccf97683b394e5301ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gust is the world’s largest network connecting the early stage ecosystem, with tools for founders, accelerators, and investment organizations to create or find promising startups.",
    "category": "Dashboards",
    "title": "Gust Integration With Cold Email Sales Tool",
    "h1": "Integrate Gust with SalesBlink",
    "metaDescription": "Integrate Gust and other Dashboards Tools with SalesBlink. Two Way Gust Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agentcis",
    "slug": "agentcis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fd805f1da5d16f7098b5307b1827717.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agentcis is a business management application for Education and Migration Agencies that goes beyond currently available CRM platforms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agentcis Integration With Cold Email Sales Tool",
    "h1": "Integrate Agentcis with SalesBlink",
    "metaDescription": "Integrate Agentcis and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agentcis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Esendex",
    "slug": "esendex",
    "logo": "https://zapier-images.imgix.net/storage/developer/92efe868ff4c92ea08765aa200b463eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Esendex offer reliable SMS, Web and Voice tools for secure business communication.",
    "category": "Phone & SMS",
    "title": "Esendex Integration With Cold Email Sales Tool",
    "h1": "Integrate Esendex with SalesBlink",
    "metaDescription": "Integrate Esendex and other Phone & SMS Tools with SalesBlink. Two Way Esendex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendiio",
    "slug": "sendiio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/145c2bf62a48ffe3cd950b061c1c6ba5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendiio platform allows to create and send Unlimited Email Campaigns without a monthly fee",
    "category": "Email",
    "title": "Sendiio Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendiio with SalesBlink",
    "metaDescription": "Integrate Sendiio and other Email Tools with SalesBlink. Two Way Sendiio Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Checkvist",
    "slug": "checkvist",
    "logo": "https://zapier-images.imgix.net/storage/developer/298dc8ff5bef6b94fd414f3170f28df5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keyboard driven online outliner and task manager for teams and individuals. Capture your ideas and notes, create checklists and plans, share with colleagues, and get everything done — together.",
    "category": "Task Management",
    "title": "Checkvist Integration With Cold Email Sales Tool",
    "h1": "Integrate Checkvist with SalesBlink",
    "metaDescription": "Integrate Checkvist and other Task Management Tools with SalesBlink. Two Way Checkvist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Smart Job Board",
    "slug": "smart-job-board",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/29e60799877ca98bbbbeb7d6b2c184be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The easiest job board software on the market.",
    "category": "HR Talent & Recruitment",
    "title": "Smart Job Board Integration With Cold Email Sales Tool",
    "h1": "Integrate Smart Job Board with SalesBlink",
    "metaDescription": "Integrate Smart Job Board and other HR Talent & Recruitment Tools with SalesBlink. Two Way Smart Job Board Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RunSignup",
    "slug": "runsignup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1322eeb0473475b49416308049abe6ad_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RunSignup provides a simple way to register for races. You can create and manage your race for free.",
    "category": "Event Management",
    "title": "RunSignup Integration With Cold Email Sales Tool",
    "h1": "Integrate RunSignup with SalesBlink",
    "metaDescription": "Integrate RunSignup and other Event Management Tools with SalesBlink. Two Way RunSignup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ally Hub",
    "slug": "ally-hub",
    "logo": "https://zapier-images.imgix.net/storage/services/c1e7d2c9f6579f60c54a82763e875eb5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ally Hub is a tool that simplifies the administration process of companies dealing with international travel education around the world.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Ally Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate Ally Hub with SalesBlink",
    "metaDescription": "Integrate Ally Hub and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Ally Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WorkWave Route Manager",
    "slug": "workwave-route-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b048ea653a4b7d51ea71dafe31c16384.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WorkWave Route Manager is a last mile, fleet management software that enables you to make successful, on time deliveries and offer a great experience to your customers.",
    "category": "Commerce",
    "title": "WorkWave Route Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate WorkWave Route Manager with SalesBlink",
    "metaDescription": "Integrate WorkWave Route Manager and other Commerce Tools with SalesBlink. Two Way WorkWave Route Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AccessAlly",
    "slug": "accessally",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/80cb6657567f6de58d70dbcb0ee2ce7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccessAlly makes it easy to build courses, memberships, and digital products on Wordpress so you have the power and flexibility you need to sell more, teach better, and engage longer.",
    "category": "Website Builders",
    "title": "AccessAlly Integration With Cold Email Sales Tool",
    "h1": "Integrate AccessAlly with SalesBlink",
    "metaDescription": "Integrate AccessAlly and other Website Builders Tools with SalesBlink. Two Way AccessAlly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LoopSpark",
    "slug": "loopspark",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e27a31e6ce4f1315579fe3f8e1724238_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LoopSpark software for converting, retaining, measuring and growing your client base is the go-to automated CRM solution for fitness studio management.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LoopSpark Integration With Cold Email Sales Tool",
    "h1": "Integrate LoopSpark with SalesBlink",
    "metaDescription": "Integrate LoopSpark and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LoopSpark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brilliant Directories",
    "slug": "brilliant-directories",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/966a10fcf5026c1112c172e15f6d8abc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brilliant Directories is a platform to Create Membership & Directory Websites",
    "category": "CRM (Customer Relationship Management)",
    "title": "Brilliant Directories Integration With Cold Email Sales Tool",
    "h1": "Integrate Brilliant Directories with SalesBlink",
    "metaDescription": "Integrate Brilliant Directories and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Brilliant Directories Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Grist",
    "slug": "grist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec443922f428e4cbe85396b441b89c68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grist combines the flexibility of a spreadsheet with the robustness of a database to organize your data, your way.",
    "category": "Spreadsheets",
    "title": "Grist Integration With Cold Email Sales Tool",
    "h1": "Integrate Grist with SalesBlink",
    "metaDescription": "Integrate Grist and other Spreadsheets Tools with SalesBlink. Two Way Grist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CloudKii",
    "slug": "cloudkii",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d010d435904ed737d9fcb0d9131cb088.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudKii empowers you to take control over your extension business.",
    "category": "Marketing Automation",
    "title": "CloudKii Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudKii with SalesBlink",
    "metaDescription": "Integrate CloudKii and other Marketing Automation Tools with SalesBlink. Two Way CloudKii Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scrape-It.Cloud",
    "slug": "scrape-itcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b35833a6394cb8975979cc8aff22677.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scrape-It.Cloud is an API for web scraping valuable data in JSON format from any website without needing a proxy.",
    "category": "Developer Tools",
    "title": "Scrape-It.Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Scrape-It.Cloud with SalesBlink",
    "metaDescription": "Integrate Scrape-It.Cloud and other Developer Tools Tools with SalesBlink. Two Way Scrape-It.Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tada - Email Pop ups Exit Game for Shopify",
    "slug": "tada-email-pop-ups-exit-game-for",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f8ddf12eb94020b9bba982305fe22dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tada is the highest converting Exit Intent, Spin the Wheel Discounts Popup for Shopify Stores.",
    "category": "Marketing",
    "title": "Tada - Email Pop ups Exit Game for Shopify Integration With Cold Email Sales Tool",
    "h1": "Integrate Tada - Email Pop ups Exit Game for Shopify with SalesBlink",
    "metaDescription": "Integrate Tada - Email Pop ups Exit Game for Shopify and other Marketing Tools with SalesBlink. Two Way Tada - Email Pop ups Exit Game for Shopify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nimbata",
    "slug": "nimbata",
    "logo": "https://zapier-images.imgix.net/storage/services/cb61630b09c5167c1d835de5b4459515.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nimbata is a call tracking platform that lets you attribute phone calls back to keyword searches, web pages, social media, digital ads or offline campaigns.",
    "category": "Call Tracking",
    "title": "Nimbata Integration With Cold Email Sales Tool",
    "h1": "Integrate Nimbata with SalesBlink",
    "metaDescription": "Integrate Nimbata and other Call Tracking Tools with SalesBlink. Two Way Nimbata Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Netgsm",
    "slug": "netgsm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd847a435381c64d2ef662a8595743ae_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Netgsm is an SMS, voice messaging and VOIP platform.",
    "category": "Phone & SMS",
    "title": "Netgsm Integration With Cold Email Sales Tool",
    "h1": "Integrate Netgsm with SalesBlink",
    "metaDescription": "Integrate Netgsm and other Phone & SMS Tools with SalesBlink. Two Way Netgsm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tatango",
    "slug": "tatango",
    "logo": "https://zapier-images.imgix.net/storage/developer/6b69200c8cf265c0df9fedbfaebf6f71_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tatango offers a complete suit of SMS marketing products, including SMS marketing campaigns to your customers. Find out more at Tatango.com",
    "category": "Phone & SMS",
    "title": "Tatango Integration With Cold Email Sales Tool",
    "h1": "Integrate Tatango with SalesBlink",
    "metaDescription": "Integrate Tatango and other Phone & SMS Tools with SalesBlink. Two Way Tatango Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "get.chat",
    "slug": "getchat-ca146209",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e3d90f8b5b52355c0b0a6a1b16caf3c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "get.chat provides access to your WhatsApp Business API to send and receive WhatsApp messages using 360dialog",
    "category": "Communication",
    "title": "get.chat Integration With Cold Email Sales Tool",
    "h1": "Integrate get.chat with SalesBlink",
    "metaDescription": "Integrate get.chat and other Communication Tools with SalesBlink. Two Way get.chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Customerly",
    "slug": "customerly",
    "logo": "https://zapier-images.imgix.net/storage/developer/d460ace8ea487571adc6c4a4a706eeaf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boost your customer engagement. The fully integrated tool your online business needs to scale up and automate your engagement.",
    "category": "Customer Support",
    "title": "Customerly Integration With Cold Email Sales Tool",
    "h1": "Integrate Customerly with SalesBlink",
    "metaDescription": "Integrate Customerly and other Customer Support Tools with SalesBlink. Two Way Customerly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EventCreate",
    "slug": "eventcreate",
    "logo": "https://zapier-images.imgix.net/storage/developer/b7f6d38916850671a8bc27bf0f8688ac_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EventCreate is a reliable, simple event marketing software for any occasion. Create a beautiful event website, sell tickets, and promote your event online.",
    "category": "Event Management",
    "title": "EventCreate Integration With Cold Email Sales Tool",
    "h1": "Integrate EventCreate with SalesBlink",
    "metaDescription": "Integrate EventCreate and other Event Management Tools with SalesBlink. Two Way EventCreate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpLead",
    "slug": "uplead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9573d6404e24f164783cf8e398196208.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpLead is a B2B sales intelligence platform to drive growth.",
    "category": "Contact Management",
    "title": "UpLead Integration With Cold Email Sales Tool",
    "h1": "Integrate UpLead with SalesBlink",
    "metaDescription": "Integrate UpLead and other Contact Management Tools with SalesBlink. Two Way UpLead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eledo",
    "slug": "eledo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/172ac748d220bcdcd47f9bfd89fbf168.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eledo is a PDF service that helps you with your automated document creation. Build your document template in Eledo online editor and include dynamic elements.",
    "category": "Documents",
    "title": "Eledo Integration With Cold Email Sales Tool",
    "h1": "Integrate Eledo with SalesBlink",
    "metaDescription": "Integrate Eledo and other Documents Tools with SalesBlink. Two Way Eledo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshmarketer",
    "slug": "freshmarketer",
    "logo": "https://zapier-images.imgix.net/storage/services/fcd62b16044e8529435a272bfe0c0bcb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshmarketer is a marketing automation suite. It helps your marketing teams run powerful engagement campaigns, drip automation, sync with CRM, capture leads via forms and much more. The tools also help you to track & optimise your website with Heatmaps, A/B testing, Session replay, Polls & Feeback, Form analytics.",
    "category": "Marketing Automation",
    "title": "Freshmarketer Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshmarketer with SalesBlink",
    "metaDescription": "Integrate Freshmarketer and other Marketing Automation Tools with SalesBlink. Two Way Freshmarketer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ClickMagick",
    "slug": "clickmagick",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a3409a74a8e4b29e3d240514db94f5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bulletproof tracking and conversion attribution, plus Audience Optimization which allows your ad networks to find you better and cheaper customers. We obsess over tracking, so you don't have to.",
    "category": "Ads & Conversion",
    "title": "ClickMagick Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickMagick with SalesBlink",
    "metaDescription": "Integrate ClickMagick and other Ads & Conversion Tools with SalesBlink. Two Way ClickMagick Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TeamWave",
    "slug": "teamwave",
    "logo": "https://zapier-images.imgix.net/storage/developer/36032592ad5116572db8e4f6fe5ab1a0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamWave is an unified platform for project management, CRM, and HR software for small businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "TeamWave Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamWave with SalesBlink",
    "metaDescription": "Integrate TeamWave and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way TeamWave Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MarkUp.io",
    "slug": "markupio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48b818b8b11d5f2ad7a2a870785b2cf9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MarkUp.io is a visual commenting tool that makes it simple to leave contextual feedback on 30+ file types, incl. Websites, PDFs, and Videos.",
    "category": "Team Collaboration",
    "title": "MarkUp.io Integration With Cold Email Sales Tool",
    "h1": "Integrate MarkUp.io with SalesBlink",
    "metaDescription": "Integrate MarkUp.io and other Team Collaboration Tools with SalesBlink. Two Way MarkUp.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ambassador",
    "slug": "ambassador",
    "logo": "https://zapier-images.imgix.net/storage/developer/02fa37502783240602ca7ae9e18ae04d_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ambassador gives any company the tools to turbo-charge their referral program, turning their customers into brand Ambassadors. \n\nUse Ambassador to easily create, track & manage custom incentives that drive referrals and evangelize your users. It's as simple as pasting a javascript snippet or can be seamlessly integrated via API.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Ambassador Integration With Cold Email Sales Tool",
    "h1": "Integrate Ambassador with SalesBlink",
    "metaDescription": "Integrate Ambassador and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Ambassador Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "When I Work",
    "slug": "when-i-work",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3032bcec3d507c920dd2f091b9811598.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "When I Work is the easiest way to schedule, manage attendance, and communicate with your staff from your Android device and PC. Notify your staff instantly about their work schedule via text, email, and web notifications.",
    "category": "HR Talent & Recruitment",
    "title": "When I Work Integration With Cold Email Sales Tool",
    "h1": "Integrate When I Work with SalesBlink",
    "metaDescription": "Integrate When I Work and other HR Talent & Recruitment Tools with SalesBlink. Two Way When I Work Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "WildJar",
    "slug": "wildjar",
    "logo": "https://zapier-images.imgix.net/storage/services/e8695fe13c0759b36ddfb639f96ae3d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate your phone and SMS conversions with your favourite platforms.",
    "category": "Call Tracking",
    "title": "WildJar Integration With Cold Email Sales Tool",
    "h1": "Integrate WildJar with SalesBlink",
    "metaDescription": "Integrate WildJar and other Call Tracking Tools with SalesBlink. Two Way WildJar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ohmylead",
    "slug": "ohmylead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aff8d0d6b48fd6a848bfdf957e8d8ed6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Ohmylead to follow up faster with ALL your leads in one platform before sending them to your favorite CRM & ESP",
    "category": "Marketing Automation",
    "title": "Ohmylead Integration With Cold Email Sales Tool",
    "h1": "Integrate Ohmylead with SalesBlink",
    "metaDescription": "Integrate Ohmylead and other Marketing Automation Tools with SalesBlink. Two Way Ohmylead Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Jitbit Helpdesk",
    "slug": "jitbit-helpdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/3f49f04168d2f7ca859334dab7f6b096.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A great help desk app for small and medium sized companies. It has all the features you may need, while staying easy to use and very user friendly.",
    "category": "Customer Support",
    "title": "Jitbit Helpdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Jitbit Helpdesk with SalesBlink",
    "metaDescription": "Integrate Jitbit Helpdesk and other Customer Support Tools with SalesBlink. Two Way Jitbit Helpdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeepCrawl",
    "slug": "deepcrawl",
    "logo": "https://zapier-images.imgix.net/storage/developer/65dcd2ba9378717b68a506bc674cdebd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeepCrawl is a comprehensive auditor of website architecture. Run a deep crawl of your site to check hreflang, discover thin content, and pre-test all code releases.",
    "category": "Website Builders",
    "title": "DeepCrawl Integration With Cold Email Sales Tool",
    "h1": "Integrate DeepCrawl with SalesBlink",
    "metaDescription": "Integrate DeepCrawl and other Website Builders Tools with SalesBlink. Two Way DeepCrawl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Indy",
    "slug": "indy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f551cf968aa8fa94bbdea4c2f5258ae5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Indy is a productivity platform that helps you manage your freelance business through marketing, workflow, and payment tools.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Indy Integration With Cold Email Sales Tool",
    "h1": "Integrate Indy with SalesBlink",
    "metaDescription": "Integrate Indy and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Indy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PDF Blocks",
    "slug": "pdf-blocks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6719d070a172973170663e3f2c7ae6d6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PDF actions for your zaps: Merge PDF documents, protect PDFs with a password, watermark PDF documents, and more.",
    "category": "Documents",
    "title": "PDF Blocks Integration With Cold Email Sales Tool",
    "h1": "Integrate PDF Blocks with SalesBlink",
    "metaDescription": "Integrate PDF Blocks and other Documents Tools with SalesBlink. Two Way PDF Blocks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ikas",
    "slug": "ikas",
    "logo": "https://zapier-images.imgix.net/storage/services/cf1b40b032db9d2a2fa8e6530f625f7f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ikas is a headless e-commerce platform for SME's.",
    "category": "eCommerce",
    "title": "ikas Integration With Cold Email Sales Tool",
    "h1": "Integrate ikas with SalesBlink",
    "metaDescription": "Integrate ikas and other eCommerce Tools with SalesBlink. Two Way ikas Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outlaw",
    "slug": "outlaw",
    "logo": "https://zapier-images.imgix.net/storage/services/8a115801d8a08639fcb36d7c4ac8139a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outlaw is a modern contract management solution that streamlines the contract lifecycle for organisations.",
    "category": "Documents",
    "title": "Outlaw Integration With Cold Email Sales Tool",
    "h1": "Integrate Outlaw with SalesBlink",
    "metaDescription": "Integrate Outlaw and other Documents Tools with SalesBlink. Two Way Outlaw Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FreshMail",
    "slug": "freshmail",
    "logo": "https://zapier-images.imgix.net/storage/developer/8669446d5f3f2c33c530cf1fda3105cc_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreshMail is a great email marketing service. Manage and update your subscribers lists easily. Create, send and track email marketing campaigns that work and get advanced reports in real time.",
    "category": "Email Newsletters",
    "title": "FreshMail Integration With Cold Email Sales Tool",
    "h1": "Integrate FreshMail with SalesBlink",
    "metaDescription": "Integrate FreshMail and other Email Newsletters Tools with SalesBlink. Two Way FreshMail Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Hilos",
    "slug": "hilos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/efa9413c7e299d18cddc81795f238f15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hilos is the full-stack for WhatsApp: get Business API Access, chat using our WhatsApp multi-agent Inbox and build automations that integrate with your existing tools (like your CRM).",
    "category": "Phone & SMS",
    "title": "Hilos Integration With Cold Email Sales Tool",
    "h1": "Integrate Hilos with SalesBlink",
    "metaDescription": "Integrate Hilos and other Phone & SMS Tools with SalesBlink. Two Way Hilos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contractors Cloud",
    "slug": "contractors-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dff0a215d06e93a9f02d579653116283.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contractors Cloud is an innovative, well-rounded and full-featured specialty trade software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Contractors Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Contractors Cloud with SalesBlink",
    "metaDescription": "Integrate Contractors Cloud and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Contractors Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FranchiseUNIVERSUM",
    "slug": "franchiseuniversum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4d49f2a0afbbe773190b81920822c88_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get leads from potential founders interested in your brand with FranchiseUNIVERSUM. You get Leads from FranchisePORTAL and other platforms.",
    "category": "Marketing Automation",
    "title": "FranchiseUNIVERSUM Integration With Cold Email Sales Tool",
    "h1": "Integrate FranchiseUNIVERSUM with SalesBlink",
    "metaDescription": "Integrate FranchiseUNIVERSUM and other Marketing Automation Tools with SalesBlink. Two Way FranchiseUNIVERSUM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convo",
    "slug": "convo",
    "logo": "https://zapier-images.imgix.net/storage/developer/e25b185974c021be72b8ae87307565e6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convo is an Enterprise Social Network that helps you have actionable work conversations and file collaboration.",
    "category": "Team Collaboration",
    "title": "Convo Integration With Cold Email Sales Tool",
    "h1": "Integrate Convo with SalesBlink",
    "metaDescription": "Integrate Convo and other Team Collaboration Tools with SalesBlink. Two Way Convo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "beeviral",
    "slug": "indexia",
    "logo": "https://zapier-images.imgix.net/storage/services/f34d89135a73f4c85e7f468acd664345.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "beeviral is a referral marketing automation platform.",
    "category": "Marketing Automation",
    "title": "beeviral Integration With Cold Email Sales Tool",
    "h1": "Integrate beeviral with SalesBlink",
    "metaDescription": "Integrate beeviral and other Marketing Automation Tools with SalesBlink. Two Way beeviral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hexowatch",
    "slug": "hexowatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c92dd3cd0f253fb26d19bcf9aa35ca28.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hexowatch is your AI sidekick to monitor any website for visual, content, source code or data changes.",
    "category": "Notifications",
    "title": "Hexowatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Hexowatch with SalesBlink",
    "metaDescription": "Integrate Hexowatch and other Notifications Tools with SalesBlink. Two Way Hexowatch Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Lusha",
    "slug": "lusha",
    "logo": "https://zapier-images.imgix.net/storage/developer/27afe56f1954c0bd4d53f369693e329b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lusha is the easiest way to find email addresses & phone numbers from anywhere on the web, with just one click.",
    "category": "Contact Management",
    "title": "Lusha Integration With Cold Email Sales Tool",
    "h1": "Integrate Lusha with SalesBlink",
    "metaDescription": "Integrate Lusha and other Contact Management Tools with SalesBlink. Two Way Lusha Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Disco",
    "slug": "disco",
    "logo": "https://zapier-images.imgix.net/storage/services/9981d03739e9459745a30e00e9811d9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The All-in-One Platform for Learning Communities",
    "category": "Education",
    "title": "Disco Integration With Cold Email Sales Tool",
    "h1": "Integrate Disco with SalesBlink",
    "metaDescription": "Integrate Disco and other Education Tools with SalesBlink. Two Way Disco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whop",
    "slug": "whop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/22e3d561d1ab8bcc0de8c7cf217850e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whop is a platform to buy and sell digital products.",
    "category": "Commerce",
    "title": "Whop Integration With Cold Email Sales Tool",
    "h1": "Integrate Whop with SalesBlink",
    "metaDescription": "Integrate Whop and other Commerce Tools with SalesBlink. Two Way Whop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Assembla",
    "slug": "assembla",
    "logo": "https://zapier-images.imgix.net/storage/developer/5abd068ed9e7a4259b9b9d3bc6992bf6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Assembla is the project management platform for developers and project managers where you can manage repos and tickets in one place.",
    "category": "Project Management",
    "title": "Assembla Integration With Cold Email Sales Tool",
    "h1": "Integrate Assembla with SalesBlink",
    "metaDescription": "Integrate Assembla and other Project Management Tools with SalesBlink. Two Way Assembla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrekkSoft",
    "slug": "trekksoft",
    "logo": "https://zapier-images.imgix.net/storage/developer/94e96681a733079c4d78930fd6e9d8c3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrekkSoft is a booking platform that allows you to receive and manage bookings and payments for your activity or tours company.",
    "category": "Event Management",
    "title": "TrekkSoft Integration With Cold Email Sales Tool",
    "h1": "Integrate TrekkSoft with SalesBlink",
    "metaDescription": "Integrate TrekkSoft and other Event Management Tools with SalesBlink. Two Way TrekkSoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clinked",
    "slug": "clinked",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e73218fdb56cfce4cb501159017d86f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clinked is a white-label client portal for business. Clinked offers secure communication, collaboration & information sharing portal.",
    "category": "Team Collaboration",
    "title": "Clinked Integration With Cold Email Sales Tool",
    "h1": "Integrate Clinked with SalesBlink",
    "metaDescription": "Integrate Clinked and other Team Collaboration Tools with SalesBlink. Two Way Clinked Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Plumsail Forms",
    "slug": "plumsail-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1bcf8ff5ba3450540c2bac69b7526840.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plumsail Forms allows you to design fully responsive web forms containing wizards, tabs, data tables, hand-written notes and publish them to any websites.",
    "category": "Forms & Surveys",
    "title": "Plumsail Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Plumsail Forms with SalesBlink",
    "metaDescription": "Integrate Plumsail Forms and other Forms & Surveys Tools with SalesBlink. Two Way Plumsail Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClientSuccess",
    "slug": "clientsuccess",
    "logo": "https://zapier-images.imgix.net/storage/developer/7f1972604261095b78ef74be3a232992.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClientSuccess is customer success platform that helps retain and grow existing customer bases.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ClientSuccess Integration With Cold Email Sales Tool",
    "h1": "Integrate ClientSuccess with SalesBlink",
    "metaDescription": "Integrate ClientSuccess and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ClientSuccess Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trolley",
    "slug": "trolley",
    "logo": "https://zapier-images.imgix.net/storage/services/826c019cb98284ac19dd31a71f3582f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trolley helps businesses send payouts to (and manage associated tax details for) their vendors,\nsuppliers, artists, and independent contractors worldwide.",
    "category": "Payment Processing",
    "title": "Trolley Integration With Cold Email Sales Tool",
    "h1": "Integrate Trolley with SalesBlink",
    "metaDescription": "Integrate Trolley and other Payment Processing Tools with SalesBlink. Two Way Trolley Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eboov",
    "slug": "eboov",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b93ecaf52c97018eee906ce45115482.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video hosting platform with in-video conversion technology. Generate more leads and sales with single video funnels.",
    "category": "Marketing Automation",
    "title": "eboov Integration With Cold Email Sales Tool",
    "h1": "Integrate eboov with SalesBlink",
    "metaDescription": "Integrate eboov and other Marketing Automation Tools with SalesBlink. Two Way eboov Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appzi",
    "slug": "appzi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89c611fa693c83fabd30da9a96c91049_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appzi captures insightful user feedback and improves your website right inside your site, for free",
    "category": "Customer Appreciation",
    "title": "Appzi Integration With Cold Email Sales Tool",
    "h1": "Integrate Appzi with SalesBlink",
    "metaDescription": "Integrate Appzi and other Customer Appreciation Tools with SalesBlink. Two Way Appzi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sequel.io",
    "slug": "sequelio",
    "logo": "https://zapier-images.imgix.net/storage/services/abeac946e7c09ad01b250f86cb3616c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sequel.io provides powerful pre-built live communication components to give your audience an experience right where they are, on your site.",
    "category": "Analytics",
    "title": "Sequel.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Sequel.io with SalesBlink",
    "metaDescription": "Integrate Sequel.io and other Analytics Tools with SalesBlink. Two Way Sequel.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "naturalForms",
    "slug": "naturalforms",
    "logo": "https://zapier-images.imgix.net/storage/services/75905487ef2179bd8e1b99685b56a34a.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NaturalForms is a mobile app that replaces paper forms and collects information electronically that exactly match your current paperwork.",
    "category": "Forms & Surveys",
    "title": "naturalForms Integration With Cold Email Sales Tool",
    "h1": "Integrate naturalForms with SalesBlink",
    "metaDescription": "Integrate naturalForms and other Forms & Surveys Tools with SalesBlink. Two Way naturalForms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Kulea",
    "slug": "kulea",
    "logo": "https://zapier-images.imgix.net/storage/developer/21c8026247dd38df7e834616a5565a56.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kulea is a marketing automation and personalisation platform build for SMEs.",
    "category": "Marketing Automation",
    "title": "Kulea Integration With Cold Email Sales Tool",
    "h1": "Integrate Kulea with SalesBlink",
    "metaDescription": "Integrate Kulea and other Marketing Automation Tools with SalesBlink. Two Way Kulea Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "plugnpaid",
    "slug": "plugnpaid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16395a6413872158e03699a9e1320706.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "plugnpaid is an online eCommerce platforms for SMEs and solopreneurs.",
    "category": "eCommerce",
    "title": "plugnpaid Integration With Cold Email Sales Tool",
    "h1": "Integrate plugnpaid with SalesBlink",
    "metaDescription": "Integrate plugnpaid and other eCommerce Tools with SalesBlink. Two Way plugnpaid Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Dash",
    "slug": "dash",
    "logo": "https://zapier-images.imgix.net/storage/services/11eed6d204657afcbe6f3293d9b0bbb6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dash is the new home for your visual content. Streamlined, lightning-fast digital asset management (DAM) for growing brands.",
    "category": "Content & Files",
    "title": "Dash Integration With Cold Email Sales Tool",
    "h1": "Integrate Dash with SalesBlink",
    "metaDescription": "Integrate Dash and other Content & Files Tools with SalesBlink. Two Way Dash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PriceHubble",
    "slug": "pricehubble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c6d999f72d27387787f9b673912ba5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PriceHubble is the leading provider of residential real estate valuation and analyses.",
    "category": "Analytics",
    "title": "PriceHubble Integration With Cold Email Sales Tool",
    "h1": "Integrate PriceHubble with SalesBlink",
    "metaDescription": "Integrate PriceHubble and other Analytics Tools with SalesBlink. Two Way PriceHubble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gatekeeper",
    "slug": "gatekeeper",
    "logo": "https://zapier-images.imgix.net/storage/developer/585f4206416333b5a891487e4035d824.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gatekeeper is a powerful, flexible and intuitive Supplier and Contract Management solution.",
    "category": "Documents",
    "title": "Gatekeeper Integration With Cold Email Sales Tool",
    "h1": "Integrate Gatekeeper with SalesBlink",
    "metaDescription": "Integrate Gatekeeper and other Documents Tools with SalesBlink. Two Way Gatekeeper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octoboard",
    "slug": "octoboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/34983676e171105f6775eabea562a08c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octoboard builds real-time office TV dashboards, online client portals, automated reports and AI data analytics platform for sharing cloud data with team members, management and clients.",
    "category": "Analytics",
    "title": "Octoboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Octoboard with SalesBlink",
    "metaDescription": "Integrate Octoboard and other Analytics Tools with SalesBlink. Two Way Octoboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Element451",
    "slug": "element451",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9a3854bd312174d4b0d79e03f80eaf4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Element451 is a CRM for higher education that optimizes admissions marketing and enrollment management. Analytics, automation, and unparalleled ease of use are at its core.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Element451 Integration With Cold Email Sales Tool",
    "h1": "Integrate Element451 with SalesBlink",
    "metaDescription": "Integrate Element451 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Element451 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Common Room",
    "slug": "common-room",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f982638efc62659c948381f26ab74878.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Common Room is the intelligent community growth platform that helps you deepen relationships, build better products, and drive business impact.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Common Room Integration With Cold Email Sales Tool",
    "h1": "Integrate Common Room with SalesBlink",
    "metaDescription": "Integrate Common Room and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Common Room Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anthill",
    "slug": "anthill-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3711f767b238dff2c72fd5e3d099dbd4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anthill is an operational CRM platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Anthill Integration With Cold Email Sales Tool",
    "h1": "Integrate Anthill with SalesBlink",
    "metaDescription": "Integrate Anthill and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Anthill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoodBarber eCommerce",
    "slug": "goodbarber-ecommerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a8bfad3ba3960aa78bff94111402e5d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoodBarber is a no-code app building platform to create iOS and Android native apps. With GoodBarber eCommerce, create and manage your online store to sell your products on mobile.",
    "category": "App Builder",
    "title": "GoodBarber eCommerce Integration With Cold Email Sales Tool",
    "h1": "Integrate GoodBarber eCommerce with SalesBlink",
    "metaDescription": "Integrate GoodBarber eCommerce and other App Builder Tools with SalesBlink. Two Way GoodBarber eCommerce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Jostle",
    "slug": "jostle",
    "logo": "https://zapier-images.imgix.net/storage/services/260fab1d5b5422a2fd7132ba9468f474.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jostle brings everyone together and helps each person succeed. We're an employee success platform that's replacing intranets.",
    "category": "Team Collaboration",
    "title": "Jostle Integration With Cold Email Sales Tool",
    "h1": "Integrate Jostle with SalesBlink",
    "metaDescription": "Integrate Jostle and other Team Collaboration Tools with SalesBlink. Two Way Jostle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuoteMachine",
    "slug": "quotemachine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7eeb60b35de594e842f726bb6ee999e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuoteMachine is online quotes and invoices that help you sell and get paid.",
    "category": "Proposal & Invoice Management",
    "title": "QuoteMachine Integration With Cold Email Sales Tool",
    "h1": "Integrate QuoteMachine with SalesBlink",
    "metaDescription": "Integrate QuoteMachine and other Proposal & Invoice Management Tools with SalesBlink. Two Way QuoteMachine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ironclad",
    "slug": "ironclad",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16fd990f20229cd8267edec567c993d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ironclad is a contract lifecycle management platform used by companies to handle every type of contract workflow.",
    "category": "Documents",
    "title": "Ironclad Integration With Cold Email Sales Tool",
    "h1": "Integrate Ironclad with SalesBlink",
    "metaDescription": "Integrate Ironclad and other Documents Tools with SalesBlink. Two Way Ironclad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CM.com",
    "slug": "cm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe1bc1942dad42238adce5729d763304.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CM is a mobile messaging, voice, authentication and payment platform that enables you to engage with your target audience by mobile communication.",
    "category": "Phone & SMS",
    "title": "CM.com Integration With Cold Email Sales Tool",
    "h1": "Integrate CM.com with SalesBlink",
    "metaDescription": "Integrate CM.com and other Phone & SMS Tools with SalesBlink. Two Way CM.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AdPage",
    "slug": "adpage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87e2ee5f1fb47716abeb9a06354385b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AdPage is a superfast landing page builder for agencies and small business owners.",
    "category": "Forms & Surveys",
    "title": "AdPage Integration With Cold Email Sales Tool",
    "h1": "Integrate AdPage with SalesBlink",
    "metaDescription": "Integrate AdPage and other Forms & Surveys Tools with SalesBlink. Two Way AdPage Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Triggre",
    "slug": "triggre",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/591c97343ca0077ea202ac26025ac0d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Triggre is a no-code platform that allows you to build applications in hours without developers.",
    "category": "App Builder",
    "title": "Triggre Integration With Cold Email Sales Tool",
    "h1": "Integrate Triggre with SalesBlink",
    "metaDescription": "Integrate Triggre and other App Builder Tools with SalesBlink. Two Way Triggre Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventmaker",
    "slug": "eventmaker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51200b02a7464ac9baf2c7ca7b92b0d2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventmaker is a platform for professional event managers and organizers. It provides everything required for a successful event such as registrations, badges, access control, check-ins, and statistics.",
    "category": "Event Management",
    "title": "Eventmaker Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventmaker with SalesBlink",
    "metaDescription": "Integrate Eventmaker and other Event Management Tools with SalesBlink. Two Way Eventmaker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Connect 365",
    "slug": "connect365",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1ca788acd80611b074f4721d2fa9733.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect 365 is a sales email automation tool designed to help you deliver personal emails at scale.",
    "category": "Email",
    "title": "Connect 365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Connect 365 with SalesBlink",
    "metaDescription": "Integrate Connect 365 and other Email Tools with SalesBlink. Two Way Connect 365 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Societeinfo",
    "slug": "societeinfo-da76110",
    "logo": "https://zapier-images.imgix.net/storage/developer/b7a11a1dbe3f64278b959e5fe183f765.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocieteInfo is a data API that lets you enrich your french company records with legal, contact or social data.",
    "category": "Marketing",
    "title": "Societeinfo Integration With Cold Email Sales Tool",
    "h1": "Integrate Societeinfo with SalesBlink",
    "metaDescription": "Integrate Societeinfo and other Marketing Tools with SalesBlink. Two Way Societeinfo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GatewayAPI SMS",
    "slug": "gatewayapi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eae67a08f1f831f3b3917ecac0cad36e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GatewayAPI is an API service providing out- and ingoing SMS message services at competitive prices.",
    "category": "Phone & SMS",
    "title": "GatewayAPI SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate GatewayAPI SMS with SalesBlink",
    "metaDescription": "Integrate GatewayAPI SMS and other Phone & SMS Tools with SalesBlink. Two Way GatewayAPI SMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Hubilo",
    "slug": "hubilo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a205d09be8597c931b829e1cf96fe00_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hubilo is a virtual event platform to drive audience engagement and event excellence.",
    "category": "Event Management",
    "title": "Hubilo Integration With Cold Email Sales Tool",
    "h1": "Integrate Hubilo with SalesBlink",
    "metaDescription": "Integrate Hubilo and other Event Management Tools with SalesBlink. Two Way Hubilo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EKM",
    "slug": "ekm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/904fdb98d806684b1ca33f99f2fb0a49_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EKM is an eCommerce provider based in the UK that helps you sell online.",
    "category": "eCommerce",
    "title": "EKM Integration With Cold Email Sales Tool",
    "h1": "Integrate EKM with SalesBlink",
    "metaDescription": "Integrate EKM and other eCommerce Tools with SalesBlink. Two Way EKM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teamgate",
    "slug": "teamgate",
    "logo": "https://zapier-images.imgix.net/storage/developer/7c0a7989e8f3aee61fba8de212e38223.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamgate is an intelligent sales CRM with a great insights. Start from Lead capturing and move along to closing a Deal. Get top reports, which will become a necessity from day one.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Teamgate Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamgate with SalesBlink",
    "metaDescription": "Integrate Teamgate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Teamgate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Speechnotes",
    "slug": "speechnotes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c2212e5f6fb77089c6b66fb9c78dbabe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatically transform all audio recordings to text. Most accurate AI transcription (speech to text) engines. Fast, affordable, secure & private.",
    "category": "Transcription",
    "title": "Speechnotes Integration With Cold Email Sales Tool",
    "h1": "Integrate Speechnotes with SalesBlink",
    "metaDescription": "Integrate Speechnotes and other Transcription Tools with SalesBlink. Two Way Speechnotes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GuideCX",
    "slug": "guidecx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7331942f4a05e87665367385ef8b6be_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GuideCX was created by professionals in a number of industries who were similarly frustrated by the lack of solutions and automation in the ‘sale to implementation’ process.",
    "category": "Project Management",
    "title": "GuideCX Integration With Cold Email Sales Tool",
    "h1": "Integrate GuideCX with SalesBlink",
    "metaDescription": "Integrate GuideCX and other Project Management Tools with SalesBlink. Two Way GuideCX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeltaNet CRM",
    "slug": "deltanet-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb0769e8802f9e865648a0400070113d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeltaNET CRM App is CRM and leads management tool for real estate agents.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DeltaNet CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate DeltaNet CRM with SalesBlink",
    "metaDescription": "Integrate DeltaNet CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DeltaNet CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Soundee",
    "slug": "soundee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9bd0baaab0bf7756756e585e9ace7f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Soundee is a social music marketplace where artists and producers can connect and license music.",
    "category": "eCommerce",
    "title": "Soundee Integration With Cold Email Sales Tool",
    "h1": "Integrate Soundee with SalesBlink",
    "metaDescription": "Integrate Soundee and other eCommerce Tools with SalesBlink. Two Way Soundee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Epos Now",
    "slug": "epos-now",
    "logo": "https://zapier-images.imgix.net/storage/developer/15217b3029935241113d16112e27616d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow and manage your business in the cloud with the Epos Now point-of-sale platform. Harness powerful reporting, inventory management and customer loyalty. Easy setup and ongoing support. Access from anywhere on any device.",
    "category": "Payment Processing",
    "title": "Epos Now Integration With Cold Email Sales Tool",
    "h1": "Integrate Epos Now with SalesBlink",
    "metaDescription": "Integrate Epos Now and other Payment Processing Tools with SalesBlink. Two Way Epos Now Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Customer.guru",
    "slug": "customer-guru",
    "logo": "https://zapier-images.imgix.net/storage/developer/4503a1dcdd196ec24cd2a93e2977755a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Customer.guru is an easy-to-setup tool for measuring customer satisfaction using Net Promoter Score.",
    "category": "Forms & Surveys",
    "title": "Customer.guru Integration With Cold Email Sales Tool",
    "h1": "Integrate Customer.guru with SalesBlink",
    "metaDescription": "Integrate Customer.guru and other Forms & Surveys Tools with SalesBlink. Two Way Customer.guru Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chatsonic",
    "slug": "chatsonic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ebd849cc518814bc0d7a019cac46cea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatSonic is a revolutionary AI assistant that helps you write faster and smarter. It uses the latest AI to generate high-quality content.",
    "category": "AI Tools",
    "title": "Chatsonic Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatsonic with SalesBlink",
    "metaDescription": "Integrate Chatsonic and other AI Tools Tools with SalesBlink. Two Way Chatsonic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "PushAlert - Web Push Notifications",
    "slug": "pushalert-web-push-notifications",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99719fe7d392914be634bf31fdf0f19f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PushAlert is a user engagement, retention and marketing automation platform which allows you to push real-time notifications to your website users on both mobile and desktop.",
    "category": "Marketing",
    "title": "PushAlert - Web Push Notifications Integration With Cold Email Sales Tool",
    "h1": "Integrate PushAlert - Web Push Notifications with SalesBlink",
    "metaDescription": "Integrate PushAlert - Web Push Notifications and other Marketing Tools with SalesBlink. Two Way PushAlert - Web Push Notifications Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Timekit",
    "slug": "timekit",
    "logo": "https://zapier-images.imgix.net/storage/developer/0d6c1c90d950606ba9c95340c0d67b00.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timekit lets you build scalable and flexible booking experiences and scheduling flows that grow your business.",
    "category": "Scheduling & Booking",
    "title": "Timekit Integration With Cold Email Sales Tool",
    "h1": "Integrate Timekit with SalesBlink",
    "metaDescription": "Integrate Timekit and other Scheduling & Booking Tools with SalesBlink. Two Way Timekit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fyrebox",
    "slug": "fyrebox",
    "logo": "https://zapier-images.imgix.net/storage/services/04a8744b34b7ae8b26a324daf163fb7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fyrebox makes it easy to create customised interactive quizzes to collect leads for your business.",
    "category": "Marketing",
    "title": "Fyrebox Integration With Cold Email Sales Tool",
    "h1": "Integrate Fyrebox with SalesBlink",
    "metaDescription": "Integrate Fyrebox and other Marketing Tools with SalesBlink. Two Way Fyrebox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drive Chat",
    "slug": "drive-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5b3c5c1b64795d07fd85567ddcfdb7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drive Chat allows your leads to be integrated with your chosen CRM",
    "category": "Contact Management",
    "title": "Drive Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Drive Chat with SalesBlink",
    "metaDescription": "Integrate Drive Chat and other Contact Management Tools with SalesBlink. Two Way Drive Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shopping Feed",
    "slug": "shopping-feed",
    "logo": "https://zapier-images.imgix.net/storage/developer/6f0cc2972641e2e69f997753eebb5f9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shopping Feed is a single platform to List, Sync, and Manage your products, inventory and orders with the world's largest marketplaces, adspaces, affiliate channels and shopping engines.",
    "category": "eCommerce",
    "title": "Shopping Feed Integration With Cold Email Sales Tool",
    "h1": "Integrate Shopping Feed with SalesBlink",
    "metaDescription": "Integrate Shopping Feed and other eCommerce Tools with SalesBlink. Two Way Shopping Feed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeevou",
    "slug": "zeevou",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f8d133868a79329dcb93eb28bbe5d29.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeevou is a Property Management System and Channel Manager that automates almost all areas of your short term rental business, optimises processes, and minimises human error.",
    "category": "Scheduling & Booking",
    "title": "Zeevou Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeevou with SalesBlink",
    "metaDescription": "Integrate Zeevou and other Scheduling & Booking Tools with SalesBlink. Two Way Zeevou Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eturnity",
    "slug": "eturnity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a15a7867fe0f840ff271b154a4eb90a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eturnity is a B2B Saas software solution for customer-friendly offers for building technology. We simplify the steps of acquisition, planning, consulting and sales, increasing efficiency and helping company in the renewable energy branch achieve better close rates.",
    "category": "Productivity",
    "title": "Eturnity Integration With Cold Email Sales Tool",
    "h1": "Integrate Eturnity with SalesBlink",
    "metaDescription": "Integrate Eturnity and other Productivity Tools with SalesBlink. Two Way Eturnity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sleekplan",
    "slug": "sleekplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f7dff92966a090c63ce0813374d18b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sleekplan is a customer feedback management tool that can easily be embedded in any web application. Use the widget to track suggestions, feedback, and bugs and discuss them with your customers. Plus, it' s easy to share a roadmap and keep a changelog.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sleekplan Integration With Cold Email Sales Tool",
    "h1": "Integrate Sleekplan with SalesBlink",
    "metaDescription": "Integrate Sleekplan and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sleekplan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MonkeyLearn",
    "slug": "monkeylearn-legacy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8baac4784d805e3824123b0788fceb97.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MonkeyLearn is a Text Analysis platform that allows you to classify and extract actionable data from raw texts like emails, chats, webpages, documents, tweets and more! You can classify texts with custom tags like sentiment or topic, and extract any particular data like organizations or keywords.",
    "category": "Analytics",
    "title": "MonkeyLearn Integration With Cold Email Sales Tool",
    "h1": "Integrate MonkeyLearn with SalesBlink",
    "metaDescription": "Integrate MonkeyLearn and other Analytics Tools with SalesBlink. Two Way MonkeyLearn Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MailboxValidator",
    "slug": "mailbox-validator",
    "logo": "https://zapier-images.imgix.net/storage/developer/8ee554ce12842d2d252f181196c753a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easy to use email list cleaning service to improve delivery success rate.",
    "category": "Email Newsletters",
    "title": "MailboxValidator Integration With Cold Email Sales Tool",
    "h1": "Integrate MailboxValidator with SalesBlink",
    "metaDescription": "Integrate MailboxValidator and other Email Newsletters Tools with SalesBlink. Two Way MailboxValidator Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "vPlan",
    "slug": "vplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82e4922464208aab4c3f06c4977835c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vPlan is a planning tool to plan and track your work.",
    "category": "Task Management",
    "title": "vPlan Integration With Cold Email Sales Tool",
    "h1": "Integrate vPlan with SalesBlink",
    "metaDescription": "Integrate vPlan and other Task Management Tools with SalesBlink. Two Way vPlan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Offorte Proposals",
    "slug": "offorte-proposals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e3d21bb8cde71fbf6a9ac1d32a553059_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Offorte helps you win more deals by letting you share beautiful business proposals in a smart automated way.",
    "category": "Proposal & Invoice Management",
    "title": "Offorte Proposals Integration With Cold Email Sales Tool",
    "h1": "Integrate Offorte Proposals with SalesBlink",
    "metaDescription": "Integrate Offorte Proposals and other Proposal & Invoice Management Tools with SalesBlink. Two Way Offorte Proposals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Carrot quest",
    "slug": "carrot-quest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff95cfb94c70840443ead42707fabb31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn visitors into customers with data driven conversation and marketing.",
    "category": "Marketing Automation",
    "title": "Carrot quest Integration With Cold Email Sales Tool",
    "h1": "Integrate Carrot quest with SalesBlink",
    "metaDescription": "Integrate Carrot quest and other Marketing Automation Tools with SalesBlink. Two Way Carrot quest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "vivenu",
    "slug": "vivenu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c193004a96ca2343abf366fd1609453.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vivenu offers a unified platform to manage, market and analyze ticket sales.",
    "category": "Event Management",
    "title": "vivenu Integration With Cold Email Sales Tool",
    "h1": "Integrate vivenu with SalesBlink",
    "metaDescription": "Integrate vivenu and other Event Management Tools with SalesBlink. Two Way vivenu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Entegral Base",
    "slug": "entegral-base",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/77b8ab6fc01c46cf5627e14b537b1641.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Base is an online property, listing syndication, contacts and document management system for estate agents.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Entegral Base Integration With Cold Email Sales Tool",
    "h1": "Integrate Entegral Base with SalesBlink",
    "metaDescription": "Integrate Entegral Base and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Entegral Base Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChargeAutomation",
    "slug": "chargeautomation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0e340b70e4502e266816c27551537a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A globally-recognized leader in online check-in, payment automation, deposit collection and upsell.\nFor hotels, B&B’s, vacation rentals, car rentals & hostels.",
    "category": "Product Management",
    "title": "ChargeAutomation Integration With Cold Email Sales Tool",
    "h1": "Integrate ChargeAutomation with SalesBlink",
    "metaDescription": "Integrate ChargeAutomation and other Product Management Tools with SalesBlink. Two Way ChargeAutomation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formdesk",
    "slug": "formdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/608b58961eeef5341955923b2f0c37af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online form builder where powerful flexible features create endless possibilities.",
    "category": "Forms & Surveys",
    "title": "Formdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Formdesk with SalesBlink",
    "metaDescription": "Integrate Formdesk and other Forms & Surveys Tools with SalesBlink. Two Way Formdesk Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Amazon CloudFront",
    "slug": "amazon-cloudfront",
    "logo": "https://zapier-images.imgix.net/storage/developer/461b7cd844cb402b674b75c343c823dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon CloudFront is a content delivery web service. It integrates with other Amazon Web Services products to give developers and businesses an easy way to distribute content to end users with low latency, high data transfer speeds, and no minimum usage commitments.",
    "category": "Amazon",
    "title": "Amazon CloudFront Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon CloudFront with SalesBlink",
    "metaDescription": "Integrate Amazon CloudFront and other Amazon Tools with SalesBlink. Two Way Amazon CloudFront Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upscribe",
    "slug": "upscribe",
    "logo": "https://zapier-images.imgix.net/storage/developer/7e72da2fe22064d4836a3ded6882f979.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upscribe enables you to create embedable newsletter signup forms for Medium, SquareSpace, WordPress, and other awesome platforms.",
    "category": "Email Newsletters",
    "title": "Upscribe Integration With Cold Email Sales Tool",
    "h1": "Integrate Upscribe with SalesBlink",
    "metaDescription": "Integrate Upscribe and other Email Newsletters Tools with SalesBlink. Two Way Upscribe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Albi",
    "slug": "albi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c263bde157cb1b2a9ebdae09dda53d8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Albi is an all inclusive ERP system for the restoration industry that tracks critical business data with as little user input as possible.",
    "category": "Sales & CRM",
    "title": "Albi Integration With Cold Email Sales Tool",
    "h1": "Integrate Albi with SalesBlink",
    "metaDescription": "Integrate Albi and other Sales & CRM Tools with SalesBlink. Two Way Albi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OKAST",
    "slug": "okast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/17a093a4fad3c8b27c27987fb0b45218.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OKAST is a video streaming service that helps you to build, in a click, a branded VOD or video streaming platform to monetize your contents",
    "category": "Video & Audio",
    "title": "OKAST Integration With Cold Email Sales Tool",
    "h1": "Integrate OKAST with SalesBlink",
    "metaDescription": "Integrate OKAST and other Video & Audio Tools with SalesBlink. Two Way OKAST Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rentometer",
    "slug": "rentometer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d2f33a37043213bc385c5f4ed8c4001e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rentometer is a fast and easy way to compare rent anywhere in the U.S.",
    "category": "Analytics",
    "title": "Rentometer Integration With Cold Email Sales Tool",
    "h1": "Integrate Rentometer with SalesBlink",
    "metaDescription": "Integrate Rentometer and other Analytics Tools with SalesBlink. Two Way Rentometer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IgnitePost",
    "slug": "ignitepost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c622e17de31f59b16a4ed16eaed7cd14_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IgnitePost is a handwritten mail and analytics service. Send real pen & ink handwritten cards as easily as sending an email!",
    "category": "Marketing Automation",
    "title": "IgnitePost Integration With Cold Email Sales Tool",
    "h1": "Integrate IgnitePost with SalesBlink",
    "metaDescription": "Integrate IgnitePost and other Marketing Automation Tools with SalesBlink. Two Way IgnitePost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Priority",
    "slug": "priority",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95f47788f3aaaf02a9c24474869c78e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Priority is a comprehensive ERP package empowering hundreds of thousands of end users worldwide in their daily tasks of running the business",
    "category": "Project Management",
    "title": "Priority Integration With Cold Email Sales Tool",
    "h1": "Integrate Priority with SalesBlink",
    "metaDescription": "Integrate Priority and other Project Management Tools with SalesBlink. Two Way Priority Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vainu",
    "slug": "vainu",
    "logo": "https://zapier-images.imgix.net/storage/developer/08f4507b5c52e66924be0890404a2e36.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Platform for updating your CRM or marketing automation tool with real-time company information.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vainu Integration With Cold Email Sales Tool",
    "h1": "Integrate Vainu with SalesBlink",
    "metaDescription": "Integrate Vainu and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vainu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zotabox",
    "slug": "zotabox",
    "logo": "https://zapier-images.imgix.net/storage/developer/9a729eb05acb21faddae8f00bbda6261.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zotabox Marketing Tools.",
    "category": "Dashboards",
    "title": "Zotabox Integration With Cold Email Sales Tool",
    "h1": "Integrate Zotabox with SalesBlink",
    "metaDescription": "Integrate Zotabox and other Dashboards Tools with SalesBlink. Two Way Zotabox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BayEngage",
    "slug": "bayengage-email-marketing-automa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b7226e6715862346a3302bb0c097e92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BayEngage is a customer engagement platform that helps businesses of all sizes and types scale and automate their marketing efforts.",
    "category": "Marketing Automation",
    "title": "BayEngage Integration With Cold Email Sales Tool",
    "h1": "Integrate BayEngage with SalesBlink",
    "metaDescription": "Integrate BayEngage and other Marketing Automation Tools with SalesBlink. Two Way BayEngage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trumpia",
    "slug": "trumpia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a7908fadf0beb41bacec609755c483ea_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trumpia offers text messaging in the easiest platform to start with, giving you everything you need for your first campagin.",
    "category": "Marketing Automation",
    "title": "Trumpia Integration With Cold Email Sales Tool",
    "h1": "Integrate Trumpia with SalesBlink",
    "metaDescription": "Integrate Trumpia and other Marketing Automation Tools with SalesBlink. Two Way Trumpia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Learnyst",
    "slug": "learnyst",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c5f062b4d9b53e329d3c7d03b35f6394.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learnyst is an online course platform. It allows you to create and sell courses and mocktests without any technical knowledge from your own branded website and mobile apps.",
    "category": "Education",
    "title": "Learnyst Integration With Cold Email Sales Tool",
    "h1": "Integrate Learnyst with SalesBlink",
    "metaDescription": "Integrate Learnyst and other Education Tools with SalesBlink. Two Way Learnyst Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Interactive Calculator",
    "slug": "interactive-calculator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27c0471cf02cb7fa2c0a8adcc84a94fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Interactive Calculator is a calculator form builder you can embed on your website",
    "category": "Forms & Surveys",
    "title": "Interactive Calculator Integration With Cold Email Sales Tool",
    "h1": "Integrate Interactive Calculator with SalesBlink",
    "metaDescription": "Integrate Interactive Calculator and other Forms & Surveys Tools with SalesBlink. Two Way Interactive Calculator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Realty Executives Prime Agent",
    "slug": "realty-executives-prime-agent",
    "logo": "https://zapier-images.imgix.net/storage/services/0228bb890ebb6155ed40b62d20e52984.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prime Agent is Realty Executives' proprietary intranet dashboard that provides quick access to business tools, marketing collateral, news, and resources in one ecosystem.",
    "category": "Product Management",
    "title": "Realty Executives Prime Agent Integration With Cold Email Sales Tool",
    "h1": "Integrate Realty Executives Prime Agent with SalesBlink",
    "metaDescription": "Integrate Realty Executives Prime Agent and other Product Management Tools with SalesBlink. Two Way Realty Executives Prime Agent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xplor Studio",
    "slug": "xplor-studio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bbb43daf93d2b8fcf411ed20a3b0c09e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xplor Studio is a studio management platform with all the tools to make life easier, discover new revenue streams, and build unique fitness experiences from anywhere.",
    "category": "Scheduling & Booking",
    "title": "Xplor Studio Integration With Cold Email Sales Tool",
    "h1": "Integrate Xplor Studio with SalesBlink",
    "metaDescription": "Integrate Xplor Studio and other Scheduling & Booking Tools with SalesBlink. Two Way Xplor Studio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elite Funnels",
    "slug": "elite-funnels",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/524870271e0b7c716bb2798f4ab5b746.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elite Funnels is a service that allows you to build awesome super fast funnels and websites",
    "category": "Website Builders",
    "title": "Elite Funnels Integration With Cold Email Sales Tool",
    "h1": "Integrate Elite Funnels with SalesBlink",
    "metaDescription": "Integrate Elite Funnels and other Website Builders Tools with SalesBlink. Two Way Elite Funnels Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "5 Stars Reputation",
    "slug": "stars-reputation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3cdb26f9f172af779e91ca00eb05c045.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "5 Stars Reputation enables users to add their company's reviews to the 5 Stars Reputation platform",
    "category": "CRM (Customer Relationship Management)",
    "title": "5 Stars Reputation Integration With Cold Email Sales Tool",
    "h1": "Integrate 5 Stars Reputation with SalesBlink",
    "metaDescription": "Integrate 5 Stars Reputation and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 5 Stars Reputation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Walled Garden",
    "slug": "walled-garden",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/967cfd188447a355964e5c738d68cc14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Walled Garden sends Real Estate leads from Facebook Listing Ads.",
    "category": "Social Media Marketing",
    "title": "Walled Garden Integration With Cold Email Sales Tool",
    "h1": "Integrate Walled Garden with SalesBlink",
    "metaDescription": "Integrate Walled Garden and other Social Media Marketing Tools with SalesBlink. Two Way Walled Garden Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Century 21 TRACKER",
    "slug": "century-tracker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8668a07bf06c051799029ee954f4d598_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Century 21® TRACKER is a communication tool used by real estate agents and their clients to simplify the home buying and selling experience.",
    "category": "Productivity",
    "title": "Century 21 TRACKER Integration With Cold Email Sales Tool",
    "h1": "Integrate Century 21 TRACKER with SalesBlink",
    "metaDescription": "Integrate Century 21 TRACKER and other Productivity Tools with SalesBlink. Two Way Century 21 TRACKER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taiga",
    "slug": "taiga",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c8fc8a5eb17175587c2125027e88769.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taiga is the project management tool for multi-functional agile teams",
    "category": "Project Management",
    "title": "Taiga Integration With Cold Email Sales Tool",
    "h1": "Integrate Taiga with SalesBlink",
    "metaDescription": "Integrate Taiga and other Project Management Tools with SalesBlink. Two Way Taiga Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Kobana (previously Boleto Simples)",
    "slug": "kobana",
    "logo": "https://zapier-images.imgix.net/storage/services/86e7d676691dc2a5931fa81635636155.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kobana connects your company's or your users' internal systems through a unique API interface that automates billing, updates statements, makes payments at more than 30 Brazilian banks in a scalable and secure way.",
    "category": "Payment Processing",
    "title": "Kobana (previously Boleto Simples) Integration With Cold Email Sales Tool",
    "h1": "Integrate Kobana (previously Boleto Simples) with SalesBlink",
    "metaDescription": "Integrate Kobana (previously Boleto Simples) and other Payment Processing Tools with SalesBlink. Two Way Kobana (previously Boleto Simples) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Surveypal",
    "slug": "surveypal",
    "logo": "https://zapier-images.imgix.net/storage/developer/3b7a97f983d0fbffe563bfaa28188205.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send branded surveys after any customer interaction, then monitor performance and improve survey response rates with Surveypal's real-time analytics and intelligent alerts.",
    "category": "Forms & Surveys",
    "title": "Surveypal Integration With Cold Email Sales Tool",
    "h1": "Integrate Surveypal with SalesBlink",
    "metaDescription": "Integrate Surveypal and other Forms & Surveys Tools with SalesBlink. Two Way Surveypal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voiceform",
    "slug": "voiceform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d0d3c59de044ef909328f09b6fc0c31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Voiceform lets you create voice, video, and text surveys and forms that feel like a conversation. Collect and share rich data and feedback.",
    "category": "Forms & Surveys",
    "title": "Voiceform Integration With Cold Email Sales Tool",
    "h1": "Integrate Voiceform with SalesBlink",
    "metaDescription": "Integrate Voiceform and other Forms & Surveys Tools with SalesBlink. Two Way Voiceform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HomeASAP Leads",
    "slug": "homeasap-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb927da82697d1578a896662a70368a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HomeASAP Leads is a real estate lead-generation tool.",
    "category": "CRM (Customer Relationship Management)",
    "title": "HomeASAP Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate HomeASAP Leads with SalesBlink",
    "metaDescription": "Integrate HomeASAP Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way HomeASAP Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tick",
    "slug": "tick",
    "logo": "https://zapier-images.imgix.net/storage/developer/170d29dd944d59abb163eb2b29118504.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tick keeps projects on track so you make more money. It's time tracking worth everyone's time.",
    "category": "Time Tracking Software",
    "title": "Tick Integration With Cold Email Sales Tool",
    "h1": "Integrate Tick with SalesBlink",
    "metaDescription": "Integrate Tick and other Time Tracking Software Tools with SalesBlink. Two Way Tick Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buttondown",
    "slug": "buttondown",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a90148902c6c86f0525a472ffe4014b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Buttondown is an easy way to start and grow your newsletter.",
    "category": "Email Newsletters",
    "title": "Buttondown Integration With Cold Email Sales Tool",
    "h1": "Integrate Buttondown with SalesBlink",
    "metaDescription": "Integrate Buttondown and other Email Newsletters Tools with SalesBlink. Two Way Buttondown Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Miestro",
    "slug": "miestro",
    "logo": "https://zapier-images.imgix.net/storage/developer/f2cd69eebe53005394d48684d85393c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Miestro is a membership platform for online education courses, where you can create powerful courses with free or payable plans.",
    "category": "Online Courses",
    "title": "Miestro Integration With Cold Email Sales Tool",
    "h1": "Integrate Miestro with SalesBlink",
    "metaDescription": "Integrate Miestro and other Online Courses Tools with SalesBlink. Two Way Miestro Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Weekdone",
    "slug": "weekdone",
    "logo": "https://zapier-images.imgix.net/storage/services/c911d595b20849d75742a6801753633b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Weekdone weekly progress reports for managers and internal communication for teams.",
    "category": "Analytics",
    "title": "Weekdone Integration With Cold Email Sales Tool",
    "h1": "Integrate Weekdone with SalesBlink",
    "metaDescription": "Integrate Weekdone and other Analytics Tools with SalesBlink. Two Way Weekdone Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Vestaboard",
    "slug": "vestaboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08379d90172a641849ac9dfd050f71e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vestaboard is a smart messaging display that connects and inspires people. Send messages to your Vestaboard from anywhere or set up clever message automations.",
    "category": "Communication",
    "title": "Vestaboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Vestaboard with SalesBlink",
    "metaDescription": "Integrate Vestaboard and other Communication Tools with SalesBlink. Two Way Vestaboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cash Flow Portal",
    "slug": "cash-flow-portal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fc895d3cdf3cb0bfe0398afcb677194.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real-estate syndication software that accelerates capital raising.",
    "category": "Accounting",
    "title": "Cash Flow Portal Integration With Cold Email Sales Tool",
    "h1": "Integrate Cash Flow Portal with SalesBlink",
    "metaDescription": "Integrate Cash Flow Portal and other Accounting Tools with SalesBlink. Two Way Cash Flow Portal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailingVox",
    "slug": "mailingvox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb6b58e9dc536540a59d4f0c220f5e1e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "In a few clicks, manage your multichannel campaigns (voice mailing, SMS, EMAIL ...) on a simple, efficient and fast interface",
    "category": "Marketing",
    "title": "MailingVox Integration With Cold Email Sales Tool",
    "h1": "Integrate MailingVox with SalesBlink",
    "metaDescription": "Integrate MailingVox and other Marketing Tools with SalesBlink. Two Way MailingVox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EdApp",
    "slug": "edapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfb7f0fee5490eeb790de201f96439b7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EdApp is an all-in-one mobile training solution with rapid authoring, free editable course library and a learner experience built for mobile",
    "category": "Education",
    "title": "EdApp Integration With Cold Email Sales Tool",
    "h1": "Integrate EdApp with SalesBlink",
    "metaDescription": "Integrate EdApp and other Education Tools with SalesBlink. Two Way EdApp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyWiFi Networks",
    "slug": "mywifi-networks",
    "logo": "https://zapier-images.imgix.net/storage/developer/6cea70d1284b76eb073121ff676791f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyWiFi Networks operates a Guest Wi-Fi Monetization Platform with social data capture, marketing automation & real-time analytics.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MyWiFi Networks Integration With Cold Email Sales Tool",
    "h1": "Integrate MyWiFi Networks with SalesBlink",
    "metaDescription": "Integrate MyWiFi Networks and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MyWiFi Networks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Picreel",
    "slug": "picreel",
    "logo": "https://zapier-images.imgix.net/storage/developer/a41cc336243ff09d83a2b8b56883ad49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Picreel provides exit offers for your website to allow you recover abandoning visitors and improve your conversion rates.",
    "category": "eCommerce",
    "title": "Picreel Integration With Cold Email Sales Tool",
    "h1": "Integrate Picreel with SalesBlink",
    "metaDescription": "Integrate Picreel and other eCommerce Tools with SalesBlink. Two Way Picreel Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Website Toolbox Community",
    "slug": "website-toolbox-forum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c06d7c58630fcdad99d6b7b8339a977a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Organize your discussions into searchable topics and categories under your brand with an independent or embedded community forum.",
    "category": "Website Builders",
    "title": "Website Toolbox Community Integration With Cold Email Sales Tool",
    "h1": "Integrate Website Toolbox Community with SalesBlink",
    "metaDescription": "Integrate Website Toolbox Community and other Website Builders Tools with SalesBlink. Two Way Website Toolbox Community Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ThunderTix",
    "slug": "thundertix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa286c4d018096dae96731f4ffc6869a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thundertix is an all-in-one event ticketing platform to integrate your event ticketing, guest lists, customer data, barcode for fraud prevention and even order and donor histories to easily manage your events.",
    "category": "Event Management",
    "title": "ThunderTix Integration With Cold Email Sales Tool",
    "h1": "Integrate ThunderTix with SalesBlink",
    "metaDescription": "Integrate ThunderTix and other Event Management Tools with SalesBlink. Two Way ThunderTix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MealPro App",
    "slug": "mealpro-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/adbdd1b4459ef2b47cd670995c4a14d5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MealPro App is a white-label and meal planning tool that allows you to share custom-branded plans and recipes with your clients and community, without writing a line of code.",
    "category": "Website & App Building",
    "title": "MealPro App Integration With Cold Email Sales Tool",
    "h1": "Integrate MealPro App with SalesBlink",
    "metaDescription": "Integrate MealPro App and other Website & App Building Tools with SalesBlink. Two Way MealPro App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyGadgetRepairs",
    "slug": "mygadgetrepairs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6cf94fe2d48dc9cd8ad85e17172aedaf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyGadgetRepairs gives you tracking, inventory management, invoicing, point of sale and much more. Trusted by hundreds of users across the globe.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MyGadgetRepairs Integration With Cold Email Sales Tool",
    "h1": "Integrate MyGadgetRepairs with SalesBlink",
    "metaDescription": "Integrate MyGadgetRepairs and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MyGadgetRepairs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickChart",
    "slug": "quickchart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d41e6b0b24810043dada60f9b825bb3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickChart turns your data into chart and graph images that you can use anywhere.",
    "category": "Images & Design",
    "title": "QuickChart Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickChart with SalesBlink",
    "metaDescription": "Integrate QuickChart and other Images & Design Tools with SalesBlink. Two Way QuickChart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "The Events Calendar",
    "slug": "the-events-calendar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b24909f6908bb39eb5114dfe3ef8dcc3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Power your events for free with The Events Calendar, or upgrade to Pro to unlock recurring events, views, premium support, and more.",
    "category": "Event Management",
    "title": "The Events Calendar Integration With Cold Email Sales Tool",
    "h1": "Integrate The Events Calendar with SalesBlink",
    "metaDescription": "Integrate The Events Calendar and other Event Management Tools with SalesBlink. Two Way The Events Calendar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upcall",
    "slug": "upcall",
    "logo": "https://zapier-images.imgix.net/storage/developer/893d3bf634f14e3a8a1a5f62ceaf219b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upcall provides phone callers on demand. Import phone numbers and our professional callers will make sales and marketing calls for you.",
    "category": "Phone & SMS",
    "title": "Upcall Integration With Cold Email Sales Tool",
    "h1": "Integrate Upcall with SalesBlink",
    "metaDescription": "Integrate Upcall and other Phone & SMS Tools with SalesBlink. Two Way Upcall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChargeDesk",
    "slug": "chargedesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a42b78fa86a50c72222419a9b3f9429.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChargeDesk helps you manage your customer payments inside of your helpdesk. This integration helps you automate tasks such as exporting charge or customer data, automatically requesting payments and cancelling subscriptions.",
    "category": "Customer Support",
    "title": "ChargeDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate ChargeDesk with SalesBlink",
    "metaDescription": "Integrate ChargeDesk and other Customer Support Tools with SalesBlink. Two Way ChargeDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Layerise",
    "slug": "layerise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7db869d932ff3abdec46a12655bfd361.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Layerise helps you build product assistants that guide and interact with your customers.",
    "category": "Customer Support",
    "title": "Layerise Integration With Cold Email Sales Tool",
    "h1": "Integrate Layerise with SalesBlink",
    "metaDescription": "Integrate Layerise and other Customer Support Tools with SalesBlink. Two Way Layerise Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Calendbook",
    "slug": "calendbook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1afd311fa39a069de6108260820401be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Share your calendar with a link to get more bookings",
    "category": "Calendar",
    "title": "Calendbook Integration With Cold Email Sales Tool",
    "h1": "Integrate Calendbook with SalesBlink",
    "metaDescription": "Integrate Calendbook and other Calendar Tools with SalesBlink. Two Way Calendbook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Back In Stock - Restock Alerts",
    "slug": "back-in-stock-restock-alerts-ca9",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1852b9659be6d14639ad07949570f87c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "'Back In Stock - Restock Alert's is a plugin for e-commerce store owners to notify their users when a product comes back in stock.",
    "category": "eCommerce",
    "title": "Back In Stock - Restock Alerts Integration With Cold Email Sales Tool",
    "h1": "Integrate Back In Stock - Restock Alerts with SalesBlink",
    "metaDescription": "Integrate Back In Stock - Restock Alerts and other eCommerce Tools with SalesBlink. Two Way Back In Stock - Restock Alerts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cheqroom",
    "slug": "cheqroom",
    "logo": "https://zapier-images.imgix.net/storage/services/7172582ebc085b0ee58a80af370aadf2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cheqroom is an equipment tracking service that makes it easy to know who has what equipment and when it’s due back.",
    "category": "Scheduling & Booking",
    "title": "Cheqroom Integration With Cold Email Sales Tool",
    "h1": "Integrate Cheqroom with SalesBlink",
    "metaDescription": "Integrate Cheqroom and other Scheduling & Booking Tools with SalesBlink. Two Way Cheqroom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoCo",
    "slug": "goco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d66c60a238fa0c484c2e57b21bcb8a01.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoCo is a modern HR, benefits and payroll platform that automates your workflow, without forcing you to change your processes, policies, and providers.",
    "category": "Human Resources",
    "title": "GoCo Integration With Cold Email Sales Tool",
    "h1": "Integrate GoCo with SalesBlink",
    "metaDescription": "Integrate GoCo and other Human Resources Tools with SalesBlink. Two Way GoCo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flipdish",
    "slug": "flipdish",
    "logo": "https://zapier-images.imgix.net/storage/services/7d9f4cdc97a04ff197b8e781f16ee19a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online ordering system for restaurants. Create your own branded food ordering website in minutes.",
    "category": "Scheduling & Booking",
    "title": "Flipdish Integration With Cold Email Sales Tool",
    "h1": "Integrate Flipdish with SalesBlink",
    "metaDescription": "Integrate Flipdish and other Scheduling & Booking Tools with SalesBlink. Two Way Flipdish Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloud BOT",
    "slug": "cloud-bot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65a9501bd0ab36d3e41b38627a0656ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud BOT is a freemium service for web browser automation. You can easily create your own robot and it can be run at any time in the cloud.",
    "category": "Developer Tools",
    "title": "Cloud BOT Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloud BOT with SalesBlink",
    "metaDescription": "Integrate Cloud BOT and other Developer Tools Tools with SalesBlink. Two Way Cloud BOT Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "vFairs",
    "slug": "vfairs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0fa7a9092f370aeaa5711ec2c434bbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vFairs is a virtual events platform. This integration will allow users to send users data within vFairs with external systems.",
    "category": "Event Management",
    "title": "vFairs Integration With Cold Email Sales Tool",
    "h1": "Integrate vFairs with SalesBlink",
    "metaDescription": "Integrate vFairs and other Event Management Tools with SalesBlink. Two Way vFairs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unstack",
    "slug": "unstack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9ab73e6036c7ef984ae902fb8233113.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unstack digital engine provides teams with the tools to build, measure, and scale their web presence.",
    "category": "Contact Management",
    "title": "Unstack Integration With Cold Email Sales Tool",
    "h1": "Integrate Unstack with SalesBlink",
    "metaDescription": "Integrate Unstack and other Contact Management Tools with SalesBlink. Two Way Unstack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buzz",
    "slug": "buzz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42640190561c8f95416890355f42539b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powerful Deal-Closing Sales Engagement Software. Maximize your reach with our scalable, multi-channel engagement platform driven by real data, customized strategy, and full automation.",
    "category": "Marketing Automation",
    "title": "Buzz Integration With Cold Email Sales Tool",
    "h1": "Integrate Buzz with SalesBlink",
    "metaDescription": "Integrate Buzz and other Marketing Automation Tools with SalesBlink. Two Way Buzz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoTo Meeting",
    "slug": "gotomeeting",
    "logo": "https://zapier-images.imgix.net/storage/developer/cfc690c68173ea3d10ec4b1aa17c6615.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoTo Meeting by LogMeIn is a world-class video conference software that makes it easier than ever to do business effectively even if you aren't in the same room.",
    "category": "Video Conferencing",
    "title": "GoTo Meeting Integration With Cold Email Sales Tool",
    "h1": "Integrate GoTo Meeting with SalesBlink",
    "metaDescription": "Integrate GoTo Meeting and other Video Conferencing Tools with SalesBlink. Two Way GoTo Meeting Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Calldrip",
    "slug": "call-drip",
    "logo": "https://zapier-images.imgix.net/storage/developer/4f0b8d8bf386406a9711112a631a2b4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Calldrip connects you and your new lead within 20 seconds increasing your chances of closing by 100%",
    "category": "Call Tracking",
    "title": "Calldrip Integration With Cold Email Sales Tool",
    "h1": "Integrate Calldrip with SalesBlink",
    "metaDescription": "Integrate Calldrip and other Call Tracking Tools with SalesBlink. Two Way Calldrip Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TextP2P",
    "slug": "textp2p",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1285ff4daad8cc3c8d0c0ccd41d42f08.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextP2P is a SMS & MMS text marketing system that enables you to cultivate better relationships with customers and streamline follow-ups.",
    "category": "Phone & SMS",
    "title": "TextP2P Integration With Cold Email Sales Tool",
    "h1": "Integrate TextP2P with SalesBlink",
    "metaDescription": "Integrate TextP2P and other Phone & SMS Tools with SalesBlink. Two Way TextP2P Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Concord CLM",
    "slug": "concord-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6afacf1c97e6aeb84a41c6b2d26359d6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Concord is a full contract lifecycle management platform with drafting, approvals, e-signature and storage - all in one place.",
    "category": "Signatures",
    "title": "Concord CLM Integration With Cold Email Sales Tool",
    "h1": "Integrate Concord CLM with SalesBlink",
    "metaDescription": "Integrate Concord CLM and other Signatures Tools with SalesBlink. Two Way Concord CLM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoe Financial",
    "slug": "zoe-financial",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe2604ce0b13e039f1169069724f5c35_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoe Financial connects clients to financial advisors. Advisors listed on Zoe's network are all independent fiduciaries who work on a commission-free model, meaning your advisor won’t try to sell you products to make a profit.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zoe Financial Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoe Financial with SalesBlink",
    "metaDescription": "Integrate Zoe Financial and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zoe Financial Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hello Outbound",
    "slug": "hello-outbound",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f695f72c550d6cf344abff8c2753d590.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hello Outbound is a sales platform that combines event data with authentic outreach campaigns.",
    "category": "Sales & CRM",
    "title": "Hello Outbound Integration With Cold Email Sales Tool",
    "h1": "Integrate Hello Outbound with SalesBlink",
    "metaDescription": "Integrate Hello Outbound and other Sales & CRM Tools with SalesBlink. Two Way Hello Outbound Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bouncer",
    "slug": "bouncer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2e3033393f15f981e7e7a37291719be6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bouncer is a fast, user-friendly, and powerful email verification service.",
    "category": "Email",
    "title": "Bouncer Integration With Cold Email Sales Tool",
    "h1": "Integrate Bouncer with SalesBlink",
    "metaDescription": "Integrate Bouncer and other Email Tools with SalesBlink. Two Way Bouncer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SmartSurvey",
    "slug": "smartsurvey",
    "logo": "https://zapier-images.imgix.net/storage/developer/dd821a3813860cfa4893a0fa0fe37505.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartSurvey provides powerful, advanced online survey software. Built to let individuals and teams create any type of questionnaire or form to get answers fast.",
    "category": "Forms & Surveys",
    "title": "SmartSurvey Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartSurvey with SalesBlink",
    "metaDescription": "Integrate SmartSurvey and other Forms & Surveys Tools with SalesBlink. Two Way SmartSurvey Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Cakemail",
    "slug": "cakemail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d50e11195e574072c4152015584424d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cakemail provides all the tools you need to deliver the emails people want. Engage your audience and grow your business with the tools and ongoing support you need to send marketing emails.",
    "category": "Email Newsletters",
    "title": "Cakemail Integration With Cold Email Sales Tool",
    "h1": "Integrate Cakemail with SalesBlink",
    "metaDescription": "Integrate Cakemail and other Email Newsletters Tools with SalesBlink. Two Way Cakemail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Airwallex",
    "slug": "airwallex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c39bdb785bfa9a6aabe26bd077140324.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Airwallex is a payments platform transforming the way businesses move and manage money globally.",
    "category": "Payment Processing",
    "title": "Airwallex Integration With Cold Email Sales Tool",
    "h1": "Integrate Airwallex with SalesBlink",
    "metaDescription": "Integrate Airwallex and other Payment Processing Tools with SalesBlink. Two Way Airwallex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "4Degrees",
    "slug": "4degrees",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/444028a2e2dab27b4041c2940a8b7bdf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "4Degrees is a relationship development tool that helps professionals build stronger, more rewarding networks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "4Degrees Integration With Cold Email Sales Tool",
    "h1": "Integrate 4Degrees with SalesBlink",
    "metaDescription": "Integrate 4Degrees and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 4Degrees Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "slyText",
    "slug": "slytext",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee0d4caff3cf7d39dbf8eee617a35626.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SlyText can send SMS messages to individual contacts or to a group of contacts.",
    "category": "Phone & SMS",
    "title": "slyText Integration With Cold Email Sales Tool",
    "h1": "Integrate slyText with SalesBlink",
    "metaDescription": "Integrate slyText and other Phone & SMS Tools with SalesBlink. Two Way slyText Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fieldnotes",
    "slug": "fieldnotes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ecda6eba0c171d04c9b7f6d5f73bd3b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fieldnotes is a minimalistic self-learning CRM platform that updates contacts, manages transaction deadlines, and handles data entry.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Fieldnotes Integration With Cold Email Sales Tool",
    "h1": "Integrate Fieldnotes with SalesBlink",
    "metaDescription": "Integrate Fieldnotes and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Fieldnotes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bloom",
    "slug": "bloom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49f02afb2c632c77843f5d3a051867a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The all-encompassing business solution. See how Bloom's marketing CRM + project management software makes managing your business 10x easier.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bloom Integration With Cold Email Sales Tool",
    "h1": "Integrate Bloom with SalesBlink",
    "metaDescription": "Integrate Bloom and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bloom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "X-Cart",
    "slug": "x-cart",
    "logo": "https://zapier-images.imgix.net/storage/services/d300c234cfa55783ad1d4d84925b9720.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "X-Cart is an all-in-one open source eCommerce solution that can be used to sell physical and digital goods, services, rentals, and subscriptions.",
    "category": "eCommerce",
    "title": "X-Cart Integration With Cold Email Sales Tool",
    "h1": "Integrate X-Cart with SalesBlink",
    "metaDescription": "Integrate X-Cart and other eCommerce Tools with SalesBlink. Two Way X-Cart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moloni",
    "slug": "moloni",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ebf8dba1183d8e1b8de7e7ccb7e6f65_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moloni is an online invoicing software, that allows each entrepreneur to effectively control business, automate billing, deliver documents quickly and intuitively.",
    "category": "Accounting",
    "title": "Moloni Integration With Cold Email Sales Tool",
    "h1": "Integrate Moloni with SalesBlink",
    "metaDescription": "Integrate Moloni and other Accounting Tools with SalesBlink. Two Way Moloni Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Protexting",
    "slug": "protexting",
    "logo": "https://zapier-images.imgix.net/storage/developer/f70d9de9cc5e8bd798ef80e902c3e6c4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProTexting is a provider of SMS and MMS messaging tools.",
    "category": "Phone & SMS",
    "title": "Protexting Integration With Cold Email Sales Tool",
    "h1": "Integrate Protexting with SalesBlink",
    "metaDescription": "Integrate Protexting and other Phone & SMS Tools with SalesBlink. Two Way Protexting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DashClicks",
    "slug": "dashclicks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2986e995392a9bf06f0734485e754fda_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DashClicks is SaaS product offering a variety of digital marketing services and software solutions generate leads and facilitate sales.",
    "category": "Marketing",
    "title": "DashClicks Integration With Cold Email Sales Tool",
    "h1": "Integrate DashClicks with SalesBlink",
    "metaDescription": "Integrate DashClicks and other Marketing Tools with SalesBlink. Two Way DashClicks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Abyssale",
    "slug": "abyssale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e8b889ae6a6126ab6d4d52a648c838e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Abyssale is a banner maker platform that helps you to automatically generate on-brand images at scale.",
    "category": "Images & Design",
    "title": "Abyssale Integration With Cold Email Sales Tool",
    "h1": "Integrate Abyssale with SalesBlink",
    "metaDescription": "Integrate Abyssale and other Images & Design Tools with SalesBlink. Two Way Abyssale Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clust",
    "slug": "clust",
    "logo": "https://zapier-images.imgix.net/storage/services/bbab08e4e1fd87dc838ecb8c0001e8d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Receive online applications easily through a branded client portal and manage them from a robust web-app with Clust.",
    "category": "Documents",
    "title": "Clust Integration With Cold Email Sales Tool",
    "h1": "Integrate Clust with SalesBlink",
    "metaDescription": "Integrate Clust and other Documents Tools with SalesBlink. Two Way Clust Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zabun",
    "slug": "zabun",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9a772f877f629c77bfefeb854891d8d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zabun is an online CRM for real-estate to maintain your properties, contacts, requests, tasks and other data and connections.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zabun Integration With Cold Email Sales Tool",
    "h1": "Integrate Zabun with SalesBlink",
    "metaDescription": "Integrate Zabun and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zabun Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Approveit",
    "slug": "approveit",
    "logo": "https://zapier-images.imgix.net/storage/services/306b289c4ac5fbabf6da90ed55ef158a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Approveit is a simple way to streamline internal approval processes like: access requests, software releases, quotes, docs, even PTOs - all through Slack.",
    "category": "IT Operations",
    "title": "Approveit Integration With Cold Email Sales Tool",
    "h1": "Integrate Approveit with SalesBlink",
    "metaDescription": "Integrate Approveit and other IT Operations Tools with SalesBlink. Two Way Approveit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CozyCal",
    "slug": "cozycal",
    "logo": "https://zapier-images.imgix.net/storage/developer/28396224f607dfc1eb7132b3f8755df8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CozyCal is a friendly appointment scheduling service that easily integrates with your website.",
    "category": "Scheduling & Booking",
    "title": "CozyCal Integration With Cold Email Sales Tool",
    "h1": "Integrate CozyCal with SalesBlink",
    "metaDescription": "Integrate CozyCal and other Scheduling & Booking Tools with SalesBlink. Two Way CozyCal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneLocal LocalReviews",
    "slug": "onelocal-localreviews",
    "logo": "https://zapier-images.imgix.net/storage/services/313fff74e3465bab45897f37ba7d3106_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LocalReviews by OneLocal provides a fully customizable survey & review generation tool for businesses so they can request feedback from their customers by text message or email & improve their online reputation.",
    "category": "Forms & Surveys",
    "title": "OneLocal LocalReviews Integration With Cold Email Sales Tool",
    "h1": "Integrate OneLocal LocalReviews with SalesBlink",
    "metaDescription": "Integrate OneLocal LocalReviews and other Forms & Surveys Tools with SalesBlink. Two Way OneLocal LocalReviews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Waitlist",
    "slug": "waitlist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea22d394df90eba9a006835e473cd1d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Waitlist is a simple app to integrate email signups with a referral system prior to your next product launch.",
    "category": "Ads & Conversion",
    "title": "Waitlist Integration With Cold Email Sales Tool",
    "h1": "Integrate Waitlist with SalesBlink",
    "metaDescription": "Integrate Waitlist and other Ads & Conversion Tools with SalesBlink. Two Way Waitlist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cinode",
    "slug": "cinode",
    "logo": "https://zapier-images.imgix.net/storage/developer/a57e4943cb9e72068d03631d2f418dd2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cinode is a way to run consultancies with support for sales, resourcing and skills management.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Cinode Integration With Cold Email Sales Tool",
    "h1": "Integrate Cinode with SalesBlink",
    "metaDescription": "Integrate Cinode and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Cinode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Veem",
    "slug": "veem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1fbd578a9549398c0b0ebe2e0235189.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Veem is an online global bank transfer service for you to send & receive payments internationally with ease.",
    "category": "Payment Processing",
    "title": "Veem Integration With Cold Email Sales Tool",
    "h1": "Integrate Veem with SalesBlink",
    "metaDescription": "Integrate Veem and other Payment Processing Tools with SalesBlink. Two Way Veem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailvio",
    "slug": "mailvio",
    "logo": "https://zapier-images.imgix.net/storage/services/dbbf67b5a4091d47fdc29e025e20c9d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailvio is an advanced email marketing and automation platform you can use to send newsletter emails, manage your transactional emails, and send SMS.",
    "category": "Email Newsletters",
    "title": "Mailvio Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailvio with SalesBlink",
    "metaDescription": "Integrate Mailvio and other Email Newsletters Tools with SalesBlink. Two Way Mailvio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clinicminds",
    "slug": "clinicminds",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c0caa543cf14a9c79cc75664077b373e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clinicminds is the leading practice and patient management software for private healthcare clinics.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clinicminds Integration With Cold Email Sales Tool",
    "h1": "Integrate Clinicminds with SalesBlink",
    "metaDescription": "Integrate Clinicminds and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clinicminds Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zenloop",
    "slug": "zenloop",
    "logo": "https://zapier-images.imgix.net/storage/services/eb065b7bea413b78d300518f87b5ca40.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "zenloop is a NPS feedback management platform which improves customer retention by using the Net Promoter System® (NPS) framework. \nzenloop's SaaS platform collects quantitative and qualitative feedback through various channels (link, email, website or each embedded). Our smart label technology analyzes the feedback and automatically identifies all relevant insights and trends.",
    "category": "Forms & Surveys",
    "title": "zenloop Integration With Cold Email Sales Tool",
    "h1": "Integrate zenloop with SalesBlink",
    "metaDescription": "Integrate zenloop and other Forms & Surveys Tools with SalesBlink. Two Way zenloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amplifier",
    "slug": "amplifier",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7d5d872a51ce8373afc25c99768a8dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amplifier is a powerful print-on-demand, screen printing & logistics platform.",
    "category": "eCommerce",
    "title": "Amplifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Amplifier with SalesBlink",
    "metaDescription": "Integrate Amplifier and other eCommerce Tools with SalesBlink. Two Way Amplifier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendblue",
    "slug": "sendblue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a24a7d79116048d83370e1f47ffb2c59.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iMessage and SMS for business. Interact with your users, leads, and customers with our powerful messaging platform.",
    "category": "Phone & SMS",
    "title": "Sendblue Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendblue with SalesBlink",
    "metaDescription": "Integrate Sendblue and other Phone & SMS Tools with SalesBlink. Two Way Sendblue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Helpjuice",
    "slug": "helpjuice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49a0056295498ac153d5fe90dc4b6a09.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helpjuice’s is a knowledge base software designed from the ground up to help you scale your customer support, and, collaborate better with your team.",
    "category": "Customer Support",
    "title": "Helpjuice Integration With Cold Email Sales Tool",
    "h1": "Integrate Helpjuice with SalesBlink",
    "metaDescription": "Integrate Helpjuice and other Customer Support Tools with SalesBlink. Two Way Helpjuice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelpSpace",
    "slug": "helpspace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/063cfde1a99e0f6a1cf35f07e6f9c69a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelpSpace is a well-rounded customer service tool including a team inbox, intuitive interface, self-service sites, and multiple inbound channels to get a competitive edge in your market.",
    "category": "Customer Support",
    "title": "HelpSpace Integration With Cold Email Sales Tool",
    "h1": "Integrate HelpSpace with SalesBlink",
    "metaDescription": "Integrate HelpSpace and other Customer Support Tools with SalesBlink. Two Way HelpSpace Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Funnel-Tunnel",
    "slug": "funnel-tunnel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/335822bd0d369d49f7eba7a66f5a38b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funnel-Tunnel is all-in-one software for small and medium-sized companies to successfully digitize. From design to marketing to sales, Funnel-Tunnel is a complete solution.",
    "category": "Website Builders",
    "title": "Funnel-Tunnel Integration With Cold Email Sales Tool",
    "h1": "Integrate Funnel-Tunnel with SalesBlink",
    "metaDescription": "Integrate Funnel-Tunnel and other Website Builders Tools with SalesBlink. Two Way Funnel-Tunnel Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Funnelytics",
    "slug": "funnelytics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a465e77bfe4b2e8c0648174d08b6715d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funnelytics is a visual customer journey analytics tool that lets you map your journey on a whiteboard and overlay data to see how it's actually performing.",
    "category": "Analytics",
    "title": "Funnelytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Funnelytics with SalesBlink",
    "metaDescription": "Integrate Funnelytics and other Analytics Tools with SalesBlink. Two Way Funnelytics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nudge Coach",
    "slug": "nudge-coach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eba04fa2587944fad6b20fe96c13095a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nudge Coach is a customizable online coaching platform that helps you build relationships and keep clients on track for better health.",
    "category": "Fitness",
    "title": "Nudge Coach Integration With Cold Email Sales Tool",
    "h1": "Integrate Nudge Coach with SalesBlink",
    "metaDescription": "Integrate Nudge Coach and other Fitness Tools with SalesBlink. Two Way Nudge Coach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bird Eats Bug",
    "slug": "bird-eats-bug",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d728dea925a8c596820d3363eb2805bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bird Eats Bug makes creating and collaborating on bug reports fast and easy.",
    "category": "Productivity",
    "title": "Bird Eats Bug Integration With Cold Email Sales Tool",
    "h1": "Integrate Bird Eats Bug with SalesBlink",
    "metaDescription": "Integrate Bird Eats Bug and other Productivity Tools with SalesBlink. Two Way Bird Eats Bug Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kissflow Digital Workplace",
    "slug": "kissflow-digital-workplace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03b0c938d2d98fe6f8424302c0fe2677_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kissflow Digital Workplace is a powerful work management solution that helps organizations optimize, manage, and track every type of work.",
    "category": "App Builder",
    "title": "Kissflow Digital Workplace Integration With Cold Email Sales Tool",
    "h1": "Integrate Kissflow Digital Workplace with SalesBlink",
    "metaDescription": "Integrate Kissflow Digital Workplace and other App Builder Tools with SalesBlink. Two Way Kissflow Digital Workplace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ybug",
    "slug": "ybug",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/334ead477b28b56b2d1c5f4b016cfed3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ybug is a visual feedback and bug tracking tool for your website. Collect detailed feedback with screenshots from your testers/clients.",
    "category": "Developer Tools",
    "title": "Ybug Integration With Cold Email Sales Tool",
    "h1": "Integrate Ybug with SalesBlink",
    "metaDescription": "Integrate Ybug and other Developer Tools Tools with SalesBlink. Two Way Ybug Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rasayel",
    "slug": "rasayel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e1e10db63f1eb8631cfbe1e098bdfdf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A simple, fast inbox that helps teams connect with people through any social messaging channel",
    "category": "Communication",
    "title": "Rasayel Integration With Cold Email Sales Tool",
    "h1": "Integrate Rasayel with SalesBlink",
    "metaDescription": "Integrate Rasayel and other Communication Tools with SalesBlink. Two Way Rasayel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kisi",
    "slug": "kisi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0855f52216d6fce306f64022bdff1954.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kisi is a cloud based physical access control system.",
    "category": "IT Operations",
    "title": "Kisi Integration With Cold Email Sales Tool",
    "h1": "Integrate Kisi with SalesBlink",
    "metaDescription": "Integrate Kisi and other IT Operations Tools with SalesBlink. Two Way Kisi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PostcardMania",
    "slug": "postcardmania",
    "logo": "https://zapier-images.imgix.net/storage/services/8d7b064b8f88ddf05caa2ffee0d3dd87.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send Direct Mail Automatically",
    "category": "Customer Appreciation",
    "title": "PostcardMania Integration With Cold Email Sales Tool",
    "h1": "Integrate PostcardMania with SalesBlink",
    "metaDescription": "Integrate PostcardMania and other Customer Appreciation Tools with SalesBlink. Two Way PostcardMania Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hub Planner",
    "slug": "hub-planner",
    "logo": "https://zapier-images.imgix.net/storage/developer/d1f7740da27bacdaff91248c5b001d28.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hub Planner is a resource planning and scheduling service with timesheets and dynamic reporting.",
    "category": "Project Management",
    "title": "Hub Planner Integration With Cold Email Sales Tool",
    "h1": "Integrate Hub Planner with SalesBlink",
    "metaDescription": "Integrate Hub Planner and other Project Management Tools with SalesBlink. Two Way Hub Planner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textellent",
    "slug": "textellent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcd148de10b38183888e6f6dbc6a6240.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Textellent engages and keeps customers through text messaging for marketing, sales as well as customer service.",
    "category": "Marketing",
    "title": "Textellent Integration With Cold Email Sales Tool",
    "h1": "Integrate Textellent with SalesBlink",
    "metaDescription": "Integrate Textellent and other Marketing Tools with SalesBlink. Two Way Textellent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Process Plan",
    "slug": "processplan",
    "logo": "https://zapier-images.imgix.net/storage/developer/e49ccc782e01b4310ba8c2031417c0ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProcessPlan is a process, procedure, project, and workflow tool.  Visually diagram your processes and then let ProcessPlan automate and manage your daily operations so you don't have to worry about making sure things are done correctly.  Make your business run like a well-oiled machine while you sit back and relax!",
    "category": "Product Management",
    "title": "Process Plan Integration With Cold Email Sales Tool",
    "h1": "Integrate Process Plan with SalesBlink",
    "metaDescription": "Integrate Process Plan and other Product Management Tools with SalesBlink. Two Way Process Plan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Portal.io",
    "slug": "portalio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f629fd3dd3664f9eb61da0707f1a6a17.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Portal is a service for professional installers. It aggregates industry product data and pricing, allowing pros to easily build proposals, collect customer payments and send purchase orders to suppliers.",
    "category": "Proposal & Invoice Management",
    "title": "Portal.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Portal.io with SalesBlink",
    "metaDescription": "Integrate Portal.io and other Proposal & Invoice Management Tools with SalesBlink. Two Way Portal.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TxtSync",
    "slug": "txtsync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b611dba3d62e724bde92b28897135f4.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TxtSync is an SMS platform that allows applications to easily, and reliably send and receive low cost SMS globally.",
    "category": "Phone & SMS",
    "title": "TxtSync Integration With Cold Email Sales Tool",
    "h1": "Integrate TxtSync with SalesBlink",
    "metaDescription": "Integrate TxtSync and other Phone & SMS Tools with SalesBlink. Two Way TxtSync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Corpay One",
    "slug": "corpay-one",
    "logo": "https://zapier-images.imgix.net/storage/services/45ec2f27aa99827a6a216785c1158d00.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Corpay One is the leading bill pay solution for small-and-medium-sized businesses. Automate your accounts payable tasks like payments, approvals, document scanning and reconciliation with one easy to use solution.",
    "category": "Accounting",
    "title": "Corpay One Integration With Cold Email Sales Tool",
    "h1": "Integrate Corpay One with SalesBlink",
    "metaDescription": "Integrate Corpay One and other Accounting Tools with SalesBlink. Two Way Corpay One Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pointagram",
    "slug": "pointagram",
    "logo": "https://zapier-images.imgix.net/storage/developer/fffe1ec38a9026eb2d2a587a75ccaf6e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pointagram is a free gamification tool that helps motivate teams and individuals in any organization or private group of people.",
    "category": "Dashboards",
    "title": "Pointagram Integration With Cold Email Sales Tool",
    "h1": "Integrate Pointagram with SalesBlink",
    "metaDescription": "Integrate Pointagram and other Dashboards Tools with SalesBlink. Two Way Pointagram Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SysAid",
    "slug": "sysaid",
    "logo": "https://zapier-images.imgix.net/storage/services/ad7e5a5e2f0fdf0791382ef9668c5272.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SysAid is a service automation company delivering software for organizations that want to get more done.",
    "category": "Customer Support",
    "title": "SysAid Integration With Cold Email Sales Tool",
    "h1": "Integrate SysAid with SalesBlink",
    "metaDescription": "Integrate SysAid and other Customer Support Tools with SalesBlink. Two Way SysAid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ExpertTexting",
    "slug": "experttexting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11c8402f733f33252afbfbcf522fc53e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ExpertTexting is a SMS platform to send and receive SMS to more than 200 countries globally.",
    "category": "Phone & SMS",
    "title": "ExpertTexting Integration With Cold Email Sales Tool",
    "h1": "Integrate ExpertTexting with SalesBlink",
    "metaDescription": "Integrate ExpertTexting and other Phone & SMS Tools with SalesBlink. Two Way ExpertTexting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Allma",
    "slug": "allma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4657cd4dffbf0fe06e226a74a9a6ac60.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Allma triages notifications ⁠from ⁠monitoring, alerting, ticketing, and customer systems ⁠⁠and allows you to take immediate action.",
    "category": "Productivity",
    "title": "Allma Integration With Cold Email Sales Tool",
    "h1": "Integrate Allma with SalesBlink",
    "metaDescription": "Integrate Allma and other Productivity Tools with SalesBlink. Two Way Allma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "net2phone",
    "slug": "net2phone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c084a6dc3070dece3ded3d97e8b563e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "net2phone is a leading provider of cloud-based business phone systems and unified communications tools.",
    "category": "Phone & SMS",
    "title": "net2phone Integration With Cold Email Sales Tool",
    "h1": "Integrate net2phone with SalesBlink",
    "metaDescription": "Integrate net2phone and other Phone & SMS Tools with SalesBlink. Two Way net2phone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Byteplant Phone Validator",
    "slug": "byteplant-phone-validator-ca2124",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a28952f3991c54a4b2b42d8e56364593.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Byteplant Phone-Validator allows you to validate phone numbers (mobile & landline) from 240 countries world-wide in real-time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Byteplant Phone Validator Integration With Cold Email Sales Tool",
    "h1": "Integrate Byteplant Phone Validator with SalesBlink",
    "metaDescription": "Integrate Byteplant Phone Validator and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Byteplant Phone Validator Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Easy Projects",
    "slug": "easy-projects",
    "logo": "https://zapier-images.imgix.net/storage/developer/d03bdc5ec1ec825c473d77214343d494.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easy Projects is a collaboration and project management software that helps teams get rid of spreadsheets and work together.",
    "category": "Project Management",
    "title": "Easy Projects Integration With Cold Email Sales Tool",
    "h1": "Integrate Easy Projects with SalesBlink",
    "metaDescription": "Integrate Easy Projects and other Project Management Tools with SalesBlink. Two Way Easy Projects Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zebraCRM",
    "slug": "zebracrm",
    "logo": "https://zapier-images.imgix.net/storage/developer/b17c8a0b49f37e5a43110f1bf33d9621_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZebraCRM is a CRM system.",
    "category": "CRM (Customer Relationship Management)",
    "title": "zebraCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate zebraCRM with SalesBlink",
    "metaDescription": "Integrate zebraCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way zebraCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimplyHired",
    "slug": "simplyhired",
    "logo": "https://zapier-images.imgix.net/storage/services/d69c3832cf729ef575d8fe3457e62393.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimplyHired is a hiring service that find, matches, and delivers the best jobseekers on the market.",
    "category": "HR Talent & Recruitment",
    "title": "SimplyHired Integration With Cold Email Sales Tool",
    "h1": "Integrate SimplyHired with SalesBlink",
    "metaDescription": "Integrate SimplyHired and other HR Talent & Recruitment Tools with SalesBlink. Two Way SimplyHired Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HourStack",
    "slug": "hourstack",
    "logo": "https://zapier-images.imgix.net/storage/services/d6576afcdcbc538b2b7429a11351b138.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simplified, visual time tracking and scheduling for teams of all sizes.",
    "category": "Time Tracking Software",
    "title": "HourStack Integration With Cold Email Sales Tool",
    "h1": "Integrate HourStack with SalesBlink",
    "metaDescription": "Integrate HourStack and other Time Tracking Software Tools with SalesBlink. Two Way HourStack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shipmondo",
    "slug": "shipmondo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a8de66eadd8b612b3811f78b094ee46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shipmondo is a freight booking platform that makes it possible for businesses to book freight and manage shipments across carriers. The integration will import order data from your webshop  to your Shipmondo account",
    "category": "eCommerce",
    "title": "Shipmondo Integration With Cold Email Sales Tool",
    "h1": "Integrate Shipmondo with SalesBlink",
    "metaDescription": "Integrate Shipmondo and other eCommerce Tools with SalesBlink. Two Way Shipmondo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HireTrack NX",
    "slug": "hiretrack-nx",
    "logo": "https://zapier-images.imgix.net/storage/developer/c6755315579b725b2d382406b6be4f23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HireTrack NX is a Windows application that organizes, schedules and tracks financials for live events equipment rental and production businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "HireTrack NX Integration With Cold Email Sales Tool",
    "h1": "Integrate HireTrack NX with SalesBlink",
    "metaDescription": "Integrate HireTrack NX and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way HireTrack NX Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Fusioo",
    "slug": "fusioo",
    "logo": "https://zapier-images.imgix.net/storage/developer/0d18c7727a7c304820c13289895d0e62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fusioo helps growing businesses get organized. Create your custom online database and manage everything in one place.",
    "category": "Databases",
    "title": "Fusioo Integration With Cold Email Sales Tool",
    "h1": "Integrate Fusioo with SalesBlink",
    "metaDescription": "Integrate Fusioo and other Databases Tools with SalesBlink. Two Way Fusioo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "WOZTELL",
    "slug": "woztell",
    "logo": "https://zapier-images.imgix.net/storage/services/734f544d4748d6a08d14fb2c5e474c81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WOZTELL is a conversational platform to manage and automate customers’ communications on WhatsApp Cloud API, Facebook, Instagram and Webchat",
    "category": "Communication",
    "title": "WOZTELL Integration With Cold Email Sales Tool",
    "h1": "Integrate WOZTELL with SalesBlink",
    "metaDescription": "Integrate WOZTELL and other Communication Tools with SalesBlink. Two Way WOZTELL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpContent",
    "slug": "upcontent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7afbd4d0c0abb2a6c18666ca5534066a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpContent is a content curation solution to help you discover, discuss, and distribute articles that inform, engage, and stimulate action amongst your audience.",
    "category": "Marketing Automation",
    "title": "UpContent Integration With Cold Email Sales Tool",
    "h1": "Integrate UpContent with SalesBlink",
    "metaDescription": "Integrate UpContent and other Marketing Automation Tools with SalesBlink. Two Way UpContent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boomerangme",
    "slug": "boomerangme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa429e4a09a5c56707967c36dc1955dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boomerangme is a Loyalty SaaS, upsell tool for agencies. Get additional recurring income from existing customers offering Loyalty as a Service. Use Reseller feature including white label, pricing set up, payment gateway connections.",
    "category": "Marketing Automation",
    "title": "Boomerangme Integration With Cold Email Sales Tool",
    "h1": "Integrate Boomerangme with SalesBlink",
    "metaDescription": "Integrate Boomerangme and other Marketing Automation Tools with SalesBlink. Two Way Boomerangme Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Powrbot",
    "slug": "powrbot",
    "logo": "https://zapier-images.imgix.net/storage/developer/1c3426bbd12165bb3cfd48ee506c01bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powrbot automates searching the web for company data.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Powrbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Powrbot with SalesBlink",
    "metaDescription": "Integrate Powrbot and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Powrbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Usherpa",
    "slug": "usherpa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7116275c23a35e03742fe54a4f1f225f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Usherpa is the easiest-to-use marketing crm for sales professionals that saves time, maximizes referrals and automates marketing.",
    "category": "Contact Management",
    "title": "Usherpa Integration With Cold Email Sales Tool",
    "h1": "Integrate Usherpa with SalesBlink",
    "metaDescription": "Integrate Usherpa and other Contact Management Tools with SalesBlink. Two Way Usherpa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrowthHero",
    "slug": "growthhero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5bd1b37eb4c95450dce25c21bd85d19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowthHero is an affiliate & refer-a-friend software, available via Shopify",
    "category": "Marketing",
    "title": "GrowthHero Integration With Cold Email Sales Tool",
    "h1": "Integrate GrowthHero with SalesBlink",
    "metaDescription": "Integrate GrowthHero and other Marketing Tools with SalesBlink. Two Way GrowthHero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wavve",
    "slug": "wavve",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b00220414749a520eb983b8a3adcb129.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn your podcast or musical audio into engaging animated videos to share on social media!",
    "category": "Social Media Marketing",
    "title": "Wavve Integration With Cold Email Sales Tool",
    "h1": "Integrate Wavve with SalesBlink",
    "metaDescription": "Integrate Wavve and other Social Media Marketing Tools with SalesBlink. Two Way Wavve Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PRINTgenie",
    "slug": "printgenie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/021ee2f0f9ddc0b2613671bffbd476a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PRINTgenie is an automated system for running Multi-Touch drip campaigns. You can Select or Build Your Own Direct Mail, Text & eMail Sequences. Simply Add a Contact, Set it and Forget it.",
    "category": "Marketing Automation",
    "title": "PRINTgenie Integration With Cold Email Sales Tool",
    "h1": "Integrate PRINTgenie with SalesBlink",
    "metaDescription": "Integrate PRINTgenie and other Marketing Automation Tools with SalesBlink. Two Way PRINTgenie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZenDirect",
    "slug": "zendirect",
    "logo": "https://zapier-images.imgix.net/storage/developer/ddb6e61cd2c21ef0ea6dd38b184cd7b5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build better relationships with your clients, employees and prospects. Our service lets you easily personalize, print and send \"handwritten\" cards, physical mailers and gifts.",
    "category": "Printing",
    "title": "ZenDirect Integration With Cold Email Sales Tool",
    "h1": "Integrate ZenDirect with SalesBlink",
    "metaDescription": "Integrate ZenDirect and other Printing Tools with SalesBlink. Two Way ZenDirect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AVANSER",
    "slug": "avanser",
    "logo": "https://zapier-images.imgix.net/storage/developer/1e35591f7941836691f86afe3a989a50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AVANSER offers call tracking and analytics tools that give greater transparency into any business’ sales and marketing activities.",
    "category": "Call Tracking",
    "title": "AVANSER Integration With Cold Email Sales Tool",
    "h1": "Integrate AVANSER with SalesBlink",
    "metaDescription": "Integrate AVANSER and other Call Tracking Tools with SalesBlink. Two Way AVANSER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cascade Strategy Classic",
    "slug": "cascade-strategy-classic",
    "logo": "https://zapier-images.imgix.net/storage/services/1ca8b2c516bb560831db49d0d7eab04b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cascade is a strategy execution platform which helps organizations create, communicate and execute their strategic plans.  For Cascade Strategy Classic users.",
    "category": "Project Management",
    "title": "Cascade Strategy Classic Integration With Cold Email Sales Tool",
    "h1": "Integrate Cascade Strategy Classic with SalesBlink",
    "metaDescription": "Integrate Cascade Strategy Classic and other Project Management Tools with SalesBlink. Two Way Cascade Strategy Classic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Daftra",
    "slug": "daftra",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8e1ef4872f65ffd453f47b345ffec67a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Daftra is a cloud ERP and accounting software designed exclusively for small and medium companies and their teams. Send invoices, track expenses, manage your accounting, and collaborate on projects.",
    "category": "Accounting",
    "title": "Daftra Integration With Cold Email Sales Tool",
    "h1": "Integrate Daftra with SalesBlink",
    "metaDescription": "Integrate Daftra and other Accounting Tools with SalesBlink. Two Way Daftra Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crossmint",
    "slug": "crossmint",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4293ad6148a56785f2df59b0d0eddcc1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crossmint is the infrastructure layer to make NFTs mainstream- a suite of APIs and developer tools, including minting APIs, custodial wallets as a service, and cross-chain payments.",
    "category": "Developer Tools",
    "title": "Crossmint Integration With Cold Email Sales Tool",
    "h1": "Integrate Crossmint with SalesBlink",
    "metaDescription": "Integrate Crossmint and other Developer Tools Tools with SalesBlink. Two Way Crossmint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CINNOX",
    "slug": "cinnox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b9f0b8ace7ad8135129964b9b831aec9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CINNOX is an innovative customer engagement and analytics platform that humanises connections for better business.",
    "category": "Communication",
    "title": "CINNOX Integration With Cold Email Sales Tool",
    "h1": "Integrate CINNOX with SalesBlink",
    "metaDescription": "Integrate CINNOX and other Communication Tools with SalesBlink. Two Way CINNOX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leader CRM",
    "slug": "leader-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1294e369691b91430e16fad739fab9e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leader helps businesses to capture and manage leads & customers on the go.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Leader CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Leader CRM with SalesBlink",
    "metaDescription": "Integrate Leader CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Leader CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Breeze",
    "slug": "breeze",
    "logo": "https://zapier-images.imgix.net/storage/developer/e01a213ee902f1869fa7a255c6712e4e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Breeze is a simple project management tool that enables teams to plan, track and organize their work.",
    "category": "Project Management",
    "title": "Breeze Integration With Cold Email Sales Tool",
    "h1": "Integrate Breeze with SalesBlink",
    "metaDescription": "Integrate Breeze and other Project Management Tools with SalesBlink. Two Way Breeze Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Workast",
    "slug": "workast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b30a74ba2c5264f8bf879b83f1478d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workast is a productivity tool that helps teams capture tasks, manage projects and collaborate to get more work done.",
    "category": "Project Management",
    "title": "Workast Integration With Cold Email Sales Tool",
    "h1": "Integrate Workast with SalesBlink",
    "metaDescription": "Integrate Workast and other Project Management Tools with SalesBlink. Two Way Workast Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cardly",
    "slug": "cardly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df6962016024da1373b16259f8b24c8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cardly helps businesses create great engagement with customers by getting out of inboxes and into mailboxes.",
    "category": "Marketing Automation",
    "title": "Cardly Integration With Cold Email Sales Tool",
    "h1": "Integrate Cardly with SalesBlink",
    "metaDescription": "Integrate Cardly and other Marketing Automation Tools with SalesBlink. Two Way Cardly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emelia",
    "slug": "emelia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/897d9c70a2557d7000a1deebd81587d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emilia is the only platform dedicated to Growth Marketing teams and B2B automation companies that has 3 e-mail addresses for cold-emailing.",
    "category": "Drip Emails",
    "title": "Emelia Integration With Cold Email Sales Tool",
    "h1": "Integrate Emelia with SalesBlink",
    "metaDescription": "Integrate Emelia and other Drip Emails Tools with SalesBlink. Two Way Emelia Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Visma Severa",
    "slug": "visma-severa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b4962794a77c055f39fdd481574804b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Visma Severa is a modern and visual professional services automation (PSA) tool that brings together CRM, work hour entries, projects and invoicing.",
    "category": "Project Management",
    "title": "Visma Severa Integration With Cold Email Sales Tool",
    "h1": "Integrate Visma Severa with SalesBlink",
    "metaDescription": "Integrate Visma Severa and other Project Management Tools with SalesBlink. Two Way Visma Severa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Texting Base",
    "slug": "texting-base",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1dd62ecbe0d4c87105ac67d5a75abb8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Texting Base is an automation and personalization texting platform for business.",
    "category": "Phone & SMS",
    "title": "Texting Base Integration With Cold Email Sales Tool",
    "h1": "Integrate Texting Base with SalesBlink",
    "metaDescription": "Integrate Texting Base and other Phone & SMS Tools with SalesBlink. Two Way Texting Base Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SimpleKPI",
    "slug": "simplekpi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a526c6c7d7e54034c429a4edfa4fe9c7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleKPI is KPI Software for companies of all sizes who track Key Performance Indicators and metrics - complete with easy to use tools for visualizing data on dashboards, building engaging reports and analyzing performance.",
    "category": "Dashboards",
    "title": "SimpleKPI Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleKPI with SalesBlink",
    "metaDescription": "Integrate SimpleKPI and other Dashboards Tools with SalesBlink. Two Way SimpleKPI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salespanel",
    "slug": "salespanel",
    "logo": "https://zapier-images.imgix.net/storage/developer/0f1dfa0b3f508e440654d78eb485c8fd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salespanel brings all your leads in a single screen when they engage with your emails, marketing newsletters, website page or webforms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salespanel Integration With Cold Email Sales Tool",
    "h1": "Integrate Salespanel with SalesBlink",
    "metaDescription": "Integrate Salespanel and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salespanel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Channeltivity",
    "slug": "channeltivity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/906f103f99b25ffd44d941e35b2e864e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Channeltivity is a user-friendly Partner Relationship Management (PRM) solution that includes everything for a successful channel program.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Channeltivity Integration With Cold Email Sales Tool",
    "h1": "Integrate Channeltivity with SalesBlink",
    "metaDescription": "Integrate Channeltivity and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Channeltivity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CRM Connector",
    "slug": "crm-connector",
    "logo": "https://zapier-images.imgix.net/storage/developer/a106d89a0b4050559e02a3ddb93b3fa2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CRM Connector is a cloud based CRM and marketing automation platform offering robust automation and sales tools for small to medium sized businesses worldwide.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CRM Connector Integration With Cold Email Sales Tool",
    "h1": "Integrate CRM Connector with SalesBlink",
    "metaDescription": "Integrate CRM Connector and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CRM Connector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Signeasy",
    "slug": "signeasy",
    "logo": "https://zapier-images.imgix.net/storage/services/fac994cae49be8f8b90f96da2bf76892.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignEasy is the easiest and fastest way to sign and send documents for signature from your phone, tablet and computer. Over 100,000 customers from 180 countries trust SignEasy to reduce paperwork turnaround times, close deals faster, cut costs, and improve the customer experience by eliminating the cumbersome print-sign-scan-fax process.",
    "category": "Signatures",
    "title": "Signeasy Integration With Cold Email Sales Tool",
    "h1": "Integrate Signeasy with SalesBlink",
    "metaDescription": "Integrate Signeasy and other Signatures Tools with SalesBlink. Two Way Signeasy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Madgex Job Board",
    "slug": "madgex-job-board",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a0661fc766cd8546430cccd3934dca3a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The most sophisticated job board software on the market, powering the world’s biggest brands & associations",
    "category": "HR Talent & Recruitment",
    "title": "Madgex Job Board Integration With Cold Email Sales Tool",
    "h1": "Integrate Madgex Job Board with SalesBlink",
    "metaDescription": "Integrate Madgex Job Board and other HR Talent & Recruitment Tools with SalesBlink. Two Way Madgex Job Board Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PheedLoop",
    "slug": "pheedloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4d981d20a24452785cfbe5cccc5a1cb4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Live, Virtual, Hybrid Event Management Software",
    "category": "Event Management",
    "title": "PheedLoop Integration With Cold Email Sales Tool",
    "h1": "Integrate PheedLoop with SalesBlink",
    "metaDescription": "Integrate PheedLoop and other Event Management Tools with SalesBlink. Two Way PheedLoop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Calculoid",
    "slug": "calculoid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03d73493d59de22925cf393beb58ca21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Calculoid is a platform for creating interactive web calculators which can be easily publish into your website. Features such as online payments or emails submissions will enhance your e-commerce and lead generation capabilities. You do not have to be a web developer to create your own online calculator in just a few clicks.",
    "category": "eCommerce",
    "title": "Calculoid Integration With Cold Email Sales Tool",
    "h1": "Integrate Calculoid with SalesBlink",
    "metaDescription": "Integrate Calculoid and other eCommerce Tools with SalesBlink. Two Way Calculoid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon Polly",
    "slug": "amazon-polly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96a87b8873e217ef680553f44233419d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Polly is a service that turns text into lifelike speech, that uses advanced deep learning technologies to synthesize speech that sounds like a human voice. Supports voices for a variety of languages and accents.",
    "category": "Amazon",
    "title": "Amazon Polly Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Polly with SalesBlink",
    "metaDescription": "Integrate Amazon Polly and other Amazon Tools with SalesBlink. Two Way Amazon Polly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Duply",
    "slug": "duply",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b3354369ed07f65b6ee4872d60983e1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Duply is a dynamic image creation tool for marketing & content.",
    "category": "Images & Design",
    "title": "Duply Integration With Cold Email Sales Tool",
    "h1": "Integrate Duply with SalesBlink",
    "metaDescription": "Integrate Duply and other Images & Design Tools with SalesBlink. Two Way Duply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nexweave",
    "slug": "nexweave",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99a32c20f086fde271dbe6862e5e6434_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engage your audiences with interactive videos that can be personalized for millions!\npersonalized videos, uniquely crafted for every recipient.\nMessages customised based on the objective of communication",
    "category": "Marketing",
    "title": "Nexweave Integration With Cold Email Sales Tool",
    "h1": "Integrate Nexweave with SalesBlink",
    "metaDescription": "Integrate Nexweave and other Marketing Tools with SalesBlink. Two Way Nexweave Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Stunning",
    "slug": "stunning",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2dbe08e72f033cea2145bff387a69651.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stunning helps our customers to recover more failed payments on Stripe.",
    "category": "Payment Processing",
    "title": "Stunning Integration With Cold Email Sales Tool",
    "h1": "Integrate Stunning with SalesBlink",
    "metaDescription": "Integrate Stunning and other Payment Processing Tools with SalesBlink. Two Way Stunning Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zonka Feedback",
    "slug": "zonka-feedback",
    "logo": "https://zapier-images.imgix.net/storage/developer/3f36c6d0a58fab4814be52684ce236ae_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zonka Feedback is a multi-channel customer feedback management and survey app on iPad & Android Tablets, Web, Emails & SMS.",
    "category": "Forms & Surveys",
    "title": "Zonka Feedback Integration With Cold Email Sales Tool",
    "h1": "Integrate Zonka Feedback with SalesBlink",
    "metaDescription": "Integrate Zonka Feedback and other Forms & Surveys Tools with SalesBlink. Two Way Zonka Feedback Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Outbound. by Why Bravo",
    "slug": "outbound-by-why-bravo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c697a4b15a1ecbde7c7f3d93f5c05924_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outbound. is a sales enablement platform, designed to use gamification to promote and rapidly grow your teams outbound activities and reward your team how they want to be rewarded.",
    "category": "Business Intelligence",
    "title": "Outbound. by Why Bravo Integration With Cold Email Sales Tool",
    "h1": "Integrate Outbound. by Why Bravo with SalesBlink",
    "metaDescription": "Integrate Outbound. by Why Bravo and other Business Intelligence Tools with SalesBlink. Two Way Outbound. by Why Bravo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoIP.ms",
    "slug": "voipms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7b5c8c40a52bf7591c9404bb636cbbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoIP.ms is a cloud-based, self-serve and contract-free communication platform. It enables business and home users with voice, SMS, and fax.",
    "category": "Phone & SMS",
    "title": "VoIP.ms Integration With Cold Email Sales Tool",
    "h1": "Integrate VoIP.ms with SalesBlink",
    "metaDescription": "Integrate VoIP.ms and other Phone & SMS Tools with SalesBlink. Two Way VoIP.ms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nextiva",
    "slug": "nextiva",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7629022040f76c4cd6aeb5a4647d0901.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nextiva empowers businesses to manage their communications, productivity, and customer relationships on a truly unified platform.",
    "category": "Phone & SMS",
    "title": "Nextiva Integration With Cold Email Sales Tool",
    "h1": "Integrate Nextiva with SalesBlink",
    "metaDescription": "Integrate Nextiva and other Phone & SMS Tools with SalesBlink. Two Way Nextiva Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SupportBee",
    "slug": "supportbee",
    "logo": "https://zapier-images.imgix.net/storage/developer/f7455a4567e77cc1614d54e3d5318ccc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SupportBee is the easiest way to manage customer support emails. It helps your team collaborate & deliver friendly customer service.",
    "category": "Customer Support",
    "title": "SupportBee Integration With Cold Email Sales Tool",
    "h1": "Integrate SupportBee with SalesBlink",
    "metaDescription": "Integrate SupportBee and other Customer Support Tools with SalesBlink. Two Way SupportBee Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Kylas CRM",
    "slug": "kylas-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b414415d01e0719ae956979a35a4866_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kylas is a CRM that helps you to manage your sales process.",
    "category": "Sales & CRM",
    "title": "Kylas CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Kylas CRM with SalesBlink",
    "metaDescription": "Integrate Kylas CRM and other Sales & CRM Tools with SalesBlink. Two Way Kylas CRM Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Contele Gestor de Equipes",
    "slug": "contele-gestor-de-equipes",
    "logo": "https://zapier-images.imgix.net/storage/services/6798f8d7a79ed282539c4a18d99358cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contele Gestor de Equipes is an app for any type of field team manager for companies with Check-in/out, Routing, Checklists, Forms, Refund register, Tracking, Digital Service Order, Plan and Scheduler visits and reports.",
    "category": "Team Collaboration",
    "title": "Contele Gestor de Equipes Integration With Cold Email Sales Tool",
    "h1": "Integrate Contele Gestor de Equipes with SalesBlink",
    "metaDescription": "Integrate Contele Gestor de Equipes and other Team Collaboration Tools with SalesBlink. Two Way Contele Gestor de Equipes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MDirector",
    "slug": "mdirector",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/67d26419ad9fb2a27a61bb5a19ecc53e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MDirector is an Email Marketing and text messaging platform that incorporates a Landing Page builder and Real Time Bidding (RTB) in the same tool.",
    "category": "Marketing",
    "title": "MDirector Integration With Cold Email Sales Tool",
    "h1": "Integrate MDirector with SalesBlink",
    "metaDescription": "Integrate MDirector and other Marketing Tools with SalesBlink. Two Way MDirector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CleverTap",
    "slug": "clevertap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07b57fc5945716ec3f437017974536f9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CleverTap is a retention cloud that empowers digital brands to increase customer retention.",
    "category": "Marketing Automation",
    "title": "CleverTap Integration With Cold Email Sales Tool",
    "h1": "Integrate CleverTap with SalesBlink",
    "metaDescription": "Integrate CleverTap and other Marketing Automation Tools with SalesBlink. Two Way CleverTap Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Range",
    "slug": "range",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6148290b10d4d371bb59ba0080632dd4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Range is an app that takes the work out of daily team check-ins by running virtual stand-ups and connecting all your tools so you can stay in sync, and feel more like a team.",
    "category": "Team Collaboration",
    "title": "Range Integration With Cold Email Sales Tool",
    "h1": "Integrate Range with SalesBlink",
    "metaDescription": "Integrate Range and other Team Collaboration Tools with SalesBlink. Two Way Range Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShipEngine",
    "slug": "shipengine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50a181b43072a4f13643a4d52077acc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShipEngine APIs empower ecommerce brands and platforms to shop rates, validate global addresses, print labels, track shipments, plus more.",
    "category": "eCommerce",
    "title": "ShipEngine Integration With Cold Email Sales Tool",
    "h1": "Integrate ShipEngine with SalesBlink",
    "metaDescription": "Integrate ShipEngine and other eCommerce Tools with SalesBlink. Two Way ShipEngine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LockedOn",
    "slug": "lockedon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcd7bfa05122b0c0171be641907f25f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LockedOn is a feature-rich, automated real estate CRM and marketing platform built to help real estate agents list and sell.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LockedOn Integration With Cold Email Sales Tool",
    "h1": "Integrate LockedOn with SalesBlink",
    "metaDescription": "Integrate LockedOn and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LockedOn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rows",
    "slug": "rows",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5df65da2bff425a32d568653fe44c957.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "More powerful than a spreadsheet and more flexible than a reporting tool, Rows is where modern teams build, use and share beautiful spreadsheets.",
    "category": "Spreadsheets",
    "title": "Rows Integration With Cold Email Sales Tool",
    "h1": "Integrate Rows with SalesBlink",
    "metaDescription": "Integrate Rows and other Spreadsheets Tools with SalesBlink. Two Way Rows Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Meetgeek.ai",
    "slug": "meetgeekai",
    "logo": "https://zapier-images.imgix.net/storage/services/abe1431b96e6c2d19bf8473e8c599951.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meetgeek helps you record, transcribe, take notes, and share key highlights of your online calls. Automatic note-taking keeps you focused on the conversation while it writes down all the important details for you.",
    "category": "Video Conferencing",
    "title": "Meetgeek.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Meetgeek.ai with SalesBlink",
    "metaDescription": "Integrate Meetgeek.ai and other Video Conferencing Tools with SalesBlink. Two Way Meetgeek.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Seamlss",
    "slug": "seamlss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf3b37a204cf36f681400b6e6054d180_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seamlss allows you to onboard your clients with ease to collect and collate data. Make life seamless with Seamlss.",
    "category": "Accounting",
    "title": "Seamlss Integration With Cold Email Sales Tool",
    "h1": "Integrate Seamlss with SalesBlink",
    "metaDescription": "Integrate Seamlss and other Accounting Tools with SalesBlink. Two Way Seamlss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "reteach",
    "slug": "reteach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25c1c02f616811d122c860a427591d27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "reteach is a training platform within the SME Market for managing corporate learning, onboarding, and product training.",
    "category": "Online Courses",
    "title": "reteach Integration With Cold Email Sales Tool",
    "h1": "Integrate reteach with SalesBlink",
    "metaDescription": "Integrate reteach and other Online Courses Tools with SalesBlink. Two Way reteach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Collect",
    "slug": "collect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be9003794d887934e05f0a6fe41d46d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect helps team to put documents and data collection on autopilot.",
    "category": "Documents",
    "title": "Collect Integration With Cold Email Sales Tool",
    "h1": "Integrate Collect with SalesBlink",
    "metaDescription": "Integrate Collect and other Documents Tools with SalesBlink. Two Way Collect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSPortal",
    "slug": "smsportal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce0671a808594b23760df8a8d11c2494_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSPortal is a business communication platform that helps organizations tap into the power of Bulk SMS for maximum reach and genuine customer engagement.",
    "category": "Communication",
    "title": "SMSPortal Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSPortal with SalesBlink",
    "metaDescription": "Integrate SMSPortal and other Communication Tools with SalesBlink. Two Way SMSPortal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Windsor",
    "slug": "windsor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e9d10fe21e1e6c9393d20000c65086e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Windsor is an AI marketing platform for e-commerce brands to automate and scale personal videos to acquire, retain and grow customers.",
    "category": "Marketing Automation",
    "title": "Windsor Integration With Cold Email Sales Tool",
    "h1": "Integrate Windsor with SalesBlink",
    "metaDescription": "Integrate Windsor and other Marketing Automation Tools with SalesBlink. Two Way Windsor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jestor",
    "slug": "jestor",
    "logo": "https://zapier-images.imgix.net/storage/services/1a9a41426e5069ec40b16e9167eeb031.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jestor is an easy way to build internal tools for your company. It's a relational database powered by analytics, low-code and no-code automations.",
    "category": "Databases",
    "title": "Jestor Integration With Cold Email Sales Tool",
    "h1": "Integrate Jestor with SalesBlink",
    "metaDescription": "Integrate Jestor and other Databases Tools with SalesBlink. Two Way Jestor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Keeping",
    "slug": "keeping",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc979ad1b84eb4297f8201a562edaf04_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keeping is a customer support help desk that integrates with Gmail.",
    "category": "Customer Support",
    "title": "Keeping Integration With Cold Email Sales Tool",
    "h1": "Integrate Keeping with SalesBlink",
    "metaDescription": "Integrate Keeping and other Customer Support Tools with SalesBlink. Two Way Keeping Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elai",
    "slug": "elai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f940aa077a5327877554b0e3a202e016.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elai.io is a leading text-to-video platform that allows anyone to build customized AI videos with a presenter using only text. A few moments is all it takes to generate a video with 69 languages available for translation.",
    "category": "Video & Audio",
    "title": "Elai Integration With Cold Email Sales Tool",
    "h1": "Integrate Elai with SalesBlink",
    "metaDescription": "Integrate Elai and other Video & Audio Tools with SalesBlink. Two Way Elai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadFuze",
    "slug": "leadfuze",
    "logo": "https://zapier-images.imgix.net/storage/developer/487b1a5e8653b48ff1f26e27b0346ae6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadFuze is a lead generation app that helps build the most targeted and accurate list of leads imaginable.",
    "category": "Marketing Automation",
    "title": "LeadFuze Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadFuze with SalesBlink",
    "metaDescription": "Integrate LeadFuze and other Marketing Automation Tools with SalesBlink. Two Way LeadFuze Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Telebroad",
    "slug": "telebroad",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7fab378680bbab6d513b250de10ef8c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Telebroad provides business scalable & feature rich communication solutions for an office phone system, SMS and eEax – In addition to call analytics & online Switchboard for real-time phone management",
    "category": "Phone & SMS",
    "title": "Telebroad Integration With Cold Email Sales Tool",
    "h1": "Integrate Telebroad with SalesBlink",
    "metaDescription": "Integrate Telebroad and other Phone & SMS Tools with SalesBlink. Two Way Telebroad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CauseVox",
    "slug": "causevox",
    "logo": "https://zapier-images.imgix.net/storage/developer/77778c13590ff5677d53fdfbf3ebd114.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CauseVox is a digital fundraising platform for nonprofits and social good projects. Increase fundraising results with our donation forms, crowdfunding campaigns, peer to peer fundraising, event ticketing, and pledge donations.",
    "category": "Fundraising",
    "title": "CauseVox Integration With Cold Email Sales Tool",
    "h1": "Integrate CauseVox with SalesBlink",
    "metaDescription": "Integrate CauseVox and other Fundraising Tools with SalesBlink. Two Way CauseVox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "incident.io",
    "slug": "incidentio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b6e6f6a03bb435c62b204f40d50e1e65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "incident.io is an incident management platform, designed for your entire organisation.",
    "category": "Communication",
    "title": "incident.io Integration With Cold Email Sales Tool",
    "h1": "Integrate incident.io with SalesBlink",
    "metaDescription": "Integrate incident.io and other Communication Tools with SalesBlink. Two Way incident.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClickSend Postcards",
    "slug": "clicksend-postcards",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd5f4022fda4a5caed8ae27548b27566.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickSend allows you to send physical postcards to any country via the internet.",
    "category": "Communication",
    "title": "ClickSend Postcards Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickSend Postcards with SalesBlink",
    "metaDescription": "Integrate ClickSend Postcards and other Communication Tools with SalesBlink. Two Way ClickSend Postcards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMTP.com",
    "slug": "smtpcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a903d76c6584eface73a59310f978050.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMTP is a trusted and powerful transactional and email relay provider that allows you to send and track high volume emails effortlessly.",
    "category": "Transactional Email",
    "title": "SMTP.com Integration With Cold Email Sales Tool",
    "h1": "Integrate SMTP.com with SalesBlink",
    "metaDescription": "Integrate SMTP.com and other Transactional Email Tools with SalesBlink. Two Way SMTP.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superpeer",
    "slug": "superpeer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/421ed50d349f9def6d039796522d900f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Superpeer is an all-in-one video platform that gives you the tools you need to engage with your audience through video and connect in a more authentic way.",
    "category": "Scheduling & Booking",
    "title": "Superpeer Integration With Cold Email Sales Tool",
    "h1": "Integrate Superpeer with SalesBlink",
    "metaDescription": "Integrate Superpeer and other Scheduling & Booking Tools with SalesBlink. Two Way Superpeer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SlapFive",
    "slug": "slapfive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9dc4c7c7ed63ce4103d829107b41f874.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SlapFive is a Customer Marketing and Advocacy Software platform for mobilizing customers and their voice to drive strategic growth initiatives.",
    "category": "Marketing",
    "title": "SlapFive Integration With Cold Email Sales Tool",
    "h1": "Integrate SlapFive with SalesBlink",
    "metaDescription": "Integrate SlapFive and other Marketing Tools with SalesBlink. Two Way SlapFive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TaxJar",
    "slug": "taxjar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f049a808fcbacdea3d765769f0651aa9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TaxJar’s award-winning solution makes it easy to automate sales tax management, reporting and filing. We obsessively leverage technology and exceptional customer service to help you focus on what matters most — growing your business.",
    "category": "eCommerce",
    "title": "TaxJar Integration With Cold Email Sales Tool",
    "h1": "Integrate TaxJar with SalesBlink",
    "metaDescription": "Integrate TaxJar and other eCommerce Tools with SalesBlink. Two Way TaxJar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Timeneye",
    "slug": "timeneye",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d2dcd282af926ff9060c6275822d5089.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timeneye is a simple and intelligent time tracking tool for teams and freelancers.",
    "category": "Time Tracking Software",
    "title": "Timeneye Integration With Cold Email Sales Tool",
    "h1": "Integrate Timeneye with SalesBlink",
    "metaDescription": "Integrate Timeneye and other Time Tracking Software Tools with SalesBlink. Two Way Timeneye Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bold Commerce",
    "slug": "bold-commerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bef643cc155f21f7db0e663fccc71cb3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bold Commerce provides a selection of apps that help convert more customers, maximize average order value and increase customer lifetime value. Specifically the Bold Subscriptions app allows merchants to build a custom recurring revenue solution.",
    "category": "eCommerce",
    "title": "Bold Commerce Integration With Cold Email Sales Tool",
    "h1": "Integrate Bold Commerce with SalesBlink",
    "metaDescription": "Integrate Bold Commerce and other eCommerce Tools with SalesBlink. Two Way Bold Commerce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fuzey",
    "slug": "fuzey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/618dc7d75fe30c89cc8df0ab6a0bcee9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fuzey is an all-in-one communication & lead management platform that automates workflows, streamlines customer engagement, and boosts productivity.",
    "category": "Communication",
    "title": "Fuzey Integration With Cold Email Sales Tool",
    "h1": "Integrate Fuzey with SalesBlink",
    "metaDescription": "Integrate Fuzey and other Communication Tools with SalesBlink. Two Way Fuzey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SE Ranking",
    "slug": "se-ranking",
    "logo": "https://zapier-images.imgix.net/storage/developer/5da78b5027b6b57cce2fbc33d4067592_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SE Ranking is a marketing platform comprised of a set of tools designed to assist marketers and SEO experts in their work.",
    "category": "Marketing Automation",
    "title": "SE Ranking Integration With Cold Email Sales Tool",
    "h1": "Integrate SE Ranking with SalesBlink",
    "metaDescription": "Integrate SE Ranking and other Marketing Automation Tools with SalesBlink. Two Way SE Ranking Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ProLine",
    "slug": "proline",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c10d0c25991e43e11a370f13c0d77fe1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProLine is a messaging automation platform for contractors.",
    "category": "Sales & CRM",
    "title": "ProLine Integration With Cold Email Sales Tool",
    "h1": "Integrate ProLine with SalesBlink",
    "metaDescription": "Integrate ProLine and other Sales & CRM Tools with SalesBlink. Two Way ProLine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Froged",
    "slug": "froged",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b8e171d53b9d7bfc8293c53e79d3b78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Froged is a messaging platform that combines modern live chat, in-app messenger, knowledge base, user behavior tracking, and email automation.",
    "category": "Customer Support",
    "title": "Froged Integration With Cold Email Sales Tool",
    "h1": "Integrate Froged with SalesBlink",
    "metaDescription": "Integrate Froged and other Customer Support Tools with SalesBlink. Two Way Froged Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "straight2Voicemail",
    "slug": "straight2voicemail",
    "logo": "https://zapier-images.imgix.net/storage/developer/dd9688f3d9d1b971cd22347d15c70871.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Straight2Voicemail is a tool for anyone to place a voicemail on a recipients mobile/cellular phone without it ringing.",
    "category": "Phone & SMS",
    "title": "straight2Voicemail Integration With Cold Email Sales Tool",
    "h1": "Integrate straight2Voicemail with SalesBlink",
    "metaDescription": "Integrate straight2Voicemail and other Phone & SMS Tools with SalesBlink. Two Way straight2Voicemail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VROMO",
    "slug": "vromo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13f05fba848de349be0a852b16426401_8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VROMO is a freemium delivery management software providing delivery providers a dashboard to view and manage their orders, and a driver's app.",
    "category": "Product Management",
    "title": "VROMO Integration With Cold Email Sales Tool",
    "h1": "Integrate VROMO with SalesBlink",
    "metaDescription": "Integrate VROMO and other Product Management Tools with SalesBlink. Two Way VROMO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Baremetrics",
    "slug": "baremetrics",
    "logo": "https://zapier-images.imgix.net/storage/developer/78febe964f301a6ccb661aa919e49dcd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Baremetrics provides one-click, zero-setup metrics, forecasting and engagement tools for SaaS companies.",
    "category": "Dashboards",
    "title": "Baremetrics Integration With Cold Email Sales Tool",
    "h1": "Integrate Baremetrics with SalesBlink",
    "metaDescription": "Integrate Baremetrics and other Dashboards Tools with SalesBlink. Two Way Baremetrics Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Popup Maker",
    "slug": "popup-maker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1a7db26a6b06c244dcdc2b3d0ba672a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Popup Maker is a multifunctional marketing tool to boost your sales and grow your email list with variable features.",
    "category": "eCommerce",
    "title": "Popup Maker Integration With Cold Email Sales Tool",
    "h1": "Integrate Popup Maker with SalesBlink",
    "metaDescription": "Integrate Popup Maker and other eCommerce Tools with SalesBlink. Two Way Popup Maker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zapnito",
    "slug": "zapnito",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f5e79b6b3d0f89707ae6e9ee7464707b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zapnito is the community software platform built to showcase expertise. Build your own branded community and engage the people who really matter to your business.",
    "category": "Website Builders",
    "title": "Zapnito Integration With Cold Email Sales Tool",
    "h1": "Integrate Zapnito with SalesBlink",
    "metaDescription": "Integrate Zapnito and other Website Builders Tools with SalesBlink. Two Way Zapnito Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Funil de Vendas",
    "slug": "funil-de-vendas",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8bd88bbc3fdb2c7d4189ff0adaacd1f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funil de Vendas helps to manage the whole sales process, from the search for opportunities to the closing of the deals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Funil de Vendas Integration With Cold Email Sales Tool",
    "h1": "Integrate Funil de Vendas with SalesBlink",
    "metaDescription": "Integrate Funil de Vendas and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Funil de Vendas Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "T2M URL Shortener",
    "slug": "t2m-url-shortener",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65cb859edf4fa535fc346b9332651709.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "T2M URL Shortener is a link management platform mainly built for Individual Creators, Businesses, Universities & Survey Agencies.",
    "category": "URL Shortener",
    "title": "T2M URL Shortener Integration With Cold Email Sales Tool",
    "h1": "Integrate T2M URL Shortener with SalesBlink",
    "metaDescription": "Integrate T2M URL Shortener and other URL Shortener Tools with SalesBlink. Two Way T2M URL Shortener Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Accelevents",
    "slug": "accelevents",
    "logo": "https://zapier-images.imgix.net/storage/services/b55d04baf620210481e1499d8ca89653.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Your Affordable, All-in-One Event Platform.",
    "category": "Event Management",
    "title": "Accelevents Integration With Cold Email Sales Tool",
    "h1": "Integrate Accelevents with SalesBlink",
    "metaDescription": "Integrate Accelevents and other Event Management Tools with SalesBlink. Two Way Accelevents Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phone Validator Fake Number Check",
    "slug": "phonevalidator-fake-number-check",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7516b05c20de53d5a1224680f64bbb9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Phone Validator Fake Number Check quickly detects fake phone numbers like 555-555-5555 and 123-456-7890.  There is a 100% certainty that a number we have identified as fake cannot receive a call or text.",
    "category": "Phone & SMS",
    "title": "Phone Validator Fake Number Check Integration With Cold Email Sales Tool",
    "h1": "Integrate Phone Validator Fake Number Check with SalesBlink",
    "metaDescription": "Integrate Phone Validator Fake Number Check and other Phone & SMS Tools with SalesBlink. Two Way Phone Validator Fake Number Check Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AcademyOcean",
    "slug": "academyocean",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74b030ecd8e69bead52fdcf47001f30c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AcademyOcean is an LMS system that helps businesses automate training for employees, customers, and partners.",
    "category": "Online Courses",
    "title": "AcademyOcean Integration With Cold Email Sales Tool",
    "h1": "Integrate AcademyOcean with SalesBlink",
    "metaDescription": "Integrate AcademyOcean and other Online Courses Tools with SalesBlink. Two Way AcademyOcean Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Reckon One",
    "slug": "reckon-one",
    "logo": "https://zapier-images.imgix.net/storage/developer/5c5e4bf9bdf85a22600bddcae0b68985_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reckon One is an easy to use cloud accounting system built for every day small business owners who want a no fuss, simple and inexpensive solution. Easily add and remove modules such as payroll as your business needs them.",
    "category": "Accounting",
    "title": "Reckon One Integration With Cold Email Sales Tool",
    "h1": "Integrate Reckon One with SalesBlink",
    "metaDescription": "Integrate Reckon One and other Accounting Tools with SalesBlink. Two Way Reckon One Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mindstamp",
    "slug": "mindstamp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9bc75d0d264c3c43cb9e8b9db15ffeb0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mindstamp is an interactive video solution that helps you increase customer engagement, collect data, drive actions, and automate workflows",
    "category": "Video & Audio",
    "title": "Mindstamp Integration With Cold Email Sales Tool",
    "h1": "Integrate Mindstamp with SalesBlink",
    "metaDescription": "Integrate Mindstamp and other Video & Audio Tools with SalesBlink. Two Way Mindstamp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Telnyx Fax",
    "slug": "telnyx-fax",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbb4370989d643764687b608073d0828_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Telnyx Fax is a full-featured global communications platform. Send and receive faxes in the cloud using our Programmable Fax Integration.",
    "category": "Communication",
    "title": "Telnyx Fax Integration With Cold Email Sales Tool",
    "h1": "Integrate Telnyx Fax with SalesBlink",
    "metaDescription": "Integrate Telnyx Fax and other Communication Tools with SalesBlink. Two Way Telnyx Fax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DOPE360",
    "slug": "dope360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a553f133968a35ff999ae8e625223d8c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DOPE360 makes direct mail simple for your business. Use our Neighborhood Blitz tool to target any neighborhood. Get laser targeted mailing lists by income, home value, and more.",
    "category": "Marketing Automation",
    "title": "DOPE360 Integration With Cold Email Sales Tool",
    "h1": "Integrate DOPE360 with SalesBlink",
    "metaDescription": "Integrate DOPE360 and other Marketing Automation Tools with SalesBlink. Two Way DOPE360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "easyfeedback",
    "slug": "easyfeedback",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27e5e961d38d19c88f4125d06fde4a48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "easyfeedback helps you create, share, and manage surveys to collect feedback from employees, customers, or anyone else to get answers to your questions.",
    "category": "Forms & Surveys",
    "title": "easyfeedback Integration With Cold Email Sales Tool",
    "h1": "Integrate easyfeedback with SalesBlink",
    "metaDescription": "Integrate easyfeedback and other Forms & Surveys Tools with SalesBlink. Two Way easyfeedback Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "New User Approve",
    "slug": "new-user-approve",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b371da398b04d928c78eb5c2f93b7d85.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "New User Approve is a plugin automates the user registration process on your WordPress website.",
    "category": "Contact Management",
    "title": "New User Approve Integration With Cold Email Sales Tool",
    "h1": "Integrate New User Approve with SalesBlink",
    "metaDescription": "Integrate New User Approve and other Contact Management Tools with SalesBlink. Two Way New User Approve Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flora",
    "slug": "flora",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/730aa9f2169547dec2d4e5c1d334123c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build, market, and sell your courses online, without any coding or design skills.",
    "category": "Online Courses",
    "title": "Flora Integration With Cold Email Sales Tool",
    "h1": "Integrate Flora with SalesBlink",
    "metaDescription": "Integrate Flora and other Online Courses Tools with SalesBlink. Two Way Flora Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FogBugz",
    "slug": "fogbugz",
    "logo": "https://zapier-images.imgix.net/storage/developer/40d0063ae7f49894265c97b4d043e1bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FogBugz is powerful hosted bug tracking software used to make great software by great teams.",
    "category": "Developer Tools",
    "title": "FogBugz Integration With Cold Email Sales Tool",
    "h1": "Integrate FogBugz with SalesBlink",
    "metaDescription": "Integrate FogBugz and other Developer Tools Tools with SalesBlink. Two Way FogBugz Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Firm360",
    "slug": "firm360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c66240c6ce2f621e55ffb43fa9c6e80_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Firm360 is an all-in-one practice management system for accounting firms.",
    "category": "Accounting",
    "title": "Firm360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Firm360 with SalesBlink",
    "metaDescription": "Integrate Firm360 and other Accounting Tools with SalesBlink. Two Way Firm360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "essensys Operate",
    "slug": "essensys",
    "logo": "https://zapier-images.imgix.net/storage/developer/33810dafd50ea8e045acacea6a923d96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "essensys Operate is a workspace management platform that helps space-as-a-service operators run their workspace from lead to cash and everything in between.",
    "category": "Website Builders",
    "title": "essensys Operate Integration With Cold Email Sales Tool",
    "h1": "Integrate essensys Operate with SalesBlink",
    "metaDescription": "Integrate essensys Operate and other Website Builders Tools with SalesBlink. Two Way essensys Operate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MeaningCloud",
    "slug": "meaningcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23d958bf5bce6835086b31e437616205.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MeaningCloud provides text analytics functionalities that allow you to extract the meaning of all kind of unstructured content.",
    "category": "Ads & Conversion",
    "title": "MeaningCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate MeaningCloud with SalesBlink",
    "metaDescription": "Integrate MeaningCloud and other Ads & Conversion Tools with SalesBlink. Two Way MeaningCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yanado",
    "slug": "yanado",
    "logo": "https://zapier-images.imgix.net/storage/developer/0e75b040ca60421343296da1e549e1ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yanado turns Gmail into a powerful collaboration tool. Create tasks and turn emails into tasks within seconds.",
    "category": "Project Management",
    "title": "Yanado Integration With Cold Email Sales Tool",
    "h1": "Integrate Yanado with SalesBlink",
    "metaDescription": "Integrate Yanado and other Project Management Tools with SalesBlink. Two Way Yanado Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "GoHire",
    "slug": "gohire-da105801",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9685963a0b4c35acb21e9b3173866743.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoHire is easy-to-use recruitment software that will transform your hiring, make it simpler, more productive, and more pleasant.",
    "category": "HR Talent & Recruitment",
    "title": "GoHire Integration With Cold Email Sales Tool",
    "h1": "Integrate GoHire with SalesBlink",
    "metaDescription": "Integrate GoHire and other HR Talent & Recruitment Tools with SalesBlink. Two Way GoHire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Broadly",
    "slug": "broadly",
    "logo": "https://zapier-images.imgix.net/storage/services/cf8e69d23a0e47ded76e6cc08779671a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Broadly helps your business get great reviews and grow word-of-mouth.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Broadly Integration With Cold Email Sales Tool",
    "h1": "Integrate Broadly with SalesBlink",
    "metaDescription": "Integrate Broadly and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Broadly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Building Stack",
    "slug": "building-stack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/374f69d88ce5f2877110f7ad3962b677.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Building Stack is an all-in-one property management software that helps you; manage tenants, collect rent payments and resolve work orders.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Building Stack Integration With Cold Email Sales Tool",
    "h1": "Integrate Building Stack with SalesBlink",
    "metaDescription": "Integrate Building Stack and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Building Stack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PhoneTrack",
    "slug": "phonetrack",
    "logo": "https://zapier-images.imgix.net/storage/developer/3d5f3920cc65319a759df3c5c81d0c98_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PhoneTrack is a call tracking platform that assists sales teams, service management and marketing.",
    "category": "Call Tracking",
    "title": "PhoneTrack Integration With Cold Email Sales Tool",
    "h1": "Integrate PhoneTrack with SalesBlink",
    "metaDescription": "Integrate PhoneTrack and other Call Tracking Tools with SalesBlink. Two Way PhoneTrack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FullContact",
    "slug": "fullcontact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94b0bdb2cb8b64d6253d097e5d53d871.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FullContact's person-first Identity Resolution Platform provides the crucial intelligence needed to accelerate omnichannel customer experiences.",
    "category": "",
    "title": "FullContact Integration With Cold Email Sales Tool",
    "h1": "Integrate FullContact with SalesBlink",
    "metaDescription": "Integrate FullContact and other  Tools with SalesBlink. Two Way FullContact Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Giddyup",
    "slug": "giddyup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b43ad75a10707e59f1e39b05a80285d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Giddyup by CutterCroix is a workflow management tool for the construction industry.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Giddyup Integration With Cold Email Sales Tool",
    "h1": "Integrate Giddyup with SalesBlink",
    "metaDescription": "Integrate Giddyup and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Giddyup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RenderForm",
    "slug": "renderform",
    "logo": "https://zapier-images.imgix.net/storage/services/e267b8224af3f20659d9cec7999f423d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RenderForm is an image generation tool for repetitive design creation.",
    "category": "Marketing Automation",
    "title": "RenderForm Integration With Cold Email Sales Tool",
    "h1": "Integrate RenderForm with SalesBlink",
    "metaDescription": "Integrate RenderForm and other Marketing Automation Tools with SalesBlink. Two Way RenderForm Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Akkio",
    "slug": "akkio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51a2fc8ad83e1aff8da30823713a9c71.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A simple, visual, easy-to-use platform that enables anyone to build and deploy AI in minutes.",
    "category": "Business Intelligence",
    "title": "Akkio Integration With Cold Email Sales Tool",
    "h1": "Integrate Akkio with SalesBlink",
    "metaDescription": "Integrate Akkio and other Business Intelligence Tools with SalesBlink. Two Way Akkio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hive.co",
    "slug": "hiveco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9eab646203e752e5ded61e2dbce77a94.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hive.co is a smart email marketing and CRM platform that helps you increase revenue with targeted email campaigns and automations.",
    "category": "Marketing Automation",
    "title": "Hive.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Hive.co with SalesBlink",
    "metaDescription": "Integrate Hive.co and other Marketing Automation Tools with SalesBlink. Two Way Hive.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fitter",
    "slug": "fitter-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1843cf6e95be5838e7360265ee1d4402.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fitter is a no-code app builder for health and fitness professionals to expand their impact online",
    "category": "App Builder",
    "title": "Fitter Integration With Cold Email Sales Tool",
    "h1": "Integrate Fitter with SalesBlink",
    "metaDescription": "Integrate Fitter and other App Builder Tools with SalesBlink. Two Way Fitter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digimind Social",
    "slug": "digimind-social",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b4bc877b41dd46630f4e7aa11ba7fb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digimind is a web and social media listening platform that helps you track consumer insights, competitors, and market trends.",
    "category": "Social Media Marketing",
    "title": "Digimind Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Digimind Social with SalesBlink",
    "metaDescription": "Integrate Digimind Social and other Social Media Marketing Tools with SalesBlink. Two Way Digimind Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AlgoDocs",
    "slug": "algodocs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/af2f9f066fb331015cd56db5f517e1ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AlgoDocs extracts data from PDF, Word and image files by converting them into structured data in a format you need. With AlgoDocs you can extract specific fields (e.g. Account Number, Date, Order Number, etc.) or data from tables of any complexity from your documents.",
    "category": "Documents",
    "title": "AlgoDocs Integration With Cold Email Sales Tool",
    "h1": "Integrate AlgoDocs with SalesBlink",
    "metaDescription": "Integrate AlgoDocs and other Documents Tools with SalesBlink. Two Way AlgoDocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elfsquad Product Configurator",
    "slug": "elfsquad-product-configurator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2528c6db00e31d8d0d93f3dc80f9a212_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate your existing workflow with the Elfsquad Product Configurator.",
    "category": "Developer Tools",
    "title": "Elfsquad Product Configurator Integration With Cold Email Sales Tool",
    "h1": "Integrate Elfsquad Product Configurator with SalesBlink",
    "metaDescription": "Integrate Elfsquad Product Configurator and other Developer Tools Tools with SalesBlink. Two Way Elfsquad Product Configurator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nimbus Platform",
    "slug": "nimbus-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e8154af6580edc3554c4efbdf5a5084_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online colloboration tool for business. Build your wikis, client portals and more. A perfect space for online work.",
    "category": "Project Management",
    "title": "Nimbus Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Nimbus Platform with SalesBlink",
    "metaDescription": "Integrate Nimbus Platform and other Project Management Tools with SalesBlink. Two Way Nimbus Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quickpage",
    "slug": "quickpage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13e20931f6dd805539fd064e4909c8b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quickpage is a video follow-up tool that's increasing engagement by 200%. The quickest most effective closing tool used by sales reps and their clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Quickpage Integration With Cold Email Sales Tool",
    "h1": "Integrate Quickpage with SalesBlink",
    "metaDescription": "Integrate Quickpage and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Quickpage Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "iFormBuilder",
    "slug": "iformbuilder",
    "logo": "https://zapier-images.imgix.net/storage/developer/7fc0316b44d8463f66f49e8e349a7425.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iFormBuilder is a universal, cloud based mobile data collection (\"forms\") platform with an emphasis on security on mobile devices, and collecting data with or without an Internet connection.",
    "category": "Forms & Surveys",
    "title": "iFormBuilder Integration With Cold Email Sales Tool",
    "h1": "Integrate iFormBuilder with SalesBlink",
    "metaDescription": "Integrate iFormBuilder and other Forms & Surveys Tools with SalesBlink. Two Way iFormBuilder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "One AI",
    "slug": "one-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2a6ee7912aaabcf19ebefda2a16db496.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "One AI is an API-first, language AI service built for developers. Embed our API to analyze, process, and transform text in your project",
    "category": "Developer Tools",
    "title": "One AI Integration With Cold Email Sales Tool",
    "h1": "Integrate One AI with SalesBlink",
    "metaDescription": "Integrate One AI and other Developer Tools Tools with SalesBlink. Two Way One AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bowimi",
    "slug": "bowimi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48586715bb8fc99791fabb8ec1fd5239.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple, powerful tools which drive real business growth at leading FMCG brands. Bowimi helps you prospect, plan routes, capture data and manage field sales teams more effectively.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bowimi Integration With Cold Email Sales Tool",
    "h1": "Integrate Bowimi with SalesBlink",
    "metaDescription": "Integrate Bowimi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bowimi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jumbula",
    "slug": "jumbula",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6980919e91000c34024d84bd66b62fea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jumbula solutions features easy online registration and management software for class, camp and after-school programs.",
    "category": "Education",
    "title": "Jumbula Integration With Cold Email Sales Tool",
    "h1": "Integrate Jumbula with SalesBlink",
    "metaDescription": "Integrate Jumbula and other Education Tools with SalesBlink. Two Way Jumbula Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Sprints",
    "slug": "zoho-sprints",
    "logo": "https://zapier-images.imgix.net/storage/developer/f2dd7173bff7edfbf30d52539d03a729.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Sprints is a project management tool that helps agile teams plan and track their work with scrum, agile reports, and collaboration capabilites for software development teams.",
    "category": "Project Management",
    "title": "Zoho Sprints Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Sprints with SalesBlink",
    "metaDescription": "Integrate Zoho Sprints and other Project Management Tools with SalesBlink. Two Way Zoho Sprints Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RecurPost",
    "slug": "recurpost",
    "logo": "https://zapier-images.imgix.net/storage/services/1ac71050995dcc569a11bf74c6c08903.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RecurPost is a social media scheduler with repeating schedules.",
    "category": "Social Media Marketing",
    "title": "RecurPost Integration With Cold Email Sales Tool",
    "h1": "Integrate RecurPost with SalesBlink",
    "metaDescription": "Integrate RecurPost and other Social Media Marketing Tools with SalesBlink. Two Way RecurPost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Calendesk",
    "slug": "calendesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd195a40766e043d05947a654a8668be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Calendesk helps to accept bookings from customers, handle their payments, contact them and generate a landing page for them.",
    "category": "Scheduling & Booking",
    "title": "Calendesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Calendesk with SalesBlink",
    "metaDescription": "Integrate Calendesk and other Scheduling & Booking Tools with SalesBlink. Two Way Calendesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rev.io",
    "slug": "revio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e652a0c21092a916c4611dc4d27af0f_2.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rev.io is an IoT billing platform with the tools needed to accurately manage billing from quote-to-cash. Save time by leveraging existing integrations with your applications and processes, so you can focus on long-term growth.",
    "category": "Payment Processing",
    "title": "Rev.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Rev.io with SalesBlink",
    "metaDescription": "Integrate Rev.io and other Payment Processing Tools with SalesBlink. Two Way Rev.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "pulseM",
    "slug": "pulsem",
    "logo": "https://zapier-images.imgix.net/storage/services/21902414a32880dc1343e24c615253d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PulseM is a customer engagement tool for the home services market which helps you to stay connected with your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "pulseM Integration With Cold Email Sales Tool",
    "h1": "Integrate pulseM with SalesBlink",
    "metaDescription": "Integrate pulseM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way pulseM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hook.Notifier",
    "slug": "hook-notifier",
    "logo": "https://zapier-images.imgix.net/storage/services/8542738ceaa1366ddacf6f725e06bdd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hook.Notifier is a notification collector, it allows you to send, store and organize useful notifications on your phone from various services.",
    "category": "Notifications",
    "title": "Hook.Notifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Hook.Notifier with SalesBlink",
    "metaDescription": "Integrate Hook.Notifier and other Notifications Tools with SalesBlink. Two Way Hook.Notifier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesHive",
    "slug": "saleshive",
    "logo": "https://zapier-images.imgix.net/storage/services/2e7550afc979e57ffeeb486970c8a5ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesHive uses cold calling, email, LinkedIn, and direct mail to book meetings with qualified buyers for your sales team.",
    "category": "Sales & CRM",
    "title": "SalesHive Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesHive with SalesBlink",
    "metaDescription": "Integrate SalesHive and other Sales & CRM Tools with SalesBlink. Two Way SalesHive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SureCart",
    "slug": "surecart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f2354600f7c632c99fb95902571f8e09.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The new way to sell on WordPress. A simple yet powerful e-commerce platform designed to grow your business by effortlessly selling online.",
    "category": "eCommerce",
    "title": "SureCart Integration With Cold Email Sales Tool",
    "h1": "Integrate SureCart with SalesBlink",
    "metaDescription": "Integrate SureCart and other eCommerce Tools with SalesBlink. Two Way SureCart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Xoda",
    "slug": "xoda",
    "logo": "https://zapier-images.imgix.net/storage/services/14b895f6706c513be56477179c33a9dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xoda is a team collaboration tool for gyms and healthclubs.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Xoda Integration With Cold Email Sales Tool",
    "h1": "Integrate Xoda with SalesBlink",
    "metaDescription": "Integrate Xoda and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Xoda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EASY2",
    "slug": "easy2",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/082870b352b78829f8a68b3fa7085dc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EASY2 is an all-in-one software for your digital marketing. Whether funnel, e-mail marketing, online shop member area, etc. with EASY2 you have everything in ONE place.",
    "category": "Marketing Automation",
    "title": "EASY2 Integration With Cold Email Sales Tool",
    "h1": "Integrate EASY2 with SalesBlink",
    "metaDescription": "Integrate EASY2 and other Marketing Automation Tools with SalesBlink. Two Way EASY2 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SweetProcess",
    "slug": "sweetprocess",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c57867b1dcb0efb2649681cd18c70b89.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SweetProcess is a process management tool for on-boarding your employees and to document your business procedures, processes and policies.",
    "category": "Team Collaboration",
    "title": "SweetProcess Integration With Cold Email Sales Tool",
    "h1": "Integrate SweetProcess with SalesBlink",
    "metaDescription": "Integrate SweetProcess and other Team Collaboration Tools with SalesBlink. Two Way SweetProcess Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paperless Parts",
    "slug": "paperless-parts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/597d4d888fcc1948805fc0c88fd6e0dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paperless Parts is the secure estimating and quoting software for manufacturing that empowers shops to make smarter, faster, more informed decisions. Communicate better, quote faster, win new customers, and increase revenue with Paperless Parts.",
    "category": "Sales & CRM",
    "title": "Paperless Parts Integration With Cold Email Sales Tool",
    "h1": "Integrate Paperless Parts with SalesBlink",
    "metaDescription": "Integrate Paperless Parts and other Sales & CRM Tools with SalesBlink. Two Way Paperless Parts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Newslit",
    "slug": "newslit",
    "logo": "https://zapier-images.imgix.net/storage/services/5ea0e61cfe18f1ffc590a1af070a7477.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Newslit is a next-generation news monitoring and research tool for PR professionals, investors, researchers, marketers, sales people, consultants, and business owners. Continuously tracking the most comprehensive set of important news sources, and integrating social signals from thousands of top business influencers.",
    "category": "News & Lifestyle",
    "title": "Newslit Integration With Cold Email Sales Tool",
    "h1": "Integrate Newslit with SalesBlink",
    "metaDescription": "Integrate Newslit and other News & Lifestyle Tools with SalesBlink. Two Way Newslit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "24sessions",
    "slug": "24sessions",
    "logo": "https://zapier-images.imgix.net/storage/developer/5c70aa7b6c6b60e7f70e3be7c089a8b4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "24sessions helps you to connect with customers via video-chat effortlessly. We make this work by combining scheduling, video-chat and reviews all in a white labeled platform.",
    "category": "Video Conferencing",
    "title": "24sessions Integration With Cold Email Sales Tool",
    "h1": "Integrate 24sessions with SalesBlink",
    "metaDescription": "Integrate 24sessions and other Video Conferencing Tools with SalesBlink. Two Way 24sessions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commune",
    "slug": "commune",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a53b657734f5d7db0946c6a25e25aa85_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Commune is the no-code customer success platform that boosts customer engagement and reduces time and efforts for successful customer success.",
    "category": "Communication",
    "title": "Commune Integration With Cold Email Sales Tool",
    "h1": "Integrate Commune with SalesBlink",
    "metaDescription": "Integrate Commune and other Communication Tools with SalesBlink. Two Way Commune Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InflatableOffice",
    "slug": "inflatableoffice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fafacb25be0518f9dfe49feba017cd92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InflatableOffice is a web applications designed to manage all facets of your party rental business.",
    "category": "Marketing",
    "title": "InflatableOffice Integration With Cold Email Sales Tool",
    "h1": "Integrate InflatableOffice with SalesBlink",
    "metaDescription": "Integrate InflatableOffice and other Marketing Tools with SalesBlink. Two Way InflatableOffice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wherefour",
    "slug": "wherefour",
    "logo": "https://zapier-images.imgix.net/storage/developer/405d476892a7d9b4dceb2123d0317077_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wherefour is a traceability/ERP software platform for modern manufacturers.",
    "category": "Product Management",
    "title": "Wherefour Integration With Cold Email Sales Tool",
    "h1": "Integrate Wherefour with SalesBlink",
    "metaDescription": "Integrate Wherefour and other Product Management Tools with SalesBlink. Two Way Wherefour Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadLander",
    "slug": "leadlander",
    "logo": "https://zapier-images.imgix.net/storage/services/86198219a40e30c7d7a9400b4bfe7a7f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadLander is a website tracking tool that can identify companies and people visiting your website to help grow your sales pipeline.",
    "category": "Website Builders",
    "title": "LeadLander Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadLander with SalesBlink",
    "metaDescription": "Integrate LeadLander and other Website Builders Tools with SalesBlink. Two Way LeadLander Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agillic",
    "slug": "agillic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b043ee472f06ddbdff5f0b1177f4026e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agillic is a native customer marketing platform enabling brands to connect with audiences across channels through data-driven communication at scale. \nWith Agillic you can take advantage of personalisation, automation, AI and omnichannel marketing capabilities from a single interface to create and retain valuable relationships throughout the customer lifecycle.",
    "category": "Marketing Automation",
    "title": "Agillic Integration With Cold Email Sales Tool",
    "h1": "Integrate Agillic with SalesBlink",
    "metaDescription": "Integrate Agillic and other Marketing Automation Tools with SalesBlink. Two Way Agillic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buddy Punch",
    "slug": "buddy-punch",
    "logo": "https://zapier-images.imgix.net/storage/developer/9f2759c11559cb2bd438c03f310cdc4a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Buddy Punch is an employee time tracking and scheduling app that lets your employees track their hours and helps streamline scheduling, time off, and payroll.",
    "category": "Time Tracking Software",
    "title": "Buddy Punch Integration With Cold Email Sales Tool",
    "h1": "Integrate Buddy Punch with SalesBlink",
    "metaDescription": "Integrate Buddy Punch and other Time Tracking Software Tools with SalesBlink. Two Way Buddy Punch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OfficeGuy/SUMIT",
    "slug": "officeguy",
    "logo": "https://zapier-images.imgix.net/storage/developer/55597b22c7e37eda7078579facf8878b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OfficeGuy is a comprehensive system for planning, management and execution for the self-employed, non-profit organizations and business organizations.",
    "category": "Project Management",
    "title": "OfficeGuy/SUMIT Integration With Cold Email Sales Tool",
    "h1": "Integrate OfficeGuy/SUMIT with SalesBlink",
    "metaDescription": "Integrate OfficeGuy/SUMIT and other Project Management Tools with SalesBlink. Two Way OfficeGuy/SUMIT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hyperise",
    "slug": "hyperise",
    "logo": "https://zapier-images.imgix.net/storage/services/f2ceb03f91214bbed737a4fe3365890b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hyperise is a hyper-personalization tool to create personalized images, that can be used in emails, websites, chatbots, videos, ads, print as well a direct message platforms like Slack, LinkedIn, WhatsApps etc.",
    "category": "Marketing",
    "title": "Hyperise Integration With Cold Email Sales Tool",
    "h1": "Integrate Hyperise with SalesBlink",
    "metaDescription": "Integrate Hyperise and other Marketing Tools with SalesBlink. Two Way Hyperise Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Checkify",
    "slug": "checkify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8fd9cfacf2dbfe445a73c702f7455e64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Checkify is one-page customizable checkout for online stores with post-purchase upsells, a wide choice of payment gateways, and much more.",
    "category": "Commerce",
    "title": "Checkify Integration With Cold Email Sales Tool",
    "h1": "Integrate Checkify with SalesBlink",
    "metaDescription": "Integrate Checkify and other Commerce Tools with SalesBlink. Two Way Checkify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OOPSpam",
    "slug": "oopspam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/460a70d57624d6d1bd5ab5c71ba72376.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OOPSpam -  stop bots and manual spam from reaching you or your customer. All with high accuracy and privacy.",
    "category": "",
    "title": "OOPSpam Integration With Cold Email Sales Tool",
    "h1": "Integrate OOPSpam with SalesBlink",
    "metaDescription": "Integrate OOPSpam and other  Tools with SalesBlink. Two Way OOPSpam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yapla",
    "slug": "yapla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a11039d88bb9a6009e257636265fb802.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yapla is a payment and an all-in-one management platform designed by and for non-profit organizations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Yapla Integration With Cold Email Sales Tool",
    "h1": "Integrate Yapla with SalesBlink",
    "metaDescription": "Integrate Yapla and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Yapla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiro.fm",
    "slug": "hirofm",
    "logo": "https://zapier-images.imgix.net/storage/services/094bbbebc5d33db6526513fc312d363b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hiro.fm lets you quickly create private audio products to share with your customers so they can consume your content on the go using their favorite podcast player.",
    "category": "Video & Audio",
    "title": "Hiro.fm Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiro.fm with SalesBlink",
    "metaDescription": "Integrate Hiro.fm and other Video & Audio Tools with SalesBlink. Two Way Hiro.fm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnlineCourseHost.com",
    "slug": "onlinecoursehostcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/da6b534b248df616acfc7bac666e826f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnlineCourseHost.com is a white-label course hosting platform that lets you host and sell your courses.",
    "category": "Education",
    "title": "OnlineCourseHost.com Integration With Cold Email Sales Tool",
    "h1": "Integrate OnlineCourseHost.com with SalesBlink",
    "metaDescription": "Integrate OnlineCourseHost.com and other Education Tools with SalesBlink. Two Way OnlineCourseHost.com Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TouchBasePro",
    "slug": "touchbasepro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f3fab5aff6ac7625dec49b9d490d6b9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TouchBasePro is a email marketing tool to help you create beautiful email campaigns that will transform your brand.",
    "category": "Marketing Automation",
    "title": "TouchBasePro Integration With Cold Email Sales Tool",
    "h1": "Integrate TouchBasePro with SalesBlink",
    "metaDescription": "Integrate TouchBasePro and other Marketing Automation Tools with SalesBlink. Two Way TouchBasePro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Super Send",
    "slug": "super-send",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe6c4558cc6ceb9deadb582229cbcdab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Super Send is an outbound sales automation tool",
    "category": "Marketing Automation",
    "title": "Super Send Integration With Cold Email Sales Tool",
    "h1": "Integrate Super Send with SalesBlink",
    "metaDescription": "Integrate Super Send and other Marketing Automation Tools with SalesBlink. Two Way Super Send Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Conversion Tools",
    "slug": "conversion-tools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d959d597d3cdcbde433a9bb0e109da1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Converting your documents between different formats (XML, Excel, CSV, PDF, etc). Fast and Simple.",
    "category": "Documents",
    "title": "Conversion Tools Integration With Cold Email Sales Tool",
    "h1": "Integrate Conversion Tools with SalesBlink",
    "metaDescription": "Integrate Conversion Tools and other Documents Tools with SalesBlink. Two Way Conversion Tools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "inwink",
    "slug": "inwink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3bf2cace0bd4d99b40b0ac5e2ea831d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "inwink is an event intelligence platform that helps event organizers to manage the complete event life-cycle and addresses the needs of all stakeholders : manage event data, build event website, deliver amazing attendee experience, enable efficient collaboration with partners and maximize event ROI.",
    "category": "Event Management",
    "title": "inwink Integration With Cold Email Sales Tool",
    "h1": "Integrate inwink with SalesBlink",
    "metaDescription": "Integrate inwink and other Event Management Tools with SalesBlink. Two Way inwink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Genoo",
    "slug": "genoo",
    "logo": "https://zapier-images.imgix.net/storage/developer/968c22d0c98484e863d4a733009826ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marketing Automation and Online Marketing Tools for small and midsized organizations.  Includes comprehensive lead database, activity tracking and scoring, email marketing, and lead nurturing.  Also contains integrated CRM for small sales teams.",
    "category": "Marketing Automation",
    "title": "Genoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Genoo with SalesBlink",
    "metaDescription": "Integrate Genoo and other Marketing Automation Tools with SalesBlink. Two Way Genoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datagma",
    "slug": "datagma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfc903e487a7228c77b5c35e18dd0a12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datagma is a data API that lets you enrich your person and company records with social, demographic, and firmographic data.",
    "category": "Marketing",
    "title": "Datagma Integration With Cold Email Sales Tool",
    "h1": "Integrate Datagma with SalesBlink",
    "metaDescription": "Integrate Datagma and other Marketing Tools with SalesBlink. Two Way Datagma Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SITE123",
    "slug": "site123",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42614e165fe6d80421142cdcd076b512.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SITE123 is the easiest free website builder that gives you ready-made layouts and styles to build your website fast and without any bugs.",
    "category": "Website Builders",
    "title": "SITE123 Integration With Cold Email Sales Tool",
    "h1": "Integrate SITE123 with SalesBlink",
    "metaDescription": "Integrate SITE123 and other Website Builders Tools with SalesBlink. Two Way SITE123 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grindery Web3 Gateway",
    "slug": "grindery-web3-gateway",
    "logo": "https://zapier-images.imgix.net/storage/services/89dd07554ae9de71f0e978acb418da3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect to your favorite Web3 dApps, protocols, and smart contracts across many chains, such as automated minting of NFTs from a Typeform.",
    "category": "Developer Tools",
    "title": "Grindery Web3 Gateway Integration With Cold Email Sales Tool",
    "h1": "Integrate Grindery Web3 Gateway with SalesBlink",
    "metaDescription": "Integrate Grindery Web3 Gateway and other Developer Tools Tools with SalesBlink. Two Way Grindery Web3 Gateway Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emitrr",
    "slug": "emitrr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ce24a500f7176b3db1d1b21757f7248.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emitrr is a human-like smart receptionist for small businesses. With Emitrr, business owners don’t have to worry about missing customer calls and can focus on their business growth, while Emitrr handles customer queries like booking appointments, quotes, FAQs, and more.",
    "category": "Contact Management",
    "title": "Emitrr Integration With Cold Email Sales Tool",
    "h1": "Integrate Emitrr with SalesBlink",
    "metaDescription": "Integrate Emitrr and other Contact Management Tools with SalesBlink. Two Way Emitrr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "hireEZ",
    "slug": "hireez",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75454de814e61186c17c619685dea37f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "hireEZ is an outbound recruiting platform that intelligently searches 800M+ candidate profiles, automates engagement, and connects with other industry systems.",
    "category": "HR Talent & Recruitment",
    "title": "hireEZ Integration With Cold Email Sales Tool",
    "h1": "Integrate hireEZ with SalesBlink",
    "metaDescription": "Integrate hireEZ and other HR Talent & Recruitment Tools with SalesBlink. Two Way hireEZ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SupaPass",
    "slug": "supapass",
    "logo": "https://zapier-images.imgix.net/storage/services/f5a6e898dcfb2853e62f04042af81007.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily create a website & mobile app for all your content, community & paywall, with no-code.",
    "category": "Website Builders",
    "title": "SupaPass Integration With Cold Email Sales Tool",
    "h1": "Integrate SupaPass with SalesBlink",
    "metaDescription": "Integrate SupaPass and other Website Builders Tools with SalesBlink. Two Way SupaPass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NGP VAN",
    "slug": "ngp-van",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7d7b51bb28a9926ecbe6d49e0fc3c8b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NGP VAN is the leading technology provider to Democratic and progressive campaigns and organizations, nonprofits, municipalities, and others, offering an integrated platform of the best fundraising, compliance, field, organizing, digital, and social networking products.",
    "category": "CRM (Customer Relationship Management)",
    "title": "NGP VAN Integration With Cold Email Sales Tool",
    "h1": "Integrate NGP VAN with SalesBlink",
    "metaDescription": "Integrate NGP VAN and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way NGP VAN Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Featurebase",
    "slug": "featurebase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c6f469ae80f9e376532fbe15be4ac1e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Featurebase is a feedback management tool that helps you prioritize user feedback and make correct product decisions.",
    "category": "Product Management",
    "title": "Featurebase Integration With Cold Email Sales Tool",
    "h1": "Integrate Featurebase with SalesBlink",
    "metaDescription": "Integrate Featurebase and other Product Management Tools with SalesBlink. Two Way Featurebase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textdrip",
    "slug": "textdrip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c0bee546a7447bbc8b5e1bdf4039ee0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text from the web, iphone, or android app, setup Textdrip campaigns, auto-responders, and more!",
    "category": "Communication",
    "title": "Textdrip Integration With Cold Email Sales Tool",
    "h1": "Integrate Textdrip with SalesBlink",
    "metaDescription": "Integrate Textdrip and other Communication Tools with SalesBlink. Two Way Textdrip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vifugo",
    "slug": "vifugo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d72dd15fa01a89c1f241339f8f87bae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vifugo is an easy to use video on demand marketing tool.",
    "category": "Marketing",
    "title": "Vifugo Integration With Cold Email Sales Tool",
    "h1": "Integrate Vifugo with SalesBlink",
    "metaDescription": "Integrate Vifugo and other Marketing Tools with SalesBlink. Two Way Vifugo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Timeero",
    "slug": "timeero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ffc55769d282ce47c31cd375f636edc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Timeero is a mileage and GPS time tracking app for mobile teams.",
    "category": "Time Tracking Software",
    "title": "Timeero Integration With Cold Email Sales Tool",
    "h1": "Integrate Timeero with SalesBlink",
    "metaDescription": "Integrate Timeero and other Time Tracking Software Tools with SalesBlink. Two Way Timeero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ebuero",
    "slug": "ebuero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef2350672cb047d687c77b76ef3de22c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ebuero is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business",
    "category": "Communication",
    "title": "ebuero Integration With Cold Email Sales Tool",
    "h1": "Integrate ebuero with SalesBlink",
    "metaDescription": "Integrate ebuero and other Communication Tools with SalesBlink. Two Way ebuero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stampede",
    "slug": "stampede",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d11607676b37dfff56810f8544acad4c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stampede offers customer insights and effective marketing campaigns that will increase your sales and returning customers.",
    "category": "Marketing Automation",
    "title": "Stampede Integration With Cold Email Sales Tool",
    "h1": "Integrate Stampede with SalesBlink",
    "metaDescription": "Integrate Stampede and other Marketing Automation Tools with SalesBlink. Two Way Stampede Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chatforma",
    "slug": "chatforma",
    "logo": "https://zapier-images.imgix.net/storage/developer/495db27dc9c118f4b787d73894d927b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chatforma is messenger marketing platform that lets you build chatbots and automated messenger sales with no coding.",
    "category": "Marketing Automation",
    "title": "Chatforma Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatforma with SalesBlink",
    "metaDescription": "Integrate Chatforma and other Marketing Automation Tools with SalesBlink. Two Way Chatforma Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ClickDesk",
    "slug": "clickdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/40567b795fa2fa8c94adba15658fa10d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickDesk is a combo of Live Chat, Voice and Video Chat and Helpdesk Software For Online Businesses. It is currently helping over 150,000 online businesses to better engage their web visitors.",
    "category": "Customer Support",
    "title": "ClickDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickDesk with SalesBlink",
    "metaDescription": "Integrate ClickDesk and other Customer Support Tools with SalesBlink. Two Way ClickDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cronofy",
    "slug": "cronofy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/58107be2f2746ffd57cb81aae24b0994_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cronofy is a product that allows you to embed real-time scheduling into your workflows with personalized booking links for your customers and prospects.",
    "category": "Scheduling & Booking",
    "title": "Cronofy Integration With Cold Email Sales Tool",
    "h1": "Integrate Cronofy with SalesBlink",
    "metaDescription": "Integrate Cronofy and other Scheduling & Booking Tools with SalesBlink. Two Way Cronofy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LawnPro",
    "slug": "lawnpro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65a21fcce163d7ea6f2dd4c2372fd19d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate business processes and workflows by scheduling, routing, automatic invoicing, payment processing, and online booking.",
    "category": "Sales & CRM",
    "title": "LawnPro Integration With Cold Email Sales Tool",
    "h1": "Integrate LawnPro with SalesBlink",
    "metaDescription": "Integrate LawnPro and other Sales & CRM Tools with SalesBlink. Two Way LawnPro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "karmaCRM",
    "slug": "karmacrm",
    "logo": "https://zapier-images.imgix.net/storage/developer/5840e40879dd696689d7b78181c3a483.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KarmaCRM is a simple CRM that helps you nurture personalized relationships at scale by connecting your email, calendar and team to bring all your data into a single place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "karmaCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate karmaCRM with SalesBlink",
    "metaDescription": "Integrate karmaCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way karmaCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lead2Team",
    "slug": "lead2team",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/322cf3c29286381995f9412b01d0a4f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead2Team is a lead generator tool to empower sales professionals.",
    "category": "Communication",
    "title": "Lead2Team Integration With Cold Email Sales Tool",
    "h1": "Integrate Lead2Team with SalesBlink",
    "metaDescription": "Integrate Lead2Team and other Communication Tools with SalesBlink. Two Way Lead2Team Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aero Workflow",
    "slug": "aero-workflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/83dba80bb72db44b4604d4b21151a44a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aero Workflow is a workflow application for accountants by accountants.",
    "category": "Accounting",
    "title": "Aero Workflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Aero Workflow with SalesBlink",
    "metaDescription": "Integrate Aero Workflow and other Accounting Tools with SalesBlink. Two Way Aero Workflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeo Route Planner",
    "slug": "zeo-route-planner",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9324ebcc8baa220b4786b528f9ccc3dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeo Route Planner helps courier drivers optimise their routes and save on time, distance, and fuel.",
    "category": "Scheduling & Booking",
    "title": "Zeo Route Planner Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeo Route Planner with SalesBlink",
    "metaDescription": "Integrate Zeo Route Planner and other Scheduling & Booking Tools with SalesBlink. Two Way Zeo Route Planner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BasicOps",
    "slug": "basicops",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bfb2e751ed880acf813d62b2dfe3e306.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BasicOps is the online project management tool that empowers hybrid teams to collaborate on projects, tasks, files and notes all in one place and in real time.",
    "category": "Productivity",
    "title": "BasicOps Integration With Cold Email Sales Tool",
    "h1": "Integrate BasicOps with SalesBlink",
    "metaDescription": "Integrate BasicOps and other Productivity Tools with SalesBlink. Two Way BasicOps Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Onboard.io",
    "slug": "onboardio",
    "logo": "https://zapier-images.imgix.net/storage/services/40c983420fc6b53a6b1dd69ca5317f40.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onboard is a SaaS made specifically for customer implementation. Customized MAPS allow teams to automate the best implementation plans and provide visibility for every customer.",
    "category": "Customer Support",
    "title": "Onboard.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Onboard.io with SalesBlink",
    "metaDescription": "Integrate Onboard.io and other Customer Support Tools with SalesBlink. Two Way Onboard.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InvoiceBerry",
    "slug": "invoiceberry",
    "logo": "https://zapier-images.imgix.net/storage/developer/f92bc12a071955d098b59d896a1f5652.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InvoiceBerry is an easy-to-use online invoicing software for small businesses and freelancers.",
    "category": "Accounting",
    "title": "InvoiceBerry Integration With Cold Email Sales Tool",
    "h1": "Integrate InvoiceBerry with SalesBlink",
    "metaDescription": "Integrate InvoiceBerry and other Accounting Tools with SalesBlink. Two Way InvoiceBerry Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Kizen",
    "slug": "kizen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ab40f9ed469df81275f73947d32ced8f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kizen is a sales and marketing tool to collect, organize, and gain actionable insights on data.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kizen Integration With Cold Email Sales Tool",
    "h1": "Integrate Kizen with SalesBlink",
    "metaDescription": "Integrate Kizen and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kizen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Veriphone",
    "slug": "veriphone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7510a84b4abf8a8b5ed34c380a44f8b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Veriphone is a phone number verification tool. It will parse, format and validate phone numbers for all countries/regions worldwide.",
    "category": "Phone & SMS",
    "title": "Veriphone Integration With Cold Email Sales Tool",
    "h1": "Integrate Veriphone with SalesBlink",
    "metaDescription": "Integrate Veriphone and other Phone & SMS Tools with SalesBlink. Two Way Veriphone Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Callbell",
    "slug": "callbell",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6433153787b08747a3208ae3e413ec3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Callbell is a centralized inbox which allows teams to collaborate and reply to customers using WhatsApp, Instagram, Facebook Messenger and Telegram.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Callbell Integration With Cold Email Sales Tool",
    "h1": "Integrate Callbell with SalesBlink",
    "metaDescription": "Integrate Callbell and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Callbell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZeptoMail",
    "slug": "zeptomail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/add6fcb834c042c88a79463dcf0a7853.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZeptoMail is a developer-friendly and secure transactional email service for your applications.",
    "category": "Transactional Email",
    "title": "ZeptoMail Integration With Cold Email Sales Tool",
    "h1": "Integrate ZeptoMail with SalesBlink",
    "metaDescription": "Integrate ZeptoMail and other Transactional Email Tools with SalesBlink. Two Way ZeptoMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "csvbox.io",
    "slug": "csvboxio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ab30b82a7d588f6ac5d4e35a5dcb5d7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "csvbox.io is a no-code tool that helps web apps and SaaS companies to add a production-ready spreadsheet import feature to their application in a few minutes.",
    "category": "Developer Tools",
    "title": "csvbox.io Integration With Cold Email Sales Tool",
    "h1": "Integrate csvbox.io with SalesBlink",
    "metaDescription": "Integrate csvbox.io and other Developer Tools Tools with SalesBlink. Two Way csvbox.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zenlist",
    "slug": "zenlist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/172104cb7d5dfd6313bb1f4bbd0e7de3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenlist is an agent and client collaboration tool to find a home using the most powerful search available and including the best inventory available.",
    "category": "Productivity",
    "title": "Zenlist Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenlist with SalesBlink",
    "metaDescription": "Integrate Zenlist and other Productivity Tools with SalesBlink. Two Way Zenlist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimplyNoted",
    "slug": "simply-noted",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33fb6f35abedc88f848592d83c8b7423_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simply Noted allows you to order Handwritten Cards and have them sent to your customers.",
    "category": "Marketing Automation",
    "title": "SimplyNoted Integration With Cold Email Sales Tool",
    "h1": "Integrate SimplyNoted with SalesBlink",
    "metaDescription": "Integrate SimplyNoted and other Marketing Automation Tools with SalesBlink. Two Way SimplyNoted Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Axosoft",
    "slug": "axosoft",
    "logo": "https://zapier-images.imgix.net/storage/developer/70ca112a5425c65b302162ff07ceb3ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Axosoft is agile project management for software development teams.",
    "category": "Project Management",
    "title": "Axosoft Integration With Cold Email Sales Tool",
    "h1": "Integrate Axosoft with SalesBlink",
    "metaDescription": "Integrate Axosoft and other Project Management Tools with SalesBlink. Two Way Axosoft Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "3Scribe",
    "slug": "3scribe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b29945c91a782ebe93af84a119768618.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "3Scribe is the most accurate, automated text transcription available.  We convert audio and video files and give near human accuracy at automated prices.",
    "category": "Transcription",
    "title": "3Scribe Integration With Cold Email Sales Tool",
    "h1": "Integrate 3Scribe with SalesBlink",
    "metaDescription": "Integrate 3Scribe and other Transcription Tools with SalesBlink. Two Way 3Scribe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Platform.ly",
    "slug": "platformly",
    "logo": "https://zapier-images.imgix.net/storage/developer/71cfbaa137fb577a119e34c253a34ce5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Platformly is your marketing automation platform that empowers you to engage your audience at every step of their journey.",
    "category": "Marketing Automation",
    "title": "Platform.ly Integration With Cold Email Sales Tool",
    "h1": "Integrate Platform.ly with SalesBlink",
    "metaDescription": "Integrate Platform.ly and other Marketing Automation Tools with SalesBlink. Two Way Platform.ly Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "The Flybook",
    "slug": "the-flybook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d38df241b1efccb9d591b3b87f370d4b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Flybook is a reservation software for the tourism industry.  It handles everything from scheduling to payments to online booking and more.",
    "category": "Scheduling & Booking",
    "title": "The Flybook Integration With Cold Email Sales Tool",
    "h1": "Integrate The Flybook with SalesBlink",
    "metaDescription": "Integrate The Flybook and other Scheduling & Booking Tools with SalesBlink. Two Way The Flybook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VolunteerHub",
    "slug": "volunteerhub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02131d9d77d48bd04a5d6c377795330b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VolunteerHub is web-based software designed to help organizations recruit, engage, and manage volunteers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "VolunteerHub Integration With Cold Email Sales Tool",
    "h1": "Integrate VolunteerHub with SalesBlink",
    "metaDescription": "Integrate VolunteerHub and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way VolunteerHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upfluence",
    "slug": "upfluence",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/80a6ca979649afadea1ef643620ffb51.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upfluence is an influencer platform that lets you identify organic influencers in your own customer base & website audience. Collect social data when visitors are browsing your site or during checkout and analyze their social data.",
    "category": "Social Media Marketing",
    "title": "Upfluence Integration With Cold Email Sales Tool",
    "h1": "Integrate Upfluence with SalesBlink",
    "metaDescription": "Integrate Upfluence and other Social Media Marketing Tools with SalesBlink. Two Way Upfluence Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Transifex",
    "slug": "transifex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cde85c4744aa7974f05a7ab5c5cd2365_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transifex is a localization platform service.",
    "category": "Developer Tools",
    "title": "Transifex Integration With Cold Email Sales Tool",
    "h1": "Integrate Transifex with SalesBlink",
    "metaDescription": "Integrate Transifex and other Developer Tools Tools with SalesBlink. Two Way Transifex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intros AI",
    "slug": "intros-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/daca68103c5dc76a5687ee302e92b45f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intros AI is a platform for building stronger communities through personalized 1:1 and group matches.",
    "category": "Communication",
    "title": "Intros AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Intros AI with SalesBlink",
    "metaDescription": "Integrate Intros AI and other Communication Tools with SalesBlink. Two Way Intros AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "I Done This",
    "slug": "i-done-this",
    "logo": "https://zapier-images.imgix.net/storage/developer/e63076b4e313fc3479c64bae9a6f9c28.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "I Done This makes it easy to track and celebrate the progress that you and your colleagues make at work every day. We email you at day's end and ask, \"What'd you get done today?\" Just reply. The next morning, you'll get a digest that shows your team's accomplishments from yesterday. Start a conversation and share your thanks.",
    "category": "Analytics",
    "title": "I Done This Integration With Cold Email Sales Tool",
    "h1": "Integrate I Done This with SalesBlink",
    "metaDescription": "Integrate I Done This and other Analytics Tools with SalesBlink. Two Way I Done This Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiveage",
    "slug": "hiveage",
    "logo": "https://zapier-images.imgix.net/storage/developer/df750e057e22ae402bf36b5471defe37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple, powerful online billing for freelancers and small businesses. Track time, expenses and mileage. Manage multiple teams & businesses.",
    "category": "Proposal & Invoice Management",
    "title": "Hiveage Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiveage with SalesBlink",
    "metaDescription": "Integrate Hiveage and other Proposal & Invoice Management Tools with SalesBlink. Two Way Hiveage Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Inmobalia CRM",
    "slug": "inmobalia-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ce395d869e43c4e42983e1c6c030910.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inmobalia CRM is the online real estate software and secure property MLS network servicing the most recognized real estate agencies on the Costa del Sol, probably one of the most competitive real estate markets in Spain and the whole Europe",
    "category": "CRM (Customer Relationship Management)",
    "title": "Inmobalia CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Inmobalia CRM with SalesBlink",
    "metaDescription": "Integrate Inmobalia CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Inmobalia CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cassa in Cloud",
    "slug": "cassa-in-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0d3acb7801dc82c75fcf969d154f39d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cassa in Cloud is both a cash register and management system.",
    "category": "Accounting",
    "title": "Cassa in Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Cassa in Cloud with SalesBlink",
    "metaDescription": "Integrate Cassa in Cloud and other Accounting Tools with SalesBlink. Two Way Cassa in Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GetLandy",
    "slug": "getlandy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2e3318304e5000bb7c6f372ace0e9a77.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetLandy makes your marketing team able to easily create customized landing pages at a bigger scale.",
    "category": "Marketing Automation",
    "title": "GetLandy Integration With Cold Email Sales Tool",
    "h1": "Integrate GetLandy with SalesBlink",
    "metaDescription": "Integrate GetLandy and other Marketing Automation Tools with SalesBlink. Two Way GetLandy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Warm Welcome",
    "slug": "warm-welcome",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c520bcf47b21fc7cb9467d4a6bf5a9b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Warm Welcome helps you improve your business relationships by leveraging video at every touchpoint. You can create video business cards, site bubbles and send video emails.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Warm Welcome Integration With Cold Email Sales Tool",
    "h1": "Integrate Warm Welcome with SalesBlink",
    "metaDescription": "Integrate Warm Welcome and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Warm Welcome Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartrMail",
    "slug": "smartrmail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e78e7f2e8ad78f75ade7c2183432fbb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartrMail is the easiest way to send your customers the products they’re most likely to buy.",
    "category": "Email Newsletters",
    "title": "SmartrMail Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartrMail with SalesBlink",
    "metaDescription": "Integrate SmartrMail and other Email Newsletters Tools with SalesBlink. Two Way SmartrMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vervoe",
    "slug": "vervoe",
    "logo": "https://zapier-images.imgix.net/storage/developer/0f3433047dbd8d584d84d8bb89b3c0f7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vervoe's AI-powered skill testing platform automatically recommends the strongest candidates to employers based purely on their performance in 'Talent Trials,' saving time and letting top candidates shine through sooner.",
    "category": "HR Talent & Recruitment",
    "title": "Vervoe Integration With Cold Email Sales Tool",
    "h1": "Integrate Vervoe with SalesBlink",
    "metaDescription": "Integrate Vervoe and other HR Talent & Recruitment Tools with SalesBlink. Two Way Vervoe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OHWO",
    "slug": "ohwo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0cf222a48e8f3b59ee29dc37362f2014.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OHWO is an email service provider exclusively designed for ecommerce based businesses.",
    "category": "Email",
    "title": "OHWO Integration With Cold Email Sales Tool",
    "h1": "Integrate OHWO with SalesBlink",
    "metaDescription": "Integrate OHWO and other Email Tools with SalesBlink. Two Way OHWO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeskTime",
    "slug": "desktime",
    "logo": "https://zapier-images.imgix.net/storage/developer/cc614e78ad162dc209fe0e9b08ab36d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeskTime is automated time tracking software.",
    "category": "Time Tracking Software",
    "title": "DeskTime Integration With Cold Email Sales Tool",
    "h1": "Integrate DeskTime with SalesBlink",
    "metaDescription": "Integrate DeskTime and other Time Tracking Software Tools with SalesBlink. Two Way DeskTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elevated CRM",
    "slug": "elevated-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be98b13e0b909271b2f6a00ae2a754de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elevated CRM is a contact management tool that manages your contacts presence on the internet.",
    "category": "Contact Management",
    "title": "Elevated CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Elevated CRM with SalesBlink",
    "metaDescription": "Integrate Elevated CRM and other Contact Management Tools with SalesBlink. Two Way Elevated CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workzone",
    "slug": "workzone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57e03104c556bfc99fc05d3a0df266ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workzone is project management software with a careful balance of functionality and ease-of-use to make it useful and accessible to diverse teams.",
    "category": "Project Management",
    "title": "Workzone Integration With Cold Email Sales Tool",
    "h1": "Integrate Workzone with SalesBlink",
    "metaDescription": "Integrate Workzone and other Project Management Tools with SalesBlink. Two Way Workzone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartFile",
    "slug": "smartfile",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b04d459b233c3bbbddacd35756df722.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Secure File Sharing & Transfer Solutions for Business and Enterprise",
    "category": "File Management & Storage",
    "title": "SmartFile Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartFile with SalesBlink",
    "metaDescription": "Integrate SmartFile and other File Management & Storage Tools with SalesBlink. Two Way SmartFile Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iPost",
    "slug": "ipost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9feb0f8bf606d0b635be181438fa6a7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iPost is an advanced Email Service Provider (ESP) built for the Digital Marketer’s growing demands for data and hyper-personalization.",
    "category": "Email Newsletters",
    "title": "iPost Integration With Cold Email Sales Tool",
    "h1": "Integrate iPost with SalesBlink",
    "metaDescription": "Integrate iPost and other Email Newsletters Tools with SalesBlink. Two Way iPost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Poplar",
    "slug": "poplar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e03f20c6eb08fc60f6afd37ff2e86aad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Poplar is a web platform for programmatically print and mail.",
    "category": "Marketing",
    "title": "Poplar Integration With Cold Email Sales Tool",
    "h1": "Integrate Poplar with SalesBlink",
    "metaDescription": "Integrate Poplar and other Marketing Tools with SalesBlink. Two Way Poplar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Proximity",
    "slug": "proximity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/940c182fda7520a5fb4b24ee22b38971.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create a seamless experience with integrated reservations, meeting room management, building access, Wi-Fi control and guest management.",
    "category": "Product Management",
    "title": "Proximity Integration With Cold Email Sales Tool",
    "h1": "Integrate Proximity with SalesBlink",
    "metaDescription": "Integrate Proximity and other Product Management Tools with SalesBlink. Two Way Proximity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Harbour",
    "slug": "harbour",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc514811c666ba7e298bece012ed40d6_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Harbour is a fast and modern e-signature and contract management platform that unlocks the creator economy. We turn agreements into fast, branded, mobile-friendly links and QR codes for seamless e-signatures.",
    "category": "Signatures",
    "title": "Harbour Integration With Cold Email Sales Tool",
    "h1": "Integrate Harbour with SalesBlink",
    "metaDescription": "Integrate Harbour and other Signatures Tools with SalesBlink. Two Way Harbour Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teem",
    "slug": "teem",
    "logo": "https://zapier-images.imgix.net/storage/developer/ac9a306b4909069ca166d1508fa6c716.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teem is a cloud-based platform that makes it easy for your employees to meet and book conference rooms, to efficiently manage workspaces and meeting resources, and to measure and analyze your company’s meeting and collaboration behavior.",
    "category": "Scheduling & Booking",
    "title": "Teem Integration With Cold Email Sales Tool",
    "h1": "Integrate Teem with SalesBlink",
    "metaDescription": "Integrate Teem and other Scheduling & Booking Tools with SalesBlink. Two Way Teem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Otixo",
    "slug": "otixo",
    "logo": "https://zapier-images.imgix.net/storage/developer/c557915c8345a3dbb70aace726568bd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.",
    "category": "Team Collaboration",
    "title": "Otixo Integration With Cold Email Sales Tool",
    "h1": "Integrate Otixo with SalesBlink",
    "metaDescription": "Integrate Otixo and other Team Collaboration Tools with SalesBlink. Two Way Otixo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fleep",
    "slug": "fleep",
    "logo": "https://zapier-images.imgix.net/storage/developer/171084e9dcb64092c6cea8f9c1ef6093.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fleep is your own business messenger. Leave email behind and manage all conversations with your team, partners and clients in Fleep. If some of them are not Fleep users yet, you can include them in the conversation with their email address and they will receive all the messages and files as normal emails.",
    "category": "Team Chat",
    "title": "Fleep Integration With Cold Email Sales Tool",
    "h1": "Integrate Fleep with SalesBlink",
    "metaDescription": "Integrate Fleep and other Team Chat Tools with SalesBlink. Two Way Fleep Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CallWidget",
    "slug": "callwidget",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61a9cac16e9cd72781f9722215181ef3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallWidget is an AI powered lead generation software designed to generate more prospects from your website.",
    "category": "Marketing Automation",
    "title": "CallWidget Integration With Cold Email Sales Tool",
    "h1": "Integrate CallWidget with SalesBlink",
    "metaDescription": "Integrate CallWidget and other Marketing Automation Tools with SalesBlink. Two Way CallWidget Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BrandMentions",
    "slug": "brandmentions",
    "logo": "https://zapier-images.imgix.net/storage/developer/256f9b3a9d6ec5584e1db7a3c3ddc058.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrandMentions is a monitoring tool that helps you find every relevant mention about anyone or anything in a heartbeat.",
    "category": "Marketing Automation",
    "title": "BrandMentions Integration With Cold Email Sales Tool",
    "h1": "Integrate BrandMentions with SalesBlink",
    "metaDescription": "Integrate BrandMentions and other Marketing Automation Tools with SalesBlink. Two Way BrandMentions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Karlia",
    "slug": "karlia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/524f37f2119a45da64357cd9097af15d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Karlia is the first CRM software to integrate your complete mailboxes with tracking, scheduled mailings, templates and predefined responses. Be twice as productive !",
    "category": "CRM (Customer Relationship Management)",
    "title": "Karlia Integration With Cold Email Sales Tool",
    "h1": "Integrate Karlia with SalesBlink",
    "metaDescription": "Integrate Karlia and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Karlia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NterNow",
    "slug": "nternow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/896773cd7427ee554af28c83d76334f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NterNow provides patented On-Demand Access Service for locked vacant properties gives “drive-buyers” and tenants the convenience of instant, independent property touring, yet keeps the property secure.",
    "category": "Internet of Things",
    "title": "NterNow Integration With Cold Email Sales Tool",
    "h1": "Integrate NterNow with SalesBlink",
    "metaDescription": "Integrate NterNow and other Internet of Things Tools with SalesBlink. Two Way NterNow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iNCOM USA",
    "slug": "incom-usa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/537957aac612517dbe7110ab9b391e2b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iNCOM is a real estate website and marketing solution including all the tools you need - Lead Generation, Cultivation, IDX Website, CRM, and marketing services.",
    "category": "CRM (Customer Relationship Management)",
    "title": "iNCOM USA Integration With Cold Email Sales Tool",
    "h1": "Integrate iNCOM USA with SalesBlink",
    "metaDescription": "Integrate iNCOM USA and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way iNCOM USA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tencent Docs",
    "slug": "tencent-docs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49246b8cdf3e00de9b9e5527dfd97dd8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tencent Docs is an online document that can be coordinated by many people. It can edit Word, Excel and PPT documents at the same time and save them in real time in the cloud.",
    "category": "Documents",
    "title": "Tencent Docs Integration With Cold Email Sales Tool",
    "h1": "Integrate Tencent Docs with SalesBlink",
    "metaDescription": "Integrate Tencent Docs and other Documents Tools with SalesBlink. Two Way Tencent Docs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BHN Rewards (FKA Rybbon)",
    "slug": "bhnrewards",
    "logo": "https://zapier-images.imgix.net/storage/services/e6215c8edf5877849debb10e3c094fbd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital rewarding made easy. Send international e-gift cards from top brands like Amazon, Visa & MasterCard prepaid cards, and e-donations.",
    "category": "Marketing",
    "title": "BHN Rewards (FKA Rybbon) Integration With Cold Email Sales Tool",
    "h1": "Integrate BHN Rewards (FKA Rybbon) with SalesBlink",
    "metaDescription": "Integrate BHN Rewards (FKA Rybbon) and other Marketing Tools with SalesBlink. Two Way BHN Rewards (FKA Rybbon) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Run my Accounts",
    "slug": "run-my-accounts",
    "logo": "https://zapier-images.imgix.net/storage/developer/176581fd0ccfc9d700db579fd9def8a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bookkeeping service for Swiss SME. Scan your documents and you are done.",
    "category": "Accounting",
    "title": "Run my Accounts Integration With Cold Email Sales Tool",
    "h1": "Integrate Run my Accounts with SalesBlink",
    "metaDescription": "Integrate Run my Accounts and other Accounting Tools with SalesBlink. Two Way Run my Accounts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cutt.ly",
    "slug": "cuttly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18d3830dc66ea3e503d615627cb5b75f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cutt.ly is a URL shortener with advanced links tracking & API. Link Management Platform. Simplify, track & manage your links like a pro.",
    "category": "URL Shortener",
    "title": "Cutt.ly Integration With Cold Email Sales Tool",
    "h1": "Integrate Cutt.ly with SalesBlink",
    "metaDescription": "Integrate Cutt.ly and other URL Shortener Tools with SalesBlink. Two Way Cutt.ly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadBooker",
    "slug": "leadbooker",
    "logo": "https://zapier-images.imgix.net/storage/services/cd74f8c24112e95def6cba80d6790c5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadBooker is an advanced version of 30 years of lead managing knowledge.",
    "category": "Scheduling & Booking",
    "title": "LeadBooker Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadBooker with SalesBlink",
    "metaDescription": "Integrate LeadBooker and other Scheduling & Booking Tools with SalesBlink. Two Way LeadBooker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MAILINGWORK",
    "slug": "mailingwork",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f77652adcadf095db501e62b9958fc61.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MAILINGWORK is your professional platform for marketing automation, email marketing & lead management.",
    "category": "eCommerce",
    "title": "MAILINGWORK Integration With Cold Email Sales Tool",
    "h1": "Integrate MAILINGWORK with SalesBlink",
    "metaDescription": "Integrate MAILINGWORK and other eCommerce Tools with SalesBlink. Two Way MAILINGWORK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReSci",
    "slug": "resci",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/12c373c2d47dce74faf32fc988b3408e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Award-winning artificial intelligence that delivers a personalized and engaged customer experience to each individual—at scale.",
    "category": "Marketing Automation",
    "title": "ReSci Integration With Cold Email Sales Tool",
    "h1": "Integrate ReSci with SalesBlink",
    "metaDescription": "Integrate ReSci and other Marketing Automation Tools with SalesBlink. Two Way ReSci Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BL.INK",
    "slug": "bl-ink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ba54b2b8f55c030c35968f75f7b293aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BL.INK is a link shortener that allows you to turn clunky URLs into word-based Smart Links or branded links with BL.INK. Transform your marketing and optimize every link you share.",
    "category": "Social Media Marketing",
    "title": "BL.INK Integration With Cold Email Sales Tool",
    "h1": "Integrate BL.INK with SalesBlink",
    "metaDescription": "Integrate BL.INK and other Social Media Marketing Tools with SalesBlink. Two Way BL.INK Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Turis",
    "slug": "turis",
    "logo": "https://zapier-images.imgix.net/storage/services/551713f7a9e1ad98c6bb2f733c2ca119.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turis is a wholesale B2B eCommerce platform focused on giving your retailers the best ordering experience you can imagine.",
    "category": "eCommerce",
    "title": "Turis Integration With Cold Email Sales Tool",
    "h1": "Integrate Turis with SalesBlink",
    "metaDescription": "Integrate Turis and other eCommerce Tools with SalesBlink. Two Way Turis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deskera",
    "slug": "deskera",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae431420656ce88ef96df007168de124.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deskera is all you need to manage your organization's business and financial requirements.",
    "category": "Accounting",
    "title": "Deskera Integration With Cold Email Sales Tool",
    "h1": "Integrate Deskera with SalesBlink",
    "metaDescription": "Integrate Deskera and other Accounting Tools with SalesBlink. Two Way Deskera Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Fidelizador",
    "slug": "fidelizador",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/886993d28942d479addcf0d803034652_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fidelizador is a professional solution to create, automate and customize emails. Engage your customers and build your brand with advanced email marketing campaigns.",
    "category": "Marketing Automation",
    "title": "Fidelizador Integration With Cold Email Sales Tool",
    "h1": "Integrate Fidelizador with SalesBlink",
    "metaDescription": "Integrate Fidelizador and other Marketing Automation Tools with SalesBlink. Two Way Fidelizador Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS Gateway Hub",
    "slug": "smsgatewayhub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4083038387fadf21bff3570f9809af20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSGATEWAYHUB is an online SMS service provider.",
    "category": "Phone & SMS",
    "title": "SMS Gateway Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Gateway Hub with SalesBlink",
    "metaDescription": "Integrate SMS Gateway Hub and other Phone & SMS Tools with SalesBlink. Two Way SMS Gateway Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BoldDesk",
    "slug": "bolddesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1301701c3f599bbf549705aad9373cbb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BoldDesk is a help desk software for managing your support email, automating repetitive tasks, and improving your customer’s experience.",
    "category": "Customer Support",
    "title": "BoldDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate BoldDesk with SalesBlink",
    "metaDescription": "Integrate BoldDesk and other Customer Support Tools with SalesBlink. Two Way BoldDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lucidchart",
    "slug": "lucidchart",
    "logo": "https://zapier-images.imgix.net/storage/services/5acef5083e43f80b175fef077b150258.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lucidchart is your solution for visual communication and cross-platform collaboration.",
    "category": "Developer Tools",
    "title": "Lucidchart Integration With Cold Email Sales Tool",
    "h1": "Integrate Lucidchart with SalesBlink",
    "metaDescription": "Integrate Lucidchart and other Developer Tools Tools with SalesBlink. Two Way Lucidchart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DoneDone",
    "slug": "donedone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f10c05f9941140394a87a94105ef7e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DoneDone is a simple bug, issue, and task tracker and easy customer support tool -- your single place to manage both projects and customers.",
    "category": "Project Management",
    "title": "DoneDone Integration With Cold Email Sales Tool",
    "h1": "Integrate DoneDone with SalesBlink",
    "metaDescription": "Integrate DoneDone and other Project Management Tools with SalesBlink. Two Way DoneDone Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "OMG Sales Assessments",
    "slug": "omg-sales-assessments",
    "logo": "https://zapier-images.imgix.net/storage/developer/95e294ac599c1c57b61d7dc30e501143.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Objective Management Group's (OMG) Sales Candidate Assessment is a sales-specific screening tool that provides an analysis of a candidate's skills, strengths and capabilities, and recommends whether to move forward with an interview.",
    "category": "Analytics",
    "title": "OMG Sales Assessments Integration With Cold Email Sales Tool",
    "h1": "Integrate OMG Sales Assessments with SalesBlink",
    "metaDescription": "Integrate OMG Sales Assessments and other Analytics Tools with SalesBlink. Two Way OMG Sales Assessments Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Northpass",
    "slug": "northpass",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ac61204d253adb74c5f8cf6aaacbd07.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Northpass is a learning platform that allows you to build powerful learning applications.",
    "category": "Education",
    "title": "Northpass Integration With Cold Email Sales Tool",
    "h1": "Integrate Northpass with SalesBlink",
    "metaDescription": "Integrate Northpass and other Education Tools with SalesBlink. Two Way Northpass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AUTORO",
    "slug": "autoro",
    "logo": "https://zapier-images.imgix.net/storage/services/95740fa8618d96f2858b3a9715d7c463.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AUTORO is a cloud based RPA (Robotic Process Automation). This integration starts an automated workflow of AUTORO projects.",
    "category": "Productivity",
    "title": "AUTORO Integration With Cold Email Sales Tool",
    "h1": "Integrate AUTORO with SalesBlink",
    "metaDescription": "Integrate AUTORO and other Productivity Tools with SalesBlink. Two Way AUTORO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SpotMe",
    "slug": "spotme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fc94015d9c70ad7c4fa4d69053f9947.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "From live to on-demand, SpotMe makes it easy to deliver digital experiences and event apps that your audience loves.",
    "category": "Event Management",
    "title": "SpotMe Integration With Cold Email Sales Tool",
    "h1": "Integrate SpotMe with SalesBlink",
    "metaDescription": "Integrate SpotMe and other Event Management Tools with SalesBlink. Two Way SpotMe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easy Project",
    "slug": "easy-project",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43aa94f8ecc0b4e1aef2562ad8dfc55d_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easy Project is a professional project management software for you, your team and the whole company. Based on WBS, Gantt, Agile and other best PM practices. It is compatible with modules for Resources, Finances, Help Desk and CRM.",
    "category": "Project Management",
    "title": "Easy Project Integration With Cold Email Sales Tool",
    "h1": "Integrate Easy Project with SalesBlink",
    "metaDescription": "Integrate Easy Project and other Project Management Tools with SalesBlink. Two Way Easy Project Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Recras",
    "slug": "recras",
    "logo": "https://zapier-images.imgix.net/storage/developer/c29ca9b657bf3cb70c16880219af0d59.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recras is an administration and booking system for recreation companies.",
    "category": "Scheduling & Booking",
    "title": "Recras Integration With Cold Email Sales Tool",
    "h1": "Integrate Recras with SalesBlink",
    "metaDescription": "Integrate Recras and other Scheduling & Booking Tools with SalesBlink. Two Way Recras Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Respond Flow",
    "slug": "respond-flow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9d07f9559c57a99343dc26d6510904cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Respond Flow enables you to personalize your text marketing campaigns.",
    "category": "Marketing",
    "title": "Respond Flow Integration With Cold Email Sales Tool",
    "h1": "Integrate Respond Flow with SalesBlink",
    "metaDescription": "Integrate Respond Flow and other Marketing Tools with SalesBlink. Two Way Respond Flow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Route4Me",
    "slug": "route4me",
    "logo": "https://zapier-images.imgix.net/storage/developer/86856341553670c03a6ec8b38bfb1257_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Route4Me optimizes where, when, and how your people, vehicles, assets, and routes should work.",
    "category": "eCommerce",
    "title": "Route4Me Integration With Cold Email Sales Tool",
    "h1": "Integrate Route4Me with SalesBlink",
    "metaDescription": "Integrate Route4Me and other eCommerce Tools with SalesBlink. Two Way Route4Me Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Guild",
    "slug": "guild",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d0e59f6e18f724be73d6f88851e42ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Guild is a platform purpose-built for professional groups, networks and communities to connect, communicate and collaborate.",
    "category": "Communication",
    "title": "Guild Integration With Cold Email Sales Tool",
    "h1": "Integrate Guild with SalesBlink",
    "metaDescription": "Integrate Guild and other Communication Tools with SalesBlink. Two Way Guild Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "actiTIME",
    "slug": "actitime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cd6d85eca391d0d7dee406bc2a63c72_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "actiTIME is a time tracking & scope management tool.",
    "category": "Time Tracking Software",
    "title": "actiTIME Integration With Cold Email Sales Tool",
    "h1": "Integrate actiTIME with SalesBlink",
    "metaDescription": "Integrate actiTIME and other Time Tracking Software Tools with SalesBlink. Two Way actiTIME Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MSG91",
    "slug": "msg91",
    "logo": "https://zapier-images.imgix.net/storage/developer/9eda0c0ca85265aed5afcfb669cd9230.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MSG91 is an enterprise communication tool which allows you to send promotional and alert SMS to your clients world wide.",
    "category": "",
    "title": "MSG91 Integration With Cold Email Sales Tool",
    "h1": "Integrate MSG91 with SalesBlink",
    "metaDescription": "Integrate MSG91 and other  Tools with SalesBlink. Two Way MSG91 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Catalog Machine",
    "slug": "catalog-machine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dcbb43659551c20fee444450e1825151.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Catalog Machine is a simple solution for creating and sharing Online and PDF Product Catalogs, Price Lists, Line Sheets and Custom Stores.",
    "category": "Marketing",
    "title": "Catalog Machine Integration With Cold Email Sales Tool",
    "h1": "Integrate Catalog Machine with SalesBlink",
    "metaDescription": "Integrate Catalog Machine and other Marketing Tools with SalesBlink. Two Way Catalog Machine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NoPaperForms",
    "slug": "nopaperforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1c0eef3bc70173c193275b0dae8835e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "One Platform to manage your entire admissions",
    "category": "Sales & CRM",
    "title": "NoPaperForms Integration With Cold Email Sales Tool",
    "h1": "Integrate NoPaperForms with SalesBlink",
    "metaDescription": "Integrate NoPaperForms and other Sales & CRM Tools with SalesBlink. Two Way NoPaperForms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hypercontext",
    "slug": "hypercontext",
    "logo": "https://zapier-images.imgix.net/storage/services/1d9fb5dac9f5bd6f419974a6c2303e62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hypercontext is an app designed to help managers and direct reports have better one-on-ones, team meetings, and discussions.",
    "category": "Team Collaboration",
    "title": "Hypercontext Integration With Cold Email Sales Tool",
    "h1": "Integrate Hypercontext with SalesBlink",
    "metaDescription": "Integrate Hypercontext and other Team Collaboration Tools with SalesBlink. Two Way Hypercontext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sphere",
    "slug": "sphere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95a843d1823b2daeeda23092e94fb649.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sphere is a lifechanging tool for Agents and Teams to manage every aspect of all transactions, To Do lists, contacts, and deadlines.",
    "category": "Contact Management",
    "title": "Sphere Integration With Cold Email Sales Tool",
    "h1": "Integrate Sphere with SalesBlink",
    "metaDescription": "Integrate Sphere and other Contact Management Tools with SalesBlink. Two Way Sphere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Archie",
    "slug": "sparkgrid-da106579",
    "logo": "https://zapier-images.imgix.net/storage/developer/f14edc423b472b7e90e173a720a0c877_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A seamless solution for coworking spaces & flexible offices.Archie provide all tools to manage a shared space from space management to community and events.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Archie Integration With Cold Email Sales Tool",
    "h1": "Integrate Archie with SalesBlink",
    "metaDescription": "Integrate Archie and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Archie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtually",
    "slug": "virtually",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79b9ecc253281c396a61dec76b5e2606_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Virtually is the easiest way to automate the backend for your live training program on Zoom. Schedule live sessions, send reminders, and track attendance from one place.",
    "category": "Education",
    "title": "Virtually Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtually with SalesBlink",
    "metaDescription": "Integrate Virtually and other Education Tools with SalesBlink. Two Way Virtually Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JustClick",
    "slug": "justclick",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/47bb61b34a74be9b75c3e6c62bbf54d7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JustClick is a platform for selling digital products & courses online.",
    "category": "Website Builders",
    "title": "JustClick Integration With Cold Email Sales Tool",
    "h1": "Integrate JustClick with SalesBlink",
    "metaDescription": "Integrate JustClick and other Website Builders Tools with SalesBlink. Two Way JustClick Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Cash Flow Frog",
    "slug": "cash-flow-frog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1df89f196c297897d599dc2c30809440.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cash Flow Frog is a simple yet powerful cash flow forecasting and scenario planning tool.",
    "category": "Accounting",
    "title": "Cash Flow Frog Integration With Cold Email Sales Tool",
    "h1": "Integrate Cash Flow Frog with SalesBlink",
    "metaDescription": "Integrate Cash Flow Frog and other Accounting Tools with SalesBlink. Two Way Cash Flow Frog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Filemail",
    "slug": "filemail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62d43548487b45d3184c4e5feb04dfe2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Filemail is a service for sending and receiving large files",
    "category": "Content & Files",
    "title": "Filemail Integration With Cold Email Sales Tool",
    "h1": "Integrate Filemail with SalesBlink",
    "metaDescription": "Integrate Filemail and other Content & Files Tools with SalesBlink. Two Way Filemail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sellix",
    "slug": "sellix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0f099d18d397c2077562c281f9ae878_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sellix is a powerful e-commerce solution that lets you create a custom store in just a couple of clicks.\nEnable 20+ triggers such as invoices paid, products out of stock or subscriptions created.",
    "category": "eCommerce",
    "title": "Sellix Integration With Cold Email Sales Tool",
    "h1": "Integrate Sellix with SalesBlink",
    "metaDescription": "Integrate Sellix and other eCommerce Tools with SalesBlink. Two Way Sellix Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Docdown",
    "slug": "docdown",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9dfd9a770009e474b64268529446d93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create PDF templates, fill them out with intelligent automation and deliver anywhere with custom workflows.",
    "category": "Documents",
    "title": "Docdown Integration With Cold Email Sales Tool",
    "h1": "Integrate Docdown with SalesBlink",
    "metaDescription": "Integrate Docdown and other Documents Tools with SalesBlink. Two Way Docdown Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MillionVerifier",
    "slug": "millionverifier",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2a6c07a9ce7737a2f0ccfa8452bcb15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MillionVerifier is an email verifier service and API",
    "category": "Email",
    "title": "MillionVerifier Integration With Cold Email Sales Tool",
    "h1": "Integrate MillionVerifier with SalesBlink",
    "metaDescription": "Integrate MillionVerifier and other Email Tools with SalesBlink. Two Way MillionVerifier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Captego",
    "slug": "captego",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cf9c093835d28c0f0d0c8cb7cd2b881.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Captego is a platform with which you can easily create Inspections and reports.",
    "category": "Project Management",
    "title": "Captego Integration With Cold Email Sales Tool",
    "h1": "Integrate Captego with SalesBlink",
    "metaDescription": "Integrate Captego and other Project Management Tools with SalesBlink. Two Way Captego Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easypromos",
    "slug": "easypromos",
    "logo": "https://zapier-images.imgix.net/storage/services/0684631e2e51293eff8679464dda3238.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easypromos is an online platform to create and manage all sorts of digital promotions, sweepstakes and contests.",
    "category": "Forms & Surveys",
    "title": "Easypromos Integration With Cold Email Sales Tool",
    "h1": "Integrate Easypromos with SalesBlink",
    "metaDescription": "Integrate Easypromos and other Forms & Surveys Tools with SalesBlink. Two Way Easypromos Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Glow",
    "slug": "glow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f3e7fe3539e7d35df30a4baf90d623a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Glow is the easiest way to build your podcast membership program.",
    "category": "eCommerce",
    "title": "Glow Integration With Cold Email Sales Tool",
    "h1": "Integrate Glow with SalesBlink",
    "metaDescription": "Integrate Glow and other eCommerce Tools with SalesBlink. Two Way Glow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Optmyzr",
    "slug": "optmyzr",
    "logo": "https://zapier-images.imgix.net/storage/services/c40b6d0952668e4fb605d2b68accae95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Optmyzr is the most advanced and world's leading PPC Optmization Tool.",
    "category": "Marketing Automation",
    "title": "Optmyzr Integration With Cold Email Sales Tool",
    "h1": "Integrate Optmyzr with SalesBlink",
    "metaDescription": "Integrate Optmyzr and other Marketing Automation Tools with SalesBlink. Two Way Optmyzr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cheddar",
    "slug": "cheddar",
    "logo": "https://zapier-images.imgix.net/storage/developer/c10305a0b0b32579a7e446fb4b2fd8e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recurring billing, SaaS subscription management, automated communication, and market intelligence.",
    "category": "Payment Processing",
    "title": "Cheddar Integration With Cold Email Sales Tool",
    "h1": "Integrate Cheddar with SalesBlink",
    "metaDescription": "Integrate Cheddar and other Payment Processing Tools with SalesBlink. Two Way Cheddar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickTapSurvey",
    "slug": "quicktapsurvey",
    "logo": "https://zapier-images.imgix.net/storage/developer/18f0a0f430ce0e4bcc5d970156367d38_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickTapSurvey lets you capture data in person using tablets and phones - no internet needed. The app is used for lead capture, feedback, research and more.",
    "category": "Forms & Surveys",
    "title": "QuickTapSurvey Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickTapSurvey with SalesBlink",
    "metaDescription": "Integrate QuickTapSurvey and other Forms & Surveys Tools with SalesBlink. Two Way QuickTapSurvey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Withfriends",
    "slug": "withfriends",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/432427947b90003c46c78a9134ca5221.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Offer memberships for your local business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Withfriends Integration With Cold Email Sales Tool",
    "h1": "Integrate Withfriends with SalesBlink",
    "metaDescription": "Integrate Withfriends and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Withfriends Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zamzar",
    "slug": "zamzar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e3af76574a9b6b1fb0c37c8d985253f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zamzar is a file conversion tool supporting over 1,200 different conversion types including all the popular formats (Document, Images, Audio, Video).",
    "category": "File Management & Storage",
    "title": "Zamzar Integration With Cold Email Sales Tool",
    "h1": "Integrate Zamzar with SalesBlink",
    "metaDescription": "Integrate Zamzar and other File Management & Storage Tools with SalesBlink. Two Way Zamzar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Forecast",
    "slug": "forecast",
    "logo": "https://zapier-images.imgix.net/storage/services/b31e0360fcecc353e1b9e86b698b9601_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Forecast is a full-suite business platform that enables professional service organizations to run efficiently and predictably in one AI-powered software.",
    "category": "Project Management",
    "title": "Forecast Integration With Cold Email Sales Tool",
    "h1": "Integrate Forecast with SalesBlink",
    "metaDescription": "Integrate Forecast and other Project Management Tools with SalesBlink. Two Way Forecast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Replyify",
    "slug": "replyify",
    "logo": "https://zapier-images.imgix.net/storage/developer/90a001767d4284376132544f36787701.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Replyify is a B2B sales platform to automate personalized cold emails & follow-ups. Generate a consistent pipeline of leads sent directly to your inbox and CRM.",
    "category": "Marketing Automation",
    "title": "Replyify Integration With Cold Email Sales Tool",
    "h1": "Integrate Replyify with SalesBlink",
    "metaDescription": "Integrate Replyify and other Marketing Automation Tools with SalesBlink. Two Way Replyify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crowdfire",
    "slug": "crowdfire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3faf56437f3350fce98a199af517909e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crowdfire is a Social Media management tool used by brands, businesses, agencies, and individuals all over the world.",
    "category": "Social Media Marketing",
    "title": "Crowdfire Integration With Cold Email Sales Tool",
    "h1": "Integrate Crowdfire with SalesBlink",
    "metaDescription": "Integrate Crowdfire and other Social Media Marketing Tools with SalesBlink. Two Way Crowdfire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "College Aid Pro",
    "slug": "college-aid-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e23ed53f9d4d557ded0945c386bd8d2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "College Aid Pro is a college planning software for financial advisors to assist college-bound families.",
    "category": "CRM (Customer Relationship Management)",
    "title": "College Aid Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate College Aid Pro with SalesBlink",
    "metaDescription": "Integrate College Aid Pro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way College Aid Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Arrivala",
    "slug": "arrivala",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f800aa22bee43c1f902a3c303ab75f17.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Arrivala helps businesses automate the review collection process to improve their business reputation online.",
    "category": "Reviews",
    "title": "Arrivala Integration With Cold Email Sales Tool",
    "h1": "Integrate Arrivala with SalesBlink",
    "metaDescription": "Integrate Arrivala and other Reviews Tools with SalesBlink. Two Way Arrivala Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Uberall",
    "slug": "uberall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95ee330e066260f56bd68a94f055d4a9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uberall enables the world's brands and businesses to deliver a relevant and memorable 'Near Me' Brand Experience at every step of today's customer journey!",
    "category": "Marketing Automation",
    "title": "Uberall Integration With Cold Email Sales Tool",
    "h1": "Integrate Uberall with SalesBlink",
    "metaDescription": "Integrate Uberall and other Marketing Automation Tools with SalesBlink. Two Way Uberall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shuffle by Elify",
    "slug": "shuffle-by-elify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/67bd6183d1346973d0ad34cea0e2bb0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shuffle helps you elevate your brand, build your network and keep track of all your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Shuffle by Elify Integration With Cold Email Sales Tool",
    "h1": "Integrate Shuffle by Elify with SalesBlink",
    "metaDescription": "Integrate Shuffle by Elify and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Shuffle by Elify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fieldfolio",
    "slug": "fieldfolio",
    "logo": "https://zapier-images.imgix.net/storage/services/83f40501008e096b7eda02b6e0bf27c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fieldfolio is an Australian wholesale marketplace for B2B ecommerce.",
    "category": "eCommerce",
    "title": "Fieldfolio Integration With Cold Email Sales Tool",
    "h1": "Integrate Fieldfolio with SalesBlink",
    "metaDescription": "Integrate Fieldfolio and other eCommerce Tools with SalesBlink. Two Way Fieldfolio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SubHub",
    "slug": "subhub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/69105a38e11926c360c8559994c6f96e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SubHub is a website platform for building membership websites and generating revenue from subscriptions, membership, e-commerce and other options.",
    "category": "Website & App Building",
    "title": "SubHub Integration With Cold Email Sales Tool",
    "h1": "Integrate SubHub with SalesBlink",
    "metaDescription": "Integrate SubHub and other Website & App Building Tools with SalesBlink. Two Way SubHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Webshipper",
    "slug": "webshipper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46337569374880473da82c8ff67a25d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webshipper is a shipping platform for all your shipping needs. Webshipper can help you automate and optimize your shipping flow.",
    "category": "eCommerce",
    "title": "Webshipper Integration With Cold Email Sales Tool",
    "h1": "Integrate Webshipper with SalesBlink",
    "metaDescription": "Integrate Webshipper and other eCommerce Tools with SalesBlink. Two Way Webshipper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Click",
    "slug": "click",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68eda52459debab3249e4a1122fbcf3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Click is a platform where anyone can create apps without code.",
    "category": "App Builder",
    "title": "Click Integration With Cold Email Sales Tool",
    "h1": "Integrate Click with SalesBlink",
    "metaDescription": "Integrate Click and other App Builder Tools with SalesBlink. Two Way Click Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartTask",
    "slug": "smarttask",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7769cb8b68497050d31c5c8541dc45b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartTask is an online work management tool helping teams streamline their communication and workflows.",
    "category": "Productivity",
    "title": "SmartTask Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartTask with SalesBlink",
    "metaDescription": "Integrate SmartTask and other Productivity Tools with SalesBlink. Two Way SmartTask Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "EDlumina",
    "slug": "edlumina",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/857f7ed48fb7f36c274ac5e27c5bb4fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The EDlumina line of educational technology solutions provides colleges and institutions alike with a full-service online platform.",
    "category": "Education",
    "title": "EDlumina Integration With Cold Email Sales Tool",
    "h1": "Integrate EDlumina with SalesBlink",
    "metaDescription": "Integrate EDlumina and other Education Tools with SalesBlink. Two Way EDlumina Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Airbrake",
    "slug": "airbrake",
    "logo": "https://zapier-images.imgix.net/storage/developer/14de7e5c58f94f570e372d48d903eec6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Airbrake collects errors generated by other applications, and aggregates the results for review.",
    "category": "Developer Tools",
    "title": "Airbrake Integration With Cold Email Sales Tool",
    "h1": "Integrate Airbrake with SalesBlink",
    "metaDescription": "Integrate Airbrake and other Developer Tools Tools with SalesBlink. Two Way Airbrake Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Podcast.co",
    "slug": "podcastco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/664069d5227bb03945db6345b135321f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Podcast.co is an all-in-one platform designed to make podcasting as simple as possible for businesses and content creators. Launch, distribute, and grow your podcast with ease.",
    "category": "Video & Audio",
    "title": "Podcast.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Podcast.co with SalesBlink",
    "metaDescription": "Integrate Podcast.co and other Video & Audio Tools with SalesBlink. Two Way Podcast.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reputation Rooster",
    "slug": "reputation-rooster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b262722fc30568d958149489df38b8d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reputation Rooster make it easy for customers to leave the 5-star reviews you deserve.",
    "category": "Marketing Automation",
    "title": "Reputation Rooster Integration With Cold Email Sales Tool",
    "h1": "Integrate Reputation Rooster with SalesBlink",
    "metaDescription": "Integrate Reputation Rooster and other Marketing Automation Tools with SalesBlink. Two Way Reputation Rooster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeeg",
    "slug": "zeeg",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3286929039e0f9ad36ae94a3a19eee40_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeeg is a smart scheduling assistant that enables everyone to connect and meet at the best time by creating a secure, simple, and beautiful scheduling experience.",
    "category": "Scheduling & Booking",
    "title": "Zeeg Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeeg with SalesBlink",
    "metaDescription": "Integrate Zeeg and other Scheduling & Booking Tools with SalesBlink. Two Way Zeeg Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WP Ultimo",
    "slug": "wp-ultimo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ab8a35ba22a746e76b3ed6cfdb5b68e7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WP Ultimo is a WordPress Multisite plugin that allows you to create a network of turnkey sites with ease.",
    "category": "Website & App Building",
    "title": "WP Ultimo Integration With Cold Email Sales Tool",
    "h1": "Integrate WP Ultimo with SalesBlink",
    "metaDescription": "Integrate WP Ultimo and other Website & App Building Tools with SalesBlink. Two Way WP Ultimo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudBlue Connect",
    "slug": "cloudblue-connect",
    "logo": "https://zapier-images.imgix.net/storage/services/f58a8d9954cbafe630bd301f17d622fd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudBlue Connect is a supply automation platform that manages your products and services, contracts, ordering and fulfillment, usage and subscriptions.",
    "category": "eCommerce",
    "title": "CloudBlue Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudBlue Connect with SalesBlink",
    "metaDescription": "Integrate CloudBlue Connect and other eCommerce Tools with SalesBlink. Two Way CloudBlue Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Feature Upvote",
    "slug": "feature-upvote",
    "logo": "https://zapier-images.imgix.net/storage/developer/6b63b2b48342d60c49c80827761a2ed9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feature Upvote is a product management tool to collect product ideas and identify development priorities.",
    "category": "Product Management",
    "title": "Feature Upvote Integration With Cold Email Sales Tool",
    "h1": "Integrate Feature Upvote with SalesBlink",
    "metaDescription": "Integrate Feature Upvote and other Product Management Tools with SalesBlink. Two Way Feature Upvote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Peggy Pay",
    "slug": "peggy-pay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02ba0dbdce5904719e155c20b67735d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Peggy Pay offers an amazingly comprehensive payment page builder to build very effective and beautiful payment pages, surveys or any kind of webform you need.",
    "category": "eCommerce",
    "title": "Peggy Pay Integration With Cold Email Sales Tool",
    "h1": "Integrate Peggy Pay with SalesBlink",
    "metaDescription": "Integrate Peggy Pay and other eCommerce Tools with SalesBlink. Two Way Peggy Pay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Meisterplan",
    "slug": "meisterplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6aeeb153abe344e0a7e8d207defb4c2b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meisterplan is a project portfolio management tool that focuses on resource management at the portfolio level and helps you make plans that work.",
    "category": "Project Management",
    "title": "Meisterplan Integration With Cold Email Sales Tool",
    "h1": "Integrate Meisterplan with SalesBlink",
    "metaDescription": "Integrate Meisterplan and other Project Management Tools with SalesBlink. Two Way Meisterplan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FreeScout",
    "slug": "freescout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5775a6ae613903e12d88be21354610c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FreeScout is the super lightweight free open source helpdesk and shared inbox built with PHP.",
    "category": "Customer Support",
    "title": "FreeScout Integration With Cold Email Sales Tool",
    "h1": "Integrate FreeScout with SalesBlink",
    "metaDescription": "Integrate FreeScout and other Customer Support Tools with SalesBlink. Two Way FreeScout Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CustomerTrax Handle",
    "slug": "customertrax-handle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/88a0736439efb540eb545618334f5803.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handle 2.0™ is a configurable CRM that shrinks the complexity of your processes",
    "category": "CRM (Customer Relationship Management)",
    "title": "CustomerTrax Handle Integration With Cold Email Sales Tool",
    "h1": "Integrate CustomerTrax Handle with SalesBlink",
    "metaDescription": "Integrate CustomerTrax Handle and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CustomerTrax Handle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Goodbits",
    "slug": "goodbits",
    "logo": "https://zapier-images.imgix.net/storage/developer/533795a24d3ee0c4f7a866ab8bd364a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Goodbits is a service that allows you to save articles from the web and build stunning emails in minutes. Perfect for those that want to build a roundup email newsletters in minutes.",
    "category": "Marketing Automation",
    "title": "Goodbits Integration With Cold Email Sales Tool",
    "h1": "Integrate Goodbits with SalesBlink",
    "metaDescription": "Integrate Goodbits and other Marketing Automation Tools with SalesBlink. Two Way Goodbits Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stikkum",
    "slug": "stikkum",
    "logo": "https://zapier-images.imgix.net/storage/services/f468fd3a2f5eca36ded9a6004b1c16c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stikkum is a platform for the mortgage industry with retention alerts and automation that continuously monitors a mortgage broker’s client base.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Stikkum Integration With Cold Email Sales Tool",
    "h1": "Integrate Stikkum with SalesBlink",
    "metaDescription": "Integrate Stikkum and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Stikkum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Backendless",
    "slug": "backendless-cloned-da66078",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb61a5aac4f3a76bb70a10caf92a1cd3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Backendless is a Visual Application Development Platform. With Backendless you can streamline the process of developing highly scalable mobile and web applications by using our universal backend system and very intuitive UI development interface.",
    "category": "App Builder",
    "title": "Backendless Integration With Cold Email Sales Tool",
    "h1": "Integrate Backendless with SalesBlink",
    "metaDescription": "Integrate Backendless and other App Builder Tools with SalesBlink. Two Way Backendless Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Rejoiner",
    "slug": "rejoiner",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4cb0250ef85f21fbacc50f37b1c25ac2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rejoiner is the only email marketing solution that's accountable for your entire email program. Merchants use it to send automated and triggered lifecycle email campaigns (cart & browse abandonment, welcome series, win back and more) to the right person at the right time.",
    "category": "Marketing",
    "title": "Rejoiner Integration With Cold Email Sales Tool",
    "h1": "Integrate Rejoiner with SalesBlink",
    "metaDescription": "Integrate Rejoiner and other Marketing Tools with SalesBlink. Two Way Rejoiner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ziflow",
    "slug": "ziflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c0942bf8ef56e8e1017c7838f020379.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feedback and approval—but faster. Ziflow optimizes your creative workflow to get from design to decision more efficiently than ever before.",
    "category": "Team Collaboration",
    "title": "Ziflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Ziflow with SalesBlink",
    "metaDescription": "Integrate Ziflow and other Team Collaboration Tools with SalesBlink. Two Way Ziflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intruder",
    "slug": "intruder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16ebe75c3cc5495ca1a50d92aa876e60.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intruder is a vulnerability scanner that finds the cyber security weaknesses in your computer systems, before the hackers do.",
    "category": "Security & Identity Tools",
    "title": "Intruder Integration With Cold Email Sales Tool",
    "h1": "Integrate Intruder with SalesBlink",
    "metaDescription": "Integrate Intruder and other Security & Identity Tools Tools with SalesBlink. Two Way Intruder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FoodStorm",
    "slug": "foodstorm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c218407d9341d176988ad77b6c80d66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FoodStorm is a catering software system that will automate your processes, generate more sales and delight your customers",
    "category": "eCommerce",
    "title": "FoodStorm Integration With Cold Email Sales Tool",
    "h1": "Integrate FoodStorm with SalesBlink",
    "metaDescription": "Integrate FoodStorm and other eCommerce Tools with SalesBlink. Two Way FoodStorm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrustRadius",
    "slug": "trustradius",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6db2f7094cdb8179f6e2c351028ade5b_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrustRadius is the most trusted review site for business technology. We help vendors harness and scale the true voice of their customers.",
    "category": "Event Management",
    "title": "TrustRadius Integration With Cold Email Sales Tool",
    "h1": "Integrate TrustRadius with SalesBlink",
    "metaDescription": "Integrate TrustRadius and other Event Management Tools with SalesBlink. Two Way TrustRadius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeplin",
    "slug": "zeplin",
    "logo": "https://zapier-images.imgix.net/storage/services/2ea115b20f0483d23ad44f8b23d794a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeplin is an organized workspace to publish designs where the entire team can collaborate to ship beautiful products together.",
    "category": "Team Collaboration",
    "title": "Zeplin Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeplin with SalesBlink",
    "metaDescription": "Integrate Zeplin and other Team Collaboration Tools with SalesBlink. Two Way Zeplin Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Zahara",
    "slug": "zahara-da83354",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b257b5205c4fc014d19ae00481840c2f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zahara is a complete purchase management platform enabling paperless approvals for supplier invoices and purchase requisition.",
    "category": "Proposal & Invoice Management",
    "title": "Zahara Integration With Cold Email Sales Tool",
    "h1": "Integrate Zahara with SalesBlink",
    "metaDescription": "Integrate Zahara and other Proposal & Invoice Management Tools with SalesBlink. Two Way Zahara Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyVR",
    "slug": "myvr",
    "logo": "https://zapier-images.imgix.net/storage/services/139a3f387f85a1e682b6efbe37d30cee_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Everything you need to manage and market your vacation rental business.",
    "category": "Scheduling & Booking",
    "title": "MyVR Integration With Cold Email Sales Tool",
    "h1": "Integrate MyVR with SalesBlink",
    "metaDescription": "Integrate MyVR and other Scheduling & Booking Tools with SalesBlink. Two Way MyVR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Harpoon",
    "slug": "harpoon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/079735a4b6f1e2b1e3b05f241ce8811d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Harpoon is a financial planning, scheduling, time-tracking, invoicing, and budgeting platform for professional freelancers, studios, and agencies.",
    "category": "Time Tracking Software",
    "title": "Harpoon Integration With Cold Email Sales Tool",
    "h1": "Integrate Harpoon with SalesBlink",
    "metaDescription": "Integrate Harpoon and other Time Tracking Software Tools with SalesBlink. Two Way Harpoon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuoteCloud",
    "slug": "quotecloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/793f2ed142e773b2c97de8c7c14ef0a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuoteCloud is a proposal building platform that guarantees your sales team are communicating in a: consistent, compliant, accurate and efficient way, ensuring respectively that marketing, legal, product and sales departments are happy !",
    "category": "Proposal & Invoice Management",
    "title": "QuoteCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate QuoteCloud with SalesBlink",
    "metaDescription": "Integrate QuoteCloud and other Proposal & Invoice Management Tools with SalesBlink. Two Way QuoteCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hire Aiva",
    "slug": "hire-aiva",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8806f177389b6b5d006c46ee8f73d38a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aiva helps real estate agents convert online leads",
    "category": "HR Talent & Recruitment",
    "title": "Hire Aiva Integration With Cold Email Sales Tool",
    "h1": "Integrate Hire Aiva with SalesBlink",
    "metaDescription": "Integrate Hire Aiva and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hire Aiva Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelpSpot",
    "slug": "helpspot",
    "logo": "https://zapier-images.imgix.net/storage/developer/551a9aef72f36157e66a4d96bcca9828_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelpSpot is a web based help desk software tool, making it easy for teams of all sizes to provide amazing customer support.",
    "category": "Customer Support",
    "title": "HelpSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate HelpSpot with SalesBlink",
    "metaDescription": "Integrate HelpSpot and other Customer Support Tools with SalesBlink. Two Way HelpSpot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ISMS.online",
    "slug": "ismsonline",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/31f20ec2e50ce2f35fa70ee7850513c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ISMS.online is a cloud-based compliance management system for your information security, privacy and other compliance work.",
    "category": "Security & Identity Tools",
    "title": "ISMS.online Integration With Cold Email Sales Tool",
    "h1": "Integrate ISMS.online with SalesBlink",
    "metaDescription": "Integrate ISMS.online and other Security & Identity Tools Tools with SalesBlink. Two Way ISMS.online Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Analytic Call Tracking",
    "slug": "analytic-call-tracking",
    "logo": "https://zapier-images.imgix.net/storage/developer/1ab0445874f32ba08b06b357e0967f31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Analytic Call Tracking (ACT) helps route and track calls with full analytic reporting.",
    "category": "Call Tracking",
    "title": "Analytic Call Tracking Integration With Cold Email Sales Tool",
    "h1": "Integrate Analytic Call Tracking with SalesBlink",
    "metaDescription": "Integrate Analytic Call Tracking and other Call Tracking Tools with SalesBlink. Two Way Analytic Call Tracking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Handwrite",
    "slug": "handwrite",
    "logo": "https://zapier-images.imgix.net/storage/services/38ea47703484463880dd5a8bbc716e58.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handwrite pens your message on premium stationery of your choice and sends it straight to your recipient. Our robots use real pen and paper to write in your actual handwriting.",
    "category": "Marketing Automation",
    "title": "Handwrite Integration With Cold Email Sales Tool",
    "h1": "Integrate Handwrite with SalesBlink",
    "metaDescription": "Integrate Handwrite and other Marketing Automation Tools with SalesBlink. Two Way Handwrite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reachdesk",
    "slug": "reachdesk",
    "logo": "https://zapier-images.imgix.net/storage/services/168ba688f97a745c221c7536fcc1d1a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reachdesk automates personalized direct mail and e-gifting.",
    "category": "Sales & CRM",
    "title": "Reachdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Reachdesk with SalesBlink",
    "metaDescription": "Integrate Reachdesk and other Sales & CRM Tools with SalesBlink. Two Way Reachdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Participate",
    "slug": "participate",
    "logo": "https://zapier-images.imgix.net/storage/services/6f6759c6f97b8bfbbfde53389a5fb9a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Participate is a team collaboration app that combines your favorite parts of social media and online learning in Communities of Practice (CoPs) that inspire professional learning, connection and growth.",
    "category": "Team Collaboration",
    "title": "Participate Integration With Cold Email Sales Tool",
    "h1": "Integrate Participate with SalesBlink",
    "metaDescription": "Integrate Participate and other Team Collaboration Tools with SalesBlink. Two Way Participate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Manatal",
    "slug": "manatal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5d213b2ce34ad460c180e3beffe2faa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manatal is a recruitment software designed for HR, Talent Acquisition, recruiting and headhunting teams.",
    "category": "HR Talent & Recruitment",
    "title": "Manatal Integration With Cold Email Sales Tool",
    "h1": "Integrate Manatal with SalesBlink",
    "metaDescription": "Integrate Manatal and other HR Talent & Recruitment Tools with SalesBlink. Two Way Manatal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StoryPrompt",
    "slug": "storyprompt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aaec58ac08e8a748a29e18f1f487c6ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoryPrompt is an easy way to send and collect personal videos from customers, employees, and your community. Capturing stories, testimonials, and updates is as simple as sharing a link.",
    "category": "Video & Audio",
    "title": "StoryPrompt Integration With Cold Email Sales Tool",
    "h1": "Integrate StoryPrompt with SalesBlink",
    "metaDescription": "Integrate StoryPrompt and other Video & Audio Tools with SalesBlink. Two Way StoryPrompt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "preezie",
    "slug": "preezie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/274269583bd51f7edf3864a63157b4ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "preezie is a guided conversion and product discovery platform designed to help you bring the in-store experience online",
    "category": "eCommerce",
    "title": "preezie Integration With Cold Email Sales Tool",
    "h1": "Integrate preezie with SalesBlink",
    "metaDescription": "Integrate preezie and other eCommerce Tools with SalesBlink. Two Way preezie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Affinda",
    "slug": "affinda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce620d75fd276572414f6eb3d479871a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Affinda provides AI-powered document extraction solutions to return structured data in seconds.",
    "category": "Documents",
    "title": "Affinda Integration With Cold Email Sales Tool",
    "h1": "Integrate Affinda with SalesBlink",
    "metaDescription": "Integrate Affinda and other Documents Tools with SalesBlink. Two Way Affinda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GigaBook",
    "slug": "gigabook",
    "logo": "https://zapier-images.imgix.net/storage/developer/6ecfde73d948179b096cdd922a89ce0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GigaBook is a cloud-based appointment booking platform designed to help businesses take appointments online.",
    "category": "Scheduling & Booking",
    "title": "GigaBook Integration With Cold Email Sales Tool",
    "h1": "Integrate GigaBook with SalesBlink",
    "metaDescription": "Integrate GigaBook and other Scheduling & Booking Tools with SalesBlink. Two Way GigaBook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Talkroute",
    "slug": "talkroute",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d566232f1b83a4e4821fd1c3664ffc81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Talkroute turns your phones & desktops into a virtual phone system that’s ready for business anywhere: office, home & on the road.",
    "category": "Call Tracking",
    "title": "Talkroute Integration With Cold Email Sales Tool",
    "h1": "Integrate Talkroute with SalesBlink",
    "metaDescription": "Integrate Talkroute and other Call Tracking Tools with SalesBlink. Two Way Talkroute Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "1SaaS.co",
    "slug": "1saasco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3bfcaea6c7a170f81835dc0d806e5ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "1SaaS.co helps you breaking LowCode and NoCode limits to enhance your automations and business.",
    "category": "IT Operations",
    "title": "1SaaS.co Integration With Cold Email Sales Tool",
    "h1": "Integrate 1SaaS.co with SalesBlink",
    "metaDescription": "Integrate 1SaaS.co and other IT Operations Tools with SalesBlink. Two Way 1SaaS.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BunkerDB",
    "slug": "bunkerdb",
    "logo": "https://zapier-images.imgix.net/storage/services/3b40c0f0a742d981d47ef9121fac6d04.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BunkerDB is a platform in which you can store, group, filter, segment your customers and trigger actions on them.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BunkerDB Integration With Cold Email Sales Tool",
    "h1": "Integrate BunkerDB with SalesBlink",
    "metaDescription": "Integrate BunkerDB and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BunkerDB Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LabsMobile",
    "slug": "labsmobile",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/318ef5a3dec72a189d48bd19e3bf8612.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LabsMobile is a communication platform that lets you send SMS to more than 200 countries. Easy, reliable and economic.",
    "category": "Phone & SMS",
    "title": "LabsMobile Integration With Cold Email Sales Tool",
    "h1": "Integrate LabsMobile with SalesBlink",
    "metaDescription": "Integrate LabsMobile and other Phone & SMS Tools with SalesBlink. Two Way LabsMobile Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Poggio Labs",
    "slug": "poggio-labs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24ef09f64e6cee02cbb6e69bc534919f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Poggio Labs powers intelligent relationships for customer-obsessed teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Poggio Labs Integration With Cold Email Sales Tool",
    "h1": "Integrate Poggio Labs with SalesBlink",
    "metaDescription": "Integrate Poggio Labs and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Poggio Labs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MasterIN",
    "slug": "masterin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd7705374f0fe53d9505c3e128fe7129_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MasterIN is a platform for postgraduate information and training.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MasterIN Integration With Cold Email Sales Tool",
    "h1": "Integrate MasterIN with SalesBlink",
    "metaDescription": "Integrate MasterIN and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MasterIN Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CertifyMe",
    "slug": "certifyme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa76fcf144209a938d1e203796730015.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Credential platform that enables Event producers and TechEd firms to maximize their brand’s presence",
    "category": "Education",
    "title": "CertifyMe Integration With Cold Email Sales Tool",
    "h1": "Integrate CertifyMe with SalesBlink",
    "metaDescription": "Integrate CertifyMe and other Education Tools with SalesBlink. Two Way CertifyMe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notify",
    "slug": "notify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8535d1dd92c33e9804d7f6efa9293a16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notify, a new messaging and notification platform for a user friendly and faster communication in B2B & B2C.",
    "category": "Notifications",
    "title": "Notify Integration With Cold Email Sales Tool",
    "h1": "Integrate Notify with SalesBlink",
    "metaDescription": "Integrate Notify and other Notifications Tools with SalesBlink. Two Way Notify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Engage",
    "slug": "engage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c583a01bca0f27875032e326409653b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engage helps businesses send personalised messages to customers based on their attributes and actions.",
    "category": "Communication",
    "title": "Engage Integration With Cold Email Sales Tool",
    "h1": "Integrate Engage with SalesBlink",
    "metaDescription": "Integrate Engage and other Communication Tools with SalesBlink. Two Way Engage Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Voluum",
    "slug": "voluum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2dee57a8975298bdfde7d596c7ec583c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Track, optimize & automate your advertising with affiliate tracking software recommended by digital marketers from around the globe!",
    "category": "Ads & Conversion",
    "title": "Voluum Integration With Cold Email Sales Tool",
    "h1": "Integrate Voluum with SalesBlink",
    "metaDescription": "Integrate Voluum and other Ads & Conversion Tools with SalesBlink. Two Way Voluum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clarizen One",
    "slug": "clarizen-one",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc7a820277286866eb0ba396284476b1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clarizen is the leader in enterprise-class work collaboration and project management solutions that harness the power of the cloud to get work done efficiently, effectively and with better results.",
    "category": "Project Management",
    "title": "Clarizen One Integration With Cold Email Sales Tool",
    "h1": "Integrate Clarizen One with SalesBlink",
    "metaDescription": "Integrate Clarizen One and other Project Management Tools with SalesBlink. Two Way Clarizen One Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sapling",
    "slug": "sapling",
    "logo": "https://zapier-images.imgix.net/storage/services/44d78d81f599793b1be29320771570a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sapling gives People Operations and HR teams powerful tools to drive repeatable and consistent processes for teams in any location.",
    "category": "Human Resources",
    "title": "Sapling Integration With Cold Email Sales Tool",
    "h1": "Integrate Sapling with SalesBlink",
    "metaDescription": "Integrate Sapling and other Human Resources Tools with SalesBlink. Two Way Sapling Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Maintenance Care",
    "slug": "maintenance-care",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df8a46c3536421a54b66fad2823a093d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maintenance Care is a CMMS software for work orders, preventive maintenance and asset management.",
    "category": "Task Management",
    "title": "Maintenance Care Integration With Cold Email Sales Tool",
    "h1": "Integrate Maintenance Care with SalesBlink",
    "metaDescription": "Integrate Maintenance Care and other Task Management Tools with SalesBlink. Two Way Maintenance Care Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digify",
    "slug": "digify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75b1c55e5e97aa069e86d2994533a547.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digify is a document security platform for businesses to share, manage and track confidential documents.",
    "category": "Documents",
    "title": "Digify Integration With Cold Email Sales Tool",
    "h1": "Integrate Digify with SalesBlink",
    "metaDescription": "Integrate Digify and other Documents Tools with SalesBlink. Two Way Digify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drips",
    "slug": "drips",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75e81b2e4bbdf22cdc799ba64de9e183.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drips is an AI-powered conversational outreach platform that engages audiences using two-way texting, scheduled calling, and voicemail messages.",
    "category": "Communication",
    "title": "Drips Integration With Cold Email Sales Tool",
    "h1": "Integrate Drips with SalesBlink",
    "metaDescription": "Integrate Drips and other Communication Tools with SalesBlink. Two Way Drips Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leave Dates",
    "slug": "leave-dates",
    "logo": "https://zapier-images.imgix.net/storage/services/039fb5de61d448b103903afea99b3af2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leave Dates is a leave management system for modern companies to plan and track employee holidays and absences.",
    "category": "Time Tracking Software",
    "title": "Leave Dates Integration With Cold Email Sales Tool",
    "h1": "Integrate Leave Dates with SalesBlink",
    "metaDescription": "Integrate Leave Dates and other Time Tracking Software Tools with SalesBlink. Two Way Leave Dates Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pavlok Wearable Device",
    "slug": "pavlok-wearable-device-da47193",
    "logo": "https://zapier-images.imgix.net/storage/developer/fc3fffb10b1db6dbc770927f500d3f2d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pavlok is a haptic wearable device. You can signal the device to vibrate, beep, shine LEDs, or electrically signal the wearer at varying levels of intensity.",
    "category": "Fitness",
    "title": "Pavlok Wearable Device Integration With Cold Email Sales Tool",
    "h1": "Integrate Pavlok Wearable Device with SalesBlink",
    "metaDescription": "Integrate Pavlok Wearable Device and other Fitness Tools with SalesBlink. Two Way Pavlok Wearable Device Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadmonk",
    "slug": "leadmonk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b492b5e70aba6161deab32f7e54f6662.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadmonk is a highly versatile and simple online appointment scheduling tool for businesses and individuals.",
    "category": "Scheduling & Booking",
    "title": "Leadmonk Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadmonk with SalesBlink",
    "metaDescription": "Integrate Leadmonk and other Scheduling & Booking Tools with SalesBlink. Two Way Leadmonk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fresh KDS",
    "slug": "freshkds",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08691ea700929faa56225ae7b6648aa0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KDS is a Kitchen / Take Out / Production Management System",
    "category": "eCommerce",
    "title": "Fresh KDS Integration With Cold Email Sales Tool",
    "h1": "Integrate Fresh KDS with SalesBlink",
    "metaDescription": "Integrate Fresh KDS and other eCommerce Tools with SalesBlink. Two Way Fresh KDS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smeetz",
    "slug": "smeetz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e39e98db08e2c0003f85c5dde86d768d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smeetz grows your business, increases your ticket sales and engages with more customers by using our ticketing, marketing and smart pricing solution for events, tours and attractions.",
    "category": "Event Management",
    "title": "Smeetz Integration With Cold Email Sales Tool",
    "h1": "Integrate Smeetz with SalesBlink",
    "metaDescription": "Integrate Smeetz and other Event Management Tools with SalesBlink. Two Way Smeetz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Map My Customers",
    "slug": "map-my-customers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c5e2e79823f328d19eb69fda6221dee1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Map My Customers is the mobile sales CRM simplifying your team's day-to-day.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Map My Customers Integration With Cold Email Sales Tool",
    "h1": "Integrate Map My Customers with SalesBlink",
    "metaDescription": "Integrate Map My Customers and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Map My Customers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outfield",
    "slug": "outfield",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d181dccfa724fea16740ecf5237449ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outfield is an outside sales & marketing CRM that makes it easy to discover valuable insights about your market, track + verify team activity, and communicate effortlessly across all your devices",
    "category": "CRM (Customer Relationship Management)",
    "title": "Outfield Integration With Cold Email Sales Tool",
    "h1": "Integrate Outfield with SalesBlink",
    "metaDescription": "Integrate Outfield and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Outfield Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Storenvy",
    "slug": "storenvy",
    "logo": "https://zapier-images.imgix.net/storage/developer/43a1c70d2a09e91a098557db83573162.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Storenvy helps you discover goods you'll love from brands that inspire. It is also the easiest way to open your own store online.",
    "category": "eCommerce",
    "title": "Storenvy Integration With Cold Email Sales Tool",
    "h1": "Integrate Storenvy with SalesBlink",
    "metaDescription": "Integrate Storenvy and other eCommerce Tools with SalesBlink. Two Way Storenvy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Web3Hook",
    "slug": "web3hook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/26eaa70f8e8b96aae621195f56fa0046.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "No Code Web3 Crypto Automation. Design powerful workflows between web3 and web2 applications without relying on developer resources.",
    "category": "Accounting",
    "title": "Web3Hook Integration With Cold Email Sales Tool",
    "h1": "Integrate Web3Hook with SalesBlink",
    "metaDescription": "Integrate Web3Hook and other Accounting Tools with SalesBlink. Two Way Web3Hook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Megaventory",
    "slug": "megaventory",
    "logo": "https://zapier-images.imgix.net/storage/developer/8bc2730b2cfe059d1184f9d6575bef85_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A powerful business ERP software. Inventory and Order Management. Light Manufacturing Management.  Reporting.",
    "category": "eCommerce",
    "title": "Megaventory Integration With Cold Email Sales Tool",
    "h1": "Integrate Megaventory with SalesBlink",
    "metaDescription": "Integrate Megaventory and other eCommerce Tools with SalesBlink. Two Way Megaventory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocuPost Postal Mail",
    "slug": "docupost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/768803876c08e5ffd8773791cc97f1dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocuPost is a print & mail service allowing you to easily mail your digital files as physical letters and postcards via the US Postal Service.",
    "category": "Printing",
    "title": "DocuPost Postal Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate DocuPost Postal Mail with SalesBlink",
    "metaDescription": "Integrate DocuPost Postal Mail and other Printing Tools with SalesBlink. Two Way DocuPost Postal Mail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paperless",
    "slug": "paperless",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db460f545e7c973741e8eac9f1ae4920_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paperless lets you create, sign, and manage intelligent documents on a single platform. For more productivity and a better experience for everyone involved.",
    "category": "Documents",
    "title": "Paperless Integration With Cold Email Sales Tool",
    "h1": "Integrate Paperless with SalesBlink",
    "metaDescription": "Integrate Paperless and other Documents Tools with SalesBlink. Two Way Paperless Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Data Soap",
    "slug": "data-soap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4200ab9bbc4e9738606d11c73fd33b8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Data Soap is a data cleansing tool used to validate mobile phone numbers (Worldwide), email addresses, TPS & CTPS register & landline numbers (UK Only).",
    "category": "Developer Tools",
    "title": "Data Soap Integration With Cold Email Sales Tool",
    "h1": "Integrate Data Soap with SalesBlink",
    "metaDescription": "Integrate Data Soap and other Developer Tools Tools with SalesBlink. Two Way Data Soap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventable",
    "slug": "eventable",
    "logo": "https://zapier-images.imgix.net/storage/developer/60bae752b1a2799e7c78c454f9c3c0ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventable is a calendar communication platform that makes it easy for anyone to create, send, and share calendar events on the web.",
    "category": "Event Management",
    "title": "Eventable Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventable with SalesBlink",
    "metaDescription": "Integrate Eventable and other Event Management Tools with SalesBlink. Two Way Eventable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WOWING",
    "slug": "wowing-cli",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21c27f85fdc82ec5bbd968feebbe90b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WOWING is the solution to automate and facilitate the creation of personal emotional touchpoints via audio, video and physical elements",
    "category": "Marketing",
    "title": "WOWING Integration With Cold Email Sales Tool",
    "h1": "Integrate WOWING with SalesBlink",
    "metaDescription": "Integrate WOWING and other Marketing Tools with SalesBlink. Two Way WOWING Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kaiten",
    "slug": "kaiten",
    "logo": "https://zapier-images.imgix.net/storage/developer/056d52289ad14afcbeecebae1050e649.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kaiten is an online platform for collaborative work. With a built-in proven methodology, it will help you to take your team and business to the next level.",
    "category": "Product Management",
    "title": "Kaiten Integration With Cold Email Sales Tool",
    "h1": "Integrate Kaiten with SalesBlink",
    "metaDescription": "Integrate Kaiten and other Product Management Tools with SalesBlink. Two Way Kaiten Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ubiqod",
    "slug": "ubiqod",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bdcb52dc42ce9591763f92c4d6d95734_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ubiqod is a traceability platform for tracking field activities with IoT, static and dynamic QR codes.",
    "category": "Devices",
    "title": "Ubiqod Integration With Cold Email Sales Tool",
    "h1": "Integrate Ubiqod with SalesBlink",
    "metaDescription": "Integrate Ubiqod and other Devices Tools with SalesBlink. Two Way Ubiqod Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formplus",
    "slug": "formplus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ddaf810461bbd84b87da449cd0eaad0f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create beautiful forms, gather insightful data, and improve collaboration with Formplusㅡan online form builder designed for everyone. Formplus is a flexible tool that lets you build online surveys, application forms, collect feedback, receive payments, and do so much more",
    "category": "Forms & Surveys",
    "title": "Formplus Integration With Cold Email Sales Tool",
    "h1": "Integrate Formplus with SalesBlink",
    "metaDescription": "Integrate Formplus and other Forms & Surveys Tools with SalesBlink. Two Way Formplus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workflow by GrowthHackers",
    "slug": "contentools",
    "logo": "https://zapier-images.imgix.net/storage/services/764bf1e6e97c4797a5b76f2a464332d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowthHackers Workflow is a content marketing platform to streamline and manage content creation. Create. Collaborate. Publish. All in one place.",
    "category": "Marketing Automation",
    "title": "Workflow by GrowthHackers Integration With Cold Email Sales Tool",
    "h1": "Integrate Workflow by GrowthHackers with SalesBlink",
    "metaDescription": "Integrate Workflow by GrowthHackers and other Marketing Automation Tools with SalesBlink. Two Way Workflow by GrowthHackers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Framework360",
    "slug": "framework360-ca123414",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0bcb976d1f68c5631a6e19a2199e14a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Framework360 è il software essenziale per i veri professionisti del digital marketing.",
    "category": "Marketing Automation",
    "title": "Framework360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Framework360 with SalesBlink",
    "metaDescription": "Integrate Framework360 and other Marketing Automation Tools with SalesBlink. Two Way Framework360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Roezan SMS",
    "slug": "roezan-sms",
    "logo": "https://zapier-images.imgix.net/storage/services/5a8d0719e9cb6be3a31a2663190f08f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Roezan is an automated text funnel solution, increasing lead conversions through marketing automations.",
    "category": "Communication",
    "title": "Roezan SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Roezan SMS with SalesBlink",
    "metaDescription": "Integrate Roezan SMS and other Communication Tools with SalesBlink. Two Way Roezan SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magnetis",
    "slug": "magnetis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6003617169c2469dfbccd6f6d17b42c6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magnetis Call-tracking is a ROI solution which permits your company to follow and analyse its incoming calls. Thanks to the data collected, you get a precise and reliable insight of the performance of each channel or operation of communication that you use in your strategy.",
    "category": "Call Tracking",
    "title": "Magnetis Integration With Cold Email Sales Tool",
    "h1": "Integrate Magnetis with SalesBlink",
    "metaDescription": "Integrate Magnetis and other Call Tracking Tools with SalesBlink. Two Way Magnetis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gupshup for Business",
    "slug": "gupshup-for-business",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d22fd0674f9af2ef30f6509c096bd71.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engage customers across sales, marketing and support journeys-with their consent, conversationally and in real-time on their favourite messaging platform",
    "category": "Communication",
    "title": "Gupshup for Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Gupshup for Business with SalesBlink",
    "metaDescription": "Integrate Gupshup for Business and other Communication Tools with SalesBlink. Two Way Gupshup for Business Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shorten.REST",
    "slug": "shorten-rest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b132a26e1c887867bc0548d3b5775425_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shorten.REST is a flexible, robust, scale-able and transactional URL Shortening app.",
    "category": "URL Shortener",
    "title": "Shorten.REST Integration With Cold Email Sales Tool",
    "h1": "Integrate Shorten.REST with SalesBlink",
    "metaDescription": "Integrate Shorten.REST and other URL Shortener Tools with SalesBlink. Two Way Shorten.REST Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "CallHippo",
    "slug": "callhippo-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d2cf46bb7f6e91b9199458f072a6247.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallHippo is a VOIP service provider.",
    "category": "Phone & SMS",
    "title": "CallHippo Integration With Cold Email Sales Tool",
    "h1": "Integrate CallHippo with SalesBlink",
    "metaDescription": "Integrate CallHippo and other Phone & SMS Tools with SalesBlink. Two Way CallHippo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Shaker",
    "slug": "shaker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5de8af97fef536288fcf75108ef814cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shaker is a collaborative client relationship and workflow manager for real estate teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Shaker Integration With Cold Email Sales Tool",
    "h1": "Integrate Shaker with SalesBlink",
    "metaDescription": "Integrate Shaker and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Shaker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrackMage",
    "slug": "trackmage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87adcc993458fc9179e88cab13574393_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrackMage is an order tracking platform that combines order fulfillment and customer support to provide customer's with a comprehensive post-purchase experience.",
    "category": "eCommerce",
    "title": "TrackMage Integration With Cold Email Sales Tool",
    "h1": "Integrate TrackMage with SalesBlink",
    "metaDescription": "Integrate TrackMage and other eCommerce Tools with SalesBlink. Two Way TrackMage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eFront Pro",
    "slug": "efront-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer/efd77cdaeba73a8b6c5394ad6b22b31f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A powerful, yet simple LMS to cover your training & skills development needs.",
    "category": "Online Courses",
    "title": "eFront Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate eFront Pro with SalesBlink",
    "metaDescription": "Integrate eFront Pro and other Online Courses Tools with SalesBlink. Two Way eFront Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Addressable",
    "slug": "addressable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c5d15e5a4898d7b48d0e8ac88ea756be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Addressable is a mail app that provides beautiful, handwritten notes tell exactly the story you want to just the right people, and that means you get more calls and appointments!",
    "category": "Printing",
    "title": "Addressable Integration With Cold Email Sales Tool",
    "h1": "Integrate Addressable with SalesBlink",
    "metaDescription": "Integrate Addressable and other Printing Tools with SalesBlink. Two Way Addressable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReleaseNotes",
    "slug": "releasenotes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb377fdabc48827966f78b4b8e6b6bf3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReleaseNotes is a change log as a service tool, allowing products to create, share, and promote their latest updates to the user base.",
    "category": "Marketing",
    "title": "ReleaseNotes Integration With Cold Email Sales Tool",
    "h1": "Integrate ReleaseNotes with SalesBlink",
    "metaDescription": "Integrate ReleaseNotes and other Marketing Tools with SalesBlink. Two Way ReleaseNotes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CustomerHub",
    "slug": "customerhub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48f96f025c9ac99de8ba195a341fe477.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sell your knowledge like a pro, in a jiffy. Publish your online course or membership site in 30 minutes or less using our powerful and easy platform.",
    "category": "Online Courses",
    "title": "CustomerHub Integration With Cold Email Sales Tool",
    "h1": "Integrate CustomerHub with SalesBlink",
    "metaDescription": "Integrate CustomerHub and other Online Courses Tools with SalesBlink. Two Way CustomerHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Maileon",
    "slug": "maileon",
    "logo": "https://zapier-images.imgix.net/storage/services/499142ac495517658331fe234f5d2d14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maileon is an email marketing and automation solution that allows you to send targeted email campaigns and develop custom marketing automations.",
    "category": "Email Newsletters",
    "title": "Maileon Integration With Cold Email Sales Tool",
    "h1": "Integrate Maileon with SalesBlink",
    "metaDescription": "Integrate Maileon and other Email Newsletters Tools with SalesBlink. Two Way Maileon Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Magentrix",
    "slug": "magentrix",
    "logo": "https://zapier-images.imgix.net/storage/services/e2f8043ea19cae8d521aa7cd48941f0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magentrix provides advanced API connections to Salesforce CRM & Microsoft Dynamics which allow you to easily build custom, self-service webpages for customers or partners to access or change account or contact related data.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Magentrix Integration With Cold Email Sales Tool",
    "h1": "Integrate Magentrix with SalesBlink",
    "metaDescription": "Integrate Magentrix and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Magentrix Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Webpushr",
    "slug": "webpushr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46c76623618099b2417586a47c73e37e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webpushr is a web push notifications platform you can use to send push notifications to your site visitors across all web browsers.",
    "category": "Notifications",
    "title": "Webpushr Integration With Cold Email Sales Tool",
    "h1": "Integrate Webpushr with SalesBlink",
    "metaDescription": "Integrate Webpushr and other Notifications Tools with SalesBlink. Two Way Webpushr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "openHandwerk",
    "slug": "openhandwerk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ace8ef22c6043e3bd9ee9ce2fb1fa46e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "openHandwerk is invoice & project management software which includes many features like CRM, DMS, Calendar & many more supporting the daily workflow as an artisan.",
    "category": "Proposal & Invoice Management",
    "title": "openHandwerk Integration With Cold Email Sales Tool",
    "h1": "Integrate openHandwerk with SalesBlink",
    "metaDescription": "Integrate openHandwerk and other Proposal & Invoice Management Tools with SalesBlink. Two Way openHandwerk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gallabox",
    "slug": "gallabox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc8a5a283938ce0ca9c81aa18f52d726.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gallabox is no-code conversation workspace for business to grow sales",
    "category": "Communication",
    "title": "Gallabox Integration With Cold Email Sales Tool",
    "h1": "Integrate Gallabox with SalesBlink",
    "metaDescription": "Integrate Gallabox and other Communication Tools with SalesBlink. Two Way Gallabox Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "PHP Point Of Sale",
    "slug": "php-point-of-sale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39bc011722974b47cb414016c5ce61a2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PHP Point Of Sale is an easy to use Web Based Point Of Sale System for Retail. Helps with inventory tracking, sales tax, and managing all aspects of your retail business.",
    "category": "Payment Processing",
    "title": "PHP Point Of Sale Integration With Cold Email Sales Tool",
    "h1": "Integrate PHP Point Of Sale with SalesBlink",
    "metaDescription": "Integrate PHP Point Of Sale and other Payment Processing Tools with SalesBlink. Two Way PHP Point Of Sale Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Precisely",
    "slug": "precisely",
    "logo": "https://zapier-images.imgix.net/storage/services/5f511e78c8e807bfe6d7f0f69350c5eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Precisely provides contract automation, e-signatures, streamlined approvals, digital archiving and smart reminders for all your contracts, all in the cloud.",
    "category": "Documents",
    "title": "Precisely Integration With Cold Email Sales Tool",
    "h1": "Integrate Precisely with SalesBlink",
    "metaDescription": "Integrate Precisely and other Documents Tools with SalesBlink. Two Way Precisely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bugfender",
    "slug": "bugfender",
    "logo": "https://zapier-images.imgix.net/storage/services/be6715b91c58409124cbe701ada4f70d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bugfender is a cloud storage for application logs. Get fast remote access to your applications’ log files on users’ devices.",
    "category": "Developer Tools",
    "title": "Bugfender Integration With Cold Email Sales Tool",
    "h1": "Integrate Bugfender with SalesBlink",
    "metaDescription": "Integrate Bugfender and other Developer Tools Tools with SalesBlink. Two Way Bugfender Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelloSells",
    "slug": "hellosells",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb9055d65f28014d683c2c327cd8172d_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelloSells is a lead response and qualification service. We respond to calls and chats to capture the qualifying information and pass it to our clients so that they can focus only on the right prospects.",
    "category": "Customer Support",
    "title": "HelloSells Integration With Cold Email Sales Tool",
    "h1": "Integrate HelloSells with SalesBlink",
    "metaDescription": "Integrate HelloSells and other Customer Support Tools with SalesBlink. Two Way HelloSells Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cirkus",
    "slug": "cirkus",
    "logo": "https://zapier-images.imgix.net/storage/services/6b66908b985f92ea833344c6c3cc948d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cirkus is a task management software for any business that relies on collaboration to get work done. With powerful project management features for free, Cirkus is simple in design and capable of adapting to your most complicated work processes.",
    "category": "Product Management",
    "title": "Cirkus Integration With Cold Email Sales Tool",
    "h1": "Integrate Cirkus with SalesBlink",
    "metaDescription": "Integrate Cirkus and other Product Management Tools with SalesBlink. Two Way Cirkus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PlanOK GCI",
    "slug": "planok-gci",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00729fca690b89ee1559aed5a3a4d5f1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GCI is a real estate CRM to manage the complete sales cycle of real estate projects.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PlanOK GCI Integration With Cold Email Sales Tool",
    "h1": "Integrate PlanOK GCI with SalesBlink",
    "metaDescription": "Integrate PlanOK GCI and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PlanOK GCI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gravity Legal",
    "slug": "gravity-legal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79506ba50db96ebac2dcc299f5ded8ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gravity Legal is a credit/debit card and bank transfer acceptance platform specifically designed for law firms.",
    "category": "Payment Processing",
    "title": "Gravity Legal Integration With Cold Email Sales Tool",
    "h1": "Integrate Gravity Legal with SalesBlink",
    "metaDescription": "Integrate Gravity Legal and other Payment Processing Tools with SalesBlink. Two Way Gravity Legal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Samepage",
    "slug": "samepage",
    "logo": "https://zapier-images.imgix.net/storage/developer/803618d84489f597d2e9932298c7a34b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Samepage is a team collaboration platform that brings people together to get work done in one place. Discuss ideas, share files, assign tasks, and finish projects – all in real time, and all from one app.",
    "category": "Team Chat",
    "title": "Samepage Integration With Cold Email Sales Tool",
    "h1": "Integrate Samepage with SalesBlink",
    "metaDescription": "Integrate Samepage and other Team Chat Tools with SalesBlink. Two Way Samepage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LawHustle",
    "slug": "lawhustle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b4402ee24623901e695af116fb195e4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LawHustle picks up where lead generation systems and marketing firms leave off.\nIt takes leads and turns them into clients, while you’re busy taking care of your existing clients.\nThe return on your marketing dollars multiplies and your firm grows!",
    "category": "Marketing",
    "title": "LawHustle Integration With Cold Email Sales Tool",
    "h1": "Integrate LawHustle with SalesBlink",
    "metaDescription": "Integrate LawHustle and other Marketing Tools with SalesBlink. Two Way LawHustle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deskpro",
    "slug": "deskpro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ca90c41fa129b94a0ace6af12629332c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deskpro is a web-based helpdesk software with multiple channel support.",
    "category": "Customer Support",
    "title": "Deskpro Integration With Cold Email Sales Tool",
    "h1": "Integrate Deskpro with SalesBlink",
    "metaDescription": "Integrate Deskpro and other Customer Support Tools with SalesBlink. Two Way Deskpro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zaxaa",
    "slug": "zaxaa",
    "logo": "https://zapier-images.imgix.net/storage/developer/e00a0b59aadcd397ab17bf0ae3f7637b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zaxaa is a platform that helps online course creators, coaches, and small businesses selling digital products to grow their sales.",
    "category": "Marketing Automation",
    "title": "Zaxaa Integration With Cold Email Sales Tool",
    "h1": "Integrate Zaxaa with SalesBlink",
    "metaDescription": "Integrate Zaxaa and other Marketing Automation Tools with SalesBlink. Two Way Zaxaa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProjectPlace",
    "slug": "projectplace",
    "logo": "https://zapier-images.imgix.net/storage/services/c74b6d85d967593bbf193b6a90c66cd4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProjectPlace is a smart project collaboration solution that brings teams together to improve collaboration and get things done.",
    "category": "Project Management",
    "title": "ProjectPlace Integration With Cold Email Sales Tool",
    "h1": "Integrate ProjectPlace with SalesBlink",
    "metaDescription": "Integrate ProjectPlace and other Project Management Tools with SalesBlink. Two Way ProjectPlace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Time etc",
    "slug": "time-etc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42055f5b8c05140ea2dbec61c3acdfe0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Time etc is a leading Virtual Assistant service helping thousands of entrepreneurs to do more, earn more and achieve more.",
    "category": "Productivity",
    "title": "Time etc Integration With Cold Email Sales Tool",
    "h1": "Integrate Time etc with SalesBlink",
    "metaDescription": "Integrate Time etc and other Productivity Tools with SalesBlink. Two Way Time etc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smaily",
    "slug": "smaily-da77074",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e9fdbc0db9ccde2285916378579a3e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smaily is an intentionally simple tool made to create and send out beautiful email newsletters. We’ll help you increase the quality and results of your email marketing, while reducing preparation time and resources.",
    "category": "Email Newsletters",
    "title": "Smaily Integration With Cold Email Sales Tool",
    "h1": "Integrate Smaily with SalesBlink",
    "metaDescription": "Integrate Smaily and other Email Newsletters Tools with SalesBlink. Two Way Smaily Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Woopra",
    "slug": "woopra",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/278bd73bc04276381107ec474fe570e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Woopra is a real-time customer analytics service designed to help organizations optimize the customer life-cycle by delivering live, granular behavioral data for individual website visitors and customers.",
    "category": "Analytics",
    "title": "Woopra Integration With Cold Email Sales Tool",
    "h1": "Integrate Woopra with SalesBlink",
    "metaDescription": "Integrate Woopra and other Analytics Tools with SalesBlink. Two Way Woopra Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AmeriCommerce",
    "slug": "americommerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/37322429299902b4d113e0a32fcf28fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AmeriCommerce is a full-featured eCommerce platform.",
    "category": "eCommerce",
    "title": "AmeriCommerce Integration With Cold Email Sales Tool",
    "h1": "Integrate AmeriCommerce with SalesBlink",
    "metaDescription": "Integrate AmeriCommerce and other eCommerce Tools with SalesBlink. Two Way AmeriCommerce Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Joomag",
    "slug": "joomag",
    "logo": "https://zapier-images.imgix.net/storage/developer/85d8373999de3710a8d03047b90b0f08.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Joomag is the all-in-one platform offering a suite of integrated solutions for every content marketing, digital publishing, corporate communications, and sales engagement needs.",
    "category": "Documents",
    "title": "Joomag Integration With Cold Email Sales Tool",
    "h1": "Integrate Joomag with SalesBlink",
    "metaDescription": "Integrate Joomag and other Documents Tools with SalesBlink. Two Way Joomag Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendHub",
    "slug": "sendhub",
    "logo": "https://zapier-images.imgix.net/storage/developer/8c3ec82a6a32315c165e0e9c70a167b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendHub is a business SMS & VoIP solution that allows you to communicate effectively over Toll-Free, Short Code or Local Numbers.",
    "category": "Phone & SMS",
    "title": "SendHub Integration With Cold Email Sales Tool",
    "h1": "Integrate SendHub with SalesBlink",
    "metaDescription": "Integrate SendHub and other Phone & SMS Tools with SalesBlink. Two Way SendHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reepay",
    "slug": "reepay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bfc39d1abe49570f11e9c5523d79f51.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reepay provides you with an easy all in one payment solution with payment gateway, acquiring service and a powerful subscription platform.",
    "category": "Payment Processing",
    "title": "Reepay Integration With Cold Email Sales Tool",
    "h1": "Integrate Reepay with SalesBlink",
    "metaDescription": "Integrate Reepay and other Payment Processing Tools with SalesBlink. Two Way Reepay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Access Charity Websites",
    "slug": "access-charity-websites",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/862aec08e6193c657a5deb9cc3768ba0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Access Charity Websites is a digital, scalable solution for fundraising, donor engagement and data collection activities",
    "category": "Website Builders",
    "title": "Access Charity Websites Integration With Cold Email Sales Tool",
    "h1": "Integrate Access Charity Websites with SalesBlink",
    "metaDescription": "Integrate Access Charity Websites and other Website Builders Tools with SalesBlink. Two Way Access Charity Websites Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hoist",
    "slug": "hoist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4b1b5861b941ee8a9123601130bed2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We help anyone become a business owner. We are owner's right hand in day to day operations, we make things easier and help you find success.",
    "category": "Sales & CRM",
    "title": "Hoist Integration With Cold Email Sales Tool",
    "h1": "Integrate Hoist with SalesBlink",
    "metaDescription": "Integrate Hoist and other Sales & CRM Tools with SalesBlink. Two Way Hoist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Syften",
    "slug": "syften",
    "logo": "https://zapier-images.imgix.net/storage/services/6150b4c4b50a95ad096cdeb0fcfb7218.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Syften is a keyword monitor and notifier. It will alert you whenever one of your keywords is mentioned on Reddit, Stack Exchange, Hacker News or others.",
    "category": "Social Media Marketing",
    "title": "Syften Integration With Cold Email Sales Tool",
    "h1": "Integrate Syften with SalesBlink",
    "metaDescription": "Integrate Syften and other Social Media Marketing Tools with SalesBlink. Two Way Syften Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MarketingPlatform",
    "slug": "emailplatform",
    "logo": "https://zapier-images.imgix.net/storage/developer/9ab64f92152b2823fcc92862550aebd5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MarketingPlatform is a SaaS for marketing automation, designing campaigns, retrieving leads and powerful segmentation for targeted messages.",
    "category": "Marketing Automation",
    "title": "MarketingPlatform Integration With Cold Email Sales Tool",
    "h1": "Integrate MarketingPlatform with SalesBlink",
    "metaDescription": "Integrate MarketingPlatform and other Marketing Automation Tools with SalesBlink. Two Way MarketingPlatform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rally",
    "slug": "rally",
    "logo": "https://zapier-images.imgix.net/storage/services/f5dcf0cc631877cb1a671b061354bdff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rally is an all-in-one automation and collaboration platform for business lawyers and their clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Rally Integration With Cold Email Sales Tool",
    "h1": "Integrate Rally with SalesBlink",
    "metaDescription": "Integrate Rally and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Rally Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProcurementExpress.com",
    "slug": "procurementexpresscom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5ff73b4b651aa80819be660babfeb68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProcurementExpress.com is a purchase order management app that eliminates company spending mistakes and helps you stay on budget",
    "category": "Proposal & Invoice Management",
    "title": "ProcurementExpress.com Integration With Cold Email Sales Tool",
    "h1": "Integrate ProcurementExpress.com with SalesBlink",
    "metaDescription": "Integrate ProcurementExpress.com and other Proposal & Invoice Management Tools with SalesBlink. Two Way ProcurementExpress.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Calibre",
    "slug": "calibre",
    "logo": "https://zapier-images.imgix.net/storage/services/cc753e3792a08b36a99dbc8abdaf1c41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Calibre gives you everything to understand and improve page speed. Built for teams of all shapes and sizes.",
    "category": "Developer Tools",
    "title": "Calibre Integration With Cold Email Sales Tool",
    "h1": "Integrate Calibre with SalesBlink",
    "metaDescription": "Integrate Calibre and other Developer Tools Tools with SalesBlink. Two Way Calibre Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Watershed LRS",
    "slug": "watershed-lrs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f7d1a1d539d8b2d8ec59a5bfd62b351_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Watershed LRS is a learning analytics platform that provides actionable insights from your training and performance data.",
    "category": "Analytics",
    "title": "Watershed LRS Integration With Cold Email Sales Tool",
    "h1": "Integrate Watershed LRS with SalesBlink",
    "metaDescription": "Integrate Watershed LRS and other Analytics Tools with SalesBlink. Two Way Watershed LRS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vista Social",
    "slug": "vista-social",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/260922c400c4abf60121ad9890577549.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vista Social is an all-in-one social media management platform for brands and agencies",
    "category": "Social Media Marketing",
    "title": "Vista Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Vista Social with SalesBlink",
    "metaDescription": "Integrate Vista Social and other Social Media Marketing Tools with SalesBlink. Two Way Vista Social Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Ruzuku",
    "slug": "ruzuku",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0458291d16508e95bb72bae9c3819790_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ruzuku helps independent coaches, consultants, and experts to create, sell and teach their own online courses with integrated learning communities.",
    "category": "Education",
    "title": "Ruzuku Integration With Cold Email Sales Tool",
    "h1": "Integrate Ruzuku with SalesBlink",
    "metaDescription": "Integrate Ruzuku and other Education Tools with SalesBlink. Two Way Ruzuku Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adobe Creative Cloud Libraries",
    "slug": "creative-cloud-libraries",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1a178d75514b6bfa52784b4f949869b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adobe Creative Cloud Libraries makes the core design assets for your brand or personal projects available to you anywhere. Easily collect and organize images, colors, text styles, and other elements created in various Creative Cloud desktop and mobile apps. Then reuse and modify these assets across projects, devices, other Creative Cloud apps, and teams.",
    "category": "File Management & Storage",
    "title": "Adobe Creative Cloud Libraries Integration With Cold Email Sales Tool",
    "h1": "Integrate Adobe Creative Cloud Libraries with SalesBlink",
    "metaDescription": "Integrate Adobe Creative Cloud Libraries and other File Management & Storage Tools with SalesBlink. Two Way Adobe Creative Cloud Libraries Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AccountEdge",
    "slug": "accountedge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/466c413bb6e363478af3d50f7996de75_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccountEdge Connect is a web-companion tool for AccountEdge; Premium Mac and Windows accounting for small businesses in the US and Canada.",
    "category": "Accounting",
    "title": "AccountEdge Integration With Cold Email Sales Tool",
    "h1": "Integrate AccountEdge with SalesBlink",
    "metaDescription": "Integrate AccountEdge and other Accounting Tools with SalesBlink. Two Way AccountEdge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pastel",
    "slug": "pastel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6560cf815222ee5b611086f56f94f671.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pastel helps web designers and developers collect and organize contextual feedback from colleagues and clients on the web projects they're building.",
    "category": "Team Collaboration",
    "title": "Pastel Integration With Cold Email Sales Tool",
    "h1": "Integrate Pastel with SalesBlink",
    "metaDescription": "Integrate Pastel and other Team Collaboration Tools with SalesBlink. Two Way Pastel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "sticky.io",
    "slug": "stickyio",
    "logo": "https://zapier-images.imgix.net/storage/services/7a37d1ebad0e9be0edb2a24e741b3594.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eliminate the need for numerous plugins with sticky.io. Our API-driven platform simplifies subscription billing and order management — turning one-time transactions into recurring revenue.",
    "category": "eCommerce",
    "title": "sticky.io Integration With Cold Email Sales Tool",
    "h1": "Integrate sticky.io with SalesBlink",
    "metaDescription": "Integrate sticky.io and other eCommerce Tools with SalesBlink. Two Way sticky.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sperant",
    "slug": "sperant",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a113cfc48badd98c6108709235ee0f0e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sperant is a complete software that covers the lead lifecycle: lead creation, sale, and post-sale. / Somos un Software completo que va desde la captación de un Lead hasta la post-venta.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sperant Integration With Cold Email Sales Tool",
    "h1": "Integrate Sperant with SalesBlink",
    "metaDescription": "Integrate Sperant and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sperant Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneDesk",
    "slug": "onedesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/b72bb447f9b238bfb6367b9cdf8a3de2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneDesk combines helpdesk, product management, and project management software into one platform. OneDesk offers a web-app, mobile app, and website portal to connect your customers, employees, and partners.",
    "category": "Project Management",
    "title": "OneDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate OneDesk with SalesBlink",
    "metaDescription": "Integrate OneDesk and other Project Management Tools with SalesBlink. Two Way OneDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sifter",
    "slug": "sifter",
    "logo": "https://zapier-images.imgix.net/storage/developer/8e1f7ec01c94599980ba2f491f3b69c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sifter is a simple hosted bug and issue tracker.",
    "category": "Developer Tools",
    "title": "Sifter Integration With Cold Email Sales Tool",
    "h1": "Integrate Sifter with SalesBlink",
    "metaDescription": "Integrate Sifter and other Developer Tools Tools with SalesBlink. Two Way Sifter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teachfloor",
    "slug": "teachfloor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b56da2add3d7cc6b73b67a268dd4d25.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teachfloor is an online cohort-based courses platform that allows any instructors to set up in just one-click their own online live academy",
    "category": "Education",
    "title": "Teachfloor Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachfloor with SalesBlink",
    "metaDescription": "Integrate Teachfloor and other Education Tools with SalesBlink. Two Way Teachfloor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leeway",
    "slug": "leeway",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8671b9b3850cea510a6a377cea93ce5_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The new way to manage your contracts. Leeway helps companies reduce the time spent on contract management and gives them more control and visibility over legal and financial risks.",
    "category": "Documents",
    "title": "Leeway Integration With Cold Email Sales Tool",
    "h1": "Integrate Leeway with SalesBlink",
    "metaDescription": "Integrate Leeway and other Documents Tools with SalesBlink. Two Way Leeway Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iDenfy",
    "slug": "idenfy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd197bc52e5f587c0842af728c4d8ed2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iDenfy helps you automate identity verification enforced with manual oversight. Defeat fraud while processing customers' data quickly and securely.",
    "category": "Security & Identity Tools",
    "title": "iDenfy Integration With Cold Email Sales Tool",
    "h1": "Integrate iDenfy with SalesBlink",
    "metaDescription": "Integrate iDenfy and other Security & Identity Tools Tools with SalesBlink. Two Way iDenfy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "MeetingKing",
    "slug": "meetingking",
    "logo": "https://zapier-images.imgix.net/storage/developer/201c1e7186cdac3d6387abda584cf2b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MeetingKing makes it easy to manage, structure, record and follow-up on your meetings. Set the agenda, meet and get things done.",
    "category": "Task Management",
    "title": "MeetingKing Integration With Cold Email Sales Tool",
    "h1": "Integrate MeetingKing with SalesBlink",
    "metaDescription": "Integrate MeetingKing and other Task Management Tools with SalesBlink. Two Way MeetingKing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuintaDB",
    "slug": "quintadb",
    "logo": "https://zapier-images.imgix.net/storage/services/371a7cfa907989da102635e4744bbba3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuintaDB is a relational online database, CRM and Web-form Builder. It's an easy web-based application with drag and drop interface.",
    "category": "Databases",
    "title": "QuintaDB Integration With Cold Email Sales Tool",
    "h1": "Integrate QuintaDB with SalesBlink",
    "metaDescription": "Integrate QuintaDB and other Databases Tools with SalesBlink. Two Way QuintaDB Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "LinkMink",
    "slug": "linkmink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bcf31db626451f814a251afcfe9c97ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LinkMink is a partnership management tool to track referrals and automate administrative tasks.",
    "category": "Marketing Automation",
    "title": "LinkMink Integration With Cold Email Sales Tool",
    "h1": "Integrate LinkMink with SalesBlink",
    "metaDescription": "Integrate LinkMink and other Marketing Automation Tools with SalesBlink. Two Way LinkMink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Broadvoice",
    "slug": "broadvoice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d54eb37fa65a782abbe2161effbb0cd5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Broadvoice is a cloud based communications platform that includes phone, SMS, video, and chat.",
    "category": "Communication",
    "title": "Broadvoice Integration With Cold Email Sales Tool",
    "h1": "Integrate Broadvoice with SalesBlink",
    "metaDescription": "Integrate Broadvoice and other Communication Tools with SalesBlink. Two Way Broadvoice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SqualoMail",
    "slug": "squalomail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c741fdcbc106049910547d0fe080dfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SqualoMail is an Email Marketing and Automation Platform.",
    "category": "Email Newsletters",
    "title": "SqualoMail Integration With Cold Email Sales Tool",
    "h1": "Integrate SqualoMail with SalesBlink",
    "metaDescription": "Integrate SqualoMail and other Email Newsletters Tools with SalesBlink. Two Way SqualoMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verloop",
    "slug": "verloop",
    "logo": "https://zapier-images.imgix.net/storage/developer/f1e3123219aa48d3f67d9b09390fb0c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verloop is an AI-powered, smart conversational marketing and sales platform.",
    "category": "Marketing Automation",
    "title": "Verloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Verloop with SalesBlink",
    "metaDescription": "Integrate Verloop and other Marketing Automation Tools with SalesBlink. Two Way Verloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pinpoint",
    "slug": "pinpoint",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/29eb398772ac1b4c8cac30df6fc061d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pinpoint is talent acquisition software for in-house recruitment teams.",
    "category": "HR Talent & Recruitment",
    "title": "Pinpoint Integration With Cold Email Sales Tool",
    "h1": "Integrate Pinpoint with SalesBlink",
    "metaDescription": "Integrate Pinpoint and other HR Talent & Recruitment Tools with SalesBlink. Two Way Pinpoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ticketbud",
    "slug": "ticketbud",
    "logo": "https://zapier-images.imgix.net/storage/developer/4fd0b9125b5e972071ab647b8e3d7439.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ticketbud is a tool that helps you manage your event, sell tickets, promote your ticket sales and capture data about your audience.",
    "category": "Event Management",
    "title": "Ticketbud Integration With Cold Email Sales Tool",
    "h1": "Integrate Ticketbud with SalesBlink",
    "metaDescription": "Integrate Ticketbud and other Event Management Tools with SalesBlink. Two Way Ticketbud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prequal Solutions",
    "slug": "prequal-solutions",
    "logo": "https://zapier-images.imgix.net/storage/developer/91384a18234c819fc3d55fc170dc64d1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prequal Solutions allows access to Fraud Protection Network's platform for credit prequalifications.",
    "category": "",
    "title": "Prequal Solutions Integration With Cold Email Sales Tool",
    "h1": "Integrate Prequal Solutions with SalesBlink",
    "metaDescription": "Integrate Prequal Solutions and other  Tools with SalesBlink. Two Way Prequal Solutions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Admiral",
    "slug": "admiral",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2da8a20665d1517072970c5701bad28e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Admiral is The Visitor Relationship Management Company, growing publisher revenues by subscriptions, email, adblock recovery, privacy & more",
    "category": "Marketing",
    "title": "Admiral Integration With Cold Email Sales Tool",
    "h1": "Integrate Admiral with SalesBlink",
    "metaDescription": "Integrate Admiral and other Marketing Tools with SalesBlink. Two Way Admiral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FormBackend",
    "slug": "formbackend",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b9f8a9cad0e049ffc14fc3b7ea3b47f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FormBackend is a tool to handle everything related to forms on your website.",
    "category": "Forms & Surveys",
    "title": "FormBackend Integration With Cold Email Sales Tool",
    "h1": "Integrate FormBackend with SalesBlink",
    "metaDescription": "Integrate FormBackend and other Forms & Surveys Tools with SalesBlink. Two Way FormBackend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zipForm Plus",
    "slug": "zipform-plus",
    "logo": "https://zapier-images.imgix.net/storage/developer/905cd6f4446347a80d7c96aac0fed981.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "For more than 20 years, zipForm® — zipLogix's real estate forms software and the exclusive and official forms software for NAR — has been the real estate industry's technology foundation. zipLogix offers products that support more than 640,000 users, and delivers innovative products and web applications that significantly improve the productivity and efficiency and address the specific needs of REALTORS®.",
    "category": "CRM (Customer Relationship Management)",
    "title": "zipForm Plus Integration With Cold Email Sales Tool",
    "h1": "Integrate zipForm Plus with SalesBlink",
    "metaDescription": "Integrate zipForm Plus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way zipForm Plus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GetSwift",
    "slug": "getswift",
    "logo": "https://zapier-images.imgix.net/storage/developer/4eec7d83033cf2996e098c3f5c4e9990.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetSwift is Simple software that streamlines your whole delivery business. Dispatch like Uber, track like Domino's and route like FedEx.",
    "category": "Marketing Automation",
    "title": "GetSwift Integration With Cold Email Sales Tool",
    "h1": "Integrate GetSwift with SalesBlink",
    "metaDescription": "Integrate GetSwift and other Marketing Automation Tools with SalesBlink. Two Way GetSwift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snippet",
    "slug": "snippet",
    "logo": "https://zapier-images.imgix.net/storage/services/bc62e1ec319dd73b4a28cce8b2cea35f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snippet is a platform for all your highlights: books, Kindle ebooks and web articles. You got everything in one place, instantly searchable, available wherever you go.",
    "category": "Notes",
    "title": "Snippet Integration With Cold Email Sales Tool",
    "h1": "Integrate Snippet with SalesBlink",
    "metaDescription": "Integrate Snippet and other Notes Tools with SalesBlink. Two Way Snippet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PHC GO",
    "slug": "phc-go",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73eaeb9353bebed9996908a379d0efb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PHC GO is more than just online management software. It is the perfect ally for your day-to-day: zero initial investment, cloud management, continuous updating and high-speed transformation. Sales, purchases, treasury, legal obligations and much more.",
    "category": "App Builder",
    "title": "PHC GO Integration With Cold Email Sales Tool",
    "h1": "Integrate PHC GO with SalesBlink",
    "metaDescription": "Integrate PHC GO and other App Builder Tools with SalesBlink. Two Way PHC GO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wherewolf",
    "slug": "wherewolf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51342191b9c69b25fe3edf3057293d2a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wherewolf is a digital waiver and guest management system, with powerful marketing analytics and follow-up review generation tools.",
    "category": "Forms & Surveys",
    "title": "Wherewolf Integration With Cold Email Sales Tool",
    "h1": "Integrate Wherewolf with SalesBlink",
    "metaDescription": "Integrate Wherewolf and other Forms & Surveys Tools with SalesBlink. Two Way Wherewolf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Condens",
    "slug": "condens",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40aa383acd41e221f85fec11c8de1b4e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Condens helps you and your team store, analyze and share UX research data.",
    "category": "Product Management",
    "title": "Condens Integration With Cold Email Sales Tool",
    "h1": "Integrate Condens with SalesBlink",
    "metaDescription": "Integrate Condens and other Product Management Tools with SalesBlink. Two Way Condens Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emarsys",
    "slug": "emarsys",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f360e900ab861347f3354556b5ef2fbf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emarsys is a marketing platform that enables personalized, one-to-one interactions between marketers and customers across all channels.",
    "category": "Marketing",
    "title": "Emarsys Integration With Cold Email Sales Tool",
    "h1": "Integrate Emarsys with SalesBlink",
    "metaDescription": "Integrate Emarsys and other Marketing Tools with SalesBlink. Two Way Emarsys Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Alpaca",
    "slug": "alpaca",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/355ff5ad91b651c544a9fe808b6974e8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alpaca is an API for Commission Free stock trading.",
    "category": "App Builder",
    "title": "Alpaca Integration With Cold Email Sales Tool",
    "h1": "Integrate Alpaca with SalesBlink",
    "metaDescription": "Integrate Alpaca and other App Builder Tools with SalesBlink. Two Way Alpaca Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EasyWeek",
    "slug": "easyweek",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea87f9ef0638577ff230126bd8e1936d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyWeek is online appointment system for beauty industry.",
    "category": "Scheduling & Booking",
    "title": "EasyWeek Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyWeek with SalesBlink",
    "metaDescription": "Integrate EasyWeek and other Scheduling & Booking Tools with SalesBlink. Two Way EasyWeek Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pledge",
    "slug": "pledge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9ff87ba41e10f00c35ce4c39e17a8f1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pledge is an award-winning fundraising platform that makes it easy for businesses, nonprofits and individuals to make a positive impact in their communities.",
    "category": "Fundraising",
    "title": "Pledge Integration With Cold Email Sales Tool",
    "h1": "Integrate Pledge with SalesBlink",
    "metaDescription": "Integrate Pledge and other Fundraising Tools with SalesBlink. Two Way Pledge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datadeck",
    "slug": "datadeck",
    "logo": "https://zapier-images.imgix.net/storage/services/13e76fa790c29bd08e7579be49978ed6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datadeck is a data integration, visualization and collaboration tool that lets you get data from different sources all into one place and keep track of important metrics with ease.",
    "category": "Dashboards",
    "title": "Datadeck Integration With Cold Email Sales Tool",
    "h1": "Integrate Datadeck with SalesBlink",
    "metaDescription": "Integrate Datadeck and other Dashboards Tools with SalesBlink. Two Way Datadeck Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Speak4",
    "slug": "speak4",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7129be090cd5381c3b72749406f6c2ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Speak4 makes it simple for organizations to empower their supporters to speak up for the causes they believe in.",
    "category": "Forms & Surveys",
    "title": "Speak4 Integration With Cold Email Sales Tool",
    "h1": "Integrate Speak4 with SalesBlink",
    "metaDescription": "Integrate Speak4 and other Forms & Surveys Tools with SalesBlink. Two Way Speak4 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Gift Goose",
    "slug": "the-gift-goose",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4498386e1046c32b17e2e6c3613c886b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Gift Goose designs custom tailored gifts and automates their delivery.",
    "category": "Customer Appreciation",
    "title": "The Gift Goose Integration With Cold Email Sales Tool",
    "h1": "Integrate The Gift Goose with SalesBlink",
    "metaDescription": "Integrate The Gift Goose and other Customer Appreciation Tools with SalesBlink. Two Way The Gift Goose Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cartegraph",
    "slug": "da-cartegraph",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa534116528ad3aa5a602bf2809ef74c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cartegraph helps local governments and universities manage assets, track work, spend smarter, and plan for the future.",
    "category": "Business Intelligence",
    "title": "Cartegraph Integration With Cold Email Sales Tool",
    "h1": "Integrate Cartegraph with SalesBlink",
    "metaDescription": "Integrate Cartegraph and other Business Intelligence Tools with SalesBlink. Two Way Cartegraph Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spinify",
    "slug": "spinify",
    "logo": "https://zapier-images.imgix.net/storage/developer/ae3be7ebf6e260e3a7761ba118bb289a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spinify is a leaderboard solution that will engage your staff and provide an uplift in productivity and an improved culture.",
    "category": "Team Collaboration",
    "title": "Spinify Integration With Cold Email Sales Tool",
    "h1": "Integrate Spinify with SalesBlink",
    "metaDescription": "Integrate Spinify and other Team Collaboration Tools with SalesBlink. Two Way Spinify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hopscotch",
    "slug": "hopscotch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9e1da763fe6bfefbe11343cacf474d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The only all-in-one social media management platform built to save you time.",
    "category": "Social Media Marketing",
    "title": "Hopscotch Integration With Cold Email Sales Tool",
    "h1": "Integrate Hopscotch with SalesBlink",
    "metaDescription": "Integrate Hopscotch and other Social Media Marketing Tools with SalesBlink. Two Way Hopscotch Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Dashmon",
    "slug": "dashmon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06e32528792cfb06db992c62ca3881b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dashmon is a Visual Communication Platform allowing you to visualize your data, inform your employers and customers.",
    "category": "Dashboards",
    "title": "Dashmon Integration With Cold Email Sales Tool",
    "h1": "Integrate Dashmon with SalesBlink",
    "metaDescription": "Integrate Dashmon and other Dashboards Tools with SalesBlink. Two Way Dashmon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ycode",
    "slug": "ycode",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad0a0622825d7502b8debd2da3259ab8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ycode empowers you to build and host advanced no-code web apps—no code or developers required.",
    "category": "Developer Tools",
    "title": "Ycode Integration With Cold Email Sales Tool",
    "h1": "Integrate Ycode with SalesBlink",
    "metaDescription": "Integrate Ycode and other Developer Tools Tools with SalesBlink. Two Way Ycode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Article Video Robot",
    "slug": "article-video-robot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b01dfef37c4d181a241b71447880dbe7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Article To Video Maker. Turn any article into a live talking video with animation, graphics and voice-over and upload it to Youtube - 100% automatically.",
    "category": "Video & Audio",
    "title": "Article Video Robot Integration With Cold Email Sales Tool",
    "h1": "Integrate Article Video Robot with SalesBlink",
    "metaDescription": "Integrate Article Video Robot and other Video & Audio Tools with SalesBlink. Two Way Article Video Robot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PushCallMe",
    "slug": "pushcallme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/426d6802f800671bc1fb50bb61423660_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PushCallMe - Simple Webhook to make a phone call to your phone number (no answer required)",
    "category": "Communication",
    "title": "PushCallMe Integration With Cold Email Sales Tool",
    "h1": "Integrate PushCallMe with SalesBlink",
    "metaDescription": "Integrate PushCallMe and other Communication Tools with SalesBlink. Two Way PushCallMe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fera",
    "slug": "fera",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/090bb2aaa86983f444c24679a1799d4c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fera is a customer reviews app that allows you to automate review requests, display reviews on your site and moderate customer submissions.",
    "category": "Reviews",
    "title": "Fera Integration With Cold Email Sales Tool",
    "h1": "Integrate Fera with SalesBlink",
    "metaDescription": "Integrate Fera and other Reviews Tools with SalesBlink. Two Way Fera Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Storylane",
    "slug": "storylane",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3894ff93d7b4e274cf55a3881d97e7d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Share Interactive product demos for marketing and sales teams",
    "category": "Sales & CRM",
    "title": "Storylane Integration With Cold Email Sales Tool",
    "h1": "Integrate Storylane with SalesBlink",
    "metaDescription": "Integrate Storylane and other Sales & CRM Tools with SalesBlink. Two Way Storylane Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wizishop",
    "slug": "wizishop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/029a152300fd1dc63b21a291a3775a23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WiziShop is an all-in-one e-commerce platform with cloud-based solutions to gain visibility and sell products and services online by harnessing the power of SEO.",
    "category": "eCommerce",
    "title": "Wizishop Integration With Cold Email Sales Tool",
    "h1": "Integrate Wizishop with SalesBlink",
    "metaDescription": "Integrate Wizishop and other eCommerce Tools with SalesBlink. Two Way Wizishop Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Zinrelo",
    "slug": "zinrelo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7bb9848d63a6bca88c8a244c2406f07e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zinrelo is a modern-day loyalty rewards platform that helps maximize repeat sales and per-customer revenue through 360-degree customer engagement.",
    "category": "Marketing Automation",
    "title": "Zinrelo Integration With Cold Email Sales Tool",
    "h1": "Integrate Zinrelo with SalesBlink",
    "metaDescription": "Integrate Zinrelo and other Marketing Automation Tools with SalesBlink. Two Way Zinrelo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quizell",
    "slug": "quizell",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe534bc72ded3bfa84402850655794da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quizell turns your website into a 24/7 sales advisor with product recommendation quizzes.",
    "category": "eCommerce",
    "title": "Quizell Integration With Cold Email Sales Tool",
    "h1": "Integrate Quizell with SalesBlink",
    "metaDescription": "Integrate Quizell and other eCommerce Tools with SalesBlink. Two Way Quizell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PodiumIO",
    "slug": "podiumio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c71c89befede62713d41e078f2e14145_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PodiumIO is an e-commerce platform for At Home Services providers.",
    "category": "eCommerce",
    "title": "PodiumIO Integration With Cold Email Sales Tool",
    "h1": "Integrate PodiumIO with SalesBlink",
    "metaDescription": "Integrate PodiumIO and other eCommerce Tools with SalesBlink. Two Way PodiumIO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnTask",
    "slug": "ontask",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3df79b205f29a33a2a25de21e3d7d269.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnTask lets you create workflows to generate documents from templates and route them for collaboration, review and electronic signatures.",
    "category": "Documents",
    "title": "OnTask Integration With Cold Email Sales Tool",
    "h1": "Integrate OnTask with SalesBlink",
    "metaDescription": "Integrate OnTask and other Documents Tools with SalesBlink. Two Way OnTask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Toybox Systems",
    "slug": "toybox-systems",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6aaeea246e8a518a279ff1df5971956e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Toybox is the leading visual bug tracking tool and allows you to collect visual feedback from your team and clients directly on your site.",
    "category": "Website Builders",
    "title": "Toybox Systems Integration With Cold Email Sales Tool",
    "h1": "Integrate Toybox Systems with SalesBlink",
    "metaDescription": "Integrate Toybox Systems and other Website Builders Tools with SalesBlink. Two Way Toybox Systems Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Noko",
    "slug": "noko",
    "logo": "https://zapier-images.imgix.net/storage/developer/e695dc586f69ebf8b62f7352b4e33f8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Noko helps you manage your time and see the big picture: which days have you been working? How much, and on what? Is all that time billable time? What are your teammates and employees doing?",
    "category": "Time Tracking Software",
    "title": "Noko Integration With Cold Email Sales Tool",
    "h1": "Integrate Noko with SalesBlink",
    "metaDescription": "Integrate Noko and other Time Tracking Software Tools with SalesBlink. Two Way Noko Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebWork Time Tracker",
    "slug": "webwork-time-tracker",
    "logo": "https://zapier-images.imgix.net/storage/services/33ebd8a8cc9b6e5e84c006b78df6ee84.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WebWork time tracker is a time tracking and employee monitoring software. Track time and screenshots, export timesheet reports, add billable contracts and generate invoices.",
    "category": "Time Tracking Software",
    "title": "WebWork Time Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate WebWork Time Tracker with SalesBlink",
    "metaDescription": "Integrate WebWork Time Tracker and other Time Tracking Software Tools with SalesBlink. Two Way WebWork Time Tracker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Field Service Management | Powered by GPS Insight",
    "slug": "field-service-management-powered",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd552e2fa39f1773c3642491c1674c7d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Field Service Management powered by GPS Insight supports your organization’s daily workflow. It eliminates paper, automates your processes, connects your technicians and office team, and ensures a smooth customer journey.",
    "category": "Project Management",
    "title": "Field Service Management | Powered by GPS Insight Integration With Cold Email Sales Tool",
    "h1": "Integrate Field Service Management | Powered by GPS Insight with SalesBlink",
    "metaDescription": "Integrate Field Service Management | Powered by GPS Insight and other Project Management Tools with SalesBlink. Two Way Field Service Management | Powered by GPS Insight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nitro",
    "slug": "nitro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fe7b69a3ee26c2ff75098a5b57a759e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nitro makes document productivity easy, powerful, affordable, and available to all.\nThis integration makes it even better enabling you to extend the power and features from Nitro to other services.",
    "category": "Documents",
    "title": "Nitro Integration With Cold Email Sales Tool",
    "h1": "Integrate Nitro with SalesBlink",
    "metaDescription": "Integrate Nitro and other Documents Tools with SalesBlink. Two Way Nitro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YesInsights",
    "slug": "yesinsights",
    "logo": "https://zapier-images.imgix.net/storage/developer/c453ea3a3394c87f12963eaff1acbe80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YesInsights allows you to create one-click surveys for your team or clients, giving you actionable feedback to move your business forward.",
    "category": "Forms & Surveys",
    "title": "YesInsights Integration With Cold Email Sales Tool",
    "h1": "Integrate YesInsights with SalesBlink",
    "metaDescription": "Integrate YesInsights and other Forms & Surveys Tools with SalesBlink. Two Way YesInsights Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PayFacile",
    "slug": "payfacile",
    "logo": "https://zapier-images.imgix.net/storage/services/869f9f906cc2b5401c0896c85792cdd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Supercharge your business with PayFacile! The ultimate no-code e-commerce app for subscriptions & sales. Build, automate, and secure effortlessly.",
    "category": "Payment Processing",
    "title": "PayFacile Integration With Cold Email Sales Tool",
    "h1": "Integrate PayFacile with SalesBlink",
    "metaDescription": "Integrate PayFacile and other Payment Processing Tools with SalesBlink. Two Way PayFacile Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Byteplant Email Validator",
    "slug": "byteplant-email-validator-ca1966",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bcfb8083c2b2d8aeaf85336d5c789f3c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Byteplant Email-Validator validates email addresses right at the point of entry with a real-time live check to find out if they really exist and accept mail. Stop Bouncing - Start Connecting!",
    "category": "Email",
    "title": "Byteplant Email Validator Integration With Cold Email Sales Tool",
    "h1": "Integrate Byteplant Email Validator with SalesBlink",
    "metaDescription": "Integrate Byteplant Email Validator and other Email Tools with SalesBlink. Two Way Byteplant Email Validator Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Recruitly",
    "slug": "recruitly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6dbff55e101b00c7f50cd9dfbe19c22c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recruitly is a cloud-based recruitment platform for agency and independent recruiters, featuring customisable pipelines, built-in job posting and out-of-the-box integrations with the tools you already use and love.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Recruitly Integration With Cold Email Sales Tool",
    "h1": "Integrate Recruitly with SalesBlink",
    "metaDescription": "Integrate Recruitly and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Recruitly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SpreadsheetWeb Hub",
    "slug": "spreadsheetweb-hub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75dd460d0ccba123683b42455d9605c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SpreadsheetWeb is a no-code platform that helps businesses translate their Excel spreadsheets into secure, responsive, and database-driven web applications.",
    "category": "Website & App Building",
    "title": "SpreadsheetWeb Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate SpreadsheetWeb Hub with SalesBlink",
    "metaDescription": "Integrate SpreadsheetWeb Hub and other Website & App Building Tools with SalesBlink. Two Way SpreadsheetWeb Hub Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DocDroid",
    "slug": "docdroid",
    "logo": "https://zapier-images.imgix.net/storage/developer/9016c29a3b2f1cf3e57f8952370caff9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocDroid is a document sharing platform. Distribute your PDFs, DOCs and presentations easily using a public URL.",
    "category": "Documents",
    "title": "DocDroid Integration With Cold Email Sales Tool",
    "h1": "Integrate DocDroid with SalesBlink",
    "metaDescription": "Integrate DocDroid and other Documents Tools with SalesBlink. Two Way DocDroid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CatchApp Bookings",
    "slug": "catchapp-bookings",
    "logo": "https://zapier-images.imgix.net/storage/services/1e00073d8844334338feb8e90cc60af6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CatchApp is an online booking system that lets your client self-book appointments and pay for them.",
    "category": "Scheduling & Booking",
    "title": "CatchApp Bookings Integration With Cold Email Sales Tool",
    "h1": "Integrate CatchApp Bookings with SalesBlink",
    "metaDescription": "Integrate CatchApp Bookings and other Scheduling & Booking Tools with SalesBlink. Two Way CatchApp Bookings Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendoso",
    "slug": "sendoso",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b13033e08a0a81817466c50a8030dfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendoso is a sending platform that allows you to send anything to anyone programatically.",
    "category": "Marketing",
    "title": "Sendoso Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendoso with SalesBlink",
    "metaDescription": "Integrate Sendoso and other Marketing Tools with SalesBlink. Two Way Sendoso Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cryptowatch",
    "slug": "cryptowatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b752b9a6af13208f775d8f307857a05c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cryptowatch is the preferred crypto trading terminal for hodlers and active traders alike.",
    "category": "News & Lifestyle",
    "title": "Cryptowatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Cryptowatch with SalesBlink",
    "metaDescription": "Integrate Cryptowatch and other News & Lifestyle Tools with SalesBlink. Two Way Cryptowatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Canto",
    "slug": "canto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c727abe3135af2d161e4166510340d5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canto is a Digital Asset Management Software that helps unleash your brand with a simple, yet powerful DAM system. Marketing teams across all industries use Canto to organize, secure and share visual brand assets with ease.",
    "category": "Content & Files",
    "title": "Canto Integration With Cold Email Sales Tool",
    "h1": "Integrate Canto with SalesBlink",
    "metaDescription": "Integrate Canto and other Content & Files Tools with SalesBlink. Two Way Canto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Huntool",
    "slug": "huntool-beta",
    "logo": "https://zapier-images.imgix.net/storage/services/69ed5aca0bd68193bafb1db96a24913e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Huntool is an ATS (application tracking system) specially designed for headhunters.",
    "category": "HR Talent & Recruitment",
    "title": "Huntool Integration With Cold Email Sales Tool",
    "h1": "Integrate Huntool with SalesBlink",
    "metaDescription": "Integrate Huntool and other HR Talent & Recruitment Tools with SalesBlink. Two Way Huntool Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "smsmode",
    "slug": "smsmode-service-denvoi-de-sms-ca",
    "logo": "https://zapier-images.imgix.net/storage/services/a6b7721d8eaa29cfb2513fb090c99757.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "smsmode is a mobile messaging provider (SMS, TTS, RCS & WhatsApp) that enables you to engage with your target audience. The Zapier integration allows you to connect other applications on the smsmode platform.",
    "category": "Phone & SMS",
    "title": "smsmode Integration With Cold Email Sales Tool",
    "h1": "Integrate smsmode with SalesBlink",
    "metaDescription": "Integrate smsmode and other Phone & SMS Tools with SalesBlink. Two Way smsmode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zinc",
    "slug": "zinc",
    "logo": "https://zapier-images.imgix.net/storage/services/fd093b49f85b11860f3b70e259969c8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automated referencing and background checking",
    "category": "HR Talent & Recruitment",
    "title": "Zinc Integration With Cold Email Sales Tool",
    "h1": "Integrate Zinc with SalesBlink",
    "metaDescription": "Integrate Zinc and other HR Talent & Recruitment Tools with SalesBlink. Two Way Zinc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "agentOS",
    "slug": "agentos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ef9e4f691f9d21785244e93eac66f8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "agentOS is a CRM platform that connects you, via open API, to your PropTech.",
    "category": "CRM (Customer Relationship Management)",
    "title": "agentOS Integration With Cold Email Sales Tool",
    "h1": "Integrate agentOS with SalesBlink",
    "metaDescription": "Integrate agentOS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way agentOS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AFRUS",
    "slug": "afrus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1a387337fe9e349201d004f8daa564f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AFRUS means Acquisition, First Donation, Recruitment, Upgrade, Socializing. We help you to receive donations and process with all the information of your leads",
    "category": "CRM (Customer Relationship Management)",
    "title": "AFRUS Integration With Cold Email Sales Tool",
    "h1": "Integrate AFRUS with SalesBlink",
    "metaDescription": "Integrate AFRUS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AFRUS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pulseem",
    "slug": "pulseem",
    "logo": "https://zapier-images.imgix.net/storage/services/f240426fb4440efffd1e324bd3aa60b4.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pulseem is a marketing platform that helps you automate your marketing processes. It allows you to send Email, SMS, MMS, Push Notifications and create stunning Landing Pages.",
    "category": "Marketing Automation",
    "title": "Pulseem Integration With Cold Email Sales Tool",
    "h1": "Integrate Pulseem with SalesBlink",
    "metaDescription": "Integrate Pulseem and other Marketing Automation Tools with SalesBlink. Two Way Pulseem Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ProcessKit",
    "slug": "processkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59cf733f8e6e8f14c0bbf761a669bb01.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProcessKit gives your team its operating procedures, systems, and automation workflows to make repeatable projects more predictable and scalable.",
    "category": "Project Management",
    "title": "ProcessKit Integration With Cold Email Sales Tool",
    "h1": "Integrate ProcessKit with SalesBlink",
    "metaDescription": "Integrate ProcessKit and other Project Management Tools with SalesBlink. Two Way ProcessKit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Goava Discover",
    "slug": "goava-discover",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5f0d825f91965b66bc880d63a0cada18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Goava is a Sales Intelligence tool that helps you Discover your best sales leads using our company database and a powerful recommendation engine.",
    "category": "Sales & CRM",
    "title": "Goava Discover Integration With Cold Email Sales Tool",
    "h1": "Integrate Goava Discover with SalesBlink",
    "metaDescription": "Integrate Goava Discover and other Sales & CRM Tools with SalesBlink. Two Way Goava Discover Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TotalBrokerage",
    "slug": "totalbrokerage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a4c3f228645f8e529da865aca2c380c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The only complete Real Estate Brokerage software solution from contact through closing.",
    "category": "CRM (Customer Relationship Management)",
    "title": "TotalBrokerage Integration With Cold Email Sales Tool",
    "h1": "Integrate TotalBrokerage with SalesBlink",
    "metaDescription": "Integrate TotalBrokerage and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way TotalBrokerage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "e-shot",
    "slug": "e-shot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/732abcb3553bc8d5c2bf84eea9220139.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "e-shot™ is an intuitive marketing automation platform offering intelligent automation & a team that cares. We help your email marketing deliver beyond the click.",
    "category": "Email Newsletters",
    "title": "e-shot Integration With Cold Email Sales Tool",
    "h1": "Integrate e-shot with SalesBlink",
    "metaDescription": "Integrate e-shot and other Email Newsletters Tools with SalesBlink. Two Way e-shot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swordfish.ai",
    "slug": "swordfishai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/483b56844c040d58537507b422b3e1af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swordfish AI is a contact finding tool for cell/mobile phone numbers and email addresses.",
    "category": "Contact Management",
    "title": "Swordfish.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Swordfish.ai with SalesBlink",
    "metaDescription": "Integrate Swordfish.ai and other Contact Management Tools with SalesBlink. Two Way Swordfish.ai Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GymFlow",
    "slug": "gymflow",
    "logo": "https://zapier-images.imgix.net/storage/services/dc246cda21456242de377d25458761b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gymflow is an intuitive all-in-one business management platform for gyms and studios.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GymFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate GymFlow with SalesBlink",
    "metaDescription": "Integrate GymFlow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GymFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FireDrum Email Marketing",
    "slug": "firedrum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b884c7aead3b8dafc0f488b18cf2ac46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FireDrum is a robust, affordable email marketing platform. From simple newsletters to enterprise white-label solutions, we’ve got you covered.",
    "category": "Email Newsletters",
    "title": "FireDrum Email Marketing Integration With Cold Email Sales Tool",
    "h1": "Integrate FireDrum Email Marketing with SalesBlink",
    "metaDescription": "Integrate FireDrum Email Marketing and other Email Newsletters Tools with SalesBlink. Two Way FireDrum Email Marketing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reveall",
    "slug": "reveall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/55c60cf3035c06fe48c7b47a67472a20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reveall helps companies improve the customer and user experience by empowering teams to turn customer data from research and feedback into clearly prioritized actions.",
    "category": "Product Management",
    "title": "Reveall Integration With Cold Email Sales Tool",
    "h1": "Integrate Reveall with SalesBlink",
    "metaDescription": "Integrate Reveall and other Product Management Tools with SalesBlink. Two Way Reveall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parallel",
    "slug": "parallel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c0c759df4fc493780b237f522bf1968.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parallel is a collaborative process management software for back-office and sales teams.",
    "category": "Forms & Surveys",
    "title": "Parallel Integration With Cold Email Sales Tool",
    "h1": "Integrate Parallel with SalesBlink",
    "metaDescription": "Integrate Parallel and other Forms & Surveys Tools with SalesBlink. Two Way Parallel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SchoolTracs",
    "slug": "schooltracs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e62176a18613dd1a54f5d94c6a2ce9f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SchoolTracs is an education center management system to manage lesson schedules, students and staff databases, invoicing and reporting.",
    "category": "Event Management",
    "title": "SchoolTracs Integration With Cold Email Sales Tool",
    "h1": "Integrate SchoolTracs with SalesBlink",
    "metaDescription": "Integrate SchoolTracs and other Event Management Tools with SalesBlink. Two Way SchoolTracs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Linkseller",
    "slug": "linkseller",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f639f285bbce5a200b0bff3e509d8a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Linkseller helps teams manage leads and accelerate closure simply and intelligently. A Linkseller é um CRM focado em simplicidade e versatilidade para se ajustar a sua empresa.\nAjudamos equipes de vendas a gerenciar leads e acelerar o fechamento dos negócios.\nE acompanhar a produtividade da sua equipe.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Linkseller Integration With Cold Email Sales Tool",
    "h1": "Integrate Linkseller with SalesBlink",
    "metaDescription": "Integrate Linkseller and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Linkseller Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crystal Ball",
    "slug": "crystal-ball",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/073a6308a31976100ee2f7875344eae6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crystal Ball helps highlight valuable data insights by simplifying and automating the tracking of actions and events",
    "category": "Analytics",
    "title": "Crystal Ball Integration With Cold Email Sales Tool",
    "h1": "Integrate Crystal Ball with SalesBlink",
    "metaDescription": "Integrate Crystal Ball and other Analytics Tools with SalesBlink. Two Way Crystal Ball Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MOFFI",
    "slug": "moffi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/81e9e469f9c592d0bdc0648740ed4824.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MOFFI offers a solution which makes it easier for workers to remain flexible and mobile every day.",
    "category": "Scheduling & Booking",
    "title": "MOFFI Integration With Cold Email Sales Tool",
    "h1": "Integrate MOFFI with SalesBlink",
    "metaDescription": "Integrate MOFFI and other Scheduling & Booking Tools with SalesBlink. Two Way MOFFI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Space Invoices / Apollo",
    "slug": "space-invoices-apollo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aca9505351e8612c8ec57ef29ddf4f55.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Space Invoices is an advanced, multi-tenant invoicing API that helps companies across the world to easily issue invoices and other documents automatically from their software.\n\nComes with Apollo (https://getapollo.io), an intuitive UI for managing all business data.",
    "category": "Accounting",
    "title": "Space Invoices / Apollo Integration With Cold Email Sales Tool",
    "h1": "Integrate Space Invoices / Apollo with SalesBlink",
    "metaDescription": "Integrate Space Invoices / Apollo and other Accounting Tools with SalesBlink. Two Way Space Invoices / Apollo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PassKit Event Tickets",
    "slug": "passkit-event-tickets",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b41e0e4d0ef9f804b5b17e79e92b70d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PassKit Event Tickets lets you manage your event productions and passes for Apple Wallet and Google Pay",
    "category": "Marketing Automation",
    "title": "PassKit Event Tickets Integration With Cold Email Sales Tool",
    "h1": "Integrate PassKit Event Tickets with SalesBlink",
    "metaDescription": "Integrate PassKit Event Tickets and other Marketing Automation Tools with SalesBlink. Two Way PassKit Event Tickets Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtual Buttons",
    "slug": "virtual-buttons",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ee2231f092ae01389ea1dcf9481bd3b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trigger Alexa Routines with Virtual Buttons. Simply enable the Virtual Buttons Alexa skill, link it to a Patreon account, and then use this service to unlock Alexa Routines as an action.",
    "category": "Internet of Things",
    "title": "Virtual Buttons Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtual Buttons with SalesBlink",
    "metaDescription": "Integrate Virtual Buttons and other Internet of Things Tools with SalesBlink. Two Way Virtual Buttons Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BugBug",
    "slug": "bugbug",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f103ada65e3e6fbb42bd7ec1374afe26.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Save time with easy-to-use, automated browser testing.\nEnsure that your website or web app works and looks like it should. No coding required.",
    "category": "Developer Tools",
    "title": "BugBug Integration With Cold Email Sales Tool",
    "h1": "Integrate BugBug with SalesBlink",
    "metaDescription": "Integrate BugBug and other Developer Tools Tools with SalesBlink. Two Way BugBug Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Visible",
    "slug": "visible",
    "logo": "https://zapier-images.imgix.net/storage/developer/7b05bbf340f3d8c03e1cdc038453c0ab_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Capture key performance data and share your story with key stakeholders all in one platform.",
    "category": "Dashboards",
    "title": "Visible Integration With Cold Email Sales Tool",
    "h1": "Integrate Visible with SalesBlink",
    "metaDescription": "Integrate Visible and other Dashboards Tools with SalesBlink. Two Way Visible Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Polymer",
    "slug": "polymer",
    "logo": "https://zapier-images.imgix.net/storage/services/337ce332e31ba61d1b6ef2abf28bc6b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Polymer combines a no-code job board with a powerful applicant tracking system to create a seamless hiring solution for growing teams.",
    "category": "HR Talent & Recruitment",
    "title": "Polymer Integration With Cold Email Sales Tool",
    "h1": "Integrate Polymer with SalesBlink",
    "metaDescription": "Integrate Polymer and other HR Talent & Recruitment Tools with SalesBlink. Two Way Polymer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "beSlick",
    "slug": "process-bliss",
    "logo": "https://zapier-images.imgix.net/storage/services/1f93eb3502ec54ecc9d7e74305a55264.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "beSlick is a business process and task management platform to help teams save time and keep everything on track.",
    "category": "Team Collaboration",
    "title": "beSlick Integration With Cold Email Sales Tool",
    "h1": "Integrate beSlick with SalesBlink",
    "metaDescription": "Integrate beSlick and other Team Collaboration Tools with SalesBlink. Two Way beSlick Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Piriod",
    "slug": "piriod",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1769a830b5075526612e94267907f1a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Subscription management & recurring billing",
    "category": "Payment Processing",
    "title": "Piriod Integration With Cold Email Sales Tool",
    "h1": "Integrate Piriod with SalesBlink",
    "metaDescription": "Integrate Piriod and other Payment Processing Tools with SalesBlink. Two Way Piriod Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VirtualPostMail",
    "slug": "virtualpostmail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce70ee1553e9010b957a0f0a90e46d8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VirtualPostMail gives you a permanent U.S. business address to help take your business remote.  Read your postal mail online, deposit checks, open business bank/merchant accounts, and more.",
    "category": "Documents",
    "title": "VirtualPostMail Integration With Cold Email Sales Tool",
    "h1": "Integrate VirtualPostMail with SalesBlink",
    "metaDescription": "Integrate VirtualPostMail and other Documents Tools with SalesBlink. Two Way VirtualPostMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Browserbear",
    "slug": "browserbear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b1a91b3f3105cf6567141e11b37578d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Browserbear helps you easily automate any browser task like testing websites, capturing data, web scraping and more",
    "category": "Marketing Automation",
    "title": "Browserbear Integration With Cold Email Sales Tool",
    "h1": "Integrate Browserbear with SalesBlink",
    "metaDescription": "Integrate Browserbear and other Marketing Automation Tools with SalesBlink. Two Way Browserbear Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Copysmith",
    "slug": "copysmith",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/758e7e8878a11205d9164808f0d20685.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create high-converting ads, product descriptions, emails, and more in seconds - never start from a blank page again.",
    "category": "Marketing",
    "title": "Copysmith Integration With Cold Email Sales Tool",
    "h1": "Integrate Copysmith with SalesBlink",
    "metaDescription": "Integrate Copysmith and other Marketing Tools with SalesBlink. Two Way Copysmith Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formsquash",
    "slug": "formsquash",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/281dcfa381045c1eebaf67ba05e1fcf6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formsquash is a form processing service with a dead-simple API. You point your form to us, we notify you every time a form is filled.",
    "category": "Forms & Surveys",
    "title": "Formsquash Integration With Cold Email Sales Tool",
    "h1": "Integrate Formsquash with SalesBlink",
    "metaDescription": "Integrate Formsquash and other Forms & Surveys Tools with SalesBlink. Two Way Formsquash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Channels",
    "slug": "channels",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f2d3c887c16c2286e87c2533b0aff393.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Channels is a data-driven phone system that lets you make and take calls directly in the browser.",
    "category": "Phone & SMS",
    "title": "Channels Integration With Cold Email Sales Tool",
    "h1": "Integrate Channels with SalesBlink",
    "metaDescription": "Integrate Channels and other Phone & SMS Tools with SalesBlink. Two Way Channels Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Experiences App",
    "slug": "experiences-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4f3424550531e5212229839b3a5d25c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Experiences App is a nimble and robust experience engine used to create a wide range of event types including multi-day and recurring weekly/monthly. Stay in control of your brand by owning the design and processing of your bookings.",
    "category": "Event Management",
    "title": "Experiences App Integration With Cold Email Sales Tool",
    "h1": "Integrate Experiences App with SalesBlink",
    "metaDescription": "Integrate Experiences App and other Event Management Tools with SalesBlink. Two Way Experiences App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ubeya",
    "slug": "ubeya",
    "logo": "https://zapier-images.imgix.net/storage/services/580258390ff3640c168338d7200f11b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ubeya is an automated solution for staffing and management. Instantly schedule, track, hire and pay staff.",
    "category": "HR Talent & Recruitment",
    "title": "Ubeya Integration With Cold Email Sales Tool",
    "h1": "Integrate Ubeya with SalesBlink",
    "metaDescription": "Integrate Ubeya and other HR Talent & Recruitment Tools with SalesBlink. Two Way Ubeya Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instagram Custom Audiences",
    "slug": "instagram-custom-audiences",
    "logo": "https://zapier-images.imgix.net/storage/services/30129480e943e5b3e334394168ae16a2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instagram Custom Audiences make signing up for business information easy for people and more valuable for businesses. The Instagram lead ads app is useful for marketers who want to automate actions on their leads.",
    "category": "Ads & Conversion",
    "title": "Instagram Custom Audiences Integration With Cold Email Sales Tool",
    "h1": "Integrate Instagram Custom Audiences with SalesBlink",
    "metaDescription": "Integrate Instagram Custom Audiences and other Ads & Conversion Tools with SalesBlink. Two Way Instagram Custom Audiences Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Angaza",
    "slug": "angaza",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bfe38cf0b6f265a00c34169e3fd8bc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Angaza is a business solution designed for last-mile distributors of products and services in emerging markets. Angaza allows you to unlock new markets and sell more products — from solar lighting systems to clean cookstoves.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Angaza Integration With Cold Email Sales Tool",
    "h1": "Integrate Angaza with SalesBlink",
    "metaDescription": "Integrate Angaza and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Angaza Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AntsRoute",
    "slug": "antsroute",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e276e3c3feaf8dbcfff49c65c2b25b6a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AntsRoute is an innovative software that smartly schedules deliveries, operations and appointments, optimizes routes and improves customer relations. The needed solutions to boost your performance and improve your team productivity.",
    "category": "Scheduling & Booking",
    "title": "AntsRoute Integration With Cold Email Sales Tool",
    "h1": "Integrate AntsRoute with SalesBlink",
    "metaDescription": "Integrate AntsRoute and other Scheduling & Booking Tools with SalesBlink. Two Way AntsRoute Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reftab",
    "slug": "reftab",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4776b069f96db95cc8949c0f1689727e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reftab is a fixed asset management service to help track items.",
    "category": "Product Management",
    "title": "Reftab Integration With Cold Email Sales Tool",
    "h1": "Integrate Reftab with SalesBlink",
    "metaDescription": "Integrate Reftab and other Product Management Tools with SalesBlink. Two Way Reftab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planado",
    "slug": "planado",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c198e99f010f3fa7086032a0653baf46_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planado is a cloud service for the company’s fieldwork management and performance quality control of mobile workers",
    "category": "Scheduling & Booking",
    "title": "Planado Integration With Cold Email Sales Tool",
    "h1": "Integrate Planado with SalesBlink",
    "metaDescription": "Integrate Planado and other Scheduling & Booking Tools with SalesBlink. Two Way Planado Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Merit",
    "slug": "merit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe737de2849a72df3684796e6e10cc98.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Merit is a verified identity platform that allows organizations to issue verifiable digital credentials.",
    "category": "Security & Identity Tools",
    "title": "Merit Integration With Cold Email Sales Tool",
    "h1": "Integrate Merit with SalesBlink",
    "metaDescription": "Integrate Merit and other Security & Identity Tools Tools with SalesBlink. Two Way Merit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QDS (Quality Driven Software)",
    "slug": "qds-quality-driven-software-ca10",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b9aca8a98614655f19d59fe697dddbf2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quality Driven is a customer satisfaction and employee performance tracking app that will improve employee accountability and quality.",
    "category": "CRM (Customer Relationship Management)",
    "title": "QDS (Quality Driven Software) Integration With Cold Email Sales Tool",
    "h1": "Integrate QDS (Quality Driven Software) with SalesBlink",
    "metaDescription": "Integrate QDS (Quality Driven Software) and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way QDS (Quality Driven Software) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoiceShot",
    "slug": "voiceshot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c608c660ef38c7edeb922550c79dc651_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Initiate text messages (from a toll-free or local number) and phone calls to anyone with your VoiceShot API plan.",
    "category": "Phone & SMS",
    "title": "VoiceShot Integration With Cold Email Sales Tool",
    "h1": "Integrate VoiceShot with SalesBlink",
    "metaDescription": "Integrate VoiceShot and other Phone & SMS Tools with SalesBlink. Two Way VoiceShot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FotoNotes",
    "slug": "fotonotes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/abf247f66469a609137e86987fa95269_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FotoNotes is a mobile field reporting tool for doing inspections, and capturing other data and photos on real estate properties and other job sites.",
    "category": "Team Collaboration",
    "title": "FotoNotes Integration With Cold Email Sales Tool",
    "h1": "Integrate FotoNotes with SalesBlink",
    "metaDescription": "Integrate FotoNotes and other Team Collaboration Tools with SalesBlink. Two Way FotoNotes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EditionGuard",
    "slug": "editionguard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d765837b311e1d903514f11d080d9b16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EditionGuard provides you freedom with eBook selling tools that are easy to use and customize, built on top of an industry standard DRM system for security. Now you can sell eBooks the way that makes you happy, without having to give up on your profits.",
    "category": "eCommerce",
    "title": "EditionGuard Integration With Cold Email Sales Tool",
    "h1": "Integrate EditionGuard with SalesBlink",
    "metaDescription": "Integrate EditionGuard and other eCommerce Tools with SalesBlink. Two Way EditionGuard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contact Reach",
    "slug": "contact-reach",
    "logo": "https://zapier-images.imgix.net/storage/services/b12365cd040538d758d298c08152b250.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contact Reach is a sms marketing application to manage leads.",
    "category": "Marketing Automation",
    "title": "Contact Reach Integration With Cold Email Sales Tool",
    "h1": "Integrate Contact Reach with SalesBlink",
    "metaDescription": "Integrate Contact Reach and other Marketing Automation Tools with SalesBlink. Two Way Contact Reach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Passbase",
    "slug": "passbase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d03dafd99963480f562ff9931fc93919.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Passbase enables developers to integrate bank level identity verification into their App or website. You can easily capture identity documents, liveness detection and facial biometrics with a few lines of code.",
    "category": "Developer Tools",
    "title": "Passbase Integration With Cold Email Sales Tool",
    "h1": "Integrate Passbase with SalesBlink",
    "metaDescription": "Integrate Passbase and other Developer Tools Tools with SalesBlink. Two Way Passbase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FoxOMS",
    "slug": "foxoms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/10fffadf9394c8f329c65424d951d129.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FoxOMS is resource scheduling software for creative agencies.",
    "category": "Scheduling & Booking",
    "title": "FoxOMS Integration With Cold Email Sales Tool",
    "h1": "Integrate FoxOMS with SalesBlink",
    "metaDescription": "Integrate FoxOMS and other Scheduling & Booking Tools with SalesBlink. Two Way FoxOMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SweepWidget",
    "slug": "sweepwidget",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f206a6a1b7eb6b6c2e5b243f5f20846d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SweepWidget helps you run amazing contests, giveaways & sweepstakes. Quickly gain unlimited new followers on 30+ social media apps.",
    "category": "Marketing",
    "title": "SweepWidget Integration With Cold Email Sales Tool",
    "h1": "Integrate SweepWidget with SalesBlink",
    "metaDescription": "Integrate SweepWidget and other Marketing Tools with SalesBlink. Two Way SweepWidget Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TruCentive",
    "slug": "trucentive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2803fa29ae98e70000b23895e4ee8755.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TruCentive helps you reward, incentivize, or compensate with digital gift cards, vouchers and coupons",
    "category": "Marketing",
    "title": "TruCentive Integration With Cold Email Sales Tool",
    "h1": "Integrate TruCentive with SalesBlink",
    "metaDescription": "Integrate TruCentive and other Marketing Tools with SalesBlink. Two Way TruCentive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LEX Reception",
    "slug": "lex-reception",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24e6d74350acb5e82a22a30398c7f0c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LEX Reception is a 24/7 legal reception dedicated to giving lawyers more time.",
    "category": "Customer Support",
    "title": "LEX Reception Integration With Cold Email Sales Tool",
    "h1": "Integrate LEX Reception with SalesBlink",
    "metaDescription": "Integrate LEX Reception and other Customer Support Tools with SalesBlink. Two Way LEX Reception Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Marketing 360",
    "slug": "marketing-360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe085b7f6a71725da5d0143fb106f0a6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marketing 360® is an all-in-one marketing platform with the tools you need to scale and the marketing programs and talent you need to grow.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Marketing 360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Marketing 360 with SalesBlink",
    "metaDescription": "Integrate Marketing 360 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Marketing 360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rep.co",
    "slug": "repco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/632b5357d4cf02792cfd3a756e70af1f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rep.co allows you to send review requests to your customers via SMS and Email to keep your company's online reputation healthy.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Rep.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Rep.co with SalesBlink",
    "metaDescription": "Integrate Rep.co and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Rep.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bright Sites",
    "slug": "bright-sites",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d931d2a64288145b9b45386ed4fa10da_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bright Sites is a SaaS eCommerce platform designed for promotional product Distributors and Suppliers.",
    "category": "eCommerce",
    "title": "Bright Sites Integration With Cold Email Sales Tool",
    "h1": "Integrate Bright Sites with SalesBlink",
    "metaDescription": "Integrate Bright Sites and other eCommerce Tools with SalesBlink. Two Way Bright Sites Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CartonCloud",
    "slug": "cartoncloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ffeb34a30f6163d8e06da54b0d4b5b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CartonCloud Warehouse and Transport Management System.",
    "category": "eCommerce",
    "title": "CartonCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate CartonCloud with SalesBlink",
    "metaDescription": "Integrate CartonCloud and other eCommerce Tools with SalesBlink. Two Way CartonCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snackeet",
    "slug": "snackeet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7472e8a7455baeaa18441bc82a28824.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snackeet is a visual builder for creating stunning interactive and conversational short stories, online quizzes, surveys, polls and much more.",
    "category": "Website & App Building",
    "title": "Snackeet Integration With Cold Email Sales Tool",
    "h1": "Integrate Snackeet with SalesBlink",
    "metaDescription": "Integrate Snackeet and other Website & App Building Tools with SalesBlink. Two Way Snackeet Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Zagomail",
    "slug": "zagomail",
    "logo": "https://zapier-images.imgix.net/storage/services/eb295f24856d2f62cafaca7da2a18549.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zagomail is an email marketing and automation platform for small businesses.Create Automated email series, send campaigns, tag subscribers, grow your audience, and automate workflows.",
    "category": "Email Newsletters",
    "title": "Zagomail Integration With Cold Email Sales Tool",
    "h1": "Integrate Zagomail with SalesBlink",
    "metaDescription": "Integrate Zagomail and other Email Newsletters Tools with SalesBlink. Two Way Zagomail Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CanadaLeads Mortgage Leads",
    "slug": "canadaleads-mortgage-leads-ca330",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1944359cae778852ede84401495f9589.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CanadaLeads is a Canadian mortgage lead generation system for agents and brokers across Canada. Leads are real time and cover purchase, home equity, refinance, debt consolidation, and more.",
    "category": "Marketing",
    "title": "CanadaLeads Mortgage Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate CanadaLeads Mortgage Leads with SalesBlink",
    "metaDescription": "Integrate CanadaLeads Mortgage Leads and other Marketing Tools with SalesBlink. Two Way CanadaLeads Mortgage Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ziggeo",
    "slug": "ziggeo",
    "logo": "https://zapier-images.imgix.net/storage/developer/e11cea585aa1e834b2e669c850bad4c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ziggeo is an award-winning cloud-based service for in-browser/in-app video recording, playback, transcoding, storage & video management.",
    "category": "Video & Audio",
    "title": "Ziggeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Ziggeo with SalesBlink",
    "metaDescription": "Integrate Ziggeo and other Video & Audio Tools with SalesBlink. Two Way Ziggeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vextras",
    "slug": "vextras",
    "logo": "https://zapier-images.imgix.net/storage/developer/a8679c0790ad6c058e6756744c254056.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An app which offers connectivity with Volusion ecommerce stores.",
    "category": "Marketing Automation",
    "title": "Vextras Integration With Cold Email Sales Tool",
    "h1": "Integrate Vextras with SalesBlink",
    "metaDescription": "Integrate Vextras and other Marketing Automation Tools with SalesBlink. Two Way Vextras Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LiveCall",
    "slug": "livecall",
    "logo": "https://zapier-images.imgix.net/storage/developer/8035ce3d0c7ee7dd469b1397b2a38078.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LiveCall is a suite for lead generation for companies that sell over the phone. Our widget calls your customers back in 25 seconds.",
    "category": "Phone & SMS",
    "title": "LiveCall Integration With Cold Email Sales Tool",
    "h1": "Integrate LiveCall with SalesBlink",
    "metaDescription": "Integrate LiveCall and other Phone & SMS Tools with SalesBlink. Two Way LiveCall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeev",
    "slug": "zeev-orquestra-bpms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11a84d16ee0143a9e8f4ce380284307a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeev allows you to map, execute and monitor processes, with a focus on transforming the business and constantly improving your company's operations.",
    "category": "Productivity",
    "title": "Zeev Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeev with SalesBlink",
    "metaDescription": "Integrate Zeev and other Productivity Tools with SalesBlink. Two Way Zeev Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ROAR Software",
    "slug": "roar-software",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2478695afb5c5d896576a3fe0ea82d8d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ROAR is the Advice Marketplace - Connecting technology to better deliver financial advice",
    "category": "CRM (Customer Relationship Management)",
    "title": "ROAR Software Integration With Cold Email Sales Tool",
    "h1": "Integrate ROAR Software with SalesBlink",
    "metaDescription": "Integrate ROAR Software and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ROAR Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Collaborative OKRs",
    "slug": "collaborative-okrs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7fe58112bd21b90fccdfa4cbae95c0c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collaborative OKRs is a goal-setting framework used by individuals, teams, and organizations to define measurable goals and track their outcomes.",
    "category": "Business Intelligence",
    "title": "Collaborative OKRs Integration With Cold Email Sales Tool",
    "h1": "Integrate Collaborative OKRs with SalesBlink",
    "metaDescription": "Integrate Collaborative OKRs and other Business Intelligence Tools with SalesBlink. Two Way Collaborative OKRs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pusher",
    "slug": "pusher",
    "logo": "https://zapier-images.imgix.net/storage/developer/702acb02d9c18da89683f3e36d8dd658_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pusher is an evented pub/sub platform that lets you push real-time messages to web, mobile and IoT applications.",
    "category": "Notifications",
    "title": "Pusher Integration With Cold Email Sales Tool",
    "h1": "Integrate Pusher with SalesBlink",
    "metaDescription": "Integrate Pusher and other Notifications Tools with SalesBlink. Two Way Pusher Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "redirect.pizza",
    "slug": "redirectpizza",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea1a6fa9314698d944935c7489247af0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "redirect.pizza allows you to create hassle-free domain redirects",
    "category": "Developer Tools",
    "title": "redirect.pizza Integration With Cold Email Sales Tool",
    "h1": "Integrate redirect.pizza with SalesBlink",
    "metaDescription": "Integrate redirect.pizza and other Developer Tools Tools with SalesBlink. Two Way redirect.pizza Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DG1",
    "slug": "dg1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06a749589251f3238ab35fb83c432abc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DG1 is an all-in-one E-Business suite with enterprise-level capabilities for small and medium-sized businesses. It is affordably delivered through the cloud as a SaaS.",
    "category": "eCommerce",
    "title": "DG1 Integration With Cold Email Sales Tool",
    "h1": "Integrate DG1 with SalesBlink",
    "metaDescription": "Integrate DG1 and other eCommerce Tools with SalesBlink. Two Way DG1 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gestioo",
    "slug": "gestioo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b23c0633355bb5fb7b3068e3835495fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Software for the management of corrective/preventive maintenance workshops.",
    "category": "Customer Support",
    "title": "Gestioo Integration With Cold Email Sales Tool",
    "h1": "Integrate Gestioo with SalesBlink",
    "metaDescription": "Integrate Gestioo and other Customer Support Tools with SalesBlink. Two Way Gestioo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BuzzBuzzHome",
    "slug": "buzzbuzzhome",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/620c3382f94a80a6eb249d145a11bc36.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BuzzBuzzHome is North America's largest marketplace for new construction homes",
    "category": "Sales & CRM",
    "title": "BuzzBuzzHome Integration With Cold Email Sales Tool",
    "h1": "Integrate BuzzBuzzHome with SalesBlink",
    "metaDescription": "Integrate BuzzBuzzHome and other Sales & CRM Tools with SalesBlink. Two Way BuzzBuzzHome Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "fitbox",
    "slug": "fitbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f57b2164352eebf4b22c012f9ca115ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "fitbox is an essential gym management software for member, billing, workout, and class management backed by an innovative business model that turns every dollar you spend into free credit that can be used to improve your gym, or reduce operating costs.",
    "category": "Fitness",
    "title": "fitbox Integration With Cold Email Sales Tool",
    "h1": "Integrate fitbox with SalesBlink",
    "metaDescription": "Integrate fitbox and other Fitness Tools with SalesBlink. Two Way fitbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tabidoo",
    "slug": "tabidoo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/329fefd78842a1585aa7a368263ff765.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tabidoo is a simple online database system that helps you organize your data.",
    "category": "App Builder",
    "title": "Tabidoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Tabidoo with SalesBlink",
    "metaDescription": "Integrate Tabidoo and other App Builder Tools with SalesBlink. Two Way Tabidoo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "LLN-Robot",
    "slug": "lln-robot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8c1081bd96a4d8524aded22610c14f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LLN Robot is a complete testing and support platform for developing Language, Literacy, and Numeracy in the education and VET sectors.",
    "category": "Online Courses",
    "title": "LLN-Robot Integration With Cold Email Sales Tool",
    "h1": "Integrate LLN-Robot with SalesBlink",
    "metaDescription": "Integrate LLN-Robot and other Online Courses Tools with SalesBlink. Two Way LLN-Robot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Infraspeak",
    "slug": "infraspeak",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e112035ef7ef98c0899863682e7a06a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infraspeak is a mobile and cloud-based platform that helps maintenance/facility managers and technicians track and manage assets, buildings and locations.\nHighly customisable, Infraspeak centralises stock, reduces costs, manages corrective and preventive maintenance.",
    "category": "Task Management",
    "title": "Infraspeak Integration With Cold Email Sales Tool",
    "h1": "Integrate Infraspeak with SalesBlink",
    "metaDescription": "Integrate Infraspeak and other Task Management Tools with SalesBlink. Two Way Infraspeak Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MakePlans",
    "slug": "makeplans",
    "logo": "https://zapier-images.imgix.net/storage/developer/38794f5aa1bdd01602637174c9af85a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MakePlans is for online appointment booking, scheduling and signups for classes/events.",
    "category": "Scheduling & Booking",
    "title": "MakePlans Integration With Cold Email Sales Tool",
    "h1": "Integrate MakePlans with SalesBlink",
    "metaDescription": "Integrate MakePlans and other Scheduling & Booking Tools with SalesBlink. Two Way MakePlans Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sunivo",
    "slug": "sunivo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/076bc26de177601b56dd7e83a501104d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sunivo is an All-In-One virtual solar sales platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sunivo Integration With Cold Email Sales Tool",
    "h1": "Integrate Sunivo with SalesBlink",
    "metaDescription": "Integrate Sunivo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sunivo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TMetric",
    "slug": "tmetric",
    "logo": "https://zapier-images.imgix.net/storage/services/15ce9651330d91d1d9a3da65189bbdb3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Work time tracker for freelancers and teams. Simple tracking, lots of integrations, billable rates, budgets, reporting, and monitoring.",
    "category": "Time Tracking Software",
    "title": "TMetric Integration With Cold Email Sales Tool",
    "h1": "Integrate TMetric with SalesBlink",
    "metaDescription": "Integrate TMetric and other Time Tracking Software Tools with SalesBlink. Two Way TMetric Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ChargeKeep",
    "slug": "chargekeep",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a981561431e555e4d5e355d3546f368e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChargeKeep is lightweight, easy-to-use recurring payments software.",
    "category": "Payment Processing",
    "title": "ChargeKeep Integration With Cold Email Sales Tool",
    "h1": "Integrate ChargeKeep with SalesBlink",
    "metaDescription": "Integrate ChargeKeep and other Payment Processing Tools with SalesBlink. Two Way ChargeKeep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quizitri",
    "slug": "quizitri",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a127fa21a89ac269c0341ab3a141266a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quizitri is an app that lets you easily build attractive quizzes for lead segmentation.",
    "category": "Marketing",
    "title": "Quizitri Integration With Cold Email Sales Tool",
    "h1": "Integrate Quizitri with SalesBlink",
    "metaDescription": "Integrate Quizitri and other Marketing Tools with SalesBlink. Two Way Quizitri Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Filepass",
    "slug": "filepass",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42d6de739a52850d0dd06300263a73f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Filepass is file sharing built for the audio industry.",
    "category": "File Management & Storage",
    "title": "Filepass Integration With Cold Email Sales Tool",
    "h1": "Integrate Filepass with SalesBlink",
    "metaDescription": "Integrate Filepass and other File Management & Storage Tools with SalesBlink. Two Way Filepass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DisputeBee",
    "slug": "disputebee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/092800cd692f823e8b103a21d33075b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DisputeBee is a professional credit repair software platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DisputeBee Integration With Cold Email Sales Tool",
    "h1": "Integrate DisputeBee with SalesBlink",
    "metaDescription": "Integrate DisputeBee and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DisputeBee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hailer",
    "slug": "hailer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/14857f9c07334e029564f8aa384e9200.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hailer is a tool that helps people be more productive and organized together. It contains modular process management, discussions, calendars and social feeds.",
    "category": "Project Management",
    "title": "Hailer Integration With Cold Email Sales Tool",
    "h1": "Integrate Hailer with SalesBlink",
    "metaDescription": "Integrate Hailer and other Project Management Tools with SalesBlink. Two Way Hailer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Firmao",
    "slug": "firmao",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e91c9c62bd8aa18c8b0eb4a82194a7bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CRM and ERP software for your business to manage customers and tasks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Firmao Integration With Cold Email Sales Tool",
    "h1": "Integrate Firmao with SalesBlink",
    "metaDescription": "Integrate Firmao and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Firmao Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eForma",
    "slug": "eforma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e3ff333f46065cbd3d762a24fa4613a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Global E-learning solution covering the course of learners from commercial management to the training itself thanks to off-the-shelf content.",
    "category": "CRM (Customer Relationship Management)",
    "title": "eForma Integration With Cold Email Sales Tool",
    "h1": "Integrate eForma with SalesBlink",
    "metaDescription": "Integrate eForma and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way eForma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IPInfo",
    "slug": "ipinfo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27d5f9ca684cf50d53da301c8e3b264f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The trusted source for IP address data that provides information about geolocation, ASN, company details, career details, abuse information, hosted domains and much more.",
    "category": "Developer Tools",
    "title": "IPInfo Integration With Cold Email Sales Tool",
    "h1": "Integrate IPInfo with SalesBlink",
    "metaDescription": "Integrate IPInfo and other Developer Tools Tools with SalesBlink. Two Way IPInfo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Donation Manager RedCloud",
    "slug": "donation-manager-redcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/b7e11ca6b066b9e79119d7a2d450751f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Donation Manager RedCloud enables charities to manage all online and offline donations.",
    "category": "Fundraising",
    "title": "Donation Manager RedCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Donation Manager RedCloud with SalesBlink",
    "metaDescription": "Integrate Donation Manager RedCloud and other Fundraising Tools with SalesBlink. Two Way Donation Manager RedCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Savio",
    "slug": "savio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0bba7f3d6b6bf4bd83347fb3c62e0dc3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Savio tracks feature requests from survey and NPS tools, help desks, CRMs, or from any customer-facing tool where you receive them.",
    "category": "Product Management",
    "title": "Savio Integration With Cold Email Sales Tool",
    "h1": "Integrate Savio with SalesBlink",
    "metaDescription": "Integrate Savio and other Product Management Tools with SalesBlink. Two Way Savio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xzazu",
    "slug": "xzazu",
    "logo": "https://zapier-images.imgix.net/storage/services/7f03b800062e2ecef0fb2a752bac12c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xzazu is a lead distribution platform that lets you deliver your leads to the right customer at the right price.",
    "category": "Marketing Automation",
    "title": "Xzazu Integration With Cold Email Sales Tool",
    "h1": "Integrate Xzazu with SalesBlink",
    "metaDescription": "Integrate Xzazu and other Marketing Automation Tools with SalesBlink. Two Way Xzazu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DragApp",
    "slug": "dragapp",
    "logo": "https://zapier-images.imgix.net/storage/services/f2728c8e7fdcfaa07194ca7fc46b07a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drag turns Gmail into a Team Workspace to manage Helpdesk, CRM, tasks and other workflows from your inbox. Built for Google Workspace users.",
    "category": "Email",
    "title": "DragApp Integration With Cold Email Sales Tool",
    "h1": "Integrate DragApp with SalesBlink",
    "metaDescription": "Integrate DragApp and other Email Tools with SalesBlink. Two Way DragApp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GetBusy",
    "slug": "getbusy",
    "logo": "https://zapier-images.imgix.net/storage/services/6865b86ba85307eaf97d1854543f2eef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GetBusy is a secure messaging platform that helps you communicate more productively. It's simply a better way to work with your team and your clients.",
    "category": "Task Management",
    "title": "GetBusy Integration With Cold Email Sales Tool",
    "h1": "Integrate GetBusy with SalesBlink",
    "metaDescription": "Integrate GetBusy and other Task Management Tools with SalesBlink. Two Way GetBusy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commerce Layer",
    "slug": "commerce-layer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe47d564bb9dfc7e2f00a75869278d85.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Commerce Layer is a lightning fast, super flexible and ultra secure REST API that lets you build cutting-edge ecommerce.",
    "category": "eCommerce",
    "title": "Commerce Layer Integration With Cold Email Sales Tool",
    "h1": "Integrate Commerce Layer with SalesBlink",
    "metaDescription": "Integrate Commerce Layer and other eCommerce Tools with SalesBlink. Two Way Commerce Layer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whaller",
    "slug": "whaller",
    "logo": "https://zapier-images.imgix.net/storage/developer/1e5470dee37de44e000c78c0a5b68be8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whaller allows anyone to create their own private social networks. Very usefull for families, companies, schools and any other organizations.",
    "category": "Social Media Accounts",
    "title": "Whaller Integration With Cold Email Sales Tool",
    "h1": "Integrate Whaller with SalesBlink",
    "metaDescription": "Integrate Whaller and other Social Media Accounts Tools with SalesBlink. Two Way Whaller Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesClue",
    "slug": "salesclue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b37b3b035ab28d00a46e15f3420ce3a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesClue - sales document & customer analytics tool",
    "category": "Sales & CRM",
    "title": "SalesClue Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesClue with SalesBlink",
    "metaDescription": "Integrate SalesClue and other Sales & CRM Tools with SalesBlink. Two Way SalesClue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bluetick.io",
    "slug": "bluetickio",
    "logo": "https://zapier-images.imgix.net/storage/developer/4cbbe728e8553824f366b0917d7c0628.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bluetick.io is an automated email followup tool designed to help move leads through your sales funnel.",
    "category": "Email",
    "title": "Bluetick.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Bluetick.io with SalesBlink",
    "metaDescription": "Integrate Bluetick.io and other Email Tools with SalesBlink. Two Way Bluetick.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrafficWave.net",
    "slug": "trafficwavenet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8bcf43649b976947375323dc4a8b1324.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrafficWave is a powerful and easy-to-use email marketing platform that will help you start growing your business and stay connected with your customers.",
    "category": "Email Newsletters",
    "title": "TrafficWave.net Integration With Cold Email Sales Tool",
    "h1": "Integrate TrafficWave.net with SalesBlink",
    "metaDescription": "Integrate TrafficWave.net and other Email Newsletters Tools with SalesBlink. Two Way TrafficWave.net Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Botsify",
    "slug": "botsify",
    "logo": "https://zapier-images.imgix.net/storage/developer/c11cd6db8ce610855bbf807e12bb4139.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Botsify is a platform to create artificial intelligent Chatbots to replace your live support without any coding.",
    "category": "Customer Support",
    "title": "Botsify Integration With Cold Email Sales Tool",
    "h1": "Integrate Botsify with SalesBlink",
    "metaDescription": "Integrate Botsify and other Customer Support Tools with SalesBlink. Two Way Botsify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Climbo",
    "slug": "Climbo",
    "logo": "https://zapier-images.imgix.net/storage/services/ab8129c82f1500e1ffa8d116485cc7e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Climbo is a piece of software for managing, sharing, and generating reviews by invitation.",
    "category": "Reviews",
    "title": "Climbo Integration With Cold Email Sales Tool",
    "h1": "Integrate Climbo with SalesBlink",
    "metaDescription": "Integrate Climbo and other Reviews Tools with SalesBlink. Two Way Climbo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Checkin.no",
    "slug": "checkin-no",
    "logo": "https://zapier-images.imgix.net/storage/services/4f29b0c8030f9b3b629a6b0779ca299e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integration with checkin.no\nTrigger events when a ticket is bought.\nAdd information from the ticket to ex. a CRM or a mailinglist",
    "category": "Event Management",
    "title": "Checkin.no Integration With Cold Email Sales Tool",
    "h1": "Integrate Checkin.no with SalesBlink",
    "metaDescription": "Integrate Checkin.no and other Event Management Tools with SalesBlink. Two Way Checkin.no Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ceipal",
    "slug": "ceipal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00566303c34ef6a1312bf00121af4abe_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ceipal talent hire creating interview scheduling and creating sample note only for ceipal users.",
    "category": "Email",
    "title": "Ceipal Integration With Cold Email Sales Tool",
    "h1": "Integrate Ceipal with SalesBlink",
    "metaDescription": "Integrate Ceipal and other Email Tools with SalesBlink. Two Way Ceipal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intertel Conversa",
    "slug": "intertel-conversa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fabd10a17999d5ccca6463566741dbc0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We put together social media channels in a cloud application, improving customer experience, boost sales and measure user base productivity.",
    "category": "Customer Appreciation",
    "title": "Intertel Conversa Integration With Cold Email Sales Tool",
    "h1": "Integrate Intertel Conversa with SalesBlink",
    "metaDescription": "Integrate Intertel Conversa and other Customer Appreciation Tools with SalesBlink. Two Way Intertel Conversa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kanbanery",
    "slug": "kanbanery",
    "logo": "https://zapier-images.imgix.net/storage/developer/131ed0a2a24afb782a53bbecc891c661.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kanbanery is an easy to use but full-featured online visual project management tool for personal or team use.  Just create task cards and move them through columns as work is completed. A fully-functional free plan is available for personal use.",
    "category": "Project Management",
    "title": "Kanbanery Integration With Cold Email Sales Tool",
    "h1": "Integrate Kanbanery with SalesBlink",
    "metaDescription": "Integrate Kanbanery and other Project Management Tools with SalesBlink. Two Way Kanbanery Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wix Answers",
    "slug": "wix-answers",
    "logo": "https://zapier-images.imgix.net/storage/developer/f2115fdbb7d94fc9dfb8a1f272132f0e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wix Answers is a customer service software that makes it possible and easier for businesses of all sizes to support their customers.",
    "category": "Customer Support",
    "title": "Wix Answers Integration With Cold Email Sales Tool",
    "h1": "Integrate Wix Answers with SalesBlink",
    "metaDescription": "Integrate Wix Answers and other Customer Support Tools with SalesBlink. Two Way Wix Answers Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ZOKO",
    "slug": "zoko",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/613d8dd816036a096a970974482650e3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZOKO is a central hub for all your WhatsApp communication: Sales, Marketing and Customer Support from a single WhatsApp number.",
    "category": "Communication",
    "title": "ZOKO Integration With Cold Email Sales Tool",
    "h1": "Integrate ZOKO with SalesBlink",
    "metaDescription": "Integrate ZOKO and other Communication Tools with SalesBlink. Two Way ZOKO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reverse Contact",
    "slug": "reverse-contact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86ddb5b3dce3ae4f2d0f4bbdce413699_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reverse Contact finds the LinkedIn profiles of your prospects thanks to their email, and recovers all the data.",
    "category": "Sales & CRM",
    "title": "Reverse Contact Integration With Cold Email Sales Tool",
    "h1": "Integrate Reverse Contact with SalesBlink",
    "metaDescription": "Integrate Reverse Contact and other Sales & CRM Tools with SalesBlink. Two Way Reverse Contact Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Uptime.com",
    "slug": "uptime",
    "logo": "https://zapier-images.imgix.net/storage/services/5893e54266b9f29794972b8ef11f5508.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uptime.com checks your website availability at one minute intervals from 30 different locations across 6 continents. Connect your Uptime.com alerts so you can integrate them fully into your workflow.",
    "category": "Server Monitoring",
    "title": "Uptime.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Uptime.com with SalesBlink",
    "metaDescription": "Integrate Uptime.com and other Server Monitoring Tools with SalesBlink. Two Way Uptime.com Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pipeline",
    "slug": "pipeline",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a21942b7d088a341ce7b5098d48d40a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pipeline extends your workflows across teams, departments, and companies.",
    "category": "Team Collaboration",
    "title": "Pipeline Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipeline with SalesBlink",
    "metaDescription": "Integrate Pipeline and other Team Collaboration Tools with SalesBlink. Two Way Pipeline Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cocosign",
    "slug": "cocosign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18c7b1f449a7cc65c2c36d0433c3960b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CocoSign is an easy, affordable alternative to DocuSign, helping SMBs and professionals streamline eSignature workflows immensely and sign contracts 5x faster.",
    "category": "Documents",
    "title": "Cocosign Integration With Cold Email Sales Tool",
    "h1": "Integrate Cocosign with SalesBlink",
    "metaDescription": "Integrate Cocosign and other Documents Tools with SalesBlink. Two Way Cocosign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Second Street",
    "slug": "second-street",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/680bfe48a37c21c6e625bce024e615ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upland Second Street is a Saas audience engagement platform that allows its customers to create interactive promotions to engage and grow their audience.",
    "category": "Forms & Surveys",
    "title": "Second Street Integration With Cold Email Sales Tool",
    "h1": "Integrate Second Street with SalesBlink",
    "metaDescription": "Integrate Second Street and other Forms & Surveys Tools with SalesBlink. Two Way Second Street Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Envoke",
    "slug": "envoke",
    "logo": "https://zapier-images.imgix.net/storage/services/e4c7ab15d5e45c9d4cf3e15b9ef25065_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Envoke is a broadcast email platform for compliance-conscious organizations sending opt-in and mandatory content with an emphasis on data security and subscription management.",
    "category": "Email Newsletters",
    "title": "Envoke Integration With Cold Email Sales Tool",
    "h1": "Integrate Envoke with SalesBlink",
    "metaDescription": "Integrate Envoke and other Email Newsletters Tools with SalesBlink. Two Way Envoke Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pledge It for Charities",
    "slug": "pledge-it-for-charities",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f959d2a6d87ba2f9d731708e284fec5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pledge It creates online fundraising tools for nonprofits that power the world's best peer-to-peer and virtual fitness event campaigns.",
    "category": "Fundraising",
    "title": "Pledge It for Charities Integration With Cold Email Sales Tool",
    "h1": "Integrate Pledge It for Charities with SalesBlink",
    "metaDescription": "Integrate Pledge It for Charities and other Fundraising Tools with SalesBlink. Two Way Pledge It for Charities Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intuiface",
    "slug": "intuiface",
    "logo": "https://zapier-images.imgix.net/storage/developer/7bd013b81aa2041f89b6ded9e90cd7c5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intuiface is a platform for creating, deploying and managing interactive experiences without writing one line of code.",
    "category": "Event Management",
    "title": "Intuiface Integration With Cold Email Sales Tool",
    "h1": "Integrate Intuiface with SalesBlink",
    "metaDescription": "Integrate Intuiface and other Event Management Tools with SalesBlink. Two Way Intuiface Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Newsman",
    "slug": "newsman",
    "logo": "https://zapier-images.imgix.net/storage/developer/c612b69a7603b604a975e7ee78eb6de7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Newsman is an email marketing service provider plus transactional emails under one umbrella with a focus on ecommerce and automation.",
    "category": "Email Newsletters",
    "title": "Newsman Integration With Cold Email Sales Tool",
    "h1": "Integrate Newsman with SalesBlink",
    "metaDescription": "Integrate Newsman and other Email Newsletters Tools with SalesBlink. Two Way Newsman Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workboard",
    "slug": "workboard",
    "logo": "https://zapier-images.imgix.net/storage/developer/95522cc83ad8a6f20d90a76730a8624d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workboard is the team performance app managers choose for themselves: goals, priorities, actions, reports and feedback aligned in one easy app.",
    "category": "HR Talent & Recruitment",
    "title": "Workboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Workboard with SalesBlink",
    "metaDescription": "Integrate Workboard and other HR Talent & Recruitment Tools with SalesBlink. Two Way Workboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "smsadvert.io",
    "slug": "smsadvertio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ca415f1a0a7125b84cda16f81fe0217.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect your phone(s) and use it as a gateway to send SMS messages or send them directly over the smsadvert.io network.",
    "category": "Phone & SMS",
    "title": "smsadvert.io Integration With Cold Email Sales Tool",
    "h1": "Integrate smsadvert.io with SalesBlink",
    "metaDescription": "Integrate smsadvert.io and other Phone & SMS Tools with SalesBlink. Two Way smsadvert.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HighGear",
    "slug": "highgear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/737dcaaa840e136c2049ae1974f77c19_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HighGear is a true no-code workflow platform that allows everyday business users to build enterprise-grade workflow applications to orchestrate work at scale without writing a single line of code.",
    "category": "App Builder",
    "title": "HighGear Integration With Cold Email Sales Tool",
    "h1": "Integrate HighGear with SalesBlink",
    "metaDescription": "Integrate HighGear and other App Builder Tools with SalesBlink. Two Way HighGear Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sked Social",
    "slug": "sked-social",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a81f62970a2e90f45e279da9efb2b849_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sked Social is the complete social media management platform, for managing your social media presence on Instagram, Facebook and Twitter.",
    "category": "Social Media Marketing",
    "title": "Sked Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Sked Social with SalesBlink",
    "metaDescription": "Integrate Sked Social and other Social Media Marketing Tools with SalesBlink. Two Way Sked Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CLOSUM",
    "slug": "closum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b685f0af246c52a4706ae2ee0a3445a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Closum is a inbound marketing platform, focused on small and medium companies to do their own landing pages, newsletters and send sms marketing in a simple way.",
    "category": "Contact Management",
    "title": "CLOSUM Integration With Cold Email Sales Tool",
    "h1": "Integrate CLOSUM with SalesBlink",
    "metaDescription": "Integrate CLOSUM and other Contact Management Tools with SalesBlink. Two Way CLOSUM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Proofly",
    "slug": "proofly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e223b6c42be5964d162bc41792fc78c5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Proofly is a powerful social-proof tool that works on/with any platform to boost your conversions and sales.",
    "category": "Marketing",
    "title": "Proofly Integration With Cold Email Sales Tool",
    "h1": "Integrate Proofly with SalesBlink",
    "metaDescription": "Integrate Proofly and other Marketing Tools with SalesBlink. Two Way Proofly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Prepear",
    "slug": "prepear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3635ae239524377ac77b43f0ef13181e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prepear helps food bloggers sell subscriptions to their content.",
    "category": "Productivity",
    "title": "Prepear Integration With Cold Email Sales Tool",
    "h1": "Integrate Prepear with SalesBlink",
    "metaDescription": "Integrate Prepear and other Productivity Tools with SalesBlink. Two Way Prepear Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Delenta",
    "slug": "delenta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24f305b412ea1dbb886303e1bcfdc357.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Delenta is an all-in-one digital platform for knowledge-based entrepreneurs, such as coaches, mentors, and consultants. Through our platform, you can launch, manage & market your practice with ease and effortless professionalism.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Delenta Integration With Cold Email Sales Tool",
    "h1": "Integrate Delenta with SalesBlink",
    "metaDescription": "Integrate Delenta and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Delenta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Consolto",
    "slug": "consolto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f3d0f31e4befe43588a4038328d7d91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Consolto is a video chat CRM that helps businesses meet clients online -- video-conferencing, scheduling, persistent messaging, invoicing and analytics.",
    "category": "Video Conferencing",
    "title": "Consolto Integration With Cold Email Sales Tool",
    "h1": "Integrate Consolto with SalesBlink",
    "metaDescription": "Integrate Consolto and other Video Conferencing Tools with SalesBlink. Two Way Consolto Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Approval Donkey",
    "slug": "approval-donkey",
    "logo": "https://zapier-images.imgix.net/storage/developer/6804df496e5da4727db69d846541f5ac_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Approval Donkey is an approval workflow tool that surfaces, automates and tracks all your approval, sign-off and decision requests.",
    "category": "Project Management",
    "title": "Approval Donkey Integration With Cold Email Sales Tool",
    "h1": "Integrate Approval Donkey with SalesBlink",
    "metaDescription": "Integrate Approval Donkey and other Project Management Tools with SalesBlink. Two Way Approval Donkey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verifalia",
    "slug": "verifalia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc893d1c380f5280f8b77a540a90c30b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verifalia is a fast and accurate email verification service which identifies deliverable, invalid, or otherwise risky email addresses in real-time: it stops bad and low-quality emails getting into your systems, reduces bounce rates and keeps your campaigns deliverable.",
    "category": "Email",
    "title": "Verifalia Integration With Cold Email Sales Tool",
    "h1": "Integrate Verifalia with SalesBlink",
    "metaDescription": "Integrate Verifalia and other Email Tools with SalesBlink. Two Way Verifalia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RapidReg",
    "slug": "rapidreg",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d64e2302f0a46663d13815293c507da4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RapidReg is a data capture tool for registrations, check-ins, and events.",
    "category": "Scheduling & Booking",
    "title": "RapidReg Integration With Cold Email Sales Tool",
    "h1": "Integrate RapidReg with SalesBlink",
    "metaDescription": "Integrate RapidReg and other Scheduling & Booking Tools with SalesBlink. Two Way RapidReg Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Blipp Reviews",
    "slug": "blipp-reviews",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ffd20aeab9b874c0d2b5466e17093299.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blipp Reviews helps local businesses stand out from the crowd by gathering valuable customer feedback and online reviews.",
    "category": "Marketing Automation",
    "title": "Blipp Reviews Integration With Cold Email Sales Tool",
    "h1": "Integrate Blipp Reviews with SalesBlink",
    "metaDescription": "Integrate Blipp Reviews and other Marketing Automation Tools with SalesBlink. Two Way Blipp Reviews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NotePM",
    "slug": "notepm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe9c63f7df5d0d8df57011f8e5bc7b68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NotePM is a company wiki that is easy for everyone to use.",
    "category": "Team Collaboration",
    "title": "NotePM Integration With Cold Email Sales Tool",
    "h1": "Integrate NotePM with SalesBlink",
    "metaDescription": "Integrate NotePM and other Team Collaboration Tools with SalesBlink. Two Way NotePM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HR Partner",
    "slug": "hr-partner",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd6a27f0e34296bb44c9b2ed0222fcfc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HR Partner is a simple & easy to use HR management system for small to medium businesses worldwide.",
    "category": "Human Resources",
    "title": "HR Partner Integration With Cold Email Sales Tool",
    "h1": "Integrate HR Partner with SalesBlink",
    "metaDescription": "Integrate HR Partner and other Human Resources Tools with SalesBlink. Two Way HR Partner Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "VoIPGRID",
    "slug": "voipgrid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8c35020c56f8f3218aca78626d2d681_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoIPGRID is a cloud telephony platform, enabling telecom providers to deliver high-quality VoIP technology to their customers.",
    "category": "Video & Audio",
    "title": "VoIPGRID Integration With Cold Email Sales Tool",
    "h1": "Integrate VoIPGRID with SalesBlink",
    "metaDescription": "Integrate VoIPGRID and other Video & Audio Tools with SalesBlink. Two Way VoIPGRID Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ScuolaSemplice",
    "slug": "scuolasemplice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27fb0836627a2f531308887328b6376c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ScuolaSemplice is the reference system for the management of private schools, academies and training centers",
    "category": "Productivity",
    "title": "ScuolaSemplice Integration With Cold Email Sales Tool",
    "h1": "Integrate ScuolaSemplice with SalesBlink",
    "metaDescription": "Integrate ScuolaSemplice and other Productivity Tools with SalesBlink. Two Way ScuolaSemplice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wuro",
    "slug": "wuro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0bf30711e013faa7116a80f554996b59_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wuro is an invoicing and management tool for small and medium-sized business.",
    "category": "IT Operations",
    "title": "Wuro Integration With Cold Email Sales Tool",
    "h1": "Integrate Wuro with SalesBlink",
    "metaDescription": "Integrate Wuro and other IT Operations Tools with SalesBlink. Two Way Wuro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flow App",
    "slug": "flow-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2348a868c38e47721cfb8b6ed733ad35.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flow App is the first marketing webinar platform built exclusively for webinars. Not for meetings. You can now host webinars without requiring participants to download complicated software.",
    "category": "Video Conferencing",
    "title": "Flow App Integration With Cold Email Sales Tool",
    "h1": "Integrate Flow App with SalesBlink",
    "metaDescription": "Integrate Flow App and other Video Conferencing Tools with SalesBlink. Two Way Flow App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MasterBase",
    "slug": "masterbase",
    "logo": "https://zapier-images.imgix.net/storage/developer/cafc334d384e2d9699a23dfd16642c0f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MasterBase is a complete software-as-a-service solution, easily accessible and scalable for all your email-marketing needs.",
    "category": "Marketing Automation",
    "title": "MasterBase Integration With Cold Email Sales Tool",
    "h1": "Integrate MasterBase with SalesBlink",
    "metaDescription": "Integrate MasterBase and other Marketing Automation Tools with SalesBlink. Two Way MasterBase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Exact Online Bouw",
    "slug": "exact-bouw7",
    "logo": "https://zapier-images.imgix.net/storage/services/326fe9549345cc3692ec85fb75210123.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Exact Online Bouw is a construction project management platform.",
    "category": "Project Management",
    "title": "Exact Online Bouw Integration With Cold Email Sales Tool",
    "h1": "Integrate Exact Online Bouw with SalesBlink",
    "metaDescription": "Integrate Exact Online Bouw and other Project Management Tools with SalesBlink. Two Way Exact Online Bouw Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Springbot",
    "slug": "springbot",
    "logo": "https://zapier-images.imgix.net/storage/services/f324225de0864b9efbb4be40605a329d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Springbot's Marketing Robotics™ streamlines the process of automating, analyzing and personalizing eCommerce marketing. With its all-in-one platform and data-driven marketing recommendations, Springbot empowers retailers through its core features.",
    "category": "eCommerce",
    "title": "Springbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Springbot with SalesBlink",
    "metaDescription": "Integrate Springbot and other eCommerce Tools with SalesBlink. Two Way Springbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClinchPad",
    "slug": "clinchpad",
    "logo": "https://zapier-images.imgix.net/storage/developer/3031f20929dd651323430f3685e14d68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClinchPad is a modern sales CRM built for small teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ClinchPad Integration With Cold Email Sales Tool",
    "h1": "Integrate ClinchPad with SalesBlink",
    "metaDescription": "Integrate ClinchPad and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ClinchPad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Base64.ai",
    "slug": "base64ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9c4b09fe228821e7c248e45d1decc540_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Base64.ai can extract text, photos, and signatures from all document types",
    "category": "Documents",
    "title": "Base64.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Base64.ai with SalesBlink",
    "metaDescription": "Integrate Base64.ai and other Documents Tools with SalesBlink. Two Way Base64.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Move With Pulse",
    "slug": "move-with-pulse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95e178cd85e1cc6b603c364daff0571f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An all-in-one martial arts membership management software system made for gyms and health clubs of all sizes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Move With Pulse Integration With Cold Email Sales Tool",
    "h1": "Integrate Move With Pulse with SalesBlink",
    "metaDescription": "Integrate Move With Pulse and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Move With Pulse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coordinate",
    "slug": "coordinate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76595fabc6093ac18cf6017d129d35c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coordinate is a collaborative project management platform for you and your customers.",
    "category": "Project Management",
    "title": "Coordinate Integration With Cold Email Sales Tool",
    "h1": "Integrate Coordinate with SalesBlink",
    "metaDescription": "Integrate Coordinate and other Project Management Tools with SalesBlink. Two Way Coordinate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Philips SpeechLive",
    "slug": "philips-speechlive",
    "logo": "https://zapier-images.imgix.net/storage/services/dc0b2638fa606b58ec7fad1f11c6bc69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Philips SpeechLive is a leading cloud-based dictation solution: smartphone recorder included, work from anywhere at anytime. Offering speech recognition, transcription service, browser-based transcription and administration.",
    "category": "Transcription",
    "title": "Philips SpeechLive Integration With Cold Email Sales Tool",
    "h1": "Integrate Philips SpeechLive with SalesBlink",
    "metaDescription": "Integrate Philips SpeechLive and other Transcription Tools with SalesBlink. Two Way Philips SpeechLive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FuseDesk",
    "slug": "fusedesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49c186b271a85502e664dac0072eaf9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FuseDesk is an integrated Help Desk and Messaging Platform for sales and customer support case management.",
    "category": "Customer Support",
    "title": "FuseDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate FuseDesk with SalesBlink",
    "metaDescription": "Integrate FuseDesk and other Customer Support Tools with SalesBlink. Two Way FuseDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cryptolens",
    "slug": "cryptolens",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99f5b8ae646877d7394788faf524e189_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cryptolens helps you to keep track of all instances of your software and enforce a wide range of licensing models.",
    "category": "Developer Tools",
    "title": "Cryptolens Integration With Cold Email Sales Tool",
    "h1": "Integrate Cryptolens with SalesBlink",
    "metaDescription": "Integrate Cryptolens and other Developer Tools Tools with SalesBlink. Two Way Cryptolens Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EasyTranslate",
    "slug": "easytranslate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ddf3a8fcb0d488dbfa0b6e27512557bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyTranslate is a complete Translation Management Software acting as a booster for your translation and content localization processes.",
    "category": "Content & Files",
    "title": "EasyTranslate Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyTranslate with SalesBlink",
    "metaDescription": "Integrate EasyTranslate and other Content & Files Tools with SalesBlink. Two Way EasyTranslate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TillyPay",
    "slug": "tillypay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/853160331968787740153a57740cb7f2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TillyPay is a payment platform that allows you to create single & recurring payment forms just by sharing a link.",
    "category": "Payment Processing",
    "title": "TillyPay Integration With Cold Email Sales Tool",
    "h1": "Integrate TillyPay with SalesBlink",
    "metaDescription": "Integrate TillyPay and other Payment Processing Tools with SalesBlink. Two Way TillyPay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimplyConvert",
    "slug": "simplyconvert",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/80842281d4bc41a5063f0cc9fa9bef50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimplyConvert is an automated intake case-qualification platform powered by an “all-knowing” AI chatbot.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SimplyConvert Integration With Cold Email Sales Tool",
    "h1": "Integrate SimplyConvert with SalesBlink",
    "metaDescription": "Integrate SimplyConvert and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SimplyConvert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whoz",
    "slug": "whoz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f33654b8235440ca51c6396f6f79d2ae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whoz boosts services companies results thanks to its unique staffing optimization solution.",
    "category": "Project Management",
    "title": "Whoz Integration With Cold Email Sales Tool",
    "h1": "Integrate Whoz with SalesBlink",
    "metaDescription": "Integrate Whoz and other Project Management Tools with SalesBlink. Two Way Whoz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Watermelon",
    "slug": "watermelon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9559d50b0e916fcbffc31fe3ebfd39d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Watermelon you create chatbots, manage conversations and collaborate with your team to deliver an unforgettable customer service experience.",
    "category": "Customer Support",
    "title": "Watermelon Integration With Cold Email Sales Tool",
    "h1": "Integrate Watermelon with SalesBlink",
    "metaDescription": "Integrate Watermelon and other Customer Support Tools with SalesBlink. Two Way Watermelon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quill Forms",
    "slug": "quill-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cc48f2b5b10196a5bb7af09e95e1acbc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quill Forms is a top WordPress plugin that lets you create conversational forms easily.",
    "category": "Forms & Surveys",
    "title": "Quill Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Quill Forms with SalesBlink",
    "metaDescription": "Integrate Quill Forms and other Forms & Surveys Tools with SalesBlink. Two Way Quill Forms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Transporters.io",
    "slug": "transporters",
    "logo": "https://zapier-images.imgix.net/storage/developer/08ca28e12f496fcabd8e3ad75c556140_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transporters.io is a booking and business management solution for private hire transport operators.",
    "category": "Scheduling & Booking",
    "title": "Transporters.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Transporters.io with SalesBlink",
    "metaDescription": "Integrate Transporters.io and other Scheduling & Booking Tools with SalesBlink. Two Way Transporters.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NMKR",
    "slug": "nmkr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db49796643a17052c6fdeaa18b4fe744.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NMKR is an NFT Creation and Selling tool using the Cardano blockchain.",
    "category": "Developer Tools",
    "title": "NMKR Integration With Cold Email Sales Tool",
    "h1": "Integrate NMKR with SalesBlink",
    "metaDescription": "Integrate NMKR and other Developer Tools Tools with SalesBlink. Two Way NMKR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudia",
    "slug": "cloudia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/10dbe5ad3d7741edc9a84a71571cc46e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudia is a chatbot platform for healthcare institutions that works in Whatsapp, Instagram, Facebook Messenger and Website.",
    "category": "Communication",
    "title": "Cloudia Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudia with SalesBlink",
    "metaDescription": "Integrate Cloudia and other Communication Tools with SalesBlink. Two Way Cloudia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tribe Social",
    "slug": "tribe-social",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87443c82334bdcfcba3a9d1ea4652ba8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tribe Social is a social video streaming platform for communities.",
    "category": "Content & Files",
    "title": "Tribe Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Tribe Social with SalesBlink",
    "metaDescription": "Integrate Tribe Social and other Content & Files Tools with SalesBlink. Two Way Tribe Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LoyaltyLoop",
    "slug": "loyaltyloop",
    "logo": "https://zapier-images.imgix.net/storage/services/4c4064afa1718eb8a6700fc5390df9a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LoyaltyLoop helps businesses build loyal customer relationships, measure satisfaction, drive online reviews and more.",
    "category": "Marketing Automation",
    "title": "LoyaltyLoop Integration With Cold Email Sales Tool",
    "h1": "Integrate LoyaltyLoop with SalesBlink",
    "metaDescription": "Integrate LoyaltyLoop and other Marketing Automation Tools with SalesBlink. Two Way LoyaltyLoop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Localazy",
    "slug": "localazy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3aeee78bd67fd01c8c7c76af94e65087.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Localazy is a highly automated localization platform allowing anyone to translate their products and content into multiple languages easily.",
    "category": "Developer Tools",
    "title": "Localazy Integration With Cold Email Sales Tool",
    "h1": "Integrate Localazy with SalesBlink",
    "metaDescription": "Integrate Localazy and other Developer Tools Tools with SalesBlink. Two Way Localazy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Malcolm!",
    "slug": "malcolm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee4b6014bcbd586ea078f7e0c504b611.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Malcolm! is a set of easy to use tools that lets your users service, interact and engage with you online.",
    "category": "Customer Support",
    "title": "Malcolm! Integration With Cold Email Sales Tool",
    "h1": "Integrate Malcolm! with SalesBlink",
    "metaDescription": "Integrate Malcolm! and other Customer Support Tools with SalesBlink. Two Way Malcolm! Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Offri",
    "slug": "offri",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1ad8f950ee7ac5f839bc5d7d885e0c5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Offri enables you to create great looking proposals quickly.",
    "category": "Proposal & Invoice Management",
    "title": "Offri Integration With Cold Email Sales Tool",
    "h1": "Integrate Offri with SalesBlink",
    "metaDescription": "Integrate Offri and other Proposal & Invoice Management Tools with SalesBlink. Two Way Offri Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agrello",
    "slug": "agrello",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/459b5df5a091be2ce417643d720979dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All in one e-signing solution for busy people",
    "category": "Signatures",
    "title": "Agrello Integration With Cold Email Sales Tool",
    "h1": "Integrate Agrello with SalesBlink",
    "metaDescription": "Integrate Agrello and other Signatures Tools with SalesBlink. Two Way Agrello Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Squirrel365",
    "slug": "squirrel365",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dae46b37ca5bccc5d6636357062cb8da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Squirrel365 is a no-code tool to build interactive content using spreadsheet logic.",
    "category": "Website & App Building",
    "title": "Squirrel365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Squirrel365 with SalesBlink",
    "metaDescription": "Integrate Squirrel365 and other Website & App Building Tools with SalesBlink. Two Way Squirrel365 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CourseStorm",
    "slug": "coursestorm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c27406cd2d6290b92dfc89cbc2e1238.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CourseStorm is a simple online class registration system to help educational organizations save time and money while increasing enrollments.",
    "category": "Education",
    "title": "CourseStorm Integration With Cold Email Sales Tool",
    "h1": "Integrate CourseStorm with SalesBlink",
    "metaDescription": "Integrate CourseStorm and other Education Tools with SalesBlink. Two Way CourseStorm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tend",
    "slug": "tend",
    "logo": "https://zapier-images.imgix.net/storage/developer/19606959635debe444355da61b71f5ae_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tend is a web analytics tool that makes it easy to see what drives customers.",
    "category": "Website Builders",
    "title": "Tend Integration With Cold Email Sales Tool",
    "h1": "Integrate Tend with SalesBlink",
    "metaDescription": "Integrate Tend and other Website Builders Tools with SalesBlink. Two Way Tend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ConvergeHub",
    "slug": "convergehub",
    "logo": "https://zapier-images.imgix.net/storage/developer/16287a821b14cb07871e0dad94d96dcf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConvergeHub is a new-age converged CRM software that functions as a comprehensive Business Management solution. It manages Sales, Marketing, Support and Billing from within its full-featured and easy-to-use platform. It is ideally suited for fast-growing small to medium businesses, in need for a single broad application rather than multiple standalone systems.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ConvergeHub Integration With Cold Email Sales Tool",
    "h1": "Integrate ConvergeHub with SalesBlink",
    "metaDescription": "Integrate ConvergeHub and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ConvergeHub Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "BuzzBoard Demand",
    "slug": "smbprospector",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/22ab45db12e5b4eb0d75e6bf9cc25ec9_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "For sales operations, marketing operations and demand generation applications, BuzzBoard Demand delivers the best-fit leads and data for crafting highly personalized marketing communications in support of ABM and other campaigns.",
    "category": "Marketing",
    "title": "BuzzBoard Demand Integration With Cold Email Sales Tool",
    "h1": "Integrate BuzzBoard Demand with SalesBlink",
    "metaDescription": "Integrate BuzzBoard Demand and other Marketing Tools with SalesBlink. Two Way BuzzBoard Demand Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yalla Team",
    "slug": "yalla-team",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9735d6d1885319acf10f249db7f0fe52.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yalla is a team collaboration tool made for teams and agencies.",
    "category": "Team Collaboration",
    "title": "Yalla Team Integration With Cold Email Sales Tool",
    "h1": "Integrate Yalla Team with SalesBlink",
    "metaDescription": "Integrate Yalla Team and other Team Collaboration Tools with SalesBlink. Two Way Yalla Team Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "1CRM",
    "slug": "1crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4c3e48818509acd5171d43847b37b0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "1CRM allows you to organize a large part of your business within a single web application, and access that information at any time or place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "1CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate 1CRM with SalesBlink",
    "metaDescription": "Integrate 1CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 1CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Image-Charts",
    "slug": "image-charts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4307d46b26b9a2432e97f06d96c2af24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Image-Charts is a service to generate images of bar charts, line charts, pie chart, QR Code (and much more) to embed  inside PDF reports, emails, and more.",
    "category": "Images & Design",
    "title": "Image-Charts Integration With Cold Email Sales Tool",
    "h1": "Integrate Image-Charts with SalesBlink",
    "metaDescription": "Integrate Image-Charts and other Images & Design Tools with SalesBlink. Two Way Image-Charts Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Samm (APE Mobile)",
    "slug": "ape-mobile",
    "logo": "https://zapier-images.imgix.net/storage/services/9fef391b5d4c9012976e43d34f9260cd.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your site paperwork with APE Mobile! Go paperless and simplify data capture & compliance.",
    "category": "Forms & Surveys",
    "title": "Samm (APE Mobile) Integration With Cold Email Sales Tool",
    "h1": "Integrate Samm (APE Mobile) with SalesBlink",
    "metaDescription": "Integrate Samm (APE Mobile) and other Forms & Surveys Tools with SalesBlink. Two Way Samm (APE Mobile) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Praiz",
    "slug": "praiz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/15195f725321804e7b8e17236ba27b70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Praiz is the video hub for modern teams where you find all your business conversations.",
    "category": "Sales & CRM",
    "title": "Praiz Integration With Cold Email Sales Tool",
    "h1": "Integrate Praiz with SalesBlink",
    "metaDescription": "Integrate Praiz and other Sales & CRM Tools with SalesBlink. Two Way Praiz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datalyse",
    "slug": "datalyse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1722335bb45989bd03325d4a01d95027.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datalyse is the all-in-one Cloud-based CRM, VoIP and Marketing solution your modern Engagement Contact Centre needs.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Datalyse Integration With Cold Email Sales Tool",
    "h1": "Integrate Datalyse with SalesBlink",
    "metaDescription": "Integrate Datalyse and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Datalyse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yuvo",
    "slug": "yuvo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a39b54f42afecd922000be30c6aedf1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yuvo is an all-in-one business productivity optimization solution for every employer of choice.",
    "category": "",
    "title": "Yuvo Integration With Cold Email Sales Tool",
    "h1": "Integrate Yuvo with SalesBlink",
    "metaDescription": "Integrate Yuvo and other  Tools with SalesBlink. Two Way Yuvo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Test IO",
    "slug": "test-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16357f2c9231c9e3d9460bbe2a0878c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Test.io provides a comprehensive range of expertise across multiple areas of software QA testing",
    "category": "IT Operations",
    "title": "Test IO Integration With Cold Email Sales Tool",
    "h1": "Integrate Test IO with SalesBlink",
    "metaDescription": "Integrate Test IO and other IT Operations Tools with SalesBlink. Two Way Test IO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digital Humani",
    "slug": "digital-humani",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d2a4fdfce4415480f4314b4f3e9d9bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital Humani is a reforestation-as-a-service API.",
    "category": "Fundraising",
    "title": "Digital Humani Integration With Cold Email Sales Tool",
    "h1": "Integrate Digital Humani with SalesBlink",
    "metaDescription": "Integrate Digital Humani and other Fundraising Tools with SalesBlink. Two Way Digital Humani Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ElasticWebinar",
    "slug": "elasticwebinar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/397eb27560806f3dec4f3a9f17944a91.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ElasticWebinar is a webinar app that lets you create modern automated webinars.",
    "category": "Webinars",
    "title": "ElasticWebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate ElasticWebinar with SalesBlink",
    "metaDescription": "Integrate ElasticWebinar and other Webinars Tools with SalesBlink. Two Way ElasticWebinar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inventory Shield",
    "slug": "inventory-shield",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b69821131d9ed8961525cf14c0d5f83.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inventory Shield is an app that helps you keep track of your businesses equipment, even if it is spread out across many locations.",
    "category": "Project Management",
    "title": "Inventory Shield Integration With Cold Email Sales Tool",
    "h1": "Integrate Inventory Shield with SalesBlink",
    "metaDescription": "Integrate Inventory Shield and other Project Management Tools with SalesBlink. Two Way Inventory Shield Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kissmetrics",
    "slug": "kissmetrics",
    "logo": "https://zapier-images.imgix.net/storage/developer/cef0d62987b22e2f4da18444b83571fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kissmetrics is a person-based analytics package that makes it insanely easy to track events and funnels online helping you focus on the important bits of your online business.",
    "category": "Ads & Conversion",
    "title": "Kissmetrics Integration With Cold Email Sales Tool",
    "h1": "Integrate Kissmetrics with SalesBlink",
    "metaDescription": "Integrate Kissmetrics and other Ads & Conversion Tools with SalesBlink. Two Way Kissmetrics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Holmes",
    "slug": "holmes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a7913b1e34cca522932ba5abca8d9f0_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Processos e documentos em um único sistema. Mais fácil que BPM e mais poderoso que Kanban.",
    "category": "",
    "title": "Holmes Integration With Cold Email Sales Tool",
    "h1": "Integrate Holmes with SalesBlink",
    "metaDescription": "Integrate Holmes and other  Tools with SalesBlink. Two Way Holmes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swiss QR Invoice",
    "slug": "swiss-qr-invoice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6ec6cdf0974c67398730f8180a55520.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This application allows creation and processing of Swiss QR Bills (including Swiss QR Code). This is app makes use of the Codeblock QR Invoice Cloud REST Services.",
    "category": "Proposal & Invoice Management",
    "title": "Swiss QR Invoice Integration With Cold Email Sales Tool",
    "h1": "Integrate Swiss QR Invoice with SalesBlink",
    "metaDescription": "Integrate Swiss QR Invoice and other Proposal & Invoice Management Tools with SalesBlink. Two Way Swiss QR Invoice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EverTransit",
    "slug": "evertransit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ca88b5814c88388f91d34cadf5e340f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EverTransit is a fleet management tool. It helps you to manage your fleet with powerful tools, built on the newest technology.",
    "category": "Productivity",
    "title": "EverTransit Integration With Cold Email Sales Tool",
    "h1": "Integrate EverTransit with SalesBlink",
    "metaDescription": "Integrate EverTransit and other Productivity Tools with SalesBlink. Two Way EverTransit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Approval Studio",
    "slug": "approval-studio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d55c4ff980059ca2bac939b4ba22707_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Approval Studio is a client-oriented online proofing software for creative teams and everyone working with designs.",
    "category": "Team Collaboration",
    "title": "Approval Studio Integration With Cold Email Sales Tool",
    "h1": "Integrate Approval Studio with SalesBlink",
    "metaDescription": "Integrate Approval Studio and other Team Collaboration Tools with SalesBlink. Two Way Approval Studio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rocket Referrals",
    "slug": "rocket-referrals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4bc84a14e54f27eb6784539356bef3dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rocket Referrals is designed to get clients to stay longer, buy more and refer their friends. You'll see results in under 48 hours.",
    "category": "Marketing Automation",
    "title": "Rocket Referrals Integration With Cold Email Sales Tool",
    "h1": "Integrate Rocket Referrals with SalesBlink",
    "metaDescription": "Integrate Rocket Referrals and other Marketing Automation Tools with SalesBlink. Two Way Rocket Referrals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DoubleLoop",
    "slug": "doubleloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0d2fa3b5fbc3026addb96b80899cefc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DoubleLoop is a modern product changelog. Create a system-of-record for your product iterations. Send the right notifications to the right people.",
    "category": "Product Management",
    "title": "DoubleLoop Integration With Cold Email Sales Tool",
    "h1": "Integrate DoubleLoop with SalesBlink",
    "metaDescription": "Integrate DoubleLoop and other Product Management Tools with SalesBlink. Two Way DoubleLoop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hyperproof",
    "slug": "hyperproof",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b532c28c6a4677d48590618ab6ac2a1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hyperproof is a tool for managing your compliance programs.",
    "category": "Project Management",
    "title": "Hyperproof Integration With Cold Email Sales Tool",
    "h1": "Integrate Hyperproof with SalesBlink",
    "metaDescription": "Integrate Hyperproof and other Project Management Tools with SalesBlink. Two Way Hyperproof Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verdn",
    "slug": "verdn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4cd573aee636d50d2a925b24a9c4cef4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verdn lets companies effortlessly pledge to support tree planting, ocean plastic recovery, and coral restoration with any action or event. End-users can track all of their impact on a timeline.",
    "category": "Fundraising",
    "title": "Verdn Integration With Cold Email Sales Tool",
    "h1": "Integrate Verdn with SalesBlink",
    "metaDescription": "Integrate Verdn and other Fundraising Tools with SalesBlink. Two Way Verdn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hyperstack Certificates",
    "slug": "hyperstack-certificates",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cbc6a20de152af352be68dc83f20c97b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hyperstack helps you easily create and manage digital certificates & badges for your events or courses.",
    "category": "Online Courses",
    "title": "Hyperstack Certificates Integration With Cold Email Sales Tool",
    "h1": "Integrate Hyperstack Certificates with SalesBlink",
    "metaDescription": "Integrate Hyperstack Certificates and other Online Courses Tools with SalesBlink. Two Way Hyperstack Certificates Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HR Cloud",
    "slug": "hr-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/601b0b38d75f3778734211b5bb06e23d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HR Cloud provides seamless On-boarding experience to process new hires.",
    "category": "HR Talent & Recruitment",
    "title": "HR Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate HR Cloud with SalesBlink",
    "metaDescription": "Integrate HR Cloud and other HR Talent & Recruitment Tools with SalesBlink. Two Way HR Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailRush.io",
    "slug": "mailrushio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a580140419dbb30bc9a9abbee15be5b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Export email events and export contact details of prospect that have triggered a tracking event from outreach campaigns at MailRush.io",
    "category": "Sales & CRM",
    "title": "MailRush.io Integration With Cold Email Sales Tool",
    "h1": "Integrate MailRush.io with SalesBlink",
    "metaDescription": "Integrate MailRush.io and other Sales & CRM Tools with SalesBlink. Two Way MailRush.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MadKudu",
    "slug": "madkudu",
    "logo": "https://zapier-images.imgix.net/storage/developer/4b50a8e7f5d8e1f768bddd64f037e897.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy tool to score your leads.",
    "category": "Contact Management",
    "title": "MadKudu Integration With Cold Email Sales Tool",
    "h1": "Integrate MadKudu with SalesBlink",
    "metaDescription": "Integrate MadKudu and other Contact Management Tools with SalesBlink. Two Way MadKudu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ConectoHub",
    "slug": "conectohub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33542c5356de97f955a9a84ab97ffecc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConectoHub is OKR Integrated Agile Work Management OS to managing your tasks, projects and OKRs.",
    "category": "Team Collaboration",
    "title": "ConectoHub Integration With Cold Email Sales Tool",
    "h1": "Integrate ConectoHub with SalesBlink",
    "metaDescription": "Integrate ConectoHub and other Team Collaboration Tools with SalesBlink. Two Way ConectoHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "elink",
    "slug": "elink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25210cbe2dadfd9c298b585c8edb5dfe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Newsletter Creator, Web Page Builder, Bookmark Manager",
    "category": "Email Newsletters",
    "title": "elink Integration With Cold Email Sales Tool",
    "h1": "Integrate elink with SalesBlink",
    "metaDescription": "Integrate elink and other Email Newsletters Tools with SalesBlink. Two Way elink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Social WiFi",
    "slug": "social-wifi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3a77d743bc9e1f8b2da232a5e4cd586.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Social WiFi App is about data flow from Social WiFi system for your usage.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Social WiFi Integration With Cold Email Sales Tool",
    "h1": "Integrate Social WiFi with SalesBlink",
    "metaDescription": "Integrate Social WiFi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Social WiFi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PADMA",
    "slug": "padma-da67847",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1fcac5f87635c53eca3358490793d23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PADMA CRM is the official system for administration of DeROSE Method schools.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PADMA Integration With Cold Email Sales Tool",
    "h1": "Integrate PADMA with SalesBlink",
    "metaDescription": "Integrate PADMA and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PADMA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formly",
    "slug": "formly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/858ea35b0c194a2797275c0d613fe6a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Formly is a Form builder designed for lead generation online. It's the fastest form on your marketing roster",
    "category": "Forms & Surveys",
    "title": "Formly Integration With Cold Email Sales Tool",
    "h1": "Integrate Formly with SalesBlink",
    "metaDescription": "Integrate Formly and other Forms & Surveys Tools with SalesBlink. Two Way Formly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LimbleCMMS",
    "slug": "limblecmms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cbc5dc476ad8344a813e4af168980927_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Limble CMMS is a cloud-based computerized maintenance management system (CMMS) suitable for small to midsize businesses in a variety of industries.",
    "category": "Task Management",
    "title": "LimbleCMMS Integration With Cold Email Sales Tool",
    "h1": "Integrate LimbleCMMS with SalesBlink",
    "metaDescription": "Integrate LimbleCMMS and other Task Management Tools with SalesBlink. Two Way LimbleCMMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Comm100",
    "slug": "comm100",
    "logo": "https://zapier-images.imgix.net/storage/services/7e8904b0fe19ac94d4cd9f99124ec331.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Comm100 is a live chat support software that enables businesses or organizations to communicate with their website visitors in real-time so as to improve conversions, sales, and customer satisfaction.",
    "category": "Customer Support",
    "title": "Comm100 Integration With Cold Email Sales Tool",
    "h1": "Integrate Comm100 with SalesBlink",
    "metaDescription": "Integrate Comm100 and other Customer Support Tools with SalesBlink. Two Way Comm100 Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "IntelligenceBank",
    "slug": "intelligencebank",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/013573769fbb1299f6aa5964035b4c78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IntelligenceBank marketing operations software helps content marketers seamlessly manage digital assets, creative content approvals and compliance, and creative project management.",
    "category": "Marketing",
    "title": "IntelligenceBank Integration With Cold Email Sales Tool",
    "h1": "Integrate IntelligenceBank with SalesBlink",
    "metaDescription": "Integrate IntelligenceBank and other Marketing Tools with SalesBlink. Two Way IntelligenceBank Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contrast",
    "slug": "contrast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/167f8c24e5e65d31a2d770dfcb8d1ddc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create, host and analyze your webinars and videos with an easy-to-use, collaborative video platform.",
    "category": "Webinars",
    "title": "Contrast Integration With Cold Email Sales Tool",
    "h1": "Integrate Contrast with SalesBlink",
    "metaDescription": "Integrate Contrast and other Webinars Tools with SalesBlink. Two Way Contrast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MantisProperty",
    "slug": "mantisproperty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cb0e2eca8daf6355c844b425f8fca463.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MantisProperty gives you an easy-to-use, low cost CRM built specifically for Real Estate Agents and Business Brokers in Australia and New Zealand.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MantisProperty Integration With Cold Email Sales Tool",
    "h1": "Integrate MantisProperty with SalesBlink",
    "metaDescription": "Integrate MantisProperty and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MantisProperty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "xMatters",
    "slug": "xmatters",
    "logo": "https://zapier-images.imgix.net/storage/developer/be44bf184fa17869ce8b6d8ae77e78a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "xMatters industry-leading Digital Service Availability platform helps enterprises prevent, manage and resolve technology IT incidents.",
    "category": "Server Monitoring",
    "title": "xMatters Integration With Cold Email Sales Tool",
    "h1": "Integrate xMatters with SalesBlink",
    "metaDescription": "Integrate xMatters and other Server Monitoring Tools with SalesBlink. Two Way xMatters Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iSoftpull",
    "slug": "isoftpull",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d77b3ebd30b927f9b8fa0471b582a0fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instantly view your customer's Equifax and TransUnion credit reports and FICO Scores with soft credit check technology.",
    "category": "Marketing Automation",
    "title": "iSoftpull Integration With Cold Email Sales Tool",
    "h1": "Integrate iSoftpull with SalesBlink",
    "metaDescription": "Integrate iSoftpull and other Marketing Automation Tools with SalesBlink. Two Way iSoftpull Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nuelink",
    "slug": "nuelink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/01ffc5a20cc9e246a4cfca9cbe462754.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nuelink helps you plan, automate and manage your social media and saves you time to focus on your business while your social media runs itself.",
    "category": "Social Media Marketing",
    "title": "Nuelink Integration With Cold Email Sales Tool",
    "h1": "Integrate Nuelink with SalesBlink",
    "metaDescription": "Integrate Nuelink and other Social Media Marketing Tools with SalesBlink. Two Way Nuelink Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Jasmin",
    "slug": "jasmin",
    "logo": "https://zapier-images.imgix.net/storage/developer/6e206aa64c1facba8b696b204da247ae_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jasmin is a Cloud Management Software by PRIMAVERA BSS that allows you to track your business any time and anywhere. Jasmin allows you to manage sales, payments, stocks, receipts, orders, etc., offering you KPIs and tips for your business.",
    "category": "Accounting",
    "title": "Jasmin Integration With Cold Email Sales Tool",
    "h1": "Integrate Jasmin with SalesBlink",
    "metaDescription": "Integrate Jasmin and other Accounting Tools with SalesBlink. Two Way Jasmin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sweven",
    "slug": "sweven",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e22036b56257988750fdf1ae8dab2e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SWEVEN allows businesses to seamlessly connect and collaborate with your team. This multi-platform and cloud-based SaaS solution gives you the power to receive, manage, assign, track, and bill for work (or product) orders of any trade. With SWEVEN in your workflow, you can bring together clients, vendors, and field staff in one place.",
    "category": "Marketing Automation",
    "title": "Sweven Integration With Cold Email Sales Tool",
    "h1": "Integrate Sweven with SalesBlink",
    "metaDescription": "Integrate Sweven and other Marketing Automation Tools with SalesBlink. Two Way Sweven Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Questmate",
    "slug": "questmate",
    "logo": "https://zapier-images.imgix.net/storage/services/9e5c1dc4cf1994bf89f1d77ae732ca89.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Questmate is an app for managing and sharing recurring tasks, like daily routines or business processes.",
    "category": "Productivity",
    "title": "Questmate Integration With Cold Email Sales Tool",
    "h1": "Integrate Questmate with SalesBlink",
    "metaDescription": "Integrate Questmate and other Productivity Tools with SalesBlink. Two Way Questmate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emailvalidation",
    "slug": "emailvalidation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0248da2dfd5b37cc0f168079212e1657.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emailvalidation is an email verification tool that helps marketers & developers to clean their mailing lists.",
    "category": "Email",
    "title": "Emailvalidation Integration With Cold Email Sales Tool",
    "h1": "Integrate Emailvalidation with SalesBlink",
    "metaDescription": "Integrate Emailvalidation and other Email Tools with SalesBlink. Two Way Emailvalidation Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GrowthZone AMS",
    "slug": "growthzone-ams",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a214fbc47a867d3c6e6a43749944db4a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowthZone AMS helps organizations grow and retain membership, engage and inform members and prospects, and streamline tedious tasks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GrowthZone AMS Integration With Cold Email Sales Tool",
    "h1": "Integrate GrowthZone AMS with SalesBlink",
    "metaDescription": "Integrate GrowthZone AMS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GrowthZone AMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Seidat",
    "slug": "seidat",
    "logo": "https://zapier-images.imgix.net/storage/services/598b8f70bfce0496c891cac6c0fa3bd7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seidat is a sales deck platform for business teams. It has tools for slide deck creation, presenting, sharing and analyzing.",
    "category": "Images & Design",
    "title": "Seidat Integration With Cold Email Sales Tool",
    "h1": "Integrate Seidat with SalesBlink",
    "metaDescription": "Integrate Seidat and other Images & Design Tools with SalesBlink. Two Way Seidat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CustomerX",
    "slug": "customerx-tracking",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30415b376350ee343c580839d9e3dedf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CustomerX is a customer success platform that helps you manage engagement and relationships with your customers",
    "category": "CRM (Customer Relationship Management)",
    "title": "CustomerX Integration With Cold Email Sales Tool",
    "h1": "Integrate CustomerX with SalesBlink",
    "metaDescription": "Integrate CustomerX and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CustomerX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Viable",
    "slug": "viable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc3e0ec6b74c87c7baee187a58556d3b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Viable automates qualitative feedback analysis so business teams can quickly and easily get summarized insights in natural language, powered by GPT-3.",
    "category": "Business Intelligence",
    "title": "Viable Integration With Cold Email Sales Tool",
    "h1": "Integrate Viable with SalesBlink",
    "metaDescription": "Integrate Viable and other Business Intelligence Tools with SalesBlink. Two Way Viable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS Conversations",
    "slug": "sms-conversations",
    "logo": "https://zapier-images.imgix.net/storage/developer/9029385c3dd1004a2bc597eb405b4472.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMS Conversations allows you to automate text and SlyVoicemail messaging to your customers.",
    "category": "Phone & SMS",
    "title": "SMS Conversations Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Conversations with SalesBlink",
    "metaDescription": "Integrate SMS Conversations and other Phone & SMS Tools with SalesBlink. Two Way SMS Conversations Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneCloud",
    "slug": "onecloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33b86971d9b678e883e0c6b1aa5a99b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneCloud is a unified communications platform.",
    "category": "Communication",
    "title": "OneCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate OneCloud with SalesBlink",
    "metaDescription": "Integrate OneCloud and other Communication Tools with SalesBlink. Two Way OneCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Explodely",
    "slug": "explodely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62a5c728ca0de02f13b4a403240b5e7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Explodely is a Digital Retailer & Affiliate Marketplace. Launch & scale your products through our Merchant of Record platform.",
    "category": "eCommerce",
    "title": "Explodely Integration With Cold Email Sales Tool",
    "h1": "Integrate Explodely with SalesBlink",
    "metaDescription": "Integrate Explodely and other eCommerce Tools with SalesBlink. Two Way Explodely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho LandingPage",
    "slug": "zoho-landingpage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/126ab7fd246553370251a5702c247fda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho LandingPage is a smart landing page builder that will help you create, customize, and launch alluring landing pages.",
    "category": "Contact Management",
    "title": "Zoho LandingPage Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho LandingPage with SalesBlink",
    "metaDescription": "Integrate Zoho LandingPage and other Contact Management Tools with SalesBlink. Two Way Zoho LandingPage Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Seam",
    "slug": "seam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/44d5f072815b8d5b478f64e4a885f000.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seam lets you control any smart lock or access system using a single API and dashboard. Unlock doors, grant temporary access, review access logs, and more",
    "category": "Internet of Things",
    "title": "Seam Integration With Cold Email Sales Tool",
    "h1": "Integrate Seam with SalesBlink",
    "metaDescription": "Integrate Seam and other Internet of Things Tools with SalesBlink. Two Way Seam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bookvault",
    "slug": "bookvault",
    "logo": "https://zapier-images.imgix.net/storage/services/0c3c6e36ca8f7366a16ffeb193121329.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bookvault enables you to have your books printed on-demand and shipped direct to your customer.",
    "category": "Printing",
    "title": "Bookvault Integration With Cold Email Sales Tool",
    "h1": "Integrate Bookvault with SalesBlink",
    "metaDescription": "Integrate Bookvault and other Printing Tools with SalesBlink. Two Way Bookvault Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EbulkSMS",
    "slug": "ebulksms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd11b2ef178bdd823a48776ecccd92ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy tool for developers to send bulk SMS from any source using the EbulkSMS API",
    "category": "Phone & SMS",
    "title": "EbulkSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate EbulkSMS with SalesBlink",
    "metaDescription": "Integrate EbulkSMS and other Phone & SMS Tools with SalesBlink. Two Way EbulkSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Passage Ticketing",
    "slug": "passage-ticketing",
    "logo": "https://zapier-images.imgix.net/storage/developer/69067779574c130b6f40e480fa8ca945_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Passage is an online event ticketing platform that helps event organizers sell tickets, merchandise, and concessions both online and at-the-door.",
    "category": "Event Management",
    "title": "Passage Ticketing Integration With Cold Email Sales Tool",
    "h1": "Integrate Passage Ticketing with SalesBlink",
    "metaDescription": "Integrate Passage Ticketing and other Event Management Tools with SalesBlink. Two Way Passage Ticketing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FunnelFLARE",
    "slug": "funnelflare",
    "logo": "https://zapier-images.imgix.net/storage/developer/401dfc26dab97f3c5709d9d18191276b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FunnelFLARE is a sales process automation platform that helps sales people sell faster by automating the common tasks that sap away profitable selling time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FunnelFLARE Integration With Cold Email Sales Tool",
    "h1": "Integrate FunnelFLARE with SalesBlink",
    "metaDescription": "Integrate FunnelFLARE and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FunnelFLARE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PlayFilm",
    "slug": "playfilm",
    "logo": "https://zapier-images.imgix.net/storage/developer/86763c8071622ca3871df6e5ba2f5989.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlayFilm is the tool to easily create video tests and capture qualified contacts. Optimize your conversion rate and generate more leads.",
    "category": "Video & Audio",
    "title": "PlayFilm Integration With Cold Email Sales Tool",
    "h1": "Integrate PlayFilm with SalesBlink",
    "metaDescription": "Integrate PlayFilm and other Video & Audio Tools with SalesBlink. Two Way PlayFilm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "incwo",
    "slug": "incwo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/554c9e7c7388c0b743781505d1bd9979.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "incwo.com is a CRM and invoicing platform for SMEs",
    "category": "CRM (Customer Relationship Management)",
    "title": "incwo Integration With Cold Email Sales Tool",
    "h1": "Integrate incwo with SalesBlink",
    "metaDescription": "Integrate incwo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way incwo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeskMoz",
    "slug": "deskmoz",
    "logo": "https://zapier-images.imgix.net/storage/developer/0d66b092127eda007e531ab8af0d3706_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeskMoz is 24x7 live chat agent service provider that helps you in generating sales leads and providing support to new and existing customers.",
    "category": "Customer Support",
    "title": "DeskMoz Integration With Cold Email Sales Tool",
    "h1": "Integrate DeskMoz with SalesBlink",
    "metaDescription": "Integrate DeskMoz and other Customer Support Tools with SalesBlink. Two Way DeskMoz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tanda",
    "slug": "tanda",
    "logo": "https://zapier-images.imgix.net/storage/services/70dea8cf16141ff838ca8f947f5bea66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tanda is a Workforce Management tool to build rosters, track attendance, manage leave and grow your business.",
    "category": "HR Talent & Recruitment",
    "title": "Tanda Integration With Cold Email Sales Tool",
    "h1": "Integrate Tanda with SalesBlink",
    "metaDescription": "Integrate Tanda and other HR Talent & Recruitment Tools with SalesBlink. Two Way Tanda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RowShare",
    "slug": "rowshare",
    "logo": "https://zapier-images.imgix.net/storage/developer/e3e50381babae824ac52692c2b1ccc23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unify your Distributed Operations Data with RowShare. The collaborative tool that simplifies and automates data collection and analysis.",
    "category": "Spreadsheets",
    "title": "RowShare Integration With Cold Email Sales Tool",
    "h1": "Integrate RowShare with SalesBlink",
    "metaDescription": "Integrate RowShare and other Spreadsheets Tools with SalesBlink. Two Way RowShare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gratavid",
    "slug": "gratavid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50694af3254466204b08406f85b41173_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gratavid is a video service that lets you create and send personalized videos, beautifully delivered via email and text message. Update supporters, promote events, and thank your biggest advocates with video.",
    "category": "Video & Audio",
    "title": "Gratavid Integration With Cold Email Sales Tool",
    "h1": "Integrate Gratavid with SalesBlink",
    "metaDescription": "Integrate Gratavid and other Video & Audio Tools with SalesBlink. Two Way Gratavid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scan2Lead",
    "slug": "scan2lead-ca129799",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f39a7b04ae9bf95b4d7939cae550b32.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scan2Lead is a system to easily capture leads on trade shows and congresses.",
    "category": "Contact Management",
    "title": "Scan2Lead Integration With Cold Email Sales Tool",
    "h1": "Integrate Scan2Lead with SalesBlink",
    "metaDescription": "Integrate Scan2Lead and other Contact Management Tools with SalesBlink. Two Way Scan2Lead Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Event Calendar App",
    "slug": "event-calendar-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/364b6388d138a5e35ea9e3f663282f80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An Event Calendar for marketing and selling your events. Beautifully.",
    "category": "Event Management",
    "title": "Event Calendar App Integration With Cold Email Sales Tool",
    "h1": "Integrate Event Calendar App with SalesBlink",
    "metaDescription": "Integrate Event Calendar App and other Event Management Tools with SalesBlink. Two Way Event Calendar App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Variance",
    "slug": "variance",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ea215223ca84bb4f92aaf933088b090.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Variance hooks into your customer data and makes it easy to access growth signals across product, marketing, and sales.",
    "category": "Productivity",
    "title": "Variance Integration With Cold Email Sales Tool",
    "h1": "Integrate Variance with SalesBlink",
    "metaDescription": "Integrate Variance and other Productivity Tools with SalesBlink. Two Way Variance Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DigitalChalk",
    "slug": "digitalchalk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef42a996285b762e8296f7a3f8442847_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DigitalChalk by Sciolytix is an award-winning LMS for delivering corporate training and powering profitable continuing education businesses.",
    "category": "Online Courses",
    "title": "DigitalChalk Integration With Cold Email Sales Tool",
    "h1": "Integrate DigitalChalk with SalesBlink",
    "metaDescription": "Integrate DigitalChalk and other Online Courses Tools with SalesBlink. Two Way DigitalChalk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anymail finder",
    "slug": "anymail-finder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/333c4df81d9c10bc56ed98429b4cfc2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anymail is a sales and marketing tool that allows you to find anybody's email address.",
    "category": "Email",
    "title": "Anymail finder Integration With Cold Email Sales Tool",
    "h1": "Integrate Anymail finder with SalesBlink",
    "metaDescription": "Integrate Anymail finder and other Email Tools with SalesBlink. Two Way Anymail finder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProspectX",
    "slug": "xsite",
    "logo": "https://zapier-images.imgix.net/storage/services/220d11951c2df6e5589a7372e3334b24.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProspectX is an all-in-one lead generation, conversion and Automation for Real Estate Investors.",
    "category": "Sales & CRM",
    "title": "ProspectX Integration With Cold Email Sales Tool",
    "h1": "Integrate ProspectX with SalesBlink",
    "metaDescription": "Integrate ProspectX and other Sales & CRM Tools with SalesBlink. Two Way ProspectX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZyraTalk",
    "slug": "zyratalk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a6413bea3a3e2adb9a2b357b038acd5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZyraTalk is a customer interaction platform that allows for automated conversations to take place anywhere.",
    "category": "Customer Support",
    "title": "ZyraTalk Integration With Cold Email Sales Tool",
    "h1": "Integrate ZyraTalk with SalesBlink",
    "metaDescription": "Integrate ZyraTalk and other Customer Support Tools with SalesBlink. Two Way ZyraTalk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lingoes.ai",
    "slug": "lingoesai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb96b4b2ba785cf26762171722899d64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lingoes.ai is a multilingual text analytics service that allows you to extract valuable data from raw texts like emails, documents and more.",
    "category": "Analytics",
    "title": "Lingoes.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Lingoes.ai with SalesBlink",
    "metaDescription": "Integrate Lingoes.ai and other Analytics Tools with SalesBlink. Two Way Lingoes.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClickTime",
    "slug": "clicktime",
    "logo": "https://zapier-images.imgix.net/storage/services/3b36d48513cbd7bb9af45932417a3a5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickTime is an Online Timesheets, Employee Resource Management, and Expense software.",
    "category": "Time Tracking Software",
    "title": "ClickTime Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickTime with SalesBlink",
    "metaDescription": "Integrate ClickTime and other Time Tracking Software Tools with SalesBlink. Two Way ClickTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reviews On My Website",
    "slug": "reviews-on-my-website",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a53d499041a449b5f317c095b43279c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect, monitor and display customer reviews for your business directly on your website.",
    "category": "Marketing",
    "title": "Reviews On My Website Integration With Cold Email Sales Tool",
    "h1": "Integrate Reviews On My Website with SalesBlink",
    "metaDescription": "Integrate Reviews On My Website and other Marketing Tools with SalesBlink. Two Way Reviews On My Website Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "jeudimerci",
    "slug": "jeudimerci",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d933625c0329b351e79441adbe66ba4f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "jeudimerci is a gifting platform that lets you engage your customers with physical sends, conveniently.",
    "category": "Marketing Automation",
    "title": "jeudimerci Integration With Cold Email Sales Tool",
    "h1": "Integrate jeudimerci with SalesBlink",
    "metaDescription": "Integrate jeudimerci and other Marketing Automation Tools with SalesBlink. Two Way jeudimerci Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "viewneo",
    "slug": "viewneo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/373e811b0b439d3cefc1c806243e9b57_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "viewneo is a proprietary, cloud-based Digital Signage Software.",
    "category": "Images & Design",
    "title": "viewneo Integration With Cold Email Sales Tool",
    "h1": "Integrate viewneo with SalesBlink",
    "metaDescription": "Integrate viewneo and other Images & Design Tools with SalesBlink. Two Way viewneo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simple Sign",
    "slug": "simple-sign",
    "logo": "https://zapier-images.imgix.net/storage/services/ed93f6b84ec1105cca396cde6198cb7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple Sign is a service for sending and signing contracts digitally.",
    "category": "Signatures",
    "title": "Simple Sign Integration With Cold Email Sales Tool",
    "h1": "Integrate Simple Sign with SalesBlink",
    "metaDescription": "Integrate Simple Sign and other Signatures Tools with SalesBlink. Two Way Simple Sign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VideoPeel",
    "slug": "videopeel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f180855ff9c3e9c9bb208ecf576c04e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VideoPeel enables businesses to request, capture, collect, share, and analyze videos from their customers, patients, students, and employees.",
    "category": "Forms & Surveys",
    "title": "VideoPeel Integration With Cold Email Sales Tool",
    "h1": "Integrate VideoPeel with SalesBlink",
    "metaDescription": "Integrate VideoPeel and other Forms & Surveys Tools with SalesBlink. Two Way VideoPeel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartEngage",
    "slug": "smartengage",
    "logo": "https://zapier-images.imgix.net/storage/developer/ee3eb2c8c7c2cb7ed03edf89c6353443_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engagement Automation Is The Future of Marketing. SmartEngage is an email autoresponder, Facebook messenger, and web push notification tool, which links all 3 platforms for the highest deliverability possible.",
    "category": "Marketing",
    "title": "SmartEngage Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartEngage with SalesBlink",
    "metaDescription": "Integrate SmartEngage and other Marketing Tools with SalesBlink. Two Way SmartEngage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Campaignware",
    "slug": "campaignware",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c6ffd2bb7f1c915a0c72cf9ef3112750.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Campaignware is a digital content creation platform that let's you create gamified, interactive content and help you grow your email list.",
    "category": "Marketing",
    "title": "Campaignware Integration With Cold Email Sales Tool",
    "h1": "Integrate Campaignware with SalesBlink",
    "metaDescription": "Integrate Campaignware and other Marketing Tools with SalesBlink. Two Way Campaignware Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SphereMail",
    "slug": "spheremail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2c69117cd9419dbc488821d9724e2602.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SphereMail's is a virtual office platform that makes it easier for mail administrators and customers to access, view and manage mail online or from a mobile device.",
    "category": "Scheduling & Booking",
    "title": "SphereMail Integration With Cold Email Sales Tool",
    "h1": "Integrate SphereMail with SalesBlink",
    "metaDescription": "Integrate SphereMail and other Scheduling & Booking Tools with SalesBlink. Two Way SphereMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalonBridge",
    "slug": "salonbridge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9060916d7c7121083655214c938432a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalonBridge lets you manage your appointments, accept online bookings, keep track of clients, payments, reports, and client reminders from the web, or from your smartphone.",
    "category": "Scheduling & Booking",
    "title": "SalonBridge Integration With Cold Email Sales Tool",
    "h1": "Integrate SalonBridge with SalesBlink",
    "metaDescription": "Integrate SalonBridge and other Scheduling & Booking Tools with SalesBlink. Two Way SalonBridge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Everee",
    "slug": "everee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f988567f07673eb037dc9cae9d3cc75f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Everee is a payroll platform for humans.",
    "category": "Payment Processing",
    "title": "Everee Integration With Cold Email Sales Tool",
    "h1": "Integrate Everee with SalesBlink",
    "metaDescription": "Integrate Everee and other Payment Processing Tools with SalesBlink. Two Way Everee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoTo Training",
    "slug": "gototraining",
    "logo": "https://zapier-images.imgix.net/storage/developer/ff1a44e3f58a281ec16c79914111cf76.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoTo Training by LogMeIn is an online training software that enables enterprises and individuals to provide interactive training sessions to both employees and customers, regardless of location.",
    "category": "Online Courses",
    "title": "GoTo Training Integration With Cold Email Sales Tool",
    "h1": "Integrate GoTo Training with SalesBlink",
    "metaDescription": "Integrate GoTo Training and other Online Courses Tools with SalesBlink. Two Way GoTo Training Integration for all your data related to sales & cold email.",
    "zapier": true,
    "pabblyConnect": true
  },
  {
    "name": "Getquanty",
    "slug": "getquanty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/477dc911e87f86449649c273ec81b649.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Getquanty is a B2B prospection & predictive marketing solution. Identify your website visitors easily and optimize your marketing campaigns.",
    "category": "Marketing Automation",
    "title": "Getquanty Integration With Cold Email Sales Tool",
    "h1": "Integrate Getquanty with SalesBlink",
    "metaDescription": "Integrate Getquanty and other Marketing Automation Tools with SalesBlink. Two Way Getquanty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TotalCoaching",
    "slug": "totalcoaching",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c7a0ad0db46def170da0e974cd7666e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TotalCoaching is a web-based software for personal trainers, allowing them to build programs online, and deliver them to their clients smartphone.",
    "category": "Fitness",
    "title": "TotalCoaching Integration With Cold Email Sales Tool",
    "h1": "Integrate TotalCoaching with SalesBlink",
    "metaDescription": "Integrate TotalCoaching and other Fitness Tools with SalesBlink. Two Way TotalCoaching Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octorate",
    "slug": "octorate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b0128df335f431158cf2c4fba6e7eae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octorate is a simple but powerful and affordable all-in-one solution for small structures which can be accessed from any mobile device",
    "category": "Scheduling & Booking",
    "title": "Octorate Integration With Cold Email Sales Tool",
    "h1": "Integrate Octorate with SalesBlink",
    "metaDescription": "Integrate Octorate and other Scheduling & Booking Tools with SalesBlink. Two Way Octorate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lessonspace",
    "slug": "lessonspace",
    "logo": "https://zapier-images.imgix.net/storage/services/39736d301d3883764ea5275d8ac5fb74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The best way to teach online. Teach live, one-on-one, or with a group, using the most versatile collaborative space for online lessons.",
    "category": "Team Collaboration",
    "title": "Lessonspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Lessonspace with SalesBlink",
    "metaDescription": "Integrate Lessonspace and other Team Collaboration Tools with SalesBlink. Two Way Lessonspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Postal.io",
    "slug": "postalio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86221fc37e24d7968d3d2906b07fa3f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Postal.io automates the offline for Sales & Marketing",
    "category": "Marketing",
    "title": "Postal.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Postal.io with SalesBlink",
    "metaDescription": "Integrate Postal.io and other Marketing Tools with SalesBlink. Two Way Postal.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RoboHead",
    "slug": "robohead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf5343d80227e919275352e35d9387d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RoboHead is a project management solution that helps teams focus on their people, deadlines, and creative deliverables.",
    "category": "Project Management",
    "title": "RoboHead Integration With Cold Email Sales Tool",
    "h1": "Integrate RoboHead with SalesBlink",
    "metaDescription": "Integrate RoboHead and other Project Management Tools with SalesBlink. Two Way RoboHead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cycle",
    "slug": "cycle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d342533115b3aecb4eab961eee287f1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cycle connects customer feedback to product delivery workflows so you can close the feedback loop and wow your customers at each release.",
    "category": "Product Management",
    "title": "Cycle Integration With Cold Email Sales Tool",
    "h1": "Integrate Cycle with SalesBlink",
    "metaDescription": "Integrate Cycle and other Product Management Tools with SalesBlink. Two Way Cycle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Framework",
    "slug": "framework",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b02e2841494aa375cc4fa199413e3cbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Framework builds the digital infrastructure for goal-based communities. Create branded membership-based virtual wellness programs that bring together community, coaching, and content.",
    "category": "Website & App Building",
    "title": "Framework Integration With Cold Email Sales Tool",
    "h1": "Integrate Framework with SalesBlink",
    "metaDescription": "Integrate Framework and other Website & App Building Tools with SalesBlink. Two Way Framework Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Remo Conference",
    "slug": "remo-conference",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86e96d4b269094a6fb896ca1d012d4a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Remo Conference is creating stronger impressions and higher engagement.",
    "category": "Event Management",
    "title": "Remo Conference Integration With Cold Email Sales Tool",
    "h1": "Integrate Remo Conference with SalesBlink",
    "metaDescription": "Integrate Remo Conference and other Event Management Tools with SalesBlink. Two Way Remo Conference Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UPilot",
    "slug": "upilot",
    "logo": "https://zapier-images.imgix.net/storage/developer/bcb277afb8f493dc89e1fdfe4e749fd6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UPilot is a CRM with advanced features like role-based access and control, email tracking, revenue forecast, all on a single platform and easy to use interface, resulting in enthusiastic user adoption in fast-growing companies.",
    "category": "CRM (Customer Relationship Management)",
    "title": "UPilot Integration With Cold Email Sales Tool",
    "h1": "Integrate UPilot with SalesBlink",
    "metaDescription": "Integrate UPilot and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way UPilot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iListTech",
    "slug": "ilisttech",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/659faa208e78803212a2ba4307c5e6d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iListTech is a tool to import new leads from your source",
    "category": "Sales & CRM",
    "title": "iListTech Integration With Cold Email Sales Tool",
    "h1": "Integrate iListTech with SalesBlink",
    "metaDescription": "Integrate iListTech and other Sales & CRM Tools with SalesBlink. Two Way iListTech Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WotNot",
    "slug": "wotnot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3693ccdc69d6e445474e5af8dda81bb_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "It's easy to build bots, but hard to get the desired results. This is where WotNot steps in, providing a done-for-you service. We build a pipeline of qualified leads, automate your support, without increasing headcount with our no-code chatbot platform.",
    "category": "Customer Support",
    "title": "WotNot Integration With Cold Email Sales Tool",
    "h1": "Integrate WotNot with SalesBlink",
    "metaDescription": "Integrate WotNot and other Customer Support Tools with SalesBlink. Two Way WotNot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pocketlaw",
    "slug": "pocketlaw",
    "logo": "https://zapier-images.imgix.net/storage/services/1f4a5aab97ceeeea7e469d57a3a00832.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Minimise legal. Maximise work.\nOptimize your everyday legal processes with Pocketlaw.",
    "category": "Notifications",
    "title": "Pocketlaw Integration With Cold Email Sales Tool",
    "h1": "Integrate Pocketlaw with SalesBlink",
    "metaDescription": "Integrate Pocketlaw and other Notifications Tools with SalesBlink. Two Way Pocketlaw Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teyuto",
    "slug": "teyuto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc828a1dbe053c6f2a08fa5eebfa087d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teyuto is a service that allows you to create a full custom video ondemand platform to distribute and monetize videos.",
    "category": "Video & Audio",
    "title": "Teyuto Integration With Cold Email Sales Tool",
    "h1": "Integrate Teyuto with SalesBlink",
    "metaDescription": "Integrate Teyuto and other Video & Audio Tools with SalesBlink. Two Way Teyuto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MessageDesk",
    "slug": "messagedesk",
    "logo": "https://zapier-images.imgix.net/storage/services/3ee68ae7de4197bb99d918efe6e16b42.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MessageDesk is a text messaging service that allows you to schedule text messages, save templates, automate recurring message sends, send out mass broadcast sends and more",
    "category": "Communication",
    "title": "MessageDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate MessageDesk with SalesBlink",
    "metaDescription": "Integrate MessageDesk and other Communication Tools with SalesBlink. Two Way MessageDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Botster",
    "slug": "botster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8fe2c4accc8b599758febea8684abc84.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Botster helps businesses automate routine tasks with zero code bots.",
    "category": "Marketing Automation",
    "title": "Botster Integration With Cold Email Sales Tool",
    "h1": "Integrate Botster with SalesBlink",
    "metaDescription": "Integrate Botster and other Marketing Automation Tools with SalesBlink. Two Way Botster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MoEngage",
    "slug": "moengage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9fafeaad5ad09304697b71f913e365c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MoEngage is a full-stack solution consisting of powerful customer analytics, automated cross-channel engagement and AI-driven personalization.",
    "category": "Marketing Automation",
    "title": "MoEngage Integration With Cold Email Sales Tool",
    "h1": "Integrate MoEngage with SalesBlink",
    "metaDescription": "Integrate MoEngage and other Marketing Automation Tools with SalesBlink. Two Way MoEngage Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ElectroNeek RPA",
    "slug": "electroneek-rpa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f4f5df1d64273af74c441eff44d54a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ElectroNeek is a low-code RPA platform to automate routine tasks and free human resources.",
    "category": "IT Operations",
    "title": "ElectroNeek RPA Integration With Cold Email Sales Tool",
    "h1": "Integrate ElectroNeek RPA with SalesBlink",
    "metaDescription": "Integrate ElectroNeek RPA and other IT Operations Tools with SalesBlink. Two Way ElectroNeek RPA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Click2Mail",
    "slug": "click2mail",
    "logo": "https://zapier-images.imgix.net/storage/developer/d977a7b55ab8d12c0d9c0e718565fc74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Click2Mail provides a printing and mailing service tool for you to add physical mail to your processes. Send everything from marketing letters to invoices, follow-up postcards, product alerts, and more.",
    "category": "Printing",
    "title": "Click2Mail Integration With Cold Email Sales Tool",
    "h1": "Integrate Click2Mail with SalesBlink",
    "metaDescription": "Integrate Click2Mail and other Printing Tools with SalesBlink. Two Way Click2Mail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reflexion.ai",
    "slug": "reflexionai",
    "logo": "https://zapier-images.imgix.net/storage/services/27db6d27df125be50070f53fe6772048.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reflexion.AI is a content collaboration & AI platform which supports all types of content - videos, audios, photos & documents. Content collaboration & review helps with efficient communication between teams.",
    "category": "Team Collaboration",
    "title": "Reflexion.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Reflexion.ai with SalesBlink",
    "metaDescription": "Integrate Reflexion.ai and other Team Collaboration Tools with SalesBlink. Two Way Reflexion.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CivicEngage",
    "slug": "civicengage",
    "logo": "https://zapier-images.imgix.net/storage/developer/c07c05bc3735b28e81668ff421924ba7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CivicEngage is a local-government website design and hosting platform. This platform is used by 2,500+ clients and 55,000+ local government users. CivicEngage is one product in the larger CivicPlus integrated technology platform for local government.",
    "category": "Website Builders",
    "title": "CivicEngage Integration With Cold Email Sales Tool",
    "h1": "Integrate CivicEngage with SalesBlink",
    "metaDescription": "Integrate CivicEngage and other Website Builders Tools with SalesBlink. Two Way CivicEngage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crowdin",
    "slug": "crowdin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d90f00e2687ea162f3227716446743b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crowdin is a cloud-based solution that streamlines localization management for your team. It's the perfect place to effectively manage all of your multilingual content.",
    "category": "Developer Tools",
    "title": "Crowdin Integration With Cold Email Sales Tool",
    "h1": "Integrate Crowdin with SalesBlink",
    "metaDescription": "Integrate Crowdin and other Developer Tools Tools with SalesBlink. Two Way Crowdin Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BoldSign",
    "slug": "boldsign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00cb54ddb4da494c6e5d7095ceab7341.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BoldSign is an electronic signature solution that lets you collect legally binding eSignatures online from any device.",
    "category": "Signatures",
    "title": "BoldSign Integration With Cold Email Sales Tool",
    "h1": "Integrate BoldSign with SalesBlink",
    "metaDescription": "Integrate BoldSign and other Signatures Tools with SalesBlink. Two Way BoldSign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intelli Messaging",
    "slug": "intelli-messaging",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/335ad9a0e46296b5dd47d064a64a06f3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intelli Messaging lets you send SMS Messages worldwide.",
    "category": "Phone & SMS",
    "title": "Intelli Messaging Integration With Cold Email Sales Tool",
    "h1": "Integrate Intelli Messaging with SalesBlink",
    "metaDescription": "Integrate Intelli Messaging and other Phone & SMS Tools with SalesBlink. Two Way Intelli Messaging Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VivoMeetings",
    "slug": "vivomeetings",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a0cdccf9bde7840c819078209758980_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vivomeetings virtual video conferencing suite allows you to create virtual meetings anytime, anywhere, on any device.",
    "category": "Video Conferencing",
    "title": "VivoMeetings Integration With Cold Email Sales Tool",
    "h1": "Integrate VivoMeetings with SalesBlink",
    "metaDescription": "Integrate VivoMeetings and other Video Conferencing Tools with SalesBlink. Two Way VivoMeetings Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Contractor Commerce",
    "slug": "contractor-commerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f1cdf3c50c26c4f35f02bf897fe4ab3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contractor Commerce is an app that provides eCommerce functionality on your existing website.",
    "category": "eCommerce",
    "title": "Contractor Commerce Integration With Cold Email Sales Tool",
    "h1": "Integrate Contractor Commerce with SalesBlink",
    "metaDescription": "Integrate Contractor Commerce and other eCommerce Tools with SalesBlink. Two Way Contractor Commerce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evenium",
    "slug": "evenium",
    "logo": "https://zapier-images.imgix.net/storage/services/7e99312e74139417596c78ce61cb7642.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evenium is a comprehensive solution for managing professional events. Evenium .NET handles registration, while OnSite takes care of entry management. ConnexMe, our mobile event app, provides interactive tools to your attendees and meetings.",
    "category": "Event Management",
    "title": "Evenium Integration With Cold Email Sales Tool",
    "h1": "Integrate Evenium with SalesBlink",
    "metaDescription": "Integrate Evenium and other Event Management Tools with SalesBlink. Two Way Evenium Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pyrus",
    "slug": "pyrus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ba5d7f8f918f2fee8d601b5557f2921.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pyrus is a platform for real-time messaging, task management, and workflow automation.",
    "category": "Team Collaboration",
    "title": "Pyrus Integration With Cold Email Sales Tool",
    "h1": "Integrate Pyrus with SalesBlink",
    "metaDescription": "Integrate Pyrus and other Team Collaboration Tools with SalesBlink. Two Way Pyrus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "talkSpirit",
    "slug": "talkspirit",
    "logo": "https://zapier-images.imgix.net/storage/developer/7c9151087afc32eff70ec4f311d08b8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "talkSpirit is a digital workplace for teams and organizations. Users can chat with anyone in their network, they can share information, photos, videos and files through open and private groups.",
    "category": "Team Collaboration",
    "title": "talkSpirit Integration With Cold Email Sales Tool",
    "h1": "Integrate talkSpirit with SalesBlink",
    "metaDescription": "Integrate talkSpirit and other Team Collaboration Tools with SalesBlink. Two Way talkSpirit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jublo",
    "slug": "jublo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62929589aac88ae86b841e0888e025cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jublo is an online communication platform aimed at manufacturers, craftsmen and housing associations.",
    "category": "IT Operations",
    "title": "Jublo Integration With Cold Email Sales Tool",
    "h1": "Integrate Jublo with SalesBlink",
    "metaDescription": "Integrate Jublo and other IT Operations Tools with SalesBlink. Two Way Jublo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CheckFlow",
    "slug": "checkflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ea1b3e904575bd504a5d6c119d5ab0f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CheckFlow is a SaaS platform that allows you to create and manage your team's recurring checklists and procedures in real-time.",
    "category": "Product Management",
    "title": "CheckFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate CheckFlow with SalesBlink",
    "metaDescription": "Integrate CheckFlow and other Product Management Tools with SalesBlink. Two Way CheckFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HootBoard",
    "slug": "hootboard",
    "logo": "https://zapier-images.imgix.net/storage/developer/e2e9095a34ce4ef7b5623616ac5d4a2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HootBoard is an information kiosk & a bulletin board platform for organizations to engage their employees, visitors or students on premises.",
    "category": "Event Management",
    "title": "HootBoard Integration With Cold Email Sales Tool",
    "h1": "Integrate HootBoard with SalesBlink",
    "metaDescription": "Integrate HootBoard and other Event Management Tools with SalesBlink. Two Way HootBoard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fixflo",
    "slug": "fixflo",
    "logo": "https://zapier-images.imgix.net/storage/developer/99320519902272bca0d3c528c8ed9ec0.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart, web-based software for\nmanaged rental properties.",
    "category": "Contact Management",
    "title": "Fixflo Integration With Cold Email Sales Tool",
    "h1": "Integrate Fixflo with SalesBlink",
    "metaDescription": "Integrate Fixflo and other Contact Management Tools with SalesBlink. Two Way Fixflo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Legalboards",
    "slug": "legalboards",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1047cadc89b382c4975969d1759c4eab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Legalboards streamline your workflow and Reduce the learning curve of new team members.",
    "category": "Project Management",
    "title": "Legalboards Integration With Cold Email Sales Tool",
    "h1": "Integrate Legalboards with SalesBlink",
    "metaDescription": "Integrate Legalboards and other Project Management Tools with SalesBlink. Two Way Legalboards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendbee",
    "slug": "sendbee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a691369fa84fedc564cf0e22e165f6f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendbee is a team inbox for WhatsApp that allows teams to collaborate on WhatsApp conversations.",
    "category": "Communication",
    "title": "Sendbee Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendbee with SalesBlink",
    "metaDescription": "Integrate Sendbee and other Communication Tools with SalesBlink. Two Way Sendbee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Memegen.link",
    "slug": "memegenlink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/126919fc337ec40f07b2ef15cc6081d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The free and open source API to generate memes.",
    "category": "Images & Design",
    "title": "Memegen.link Integration With Cold Email Sales Tool",
    "h1": "Integrate Memegen.link with SalesBlink",
    "metaDescription": "Integrate Memegen.link and other Images & Design Tools with SalesBlink. Two Way Memegen.link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Forms On Fire",
    "slug": "formsonfire",
    "logo": "https://zapier-images.imgix.net/storage/developer/d7bdc51be9255b5913969a0b28cfcd26_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Forms On Fire is an enterprise-grade mobile forms platform. Capture rich data on a mobile device including pictures, GPS, audio/video, signatures and more to submit to a secure data storage vault and integrate with hundreds of external systems.",
    "category": "Forms & Surveys",
    "title": "Forms On Fire Integration With Cold Email Sales Tool",
    "h1": "Integrate Forms On Fire with SalesBlink",
    "metaDescription": "Integrate Forms On Fire and other Forms & Surveys Tools with SalesBlink. Two Way Forms On Fire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cigo Tracker",
    "slug": "cigo-tracker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/841233053b01a43fa59ffc7dedeffb72_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cigo Tracker is an innovative cloud solution to increase deliveries, service calls or pick-ups, scale efficiently and provide your customers with a perfect last-mile delivery experience.",
    "category": "Task Management",
    "title": "Cigo Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate Cigo Tracker with SalesBlink",
    "metaDescription": "Integrate Cigo Tracker and other Task Management Tools with SalesBlink. Two Way Cigo Tracker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Client Hub",
    "slug": "client-hub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e50ffccbdb8f094456a8118138acea7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Client Hub is a modern workflow platform with client experience built-in, offering an amazing client collaboration experience.",
    "category": "Communication",
    "title": "Client Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate Client Hub with SalesBlink",
    "metaDescription": "Integrate Client Hub and other Communication Tools with SalesBlink. Two Way Client Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobRouter",
    "slug": "jobrouter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c8aea8e36672534c183eed382ad2eba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobRouter® links your processes, documents and data in one feature-rich platform.",
    "category": "Task Management",
    "title": "JobRouter Integration With Cold Email Sales Tool",
    "h1": "Integrate JobRouter with SalesBlink",
    "metaDescription": "Integrate JobRouter and other Task Management Tools with SalesBlink. Two Way JobRouter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RentCheck",
    "slug": "rentcheck",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2a96171225e635b91036ed86f8730ae9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RentCheck is a property management tool that enables a transparent renting experience between Renters and Property Managers through easy to use and comprehensive property inspections.",
    "category": "Customer Support",
    "title": "RentCheck Integration With Cold Email Sales Tool",
    "h1": "Integrate RentCheck with SalesBlink",
    "metaDescription": "Integrate RentCheck and other Customer Support Tools with SalesBlink. Two Way RentCheck Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "REMARKETER",
    "slug": "remarketer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fedcf656cf464f7f6a05f6a1269a1ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "REMARKETER helps you manage your online marketing & daily business tasks under one, easy-to-use management console that's specifically designed for Real Estate Professionals!",
    "category": "CRM (Customer Relationship Management)",
    "title": "REMARKETER Integration With Cold Email Sales Tool",
    "h1": "Integrate REMARKETER with SalesBlink",
    "metaDescription": "Integrate REMARKETER and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way REMARKETER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Time Tracker",
    "slug": "time-tracker",
    "logo": "https://zapier-images.imgix.net/storage/developer/fe517a70977f0ead04189ce69aac1624_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Time Tracker by eBillity helps you track and bill for your time.",
    "category": "Time Tracking Software",
    "title": "Time Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate Time Tracker with SalesBlink",
    "metaDescription": "Integrate Time Tracker and other Time Tracking Software Tools with SalesBlink. Two Way Time Tracker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simplebooklet",
    "slug": "simplebooklet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be53c5e386b6bac2a2874ded8da06556.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simplebooklet is a tool to publish your documents as web shareable content.",
    "category": "Documents",
    "title": "Simplebooklet Integration With Cold Email Sales Tool",
    "h1": "Integrate Simplebooklet with SalesBlink",
    "metaDescription": "Integrate Simplebooklet and other Documents Tools with SalesBlink. Two Way Simplebooklet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vepaar",
    "slug": "vepaar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4e00d0be4e31891bebf1fd1b5e84251_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vepaar app contains all the tools you need to create your business page, efficiently communicate with your business contacts, manage your sales pipeline, build an e-commerce store, and run polls.",
    "category": "eCommerce",
    "title": "Vepaar Integration With Cold Email Sales Tool",
    "h1": "Integrate Vepaar with SalesBlink",
    "metaDescription": "Integrate Vepaar and other eCommerce Tools with SalesBlink. Two Way Vepaar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Glean.ly",
    "slug": "gleanly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96b7ba776644d21ec7af7179c47d8932.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A single, searchable, secure, scalable user research repository, allowing the whole organisation to benefit from shared knowledge.",
    "category": "Business Intelligence",
    "title": "Glean.ly Integration With Cold Email Sales Tool",
    "h1": "Integrate Glean.ly with SalesBlink",
    "metaDescription": "Integrate Glean.ly and other Business Intelligence Tools with SalesBlink. Two Way Glean.ly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneLocal LocalContacts",
    "slug": "onelocal-localcontacts",
    "logo": "https://zapier-images.imgix.net/storage/services/313fff74e3465bab45897f37ba7d3106_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LocalContacts provides a simple and easy way to manage & connect with your customers through OneLocal. LocalContacts seamlessly connects with OneLocal's LocalReviews & LocalReferrals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OneLocal LocalContacts Integration With Cold Email Sales Tool",
    "h1": "Integrate OneLocal LocalContacts with SalesBlink",
    "metaDescription": "Integrate OneLocal LocalContacts and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OneLocal LocalContacts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BOSSDesk",
    "slug": "bossdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf9d753dbe44c010e7f941a5f8528567.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IT Service Management and Help Desk Solution for both Cloud and On-Premise",
    "category": "IT Operations",
    "title": "BOSSDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate BOSSDesk with SalesBlink",
    "metaDescription": "Integrate BOSSDesk and other IT Operations Tools with SalesBlink. Two Way BOSSDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mapsly",
    "slug": "mapsly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d50225f3e7dc79bccfc3f766bf184ce6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Customizable map for geo-analysis, routing, territory management with auto-assignment, and no-code automation with built-in geo-functions.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mapsly Integration With Cold Email Sales Tool",
    "h1": "Integrate Mapsly with SalesBlink",
    "metaDescription": "Integrate Mapsly and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mapsly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesNexus",
    "slug": "salesnexus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9d5589bf8fe9f1fea51fd5b843b9a39.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesNexus is lead generation, lead management plus sales process automation and full featured CRM, that's simple to set up and use.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesNexus Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesNexus with SalesBlink",
    "metaDescription": "Integrate SalesNexus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesNexus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zkipster",
    "slug": "zkipster",
    "logo": "https://zapier-images.imgix.net/storage/services/86ed2bc7315233f5f9a826e3910b8c6a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Event management software for the world’s best events. Trusted by global brands and organizations for custom online invitations, guest check-in, and more.",
    "category": "Event Management",
    "title": "zkipster Integration With Cold Email Sales Tool",
    "h1": "Integrate zkipster with SalesBlink",
    "metaDescription": "Integrate zkipster and other Event Management Tools with SalesBlink. Two Way zkipster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JoyForm",
    "slug": "joyform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/769ca6b92cc0230d09213c59e05ff4fe_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JoyForm is a tool that helps create lead-generation forms on WhatsApp.",
    "category": "Phone & SMS",
    "title": "JoyForm Integration With Cold Email Sales Tool",
    "h1": "Integrate JoyForm with SalesBlink",
    "metaDescription": "Integrate JoyForm and other Phone & SMS Tools with SalesBlink. Two Way JoyForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AbcSubmit",
    "slug": "abcsubmit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4d5771345246df790221c3d804bf6097.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AbcSubmit is a platform which allows you to create stunning contact forms, survey forms and any custom forms for your business.",
    "category": "Forms & Surveys",
    "title": "AbcSubmit Integration With Cold Email Sales Tool",
    "h1": "Integrate AbcSubmit with SalesBlink",
    "metaDescription": "Integrate AbcSubmit and other Forms & Surveys Tools with SalesBlink. Two Way AbcSubmit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GovSpend",
    "slug": "govspend",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/101db24f7a8df91e810c9427c89d7984.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GovSpend is a tool that allows agencies and companies to view purchases, bids, contracts, and much more.",
    "category": "Databases",
    "title": "GovSpend Integration With Cold Email Sales Tool",
    "h1": "Integrate GovSpend with SalesBlink",
    "metaDescription": "Integrate GovSpend and other Databases Tools with SalesBlink. Two Way GovSpend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yuccan Lead",
    "slug": "yuccan-lead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9f30355c824d833a6ac1ef2bbb81d5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yuccan Lead helps companies to build a custom referral program for their customers so that they can receive referrals from them",
    "category": "Sales & CRM",
    "title": "Yuccan Lead Integration With Cold Email Sales Tool",
    "h1": "Integrate Yuccan Lead with SalesBlink",
    "metaDescription": "Integrate Yuccan Lead and other Sales & CRM Tools with SalesBlink. Two Way Yuccan Lead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SpreadSimple",
    "slug": "spreadsimple",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/526f98395e12d278d1f6fac5fdb02614.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A blazing-fast way to create and manage websites using Google Sheets",
    "category": "Website Builders",
    "title": "SpreadSimple Integration With Cold Email Sales Tool",
    "h1": "Integrate SpreadSimple with SalesBlink",
    "metaDescription": "Integrate SpreadSimple and other Website Builders Tools with SalesBlink. Two Way SpreadSimple Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Websand",
    "slug": "websand",
    "logo": "https://zapier-images.imgix.net/storage/developer/001839517e053dcb89ecb0be4986f899_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Websand makes the data management of your marketing easy, so you can create email marketing that really delivers.",
    "category": "Marketing Automation",
    "title": "Websand Integration With Cold Email Sales Tool",
    "h1": "Integrate Websand with SalesBlink",
    "metaDescription": "Integrate Websand and other Marketing Automation Tools with SalesBlink. Two Way Websand Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Messente",
    "slug": "messente",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d65d488d798b785fcdfbdb23b783a58d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Messente allows you to send out business-critical SMS message notifications and marketing campaigns.",
    "category": "Phone & SMS",
    "title": "Messente Integration With Cold Email Sales Tool",
    "h1": "Integrate Messente with SalesBlink",
    "metaDescription": "Integrate Messente and other Phone & SMS Tools with SalesBlink. Two Way Messente Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadStation",
    "slug": "leadstation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23b5ab5d57e5deaf114ba67c1c5d7d41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Selling has never been easier with CRM LeadStation",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadStation Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadStation with SalesBlink",
    "metaDescription": "Integrate LeadStation and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadStation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prima.Law",
    "slug": "prima-law",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5edfe428d65395824d774885bee274d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prima.Law is an immigration forms platform to collect data, generate forms and manage immigration cases for immigration attorneys.",
    "category": "Forms & Surveys",
    "title": "Prima.Law Integration With Cold Email Sales Tool",
    "h1": "Integrate Prima.Law with SalesBlink",
    "metaDescription": "Integrate Prima.Law and other Forms & Surveys Tools with SalesBlink. Two Way Prima.Law Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coherent",
    "slug": "coherent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/136d472495afeadfefe64fdbed3d106f_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coherent is a web application specifically designed to manage coworking and shared workspaces and to connect their communities.",
    "category": "Scheduling & Booking",
    "title": "Coherent Integration With Cold Email Sales Tool",
    "h1": "Integrate Coherent with SalesBlink",
    "metaDescription": "Integrate Coherent and other Scheduling & Booking Tools with SalesBlink. Two Way Coherent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yay! Forms",
    "slug": "yay-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/329f4f82cd4a706d81d962c3d57d8ff0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This integration allows users to receive responses from Yay! Forms and connect them to 5000+ apps.",
    "category": "Forms & Surveys",
    "title": "Yay! Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Yay! Forms with SalesBlink",
    "metaDescription": "Integrate Yay! Forms and other Forms & Surveys Tools with SalesBlink. Two Way Yay! Forms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Clientary",
    "slug": "clientary",
    "logo": "https://zapier-images.imgix.net/storage/services/70252aeb13741704fa577947bd8b305b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online invoicing and time tracking for small businesses and freelancers. Clientary helps you get paid quicker and painlessly helps you manage your staff and projects.",
    "category": "Proposal & Invoice Management",
    "title": "Clientary Integration With Cold Email Sales Tool",
    "h1": "Integrate Clientary with SalesBlink",
    "metaDescription": "Integrate Clientary and other Proposal & Invoice Management Tools with SalesBlink. Two Way Clientary Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TeamGram",
    "slug": "teamgram",
    "logo": "https://zapier-images.imgix.net/storage/developer/ddeef65bec2a241286de2242360df740.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamGram is a CRM and collaboration tool that helps businesses close more sales and get more done.",
    "category": "CRM (Customer Relationship Management)",
    "title": "TeamGram Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamGram with SalesBlink",
    "metaDescription": "Integrate TeamGram and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way TeamGram Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Landin",
    "slug": "landin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19d95f3bba7ad28cc600dc8135f83e44.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Landin is a website where you can build landing pages quickly and easily.",
    "category": "Website Builders",
    "title": "Landin Integration With Cold Email Sales Tool",
    "h1": "Integrate Landin with SalesBlink",
    "metaDescription": "Integrate Landin and other Website Builders Tools with SalesBlink. Two Way Landin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bttn",
    "slug": "bttn",
    "logo": "https://zapier-images.imgix.net/storage/developer/9cd3697997bea52cc0bd386f2dad97ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bttn is a stand-alone physical push button that connects to the internet.",
    "category": "Devices",
    "title": "bttn Integration With Cold Email Sales Tool",
    "h1": "Integrate bttn with SalesBlink",
    "metaDescription": "Integrate bttn and other Devices Tools with SalesBlink. Two Way bttn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NegociOH!",
    "slug": "negocioh",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2c964bf3c4f017c0bedf9de67b14a2af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NegociOH is a platform to manage sales and CRM.",
    "category": "Sales & CRM",
    "title": "NegociOH! Integration With Cold Email Sales Tool",
    "h1": "Integrate NegociOH! with SalesBlink",
    "metaDescription": "Integrate NegociOH! and other Sales & CRM Tools with SalesBlink. Two Way NegociOH! Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneWaySMS",
    "slug": "onewaysms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6905ada8ffe7e15883415958ce5857f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneWaySMS Malaysia offers a complete bulk SMS service that allow businesses to send customized SMS to an ever increasing mobile audience.",
    "category": "Phone & SMS",
    "title": "OneWaySMS Integration With Cold Email Sales Tool",
    "h1": "Integrate OneWaySMS with SalesBlink",
    "metaDescription": "Integrate OneWaySMS and other Phone & SMS Tools with SalesBlink. Two Way OneWaySMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SYNDUIT",
    "slug": "synduit",
    "logo": "https://zapier-images.imgix.net/storage/services/5fcb9c50e1819f969749777e3c2e72b9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SYNDUIT is the first marketing platform with content for your industry.",
    "category": "Marketing Automation",
    "title": "SYNDUIT Integration With Cold Email Sales Tool",
    "h1": "Integrate SYNDUIT with SalesBlink",
    "metaDescription": "Integrate SYNDUIT and other Marketing Automation Tools with SalesBlink. Two Way SYNDUIT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Skalin",
    "slug": "skalin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c8bcde49f2fece117a693aef106bfcc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Skalin is a Customer Success platform for Startups who want to scale faster.",
    "category": "Sales & CRM",
    "title": "Skalin Integration With Cold Email Sales Tool",
    "h1": "Integrate Skalin with SalesBlink",
    "metaDescription": "Integrate Skalin and other Sales & CRM Tools with SalesBlink. Two Way Skalin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saysimple",
    "slug": "saysimple-ca1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfab1019c32d88dfe37bf559a95d37ea_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Saysimple is an omni-channel customer communications platform, built to leverage digital customer service from one single workspace.",
    "category": "Customer Support",
    "title": "Saysimple Integration With Cold Email Sales Tool",
    "h1": "Integrate Saysimple with SalesBlink",
    "metaDescription": "Integrate Saysimple and other Customer Support Tools with SalesBlink. Two Way Saysimple Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BuzzBoard Insights",
    "slug": "smbadvisor",
    "logo": "https://zapier-images.imgix.net/storage/services/22ab45db12e5b4eb0d75e6bf9cc25ec9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BuzzBoard Insights is an account intelligence platform that sources thousands of robust data points on small to mid-sized businesses. Plug-in to start streaming real-time account intelligence.",
    "category": "Dashboards",
    "title": "BuzzBoard Insights Integration With Cold Email Sales Tool",
    "h1": "Integrate BuzzBoard Insights with SalesBlink",
    "metaDescription": "Integrate BuzzBoard Insights and other Dashboards Tools with SalesBlink. Two Way BuzzBoard Insights Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Exly",
    "slug": "exly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e617d22ad76e46db2b5b5f5c5ff2de2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An all-in-one solution built to help enterprises run businesses & manage their community using our sales and marketing suite and robust CRM.",
    "category": "eCommerce",
    "title": "Exly Integration With Cold Email Sales Tool",
    "h1": "Integrate Exly with SalesBlink",
    "metaDescription": "Integrate Exly and other eCommerce Tools with SalesBlink. Two Way Exly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Roistat",
    "slug": "roistat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fce48ba086e7380c22d94cea7218c099.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Roistat is a smart marketing analytics system powered by AI. Access & visualize the insights you need to drive your online sales.",
    "category": "Marketing",
    "title": "Roistat Integration With Cold Email Sales Tool",
    "h1": "Integrate Roistat with SalesBlink",
    "metaDescription": "Integrate Roistat and other Marketing Tools with SalesBlink. Two Way Roistat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lokalise",
    "slug": "lokalise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/31cf802f944a895e78a124cb6054a7ae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lokalise is a translation management tool that lets you structure and automate workflows while encouraging collaboration among all involved.",
    "category": "Developer Tools",
    "title": "Lokalise Integration With Cold Email Sales Tool",
    "h1": "Integrate Lokalise with SalesBlink",
    "metaDescription": "Integrate Lokalise and other Developer Tools Tools with SalesBlink. Two Way Lokalise Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "FlowFast",
    "slug": "flowfast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21f314d7ed86fd408c97a6cbf42161cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FlowFast is a modern task tracker for agile teams.",
    "category": "Task Management",
    "title": "FlowFast Integration With Cold Email Sales Tool",
    "h1": "Integrate FlowFast with SalesBlink",
    "metaDescription": "Integrate FlowFast and other Task Management Tools with SalesBlink. Two Way FlowFast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dotcom Monitor",
    "slug": "dotcom-monitor",
    "logo": "https://zapier-images.imgix.net/storage/developer/33b7587b715c05bb6ad2d9f7c6fc5098_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monitor websites, applications, servers - anything online.  Gather performance and uptime reports and real-time alerts when something goes wrong.  Synthetic transaction monitoring from dozens of locations around the world.",
    "category": "Server Monitoring",
    "title": "Dotcom Monitor Integration With Cold Email Sales Tool",
    "h1": "Integrate Dotcom Monitor with SalesBlink",
    "metaDescription": "Integrate Dotcom Monitor and other Server Monitoring Tools with SalesBlink. Two Way Dotcom Monitor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Siteleaf",
    "slug": "siteleaf",
    "logo": "https://zapier-images.imgix.net/storage/developer/5f9b1a0b41c690b1abdd21ae5bdfe467_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send invitations to clients, colleagues, and others to edit and review sites. Managing content in Siteleaf is easy enough anyone can do it, with nothing to install.",
    "category": "Website Builders",
    "title": "Siteleaf Integration With Cold Email Sales Tool",
    "h1": "Integrate Siteleaf with SalesBlink",
    "metaDescription": "Integrate Siteleaf and other Website Builders Tools with SalesBlink. Two Way Siteleaf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BigML",
    "slug": "bigml",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/45bc0a05421b756beef834d1f4d00372.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BigML is a Machine Learning platform to solve and automate Classification, Regression, Time Series Forecasting, Cluster Analysis, Anomaly Detection, and Topic Modeling tasks. BigML seamlessly transforms data into actionable models that are used as remote services or, locally, embedded into applications to make predictions.",
    "category": "Developer Tools",
    "title": "BigML Integration With Cold Email Sales Tool",
    "h1": "Integrate BigML with SalesBlink",
    "metaDescription": "Integrate BigML and other Developer Tools Tools with SalesBlink. Two Way BigML Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "HeroTofu",
    "slug": "herotofu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73ce164d1b98975dc0eaf48f85b9fa2b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HeroTofu is a toolbox for marketers to increase conversions and capture leads.",
    "category": "Marketing Automation",
    "title": "HeroTofu Integration With Cold Email Sales Tool",
    "h1": "Integrate HeroTofu with SalesBlink",
    "metaDescription": "Integrate HeroTofu and other Marketing Automation Tools with SalesBlink. Two Way HeroTofu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pushwoosh",
    "slug": "pushwoosh",
    "logo": "https://zapier-images.imgix.net/storage/developer/55c32a473a0d7938925e67c13dd03520.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cross-platform push notifications with Pushwoosh!",
    "category": "Notifications",
    "title": "Pushwoosh Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushwoosh with SalesBlink",
    "metaDescription": "Integrate Pushwoosh and other Notifications Tools with SalesBlink. Two Way Pushwoosh Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Rota.fit Connect",
    "slug": "rotafit-ca15",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/433b85cbad4ee9dee1c7f8a4aa24fe8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rota.fit offers a suite of tools for fitness studio owners using Mindbody. Our unique solution allows you to connect your Mindbody studio to thousands of other apps.",
    "category": "Fitness",
    "title": "Rota.fit Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Rota.fit Connect with SalesBlink",
    "metaDescription": "Integrate Rota.fit Connect and other Fitness Tools with SalesBlink. Two Way Rota.fit Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OrderOut",
    "slug": "orderout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9bcd842da81586fea2ac3423afd37545.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OrderOut integrates third-party food delivery orders (UberEats, Postmates, DoorDash, Wix, etc.) directly into any POS system.",
    "category": "Notifications",
    "title": "OrderOut Integration With Cold Email Sales Tool",
    "h1": "Integrate OrderOut with SalesBlink",
    "metaDescription": "Integrate OrderOut and other Notifications Tools with SalesBlink. Two Way OrderOut Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CrazyCall",
    "slug": "crazycall",
    "logo": "https://zapier-images.imgix.net/storage/developer/d4673d0c6cf6ff736bd45218518883d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CrazyCall is a browser-based calling application. It allows you to make and take calls directly from your browser.",
    "category": "Phone & SMS",
    "title": "CrazyCall Integration With Cold Email Sales Tool",
    "h1": "Integrate CrazyCall with SalesBlink",
    "metaDescription": "Integrate CrazyCall and other Phone & SMS Tools with SalesBlink. Two Way CrazyCall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vonage Voice API",
    "slug": "vonage-voice-api",
    "logo": "https://zapier-images.imgix.net/storage/services/e80dae864efc9633eb31a1d283a34732.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Vonage Voice API (formerly Nexmo) allows you to make and receive calls with text-to-speech, audio playback, and forwarding.",
    "category": "Phone & SMS",
    "title": "Vonage Voice API Integration With Cold Email Sales Tool",
    "h1": "Integrate Vonage Voice API with SalesBlink",
    "metaDescription": "Integrate Vonage Voice API and other Phone & SMS Tools with SalesBlink. Two Way Vonage Voice API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Versium REACH",
    "slug": "versium-reach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/619a99c09fba08be9041ca62a50e2d35_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Data enrichment tool powered by an industry-leading identity resolution engine that adds contact and insight information to first-party data.",
    "category": "Contact Management",
    "title": "Versium REACH Integration With Cold Email Sales Tool",
    "h1": "Integrate Versium REACH with SalesBlink",
    "metaDescription": "Integrate Versium REACH and other Contact Management Tools with SalesBlink. Two Way Versium REACH Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swiss Newsletter",
    "slug": "swiss-newsletter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8f6986bb1d1e2ea842797fe5ad572e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swiss Newsletter by mailXpert helps small organizations to design and send professional newsletters. Its infrastructure is based in Switzerland.",
    "category": "Email Newsletters",
    "title": "Swiss Newsletter Integration With Cold Email Sales Tool",
    "h1": "Integrate Swiss Newsletter with SalesBlink",
    "metaDescription": "Integrate Swiss Newsletter and other Email Newsletters Tools with SalesBlink. Two Way Swiss Newsletter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bookingkit",
    "slug": "bookingkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dde5cafb0a8ca321608592f7f7e6de6b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bookingkit is the Europe’s leading booking, marketing & administration suite for professional operators of tours, activities & attractions.",
    "category": "Scheduling & Booking",
    "title": "bookingkit Integration With Cold Email Sales Tool",
    "h1": "Integrate bookingkit with SalesBlink",
    "metaDescription": "Integrate bookingkit and other Scheduling & Booking Tools with SalesBlink. Two Way bookingkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vowel",
    "slug": "vowel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b30c2d4db6b7a126658bedcd0b3d5ecd_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vowel is a tool for remote teams to host, capture, search, and share video meetings.",
    "category": "AI Tools",
    "title": "Vowel Integration With Cold Email Sales Tool",
    "h1": "Integrate Vowel with SalesBlink",
    "metaDescription": "Integrate Vowel and other AI Tools Tools with SalesBlink. Two Way Vowel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reach.at",
    "slug": "reachat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ebd82ede64308ab739ffb2a335a017d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reach.at is a conversational landing page builder. Replace your traditional contact us web forms with a high converting reach.at/you page.",
    "category": "Website Builders",
    "title": "Reach.at Integration With Cold Email Sales Tool",
    "h1": "Integrate Reach.at with SalesBlink",
    "metaDescription": "Integrate Reach.at and other Website Builders Tools with SalesBlink. Two Way Reach.at Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Feathery",
    "slug": "feathery",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bdfeb1046287009062266c02ba778ef5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feathery is a powerful form builder for product teams.",
    "category": "Forms & Surveys",
    "title": "Feathery Integration With Cold Email Sales Tool",
    "h1": "Integrate Feathery with SalesBlink",
    "metaDescription": "Integrate Feathery and other Forms & Surveys Tools with SalesBlink. Two Way Feathery Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Bitskout",
    "slug": "bitskout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7643a2b23c33e53d2a2dea1498d42b62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bitskout allows users to add A.I. without any coding or training and stop typing data into tools.",
    "category": "Productivity",
    "title": "Bitskout Integration With Cold Email Sales Tool",
    "h1": "Integrate Bitskout with SalesBlink",
    "metaDescription": "Integrate Bitskout and other Productivity Tools with SalesBlink. Two Way Bitskout Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "WPL",
    "slug": "wpl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d36a22136cf628ea691ec4942509fecb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WPL (WordPress Property Listing) is a flexible plugin for real estate and vertical markets.",
    "category": "Product Management",
    "title": "WPL Integration With Cold Email Sales Tool",
    "h1": "Integrate WPL with SalesBlink",
    "metaDescription": "Integrate WPL and other Product Management Tools with SalesBlink. Two Way WPL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartSender.io",
    "slug": "smartsenderio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b169f0031c40af2e533552ee61d7e28.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Capture and act on customer experience insights in one place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SmartSender.io Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartSender.io with SalesBlink",
    "metaDescription": "Integrate SmartSender.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SmartSender.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contentstack",
    "slug": "contentstack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54a48b59aaf9d60f53659f31584d5cce_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contentstack is a headless CMS with an API-first approach that puts content at the center. It is designed to simplify the process of publication by separating code from content.",
    "category": "Website Builders",
    "title": "Contentstack Integration With Cold Email Sales Tool",
    "h1": "Integrate Contentstack with SalesBlink",
    "metaDescription": "Integrate Contentstack and other Website Builders Tools with SalesBlink. Two Way Contentstack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brosix",
    "slug": "brosix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42cfa0a835a163d34be3a6851ce6728b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brosix is a secure instant messenger for your team, offering unlimited file transfer, screen-sharing, audio and video calls, and many more collaboration features to improve your team performance.",
    "category": "Team Chat",
    "title": "Brosix Integration With Cold Email Sales Tool",
    "h1": "Integrate Brosix with SalesBlink",
    "metaDescription": "Integrate Brosix and other Team Chat Tools with SalesBlink. Two Way Brosix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fiskl",
    "slug": "fiskl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91982d6c8c790cc5c7747cbe2c3be441.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fiskl is a mobile-first business management tool for the business owner which helps you invoice in 60 languages, get paid faster, manage expenses, time, mileage, products & services and much more.",
    "category": "Accounting",
    "title": "Fiskl Integration With Cold Email Sales Tool",
    "h1": "Integrate Fiskl with SalesBlink",
    "metaDescription": "Integrate Fiskl and other Accounting Tools with SalesBlink. Two Way Fiskl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convertlabs",
    "slug": "convertlabs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/443720e8beb3d889c35fd9bc8a299b18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convertlabs is a powerful Booking & Marketing Software \nfor your Maid Service and other Local Services",
    "category": "Scheduling & Booking",
    "title": "Convertlabs Integration With Cold Email Sales Tool",
    "h1": "Integrate Convertlabs with SalesBlink",
    "metaDescription": "Integrate Convertlabs and other Scheduling & Booking Tools with SalesBlink. Two Way Convertlabs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sales Cookie",
    "slug": "sales-cookie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/90d909520da7c95e9a15194a4049534f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sales Cookie automate all aspects of sales commission programs.",
    "category": "Accounting",
    "title": "Sales Cookie Integration With Cold Email Sales Tool",
    "h1": "Integrate Sales Cookie with SalesBlink",
    "metaDescription": "Integrate Sales Cookie and other Accounting Tools with SalesBlink. Two Way Sales Cookie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Credit Letters Software",
    "slug": "credit-letter-software",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfa08e5b760b49321abb21bb16aa01fb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud-based Software and CRM for the Credit Repair Industry. Designed for credit consultants and starting a credit repair business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Credit Letters Software Integration With Cold Email Sales Tool",
    "h1": "Integrate Credit Letters Software with SalesBlink",
    "metaDescription": "Integrate Credit Letters Software and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Credit Letters Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Insiteful",
    "slug": "insiteful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a0258b690830d17337340d084c756ce3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Insiteful is a web form tracking & optimization tool to automagically unlock more leads from any form — make every click count!",
    "category": "Marketing Automation",
    "title": "Insiteful Integration With Cold Email Sales Tool",
    "h1": "Integrate Insiteful with SalesBlink",
    "metaDescription": "Integrate Insiteful and other Marketing Automation Tools with SalesBlink. Two Way Insiteful Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailmojo",
    "slug": "mailmojo",
    "logo": "https://zapier-images.imgix.net/storage/services/badead3cd53a3bcfdd2496f339739f68.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailmojo is a Norwegian email marketing platform made especially with small businesses in mind.",
    "category": "Email Newsletters",
    "title": "Mailmojo Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailmojo with SalesBlink",
    "metaDescription": "Integrate Mailmojo and other Email Newsletters Tools with SalesBlink. Two Way Mailmojo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wimi",
    "slug": "wimi",
    "logo": "https://zapier-images.imgix.net/storage/developer/057bcaf402665965445820c21754ed3c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wimi is a team collaboration platform for businesses who want to boost teamwork and manage projects efficiently.",
    "category": "Project Management",
    "title": "Wimi Integration With Cold Email Sales Tool",
    "h1": "Integrate Wimi with SalesBlink",
    "metaDescription": "Integrate Wimi and other Project Management Tools with SalesBlink. Two Way Wimi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeda.io",
    "slug": "zedaio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c53b967e6ca0b1d06e9cef557b06e558.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeda.io is a product management tool that helps product teams achieve maximum efficiency by helping them to define, manage, and collaborate better on their products.",
    "category": "Product Management",
    "title": "Zeda.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeda.io with SalesBlink",
    "metaDescription": "Integrate Zeda.io and other Product Management Tools with SalesBlink. Two Way Zeda.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobilize",
    "slug": "mobilize",
    "logo": "https://zapier-images.imgix.net/storage/developer/dd7ee48c3935fa321acdedcc42284084_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobilize is a community management platform.",
    "category": "Communication",
    "title": "Mobilize Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobilize with SalesBlink",
    "metaDescription": "Integrate Mobilize and other Communication Tools with SalesBlink. Two Way Mobilize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omnicasa",
    "slug": "omnicasa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c275992e1d2ccae8b02036c8a16b9db_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omnicasa is a real estate software platform designed for real estate agents. Everything in one place:  create/manage properties/projects, contacts, tasks, appointments, demand, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Omnicasa Integration With Cold Email Sales Tool",
    "h1": "Integrate Omnicasa with SalesBlink",
    "metaDescription": "Integrate Omnicasa and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Omnicasa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HeadlessForms",
    "slug": "headlessforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/281db058b6a08c5fe03d7e1ed74b054a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HeadlessForms is a service that receives submissions from a form on your website - without requiring you to write a single line of backend code. Perfect for WordPress, Netlify, Github Pages or any static website.",
    "category": "Forms & Surveys",
    "title": "HeadlessForms Integration With Cold Email Sales Tool",
    "h1": "Integrate HeadlessForms with SalesBlink",
    "metaDescription": "Integrate HeadlessForms and other Forms & Surveys Tools with SalesBlink. Two Way HeadlessForms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Relatable",
    "slug": "relatable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8863ded0f1ad217cd0253e6afd6f5ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Relatable is a personal CRM that helps you be excellent with people.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Relatable Integration With Cold Email Sales Tool",
    "h1": "Integrate Relatable with SalesBlink",
    "metaDescription": "Integrate Relatable and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Relatable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "String",
    "slug": "string",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1dd8a9378f80610e58139457742c82a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "String is a cloud-based direct phone line and messaging solution that scales and modernizes business texting, calling, and direct messaging.",
    "category": "Communication",
    "title": "String Integration With Cold Email Sales Tool",
    "h1": "Integrate String with SalesBlink",
    "metaDescription": "Integrate String and other Communication Tools with SalesBlink. Two Way String Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mingdao",
    "slug": "mingdao",
    "logo": "https://zapier-images.imgix.net/storage/developer/3b1aff4e8ba13942302bcf692c56ca90_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mingdao is the leading social collaboration suite for China market, covering messaging, social, task, calendar to file sharing.",
    "category": "Team Chat",
    "title": "Mingdao Integration With Cold Email Sales Tool",
    "h1": "Integrate Mingdao with SalesBlink",
    "metaDescription": "Integrate Mingdao and other Team Chat Tools with SalesBlink. Two Way Mingdao Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Local Falcon",
    "slug": "local-falcon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7562e9afc1b6e4662fa049a5c13c78b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Enrich and visualize your Local SEO performance reports with ranking information about your Google Business Profile on Google Maps and Google Search.",
    "category": "Analytics",
    "title": "Local Falcon Integration With Cold Email Sales Tool",
    "h1": "Integrate Local Falcon with SalesBlink",
    "metaDescription": "Integrate Local Falcon and other Analytics Tools with SalesBlink. Two Way Local Falcon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Image Relay",
    "slug": "image-relay",
    "logo": "https://zapier-images.imgix.net/storage/services/f7f8b3623f0327645b4842a552af6121.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Digital Asset Management system designed with your time in mind. Upload, organize, and distribute your files from one centralized, secure, easy-to-use spot in the cloud.",
    "category": "File Management & Storage",
    "title": "Image Relay Integration With Cold Email Sales Tool",
    "h1": "Integrate Image Relay with SalesBlink",
    "metaDescription": "Integrate Image Relay and other File Management & Storage Tools with SalesBlink. Two Way Image Relay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JotUrl",
    "slug": "joturl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2c1dc242b6970f044b96eadd217ae0f8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JotURL is an all-in-one suite for your marketing links.",
    "category": "Marketing",
    "title": "JotUrl Integration With Cold Email Sales Tool",
    "h1": "Integrate JotUrl with SalesBlink",
    "metaDescription": "Integrate JotUrl and other Marketing Tools with SalesBlink. Two Way JotUrl Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Netex learningCloud",
    "slug": "netex-learningcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/4a2857af627ee74b958c1c407d4970ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Netex learningCloud is a learning platform designed for the modern workforce.",
    "category": "Online Courses",
    "title": "Netex learningCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Netex learningCloud with SalesBlink",
    "metaDescription": "Integrate Netex learningCloud and other Online Courses Tools with SalesBlink. Two Way Netex learningCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "nunify",
    "slug": "nunify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25ccdf26dc45a8e4bbc1b22a3a7d6d9c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "nunify is an event tech platform to run any type of event - virtual events, hybrid events, webinars, in-person events & meetings. This platform provides all tools for registrations, onsite check-in, networking, audience engagement, gamification, live stream, event apps, sponsorships.",
    "category": "Event Management",
    "title": "nunify Integration With Cold Email Sales Tool",
    "h1": "Integrate nunify with SalesBlink",
    "metaDescription": "Integrate nunify and other Event Management Tools with SalesBlink. Two Way nunify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flurly",
    "slug": "flurly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea20b9f38527c4670d3746192549ab13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flurly is the easiest and most affordable way to sell digital products online",
    "category": "Commerce",
    "title": "Flurly Integration With Cold Email Sales Tool",
    "h1": "Integrate Flurly with SalesBlink",
    "metaDescription": "Integrate Flurly and other Commerce Tools with SalesBlink. Two Way Flurly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YoPrint",
    "slug": "yoprint",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b0fa44e68acb722b9e2753a40c54cb0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YoPrint is an all-in-one print shop management software that helps you keep track of your sales, inventory and production.",
    "category": "Printing",
    "title": "YoPrint Integration With Cold Email Sales Tool",
    "h1": "Integrate YoPrint with SalesBlink",
    "metaDescription": "Integrate YoPrint and other Printing Tools with SalesBlink. Two Way YoPrint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HappyFox Chat",
    "slug": "happyfox-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer/b8813fec5c75641b8a58f8b9dfe300b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powerful live chat software built from ground-up for super fast customer support.",
    "category": "Customer Support",
    "title": "HappyFox Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate HappyFox Chat with SalesBlink",
    "metaDescription": "Integrate HappyFox Chat and other Customer Support Tools with SalesBlink. Two Way HappyFox Chat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pipes.ai",
    "slug": "pipesai",
    "logo": "https://zapier-images.imgix.net/storage/services/f7d64e7863605ee9849402526e6dfb75.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pipes.ai is a platform that improves your outbound marketing contact rates: Get more of your customers on the phone without making a hire.",
    "category": "Phone & SMS",
    "title": "Pipes.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipes.ai with SalesBlink",
    "metaDescription": "Integrate Pipes.ai and other Phone & SMS Tools with SalesBlink. Two Way Pipes.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Project Manager",
    "slug": "project-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer/320702e9fd6a6ede45bf102160f7eca6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProjectManager.com is a simple yet powerful online project management tool that enables you and your team to plan, track, monitor and report on your projects in real-time.",
    "category": "Project Management",
    "title": "Project Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Project Manager with SalesBlink",
    "metaDescription": "Integrate Project Manager and other Project Management Tools with SalesBlink. Two Way Project Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WikiRealty",
    "slug": "wikirealty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46e182f48cb7fcc5e03fb4226ffafb00_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WikiRealty is an automated hyperlocal marketing, advertising, and integrations platform for real estate.",
    "category": "Marketing",
    "title": "WikiRealty Integration With Cold Email Sales Tool",
    "h1": "Integrate WikiRealty with SalesBlink",
    "metaDescription": "Integrate WikiRealty and other Marketing Tools with SalesBlink. Two Way WikiRealty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReadyCloud Suite",
    "slug": "readycloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cd47e5ccc14686f15649150ff77de33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReadyCloud is the shipping, returns and CRM suite built for eCommerce.",
    "category": "eCommerce",
    "title": "ReadyCloud Suite Integration With Cold Email Sales Tool",
    "h1": "Integrate ReadyCloud Suite with SalesBlink",
    "metaDescription": "Integrate ReadyCloud Suite and other eCommerce Tools with SalesBlink. Two Way ReadyCloud Suite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Data Blaze",
    "slug": "data-blaze",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b94069aaa7e9949f53c39d3a1a87224_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Data Blaze is a spreadsheet app that can be used in any website by using snippets and templates from the companion application Text Blaze.",
    "category": "Spreadsheets",
    "title": "Data Blaze Integration With Cold Email Sales Tool",
    "h1": "Integrate Data Blaze with SalesBlink",
    "metaDescription": "Integrate Data Blaze and other Spreadsheets Tools with SalesBlink. Two Way Data Blaze Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agent Response",
    "slug": "agent-response",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a026a2ca8268d10c5290d19522ed68f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We help Letting and Estate Agents get better leads, freeing up your staff to make better use of their time & improve your customer service.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agent Response Integration With Cold Email Sales Tool",
    "h1": "Integrate Agent Response with SalesBlink",
    "metaDescription": "Integrate Agent Response and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agent Response Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "E-Sign",
    "slug": "e-sign",
    "logo": "https://zapier-images.imgix.net/storage/services/7de89d204fe9db029c6022b2a5a89a30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online document signing platform.",
    "category": "Signatures",
    "title": "E-Sign Integration With Cold Email Sales Tool",
    "h1": "Integrate E-Sign with SalesBlink",
    "metaDescription": "Integrate E-Sign and other Signatures Tools with SalesBlink. Two Way E-Sign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appspotr",
    "slug": "appspotr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/af934c2128f6fd5a1ee456e528cfc0b3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appspotr lets you create smartphone apps visually without coding. It is a cheaper, faster and easier way of creating and maintaining native IOS and Android apps and PWAs.",
    "category": "App Builder",
    "title": "Appspotr Integration With Cold Email Sales Tool",
    "h1": "Integrate Appspotr with SalesBlink",
    "metaDescription": "Integrate Appspotr and other App Builder Tools with SalesBlink. Two Way Appspotr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RoboAuditor",
    "slug": "roboauditor",
    "logo": "https://zapier-images.imgix.net/storage/developer/cf6810a04c6813bbd5724a0d07a8504e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RoboAuditor is an embeddable white label SEO audit tool.",
    "category": "Website Builders",
    "title": "RoboAuditor Integration With Cold Email Sales Tool",
    "h1": "Integrate RoboAuditor with SalesBlink",
    "metaDescription": "Integrate RoboAuditor and other Website Builders Tools with SalesBlink. Two Way RoboAuditor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Birdie",
    "slug": "birdie",
    "logo": "https://zapier-images.imgix.net/storage/services/18f48aac1b71edecce2f926d6321351d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Birdie is a platform that allows people in product-related jobs to centralize and classify user feedback from many sources in a single place and get insights with the help of AI",
    "category": "Analytics",
    "title": "Birdie Integration With Cold Email Sales Tool",
    "h1": "Integrate Birdie with SalesBlink",
    "metaDescription": "Integrate Birdie and other Analytics Tools with SalesBlink. Two Way Birdie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS.dk",
    "slug": "smsdk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a846fed9a44265d3ab02048a855f51c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMS.dk is an online SMS gateway for easy communication with your customers.",
    "category": "Phone & SMS",
    "title": "SMS.dk Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS.dk with SalesBlink",
    "metaDescription": "Integrate SMS.dk and other Phone & SMS Tools with SalesBlink. Two Way SMS.dk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kenect Text Messaging",
    "slug": "kenect-text-messaging",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4cd48d7b8187a23f187ca5415ead3b86.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kenect is a texting platform that allows companies to send and receive texts, attachments, web leads, and more in one unified inbox.",
    "category": "Communication",
    "title": "Kenect Text Messaging Integration With Cold Email Sales Tool",
    "h1": "Integrate Kenect Text Messaging with SalesBlink",
    "metaDescription": "Integrate Kenect Text Messaging and other Communication Tools with SalesBlink. Two Way Kenect Text Messaging Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Conexteo",
    "slug": "conexteo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed18c0bd6c72ab8ecd81092de1ff9749.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate the sending of your Transactional and Marketing SMS with Conexteo.",
    "category": "Marketing Automation",
    "title": "Conexteo Integration With Cold Email Sales Tool",
    "h1": "Integrate Conexteo with SalesBlink",
    "metaDescription": "Integrate Conexteo and other Marketing Automation Tools with SalesBlink. Two Way Conexteo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Arrangr",
    "slug": "arrangr",
    "logo": "https://zapier-images.imgix.net/storage/services/0e93ef87cdc370b518f1488e26a90052.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Arrangr makes arranging meetings fast and easy. Whether meeting 1-on-1 or herding a group, meeting for coffee or scheduling a call, Arrangr provides the tools and flows to arrange it with ease and efficiency.",
    "category": "Scheduling & Booking",
    "title": "Arrangr Integration With Cold Email Sales Tool",
    "h1": "Integrate Arrangr with SalesBlink",
    "metaDescription": "Integrate Arrangr and other Scheduling & Booking Tools with SalesBlink. Two Way Arrangr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BriteVerify",
    "slug": "briteverify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d28af3bc82827f511602d26f39da424.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BriteVerify is a contact validation tool for point-of-capture analysis to prevent invalid data from entering your subscriber database.",
    "category": "Email",
    "title": "BriteVerify Integration With Cold Email Sales Tool",
    "h1": "Integrate BriteVerify with SalesBlink",
    "metaDescription": "Integrate BriteVerify and other Email Tools with SalesBlink. Two Way BriteVerify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FINOM",
    "slug": "finom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3dc46eafaed2527b6f6e08ca8f7ca74_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FINOM is a financial service that combines a banking business account, invoicing, and accounting software integration",
    "category": "Accounting",
    "title": "FINOM Integration With Cold Email Sales Tool",
    "h1": "Integrate FINOM with SalesBlink",
    "metaDescription": "Integrate FINOM and other Accounting Tools with SalesBlink. Two Way FINOM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gorilla CRM",
    "slug": "gorilla-crm-latest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53f50519702f1ff554ecb35a72f854f6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gorilla CRM will help your Team to stay on track with all related connections and tasks. Bring your company to the next level.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Gorilla CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Gorilla CRM with SalesBlink",
    "metaDescription": "Integrate Gorilla CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Gorilla CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FormTitan",
    "slug": "formtitan",
    "logo": "https://zapier-images.imgix.net/storage/developer/f7c76d4335c0bd279bcd65e53df15a15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FormTitan is an online form builder that allows you to easily create powerful forms without a single line of code.\nImprove your conversions with our innovative CRO engine, integration with many services, custom logic, ui logic and much more!",
    "category": "Forms & Surveys",
    "title": "FormTitan Integration With Cold Email Sales Tool",
    "h1": "Integrate FormTitan with SalesBlink",
    "metaDescription": "Integrate FormTitan and other Forms & Surveys Tools with SalesBlink. Two Way FormTitan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Certopus",
    "slug": "certopus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e5ff8ed4743a77d92e6b660a919a67a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Certopus is a complete certificate management platform, where you can design, generate, send and verify certificates seamlessly.",
    "category": "Online Courses",
    "title": "Certopus Integration With Cold Email Sales Tool",
    "h1": "Integrate Certopus with SalesBlink",
    "metaDescription": "Integrate Certopus and other Online Courses Tools with SalesBlink. Two Way Certopus Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Frill",
    "slug": "frill",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b3c8a6853854aa37604261f418e94f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Frill lets you collect customer feedback, communicate a public roadmap and announce new features.",
    "category": "Product Management",
    "title": "Frill Integration With Cold Email Sales Tool",
    "h1": "Integrate Frill with SalesBlink",
    "metaDescription": "Integrate Frill and other Product Management Tools with SalesBlink. Two Way Frill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scribeless",
    "slug": "scribeless",
    "logo": "https://zapier-images.imgix.net/storage/services/34069beb7e8df624f20a3d1814e87570.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handwritten notes feel special. Our technology helps brands automate handwritten marketing and deliver delightful customer experiences.",
    "category": "Marketing Automation",
    "title": "Scribeless Integration With Cold Email Sales Tool",
    "h1": "Integrate Scribeless with SalesBlink",
    "metaDescription": "Integrate Scribeless and other Marketing Automation Tools with SalesBlink. Two Way Scribeless Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PEX",
    "slug": "pex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd4e8a73437c267c1b9e251b7f591157.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PEX is the only spend management platform that combines a cloud-based application with a Visa prepaid card.",
    "category": "Payment Processing",
    "title": "PEX Integration With Cold Email Sales Tool",
    "h1": "Integrate PEX with SalesBlink",
    "metaDescription": "Integrate PEX and other Payment Processing Tools with SalesBlink. Two Way PEX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drove",
    "slug": "drove",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cce21a7a7ec2027f49c5777cc4c4896.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drove provides simple and powerful marketing tools for everyone.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Drove Integration With Cold Email Sales Tool",
    "h1": "Integrate Drove with SalesBlink",
    "metaDescription": "Integrate Drove and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Drove Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tribal Habits LMS",
    "slug": "tribal-habits",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8fb23f5aaaf346aa234cdc6fdb7bd7f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tribal Habits is an all-in-one learning management system (LMS) - create interactive learning, manage online and blended training, dive into ready-made modules and report on all learning contributions",
    "category": "HR Talent & Recruitment",
    "title": "Tribal Habits LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Tribal Habits LMS with SalesBlink",
    "metaDescription": "Integrate Tribal Habits LMS and other HR Talent & Recruitment Tools with SalesBlink. Two Way Tribal Habits LMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Network Leads",
    "slug": "network-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c847b3702a702708b602a639fdb4c0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage leads, sales, marketing, operations and billing for moving companies in a cloud-based system.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Network Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Network Leads with SalesBlink",
    "metaDescription": "Integrate Network Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Network Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneSimpleApi",
    "slug": "onesimpleapi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dea9fb5d9e4069e659ce909dd522bba8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneSimpleApi is a no-code API designed with all the things you need to get your no-code project to success: PDF generation, Currency Exchange, QR codes, Screenshots, and more.",
    "category": "Developer Tools",
    "title": "OneSimpleApi Integration With Cold Email Sales Tool",
    "h1": "Integrate OneSimpleApi with SalesBlink",
    "metaDescription": "Integrate OneSimpleApi and other Developer Tools Tools with SalesBlink. Two Way OneSimpleApi Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Dataclay QUE",
    "slug": "dataclay-que",
    "logo": "https://zapier-images.imgix.net/storage/services/a43a211bde4d091af229f9cc279c4323.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QUE is the optimal API that leverages the full capabilities of Templater for Adobe After Effects to produce dynamic video content.",
    "category": "Video & Audio",
    "title": "Dataclay QUE Integration With Cold Email Sales Tool",
    "h1": "Integrate Dataclay QUE with SalesBlink",
    "metaDescription": "Integrate Dataclay QUE and other Video & Audio Tools with SalesBlink. Two Way Dataclay QUE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DreamApply",
    "slug": "dreamapply",
    "logo": "https://zapier-images.imgix.net/storage/services/9d3ec41b0adf40de77476810fb44c089.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DreamApply is a next generation digital admissions and marketing solution used by hundreds of universities and other education institutions worldwide.",
    "category": "CRM (Customer Relationship Management)",
    "title": "DreamApply Integration With Cold Email Sales Tool",
    "h1": "Integrate DreamApply with SalesBlink",
    "metaDescription": "Integrate DreamApply and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way DreamApply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Social Snowball",
    "slug": "social-snowball",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5531266fa6817cf85ff75392344e2156.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Affiliate marketing for modern eCommerce",
    "category": "Marketing",
    "title": "Social Snowball Integration With Cold Email Sales Tool",
    "h1": "Integrate Social Snowball with SalesBlink",
    "metaDescription": "Integrate Social Snowball and other Marketing Tools with SalesBlink. Two Way Social Snowball Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bridge",
    "slug": "bridge-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/36aabecaccb6ce29eb3fb38e04c43de7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bridge is a network collaboration platform that helps you access dream customers, great investors, top talent and knowledge from anywhere.",
    "category": "Team Collaboration",
    "title": "Bridge Integration With Cold Email Sales Tool",
    "h1": "Integrate Bridge with SalesBlink",
    "metaDescription": "Integrate Bridge and other Team Collaboration Tools with SalesBlink. Two Way Bridge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outline",
    "slug": "outline",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad320eae71efe6bc1a6129b1d7237381.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outline is the fastest knowledge base and wiki for growing teams.",
    "category": "Documents",
    "title": "Outline Integration With Cold Email Sales Tool",
    "h1": "Integrate Outline with SalesBlink",
    "metaDescription": "Integrate Outline and other Documents Tools with SalesBlink. Two Way Outline Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Mbody 360",
    "slug": "mbody-da90214",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a314f398d24f0cca6f699534402041a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MBODY360 – Puts an Integrative Health Practitioner in Your Pocket. View custom plans and share your nutrition, exercise, sleep and meditation data with your practitioner.",
    "category": "Task Management",
    "title": "Mbody 360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Mbody 360 with SalesBlink",
    "metaDescription": "Integrate Mbody 360 and other Task Management Tools with SalesBlink. Two Way Mbody 360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HEU",
    "slug": "heu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c2ec4e12ed23b4b7444ae759048899a9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HEU is a contract lifecycle manager that streamlines and speeds up the contract management process designed for sales, hr, legal, procurement, operations and startups.",
    "category": "Signatures",
    "title": "HEU Integration With Cold Email Sales Tool",
    "h1": "Integrate HEU with SalesBlink",
    "metaDescription": "Integrate HEU and other Signatures Tools with SalesBlink. Two Way HEU Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shiptheory",
    "slug": "shiptheory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b84aa8fde85b006d076b6e245f873037_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your shipping processes for more efficient and reliable shipping using Shiptheory to access carrier APIs.",
    "category": "eCommerce",
    "title": "Shiptheory Integration With Cold Email Sales Tool",
    "h1": "Integrate Shiptheory with SalesBlink",
    "metaDescription": "Integrate Shiptheory and other eCommerce Tools with SalesBlink. Two Way Shiptheory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Glidr",
    "slug": "glidr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a0131a29078b69f1273f8d05fc0bc6f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GLIDR helps you make data-driven decisions on what to build, from roadmap through discovery, launch and iteration.",
    "category": "Product Management",
    "title": "Glidr Integration With Cold Email Sales Tool",
    "h1": "Integrate Glidr with SalesBlink",
    "metaDescription": "Integrate Glidr and other Product Management Tools with SalesBlink. Two Way Glidr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stream",
    "slug": "stream",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b098d022fca9e0d6ffe7e929999642a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stream helps you to plan & manage your delivery routes & logistics operation. Intuitive mobile app for barcode scanning & Proof of Delivery",
    "category": "Product Management",
    "title": "Stream Integration With Cold Email Sales Tool",
    "h1": "Integrate Stream with SalesBlink",
    "metaDescription": "Integrate Stream and other Product Management Tools with SalesBlink. Two Way Stream Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Addigy",
    "slug": "addigy",
    "logo": "https://zapier-images.imgix.net/storage/services/d23c461a3bbe7f28c12f7a8cb62c8cbb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Addigy provides a simple, yet powerful way to manage all your Apple IT infrastructure.",
    "category": "Devices",
    "title": "Addigy Integration With Cold Email Sales Tool",
    "h1": "Integrate Addigy with SalesBlink",
    "metaDescription": "Integrate Addigy and other Devices Tools with SalesBlink. Two Way Addigy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Halo Security",
    "slug": "halosecurity",
    "logo": "https://zapier-images.imgix.net/storage/services/473eb87f569b0d200e1a272dbb10203a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Halo Security offers organizations a comprehensive platform to improve their external security posture from the attacker’s perspective.",
    "category": "Security & Identity Tools",
    "title": "Halo Security Integration With Cold Email Sales Tool",
    "h1": "Integrate Halo Security with SalesBlink",
    "metaDescription": "Integrate Halo Security and other Security & Identity Tools Tools with SalesBlink. Two Way Halo Security Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "PassKit Coupons",
    "slug": "passkit-coupons",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b41e0e4d0ef9f804b5b17e79e92b70d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PassKit Coupons lets you manage your coupon campaigns and passes for Apple Wallet and Google Pay",
    "category": "Marketing Automation",
    "title": "PassKit Coupons Integration With Cold Email Sales Tool",
    "h1": "Integrate PassKit Coupons with SalesBlink",
    "metaDescription": "Integrate PassKit Coupons and other Marketing Automation Tools with SalesBlink. Two Way PassKit Coupons Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Interviewer.ai",
    "slug": "interviewerai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9989f4a245253c58b04bc4c1c0c3bbac_2.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Interviewer.ai is a  effective tool which can assist you in your hiring process. This product lets you to create and manage jobs , candidate and application and create video interview links and provides you results of candidate using AI.",
    "category": "HR Talent & Recruitment",
    "title": "Interviewer.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Interviewer.ai with SalesBlink",
    "metaDescription": "Integrate Interviewer.ai and other HR Talent & Recruitment Tools with SalesBlink. Two Way Interviewer.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slimster",
    "slug": "slimster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/98c9a992d8f5bcc980a4c48d85409b81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate Slimster leads into your own workflow.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Slimster Integration With Cold Email Sales Tool",
    "h1": "Integrate Slimster with SalesBlink",
    "metaDescription": "Integrate Slimster and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Slimster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NinjaOutreach",
    "slug": "ninjaoutreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6184d618a4424253b9f99721f2e35f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NinjaOutreach streamlines your influencer marketing, blogger outreach and lead generation process with automated campaigns and follow-ups.",
    "category": "Marketing Automation",
    "title": "NinjaOutreach Integration With Cold Email Sales Tool",
    "h1": "Integrate NinjaOutreach with SalesBlink",
    "metaDescription": "Integrate NinjaOutreach and other Marketing Automation Tools with SalesBlink. Two Way NinjaOutreach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tweet Hunter",
    "slug": "tweet-hunter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbccd6b4cbe0ac68ab2b1f1a3b1b2ae1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tweet Hunter lets you schedule, automate and get inspiration for your Twitter content.",
    "category": "Social Media Marketing",
    "title": "Tweet Hunter Integration With Cold Email Sales Tool",
    "h1": "Integrate Tweet Hunter with SalesBlink",
    "metaDescription": "Integrate Tweet Hunter and other Social Media Marketing Tools with SalesBlink. Two Way Tweet Hunter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "New Relic Insights",
    "slug": "new-relic-insights",
    "logo": "https://zapier-images.imgix.net/storage/developer/8413694dd3b6c17caf26cadd74c42c0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "New Relic Insights is a real-time analytics platform that collects metrics directly from your live production software and transforms them into actionable insights about your business, customers, and applications.",
    "category": "Ads & Conversion",
    "title": "New Relic Insights Integration With Cold Email Sales Tool",
    "h1": "Integrate New Relic Insights with SalesBlink",
    "metaDescription": "Integrate New Relic Insights and other Ads & Conversion Tools with SalesBlink. Two Way New Relic Insights Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Starred",
    "slug": "starred",
    "logo": "https://zapier-images.imgix.net/storage/services/426ef9d1f43b976854f13d971d0dd735_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Starred is a feedback software solution that helps you improve on your People Experience, from hire to retire.",
    "category": "HR Talent & Recruitment",
    "title": "Starred Integration With Cold Email Sales Tool",
    "h1": "Integrate Starred with SalesBlink",
    "metaDescription": "Integrate Starred and other HR Talent & Recruitment Tools with SalesBlink. Two Way Starred Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Startquestion",
    "slug": "startquestion",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ceefc19a6e6787084f2fea9a26eb7d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Startquestion is a professional survey software for your company. NPS, Customer Satisfaction, UX feedback, User Research, Employee Feedback, Customer Experience.",
    "category": "Forms & Surveys",
    "title": "Startquestion Integration With Cold Email Sales Tool",
    "h1": "Integrate Startquestion with SalesBlink",
    "metaDescription": "Integrate Startquestion and other Forms & Surveys Tools with SalesBlink. Two Way Startquestion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Protiv",
    "slug": "protiv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed925921f92c1e4147965f73426e52a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Protiv is a bonus program for employees that provides an automatic incentive to elevate productivity, performance, and lower labor costs.",
    "category": "Time Tracking Software",
    "title": "Protiv Integration With Cold Email Sales Tool",
    "h1": "Integrate Protiv with SalesBlink",
    "metaDescription": "Integrate Protiv and other Time Tracking Software Tools with SalesBlink. Two Way Protiv Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "imeetify",
    "slug": "imeetify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/684023b9f3a4d70c560722162623bd42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "imeetify is a online appointment scheduling and event hosting platform",
    "category": "Calendar",
    "title": "imeetify Integration With Cold Email Sales Tool",
    "h1": "Integrate imeetify with SalesBlink",
    "metaDescription": "Integrate imeetify and other Calendar Tools with SalesBlink. Two Way imeetify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventee",
    "slug": "eventee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ba4778f358ad39d751537a14ecd112c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventee is an intuitive mobile and web app that enhances attendee experience and engagement at in-person, virtual or hybrid events.",
    "category": "Event Management",
    "title": "Eventee Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventee with SalesBlink",
    "metaDescription": "Integrate Eventee and other Event Management Tools with SalesBlink. Two Way Eventee Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "FormDesigner",
    "slug": "form-designer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8de8cc8e8ac1d02d28a0220d6dc089ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FormDesigner - builder of web forms, surveys, quizzes and calculators",
    "category": "Forms & Surveys",
    "title": "FormDesigner Integration With Cold Email Sales Tool",
    "h1": "Integrate FormDesigner with SalesBlink",
    "metaDescription": "Integrate FormDesigner and other Forms & Surveys Tools with SalesBlink. Two Way FormDesigner Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Solid Performers CRM",
    "slug": "solid-performers-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f044d6e14cb794cb70a29f508db00af1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We Help your Sales Team Close More Leads Faster & Smarter",
    "category": "Sales & CRM",
    "title": "Solid Performers CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Solid Performers CRM with SalesBlink",
    "metaDescription": "Integrate Solid Performers CRM and other Sales & CRM Tools with SalesBlink. Two Way Solid Performers CRM Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ZapFloorHQ",
    "slug": "zapfloorhq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7fd6a8297999ac7dc343fbe3a675eefe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZapFloorHQ really simplifies our billing process and allows us to easily generate all our monthly invoices and track the payments.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ZapFloorHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate ZapFloorHQ with SalesBlink",
    "metaDescription": "Integrate ZapFloorHQ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ZapFloorHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Text to Speech PRO",
    "slug": "text-to-speech-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d4e25b1d454da50afa1fb56d0b7e818.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert multi-language text into natural-sounding speech.",
    "category": "Developer Tools",
    "title": "Text to Speech PRO Integration With Cold Email Sales Tool",
    "h1": "Integrate Text to Speech PRO with SalesBlink",
    "metaDescription": "Integrate Text to Speech PRO and other Developer Tools Tools with SalesBlink. Two Way Text to Speech PRO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatArchitect.com",
    "slug": "chatarchitect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb54ec471df1356ef08cae09e6920dd2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatArchitect.com manages inbound WhatsApp messages, outbound session and template messages (HSM), media, and WhatsApp buttons.",
    "category": "Phone & SMS",
    "title": "ChatArchitect.com Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatArchitect.com with SalesBlink",
    "metaDescription": "Integrate ChatArchitect.com and other Phone & SMS Tools with SalesBlink. Two Way ChatArchitect.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ticketeer",
    "slug": "ticketeer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f4aa934e8d512d66808e724fe9e554d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A free and user focused, yet powerful project management platform for businesses of all sizes.",
    "category": "Project Management",
    "title": "ticketeer Integration With Cold Email Sales Tool",
    "h1": "Integrate ticketeer with SalesBlink",
    "metaDescription": "Integrate ticketeer and other Project Management Tools with SalesBlink. Two Way ticketeer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BrightMove",
    "slug": "brightmove",
    "logo": "https://zapier-images.imgix.net/storage/developer/d63c22bef9f6edd7cc7ec84eece4f9e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrightMove is an enterprise ready applicant tracking system (ATS) for any staffing, recruiting, RPO or HR team.",
    "category": "HR Talent & Recruitment",
    "title": "BrightMove Integration With Cold Email Sales Tool",
    "h1": "Integrate BrightMove with SalesBlink",
    "metaDescription": "Integrate BrightMove and other HR Talent & Recruitment Tools with SalesBlink. Two Way BrightMove Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BuildTopia",
    "slug": "buildtopia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c2dd0aa2ece71c99a178f7a32f4da6c3_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BuildTopia is an integrated construction management system for residential builders, bringing all tasks, processes and workflow together as a single system with purchasing, project management and job-costing technology.",
    "category": "Sales & CRM",
    "title": "BuildTopia Integration With Cold Email Sales Tool",
    "h1": "Integrate BuildTopia with SalesBlink",
    "metaDescription": "Integrate BuildTopia and other Sales & CRM Tools with SalesBlink. Two Way BuildTopia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adaptive Card Maker by adenin",
    "slug": "adenin-digital-assistant",
    "logo": "https://zapier-images.imgix.net/storage/services/54d572f4baa3c2b638a0b56ddf2509ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily monitor your other work apps by creating Adaptive Cards for free. Never miss another update simply by placing them on your own Board, SharePoint, intranet or chatbot.",
    "category": "Dashboards",
    "title": "Adaptive Card Maker by adenin Integration With Cold Email Sales Tool",
    "h1": "Integrate Adaptive Card Maker by adenin with SalesBlink",
    "metaDescription": "Integrate Adaptive Card Maker by adenin and other Dashboards Tools with SalesBlink. Two Way Adaptive Card Maker by adenin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TestMonitor",
    "slug": "testmonitor",
    "logo": "https://zapier-images.imgix.net/storage/services/cd606da05184bd107a78b9c241f4c4bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TestMonitor is a test management application that allows you to design, plan, and run user acceptance tests.",
    "category": "Developer Tools",
    "title": "TestMonitor Integration With Cold Email Sales Tool",
    "h1": "Integrate TestMonitor with SalesBlink",
    "metaDescription": "Integrate TestMonitor and other Developer Tools Tools with SalesBlink. Two Way TestMonitor Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "DoubleTick",
    "slug": "doubletick",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2575a7905ccf9179fcf09effb1034b10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DoubleTick let's you use WhatsApp Business API on mobile.",
    "category": "Communication",
    "title": "DoubleTick Integration With Cold Email Sales Tool",
    "h1": "Integrate DoubleTick with SalesBlink",
    "metaDescription": "Integrate DoubleTick and other Communication Tools with SalesBlink. Two Way DoubleTick Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pitchbox",
    "slug": "pitchbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f17a48fdbe554fd4065ddd3fbbcad84.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pitchbox is a Link Building & Influencer Outreach Platform for SEO Agencies, Brands, and In-house Marketers.",
    "category": "Marketing Automation",
    "title": "Pitchbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Pitchbox with SalesBlink",
    "metaDescription": "Integrate Pitchbox and other Marketing Automation Tools with SalesBlink. Two Way Pitchbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evessio",
    "slug": "evessio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f67372912b3d996843696008cbaf7a70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evessio is an events management platform for Awards, Conferences and Exhibitions",
    "category": "Event Management",
    "title": "Evessio Integration With Cold Email Sales Tool",
    "h1": "Integrate Evessio with SalesBlink",
    "metaDescription": "Integrate Evessio and other Event Management Tools with SalesBlink. Two Way Evessio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Urbanise FM",
    "slug": "urbanise-fm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce0aff5874f0138e19641bc417380025_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Urbanise offers cloud-based solutions to service the Facilities and Strata Management industries.",
    "category": "IT Operations",
    "title": "Urbanise FM Integration With Cold Email Sales Tool",
    "h1": "Integrate Urbanise FM with SalesBlink",
    "metaDescription": "Integrate Urbanise FM and other IT Operations Tools with SalesBlink. Two Way Urbanise FM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spincast",
    "slug": "spincast-ca124680",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/779bb13f530a57efed90a4a4b95f45c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PelotonZap allows you to integrate your Peloton Data into other services.",
    "category": "Fitness",
    "title": "Spincast Integration With Cold Email Sales Tool",
    "h1": "Integrate Spincast with SalesBlink",
    "metaDescription": "Integrate Spincast and other Fitness Tools with SalesBlink. Two Way Spincast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Expiration Reminder",
    "slug": "expiration-reminder",
    "logo": "https://zapier-images.imgix.net/storage/developer/16b0d291880ea8ba7a6fa74565acfc73.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Expiration Reminder automatically tracks expiration dates and renewals for employee certifications, software licenses, contracts, etc.",
    "category": "HR Talent & Recruitment",
    "title": "Expiration Reminder Integration With Cold Email Sales Tool",
    "h1": "Integrate Expiration Reminder with SalesBlink",
    "metaDescription": "Integrate Expiration Reminder and other HR Talent & Recruitment Tools with SalesBlink. Two Way Expiration Reminder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InputKit",
    "slug": "inputkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1178986e905eeb3adfd39e66337f398a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InputKit is a customer feedback software that helps B2B and B2C companies around the world streamline their customer satisfaction follow-up process. InputKit offers multiple tools to help businesses grow: Customer Feedback Surveys, Net Promoter Score, Online Reviews, Cross-Sales and more.",
    "category": "Forms & Surveys",
    "title": "InputKit Integration With Cold Email Sales Tool",
    "h1": "Integrate InputKit with SalesBlink",
    "metaDescription": "Integrate InputKit and other Forms & Surveys Tools with SalesBlink. Two Way InputKit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lexamica",
    "slug": "lexamica",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8dd189271d864d0b0ef35213c79812dc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lexamica is the first platform dedicated to legal referrals.",
    "category": "Communication",
    "title": "Lexamica Integration With Cold Email Sales Tool",
    "h1": "Integrate Lexamica with SalesBlink",
    "metaDescription": "Integrate Lexamica and other Communication Tools with SalesBlink. Two Way Lexamica Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CLOSEM",
    "slug": "closem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8697a04344012370ce81abc3093004c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CLOSEM is an automated tool  that puts your follow-up on autopliot.",
    "category": "Sales & CRM",
    "title": "CLOSEM Integration With Cold Email Sales Tool",
    "h1": "Integrate CLOSEM with SalesBlink",
    "metaDescription": "Integrate CLOSEM and other Sales & CRM Tools with SalesBlink. Two Way CLOSEM Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Feathr",
    "slug": "feathr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/481d9e3da44ac8b155133f86710e260e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feathr is an all-in-one digital marketing and advertising tool that gives organizations everything they need to grow their missions.",
    "category": "Ads & Conversion",
    "title": "Feathr Integration With Cold Email Sales Tool",
    "h1": "Integrate Feathr with SalesBlink",
    "metaDescription": "Integrate Feathr and other Ads & Conversion Tools with SalesBlink. Two Way Feathr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ScrapingAnt",
    "slug": "scrapingant",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3104fdf34d3cc08c3446451f8fa9a53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ScrapingAnt is a web scraping tool that handles proxies and headless browsers.",
    "category": "Content & Files",
    "title": "ScrapingAnt Integration With Cold Email Sales Tool",
    "h1": "Integrate ScrapingAnt with SalesBlink",
    "metaDescription": "Integrate ScrapingAnt and other Content & Files Tools with SalesBlink. Two Way ScrapingAnt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cohere",
    "slug": "cohere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b012268abb86757d4b4ddec2c5d4b778.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cohere is an all-in-one platform for Coaches, Consultants, and Creators providing them one place to sell, deliver, and scale services.",
    "category": "Productivity",
    "title": "Cohere Integration With Cold Email Sales Tool",
    "h1": "Integrate Cohere with SalesBlink",
    "metaDescription": "Integrate Cohere and other Productivity Tools with SalesBlink. Two Way Cohere Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Slickstream",
    "slug": "slickstream",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2e5714ef6f29e4cca466be9b34d83f06_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Slickstream is a service that helps you add engagement features into your website, including site search, content recommendations, membership, favorites and more",
    "category": "Website & App Building",
    "title": "Slickstream Integration With Cold Email Sales Tool",
    "h1": "Integrate Slickstream with SalesBlink",
    "metaDescription": "Integrate Slickstream and other Website & App Building Tools with SalesBlink. Two Way Slickstream Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GitScrum",
    "slug": "gitscrum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b979f5c8d4c39e71363c06b5fa5a2b12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Everything You Need to Manage your Projects Remotely",
    "category": "Project Management",
    "title": "GitScrum Integration With Cold Email Sales Tool",
    "h1": "Integrate GitScrum with SalesBlink",
    "metaDescription": "Integrate GitScrum and other Project Management Tools with SalesBlink. Two Way GitScrum Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "VOMO",
    "slug": "vomo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcf28eecf7e3f9204581e3187df81552.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VOMO is a volunteer engagement platform that helps organizations recruit, manage, and reward their volunteers.",
    "category": "Contact Management",
    "title": "VOMO Integration With Cold Email Sales Tool",
    "h1": "Integrate VOMO with SalesBlink",
    "metaDescription": "Integrate VOMO and other Contact Management Tools with SalesBlink. Two Way VOMO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wingmate",
    "slug": "wingmate",
    "logo": "https://zapier-images.imgix.net/storage/services/fbb694511e2dfcf8caeeec3242e16fcc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow, engage & simplify with the #1 in-field solution. Wingmate makes it easy for your people on-the-go to capture information and bridge communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wingmate Integration With Cold Email Sales Tool",
    "h1": "Integrate Wingmate with SalesBlink",
    "metaDescription": "Integrate Wingmate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wingmate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ehjzny",
    "slug": "ehjzny",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/611ec8e31d48fafee94c255aa0c5b1fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ehjzny.com is your go-to app for scheduling services efficiently.",
    "category": "Scheduling & Booking",
    "title": "Ehjzny Integration With Cold Email Sales Tool",
    "h1": "Integrate Ehjzny with SalesBlink",
    "metaDescription": "Integrate Ehjzny and other Scheduling & Booking Tools with SalesBlink. Two Way Ehjzny Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Learner Community",
    "slug": "learner-community",
    "logo": "https://zapier-images.imgix.net/storage/developer/30115c14e8f9b0a44eade6b873c46e72.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learner Community, a Xerox Initiative and Learning Management System",
    "category": "Online Courses",
    "title": "Learner Community Integration With Cold Email Sales Tool",
    "h1": "Integrate Learner Community with SalesBlink",
    "metaDescription": "Integrate Learner Community and other Online Courses Tools with SalesBlink. Two Way Learner Community Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Routee",
    "slug": "routee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/776d52e9207d5ef2392c1c58cc481592.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Routee is a platform that provides users with SMS sending functionality, enhancing communication between businesses and individuals.",
    "category": "Phone & SMS",
    "title": "Routee Integration With Cold Email Sales Tool",
    "h1": "Integrate Routee with SalesBlink",
    "metaDescription": "Integrate Routee and other Phone & SMS Tools with SalesBlink. Two Way Routee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eden AI",
    "slug": "eden-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86ebf958676c8bddfb913bd6b632913b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eden AI is an AI API aggregator that allows you to use many AI technologies and providers without having to set up each API individually.",
    "category": "AI Tools",
    "title": "Eden AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Eden AI with SalesBlink",
    "metaDescription": "Integrate Eden AI and other AI Tools Tools with SalesBlink. Two Way Eden AI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LetHub",
    "slug": "lethub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a74e51c05aad12398397a96d86472e99.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LetHub is an AI automation tool for modern property managers to automate their daily leasing tasks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LetHub Integration With Cold Email Sales Tool",
    "h1": "Integrate LetHub with SalesBlink",
    "metaDescription": "Integrate LetHub and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LetHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mailfloss",
    "slug": "mailfloss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3dc43e2e11f6225da1bf837caf23d4df_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailfloss is an email verification platform where you can put your bulk email verification on autopilot and start maximizing your email ROI.",
    "category": "Email",
    "title": "mailfloss Integration With Cold Email Sales Tool",
    "h1": "Integrate mailfloss with SalesBlink",
    "metaDescription": "Integrate mailfloss and other Email Tools with SalesBlink. Two Way mailfloss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gumlet",
    "slug": "gumlet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c856aa28a132036fefe4c03816a5cd9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gumlet is best way to stream ad-free videos on internet. It has powerful CMS to organise videos and hollywood grade DRM.",
    "category": "Video & Audio",
    "title": "Gumlet Integration With Cold Email Sales Tool",
    "h1": "Integrate Gumlet with SalesBlink",
    "metaDescription": "Integrate Gumlet and other Video & Audio Tools with SalesBlink. Two Way Gumlet Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Playvox Customer AI",
    "slug": "playvox-customer-ai",
    "logo": "https://zapier-images.imgix.net/storage/services/b4fd9317f8a53f1bdda743897db45355.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatically analyze the sentiment and topics of your customer interactions.",
    "category": "Analytics",
    "title": "Playvox Customer AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Playvox Customer AI with SalesBlink",
    "metaDescription": "Integrate Playvox Customer AI and other Analytics Tools with SalesBlink. Two Way Playvox Customer AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Request Tracker (RT)",
    "slug": "request-tracker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e286366c728434105092a7b4a5f8e1d_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Request Tracker (RT) is an open-source, enterprise-grade ticketing system.",
    "category": "Support",
    "title": "Request Tracker (RT) Integration With Cold Email Sales Tool",
    "h1": "Integrate Request Tracker (RT) with SalesBlink",
    "metaDescription": "Integrate Request Tracker (RT) and other Support Tools with SalesBlink. Two Way Request Tracker (RT) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PARiM",
    "slug": "parim",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5092c0ee033f7e18fdb73883a84c972.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Schedule work for your employees, control their attendance and calculate payments. Collaborate with your clients and subcontractors online.",
    "category": "Human Resources",
    "title": "PARiM Integration With Cold Email Sales Tool",
    "h1": "Integrate PARiM with SalesBlink",
    "metaDescription": "Integrate PARiM and other Human Resources Tools with SalesBlink. Two Way PARiM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clearly Cloud",
    "slug": "clearly-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d25719f11f214717f1c52c816824299.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clearly Cloud is a feature-rich unified communications solution that offers a suite of powerful services to businesses of all sizes.",
    "category": "Phone & SMS",
    "title": "Clearly Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Clearly Cloud with SalesBlink",
    "metaDescription": "Integrate Clearly Cloud and other Phone & SMS Tools with SalesBlink. Two Way Clearly Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Raklet",
    "slug": "raklet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7b5ec5ce0f40881ba0a5796809b4feb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Raklet is a CRM to help manage contacts, messages and payments. Schedule email and SMS, collect payments online and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Raklet Integration With Cold Email Sales Tool",
    "h1": "Integrate Raklet with SalesBlink",
    "metaDescription": "Integrate Raklet and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Raklet Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TwnSqr",
    "slug": "twnsqr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc2442f8a97a11a137a0d124e78f18fb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate your CRM with TwnSqr to automate post creation in your TwnSqr account.",
    "category": "Sales & CRM",
    "title": "TwnSqr Integration With Cold Email Sales Tool",
    "h1": "Integrate TwnSqr with SalesBlink",
    "metaDescription": "Integrate TwnSqr and other Sales & CRM Tools with SalesBlink. Two Way TwnSqr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Callback24",
    "slug": "callback24",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99c46ca28ad9a7de7af06bf6f75dee37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Callback24 is a complete lead generation solution to help increase your sales.",
    "category": "Marketing",
    "title": "Callback24 Integration With Cold Email Sales Tool",
    "h1": "Integrate Callback24 with SalesBlink",
    "metaDescription": "Integrate Callback24 and other Marketing Tools with SalesBlink. Two Way Callback24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tilkee",
    "slug": "tilkee-v3",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/09082699c902ab4048e4a5119b59f87b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Tilkee's latest API to track how your contacts read your documents.",
    "category": "Proposal & Invoice Management",
    "title": "Tilkee Integration With Cold Email Sales Tool",
    "h1": "Integrate Tilkee with SalesBlink",
    "metaDescription": "Integrate Tilkee and other Proposal & Invoice Management Tools with SalesBlink. Two Way Tilkee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Work Wallet",
    "slug": "work-wallet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/83ec47a1c3ad92efe10fc33b3d1d12e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Work Wallet provides an all-in-one platform to manage Health and Safety - helping keep employees and contractors safe in their place of work.",
    "category": "IT Operations",
    "title": "Work Wallet Integration With Cold Email Sales Tool",
    "h1": "Integrate Work Wallet with SalesBlink",
    "metaDescription": "Integrate Work Wallet and other IT Operations Tools with SalesBlink. Two Way Work Wallet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wasp AssetCloud",
    "slug": "wasp-assetcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62b36a546366c881389128908f2f5e20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AssetCloud by Wasp delivers full life-cycle asset management, streamlined auditing and loss prevention.",
    "category": "IT Operations",
    "title": "Wasp AssetCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Wasp AssetCloud with SalesBlink",
    "metaDescription": "Integrate Wasp AssetCloud and other IT Operations Tools with SalesBlink. Two Way Wasp AssetCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Linkjoy",
    "slug": "linkjoy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ad299810e28de2d14fd4f8aa8bcafab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Linkjoy helps Creators Increase Brand Awareness, Generate More Leads, Re-Target Visitors and Monetize social media followers .",
    "category": "Marketing",
    "title": "Linkjoy Integration With Cold Email Sales Tool",
    "h1": "Integrate Linkjoy with SalesBlink",
    "metaDescription": "Integrate Linkjoy and other Marketing Tools with SalesBlink. Two Way Linkjoy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Alert Communications",
    "slug": "alert-communications",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/947a3aa9a60d1f3736575904813d1b5b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alert Communications is a call center specializing in customized legal intakes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Alert Communications Integration With Cold Email Sales Tool",
    "h1": "Integrate Alert Communications with SalesBlink",
    "metaDescription": "Integrate Alert Communications and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Alert Communications Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mindburp",
    "slug": "mindburp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f712a11fb8e2a279f2e47a15d06034aa_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mindburp is an easy to use tool to keep track of tasks and collaborate with friends and colleagues",
    "category": "Task Management",
    "title": "mindburp Integration With Cold Email Sales Tool",
    "h1": "Integrate mindburp with SalesBlink",
    "metaDescription": "Integrate mindburp and other Task Management Tools with SalesBlink. Two Way mindburp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LoyaltySurf",
    "slug": "loyaltysurf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30c89f47059afde032f15e2706c4f20c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LoyaltySurf is loyalty software for B2C, FinTech, and SaaS companies.",
    "category": "Customer Appreciation",
    "title": "LoyaltySurf Integration With Cold Email Sales Tool",
    "h1": "Integrate LoyaltySurf with SalesBlink",
    "metaDescription": "Integrate LoyaltySurf and other Customer Appreciation Tools with SalesBlink. Two Way LoyaltySurf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gearbooker",
    "slug": "gearbooker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b4aa45511a5a389861f1aadc936ceed7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gearbooker is a peer-to-peer rental platform for creators to rent (out) photo, video and audio equipment.",
    "category": "Scheduling & Booking",
    "title": "Gearbooker Integration With Cold Email Sales Tool",
    "h1": "Integrate Gearbooker with SalesBlink",
    "metaDescription": "Integrate Gearbooker and other Scheduling & Booking Tools with SalesBlink. Two Way Gearbooker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smartcat",
    "slug": "smartcat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3d785be3b71c4a37cfea17c69d8bced_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smartcat is an all-in-one platform connecting businesses and translators into a continuous translation delivery loop by combining a collaborative workflow solution, a marketplace, and a network of translation professionals.",
    "category": "Transcription",
    "title": "Smartcat Integration With Cold Email Sales Tool",
    "h1": "Integrate Smartcat with SalesBlink",
    "metaDescription": "Integrate Smartcat and other Transcription Tools with SalesBlink. Two Way Smartcat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "RingByName",
    "slug": "ringbyname",
    "logo": "https://zapier-images.imgix.net/storage/developer/f08a2658d948106c7bdaed8a413cda79_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RingByName is a cloud-based phone service. It helps you build stronger relationships with your customers with CRM features.",
    "category": "Phone & SMS",
    "title": "RingByName Integration With Cold Email Sales Tool",
    "h1": "Integrate RingByName with SalesBlink",
    "metaDescription": "Integrate RingByName and other Phone & SMS Tools with SalesBlink. Two Way RingByName Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Groupified",
    "slug": "groupified",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8fef8349dd79ea7d992160a98a0657f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Groupified is a platform for connecting with your Facebook group members through other platforms and campaigns.",
    "category": "Social Media Marketing",
    "title": "Groupified Integration With Cold Email Sales Tool",
    "h1": "Integrate Groupified with SalesBlink",
    "metaDescription": "Integrate Groupified and other Social Media Marketing Tools with SalesBlink. Two Way Groupified Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "8x8",
    "slug": "8x8",
    "logo": "https://zapier-images.imgix.net/storage/services/ce5c0ca126e9ec1c867fd7ef0a850378.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "8x8’s integrated communications platform as a service(CPaaS) providing SMS, Voice, Chat Apps and Video Interaction integrations",
    "category": "Phone & SMS",
    "title": "8x8 Integration With Cold Email Sales Tool",
    "h1": "Integrate 8x8 with SalesBlink",
    "metaDescription": "Integrate 8x8 and other Phone & SMS Tools with SalesBlink. Two Way 8x8 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "StreamFit",
    "slug": "streamfit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1d7204f518a3a0f1ce55e146d468327.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StreamFit is a gym management platform that combines virtual, competitions and member management, all in one.",
    "category": "Fitness",
    "title": "StreamFit Integration With Cold Email Sales Tool",
    "h1": "Integrate StreamFit with SalesBlink",
    "metaDescription": "Integrate StreamFit and other Fitness Tools with SalesBlink. Two Way StreamFit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agora",
    "slug": "agora",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/404dff634fdd0f7c2529ab3702860bcd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agora helps real estate firms accelerate their growth and exceed investors’ expectations with digital transformation",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agora Integration With Cold Email Sales Tool",
    "h1": "Integrate Agora with SalesBlink",
    "metaDescription": "Integrate Agora and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agora Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TAYL",
    "slug": "tayl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e143ba306b205ef22a212293d4b9f43.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TAYL is an app that turns text and web pages into podcast audio.",
    "category": "Video & Audio",
    "title": "TAYL Integration With Cold Email Sales Tool",
    "h1": "Integrate TAYL with SalesBlink",
    "metaDescription": "Integrate TAYL and other Video & Audio Tools with SalesBlink. Two Way TAYL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Olvy",
    "slug": "olvy",
    "logo": "https://zapier-images.imgix.net/storage/services/cfa7237edaa8a85b4960cf3a0b64c735.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Olvy is the comms center for your product team. Keep track of user feedback, and close the loop everywhere your users are.",
    "category": "Product Management",
    "title": "Olvy Integration With Cold Email Sales Tool",
    "h1": "Integrate Olvy with SalesBlink",
    "metaDescription": "Integrate Olvy and other Product Management Tools with SalesBlink. Two Way Olvy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrustedForm",
    "slug": "trustedform-consent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9dd1feb80a5a7699baa0fe2cce60add4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrustedForm provides unbiased, third-party documentation of consent in the form of a certificate for the leads you are acquiring.",
    "category": "Marketing",
    "title": "TrustedForm Integration With Cold Email Sales Tool",
    "h1": "Integrate TrustedForm with SalesBlink",
    "metaDescription": "Integrate TrustedForm and other Marketing Tools with SalesBlink. Two Way TrustedForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easydoc",
    "slug": "easydoc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a165f76fca804ee4e14ee62903db116.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send document for E Signature and Receive back a signed copy",
    "category": "Documents",
    "title": "Easydoc Integration With Cold Email Sales Tool",
    "h1": "Integrate Easydoc with SalesBlink",
    "metaDescription": "Integrate Easydoc and other Documents Tools with SalesBlink. Two Way Easydoc Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Hints",
    "slug": "hints",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/164a9cdcb0a0f7a003a8afe542d62296.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Low-friction tool for capturing, organizing, and sharing information with teams on the go and move ideas forward.",
    "category": "Productivity",
    "title": "Hints Integration With Cold Email Sales Tool",
    "h1": "Integrate Hints with SalesBlink",
    "metaDescription": "Integrate Hints and other Productivity Tools with SalesBlink. Two Way Hints Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GRID.is",
    "slug": "gridis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c223bdb444bccda2168f960c1725881c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GRID is a cloud-based web platform that allows anyone to create and share professional looking interactive documents online.",
    "category": "Spreadsheets",
    "title": "GRID.is Integration With Cold Email Sales Tool",
    "h1": "Integrate GRID.is with SalesBlink",
    "metaDescription": "Integrate GRID.is and other Spreadsheets Tools with SalesBlink. Two Way GRID.is Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Etrafficers",
    "slug": "etrafficers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1974c90fff24837a6a49e6373b4b25ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Etrafficers creates beautifully designed websites for mortgage companies and loan officers.",
    "category": "Website Builders",
    "title": "Etrafficers Integration With Cold Email Sales Tool",
    "h1": "Integrate Etrafficers with SalesBlink",
    "metaDescription": "Integrate Etrafficers and other Website Builders Tools with SalesBlink. Two Way Etrafficers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Morning Metrics",
    "slug": "morning-metrics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/552a23d1e52940bcf9fc618dedc37c37_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Morning is a platform that allows you to trigger workflows as your metrics change.",
    "category": "Business Intelligence",
    "title": "Morning Metrics Integration With Cold Email Sales Tool",
    "h1": "Integrate Morning Metrics with SalesBlink",
    "metaDescription": "Integrate Morning Metrics and other Business Intelligence Tools with SalesBlink. Two Way Morning Metrics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Joinpoints",
    "slug": "joinpoints",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fdca9f7b685d980d3577dbcf6e363832.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Joinpoints is a platform for creating digital recurring workflows in order to perform and automate them in your company.",
    "category": "Productivity",
    "title": "Joinpoints Integration With Cold Email Sales Tool",
    "h1": "Integrate Joinpoints with SalesBlink",
    "metaDescription": "Integrate Joinpoints and other Productivity Tools with SalesBlink. Two Way Joinpoints Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alkymi",
    "slug": "alkymi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/64646cab8a8b584431c1bd6ab9af9e03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alkymi is an intelligent data extraction and process automation application for the financial services industry.",
    "category": "Documents",
    "title": "Alkymi Integration With Cold Email Sales Tool",
    "h1": "Integrate Alkymi with SalesBlink",
    "metaDescription": "Integrate Alkymi and other Documents Tools with SalesBlink. Two Way Alkymi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blastable",
    "slug": "blastable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/261b20699516c88253920d79617b1865_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blastable is a simple but powerful email software that focuses on email inbox deliverability.",
    "category": "Marketing Automation",
    "title": "Blastable Integration With Cold Email Sales Tool",
    "h1": "Integrate Blastable with SalesBlink",
    "metaDescription": "Integrate Blastable and other Marketing Automation Tools with SalesBlink. Two Way Blastable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Twitvid",
    "slug": "twitvid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d2a10e63b36a4f101ebdbe2d25b7ba01.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twitvid turns tweets into short video content with one click.",
    "category": "Social Media Marketing",
    "title": "Twitvid Integration With Cold Email Sales Tool",
    "h1": "Integrate Twitvid with SalesBlink",
    "metaDescription": "Integrate Twitvid and other Social Media Marketing Tools with SalesBlink. Two Way Twitvid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ganttic",
    "slug": "ganttic",
    "logo": "https://zapier-images.imgix.net/storage/services/487dc0ad172873680d9b1b7c8f307365.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ganttic is an online resource planning software, that you can use for managing project portfolios while planning resources with maximum efficiency. You can create clear and comprehensive visual plans that give you an instant overview of all your resources and projects.",
    "category": "Project Management",
    "title": "Ganttic Integration With Cold Email Sales Tool",
    "h1": "Integrate Ganttic with SalesBlink",
    "metaDescription": "Integrate Ganttic and other Project Management Tools with SalesBlink. Two Way Ganttic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "bountyblok",
    "slug": "bountyblok",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1ac00ce0d541e9021dcae2db177d536.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bountyblok is a gamification platform to integrate gaming mechanics in order to motivate and promote user engagement.",
    "category": "Notifications",
    "title": "bountyblok Integration With Cold Email Sales Tool",
    "h1": "Integrate bountyblok with SalesBlink",
    "metaDescription": "Integrate bountyblok and other Notifications Tools with SalesBlink. Two Way bountyblok Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Desku.io",
    "slug": "deskuio-integrations",
    "logo": "https://zapier-images.imgix.net/storage/services/5be74d176cce6568afa13265fdfe1d99.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Desku.io you can automate your customer support day to day operations and have more time for what matters most — no code required.",
    "category": "Customer Support",
    "title": "Desku.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Desku.io with SalesBlink",
    "metaDescription": "Integrate Desku.io and other Customer Support Tools with SalesBlink. Two Way Desku.io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "OriginStamp",
    "slug": "originstamp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a84558b8b29423dcb9bed4e451ac3716.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OriginStamp allows to create digital timestamps that are shared within a huge network world-wide which makes them tamper-proof.",
    "category": "Documents",
    "title": "OriginStamp Integration With Cold Email Sales Tool",
    "h1": "Integrate OriginStamp with SalesBlink",
    "metaDescription": "Integrate OriginStamp and other Documents Tools with SalesBlink. Two Way OriginStamp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Maysee",
    "slug": "maysee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99d1abc0a678267f7c64e2e357bc269c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maysee is a business card management app that makes it easy to send them on your smartphone or PC.",
    "category": "Contact Management",
    "title": "Maysee Integration With Cold Email Sales Tool",
    "h1": "Integrate Maysee with SalesBlink",
    "metaDescription": "Integrate Maysee and other Contact Management Tools with SalesBlink. Two Way Maysee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ManyContacts",
    "slug": "manycontacts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06ec081b41296ef0a97595a50dde666b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ManyContacts is a WhatsAPP CRM to help SMBs manage hundreds of conversations",
    "category": "Contact Management",
    "title": "ManyContacts Integration With Cold Email Sales Tool",
    "h1": "Integrate ManyContacts with SalesBlink",
    "metaDescription": "Integrate ManyContacts and other Contact Management Tools with SalesBlink. Two Way ManyContacts Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Amped",
    "slug": "amped",
    "logo": "https://zapier-images.imgix.net/storage/services/f3d76eb13e175bcebecc17ad1448cc36.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amped is an intelligent sales automation tool that enhances productivity, streamlines process, and empowers your sales teams to close more and increase retention.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Amped Integration With Cold Email Sales Tool",
    "h1": "Integrate Amped with SalesBlink",
    "metaDescription": "Integrate Amped and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Amped Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docspo",
    "slug": "docspo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eaeeda2b2e1cdc75dc09294d1fb4d58f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send beautiful documents for eSignature.",
    "category": "Signatures",
    "title": "Docspo Integration With Cold Email Sales Tool",
    "h1": "Integrate Docspo with SalesBlink",
    "metaDescription": "Integrate Docspo and other Signatures Tools with SalesBlink. Two Way Docspo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesdash CRM",
    "slug": "salesdash-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3932cbeb6394ba2c0d7feba75a46a5be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesdash CRM helps small businesses grow sales with our simple client management software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salesdash CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesdash CRM with SalesBlink",
    "metaDescription": "Integrate Salesdash CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salesdash CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YouTestMe",
    "slug": "youtestme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1769efb45d8ac993ca91b33cdc0b6149_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YouTestMe GetCertified is a web-based platform for examination, training, survey & certification with an extensive reporting module.",
    "category": "Human Resources",
    "title": "YouTestMe Integration With Cold Email Sales Tool",
    "h1": "Integrate YouTestMe with SalesBlink",
    "metaDescription": "Integrate YouTestMe and other Human Resources Tools with SalesBlink. Two Way YouTestMe Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "UProc",
    "slug": "uproc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c4950b849b743b84b0ea781273089d90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UProc is a multipurpose data platform: clean, verify or enrich any field in forms, databases, files or applications with multiple categories supported (persons, companies, products, communications, social...).",
    "category": "Databases",
    "title": "UProc Integration With Cold Email Sales Tool",
    "h1": "Integrate UProc with SalesBlink",
    "metaDescription": "Integrate UProc and other Databases Tools with SalesBlink. Two Way UProc Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Moderation API",
    "slug": "moderation-api",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f80d364dbe49c5045d8aa49ea101c622.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatically detect and hide personal information like email addresses and phone numbers - even if the user is trying to bypass detection!",
    "category": "",
    "title": "Moderation API Integration With Cold Email Sales Tool",
    "h1": "Integrate Moderation API with SalesBlink",
    "metaDescription": "Integrate Moderation API and other  Tools with SalesBlink. Two Way Moderation API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qomon",
    "slug": "qomon",
    "logo": "https://zapier-images.imgix.net/storage/services/2db094a4bb7c1a8b7a10e4af139b689f.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Qomon, turn anyone into an active supporter, drive actions digitally & on the field, capture data, make the most of it.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Qomon Integration With Cold Email Sales Tool",
    "h1": "Integrate Qomon with SalesBlink",
    "metaDescription": "Integrate Qomon and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Qomon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrowKarma",
    "slug": "growkarma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f6b803c2be147899f6f8b5984c401ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowKarma is an online based leadership programs built from extracting applicable concepts and ideas from various books, and translating them into habits and skills people can apply towards their goals.",
    "category": "Education",
    "title": "GrowKarma Integration With Cold Email Sales Tool",
    "h1": "Integrate GrowKarma with SalesBlink",
    "metaDescription": "Integrate GrowKarma and other Education Tools with SalesBlink. Two Way GrowKarma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoilaNorbert",
    "slug": "voilanorbert",
    "logo": "https://zapier-images.imgix.net/storage/developer/3186ce648bea6d5e696c70cc04332068.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoilaNorbert is an easy tool to find anyone's email address and reach out to them.",
    "category": "Contact Management",
    "title": "VoilaNorbert Integration With Cold Email Sales Tool",
    "h1": "Integrate VoilaNorbert with SalesBlink",
    "metaDescription": "Integrate VoilaNorbert and other Contact Management Tools with SalesBlink. Two Way VoilaNorbert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NetBase Quid",
    "slug": "netbase-quid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62273e3a4d686552170402565452bec9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NetBase Quid is a Consumer and Market Intelligence Platform",
    "category": "Social Media Marketing",
    "title": "NetBase Quid Integration With Cold Email Sales Tool",
    "h1": "Integrate NetBase Quid with SalesBlink",
    "metaDescription": "Integrate NetBase Quid and other Social Media Marketing Tools with SalesBlink. Two Way NetBase Quid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobivate BulkSMS",
    "slug": "mobivate-bulksms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd710443e1ce8aaebcd757b227a57c30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send SMS messages hassle-free using Mobivate. Receive SMS messages and trigger your ZAPs.",
    "category": "Phone & SMS",
    "title": "Mobivate BulkSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobivate BulkSMS with SalesBlink",
    "metaDescription": "Integrate Mobivate BulkSMS and other Phone & SMS Tools with SalesBlink. Two Way Mobivate BulkSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gnowbe",
    "slug": "gnowbe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19360f49731a7193a12879be5a14cc4e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gnowbe is leveraging mobile learning to grow productivity and redefining employee development.",
    "category": "Education",
    "title": "Gnowbe Integration With Cold Email Sales Tool",
    "h1": "Integrate Gnowbe with SalesBlink",
    "metaDescription": "Integrate Gnowbe and other Education Tools with SalesBlink. Two Way Gnowbe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Edutive",
    "slug": "edutive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc18d65e352d15410dd7b47b1d1d9d83_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Edutive is a web app designed to manage and grow online schools and bootcamps",
    "category": "Education",
    "title": "Edutive Integration With Cold Email Sales Tool",
    "h1": "Integrate Edutive with SalesBlink",
    "metaDescription": "Integrate Edutive and other Education Tools with SalesBlink. Two Way Edutive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agendize",
    "slug": "agendize",
    "logo": "https://zapier-images.imgix.net/storage/developer/de5542bfb6c47d267bb7014f5698ec18_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agendize is a customer engagement platform that offers easy appointment booking, contact management, live chat and call-tracking.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Agendize Integration With Cold Email Sales Tool",
    "h1": "Integrate Agendize with SalesBlink",
    "metaDescription": "Integrate Agendize and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Agendize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oktopost",
    "slug": "oktopost",
    "logo": "https://zapier-images.imgix.net/storage/developer/7bcd0dac1f009e813b646ec1c59c9bf4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Oktopost is a B2B social engagement suite that supports modern data-driven organizations.",
    "category": "Social Media Marketing",
    "title": "Oktopost Integration With Cold Email Sales Tool",
    "h1": "Integrate Oktopost with SalesBlink",
    "metaDescription": "Integrate Oktopost and other Social Media Marketing Tools with SalesBlink. Two Way Oktopost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UptimeToolbox",
    "slug": "uptimetoolbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/501eb1f96b65c1a56b0e2ce0e4149dd0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Website uptime monitoring with public status pages.",
    "category": "Developer Tools",
    "title": "UptimeToolbox Integration With Cold Email Sales Tool",
    "h1": "Integrate UptimeToolbox with SalesBlink",
    "metaDescription": "Integrate UptimeToolbox and other Developer Tools Tools with SalesBlink. Two Way UptimeToolbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextSpot",
    "slug": "textspot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/846b6daf00f5cade76a9e36e85002257.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextSpot lets you easily send text messages to your contacts.",
    "category": "Marketing",
    "title": "TextSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate TextSpot with SalesBlink",
    "metaDescription": "Integrate TextSpot and other Marketing Tools with SalesBlink. Two Way TextSpot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wavo",
    "slug": "wavo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89c3c37fc29be07d1a3a15493615aa80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wavo is a cold email tool that automatically sends outbound emails and follow up sequences directly from your email account.",
    "category": "Drip Emails",
    "title": "Wavo Integration With Cold Email Sales Tool",
    "h1": "Integrate Wavo with SalesBlink",
    "metaDescription": "Integrate Wavo and other Drip Emails Tools with SalesBlink. Two Way Wavo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RecallCue",
    "slug": "recallcue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b83c3c0c968773ccc7621cd4dff4ecd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RecallCue is a \"connected\" Day Clock enriching the lives of your elderly loved ones with the ability to send messages, reminders, photos and more.",
    "category": "App Families",
    "title": "RecallCue Integration With Cold Email Sales Tool",
    "h1": "Integrate RecallCue with SalesBlink",
    "metaDescription": "Integrate RecallCue and other App Families Tools with SalesBlink. Two Way RecallCue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pitchly",
    "slug": "pitchly",
    "logo": "https://zapier-images.imgix.net/storage/services/6e62e820d5eafc0c6cc6aa678f43a6d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pitchly helps you collect and turn raw data into work assets, like pitch decks and proposals.",
    "category": "Databases",
    "title": "Pitchly Integration With Cold Email Sales Tool",
    "h1": "Integrate Pitchly with SalesBlink",
    "metaDescription": "Integrate Pitchly and other Databases Tools with SalesBlink. Two Way Pitchly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Roadoo",
    "slug": "roadoo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dece1a0e1c404273bfa2416463df6627_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Roadoo is the incentive app that saves you time and optimizes the results of your business challenges.",
    "category": "Productivity",
    "title": "Roadoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Roadoo with SalesBlink",
    "metaDescription": "Integrate Roadoo and other Productivity Tools with SalesBlink. Two Way Roadoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrizzlyLeads",
    "slug": "grizzlyleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d30d56ba3e7877cc1f038413433002a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrizzlyLeads uses multi-platform advertising to connect real estate agents with potential clients.",
    "category": "Ads & Conversion",
    "title": "GrizzlyLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate GrizzlyLeads with SalesBlink",
    "metaDescription": "Integrate GrizzlyLeads and other Ads & Conversion Tools with SalesBlink. Two Way GrizzlyLeads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Advantage Anywhere",
    "slug": "advantage-anywhere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/173035edac11e6aa236c995554fa3b27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The leads, sales & marketing automation machine: CRM, email, video, print, text, landing pages, social, events, sales funnel…all-in-one!",
    "category": "Marketing Automation",
    "title": "Advantage Anywhere Integration With Cold Email Sales Tool",
    "h1": "Integrate Advantage Anywhere with SalesBlink",
    "metaDescription": "Integrate Advantage Anywhere and other Marketing Automation Tools with SalesBlink. Two Way Advantage Anywhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ideanote",
    "slug": "ideanote",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85ff652b3a106bd1fb8659f8d4699f8e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ideanote is an easier and faster way for companies to collect, collaborate and act on ideas from customers and employees.",
    "category": "Team Collaboration",
    "title": "Ideanote Integration With Cold Email Sales Tool",
    "h1": "Integrate Ideanote with SalesBlink",
    "metaDescription": "Integrate Ideanote and other Team Collaboration Tools with SalesBlink. Two Way Ideanote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Letterhead",
    "slug": "letterhead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f5c5f84ef7991b32e295bc3b64548ee1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Letterhead is a business toolkit for newsletter creators.",
    "category": "Sales & CRM",
    "title": "Letterhead Integration With Cold Email Sales Tool",
    "h1": "Integrate Letterhead with SalesBlink",
    "metaDescription": "Integrate Letterhead and other Sales & CRM Tools with SalesBlink. Two Way Letterhead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wave Cards",
    "slug": "wave-cards",
    "logo": "https://zapier-images.imgix.net/storage/services/ba212f4d1d3b6118178697b16afba06d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Share your contact details, a landing page, or social media accounts with a single Wave of your NFC business card.",
    "category": "Contact Management",
    "title": "Wave Cards Integration With Cold Email Sales Tool",
    "h1": "Integrate Wave Cards with SalesBlink",
    "metaDescription": "Integrate Wave Cards and other Contact Management Tools with SalesBlink. Two Way Wave Cards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vaunt",
    "slug": "vaunt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/38210b9f4e605ecf33b22261d765a963.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vaunt® is a real estate ERP designed for developers and agencies.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vaunt Integration With Cold Email Sales Tool",
    "h1": "Integrate Vaunt with SalesBlink",
    "metaDescription": "Integrate Vaunt and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vaunt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fuze",
    "slug": "fuze",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4dab1fe3aa60c2f01beac627de6a69ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fuze is an enterprise-grade Unified Communications as a Service (UCaaS) platform, enabling teams and individuals to chat, call, meet and content share effortlessly, anywhere on any device.",
    "category": "Team Collaboration",
    "title": "Fuze Integration With Cold Email Sales Tool",
    "h1": "Integrate Fuze with SalesBlink",
    "metaDescription": "Integrate Fuze and other Team Collaboration Tools with SalesBlink. Two Way Fuze Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrazeCart",
    "slug": "grazecart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8392e48bca03d999df189317066c5c21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sell more and streamline operations with the easiest eCommerce platform built for perishable food suppliers.",
    "category": "eCommerce",
    "title": "GrazeCart Integration With Cold Email Sales Tool",
    "h1": "Integrate GrazeCart with SalesBlink",
    "metaDescription": "Integrate GrazeCart and other eCommerce Tools with SalesBlink. Two Way GrazeCart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emotive",
    "slug": "emotive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a345363d18a932a2d9c6e37f21c2491f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emotive is an eCommerce service that enables eCommerce businesses to scale 1:1 relationships through conversational text messaging.",
    "category": "eCommerce",
    "title": "Emotive Integration With Cold Email Sales Tool",
    "h1": "Integrate Emotive with SalesBlink",
    "metaDescription": "Integrate Emotive and other eCommerce Tools with SalesBlink. Two Way Emotive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pure360",
    "slug": "pure360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/725005b4142c4df287eaf9a1132f2525_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pure360 offers marketing automation powered by email, enabling UK marketers to deliver targeted multi-channel campaigns.",
    "category": "Marketing Automation",
    "title": "Pure360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Pure360 with SalesBlink",
    "metaDescription": "Integrate Pure360 and other Marketing Automation Tools with SalesBlink. Two Way Pure360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Owloops",
    "slug": "owloops",
    "logo": "https://zapier-images.imgix.net/storage/services/dc5ce729b1bffb4d9d89ddfb4b96ab64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Owloops is a low-code in-browser automation tool.",
    "category": "Website & App Building",
    "title": "Owloops Integration With Cold Email Sales Tool",
    "h1": "Integrate Owloops with SalesBlink",
    "metaDescription": "Integrate Owloops and other Website & App Building Tools with SalesBlink. Two Way Owloops Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloud Assess",
    "slug": "cloud-assess",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ddbaed345ce9ee200eb956452cdb74e0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud Assess provides corporates, training providers and government entities with flexible and powerful online assessment and training solutions.",
    "category": "Education",
    "title": "Cloud Assess Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloud Assess with SalesBlink",
    "metaDescription": "Integrate Cloud Assess and other Education Tools with SalesBlink. Two Way Cloud Assess Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CaptainBook.io",
    "slug": "captainbookio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85939cbef650a7efe2689aadc97ff59c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CaptainBook.io is a booking and ticketing software for travel tours and experience companies.",
    "category": "Scheduling & Booking",
    "title": "CaptainBook.io Integration With Cold Email Sales Tool",
    "h1": "Integrate CaptainBook.io with SalesBlink",
    "metaDescription": "Integrate CaptainBook.io and other Scheduling & Booking Tools with SalesBlink. Two Way CaptainBook.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrassBlade LRS",
    "slug": "grassblade-lrs-ca26965",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/615cc3c76ef9beb4ac29f7710d9331f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrassBlade LRS is a Learning Record Store and eLearning Analytics platform used for handling Experience API (xAPI) data.",
    "category": "Databases",
    "title": "GrassBlade LRS Integration With Cold Email Sales Tool",
    "h1": "Integrate GrassBlade LRS with SalesBlink",
    "metaDescription": "Integrate GrassBlade LRS and other Databases Tools with SalesBlink. Two Way GrassBlade LRS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "installHUB",
    "slug": "installhub",
    "logo": "https://zapier-images.imgix.net/storage/services/fee850622174be587f9d319cdccc17ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "installHUB, the flexible solution helping you engage, track and manage customers and installation lifecycles with efficiency and simplicity.",
    "category": "Scheduling & Booking",
    "title": "installHUB Integration With Cold Email Sales Tool",
    "h1": "Integrate installHUB with SalesBlink",
    "metaDescription": "Integrate installHUB and other Scheduling & Booking Tools with SalesBlink. Two Way installHUB Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bloom Intelligence",
    "slug": "bloom-intelligence",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/134cb53bcb4040818fe0853a97d47aeb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bloom Intelligence is a platform that provides best-in-class detailed location-based WiFi analytics that allows you to measure customer behavior in real-time at your physical location.",
    "category": "Business Intelligence",
    "title": "Bloom Intelligence Integration With Cold Email Sales Tool",
    "h1": "Integrate Bloom Intelligence with SalesBlink",
    "metaDescription": "Integrate Bloom Intelligence and other Business Intelligence Tools with SalesBlink. Two Way Bloom Intelligence Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyCRM Leads",
    "slug": "mycrm-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/812c0168bf6334e8bbae907304a23981_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyCRM puts everything you need at your fingertips so you can make the most of your limited and valuable time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MyCRM Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate MyCRM Leads with SalesBlink",
    "metaDescription": "Integrate MyCRM Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MyCRM Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Odeum",
    "slug": "odeum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/34335fa961377e29c94e86b48ae8bb1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Odeum is a complete solution for launching a branded subscription video service with apps on every major platform.",
    "category": "Video & Audio",
    "title": "Odeum Integration With Cold Email Sales Tool",
    "h1": "Integrate Odeum with SalesBlink",
    "metaDescription": "Integrate Odeum and other Video & Audio Tools with SalesBlink. Two Way Odeum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slazzer",
    "slug": "slazzer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a8e7509c5c697283cadfb70f442540a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Slazzer is an AI powered tool that remove the background from any image automatically in just a few seconds.",
    "category": "Images & Design",
    "title": "Slazzer Integration With Cold Email Sales Tool",
    "h1": "Integrate Slazzer with SalesBlink",
    "metaDescription": "Integrate Slazzer and other Images & Design Tools with SalesBlink. Two Way Slazzer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jobtoolz",
    "slug": "jobtoolz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68810140b33dc662e3bdc85515f8f88d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jobtoolz is an intuitive cloud-based recruitment platform on which you can build and show your employer branding, which automates your recruitment processes (ATS) and also attracts the right talent by data-driven recruitment marketing.",
    "category": "HR Talent & Recruitment",
    "title": "Jobtoolz Integration With Cold Email Sales Tool",
    "h1": "Integrate Jobtoolz with SalesBlink",
    "metaDescription": "Integrate Jobtoolz and other HR Talent & Recruitment Tools with SalesBlink. Two Way Jobtoolz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InTouch Tool",
    "slug": "intouch-tool",
    "logo": "https://zapier-images.imgix.net/storage/services/f36b89e21195d976f231515809a58835.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InTouch Tool automates your LinkedIn outreach with auto connections and followups. Setup your search filters to collect prospects, set your messages sequence aka drip campaign and get replies from new potential customers at scale.",
    "category": "Marketing Automation",
    "title": "InTouch Tool Integration With Cold Email Sales Tool",
    "h1": "Integrate InTouch Tool with SalesBlink",
    "metaDescription": "Integrate InTouch Tool and other Marketing Automation Tools with SalesBlink. Two Way InTouch Tool Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "supportivekoala",
    "slug": "supportivekoala",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/840213653ed0e3be2c1bb16521d3fa27_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Supportivekoala is a tool for automating marketing. It allows you to autogenerate images by your input.",
    "category": "Marketing Automation",
    "title": "supportivekoala Integration With Cold Email Sales Tool",
    "h1": "Integrate supportivekoala with SalesBlink",
    "metaDescription": "Integrate supportivekoala and other Marketing Automation Tools with SalesBlink. Two Way supportivekoala Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Krunch",
    "slug": "krunch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7dc2c80505a3c29420f5a5432bedf97e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Krunch is a content engagement analytic tool providing a single view of performance for different types of contents from a tweet, video, Q/A, livestreams, blog posts, talks and meetup.",
    "category": "Marketing",
    "title": "Krunch Integration With Cold Email Sales Tool",
    "h1": "Integrate Krunch with SalesBlink",
    "metaDescription": "Integrate Krunch and other Marketing Tools with SalesBlink. Two Way Krunch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BillBjorn",
    "slug": "billbjorn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8298fd7758a9179be3ac35926641e700_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BillBjorn is a tool to automate the data entry of bills and receipts into your accounting software.",
    "category": "Accounting",
    "title": "BillBjorn Integration With Cold Email Sales Tool",
    "h1": "Integrate BillBjorn with SalesBlink",
    "metaDescription": "Integrate BillBjorn and other Accounting Tools with SalesBlink. Two Way BillBjorn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "7todos",
    "slug": "seven-todos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/78dcedbec03a5c8c80c6629cce845ae8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "7todos is a task board for people working on multiple projects. Get an overview of all your tasks across projects, at once.",
    "category": "Product Management",
    "title": "7todos Integration With Cold Email Sales Tool",
    "h1": "Integrate 7todos with SalesBlink",
    "metaDescription": "Integrate 7todos and other Product Management Tools with SalesBlink. Two Way 7todos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CallCabinet Atmos",
    "slug": "callcabinet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33a47e3f0ab2bb3dd26f4b8cba91ad5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Call Cabinet Atmos is a cloud based enterprise call recording and quality management platform.",
    "category": "Call Tracking",
    "title": "CallCabinet Atmos Integration With Cold Email Sales Tool",
    "h1": "Integrate CallCabinet Atmos with SalesBlink",
    "metaDescription": "Integrate CallCabinet Atmos and other Call Tracking Tools with SalesBlink. Two Way CallCabinet Atmos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CRM Web App",
    "slug": "crm-web-app",
    "logo": "https://zapier-images.imgix.net/storage/services/8801d7bc0b34e37e38ff8368d5e3ec37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CRM Web App is a Zapier App that interacts with Credit Money Machine Web, CRM Business Companion, CRM Monitor Companion, and Automatic Business Machine.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CRM Web App Integration With Cold Email Sales Tool",
    "h1": "Integrate CRM Web App with SalesBlink",
    "metaDescription": "Integrate CRM Web App and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CRM Web App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContentBot",
    "slug": "contentbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c728bb38a6e5f3680b3dd234c1b47659.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContentBot is the perfect AI Writer for founders and marketers. Add an AI Content Assistant to your team today - Create high quality content with ContentBot.ai and GPT-3",
    "category": "Content & Files",
    "title": "ContentBot Integration With Cold Email Sales Tool",
    "h1": "Integrate ContentBot with SalesBlink",
    "metaDescription": "Integrate ContentBot and other Content & Files Tools with SalesBlink. Two Way ContentBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Striven",
    "slug": "striven",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85872925d803ff812ef4528e982f8be7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Striven is a full suite of integrated business management tools with everything your organization needs for success",
    "category": "Accounting",
    "title": "Striven Integration With Cold Email Sales Tool",
    "h1": "Integrate Striven with SalesBlink",
    "metaDescription": "Integrate Striven and other Accounting Tools with SalesBlink. Two Way Striven Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ServiceBell",
    "slug": "servicebell",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46da7e8c8371c1b72772c4039427be4e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceBell helps you win more customers and create better support experiences with live video chat, screen takeover, and user monitoring – directly on your website.",
    "category": "Customer Support",
    "title": "ServiceBell Integration With Cold Email Sales Tool",
    "h1": "Integrate ServiceBell with SalesBlink",
    "metaDescription": "Integrate ServiceBell and other Customer Support Tools with SalesBlink. Two Way ServiceBell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PeopleSmart",
    "slug": "peoplesmart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/275eeb0367ea93ea90fb31b116c9a80b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PeopleSmart is a B2B contact search engine for finding leads, candidates, and prospects",
    "category": "Contact Management",
    "title": "PeopleSmart Integration With Cold Email Sales Tool",
    "h1": "Integrate PeopleSmart with SalesBlink",
    "metaDescription": "Integrate PeopleSmart and other Contact Management Tools with SalesBlink. Two Way PeopleSmart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Engage Interactive - SMS",
    "slug": "engage-interactive-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d75d373544f426ce991f04ecc0d9dd9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engage Hub brings together a range of consumer engagement channels under a single platform. Timely SMS, MMS, Voice, Contextually relevant emails, Facebook Messenger, WeChat and many others.",
    "category": "Phone & SMS",
    "title": "Engage Interactive - SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Engage Interactive - SMS with SalesBlink",
    "metaDescription": "Integrate Engage Interactive - SMS and other Phone & SMS Tools with SalesBlink. Two Way Engage Interactive - SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RocketRez",
    "slug": "rocketrez",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40685f212270332060c669edee2c2058.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The operating system for tours and attractions.",
    "category": "Sales & CRM",
    "title": "RocketRez Integration With Cold Email Sales Tool",
    "h1": "Integrate RocketRez with SalesBlink",
    "metaDescription": "Integrate RocketRez and other Sales & CRM Tools with SalesBlink. Two Way RocketRez Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pipebooster",
    "slug": "pipebooster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae4e7083e995fac3d210d7fca93c9592.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pipebooster tracks your contacts’ job moves, creates updated details, including new contact information, and alerts your sales team to a new prospect for your SDRs.",
    "category": "Business Intelligence",
    "title": "Pipebooster Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipebooster with SalesBlink",
    "metaDescription": "Integrate Pipebooster and other Business Intelligence Tools with SalesBlink. Two Way Pipebooster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aspose",
    "slug": "asposewords",
    "logo": "https://zapier-images.imgix.net/storage/services/f557a185b8f28c2cdffecd14470ea8bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aspose is a document automation and reporting service (Word, PDF, OpenOffice, HTML, etc). Create, edit, convert, compare, merge, split & perform other documentation tasks with ease.",
    "category": "Documents",
    "title": "Aspose Integration With Cold Email Sales Tool",
    "h1": "Integrate Aspose with SalesBlink",
    "metaDescription": "Integrate Aspose and other Documents Tools with SalesBlink. Two Way Aspose Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Signals",
    "slug": "signals",
    "logo": "https://zapier-images.imgix.net/storage/services/322c7c3f20f3b6b3355e486c7317bf9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Signals helps you engage with your ideal customers at the right time, through the best channel, and with the right message.",
    "category": "Marketing",
    "title": "Signals Integration With Cold Email Sales Tool",
    "h1": "Integrate Signals with SalesBlink",
    "metaDescription": "Integrate Signals and other Marketing Tools with SalesBlink. Two Way Signals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Factomos",
    "slug": "factomos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c876e9373af6c63cbd5d2c8b57a131c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Factomos is an invoicing tool that can help you create invoices and quotes.",
    "category": "Accounting",
    "title": "Factomos Integration With Cold Email Sales Tool",
    "h1": "Integrate Factomos with SalesBlink",
    "metaDescription": "Integrate Factomos and other Accounting Tools with SalesBlink. Two Way Factomos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deel",
    "slug": "deel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd2e1ca98c9eefd64e78c786696fd57c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deel helps thousands of companies expand globally with unmatched speed and flexibility. It’s global hiring, HR, and payroll in just one system.",
    "category": "HR Talent & Recruitment",
    "title": "Deel Integration With Cold Email Sales Tool",
    "h1": "Integrate Deel with SalesBlink",
    "metaDescription": "Integrate Deel and other HR Talent & Recruitment Tools with SalesBlink. Two Way Deel Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Udio",
    "slug": "udio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4da022bf88e1ef560fec05cbd5ed12ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Udio is a booking management software designed to help business owners and managers streamline their day-to-day operations.",
    "category": "Scheduling & Booking",
    "title": "Udio Integration With Cold Email Sales Tool",
    "h1": "Integrate Udio with SalesBlink",
    "metaDescription": "Integrate Udio and other Scheduling & Booking Tools with SalesBlink. Two Way Udio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jusnote",
    "slug": "jusnote",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d20b9b5dff45be81820660c523be79f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jusnote is a practice management platform that helps lawyers work more efficiently by making it easy to do tasks like tracking time, managing matters, and generating invoices and reports.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Jusnote Integration With Cold Email Sales Tool",
    "h1": "Integrate Jusnote with SalesBlink",
    "metaDescription": "Integrate Jusnote and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Jusnote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "todo.vu",
    "slug": "todovu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9235b4b5f0ff13f9b67c44a3f71a1383.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "todo.vu offers task management, time tracking and time billing for small teams and freelancers",
    "category": "Productivity",
    "title": "todo.vu Integration With Cold Email Sales Tool",
    "h1": "Integrate todo.vu with SalesBlink",
    "metaDescription": "Integrate todo.vu and other Productivity Tools with SalesBlink. Two Way todo.vu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MindMe",
    "slug": "mindme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/169b666fe827373a0377e42592075200.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MindMe is a powerful, easy-to-use, cost effective marketing and communication platform that helps you capture leads, convert customers, organize contacts and engage your audience to drive sales and revenue.",
    "category": "Communication",
    "title": "MindMe Integration With Cold Email Sales Tool",
    "h1": "Integrate MindMe with SalesBlink",
    "metaDescription": "Integrate MindMe and other Communication Tools with SalesBlink. Two Way MindMe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oode",
    "slug": "oode",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbceaff40055ce683de5a625240d5391.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Oode is a business management solution for service-providers that helps attract customers, increase recurring revenue, manage operations and so much more.",
    "category": "eCommerce",
    "title": "Oode Integration With Cold Email Sales Tool",
    "h1": "Integrate Oode with SalesBlink",
    "metaDescription": "Integrate Oode and other eCommerce Tools with SalesBlink. Two Way Oode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadCenter.AI",
    "slug": "oryx-cloud",
    "logo": "https://zapier-images.imgix.net/storage/services/fd01f58e6bb783529f8d36358d5d6cba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AI-Powered Lead Management Software that automates tracking, scoring, and engaging leads to improve conversion of leads to business to grow your revenue.",
    "category": "Contact Management",
    "title": "LeadCenter.AI Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadCenter.AI with SalesBlink",
    "metaDescription": "Integrate LeadCenter.AI and other Contact Management Tools with SalesBlink. Two Way LeadCenter.AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pinpointe",
    "slug": "pinpointe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e08a4f66129ccca07b133aac59af07f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pinpointe is a email marketing and contact management platform.",
    "category": "Email Newsletters",
    "title": "Pinpointe Integration With Cold Email Sales Tool",
    "h1": "Integrate Pinpointe with SalesBlink",
    "metaDescription": "Integrate Pinpointe and other Email Newsletters Tools with SalesBlink. Two Way Pinpointe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avify",
    "slug": "avify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00096b9ab1d819922bab291606384ed9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engine for E-commerce automation. Manage inventories and orders from all your sales channels.",
    "category": "eCommerce",
    "title": "Avify Integration With Cold Email Sales Tool",
    "h1": "Integrate Avify with SalesBlink",
    "metaDescription": "Integrate Avify and other eCommerce Tools with SalesBlink. Two Way Avify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "nodaFi",
    "slug": "nodafi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/66cbe2956e7f4ca13e10f7bf63af7f23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "nodaFi is a proptech platform that helps facility leaders create a better tenant experience at a reduced operating cost. Our inexpensive, easy to deploy indoor positioning system helps you track and monitor facility issues and collect tenant feedback in realtime.",
    "category": "Task Management",
    "title": "nodaFi Integration With Cold Email Sales Tool",
    "h1": "Integrate nodaFi with SalesBlink",
    "metaDescription": "Integrate nodaFi and other Task Management Tools with SalesBlink. Two Way nodaFi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Typlog",
    "slug": "typlog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2495aae5cca901ffdbbccf4a84f68c15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Typlog is a blogging and podcasting platform for your stories and voices.",
    "category": "Website Builders",
    "title": "Typlog Integration With Cold Email Sales Tool",
    "h1": "Integrate Typlog with SalesBlink",
    "metaDescription": "Integrate Typlog and other Website Builders Tools with SalesBlink. Two Way Typlog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Logicare",
    "slug": "logicare",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/958988188c78f0038b8d52b9890f8e8d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Logicare is Administrative app with CRM, maintenance, budget and logistics tools, it is part of the ERP world of Puzzlesoft inc.",
    "category": "Marketing",
    "title": "Logicare Integration With Cold Email Sales Tool",
    "h1": "Integrate Logicare with SalesBlink",
    "metaDescription": "Integrate Logicare and other Marketing Tools with SalesBlink. Two Way Logicare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ruby",
    "slug": "ruby",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/45e237daafa3ea1e7b14fcab1c5ae55d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ruby provides small businesses with the services, products, and analytics they need to manage customer interactions and deliver exceptional experiences.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Ruby Integration With Cold Email Sales Tool",
    "h1": "Integrate Ruby with SalesBlink",
    "metaDescription": "Integrate Ruby and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Ruby Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "REVE Chat",
    "slug": "reve-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c4b29c913b7e32172d11c0dbf47f282.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "REVE Chat is an AI enabled omnichannel customer engagement platform that provides real time sales and support assistance.",
    "category": "Customer Support",
    "title": "REVE Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate REVE Chat with SalesBlink",
    "metaDescription": "Integrate REVE Chat and other Customer Support Tools with SalesBlink. Two Way REVE Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vyte",
    "slug": "vytein",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/271c354c1daa384e2e035a8e9a7584ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vyte is a tool that makes scheduling appointments, meetings, and customers bookings easy and user-friendly.",
    "category": "Scheduling & Booking",
    "title": "Vyte Integration With Cold Email Sales Tool",
    "h1": "Integrate Vyte with SalesBlink",
    "metaDescription": "Integrate Vyte and other Scheduling & Booking Tools with SalesBlink. Two Way Vyte Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Rocket Agents",
    "slug": "rocket-agents",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9938ef48410e6e1965a59902dfefadd9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RocketAgents is a CRM that automates brokerages from lead generation to closing. Send leads, notes, calls and other triggers in/out of your Rocket CRM",
    "category": "CRM (Customer Relationship Management)",
    "title": "Rocket Agents Integration With Cold Email Sales Tool",
    "h1": "Integrate Rocket Agents with SalesBlink",
    "metaDescription": "Integrate Rocket Agents and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Rocket Agents Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReviewStudio",
    "slug": "reviewstudio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d341ffa9606ed3204369adeeebbd266d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReviewStudio is online proofing software for marketing teams, ad agencies, creatives and production studios.",
    "category": "Team Collaboration",
    "title": "ReviewStudio Integration With Cold Email Sales Tool",
    "h1": "Integrate ReviewStudio with SalesBlink",
    "metaDescription": "Integrate ReviewStudio and other Team Collaboration Tools with SalesBlink. Two Way ReviewStudio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Arkon Event",
    "slug": "arkon-event",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/945d8d1ef51eed5b565f289808c9f094.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Arkon Event is a Norwegian personnel management system for festivals and events.",
    "category": "Event Management",
    "title": "Arkon Event Integration With Cold Email Sales Tool",
    "h1": "Integrate Arkon Event with SalesBlink",
    "metaDescription": "Integrate Arkon Event and other Event Management Tools with SalesBlink. Two Way Arkon Event Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Good Grants",
    "slug": "good-grants",
    "logo": "https://zapier-images.imgix.net/storage/services/ed41a91b4d37beea09b9de8d5ab14578.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Good Grants is a tool for managing the entire grant life cycle from submission to review to the allocation of funds.",
    "category": "Forms & Surveys",
    "title": "Good Grants Integration With Cold Email Sales Tool",
    "h1": "Integrate Good Grants with SalesBlink",
    "metaDescription": "Integrate Good Grants and other Forms & Surveys Tools with SalesBlink. Two Way Good Grants Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pics.io",
    "slug": "pics-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c76411a5195f58e8eb16e22afa6879e3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pics.io is a cloud service for digital assets management built on top of Google Drive.",
    "category": "Team Collaboration",
    "title": "Pics.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Pics.io with SalesBlink",
    "metaDescription": "Integrate Pics.io and other Team Collaboration Tools with SalesBlink. Two Way Pics.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LumApps",
    "slug": "lumapps",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d368ba36d0be40d142a27389108412c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LumApps is a social intranet platform that concentrates everything you need to work in one place. Providing corporate content, business apps, social communities and enterprise tools, wherever you are.",
    "category": "Team Collaboration",
    "title": "LumApps Integration With Cold Email Sales Tool",
    "h1": "Integrate LumApps with SalesBlink",
    "metaDescription": "Integrate LumApps and other Team Collaboration Tools with SalesBlink. Two Way LumApps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Insurance Website Builder",
    "slug": "insurance-website-builder-ca1153",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f332492f7b04a9d05016e13422274e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ITC’s Insurance Website Builder builds websites for insurance agencies in the United States. Our insurance website experts know insurance website design so you get a strong online presence.",
    "category": "Website Builders",
    "title": "Insurance Website Builder Integration With Cold Email Sales Tool",
    "h1": "Integrate Insurance Website Builder with SalesBlink",
    "metaDescription": "Integrate Insurance Website Builder and other Website Builders Tools with SalesBlink. Two Way Insurance Website Builder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TLY Link Shortener",
    "slug": "tly-link-shortener",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/185eebf3f01241cc6469fab60a585592_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TLY Link Shortener is a platform to quickly generate a short URL to make it easier to share, track and manage your links.",
    "category": "URL Shortener",
    "title": "TLY Link Shortener Integration With Cold Email Sales Tool",
    "h1": "Integrate TLY Link Shortener with SalesBlink",
    "metaDescription": "Integrate TLY Link Shortener and other URL Shortener Tools with SalesBlink. Two Way TLY Link Shortener Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Live Data Technologies",
    "slug": "live-data-technologies",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e84262b88ceef7de1634f96fa99760a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Live Data turns the open web into a real-time truth source and notifies you when you B2B contacts change jobs.",
    "category": "Contact Management",
    "title": "Live Data Technologies Integration With Cold Email Sales Tool",
    "h1": "Integrate Live Data Technologies with SalesBlink",
    "metaDescription": "Integrate Live Data Technologies and other Contact Management Tools with SalesBlink. Two Way Live Data Technologies Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TeleSign",
    "slug": "telesign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e23fffefe908b7c244e0ecd352feb610.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeleSign is a global communications and trusted identity data solutions platform.  We help digital enterprises connect, protect and engage their customers.",
    "category": "Phone & SMS",
    "title": "TeleSign Integration With Cold Email Sales Tool",
    "h1": "Integrate TeleSign with SalesBlink",
    "metaDescription": "Integrate TeleSign and other Phone & SMS Tools with SalesBlink. Two Way TeleSign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Way We Do",
    "slug": "way-we-do",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/574e8e7ce8af11164a352acd91f5d063_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Way We Do is an Active Standard Operating Procedures software, enabling your team to follow repeatable process within their daily workflow.",
    "category": "Project Management",
    "title": "Way We Do Integration With Cold Email Sales Tool",
    "h1": "Integrate Way We Do with SalesBlink",
    "metaDescription": "Integrate Way We Do and other Project Management Tools with SalesBlink. Two Way Way We Do Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TailoredMail",
    "slug": "tailoredmail",
    "logo": "https://zapier-images.imgix.net/storage/developer/49bb83a1c5f387056cdc53c46ef3779f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TailoredMail is a powerful email marketing and automation solution that exclusively offers embedded-video-in-email, doubling engagement.",
    "category": "Email Newsletters",
    "title": "TailoredMail Integration With Cold Email Sales Tool",
    "h1": "Integrate TailoredMail with SalesBlink",
    "metaDescription": "Integrate TailoredMail and other Email Newsletters Tools with SalesBlink. Two Way TailoredMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IDWise",
    "slug": "idwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/004649ff693a5d38fd80e436814cf7fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Global Identity Verification, KYC and AML platform powered by AI and machine learning for customer onboarding.",
    "category": "Security & Identity Tools",
    "title": "IDWise Integration With Cold Email Sales Tool",
    "h1": "Integrate IDWise with SalesBlink",
    "metaDescription": "Integrate IDWise and other Security & Identity Tools Tools with SalesBlink. Two Way IDWise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ujoin.co",
    "slug": "ujoin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/edfbe299b864303092bb522e6894995d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send Twitter, Email, Click to Call, and Video messages to city/county councilors, state/federal legislators, department heads, and other decision-makers for U.S. and Canada.  Track legislative bills and display them on your own website.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Ujoin.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Ujoin.co with SalesBlink",
    "metaDescription": "Integrate Ujoin.co and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Ujoin.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spotilla",
    "slug": "spotilla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/83f11dc4eaba75729e99bd1d84166cf8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spotilla is a maintenance and asset management solution for all businesses.",
    "category": "Task Management",
    "title": "Spotilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Spotilla with SalesBlink",
    "metaDescription": "Integrate Spotilla and other Task Management Tools with SalesBlink. Two Way Spotilla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CSG Forte Payments",
    "slug": "csg-forte-payments",
    "logo": "https://zapier-images.imgix.net/storage/services/c9e9339a364a2c15afdcfe01b5c0ceff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CSG Forte Payments is a single platform to accept card and echeck/ACH payments, online, in-person or via IVR.",
    "category": "Payment Processing",
    "title": "CSG Forte Payments Integration With Cold Email Sales Tool",
    "h1": "Integrate CSG Forte Payments with SalesBlink",
    "metaDescription": "Integrate CSG Forte Payments and other Payment Processing Tools with SalesBlink. Two Way CSG Forte Payments Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sangoma",
    "slug": "sangoma-freepbxpbxact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c9abd87fba11fd726866451202d8a20_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sangoma is a leading voice and Unified Communications (UC) solution provider.",
    "category": "Phone & SMS",
    "title": "Sangoma Integration With Cold Email Sales Tool",
    "h1": "Integrate Sangoma with SalesBlink",
    "metaDescription": "Integrate Sangoma and other Phone & SMS Tools with SalesBlink. Two Way Sangoma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quick Scraper",
    "slug": "quick-scraper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73618391fb33172f8708c8dc7d9f6a61.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Quick Scraper API handles proxy servers, browsers, and CAPTCHA so that you can get the HTML from any website with an easy API call!.",
    "category": "IT Operations",
    "title": "Quick Scraper Integration With Cold Email Sales Tool",
    "h1": "Integrate Quick Scraper with SalesBlink",
    "metaDescription": "Integrate Quick Scraper and other IT Operations Tools with SalesBlink. Two Way Quick Scraper Integration for all your data related to sales & cold email.",
    "zapier": true,
    "pabblyConnect": true
  },
  {
    "name": "Yottled",
    "slug": "yottled",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57d1cbac215f51f8aa570973cfc95187.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yottled makes hosting online and in-person experiences delightful and automated.",
    "category": "Sales & CRM",
    "title": "Yottled Integration With Cold Email Sales Tool",
    "h1": "Integrate Yottled with SalesBlink",
    "metaDescription": "Integrate Yottled and other Sales & CRM Tools with SalesBlink. Two Way Yottled Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Classe365",
    "slug": "classe365",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c7740c8fee89156db28264181b67ba4c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We are Classe365. We build tools that are used today by thousands of educational organizations across the world to manage their entire learner journey – from admissions to alumni and beyond.",
    "category": "Education",
    "title": "Classe365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Classe365 with SalesBlink",
    "metaDescription": "Integrate Classe365 and other Education Tools with SalesBlink. Two Way Classe365 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sinch",
    "slug": "sinch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/028d1d03e48c434285a422342fc8279a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send and receive messages over SMS, WhatsApp, Facebook, Instagram, Telegram, Viber, and more using Sinch — a single app, all the messaging channels you need!",
    "category": "Social Media Accounts",
    "title": "Sinch Integration With Cold Email Sales Tool",
    "h1": "Integrate Sinch with SalesBlink",
    "metaDescription": "Integrate Sinch and other Social Media Accounts Tools with SalesBlink. Two Way Sinch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qualaroo",
    "slug": "qualaroo",
    "logo": "https://zapier-images.imgix.net/storage/developer/15b0e556686ecd706964defddcbff352.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qualaroo is a SaaS company that enables companies to gain valuable insights from their prospects and customers in real time.",
    "category": "Forms & Surveys",
    "title": "Qualaroo Integration With Cold Email Sales Tool",
    "h1": "Integrate Qualaroo with SalesBlink",
    "metaDescription": "Integrate Qualaroo and other Forms & Surveys Tools with SalesBlink. Two Way Qualaroo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AX Semantics",
    "slug": "ax-semantics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a701f5ed8025bf6199831f9723d54820.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AX Semantics is a SaaS provider for Natural Language Generation.",
    "category": "Content & Files",
    "title": "AX Semantics Integration With Cold Email Sales Tool",
    "h1": "Integrate AX Semantics with SalesBlink",
    "metaDescription": "Integrate AX Semantics and other Content & Files Tools with SalesBlink. Two Way AX Semantics Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "OneLocal LocalMessages",
    "slug": "onelocal-localmessages",
    "logo": "https://zapier-images.imgix.net/storage/services/313fff74e3465bab45897f37ba7d3106.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LocalMessages by OneLocal allows you to integrate text messaging into your customer communications to increase response rates, reduce on-hold times and deliver a better overall customer experience. LocalMessages seamlessly connects with OneLocal's full product suite.",
    "category": "Phone & SMS",
    "title": "OneLocal LocalMessages Integration With Cold Email Sales Tool",
    "h1": "Integrate OneLocal LocalMessages with SalesBlink",
    "metaDescription": "Integrate OneLocal LocalMessages and other Phone & SMS Tools with SalesBlink. Two Way OneLocal LocalMessages Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yonder",
    "slug": "yonder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b8e704231eaedc9727ffa15b2491c32c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yonder helps you gather customer feedback, take care of customers 24-7 and turn that into raving reviews and more sales.",
    "category": "Marketing",
    "title": "Yonder Integration With Cold Email Sales Tool",
    "h1": "Integrate Yonder with SalesBlink",
    "metaDescription": "Integrate Yonder and other Marketing Tools with SalesBlink. Two Way Yonder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Black Magic",
    "slug": "black-magic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf5a4757b501fb8848a3894a9915077a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Black Magic helps tweeters measure and analyze tweet data to understand their followers, thus optimizing their future tweets.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Black Magic Integration With Cold Email Sales Tool",
    "h1": "Integrate Black Magic with SalesBlink",
    "metaDescription": "Integrate Black Magic and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Black Magic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bankflip",
    "slug": "bankflip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08bda683d6065ad6bc99b8bd7b35c82a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bankflip is a tool to extract data from public administrations.",
    "category": "Documents",
    "title": "Bankflip Integration With Cold Email Sales Tool",
    "h1": "Integrate Bankflip with SalesBlink",
    "metaDescription": "Integrate Bankflip and other Documents Tools with SalesBlink. Two Way Bankflip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Roster",
    "slug": "roster",
    "logo": "https://zapier-images.imgix.net/storage/services/ed4f0871e11e56f2ec915d054443ca67.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The brand engagement platform. Drive sales & awareness through word of mouth marketing.",
    "category": "Marketing",
    "title": "Roster Integration With Cold Email Sales Tool",
    "h1": "Integrate Roster with SalesBlink",
    "metaDescription": "Integrate Roster and other Marketing Tools with SalesBlink. Two Way Roster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Muvi",
    "slug": "muvi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b28849b41ef3d82f7c3cf1a6c003d75.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Muvi is a SaaS-based OTT Platform Provider that allows Content owners to launch Audio/Video Streaming Platforms (On Demand/Live) for Web/Mobile/TV.",
    "category": "Video & Audio",
    "title": "Muvi Integration With Cold Email Sales Tool",
    "h1": "Integrate Muvi with SalesBlink",
    "metaDescription": "Integrate Muvi and other Video & Audio Tools with SalesBlink. Two Way Muvi Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Streamtools",
    "slug": "streamtools",
    "logo": "https://zapier-images.imgix.net/storage/services/270b6aca25ca9786712cc57efd70ddc2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Streamtools is a suite of streaming tools for your live content.",
    "category": "Content & Files",
    "title": "Streamtools Integration With Cold Email Sales Tool",
    "h1": "Integrate Streamtools with SalesBlink",
    "metaDescription": "Integrate Streamtools and other Content & Files Tools with SalesBlink. Two Way Streamtools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Siigo",
    "slug": "siigo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e4ddb3e9cde837babe3d5a0209485cb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Siigo Nube is an Accounting and Administrative software for SMBs companies.",
    "category": "Accounting",
    "title": "Siigo Integration With Cold Email Sales Tool",
    "h1": "Integrate Siigo with SalesBlink",
    "metaDescription": "Integrate Siigo and other Accounting Tools with SalesBlink. Two Way Siigo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Waybook",
    "slug": "waybook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c087c37ef89f25ee3c5f77fc2377513f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build a playbook for your business. All your training, onboarding, process documentation and best practices in one place.",
    "category": "Online Courses",
    "title": "Waybook Integration With Cold Email Sales Tool",
    "h1": "Integrate Waybook with SalesBlink",
    "metaDescription": "Integrate Waybook and other Online Courses Tools with SalesBlink. Two Way Waybook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VOM",
    "slug": "vom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8bf5dd96bd5f98661a83519b11bb0612.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VôM is a homegrown accounting software platform. VôM aims to provide “Value of Money” to small businesses that are focused on easily managing their financials. Provides anyone that has no background in accounting with all accounting reports easily",
    "category": "Accounting",
    "title": "VOM Integration With Cold Email Sales Tool",
    "h1": "Integrate VOM with SalesBlink",
    "metaDescription": "Integrate VOM and other Accounting Tools with SalesBlink. Two Way VOM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoLinks",
    "slug": "golinks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65687c0384a0d6a56765b53f65abb88d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoLinks is the easiest way to access and share resources at  your company with intuitive links called go/links",
    "category": "Productivity",
    "title": "GoLinks Integration With Cold Email Sales Tool",
    "h1": "Integrate GoLinks with SalesBlink",
    "metaDescription": "Integrate GoLinks and other Productivity Tools with SalesBlink. Two Way GoLinks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Porsline",
    "slug": "porsline",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d4e6391d9579d3c0575a98dae34443d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Porsline is an Online Survey Software dedicated to help companies of all sizes doing effective market research, customer experience, and employee surveys. We help companies cut their costs and get more by real-time decision making.",
    "category": "Forms & Surveys",
    "title": "Porsline Integration With Cold Email Sales Tool",
    "h1": "Integrate Porsline with SalesBlink",
    "metaDescription": "Integrate Porsline and other Forms & Surveys Tools with SalesBlink. Two Way Porsline Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Airgram",
    "slug": "airgram",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7686f69002454e96157e626ae162bd6e_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "You can use Airgram to write better agendas, notes, as well as record and transcribe Zoom, Google Meet and Teams calls.",
    "category": "Productivity",
    "title": "Airgram Integration With Cold Email Sales Tool",
    "h1": "Integrate Airgram with SalesBlink",
    "metaDescription": "Integrate Airgram and other Productivity Tools with SalesBlink. Two Way Airgram Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blanket",
    "slug": "blanket",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/333b77f2c8e74ff0a39182ec38d4e820.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get more qualified leads. Reach out to your audience, generate interest, and analyze results with one tool.",
    "category": "Contact Management",
    "title": "Blanket Integration With Cold Email Sales Tool",
    "h1": "Integrate Blanket with SalesBlink",
    "metaDescription": "Integrate Blanket and other Contact Management Tools with SalesBlink. Two Way Blanket Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mav",
    "slug": "mav",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5ddfaa5ddd243417c21bb0fa47d78a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mav automates the back-and-forth with your leads and customers via text message. Whether it’s scheduling meetings, qualifying prospects, or following up with leads, Mav is on it.",
    "category": "Sales & CRM",
    "title": "Mav Integration With Cold Email Sales Tool",
    "h1": "Integrate Mav with SalesBlink",
    "metaDescription": "Integrate Mav and other Sales & CRM Tools with SalesBlink. Two Way Mav Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Calendar",
    "slug": "calendar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20ea786aefae266939b62a8eee2ab0d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Calendar is an all-in-one calendar application with personalized scheduling, team scheduling, availability time slots, and analytics.  We make scheduling simple and eliminate the back-and-forth emails.",
    "category": "Scheduling & Booking",
    "title": "Calendar Integration With Cold Email Sales Tool",
    "h1": "Integrate Calendar with SalesBlink",
    "metaDescription": "Integrate Calendar and other Scheduling & Booking Tools with SalesBlink. Two Way Calendar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Umbraco",
    "slug": "umbraco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74f076a41b09099d88e05201ebecb7b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Umbraco is a content management system to help create and update your website. With Umbraco, you get a content management system known and loved for its flexibility and great editing experience.",
    "category": "IT Operations",
    "title": "Umbraco Integration With Cold Email Sales Tool",
    "h1": "Integrate Umbraco with SalesBlink",
    "metaDescription": "Integrate Umbraco and other IT Operations Tools with SalesBlink. Two Way Umbraco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RedPodium",
    "slug": "redpodium",
    "logo": "https://zapier-images.imgix.net/storage/services/a5936bb307d9a2c645d32728b6cce1a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RedPodium is a premier event management platform for making a registration page, tracking your registrants, and promoting athletic centric events.",
    "category": "Event Management",
    "title": "RedPodium Integration With Cold Email Sales Tool",
    "h1": "Integrate RedPodium with SalesBlink",
    "metaDescription": "Integrate RedPodium and other Event Management Tools with SalesBlink. Two Way RedPodium Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kagent",
    "slug": "kagent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c81fa38a658a280ceb64a25e446c756a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart Sign-in Sheets for Open Houses - Create follow-up surveys and maximize booked meetings.",
    "category": "Sales & CRM",
    "title": "Kagent Integration With Cold Email Sales Tool",
    "h1": "Integrate Kagent with SalesBlink",
    "metaDescription": "Integrate Kagent and other Sales & CRM Tools with SalesBlink. Two Way Kagent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aimtell",
    "slug": "aimtell",
    "logo": "https://zapier-images.imgix.net/storage/developer/2cbc4bcb5981699a6c734725c252543a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aimtell is a platform that enables you to re-engage your website visitors with highly targeted browser push notifications.",
    "category": "Marketing Automation",
    "title": "Aimtell Integration With Cold Email Sales Tool",
    "h1": "Integrate Aimtell with SalesBlink",
    "metaDescription": "Integrate Aimtell and other Marketing Automation Tools with SalesBlink. Two Way Aimtell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Listnr",
    "slug": "listnr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7cb7df6958ae7e780617c9a3e504961e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate high-quality Text to Speech audio within seconds.",
    "category": "Internet of Things",
    "title": "Listnr Integration With Cold Email Sales Tool",
    "h1": "Integrate Listnr with SalesBlink",
    "metaDescription": "Integrate Listnr and other Internet of Things Tools with SalesBlink. Two Way Listnr Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Order Time",
    "slug": "order-time",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b4003f61776eb48fc4996f5bc88b83b3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Order Time is a powerful inventory control and order management platform integrated with your accounting.",
    "category": "Accounting",
    "title": "Order Time Integration With Cold Email Sales Tool",
    "h1": "Integrate Order Time with SalesBlink",
    "metaDescription": "Integrate Order Time and other Accounting Tools with SalesBlink. Two Way Order Time Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Confection",
    "slug": "confection",
    "logo": "https://zapier-images.imgix.net/storage/services/c914db2f36c62f8a6fed93d7df5ea0d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Confection collects, stores, and distributes data in a way that's unaffected by client-side disruptions involving cookies, cross-domain scripts, and device IDs. It's also compliant with global privacy laws so it’s good for people too.",
    "category": "Business Intelligence",
    "title": "Confection Integration With Cold Email Sales Tool",
    "h1": "Integrate Confection with SalesBlink",
    "metaDescription": "Integrate Confection and other Business Intelligence Tools with SalesBlink. Two Way Confection Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Newt",
    "slug": "newt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/059a8b2b3a05b5de671f446c901972c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Newt is a content management platform that provides flexible and extensible APIs and user-friendly admin pages.",
    "category": "Website & App Building",
    "title": "Newt Integration With Cold Email Sales Tool",
    "h1": "Integrate Newt with SalesBlink",
    "metaDescription": "Integrate Newt and other Website & App Building Tools with SalesBlink. Two Way Newt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stella Connect",
    "slug": "stella-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62a2b312b43f56429a27e1cf9918c8a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stella Connect is a software platform built to empower customer service agents, make them happier in their jobs, and help with their career development.",
    "category": "Forms & Surveys",
    "title": "Stella Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Stella Connect with SalesBlink",
    "metaDescription": "Integrate Stella Connect and other Forms & Surveys Tools with SalesBlink. Two Way Stella Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lytho",
    "slug": "lytho",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e60dcf7db9ea627a83b82e3b37c7bf5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lytho's creative operations platform equips creative teams with better, simpler processes so that they can do the work that matters.",
    "category": "Project Management",
    "title": "Lytho Integration With Cold Email Sales Tool",
    "h1": "Integrate Lytho with SalesBlink",
    "metaDescription": "Integrate Lytho and other Project Management Tools with SalesBlink. Two Way Lytho Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sponsy",
    "slug": "sponsy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f7287619fb8a10909d8956eacb226b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sponsy is the modern all-in-one sponsorship manager for newsletters, podcasts, and any other type of publication.",
    "category": "Scheduling & Booking",
    "title": "Sponsy Integration With Cold Email Sales Tool",
    "h1": "Integrate Sponsy with SalesBlink",
    "metaDescription": "Integrate Sponsy and other Scheduling & Booking Tools with SalesBlink. Two Way Sponsy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SpamSpy",
    "slug": "spamspy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcbdddbef9e50a8fe2118acc273e6c10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SpamSpy is an API service that uses artificial intelligence to determine whether text is spam or legitimate content.",
    "category": "Business Intelligence",
    "title": "SpamSpy Integration With Cold Email Sales Tool",
    "h1": "Integrate SpamSpy with SalesBlink",
    "metaDescription": "Integrate SpamSpy and other Business Intelligence Tools with SalesBlink. Two Way SpamSpy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CupixWorks",
    "slug": "cupixworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9db9c82071162cc166b5013117f59b78_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CupixWorks is a web platform to unify all the photos, BIM, drone, & reality capture data from your jobsite.",
    "category": "Business Intelligence",
    "title": "CupixWorks Integration With Cold Email Sales Tool",
    "h1": "Integrate CupixWorks with SalesBlink",
    "metaDescription": "Integrate CupixWorks and other Business Intelligence Tools with SalesBlink. Two Way CupixWorks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notud",
    "slug": "notud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f95c585e9760fc844b757943cd4ffaf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notud is cloud note-taking for small business. Handwrite on your tablet or iPad and your notes save instantly to a client file.",
    "category": "Notes",
    "title": "Notud Integration With Cold Email Sales Tool",
    "h1": "Integrate Notud with SalesBlink",
    "metaDescription": "Integrate Notud and other Notes Tools with SalesBlink. Two Way Notud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SKOOT",
    "slug": "skoot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b7858c746ea88d8b70b2c5ef320a47f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The SKOOT Climate Platform helps businesses achieve their goal of being net-zero, our API makes it easy to Plant Trees and Buying Carbon Offset.",
    "category": "Developer Tools",
    "title": "SKOOT Integration With Cold Email Sales Tool",
    "h1": "Integrate SKOOT with SalesBlink",
    "metaDescription": "Integrate SKOOT and other Developer Tools Tools with SalesBlink. Two Way SKOOT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Iptelecom",
    "slug": "iptelecom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/72932e5a29eaf69166eac4f4816ae115.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Iptelecom is a VOIP service provider. Allows you to receive detailed information about calls made, call center events, receive recording of your calls and other features.",
    "category": "Call Tracking",
    "title": "Iptelecom Integration With Cold Email Sales Tool",
    "h1": "Integrate Iptelecom with SalesBlink",
    "metaDescription": "Integrate Iptelecom and other Call Tracking Tools with SalesBlink. Two Way Iptelecom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mindstone",
    "slug": "mindstone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb50319c2a73c512d76562ac86005196.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mindstone is a productivity tool that helps to organise, share and take notes on web pages, PDFs, videos and podcasts — so you can learn faster, remember more and get things done.",
    "category": "Productivity",
    "title": "Mindstone Integration With Cold Email Sales Tool",
    "h1": "Integrate Mindstone with SalesBlink",
    "metaDescription": "Integrate Mindstone and other Productivity Tools with SalesBlink. Two Way Mindstone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Data8",
    "slug": "data8-data-enrichment",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b20c2745c69a4ce0b93d773d30e3c10d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Data8 can save you money by cleaning and enriching your data, as well as keeping it high quality at the point of entry.",
    "category": "Developer Tools",
    "title": "Data8 Integration With Cold Email Sales Tool",
    "h1": "Integrate Data8 with SalesBlink",
    "metaDescription": "Integrate Data8 and other Developer Tools Tools with SalesBlink. Two Way Data8 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moon Invoice",
    "slug": "moon-invoice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f4ee73d54ad5310ad0cb976d78da0882.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moon Invoice is invoicing app providing invoice functionality to users.",
    "category": "Proposal & Invoice Management",
    "title": "Moon Invoice Integration With Cold Email Sales Tool",
    "h1": "Integrate Moon Invoice with SalesBlink",
    "metaDescription": "Integrate Moon Invoice and other Proposal & Invoice Management Tools with SalesBlink. Two Way Moon Invoice Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DecisionVault",
    "slug": "decisionvault",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f36e11b3116a89d208411bfc757a6bf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The DecisionVault portal makes intake effortless for law firms",
    "category": "Forms & Surveys",
    "title": "DecisionVault Integration With Cold Email Sales Tool",
    "h1": "Integrate DecisionVault with SalesBlink",
    "metaDescription": "Integrate DecisionVault and other Forms & Surveys Tools with SalesBlink. Two Way DecisionVault Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evolvepreneur.app",
    "slug": "evolvepreneur",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5660cc9e7b66917f2786278d7dff408d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Discover The New Revolution in Community-Based eLearning and Marketing Solutions for Coaches, Consultants, Authors, Podcasters, Publishers and Mastermind Groups",
    "category": "Marketing",
    "title": "Evolvepreneur.app Integration With Cold Email Sales Tool",
    "h1": "Integrate Evolvepreneur.app with SalesBlink",
    "metaDescription": "Integrate Evolvepreneur.app and other Marketing Tools with SalesBlink. Two Way Evolvepreneur.app Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SaasAnt Transactions",
    "slug": "saasant-transactions",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5435c77ded1a8f27f0e4ef9d1d5c1c95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SaasAnt Transactions helps you to automate the imports of invoices, bills, sales, estimates, journals and payments to your accounting platforms.",
    "category": "Accounting",
    "title": "SaasAnt Transactions Integration With Cold Email Sales Tool",
    "h1": "Integrate SaasAnt Transactions with SalesBlink",
    "metaDescription": "Integrate SaasAnt Transactions and other Accounting Tools with SalesBlink. Two Way SaasAnt Transactions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ducalis.io",
    "slug": "ducalisio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/902f918dd01ae15694a19bd533c04ddc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ducalis is the prioritization tool to help your team make the most impactful things",
    "category": "Task Management",
    "title": "Ducalis.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Ducalis.io with SalesBlink",
    "metaDescription": "Integrate Ducalis.io and other Task Management Tools with SalesBlink. Two Way Ducalis.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Citima",
    "slug": "citima",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76eaffb9fcf56fe2200bb83392a9bfb4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Citima is a new, indispensable customer relation manager (crm) for real estate advisors.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Citima Integration With Cold Email Sales Tool",
    "h1": "Integrate Citima with SalesBlink",
    "metaDescription": "Integrate Citima and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Citima Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GeniusReferrals",
    "slug": "geniusreferrals",
    "logo": "https://zapier-images.imgix.net/storage/developer/b59408abf1c08f03eb8cb20baf10001d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Referral Marketing Platform that helps businesses to acquire new customers using word of mouth and recommendation techniques.",
    "category": "Marketing Automation",
    "title": "GeniusReferrals Integration With Cold Email Sales Tool",
    "h1": "Integrate GeniusReferrals with SalesBlink",
    "metaDescription": "Integrate GeniusReferrals and other Marketing Automation Tools with SalesBlink. Two Way GeniusReferrals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TalkBox",
    "slug": "talkbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3bf692d8fb213d8203a429de7e00f9f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TalkBox can drive database acquisition, increase growth and value of your database, and identify and retain your best customers.",
    "category": "Marketing",
    "title": "TalkBox Integration With Cold Email Sales Tool",
    "h1": "Integrate TalkBox with SalesBlink",
    "metaDescription": "Integrate TalkBox and other Marketing Tools with SalesBlink. Two Way TalkBox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneLocal LocalReferrals",
    "slug": "onelocal-localreferrals",
    "logo": "https://zapier-images.imgix.net/storage/services/313fff74e3465bab45897f37ba7d3106_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LocalReferrals by OneLocal allows you to create a fully customized referral program so you can generate more business from your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OneLocal LocalReferrals Integration With Cold Email Sales Tool",
    "h1": "Integrate OneLocal LocalReferrals with SalesBlink",
    "metaDescription": "Integrate OneLocal LocalReferrals and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OneLocal LocalReferrals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iFax",
    "slug": "ifax",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82fb6d5610ed4c390212a93c81ac3f91_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iFax is a go-to solution for effortless, lightning-fast online fax app that’s reliable and secure. You can send and receive a fax as easily you would send an email.",
    "category": "Fax",
    "title": "iFax Integration With Cold Email Sales Tool",
    "h1": "Integrate iFax with SalesBlink",
    "metaDescription": "Integrate iFax and other Fax Tools with SalesBlink. Two Way iFax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mela Works",
    "slug": "mela-works",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aac320fa4e3194ec44e641ca1702c517_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mela Works tracks your construction site and maintenance works.",
    "category": "IT Operations",
    "title": "Mela Works Integration With Cold Email Sales Tool",
    "h1": "Integrate Mela Works with SalesBlink",
    "metaDescription": "Integrate Mela Works and other IT Operations Tools with SalesBlink. Two Way Mela Works Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saalz",
    "slug": "saalz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/456b036bf4208f2eb25c37d55ee1a74b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Saalz is a simple and efficient CRM for managing your leads, deals and tasks, and enrich all datas.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Saalz Integration With Cold Email Sales Tool",
    "h1": "Integrate Saalz with SalesBlink",
    "metaDescription": "Integrate Saalz and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Saalz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stormboard",
    "slug": "stormboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/09d0a95617885e14fdf6576db36dd39a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An online stickynote whiteboard making meetings, brainstorms, and creative projects more productive and effective.",
    "category": "Project Management",
    "title": "Stormboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Stormboard with SalesBlink",
    "metaDescription": "Integrate Stormboard and other Project Management Tools with SalesBlink. Two Way Stormboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Darwin CX",
    "slug": "darwin-cx-ca115721",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07dbdbb405fb2054959d56496acf04e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DarwinCX is an end-to-end SaaS customer data platform for magazine publishers and membership organizations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Darwin CX Integration With Cold Email Sales Tool",
    "h1": "Integrate Darwin CX with SalesBlink",
    "metaDescription": "Integrate Darwin CX and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Darwin CX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upzelo",
    "slug": "upzelo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e054bce61a67998295388d7892c72bb0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upzelo is subscription retention software to help you spend more time retaining customers with automated retention offers.",
    "category": "eCommerce",
    "title": "Upzelo Integration With Cold Email Sales Tool",
    "h1": "Integrate Upzelo with SalesBlink",
    "metaDescription": "Integrate Upzelo and other eCommerce Tools with SalesBlink. Two Way Upzelo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dazos CRM",
    "slug": "dazos-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea3670ab13f524ec61cbfd665b594d37.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "For organizations who value every single lead, our advanced CRM empowers true oversight of your entire admissions and marketing departments.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Dazos CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Dazos CRM with SalesBlink",
    "metaDescription": "Integrate Dazos CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Dazos CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sacscloud",
    "slug": "sacscloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/965bf8d772de3017ea137be33ce94373.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sacscloud is a platform to manage proccess for your company.",
    "category": "App Builder",
    "title": "Sacscloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Sacscloud with SalesBlink",
    "metaDescription": "Integrate Sacscloud and other App Builder Tools with SalesBlink. Two Way Sacscloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reloadify",
    "slug": "reloadify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a765e9c0396e68bfc8342639f482368.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send personal e-mails and transform one-time-buyers into lifelong customers.\n\nCommunicate on a large scale, but talk with customers at the personal level. Raise your revenue by using marketing automation. Are you ready to double your conversion rates?",
    "category": "CRM (Customer Relationship Management)",
    "title": "Reloadify Integration With Cold Email Sales Tool",
    "h1": "Integrate Reloadify with SalesBlink",
    "metaDescription": "Integrate Reloadify and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Reloadify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadExec",
    "slug": "leadexec-da56276",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/739407422ac7274f3e1904af95736b1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadExec provides centralized lead distribution.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LeadExec Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadExec with SalesBlink",
    "metaDescription": "Integrate LeadExec and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LeadExec Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magpi",
    "slug": "magpi",
    "logo": "https://zapier-images.imgix.net/storage/developer/131988cb540dcb2ffec3949127d2f070.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magpi is a full-featured and easy to use mobile data collection and messaging system, allowing online or offline data collection from any mobile device. Collect data using iOS, Android, or Symbian apps, or using SMS from any device.",
    "category": "Forms & Surveys",
    "title": "Magpi Integration With Cold Email Sales Tool",
    "h1": "Integrate Magpi with SalesBlink",
    "metaDescription": "Integrate Magpi and other Forms & Surveys Tools with SalesBlink. Two Way Magpi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Autohost",
    "slug": "autohost-webhooks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13eb4cd2f41773bd7b7b0c28e04e63d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Autohost is an automated identity verification and fraud prevention platform integrated with multiple property management systems.",
    "category": "Security & Identity Tools",
    "title": "Autohost Integration With Cold Email Sales Tool",
    "h1": "Integrate Autohost with SalesBlink",
    "metaDescription": "Integrate Autohost and other Security & Identity Tools Tools with SalesBlink. Two Way Autohost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Billit.io",
    "slug": "billitio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e087436148dd8aa0675b4a96bbb0b396.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Access your billit.io account, be notified about your invoices, customers or payments.",
    "category": "Accounting",
    "title": "Billit.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Billit.io with SalesBlink",
    "metaDescription": "Integrate Billit.io and other Accounting Tools with SalesBlink. Two Way Billit.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OpenAsset",
    "slug": "openasset",
    "logo": "https://zapier-images.imgix.net/storage/services/2e03335426f752c0b4e1d166a5119f06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OpenAsset is a cloud Digital Asset Management (DAM) solution tailored to Architecture, Engineering, Construction (AEC) and Real Estate.",
    "category": "File Management & Storage",
    "title": "OpenAsset Integration With Cold Email Sales Tool",
    "h1": "Integrate OpenAsset with SalesBlink",
    "metaDescription": "Integrate OpenAsset and other File Management & Storage Tools with SalesBlink. Two Way OpenAsset Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quantive Results",
    "slug": "quantive",
    "logo": "https://zapier-images.imgix.net/storage/services/08b923d24a8d90ebe04ea081b32f1e1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quantive helps organizations achieve the best possible by turning their strategic priorities into reality.",
    "category": "Task Management",
    "title": "Quantive Results Integration With Cold Email Sales Tool",
    "h1": "Integrate Quantive Results with SalesBlink",
    "metaDescription": "Integrate Quantive Results and other Task Management Tools with SalesBlink. Two Way Quantive Results Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "360NRS SMS",
    "slug": "360nrs-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f5243ea2506a7ab3acfe9bf04e5927df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy way to send SMS. 360NRS allows you to send fully personalized SMS automatically.",
    "category": "Phone & SMS",
    "title": "360NRS SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate 360NRS SMS with SalesBlink",
    "metaDescription": "Integrate 360NRS SMS and other Phone & SMS Tools with SalesBlink. Two Way 360NRS SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobimag",
    "slug": "mobimag",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ffe77c892308e45ab4d15a58d410f49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobimag is a cool new way to publish and monetize magazines.",
    "category": "Images & Design",
    "title": "Mobimag Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobimag with SalesBlink",
    "metaDescription": "Integrate Mobimag and other Images & Design Tools with SalesBlink. Two Way Mobimag Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tugboat Logic",
    "slug": "tugboat-logic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7464fdf75b6581c234a86e7a15b7879.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Tugboat Logic Security Assurance - Custom Integrations App is a service that is set up for third-party integrations to automate the collection of any piece of evidence in your Infosec Program.",
    "category": "Security & Identity Tools",
    "title": "Tugboat Logic Integration With Cold Email Sales Tool",
    "h1": "Integrate Tugboat Logic with SalesBlink",
    "metaDescription": "Integrate Tugboat Logic and other Security & Identity Tools Tools with SalesBlink. Two Way Tugboat Logic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ratecard",
    "slug": "ratecard",
    "logo": "https://zapier-images.imgix.net/storage/developer/9208e9d85da4ecddb0508818e3d1e3be_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ratecard is the online feedback and reputation management tool that helps you to collect feedback and reviews on autopilot.",
    "category": "Forms & Surveys",
    "title": "Ratecard Integration With Cold Email Sales Tool",
    "h1": "Integrate Ratecard with SalesBlink",
    "metaDescription": "Integrate Ratecard and other Forms & Surveys Tools with SalesBlink. Two Way Ratecard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notyfile",
    "slug": "notyfile",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9d84209bf6c11b6ac961f58179cce2bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notyfile is a sales driven CRM platform that helps your organisation work better and faster together.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Notyfile Integration With Cold Email Sales Tool",
    "h1": "Integrate Notyfile with SalesBlink",
    "metaDescription": "Integrate Notyfile and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Notyfile Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Array",
    "slug": "array",
    "logo": "https://zapier-images.imgix.net/storage/developer/6388c3fb9921bcb3042705459aabf38a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Array is a no-code tool to build better business apps, faster, and helps you improve productivity, workflows and data collection across all your business operations.",
    "category": "Forms & Surveys",
    "title": "Array Integration With Cold Email Sales Tool",
    "h1": "Integrate Array with SalesBlink",
    "metaDescription": "Integrate Array and other Forms & Surveys Tools with SalesBlink. Two Way Array Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CommerceHQ",
    "slug": "commercehq",
    "logo": "https://zapier-images.imgix.net/storage/developer/6c80343fa3e1dbb236da26820d3bdcf2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CommerceHQ is an e-commerce tool that allows you to sell easily.",
    "category": "eCommerce",
    "title": "CommerceHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate CommerceHQ with SalesBlink",
    "metaDescription": "Integrate CommerceHQ and other eCommerce Tools with SalesBlink. Two Way CommerceHQ Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Eventscase",
    "slug": "eventscase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/31912d1b27d9fbae4f8c719e41b2600f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventscase provides the technology to efficiently manage, enrich and boost your physical, virtual & hybrid events.",
    "category": "Event Management",
    "title": "Eventscase Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventscase with SalesBlink",
    "metaDescription": "Integrate Eventscase and other Event Management Tools with SalesBlink. Two Way Eventscase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JustCast",
    "slug": "justcast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7092748b601de0bf7dbf4c714ed37d09_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JustCast is a podcast hosting platform that offers a private podcast option. Great for courses, ebooks, and journey podcasts.",
    "category": "CRM (Customer Relationship Management)",
    "title": "JustCast Integration With Cold Email Sales Tool",
    "h1": "Integrate JustCast with SalesBlink",
    "metaDescription": "Integrate JustCast and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way JustCast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Partnero",
    "slug": "partnero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ebfb290a0d606a39d21b7d0e949331b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Partnero is a partnership management software with a focus on customizable program settings & partner experience.",
    "category": "Marketing Automation",
    "title": "Partnero Integration With Cold Email Sales Tool",
    "h1": "Integrate Partnero with SalesBlink",
    "metaDescription": "Integrate Partnero and other Marketing Automation Tools with SalesBlink. Two Way Partnero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SMSBOX",
    "slug": "smsbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f9a175afd2fa454059642c03143b687_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSBOX is a web service allowing you to communicate by sending SMS, voice messages to your recipients.",
    "category": "Communication",
    "title": "SMSBOX Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSBOX with SalesBlink",
    "metaDescription": "Integrate SMSBOX and other Communication Tools with SalesBlink. Two Way SMSBOX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Revv Documents",
    "slug": "revv-documents",
    "logo": "https://zapier-images.imgix.net/storage/services/5b03dc216174b03d48bf6e79eeb3e5e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revv Documents is intended for individuals and teams looking to automate everyday paperwork accurately and smartly, all-in-one tool. Use Revv Documents to manage proposals, quotes, contracts and agreements.",
    "category": "Documents",
    "title": "Revv Documents Integration With Cold Email Sales Tool",
    "h1": "Integrate Revv Documents with SalesBlink",
    "metaDescription": "Integrate Revv Documents and other Documents Tools with SalesBlink. Two Way Revv Documents Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "FireHawkCRM",
    "slug": "firehawkcrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f70535495baa4bad5a2ffa0cb43ccfa1.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FireHawkCRM is great for small business. We help you look after your customers, so they’ll tell the world how good you are! Quotes, Invoices, Email and File sharing all in one neat little package.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FireHawkCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate FireHawkCRM with SalesBlink",
    "metaDescription": "Integrate FireHawkCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FireHawkCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Knowork",
    "slug": "knowork",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee13d98ba0603a5e10ca553d2d5eca70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Knowork is a coworking space management and community engagement platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Knowork Integration With Cold Email Sales Tool",
    "h1": "Integrate Knowork with SalesBlink",
    "metaDescription": "Integrate Knowork and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Knowork Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sesamy",
    "slug": "sesamy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4434d53b47938de0f5c305a3763196a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sesamy is a platform to monetize content on the web",
    "category": "Commerce",
    "title": "Sesamy Integration With Cold Email Sales Tool",
    "h1": "Integrate Sesamy with SalesBlink",
    "metaDescription": "Integrate Sesamy and other Commerce Tools with SalesBlink. Two Way Sesamy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "webinar.net",
    "slug": "webinarnet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ba53804b6f9bbda586e4c921f137bfde_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "webinar.net is the first online presentation platform fully hosted in the cloud. This architecture allows our customers to reach very large audiences without capacity concerns.",
    "category": "Webinars",
    "title": "webinar.net Integration With Cold Email Sales Tool",
    "h1": "Integrate webinar.net with SalesBlink",
    "metaDescription": "Integrate webinar.net and other Webinars Tools with SalesBlink. Two Way webinar.net Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hexomatic",
    "slug": "hexomatic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a8fcc853635f749d3882e665778db2a2_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hexomatic is a no-code, work automation platform that enables you to harness the internet as your own data source, leverage the most sophisticated AI services and a crowdsourced team of human assistants to automate and delegate time consuming tasks.",
    "category": "Analytics",
    "title": "Hexomatic Integration With Cold Email Sales Tool",
    "h1": "Integrate Hexomatic with SalesBlink",
    "metaDescription": "Integrate Hexomatic and other Analytics Tools with SalesBlink. Two Way Hexomatic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ContentPepper Marketing Platform",
    "slug": "contentpepper-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16b57c405e463c42623b1fd8bc723162_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContentPepper Marketing Platform is a Digital-Marketing-Platform.",
    "category": "Marketing",
    "title": "ContentPepper Marketing Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate ContentPepper Marketing Platform with SalesBlink",
    "metaDescription": "Integrate ContentPepper Marketing Platform and other Marketing Tools with SalesBlink. Two Way ContentPepper Marketing Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xledger",
    "slug": "xledger",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/897900ea6de1c6159a142744177d566d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xledger is cloud based accounting platform. It leverages automation to save users time and use complex worflows.",
    "category": "Accounting",
    "title": "Xledger Integration With Cold Email Sales Tool",
    "h1": "Integrate Xledger with SalesBlink",
    "metaDescription": "Integrate Xledger and other Accounting Tools with SalesBlink. Two Way Xledger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clickedin",
    "slug": "clickedin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d58a4930126f3d0d48f5b0c667f4e951.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clickedin is a lead generation solution that uses artificial intelligence to safely and smartly generate leads for your software or SaaS company.",
    "category": "Marketing Automation",
    "title": "Clickedin Integration With Cold Email Sales Tool",
    "h1": "Integrate Clickedin with SalesBlink",
    "metaDescription": "Integrate Clickedin and other Marketing Automation Tools with SalesBlink. Two Way Clickedin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spotler UK",
    "slug": "spotler-uk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b9da8dc9393039a1a0e4fd6cddaf8c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spotler UK develops and delivers software for professional email marketing automation.",
    "category": "Marketing Automation",
    "title": "Spotler UK Integration With Cold Email Sales Tool",
    "h1": "Integrate Spotler UK with SalesBlink",
    "metaDescription": "Integrate Spotler UK and other Marketing Automation Tools with SalesBlink. Two Way Spotler UK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Routezilla",
    "slug": "routezilla",
    "logo": "https://zapier-images.imgix.net/storage/services/4aeab9fb618f90d53424920764790fe6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Routezilla is a location based scheduling software that understands geography (and your customers).",
    "category": "Scheduling & Booking",
    "title": "Routezilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Routezilla with SalesBlink",
    "metaDescription": "Integrate Routezilla and other Scheduling & Booking Tools with SalesBlink. Two Way Routezilla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BlogIn",
    "slug": "blogin",
    "logo": "https://zapier-images.imgix.net/storage/developer/044fee9f32ecd9c5d9b85f4954142d26.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BlogIn is the beautifully simple internal blog and knowledge sharing platform for teams of all sizes.",
    "category": "Team Collaboration",
    "title": "BlogIn Integration With Cold Email Sales Tool",
    "h1": "Integrate BlogIn with SalesBlink",
    "metaDescription": "Integrate BlogIn and other Team Collaboration Tools with SalesBlink. Two Way BlogIn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ezidox",
    "slug": "ezidox",
    "logo": "https://zapier-images.imgix.net/storage/developer/8f1897d2032ff95a5aebf7280df70dcb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ezidox is a cloud-based platform for automating and simplifying the collection and exchange of documents required for transactions.",
    "category": "Documents",
    "title": "ezidox Integration With Cold Email Sales Tool",
    "h1": "Integrate ezidox with SalesBlink",
    "metaDescription": "Integrate ezidox and other Documents Tools with SalesBlink. Two Way ezidox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Obviously AI",
    "slug": "obviously-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/31c69d2969e593003fef24d1bab3b6de_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Obviously AI enables any business user to use historical data and make high fidelity predictive AI models in minutes, without writing code.",
    "category": "Developer Tools",
    "title": "Obviously AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Obviously AI with SalesBlink",
    "metaDescription": "Integrate Obviously AI and other Developer Tools Tools with SalesBlink. Two Way Obviously AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JGID",
    "slug": "jgid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/daed03a7e7a3e3d667f9c0f3ede91ebd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JGID – Just Get It Done! is an all-in-one solution for easily managing all aspects of your business from one platform.",
    "category": "Project Management",
    "title": "JGID Integration With Cold Email Sales Tool",
    "h1": "Integrate JGID with SalesBlink",
    "metaDescription": "Integrate JGID and other Project Management Tools with SalesBlink. Two Way JGID Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Huddo Boards",
    "slug": "huddo-boards",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ab1c6b8d58d18fff86974c195d5e859.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Huddo Boards - Collaboration done right. A task management tool with multiple ways to enter and view the data, including textual, kanban, mindmap and timeline.",
    "category": "Project Management",
    "title": "Huddo Boards Integration With Cold Email Sales Tool",
    "h1": "Integrate Huddo Boards with SalesBlink",
    "metaDescription": "Integrate Huddo Boards and other Project Management Tools with SalesBlink. Two Way Huddo Boards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VentiPay",
    "slug": "ventipay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e214a4a1f411a551266a72a9d5e678b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VentiPay is a payment platform that accepts online and physical payments via debit, prepaid, and credit cards, and handles subscriptions.",
    "category": "Payment Processing",
    "title": "VentiPay Integration With Cold Email Sales Tool",
    "h1": "Integrate VentiPay with SalesBlink",
    "metaDescription": "Integrate VentiPay and other Payment Processing Tools with SalesBlink. Two Way VentiPay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MakeMySummary",
    "slug": "makemysummary",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68dd575672ec91346d5f21f8471f6268.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MakeMySummary is an app to summarize any news articles or youtube videos into a few short sentences.",
    "category": "Bookmark Managers",
    "title": "MakeMySummary Integration With Cold Email Sales Tool",
    "h1": "Integrate MakeMySummary with SalesBlink",
    "metaDescription": "Integrate MakeMySummary and other Bookmark Managers Tools with SalesBlink. Two Way MakeMySummary Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jammed",
    "slug": "jammed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/019107c71629e230a3d76bc0cc368cc8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jammed is powering the scheduling and bookings for independent creative spaces, like rehearsal rooms, recording studios, dance studios, and more",
    "category": "Scheduling & Booking",
    "title": "Jammed Integration With Cold Email Sales Tool",
    "h1": "Integrate Jammed with SalesBlink",
    "metaDescription": "Integrate Jammed and other Scheduling & Booking Tools with SalesBlink. Two Way Jammed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LambdaTest",
    "slug": "lambdatest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ef40ae449db272b406c58613a7013c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LambdaTest is a platform to perform Automated and Live Interactive Cross Browser Testing on 3000+ Real Browsers and Operating Systems Online",
    "category": "Developer Tools",
    "title": "LambdaTest Integration With Cold Email Sales Tool",
    "h1": "Integrate LambdaTest with SalesBlink",
    "metaDescription": "Integrate LambdaTest and other Developer Tools Tools with SalesBlink. Two Way LambdaTest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Breakcold",
    "slug": "breakcold",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b41578a01f67c23ac1e26224aa7ec19f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Breakcold is a social selling and sales engagement platform. Engage and contact prospects via LinkedIn, Twitter & Email, from one place.",
    "category": "Drip Emails",
    "title": "Breakcold Integration With Cold Email Sales Tool",
    "h1": "Integrate Breakcold with SalesBlink",
    "metaDescription": "Integrate Breakcold and other Drip Emails Tools with SalesBlink. Two Way Breakcold Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "muse.ai",
    "slug": "museai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b2e1a1e542eef11e915902afd004b4f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "muse.ai is a complete video hosting and streaming platform with a clean\ninterface, sharing, embedding, and advanced video search.",
    "category": "Video & Audio",
    "title": "muse.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate muse.ai with SalesBlink",
    "metaDescription": "Integrate muse.ai and other Video & Audio Tools with SalesBlink. Two Way muse.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ExpertConnect",
    "slug": "expertconnect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5209994a261dc899b40b9e137df048e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ExpertConnect is a customer service platform for John Deere dealers.",
    "category": "Customer Support",
    "title": "ExpertConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate ExpertConnect with SalesBlink",
    "metaDescription": "Integrate ExpertConnect and other Customer Support Tools with SalesBlink. Two Way ExpertConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MemoMeister",
    "slug": "memomeister",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7507e52f97ecc00e99f7542334aae681_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MemoMeister is an easy-to-use application that helps you document projects by facts from anywhere. Documents like images, videos, plans or forms are stored as memos (including description, metadata and comments) securely and structured in one place: the digital project file.",
    "category": "File Management & Storage",
    "title": "MemoMeister Integration With Cold Email Sales Tool",
    "h1": "Integrate MemoMeister with SalesBlink",
    "metaDescription": "Integrate MemoMeister and other File Management & Storage Tools with SalesBlink. Two Way MemoMeister Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Probooking",
    "slug": "probooking",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd955c54cb75344593bd5b9d034b529c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Probooking is an appointment scheduling tool",
    "category": "Scheduling & Booking",
    "title": "Probooking Integration With Cold Email Sales Tool",
    "h1": "Integrate Probooking with SalesBlink",
    "metaDescription": "Integrate Probooking and other Scheduling & Booking Tools with SalesBlink. Two Way Probooking Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Strive",
    "slug": "strive",
    "logo": "https://zapier-images.imgix.net/storage/services/0725b24e42316d4f188276c269a939c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Strive is an AI-Powered Sales Coach that provides real-time analytics and one-to-one personalised coaching suggestions so that entrepreneurs and sales leaders can uncover hidden opportunities and close more deals.",
    "category": "Website & App Building",
    "title": "Strive Integration With Cold Email Sales Tool",
    "h1": "Integrate Strive with SalesBlink",
    "metaDescription": "Integrate Strive and other Website & App Building Tools with SalesBlink. Two Way Strive Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Pneumatic",
    "slug": "pneumatic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb39007aafaf8e459f10d409e614b310.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pneumatic is first workflow service created especially for small teams.",
    "category": "Team Collaboration",
    "title": "Pneumatic Integration With Cold Email Sales Tool",
    "h1": "Integrate Pneumatic with SalesBlink",
    "metaDescription": "Integrate Pneumatic and other Team Collaboration Tools with SalesBlink. Two Way Pneumatic Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sflow",
    "slug": "sflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6b2ec016e975f7e1e8b8a7bddedb7c6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sflow is a project collaboration tool to organize tasks and keep projects on track.",
    "category": "Project Management",
    "title": "Sflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Sflow with SalesBlink",
    "metaDescription": "Integrate Sflow and other Project Management Tools with SalesBlink. Two Way Sflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "waaq Link",
    "slug": "waaq-link",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ad644f9e8f816b320fd6be607dd4c0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "waaq Link is a powerful and simple scheduling tool for businesses.",
    "category": "Scheduling & Booking",
    "title": "waaq Link Integration With Cold Email Sales Tool",
    "h1": "Integrate waaq Link with SalesBlink",
    "metaDescription": "Integrate waaq Link and other Scheduling & Booking Tools with SalesBlink. Two Way waaq Link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Maestro",
    "slug": "maestro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6729f8f8559a0bf1314857bd3e0bc21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maestro is a no-code process orchestration tool, created to support agile business processes and leverage automation to allow team to focus on innovation.",
    "category": "Project Management",
    "title": "Maestro Integration With Cold Email Sales Tool",
    "h1": "Integrate Maestro with SalesBlink",
    "metaDescription": "Integrate Maestro and other Project Management Tools with SalesBlink. Two Way Maestro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kavkom",
    "slug": "kavkom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4fd2e5faf9fffd2dee7288fb76cd1b57.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kavkom CRM is a cloud-based enterprise telephony solution with innovative, simple and intuitive features.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kavkom Integration With Cold Email Sales Tool",
    "h1": "Integrate Kavkom with SalesBlink",
    "metaDescription": "Integrate Kavkom and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kavkom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magnetic",
    "slug": "magnetic",
    "logo": "https://zapier-images.imgix.net/storage/developer/719ad54fdd153cbf838460931ca1e12c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magnetic is a cloud based business management app that allows you to manage your whole business. We have modules focused on CRM, Project Management, Accounts and HR.",
    "category": "Project Management",
    "title": "Magnetic Integration With Cold Email Sales Tool",
    "h1": "Integrate Magnetic with SalesBlink",
    "metaDescription": "Integrate Magnetic and other Project Management Tools with SalesBlink. Two Way Magnetic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AgentHub",
    "slug": "agenthub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5bc94f330098a7764ee57a03b760a0f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The #1 Automation & Text Messaging Platform\nFor The Real Estate Industry\nThe first ever platform built to manage a Business's follow up,\ntwo-way texting, pipeline, scheduling, and so much more.\nBuilt for Real Estate Professionals, By Real Estate Professionals.",
    "category": "Marketing Automation",
    "title": "AgentHub Integration With Cold Email Sales Tool",
    "h1": "Integrate AgentHub with SalesBlink",
    "metaDescription": "Integrate AgentHub and other Marketing Automation Tools with SalesBlink. Two Way AgentHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DevSkiller",
    "slug": "devskiller",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e36d4c7e13ee2a0b071b7ad3d1661c9f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DevSkiller is a developer screening and online interview platform powered by RealLifeTesting™ methodology. The tool helps you find developers with the right skill set, shortening significantly your time to hire.",
    "category": "HR Talent & Recruitment",
    "title": "DevSkiller Integration With Cold Email Sales Tool",
    "h1": "Integrate DevSkiller with SalesBlink",
    "metaDescription": "Integrate DevSkiller and other HR Talent & Recruitment Tools with SalesBlink. Two Way DevSkiller Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MindBehind",
    "slug": "mindbehind",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e2f50a3adf544d933e40d8cf4c2b9e77.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mindbehind is a low code chatbot management platform.",
    "category": "Customer Support",
    "title": "MindBehind Integration With Cold Email Sales Tool",
    "h1": "Integrate MindBehind with SalesBlink",
    "metaDescription": "Integrate MindBehind and other Customer Support Tools with SalesBlink. Two Way MindBehind Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Issuetrak",
    "slug": "issuetrak",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d5fea9e92e351bcb266a993989a4333_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Issuetrak is an operational management platform to help you solve problems, control chaos, and experience less stress.",
    "category": "Customer Support",
    "title": "Issuetrak Integration With Cold Email Sales Tool",
    "h1": "Integrate Issuetrak with SalesBlink",
    "metaDescription": "Integrate Issuetrak and other Customer Support Tools with SalesBlink. Two Way Issuetrak Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rzilient",
    "slug": "rzilient",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d2a0986cf225d0621fbb66915fe7253.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rzilient is an outsourcing solution that simplifies and optimizes IT asset management for SMEs through IoT technology and telemetry systems.",
    "category": "IT Operations",
    "title": "Rzilient Integration With Cold Email Sales Tool",
    "h1": "Integrate Rzilient with SalesBlink",
    "metaDescription": "Integrate Rzilient and other IT Operations Tools with SalesBlink. Two Way Rzilient Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "crm4 solution",
    "slug": "crm4-solution",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b8f8d9ae975eb64961983732e1051f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "crm4  is a software for call center outbound.",
    "category": "Phone & SMS",
    "title": "crm4 solution Integration With Cold Email Sales Tool",
    "h1": "Integrate crm4 solution with SalesBlink",
    "metaDescription": "Integrate crm4 solution and other Phone & SMS Tools with SalesBlink. Two Way crm4 solution Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CoDo",
    "slug": "codo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff5df1e1de582f2d389591ee9cb085fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A training platform that turns repetitive, manual onboarding into automated, self-paced group journeys optimized for engagement, connection, retention, and scalability.",
    "category": "Human Resources",
    "title": "CoDo Integration With Cold Email Sales Tool",
    "h1": "Integrate CoDo with SalesBlink",
    "metaDescription": "Integrate CoDo and other Human Resources Tools with SalesBlink. Two Way CoDo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RotaCloud",
    "slug": "rotacloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8166f3a4ff8b01ed1da6121417870fb4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RotaCloud is an online rota solution which makes it easy to build and share your rotas, make changes and manage time off requests, all in one place.",
    "category": "Scheduling & Booking",
    "title": "RotaCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate RotaCloud with SalesBlink",
    "metaDescription": "Integrate RotaCloud and other Scheduling & Booking Tools with SalesBlink. Two Way RotaCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Engage AI",
    "slug": "engage-ai-by-filt-pod",
    "logo": "https://zapier-images.imgix.net/storage/services/28a9d63c6a6a1ef551337ee1ce69dc3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engage AI increases LinkedIn lead touchpoints by alerting you when they post and creating comments to speed up engagements.",
    "category": "AI Tools",
    "title": "Engage AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Engage AI with SalesBlink",
    "metaDescription": "Integrate Engage AI and other AI Tools Tools with SalesBlink. Two Way Engage AI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Whale",
    "slug": "whale",
    "logo": "https://zapier-images.imgix.net/storage/services/6f543e51c7c3780072542426b0a97930.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Centralize all your processes, SOPs, playbooks, and more — so you can quickly onboard, train, and set your team up for success.",
    "category": "Online Courses",
    "title": "Whale Integration With Cold Email Sales Tool",
    "h1": "Integrate Whale with SalesBlink",
    "metaDescription": "Integrate Whale and other Online Courses Tools with SalesBlink. Two Way Whale Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FlexBloc",
    "slug": "flexbloc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbf9f98313af3c4e2380526c926362c9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FlexBloc is a tool to help Product Owners understand and communicate with their users.",
    "category": "Product Management",
    "title": "FlexBloc Integration With Cold Email Sales Tool",
    "h1": "Integrate FlexBloc with SalesBlink",
    "metaDescription": "Integrate FlexBloc and other Product Management Tools with SalesBlink. Two Way FlexBloc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Composure",
    "slug": "composure",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/270b4a8964a0c81201f4680c7a68b92f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Composure is software designed for in-house legal departments. This allows legal departments to do more with less, maximizing their efficiency and minimizing their costs. Composure’s key features include intake management and workflow management to quantify legal work.",
    "category": "Productivity",
    "title": "Composure Integration With Cold Email Sales Tool",
    "h1": "Integrate Composure with SalesBlink",
    "metaDescription": "Integrate Composure and other Productivity Tools with SalesBlink. Two Way Composure Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DivvyHQ",
    "slug": "divvyhq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/175e1f76fc8c608fcf816d32ba360a49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DivvyHQ is a content operations platform built to help organizations improve their content planning, production, and publishing processes.",
    "category": "Marketing",
    "title": "DivvyHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate DivvyHQ with SalesBlink",
    "metaDescription": "Integrate DivvyHQ and other Marketing Tools with SalesBlink. Two Way DivvyHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Finaeo",
    "slug": "finaeo-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/da6a0251dc3cb9253de52d2c83380353.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Finaeo is a digital marketplace empowering independent life and health insurance brokers to increase efficiency and take clients from prospect-to-policy.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Finaeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Finaeo with SalesBlink",
    "metaDescription": "Integrate Finaeo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Finaeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tradler",
    "slug": "tradler",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcb2086147741a89b12235494a771d16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tradler is a productivity and employee engagement tool that uses Gamification to help you achieve your goals.",
    "category": "Human Resources",
    "title": "Tradler Integration With Cold Email Sales Tool",
    "h1": "Integrate Tradler with SalesBlink",
    "metaDescription": "Integrate Tradler and other Human Resources Tools with SalesBlink. Two Way Tradler Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Topia",
    "slug": "topia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1039525866e25e438981e36a87b1e6e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Topia is an always-on metaverse platform to gather in real-time with colleagues or friends for virtual events, conferences and more!",
    "category": "Marketing Automation",
    "title": "Topia Integration With Cold Email Sales Tool",
    "h1": "Integrate Topia with SalesBlink",
    "metaDescription": "Integrate Topia and other Marketing Automation Tools with SalesBlink. Two Way Topia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bluebird.cx",
    "slug": "bluebirdcx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eee669f002e71715efe5abf37f0d60a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bluebird.cx is an automated sending platform delivering handwritten notecards, personalized gifts, and digital gift cards to prospects, customers, and teammates.",
    "category": "Sales & CRM",
    "title": "Bluebird.cx Integration With Cold Email Sales Tool",
    "h1": "Integrate Bluebird.cx with SalesBlink",
    "metaDescription": "Integrate Bluebird.cx and other Sales & CRM Tools with SalesBlink. Two Way Bluebird.cx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qobrix",
    "slug": "qobrix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a726285196786287a42211743eaf2e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qobrix is a powerful platform that helps real estate professionals to build long-term customer & partner relationships, simplify sales & marketing activities, and better manage their property listings.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Qobrix Integration With Cold Email Sales Tool",
    "h1": "Integrate Qobrix with SalesBlink",
    "metaDescription": "Integrate Qobrix and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Qobrix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Peblo",
    "slug": "peblo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f858158ca328da4afd1af34b08cb56b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Peblo pays you for brand deals as soon as they are completed.",
    "category": "Accounting",
    "title": "Peblo Integration With Cold Email Sales Tool",
    "h1": "Integrate Peblo with SalesBlink",
    "metaDescription": "Integrate Peblo and other Accounting Tools with SalesBlink. Two Way Peblo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesdock",
    "slug": "salesdock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07544f1cdfaab809259d6a879d8f947b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesdock is a sales platform that digitizes & automates sales.",
    "category": "Sales & CRM",
    "title": "Salesdock Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesdock with SalesBlink",
    "metaDescription": "Integrate Salesdock and other Sales & CRM Tools with SalesBlink. Two Way Salesdock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AccurAI",
    "slug": "accurai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b79f75abff11c2ff50ef026f8f7d528a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccurAI is a machine-learning-based end-to-end automation app, providing unparalleled savings in document processing with tight integration options.",
    "category": "AI Tools",
    "title": "AccurAI Integration With Cold Email Sales Tool",
    "h1": "Integrate AccurAI with SalesBlink",
    "metaDescription": "Integrate AccurAI and other AI Tools Tools with SalesBlink. Two Way AccurAI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChannelDock",
    "slug": "channeldock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/34a7acd298b7533e77f202f1c893f5e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your inventory and order process with ChannelDock; you'll make not only yourself, but also your customers happy.",
    "category": "eCommerce",
    "title": "ChannelDock Integration With Cold Email Sales Tool",
    "h1": "Integrate ChannelDock with SalesBlink",
    "metaDescription": "Integrate ChannelDock and other eCommerce Tools with SalesBlink. Two Way ChannelDock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kommunity",
    "slug": "kommunity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65f4cc213efff1d2ac52cd512e43c0cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kommunity is a social event platform where you can grow your community, organize events, and communicate with members in one place.",
    "category": "Event Management",
    "title": "Kommunity Integration With Cold Email Sales Tool",
    "h1": "Integrate Kommunity with SalesBlink",
    "metaDescription": "Integrate Kommunity and other Event Management Tools with SalesBlink. Two Way Kommunity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Split CSV",
    "slug": "split-csv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f06ddf31805b001c3bcea05dc234dff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Split CSV is the easiest way to split a CSV file: split a large file into multiple files by file size, number of files, number of lines per file, or number of records per file. You can also remove columns, eliminate duplicates and more!",
    "category": "Productivity",
    "title": "Split CSV Integration With Cold Email Sales Tool",
    "h1": "Integrate Split CSV with SalesBlink",
    "metaDescription": "Integrate Split CSV and other Productivity Tools with SalesBlink. Two Way Split CSV Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Weemss",
    "slug": "weemss",
    "logo": "https://zapier-images.imgix.net/storage/developer/e4d8787bb6522faea089f031af1e705c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Weemss® is an event management software that does the heavy-duty work, so you can focus on the actual event. It lets you manage any type of event, customize the registration process and integrate it on your own site, or use Weemss to create one. Accept payments, boost sales and track performance data - everything made fast and easy.",
    "category": "Event Management",
    "title": "Weemss Integration With Cold Email Sales Tool",
    "h1": "Integrate Weemss with SalesBlink",
    "metaDescription": "Integrate Weemss and other Event Management Tools with SalesBlink. Two Way Weemss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pluto LMS",
    "slug": "pluto-lms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7fbaa6da49fb309d758aaad4094f6385.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pluto LMS is the easiest way to create and manage online courses.",
    "category": "Online Courses",
    "title": "Pluto LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Pluto LMS with SalesBlink",
    "metaDescription": "Integrate Pluto LMS and other Online Courses Tools with SalesBlink. Two Way Pluto LMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fiveoak",
    "slug": "fiveoak",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75e06e499e7e464931a793fdc5fc67bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fiveoak is a service that allows users to manage their reviews and send review invites to their customers.",
    "category": "Reviews",
    "title": "Fiveoak Integration With Cold Email Sales Tool",
    "h1": "Integrate Fiveoak with SalesBlink",
    "metaDescription": "Integrate Fiveoak and other Reviews Tools with SalesBlink. Two Way Fiveoak Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PortalCX",
    "slug": "portal-cx",
    "logo": "https://zapier-images.imgix.net/storage/services/383ce3a7aa9a6e467942cf33c58cc100_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PortalCX helps you give your customers a real-time view of their projects to reduce churn, cut support costs, and improve customer satisfaction. All through Portals customized to your business.",
    "category": "Sales & CRM",
    "title": "PortalCX Integration With Cold Email Sales Tool",
    "h1": "Integrate PortalCX with SalesBlink",
    "metaDescription": "Integrate PortalCX and other Sales & CRM Tools with SalesBlink. Two Way PortalCX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NUACOM",
    "slug": "nuacom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9fa98f0657426a6d9bc1facc207c50d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NUACOM is a unified communication platform and phone system to automate your call process.",
    "category": "Phone & SMS",
    "title": "NUACOM Integration With Cold Email Sales Tool",
    "h1": "Integrate NUACOM with SalesBlink",
    "metaDescription": "Integrate NUACOM and other Phone & SMS Tools with SalesBlink. Two Way NUACOM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickReply.ai",
    "slug": "quickreplyai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d765adcdd63a95aade6d9b41b6c770e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickReply.ai is a WhatsApp marketing platform, with Team Inbox, Chatbots, bulk messaging and automated campaigns.",
    "category": "Notifications",
    "title": "QuickReply.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickReply.ai with SalesBlink",
    "metaDescription": "Integrate QuickReply.ai and other Notifications Tools with SalesBlink. Two Way QuickReply.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kaleyra",
    "slug": "kaleyra",
    "logo": "https://zapier-images.imgix.net/storage/developer/4c002debffd0a9adeab4a94420f4adaa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kaleyra focuses on providing an integrated Cloud communication platform that caters to messaging services globally.",
    "category": "Phone & SMS",
    "title": "Kaleyra Integration With Cold Email Sales Tool",
    "h1": "Integrate Kaleyra with SalesBlink",
    "metaDescription": "Integrate Kaleyra and other Phone & SMS Tools with SalesBlink. Two Way Kaleyra Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Infotems",
    "slug": "infotems",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0b2c85601701184ff810c96a30d34696.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infotems is a cloud-based, case management software for law practitioners.",
    "category": "Task Management",
    "title": "Infotems Integration With Cold Email Sales Tool",
    "h1": "Integrate Infotems with SalesBlink",
    "metaDescription": "Integrate Infotems and other Task Management Tools with SalesBlink. Two Way Infotems Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MakeForms",
    "slug": "make-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4aa8238a00190b83df80cbade0d7ef8b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Open the gateway to verified, lifelong customers with MakeForms’ form builder. Create good-looking, email-friendly forms, surveys, and quizzes.",
    "category": "Website & App Building",
    "title": "MakeForms Integration With Cold Email Sales Tool",
    "h1": "Integrate MakeForms with SalesBlink",
    "metaDescription": "Integrate MakeForms and other Website & App Building Tools with SalesBlink. Two Way MakeForms Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Route the Service Platform",
    "slug": "route-the-service-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f6a1224cc3809b4743ba3463b1d6362_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Walkthrough, bidding and proposal software for commercial cleaning companies.",
    "category": "Proposal & Invoice Management",
    "title": "Route the Service Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Route the Service Platform with SalesBlink",
    "metaDescription": "Integrate Route the Service Platform and other Proposal & Invoice Management Tools with SalesBlink. Two Way Route the Service Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orioly",
    "slug": "orioly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27b591b24faa9b4b06e4616b120d3381.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Orioly is an Easy to use Booking and Management solution for Tours & Activities",
    "category": "IT Operations",
    "title": "Orioly Integration With Cold Email Sales Tool",
    "h1": "Integrate Orioly with SalesBlink",
    "metaDescription": "Integrate Orioly and other IT Operations Tools with SalesBlink. Two Way Orioly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contently",
    "slug": "contently",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d08e81ee4b9ad01c0c1c73a6c9c4a8de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contently offers the platform, freelance talent, and strategic services you need to create content that builds trust and drives results.",
    "category": "Marketing Automation",
    "title": "Contently Integration With Cold Email Sales Tool",
    "h1": "Integrate Contently with SalesBlink",
    "metaDescription": "Integrate Contently and other Marketing Automation Tools with SalesBlink. Two Way Contently Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickDesk",
    "slug": "quickdesk",
    "logo": "https://zapier-images.imgix.net/storage/services/a7dc559e759254a7a3fbbe6d0e46ddd4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickDesk is a simplified CRM software optimised for sales teams and professionals. Convert leads and close prospects easier than ever.",
    "category": "CRM (Customer Relationship Management)",
    "title": "QuickDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickDesk with SalesBlink",
    "metaDescription": "Integrate QuickDesk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way QuickDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kontent.ai",
    "slug": "kontentai",
    "logo": "https://zapier-images.imgix.net/storage/services/fd4b4e7168eaed2667457a0ae0b52651.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kontent.ai is a Content-as-a-Service platform that gives you all the headless CMS benefits while empowering marketers at the same time.",
    "category": "Developer Tools",
    "title": "Kontent.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Kontent.ai with SalesBlink",
    "metaDescription": "Integrate Kontent.ai and other Developer Tools Tools with SalesBlink. Two Way Kontent.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Re-Leased",
    "slug": "re-leased",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bfb385577527e62373bada88b16c05c8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Re-Leased property software is a complete cloud-based property management and accounting solution.",
    "category": "Accounting",
    "title": "Re-Leased Integration With Cold Email Sales Tool",
    "h1": "Integrate Re-Leased with SalesBlink",
    "metaDescription": "Integrate Re-Leased and other Accounting Tools with SalesBlink. Two Way Re-Leased Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nimble by NimbleWork",
    "slug": "nimble-by-nimblework",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30e195ad6359e2bd7e5c4a87c6672209_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nimble by NimbleWork is an All-in-one Adaptive Visual Work and Project management platform that helps teams to collaborate and deliver seamlessly.",
    "category": "Project Management",
    "title": "Nimble by NimbleWork Integration With Cold Email Sales Tool",
    "h1": "Integrate Nimble by NimbleWork with SalesBlink",
    "metaDescription": "Integrate Nimble by NimbleWork and other Project Management Tools with SalesBlink. Two Way Nimble by NimbleWork Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qwil Messenger",
    "slug": "qwil-messenger",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5604ae171487b0693043200a478f1b56.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qwil Messenger is an all-in one professional platform that allows firms and their employees to engage securely with their clients anywhere.",
    "category": "Communication",
    "title": "Qwil Messenger Integration With Cold Email Sales Tool",
    "h1": "Integrate Qwil Messenger with SalesBlink",
    "metaDescription": "Integrate Qwil Messenger and other Communication Tools with SalesBlink. Two Way Qwil Messenger Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Jaldi",
    "slug": "jaldi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27bb5ca07af5e32371047e763d0971df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jaldi allows you to respond to leads faster, never miss follow ups, and view your teams’ activity live.",
    "category": "Ads & Conversion",
    "title": "Jaldi Integration With Cold Email Sales Tool",
    "h1": "Integrate Jaldi with SalesBlink",
    "metaDescription": "Integrate Jaldi and other Ads & Conversion Tools with SalesBlink. Two Way Jaldi Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Consignly",
    "slug": "consignly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec293e669d7a1e78d7ce1072f714d1fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The ultimate solution for your 3PL operation.",
    "category": "Business Intelligence",
    "title": "Consignly Integration With Cold Email Sales Tool",
    "h1": "Integrate Consignly with SalesBlink",
    "metaDescription": "Integrate Consignly and other Business Intelligence Tools with SalesBlink. Two Way Consignly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sociocs",
    "slug": "sociocs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c7a96de190c18b77e28fde210fbc8bf0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sociocs is a customer communication tool for your business.",
    "category": "Communication",
    "title": "Sociocs Integration With Cold Email Sales Tool",
    "h1": "Integrate Sociocs with SalesBlink",
    "metaDescription": "Integrate Sociocs and other Communication Tools with SalesBlink. Two Way Sociocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superdocu",
    "slug": "superdocu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19e33a2374e6df51c50e08ff57d90d52.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Superdocu helps people collect documents and information easily, and follow-up in case of missing data.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Superdocu Integration With Cold Email Sales Tool",
    "h1": "Integrate Superdocu with SalesBlink",
    "metaDescription": "Integrate Superdocu and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Superdocu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EventsX",
    "slug": "eventsx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7fe380e3f762124c4c23d2044bd931c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EventX is an online event platform which will allow users to create new events, sell tickets, create booths  and much more.",
    "category": "Event Management",
    "title": "EventsX Integration With Cold Email Sales Tool",
    "h1": "Integrate EventsX with SalesBlink",
    "metaDescription": "Integrate EventsX and other Event Management Tools with SalesBlink. Two Way EventsX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whippy",
    "slug": "whippy",
    "logo": "https://zapier-images.imgix.net/storage/services/883b782209df9628be1968ddc096e1c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whippy is a shared SMS inbox for teams. With Whippy you can convert leads on your website. Qualify them via SMS with your team and gather feedback and reviews from customers.",
    "category": "Communication",
    "title": "Whippy Integration With Cold Email Sales Tool",
    "h1": "Integrate Whippy with SalesBlink",
    "metaDescription": "Integrate Whippy and other Communication Tools with SalesBlink. Two Way Whippy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InventoryBase",
    "slug": "inventorybase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e8b49eabb32e2a0a3afb37f432e94a8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Produce professional property inventories, check ins, check outs, interim inspections, building inspections, risk assessments and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "InventoryBase Integration With Cold Email Sales Tool",
    "h1": "Integrate InventoryBase with SalesBlink",
    "metaDescription": "Integrate InventoryBase and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way InventoryBase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Confetti",
    "slug": "confetti",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc34208e5e018d61806435b25564078c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Confetti is the all-in-one tool for all your conferences, festivals, workshops and social events.",
    "category": "Event Management",
    "title": "Confetti Integration With Cold Email Sales Tool",
    "h1": "Integrate Confetti with SalesBlink",
    "metaDescription": "Integrate Confetti and other Event Management Tools with SalesBlink. Two Way Confetti Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CampusReel",
    "slug": "campusreel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1296ff8204d0e4575cb9264e79877c23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CampusReel is a video platform for education. Easily scale user-generated video content, create engaging videos, and drive admissions, enrollment, and marketing goals.",
    "category": "Website & App Building",
    "title": "CampusReel Integration With Cold Email Sales Tool",
    "h1": "Integrate CampusReel with SalesBlink",
    "metaDescription": "Integrate CampusReel and other Website & App Building Tools with SalesBlink. Two Way CampusReel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Juice.ai",
    "slug": "juiceai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b59dda4d5289ff542466124493f97351.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Juice.ai to turn your site into a money-making machine. Build your content on powerful long-tail keywords that rank high in Google's SERPs.",
    "category": "Marketing Automation",
    "title": "Juice.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Juice.ai with SalesBlink",
    "metaDescription": "Integrate Juice.ai and other Marketing Automation Tools with SalesBlink. Two Way Juice.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HiDeliver",
    "slug": "hideliver",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ab8b3a9204f530b211d069ae97740a10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Schedule local Same-Day and 2 Hour Package Deliveries",
    "category": "eCommerce",
    "title": "HiDeliver Integration With Cold Email Sales Tool",
    "h1": "Integrate HiDeliver with SalesBlink",
    "metaDescription": "Integrate HiDeliver and other eCommerce Tools with SalesBlink. Two Way HiDeliver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ink.Works",
    "slug": "inkworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a47b9299bead0234a7b3f994e9ca759a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ink.Works is simple, affordable software for managing your print shop, sign shop, or custom apparel business.",
    "category": "Productivity",
    "title": "Ink.Works Integration With Cold Email Sales Tool",
    "h1": "Integrate Ink.Works with SalesBlink",
    "metaDescription": "Integrate Ink.Works and other Productivity Tools with SalesBlink. Two Way Ink.Works Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSGatewayCenter SMS",
    "slug": "smsgatewaycenter-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b3f2b16df49162e1986530384c353188_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSGatewayCenter SMS is a Bulk SMS Gateway Provider in India.",
    "category": "Communication",
    "title": "SMSGatewayCenter SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSGatewayCenter SMS with SalesBlink",
    "metaDescription": "Integrate SMSGatewayCenter SMS and other Communication Tools with SalesBlink. Two Way SMSGatewayCenter SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qiwio",
    "slug": "qiwio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23ae6a5d2fd2e05e0c2a78cb30472226.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qiwio is a video platform for your business that helps sales, marketing and support teams worldwide to easily find, engage and grow your brands audience and customer base.",
    "category": "Marketing",
    "title": "Qiwio Integration With Cold Email Sales Tool",
    "h1": "Integrate Qiwio with SalesBlink",
    "metaDescription": "Integrate Qiwio and other Marketing Tools with SalesBlink. Two Way Qiwio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Macanta",
    "slug": "macanta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40b5cae468a71651e1435000fead9359.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Macanta allows you to create a CRM as unique as your business, without the risk, cost or need for custom development. Macanta does for CRM as WIX does for websites.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Macanta Integration With Cold Email Sales Tool",
    "h1": "Integrate Macanta with SalesBlink",
    "metaDescription": "Integrate Macanta and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Macanta Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sivo",
    "slug": "sivo",
    "logo": "https://zapier-images.imgix.net/storage/services/229475b3c108bc3149b5c2d0a68590c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sivo provides debt-as-a-service. Our API allows companies to access debt funding and lend money to their users at scale.",
    "category": "Accounting",
    "title": "Sivo Integration With Cold Email Sales Tool",
    "h1": "Integrate Sivo with SalesBlink",
    "metaDescription": "Integrate Sivo and other Accounting Tools with SalesBlink. Two Way Sivo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Townscript",
    "slug": "townscript",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5ab046ef4ba9b8c5c02a1d2fbfbf555.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Townscript is a SAAS platform for Event organizers and also marketplace for Event enthusiasts. Townscript provides comprehensive DIY tool for Event organizers across the globe.",
    "category": "Event Management",
    "title": "Townscript Integration With Cold Email Sales Tool",
    "h1": "Integrate Townscript with SalesBlink",
    "metaDescription": "Integrate Townscript and other Event Management Tools with SalesBlink. Two Way Townscript Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brain Pod AI",
    "slug": "brain-pod-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2dd328b84092b9cb9596b4e4611bf617.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AI content creation tool for streamlined content production, including AI content, images, bulk article generator, & SEO mode.",
    "category": "AI Tools",
    "title": "Brain Pod AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Brain Pod AI with SalesBlink",
    "metaDescription": "Integrate Brain Pod AI and other AI Tools Tools with SalesBlink. Two Way Brain Pod AI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "trune",
    "slug": "trune",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b16e9c0417e41668aaa6692ad9d08ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TRUNE IS A STRUCTURED ONLINE RETROSPECTIVE TOOL & TEAM FEEDBACK SOFTWARE",
    "category": "Team Collaboration",
    "title": "trune Integration With Cold Email Sales Tool",
    "h1": "Integrate trune with SalesBlink",
    "metaDescription": "Integrate trune and other Team Collaboration Tools with SalesBlink. Two Way trune Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prexle POS",
    "slug": "prexle-pos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1707eaa3dac1c1b1b12694f6bf05a1ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prexle is a cloud-based Point of Sale that serves retail stores.",
    "category": "Sales & CRM",
    "title": "Prexle POS Integration With Cold Email Sales Tool",
    "h1": "Integrate Prexle POS with SalesBlink",
    "metaDescription": "Integrate Prexle POS and other Sales & CRM Tools with SalesBlink. Two Way Prexle POS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tuemilio",
    "slug": "tuemilio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43b8e2c11d2db548d099ea10e02e3baa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Early access waitlists for founders and startups that want to bring their idea to potential customers faster.",
    "category": "Marketing",
    "title": "Tuemilio Integration With Cold Email Sales Tool",
    "h1": "Integrate Tuemilio with SalesBlink",
    "metaDescription": "Integrate Tuemilio and other Marketing Tools with SalesBlink. Two Way Tuemilio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kitemaker",
    "slug": "kitemaker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68a70ae246284e8cf44cb921ede542ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get the most out of your product development team by planning and developing what your customers need together.",
    "category": "Team Collaboration",
    "title": "Kitemaker Integration With Cold Email Sales Tool",
    "h1": "Integrate Kitemaker with SalesBlink",
    "metaDescription": "Integrate Kitemaker and other Team Collaboration Tools with SalesBlink. Two Way Kitemaker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pipelyne",
    "slug": "pipelyne",
    "logo": "https://zapier-images.imgix.net/storage/services/7056dfb3876780ff511ca1c674da438d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pipelyne allows you to create questionnaires that will provide your leads with instant estimates, allowing you to generate high quality leads for your business.",
    "category": "Forms & Surveys",
    "title": "Pipelyne Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipelyne with SalesBlink",
    "metaDescription": "Integrate Pipelyne and other Forms & Surveys Tools with SalesBlink. Two Way Pipelyne Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Néo Sphère",
    "slug": "neo-sphere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa4968a4c17e27624934f6b89b45a82b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Néo Sphère is a software designed for real estate agencies",
    "category": "CRM (Customer Relationship Management)",
    "title": "Néo Sphère Integration With Cold Email Sales Tool",
    "h1": "Integrate Néo Sphère with SalesBlink",
    "metaDescription": "Integrate Néo Sphère and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Néo Sphère Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Suiteshare",
    "slug": "suiteshare",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3a2eb658b7facf3d5821444c21764ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Suiteshare is a digital marketing platform for lead capture, sales conversion, and analysis of results through WhatsApp.",
    "category": "Ads & Conversion",
    "title": "Suiteshare Integration With Cold Email Sales Tool",
    "h1": "Integrate Suiteshare with SalesBlink",
    "metaDescription": "Integrate Suiteshare and other Ads & Conversion Tools with SalesBlink. Two Way Suiteshare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vicodo",
    "slug": "vicodo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ac03fc5b069b20c610edfab3e1adc58.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vicodo is a customer assistance platform that combines video & live chat with the features of a CRM system.",
    "category": "Communication",
    "title": "Vicodo Integration With Cold Email Sales Tool",
    "h1": "Integrate Vicodo with SalesBlink",
    "metaDescription": "Integrate Vicodo and other Communication Tools with SalesBlink. Two Way Vicodo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zuddl",
    "slug": "zuddl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7dd72e52decfa5c669aa18771f8feca0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zuddl is a platform for B2B marketers and conference organizers to plan and run webinar and event programs that drive revenue. Zuddl supports the entire event lifecycle regardless of event format.",
    "category": "Event Management",
    "title": "Zuddl Integration With Cold Email Sales Tool",
    "h1": "Integrate Zuddl with SalesBlink",
    "metaDescription": "Integrate Zuddl and other Event Management Tools with SalesBlink. Two Way Zuddl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SnipForm",
    "slug": "snipform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf412280e57b0fcee8ae20703d6fa057.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SnipForm is a serverless form solution that helps developers add a validated & secure form backend in a matter of minutes.",
    "category": "Forms & Surveys",
    "title": "SnipForm Integration With Cold Email Sales Tool",
    "h1": "Integrate SnipForm with SalesBlink",
    "metaDescription": "Integrate SnipForm and other Forms & Surveys Tools with SalesBlink. Two Way SnipForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cisco Webex Meetings",
    "slug": "cisco-webex-meetings",
    "logo": "https://zapier-images.imgix.net/storage/services/293c594956009bdc2f484013e02ed104.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cisco Webex Meetings is the leading enterprise solution for video conferencing, online meetings, screen share, and webinars.",
    "category": "Video Conferencing",
    "title": "Cisco Webex Meetings Integration With Cold Email Sales Tool",
    "h1": "Integrate Cisco Webex Meetings with SalesBlink",
    "metaDescription": "Integrate Cisco Webex Meetings and other Video Conferencing Tools with SalesBlink. Two Way Cisco Webex Meetings Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Synergy!",
    "slug": "synergy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06308a00a35ea18030402595ebdf18bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Synergy! is a cloud-based CRM service that enables optimal communication based on a customer database which securely stores information.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Synergy! Integration With Cold Email Sales Tool",
    "h1": "Integrate Synergy! with SalesBlink",
    "metaDescription": "Integrate Synergy! and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Synergy! Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudphone.com",
    "slug": "cloudphonecom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/98f0371fb256d5d040dffc6703cdc5eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudphone.com is a Voice and Text automation tool for your business.  Never miss a call again.",
    "category": "Communication",
    "title": "Cloudphone.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudphone.com with SalesBlink",
    "metaDescription": "Integrate Cloudphone.com and other Communication Tools with SalesBlink. Two Way Cloudphone.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spylead",
    "slug": "spylead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d71c1f66fce2d91d0f2f28c8bdf752cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spylead is a scraping tool that finds and checks emails from LinkedIn, Google maps and SERPs.",
    "category": "Email",
    "title": "Spylead Integration With Cold Email Sales Tool",
    "h1": "Integrate Spylead with SalesBlink",
    "metaDescription": "Integrate Spylead and other Email Tools with SalesBlink. Two Way Spylead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MrScraper",
    "slug": "mrscraper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75b91b3adabb7b4b4a3f4d38baa70aad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The easiest web scraper with proxy rotation, infinite pagination, real browsers using JavaScript, and a built in scheduler.",
    "category": "Productivity",
    "title": "MrScraper Integration With Cold Email Sales Tool",
    "h1": "Integrate MrScraper with SalesBlink",
    "metaDescription": "Integrate MrScraper and other Productivity Tools with SalesBlink. Two Way MrScraper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Callexa Feedback",
    "slug": "callexa-feedback",
    "logo": "https://zapier-images.imgix.net/storage/developer/d3ee2f330de5dfa2a5c2c6d8d3457c8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Callexa is a full-featured surveying tool based on the established Net Promoter Score. Get valuable customer feedback with minimal effort.",
    "category": "Forms & Surveys",
    "title": "Callexa Feedback Integration With Cold Email Sales Tool",
    "h1": "Integrate Callexa Feedback with SalesBlink",
    "metaDescription": "Integrate Callexa Feedback and other Forms & Surveys Tools with SalesBlink. Two Way Callexa Feedback Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VaultRE",
    "slug": "vaultre",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/242d5e247d0a79c285cfcaa3b2632f9f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VaultRE is a sales & listings ; and property management platform for the real estate industry",
    "category": "CRM (Customer Relationship Management)",
    "title": "VaultRE Integration With Cold Email Sales Tool",
    "h1": "Integrate VaultRE with SalesBlink",
    "metaDescription": "Integrate VaultRE and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way VaultRE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Residential Proxies",
    "slug": "residential-proxy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3dcf4b002d26cbb8b5a832e7d88972a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Allows you to bypass IP restrictions and scrape multiple targets simultaneously to managing multiple social media and eCommerce accounts.",
    "category": "Developer Tools",
    "title": "Residential Proxies Integration With Cold Email Sales Tool",
    "h1": "Integrate Residential Proxies with SalesBlink",
    "metaDescription": "Integrate Residential Proxies and other Developer Tools Tools with SalesBlink. Two Way Residential Proxies Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nuclia",
    "slug": "nuclia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2493b5eb455378b2722d82287919c9df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nuclia provides AI search accross your unstructured data from any source. It handles video/audio transcription, OCR, and document parsing.",
    "category": "AI Tools",
    "title": "Nuclia Integration With Cold Email Sales Tool",
    "h1": "Integrate Nuclia with SalesBlink",
    "metaDescription": "Integrate Nuclia and other AI Tools Tools with SalesBlink. Two Way Nuclia Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Zoho Backstage",
    "slug": "zoho-backstage",
    "logo": "https://zapier-images.imgix.net/storage/developer/8b590b71b67401f0445e5ddf617c896e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Backstage is an enterprise event management tool to help you create memorable event experiences.",
    "category": "Event Management",
    "title": "Zoho Backstage Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Backstage with SalesBlink",
    "metaDescription": "Integrate Zoho Backstage and other Event Management Tools with SalesBlink. Two Way Zoho Backstage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tuskr",
    "slug": "tuskr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb03e7dfe4335fb93e4c15f83627c7b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tuskr is a cloud-based test management software.",
    "category": "Developer Tools",
    "title": "Tuskr Integration With Cold Email Sales Tool",
    "h1": "Integrate Tuskr with SalesBlink",
    "metaDescription": "Integrate Tuskr and other Developer Tools Tools with SalesBlink. Two Way Tuskr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Abby Connect",
    "slug": "abby-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5346cb41ef9ff582f6e6073f21e165df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Abby Connect helps small businesses grow and stay connected with potential clients and customers 24/7/365 with live phone answering and chat messaging services.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Abby Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Abby Connect with SalesBlink",
    "metaDescription": "Integrate Abby Connect and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Abby Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VacationLabs",
    "slug": "vacationlabs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1298016d1f1dcbbf03d12e7595ab1f4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A comprehensive online Booking system and website builder for tour operators , to manage  website, inventory, rates, and agents",
    "category": "Website Builders",
    "title": "VacationLabs Integration With Cold Email Sales Tool",
    "h1": "Integrate VacationLabs with SalesBlink",
    "metaDescription": "Integrate VacationLabs and other Website Builders Tools with SalesBlink. Two Way VacationLabs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dante Systems",
    "slug": "dante-systems",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c6cf174d0085df2202af7480020ad61.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Training Management Software to centralise training operations and optimise administration time",
    "category": "Education",
    "title": "Dante Systems Integration With Cold Email Sales Tool",
    "h1": "Integrate Dante Systems with SalesBlink",
    "metaDescription": "Integrate Dante Systems and other Education Tools with SalesBlink. Two Way Dante Systems Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SolidNexus",
    "slug": "solidnexus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b93f0fe9691c9fd7cccaf2886864b9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SolidNexus is a service that extracts contacts from email signatures.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SolidNexus Integration With Cold Email Sales Tool",
    "h1": "Integrate SolidNexus with SalesBlink",
    "metaDescription": "Integrate SolidNexus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SolidNexus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contacts.Link",
    "slug": "contactslink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e7a2710ea11e10ba4c673c3aded69ba5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contacts.Link helps Sales and Marketing to build brand audience and convert them into clients.",
    "category": "Sales & CRM",
    "title": "Contacts.Link Integration With Cold Email Sales Tool",
    "h1": "Integrate Contacts.Link with SalesBlink",
    "metaDescription": "Integrate Contacts.Link and other Sales & CRM Tools with SalesBlink. Two Way Contacts.Link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DashNex PowerTech Store Builder",
    "slug": "dashnex-powertech-store-builder-",
    "logo": "https://zapier-images.imgix.net/storage/services/d5eb8370649058057f545ad46393b267.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A powerful store builder platform to launch and host a high converting eCommerce store in minutes without prior know-how or skills.",
    "category": "eCommerce",
    "title": "DashNex PowerTech Store Builder Integration With Cold Email Sales Tool",
    "h1": "Integrate DashNex PowerTech Store Builder with SalesBlink",
    "metaDescription": "Integrate DashNex PowerTech Store Builder and other eCommerce Tools with SalesBlink. Two Way DashNex PowerTech Store Builder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Goals by KeepSolid",
    "slug": "goals-by-keepsolid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/738ac0386eaa452ae8c5567f6d8b9bd5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Goals by KeepSolid is a goal management platform, where you can set goals, plan strategy, involve your team, and achieve results together.",
    "category": "Productivity",
    "title": "Goals by KeepSolid Integration With Cold Email Sales Tool",
    "h1": "Integrate Goals by KeepSolid with SalesBlink",
    "metaDescription": "Integrate Goals by KeepSolid and other Productivity Tools with SalesBlink. Two Way Goals by KeepSolid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alive5",
    "slug": "alive5",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bdb6eca813624434a3b2dc48ba466fcd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alive5 is a lead generation tool that engages people on your website, phone line, and real-world marketing materials to help generate more business opportunities.",
    "category": "Customer Support",
    "title": "Alive5 Integration With Cold Email Sales Tool",
    "h1": "Integrate Alive5 with SalesBlink",
    "metaDescription": "Integrate Alive5 and other Customer Support Tools with SalesBlink. Two Way Alive5 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lighthouse",
    "slug": "lighthouse",
    "logo": "https://zapier-images.imgix.net/storage/services/6b027b9bef98931ad8c6c1ab264e29c3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lighthouse is a beautifully simple issue tracker changing the way thousands manage their issues.",
    "category": "Developer Tools",
    "title": "Lighthouse Integration With Cold Email Sales Tool",
    "h1": "Integrate Lighthouse with SalesBlink",
    "metaDescription": "Integrate Lighthouse and other Developer Tools Tools with SalesBlink. Two Way Lighthouse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Followup CRM",
    "slug": "followup-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/83b68f399bad7f88669c588cb7660697.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Followup CRM allows construction contractors to keep track of leads, jobs, and bids, upload photos and documents from anywhere with our cloud-based software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Followup CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Followup CRM with SalesBlink",
    "metaDescription": "Integrate Followup CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Followup CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Otter Waiver",
    "slug": "otter-waiver",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1cfbf476ec126d3539778d24f1234fd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Otter Waiver delivers a modern waiver signing experience that your staff and participants will love.",
    "category": "Productivity",
    "title": "Otter Waiver Integration With Cold Email Sales Tool",
    "h1": "Integrate Otter Waiver with SalesBlink",
    "metaDescription": "Integrate Otter Waiver and other Productivity Tools with SalesBlink. Two Way Otter Waiver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phone.do",
    "slug": "phonedo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82442a4a4faed5bc7299b04c77532736_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate your Phone.do call center with other platforms, automatically send call summaries. Improve customer service and sales performance.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Phone.do Integration With Cold Email Sales Tool",
    "h1": "Integrate Phone.do with SalesBlink",
    "metaDescription": "Integrate Phone.do and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Phone.do Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FollowUp.cc",
    "slug": "followup",
    "logo": "https://zapier-images.imgix.net/storage/developer/1f0c94a53d7bc317391e6c83c39cd68b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FollowUp.cc is a timed email reminder system that gives users the ability to create reminders via email through formatted email addresses in the TO, CC and BCC fields. Allows users to set time based email reminders for individuals or from groups for single or recurring events.",
    "category": "Drip Emails",
    "title": "FollowUp.cc Integration With Cold Email Sales Tool",
    "h1": "Integrate FollowUp.cc with SalesBlink",
    "metaDescription": "Integrate FollowUp.cc and other Drip Emails Tools with SalesBlink. Two Way FollowUp.cc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rhythm",
    "slug": "rhythm-da106222",
    "logo": "https://zapier-images.imgix.net/storage/developer/b93baef2b40c8636d6270fa49433f087.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rhythm is a strategy execution platform for helping companies and teams achieve their goals.",
    "category": "Dashboards",
    "title": "Rhythm Integration With Cold Email Sales Tool",
    "h1": "Integrate Rhythm with SalesBlink",
    "metaDescription": "Integrate Rhythm and other Dashboards Tools with SalesBlink. Two Way Rhythm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GiveForms",
    "slug": "giveforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ffbc21b8a7b5d91dd7521836db8369d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Best in Class Donation Forms for Your Website",
    "category": "Fundraising",
    "title": "GiveForms Integration With Cold Email Sales Tool",
    "h1": "Integrate GiveForms with SalesBlink",
    "metaDescription": "Integrate GiveForms and other Fundraising Tools with SalesBlink. Two Way GiveForms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Back in Stock",
    "slug": "back-in-stock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0904bbde3f631d3714250e4d8ffc873b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notify customers when products are back in stock",
    "category": "eCommerce",
    "title": "Back in Stock Integration With Cold Email Sales Tool",
    "h1": "Integrate Back in Stock with SalesBlink",
    "metaDescription": "Integrate Back in Stock and other eCommerce Tools with SalesBlink. Two Way Back in Stock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho ShowTime",
    "slug": "zoho-showtime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/de0f13605dfe3b5c62fee744acfecefb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho ShowTime is a web-conferencing and an online virtual training tool that lets trainers engage with their attendees to drive self-improvement through feedback, and showcase their delivery skills on a secure cloud platform. It is available on the web and as a mobile app.",
    "category": "Webinars",
    "title": "Zoho ShowTime Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho ShowTime with SalesBlink",
    "metaDescription": "Integrate Zoho ShowTime and other Webinars Tools with SalesBlink. Two Way Zoho ShowTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Doktor365",
    "slug": "doktor365",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ffd0699f1d79561db7ee124d14e591d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily follow up and plan your potential and active patients. Manage all processes of your clinic from a single platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Doktor365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Doktor365 with SalesBlink",
    "metaDescription": "Integrate Doktor365 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Doktor365 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Serio Verify",
    "slug": "serio-verify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0316e9a794759c7740a7ed1670715fde_12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Serio Verify is your software platform for lead management. Predict your conversion with AI, automatically validate and enrich your leads and get relevant statistics, so you can make the right strategic decisions. 100% GDPR compliant - all the way.",
    "category": "Business Intelligence",
    "title": "Serio Verify Integration With Cold Email Sales Tool",
    "h1": "Integrate Serio Verify with SalesBlink",
    "metaDescription": "Integrate Serio Verify and other Business Intelligence Tools with SalesBlink. Two Way Serio Verify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PicallEx",
    "slug": "picallex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6cdf9456dd0bea7e81dc8a264b82dae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This integration allow to our users create orders via API Integration into PicallEx CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PicallEx Integration With Cold Email Sales Tool",
    "h1": "Integrate PicallEx with SalesBlink",
    "metaDescription": "Integrate PicallEx and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PicallEx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PickFu",
    "slug": "pickfu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02d54fd7c555ab25033ae2ebd3ae5017.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PickFu allows you to run a poll and get in-depth feedback from real people in minutes. It's that easy.",
    "category": "Marketing",
    "title": "PickFu Integration With Cold Email Sales Tool",
    "h1": "Integrate PickFu with SalesBlink",
    "metaDescription": "Integrate PickFu and other Marketing Tools with SalesBlink. Two Way PickFu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DigitalMentors",
    "slug": "digitalmentors-business-center-c",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b4912a0a70371ac4870b5388baf1864.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DigitalMentors is an all-in-one solution for creating, marketing, and selling digital products, as well as recurring membership programs.",
    "category": "Marketing",
    "title": "DigitalMentors Integration With Cold Email Sales Tool",
    "h1": "Integrate DigitalMentors with SalesBlink",
    "metaDescription": "Integrate DigitalMentors and other Marketing Tools with SalesBlink. Two Way DigitalMentors Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sandbox",
    "slug": "sandbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b85467d279a900d1c0985759025a54f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sandbox's talent intelligence solutions help you align the right team members to the right projects, initiatives, and jobs in your organization.",
    "category": "Business Intelligence",
    "title": "Sandbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Sandbox with SalesBlink",
    "metaDescription": "Integrate Sandbox and other Business Intelligence Tools with SalesBlink. Two Way Sandbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inflowcare",
    "slug": "inflowcare",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/159bbb9bdd46f21488aa4eb2558be30b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inflowcare is a CRM Platform for tracking and managing new patient referrals and Caregiver application tracking for Healthcare agencies.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Inflowcare Integration With Cold Email Sales Tool",
    "h1": "Integrate Inflowcare with SalesBlink",
    "metaDescription": "Integrate Inflowcare and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Inflowcare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hey Oliver",
    "slug": "hey-oliver",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f600ea70b77b810ba93523983b25d0a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hey Oliver is marketing automation tool that helps you convert more visitors, and run automated, targeted and personalized marketing campaigns.",
    "category": "Marketing Automation",
    "title": "Hey Oliver Integration With Cold Email Sales Tool",
    "h1": "Integrate Hey Oliver with SalesBlink",
    "metaDescription": "Integrate Hey Oliver and other Marketing Automation Tools with SalesBlink. Two Way Hey Oliver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Barracuda",
    "slug": "barracuda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b29ec4bf48efe11a45d2e73ab99bea73.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A lightweight CRM for modern sales & partnerships teams",
    "category": "Sales & CRM",
    "title": "Barracuda Integration With Cold Email Sales Tool",
    "h1": "Integrate Barracuda with SalesBlink",
    "metaDescription": "Integrate Barracuda and other Sales & CRM Tools with SalesBlink. Two Way Barracuda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Universell",
    "slug": "universell",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/af6d1438c3824b09aa3fc6301b2e32e0_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scale your business faster, minus heavy resources.\nFully managed tools & services helping you reach more customers, get paid easily, and scale & grow your business without any additional investment in staff and systems.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Universell Integration With Cold Email Sales Tool",
    "h1": "Integrate Universell with SalesBlink",
    "metaDescription": "Integrate Universell and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Universell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BeeLiked",
    "slug": "beeliked",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BeeLiked is a promotional marketing platform for businesses to create interactive and gamified promotions to engage, incentivize and reward their online audiences, customers and employees.",
    "category": "Marketing",
    "title": "BeeLiked Integration With Cold Email Sales Tool",
    "h1": "Integrate BeeLiked with SalesBlink",
    "metaDescription": "Integrate BeeLiked and other Marketing Tools with SalesBlink. Two Way BeeLiked Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventify",
    "slug": "eventify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c128368daf960cda581cb34ab159678.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventify is an event based platform for in-person or virtual B2B events & conferences. Registration & ticketing, event networking, attendee check-in and many more.",
    "category": "Event Management",
    "title": "Eventify Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventify with SalesBlink",
    "metaDescription": "Integrate Eventify and other Event Management Tools with SalesBlink. Two Way Eventify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sinorbis",
    "slug": "sinorbis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/90762c18dab879a9ad105f637bffa19a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sinorbis is an all-in-one marketing platform that lets you manage, measure and optimise your digital marketing in China. Create your Chinese website and WeChat content, generate leads, manage your followers and integrate the tool with your CRM or other marketing channels.",
    "category": "Marketing Automation",
    "title": "Sinorbis Integration With Cold Email Sales Tool",
    "h1": "Integrate Sinorbis with SalesBlink",
    "metaDescription": "Integrate Sinorbis and other Marketing Automation Tools with SalesBlink. Two Way Sinorbis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FinLegal Claims Automation",
    "slug": "finlegal-claims-automation-ca142",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21b0720cc571edc2d31fa60812a8efeb.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FinLegal Claims Automation is a total solution for legal claims automation.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FinLegal Claims Automation Integration With Cold Email Sales Tool",
    "h1": "Integrate FinLegal Claims Automation with SalesBlink",
    "metaDescription": "Integrate FinLegal Claims Automation and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FinLegal Claims Automation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SkyPrep",
    "slug": "skyprep",
    "logo": "https://zapier-images.imgix.net/storage/services/046ca676e08eff063dd36a414d90fd78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SkyPrep is an LMS that allows you to easily host, deliver, and track all your training.",
    "category": "Online Courses",
    "title": "SkyPrep Integration With Cold Email Sales Tool",
    "h1": "Integrate SkyPrep with SalesBlink",
    "metaDescription": "Integrate SkyPrep and other Online Courses Tools with SalesBlink. Two Way SkyPrep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bugpilot",
    "slug": "adopto",
    "logo": "https://zapier-images.imgix.net/storage/services/47b6995ab897229d1a0d00d05d9a7571.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatically collect screenshots and technical info from users.",
    "category": "Developer Tools",
    "title": "Bugpilot Integration With Cold Email Sales Tool",
    "h1": "Integrate Bugpilot with SalesBlink",
    "metaDescription": "Integrate Bugpilot and other Developer Tools Tools with SalesBlink. Two Way Bugpilot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Churchpool",
    "slug": "churchpool",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99b7acb147107633da687e19f95936c2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Churchpool is a communication and content platform for churches and their members.",
    "category": "Social Media Accounts",
    "title": "Churchpool Integration With Cold Email Sales Tool",
    "h1": "Integrate Churchpool with SalesBlink",
    "metaDescription": "Integrate Churchpool and other Social Media Accounts Tools with SalesBlink. Two Way Churchpool Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aerofiler",
    "slug": "aerofiler",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e53b430aaea519b1a5729d4fea65a2d4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aerofiler is for in-house legal teams who wish to automate the process of creating, approving, filing and tracking contracts.",
    "category": "Documents",
    "title": "Aerofiler Integration With Cold Email Sales Tool",
    "h1": "Integrate Aerofiler with SalesBlink",
    "metaDescription": "Integrate Aerofiler and other Documents Tools with SalesBlink. Two Way Aerofiler Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WorkAssist",
    "slug": "workassist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f2ef904b8294450518b605f40a78d07.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WorkAssist provides a conversational AI enabled workspace for teams to collaborate and take action on this data, thereby improving productivity and optimizing employee experiences",
    "category": "Productivity",
    "title": "WorkAssist Integration With Cold Email Sales Tool",
    "h1": "Integrate WorkAssist with SalesBlink",
    "metaDescription": "Integrate WorkAssist and other Productivity Tools with SalesBlink. Two Way WorkAssist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocMerger",
    "slug": "docmerger",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f22b803805db7608730591ccfb0dee2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Merge and Create PDF and Word Documents on the fly using templates.",
    "category": "Documents",
    "title": "DocMerger Integration With Cold Email Sales Tool",
    "h1": "Integrate DocMerger with SalesBlink",
    "metaDescription": "Integrate DocMerger and other Documents Tools with SalesBlink. Two Way DocMerger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Writesonic Bulk",
    "slug": "writesonic-bulk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e5c3ed9d0bab575c3a46484de5871a7.PNG?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Writesonic Bulk is an AI content generator.",
    "category": "AI Tools",
    "title": "Writesonic Bulk Integration With Cold Email Sales Tool",
    "h1": "Integrate Writesonic Bulk with SalesBlink",
    "metaDescription": "Integrate Writesonic Bulk and other AI Tools Tools with SalesBlink. Two Way Writesonic Bulk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flexie CRM",
    "slug": "flexie-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/7674272da314d5c0d10cce68ce1593ad_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flexie CRM solution is built to help sales teams stay organized, nurture leads, close more deals, and improve overall productivity.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Flexie CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Flexie CRM with SalesBlink",
    "metaDescription": "Integrate Flexie CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Flexie CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SMSINDIAHUB (India)",
    "slug": "smsindiahub-india",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/029ee7a4fd82f96b0099ce8698184f0c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSINDIAHUB India is an SMS Service that allows you to send SMS messages. Suitable for users in India - if not, please use SMSINDIAHUB (International).",
    "category": "Phone & SMS",
    "title": "SMSINDIAHUB (India) Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSINDIAHUB (India) with SalesBlink",
    "metaDescription": "Integrate SMSINDIAHUB (India) and other Phone & SMS Tools with SalesBlink. Two Way SMSINDIAHUB (India) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CrowdPower",
    "slug": "crowdpower",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0ebce0b176bd32727101f8bf3a7c4d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CrowdPower is an automation tool that performs actions based on website user behavior.",
    "category": "Marketing Automation",
    "title": "CrowdPower Integration With Cold Email Sales Tool",
    "h1": "Integrate CrowdPower with SalesBlink",
    "metaDescription": "Integrate CrowdPower and other Marketing Automation Tools with SalesBlink. Two Way CrowdPower Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Quiltt",
    "slug": "quiltt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f24dc589ea38cbeac7e9251c46b75c8a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quiltt provides turnkey access to fintech APIs and apps. We integrate and enhance consumer financial data through one API.",
    "category": "App Builder",
    "title": "Quiltt Integration With Cold Email Sales Tool",
    "h1": "Integrate Quiltt with SalesBlink",
    "metaDescription": "Integrate Quiltt and other App Builder Tools with SalesBlink. Two Way Quiltt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ricochet - Speed to Contact",
    "slug": "ricochet360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1df427b74a1eac3f18852c8b1f81b02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ricochet Dialer & CRM official Zapier integration",
    "category": "Phone & SMS",
    "title": "Ricochet - Speed to Contact Integration With Cold Email Sales Tool",
    "h1": "Integrate Ricochet - Speed to Contact with SalesBlink",
    "metaDescription": "Integrate Ricochet - Speed to Contact and other Phone & SMS Tools with SalesBlink. Two Way Ricochet - Speed to Contact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LianaMailer",
    "slug": "lianamailer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/baf670557aaa7ff20d9556ce4a5a7d66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LianaMailer is an email marketing tool to create device-optimized messages in line with your brand with just a few clicks.",
    "category": "Email Newsletters",
    "title": "LianaMailer Integration With Cold Email Sales Tool",
    "h1": "Integrate LianaMailer with SalesBlink",
    "metaDescription": "Integrate LianaMailer and other Email Newsletters Tools with SalesBlink. Two Way LianaMailer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shoplazza",
    "slug": "shoplazza",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ed7010e638af2007567375987c8d9b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shoplazza is an online store builder platform that empowers over 360,000+ stores of all sizes to grow their businesses.",
    "category": "Commerce",
    "title": "Shoplazza Integration With Cold Email Sales Tool",
    "h1": "Integrate Shoplazza with SalesBlink",
    "metaDescription": "Integrate Shoplazza and other Commerce Tools with SalesBlink. Two Way Shoplazza Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Recognize",
    "slug": "recognize",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cec7d22895a99aa73a159f11cd05b51_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recognize is a social employee recognition and rewards platform.",
    "category": "Human Resources",
    "title": "Recognize Integration With Cold Email Sales Tool",
    "h1": "Integrate Recognize with SalesBlink",
    "metaDescription": "Integrate Recognize and other Human Resources Tools with SalesBlink. Two Way Recognize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Writeathon",
    "slug": "writeathon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/089882aa0b0d9aa229d19064d009ce13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Writeathon is the product for writing, providing the flow and inspiration creators need to make content.",
    "category": "Productivity",
    "title": "Writeathon Integration With Cold Email Sales Tool",
    "h1": "Integrate Writeathon with SalesBlink",
    "metaDescription": "Integrate Writeathon and other Productivity Tools with SalesBlink. Two Way Writeathon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sinao",
    "slug": "sinao",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a351daeb6f035814f2676211740919a2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integration of Sinao Version 2 (Accounting Software)",
    "category": "Accounting",
    "title": "Sinao Integration With Cold Email Sales Tool",
    "h1": "Integrate Sinao with SalesBlink",
    "metaDescription": "Integrate Sinao and other Accounting Tools with SalesBlink. Two Way Sinao Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Attention Insight",
    "slug": "attention-insight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/17eff603f926997f852609bfa5b8327a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Allows you to upload images to Attention Insight platform, perform attention prediction analysis and return attention heatmap image",
    "category": "Marketing",
    "title": "Attention Insight Integration With Cold Email Sales Tool",
    "h1": "Integrate Attention Insight with SalesBlink",
    "metaDescription": "Integrate Attention Insight and other Marketing Tools with SalesBlink. Two Way Attention Insight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inbound Insight",
    "slug": "inbound-insight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/92a645da5e41bfe8c52bfdf3efa1cbec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inbound Insight allow marketers and data scientists to gain the insight they need about customers and prospects to be able to maximize marketing conversions.",
    "category": "Marketing",
    "title": "Inbound Insight Integration With Cold Email Sales Tool",
    "h1": "Integrate Inbound Insight with SalesBlink",
    "metaDescription": "Integrate Inbound Insight and other Marketing Tools with SalesBlink. Two Way Inbound Insight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Attach",
    "slug": "attach",
    "logo": "https://zapier-images.imgix.net/storage/developer/67fac31334942647666cc56f5302355c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Attach is a sales engagement tool that lets you manage, track and control the sales collateral you share.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Attach Integration With Cold Email Sales Tool",
    "h1": "Integrate Attach with SalesBlink",
    "metaDescription": "Integrate Attach and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Attach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YourWoo",
    "slug": "yourwoo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53578308ec01e9743979f36c4301e0d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YourWoo identifies Sales Opportunities for Entrepreneurs and SMEs throughout Europe.",
    "category": "Sales & CRM",
    "title": "YourWoo Integration With Cold Email Sales Tool",
    "h1": "Integrate YourWoo with SalesBlink",
    "metaDescription": "Integrate YourWoo and other Sales & CRM Tools with SalesBlink. Two Way YourWoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flow",
    "slug": "flow",
    "logo": "https://zapier-images.imgix.net/storage/services/9d844a0ae78644668c0fde1a001233d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flow is a task management solution that offers an online web application, iOS mobile app, and a native Mac OS X application for users.",
    "category": "Task Management",
    "title": "Flow Integration With Cold Email Sales Tool",
    "h1": "Integrate Flow with SalesBlink",
    "metaDescription": "Integrate Flow and other Task Management Tools with SalesBlink. Two Way Flow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Happyfeed",
    "slug": "happyfeed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c7aa111419aa4788130f5a8664192ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Happyfeed is a simple journaling app to help you cultivate little moments of joy and gratitude in your life.",
    "category": "Notes",
    "title": "Happyfeed Integration With Cold Email Sales Tool",
    "h1": "Integrate Happyfeed with SalesBlink",
    "metaDescription": "Integrate Happyfeed and other Notes Tools with SalesBlink. Two Way Happyfeed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProfileGrid",
    "slug": "profilegrid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/907055204e60d53117227028adc5aa40_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProfileGrid is a power-packed WordPress user profile, membership and directory plugin.",
    "category": "Website & App Building",
    "title": "ProfileGrid Integration With Cold Email Sales Tool",
    "h1": "Integrate ProfileGrid with SalesBlink",
    "metaDescription": "Integrate ProfileGrid and other Website & App Building Tools with SalesBlink. Two Way ProfileGrid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jetdocs",
    "slug": "jetdocs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59cf86ede842d0255e0b0292394adce8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jetdocs is an internal ticketing solution that integrates with Slack and Teams, powered by no-code catalogs, queues, templates and bots.",
    "category": "Productivity",
    "title": "Jetdocs Integration With Cold Email Sales Tool",
    "h1": "Integrate Jetdocs with SalesBlink",
    "metaDescription": "Integrate Jetdocs and other Productivity Tools with SalesBlink. Two Way Jetdocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Countable",
    "slug": "countable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ff129f1efed82550f0afa05c11f1169.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Countable API integration to help clients create a more dynamic user experience.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Countable Integration With Cold Email Sales Tool",
    "h1": "Integrate Countable with SalesBlink",
    "metaDescription": "Integrate Countable and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Countable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Narrato",
    "slug": "narrato",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/35806b86ec5464572599ebf027374727.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Narrato Workspace is a content creation, planning and collaboration software. Built for content marketing and publishing teams.",
    "category": "Marketing Automation",
    "title": "Narrato Integration With Cold Email Sales Tool",
    "h1": "Integrate Narrato with SalesBlink",
    "metaDescription": "Integrate Narrato and other Marketing Automation Tools with SalesBlink. Two Way Narrato Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NextEvent",
    "slug": "nextevent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3dfd65f660d403305f6f050b4edf44ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NextEvent is an event management platform that lets you sell tickets, create powerful seating plans and provides you a mobile app to validate your visitors tickets.",
    "category": "Event Management",
    "title": "NextEvent Integration With Cold Email Sales Tool",
    "h1": "Integrate NextEvent with SalesBlink",
    "metaDescription": "Integrate NextEvent and other Event Management Tools with SalesBlink. Two Way NextEvent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoReminders",
    "slug": "goreminders",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b12de837a084e534a97fc3003c9f404b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text and email appointment reminders for your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GoReminders Integration With Cold Email Sales Tool",
    "h1": "Integrate GoReminders with SalesBlink",
    "metaDescription": "Integrate GoReminders and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GoReminders Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elham",
    "slug": "elham",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1a46e41b9a9b1a92b3d0215345faba2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Launch your academy with all your needs for an outstanding online learning experience.",
    "category": "Education",
    "title": "Elham Integration With Cold Email Sales Tool",
    "h1": "Integrate Elham with SalesBlink",
    "metaDescription": "Integrate Elham and other Education Tools with SalesBlink. Two Way Elham Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chat Agents",
    "slug": "chat-agents",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06939fdcd92dc3be0c3e41abd2de03b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chat Agents is a true human-managed 24/7 website live chat service provider.",
    "category": "Customer Support",
    "title": "Chat Agents Integration With Cold Email Sales Tool",
    "h1": "Integrate Chat Agents with SalesBlink",
    "metaDescription": "Integrate Chat Agents and other Customer Support Tools with SalesBlink. Two Way Chat Agents Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Frontify",
    "slug": "frontify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b3886c53c8eee61d34dd40bfff750f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Frontify simplifies brand management with a platform that connects everything (and everyone) important to the growth of your brand.",
    "category": "Images & Design",
    "title": "Frontify Integration With Cold Email Sales Tool",
    "h1": "Integrate Frontify with SalesBlink",
    "metaDescription": "Integrate Frontify and other Images & Design Tools with SalesBlink. Two Way Frontify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CROSStrax",
    "slug": "crosstrax",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48d34e4a8a6fe0fe8ee14b267fb8723f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CROSStrax is case management system for investigator to organize all investigation related stuff and more within single platform.",
    "category": "Project Management",
    "title": "CROSStrax Integration With Cold Email Sales Tool",
    "h1": "Integrate CROSStrax with SalesBlink",
    "metaDescription": "Integrate CROSStrax and other Project Management Tools with SalesBlink. Two Way CROSStrax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mino CRM",
    "slug": "mino-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/507a33f93d4411bf69d12cedafe1a444.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mino CRM is a highly scalable CRM for small and medium sales teams.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mino CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Mino CRM with SalesBlink",
    "metaDescription": "Integrate Mino CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mino CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VIVAHR",
    "slug": "vivahr",
    "logo": "https://zapier-images.imgix.net/storage/developer/515f11231b16ad3fa56991f03a038096.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VIVAHR is a recruitment marketing tool to help you advertise jobs, collaborate on candidates and accelerate your company's hiring process.",
    "category": "HR Talent & Recruitment",
    "title": "VIVAHR Integration With Cold Email Sales Tool",
    "h1": "Integrate VIVAHR with SalesBlink",
    "metaDescription": "Integrate VIVAHR and other HR Talent & Recruitment Tools with SalesBlink. Two Way VIVAHR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Venue",
    "slug": "venue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad3d454084ac0e161873fe44e009ee7e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Venue makes your live streaming events memorable.",
    "category": "Event Management",
    "title": "Venue Integration With Cold Email Sales Tool",
    "h1": "Integrate Venue with SalesBlink",
    "metaDescription": "Integrate Venue and other Event Management Tools with SalesBlink. Two Way Venue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hover Lead",
    "slug": "hover-lead",
    "logo": "https://zapier-images.imgix.net/storage/developer/15e030fb8d4f210a2ef8d91e3a699f54.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hover Leads is a professional leads capture and management system for digital marketers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Hover Lead Integration With Cold Email Sales Tool",
    "h1": "Integrate Hover Lead with SalesBlink",
    "metaDescription": "Integrate Hover Lead and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Hover Lead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yatmatch",
    "slug": "yatmatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6016896e2f1a0816226f9a7f1870a478.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yatmatch is a matchmaking CRM for yacht brokers to multiply sales by eliminating business chaos.",
    "category": "Sales & CRM",
    "title": "Yatmatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Yatmatch with SalesBlink",
    "metaDescription": "Integrate Yatmatch and other Sales & CRM Tools with SalesBlink. Two Way Yatmatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rannko",
    "slug": "rannko",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84c08675fc3a3669beeb0dbcbe052598_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rannko is at the forefront of digital review & reputation management, helping business owners grow their brand and improve public perception.",
    "category": "Reviews",
    "title": "Rannko Integration With Cold Email Sales Tool",
    "h1": "Integrate Rannko with SalesBlink",
    "metaDescription": "Integrate Rannko and other Reviews Tools with SalesBlink. Two Way Rannko Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ThankView",
    "slug": "thankview",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/514dc82241542a43d21e5ab631d71eb5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ThankView is a donor management platform for creating and sharing personalized videos through email and text.",
    "category": "Marketing Automation",
    "title": "ThankView Integration With Cold Email Sales Tool",
    "h1": "Integrate ThankView with SalesBlink",
    "metaDescription": "Integrate ThankView and other Marketing Automation Tools with SalesBlink. Two Way ThankView Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myCred",
    "slug": "mycred",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1db9296533970b667add74117785ad79.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myCred is an intelligent and adaptive points management system that allows you to build and manage a broad range of digital rewards including points, ranks and, badges on your WordPress/WooCommerce powered website.",
    "category": "Productivity",
    "title": "myCred Integration With Cold Email Sales Tool",
    "h1": "Integrate myCred with SalesBlink",
    "metaDescription": "Integrate myCred and other Productivity Tools with SalesBlink. Two Way myCred Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Locate2u",
    "slug": "locate2u-stops-integration-ca155",
    "logo": "https://zapier-images.imgix.net/storage/services/9d9113a0abac38a30a298d379947e067.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Locate2u helps your service or delivery business. It helps you manage your bookings, optimizes your route, and shares your live location with your customers",
    "category": "Scheduling & Booking",
    "title": "Locate2u Integration With Cold Email Sales Tool",
    "h1": "Integrate Locate2u with SalesBlink",
    "metaDescription": "Integrate Locate2u and other Scheduling & Booking Tools with SalesBlink. Two Way Locate2u Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PPM Express",
    "slug": "ppm-express",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cbc1dad742e9cd33a3b40fb72f2345e2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PPM Express is Product Portfolio Management platform to easily integrate and orchestrate your existing projects in Microsoft Planner, Azure DevOps, Jira Software, Monday.com, and Microsoft Project Online to get a big picture view of your projects and product portfolio.",
    "category": "Product Management",
    "title": "PPM Express Integration With Cold Email Sales Tool",
    "h1": "Integrate PPM Express with SalesBlink",
    "metaDescription": "Integrate PPM Express and other Product Management Tools with SalesBlink. Two Way PPM Express Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Acute",
    "slug": "acute",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/67efb14b43b82da8abf65c258abb5766.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Acute is a customer feedback management tool that helps businesses better understand their users needs, prioritize feedback, and keep customers in the loop.",
    "category": "Product Management",
    "title": "Acute Integration With Cold Email Sales Tool",
    "h1": "Integrate Acute with SalesBlink",
    "metaDescription": "Integrate Acute and other Product Management Tools with SalesBlink. Two Way Acute Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vepple",
    "slug": "vepple",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40cb57a0f611b15100a41bcb3d1d9c65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vepple is a content hub and virtual experience platform created specifically for Higher Education student recruitment with a focus on personalisation to increase conversion and lead-capture.",
    "category": "Marketing",
    "title": "Vepple Integration With Cold Email Sales Tool",
    "h1": "Integrate Vepple with SalesBlink",
    "metaDescription": "Integrate Vepple and other Marketing Tools with SalesBlink. Two Way Vepple Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scalr",
    "slug": "scalr",
    "logo": "https://zapier-images.imgix.net/storage/developer/4a10d1a5a7031276bc44f434e3d877b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scalr Cloud Management Platform offers a unified API and web interface for developers and IT users to access and manage infrastructure resources distributed across numerous public and private clouds, such as Amazon Web Services and OpenStack.  Scalr delivers the balance required between development autonomy and IT control.",
    "category": "Server Monitoring",
    "title": "Scalr Integration With Cold Email Sales Tool",
    "h1": "Integrate Scalr with SalesBlink",
    "metaDescription": "Integrate Scalr and other Server Monitoring Tools with SalesBlink. Two Way Scalr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clozio",
    "slug": "clozio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d2dd713a7330414d93f4557965aee9c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clozio is a productivity tool for real estate professionals. Clozio offers free transaction hubs that allow professionals to create and share relevant transaction information with their clients.",
    "category": "Productivity",
    "title": "Clozio Integration With Cold Email Sales Tool",
    "h1": "Integrate Clozio with SalesBlink",
    "metaDescription": "Integrate Clozio and other Productivity Tools with SalesBlink. Two Way Clozio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendMails",
    "slug": "sendmails",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe520e619cc31bf8f510bf1f8e807399.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendMails.io is an all-in-one platform to send emails, manage contacts, capture leads and automate your marketing.",
    "category": "Drip Emails",
    "title": "SendMails Integration With Cold Email Sales Tool",
    "h1": "Integrate SendMails with SalesBlink",
    "metaDescription": "Integrate SendMails and other Drip Emails Tools with SalesBlink. Two Way SendMails Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Azendoo",
    "slug": "azendoo",
    "logo": "https://zapier-images.imgix.net/storage/developer/d8f61dc8defeabf290bcc1b7a235370b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Azendoo groups all your teamwork in one place so that you can plan, share and get organized, together.",
    "category": "Project Management",
    "title": "Azendoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Azendoo with SalesBlink",
    "metaDescription": "Integrate Azendoo and other Project Management Tools with SalesBlink. Two Way Azendoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digidom",
    "slug": "digidom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e57e0a39128e35f7123237dbed5b921f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This app is for digidom's customers and to help them always be aware of the new stuffs",
    "category": "Documents",
    "title": "Digidom Integration With Cold Email Sales Tool",
    "h1": "Integrate Digidom with SalesBlink",
    "metaDescription": "Integrate Digidom and other Documents Tools with SalesBlink. Two Way Digidom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventcube",
    "slug": "eventcube",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5586153947d4dda6f75022622f5d8b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventcube is a white label event platform for virtual and ticketed events.",
    "category": "Event Management",
    "title": "Eventcube Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventcube with SalesBlink",
    "metaDescription": "Integrate Eventcube and other Event Management Tools with SalesBlink. Two Way Eventcube Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jumper.ai",
    "slug": "jumperai",
    "logo": "https://zapier-images.imgix.net/storage/developer/9395336396ec9e7e2f3e1e0945686d75_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jumper.ai is a conversational commerce solution that turns digital marketing efforts into direct point of sales.",
    "category": "eCommerce",
    "title": "Jumper.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Jumper.ai with SalesBlink",
    "metaDescription": "Integrate Jumper.ai and other eCommerce Tools with SalesBlink. Two Way Jumper.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WooRank",
    "slug": "woorank",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0d038e8f3763da40f9e96f33b262ebd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WooRank is an easy-to-use SEO tools that will help you optimize your online presence, grow your business and generate more leads.",
    "category": "Marketing",
    "title": "WooRank Integration With Cold Email Sales Tool",
    "h1": "Integrate WooRank with SalesBlink",
    "metaDescription": "Integrate WooRank and other Marketing Tools with SalesBlink. Two Way WooRank Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Handle",
    "slug": "handle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/102f7ba14a1276707578cefa3a2d2411.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build better customer relations with Handle chatbot.",
    "category": "Marketing Automation",
    "title": "Handle Integration With Cold Email Sales Tool",
    "h1": "Integrate Handle with SalesBlink",
    "metaDescription": "Integrate Handle and other Marketing Automation Tools with SalesBlink. Two Way Handle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Engage by Cell Pass",
    "slug": "engage-by-cell-pass",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc100cdbbb60a6c7e12b8852d869f135_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engage by Cell Pass is a text messaging and mobile communications platform designed to engage your audience wherever they are.",
    "category": "Marketing",
    "title": "Engage by Cell Pass Integration With Cold Email Sales Tool",
    "h1": "Integrate Engage by Cell Pass with SalesBlink",
    "metaDescription": "Integrate Engage by Cell Pass and other Marketing Tools with SalesBlink. Two Way Engage by Cell Pass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "K12 Insight Engage",
    "slug": "k12-insight-engage",
    "logo": "https://zapier-images.imgix.net/storage/developer/6b548ef35260fff0ebfa4edd2dd7711c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "K12 Insight Engage allows users to create, distribute, and analyze professional surveys, polls, and assessments.",
    "category": "Forms & Surveys",
    "title": "K12 Insight Engage Integration With Cold Email Sales Tool",
    "h1": "Integrate K12 Insight Engage with SalesBlink",
    "metaDescription": "Integrate K12 Insight Engage and other Forms & Surveys Tools with SalesBlink. Two Way K12 Insight Engage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docage",
    "slug": "docage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05441ebdf4dea676c8cf4a1d866cf482_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docage is an electronic signature platform and fully eIDAS compliant.",
    "category": "Signatures",
    "title": "Docage Integration With Cold Email Sales Tool",
    "h1": "Integrate Docage with SalesBlink",
    "metaDescription": "Integrate Docage and other Signatures Tools with SalesBlink. Two Way Docage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WodBuster",
    "slug": "wodbuster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd7474b5d43d365408db00fc6fb63203_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WodBuster is a comprehensive management platform for gyms. Management + Reservations for your gym.\n\nWodBuster, es una plataforma de gestión integral para gimnasios. Gestión + Reservas para tu gym",
    "category": "Product Management",
    "title": "WodBuster Integration With Cold Email Sales Tool",
    "h1": "Integrate WodBuster with SalesBlink",
    "metaDescription": "Integrate WodBuster and other Product Management Tools with SalesBlink. Two Way WodBuster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StayFi",
    "slug": "stayfi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/113f4066a58bef41d85728fee16d7c50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect names, emails, and more from every guest with the leading provider of WiFi Marketing for short-term rentals.",
    "category": "Marketing",
    "title": "StayFi Integration With Cold Email Sales Tool",
    "h1": "Integrate StayFi with SalesBlink",
    "metaDescription": "Integrate StayFi and other Marketing Tools with SalesBlink. Two Way StayFi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Osarh",
    "slug": "osarh",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9b884c777a6ee2ec7eba843b6a35145.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Osarh pro is a platform that enables trainers and institutions to establish, manage and operate their academy without any technical hassles.",
    "category": "Online Courses",
    "title": "Osarh Integration With Cold Email Sales Tool",
    "h1": "Integrate Osarh with SalesBlink",
    "metaDescription": "Integrate Osarh and other Online Courses Tools with SalesBlink. Two Way Osarh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpHabit",
    "slug": "uphabit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b775e06004252a256a40e02ad45c4ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpHabit is a Personal CRM available on mobile and web. It has advanced reminders, notes and tags, along with deep integrations with address books, calendars and email platforms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "UpHabit Integration With Cold Email Sales Tool",
    "h1": "Integrate UpHabit with SalesBlink",
    "metaDescription": "Integrate UpHabit and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way UpHabit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "membermate",
    "slug": "membermate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2344b033d709d90445c246435eaac424.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "membermate is a platform to easily set up your own member area, including courses, memberships, community, live calls and more.",
    "category": "Online Courses",
    "title": "membermate Integration With Cold Email Sales Tool",
    "h1": "Integrate membermate with SalesBlink",
    "metaDescription": "Integrate membermate and other Online Courses Tools with SalesBlink. Two Way membermate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saleshandy",
    "slug": "saleshandy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d020518bdc4c0ce2f80ca7dfefe816a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Saleshandy, businesses send personalized emails and automated follow-ups on a large scale to generate qualified leads and drive increased sales.",
    "category": "Sales & CRM",
    "title": "Saleshandy Integration With Cold Email Sales Tool",
    "h1": "Integrate Saleshandy with SalesBlink",
    "metaDescription": "Integrate Saleshandy and other Sales & CRM Tools with SalesBlink. Two Way Saleshandy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "iconpractice",
    "slug": "iconpractice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25bc690a84722a8f186276c8b43e9224_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Software for your chiro, osteo or myotherapy clinic. iconpractice is a full featured cloud based solution",
    "category": "Scheduling & Booking",
    "title": "iconpractice Integration With Cold Email Sales Tool",
    "h1": "Integrate iconpractice with SalesBlink",
    "metaDescription": "Integrate iconpractice and other Scheduling & Booking Tools with SalesBlink. Two Way iconpractice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zeymo",
    "slug": "zeymo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5909ec5034bc2ceab53cf971ead5736.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zeymo helps small and medium manufacturers efficiently run their business.",
    "category": "Accounting",
    "title": "Zeymo Integration With Cold Email Sales Tool",
    "h1": "Integrate Zeymo with SalesBlink",
    "metaDescription": "Integrate Zeymo and other Accounting Tools with SalesBlink. Two Way Zeymo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workflow86",
    "slug": "workflow86",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/556abc4f9b04748e749e2c7eedc0a352_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workflow86 is an all-in-one workflow automation platform for complex and mission-critical business operations.",
    "category": "App Builder",
    "title": "Workflow86 Integration With Cold Email Sales Tool",
    "h1": "Integrate Workflow86 with SalesBlink",
    "metaDescription": "Integrate Workflow86 and other App Builder Tools with SalesBlink. Two Way Workflow86 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AddressFinder Australia",
    "slug": "addressfinder-australia",
    "logo": "https://zapier-images.imgix.net/storage/developer/d9e8c17c8ab2f0e71fc63647b8abb4dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy tool to validate human-entered addresses and return a full verified address. Responses come with \nfull meta data, including GPS coordinates.",
    "category": "Contact Management",
    "title": "AddressFinder Australia Integration With Cold Email Sales Tool",
    "h1": "Integrate AddressFinder Australia with SalesBlink",
    "metaDescription": "Integrate AddressFinder Australia and other Contact Management Tools with SalesBlink. Two Way AddressFinder Australia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KeyNest",
    "slug": "keynest-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf993d6d9327b3fd7472a7049855e00c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KeyNest is a simple and secure self-check-in solution for Airbnb hosts, serviced apartment operators and estate agents.",
    "category": "Productivity",
    "title": "KeyNest Integration With Cold Email Sales Tool",
    "h1": "Integrate KeyNest with SalesBlink",
    "metaDescription": "Integrate KeyNest and other Productivity Tools with SalesBlink. Two Way KeyNest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Edisen",
    "slug": "edisen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/740f96668749fe8ce37381d5f315eb65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Edisen is a platform that allows marketing teams to store and create new assets and plans.",
    "category": "Video & Audio",
    "title": "Edisen Integration With Cold Email Sales Tool",
    "h1": "Integrate Edisen with SalesBlink",
    "metaDescription": "Integrate Edisen and other Video & Audio Tools with SalesBlink. Two Way Edisen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CustomerLabs",
    "slug": "customerlabs",
    "logo": "https://zapier-images.imgix.net/storage/developer/7d7d653a5486a7c305b38be169f0f69d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CustomerLabs Action Recorder helps you track website without writing code and unify customer data across all marketing tools.",
    "category": "Marketing Automation",
    "title": "CustomerLabs Integration With Cold Email Sales Tool",
    "h1": "Integrate CustomerLabs with SalesBlink",
    "metaDescription": "Integrate CustomerLabs and other Marketing Automation Tools with SalesBlink. Two Way CustomerLabs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ninetailed",
    "slug": "ninetailed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42de0d851ef6db233d9b11cb40468a46_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "API-first personalization and experimentation solution that gives digital builders radical freedom to create instant experiences.",
    "category": "Marketing Automation",
    "title": "Ninetailed Integration With Cold Email Sales Tool",
    "h1": "Integrate Ninetailed with SalesBlink",
    "metaDescription": "Integrate Ninetailed and other Marketing Automation Tools with SalesBlink. Two Way Ninetailed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TASKER",
    "slug": "tasker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23e41e8895eca553e0a9b70e28fcb6e5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TASKER is a flexible and effective tool for managing tasks, processes and employees.",
    "category": "Productivity",
    "title": "TASKER Integration With Cold Email Sales Tool",
    "h1": "Integrate TASKER with SalesBlink",
    "metaDescription": "Integrate TASKER and other Productivity Tools with SalesBlink. Two Way TASKER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WowTo",
    "slug": "wowto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef6214994356784911af9af20ea327c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily create automated How-to videos with WowTo for support, training and education purposes.",
    "category": "Video & Audio",
    "title": "WowTo Integration With Cold Email Sales Tool",
    "h1": "Integrate WowTo with SalesBlink",
    "metaDescription": "Integrate WowTo and other Video & Audio Tools with SalesBlink. Two Way WowTo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cool Tabs",
    "slug": "cool-tabs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a126e2f98f8d0f2532490a4a4973818.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cool Tabs is a comprehensive Social Media Marketing platform designed to help users increase their online audience and get qualified leads by creating simple interactive content",
    "category": "Forms & Surveys",
    "title": "Cool Tabs Integration With Cold Email Sales Tool",
    "h1": "Integrate Cool Tabs with SalesBlink",
    "metaDescription": "Integrate Cool Tabs and other Forms & Surveys Tools with SalesBlink. Two Way Cool Tabs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PHOTO iD by U Scope",
    "slug": "photo-id",
    "logo": "https://zapier-images.imgix.net/storage/services/e3d5f927fb082c56558021d7d3080d7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PHOTO iD is a photo management documentation platform that labels & organizes inspection images into detailed photo reports instantly.",
    "category": "File Management & Storage",
    "title": "PHOTO iD by U Scope Integration With Cold Email Sales Tool",
    "h1": "Integrate PHOTO iD by U Scope with SalesBlink",
    "metaDescription": "Integrate PHOTO iD by U Scope and other File Management & Storage Tools with SalesBlink. Two Way PHOTO iD by U Scope Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swirl",
    "slug": "swirl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/006cacc09a4863fa2f66414fc33012ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Community first platform for coaches. Smoothly host sessions, courses, communities and memberships — all in one place.",
    "category": "Communication",
    "title": "Swirl Integration With Cold Email Sales Tool",
    "h1": "Integrate Swirl with SalesBlink",
    "metaDescription": "Integrate Swirl and other Communication Tools with SalesBlink. Two Way Swirl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Introwise",
    "slug": "introwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/da2ef00a81c5370ec0e1ca4908892b93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Introwise is an all-in-one business platform with scheduling, payments, video calls, no-code booking page builder and a lightweight CRM.",
    "category": "Scheduling & Booking",
    "title": "Introwise Integration With Cold Email Sales Tool",
    "h1": "Integrate Introwise with SalesBlink",
    "metaDescription": "Integrate Introwise and other Scheduling & Booking Tools with SalesBlink. Two Way Introwise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HitSend",
    "slug": "hitsend",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c7d6204488db872fd2cb19884d2ed86b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HitSend is an audio review and collaboration platform that consolidates file sharing, feedback, and payments to one secure, centralized hub.",
    "category": "Project Management",
    "title": "HitSend Integration With Cold Email Sales Tool",
    "h1": "Integrate HitSend with SalesBlink",
    "metaDescription": "Integrate HitSend and other Project Management Tools with SalesBlink. Two Way HitSend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PushEngage",
    "slug": "pushengage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/52a058b8ad79be61c3dde4bcbff8a963_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PushEngage is a web push notification platform that delivers targeted & personalized messages to increase engagement & retention.",
    "category": "Notifications",
    "title": "PushEngage Integration With Cold Email Sales Tool",
    "h1": "Integrate PushEngage with SalesBlink",
    "metaDescription": "Integrate PushEngage and other Notifications Tools with SalesBlink. Two Way PushEngage Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Documentero",
    "slug": "documentero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1c236b62e6a8fb1c3112a13792c4880.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Documentero is a cloud service that can generate simple and advanced Word or PDF documents based on .docx templates.",
    "category": "Documents",
    "title": "Documentero Integration With Cold Email Sales Tool",
    "h1": "Integrate Documentero with SalesBlink",
    "metaDescription": "Integrate Documentero and other Documents Tools with SalesBlink. Two Way Documentero Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SkylerAI",
    "slug": "skyler360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/050b91c812da81e6ba3bbbd503ef3c43.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SkylerAI is a conversational AI platform",
    "category": "Developer Tools",
    "title": "SkylerAI Integration With Cold Email Sales Tool",
    "h1": "Integrate SkylerAI with SalesBlink",
    "metaDescription": "Integrate SkylerAI and other Developer Tools Tools with SalesBlink. Two Way SkylerAI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Walls.io",
    "slug": "wallsio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b0f87b5a5d7db8d08b9f3bed7a95e6f6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Walls.io allows you to connect your social wall with hundreds of other applications easily and with out any code.",
    "category": "Social Media Marketing",
    "title": "Walls.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Walls.io with SalesBlink",
    "metaDescription": "Integrate Walls.io and other Social Media Marketing Tools with SalesBlink. Two Way Walls.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Famewall",
    "slug": "famewall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f2c083b08a730e4a448e2cea124ac4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Famewall makes it easy to collect testimonials from customers & add them in your websites in a few minutes without writing any code.",
    "category": "Marketing",
    "title": "Famewall Integration With Cold Email Sales Tool",
    "h1": "Integrate Famewall with SalesBlink",
    "metaDescription": "Integrate Famewall and other Marketing Tools with SalesBlink. Two Way Famewall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dispute Panda",
    "slug": "dispute-panda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51386e5627e0c3723b4b8d103b2866d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DisputePanda is an AI-powered credit repair engine that will deliver a whole new experience and revolutionize the way you service your clients.",
    "category": "Business Intelligence",
    "title": "Dispute Panda Integration With Cold Email Sales Tool",
    "h1": "Integrate Dispute Panda with SalesBlink",
    "metaDescription": "Integrate Dispute Panda and other Business Intelligence Tools with SalesBlink. Two Way Dispute Panda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Isofy",
    "slug": "isofy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9131df644d2ff14bd6b905d3bd2561ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Isofy is a network management platform that allows you to control your co-working space's network infrastructure.",
    "category": "Dashboards",
    "title": "Isofy Integration With Cold Email Sales Tool",
    "h1": "Integrate Isofy with SalesBlink",
    "metaDescription": "Integrate Isofy and other Dashboards Tools with SalesBlink. Two Way Isofy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ALIS",
    "slug": "alis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/152950cc7c7f0ffa647ed04e11762f3f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ALIS is a comprehensive suite of eHR tools purpose-built for assisted living communities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ALIS Integration With Cold Email Sales Tool",
    "h1": "Integrate ALIS with SalesBlink",
    "metaDescription": "Integrate ALIS and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ALIS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LegalSite",
    "slug": "legalsite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1639f46ffd9359bafe129852f1015990.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LegalSite is a drop in legal & privacy department for your website. Protected against legal risks, privacy compliant and always up-to-date with the latest regulatory developments.",
    "category": "",
    "title": "LegalSite Integration With Cold Email Sales Tool",
    "h1": "Integrate LegalSite with SalesBlink",
    "metaDescription": "Integrate LegalSite and other  Tools with SalesBlink. Two Way LegalSite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Funecy",
    "slug": "funecy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f2c1c9451ec4e7303a45c64369e2c02d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funecy is a system for creating and implementing marketing tools in websites and sales funnels.",
    "category": "Marketing",
    "title": "Funecy Integration With Cold Email Sales Tool",
    "h1": "Integrate Funecy with SalesBlink",
    "metaDescription": "Integrate Funecy and other Marketing Tools with SalesBlink. Two Way Funecy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Writer",
    "slug": "writer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/66ee50eb37130a159fe5cfcf8c3e0fc5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Writer is a generative AI platform built for the needs of companies and teams.",
    "category": "AI Tools",
    "title": "Writer Integration With Cold Email Sales Tool",
    "h1": "Integrate Writer with SalesBlink",
    "metaDescription": "Integrate Writer and other AI Tools Tools with SalesBlink. Two Way Writer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BIGContacts",
    "slug": "bigcontacts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7d653474f99d7b29c8cfe95b654b966.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BIGContacts by ProProfs is designed for teams to grow their business through improved contact management. Sort and view contacts by pending or upcoming activity, opportunity, last contact, or last communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BIGContacts Integration With Cold Email Sales Tool",
    "h1": "Integrate BIGContacts with SalesBlink",
    "metaDescription": "Integrate BIGContacts and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BIGContacts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EventMobi",
    "slug": "eventmobi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4f5ddd6566595a930637577354484d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EventMobi’s event management platform has been trusted by associations, agencies and corporations to deliver engaging professional events for 12+ years",
    "category": "Event Management",
    "title": "EventMobi Integration With Cold Email Sales Tool",
    "h1": "Integrate EventMobi with SalesBlink",
    "metaDescription": "Integrate EventMobi and other Event Management Tools with SalesBlink. Two Way EventMobi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qualified.io",
    "slug": "qualifiedio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d807d565fda89c7c1d5e63389a9ea9df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qualified helps you identify the best technical candidates faster and better with Code Assessments and Pair-Programming Interviews",
    "category": "HR Talent & Recruitment",
    "title": "Qualified.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Qualified.io with SalesBlink",
    "metaDescription": "Integrate Qualified.io and other HR Talent & Recruitment Tools with SalesBlink. Two Way Qualified.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anchor",
    "slug": "anchor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e1e550d41c1d74c3e29e60b1ff5b5b9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get freedom from the burden of invoicing and collections. Anchor is a platform to make your billing cycle flow. Autonomously.",
    "category": "Proposal & Invoice Management",
    "title": "Anchor Integration With Cold Email Sales Tool",
    "h1": "Integrate Anchor with SalesBlink",
    "metaDescription": "Integrate Anchor and other Proposal & Invoice Management Tools with SalesBlink. Two Way Anchor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gravitec.net",
    "slug": "gravitecnet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe22bffd7e512384af17352714bf8d2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gravitec.net offers the widest range of push notification services throughout the world!",
    "category": "Notifications",
    "title": "Gravitec.net Integration With Cold Email Sales Tool",
    "h1": "Integrate Gravitec.net with SalesBlink",
    "metaDescription": "Integrate Gravitec.net and other Notifications Tools with SalesBlink. Two Way Gravitec.net Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EarlyBird",
    "slug": "earlybird",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/337d52e41e608e14f5c053569213c068.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EarlyBird (https://earlybird.im) is a no-code landing page builder for early-stage startups to pitch and validate business ideas fast.",
    "category": "Website Builders",
    "title": "EarlyBird Integration With Cold Email Sales Tool",
    "h1": "Integrate EarlyBird with SalesBlink",
    "metaDescription": "Integrate EarlyBird and other Website Builders Tools with SalesBlink. Two Way EarlyBird Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hously",
    "slug": "hously",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9736fee0bef809801e533120281ec8a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hously is a Real Estate CRM designed by agents, for agents. Simplify and scale by adding automation to your business.",
    "category": "Productivity",
    "title": "Hously Integration With Cold Email Sales Tool",
    "h1": "Integrate Hously with SalesBlink",
    "metaDescription": "Integrate Hously and other Productivity Tools with SalesBlink. Two Way Hously Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMMware",
    "slug": "smmware",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aef0a4ee1e7ee0aeb4e3fa180b645ee3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMMware helps small to medium businesses with part time employees, with scheduling plus time entry, invoicing, tasks, files and more.",
    "category": "Time Tracking Software",
    "title": "SMMware Integration With Cold Email Sales Tool",
    "h1": "Integrate SMMware with SalesBlink",
    "metaDescription": "Integrate SMMware and other Time Tracking Software Tools with SalesBlink. Two Way SMMware Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoiceSpin",
    "slug": "voicespin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b18003cd92397b56cfcc42b40715b86b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoiceSpin is a sales-amplifying communication platform, built to improve contact centers' operational efficiency. Offering voice, omni-channel messaging, & more.",
    "category": "Phone & SMS",
    "title": "VoiceSpin Integration With Cold Email Sales Tool",
    "h1": "Integrate VoiceSpin with SalesBlink",
    "metaDescription": "Integrate VoiceSpin and other Phone & SMS Tools with SalesBlink. Two Way VoiceSpin Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Optimizely Campaign",
    "slug": "optimizely-campaign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a60259734392f4082868976a9a654597_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Optimizely Campaign is an omnichannel marketing software that allows users to create, send and report on targeted content.",
    "category": "Email Newsletters",
    "title": "Optimizely Campaign Integration With Cold Email Sales Tool",
    "h1": "Integrate Optimizely Campaign with SalesBlink",
    "metaDescription": "Integrate Optimizely Campaign and other Email Newsletters Tools with SalesBlink. Two Way Optimizely Campaign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sozuri  (Kenya) SMS",
    "slug": "sozuri-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c71d4fab1c0e26912cce5d073bd6d2d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sozuri is an innovative and affordable Omnichannel (SMS and WhatsApp) platform in Kenya suited for businesses and individuals to connect with customers.",
    "category": "Phone & SMS",
    "title": "Sozuri  (Kenya) SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Sozuri  (Kenya) SMS with SalesBlink",
    "metaDescription": "Integrate Sozuri  (Kenya) SMS and other Phone & SMS Tools with SalesBlink. Two Way Sozuri  (Kenya) SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voxloud",
    "slug": "voxloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c70d71fd3898630fe48b943a64b82cd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Voxloud is a call tracking system where you can get your cloud phone system in just 59 seconds.",
    "category": "Call Tracking",
    "title": "Voxloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Voxloud with SalesBlink",
    "metaDescription": "Integrate Voxloud and other Call Tracking Tools with SalesBlink. Two Way Voxloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Letterdrop",
    "slug": "letterdrop",
    "logo": "https://zapier-images.imgix.net/storage/services/a21d816f19d29c87884030728343eb46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Content marketing automation for SEO, social media, and email newsletters.",
    "category": "Marketing Automation",
    "title": "Letterdrop Integration With Cold Email Sales Tool",
    "h1": "Integrate Letterdrop with SalesBlink",
    "metaDescription": "Integrate Letterdrop and other Marketing Automation Tools with SalesBlink. Two Way Letterdrop Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SIGNL4",
    "slug": "signl4",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0afdd82d062ca608d7f12daa29820e50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SIGNL4 is a mobile alert notification app with tracking and escalation to reliably inform teams about alerts and incidents.",
    "category": "Notifications",
    "title": "SIGNL4 Integration With Cold Email Sales Tool",
    "h1": "Integrate SIGNL4 with SalesBlink",
    "metaDescription": "Integrate SIGNL4 and other Notifications Tools with SalesBlink. Two Way SIGNL4 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "EnvíaloSimple by DonWeb",
    "slug": "envialosimple",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e10fdeaadfd7d77d0a4d2f93c420cb0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EnvíaloSimple is the DonWeb's Email Marketing service.",
    "category": "Marketing Automation",
    "title": "EnvíaloSimple by DonWeb Integration With Cold Email Sales Tool",
    "h1": "Integrate EnvíaloSimple by DonWeb with SalesBlink",
    "metaDescription": "Integrate EnvíaloSimple by DonWeb and other Marketing Automation Tools with SalesBlink. Two Way EnvíaloSimple by DonWeb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RallyUp",
    "slug": "rallyup",
    "logo": "https://zapier-images.imgix.net/storage/services/4241a257bfecc962f1f724c87f7c727f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RallyUp.com provides nonprofit organizations with their own branded platform for launching amazing virtual fundraising experiences.",
    "category": "Fundraising",
    "title": "RallyUp Integration With Cold Email Sales Tool",
    "h1": "Integrate RallyUp with SalesBlink",
    "metaDescription": "Integrate RallyUp and other Fundraising Tools with SalesBlink. Two Way RallyUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Visual Quiz Builder",
    "slug": "visual-quiz-builder",
    "logo": "https://zapier-images.imgix.net/storage/services/9a0a5d41ad6e1d9d4668db7a15ddb74a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Visual Quiz Builder is a tool to create custom quizzes for your online store, engage visitors, and increase conversion and revenue. Integrates with Shopify and other eCommerce platforms to sync quiz data to your company's analytics and visual dashboard tools.",
    "category": "Sales & CRM",
    "title": "Visual Quiz Builder Integration With Cold Email Sales Tool",
    "h1": "Integrate Visual Quiz Builder with SalesBlink",
    "metaDescription": "Integrate Visual Quiz Builder and other Sales & CRM Tools with SalesBlink. Two Way Visual Quiz Builder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Builderly",
    "slug": "builderly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a03211d30ba00205efca596dfc2757ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Builderly by max-media.io is a platform that allows users with no coding skills, to build and manage a functional Website and native iOS and Android app.",
    "category": "App Builder",
    "title": "Builderly Integration With Cold Email Sales Tool",
    "h1": "Integrate Builderly with SalesBlink",
    "metaDescription": "Integrate Builderly and other App Builder Tools with SalesBlink. Two Way Builderly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Documently",
    "slug": "documently",
    "logo": "https://zapier-images.imgix.net/storage/services/23b046abb9a1344e0507a6e6e0bcfbc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Documently makes it easy to collect documents using industry-specific templates. Entire Document collection lifecycle in one place.",
    "category": "Documents",
    "title": "Documently Integration With Cold Email Sales Tool",
    "h1": "Integrate Documently with SalesBlink",
    "metaDescription": "Integrate Documently and other Documents Tools with SalesBlink. Two Way Documently Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PhotoRoom",
    "slug": "photoroom",
    "logo": "https://zapier-images.imgix.net/storage/services/b0bc6add3473b7fd067350e9fcc24066.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PhotoRoom automatically removes the background from your images.",
    "category": "Images & Design",
    "title": "PhotoRoom Integration With Cold Email Sales Tool",
    "h1": "Integrate PhotoRoom with SalesBlink",
    "metaDescription": "Integrate PhotoRoom and other Images & Design Tools with SalesBlink. Two Way PhotoRoom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventdex",
    "slug": "eventdex-da96272",
    "logo": "https://zapier-images.imgix.net/storage/developer/47a064fc250fb9c42547bb7659054b82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "At Eventdex we provide a cloud platform and mobile apps for Business to Business events.",
    "category": "Event Management",
    "title": "Eventdex Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventdex with SalesBlink",
    "metaDescription": "Integrate Eventdex and other Event Management Tools with SalesBlink. Two Way Eventdex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kontainers",
    "slug": "kontainers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e4e27e09ae7b3142c1360dd963b73f6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "kontainers is an end to end SaaS logistics platform. Reduce operation cost and increase customer satisfaction through innovative technology.",
    "category": "",
    "title": "Kontainers Integration With Cold Email Sales Tool",
    "h1": "Integrate Kontainers with SalesBlink",
    "metaDescription": "Integrate Kontainers and other  Tools with SalesBlink. Two Way Kontainers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beakid.com",
    "slug": "beakidcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3ac1ea1d9e1032261a7a3ec31973109.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BeAKid is a back-office solution for after-school and summer providers. Manage registrations, payments, marketing and back office operations.",
    "category": "Scheduling & Booking",
    "title": "Beakid.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Beakid.com with SalesBlink",
    "metaDescription": "Integrate Beakid.com and other Scheduling & Booking Tools with SalesBlink. Two Way Beakid.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kaydoh",
    "slug": "kaydoh",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84ad87281a9f5a32bf6f15b2acaaa64d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kaydoh provides instant notification when leads are captured on Kaydoh pages",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kaydoh Integration With Cold Email Sales Tool",
    "h1": "Integrate Kaydoh with SalesBlink",
    "metaDescription": "Integrate Kaydoh and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kaydoh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rulebricks",
    "slug": "rulebricks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fefba66a85739a054554cd84e946e3fe_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rulebricks is the easiest way to add powerful decision-making capabilities to any automated process.",
    "category": "",
    "title": "Rulebricks Integration With Cold Email Sales Tool",
    "h1": "Integrate Rulebricks with SalesBlink",
    "metaDescription": "Integrate Rulebricks and other  Tools with SalesBlink. Two Way Rulebricks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Humand",
    "slug": "humand",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c43bb1b37032613c313e1627d2cda82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Humand is a digital community for your organization to enhance relationships, engagement, collaboration and productivity.",
    "category": "Human Resources",
    "title": "Humand Integration With Cold Email Sales Tool",
    "h1": "Integrate Humand with SalesBlink",
    "metaDescription": "Integrate Humand and other Human Resources Tools with SalesBlink. Two Way Humand Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sapling Spelling and Grammar Checker",
    "slug": "sapling-spelling-and-grammar-che",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8e62b1d1c1aaf120888ecfe176dedf95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sapling's grammar and spelling checker provides best-in-class suggestions to reduce errors and improve messaging and document quality.",
    "category": "Communication",
    "title": "Sapling Spelling and Grammar Checker Integration With Cold Email Sales Tool",
    "h1": "Integrate Sapling Spelling and Grammar Checker with SalesBlink",
    "metaDescription": "Integrate Sapling Spelling and Grammar Checker and other Communication Tools with SalesBlink. Two Way Sapling Spelling and Grammar Checker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upodi",
    "slug": "upodi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d3d361ddf9459df0db31ef9ebf75f1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upodi is a subscription and billing management platform.",
    "category": "Payment Processing",
    "title": "Upodi Integration With Cold Email Sales Tool",
    "h1": "Integrate Upodi with SalesBlink",
    "metaDescription": "Integrate Upodi and other Payment Processing Tools with SalesBlink. Two Way Upodi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Symplify Communication",
    "slug": "symplify-communication",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f4e8b852ee18c6fcd83e54bff6bb4e15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Symplify is an omnichannel marketing automation platform that helps marketers leverage their data in their digital 1:1 communications.",
    "category": "Marketing Automation",
    "title": "Symplify Communication Integration With Cold Email Sales Tool",
    "h1": "Integrate Symplify Communication with SalesBlink",
    "metaDescription": "Integrate Symplify Communication and other Marketing Automation Tools with SalesBlink. Two Way Symplify Communication Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Accord",
    "slug": "accord",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a2a1e4f3c094b777cde330c70a59cda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Accord is a customer collaboration platform that connects sellers and buyers.",
    "category": "Sales & CRM",
    "title": "Accord Integration With Cold Email Sales Tool",
    "h1": "Integrate Accord with SalesBlink",
    "metaDescription": "Integrate Accord and other Sales & CRM Tools with SalesBlink. Two Way Accord Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BackNine Insurance",
    "slug": "backnine-insurance",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad475b6e38138f6bea2e4270551e7dce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BackNine is a tech enabled general agency geared towards selling life insurance that enables its users with a free to use CRM and online quoting software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BackNine Insurance Integration With Cold Email Sales Tool",
    "h1": "Integrate BackNine Insurance with SalesBlink",
    "metaDescription": "Integrate BackNine Insurance and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BackNine Insurance Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatPay",
    "slug": "chatpay-ca141500",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62ad87a655c7c07e28e6c9f1e45192da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatPay is a platform for sharing knowledge and creating digital communities.",
    "category": "Payment Processing",
    "title": "ChatPay Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatPay with SalesBlink",
    "metaDescription": "Integrate ChatPay and other Payment Processing Tools with SalesBlink. Two Way ChatPay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Altiria",
    "slug": "altiria",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4d1237811832c8e6ddbf8e930c1db7fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Altiria is a text messaging platform for sending SMS globally. Great for transactional SMS: alerts, notifications and automation systems.",
    "category": "Phone & SMS",
    "title": "Altiria Integration With Cold Email Sales Tool",
    "h1": "Integrate Altiria with SalesBlink",
    "metaDescription": "Integrate Altiria and other Phone & SMS Tools with SalesBlink. Two Way Altiria Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CourseSight",
    "slug": "coursesight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/159c334019d5d85618d55780d891aad7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CourseSight is a course booking and training management system designed to help training providers manage the sales and delivery of the courses they offer.",
    "category": "Event Management",
    "title": "CourseSight Integration With Cold Email Sales Tool",
    "h1": "Integrate CourseSight with SalesBlink",
    "metaDescription": "Integrate CourseSight and other Event Management Tools with SalesBlink. Two Way CourseSight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mindmesh",
    "slug": "mindmesh",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43899bc7b9fdfdf5d7c400475eb66c5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mindmesh is the virtual Desk which helps you stay on top of your day.",
    "category": "Productivity",
    "title": "Mindmesh Integration With Cold Email Sales Tool",
    "h1": "Integrate Mindmesh with SalesBlink",
    "metaDescription": "Integrate Mindmesh and other Productivity Tools with SalesBlink. Two Way Mindmesh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProSMS.se",
    "slug": "prosmsse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1054c29bcc45b3685c65635bb712506.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProSMS is an online SMS gateway for easy communication with your customers.",
    "category": "Phone & SMS",
    "title": "ProSMS.se Integration With Cold Email Sales Tool",
    "h1": "Integrate ProSMS.se with SalesBlink",
    "metaDescription": "Integrate ProSMS.se and other Phone & SMS Tools with SalesBlink. Two Way ProSMS.se Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RouteLogic",
    "slug": "routelogic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9392b592711f5170a33a4656f3a5409f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RouteLogic helps you make the most efficient routes and provides everything you need for a seamless customer experience.",
    "category": "eCommerce",
    "title": "RouteLogic Integration With Cold Email Sales Tool",
    "h1": "Integrate RouteLogic with SalesBlink",
    "metaDescription": "Integrate RouteLogic and other eCommerce Tools with SalesBlink. Two Way RouteLogic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "My AskAI",
    "slug": "my-askai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8e92e84230dcdc1d999245fc87d11e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "My AskAI allows anyone to build a ChatGPT-style AI assistant that can answer any question on the content you upload.",
    "category": "AI Tools",
    "title": "My AskAI Integration With Cold Email Sales Tool",
    "h1": "Integrate My AskAI with SalesBlink",
    "metaDescription": "Integrate My AskAI and other AI Tools Tools with SalesBlink. Two Way My AskAI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Campayn",
    "slug": "campayn",
    "logo": "https://zapier-images.imgix.net/storage/developer/2835fb01e3d2cc35368eece4ba77d5d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Campayn - Amazingly simple email newsletters.",
    "category": "Email Newsletters",
    "title": "Campayn Integration With Cold Email Sales Tool",
    "h1": "Integrate Campayn with SalesBlink",
    "metaDescription": "Integrate Campayn and other Email Newsletters Tools with SalesBlink. Two Way Campayn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Signalize",
    "slug": "signalize",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/78f9ddc78f44accc8b70d693db53e0cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send push notifications to your subscribers",
    "category": "Marketing Automation",
    "title": "Signalize Integration With Cold Email Sales Tool",
    "h1": "Integrate Signalize with SalesBlink",
    "metaDescription": "Integrate Signalize and other Marketing Automation Tools with SalesBlink. Two Way Signalize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KleverKey",
    "slug": "kleverkey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/36628ec4f9d3232789ad3af265c4d2ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KleverKey is an award-winning and cloud based access management solution containing professional wireless smart locks to simplify access control for SMB, Coworking and others.",
    "category": "Internet of Things",
    "title": "KleverKey Integration With Cold Email Sales Tool",
    "h1": "Integrate KleverKey with SalesBlink",
    "metaDescription": "Integrate KleverKey and other Internet of Things Tools with SalesBlink. Two Way KleverKey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoDial",
    "slug": "godial",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e909ec5bd4aa1d26ac0b2c708fb5f149.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoDial is a CRM and Auto Dialer for your phone.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GoDial Integration With Cold Email Sales Tool",
    "h1": "Integrate GoDial with SalesBlink",
    "metaDescription": "Integrate GoDial and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GoDial Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "LogicGate",
    "slug": "logicgate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc687e26384fea4612b9788087f1c016.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LogicGate is a governance, risk, and compliance (GRC) automation platform that allows organizations to standardize and improve risk and compliance programs.",
    "category": "Productivity",
    "title": "LogicGate Integration With Cold Email Sales Tool",
    "h1": "Integrate LogicGate with SalesBlink",
    "metaDescription": "Integrate LogicGate and other Productivity Tools with SalesBlink. Two Way LogicGate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Konnect Insights",
    "slug": "konnect-insights",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/716bb5d265c7420140d8725316e9b46f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Konnect Insights is a social listening and analytics platform that gives you data from social platforms and web. Data is fetched based on keywords and social profiles. Get the power of social Listening with this integration.",
    "category": "Social Media Accounts",
    "title": "Konnect Insights Integration With Cold Email Sales Tool",
    "h1": "Integrate Konnect Insights with SalesBlink",
    "metaDescription": "Integrate Konnect Insights and other Social Media Accounts Tools with SalesBlink. Two Way Konnect Insights Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FIN Compliance",
    "slug": "fin-compliance",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/88daf1d823ce3b8ad42331ac63d8e366.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FIN Compliance is a consortium of compliance, consulting, and business management solutions to help investment firms to structure, maintain, and develop their regulatory review programs.",
    "category": "Project Management",
    "title": "FIN Compliance Integration With Cold Email Sales Tool",
    "h1": "Integrate FIN Compliance with SalesBlink",
    "metaDescription": "Integrate FIN Compliance and other Project Management Tools with SalesBlink. Two Way FIN Compliance Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Picsart",
    "slug": "picsart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1099acac8bc0e54de9d8b0e769a0f538_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Picsart offers creative APIs to boost artistic expression on multiple platforms. Our tools unleash creativity, empowering users to experiment with imagination.",
    "category": "Images & Design",
    "title": "Picsart Integration With Cold Email Sales Tool",
    "h1": "Integrate Picsart with SalesBlink",
    "metaDescription": "Integrate Picsart and other Images & Design Tools with SalesBlink. Two Way Picsart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Chirpley",
    "slug": "chirpley",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bdc5f9ba1afe7afb9fc3bb4de0c969d1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chirpley is a SMS AI tool to create powerful productivity and text based applications.",
    "category": "Phone & SMS",
    "title": "Chirpley Integration With Cold Email Sales Tool",
    "h1": "Integrate Chirpley with SalesBlink",
    "metaDescription": "Integrate Chirpley and other Phone & SMS Tools with SalesBlink. Two Way Chirpley Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZipperAgent",
    "slug": "zipperagent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5103f0ad827a9c1e6ee4a63cb835bafa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZipperAgent is all-in-one Solution for Real Estate Professionals. It is a platform designed exclusively for Real Estate Offices, Brokers and Agents, to run their marketing, sales, and transactions easily from a single cloud based application and smartphones.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ZipperAgent Integration With Cold Email Sales Tool",
    "h1": "Integrate ZipperAgent with SalesBlink",
    "metaDescription": "Integrate ZipperAgent and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ZipperAgent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Link",
    "slug": "link",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07cc7255f94bc739814752253ea387bd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Link helps users create a digital business card, manage leads and manage their online presence",
    "category": "Contact Management",
    "title": "Link Integration With Cold Email Sales Tool",
    "h1": "Integrate Link with SalesBlink",
    "metaDescription": "Integrate Link and other Contact Management Tools with SalesBlink. Two Way Link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "McGill CRM",
    "slug": "mcgill-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18eb01b03071b5ab9d866dd9866ac9c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quick and easily coordinate Sales and Marketing of new real estate using McGill CRM. Generate a complete sales contract with electronic signatures in less than 5 minutes.",
    "category": "Sales & CRM",
    "title": "McGill CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate McGill CRM with SalesBlink",
    "metaDescription": "Integrate McGill CRM and other Sales & CRM Tools with SalesBlink. Two Way McGill CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Signedly",
    "slug": "signedly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9c4252db01e38ca1de902f7f0262f84e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Signedly streamlines your signing process with e-signature technology, integrates with various apps, and ensures secure and fast document signing.",
    "category": "Signatures",
    "title": "Signedly Integration With Cold Email Sales Tool",
    "h1": "Integrate Signedly with SalesBlink",
    "metaDescription": "Integrate Signedly and other Signatures Tools with SalesBlink. Two Way Signedly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Home Inspector Pro Office",
    "slug": "hip-office",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfedbbb27f2b650fdd7be26a3200a6e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Home Inspector Pro Office is a tool to automate and streamline your home inspection business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Home Inspector Pro Office Integration With Cold Email Sales Tool",
    "h1": "Integrate Home Inspector Pro Office with SalesBlink",
    "metaDescription": "Integrate Home Inspector Pro Office and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Home Inspector Pro Office Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AbleCommerce",
    "slug": "ablecommerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/126983627e7cc0afb0101bce2f7068d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AbleCommerce is a complete and secure eCommerce platform for your business.",
    "category": "eCommerce",
    "title": "AbleCommerce Integration With Cold Email Sales Tool",
    "h1": "Integrate AbleCommerce with SalesBlink",
    "metaDescription": "Integrate AbleCommerce and other eCommerce Tools with SalesBlink. Two Way AbleCommerce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Engage by Cell",
    "slug": "engagebycell",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc100cdbbb60a6c7e12b8852d869f135.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GuidebyCell SMS is text message service to send text to one phone number.",
    "category": "Phone & SMS",
    "title": "Engage by Cell Integration With Cold Email Sales Tool",
    "h1": "Integrate Engage by Cell with SalesBlink",
    "metaDescription": "Integrate Engage by Cell and other Phone & SMS Tools with SalesBlink. Two Way Engage by Cell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Enquiry Tracker",
    "slug": "enquiry-tracker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05f62032874ed13287777d7ece9cdbe6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Enquiry Tracker is a CRM for K-12 Schools to organize and track future families, manage events and provide detailed analytics.",
    "category": "Education",
    "title": "Enquiry Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate Enquiry Tracker with SalesBlink",
    "metaDescription": "Integrate Enquiry Tracker and other Education Tools with SalesBlink. Two Way Enquiry Tracker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Microsoft Viva Goals",
    "slug": "microsoft-viva-goals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/319294974cac6b819270b2a21865c834_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft Viva Goals is a goal-setting and OKR management solution that aligns teams to your organization’s priorities, driving results and a thriving business.",
    "category": "Product Management",
    "title": "Microsoft Viva Goals Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft Viva Goals with SalesBlink",
    "metaDescription": "Integrate Microsoft Viva Goals and other Product Management Tools with SalesBlink. Two Way Microsoft Viva Goals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contaqt",
    "slug": "contaqt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8972a766f9b36528639f29a51661e026.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contaqt is a social media marketing tool used to send out newsletters, surveys, articles etc and build and manage your subscriber lists",
    "category": "Marketing",
    "title": "Contaqt Integration With Cold Email Sales Tool",
    "h1": "Integrate Contaqt with SalesBlink",
    "metaDescription": "Integrate Contaqt and other Marketing Tools with SalesBlink. Two Way Contaqt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtual Business Cards",
    "slug": "virtual-business-cards",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb472fa65eaef9e5fbbac2e611f12e61.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Virtual Business Cards secures leads from new connections and can distribute them to your CRMs",
    "category": "Contact Management",
    "title": "Virtual Business Cards Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtual Business Cards with SalesBlink",
    "metaDescription": "Integrate Virtual Business Cards and other Contact Management Tools with SalesBlink. Two Way Virtual Business Cards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onpipeline",
    "slug": "onpipeline",
    "logo": "https://zapier-images.imgix.net/storage/services/db684200643ada52b18dccd6df1c97ce_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onpipeline is a cloud based Sales CRM for small and medium businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Onpipeline Integration With Cold Email Sales Tool",
    "h1": "Integrate Onpipeline with SalesBlink",
    "metaDescription": "Integrate Onpipeline and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Onpipeline Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "i-Reserve",
    "slug": "i-reserve-da85973",
    "logo": "https://zapier-images.imgix.net/storage/developer/bddcd9841eb854ea852d3256bc643ef1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "I-Reserve is an online booking system, allowing companies to receive bookings from their clients.  Several steps in the workflow can be automated, amongst which the email communication.",
    "category": "Scheduling & Booking",
    "title": "i-Reserve Integration With Cold Email Sales Tool",
    "h1": "Integrate i-Reserve with SalesBlink",
    "metaDescription": "Integrate i-Reserve and other Scheduling & Booking Tools with SalesBlink. Two Way i-Reserve Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoom Events",
    "slug": "zoom-events",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9c3b07bf26f752389957fda34f09e56_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoom Events is an all-in-one event management platform that allows you to host multi-session, multi-day events including concurrent sessions.",
    "category": "Event Management",
    "title": "Zoom Events Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoom Events with SalesBlink",
    "metaDescription": "Integrate Zoom Events and other Event Management Tools with SalesBlink. Two Way Zoom Events Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReviewRail",
    "slug": "reviewrail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc9feb59b92f7c314cef06d63d8f2791.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReviewRail offers Small Businesses a way to automate the feedback request process with their customers. We invite happy customers to share ratings on review sites like Google and Facebook for your business.",
    "category": "Reviews",
    "title": "ReviewRail Integration With Cold Email Sales Tool",
    "h1": "Integrate ReviewRail with SalesBlink",
    "metaDescription": "Integrate ReviewRail and other Reviews Tools with SalesBlink. Two Way ReviewRail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Denim",
    "slug": "denim",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e210aabd96babd3e387b11049fc8282c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Denim is a financial enablement platform for the logistics industry.",
    "category": "Payment Processing",
    "title": "Denim Integration With Cold Email Sales Tool",
    "h1": "Integrate Denim with SalesBlink",
    "metaDescription": "Integrate Denim and other Payment Processing Tools with SalesBlink. Two Way Denim Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teli",
    "slug": "teli",
    "logo": "https://zapier-images.imgix.net/storage/services/632a52673d5de9320d15048af00fde2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teli is an application used for messaging, VoIP, and fax.",
    "category": "Phone & SMS",
    "title": "Teli Integration With Cold Email Sales Tool",
    "h1": "Integrate Teli with SalesBlink",
    "metaDescription": "Integrate Teli and other Phone & SMS Tools with SalesBlink. Two Way Teli Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Foqal",
    "slug": "foqal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99dbabce89bf27a217fb256c8a0c5e5b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Foqal is a ticketing tool allowing you to support your customers on Salck",
    "category": "CRM (Customer Relationship Management)",
    "title": "Foqal Integration With Cold Email Sales Tool",
    "h1": "Integrate Foqal with SalesBlink",
    "metaDescription": "Integrate Foqal and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Foqal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NextAgency",
    "slug": "nextagency",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53d61d8b12689b7c5c60c4b5f077f3e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NextAgency is an insurance agency management software and CRM that helps producers save time, money and clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "NextAgency Integration With Cold Email Sales Tool",
    "h1": "Integrate NextAgency with SalesBlink",
    "metaDescription": "Integrate NextAgency and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way NextAgency Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BSC Designer",
    "slug": "bsc-designer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0287e86908077ebfbc252ca87e743fce_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BSC Designer is a Balanced Scorecard software. Integrate with BSC Designer to automate your scorecards and KPIs.",
    "category": "Business Intelligence",
    "title": "BSC Designer Integration With Cold Email Sales Tool",
    "h1": "Integrate BSC Designer with SalesBlink",
    "metaDescription": "Integrate BSC Designer and other Business Intelligence Tools with SalesBlink. Two Way BSC Designer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Revel Digital",
    "slug": "revel-digital",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74131c6312823a048c175c90ed48b87c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revel Digital is a comprehensive, cloud-based platform for digital signage with a focus on usability coupled with enterprise class features.",
    "category": "Marketing",
    "title": "Revel Digital Integration With Cold Email Sales Tool",
    "h1": "Integrate Revel Digital with SalesBlink",
    "metaDescription": "Integrate Revel Digital and other Marketing Tools with SalesBlink. Two Way Revel Digital Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hipsy",
    "slug": "hipsy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff460d47b830ebf398861fc57b46945c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hipsy is an event platform with ticket service.",
    "category": "Event Management",
    "title": "Hipsy Integration With Cold Email Sales Tool",
    "h1": "Integrate Hipsy with SalesBlink",
    "metaDescription": "Integrate Hipsy and other Event Management Tools with SalesBlink. Two Way Hipsy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agency Revolution Fuse",
    "slug": "agency-revolution-fuse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08a60ce8f297f3da6cc935bce96def5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fuse is an Insurance Marketing Automation platform that helps agents build deep relationships with their customers.",
    "category": "Marketing",
    "title": "Agency Revolution Fuse Integration With Cold Email Sales Tool",
    "h1": "Integrate Agency Revolution Fuse with SalesBlink",
    "metaDescription": "Integrate Agency Revolution Fuse and other Marketing Tools with SalesBlink. Two Way Agency Revolution Fuse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Billy (The Birthday Bot)",
    "slug": "billy-the-birthday-bot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0bd76e7fe56875783cf55840df20c1e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate birthdays and work anniversaries celebrations in Slack",
    "category": "Human Resources",
    "title": "Billy (The Birthday Bot) Integration With Cold Email Sales Tool",
    "h1": "Integrate Billy (The Birthday Bot) with SalesBlink",
    "metaDescription": "Integrate Billy (The Birthday Bot) and other Human Resources Tools with SalesBlink. Two Way Billy (The Birthday Bot) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NowDraft",
    "slug": "nowdraft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/287f292e47f7f252342fbed98c4ccce4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate AI Content At The Speed Of Now.",
    "category": "Content & Files",
    "title": "NowDraft Integration With Cold Email Sales Tool",
    "h1": "Integrate NowDraft with SalesBlink",
    "metaDescription": "Integrate NowDraft and other Content & Files Tools with SalesBlink. Two Way NowDraft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Homes Made Easy",
    "slug": "homes-made-easy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/14caafbf5d9419710032c45f4aba5b7a_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Homes Made Easy is a revolutionary real estate app platform allowing you to capture, trap and track prospects.",
    "category": "Website & App Building",
    "title": "Homes Made Easy Integration With Cold Email Sales Tool",
    "h1": "Integrate Homes Made Easy with SalesBlink",
    "metaDescription": "Integrate Homes Made Easy and other Website & App Building Tools with SalesBlink. Two Way Homes Made Easy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProProfs Project",
    "slug": "proprofs-project",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1ca2795e75f0be357aead6e6640ab51.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProProfs Project is an online project management tool that also lets you collaborate with your team on the go.",
    "category": "Project Management",
    "title": "ProProfs Project Integration With Cold Email Sales Tool",
    "h1": "Integrate ProProfs Project with SalesBlink",
    "metaDescription": "Integrate ProProfs Project and other Project Management Tools with SalesBlink. Two Way ProProfs Project Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EzzyCRM",
    "slug": "ezzycrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d71ca151ace32dfd458686735fecbac0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EzzyCRM is a Customer Relationship Management software that helps companies to manage sales team, assign task and followup, track pipeline, and visualize deal stages for better ROI.",
    "category": "CRM (Customer Relationship Management)",
    "title": "EzzyCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate EzzyCRM with SalesBlink",
    "metaDescription": "Integrate EzzyCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way EzzyCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bling Cloud",
    "slug": "bling-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95b64d6f99a1e1edea797938392b9faa_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All in one communication platform along with embedded phone and auto powered CRM.",
    "category": "Communication",
    "title": "Bling Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Bling Cloud with SalesBlink",
    "metaDescription": "Integrate Bling Cloud and other Communication Tools with SalesBlink. Two Way Bling Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventcombo",
    "slug": "eventcombo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/97a730b9f2541f8d28da61ad042d7931.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventcombo is a platform to organize any online virtual event, meeting or webinar.",
    "category": "Event Management",
    "title": "Eventcombo Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventcombo with SalesBlink",
    "metaDescription": "Integrate Eventcombo and other Event Management Tools with SalesBlink. Two Way Eventcombo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OurSMS",
    "slug": "oursms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79790c034da776bd46f7a741f152a8c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OurSMS is an app that allows you to send SMS messages.",
    "category": "Phone & SMS",
    "title": "OurSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate OurSMS with SalesBlink",
    "metaDescription": "Integrate OurSMS and other Phone & SMS Tools with SalesBlink. Two Way OurSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hypnobox CRM",
    "slug": "hypnobox-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6259648ed8f512f7aa511de3b56418b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hypnobox is a real estate CRM.",
    "category": "Sales & CRM",
    "title": "Hypnobox CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Hypnobox CRM with SalesBlink",
    "metaDescription": "Integrate Hypnobox CRM and other Sales & CRM Tools with SalesBlink. Two Way Hypnobox CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tall Bob",
    "slug": "tallbob",
    "logo": "https://zapier-images.imgix.net/storage/services/2c2508f72b6200764b37291266d88dfc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tall Bob allows you to send SMS messages to your customers",
    "category": "Phone & SMS",
    "title": "Tall Bob Integration With Cold Email Sales Tool",
    "h1": "Integrate Tall Bob with SalesBlink",
    "metaDescription": "Integrate Tall Bob and other Phone & SMS Tools with SalesBlink. Two Way Tall Bob Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MobiCRM",
    "slug": "mobicrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d91965bc2d0f6f71625944436639007e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MobiCRM is a smartphone-first CRM, designed for your itinerant sales force, offering prospection tools, contacts management, and calendar synchronization.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MobiCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate MobiCRM with SalesBlink",
    "metaDescription": "Integrate MobiCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MobiCRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pinata",
    "slug": "pinata",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59567ca43212dd5a6dfdedc06157fd0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pinata is the simplest way to upload and manage files on IPFS.",
    "category": "Developer Tools",
    "title": "Pinata Integration With Cold Email Sales Tool",
    "h1": "Integrate Pinata with SalesBlink",
    "metaDescription": "Integrate Pinata and other Developer Tools Tools with SalesBlink. Two Way Pinata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ImprintNext",
    "slug": "imprintnext",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7da4dc445a87b1db0320971ce1da2ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This app generates data for events in the ImprintNext designer studio including custom order creation and custom quote generation.",
    "category": "eCommerce",
    "title": "ImprintNext Integration With Cold Email Sales Tool",
    "h1": "Integrate ImprintNext with SalesBlink",
    "metaDescription": "Integrate ImprintNext and other eCommerce Tools with SalesBlink. Two Way ImprintNext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Setster",
    "slug": "setster",
    "logo": "https://zapier-images.imgix.net/storage/services/808c7ea3d94cd5596a67a329c6b6609c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Setster is an online appointment scheduling software built for complex enterprise booking needs, yet simple enough to use by smaller teams.",
    "category": "Scheduling & Booking",
    "title": "Setster Integration With Cold Email Sales Tool",
    "h1": "Integrate Setster with SalesBlink",
    "metaDescription": "Integrate Setster and other Scheduling & Booking Tools with SalesBlink. Two Way Setster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReviewInc",
    "slug": "reviewinc",
    "logo": "https://zapier-images.imgix.net/storage/services/f0b0ef509d0ebaed2e2fbe87d6c66aba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReviewInc is reputation management and customer communication software that automates the customer experience journey through forms, emails, and text messages. Our service enhances Online Reputation by monitoring, collecting, and amplifying reviews to help businesses shine.",
    "category": "Reviews",
    "title": "ReviewInc Integration With Cold Email Sales Tool",
    "h1": "Integrate ReviewInc with SalesBlink",
    "metaDescription": "Integrate ReviewInc and other Reviews Tools with SalesBlink. Two Way ReviewInc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Socie",
    "slug": "socie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c8447c7dcc4a4d112c432d31a9aee62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Socie is the top down communication app for societies, companies and associations. We serve a large variety of communities, such as sports clubs, churches, businesses and many others.",
    "category": "App Builder",
    "title": "Socie Integration With Cold Email Sales Tool",
    "h1": "Integrate Socie with SalesBlink",
    "metaDescription": "Integrate Socie and other App Builder Tools with SalesBlink. Two Way Socie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SourceWhale",
    "slug": "sourcewhale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5d67b339eec0086a5ed8a76b9ce22fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SourceWhale is a recruitment engagement platform for teams to execute their daily activities and make more placements, faster.",
    "category": "HR Talent & Recruitment",
    "title": "SourceWhale Integration With Cold Email Sales Tool",
    "h1": "Integrate SourceWhale with SalesBlink",
    "metaDescription": "Integrate SourceWhale and other HR Talent & Recruitment Tools with SalesBlink. Two Way SourceWhale Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Timesheet by Dovico",
    "slug": "timesheet-by-dovico",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ac124072208c52805205873f69110928.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The best employee timesheet software for simple project time tracking. Track and approve hours worked on projects and tasks for billing and reporting.",
    "category": "Time Tracking Software",
    "title": "Timesheet by Dovico Integration With Cold Email Sales Tool",
    "h1": "Integrate Timesheet by Dovico with SalesBlink",
    "metaDescription": "Integrate Timesheet by Dovico and other Time Tracking Software Tools with SalesBlink. Two Way Timesheet by Dovico Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Referral Reactor",
    "slug": "referral-reactor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff6894a70fcb411535339edd7f8d5247.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Referral Reactor is a tool that helps you manage the referrals your business receives.  Referrers can join your Reactor and send you referrals right through an app and you can pay them instantly for their referral.",
    "category": "Sales & CRM",
    "title": "Referral Reactor Integration With Cold Email Sales Tool",
    "h1": "Integrate Referral Reactor with SalesBlink",
    "metaDescription": "Integrate Referral Reactor and other Sales & CRM Tools with SalesBlink. Two Way Referral Reactor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agent Fixup",
    "slug": "listings-machine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e537324be7a108549e2d3e9a6cc28557.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agent Fixup turns web traffic into qualified real estate leads.",
    "category": "Ads & Conversion",
    "title": "Agent Fixup Integration With Cold Email Sales Tool",
    "h1": "Integrate Agent Fixup with SalesBlink",
    "metaDescription": "Integrate Agent Fixup and other Ads & Conversion Tools with SalesBlink. Two Way Agent Fixup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CoffeeCup App",
    "slug": "coffeecup-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/679a9f4d57196d84df992e3fb1ead68c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coffeecup is an extension for your product management software that supercharges your workflow with budget, cost- and team capacity management and billing.",
    "category": "Time Tracking Software",
    "title": "CoffeeCup App Integration With Cold Email Sales Tool",
    "h1": "Integrate CoffeeCup App with SalesBlink",
    "metaDescription": "Integrate CoffeeCup App and other Time Tracking Software Tools with SalesBlink. Two Way CoffeeCup App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS Pro TextingHouse",
    "slug": "sms-pro-textinghouse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df515ca8e775754c8be90a7ed2ef1519.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextingHouse allows you to automate your professional SMS sending.\nTextingHouse vous permet d'automatiser vos envois de SMS pro.",
    "category": "Communication",
    "title": "SMS Pro TextingHouse Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Pro TextingHouse with SalesBlink",
    "metaDescription": "Integrate SMS Pro TextingHouse and other Communication Tools with SalesBlink. Two Way SMS Pro TextingHouse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KEYZY",
    "slug": "keyzy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0be3dee4940c68817468b8fc4a43ac8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KEYZY is a software licensing service that monetize software",
    "category": "Developer Tools",
    "title": "KEYZY Integration With Cold Email Sales Tool",
    "h1": "Integrate KEYZY with SalesBlink",
    "metaDescription": "Integrate KEYZY and other Developer Tools Tools with SalesBlink. Two Way KEYZY Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blue Form Builder",
    "slug": "blue-form-builder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb277692e54215a213d1e6a9dffae9b7_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blue Form Builder is a service that response ever having submission from website",
    "category": "Website & App Building",
    "title": "Blue Form Builder Integration With Cold Email Sales Tool",
    "h1": "Integrate Blue Form Builder with SalesBlink",
    "metaDescription": "Integrate Blue Form Builder and other Website & App Building Tools with SalesBlink. Two Way Blue Form Builder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Predictive Sales AI",
    "slug": "predictive-sales-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6171eb576246932a262a2e44c5afea2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Predictive Sales AI is a lead generation and communication tool to help contractors generate more leads and sales.",
    "category": "Marketing Automation",
    "title": "Predictive Sales AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Predictive Sales AI with SalesBlink",
    "metaDescription": "Integrate Predictive Sales AI and other Marketing Automation Tools with SalesBlink. Two Way Predictive Sales AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Centrico",
    "slug": "centrico",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a37a3e57adcc8cd0e4e0c5c46e6cdba_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Centrico is a platform to manage contacts with marketing and sales features.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Centrico Integration With Cold Email Sales Tool",
    "h1": "Integrate Centrico with SalesBlink",
    "metaDescription": "Integrate Centrico and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Centrico Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChipBot",
    "slug": "chipbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc8c413f32800684121491690c28e462_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChipBot is a knowledge base bot for your website.",
    "category": "Customer Support",
    "title": "ChipBot Integration With Cold Email Sales Tool",
    "h1": "Integrate ChipBot with SalesBlink",
    "metaDescription": "Integrate ChipBot and other Customer Support Tools with SalesBlink. Two Way ChipBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Civalgo",
    "slug": "civalgo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1cada2cafb3026e7e89eecc1c038b21a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Civalgo is a platform to operate your construction company which goal is to enhance productivity in the field with a powerful Operational Project Management Platform that links schedule to daily logs to provide an optimal workflow and past cost data.",
    "category": "Project Management",
    "title": "Civalgo Integration With Cold Email Sales Tool",
    "h1": "Integrate Civalgo with SalesBlink",
    "metaDescription": "Integrate Civalgo and other Project Management Tools with SalesBlink. Two Way Civalgo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trelis",
    "slug": "trelis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e543946e028d451e6ccc9cba3ed8e7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trelis allows merchants to accept USDC payments over Ethereum, including recurring subscription payments, directly to their wallet.",
    "category": "Payment Processing",
    "title": "Trelis Integration With Cold Email Sales Tool",
    "h1": "Integrate Trelis with SalesBlink",
    "metaDescription": "Integrate Trelis and other Payment Processing Tools with SalesBlink. Two Way Trelis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IndustryNewsletters",
    "slug": "industrynewsletters",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/399f8fccb851ae4b33a13ca3b5f32725.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IndustryNewsletters is a powerful, modern, easy to use digital marketing tool for professional service firms with built-in interactive content.",
    "category": "Email Newsletters",
    "title": "IndustryNewsletters Integration With Cold Email Sales Tool",
    "h1": "Integrate IndustryNewsletters with SalesBlink",
    "metaDescription": "Integrate IndustryNewsletters and other Email Newsletters Tools with SalesBlink. Two Way IndustryNewsletters Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cimple",
    "slug": "cimple",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/675badae1530c7858716f0426211b023_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cimple is an Applicant tracking system, that helps you manage candidates, proposals and onboarding of new candidates.",
    "category": "HR Talent & Recruitment",
    "title": "Cimple Integration With Cold Email Sales Tool",
    "h1": "Integrate Cimple with SalesBlink",
    "metaDescription": "Integrate Cimple and other HR Talent & Recruitment Tools with SalesBlink. Two Way Cimple Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "wiin",
    "slug": "wiin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7bb7f71545eceb5842a7b54e98445548.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WIIN is a collaborative portal to dematerialize all your information collection process",
    "category": "Forms & Surveys",
    "title": "wiin Integration With Cold Email Sales Tool",
    "h1": "Integrate wiin with SalesBlink",
    "metaDescription": "Integrate wiin and other Forms & Surveys Tools with SalesBlink. Two Way wiin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CineSend On Demand",
    "slug": "cinesend-on-demand",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3349d9e94a2ef847708b07c6ad9c37bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Launch a brand-forward virtual film festival on a trusted, secure video platform.",
    "category": "Video & Audio",
    "title": "CineSend On Demand Integration With Cold Email Sales Tool",
    "h1": "Integrate CineSend On Demand with SalesBlink",
    "metaDescription": "Integrate CineSend On Demand and other Video & Audio Tools with SalesBlink. Two Way CineSend On Demand Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YouCan",
    "slug": "youcan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d52845ab57f22e04ee5c4222959b180e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YouCan is an integrated platform specialized in e-commerce, offering a wide range of services needed by merchants and entrepreneurs.",
    "category": "eCommerce",
    "title": "YouCan Integration With Cold Email Sales Tool",
    "h1": "Integrate YouCan with SalesBlink",
    "metaDescription": "Integrate YouCan and other eCommerce Tools with SalesBlink. Two Way YouCan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialGrep Alerts",
    "slug": "socialgrep-alerts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3eab26356b2457d478d750209700d791.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocialGrep is a versatile social data collection and analysis platform that can monitor Reddit posts and comments in real time.",
    "category": "Social Media Marketing",
    "title": "SocialGrep Alerts Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialGrep Alerts with SalesBlink",
    "metaDescription": "Integrate SocialGrep Alerts and other Social Media Marketing Tools with SalesBlink. Two Way SocialGrep Alerts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloutly",
    "slug": "cloutly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03379e2e986f87de141f7124c2f57f1e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloutly is a review management tool that helps you collect, respond to and showcase your online reviews.",
    "category": "Reviews",
    "title": "Cloutly Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloutly with SalesBlink",
    "metaDescription": "Integrate Cloutly and other Reviews Tools with SalesBlink. Two Way Cloutly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mintBlue",
    "slug": "mintblue",
    "logo": "https://zapier-images.imgix.net/storage/services/9a445450bc75334251b3a6b0a507dea1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mintBlue connects organizations to the blockchain to make them future-proof.",
    "category": "Task Management",
    "title": "mintBlue Integration With Cold Email Sales Tool",
    "h1": "Integrate mintBlue with SalesBlink",
    "metaDescription": "Integrate mintBlue and other Task Management Tools with SalesBlink. Two Way mintBlue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brand Gaming",
    "slug": "brand-gaming",
    "logo": "https://zapier-images.imgix.net/storage/developer/34ae63306c7691c50db53c7074fa702c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brand Gaming is an Instant Win application for lead generation and engagement. Offering odds and inventory controls. Games include Virtual Prize Wheel, Scratch Off, Slot Machine and others.",
    "category": "Marketing Automation",
    "title": "Brand Gaming Integration With Cold Email Sales Tool",
    "h1": "Integrate Brand Gaming with SalesBlink",
    "metaDescription": "Integrate Brand Gaming and other Marketing Automation Tools with SalesBlink. Two Way Brand Gaming Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trivie",
    "slug": "trivie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/924073287ed71a8b4383170908dd707b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trivie is an automated learning reinforcement platform.",
    "category": "Business Intelligence",
    "title": "Trivie Integration With Cold Email Sales Tool",
    "h1": "Integrate Trivie with SalesBlink",
    "metaDescription": "Integrate Trivie and other Business Intelligence Tools with SalesBlink. Two Way Trivie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DataCrush",
    "slug": "datacrush",
    "logo": "https://zapier-images.imgix.net/storage/developer/451b1a906cd05055e08a2082c8f9faf6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DataCrush is an all-in-one Marketing & Sales Automation platform. \nThe growth of your business requires a flexible and secure platform in your language. Create incredible Marketing actions, the limit is your imagination.",
    "category": "Marketing Automation",
    "title": "DataCrush Integration With Cold Email Sales Tool",
    "h1": "Integrate DataCrush with SalesBlink",
    "metaDescription": "Integrate DataCrush and other Marketing Automation Tools with SalesBlink. Two Way DataCrush Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DOXI",
    "slug": "doxi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e172eb27192427472bb0e6a3fc66a879_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Doxi is a digital signature system for organizational processes that allow the distribution of documents for the participants' signatures.",
    "category": "Signatures",
    "title": "DOXI Integration With Cold Email Sales Tool",
    "h1": "Integrate DOXI with SalesBlink",
    "metaDescription": "Integrate DOXI and other Signatures Tools with SalesBlink. Two Way DOXI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Exceed.ai",
    "slug": "exceedai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9a34d3a384074b8ae099e0612d6e8b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Exceed.ai helps companies engage, nurture, and qualify more leads at scale, using a two-way conversational AI engine",
    "category": "Marketing Automation",
    "title": "Exceed.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Exceed.ai with SalesBlink",
    "metaDescription": "Integrate Exceed.ai and other Marketing Automation Tools with SalesBlink. Two Way Exceed.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wbiztool",
    "slug": "wbiztool",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc81b8dcc07cac245db14b6c0b51173c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send WhatsApp messages to your customers from your WhatsApp number",
    "category": "Communication",
    "title": "Wbiztool Integration With Cold Email Sales Tool",
    "h1": "Integrate Wbiztool with SalesBlink",
    "metaDescription": "Integrate Wbiztool and other Communication Tools with SalesBlink. Two Way Wbiztool Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Showroom",
    "slug": "showroom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f1ac81910dfb10f5538cf1fc00879ae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Showroom is a polling app that helps you build and collect the results from easy polls, conversational forms and clean educational tests",
    "category": "Forms & Surveys",
    "title": "Showroom Integration With Cold Email Sales Tool",
    "h1": "Integrate Showroom with SalesBlink",
    "metaDescription": "Integrate Showroom and other Forms & Surveys Tools with SalesBlink. Two Way Showroom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mailXpert",
    "slug": "mailxpert",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eff17951a2ad2d04cb7a396b8959fc55.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mailXpert is a leading E-Mail-Marketing Solution based in Switzerland.",
    "category": "Email Newsletters",
    "title": "mailXpert Integration With Cold Email Sales Tool",
    "h1": "Integrate mailXpert with SalesBlink",
    "metaDescription": "Integrate mailXpert and other Email Newsletters Tools with SalesBlink. Two Way mailXpert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Organimi",
    "slug": "organimi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b2e30b7eedace3883faec273be44f36b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Organimi is a web based tool to create online Organization Charts for your Organization large or small.",
    "category": "Human Resources",
    "title": "Organimi Integration With Cold Email Sales Tool",
    "h1": "Integrate Organimi with SalesBlink",
    "metaDescription": "Integrate Organimi and other Human Resources Tools with SalesBlink. Two Way Organimi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Successeve",
    "slug": "successeve",
    "logo": "https://zapier-images.imgix.net/storage/services/97c61d5eed4f9bf1e963690f31ded0ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Successeve is a customer lifecycle management software that helps companies increase customer conversion, retention, and expansion.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Successeve Integration With Cold Email Sales Tool",
    "h1": "Integrate Successeve with SalesBlink",
    "metaDescription": "Integrate Successeve and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Successeve Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PractiTest",
    "slug": "practitest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c0c025c0ddd452a3c4ee5a88689a7401.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PractiTest QA and test management solution helps users to manage the development and testing process, with an end-to-end approach starting from requirements, creating and running tests, tracking bugs and reporting them to all relevant stakeholders. \n \nPractiTest integrates with all leading testing tools such bug tracking (JIRA and others), CI/CD , automation and collaboration tools .",
    "category": "Developer Tools",
    "title": "PractiTest Integration With Cold Email Sales Tool",
    "h1": "Integrate PractiTest with SalesBlink",
    "metaDescription": "Integrate PractiTest and other Developer Tools Tools with SalesBlink. Two Way PractiTest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "XemailVerify",
    "slug": "xemailverify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3eca18e440966b74106e1c17e3879db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A application to verify lots of emails if they are safe to send or not, within a moment of time.\nProviding integrations to some popular application for easy one click verification of emails.",
    "category": "Email",
    "title": "XemailVerify Integration With Cold Email Sales Tool",
    "h1": "Integrate XemailVerify with SalesBlink",
    "metaDescription": "Integrate XemailVerify and other Email Tools with SalesBlink. Two Way XemailVerify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "adam.ai",
    "slug": "adamai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a4e6c784f17f98f75d4da757314c06f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An all-in-one intelligent meeting management solution that handles the entire meeting content lifecycle.",
    "category": "Project Management",
    "title": "adam.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate adam.ai with SalesBlink",
    "metaDescription": "Integrate adam.ai and other Project Management Tools with SalesBlink. Two Way adam.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "4Dem.it",
    "slug": "4demit",
    "logo": "https://zapier-images.imgix.net/storage/developer/d9a54265a72f783b0e19f96224ebae12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "4Dem.it is an Italian professional email marketing platform.",
    "category": "Marketing Automation",
    "title": "4Dem.it Integration With Cold Email Sales Tool",
    "h1": "Integrate 4Dem.it with SalesBlink",
    "metaDescription": "Integrate 4Dem.it and other Marketing Automation Tools with SalesBlink. Two Way 4Dem.it Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cobli",
    "slug": "cobli",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f17423a5f5e37a885764ff7688ffd3ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cobli is a fleet management platform: more visibility to manage your vehicles fleet",
    "category": "Internet of Things",
    "title": "Cobli Integration With Cold Email Sales Tool",
    "h1": "Integrate Cobli with SalesBlink",
    "metaDescription": "Integrate Cobli and other Internet of Things Tools with SalesBlink. Two Way Cobli Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CardClan",
    "slug": "cardclan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1fdf8579bf7b1ef63c77670fa1e24c2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A platform to build and send interactive and personalized digital cards using prebuilt templates. Replace plain old boring emails.",
    "category": "Customer Appreciation",
    "title": "CardClan Integration With Cold Email Sales Tool",
    "h1": "Integrate CardClan with SalesBlink",
    "metaDescription": "Integrate CardClan and other Customer Appreciation Tools with SalesBlink. Two Way CardClan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Poodll NET",
    "slug": "poodll-net",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a81e2ccd247877a69a12153fa6e05b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Poodll NET is a Moodle based LMS platform for language schools. It is used to deliver online language courses and assessments.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Poodll NET Integration With Cold Email Sales Tool",
    "h1": "Integrate Poodll NET with SalesBlink",
    "metaDescription": "Integrate Poodll NET and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Poodll NET Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BrowserStack",
    "slug": "browserstack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fbda1ef8af8c6c4c352f1127eedc2bfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrowserStack is a reliable cross-browser and device testing platform helping you test across 2000+ combinations.",
    "category": "Developer Tools",
    "title": "BrowserStack Integration With Cold Email Sales Tool",
    "h1": "Integrate BrowserStack with SalesBlink",
    "metaDescription": "Integrate BrowserStack and other Developer Tools Tools with SalesBlink. Two Way BrowserStack Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "MessengerPeople.dev",
    "slug": "messengerpeople-dev",
    "logo": "https://zapier-images.imgix.net/storage/services/9e9b24a2beca5b8f874393f842f029cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MessengerPeople API is a unified API that lets you send and receive messages through multiple channels, like WhatsApp Business (official partner), Telegram and Facebook Messenger.",
    "category": "Social Media Accounts",
    "title": "MessengerPeople.dev Integration With Cold Email Sales Tool",
    "h1": "Integrate MessengerPeople.dev with SalesBlink",
    "metaDescription": "Integrate MessengerPeople.dev and other Social Media Accounts Tools with SalesBlink. Two Way MessengerPeople.dev Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Last Mile Delivery Planner by MyRouteOnline",
    "slug": "last-mile-delivery-planner-by-my",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/52fa9e79024d3cc8f81cf41febaca1e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Route planning app for local deliveries, tracking & navigating",
    "category": "Productivity",
    "title": "Last Mile Delivery Planner by MyRouteOnline Integration With Cold Email Sales Tool",
    "h1": "Integrate Last Mile Delivery Planner by MyRouteOnline with SalesBlink",
    "metaDescription": "Integrate Last Mile Delivery Planner by MyRouteOnline and other Productivity Tools with SalesBlink. Two Way Last Mile Delivery Planner by MyRouteOnline Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GivePanel",
    "slug": "givepanel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d166cee30857d9f4e58a74ee9397051.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GivePanel helps you unlock social fundraising by giving you a proven way to acquire more contact data and help build custom imports for your CRM",
    "category": "CRM (Customer Relationship Management)",
    "title": "GivePanel Integration With Cold Email Sales Tool",
    "h1": "Integrate GivePanel with SalesBlink",
    "metaDescription": "Integrate GivePanel and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GivePanel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tixoom",
    "slug": "tixoom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ad8165a3c4c3c190d26b97da0dc3b7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tixoom lets you sell tickets or get donations for your Zoom® events.",
    "category": "Scheduling & Booking",
    "title": "Tixoom Integration With Cold Email Sales Tool",
    "h1": "Integrate Tixoom with SalesBlink",
    "metaDescription": "Integrate Tixoom and other Scheduling & Booking Tools with SalesBlink. Two Way Tixoom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "My Most Trusted Network",
    "slug": "my-most-trusted-network",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c99b202afde97b46947c238a6e1f2ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "My Most Trusted Network is a network building Chrome Extension for LinkedIn that allows its users to make business referrals and introductions in a safe and trusted way. It also allows users to link and sync LinkedIn data to a CRM.",
    "category": "Social Media Marketing",
    "title": "My Most Trusted Network Integration With Cold Email Sales Tool",
    "h1": "Integrate My Most Trusted Network with SalesBlink",
    "metaDescription": "Integrate My Most Trusted Network and other Social Media Marketing Tools with SalesBlink. Two Way My Most Trusted Network Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AerisWeather",
    "slug": "aerisweather",
    "logo": "https://zapier-images.imgix.net/storage/services/8dedef64f2c85cc6a0ae4d31d39618af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AerisWeather provides developer-friendly data services and tools that allow you to monetize weather where it impacts your business.",
    "category": "App Builder",
    "title": "AerisWeather Integration With Cold Email Sales Tool",
    "h1": "Integrate AerisWeather with SalesBlink",
    "metaDescription": "Integrate AerisWeather and other App Builder Tools with SalesBlink. Two Way AerisWeather Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiretalent",
    "slug": "hiretalent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6725abd41b8d7e0ecbd6ff5a5ceb7c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AI-Powered Hiring Automation Platform For Global Hiring\nEmpowering companies to source, select, and appoint talent.",
    "category": "HR Talent & Recruitment",
    "title": "Hiretalent Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiretalent with SalesBlink",
    "metaDescription": "Integrate Hiretalent and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hiretalent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ServicePRO",
    "slug": "servicepro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6445e4b1e373358c475a405cf183f57c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServicePRO is a powerful Collaborative Workflow and Service Management System that delivers Return on Investment. Requires 14.2.16.50 or above version of ServicePRO.",
    "category": "Customer Support",
    "title": "ServicePRO Integration With Cold Email Sales Tool",
    "h1": "Integrate ServicePRO with SalesBlink",
    "metaDescription": "Integrate ServicePRO and other Customer Support Tools with SalesBlink. Two Way ServicePRO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "7shifts",
    "slug": "7shifts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c9fbfe7096c87ac3ed875c8320725e3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "7shifts is the complete restaurant team management tools that helps you hire, train, schedule, pay and retain employees.",
    "category": "Productivity",
    "title": "7shifts Integration With Cold Email Sales Tool",
    "h1": "Integrate 7shifts with SalesBlink",
    "metaDescription": "Integrate 7shifts and other Productivity Tools with SalesBlink. Two Way 7shifts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ipregistry",
    "slug": "ipregistry",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e2fd474972788f1ba4bbe58a135a034.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build location and threat aware applications with the most reliable IP Geolocation and threat data service.",
    "category": "Developer Tools",
    "title": "Ipregistry Integration With Cold Email Sales Tool",
    "h1": "Integrate Ipregistry with SalesBlink",
    "metaDescription": "Integrate Ipregistry and other Developer Tools Tools with SalesBlink. Two Way Ipregistry Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sofy",
    "slug": "sofy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b64abf38c0947f01a85b0d08e0badd66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sofy is a marketing platform for businesses, allowing them to manage their online visibility and reach out to their customers.",
    "category": "Phone & SMS",
    "title": "Sofy Integration With Cold Email Sales Tool",
    "h1": "Integrate Sofy with SalesBlink",
    "metaDescription": "Integrate Sofy and other Phone & SMS Tools with SalesBlink. Two Way Sofy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Press'nXPress",
    "slug": "pressnxpress",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db1de72e6e27f72d50d63b066c3d1641.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Press'nXPress is a multi-channel customer feedback management collects feedback on Web, Email, SMS, QR code and generates actionable insights in real-time.",
    "category": "Forms & Surveys",
    "title": "Press'nXPress Integration With Cold Email Sales Tool",
    "h1": "Integrate Press'nXPress with SalesBlink",
    "metaDescription": "Integrate Press'nXPress and other Forms & Surveys Tools with SalesBlink. Two Way Press'nXPress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UDO",
    "slug": "udo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be4887c782cf32e38ee736f0d45b7cb7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UDO is a sales & marketing automation app that checks customer status, leadscore, and correct address.",
    "category": "Business Intelligence",
    "title": "UDO Integration With Cold Email Sales Tool",
    "h1": "Integrate UDO with SalesBlink",
    "metaDescription": "Integrate UDO and other Business Intelligence Tools with SalesBlink. Two Way UDO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NextBrain",
    "slug": "nextbrain",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cc4423d6813577f01154e5e6cd40fb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AutoML no-code Machine Learning in seconds",
    "category": "Business Intelligence",
    "title": "NextBrain Integration With Cold Email Sales Tool",
    "h1": "Integrate NextBrain with SalesBlink",
    "metaDescription": "Integrate NextBrain and other Business Intelligence Tools with SalesBlink. Two Way NextBrain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omeda",
    "slug": "omeda",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b5b745aa7965f5968070625c4b841c3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omeda helps companies manage and activate audiences.",
    "category": "Databases",
    "title": "Omeda Integration With Cold Email Sales Tool",
    "h1": "Integrate Omeda with SalesBlink",
    "metaDescription": "Integrate Omeda and other Databases Tools with SalesBlink. Two Way Omeda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Valuecard",
    "slug": "valuecard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b456f64af483c5e790edc97d30f996bc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect valuecard members with other platforms",
    "category": "Marketing",
    "title": "Valuecard Integration With Cold Email Sales Tool",
    "h1": "Integrate Valuecard with SalesBlink",
    "metaDescription": "Integrate Valuecard and other Marketing Tools with SalesBlink. Two Way Valuecard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qawafil",
    "slug": "qawafil",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f8b0a2afa410b3c4b8c0ed68a942f70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qawafil is an e-commerce platform to create online stores.",
    "category": "eCommerce",
    "title": "Qawafil Integration With Cold Email Sales Tool",
    "h1": "Integrate Qawafil with SalesBlink",
    "metaDescription": "Integrate Qawafil and other eCommerce Tools with SalesBlink. Two Way Qawafil Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reviews360",
    "slug": "reviews360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d45d99832fa0d9eee8d9118952eacbe_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reviews360 is a review collection platform. Automatically send surveys to your customers via text message and collect reviews on all the major review sites.",
    "category": "Reviews",
    "title": "Reviews360 Integration With Cold Email Sales Tool",
    "h1": "Integrate Reviews360 with SalesBlink",
    "metaDescription": "Integrate Reviews360 and other Reviews Tools with SalesBlink. Two Way Reviews360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aunoa",
    "slug": "aunoa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d08e1077cc1156f05ab6a210aad33338.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aunoa automates interactions and conversations through AI chatbots. With this app you can send proactive Whatsapp templates.",
    "category": "Customer Support",
    "title": "Aunoa Integration With Cold Email Sales Tool",
    "h1": "Integrate Aunoa with SalesBlink",
    "metaDescription": "Integrate Aunoa and other Customer Support Tools with SalesBlink. Two Way Aunoa Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Botium Box",
    "slug": "botium-box",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/beb6f738c2ae7121502053ef27699503.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Botium, also known as \"the Selenium for Chatbots\", is the industry standard for testing of conversational A.I. (chatbots).",
    "category": "Developer Tools",
    "title": "Botium Box Integration With Cold Email Sales Tool",
    "h1": "Integrate Botium Box with SalesBlink",
    "metaDescription": "Integrate Botium Box and other Developer Tools Tools with SalesBlink. Two Way Botium Box Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Concord",
    "slug": "concord",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/652b880401122a4d65e244cfc6862fb4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Concord provides privacy-first data solutions that protect and reward companies and the people they engage with",
    "category": "Security & Identity Tools",
    "title": "Concord Integration With Cold Email Sales Tool",
    "h1": "Integrate Concord with SalesBlink",
    "metaDescription": "Integrate Concord and other Security & Identity Tools Tools with SalesBlink. Two Way Concord Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Inperium Talk",
    "slug": "inperium-talk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a9b90879ded78ea51691fdede951f2f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inperium Talk is a cloud-native business phone system offering a truly seamless calling experience for small and midsize organizations.",
    "category": "Communication",
    "title": "Inperium Talk Integration With Cold Email Sales Tool",
    "h1": "Integrate Inperium Talk with SalesBlink",
    "metaDescription": "Integrate Inperium Talk and other Communication Tools with SalesBlink. Two Way Inperium Talk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickSchools.com",
    "slug": "quickschoolscom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a78c297611189bee56936eaa8429a2a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickSchools.com is an Online School Management System that offers a comprehensive school management solution that you can use without any training from day one.",
    "category": "Education",
    "title": "QuickSchools.com Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickSchools.com with SalesBlink",
    "metaDescription": "Integrate QuickSchools.com and other Education Tools with SalesBlink. Two Way QuickSchools.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inperium Sell",
    "slug": "inperium-sell",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0513e62bc49c113cdcd2f589dd7dd7f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inperium Sell is a Cloud-Based Sales CRM system to close more deals faster, reach contacts better, and generate more revenue.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Inperium Sell Integration With Cold Email Sales Tool",
    "h1": "Integrate Inperium Sell with SalesBlink",
    "metaDescription": "Integrate Inperium Sell and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Inperium Sell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SEO Tester Online",
    "slug": "seo-tester-online",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6d4c2f9ff9536e8684a9eefb6209445.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SEO Tester Online is an all-in-one SEO Platform for agencies, marketers and SMEs to get more organic traffic.",
    "category": "Marketing Automation",
    "title": "SEO Tester Online Integration With Cold Email Sales Tool",
    "h1": "Integrate SEO Tester Online with SalesBlink",
    "metaDescription": "Integrate SEO Tester Online and other Marketing Automation Tools with SalesBlink. Two Way SEO Tester Online Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aurelius",
    "slug": "aurelius",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1a84668259611018abdf266dbf6a2b19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aurelius is a user research and insights platform to tag, analyze, search and share all your UX research in one place.",
    "category": "Product Management",
    "title": "Aurelius Integration With Cold Email Sales Tool",
    "h1": "Integrate Aurelius with SalesBlink",
    "metaDescription": "Integrate Aurelius and other Product Management Tools with SalesBlink. Two Way Aurelius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TPNI Engage",
    "slug": "instantcustomer",
    "logo": "https://zapier-images.imgix.net/storage/developer/e775b93c1d5a2a2cfc5386465136d7f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The world's first completely integrated multi-channel lead capture, follow up, sales and marketing system.",
    "category": "Marketing Automation",
    "title": "TPNI Engage Integration With Cold Email Sales Tool",
    "h1": "Integrate TPNI Engage with SalesBlink",
    "metaDescription": "Integrate TPNI Engage and other Marketing Automation Tools with SalesBlink. Two Way TPNI Engage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "meetergo",
    "slug": "meetergo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aeb351a15da4cf34a0105e8002cdfbb6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meetergo is appointment scheduling software to help anyone quickly and easily schedule meetings with you from anywhere. Manage your appointments, join calls, and accept payments all in one place.",
    "category": "Calendar",
    "title": "meetergo Integration With Cold Email Sales Tool",
    "h1": "Integrate meetergo with SalesBlink",
    "metaDescription": "Integrate meetergo and other Calendar Tools with SalesBlink. Two Way meetergo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vlow",
    "slug": "vlow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91d5c18e32f603367a1cab12b010e659.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A HR platform that helps improve the management process.",
    "category": "Human Resources",
    "title": "Vlow Integration With Cold Email Sales Tool",
    "h1": "Integrate Vlow with SalesBlink",
    "metaDescription": "Integrate Vlow and other Human Resources Tools with SalesBlink. Two Way Vlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "2Chat",
    "slug": "2chat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96b5fb6dbf718ed9b8e6b4b3687a7fa3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "2Chat is a conversational commerce automation tool for WhatsApp, Instagram, Messenger, and SMS.",
    "category": "eCommerce",
    "title": "2Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate 2Chat with SalesBlink",
    "metaDescription": "Integrate 2Chat and other eCommerce Tools with SalesBlink. Two Way 2Chat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "EnterpriseAlumni",
    "slug": "enterprisealumni",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c026592b03aad88e27e3bd2db4eac53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage and engage your alumni community with EnterpriseAlumni - the market-leading Alumni Platform trusted by the world's top organizations.",
    "category": "Human Resources",
    "title": "EnterpriseAlumni Integration With Cold Email Sales Tool",
    "h1": "Integrate EnterpriseAlumni with SalesBlink",
    "metaDescription": "Integrate EnterpriseAlumni and other Human Resources Tools with SalesBlink. Two Way EnterpriseAlumni Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ManicTime Cloud",
    "slug": "manictime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd873e105633b7fc49a405a7d4a2c1b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ManicTime allows you to keep up with your employees’ work, send accurate progress reports, and manage your time better than ever before.",
    "category": "Time Tracking Software",
    "title": "ManicTime Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate ManicTime Cloud with SalesBlink",
    "metaDescription": "Integrate ManicTime Cloud and other Time Tracking Software Tools with SalesBlink. Two Way ManicTime Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProAgentSolutions",
    "slug": "pro-agent-solutions",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c28e43411875f740f28afd8408f9a95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pro Agent Solutions helps real estate agents and brokers manage their customer relationships, stay organized and generate leads so they can sell more homes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ProAgentSolutions Integration With Cold Email Sales Tool",
    "h1": "Integrate ProAgentSolutions with SalesBlink",
    "metaDescription": "Integrate ProAgentSolutions and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ProAgentSolutions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dotcal",
    "slug": "dotcal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/92afc6e6675db5f4199848d25c9a6f34_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dotcal makes scheduling easier with beautiful, customizable booking pages that help you make bookings with friends, colleagues, and clients in style.",
    "category": "Scheduling & Booking",
    "title": "Dotcal Integration With Cold Email Sales Tool",
    "h1": "Integrate Dotcal with SalesBlink",
    "metaDescription": "Integrate Dotcal and other Scheduling & Booking Tools with SalesBlink. Two Way Dotcal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slidecast",
    "slug": "slidecast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b6bcd27735ff34cb4ff277b00571ac24_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Slidecast enables you to engage your customers seamlessly and virtually using simple, beautiful, and effective presentation sharing, in real-time without software or apps to install.",
    "category": "Sales & CRM",
    "title": "Slidecast Integration With Cold Email Sales Tool",
    "h1": "Integrate Slidecast with SalesBlink",
    "metaDescription": "Integrate Slidecast and other Sales & CRM Tools with SalesBlink. Two Way Slidecast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mirro",
    "slug": "mirro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a452d74b0e4eef2d3215f8a6659d6b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mirro is simple all-in-one performance management software.",
    "category": "Human Resources",
    "title": "Mirro Integration With Cold Email Sales Tool",
    "h1": "Integrate Mirro with SalesBlink",
    "metaDescription": "Integrate Mirro and other Human Resources Tools with SalesBlink. Two Way Mirro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobiz SMS",
    "slug": "mobiz-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6905853577edde69313516571b15843f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobiz is a service that enables a business owner or marketer to create personalised SMS campaigns with a personalised and feature-rich landing page.",
    "category": "Communication",
    "title": "Mobiz SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobiz SMS with SalesBlink",
    "metaDescription": "Integrate Mobiz SMS and other Communication Tools with SalesBlink. Two Way Mobiz SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plek",
    "slug": "plek-da88420",
    "logo": "https://zapier-images.imgix.net/storage/developer/f04fd7e9242bc70a84561019417afe1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plek is a social intranet and internal communication tool.",
    "category": "Team Collaboration",
    "title": "Plek Integration With Cold Email Sales Tool",
    "h1": "Integrate Plek with SalesBlink",
    "metaDescription": "Integrate Plek and other Team Collaboration Tools with SalesBlink. Two Way Plek Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Real Magnet",
    "slug": "real-magnet",
    "logo": "https://zapier-images.imgix.net/storage/developer/82885db69a398f4c0a743aef0f762854.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real Magnet is a leading marketing automation platform that helps businesses attract, engage and deliver better email, marketing, social and mobile campaigns.",
    "category": "Marketing Automation",
    "title": "Real Magnet Integration With Cold Email Sales Tool",
    "h1": "Integrate Real Magnet with SalesBlink",
    "metaDescription": "Integrate Real Magnet and other Marketing Automation Tools with SalesBlink. Two Way Real Magnet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rylee",
    "slug": "rylee",
    "logo": "https://zapier-images.imgix.net/storage/services/e3c7bc7b6ab8ba7c63a9d11a15dc3730.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rylee is a platform that helps bol.com sellers to grow their business.",
    "category": "Analytics",
    "title": "Rylee Integration With Cold Email Sales Tool",
    "h1": "Integrate Rylee with SalesBlink",
    "metaDescription": "Integrate Rylee and other Analytics Tools with SalesBlink. Two Way Rylee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Efidem",
    "slug": "efidem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/556fbdc653aee7661f649e56257b5688.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Efidem is a multi-channel router (email, sms, mail)",
    "category": "Communication",
    "title": "Efidem Integration With Cold Email Sales Tool",
    "h1": "Integrate Efidem with SalesBlink",
    "metaDescription": "Integrate Efidem and other Communication Tools with SalesBlink. Two Way Efidem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StoriesOnBoard",
    "slug": "storiesonboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46fdcbc1c9e3b364e94d30e65a563b86.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoriesOnBoard is a feedback collection tool that helps remote teams collect and understand the user needs, prioritize the right ideas and collaborate with everyone around a user story map.",
    "category": "Product Management",
    "title": "StoriesOnBoard Integration With Cold Email Sales Tool",
    "h1": "Integrate StoriesOnBoard with SalesBlink",
    "metaDescription": "Integrate StoriesOnBoard and other Product Management Tools with SalesBlink. Two Way StoriesOnBoard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Informizely",
    "slug": "informizely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70f707b7ce862e04a5fab5af327b5e90_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Informizely is a powerful survey platform for customer feedback",
    "category": "Forms & Surveys",
    "title": "Informizely Integration With Cold Email Sales Tool",
    "h1": "Integrate Informizely with SalesBlink",
    "metaDescription": "Integrate Informizely and other Forms & Surveys Tools with SalesBlink. Two Way Informizely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ziwo",
    "slug": "ziwo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f8eb9ac482f9155d01f57eebf529ce48_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ziwo Cloud Contact Center is an app with readymade CRM plugins to connect real or virtual agents with phone numbers in any country.",
    "category": "Customer Support",
    "title": "Ziwo Integration With Cold Email Sales Tool",
    "h1": "Integrate Ziwo with SalesBlink",
    "metaDescription": "Integrate Ziwo and other Customer Support Tools with SalesBlink. Two Way Ziwo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kado",
    "slug": "kado",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2bb2ac2099071f64b4d700d3fffafd45_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kado strengthens relationships & builds connections by making it easy for companies to maintain strong ties with customers, employees & partners.",
    "category": "eCommerce",
    "title": "Kado Integration With Cold Email Sales Tool",
    "h1": "Integrate Kado with SalesBlink",
    "metaDescription": "Integrate Kado and other eCommerce Tools with SalesBlink. Two Way Kado Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simple CRM",
    "slug": "simple-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0113aa6e6e803e1c71bd17f8465a90c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple CRM is a team collaboration tool to unite company data in one place",
    "category": "CRM (Customer Relationship Management)",
    "title": "Simple CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Simple CRM with SalesBlink",
    "metaDescription": "Integrate Simple CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Simple CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sensorpro",
    "slug": "sensorpro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6451f58b217864610a89658f0b2b48e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sensorpro is an EU-made email marketing platform.",
    "category": "Email",
    "title": "Sensorpro Integration With Cold Email Sales Tool",
    "h1": "Integrate Sensorpro with SalesBlink",
    "metaDescription": "Integrate Sensorpro and other Email Tools with SalesBlink. Two Way Sensorpro Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Good2Go",
    "slug": "good2go",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0b35327298e8b882087a40d09e48dd7d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Good2Go offers workflow automation for print — Connecting people, documents and time.",
    "category": "File Management & Storage",
    "title": "Good2Go Integration With Cold Email Sales Tool",
    "h1": "Integrate Good2Go with SalesBlink",
    "metaDescription": "Integrate Good2Go and other File Management & Storage Tools with SalesBlink. Two Way Good2Go Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSEdge",
    "slug": "smsedge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fa1d7fb4be15781070cefa5d26168a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSEdge is an SMS marketing platform for marketers who want to get the most out of their SMS campaigns.",
    "category": "Communication",
    "title": "SMSEdge Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSEdge with SalesBlink",
    "metaDescription": "Integrate SMSEdge and other Communication Tools with SalesBlink. Two Way SMSEdge Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "join.me",
    "slug": "joinme",
    "logo": "https://zapier-images.imgix.net/storage/developer/3c2819c960d6ca14553fabc8d78a95d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "join.me  by LogMeIn is the instant, intuitive online meeting and collaboration solution. With join.me users can share their screens, as well as host/attend audio and video meetings with ease. Integrate join.me into your workflow to save time, and stay connected with with coworkers and clients alike",
    "category": "Video Conferencing",
    "title": "join.me Integration With Cold Email Sales Tool",
    "h1": "Integrate join.me with SalesBlink",
    "metaDescription": "Integrate join.me and other Video Conferencing Tools with SalesBlink. Two Way join.me Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "World Options",
    "slug": "world-options",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb7e516dda8da62b8bca00345ea474f3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The World Options app provides merchants & businesses access to discounted shipping rates for both domestic and worldwide shipments.",
    "category": "eCommerce",
    "title": "World Options Integration With Cold Email Sales Tool",
    "h1": "Integrate World Options with SalesBlink",
    "metaDescription": "Integrate World Options and other eCommerce Tools with SalesBlink. Two Way World Options Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Review Generation Inc.",
    "slug": "review-generation-inc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/badee7d30757a9cdf6779f4d56345d15_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Review Generation Inc. is a CRM that allows you to send review requests to your customers via SMS and Email to keep your company's online reputation healthy.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Review Generation Inc. Integration With Cold Email Sales Tool",
    "h1": "Integrate Review Generation Inc. with SalesBlink",
    "metaDescription": "Integrate Review Generation Inc. and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Review Generation Inc. Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NetX",
    "slug": "netx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/37be0dffb50bf6a978a1a6d51aa5c20d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NetX is a Digital Asset Management application that empowers users to store, manage, and distribute their digital assets.",
    "category": "Content & Files",
    "title": "NetX Integration With Cold Email Sales Tool",
    "h1": "Integrate NetX with SalesBlink",
    "metaDescription": "Integrate NetX and other Content & Files Tools with SalesBlink. Two Way NetX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cleansify",
    "slug": "cleansify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0860f9b60d9f0b075b60d39a4e4d8e15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cleansify, an email validation service, helps you eliminate bounces and avoid spam traps by validating and verifying your emails.",
    "category": "Email",
    "title": "Cleansify Integration With Cold Email Sales Tool",
    "h1": "Integrate Cleansify with SalesBlink",
    "metaDescription": "Integrate Cleansify and other Email Tools with SalesBlink. Two Way Cleansify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FraudLabs Pro",
    "slug": "fraudlabs-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer/1e679b50e3bc60644bbef6157f945fbb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FraudLabs Pro helps merchants to protect their online stores from payment frauds.",
    "category": "eCommerce",
    "title": "FraudLabs Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate FraudLabs Pro with SalesBlink",
    "metaDescription": "Integrate FraudLabs Pro and other eCommerce Tools with SalesBlink. Two Way FraudLabs Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Glynk",
    "slug": "glynk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ba54998be6b2d43854dfcb0e71e597f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Glynk is a customer community platform, meticulously crafted by successful community makers.",
    "category": "Website & App Building",
    "title": "Glynk Integration With Cold Email Sales Tool",
    "h1": "Integrate Glynk with SalesBlink",
    "metaDescription": "Integrate Glynk and other Website & App Building Tools with SalesBlink. Two Way Glynk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docusnap365",
    "slug": "docusnap365",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c050725c291da0d7346139123d4d5ce7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docusnap is a software for automated network inventory, IT documentation, license management and permission analysis.",
    "category": "Analytics",
    "title": "Docusnap365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Docusnap365 with SalesBlink",
    "metaDescription": "Integrate Docusnap365 and other Analytics Tools with SalesBlink. Two Way Docusnap365 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Milestones",
    "slug": "milestones",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ead3f13c940e61edf9ce24036ae857b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Milestones allows law firms and attorneys the ability to keep their clients in the know with automatic updates on their case. With no additional work on the legal staff, Milestones helps deliver an exceptional client experience while also limiting unnecessary communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Milestones Integration With Cold Email Sales Tool",
    "h1": "Integrate Milestones with SalesBlink",
    "metaDescription": "Integrate Milestones and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Milestones Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KEABUILDER",
    "slug": "keabuilder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/099a7afa4c1b7eeb9aa4ee67e92d6ab7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keabuilder is an online platform that helps customer to grow their online business. It is a multi-platform of CRM/CMS.",
    "category": "CRM (Customer Relationship Management)",
    "title": "KEABUILDER Integration With Cold Email Sales Tool",
    "h1": "Integrate KEABUILDER with SalesBlink",
    "metaDescription": "Integrate KEABUILDER and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way KEABUILDER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blustream",
    "slug": "blustream",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/63871ea4fc8aec2a77f75f33d1b64039.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Blustream App enables post sales experiences by coinciding contacts and product purchases with the Blustream platform.",
    "category": "Marketing Automation",
    "title": "Blustream Integration With Cold Email Sales Tool",
    "h1": "Integrate Blustream with SalesBlink",
    "metaDescription": "Integrate Blustream and other Marketing Automation Tools with SalesBlink. Two Way Blustream Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CauseVid Essentials",
    "slug": "causevid-essentials",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49232e9e4647705a0b5b6edabfd3b391.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CauseVid makes it easy to send personalized video messages to wow donors and put a human face on every interaction.",
    "category": "Video & Audio",
    "title": "CauseVid Essentials Integration With Cold Email Sales Tool",
    "h1": "Integrate CauseVid Essentials with SalesBlink",
    "metaDescription": "Integrate CauseVid Essentials and other Video & Audio Tools with SalesBlink. Two Way CauseVid Essentials Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kendo Email",
    "slug": "kendo-email",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f8d70ab219f0502f3a1ba30c7c6bed2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kendo Email is a service that provides extra information on LinkedIn profiles.",
    "category": "Email",
    "title": "Kendo Email Integration With Cold Email Sales Tool",
    "h1": "Integrate Kendo Email with SalesBlink",
    "metaDescription": "Integrate Kendo Email and other Email Tools with SalesBlink. Two Way Kendo Email Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ultimeter",
    "slug": "ultimeter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6576b6c7aafa010b5ffdeb45bf95a706.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ultimeter is the most advanced goal meter and progress bar system for WordPress. You've done the hard work. Now display your success.",
    "category": "Website & App Building",
    "title": "Ultimeter Integration With Cold Email Sales Tool",
    "h1": "Integrate Ultimeter with SalesBlink",
    "metaDescription": "Integrate Ultimeter and other Website & App Building Tools with SalesBlink. Two Way Ultimeter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ToastWorx",
    "slug": "toastworx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cdb88ca21619b9504cef56a5f32278a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send instant, beautiful notifications to phones and tablets from any browser or web server",
    "category": "Notifications",
    "title": "ToastWorx Integration With Cold Email Sales Tool",
    "h1": "Integrate ToastWorx with SalesBlink",
    "metaDescription": "Integrate ToastWorx and other Notifications Tools with SalesBlink. Two Way ToastWorx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zenventory",
    "slug": "zenventory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/32629f6c50ddb65512720d37f77de18a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenventory is a warehouse operations platform to help online merchants ship orders fast and keep inventory accurate across all selling channels.",
    "category": "eCommerce",
    "title": "Zenventory Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenventory with SalesBlink",
    "metaDescription": "Integrate Zenventory and other eCommerce Tools with SalesBlink. Two Way Zenventory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RemOnline",
    "slug": "remonline",
    "logo": "https://zapier-images.imgix.net/storage/services/66359f7c6e09c1a77a301688555687f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RemOnline Is End-To-End Software For Your Service Business Or Repair Shop",
    "category": "CRM (Customer Relationship Management)",
    "title": "RemOnline Integration With Cold Email Sales Tool",
    "h1": "Integrate RemOnline with SalesBlink",
    "metaDescription": "Integrate RemOnline and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way RemOnline Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrueMail",
    "slug": "truemail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9a07e3ae659e3e9dcf840d7ffed9ca7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrueMail is an email verification service that helps businesses to improve deliverability.",
    "category": "Email",
    "title": "TrueMail Integration With Cold Email Sales Tool",
    "h1": "Integrate TrueMail with SalesBlink",
    "metaDescription": "Integrate TrueMail and other Email Tools with SalesBlink. Two Way TrueMail Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "LicitaLab",
    "slug": "licitalab",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05ec1030a6bd6075901d8da00982392c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gana el doble de licitaciones invirtiendo la mitad del tiempo.",
    "category": "Sales & CRM",
    "title": "LicitaLab Integration With Cold Email Sales Tool",
    "h1": "Integrate LicitaLab with SalesBlink",
    "metaDescription": "Integrate LicitaLab and other Sales & CRM Tools with SalesBlink. Two Way LicitaLab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RevenueJump",
    "slug": "revenuejump",
    "logo": "https://zapier-images.imgix.net/storage/services/5489dad6f55c9ec6faca2a30ee933af2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RevenueJump is a web application that helps companies maintain and grow their 5-star online reputation.",
    "category": "Marketing",
    "title": "RevenueJump Integration With Cold Email Sales Tool",
    "h1": "Integrate RevenueJump with SalesBlink",
    "metaDescription": "Integrate RevenueJump and other Marketing Tools with SalesBlink. Two Way RevenueJump Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShareDocView",
    "slug": "sharedocview",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bfeb20597bdb522befd49b277a6fe0b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShareDocView is a document sharing platform for lead generation through content marketing.",
    "category": "Content & Files",
    "title": "ShareDocView Integration With Cold Email Sales Tool",
    "h1": "Integrate ShareDocView with SalesBlink",
    "metaDescription": "Integrate ShareDocView and other Content & Files Tools with SalesBlink. Two Way ShareDocView Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ServiceWorks",
    "slug": "serviceworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8e266ebcbbae9b1b2ece2672f94d7566.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceWorks is a Field Service Management Platform",
    "category": "Proposal & Invoice Management",
    "title": "ServiceWorks Integration With Cold Email Sales Tool",
    "h1": "Integrate ServiceWorks with SalesBlink",
    "metaDescription": "Integrate ServiceWorks and other Proposal & Invoice Management Tools with SalesBlink. Two Way ServiceWorks Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Bytesafe",
    "slug": "bytesafe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7263f42f49f2d638d03b959a9b41109.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bytesafe is a security platform that protects you from open source software supply chain attacks.",
    "category": "Security & Identity Tools",
    "title": "Bytesafe Integration With Cold Email Sales Tool",
    "h1": "Integrate Bytesafe with SalesBlink",
    "metaDescription": "Integrate Bytesafe and other Security & Identity Tools Tools with SalesBlink. Two Way Bytesafe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Learnifier",
    "slug": "learnifier",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/47912f4fcc5a4c43ae4a28e8c13beeda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learnifier is a multi-featured tool that makes it easy to create online courses, onboarding programs or even a learning academy. You get the best of two worlds. Technology simplified, learning amplified.",
    "category": "Online Courses",
    "title": "Learnifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Learnifier with SalesBlink",
    "metaDescription": "Integrate Learnifier and other Online Courses Tools with SalesBlink. Two Way Learnifier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HyperDone",
    "slug": "hyperdone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e98cb54078ab583fe0c00e693ff32b73.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HyperDone is a team collaboration CRM tool that helps you organize work and life.",
    "category": "Team Collaboration",
    "title": "HyperDone Integration With Cold Email Sales Tool",
    "h1": "Integrate HyperDone with SalesBlink",
    "metaDescription": "Integrate HyperDone and other Team Collaboration Tools with SalesBlink. Two Way HyperDone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cognism Prospector",
    "slug": "cognism-prospector",
    "logo": "https://zapier-images.imgix.net/storage/services/059b9132ed5b184886284bb949928768.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cognism Prospector is the number one solution for B2B salespeople looking to improve their data quality and generate better quality leads. Gain access to a global, GDPR compliant database of B2B leads drawn from any industry you can think of.",
    "category": "Marketing Automation",
    "title": "Cognism Prospector Integration With Cold Email Sales Tool",
    "h1": "Integrate Cognism Prospector with SalesBlink",
    "metaDescription": "Integrate Cognism Prospector and other Marketing Automation Tools with SalesBlink. Two Way Cognism Prospector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Segretaria24.it",
    "slug": "segretaria24",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5efab7de8b2998e226e67fcee8cb49c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Segretaria24.it is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business.",
    "category": "Communication",
    "title": "Segretaria24.it Integration With Cold Email Sales Tool",
    "h1": "Integrate Segretaria24.it with SalesBlink",
    "metaDescription": "Integrate Segretaria24.it and other Communication Tools with SalesBlink. Two Way Segretaria24.it Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Projectworks",
    "slug": "projectworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f1c7e6f2031f470d9e1e4358d4685ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Projectworks is an intelligent business management software for your entire professional services company.",
    "category": "Project Management",
    "title": "Projectworks Integration With Cold Email Sales Tool",
    "h1": "Integrate Projectworks with SalesBlink",
    "metaDescription": "Integrate Projectworks and other Project Management Tools with SalesBlink. Two Way Projectworks Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Viafirma",
    "slug": "viafirma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1da01e773fa4cdce7f2ec2feafff991.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Viafirma is an electronic signature solution for large and small teams.",
    "category": "Signatures",
    "title": "Viafirma Integration With Cold Email Sales Tool",
    "h1": "Integrate Viafirma with SalesBlink",
    "metaDescription": "Integrate Viafirma and other Signatures Tools with SalesBlink. Two Way Viafirma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClientRock",
    "slug": "clientrock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ac4b66db9435adc8dfcfbc06e9f0b59_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClientRock is an online booking tool made for attorneys to book consultations and get paid all in one step.",
    "category": "Scheduling & Booking",
    "title": "ClientRock Integration With Cold Email Sales Tool",
    "h1": "Integrate ClientRock with SalesBlink",
    "metaDescription": "Integrate ClientRock and other Scheduling & Booking Tools with SalesBlink. Two Way ClientRock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mighty Tix",
    "slug": "mighty-tix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dcc1ea5c45cf1df8d8b3cb63bf87b39c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mighty Tix is an event ticketing service with no per-ticket fees, white-label customization, and direct cashflow to organizers via Stripe.",
    "category": "Event Management",
    "title": "Mighty Tix Integration With Cold Email Sales Tool",
    "h1": "Integrate Mighty Tix with SalesBlink",
    "metaDescription": "Integrate Mighty Tix and other Event Management Tools with SalesBlink. Two Way Mighty Tix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eRelocation",
    "slug": "erelocation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e95873a172f638b0beaac22db8b2571e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eRelocation is a web-based relocation department management solution for real estate brokerages.",
    "category": "CRM (Customer Relationship Management)",
    "title": "eRelocation Integration With Cold Email Sales Tool",
    "h1": "Integrate eRelocation with SalesBlink",
    "metaDescription": "Integrate eRelocation and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way eRelocation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesgear",
    "slug": "salesgear",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa4115734d2a01ba18a5cc5fc4a64416.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesgear helps sales teams send emails, make calls, and connect via LinkedIn using a single platform.",
    "category": "Sales & CRM",
    "title": "Salesgear Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesgear with SalesBlink",
    "metaDescription": "Integrate Salesgear and other Sales & CRM Tools with SalesBlink. Two Way Salesgear Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omniconvert",
    "slug": "omniconvert",
    "logo": "https://zapier-images.imgix.net/storage/developer/33e5474f1a278407a0d6f734c6eb4f4d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omniconvert delivers the software, services & know-how to empower retailers to become customer-centric; from acquisition to retention.",
    "category": "Forms & Surveys",
    "title": "Omniconvert Integration With Cold Email Sales Tool",
    "h1": "Integrate Omniconvert with SalesBlink",
    "metaDescription": "Integrate Omniconvert and other Forms & Surveys Tools with SalesBlink. Two Way Omniconvert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DreamzIoT",
    "slug": "dreamziot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df210155c7ace4243723f293e7b78dcc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DreamzIoT is a secured Internet of Things (IoT) solution to increase your productivity without having to build a software or think about infrastructure.",
    "category": "Internet of Things",
    "title": "DreamzIoT Integration With Cold Email Sales Tool",
    "h1": "Integrate DreamzIoT with SalesBlink",
    "metaDescription": "Integrate DreamzIoT and other Internet of Things Tools with SalesBlink. Two Way DreamzIoT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FieldGoal",
    "slug": "fieldgoal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8e7bc155ac2d1b3463eec300c15cbf78_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FieldGoal provides form endpoints as a service, so your simple sites can stay simple.",
    "category": "Forms & Surveys",
    "title": "FieldGoal Integration With Cold Email Sales Tool",
    "h1": "Integrate FieldGoal with SalesBlink",
    "metaDescription": "Integrate FieldGoal and other Forms & Surveys Tools with SalesBlink. Two Way FieldGoal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneClick Code",
    "slug": "oneclick-code",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/29c6f999e2b3bd8f0614c10bed588577.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneClick Code is a tool used to quickly identify building and roofing codes relevant to home repairs for contractors,  insurers, and others.",
    "category": "Documents",
    "title": "OneClick Code Integration With Cold Email Sales Tool",
    "h1": "Integrate OneClick Code with SalesBlink",
    "metaDescription": "Integrate OneClick Code and other Documents Tools with SalesBlink. Two Way OneClick Code Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evalinator",
    "slug": "evalinator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e950908935f2e14b02bbff55549c374.PNG?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evalinator lets you create interactive assessments to use with your clients and prospects. You can add them to your website to personalize your customer engagement and account growth.",
    "category": "Sales & CRM",
    "title": "Evalinator Integration With Cold Email Sales Tool",
    "h1": "Integrate Evalinator with SalesBlink",
    "metaDescription": "Integrate Evalinator and other Sales & CRM Tools with SalesBlink. Two Way Evalinator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Starion",
    "slug": "starion",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5189659435eaf587bee9e5e3bf87fd59.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build dynamic mobile apps effortlessly with Starion, a no-code platform empowering users to create, customize, and share apps seamlessly.",
    "category": "App Builder",
    "title": "Starion Integration With Cold Email Sales Tool",
    "h1": "Integrate Starion with SalesBlink",
    "metaDescription": "Integrate Starion and other App Builder Tools with SalesBlink. Two Way Starion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Switchboard",
    "slug": "switchboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/752418e2fa5aba70c889afda0d18e9cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Switchboard enables you to build deeper relationships with customers through SMS, MMS and Video Texting.",
    "category": "Phone & SMS",
    "title": "Switchboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Switchboard with SalesBlink",
    "metaDescription": "Integrate Switchboard and other Phone & SMS Tools with SalesBlink. Two Way Switchboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superbot",
    "slug": "superbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f446545ce99a92c8a0bb0838c4540803.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An Intelligent Voice Agent capable of having Smart Dialogues with Humans over Telephony Channels.",
    "category": "Communication",
    "title": "Superbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Superbot with SalesBlink",
    "metaDescription": "Integrate Superbot and other Communication Tools with SalesBlink. Two Way Superbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sonderplan",
    "slug": "sonderplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87d8eb45ac75164317805d92c732f5b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sonderplan is an affordable planning tool for scheduling people, rooms, & equipment",
    "category": "Scheduling & Booking",
    "title": "Sonderplan Integration With Cold Email Sales Tool",
    "h1": "Integrate Sonderplan with SalesBlink",
    "metaDescription": "Integrate Sonderplan and other Scheduling & Booking Tools with SalesBlink. Two Way Sonderplan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TimeCamp Planner",
    "slug": "timecampplanner",
    "logo": "https://zapier-images.imgix.net/storage/services/ba61907165991acbf9a29b72e1682257.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeCamp Planner is a software that allows to plan resources, make timelines, manage teams and tasks, and track progress.",
    "category": "Team Collaboration",
    "title": "TimeCamp Planner Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeCamp Planner with SalesBlink",
    "metaDescription": "Integrate TimeCamp Planner and other Team Collaboration Tools with SalesBlink. Two Way TimeCamp Planner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agiliron",
    "slug": "agiliron",
    "logo": "https://zapier-images.imgix.net/storage/services/983cb63b257ae247ae65731698a55f2c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agiliron is a SaaS platform for Omni-Channel Commerce focused on helping Product Based Businesses (Retail, Wholesale, Distribution, eCommerce) \"Sell More in More Places. But Manage in One\".",
    "category": "eCommerce",
    "title": "Agiliron Integration With Cold Email Sales Tool",
    "h1": "Integrate Agiliron with SalesBlink",
    "metaDescription": "Integrate Agiliron and other eCommerce Tools with SalesBlink. Two Way Agiliron Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alterdesk",
    "slug": "alterdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer/52e65960165a95a133af34fbcfd1ea6d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alterdesk is a user-friendly, secure messenger for teams. Send messages and files to your co-workers and other business contacts, using any available device!",
    "category": "Team Chat",
    "title": "Alterdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Alterdesk with SalesBlink",
    "metaDescription": "Integrate Alterdesk and other Team Chat Tools with SalesBlink. Two Way Alterdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Handbid",
    "slug": "handbid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e163ea77144b1e631680e5280349cc38.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handbid is an online mobile bidding and fundraising platform for charity and commercial auctions and events.  Setup an auction or fundraiser in Handbid and allow users to bid or donate through the Handbid apps for iPhone, Android, and web.",
    "category": "Fundraising",
    "title": "Handbid Integration With Cold Email Sales Tool",
    "h1": "Integrate Handbid with SalesBlink",
    "metaDescription": "Integrate Handbid and other Fundraising Tools with SalesBlink. Two Way Handbid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jampack AI",
    "slug": "jampack-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ea0033fe6a88fe51c93942c21872a43_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jampack enables you to easily add AI into your workflows — so you can increase operational efficiency.",
    "category": "Business Intelligence",
    "title": "Jampack AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Jampack AI with SalesBlink",
    "metaDescription": "Integrate Jampack AI and other Business Intelligence Tools with SalesBlink. Two Way Jampack AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Treeapp",
    "slug": "treeapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa47052830c0c6307d0edebd5021493f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your positive impact on the world. Plant a tree for every order, each user, every employee, etc. with Treeapp.",
    "category": "Commerce",
    "title": "Treeapp Integration With Cold Email Sales Tool",
    "h1": "Integrate Treeapp with SalesBlink",
    "metaDescription": "Integrate Treeapp and other Commerce Tools with SalesBlink. Two Way Treeapp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rolldog",
    "slug": "rolldog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c214fc5284ae665cc95655d896db462.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rolldog is a CRM designed to manage your business relationships, increase revenue and boost sales effectiveness.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Rolldog Integration With Cold Email Sales Tool",
    "h1": "Integrate Rolldog with SalesBlink",
    "metaDescription": "Integrate Rolldog and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Rolldog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesPark",
    "slug": "salesparkio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a81b575af3616b34de2866682b3bb95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payment solution for small businesses in the Portuguese market",
    "category": "Sales & CRM",
    "title": "SalesPark Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesPark with SalesBlink",
    "metaDescription": "Integrate SalesPark and other Sales & CRM Tools with SalesBlink. Two Way SalesPark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UUKI",
    "slug": "uuki",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1797c0f5ca8c4fc14e91565db0c600f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UUKI is a Community Platform for Creators, Brands & Products",
    "category": "Communication",
    "title": "UUKI Integration With Cold Email Sales Tool",
    "h1": "Integrate UUKI with SalesBlink",
    "metaDescription": "Integrate UUKI and other Communication Tools with SalesBlink. Two Way UUKI Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Connect the Doc",
    "slug": "connect-the-doc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13dcce76f735c7d51f80eec81b37ed09.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We connect patients with healthcare providers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Connect the Doc Integration With Cold Email Sales Tool",
    "h1": "Integrate Connect the Doc with SalesBlink",
    "metaDescription": "Integrate Connect the Doc and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Connect the Doc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StoreHippo",
    "slug": "storehippo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f813ac9353c3457701c15c0910391040.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoreHippo is the new age eCommerce platform that provides you everything that you need to sell your products or services online.",
    "category": "eCommerce",
    "title": "StoreHippo Integration With Cold Email Sales Tool",
    "h1": "Integrate StoreHippo with SalesBlink",
    "metaDescription": "Integrate StoreHippo and other eCommerce Tools with SalesBlink. Two Way StoreHippo Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Rally Corp",
    "slug": "rally-corp",
    "logo": "https://zapier-images.imgix.net/storage/services/968f1ad4e77be5a0962637c155ed0cbd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rally is for Charities & Causes Who Make a Lasting Impact. Use conversational text messaging to forge unbreakable relationships with donors and volunteers.",
    "category": "Fundraising",
    "title": "Rally Corp Integration With Cold Email Sales Tool",
    "h1": "Integrate Rally Corp with SalesBlink",
    "metaDescription": "Integrate Rally Corp and other Fundraising Tools with SalesBlink. Two Way Rally Corp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Client Diary",
    "slug": "client-diary",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d03fd4e28fb2f0a71f09420d0e2f80c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Client Diary is a booking & business management app to deliver an exceptional client experience with appointments management and drive your business.",
    "category": "Scheduling & Booking",
    "title": "Client Diary Integration With Cold Email Sales Tool",
    "h1": "Integrate Client Diary with SalesBlink",
    "metaDescription": "Integrate Client Diary and other Scheduling & Booking Tools with SalesBlink. Two Way Client Diary Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Behavior Sales",
    "slug": "behavior-sales",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/525d7ee2779d47b2a8e77650291ef853.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Personalize Your Sales using AI without Complicated Personality Testing!",
    "category": "Business Intelligence",
    "title": "Behavior Sales Integration With Cold Email Sales Tool",
    "h1": "Integrate Behavior Sales with SalesBlink",
    "metaDescription": "Integrate Behavior Sales and other Business Intelligence Tools with SalesBlink. Two Way Behavior Sales Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Talkwalker",
    "slug": "talkwalker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0315a0ba1b0feeb259bbf04acb772a76.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Talkwalker delivers the social insights that help brands build growth.",
    "category": "Analytics",
    "title": "Talkwalker Integration With Cold Email Sales Tool",
    "h1": "Integrate Talkwalker with SalesBlink",
    "metaDescription": "Integrate Talkwalker and other Analytics Tools with SalesBlink. Two Way Talkwalker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coupon River",
    "slug": "coupon-river",
    "logo": "https://zapier-images.imgix.net/storage/developer/4e0d8b45227c28ff20c60117f261de83.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coupon River is a unique coupon code distributtion service for your Amazon or e-commerce business.",
    "category": "eCommerce",
    "title": "Coupon River Integration With Cold Email Sales Tool",
    "h1": "Integrate Coupon River with SalesBlink",
    "metaDescription": "Integrate Coupon River and other eCommerce Tools with SalesBlink. Two Way Coupon River Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lighthouse By Cloudify",
    "slug": "lighthouse-by-cloudify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e3a9443aa67d7c25a40354dc9d04c52.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lighthouse is a real-time monitoring tool for all automated and manual business processes.",
    "category": "Developer Tools",
    "title": "Lighthouse By Cloudify Integration With Cold Email Sales Tool",
    "h1": "Integrate Lighthouse By Cloudify with SalesBlink",
    "metaDescription": "Integrate Lighthouse By Cloudify and other Developer Tools Tools with SalesBlink. Two Way Lighthouse By Cloudify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TiDB Cloud",
    "slug": "tidb-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b6d046677ed0cb8fd7114e66d50b1dc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TiDB Cloud is a fully-managed Database-as-a-Service (DBaaS) that brings TiDB, a MySQL-Compatible serverless HTAP database, to your business.",
    "category": "Databases",
    "title": "TiDB Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate TiDB Cloud with SalesBlink",
    "metaDescription": "Integrate TiDB Cloud and other Databases Tools with SalesBlink. Two Way TiDB Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MerrenIO",
    "slug": "merrenio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c4c93b8994af721c081cb7f5fb45b95_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MerrenIO is a service that helps users to conduct customer experience surveys through WhatsApp.",
    "category": "Business Intelligence",
    "title": "MerrenIO Integration With Cold Email Sales Tool",
    "h1": "Integrate MerrenIO with SalesBlink",
    "metaDescription": "Integrate MerrenIO and other Business Intelligence Tools with SalesBlink. Two Way MerrenIO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gather",
    "slug": "gather",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc19a8dde24c778b1e2fc8d216f4dbe2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gather is a platform for learning that brings people together.",
    "category": "Education",
    "title": "Gather Integration With Cold Email Sales Tool",
    "h1": "Integrate Gather with SalesBlink",
    "metaDescription": "Integrate Gather and other Education Tools with SalesBlink. Two Way Gather Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teamhood",
    "slug": "teamhood",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aaa3c1b024d2df9d25295a9239981408.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teamhood is visual work management tool for high performing teams.",
    "category": "Project Management",
    "title": "Teamhood Integration With Cold Email Sales Tool",
    "h1": "Integrate Teamhood with SalesBlink",
    "metaDescription": "Integrate Teamhood and other Project Management Tools with SalesBlink. Two Way Teamhood Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pepper Content",
    "slug": "pepper-content",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c86a0f7c6928262a163f0959c1bdaad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pepper Content is a marketplace for professional content creators.",
    "category": "Content & Files",
    "title": "Pepper Content Integration With Cold Email Sales Tool",
    "h1": "Integrate Pepper Content with SalesBlink",
    "metaDescription": "Integrate Pepper Content and other Content & Files Tools with SalesBlink. Two Way Pepper Content Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClickSend Letter Campaign",
    "slug": "clicksend-letter-campaign-ca1705",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd5f4022fda4a5caed8ae27548b27566_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send personalized bulk letters globally.",
    "category": "Communication",
    "title": "ClickSend Letter Campaign Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickSend Letter Campaign with SalesBlink",
    "metaDescription": "Integrate ClickSend Letter Campaign and other Communication Tools with SalesBlink. Two Way ClickSend Letter Campaign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teachmore",
    "slug": "teachmore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd02bc72da44a54978d0adb76365f4ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create and sell online courses with your own fully-featured mobile apps and website. Don't miss out on sales for not having mobile apps!",
    "category": "Online Courses",
    "title": "Teachmore Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachmore with SalesBlink",
    "metaDescription": "Integrate Teachmore and other Online Courses Tools with SalesBlink. Two Way Teachmore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flatwork ATS",
    "slug": "flatwork-ats",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5bbe7c5e8f731bf4b692c37e6d44999a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flatwork ATS is an all-in-one recruitment software for startups and recruiters to organize and speed up their hiring pipeline.",
    "category": "HR Talent & Recruitment",
    "title": "Flatwork ATS Integration With Cold Email Sales Tool",
    "h1": "Integrate Flatwork ATS with SalesBlink",
    "metaDescription": "Integrate Flatwork ATS and other HR Talent & Recruitment Tools with SalesBlink. Two Way Flatwork ATS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ambee",
    "slug": "ambee",
    "logo": "https://zapier-images.imgix.net/storage/services/f09887e581714b295ee018b3c7cde984.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ambee fuses the power of thousands of on-ground sensor data and hundreds of remote imagery from satellites. We analyze environmental factors such as air quality, soil, micro weather, pollen, and more to help millions worldwide stay safe and protect themselves.",
    "category": "Marketing Automation",
    "title": "Ambee Integration With Cold Email Sales Tool",
    "h1": "Integrate Ambee with SalesBlink",
    "metaDescription": "Integrate Ambee and other Marketing Automation Tools with SalesBlink. Two Way Ambee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Archie CRM",
    "slug": "archie-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ec2d863c0020d9b047519dd2b6bef11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Archie is a CRM software suite developed in the Netherlands.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Archie CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Archie CRM with SalesBlink",
    "metaDescription": "Integrate Archie CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Archie CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deep Talk",
    "slug": "deep-talk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1d8c0a4503a0849d416d35b70ce5c1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deep Talk is a no code AI platform to get value from your unstructured text data.",
    "category": "Analytics",
    "title": "Deep Talk Integration With Cold Email Sales Tool",
    "h1": "Integrate Deep Talk with SalesBlink",
    "metaDescription": "Integrate Deep Talk and other Analytics Tools with SalesBlink. Two Way Deep Talk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Howuku",
    "slug": "howuku",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/149fd6e5f24708728d20491e94b910de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Howuku is a UX testing tool to help business collects feedback and records user behavior.",
    "category": "Analytics",
    "title": "Howuku Integration With Cold Email Sales Tool",
    "h1": "Integrate Howuku with SalesBlink",
    "metaDescription": "Integrate Howuku and other Analytics Tools with SalesBlink. Two Way Howuku Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "NoiseAware",
    "slug": "noiseaware",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c829b4ce2822cfb23bb48bd022082c0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NoiseAware provides noise-monitoring solutions for property owners and professional property managers.",
    "category": "Internet of Things",
    "title": "NoiseAware Integration With Cold Email Sales Tool",
    "h1": "Integrate NoiseAware with SalesBlink",
    "metaDescription": "Integrate NoiseAware and other Internet of Things Tools with SalesBlink. Two Way NoiseAware Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loop & Tie",
    "slug": "loop-and-tie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cdf6967ce3218dbf5da642b0e4e28704_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loop & Tie is a gifting-at-scale engagement platform, which allows gifters to send a list of curated gifts that a recipient can choose from.",
    "category": "Marketing",
    "title": "Loop & Tie Integration With Cold Email Sales Tool",
    "h1": "Integrate Loop & Tie with SalesBlink",
    "metaDescription": "Integrate Loop & Tie and other Marketing Tools with SalesBlink. Two Way Loop & Tie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NPS.Today",
    "slug": "npstoday",
    "logo": "https://zapier-images.imgix.net/storage/services/36cb1a0c8e67111f7396895a7f4ff0df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn on your customer experience radar and keep it turned on. Make your loyalty program simple and effective with NPS. Get real-time customer feedback for improved customer interaction and customer experience transformation.",
    "category": "Customer Appreciation",
    "title": "NPS.Today Integration With Cold Email Sales Tool",
    "h1": "Integrate NPS.Today with SalesBlink",
    "metaDescription": "Integrate NPS.Today and other Customer Appreciation Tools with SalesBlink. Two Way NPS.Today Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pinch Payments",
    "slug": "pinch-payments",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05d978a3c1abb2886d02a9ea7c61c092.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pinch Payments is a PCI Compliant, Australian payments platform offering automated bank account, direct debit, and credit card payments for standard and repeating invoices or standalone transactions.",
    "category": "Payment Processing",
    "title": "Pinch Payments Integration With Cold Email Sales Tool",
    "h1": "Integrate Pinch Payments with SalesBlink",
    "metaDescription": "Integrate Pinch Payments and other Payment Processing Tools with SalesBlink. Two Way Pinch Payments Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Syft Analytics",
    "slug": "syft-analytics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c4e39e8f0f21ae2dc01f75bbe34d474.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Syft Analytics is the interactive & collaborative financial reporting tool.",
    "category": "Dashboards",
    "title": "Syft Analytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Syft Analytics with SalesBlink",
    "metaDescription": "Integrate Syft Analytics and other Dashboards Tools with SalesBlink. Two Way Syft Analytics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Volusion",
    "slug": "volusion",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e81ad6ae727591199646796432e2df8d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Volusion is an all-in-one leading ecommerce solution that lets you easily create, manage, and expand your online store.",
    "category": "eCommerce",
    "title": "Volusion Integration With Cold Email Sales Tool",
    "h1": "Integrate Volusion with SalesBlink",
    "metaDescription": "Integrate Volusion and other eCommerce Tools with SalesBlink. Two Way Volusion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Univid",
    "slug": "univid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0393560d7f941d8bc9c99ea0568a466.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Univid is a webinar platform that lets you create engaging webinars in less than 60 seconds. Go live, get a ton of engagement, and drive conversions.",
    "category": "Webinars",
    "title": "Univid Integration With Cold Email Sales Tool",
    "h1": "Integrate Univid with SalesBlink",
    "metaDescription": "Integrate Univid and other Webinars Tools with SalesBlink. Two Way Univid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plate",
    "slug": "plate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11209d4a90a0d614bc90e5101a26c0f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plate is a content management platform used to create, manage, analyse, and improve content in multi-channels such as websites, shops, apps, portals, and more.",
    "category": "Website & App Building",
    "title": "Plate Integration With Cold Email Sales Tool",
    "h1": "Integrate Plate with SalesBlink",
    "metaDescription": "Integrate Plate and other Website & App Building Tools with SalesBlink. Two Way Plate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "XintelWeb",
    "slug": "xintelweb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1f6927cda040539853d164e1e33d2cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keep track of the contacts that come in from different media in the Xintelweb CRM.",
    "category": "Contact Management",
    "title": "XintelWeb Integration With Cold Email Sales Tool",
    "h1": "Integrate XintelWeb with SalesBlink",
    "metaDescription": "Integrate XintelWeb and other Contact Management Tools with SalesBlink. Two Way XintelWeb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The.com",
    "slug": "thecom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c498f2c1cdf5eb72ab9b0e7f539503f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The.com is where you make your websites. It's where you share your work with others. It's where the next generation of creators are rebuilding the internet.",
    "category": "Website Builders",
    "title": "The.com Integration With Cold Email Sales Tool",
    "h1": "Integrate The.com with SalesBlink",
    "metaDescription": "Integrate The.com and other Website Builders Tools with SalesBlink. Two Way The.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mirabel's Marketing Manager",
    "slug": "mirabels-marketing-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer/0061eb9a6a522de168f06fc47879d299.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Marketing Manager identifies who is visiting your website and who is most interested with marketing CRM software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mirabel's Marketing Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Mirabel's Marketing Manager with SalesBlink",
    "metaDescription": "Integrate Mirabel's Marketing Manager and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mirabel's Marketing Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MYFUNDBOX",
    "slug": "myfundbox-subscription-billing-c",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/724ca6b3e8ffd9de33cf38ef34ce468a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MYFUNDBOX Subscription Billing  Platform is the simplest way to Accept, Process, Manage subscriptions Billing  securely.",
    "category": "Payment Processing",
    "title": "MYFUNDBOX Integration With Cold Email Sales Tool",
    "h1": "Integrate MYFUNDBOX with SalesBlink",
    "metaDescription": "Integrate MYFUNDBOX and other Payment Processing Tools with SalesBlink. Two Way MYFUNDBOX Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Nashpush",
    "slug": "nashpush",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e01b52d097fd45cd9ebfafff454b4fc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nashpush is a push notifications platform letting you to stay in touch with your subscribers.",
    "category": "Notifications",
    "title": "Nashpush Integration With Cold Email Sales Tool",
    "h1": "Integrate Nashpush with SalesBlink",
    "metaDescription": "Integrate Nashpush and other Notifications Tools with SalesBlink. Two Way Nashpush Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Techpacker",
    "slug": "techpacker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b917b04f446dac70357b901289f8f3d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Techpacker is a service that makes it easy for fashion brands to create techpacks, manage product development and work together with manufacturers.",
    "category": "Commerce",
    "title": "Techpacker Integration With Cold Email Sales Tool",
    "h1": "Integrate Techpacker with SalesBlink",
    "metaDescription": "Integrate Techpacker and other Commerce Tools with SalesBlink. Two Way Techpacker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MediaSilo Legacy",
    "slug": "mediasilo-legacy",
    "logo": "https://zapier-images.imgix.net/storage/developer/12107bf811393cc0d2494883f4aa7f34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MediaSilo Legacy's plug-and-play video management platform sits in the middle of your production process, working with your existing tools and workflows to keep your team connected, your assets secure and all your bases covered.",
    "category": "Video & Audio",
    "title": "MediaSilo Legacy Integration With Cold Email Sales Tool",
    "h1": "Integrate MediaSilo Legacy with SalesBlink",
    "metaDescription": "Integrate MediaSilo Legacy and other Video & Audio Tools with SalesBlink. Two Way MediaSilo Legacy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Insurgo",
    "slug": "insurgo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/df65d8382ca0f34eeb9eb4f896b6f8ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "As an innovative cloud-based broker management program, Insurgo is the timely all-in-one solution for digitized brokerage firms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Insurgo Integration With Cold Email Sales Tool",
    "h1": "Integrate Insurgo with SalesBlink",
    "metaDescription": "Integrate Insurgo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Insurgo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Persio",
    "slug": "persio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5218cffae3e16aa3a7993826e0191345.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Persio exists to help businesses unify and mesh all their customer data in a single source of truth platform and auto-qualify their leads.",
    "category": "Databases",
    "title": "Persio Integration With Cold Email Sales Tool",
    "h1": "Integrate Persio with SalesBlink",
    "metaDescription": "Integrate Persio and other Databases Tools with SalesBlink. Two Way Persio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Delesign",
    "slug": "delesign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b2637b7e34c6ce2d13a9f0e84e5005c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Delesign provides you with a dedicated senior-level designer. For a flat monthly rate, complete all your graphic design, animation, web & app, UI/UX, and illustration projects (and more) today.",
    "category": "Project Management",
    "title": "Delesign Integration With Cold Email Sales Tool",
    "h1": "Integrate Delesign with SalesBlink",
    "metaDescription": "Integrate Delesign and other Project Management Tools with SalesBlink. Two Way Delesign Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ReviewSender",
    "slug": "reviewsender",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad7655438b6b2eecfb603afaf534c64a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReviewSender notifies you about new reviews on Amazon, Walmart, Target and many other sites.",
    "category": "eCommerce",
    "title": "ReviewSender Integration With Cold Email Sales Tool",
    "h1": "Integrate ReviewSender with SalesBlink",
    "metaDescription": "Integrate ReviewSender and other eCommerce Tools with SalesBlink. Two Way ReviewSender Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailinator",
    "slug": "mailinator",
    "logo": "https://zapier-images.imgix.net/storage/services/d7639f6b4c9f39aa066c53e0cf98d7a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailinator is an email app that allows you to post messages into your private domain.",
    "category": "Email",
    "title": "Mailinator Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailinator with SalesBlink",
    "metaDescription": "Integrate Mailinator and other Email Tools with SalesBlink. Two Way Mailinator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sensible",
    "slug": "sensible",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a1762abb817d22b55e726d65120d854_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sensible is an API that transforms documents into structured data",
    "category": "Documents",
    "title": "Sensible Integration With Cold Email Sales Tool",
    "h1": "Integrate Sensible with SalesBlink",
    "metaDescription": "Integrate Sensible and other Documents Tools with SalesBlink. Two Way Sensible Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Audius",
    "slug": "audius-ca120385",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/304f32ba55278b8f653fc5642d50a18d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Audius is a brand-new streaming platform built for all musicians, not just those signed to labels. Build a fanbase, share your works in progress, and then publish your completed tracks for all the world to hear.",
    "category": "Video & Audio",
    "title": "Audius Integration With Cold Email Sales Tool",
    "h1": "Integrate Audius with SalesBlink",
    "metaDescription": "Integrate Audius and other Video & Audio Tools with SalesBlink. Two Way Audius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pingen",
    "slug": "pingen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/463b6d8b14a5487a2d734d4c97b1a0d5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send documents via postmail worldwide at reasonable prices",
    "category": "Content & Files",
    "title": "Pingen Integration With Cold Email Sales Tool",
    "h1": "Integrate Pingen with SalesBlink",
    "metaDescription": "Integrate Pingen and other Content & Files Tools with SalesBlink. Two Way Pingen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ruuster",
    "slug": "ruuster",
    "logo": "https://zapier-images.imgix.net/storage/services/9d5e228b8dbae48829cb2489f0b3e0b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ruuster is a home buying management platform for agents and clients to learn, plan, budget, and hire - all in one place.",
    "category": "Sales & CRM",
    "title": "Ruuster Integration With Cold Email Sales Tool",
    "h1": "Integrate Ruuster with SalesBlink",
    "metaDescription": "Integrate Ruuster and other Sales & CRM Tools with SalesBlink. Two Way Ruuster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeliverLogic",
    "slug": "deliverlogic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cc10da9a7b4b484e7a6931b4f7775187.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeliverLogic is an industry leading logistics platforms designed to support Restaurant Delivery services, local restaurants, and other logistics and delivery services.",
    "category": "eCommerce",
    "title": "DeliverLogic Integration With Cold Email Sales Tool",
    "h1": "Integrate DeliverLogic with SalesBlink",
    "metaDescription": "Integrate DeliverLogic and other eCommerce Tools with SalesBlink. Two Way DeliverLogic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Recruiters*Unite",
    "slug": "recruiters-unite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85717842210295ffd825a126a87dad09.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Recruiters*Unite is a smart search engine dedicated to CV-Search. Use big data and AI-technology to manager and search your talent pool.",
    "category": "HR Talent & Recruitment",
    "title": "Recruiters*Unite Integration With Cold Email Sales Tool",
    "h1": "Integrate Recruiters*Unite with SalesBlink",
    "metaDescription": "Integrate Recruiters*Unite and other HR Talent & Recruitment Tools with SalesBlink. Two Way Recruiters*Unite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superthread",
    "slug": "superthread",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3be8717525961d0728d332b347e19498.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Superthread is the world's fastest issue tracker with tasks and docs in one tool.",
    "category": "Project Management",
    "title": "Superthread Integration With Cold Email Sales Tool",
    "h1": "Integrate Superthread with SalesBlink",
    "metaDescription": "Integrate Superthread and other Project Management Tools with SalesBlink. Two Way Superthread Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lucid Meetings",
    "slug": "lucid-meetings",
    "logo": "https://zapier-images.imgix.net/storage/developer/32964dcd080eee1f2feaea3774e8557a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lucid Meetings is an end-to-end platform for designing, running, and continuously improving the business meetings that power your organization's success.",
    "category": "Scheduling & Booking",
    "title": "Lucid Meetings Integration With Cold Email Sales Tool",
    "h1": "Integrate Lucid Meetings with SalesBlink",
    "metaDescription": "Integrate Lucid Meetings and other Scheduling & Booking Tools with SalesBlink. Two Way Lucid Meetings Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AccountingBox",
    "slug": "accountingbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02b16c7f82d2d75a253695d109b0cc02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccountingBox is a modern business management system that provides accounting services and businesses with effective business monitoring and easy collaboration.\n\nIt also includes an intelligent system for automatic data capture and virtual business assistance.",
    "category": "Accounting",
    "title": "AccountingBox Integration With Cold Email Sales Tool",
    "h1": "Integrate AccountingBox with SalesBlink",
    "metaDescription": "Integrate AccountingBox and other Accounting Tools with SalesBlink. Two Way AccountingBox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nureply",
    "slug": "nureply",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1600daed8416405e628c0a98a48d5b54.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nureply is email marketing software made for cold email marketing professionals. Using advanced AI to generate email personalization in seconds.",
    "category": "Drip Emails",
    "title": "Nureply Integration With Cold Email Sales Tool",
    "h1": "Integrate Nureply with SalesBlink",
    "metaDescription": "Integrate Nureply and other Drip Emails Tools with SalesBlink. Two Way Nureply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Distilled",
    "slug": "distilled",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cbba7dc516cd05db591f8f97010808e2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create and manage DevRel and community OKRs with Distilled's flexible OKR tool.",
    "category": "Project Management",
    "title": "Distilled Integration With Cold Email Sales Tool",
    "h1": "Integrate Distilled with SalesBlink",
    "metaDescription": "Integrate Distilled and other Project Management Tools with SalesBlink. Two Way Distilled Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OSG o360",
    "slug": "osg-o360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4b0bd2a400f7f28246838f6855d3179.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OSG o360 provides an understanding of all the key consumer and user touchpoints from awareness and consideration to purchase, usage, and advocacy.",
    "category": "Analytics",
    "title": "OSG o360 Integration With Cold Email Sales Tool",
    "h1": "Integrate OSG o360 with SalesBlink",
    "metaDescription": "Integrate OSG o360 and other Analytics Tools with SalesBlink. Two Way OSG o360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RoboRecruiter Application",
    "slug": "roborecruiter-application",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b4c2d1103f465ee6333840d2867e183.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RoboRecruiter is a messaging chatbot for recruitment. Reach 1 to 10's of thousands of your candidates instantly",
    "category": "HR Talent & Recruitment",
    "title": "RoboRecruiter Application Integration With Cold Email Sales Tool",
    "h1": "Integrate RoboRecruiter Application with SalesBlink",
    "metaDescription": "Integrate RoboRecruiter Application and other HR Talent & Recruitment Tools with SalesBlink. Two Way RoboRecruiter Application Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tutory",
    "slug": "tutory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/724fb5d9166a6e2a6bf28283f70f6aa2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tutory is a platform that helps coaches to improve their methodologies into studies plans for its coachees.",
    "category": "Education",
    "title": "Tutory Integration With Cold Email Sales Tool",
    "h1": "Integrate Tutory with SalesBlink",
    "metaDescription": "Integrate Tutory and other Education Tools with SalesBlink. Two Way Tutory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AnonyFlow",
    "slug": "anonyflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9319374b30967ef91a38e43a34fa6fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AnonyFlow is a simple and powerful service for encryption-based data anonymization / deanonymization and community sharing, Enabling GDPR, CCPA, and HIPAA data privacy protection compliance.",
    "category": "Security & Identity Tools",
    "title": "AnonyFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate AnonyFlow with SalesBlink",
    "metaDescription": "Integrate AnonyFlow and other Security & Identity Tools Tools with SalesBlink. Two Way AnonyFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bureau24.fr",
    "slug": "bureau24",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4bbda52a09e0a5d6b1dc27fc6f11255.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bureau24.fr is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business.",
    "category": "Communication",
    "title": "bureau24.fr Integration With Cold Email Sales Tool",
    "h1": "Integrate bureau24.fr with SalesBlink",
    "metaDescription": "Integrate bureau24.fr and other Communication Tools with SalesBlink. Two Way bureau24.fr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CustomerGauge",
    "slug": "customergauge",
    "logo": "https://zapier-images.imgix.net/storage/services/dec22c47eba8397243fc9d837d2fc14b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CustomerGauge is a SaaS platform that helps clients improve retention, using Customer Success and Net Promoter® methodologies.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CustomerGauge Integration With Cold Email Sales Tool",
    "h1": "Integrate CustomerGauge with SalesBlink",
    "metaDescription": "Integrate CustomerGauge and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CustomerGauge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WeConnect.Chat",
    "slug": "weconnectchat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c7eb052a29d1014457b2cd7aa0e3af8e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WeConnect B2B companies with their high intent audience using conversational experiences combining chatbot, live messaging, video chat, booked meetings, and much more.",
    "category": "Communication",
    "title": "WeConnect.Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate WeConnect.Chat with SalesBlink",
    "metaDescription": "Integrate WeConnect.Chat and other Communication Tools with SalesBlink. Two Way WeConnect.Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignHero",
    "slug": "signhero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a06fea9ed9346ae552e140f3802bd784.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignHero is fast and free eSigning for the lazy. Send & sign PDFs, track processes, remind signers, access audit trail, work in teams, and archive signed documents.",
    "category": "Documents",
    "title": "SignHero Integration With Cold Email Sales Tool",
    "h1": "Integrate SignHero with SalesBlink",
    "metaDescription": "Integrate SignHero and other Documents Tools with SalesBlink. Two Way SignHero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stayflexi",
    "slug": "stayflexi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa494d4e81dbd9d374777201ae82103d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stayflexi is the Modern Operating System for Hotels and Vacation Rentals. It helps properties to automate operations and maximize revenues on unsold rooms and amenities with an innovative flexible booking model.",
    "category": "Scheduling & Booking",
    "title": "Stayflexi Integration With Cold Email Sales Tool",
    "h1": "Integrate Stayflexi with SalesBlink",
    "metaDescription": "Integrate Stayflexi and other Scheduling & Booking Tools with SalesBlink. Two Way Stayflexi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deposyt",
    "slug": "centz-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/cc2509ff3d1b18a9d349fa158f041f26.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deposyt is the online Customer Relation Management software to manage your customers, referrals, reviews, emails, products and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Deposyt Integration With Cold Email Sales Tool",
    "h1": "Integrate Deposyt with SalesBlink",
    "metaDescription": "Integrate Deposyt and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Deposyt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Storipress",
    "slug": "storipress",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e17807772284efaa9fae999080dea4e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Storipress is a publishing platform designed for teams making tomorrows content today",
    "category": "App Builder",
    "title": "Storipress Integration With Cold Email Sales Tool",
    "h1": "Integrate Storipress with SalesBlink",
    "metaDescription": "Integrate Storipress and other App Builder Tools with SalesBlink. Two Way Storipress Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Gleantap",
    "slug": "gleantap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ce73a674df9c030b962f5a9c717f892.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gleantap is an AI customer retention platform for retail businesses.",
    "category": "Marketing",
    "title": "Gleantap Integration With Cold Email Sales Tool",
    "h1": "Integrate Gleantap with SalesBlink",
    "metaDescription": "Integrate Gleantap and other Marketing Tools with SalesBlink. Two Way Gleantap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zenvia SMS",
    "slug": "zenvia-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a868314016b6dcb849cff7660611f253.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zenvia is a communication platform that simplifies the relationship between companies and consumers.",
    "category": "Phone & SMS",
    "title": "Zenvia SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Zenvia SMS with SalesBlink",
    "metaDescription": "Integrate Zenvia SMS and other Phone & SMS Tools with SalesBlink. Two Way Zenvia SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lineup",
    "slug": "lineup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5d57f68d3ae59a598b4dba06b29de67.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lineup is an online solution designed to automate and streamline the volunteer management process by creating filterable and actionable talent profiles.",
    "category": "Productivity",
    "title": "Lineup Integration With Cold Email Sales Tool",
    "h1": "Integrate Lineup with SalesBlink",
    "metaDescription": "Integrate Lineup and other Productivity Tools with SalesBlink. Two Way Lineup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myPresences",
    "slug": "mypresences",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ea3e3af83b3e13b412c0f7dee6771b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage and grow your business's online presence and reputation everywhere online. Get more reviews and showcase them on your website or on social media.",
    "category": "Reviews",
    "title": "myPresences Integration With Cold Email Sales Tool",
    "h1": "Integrate myPresences with SalesBlink",
    "metaDescription": "Integrate myPresences and other Reviews Tools with SalesBlink. Two Way myPresences Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Direct Mail Manager",
    "slug": "direct-mail-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a16e51ab167b44a2edfb3b618ec0b22a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Direct Mail Manager makes sending postcards, letters, and checks as easy as sending an email.",
    "category": "Marketing Automation",
    "title": "Direct Mail Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Direct Mail Manager with SalesBlink",
    "metaDescription": "Integrate Direct Mail Manager and other Marketing Automation Tools with SalesBlink. Two Way Direct Mail Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teambook",
    "slug": "teambook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c7717611cf8a1410e0f355574f42646.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teambook is the tool you need to level-up your project management game. Get the big picture of your consultants’ workload thanks to the visual team board(s) and see who’s available or not, in the snap of a finger.",
    "category": "Scheduling & Booking",
    "title": "Teambook Integration With Cold Email Sales Tool",
    "h1": "Integrate Teambook with SalesBlink",
    "metaDescription": "Integrate Teambook and other Scheduling & Booking Tools with SalesBlink. Two Way Teambook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadport",
    "slug": "leadport",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/585f4e9bf8114ac03cf54cf858fb073b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Call your leads instantly. Never forget to follow up with your customers. Track every interaction of your sales team including call data.",
    "category": "Sales & CRM",
    "title": "Leadport Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadport with SalesBlink",
    "metaDescription": "Integrate Leadport and other Sales & CRM Tools with SalesBlink. Two Way Leadport Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IsOn24",
    "slug": "ison24",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70c0e6e32b2fd90a3b7533f4f81f4de2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IsOn24 is a conversational AI voice assistant that answers your calls just like you, sends text messages, and automates your front office tasks including appointments and lead management.",
    "category": "Phone & SMS",
    "title": "IsOn24 Integration With Cold Email Sales Tool",
    "h1": "Integrate IsOn24 with SalesBlink",
    "metaDescription": "Integrate IsOn24 and other Phone & SMS Tools with SalesBlink. Two Way IsOn24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Masterpages",
    "slug": "masterpages",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f2993430aac1179f3e2a5e81e973644.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Masterpages builds websites and delivers tools for lead generation",
    "category": "Website Builders",
    "title": "Masterpages Integration With Cold Email Sales Tool",
    "h1": "Integrate Masterpages with SalesBlink",
    "metaDescription": "Integrate Masterpages and other Website Builders Tools with SalesBlink. Two Way Masterpages Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LoopVOC",
    "slug": "loopvoc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d344d147a697eb154157ad26c5d539fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LoopVOC provides customer feedback analytics built to fuel your growth and is used to build, position, and grow the best products.",
    "category": "Analytics",
    "title": "LoopVOC Integration With Cold Email Sales Tool",
    "h1": "Integrate LoopVOC with SalesBlink",
    "metaDescription": "Integrate LoopVOC and other Analytics Tools with SalesBlink. Two Way LoopVOC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Last Video",
    "slug": "last-video",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c570503d4167c573bf772053409a6ef_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Last Video allows you to feature your latest YouTube video on your website with absolutely no code updates.",
    "category": "Video & Audio",
    "title": "Last Video Integration With Cold Email Sales Tool",
    "h1": "Integrate Last Video with SalesBlink",
    "metaDescription": "Integrate Last Video and other Video & Audio Tools with SalesBlink. Two Way Last Video Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Efactuurdirect",
    "slug": "efactuurdirect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b798d39b1ff4ad7bf0ed69e99a29d5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Efactuurdirect is a service for sending invoices and offers.",
    "category": "Proposal & Invoice Management",
    "title": "Efactuurdirect Integration With Cold Email Sales Tool",
    "h1": "Integrate Efactuurdirect with SalesBlink",
    "metaDescription": "Integrate Efactuurdirect and other Proposal & Invoice Management Tools with SalesBlink. Two Way Efactuurdirect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "M2p",
    "slug": "m2p",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9dc27b9c1f1a4c038cb181a28d827881_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "M2p Chat connects you & your customers with customer messaging apps.",
    "category": "App Builder",
    "title": "M2p Integration With Cold Email Sales Tool",
    "h1": "Integrate M2p with SalesBlink",
    "metaDescription": "Integrate M2p and other App Builder Tools with SalesBlink. Two Way M2p Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BestMoversCRM",
    "slug": "bestmoverscrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4295767794a47a234dd9c5c1b374ed4e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BestMoversCRM is a tool that helps companies that use CRM to create leads from various sources.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BestMoversCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate BestMoversCRM with SalesBlink",
    "metaDescription": "Integrate BestMoversCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BestMoversCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bundeling",
    "slug": "bundeling",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1bf9869492561c645c38dd1a0ca471a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The central communication hub for your organization",
    "category": "Communication",
    "title": "Bundeling Integration With Cold Email Sales Tool",
    "h1": "Integrate Bundeling with SalesBlink",
    "metaDescription": "Integrate Bundeling and other Communication Tools with SalesBlink. Two Way Bundeling Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Internal.io",
    "slug": "internalio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/807ace06cdf4c7ac5ef6d859b4818461.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build internal tools incredibly fast on top of your databases, APIs, spreadsheets, and business apps.",
    "category": "Developer Tools",
    "title": "Internal.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Internal.io with SalesBlink",
    "metaDescription": "Integrate Internal.io and other Developer Tools Tools with SalesBlink. Two Way Internal.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Humanagement",
    "slug": "humanagement",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a4ae705e81975c2081cca8ab4e121bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Humanagement is an app to help you manage employees. Managing employees should be enjoyable and clear-cut, we offer KPI's, Requests management, KB, LMS and more!",
    "category": "Business Intelligence",
    "title": "Humanagement Integration With Cold Email Sales Tool",
    "h1": "Integrate Humanagement with SalesBlink",
    "metaDescription": "Integrate Humanagement and other Business Intelligence Tools with SalesBlink. Two Way Humanagement Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chatkick Talent CRM",
    "slug": "chatkick-talent-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/29872372e615c627ccdab4a25820e980.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Talent CRM provides recruiting and sourcing teams a powerful tool to source candidates, and automatically follow up on their email outreach.",
    "category": "HR Talent & Recruitment",
    "title": "Chatkick Talent CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatkick Talent CRM with SalesBlink",
    "metaDescription": "Integrate Chatkick Talent CRM and other HR Talent & Recruitment Tools with SalesBlink. Two Way Chatkick Talent CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Comdesk Lead",
    "slug": "comdesk-lead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9761a9be64901d08a4b51fed7a5f52b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Comdesk Lead is an inside sales CTI tool that can be linked to mobile phone lines.",
    "category": "Call Tracking",
    "title": "Comdesk Lead Integration With Cold Email Sales Tool",
    "h1": "Integrate Comdesk Lead with SalesBlink",
    "metaDescription": "Integrate Comdesk Lead and other Call Tracking Tools with SalesBlink. Two Way Comdesk Lead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clarkup",
    "slug": "clarkup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f12fdf82fa596a6494ae454a7ee601e1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClarkUp is a CRM to organize your leads and create personalized videos to interact with them.",
    "category": "Business Intelligence",
    "title": "Clarkup Integration With Cold Email Sales Tool",
    "h1": "Integrate Clarkup with SalesBlink",
    "metaDescription": "Integrate Clarkup and other Business Intelligence Tools with SalesBlink. Two Way Clarkup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Defastra",
    "slug": "defastra",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fef44dcde8f17a2f8e410cd8839e34ae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Defastra offers data enrichment & fraud prevention services aimed at restoring trust and fighting fake identities.",
    "category": "Security & Identity Tools",
    "title": "Defastra Integration With Cold Email Sales Tool",
    "h1": "Integrate Defastra with SalesBlink",
    "metaDescription": "Integrate Defastra and other Security & Identity Tools Tools with SalesBlink. Two Way Defastra Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gruveo",
    "slug": "gruveo",
    "logo": "https://zapier-images.imgix.net/storage/services/3d71e6d862ba46d273d48e3d977dcf37_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gruveo makes it possible to make video and voice calls right in your browser, without having to install software or register for an account.",
    "category": "Video Conferencing",
    "title": "Gruveo Integration With Cold Email Sales Tool",
    "h1": "Integrate Gruveo with SalesBlink",
    "metaDescription": "Integrate Gruveo and other Video Conferencing Tools with SalesBlink. Two Way Gruveo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InventoryLab",
    "slug": "inventorylab",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5a7069466457585fd7d01e6c42f2f53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "List products, print labels, build FBA shipments or add to your Merchant Fulfilled inventory, track costs and your net profit as an Amazon Seller",
    "category": "eCommerce",
    "title": "InventoryLab Integration With Cold Email Sales Tool",
    "h1": "Integrate InventoryLab with SalesBlink",
    "metaDescription": "Integrate InventoryLab and other eCommerce Tools with SalesBlink. Two Way InventoryLab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swag.com",
    "slug": "swagcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/647465e04e2c5e5d5e5994f8f8eb12df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swag.com is a platform for customizing, storing and distributing high-quality branded gifts and swag globally.",
    "category": "eCommerce",
    "title": "Swag.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Swag.com with SalesBlink",
    "metaDescription": "Integrate Swag.com and other eCommerce Tools with SalesBlink. Two Way Swag.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gymvue Leads",
    "slug": "gymvue-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f23ed558cbede44ee1e7ffaf797f882.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GymVUE is an Australian Cloud Member Management Software built and designed with a Gym Owners requirements in mind.",
    "category": "Sales & CRM",
    "title": "Gymvue Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Gymvue Leads with SalesBlink",
    "metaDescription": "Integrate Gymvue Leads and other Sales & CRM Tools with SalesBlink. Two Way Gymvue Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TTNC",
    "slug": "ttnc",
    "logo": "https://zapier-images.imgix.net/storage/services/dddae6349f18c804de3106c269995666_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TTNC is an award-winning cloud-based communications provider that supplies businesses with hosted telephone systems and messaging services.",
    "category": "Phone & SMS",
    "title": "TTNC Integration With Cold Email Sales Tool",
    "h1": "Integrate TTNC with SalesBlink",
    "metaDescription": "Integrate TTNC and other Phone & SMS Tools with SalesBlink. Two Way TTNC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HomeManager",
    "slug": "homemanager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/56852e913e176c352629578af084c42a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HomeManager is a platform that makes it easy for homeowners to manage their home and keep their family safe.",
    "category": "Task Management",
    "title": "HomeManager Integration With Cold Email Sales Tool",
    "h1": "Integrate HomeManager with SalesBlink",
    "metaDescription": "Integrate HomeManager and other Task Management Tools with SalesBlink. Two Way HomeManager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InsTrack",
    "slug": "instrack-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ab97215e3f0ce4f8406641ac06ed5d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InsTrack offers powerful in-depth Instagram analytics and actionable insights for brands, influencers and agencies.",
    "category": "Analytics",
    "title": "InsTrack Integration With Cold Email Sales Tool",
    "h1": "Integrate InsTrack with SalesBlink",
    "metaDescription": "Integrate InsTrack and other Analytics Tools with SalesBlink. Two Way InsTrack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adabra",
    "slug": "adabra",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be211a1d798dd64c1cfb33971a8972c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adabra is a platform that allows you to create unique and customized experiences for each user. All done by coordinating and adapting your marketing activities on all online and offline channels in real time.",
    "category": "Marketing",
    "title": "Adabra Integration With Cold Email Sales Tool",
    "h1": "Integrate Adabra with SalesBlink",
    "metaDescription": "Integrate Adabra and other Marketing Tools with SalesBlink. Two Way Adabra Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hibu Assistant Connect",
    "slug": "hibu-assistant-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2321c88f0e9873c87992bb8101ca68eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hibu Assistant Connect allows clients to connect 3rd party lists to their Hibu suite of products",
    "category": "Marketing",
    "title": "Hibu Assistant Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Hibu Assistant Connect with SalesBlink",
    "metaDescription": "Integrate Hibu Assistant Connect and other Marketing Tools with SalesBlink. Two Way Hibu Assistant Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Baresquare",
    "slug": "baresquare",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9abe56f5ff2e29659c9a50778ab9cf6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Baresquare connects with your current analytics platform to make sense of the trends in your data, surfacing micro insights that could lead to major opportunities. In a sea of data, get your bearings with Baresquare’s action-driven analytics.",
    "category": "Analytics",
    "title": "Baresquare Integration With Cold Email Sales Tool",
    "h1": "Integrate Baresquare with SalesBlink",
    "metaDescription": "Integrate Baresquare and other Analytics Tools with SalesBlink. Two Way Baresquare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Acadle",
    "slug": "acadle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0dd5bbb4fc0dc4f634f5c6bbf21f099f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plug and Play in 2 hours, Train your employees, partners and customers at scale and with zero tech skills !",
    "category": "Online Courses",
    "title": "Acadle Integration With Cold Email Sales Tool",
    "h1": "Integrate Acadle with SalesBlink",
    "metaDescription": "Integrate Acadle and other Online Courses Tools with SalesBlink. Two Way Acadle Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "LoudHippo",
    "slug": "loudhippo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/beae4359249f9ce7caa91dbf09a5c6f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Post-purchase attribution surveys and insights. Ask questions on your eCommerce order status page after a successful checkout to inform your marketing and grow ROAS without guesswork",
    "category": "Marketing",
    "title": "LoudHippo Integration With Cold Email Sales Tool",
    "h1": "Integrate LoudHippo with SalesBlink",
    "metaDescription": "Integrate LoudHippo and other Marketing Tools with SalesBlink. Two Way LoudHippo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fitness Nation",
    "slug": "fitness-nation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8fbc405645a19267296ca16f824c14c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Your innovative partner for individualized, digital and smart software solutions in the fitness and health industry.",
    "category": "Fitness",
    "title": "Fitness Nation Integration With Cold Email Sales Tool",
    "h1": "Integrate Fitness Nation with SalesBlink",
    "metaDescription": "Integrate Fitness Nation and other Fitness Tools with SalesBlink. Two Way Fitness Nation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Promptly",
    "slug": "promptly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e002f02f4993b36bcca5b6430b20412.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Promptly helps anyone build generative AI apps by integrating with OpenAI, Stability, and Cohere models without writing any code",
    "category": "AI Tools",
    "title": "Promptly Integration With Cold Email Sales Tool",
    "h1": "Integrate Promptly with SalesBlink",
    "metaDescription": "Integrate Promptly and other AI Tools Tools with SalesBlink. Two Way Promptly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "allGeo",
    "slug": "allgeo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/af89b4c93ea21ae5b4c8d811f1679242.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Field Service Automation with scheduling, time-clock, location tracking, geofencing, mobile forms and event monitoring",
    "category": "Scheduling & Booking",
    "title": "allGeo Integration With Cold Email Sales Tool",
    "h1": "Integrate allGeo with SalesBlink",
    "metaDescription": "Integrate allGeo and other Scheduling & Booking Tools with SalesBlink. Two Way allGeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Togezzer",
    "slug": "togezzer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e49e0c7212b06b76e5e74febc3a32704_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Togezzer is a digital workplace. Your team can use chats, collaborate on tasks, track processes and organize towards the company goals.",
    "category": "Task Management",
    "title": "Togezzer Integration With Cold Email Sales Tool",
    "h1": "Integrate Togezzer with SalesBlink",
    "metaDescription": "Integrate Togezzer and other Task Management Tools with SalesBlink. Two Way Togezzer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reliable PBX",
    "slug": "reliable-pbx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff331fc30be3df5850ad655d845da407.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We connect local businesses to the rest of the universe with a powerful VoIP business phone system.",
    "category": "Communication",
    "title": "Reliable PBX Integration With Cold Email Sales Tool",
    "h1": "Integrate Reliable PBX with SalesBlink",
    "metaDescription": "Integrate Reliable PBX and other Communication Tools with SalesBlink. Two Way Reliable PBX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Propago",
    "slug": "propago",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c66572a1b31711c408d176f615070f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Propago Allows Print Producers, Enterprises and Marketers To Better Manage, Personalize, Produce, and Distribute Their Marketing Assets.",
    "category": "eCommerce",
    "title": "Propago Integration With Cold Email Sales Tool",
    "h1": "Integrate Propago with SalesBlink",
    "metaDescription": "Integrate Propago and other eCommerce Tools with SalesBlink. Two Way Propago Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Userbot",
    "slug": "userbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b841d67225848a812b8e1fac62f987f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Userbot helps you to reply to all messages quickly, easily and in a personalized manner.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Userbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Userbot with SalesBlink",
    "metaDescription": "Integrate Userbot and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Userbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magistrate",
    "slug": "magistrate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57aeb15d100953dc26f15bba700f24ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magistrate is a legal document automation app. Kill busywork. One routine contract at a time.",
    "category": "Documents",
    "title": "Magistrate Integration With Cold Email Sales Tool",
    "h1": "Integrate Magistrate with SalesBlink",
    "metaDescription": "Integrate Magistrate and other Documents Tools with SalesBlink. Two Way Magistrate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Search And Save",
    "slug": "search-and-save",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb95c0e75573c4aef6a0b3ce30fe3e63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Search & Save system is a Medicare quoting system and CRM for agents use only.",
    "category": "Databases",
    "title": "Search And Save Integration With Cold Email Sales Tool",
    "h1": "Integrate Search And Save with SalesBlink",
    "metaDescription": "Integrate Search And Save and other Databases Tools with SalesBlink. Two Way Search And Save Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Converflow",
    "slug": "converflow",
    "logo": "https://zapier-images.imgix.net/storage/services/3781fdc180f2e46e78e417b5f3dc1576.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert conversations from Converflow chatbot into leads",
    "category": "Sales & CRM",
    "title": "Converflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Converflow with SalesBlink",
    "metaDescription": "Integrate Converflow and other Sales & CRM Tools with SalesBlink. Two Way Converflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nectar Desk",
    "slug": "nectar-desk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3282aa3151ab9973059de1397c43042f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nectar Desk is a multi-channel communication platform for Sales teams, Support teams and Call Centers",
    "category": "Phone & SMS",
    "title": "Nectar Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate Nectar Desk with SalesBlink",
    "metaDescription": "Integrate Nectar Desk and other Phone & SMS Tools with SalesBlink. Two Way Nectar Desk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aiva",
    "slug": "aiva",
    "logo": "https://zapier-images.imgix.net/storage/developer/3f90c5fb1f3ab7478e66593dbd026c6b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aiva is a design focused website campaign tool that allows you to engage your website visitors and inspire action with no coding required.",
    "category": "Forms & Surveys",
    "title": "Aiva Integration With Cold Email Sales Tool",
    "h1": "Integrate Aiva with SalesBlink",
    "metaDescription": "Integrate Aiva and other Forms & Surveys Tools with SalesBlink. Two Way Aiva Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alchemy",
    "slug": "alchemy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c8cd125ac35282a648226d8d4ad0dcb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alchemy is a web3 development platform with the most powerful set of web3 development tools to build and scale your dApp with ease!",
    "category": "Developer Tools",
    "title": "Alchemy Integration With Cold Email Sales Tool",
    "h1": "Integrate Alchemy with SalesBlink",
    "metaDescription": "Integrate Alchemy and other Developer Tools Tools with SalesBlink. Two Way Alchemy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignusCRM",
    "slug": "signuscrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfe17fd252d2cd0ab93812338caeec8c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignusCRM is a service that stores and manages data from various sources",
    "category": "Sales & CRM",
    "title": "SignusCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate SignusCRM with SalesBlink",
    "metaDescription": "Integrate SignusCRM and other Sales & CRM Tools with SalesBlink. Two Way SignusCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bento Everboarding",
    "slug": "bento-everboarding",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/641d3df1115d636666e30c0194342b1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bento allows Product teams to design personalized onboarding flows that help customers and users take valuable actions and drive product engagement.",
    "category": "Website & App Building",
    "title": "Bento Everboarding Integration With Cold Email Sales Tool",
    "h1": "Integrate Bento Everboarding with SalesBlink",
    "metaDescription": "Integrate Bento Everboarding and other Website & App Building Tools with SalesBlink. Two Way Bento Everboarding Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "towio",
    "slug": "towio",
    "logo": "https://zapier-images.imgix.net/storage/services/309dfc39c90948b63343df87936c4de8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "towio helps craftsmen in the office and on the construction site to document, plan and organize their business more professionally",
    "category": "Accounting",
    "title": "towio Integration With Cold Email Sales Tool",
    "h1": "Integrate towio with SalesBlink",
    "metaDescription": "Integrate towio and other Accounting Tools with SalesBlink. Two Way towio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "XRay Workflow",
    "slug": "xray-workflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fa707b07c4d176bc21097eab753ffd5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This is a software agnostic workflow application. Create your home screen for workflows today!",
    "category": "Productivity",
    "title": "XRay Workflow Integration With Cold Email Sales Tool",
    "h1": "Integrate XRay Workflow with SalesBlink",
    "metaDescription": "Integrate XRay Workflow and other Productivity Tools with SalesBlink. Two Way XRay Workflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Troop Messenger",
    "slug": "troop-messenger",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75b5ccc5e7f7ea8b2ed6802a049ddf66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Troop Messenger is a business instant messaging & team collaboration application.",
    "category": "Team Collaboration",
    "title": "Troop Messenger Integration With Cold Email Sales Tool",
    "h1": "Integrate Troop Messenger with SalesBlink",
    "metaDescription": "Integrate Troop Messenger and other Team Collaboration Tools with SalesBlink. Two Way Troop Messenger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Serviceaide",
    "slug": "serviceaide",
    "logo": "https://zapier-images.imgix.net/storage/services/3ac186ff68f79fe47d485fab9cd47521.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ServiceAide Cloud Service Management is an online, Cloud based IT Service Management system.",
    "category": "Customer Support",
    "title": "Serviceaide Integration With Cold Email Sales Tool",
    "h1": "Integrate Serviceaide with SalesBlink",
    "metaDescription": "Integrate Serviceaide and other Customer Support Tools with SalesBlink. Two Way Serviceaide Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Futures",
    "slug": "the-futures",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48ac48722abe5305a4c18ecb8c800f99.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We offer services of graphics designs, landing pages, and videos to our customers in order to develop their brands, our products are focused on agencies and marketers.",
    "category": "Images & Design",
    "title": "The Futures Integration With Cold Email Sales Tool",
    "h1": "Integrate The Futures with SalesBlink",
    "metaDescription": "Integrate The Futures and other Images & Design Tools with SalesBlink. Two Way The Futures Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sellgo",
    "slug": "sellgo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fece66ebd1f61447f6c90ddc4a08314f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sellgo is All-In-One platform for selling on Amazon.",
    "category": "eCommerce",
    "title": "Sellgo Integration With Cold Email Sales Tool",
    "h1": "Integrate Sellgo with SalesBlink",
    "metaDescription": "Integrate Sellgo and other eCommerce Tools with SalesBlink. Two Way Sellgo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Decidento",
    "slug": "decidento",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6679562bd393a52ff44712afbaa91084.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Decidento is a smartdata BtoB tool to automatically create leads every day and enrich your accounts and contacts in your CRM.",
    "category": "Sales & CRM",
    "title": "Decidento Integration With Cold Email Sales Tool",
    "h1": "Integrate Decidento with SalesBlink",
    "metaDescription": "Integrate Decidento and other Sales & CRM Tools with SalesBlink. Two Way Decidento Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JetWebinar",
    "slug": "jetwebinar",
    "logo": "https://zapier-images.imgix.net/storage/services/f5476b6d512bc53b0a5695d71a28645d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JetWebinar is a real-time live and simulated live webinar hosting platform.",
    "category": "Video Conferencing",
    "title": "JetWebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate JetWebinar with SalesBlink",
    "metaDescription": "Integrate JetWebinar and other Video Conferencing Tools with SalesBlink. Two Way JetWebinar Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Railer.com",
    "slug": "railer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c458c8aa540270d7d161824a12d8d5a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Railer.com is a Mobile Face Recognition Attendance and Shift Management Platform. Powerful features such as smart attendance detection, daily timesheet, QR Code, geolocation, real-time sensor based people tracking, roster, reporting and much more.",
    "category": "Time Tracking Software",
    "title": "Railer.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Railer.com with SalesBlink",
    "metaDescription": "Integrate Railer.com and other Time Tracking Software Tools with SalesBlink. Two Way Railer.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ingram Micro Cloud Marketplace",
    "slug": "ingram-micro-cloud-marketplace-c",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea263f64b2e887361136460120cb9ece.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate your eCommerce, CRM, billing and ERP systems with Cloud Marketplace. Put the most time-consuming, frustrating aspects of reconciling your systems on autopilot—and focus instead on growing your business.",
    "category": "Commerce",
    "title": "Ingram Micro Cloud Marketplace Integration With Cold Email Sales Tool",
    "h1": "Integrate Ingram Micro Cloud Marketplace with SalesBlink",
    "metaDescription": "Integrate Ingram Micro Cloud Marketplace and other Commerce Tools with SalesBlink. Two Way Ingram Micro Cloud Marketplace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hippo CMMS",
    "slug": "hippo-cmms-ca11403",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ef2687996a96e70bc0d439fa7c799d7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hippo CMMS is a simple solution for preventive maintenance, work order management, equipment maintenance, inventory and more!",
    "category": "Product Management",
    "title": "Hippo CMMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Hippo CMMS with SalesBlink",
    "metaDescription": "Integrate Hippo CMMS and other Product Management Tools with SalesBlink. Two Way Hippo CMMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notifyre SMS",
    "slug": "notifyre-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a024ca623c13ae91c239adadc2ec8e72.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notifyre’s powerful SMS toolkit is designed to send targeted text messages directly to your audience, receive and manage replies and more.",
    "category": "Phone & SMS",
    "title": "Notifyre SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Notifyre SMS with SalesBlink",
    "metaDescription": "Integrate Notifyre SMS and other Phone & SMS Tools with SalesBlink. Two Way Notifyre SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commerceup",
    "slug": "commerceup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96c95d3bc16e28ebdf34bc033a585391.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Modern & powerful cloud based eCommerce platform that is built for unique e-commerce business needs.",
    "category": "eCommerce",
    "title": "Commerceup Integration With Cold Email Sales Tool",
    "h1": "Integrate Commerceup with SalesBlink",
    "metaDescription": "Integrate Commerceup and other eCommerce Tools with SalesBlink. Two Way Commerceup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bluejeans Meetings",
    "slug": "bluejeans-meetings",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/514cc316837030def8e1c9b43430c9ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BlueJeans Meetings is a video conferencing service with productivity features.",
    "category": "Video Conferencing",
    "title": "Bluejeans Meetings Integration With Cold Email Sales Tool",
    "h1": "Integrate Bluejeans Meetings with SalesBlink",
    "metaDescription": "Integrate Bluejeans Meetings and other Video Conferencing Tools with SalesBlink. Two Way Bluejeans Meetings Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Metadata",
    "slug": "metadata",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be56a32a36f1184123871f600566fc2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Metadata’s patented technology executes thousands of B2B campaigns in a matter of hours, automatically optimizing campaigns for pipeline impact at a velocity that is not humanly possible.",
    "category": "Marketing Automation",
    "title": "Metadata Integration With Cold Email Sales Tool",
    "h1": "Integrate Metadata with SalesBlink",
    "metaDescription": "Integrate Metadata and other Marketing Automation Tools with SalesBlink. Two Way Metadata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Econodata",
    "slug": "econodata",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/12d1920458e06e500cea9fcd6943a17c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A mais assertiva plataforma de dados para prospecção B2B.",
    "category": "Sales & CRM",
    "title": "Econodata Integration With Cold Email Sales Tool",
    "h1": "Integrate Econodata with SalesBlink",
    "metaDescription": "Integrate Econodata and other Sales & CRM Tools with SalesBlink. Two Way Econodata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TestDome",
    "slug": "testdome",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d0339957afc033b90eea8004554fcb2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TestDome is a service that helps you screen job candidates using automated work-sample tests.",
    "category": "HR Talent & Recruitment",
    "title": "TestDome Integration With Cold Email Sales Tool",
    "h1": "Integrate TestDome with SalesBlink",
    "metaDescription": "Integrate TestDome and other HR Talent & Recruitment Tools with SalesBlink. Two Way TestDome Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BusinessMate",
    "slug": "businessmate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/813c2917e6cdd735a4d2e6a02001b508.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grab your business opportunities contact with ease and get a thousand phone numbers, websites, etc., with only one click.",
    "category": "Marketing Automation",
    "title": "BusinessMate Integration With Cold Email Sales Tool",
    "h1": "Integrate BusinessMate with SalesBlink",
    "metaDescription": "Integrate BusinessMate and other Marketing Automation Tools with SalesBlink. Two Way BusinessMate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ExRt.Live",
    "slug": "exrtlive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c532ffa2a3dc37cb4fb85b3006dea2de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fast and secure currency exchange service with competitive rates and 24/7 support. Trustworthy and reliable, we make global transactions easy.",
    "category": "Accounting",
    "title": "ExRt.Live Integration With Cold Email Sales Tool",
    "h1": "Integrate ExRt.Live with SalesBlink",
    "metaDescription": "Integrate ExRt.Live and other Accounting Tools with SalesBlink. Two Way ExRt.Live Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Red Deer Pathfinder",
    "slug": "red-deer-pathfinder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/168d75d545c5d4819e19f5f4cdd8574d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Red Deer Pathfinder is a system that  takes messy real-world inputs and turns them into organized information.",
    "category": "Business Intelligence",
    "title": "Red Deer Pathfinder Integration With Cold Email Sales Tool",
    "h1": "Integrate Red Deer Pathfinder with SalesBlink",
    "metaDescription": "Integrate Red Deer Pathfinder and other Business Intelligence Tools with SalesBlink. Two Way Red Deer Pathfinder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadMeUp",
    "slug": "leadmeup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b89e2b691a6795f42f8ac7b1c2dd0253.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadmeup is an all-in-one software that helps you automate your acquisition process.",
    "category": "Marketing",
    "title": "LeadMeUp Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadMeUp with SalesBlink",
    "metaDescription": "Integrate LeadMeUp and other Marketing Tools with SalesBlink. Two Way LeadMeUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CodeSubmit",
    "slug": "codesubmit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f1eccb8345a41f83619ffa6827631da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CodeSubmit helps you make better hiring decisions with take-home coding challenges.",
    "category": "HR Talent & Recruitment",
    "title": "CodeSubmit Integration With Cold Email Sales Tool",
    "h1": "Integrate CodeSubmit with SalesBlink",
    "metaDescription": "Integrate CodeSubmit and other HR Talent & Recruitment Tools with SalesBlink. Two Way CodeSubmit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flotiq",
    "slug": "flotiq",
    "logo": "https://zapier-images.imgix.net/storage/services/8163f1c463b07131469a9cf2fff81b24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flotiq is an API-first CMS that takes care of hosting, securing and scaling to guarantee your content is always on.",
    "category": "Developer Tools",
    "title": "Flotiq Integration With Cold Email Sales Tool",
    "h1": "Integrate Flotiq with SalesBlink",
    "metaDescription": "Integrate Flotiq and other Developer Tools Tools with SalesBlink. Two Way Flotiq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HulkApps Form Builder",
    "slug": "hulkapps-form-builder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5029396d2f13e11134d7fb92aabeec0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HulkApps Form Builder is a Shopify app developed to design basic or complex forms without any HTML knowledge.",
    "category": "App Builder",
    "title": "HulkApps Form Builder Integration With Cold Email Sales Tool",
    "h1": "Integrate HulkApps Form Builder with SalesBlink",
    "metaDescription": "Integrate HulkApps Form Builder and other App Builder Tools with SalesBlink. Two Way HulkApps Form Builder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SweetAssist",
    "slug": "sweetassist-v3",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28a20333c27935fd8601f814e0b99d5e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SweetAssist is a complete real estate training centre relationship marketing software and mastermind community.",
    "category": "Marketing Automation",
    "title": "SweetAssist Integration With Cold Email Sales Tool",
    "h1": "Integrate SweetAssist with SalesBlink",
    "metaDescription": "Integrate SweetAssist and other Marketing Automation Tools with SalesBlink. Two Way SweetAssist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BizMerlinHR",
    "slug": "bizmerlinhr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c270f8618b4c81e000f943db4dec84d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HR Software for all your Human Capital Management needs employee records, onboarding, PTO & attendance, performance reviews, applicant tracking system.",
    "category": "HR Talent & Recruitment",
    "title": "BizMerlinHR Integration With Cold Email Sales Tool",
    "h1": "Integrate BizMerlinHR with SalesBlink",
    "metaDescription": "Integrate BizMerlinHR and other HR Talent & Recruitment Tools with SalesBlink. Two Way BizMerlinHR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Conversional",
    "slug": "conversional",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c7b1e33aaefd2a85511b4c3c58ff1399.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead generation and conversion from your website",
    "category": "Ads & Conversion",
    "title": "Conversional Integration With Cold Email Sales Tool",
    "h1": "Integrate Conversional with SalesBlink",
    "metaDescription": "Integrate Conversional and other Ads & Conversion Tools with SalesBlink. Two Way Conversional Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Riseact",
    "slug": "riseact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/247be33098f1273d8666a99953845f0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Riseact helps people and organization run their fundraising campaigns.",
    "category": "Fundraising",
    "title": "Riseact Integration With Cold Email Sales Tool",
    "h1": "Integrate Riseact with SalesBlink",
    "metaDescription": "Integrate Riseact and other Fundraising Tools with SalesBlink. Two Way Riseact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cartfuel",
    "slug": "cartfuel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a19dc3d75e25dbdc68537d01255d7e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cartfuel is an embeddable payment solution application that allows you to accept payments and one click-upsells regardless of the platform.",
    "category": "Commerce",
    "title": "Cartfuel Integration With Cold Email Sales Tool",
    "h1": "Integrate Cartfuel with SalesBlink",
    "metaDescription": "Integrate Cartfuel and other Commerce Tools with SalesBlink. Two Way Cartfuel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inistate",
    "slug": "inistate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23bed083be586067af2dcb0782305d50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inistate is simplifying processes to best-fit sustainable and scalable solutions and reinventing no-code business process management with a state engine.",
    "category": "Website & App Building",
    "title": "Inistate Integration With Cold Email Sales Tool",
    "h1": "Integrate Inistate with SalesBlink",
    "metaDescription": "Integrate Inistate and other Website & App Building Tools with SalesBlink. Two Way Inistate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simple Social",
    "slug": "simple-social",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7174dc5c839ff9b2ab519ac2ac8367f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple Social is an automated social media tool that simplifies the necessary, but painful process of building and maintaining a strong online presence.",
    "category": "Social Media Marketing",
    "title": "Simple Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Simple Social with SalesBlink",
    "metaDescription": "Integrate Simple Social and other Social Media Marketing Tools with SalesBlink. Two Way Simple Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "timetoreply",
    "slug": "timetoreply",
    "logo": "https://zapier-images.imgix.net/storage/services/0b3cc3b5e77e4ff5b3295bbf50b32b8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "timetoreply is an email analytics and reply time tracking tool for company email. Perfect for customer-facing teams who need to track and improve their business email reply times.",
    "category": "Analytics",
    "title": "timetoreply Integration With Cold Email Sales Tool",
    "h1": "Integrate timetoreply with SalesBlink",
    "metaDescription": "Integrate timetoreply and other Analytics Tools with SalesBlink. Two Way timetoreply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Glympse PRO",
    "slug": "glympse-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48fce1d9c5f5305cc8a10c8942d3075e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Glympse PRO is for businesses that need an uber-like, reliable location-tracking and customer notification solution.",
    "category": "Scheduling & Booking",
    "title": "Glympse PRO Integration With Cold Email Sales Tool",
    "h1": "Integrate Glympse PRO with SalesBlink",
    "metaDescription": "Integrate Glympse PRO and other Scheduling & Booking Tools with SalesBlink. Two Way Glympse PRO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nucleus One",
    "slug": "nucleus-one",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ac6ae0df17149986ad81a9b3547852c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nucleus One is cloud-based business process management (BPM), designed to easily manage projects, tasks, documents and processes.",
    "category": "Business Intelligence",
    "title": "Nucleus One Integration With Cold Email Sales Tool",
    "h1": "Integrate Nucleus One with SalesBlink",
    "metaDescription": "Integrate Nucleus One and other Business Intelligence Tools with SalesBlink. Two Way Nucleus One Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MPOWR Envision",
    "slug": "mpowr-envision",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a1b86076213f51767d4cfc5ee61e8be_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MPOWR Envision is a place to easily create and share company values and goals, as well as track key performance indicators necessary to accomplishing those goals.",
    "category": "Team Collaboration",
    "title": "MPOWR Envision Integration With Cold Email Sales Tool",
    "h1": "Integrate MPOWR Envision with SalesBlink",
    "metaDescription": "Integrate MPOWR Envision and other Team Collaboration Tools with SalesBlink. Two Way MPOWR Envision Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudContactAI",
    "slug": "cloudcontactai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fdb3b8f48a54414288b2bc570831d22.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudContactAI is a platform enabling you to send SMS, MMS, and email messages.",
    "category": "Phone & SMS",
    "title": "CloudContactAI Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudContactAI with SalesBlink",
    "metaDescription": "Integrate CloudContactAI and other Phone & SMS Tools with SalesBlink. Two Way CloudContactAI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zefort",
    "slug": "zefort",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c4619cdcc736da5024ff06edc42e543f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zefort is a contract management platform to easily store, search and share contracts and other important documents.",
    "category": "Content & Files",
    "title": "Zefort Integration With Cold Email Sales Tool",
    "h1": "Integrate Zefort with SalesBlink",
    "metaDescription": "Integrate Zefort and other Content & Files Tools with SalesBlink. Two Way Zefort Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SENAR",
    "slug": "senar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b93c56e90fce54c3f7d96a234097d3e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SENAR is an AR platform to rapidly deploy immersive simulators on mobile devices, improving safety through practical training.",
    "category": "Education",
    "title": "SENAR Integration With Cold Email Sales Tool",
    "h1": "Integrate SENAR with SalesBlink",
    "metaDescription": "Integrate SENAR and other Education Tools with SalesBlink. Two Way SENAR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zifront",
    "slug": "zifront",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c174bd3698371167b96e151c23fa903b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zifront is a Chatbot and Live Chat software for lead generation and customer success.",
    "category": "Communication",
    "title": "Zifront Integration With Cold Email Sales Tool",
    "h1": "Integrate Zifront with SalesBlink",
    "metaDescription": "Integrate Zifront and other Communication Tools with SalesBlink. Two Way Zifront Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lunni",
    "slug": "lunni",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b50dfc7f33007e761f09df4002c7c9c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lunni Device Management system is a tool to help you to keep track of your devices and device components and your other realty in one place.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Lunni Integration With Cold Email Sales Tool",
    "h1": "Integrate Lunni with SalesBlink",
    "metaDescription": "Integrate Lunni and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Lunni Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BRIKER",
    "slug": "briker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ebcfdb5c62303cfd5dd122cf285e460.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BRIKER is a CRM for building companies is an easy tool to commercially manage construction project sales.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BRIKER Integration With Cold Email Sales Tool",
    "h1": "Integrate BRIKER with SalesBlink",
    "metaDescription": "Integrate BRIKER and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BRIKER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stagent",
    "slug": "stagent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a746227d9bbd3a49558a95f74037443d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Software for booking agencies, artists and managers in the music and entertainment industry.",
    "category": "Scheduling & Booking",
    "title": "Stagent Integration With Cold Email Sales Tool",
    "h1": "Integrate Stagent with SalesBlink",
    "metaDescription": "Integrate Stagent and other Scheduling & Booking Tools with SalesBlink. Two Way Stagent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nibo",
    "slug": "nibo",
    "logo": "https://zapier-images.imgix.net/storage/developer/67d37f3956951e2fb8ea41efaf5ef93a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nibo is a simple but powerful financial management app for SMEs. Among other things, it allows the integration of accounting file.",
    "category": "Accounting",
    "title": "Nibo Integration With Cold Email Sales Tool",
    "h1": "Integrate Nibo with SalesBlink",
    "metaDescription": "Integrate Nibo and other Accounting Tools with SalesBlink. Two Way Nibo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Monax",
    "slug": "monax",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76239571a0d517016d48339020a07592_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart, simple, secure digital contracting",
    "category": "Documents",
    "title": "Monax Integration With Cold Email Sales Tool",
    "h1": "Integrate Monax with SalesBlink",
    "metaDescription": "Integrate Monax and other Documents Tools with SalesBlink. Two Way Monax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nutcache",
    "slug": "nutcache",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbe4c8c6a863b221f34f951b1b2b19e2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nutcache is an all-in-one project management app helping businesses and teams of all sizes work smarter.",
    "category": "Project Management",
    "title": "Nutcache Integration With Cold Email Sales Tool",
    "h1": "Integrate Nutcache with SalesBlink",
    "metaDescription": "Integrate Nutcache and other Project Management Tools with SalesBlink. Two Way Nutcache Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon Redshift",
    "slug": "amazon-redshift",
    "logo": "https://zapier-images.imgix.net/storage/services/65c9d4a2032b7f549d945569a04fdaaf_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Redshift is a fast, scalable data warehouse that makes it simple and cost-effective to analyze all your data across your data warehouse and data lake.",
    "category": "Amazon",
    "title": "Amazon Redshift Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Redshift with SalesBlink",
    "metaDescription": "Integrate Amazon Redshift and other Amazon Tools with SalesBlink. Two Way Amazon Redshift Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "clieent.com",
    "slug": "clieentcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f87e3bcc6b14d9fff5cab6fe6555a7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "clieent.com is a powerful crm that helps you grow your business",
    "category": "CRM (Customer Relationship Management)",
    "title": "clieent.com Integration With Cold Email Sales Tool",
    "h1": "Integrate clieent.com with SalesBlink",
    "metaDescription": "Integrate clieent.com and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way clieent.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crazy Egg",
    "slug": "crazy-egg",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6de149b8dbbd155e17b15738368feef0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Crazy Egg to see what’s hot and what’s not, and to know what your web visitors are doing with tools, such as heatmaps, recordings, surveys, A/B testing & more.",
    "category": "Analytics",
    "title": "Crazy Egg Integration With Cold Email Sales Tool",
    "h1": "Integrate Crazy Egg with SalesBlink",
    "metaDescription": "Integrate Crazy Egg and other Analytics Tools with SalesBlink. Two Way Crazy Egg Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Green Future Project",
    "slug": "green-future-project",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ffe995ab3312f908680cd5e68c6f318_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate with Green Future Project to offset your emissions with every transaction",
    "category": "Commerce",
    "title": "Green Future Project Integration With Cold Email Sales Tool",
    "h1": "Integrate Green Future Project with SalesBlink",
    "metaDescription": "Integrate Green Future Project and other Commerce Tools with SalesBlink. Two Way Green Future Project Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digital Practice",
    "slug": "digital-practice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d211001d2d626df1a71281e030567bc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital Practice is a Practice Management Software that helps practices manage their day-to-day admin, HR, accounting, project management, HR, CRM and much more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Digital Practice Integration With Cold Email Sales Tool",
    "h1": "Integrate Digital Practice with SalesBlink",
    "metaDescription": "Integrate Digital Practice and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Digital Practice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oracle Primavera Cloud",
    "slug": "oracle-primavera-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ebdcb76606d26f8a50e5fd5e038ae956_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Oracle Primavera cloud is an integrated CPM and task management solution for Construction & Engineering industry.",
    "category": "Project Management",
    "title": "Oracle Primavera Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Oracle Primavera Cloud with SalesBlink",
    "metaDescription": "Integrate Oracle Primavera Cloud and other Project Management Tools with SalesBlink. Two Way Oracle Primavera Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inquisiq LMS",
    "slug": "inquisiq-lms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b38970c3305db619ae0d7a97ca094fa2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inquisiq LMS is a learning management system that helps you build and deliver powerful learning programs.",
    "category": "Online Courses",
    "title": "Inquisiq LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Inquisiq LMS with SalesBlink",
    "metaDescription": "Integrate Inquisiq LMS and other Online Courses Tools with SalesBlink. Two Way Inquisiq LMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MediaSilo",
    "slug": "mediasilo",
    "logo": "https://zapier-images.imgix.net/storage/services/dfd37c1d6f8c4405d208cf2f0104ae69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MediaSilo is a collaboration tool for creative teams to review and organize your videos, images, and files.",
    "category": "Team Collaboration",
    "title": "MediaSilo Integration With Cold Email Sales Tool",
    "h1": "Integrate MediaSilo with SalesBlink",
    "metaDescription": "Integrate MediaSilo and other Team Collaboration Tools with SalesBlink. Two Way MediaSilo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salescamp",
    "slug": "salescamp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/896cafbbdbb1772dd5de9fdd89d9fad0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salescamp app help to simplify your sales workflow and increase sales productivity. Salescamp is built to thrive your sales. Maximise your sales up to 2x.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salescamp Integration With Cold Email Sales Tool",
    "h1": "Integrate Salescamp with SalesBlink",
    "metaDescription": "Integrate Salescamp and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salescamp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orderry",
    "slug": "orderry",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61483eeabb6b8d0dd5fc7c5ceb471059.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Orderry Is End-To-End Software For Your Service Business Or Repair Shop",
    "category": "CRM (Customer Relationship Management)",
    "title": "Orderry Integration With Cold Email Sales Tool",
    "h1": "Integrate Orderry with SalesBlink",
    "metaDescription": "Integrate Orderry and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Orderry Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Atarim",
    "slug": "atarim",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08b54953d90bf788389b9ba2e712972b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Atarim is a client-facing web agency management platform designed to speed up your work, client collaboration and team management",
    "category": "Website & App Building",
    "title": "Atarim Integration With Cold Email Sales Tool",
    "h1": "Integrate Atarim with SalesBlink",
    "metaDescription": "Integrate Atarim and other Website & App Building Tools with SalesBlink. Two Way Atarim Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SpeechTrans",
    "slug": "speechtrans",
    "logo": "https://zapier-images.imgix.net/storage/developer/f34a3f6a133ec9e5296b7ff0f272348d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert Speech to Text (Transcribe audio or video files) through Automatic Speech Recognition (ASR) 44 languages, Convert Text to Speech (TTS). Translate text from one language to another.",
    "category": "Documents",
    "title": "SpeechTrans Integration With Cold Email Sales Tool",
    "h1": "Integrate SpeechTrans with SalesBlink",
    "metaDescription": "Integrate SpeechTrans and other Documents Tools with SalesBlink. Two Way SpeechTrans Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tettra",
    "slug": "tettra",
    "logo": "https://zapier-images.imgix.net/storage/developer/2d19a081a5f454d0838718dafecb9275_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tettra is a company wiki for organizing & sharing knowledge with your team.",
    "category": "Documents",
    "title": "Tettra Integration With Cold Email Sales Tool",
    "h1": "Integrate Tettra with SalesBlink",
    "metaDescription": "Integrate Tettra and other Documents Tools with SalesBlink. Two Way Tettra Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Answering Legal",
    "slug": "answering-legal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bf699ad48c607c011c640019a958646.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Answering Legal’s virtual receptionists can send your legal intakes, client inquiries, and all messages directly to your favorite CRM.",
    "category": "Call Tracking",
    "title": "Answering Legal Integration With Cold Email Sales Tool",
    "h1": "Integrate Answering Legal with SalesBlink",
    "metaDescription": "Integrate Answering Legal and other Call Tracking Tools with SalesBlink. Two Way Answering Legal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agilix Dawn",
    "slug": "agilix-dawn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/64d519be8a819056ef341351ae4894c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agilix Dawn takes learning to the next level with mastery based instruction, video centric courses, interactive coaching, peer learning, and offline and mobile device support.",
    "category": "Education",
    "title": "Agilix Dawn Integration With Cold Email Sales Tool",
    "h1": "Integrate Agilix Dawn with SalesBlink",
    "metaDescription": "Integrate Agilix Dawn and other Education Tools with SalesBlink. Two Way Agilix Dawn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "6clicks",
    "slug": "6clicks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a20d3d88697185e3d6dbd216c710fd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "6clicks is an operating system for risk and compliance.",
    "category": "Productivity",
    "title": "6clicks Integration With Cold Email Sales Tool",
    "h1": "Integrate 6clicks with SalesBlink",
    "metaDescription": "Integrate 6clicks and other Productivity Tools with SalesBlink. Two Way 6clicks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TimeLive",
    "slug": "timelive",
    "logo": "https://zapier-images.imgix.net/storage/developer/28b1633d789957c1398262087cc50e93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeLive time and expense tracking software.",
    "category": "Time Tracking Software",
    "title": "TimeLive Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeLive with SalesBlink",
    "metaDescription": "Integrate TimeLive and other Time Tracking Software Tools with SalesBlink. Two Way TimeLive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ardary-Sms",
    "slug": "ardary-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/10ed526068088639239870d7bb63395e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ardary-Sms is a collaboration tool that you can use to Send SMS messages immediately to any number in the world.",
    "category": "Developer Tools",
    "title": "Ardary-Sms Integration With Cold Email Sales Tool",
    "h1": "Integrate Ardary-Sms with SalesBlink",
    "metaDescription": "Integrate Ardary-Sms and other Developer Tools Tools with SalesBlink. Two Way Ardary-Sms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inksprout",
    "slug": "inksprout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e3c8258bfbaa3c83e5297e282eac0359.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inksprout lets you summarize articles easily and in an automated manner.",
    "category": "Social Media Marketing",
    "title": "Inksprout Integration With Cold Email Sales Tool",
    "h1": "Integrate Inksprout with SalesBlink",
    "metaDescription": "Integrate Inksprout and other Social Media Marketing Tools with SalesBlink. Two Way Inksprout Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FynCom",
    "slug": "fyncom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/74a8796072e6514af153b6c7c713e153.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FynCom helps businesses use their marketing budgets more efficiently by allowing them to reward responses to surveys, in-app notifications,  outbound emails, calls, and messages.",
    "category": "Marketing",
    "title": "FynCom Integration With Cold Email Sales Tool",
    "h1": "Integrate FynCom with SalesBlink",
    "metaDescription": "Integrate FynCom and other Marketing Tools with SalesBlink. Two Way FynCom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Done4U",
    "slug": "done4u",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e09e38af4ec8345984344f96da407d96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Done4U is a marketing automation tool to organise leads and communications.",
    "category": "Marketing",
    "title": "Done4U Integration With Cold Email Sales Tool",
    "h1": "Integrate Done4U with SalesBlink",
    "metaDescription": "Integrate Done4U and other Marketing Tools with SalesBlink. Two Way Done4U Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ksaar",
    "slug": "ksaar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e12ea4c9809219c6ca1e8dfe256e178_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Ksaar, you can build your own custom enterprise system, in a few hours, without coding.",
    "category": "App Builder",
    "title": "Ksaar Integration With Cold Email Sales Tool",
    "h1": "Integrate Ksaar with SalesBlink",
    "metaDescription": "Integrate Ksaar and other App Builder Tools with SalesBlink. Two Way Ksaar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Rival",
    "slug": "rival",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86a4dab4000aaf687bb3434874b5cda9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rival is a mobile-first, insight community platform that market researchers use to uncover actionable insights fast and effectively.",
    "category": "Forms & Surveys",
    "title": "Rival Integration With Cold Email Sales Tool",
    "h1": "Integrate Rival with SalesBlink",
    "metaDescription": "Integrate Rival and other Forms & Surveys Tools with SalesBlink. Two Way Rival Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Archbee",
    "slug": "archbee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2fd0ea723f467db59e3c6de4fa7e9e2d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Publish product and API documentation with a no-code tool.",
    "category": "Website Builders",
    "title": "Archbee Integration With Cold Email Sales Tool",
    "h1": "Integrate Archbee with SalesBlink",
    "metaDescription": "Integrate Archbee and other Website Builders Tools with SalesBlink. Two Way Archbee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSPLANET",
    "slug": "smsplanet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11fde009084baedcfd34932807b13914.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSPLANET is a Polish mobile marketing platform which provides bulk SMS, MMS & EMAIL services.",
    "category": "Phone & SMS",
    "title": "SMSPLANET Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSPLANET with SalesBlink",
    "metaDescription": "Integrate SMSPLANET and other Phone & SMS Tools with SalesBlink. Two Way SMSPLANET Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyCarTracks",
    "slug": "mycartracks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84cbcb5bbebe3e7fe82cdce067c9320f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyCarTracks is a reliable smartphone-based vehicle tracking service",
    "category": "Accounting",
    "title": "MyCarTracks Integration With Cold Email Sales Tool",
    "h1": "Integrate MyCarTracks with SalesBlink",
    "metaDescription": "Integrate MyCarTracks and other Accounting Tools with SalesBlink. Two Way MyCarTracks Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BuyerAssist",
    "slug": "buyerassist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/334a79423f9e910f4cf10dd183f26e52_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BuyerAssist is a buyer engagement platform that enables you to provide your buyers with a single, integrated, collaborative engagement interface throughout their journey.",
    "category": "Sales & CRM",
    "title": "BuyerAssist Integration With Cold Email Sales Tool",
    "h1": "Integrate BuyerAssist with SalesBlink",
    "metaDescription": "Integrate BuyerAssist and other Sales & CRM Tools with SalesBlink. Two Way BuyerAssist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intuto",
    "slug": "intuto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3158d266c5ab8bb6507e9023c1cb8c5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intuto is an online Learning Management System designed to help businesses get their learning online quickly and easily.",
    "category": "Online Courses",
    "title": "Intuto Integration With Cold Email Sales Tool",
    "h1": "Integrate Intuto with SalesBlink",
    "metaDescription": "Integrate Intuto and other Online Courses Tools with SalesBlink. Two Way Intuto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Runo CRM",
    "slug": "runo-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5f7e242fdda190c60fc3f223ee58fb53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Runo CRM is a calling and sales focused mobile CRM.",
    "category": "Sales & CRM",
    "title": "Runo CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Runo CRM with SalesBlink",
    "metaDescription": "Integrate Runo CRM and other Sales & CRM Tools with SalesBlink. Two Way Runo CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LemonInk",
    "slug": "lemonink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/870e3de8f786b7972dcc08cfffae160d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "THE ebook watermarking API that makes it a breeze to add safe ebook sales to your webstore.",
    "category": "eCommerce",
    "title": "LemonInk Integration With Cold Email Sales Tool",
    "h1": "Integrate LemonInk with SalesBlink",
    "metaDescription": "Integrate LemonInk and other eCommerce Tools with SalesBlink. Two Way LemonInk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Question.to",
    "slug": "questionto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd4a98fe17fe1a3bdd7800b58bc6ff65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect ideas, compliments and issues with our uncomplicated website embed. Users can take video recordings with Loom, or write normal text. No Loom account or extension required.",
    "category": "Customer Support",
    "title": "Question.to Integration With Cold Email Sales Tool",
    "h1": "Integrate Question.to with SalesBlink",
    "metaDescription": "Integrate Question.to and other Customer Support Tools with SalesBlink. Two Way Question.to Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EZ File Drop",
    "slug": "ez-file-drop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a77d19029430ad031d5180c88cfedcc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With EZ File Drop, anyone can upload files straight to your cloud storage without the need to login, download an app, create an account, or stress about file permissions.",
    "category": "Productivity",
    "title": "EZ File Drop Integration With Cold Email Sales Tool",
    "h1": "Integrate EZ File Drop with SalesBlink",
    "metaDescription": "Integrate EZ File Drop and other Productivity Tools with SalesBlink. Two Way EZ File Drop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contlo",
    "slug": "contlo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/98f8ee5f909990dc3a8f5f91c1c27c2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contlo is a commerce Data and Marketing Platform for Ecommerce and D2C brands that enables e-commerce stores to increase their sales, and drive customer loyalty and retention using omnichannel customer engagement.",
    "category": "eCommerce",
    "title": "Contlo Integration With Cold Email Sales Tool",
    "h1": "Integrate Contlo with SalesBlink",
    "metaDescription": "Integrate Contlo and other eCommerce Tools with SalesBlink. Two Way Contlo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Route",
    "slug": "route",
    "logo": "https://zapier-images.imgix.net/storage/developer/f2735473fd9358979f9fa70ab6fb0535.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Route is a multi-channel marketing automation platform. Identify contacts in your site or app, track their behaviour and trigger automated personalized campaigns.",
    "category": "Marketing Automation",
    "title": "Route Integration With Cold Email Sales Tool",
    "h1": "Integrate Route with SalesBlink",
    "metaDescription": "Integrate Route and other Marketing Automation Tools with SalesBlink. Two Way Route Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cardz",
    "slug": "cardz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9b07e28174168fd24ec0307d699062e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The most advanced Digital Business Cards that get results and tools for your business.",
    "category": "Dashboards",
    "title": "Cardz Integration With Cold Email Sales Tool",
    "h1": "Integrate Cardz with SalesBlink",
    "metaDescription": "Integrate Cardz and other Dashboards Tools with SalesBlink. Two Way Cardz Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "MENU TIGER",
    "slug": "menu-tiger",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e3884b1b04a95e991b39c6fce71cca2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MENU TIGER makes it easy for your customers to order and pay. Customize and edit your QR code menu to get higher returns. Start reinventing your customers’ digital menu experience now!",
    "category": "Productivity",
    "title": "MENU TIGER Integration With Cold Email Sales Tool",
    "h1": "Integrate MENU TIGER with SalesBlink",
    "metaDescription": "Integrate MENU TIGER and other Productivity Tools with SalesBlink. Two Way MENU TIGER Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swype",
    "slug": "swype",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8b8c4302276063ded9c9bcb10894a36.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swype is a next generation form building tool.",
    "category": "Forms & Surveys",
    "title": "Swype Integration With Cold Email Sales Tool",
    "h1": "Integrate Swype with SalesBlink",
    "metaDescription": "Integrate Swype and other Forms & Surveys Tools with SalesBlink. Two Way Swype Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eWay-CRM",
    "slug": "eway-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd1abdfe99f678b6aef0eb121c7adcc5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eWay-CRM is a CRM software embedded right into Microsoft Outlook. It helps companies all around the world efficiently manage contacts, customers, deals, projects, and marketing campaigns.",
    "category": "CRM (Customer Relationship Management)",
    "title": "eWay-CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate eWay-CRM with SalesBlink",
    "metaDescription": "Integrate eWay-CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way eWay-CRM Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Promotion Vault",
    "slug": "promotion-vault",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb47d2ce64bfff4ae4f19c5bec9fcfd7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Promotion Vault is a zero-waste reward platform that provides its customers the ability to deliver digital gift cards at scale.",
    "category": "Marketing",
    "title": "Promotion Vault Integration With Cold Email Sales Tool",
    "h1": "Integrate Promotion Vault with SalesBlink",
    "metaDescription": "Integrate Promotion Vault and other Marketing Tools with SalesBlink. Two Way Promotion Vault Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Opentute",
    "slug": "opentute",
    "logo": "https://zapier-images.imgix.net/storage/services/1832ad8581c2ba30ac6806f11b6ca2a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Opentute is an e-learning platform for anything from selling online courses or blended learning and training for your staff.",
    "category": "Online Courses",
    "title": "Opentute Integration With Cold Email Sales Tool",
    "h1": "Integrate Opentute with SalesBlink",
    "metaDescription": "Integrate Opentute and other Online Courses Tools with SalesBlink. Two Way Opentute Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crescendo Lab",
    "slug": "cresendo-lab",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9666d5a7ce2c7da43574014f8c50ef76_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An connector for Crescendo Lab's marketing platform - MAAC",
    "category": "Marketing Automation",
    "title": "Crescendo Lab Integration With Cold Email Sales Tool",
    "h1": "Integrate Crescendo Lab with SalesBlink",
    "metaDescription": "Integrate Crescendo Lab and other Marketing Automation Tools with SalesBlink. Two Way Crescendo Lab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BLOOCK",
    "slug": "bloock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/71f7f144870675fb04ebcccf45c6324b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BLOOCK enables any project to record data privately in Ethereum without any effort.",
    "category": "Developer Tools",
    "title": "BLOOCK Integration With Cold Email Sales Tool",
    "h1": "Integrate BLOOCK with SalesBlink",
    "metaDescription": "Integrate BLOOCK and other Developer Tools Tools with SalesBlink. Two Way BLOOCK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alerty",
    "slug": "alerty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b4498540e6cc4dea441c5069b1a3de75.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alerty enables you to send push notifications to yourself from anywhere.",
    "category": "Developer Tools",
    "title": "Alerty Integration With Cold Email Sales Tool",
    "h1": "Integrate Alerty with SalesBlink",
    "metaDescription": "Integrate Alerty and other Developer Tools Tools with SalesBlink. Two Way Alerty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Charidy",
    "slug": "charidy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6002040a28502694eb708f95f0e61130.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Charidy is a platform for non profit organization to run their fundraising campaigns.",
    "category": "Commerce",
    "title": "Charidy Integration With Cold Email Sales Tool",
    "h1": "Integrate Charidy with SalesBlink",
    "metaDescription": "Integrate Charidy and other Commerce Tools with SalesBlink. Two Way Charidy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bannertize",
    "slug": "bannertize",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28d965d8389a232ac14abd5ff5600bc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bannertize is a tool to generate Dynamic Images & PDFs on the fly. Supports dark mode, retina & transparent images.",
    "category": "Images & Design",
    "title": "Bannertize Integration With Cold Email Sales Tool",
    "h1": "Integrate Bannertize with SalesBlink",
    "metaDescription": "Integrate Bannertize and other Images & Design Tools with SalesBlink. Two Way Bannertize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StatePIXEL",
    "slug": "statepixel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8db713b767a052543f2baa498c1b59a8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StatePIXEL is a gadget with a service to change the lamp to custom colors",
    "category": "Internet of Things",
    "title": "StatePIXEL Integration With Cold Email Sales Tool",
    "h1": "Integrate StatePIXEL with SalesBlink",
    "metaDescription": "Integrate StatePIXEL and other Internet of Things Tools with SalesBlink. Two Way StatePIXEL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datasurf",
    "slug": "datasurf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02a8430eef6e9479b0e9ed9c36797016.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datasurf is on-cloud software for the integrated management of multichannel sales.",
    "category": "Commerce",
    "title": "Datasurf Integration With Cold Email Sales Tool",
    "h1": "Integrate Datasurf with SalesBlink",
    "metaDescription": "Integrate Datasurf and other Commerce Tools with SalesBlink. Two Way Datasurf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flowster",
    "slug": "flowster",
    "logo": "https://zapier-images.imgix.net/storage/services/43d2012d649d46b032f86e575df7475c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flowster is a workflow management tool that helps you automate and outsource your repetitive business processes.",
    "category": "Project Management",
    "title": "Flowster Integration With Cold Email Sales Tool",
    "h1": "Integrate Flowster with SalesBlink",
    "metaDescription": "Integrate Flowster and other Project Management Tools with SalesBlink. Two Way Flowster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Online Car Store",
    "slug": "online-car-store",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24df3e7576f9137d8200fa2d515b2039.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online Car Store allows car dealers and brands to create quick landing sites to sell their cars in an easy way, connected to OEM's apps.",
    "category": "eCommerce",
    "title": "Online Car Store Integration With Cold Email Sales Tool",
    "h1": "Integrate Online Car Store with SalesBlink",
    "metaDescription": "Integrate Online Car Store and other eCommerce Tools with SalesBlink. Two Way Online Car Store Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Text2VIP",
    "slug": "text2vip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6628f1f1eb22ed115f1788db5fc68a0e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text2VIP is an SMS Marketing Software to allow you to drive customer engagement through SMS Marketing.",
    "category": "Communication",
    "title": "Text2VIP Integration With Cold Email Sales Tool",
    "h1": "Integrate Text2VIP with SalesBlink",
    "metaDescription": "Integrate Text2VIP and other Communication Tools with SalesBlink. Two Way Text2VIP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BigDataCloud",
    "slug": "bigdatacloud",
    "logo": "https://zapier-images.imgix.net/storage/services/2b0e6aae5a06f46e45261d5c5af1419c.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BigDataCloud provides advanced IP geolocation and Reverse geocoding APIs, along with customer data APIs like phone number and email address validation APIS.",
    "category": "Security & Identity Tools",
    "title": "BigDataCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate BigDataCloud with SalesBlink",
    "metaDescription": "Integrate BigDataCloud and other Security & Identity Tools Tools with SalesBlink. Two Way BigDataCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Glue",
    "slug": "glue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5dbaf17f2c9510bc5b04caad6e135ea2_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Glue is a better way to chat without the noise and channel fatigue.",
    "category": "Team Chat",
    "title": "Glue Integration With Cold Email Sales Tool",
    "h1": "Integrate Glue with SalesBlink",
    "metaDescription": "Integrate Glue and other Team Chat Tools with SalesBlink. Two Way Glue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpCity",
    "slug": "upcity",
    "logo": "https://zapier-images.imgix.net/storage/developer/ffb8ead52baf4914b72aa0a73df2670e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpCity is a local inbound marketing platform for agencies, providing tools for SEO, reputation management, social media, and reporting.",
    "category": "Marketing Automation",
    "title": "UpCity Integration With Cold Email Sales Tool",
    "h1": "Integrate UpCity with SalesBlink",
    "metaDescription": "Integrate UpCity and other Marketing Automation Tools with SalesBlink. Two Way UpCity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Candid Wholesale",
    "slug": "candid-wholesale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c714a5689bb9616af30f2360acf96fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Candid Wholesale is an ecommerce and B2B management platform that connects Brands and Retailers.",
    "category": "eCommerce",
    "title": "Candid Wholesale Integration With Cold Email Sales Tool",
    "h1": "Integrate Candid Wholesale with SalesBlink",
    "metaDescription": "Integrate Candid Wholesale and other eCommerce Tools with SalesBlink. Two Way Candid Wholesale Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VivifyScrum",
    "slug": "vivifyscrum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf4f95069f84da19b2fdf136875e365e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VivifyScrum is a web-based project management tool for small agile teams and large organizations alike. Handle all your projects in one place - from top to bottom.",
    "category": "Project Management",
    "title": "VivifyScrum Integration With Cold Email Sales Tool",
    "h1": "Integrate VivifyScrum with SalesBlink",
    "metaDescription": "Integrate VivifyScrum and other Project Management Tools with SalesBlink. Two Way VivifyScrum Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mumara",
    "slug": "mumara",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d7f59e0ffef44a2108a3910385d6a41_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mumara Campaigns is a collection of core features to help you leverage data-driven email marketing. Setup a correct workflow and send the right email at the right time to the right person with the right CTA (call to action) to get the maximum engagements... Just with Mumara Campaigns!",
    "category": "Marketing Automation",
    "title": "Mumara Integration With Cold Email Sales Tool",
    "h1": "Integrate Mumara with SalesBlink",
    "metaDescription": "Integrate Mumara and other Marketing Automation Tools with SalesBlink. Two Way Mumara Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Brij",
    "slug": "brij",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39230a6d91237f8dc76fcff3bc123371.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brij is a platform for creating digital experiences for products activated by QR codes",
    "category": "CRM (Customer Relationship Management)",
    "title": "Brij Integration With Cold Email Sales Tool",
    "h1": "Integrate Brij with SalesBlink",
    "metaDescription": "Integrate Brij and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Brij Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hirely",
    "slug": "hirely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a429127ec76a4aee3b8a0a767c4bc1ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hirely is a software solution for companies to create engaging career pages for open jobs and manage applications digitally with its team.",
    "category": "HR Talent & Recruitment",
    "title": "Hirely Integration With Cold Email Sales Tool",
    "h1": "Integrate Hirely with SalesBlink",
    "metaDescription": "Integrate Hirely and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hirely Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Universal Summarizer by Kagi",
    "slug": "universal-summarizer-by-kagi-ca1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/32c465622f2c31c8df50afa871b11046.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Summarize anything on the web with Universal Summarizer by Kagi",
    "category": "AI Tools",
    "title": "Universal Summarizer by Kagi Integration With Cold Email Sales Tool",
    "h1": "Integrate Universal Summarizer by Kagi with SalesBlink",
    "metaDescription": "Integrate Universal Summarizer by Kagi and other AI Tools Tools with SalesBlink. Two Way Universal Summarizer by Kagi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dolby.io",
    "slug": "dolbyio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f1a20198330f3843857a93ab815fbb7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dolby.io offers support for media workflows to analyze and enhance file-based media and improve the quality of audience experiences.",
    "category": "Video & Audio",
    "title": "Dolby.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Dolby.io with SalesBlink",
    "metaDescription": "Integrate Dolby.io and other Video & Audio Tools with SalesBlink. Two Way Dolby.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Openpaye",
    "slug": "openpaye",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b474134109c932f9b3f95aa1b8250b8b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Openpaye is an online payroll software for SME, allowing you to produce your payroll quickly and reliably.",
    "category": "HR Talent & Recruitment",
    "title": "Openpaye Integration With Cold Email Sales Tool",
    "h1": "Integrate Openpaye with SalesBlink",
    "metaDescription": "Integrate Openpaye and other HR Talent & Recruitment Tools with SalesBlink. Two Way Openpaye Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Testlify",
    "slug": "testlify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c497d27db5827a2c91275e8f3cb76a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "#1 Talent Assessment Platform for Recruiting at scale. Find the best candidates with deep analysis that’s accurate, automated, and unbiased",
    "category": "HR Talent & Recruitment",
    "title": "Testlify Integration With Cold Email Sales Tool",
    "h1": "Integrate Testlify with SalesBlink",
    "metaDescription": "Integrate Testlify and other HR Talent & Recruitment Tools with SalesBlink. Two Way Testlify Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Let's Connect",
    "slug": "lets-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b108b84e85b5db66d0f4d44d512353d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect with your customers via the Let's Connect widget to catch your customers when their interest is at it’s peak! Using live communication makes converting them into new customers super easy.",
    "category": "Ads & Conversion",
    "title": "Let's Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Let's Connect with SalesBlink",
    "metaDescription": "Integrate Let's Connect and other Ads & Conversion Tools with SalesBlink. Two Way Let's Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TxtSquad",
    "slug": "txtsquad",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20cb73ec2542a73d6a9d5e7185209a74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TxtSquad helps organizations with personalized, real time communications. Adding texting to your engagement toolbox.",
    "category": "Phone & SMS",
    "title": "TxtSquad Integration With Cold Email Sales Tool",
    "h1": "Integrate TxtSquad with SalesBlink",
    "metaDescription": "Integrate TxtSquad and other Phone & SMS Tools with SalesBlink. Two Way TxtSquad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyHR",
    "slug": "myhr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91d3a66d943f6b8475818bec61d74590.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyHR simplifies people management with integrated HR advisory and easy-to-use HR software.",
    "category": "Human Resources",
    "title": "MyHR Integration With Cold Email Sales Tool",
    "h1": "Integrate MyHR with SalesBlink",
    "metaDescription": "Integrate MyHR and other Human Resources Tools with SalesBlink. Two Way MyHR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mallabe Validations",
    "slug": "mallabe-validations-phone-email-",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6fb8f61e77e919a7851b0287f3f15210_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mallabe is an automation toolchain.\nThis integration can be used by sales, support, other departments to validate customer data such as phone, email, credit cards.\n\nOther mallabe Integrations:\n- mallabe Currencies - for daily currencies, currencies conversion",
    "category": "Content & Files",
    "title": "mallabe Validations Integration With Cold Email Sales Tool",
    "h1": "Integrate mallabe Validations with SalesBlink",
    "metaDescription": "Integrate mallabe Validations and other Content & Files Tools with SalesBlink. Two Way mallabe Validations Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Beagle Security",
    "slug": "beagle-security",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6f1f6e486bed80430e6375511ed79d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beagle Security is a web application penetration testing tool that helps you to identify vulnerabilities on your website before hackers exploit them.",
    "category": "Security & Identity Tools",
    "title": "Beagle Security Integration With Cold Email Sales Tool",
    "h1": "Integrate Beagle Security with SalesBlink",
    "metaDescription": "Integrate Beagle Security and other Security & Identity Tools Tools with SalesBlink. Two Way Beagle Security Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "SeeBotRun - Link",
    "slug": "seebotrun-link",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e38a71cb6d2dffc6a6c4bf7f804f1e2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SeeBotRun - Link is one of SeeBotRun products that provides shorten URLs, branded domains, QR codes, and platform tracking all from one simple link.",
    "category": "URL Shortener",
    "title": "SeeBotRun - Link Integration With Cold Email Sales Tool",
    "h1": "Integrate SeeBotRun - Link with SalesBlink",
    "metaDescription": "Integrate SeeBotRun - Link and other URL Shortener Tools with SalesBlink. Two Way SeeBotRun - Link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shireburn Indigo People",
    "slug": "shireburn-indigo-people",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/323ab99b90ee3f8363a849145f48732c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Indigo People is a people management platform for payroll, HR, leave, attendance management, and more.",
    "category": "Human Resources",
    "title": "Shireburn Indigo People Integration With Cold Email Sales Tool",
    "h1": "Integrate Shireburn Indigo People with SalesBlink",
    "metaDescription": "Integrate Shireburn Indigo People and other Human Resources Tools with SalesBlink. Two Way Shireburn Indigo People Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coaching Loft",
    "slug": "coaching-loft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c72de2297ae47a84300c1c4713dc523a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coaching Loft is a platform that helps coaches manage and organize their practices to deliver a fluent coaching experience to their clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Coaching Loft Integration With Cold Email Sales Tool",
    "h1": "Integrate Coaching Loft with SalesBlink",
    "metaDescription": "Integrate Coaching Loft and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Coaching Loft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShopHire",
    "slug": "shophire-ca119476",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aaad0e79c8577af482a64e5f54dfa27a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShopHire is a recruitment marketing and applicant tracking plugin for Shopify stores to promote their employer brand, post jobs and manage their hiring process.",
    "category": "HR Talent & Recruitment",
    "title": "ShopHire Integration With Cold Email Sales Tool",
    "h1": "Integrate ShopHire with SalesBlink",
    "metaDescription": "Integrate ShopHire and other HR Talent & Recruitment Tools with SalesBlink. Two Way ShopHire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Main Campaign",
    "slug": "main-campaign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8b2da9ebdbda9a380f62a45f43fa557.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Main Campaign is an ad performance analytics tool to match actual revenue in CRMs to ads on marketing platforms.",
    "category": "Ads & Conversion",
    "title": "Main Campaign Integration With Cold Email Sales Tool",
    "h1": "Integrate Main Campaign with SalesBlink",
    "metaDescription": "Integrate Main Campaign and other Ads & Conversion Tools with SalesBlink. Two Way Main Campaign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MotionTools",
    "slug": "motiontools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3724a0abda97424dd8fcbfa2c8c53a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your workforce & deliveries with MotionTools.",
    "category": "Dashboards",
    "title": "MotionTools Integration With Cold Email Sales Tool",
    "h1": "Integrate MotionTools with SalesBlink",
    "metaDescription": "Integrate MotionTools and other Dashboards Tools with SalesBlink. Two Way MotionTools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Relysia",
    "slug": "relysia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b719750ae5399df06a9c89acf78291ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Relysia is a Blockchain as a Service offer to write immutable data to chain, create smart contracts on the fly and send them to hundrets of recipients at once.",
    "category": "Developer Tools",
    "title": "Relysia Integration With Cold Email Sales Tool",
    "h1": "Integrate Relysia with SalesBlink",
    "metaDescription": "Integrate Relysia and other Developer Tools Tools with SalesBlink. Two Way Relysia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pelcro",
    "slug": "pelcro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c07a4689f66065d81b5eeb894ad254f5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pelcro is a subscription and membership management platform",
    "category": "eCommerce",
    "title": "Pelcro Integration With Cold Email Sales Tool",
    "h1": "Integrate Pelcro with SalesBlink",
    "metaDescription": "Integrate Pelcro and other eCommerce Tools with SalesBlink. Two Way Pelcro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quiz Class",
    "slug": "quiz-class",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9344f234bcf9e6117a6f1585947dfc8f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quiz Class is a lead generation platform through Quizzes.",
    "category": "Marketing",
    "title": "Quiz Class Integration With Cold Email Sales Tool",
    "h1": "Integrate Quiz Class with SalesBlink",
    "metaDescription": "Integrate Quiz Class and other Marketing Tools with SalesBlink. Two Way Quiz Class Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upflow",
    "slug": "upflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/469768430b5e1ebae3c1e81e75ca3126_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your invoices, from creation to payment, to collect 100% of your turnover.",
    "category": "Accounting",
    "title": "Upflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Upflow with SalesBlink",
    "metaDescription": "Integrate Upflow and other Accounting Tools with SalesBlink. Two Way Upflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kraaft",
    "slug": "kraaft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9938ff3312da2747568147d07a75c87b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kraaft is a communication app for frontline workers: make your phone a walkie-talkie, and contextualize each exchange in your project flows.",
    "category": "Team Chat",
    "title": "Kraaft Integration With Cold Email Sales Tool",
    "h1": "Integrate Kraaft with SalesBlink",
    "metaDescription": "Integrate Kraaft and other Team Chat Tools with SalesBlink. Two Way Kraaft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NoCodeForm",
    "slug": "nocodeform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6c870cf22545b9371ff2d5104340c96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NoCodeForm is a form backend service that enables you to create, store and automate form submissions.",
    "category": "Forms & Surveys",
    "title": "NoCodeForm Integration With Cold Email Sales Tool",
    "h1": "Integrate NoCodeForm with SalesBlink",
    "metaDescription": "Integrate NoCodeForm and other Forms & Surveys Tools with SalesBlink. Two Way NoCodeForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PSOhub",
    "slug": "psohub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eea25ad1950f7eb41a946cd3574d6c76.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PSOhub provides predictive project management, smart contract management, self-driving time and expense tracking, and invoicing in a seamlessly integrated platform.",
    "category": "Project Management",
    "title": "PSOhub Integration With Cold Email Sales Tool",
    "h1": "Integrate PSOhub with SalesBlink",
    "metaDescription": "Integrate PSOhub and other Project Management Tools with SalesBlink. Two Way PSOhub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clever Messenger",
    "slug": "clever-messenger",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/409605ac3c8b843c0162c23b092bf1b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clever Messenger is a Facebook Messenger Bot building platform",
    "category": "Marketing",
    "title": "Clever Messenger Integration With Cold Email Sales Tool",
    "h1": "Integrate Clever Messenger with SalesBlink",
    "metaDescription": "Integrate Clever Messenger and other Marketing Tools with SalesBlink. Two Way Clever Messenger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reward Sciences",
    "slug": "reward-sciences",
    "logo": "https://zapier-images.imgix.net/storage/developer/c17bf322c8cb56e9b12efcf3e090360a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reward Sciences is a loyalty system that lets you incentivize and reward your end users as they engage on one or more activities of your choice.",
    "category": "Developer Tools",
    "title": "Reward Sciences Integration With Cold Email Sales Tool",
    "h1": "Integrate Reward Sciences with SalesBlink",
    "metaDescription": "Integrate Reward Sciences and other Developer Tools Tools with SalesBlink. Two Way Reward Sciences Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ConfigCat",
    "slug": "configcat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/14661db1286cec0b5e0fb63cac7933dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "10 minutes trainable feature flag and configuration management service with unlimited team size, awesome support and no surprises.",
    "category": "Developer Tools",
    "title": "ConfigCat Integration With Cold Email Sales Tool",
    "h1": "Integrate ConfigCat with SalesBlink",
    "metaDescription": "Integrate ConfigCat and other Developer Tools Tools with SalesBlink. Two Way ConfigCat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GTR Event Technology",
    "slug": "gtr-event-technology",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5817d3eadb1c6752d4f4d41dfa32ef36.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GTR™ provides event technology & end-to-end support to help planners execute unforgettable conferences, tradeshows, meetings, and events.  Connect with your existing workflow and put your event data where you need it with our all-in-one event management platform.",
    "category": "Event Management",
    "title": "GTR Event Technology Integration With Cold Email Sales Tool",
    "h1": "Integrate GTR Event Technology with SalesBlink",
    "metaDescription": "Integrate GTR Event Technology and other Event Management Tools with SalesBlink. Two Way GTR Event Technology Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TimeOps",
    "slug": "timeops",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a31ad315e4faeb4d4a443a3dd821b2c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TimeOps is simple and intuitive timetracking software.",
    "category": "Time Tracking Software",
    "title": "TimeOps Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeOps with SalesBlink",
    "metaDescription": "Integrate TimeOps and other Time Tracking Software Tools with SalesBlink. Two Way TimeOps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coupon Reach",
    "slug": "coupon-reach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1736ce554b922d12339506029dab6be6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital Coupon Marketing Platform for Businesses to create Digital Coupons for their customers.",
    "category": "Marketing",
    "title": "Coupon Reach Integration With Cold Email Sales Tool",
    "h1": "Integrate Coupon Reach with SalesBlink",
    "metaDescription": "Integrate Coupon Reach and other Marketing Tools with SalesBlink. Two Way Coupon Reach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Explara",
    "slug": "explara",
    "logo": "https://zapier-images.imgix.net/storage/services/a581dfc35d2c2fad854e8618cfa84e8d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Explara is an integrated event technology platform offering everything you need to make your event, membership, and meeting management successful.",
    "category": "Event Management",
    "title": "Explara Integration With Cold Email Sales Tool",
    "h1": "Integrate Explara with SalesBlink",
    "metaDescription": "Integrate Explara and other Event Management Tools with SalesBlink. Two Way Explara Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Crexendo",
    "slug": "crexendo",
    "logo": "https://zapier-images.imgix.net/storage/developer/65298d355f3e3dd806b7ac550633f204_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crexendo is a full-service cloud solutions provider that delivers critical voice and data technology infrastructure services to Start-up, SMB and Enterprise markets.",
    "category": "Phone & SMS",
    "title": "Crexendo Integration With Cold Email Sales Tool",
    "h1": "Integrate Crexendo with SalesBlink",
    "metaDescription": "Integrate Crexendo and other Phone & SMS Tools with SalesBlink. Two Way Crexendo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OrderForms",
    "slug": "orderforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a19865adee8ebb13eb80d4500f4d2fb4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OrderForms is easy, secure, and effective when it comes to taking payments online. Sell products & subscriptions with our drag-and-drop form builder.",
    "category": "eCommerce",
    "title": "OrderForms Integration With Cold Email Sales Tool",
    "h1": "Integrate OrderForms with SalesBlink",
    "metaDescription": "Integrate OrderForms and other eCommerce Tools with SalesBlink. Two Way OrderForms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Mavenoid",
    "slug": "mavenoid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/703cdcab6c51ef072b774cde721649cd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mavenoid combines self-service and live support in one, so companies can effortlessly support their customers in every step. From installing, to using, to troubleshooting their products.",
    "category": "Customer Support",
    "title": "Mavenoid Integration With Cold Email Sales Tool",
    "h1": "Integrate Mavenoid with SalesBlink",
    "metaDescription": "Integrate Mavenoid and other Customer Support Tools with SalesBlink. Two Way Mavenoid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BoardCRM",
    "slug": "boardcrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e7161d9f8ebd5f9ff3ac1590960100dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fresh and user-friendly CRM system of next-generation for effective small and medium-sized businesses: deal stats, sales pipeline, customer base (leads), form builder for collecting leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BoardCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate BoardCRM with SalesBlink",
    "metaDescription": "Integrate BoardCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BoardCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Akna",
    "slug": "akna",
    "logo": "https://zapier-images.imgix.net/storage/developer/006b1c3c3b8a28f1d239a27846effafe_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "E-mail Marketing Software",
    "category": "Drip Emails",
    "title": "Akna Integration With Cold Email Sales Tool",
    "h1": "Integrate Akna with SalesBlink",
    "metaDescription": "Integrate Akna and other Drip Emails Tools with SalesBlink. Two Way Akna Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Summari",
    "slug": "summari",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d11d3cda5a164703c99875db50045242_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Summari is an API that summarizes any type of text into short, concise bullets.",
    "category": "Productivity",
    "title": "Summari Integration With Cold Email Sales Tool",
    "h1": "Integrate Summari with SalesBlink",
    "metaDescription": "Integrate Summari and other Productivity Tools with SalesBlink. Two Way Summari Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iStores",
    "slug": "ca114443",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/606a6e0071225348286575d4af9352db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Istores from Isracard is a platform to create and manage your e-commerce store, including domains, invoicing, logistics, and marketing.",
    "category": "Commerce",
    "title": "iStores Integration With Cold Email Sales Tool",
    "h1": "Integrate iStores with SalesBlink",
    "metaDescription": "Integrate iStores and other Commerce Tools with SalesBlink. Two Way iStores Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flexxter",
    "slug": "flexxter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59e2185eb74bfaf2b020828b7cf642d4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flexxter is an app to plan, build and collaborate on construction projects.",
    "category": "Project Management",
    "title": "Flexxter Integration With Cold Email Sales Tool",
    "h1": "Integrate Flexxter with SalesBlink",
    "metaDescription": "Integrate Flexxter and other Project Management Tools with SalesBlink. Two Way Flexxter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Infomaniak Billetterie",
    "slug": "infomaniak-billetterie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c04f179461b3176b609fcf54150f796.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infomaniak Billetterie is a ticketing solution for discerning organisers.\nFrom selling to checking tickets, calmly master all the aspects involved in organising your events.",
    "category": "Event Management",
    "title": "Infomaniak Billetterie Integration With Cold Email Sales Tool",
    "h1": "Integrate Infomaniak Billetterie with SalesBlink",
    "metaDescription": "Integrate Infomaniak Billetterie and other Event Management Tools with SalesBlink. Two Way Infomaniak Billetterie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nvoip SMS",
    "slug": "nvoip-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc524e5a83fc80d197a7e4753c212310_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nvoip SMS is an API for send SMS to Brazilian Mobile Phone Numbers.",
    "category": "Phone & SMS",
    "title": "Nvoip SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Nvoip SMS with SalesBlink",
    "metaDescription": "Integrate Nvoip SMS and other Phone & SMS Tools with SalesBlink. Two Way Nvoip SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RVM Pro",
    "slug": "rvm-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be0136926ff53f710706d2786c35c5c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RVM Pro is a simple tool for advertisers to send ringless voicemails to potential customers.",
    "category": "Phone & SMS",
    "title": "RVM Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate RVM Pro with SalesBlink",
    "metaDescription": "Integrate RVM Pro and other Phone & SMS Tools with SalesBlink. Two Way RVM Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Enrich.so",
    "slug": "enrichso",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e89ab65483e2c07d2d63760f1683b613.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Enrich is a reverse email lookup tool that finds 50+ data points on your prospects using their email.",
    "category": "Sales & CRM",
    "title": "Enrich.so Integration With Cold Email Sales Tool",
    "h1": "Integrate Enrich.so with SalesBlink",
    "metaDescription": "Integrate Enrich.so and other Sales & CRM Tools with SalesBlink. Two Way Enrich.so Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tangelo",
    "slug": "tangelo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dfc55e78a549357d1d6384fc1f8451dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tangelo is making employee onboarding approachable and easy. With our simple checklists, employees know exactly what to focus on to get ramped up faster than ever.",
    "category": "HR Talent & Recruitment",
    "title": "Tangelo Integration With Cold Email Sales Tool",
    "h1": "Integrate Tangelo with SalesBlink",
    "metaDescription": "Integrate Tangelo and other HR Talent & Recruitment Tools with SalesBlink. Two Way Tangelo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Profit.co",
    "slug": "profitco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2695cab2b59ce914dc3021f4ea00250b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Profit is a comprehensive software solution to define Objectives & Key Results (OKRs) at every level of your organisation. Connect all of your apps with Profit.co. Profit integrates your OKRs with your favourite apps to update your tasks without any manual entry.",
    "category": "Project Management",
    "title": "Profit.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Profit.co with SalesBlink",
    "metaDescription": "Integrate Profit.co and other Project Management Tools with SalesBlink. Two Way Profit.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Formester",
    "slug": "formester-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3cae3897a456d27c2b87eeed18d73f02_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build forms in minutes using our drag and drop builder or integrate with your existing HTML form.",
    "category": "Website & App Building",
    "title": "Formester Integration With Cold Email Sales Tool",
    "h1": "Integrate Formester with SalesBlink",
    "metaDescription": "Integrate Formester and other Website & App Building Tools with SalesBlink. Two Way Formester Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Caplena",
    "slug": "caplena",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7792004e00076b278cd8746ae4b6a2eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Caplena helps you automatically analyse free-text, creating actionable results from unstructured customer feedback, tweets or app reviews.",
    "category": "Analytics",
    "title": "Caplena Integration With Cold Email Sales Tool",
    "h1": "Integrate Caplena with SalesBlink",
    "metaDescription": "Integrate Caplena and other Analytics Tools with SalesBlink. Two Way Caplena Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Learnistic",
    "slug": "learnistic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/227ec985cd2b1acd842ae28e05ebd8b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learnistic allows you to create and sell your courses with your own professionally created & prestigiously branded quality mobile app.",
    "category": "Online Courses",
    "title": "Learnistic Integration With Cold Email Sales Tool",
    "h1": "Integrate Learnistic with SalesBlink",
    "metaDescription": "Integrate Learnistic and other Online Courses Tools with SalesBlink. Two Way Learnistic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RealVuu",
    "slug": "realvuu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cdcf0a2620b3c333abe02bff98c18d25.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RealVuu is a real estate platform that helps promoters fill their empty units",
    "category": "CRM (Customer Relationship Management)",
    "title": "RealVuu Integration With Cold Email Sales Tool",
    "h1": "Integrate RealVuu with SalesBlink",
    "metaDescription": "Integrate RealVuu and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way RealVuu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Atolia",
    "slug": "atolia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21d18aa2efa3d1aec3fbc2189ee277cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Atolia is an agile and secure workspace for teams.",
    "category": "Team Collaboration",
    "title": "Atolia Integration With Cold Email Sales Tool",
    "h1": "Integrate Atolia with SalesBlink",
    "metaDescription": "Integrate Atolia and other Team Collaboration Tools with SalesBlink. Two Way Atolia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RollWorks",
    "slug": "rollworks",
    "logo": "https://zapier-images.imgix.net/storage/services/f4f39580e7b31c98245261860ec79f78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RollWorks combines lead generation with marketing and sales automation. They work with B2B companies to help them acquire new clients.",
    "category": "Drip Emails",
    "title": "RollWorks Integration With Cold Email Sales Tool",
    "h1": "Integrate RollWorks with SalesBlink",
    "metaDescription": "Integrate RollWorks and other Drip Emails Tools with SalesBlink. Two Way RollWorks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JustOneCard",
    "slug": "justonecard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c37324cfa1da926b71aad84162a18015.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JustOneCard is a personal management tools linked to a smart business card to empower people with strong relationships.",
    "category": "CRM (Customer Relationship Management)",
    "title": "JustOneCard Integration With Cold Email Sales Tool",
    "h1": "Integrate JustOneCard with SalesBlink",
    "metaDescription": "Integrate JustOneCard and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way JustOneCard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClockIt",
    "slug": "clockit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/035360254ee9b724744f3c2d59c3b9df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClockIt is a time clock software that allows you to run payroll in 5 minutes.",
    "category": "Time Tracking Software",
    "title": "ClockIt Integration With Cold Email Sales Tool",
    "h1": "Integrate ClockIt with SalesBlink",
    "metaDescription": "Integrate ClockIt and other Time Tracking Software Tools with SalesBlink. Two Way ClockIt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Patient Communicator",
    "slug": "patient-communicator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96b9888e81664a35da9438392bd7d087.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Patient Communicator is a SaaS service that facilitates communications between health providers and their patients.",
    "category": "Communication",
    "title": "Patient Communicator Integration With Cold Email Sales Tool",
    "h1": "Integrate Patient Communicator with SalesBlink",
    "metaDescription": "Integrate Patient Communicator and other Communication Tools with SalesBlink. Two Way Patient Communicator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clappia",
    "slug": "clappia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11017eaebb5e1069d60903fce409e278.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clappia provides a cloud workplace where a suite of custom apps can be created within minutes by any business user.",
    "category": "Databases",
    "title": "Clappia Integration With Cold Email Sales Tool",
    "h1": "Integrate Clappia with SalesBlink",
    "metaDescription": "Integrate Clappia and other Databases Tools with SalesBlink. Two Way Clappia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SideDrawer",
    "slug": "sidedrawer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a441ab0bc92f6c5f86801c6c0c35126_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SideDrawer is a collaborative digital vault platform designed for efficiency & privacy.",
    "category": "File Management & Storage",
    "title": "SideDrawer Integration With Cold Email Sales Tool",
    "h1": "Integrate SideDrawer with SalesBlink",
    "metaDescription": "Integrate SideDrawer and other File Management & Storage Tools with SalesBlink. Two Way SideDrawer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IntervalPay",
    "slug": "intervalpay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/efbf6cefc35957f194857f85c0f557e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IntervalPay is a subscription management and billing platform that drives transformational growth and automation for your business.",
    "category": "Payment Processing",
    "title": "IntervalPay Integration With Cold Email Sales Tool",
    "h1": "Integrate IntervalPay with SalesBlink",
    "metaDescription": "Integrate IntervalPay and other Payment Processing Tools with SalesBlink. Two Way IntervalPay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Startel CMC",
    "slug": "startel-cmc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f85dccee5b4affa0e8c04179fd9ae04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Startel CMC is a best-in-class contact management system for contact centers and telephone answering services.",
    "category": "Communication",
    "title": "Startel CMC Integration With Cold Email Sales Tool",
    "h1": "Integrate Startel CMC with SalesBlink",
    "metaDescription": "Integrate Startel CMC and other Communication Tools with SalesBlink. Two Way Startel CMC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BROADCASTsms",
    "slug": "broadcastsms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e761b81132616929ad9b8247123b5840_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BROADCASTsms is a mass/bulking texting solution used by Canadian and U.S. businesses, non profits and municipalities.",
    "category": "Phone & SMS",
    "title": "BROADCASTsms Integration With Cold Email Sales Tool",
    "h1": "Integrate BROADCASTsms with SalesBlink",
    "metaDescription": "Integrate BROADCASTsms and other Phone & SMS Tools with SalesBlink. Two Way BROADCASTsms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextSanity",
    "slug": "textsanity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d81ca81c58050d858f26bb977638b5d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect TextSanity with apps you use everyday. Integrate with 3,000 apps on Zapier - it's the easiest way to automate your text marketing.",
    "category": "Phone & SMS",
    "title": "TextSanity Integration With Cold Email Sales Tool",
    "h1": "Integrate TextSanity with SalesBlink",
    "metaDescription": "Integrate TextSanity and other Phone & SMS Tools with SalesBlink. Two Way TextSanity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "3veta",
    "slug": "3veta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59593a67e3de8334054788b4bc38d7a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "3veta - The go-to solution for meeting clients online and getting paid",
    "category": "Communication",
    "title": "3veta Integration With Cold Email Sales Tool",
    "h1": "Integrate 3veta with SalesBlink",
    "metaDescription": "Integrate 3veta and other Communication Tools with SalesBlink. Two Way 3veta Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Paced Email",
    "slug": "paced-email",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/77da469a818950efd5118c46a7d910c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paced Email allows you to control when you receive emails by creating custom aliases. Batch messages into daily, weekly or monthly digests.",
    "category": "Email",
    "title": "Paced Email Integration With Cold Email Sales Tool",
    "h1": "Integrate Paced Email with SalesBlink",
    "metaDescription": "Integrate Paced Email and other Email Tools with SalesBlink. Two Way Paced Email Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Inkybay",
    "slug": "inkybay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9eaf37ecdc7c4dd52d19b2518abed700_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inkybay - Product Customizer tool for Shopify and other eCommerce platforms. Automate your customized order flow by integrating with your favorite apps.",
    "category": "Productivity",
    "title": "Inkybay Integration With Cold Email Sales Tool",
    "h1": "Integrate Inkybay with SalesBlink",
    "metaDescription": "Integrate Inkybay and other Productivity Tools with SalesBlink. Two Way Inkybay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CRM Messaging",
    "slug": "crm-messaging",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd320e0bdc4085fb2d274758c0f4e9e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CRM Messaging is a platform for sending text messages on popular channels.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CRM Messaging Integration With Cold Email Sales Tool",
    "h1": "Integrate CRM Messaging with SalesBlink",
    "metaDescription": "Integrate CRM Messaging and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CRM Messaging Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Close App",
    "slug": "close-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f3da40109d8a33afd5cf4ea592987bbf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Close is the app for all your events:  All your tickets, practical information and the latest updates from your events in one spot.",
    "category": "Communication",
    "title": "Close App Integration With Cold Email Sales Tool",
    "h1": "Integrate Close App with SalesBlink",
    "metaDescription": "Integrate Close App and other Communication Tools with SalesBlink. Two Way Close App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taggbox",
    "slug": "taggbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5baefd41b69ea0b3bed1d493a0e3f9ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Platform to create and publish User-Generated Content campaigns across different marketing touchpoints.",
    "category": "Social Media Marketing",
    "title": "Taggbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Taggbox with SalesBlink",
    "metaDescription": "Integrate Taggbox and other Social Media Marketing Tools with SalesBlink. Two Way Taggbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtual Summits Software",
    "slug": "virtual-summits-software-ca17520",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eea17d20858e54ce9c4d668db20fe815.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Virtual Summits Software is a platform that helps users build their network, generate leads, and promote their brand through virtual summits.",
    "category": "Email",
    "title": "Virtual Summits Software Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtual Summits Software with SalesBlink",
    "metaDescription": "Integrate Virtual Summits Software and other Email Tools with SalesBlink. Two Way Virtual Summits Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agencyjoy",
    "slug": "agencyjoy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f218df0b9ca32d77c46f159f402d2528.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agencyjoy is a Sales CRM for Modern Agencies that helps you manage Leads, Clients, Activities, Emails, and Scheduling under a single roof.",
    "category": "Sales & CRM",
    "title": "Agencyjoy Integration With Cold Email Sales Tool",
    "h1": "Integrate Agencyjoy with SalesBlink",
    "metaDescription": "Integrate Agencyjoy and other Sales & CRM Tools with SalesBlink. Two Way Agencyjoy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Vertask",
    "slug": "vertask",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f60586babdd268b66b41589cb3062a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vertask is a ticketing system and helpdesk solution to manage your assets, requests, and documentation.",
    "category": "Task Management",
    "title": "Vertask Integration With Cold Email Sales Tool",
    "h1": "Integrate Vertask with SalesBlink",
    "metaDescription": "Integrate Vertask and other Task Management Tools with SalesBlink. Two Way Vertask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "B2BBricks",
    "slug": "b2bbricks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16d13066720d39342fce1d975ac5a589.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real Estate Software for individual and enterprise level realtors which will help you to provide real estate services in more efficiently and effectively than ever before",
    "category": "CRM (Customer Relationship Management)",
    "title": "B2BBricks Integration With Cold Email Sales Tool",
    "h1": "Integrate B2BBricks with SalesBlink",
    "metaDescription": "Integrate B2BBricks and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way B2BBricks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reloadly",
    "slug": "reloadly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a19068a780081db96b2daec7ac678b22_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reloadly is a technology company that builds airtime APIs for developers and their brands. Businesses of every size in a wide variety of industries use our software to enable the transfer of digital mobile payments, digital goods and mobile top-ups.",
    "category": "Payment Processing",
    "title": "Reloadly Integration With Cold Email Sales Tool",
    "h1": "Integrate Reloadly with SalesBlink",
    "metaDescription": "Integrate Reloadly and other Payment Processing Tools with SalesBlink. Two Way Reloadly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendloop",
    "slug": "sendloop",
    "logo": "https://zapier-images.imgix.net/storage/developer/34e40ecb3df1f1f560bc13a7b3ea24b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one email marketing solution for SaaS, e-commerce, application and small business owners.",
    "category": "Transactional Email",
    "title": "Sendloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendloop with SalesBlink",
    "metaDescription": "Integrate Sendloop and other Transactional Email Tools with SalesBlink. Two Way Sendloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Noodl",
    "slug": "noodl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6c547b1d28fea52a5e793d0cc1629d7.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Noodl is a no-code web app development platform",
    "category": "Website & App Building",
    "title": "Noodl Integration With Cold Email Sales Tool",
    "h1": "Integrate Noodl with SalesBlink",
    "metaDescription": "Integrate Noodl and other Website & App Building Tools with SalesBlink. Two Way Noodl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TDox",
    "slug": "tdox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9a22b4483da2a2a4a20b3d5c0d290ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TDox turns business processes and paper forms in mobile apps. The designer allows creating forms, which are filled in using the mobile app.",
    "category": "App Builder",
    "title": "TDox Integration With Cold Email Sales Tool",
    "h1": "Integrate TDox with SalesBlink",
    "metaDescription": "Integrate TDox and other App Builder Tools with SalesBlink. Two Way TDox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Score My Reviews",
    "slug": "scoremyreviews",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/341b70b2e873eb2b2bc42f225e99428d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Score My Reviews, helps you to get reviews fast & easy. With our review management system, get more reviews and improve your online reputation.",
    "category": "Reviews",
    "title": "Score My Reviews Integration With Cold Email Sales Tool",
    "h1": "Integrate Score My Reviews with SalesBlink",
    "metaDescription": "Integrate Score My Reviews and other Reviews Tools with SalesBlink. Two Way Score My Reviews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Publica",
    "slug": "publica",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/abe5a942d50c0dacffc04c70be36f332.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Publica, you can sell, share and consume all kinds of digital content directly through a reliable and secure multi-device platform.",
    "category": "Content & Files",
    "title": "Publica Integration With Cold Email Sales Tool",
    "h1": "Integrate Publica with SalesBlink",
    "metaDescription": "Integrate Publica and other Content & Files Tools with SalesBlink. Two Way Publica Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rootly",
    "slug": "rootly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a0bb05f3ef9dc7e6c7fac63a5ceb30ea_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rootly is an incident management platform that automates tedious manual work during incidents. 1,000's of users around the world trust Rootly daily to create a simple, consistent, and delightful incident management process.",
    "category": "Developer Tools",
    "title": "Rootly Integration With Cold Email Sales Tool",
    "h1": "Integrate Rootly with SalesBlink",
    "metaDescription": "Integrate Rootly and other Developer Tools Tools with SalesBlink. Two Way Rootly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Myloanofficer",
    "slug": "myloanofficer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f77fe4be343ca3f2d4341ec8b5745ded.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyLoanOfficer is a platform owned by Reverse Focus that creates websites for Reverse Mortgage Loan Officers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Myloanofficer Integration With Cold Email Sales Tool",
    "h1": "Integrate Myloanofficer with SalesBlink",
    "metaDescription": "Integrate Myloanofficer and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Myloanofficer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Diffbot Enhance",
    "slug": "diffbot-enhance",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/35419f25415525b268693c363b093592.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn a name into an account. Get firmographic data and insights on every prospect, lead, and\ncustomer by enhancing your data with Diffbot.",
    "category": "Contact Management",
    "title": "Diffbot Enhance Integration With Cold Email Sales Tool",
    "h1": "Integrate Diffbot Enhance with SalesBlink",
    "metaDescription": "Integrate Diffbot Enhance and other Contact Management Tools with SalesBlink. Two Way Diffbot Enhance Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bannerbite",
    "slug": "bannerbite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf092e52f34e5a1af5a24ba03a621b3b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bannerbite is an automated video generation tool",
    "category": "Content & Files",
    "title": "Bannerbite Integration With Cold Email Sales Tool",
    "h1": "Integrate Bannerbite with SalesBlink",
    "metaDescription": "Integrate Bannerbite and other Content & Files Tools with SalesBlink. Two Way Bannerbite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "RumbleUp",
    "slug": "rumbleup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/886ea163fe7b3feb86185a18a05b2c33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RumbleUp is a P2P texting platform to quickly engage any size audience via SMS, MMS or Video Text.",
    "category": "Phone & SMS",
    "title": "RumbleUp Integration With Cold Email Sales Tool",
    "h1": "Integrate RumbleUp with SalesBlink",
    "metaDescription": "Integrate RumbleUp and other Phone & SMS Tools with SalesBlink. Two Way RumbleUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Authory",
    "slug": "authory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/71fe9009c00054d565ddeb0e89611e9b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Authory automatically finds all the articles, podcasts, videos and posts you created.",
    "category": "Content & Files",
    "title": "Authory Integration With Cold Email Sales Tool",
    "h1": "Integrate Authory with SalesBlink",
    "metaDescription": "Integrate Authory and other Content & Files Tools with SalesBlink. Two Way Authory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tempokit",
    "slug": "tempokit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e19667d3e4919404425ad78b6c80f844.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tempokit is a no-code fitness app creator for personal trainers, gyms, nutritionists to sell workout/nutrition programs on their own custom branded app.",
    "category": "App Builder",
    "title": "Tempokit Integration With Cold Email Sales Tool",
    "h1": "Integrate Tempokit with SalesBlink",
    "metaDescription": "Integrate Tempokit and other App Builder Tools with SalesBlink. Two Way Tempokit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cikisi",
    "slug": "cikisi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1820e4ba8ddb15d65fa6c8a9d33af078.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cikisi is a collaboration tool dedicated to Market & Competitive Intelligence, Innovation Watch, Knowledge Management and Web Business Intelligence",
    "category": "Productivity",
    "title": "Cikisi Integration With Cold Email Sales Tool",
    "h1": "Integrate Cikisi with SalesBlink",
    "metaDescription": "Integrate Cikisi and other Productivity Tools with SalesBlink. Two Way Cikisi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BroadNet SMS",
    "slug": "broadnet-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b3809c57a52808593c87156ff8d45fbb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Best SMS tool for developers to send SMS in a fast, secure and most reliable channel",
    "category": "Phone & SMS",
    "title": "BroadNet SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate BroadNet SMS with SalesBlink",
    "metaDescription": "Integrate BroadNet SMS and other Phone & SMS Tools with SalesBlink. Two Way BroadNet SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContractSafe",
    "slug": "contractsafe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a77b73e9d4c5b473c7d07942c31f2e1c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContractSafe easily organizes and manages your contracts",
    "category": "Documents",
    "title": "ContractSafe Integration With Cold Email Sales Tool",
    "h1": "Integrate ContractSafe with SalesBlink",
    "metaDescription": "Integrate ContractSafe and other Documents Tools with SalesBlink. Two Way ContractSafe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocsCloud",
    "slug": "docscloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/673c4057c0af53d6688f37180cd6b580_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocsCloud is a web-based software platform that helps you create web forms, generate filled documents, get the documents signed & publish documents almost anything.",
    "category": "Documents",
    "title": "DocsCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate DocsCloud with SalesBlink",
    "metaDescription": "Integrate DocsCloud and other Documents Tools with SalesBlink. Two Way DocsCloud Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Naamly",
    "slug": "naamly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0cfe18f5364120ad5b1575862207f432_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Naamly is a communication medium to interact with the clients. We help gym business owners manage their clients and improve their business.",
    "category": "Fitness",
    "title": "Naamly Integration With Cold Email Sales Tool",
    "h1": "Integrate Naamly with SalesBlink",
    "metaDescription": "Integrate Naamly and other Fitness Tools with SalesBlink. Two Way Naamly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rownd Data Privacy",
    "slug": "rownd-data-privacy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49ea5b3c633af3d2a12f9830df948147.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rownd is a data privacy platform that gives end-users and consumers the ability to redact their data.",
    "category": "Developer Tools",
    "title": "Rownd Data Privacy Integration With Cold Email Sales Tool",
    "h1": "Integrate Rownd Data Privacy with SalesBlink",
    "metaDescription": "Integrate Rownd Data Privacy and other Developer Tools Tools with SalesBlink. Two Way Rownd Data Privacy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zipperHQ",
    "slug": "zipperhq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5103f0ad827a9c1e6ee4a63cb835bafa_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "zipperHQ is the fastest way to create and send personalised video messages at scale.",
    "category": "Marketing",
    "title": "zipperHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate zipperHQ with SalesBlink",
    "metaDescription": "Integrate zipperHQ and other Marketing Tools with SalesBlink. Two Way zipperHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Compta",
    "slug": "compta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/09039d96b62ebbf80cc656855e0fd981.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Compta.com is a french accounting and invoicing software. Compta.com est un logiciel de comptabilité, gestion et facturation.",
    "category": "Accounting",
    "title": "Compta Integration With Cold Email Sales Tool",
    "h1": "Integrate Compta with SalesBlink",
    "metaDescription": "Integrate Compta and other Accounting Tools with SalesBlink. Two Way Compta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Solar CRM",
    "slug": "solar-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2bf46e62ef8ee2a1dc11cf5dd0554db8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Solar CRM is a CRM tool designed specifically for the Solar sales individual.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Solar CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Solar CRM with SalesBlink",
    "metaDescription": "Integrate Solar CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Solar CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "3Sigma",
    "slug": "3sigma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9749e543e98c69ca76d4798c83a6f057.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "3Sigma is a CRM application and one stop solution to manage your leads in realtime from different resources.",
    "category": "CRM (Customer Relationship Management)",
    "title": "3Sigma Integration With Cold Email Sales Tool",
    "h1": "Integrate 3Sigma with SalesBlink",
    "metaDescription": "Integrate 3Sigma and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 3Sigma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ParcelParcel",
    "slug": "parcelparcel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/654063d7a00cad70b5f748dd27aa06af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ParcelParcel is an online logistics shipping platform where people can ship worldwide.",
    "category": "Productivity",
    "title": "ParcelParcel Integration With Cold Email Sales Tool",
    "h1": "Integrate ParcelParcel with SalesBlink",
    "metaDescription": "Integrate ParcelParcel and other Productivity Tools with SalesBlink. Two Way ParcelParcel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Weworkbook",
    "slug": "weworkbook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b95d7ed0ddfcc312317bccf56882e52e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Weworkbook is an Australian-owned Student Management System created to help training organisations increase revenue and streamline workflows.",
    "category": "Education",
    "title": "Weworkbook Integration With Cold Email Sales Tool",
    "h1": "Integrate Weworkbook with SalesBlink",
    "metaDescription": "Integrate Weworkbook and other Education Tools with SalesBlink. Two Way Weworkbook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Repzo",
    "slug": "repzo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee5470583bc02015d0327341c4e9f0f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Complete Sales / Field Force\nAutomation Platform for managing Sales,\nMarketing and Service Providers which\nprovides you with real time coverage analytics",
    "category": "CRM (Customer Relationship Management)",
    "title": "Repzo Integration With Cold Email Sales Tool",
    "h1": "Integrate Repzo with SalesBlink",
    "metaDescription": "Integrate Repzo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Repzo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kaddim",
    "slug": "kaddim",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3d26e7f2f28f4aba4b2f11eeb7663dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Getting documents and information from clients can be time-consuming, messy and a pain. Kaddim is a Painkiller",
    "category": "Documents",
    "title": "Kaddim Integration With Cold Email Sales Tool",
    "h1": "Integrate Kaddim with SalesBlink",
    "metaDescription": "Integrate Kaddim and other Documents Tools with SalesBlink. Two Way Kaddim Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SnapCall",
    "slug": "snapcall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50f13e72d391222c5488a7ab5edad4f7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meet SnapCall, the easiest way to add real-time video to your platform, product or service.",
    "category": "Video Conferencing",
    "title": "SnapCall Integration With Cold Email Sales Tool",
    "h1": "Integrate SnapCall with SalesBlink",
    "metaDescription": "Integrate SnapCall and other Video Conferencing Tools with SalesBlink. Two Way SnapCall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sapium",
    "slug": "sapium",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a2d7ccc8ae154e82ec71967d4e6d0ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sapium is a research automation tool to collect, structure and summarize meeting notes, interviews, and more into shareable insights and recommendations.",
    "category": "Notes",
    "title": "Sapium Integration With Cold Email Sales Tool",
    "h1": "Integrate Sapium with SalesBlink",
    "metaDescription": "Integrate Sapium and other Notes Tools with SalesBlink. Two Way Sapium Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrustFinance",
    "slug": "trustfinance",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f4ae5762034c280c90a399ace178b17.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrustFinance encourages financial companies to be more reliable and provide for the best interests of their customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "TrustFinance Integration With Cold Email Sales Tool",
    "h1": "Integrate TrustFinance with SalesBlink",
    "metaDescription": "Integrate TrustFinance and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way TrustFinance Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChayAll",
    "slug": "chayall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96ba55fa443698cf9dec05923bc97576.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChayAll is a tool that allows you to centralize all your instant messaging customer messages within a single platform",
    "category": "Customer Support",
    "title": "ChayAll Integration With Cold Email Sales Tool",
    "h1": "Integrate ChayAll with SalesBlink",
    "metaDescription": "Integrate ChayAll and other Customer Support Tools with SalesBlink. Two Way ChayAll Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BreachRx",
    "slug": "breachrx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7db6e544975d3493f6e82a0847dfcd5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The incident management and response platform.",
    "category": "Security & Identity Tools",
    "title": "BreachRx Integration With Cold Email Sales Tool",
    "h1": "Integrate BreachRx with SalesBlink",
    "metaDescription": "Integrate BreachRx and other Security & Identity Tools Tools with SalesBlink. Two Way BreachRx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CatchUp",
    "slug": "catchup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/505615618b3c78e53ff3ed9fe47569f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CatchUp is a one-stop productivity solution for managing projects, tasks, and teams.",
    "category": "Project Management",
    "title": "CatchUp Integration With Cold Email Sales Tool",
    "h1": "Integrate CatchUp with SalesBlink",
    "metaDescription": "Integrate CatchUp and other Project Management Tools with SalesBlink. Two Way CatchUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dedoco",
    "slug": "dedoco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87b0182a190131e65409b4048cc8606f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dedoco is a digital document and signing solution that transforms the way enterprises manage digital workflows, while issuing trusted, verifiable documents and credentials.",
    "category": "Signatures",
    "title": "Dedoco Integration With Cold Email Sales Tool",
    "h1": "Integrate Dedoco with SalesBlink",
    "metaDescription": "Integrate Dedoco and other Signatures Tools with SalesBlink. Two Way Dedoco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Messenger Bot",
    "slug": "messenger-bot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25fe71e82a16b4c6acfeaca04db354cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Messenger Bot is a multi channel marketing suite that combines Messenger Marketing, SMS Marketing, Email Marketing, Social Media Marketing, and eCommerce into one dashboard.",
    "category": "Marketing",
    "title": "Messenger Bot Integration With Cold Email Sales Tool",
    "h1": "Integrate Messenger Bot with SalesBlink",
    "metaDescription": "Integrate Messenger Bot and other Marketing Tools with SalesBlink. Two Way Messenger Bot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smsbox.be",
    "slug": "smsboxbe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f7b065c156cb915c4e7131cc82ad07b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Smsbox you send SMS/PUSH messages to your contacts via our user-friendly tool or our SMS API.\nThis way you can quickly and easily reach your customers with notifications or with SMS marketing.",
    "category": "Communication",
    "title": "Smsbox.be Integration With Cold Email Sales Tool",
    "h1": "Integrate Smsbox.be with SalesBlink",
    "metaDescription": "Integrate Smsbox.be and other Communication Tools with SalesBlink. Two Way Smsbox.be Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LEVERADE",
    "slug": "leverade",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d29e442e8c4ca9b5586415b49e1daf81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LEVERADE is a federation and competition management platform, providing competition management, registration and referee assignment connected in a common platform",
    "category": "Gaming",
    "title": "LEVERADE Integration With Cold Email Sales Tool",
    "h1": "Integrate LEVERADE with SalesBlink",
    "metaDescription": "Integrate LEVERADE and other Gaming Tools with SalesBlink. Two Way LEVERADE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superglue",
    "slug": "superglue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/940a82ed3e0b7df7100daf5d23342555_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Superglue is a partner engagement platform. Companies use our platform to automate workflows, gain predictive insights into their ecosystem, enhance partner experiences, and consolidate their growing partner management tech stack.",
    "category": "Communication",
    "title": "Superglue Integration With Cold Email Sales Tool",
    "h1": "Integrate Superglue with SalesBlink",
    "metaDescription": "Integrate Superglue and other Communication Tools with SalesBlink. Two Way Superglue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fig Booking Software",
    "slug": "fig-booking-software",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a0d34ba0320caff56896ec38984cb69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fig is an online booking platform for service businesses, helping manage and book clients, process payments, and more!",
    "category": "Scheduling & Booking",
    "title": "Fig Booking Software Integration With Cold Email Sales Tool",
    "h1": "Integrate Fig Booking Software with SalesBlink",
    "metaDescription": "Integrate Fig Booking Software and other Scheduling & Booking Tools with SalesBlink. Two Way Fig Booking Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tarvent",
    "slug": "tarvent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5113206abc7e485bacf1a19a8776b8d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tarvent marketing automation platform empowers businesses of all sizes by providing a great depth of features and customization to control every aspect of your customer's experience.",
    "category": "Marketing Automation",
    "title": "Tarvent Integration With Cold Email Sales Tool",
    "h1": "Integrate Tarvent with SalesBlink",
    "metaDescription": "Integrate Tarvent and other Marketing Automation Tools with SalesBlink. Two Way Tarvent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Proggio",
    "slug": "proggio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfa1e5d85de4d21374740e6e957215ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Proggio is a collaborative project management solution for teams.",
    "category": "Team Collaboration",
    "title": "Proggio Integration With Cold Email Sales Tool",
    "h1": "Integrate Proggio with SalesBlink",
    "metaDescription": "Integrate Proggio and other Team Collaboration Tools with SalesBlink. Two Way Proggio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trak Qr Automation",
    "slug": "trak-qr-automation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/22e911b1bcfc29d2e5e8a4f270ceed5f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trak enables using smartphones and QR codes to track actions on physical artifacts like documents, envelopes, boxes, tools, buildings, etc.",
    "category": "Team Collaboration",
    "title": "Trak Qr Automation Integration With Cold Email Sales Tool",
    "h1": "Integrate Trak Qr Automation with SalesBlink",
    "metaDescription": "Integrate Trak Qr Automation and other Team Collaboration Tools with SalesBlink. Two Way Trak Qr Automation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sell My House Fast",
    "slug": "sell-my-house-fast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/530dc625bd5cc5cbca313fef8438e776_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sell My House Fast allows you to get the estimation of a house to sell it fast.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sell My House Fast Integration With Cold Email Sales Tool",
    "h1": "Integrate Sell My House Fast with SalesBlink",
    "metaDescription": "Integrate Sell My House Fast and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sell My House Fast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anthropic (Claude)",
    "slug": "anthropic-claude",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/105cc77d707788a883c33a7f13564222.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Claude by Anthropic is an AI assistant trained for safety and conversational understanding.",
    "category": "AI Tools",
    "title": "Anthropic (Claude) Integration With Cold Email Sales Tool",
    "h1": "Integrate Anthropic (Claude) with SalesBlink",
    "metaDescription": "Integrate Anthropic (Claude) and other AI Tools Tools with SalesBlink. Two Way Anthropic (Claude) Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Ytel Platform",
    "slug": "ytel-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/541345d13eb1e2be4412e3043db6d603.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ytel gives you an all in one customer engagement platform that allows you to call, text, and track contacts.",
    "category": "Communication",
    "title": "Ytel Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Ytel Platform with SalesBlink",
    "metaDescription": "Integrate Ytel Platform and other Communication Tools with SalesBlink. Two Way Ytel Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sonetel",
    "slug": "sonetel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d02383fed910338992c6ecdaa2e9dd61_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sonetel enables voice and text communication for small companies",
    "category": "Communication",
    "title": "Sonetel Integration With Cold Email Sales Tool",
    "h1": "Integrate Sonetel with SalesBlink",
    "metaDescription": "Integrate Sonetel and other Communication Tools with SalesBlink. Two Way Sonetel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickTable",
    "slug": "quicktable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a95e3c40f18b4c74a2bceb6ab507db5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuickTable is a visual data modeling tool that lets everyone clean and transforms data without writing code. Using QuickTable helps reduce the time it takes to prepare data for analytics and modeling by up to 70 percent compared to traditional ways.",
    "category": "Analytics",
    "title": "QuickTable Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickTable with SalesBlink",
    "metaDescription": "Integrate QuickTable and other Analytics Tools with SalesBlink. Two Way QuickTable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Core-Strategy",
    "slug": "core-strategy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e308d3e4657535ede7dc86e08f43a1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Core strategy allows enterprises to align everyone in realtime with the organization's long term strategic vision, and planning.",
    "category": "Project Management",
    "title": "Core-Strategy Integration With Cold Email Sales Tool",
    "h1": "Integrate Core-Strategy with SalesBlink",
    "metaDescription": "Integrate Core-Strategy and other Project Management Tools with SalesBlink. Two Way Core-Strategy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Corporategift",
    "slug": "corporategift",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/103660f338722dd9d5aac00f26c4048e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CorporateGift.com makes sending gifts, swag, and other incentives at scale easy by allowing you to setup events or triggers to send physical gifts using just an email.",
    "category": "eCommerce",
    "title": "Corporategift Integration With Cold Email Sales Tool",
    "h1": "Integrate Corporategift with SalesBlink",
    "metaDescription": "Integrate Corporategift and other eCommerce Tools with SalesBlink. Two Way Corporategift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RADAAR",
    "slug": "radaar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b71e4211ed771dbd5428bf4ab6ae4658.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RADAAR is a powerful social media management and automation platform designed for handling multiple social media profiles. It helps small and medium-sized enterprises at every step, from scheduling and publishing posts on their profiles to analyzing their efforts.",
    "category": "Social Media Marketing",
    "title": "RADAAR Integration With Cold Email Sales Tool",
    "h1": "Integrate RADAAR with SalesBlink",
    "metaDescription": "Integrate RADAAR and other Social Media Marketing Tools with SalesBlink. Two Way RADAAR Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Happily.ai",
    "slug": "happilyai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c2741ac1a80c025fb614ef177766390.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Happily.ai is an engagement, recognition, and rewards tool that aims to create happy, high-performing teams.",
    "category": "Productivity",
    "title": "Happily.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Happily.ai with SalesBlink",
    "metaDescription": "Integrate Happily.ai and other Productivity Tools with SalesBlink. Two Way Happily.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Corymbus",
    "slug": "corymbus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c789128ade0fee7ede10239a49b77e6d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Corymbus is a CRM platform devoted to small businesses and freelances. It focuses on an extremely straightforward user experience. It covers the needs of both sales and marketing.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Corymbus Integration With Cold Email Sales Tool",
    "h1": "Integrate Corymbus with SalesBlink",
    "metaDescription": "Integrate Corymbus and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Corymbus Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "eEndorsements",
    "slug": "eendorsements",
    "logo": "https://zapier-images.imgix.net/storage/developer/9681d3f4d4e1c7740f71b3ce70b0787f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EEndorsements is an easy way to manage your online reputation and capture valuable customer feedback.",
    "category": "Marketing Automation",
    "title": "eEndorsements Integration With Cold Email Sales Tool",
    "h1": "Integrate eEndorsements with SalesBlink",
    "metaDescription": "Integrate eEndorsements and other Marketing Automation Tools with SalesBlink. Two Way eEndorsements Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DIDWW SMS OUT",
    "slug": "didww-sms-out",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a6d16deb3d019da9153d348fc1149d7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DIDWW SMS OUT is a app to send outgoing SMS via DIDWW HTTP SMS OUT Trunk",
    "category": "Phone & SMS",
    "title": "DIDWW SMS OUT Integration With Cold Email Sales Tool",
    "h1": "Integrate DIDWW SMS OUT with SalesBlink",
    "metaDescription": "Integrate DIDWW SMS OUT and other Phone & SMS Tools with SalesBlink. Two Way DIDWW SMS OUT Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "GoSweetSpot",
    "slug": "gosweetspot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea76553bc493c3afe4ca806f320cdb10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easy to use software, impressive shipping rates. GoSweetSpot saves you time and money.",
    "category": "eCommerce",
    "title": "GoSweetSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate GoSweetSpot with SalesBlink",
    "metaDescription": "Integrate GoSweetSpot and other eCommerce Tools with SalesBlink. Two Way GoSweetSpot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AlphaSMS",
    "slug": "alphasms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a19a05fc7b9a7c2430315cd914e77a90_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AlphaSMS provides messaging services via SMS and messengers throughout Ukraine and worldwide.",
    "category": "Phone & SMS",
    "title": "AlphaSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate AlphaSMS with SalesBlink",
    "metaDescription": "Integrate AlphaSMS and other Phone & SMS Tools with SalesBlink. Two Way AlphaSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Procys",
    "slug": "procys",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a6c586d1c9c368ca0c2caedbb39b0aff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Extract booking data from PDF or JPEG/PNG invoices",
    "category": "Accounting",
    "title": "Procys Integration With Cold Email Sales Tool",
    "h1": "Integrate Procys with SalesBlink",
    "metaDescription": "Integrate Procys and other Accounting Tools with SalesBlink. Two Way Procys Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invivox",
    "slug": "invivox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/560ddcf7a37b91fec35fe79c4f95b777.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Invivox Connect is a tool to organize online and on site courses for healthcare professionals",
    "category": "Scheduling & Booking",
    "title": "Invivox Integration With Cold Email Sales Tool",
    "h1": "Integrate Invivox with SalesBlink",
    "metaDescription": "Integrate Invivox and other Scheduling & Booking Tools with SalesBlink. Two Way Invivox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartDialog",
    "slug": "smartdialog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cf51e7e750d9d86f6eb508b7c7cf2d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartDialog is a platform used to send and receive SMS- and WhatsApp messages. It offers a wide variety of smart messaging services.",
    "category": "Phone & SMS",
    "title": "SmartDialog Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartDialog with SalesBlink",
    "metaDescription": "Integrate SmartDialog and other Phone & SMS Tools with SalesBlink. Two Way SmartDialog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Answering Service Care",
    "slug": "answering-service-care",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ca46ef92a5a0465c9392194197e2d4ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "24/7 Answering Service: Never Miss Another Call. Family Owned and Operated Since 1974.",
    "category": "Customer Support",
    "title": "Answering Service Care Integration With Cold Email Sales Tool",
    "h1": "Integrate Answering Service Care with SalesBlink",
    "metaDescription": "Integrate Answering Service Care and other Customer Support Tools with SalesBlink. Two Way Answering Service Care Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YumiSign",
    "slug": "yumisign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d31b1c2c58ff79409099f70a18ea1cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yumisign is a digital signature platform that simplifies the signing process, making it easier to sign documents securely and efficiently.",
    "category": "Signatures",
    "title": "YumiSign Integration With Cold Email Sales Tool",
    "h1": "Integrate YumiSign with SalesBlink",
    "metaDescription": "Integrate YumiSign and other Signatures Tools with SalesBlink. Two Way YumiSign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Property Inspect",
    "slug": "property-inspect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3664eabc63c00b728ef869e39a894347.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Property Inspect is a web and mobile platform for managing and producing property inspections and reports",
    "category": "Documents",
    "title": "Property Inspect Integration With Cold Email Sales Tool",
    "h1": "Integrate Property Inspect with SalesBlink",
    "metaDescription": "Integrate Property Inspect and other Documents Tools with SalesBlink. Two Way Property Inspect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slayte",
    "slug": "slayte",
    "logo": "https://zapier-images.imgix.net/storage/services/ae465a4e537970005bed6f469fd43505.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Slayte is a Suite of Submissions and Event Management tools to streamline submission and review processes and create sessions automatically.",
    "category": "Event Management",
    "title": "Slayte Integration With Cold Email Sales Tool",
    "h1": "Integrate Slayte with SalesBlink",
    "metaDescription": "Integrate Slayte and other Event Management Tools with SalesBlink. Two Way Slayte Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimpleLocalize",
    "slug": "simplelocalize-translation-manag",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d5922c976610c6ad788cb94f0dc389e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleLocalize is a service that helps in managing translations in your applications.",
    "category": "Databases",
    "title": "SimpleLocalize Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleLocalize with SalesBlink",
    "metaDescription": "Integrate SimpleLocalize and other Databases Tools with SalesBlink. Two Way SimpleLocalize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OmniPUSH",
    "slug": "omnipush",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb389b8f76be0be6783686f31721b949.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Preeminent SaaS for TOTAL Remote Sales Pros",
    "category": "CRM (Customer Relationship Management)",
    "title": "OmniPUSH Integration With Cold Email Sales Tool",
    "h1": "Integrate OmniPUSH with SalesBlink",
    "metaDescription": "Integrate OmniPUSH and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OmniPUSH Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "sendSMS",
    "slug": "sendsms",
    "logo": "https://zapier-images.imgix.net/storage/services/1ea9e18297f2341bea64a3de49860d36.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "sendSMS is a SMS marketing platform to help you create solutions to deliver the right information at the right time.",
    "category": "Communication",
    "title": "sendSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate sendSMS with SalesBlink",
    "metaDescription": "Integrate sendSMS and other Communication Tools with SalesBlink. Two Way sendSMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "SeoToaster",
    "slug": "seotoaster",
    "logo": "https://zapier-images.imgix.net/storage/developer/78b58791c7c0701171daf266ca13d0da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SeoToaster is a great CMS platform for SEO and Ecommerce",
    "category": "CRM (Customer Relationship Management)",
    "title": "SeoToaster Integration With Cold Email Sales Tool",
    "h1": "Integrate SeoToaster with SalesBlink",
    "metaDescription": "Integrate SeoToaster and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SeoToaster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneBill",
    "slug": "onebill",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8037e5500f431e0b9855ebebdcd389bf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneBill is a billing and subscription management platform for businesses offering a range of products/services based around subscription.",
    "category": "Accounting",
    "title": "OneBill Integration With Cold Email Sales Tool",
    "h1": "Integrate OneBill with SalesBlink",
    "metaDescription": "Integrate OneBill and other Accounting Tools with SalesBlink. Two Way OneBill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Birdie Screen Recording",
    "slug": "birdie-screen-recording",
    "logo": "https://zapier-images.imgix.net/storage/services/9d30cbf43ca584ef7475898b3c02efd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Birdie Screen Recording, users can record their screen and voice and instantly share their feedback in less time than it would take to take screenshots, annotate them and type an email.",
    "category": "Customer Appreciation",
    "title": "Birdie Screen Recording Integration With Cold Email Sales Tool",
    "h1": "Integrate Birdie Screen Recording with SalesBlink",
    "metaDescription": "Integrate Birdie Screen Recording and other Customer Appreciation Tools with SalesBlink. Two Way Birdie Screen Recording Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PureSMS",
    "slug": "puresms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ea89a3f28591e5f8606fee2e6295216_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PureSMS is an SMS Messaging Provider based in the UK with global coverage.",
    "category": "Communication",
    "title": "PureSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate PureSMS with SalesBlink",
    "metaDescription": "Integrate PureSMS and other Communication Tools with SalesBlink. Two Way PureSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datrm.in",
    "slug": "datrmin-da108794",
    "logo": "https://zapier-images.imgix.net/storage/developer/3b114165a8c64ee1373618c2654beb02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A CRM solution for advocacy organizations, Datrm.in is determined to empower non-profits, advance advocacy, build movements, and change the world.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Datrm.in Integration With Cold Email Sales Tool",
    "h1": "Integrate Datrm.in with SalesBlink",
    "metaDescription": "Integrate Datrm.in and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Datrm.in Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HiCustomer",
    "slug": "hicustomer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d973ca50825377ac5671535d5584f4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HiCustomer is a customer success management platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "HiCustomer Integration With Cold Email Sales Tool",
    "h1": "Integrate HiCustomer with SalesBlink",
    "metaDescription": "Integrate HiCustomer and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way HiCustomer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesOptima",
    "slug": "salesoptima",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c0e323e3f13ecac9db9ea70884e8ec3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesOptima is an online Customer Relationship Management (CRM) service that conveniently enables you to organize, manage, and nurture your customer contacts in the office or on the go.",
    "category": "Sales & CRM",
    "title": "SalesOptima Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesOptima with SalesBlink",
    "metaDescription": "Integrate SalesOptima and other Sales & CRM Tools with SalesBlink. Two Way SalesOptima Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LogiONE Document",
    "slug": "logione-document",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0f3640e054e907b156f721e02de9162.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LogiONE Document is a secure cloud base solution to store and share files",
    "category": "Documents",
    "title": "LogiONE Document Integration With Cold Email Sales Tool",
    "h1": "Integrate LogiONE Document with SalesBlink",
    "metaDescription": "Integrate LogiONE Document and other Documents Tools with SalesBlink. Two Way LogiONE Document Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Secretaría.es",
    "slug": "secretaria",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b52acd02a888b91a43c4cc43503f690e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Secretaría.es is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business.",
    "category": "Communication",
    "title": "Secretaría.es Integration With Cold Email Sales Tool",
    "h1": "Integrate Secretaría.es with SalesBlink",
    "metaDescription": "Integrate Secretaría.es and other Communication Tools with SalesBlink. Two Way Secretaría.es Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vortext",
    "slug": "vortext",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/23143ac161e22b1cb8f5819e4ee72607.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vorText is an ecosystem of SMS Apps you can use to engage with your customers in new exciting ways.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vortext Integration With Cold Email Sales Tool",
    "h1": "Integrate Vortext with SalesBlink",
    "metaDescription": "Integrate Vortext and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vortext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wordapp",
    "slug": "wordapp",
    "logo": "https://zapier-images.imgix.net/storage/developer/2041723edd46145b1e9a681d70f200ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wordapp is a word-processing platform for e-commerce that lets you manage & control your content creation with a crowd of writers & editors.",
    "category": "eCommerce",
    "title": "Wordapp Integration With Cold Email Sales Tool",
    "h1": "Integrate Wordapp with SalesBlink",
    "metaDescription": "Integrate Wordapp and other eCommerce Tools with SalesBlink. Two Way Wordapp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saastic",
    "slug": "saastic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/def743707f2ce575fbf2fdff0d45842e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Saastic automatically requests reviews from your best customers, so you can focus on doing what you do best — building a 5-star product.",
    "category": "Reviews",
    "title": "Saastic Integration With Cold Email Sales Tool",
    "h1": "Integrate Saastic with SalesBlink",
    "metaDescription": "Integrate Saastic and other Reviews Tools with SalesBlink. Two Way Saastic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "itemit",
    "slug": "itemit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5bbe3e1d1a18a884e3d50ecddb131eb8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "itemit is an asset management solution your whole team can use.",
    "category": "Product Management",
    "title": "itemit Integration With Cold Email Sales Tool",
    "h1": "Integrate itemit with SalesBlink",
    "metaDescription": "Integrate itemit and other Product Management Tools with SalesBlink. Two Way itemit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BetterChat",
    "slug": "betterchat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2bfbad722b7bb03d46798617d938bccc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send & receive WhatsApp messages. Create no-code WhatsApp chatbots! Use BetterChat and automate customer support through WhatsApp.",
    "category": "Notifications",
    "title": "BetterChat Integration With Cold Email Sales Tool",
    "h1": "Integrate BetterChat with SalesBlink",
    "metaDescription": "Integrate BetterChat and other Notifications Tools with SalesBlink. Two Way BetterChat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LogSpace",
    "slug": "logspace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b0df3acce3706ee26e39b9a6ea72d636.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LogSpace is a time and expense tracking system enhanced with automatic invoicing.",
    "category": "Time Tracking Software",
    "title": "LogSpace Integration With Cold Email Sales Tool",
    "h1": "Integrate LogSpace with SalesBlink",
    "metaDescription": "Integrate LogSpace and other Time Tracking Software Tools with SalesBlink. Two Way LogSpace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AvidMobile",
    "slug": "avidmobile-da92390",
    "logo": "https://zapier-images.imgix.net/storage/developer/eb34c7916fcd542ca5418fdab2be1d1e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AvidMobile is a service that enables you to add SMS to your communication and marketing strategies with 2-way SMS, coupons, contests, and loyalty.",
    "category": "Phone & SMS",
    "title": "AvidMobile Integration With Cold Email Sales Tool",
    "h1": "Integrate AvidMobile with SalesBlink",
    "metaDescription": "Integrate AvidMobile and other Phone & SMS Tools with SalesBlink. Two Way AvidMobile Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Minsh",
    "slug": "minsh",
    "logo": "https://zapier-images.imgix.net/storage/services/a30f87b01efc6d4ecdee9a62564c2647.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learn when users sign up, post messages, create events in a Minsh app",
    "category": "Team Chat",
    "title": "Minsh Integration With Cold Email Sales Tool",
    "h1": "Integrate Minsh with SalesBlink",
    "metaDescription": "Integrate Minsh and other Team Chat Tools with SalesBlink. Two Way Minsh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Klutch",
    "slug": "klutch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89926ec08be95c06fa7be7df0376842e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Klutch is a credit card for techies that allows you to add different features to the card",
    "category": "Payment Processing",
    "title": "Klutch Integration With Cold Email Sales Tool",
    "h1": "Integrate Klutch with SalesBlink",
    "metaDescription": "Integrate Klutch and other Payment Processing Tools with SalesBlink. Two Way Klutch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Makesbridge",
    "slug": "makesbridge",
    "logo": "https://zapier-images.imgix.net/storage/developer/1a2135fd82d6e66954dd7a5ca1b034fe_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Makesbridge is a sales and marketing automation tool that grows contact lists, nurtures prospects, sends mass emails, and generates sales.",
    "category": "Marketing Automation",
    "title": "Makesbridge Integration With Cold Email Sales Tool",
    "h1": "Integrate Makesbridge with SalesBlink",
    "metaDescription": "Integrate Makesbridge and other Marketing Automation Tools with SalesBlink. Two Way Makesbridge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vetrina Live",
    "slug": "vetrina-live",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b8663126c8f3b69e024f265156105dae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vetrina Live is an e-commerce platform that allows you to easily create your online store and sell online.",
    "category": "eCommerce",
    "title": "Vetrina Live Integration With Cold Email Sales Tool",
    "h1": "Integrate Vetrina Live with SalesBlink",
    "metaDescription": "Integrate Vetrina Live and other eCommerce Tools with SalesBlink. Two Way Vetrina Live Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tide Software SMS",
    "slug": "tide-software-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cb973e75dd20e89b5709b65f17e1f551.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tide Software SMS is an SMS platform to message your customers automatically with full control, message customization, phishing protection, and more.",
    "category": "Phone & SMS",
    "title": "Tide Software SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Tide Software SMS with SalesBlink",
    "metaDescription": "Integrate Tide Software SMS and other Phone & SMS Tools with SalesBlink. Two Way Tide Software SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taskworld",
    "slug": "taskworld-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/92f1aeb86fc0051f7f4eb3484dd0ec9d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Receive your Taskworld notifications in any app and collaborate more effectively!",
    "category": "Project Management",
    "title": "Taskworld Integration With Cold Email Sales Tool",
    "h1": "Integrate Taskworld with SalesBlink",
    "metaDescription": "Integrate Taskworld and other Project Management Tools with SalesBlink. Two Way Taskworld Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cardeleine",
    "slug": "cardeleine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d42f97cfb3e8b18c35b5f99ead86846_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cardeleine is a platform for digital business cards that can connect to your favourite CRM.",
    "category": "Contact Management",
    "title": "Cardeleine Integration With Cold Email Sales Tool",
    "h1": "Integrate Cardeleine with SalesBlink",
    "metaDescription": "Integrate Cardeleine and other Contact Management Tools with SalesBlink. Two Way Cardeleine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "c2a",
    "slug": "c2a",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4ef99ed964e15e61cd72fa88c888b1f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The c2a system was created for Contact Center offices to support, automate calls and other communication channels.",
    "category": "CRM (Customer Relationship Management)",
    "title": "c2a Integration With Cold Email Sales Tool",
    "h1": "Integrate c2a with SalesBlink",
    "metaDescription": "Integrate c2a and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way c2a Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyDocSafe",
    "slug": "mydocsafe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33ddb81299f6c831cd2ec09b601dd870.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyDocSafe is secure eSign software for companies in diverse sectors. End-to-end document security and compliance.",
    "category": "Documents",
    "title": "MyDocSafe Integration With Cold Email Sales Tool",
    "h1": "Integrate MyDocSafe with SalesBlink",
    "metaDescription": "Integrate MyDocSafe and other Documents Tools with SalesBlink. Two Way MyDocSafe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ninehire",
    "slug": "ninehire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d0c6f27150d5ef9a808a5c330a63b9f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ninehire is a collaboration tool that helps you with recruitment and applicants management.",
    "category": "HR Talent & Recruitment",
    "title": "Ninehire Integration With Cold Email Sales Tool",
    "h1": "Integrate Ninehire with SalesBlink",
    "metaDescription": "Integrate Ninehire and other HR Talent & Recruitment Tools with SalesBlink. Two Way Ninehire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Compass Wave",
    "slug": "compass-wave",
    "logo": "https://zapier-images.imgix.net/storage/services/767350afa215538c34d5321fdc51b7f6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Compass Wave is an online estimate tool created to quickly and efficiently capture leads.",
    "category": "Forms & Surveys",
    "title": "Compass Wave Integration With Cold Email Sales Tool",
    "h1": "Integrate Compass Wave with SalesBlink",
    "metaDescription": "Integrate Compass Wave and other Forms & Surveys Tools with SalesBlink. Two Way Compass Wave Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voxtelesys",
    "slug": "voxtelesys",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc23b652def5323283ff160406f0d49b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy tool for developers to integrate with the SMS/MMS, Voice, and Fax APIs provided by Voxtelesys.",
    "category": "Communication",
    "title": "Voxtelesys Integration With Cold Email Sales Tool",
    "h1": "Integrate Voxtelesys with SalesBlink",
    "metaDescription": "Integrate Voxtelesys and other Communication Tools with SalesBlink. Two Way Voxtelesys Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clinchd",
    "slug": "clinchd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e13d6ca483eb94dddae889153cc30d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clinchd is a sales engagement platform that makes selling a team game with less admin and more clarity for all involved",
    "category": "Sales & CRM",
    "title": "Clinchd Integration With Cold Email Sales Tool",
    "h1": "Integrate Clinchd with SalesBlink",
    "metaDescription": "Integrate Clinchd and other Sales & CRM Tools with SalesBlink. Two Way Clinchd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wordsmith",
    "slug": "wordsmith",
    "logo": "https://zapier-images.imgix.net/storage/developer/6a4afee783f1ea68603c6d116a24f1a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wordsmith automatically turns data into articles and reports so you can generate personalized messages in real time for any audience.",
    "category": "Documents",
    "title": "Wordsmith Integration With Cold Email Sales Tool",
    "h1": "Integrate Wordsmith with SalesBlink",
    "metaDescription": "Integrate Wordsmith and other Documents Tools with SalesBlink. Two Way Wordsmith Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Life Mib",
    "slug": "life-mib",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e00e717d4b486c6a3a6d9f73cec01e16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Life Mib connects agents and customers to a single platform that is reducing the insurance buying process from hours to minutes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Life Mib Integration With Cold Email Sales Tool",
    "h1": "Integrate Life Mib with SalesBlink",
    "metaDescription": "Integrate Life Mib and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Life Mib Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SuperPath",
    "slug": "superpath",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/793b2d6e3f5af2ad90e12a80453ea013.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SuperPath is a learning experience platform that empowers your team to build beautiful learning and onboarding pathways as well as track all types of learning from articles, podcasts to books",
    "category": "Education",
    "title": "SuperPath Integration With Cold Email Sales Tool",
    "h1": "Integrate SuperPath with SalesBlink",
    "metaDescription": "Integrate SuperPath and other Education Tools with SalesBlink. Two Way SuperPath Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PromoTix",
    "slug": "promotix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82005adbe22ad12adc133eac8d48c0e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PromoTix is a robust ticketing, marketing, and live streaming platform for events.",
    "category": "Event Management",
    "title": "PromoTix Integration With Cold Email Sales Tool",
    "h1": "Integrate PromoTix with SalesBlink",
    "metaDescription": "Integrate PromoTix and other Event Management Tools with SalesBlink. Two Way PromoTix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CoachConnect",
    "slug": "coachconnect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c7ee968e5143f01acfed5dfdfea24fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CoachConnect facilitates the creation of structured Courses by Coaches. Courses are used to disseminate the Content of a Course to enrolled Students.",
    "category": "Online Courses",
    "title": "CoachConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate CoachConnect with SalesBlink",
    "metaDescription": "Integrate CoachConnect and other Online Courses Tools with SalesBlink. Two Way CoachConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bellbird",
    "slug": "bellbird",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/683126bb3763b8a14b95e491e2161b1e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bellbird is a video calling service that offers simple and secure browser based video calls. Just send a link, no accounts required.",
    "category": "Scheduling & Booking",
    "title": "Bellbird Integration With Cold Email Sales Tool",
    "h1": "Integrate Bellbird with SalesBlink",
    "metaDescription": "Integrate Bellbird and other Scheduling & Booking Tools with SalesBlink. Two Way Bellbird Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Divi Checkout",
    "slug": "north-checkout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3cf9b04e1c361d1c95e9310e02152e95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "North Checkout is a customizable checkout module for Divi. Easily add any product to any page and start selling! We focus on adding, upsells and order bumps.",
    "category": "eCommerce",
    "title": "Divi Checkout Integration With Cold Email Sales Tool",
    "h1": "Integrate Divi Checkout with SalesBlink",
    "metaDescription": "Integrate Divi Checkout and other eCommerce Tools with SalesBlink. Two Way Divi Checkout Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parcy",
    "slug": "parcy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e69e7c9b341b0ba2b8294f5ab87b52d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parcy helps you easily create live, hybrid, and virtual events that are fully customizable and personalized for each attendee.",
    "category": "Event Management",
    "title": "Parcy Integration With Cold Email Sales Tool",
    "h1": "Integrate Parcy with SalesBlink",
    "metaDescription": "Integrate Parcy and other Event Management Tools with SalesBlink. Two Way Parcy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MimePost",
    "slug": "mimepost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f747742add51f1c8aca15b1e7a84743c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Secure SMTP & Email API Service. Inject, Process and Deliver. Nothing gets stored",
    "category": "Email",
    "title": "MimePost Integration With Cold Email Sales Tool",
    "h1": "Integrate MimePost with SalesBlink",
    "metaDescription": "Integrate MimePost and other Email Tools with SalesBlink. Two Way MimePost Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Hotscool",
    "slug": "hotscool",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7023f10af91cdecc1d85d5890eccca27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hotscool is an EdTech that develops simple and safe solutions that allows companies to create and deliver the best of online training.",
    "category": "Education",
    "title": "Hotscool Integration With Cold Email Sales Tool",
    "h1": "Integrate Hotscool with SalesBlink",
    "metaDescription": "Integrate Hotscool and other Education Tools with SalesBlink. Two Way Hotscool Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Repuso",
    "slug": "repuso",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad9f6c56cb9705b8f9530d129ed0ed06.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Repuso is a cloud-based review aggregation tool which automatically collects reviews from social media channels and showcase them on your website.",
    "category": "Marketing Automation",
    "title": "Repuso Integration With Cold Email Sales Tool",
    "h1": "Integrate Repuso with SalesBlink",
    "metaDescription": "Integrate Repuso and other Marketing Automation Tools with SalesBlink. Two Way Repuso Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Legal Monster",
    "slug": "legal-monster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/278e9b8d97812c16777a6e862a405386.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Legal Monster is legal consent management made easy. Connect Legal Monster with apps that need to know what your users have consented to.",
    "category": "Signatures",
    "title": "Legal Monster Integration With Cold Email Sales Tool",
    "h1": "Integrate Legal Monster with SalesBlink",
    "metaDescription": "Integrate Legal Monster and other Signatures Tools with SalesBlink. Two Way Legal Monster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Termii",
    "slug": "termii",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0031f92a6be21b6799c8f8bf028b27a2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Communication Infrastructure for African Businesses",
    "category": "Communication",
    "title": "Termii Integration With Cold Email Sales Tool",
    "h1": "Integrate Termii with SalesBlink",
    "metaDescription": "Integrate Termii and other Communication Tools with SalesBlink. Two Way Termii Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Ownest",
    "slug": "ownest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/01cfa0e02f0d46e11eae27f3cb69b3c2_8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ownest is a cloud-based, financing approval engine for borrowers to a B2B SaaS model to help developers, homebuilders and real estate professionals sell more homes, faster.",
    "category": "Business Intelligence",
    "title": "Ownest Integration With Cold Email Sales Tool",
    "h1": "Integrate Ownest with SalesBlink",
    "metaDescription": "Integrate Ownest and other Business Intelligence Tools with SalesBlink. Two Way Ownest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bookee",
    "slug": "bookee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/109b9256c1067b8a5e03edbbb7b95be1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bookee is a software that provides solutions for all needs of fitness & wellness businesses, saving them manual effort, time & money.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Bookee Integration With Cold Email Sales Tool",
    "h1": "Integrate Bookee with SalesBlink",
    "metaDescription": "Integrate Bookee and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Bookee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "STADIUM",
    "slug": "stadium",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e1c0388797d41f538c6ec466c06de2bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stadium lets you create an occasion specific gift shop from predefined templates. Send points to your recipients to redeem it on your store.",
    "category": "eCommerce",
    "title": "STADIUM Integration With Cold Email Sales Tool",
    "h1": "Integrate STADIUM with SalesBlink",
    "metaDescription": "Integrate STADIUM and other eCommerce Tools with SalesBlink. Two Way STADIUM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fliip",
    "slug": "fliip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/97fc18008ca972f0d57f9240b09e8375_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fliip is gym management software that works for you.",
    "category": "Fitness",
    "title": "Fliip Integration With Cold Email Sales Tool",
    "h1": "Integrate Fliip with SalesBlink",
    "metaDescription": "Integrate Fliip and other Fitness Tools with SalesBlink. Two Way Fliip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoiceRules",
    "slug": "voicerules",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e0a1ab0c99b3768fecddad49bad38fd8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoiceRules is any device, anytime, anywhere phone system for your sales and support teams. It takes seconds to get phone numbers in 100+ regions and start making or receiving calls.",
    "category": "CRM (Customer Relationship Management)",
    "title": "VoiceRules Integration With Cold Email Sales Tool",
    "h1": "Integrate VoiceRules with SalesBlink",
    "metaDescription": "Integrate VoiceRules and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way VoiceRules Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "pro-Forms",
    "slug": "pro-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ebc561d4d6d6be7f7ac0b9c036c90d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobile form building and Field Service software provider. From Mobile & Online forms to Job & Asset Management we have solutions for all companies.",
    "category": "Forms & Surveys",
    "title": "pro-Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate pro-Forms with SalesBlink",
    "metaDescription": "Integrate pro-Forms and other Forms & Surveys Tools with SalesBlink. Two Way pro-Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MeetingBooster",
    "slug": "meetingbooster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16bd39252a1d0ddd0dc88df1cf38f129.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MeetingBooster is a powerful cloud-based meeting and task management system",
    "category": "Productivity",
    "title": "MeetingBooster Integration With Cold Email Sales Tool",
    "h1": "Integrate MeetingBooster with SalesBlink",
    "metaDescription": "Integrate MeetingBooster and other Productivity Tools with SalesBlink. Two Way MeetingBooster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SwiftKanban",
    "slug": "swiftkanban",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8614e9eb0e8653421f16563e41b92a30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SwiftKanban is a powerful, yet intuitive, Kanban software for helping you manage your Lean/ Agile initiatives.",
    "category": "Project Management",
    "title": "SwiftKanban Integration With Cold Email Sales Tool",
    "h1": "Integrate SwiftKanban with SalesBlink",
    "metaDescription": "Integrate SwiftKanban and other Project Management Tools with SalesBlink. Two Way SwiftKanban Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Umbrella",
    "slug": "umbrella",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6af7c37f29739a9ac3ae20a893eb806a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Umbrella gives you the tools, training and support, to build and grow a digital marketing agency in partnership with known tech-brands.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Umbrella Integration With Cold Email Sales Tool",
    "h1": "Integrate Umbrella with SalesBlink",
    "metaDescription": "Integrate Umbrella and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Umbrella Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Project Monitor",
    "slug": "project-monitor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7a5cb7d6db7aa075510903129775e838.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Project Monitor is a PPM software that aims to manage your portfolios for all kinds of projects/sectors. Control the entire lifecycle, from request management to project execution. Measure progress on your team's workload and save time with a data-driven dashboard.",
    "category": "Project Management",
    "title": "Project Monitor Integration With Cold Email Sales Tool",
    "h1": "Integrate Project Monitor with SalesBlink",
    "metaDescription": "Integrate Project Monitor and other Project Management Tools with SalesBlink. Two Way Project Monitor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KARTE",
    "slug": "karte",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b639b17020af4373ff8606fe0d7d0203_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KARTE changes that by providing a seamless CX across industries, platforms and services.",
    "category": "Developer Tools",
    "title": "KARTE Integration With Cold Email Sales Tool",
    "h1": "Integrate KARTE with SalesBlink",
    "metaDescription": "Integrate KARTE and other Developer Tools Tools with SalesBlink. Two Way KARTE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clariti",
    "slug": "clariti",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5062a3c4541768d36e1b8be278a29c1c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Everything you need for effective Team Communication… in one app! Connected by topic.",
    "category": "Productivity",
    "title": "Clariti Integration With Cold Email Sales Tool",
    "h1": "Integrate Clariti with SalesBlink",
    "metaDescription": "Integrate Clariti and other Productivity Tools with SalesBlink. Two Way Clariti Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "360 Application Service",
    "slug": "evolution360",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/727ebaee483ce0277453445124d7644a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "360 Application Service provides Evolution360 and Agency360 with tools to manage leads, SEO, web analytics, social media, form tracking, intuitive reports and more.",
    "category": "Dashboards",
    "title": "360 Application Service Integration With Cold Email Sales Tool",
    "h1": "Integrate 360 Application Service with SalesBlink",
    "metaDescription": "Integrate 360 Application Service and other Dashboards Tools with SalesBlink. Two Way 360 Application Service Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brunch",
    "slug": "brunch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bbdcd5d83a068f29dde6b2589bfb6f08.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brunch is a tool for giving feedback on live websites. No need for long calls to explain where that text should be different or where is that extra pixel you didn’t notice in design. People can use Brunch to show you exactly what they want and where they want.",
    "category": "Project Management",
    "title": "Brunch Integration With Cold Email Sales Tool",
    "h1": "Integrate Brunch with SalesBlink",
    "metaDescription": "Integrate Brunch and other Project Management Tools with SalesBlink. Two Way Brunch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slooce - Unlimited SMS Texting",
    "slug": "slooce-unlimited-sms-texting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/770a470aa2ed3bdb58b7eed9b387d6b4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Business Text Messaging Solutions: sloocetech.com | Unlimited SMS for a Flat Monthly Fee | Scalable | Reliable | Fully Automated",
    "category": "Phone & SMS",
    "title": "Slooce - Unlimited SMS Texting Integration With Cold Email Sales Tool",
    "h1": "Integrate Slooce - Unlimited SMS Texting with SalesBlink",
    "metaDescription": "Integrate Slooce - Unlimited SMS Texting and other Phone & SMS Tools with SalesBlink. Two Way Slooce - Unlimited SMS Texting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MPower CS",
    "slug": "mpower-cs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1300730067b37c00075bc2c6de73d91f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MPower CS is a conversational marketing tool that will supercharge your social media and messaging platforms into a well-oiled selling machine.",
    "category": "Communication",
    "title": "MPower CS Integration With Cold Email Sales Tool",
    "h1": "Integrate MPower CS with SalesBlink",
    "metaDescription": "Integrate MPower CS and other Communication Tools with SalesBlink. Two Way MPower CS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YoCo Board",
    "slug": "yoco-board",
    "logo": "https://zapier-images.imgix.net/storage/developer/5baa4a31a7d6d379a7799b05b4632da6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YoCo Board is an online time tracking application that lets you track employees work hours.",
    "category": "Time Tracking Software",
    "title": "YoCo Board Integration With Cold Email Sales Tool",
    "h1": "Integrate YoCo Board with SalesBlink",
    "metaDescription": "Integrate YoCo Board and other Time Tracking Software Tools with SalesBlink. Two Way YoCo Board Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Colligso WalletIn",
    "slug": "colligso-walletin",
    "logo": "https://zapier-images.imgix.net/storage/services/9f422e5f2bc7a55d8e17a3812b407848.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Colligso's WalletIn app helps merchants to remarket their product/services directly to customers using a Pass in Apple Pay and Google Pay wallets.",
    "category": "Ads & Conversion",
    "title": "Colligso WalletIn Integration With Cold Email Sales Tool",
    "h1": "Integrate Colligso WalletIn with SalesBlink",
    "metaDescription": "Integrate Colligso WalletIn and other Ads & Conversion Tools with SalesBlink. Two Way Colligso WalletIn Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Maybe*",
    "slug": "maybe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8cee6f503b9d7f7553b9807f7fd6c852.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maybe* is a tool that helps organisations listen and engage with their customers through social media, benchmark their results, and optimise the ROI of their activity.",
    "category": "Social Media Marketing",
    "title": "Maybe* Integration With Cold Email Sales Tool",
    "h1": "Integrate Maybe* with SalesBlink",
    "metaDescription": "Integrate Maybe* and other Social Media Marketing Tools with SalesBlink. Two Way Maybe* Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RealWebsite",
    "slug": "realwebsite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/188d8c51e264c5114cee089427a45568.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RealWebsite is a website builder and hosting platform designed to give small business the ability to attract visitors and convert those visitors into leads and customers",
    "category": "Website Builders",
    "title": "RealWebsite Integration With Cold Email Sales Tool",
    "h1": "Integrate RealWebsite with SalesBlink",
    "metaDescription": "Integrate RealWebsite and other Website Builders Tools with SalesBlink. Two Way RealWebsite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beepsend SMS",
    "slug": "beepsend",
    "logo": "https://zapier-images.imgix.net/storage/developer/17b9eef73e0de1399da7ea67fbd7b884_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beepsend SMS is an easy and reliable tool to integrate SMS with your business applications. Beepsend's SMS services lets you send and receive SMS from mobile phones all over the world.",
    "category": "Phone & SMS",
    "title": "Beepsend SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Beepsend SMS with SalesBlink",
    "metaDescription": "Integrate Beepsend SMS and other Phone & SMS Tools with SalesBlink. Two Way Beepsend SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SurveyMethods",
    "slug": "surveymethods",
    "logo": "https://zapier-images.imgix.net/storage/developer/82a64bc9c6ff8c5ac3266d39bae58019.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveyMethods is a powerful and easy-to-use online survey software tool for survey creation, deployment and analysis.",
    "category": "Forms & Surveys",
    "title": "SurveyMethods Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveyMethods with SalesBlink",
    "metaDescription": "Integrate SurveyMethods and other Forms & Surveys Tools with SalesBlink. Two Way SurveyMethods Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MailSlurp Email Plugin",
    "slug": "mailslurp-email-plugin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f8984c8ed5320194879279a4b6c5d71.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MailSlurp is an API to generate test email accounts for sending and receiving real emails in tests.",
    "category": "Email",
    "title": "MailSlurp Email Plugin Integration With Cold Email Sales Tool",
    "h1": "Integrate MailSlurp Email Plugin with SalesBlink",
    "metaDescription": "Integrate MailSlurp Email Plugin and other Email Tools with SalesBlink. Two Way MailSlurp Email Plugin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TeleCube VPBX",
    "slug": "telecube-vpbx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3a4f590e794757fa2c85a46eed53783.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeleCube is a VoIP operator that delivers innovative telecommunication solutions to Business Clients. Through the modern and intuitive Client Panel, TeleCube allows easy management of calls and telecommunication services.",
    "category": "Call Tracking",
    "title": "TeleCube VPBX Integration With Cold Email Sales Tool",
    "h1": "Integrate TeleCube VPBX with SalesBlink",
    "metaDescription": "Integrate TeleCube VPBX and other Call Tracking Tools with SalesBlink. Two Way TeleCube VPBX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InEvent",
    "slug": "inevent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/815d4b307d1c281bcbb313a60875d64b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InEvent is a software for corporate events.",
    "category": "Event Management",
    "title": "InEvent Integration With Cold Email Sales Tool",
    "h1": "Integrate InEvent with SalesBlink",
    "metaDescription": "Integrate InEvent and other Event Management Tools with SalesBlink. Two Way InEvent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "indemandly",
    "slug": "indemandly",
    "logo": "https://zapier-images.imgix.net/storage/developer/761cba3a60e849230152b827f6781382_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "indemandly is a messaging app for your website & social media that helps convert visitors into customers.",
    "category": "Customer Support",
    "title": "indemandly Integration With Cold Email Sales Tool",
    "h1": "Integrate indemandly with SalesBlink",
    "metaDescription": "Integrate indemandly and other Customer Support Tools with SalesBlink. Two Way indemandly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NuMetric",
    "slug": "numetric",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6ae9e3de74ce4c6359b36630a52dcf8_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NuMetric is a one stop accounting platform for your business",
    "category": "Accounting",
    "title": "NuMetric Integration With Cold Email Sales Tool",
    "h1": "Integrate NuMetric with SalesBlink",
    "metaDescription": "Integrate NuMetric and other Accounting Tools with SalesBlink. Two Way NuMetric Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nalpeiron",
    "slug": "nalpeiron",
    "logo": "https://zapier-images.imgix.net/storage/developer/5c382fab86395c554de4d895a9256b3f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nalpeiron is a tool that allows companies to manage their customers software subscriptions and licensing.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Nalpeiron Integration With Cold Email Sales Tool",
    "h1": "Integrate Nalpeiron with SalesBlink",
    "metaDescription": "Integrate Nalpeiron and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Nalpeiron Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PriceBlocs",
    "slug": "priceblocs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96d3ed4c3ca13ee1e3bbaf8ac27a537b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PriceBlocs helps sales and marketing teams upgrade their users with as little friction as possible.",
    "category": "Payment Processing",
    "title": "PriceBlocs Integration With Cold Email Sales Tool",
    "h1": "Integrate PriceBlocs with SalesBlink",
    "metaDescription": "Integrate PriceBlocs and other Payment Processing Tools with SalesBlink. Two Way PriceBlocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boathouse Connect",
    "slug": "boathouse-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ab1f7c09a31161dec5fec084494b105_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your rowing organization from one place. From practice lineups to equipment to communication, Boathouse Connect can do it all",
    "category": "Team Collaboration",
    "title": "Boathouse Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Boathouse Connect with SalesBlink",
    "metaDescription": "Integrate Boathouse Connect and other Team Collaboration Tools with SalesBlink. Two Way Boathouse Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialGlow",
    "slug": "socialglow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9e78f6f9b4eb297f0e9fc28b50afb0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocialGlow helps course creators build amazing communities and online courses.",
    "category": "Online Courses",
    "title": "SocialGlow Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialGlow with SalesBlink",
    "metaDescription": "Integrate SocialGlow and other Online Courses Tools with SalesBlink. Two Way SocialGlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cincopa",
    "slug": "cincopa",
    "logo": "https://zapier-images.imgix.net/storage/developer/f668d9a57dc35edad40b52f26d802f2b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cincopa is multimedia solution allowing to handle all aspects of hosting, managing, publishing, and sharing multiple types of media content.",
    "category": "Video & Audio",
    "title": "Cincopa Integration With Cold Email Sales Tool",
    "h1": "Integrate Cincopa with SalesBlink",
    "metaDescription": "Integrate Cincopa and other Video & Audio Tools with SalesBlink. Two Way Cincopa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Berg System",
    "slug": "berg-system",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b933cd70d05d31f88133f2385e72acb7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Berg System is a CRM tool dedicated to sales teams in the financial, insurance, investment and photovoltaic industries.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Berg System Integration With Cold Email Sales Tool",
    "h1": "Integrate Berg System with SalesBlink",
    "metaDescription": "Integrate Berg System and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Berg System Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Releventful",
    "slug": "releventful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfdc25aae83b5435fce3ffc399dd2f38.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Releventful is customer relationship management solution built for the event industry. Connect with your customers, automate your workflows, and have clear insight into your operations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Releventful Integration With Cold Email Sales Tool",
    "h1": "Integrate Releventful with SalesBlink",
    "metaDescription": "Integrate Releventful and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Releventful Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Detectify",
    "slug": "detectify",
    "logo": "https://zapier-images.imgix.net/storage/developer/5f0fc43d3763bdd3d4847d337451c25a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Detectify continuously analyzes your web application from a hacker’s perspective and reports back to you with security issues and descriptive reports. We monitor your security, so that you can focus on building great products.",
    "category": "Security & Identity Tools",
    "title": "Detectify Integration With Cold Email Sales Tool",
    "h1": "Integrate Detectify with SalesBlink",
    "metaDescription": "Integrate Detectify and other Security & Identity Tools Tools with SalesBlink. Two Way Detectify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ROI4Presenter",
    "slug": "roi4presenter",
    "logo": "https://zapier-images.imgix.net/storage/services/6dd10bc5ec4e57df0eb5f59b09861de7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ROI4Presenter is a Web service and mobile application for online presentations and product demo, lead generation and outreach.",
    "category": "Marketing Automation",
    "title": "ROI4Presenter Integration With Cold Email Sales Tool",
    "h1": "Integrate ROI4Presenter with SalesBlink",
    "metaDescription": "Integrate ROI4Presenter and other Marketing Automation Tools with SalesBlink. Two Way ROI4Presenter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Diginius Insight",
    "slug": "diginius-insight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b13b20067bafb4a7541821abb0ebb66f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Diginius Insight app removes the need to both check and understand multiple unconnected platforms such as Google, Microsoft, Twitter, Facebook, Instagram, and online marketplaces, such as Amazon.",
    "category": "Business Intelligence",
    "title": "Diginius Insight Integration With Cold Email Sales Tool",
    "h1": "Integrate Diginius Insight with SalesBlink",
    "metaDescription": "Integrate Diginius Insight and other Business Intelligence Tools with SalesBlink. Two Way Diginius Insight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Let's Rolo",
    "slug": "lets-rolo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d62c6d9cfb29e00bb501069fef172123.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rolo is the world's smartest Micro CRM bringing intelligence to contacts from handshakes to first meetings.",
    "category": "Sales & CRM",
    "title": "Let's Rolo Integration With Cold Email Sales Tool",
    "h1": "Integrate Let's Rolo with SalesBlink",
    "metaDescription": "Integrate Let's Rolo and other Sales & CRM Tools with SalesBlink. Two Way Let's Rolo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Modash",
    "slug": "modash",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68e0b442e2138ae44b82477db15330cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Modash is an influencer tool helping you find creators who speak to your audience at scale.",
    "category": "Marketing Automation",
    "title": "Modash Integration With Cold Email Sales Tool",
    "h1": "Integrate Modash with SalesBlink",
    "metaDescription": "Integrate Modash and other Marketing Automation Tools with SalesBlink. Two Way Modash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSINDIAHUB (International)",
    "slug": "smsindiahub-international",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/029ee7a4fd82f96b0099ce8698184f0c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSINDIAHUB International is an SMS Service that allows you to send SMS messages. Suitable for International users - if in India, please use SMSINDIAHUB (India).",
    "category": "Phone & SMS",
    "title": "SMSINDIAHUB (International) Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSINDIAHUB (International) with SalesBlink",
    "metaDescription": "Integrate SMSINDIAHUB (International) and other Phone & SMS Tools with SalesBlink. Two Way SMSINDIAHUB (International) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Worksome",
    "slug": "worksome",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f73e10316c0a60202751485590a3fa67_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Worksome is a technology provider enabling businesses to identify, classify, engage and payroll all their talent in a single tool.",
    "category": "HR Talent & Recruitment",
    "title": "Worksome Integration With Cold Email Sales Tool",
    "h1": "Integrate Worksome with SalesBlink",
    "metaDescription": "Integrate Worksome and other HR Talent & Recruitment Tools with SalesBlink. Two Way Worksome Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prodia",
    "slug": "prodia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/67b0289f5b9747dd53a5899c125586ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prodia-Stable Diffusion API, offers a fast and easy-to-use API for image and music generation.",
    "category": "AI Tools",
    "title": "Prodia Integration With Cold Email Sales Tool",
    "h1": "Integrate Prodia with SalesBlink",
    "metaDescription": "Integrate Prodia and other AI Tools Tools with SalesBlink. Two Way Prodia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Microspace",
    "slug": "microspace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5994b9ab46223cffd671605c1cfbfcb0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microspace is an intuitive tool for teams to share their story with clients and get customer insights in a branded shared digital space.",
    "category": "Documents",
    "title": "Microspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Microspace with SalesBlink",
    "metaDescription": "Integrate Microspace and other Documents Tools with SalesBlink. Two Way Microspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NorthStar by GrowthHackers",
    "slug": "northstar",
    "logo": "https://zapier-images.imgix.net/storage/developer/8810fd489edbe5495e509d75e9d5cda8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowthHackers is a powerful collaboration platform helping marketers and growth professionals unleash their company's growth potential. We do this via a community of growth practitioners that share insights and resources at GrowthHackers.com, as well as a collaborative software platform, NorthStar by GrowthHackers, that keeps teams on the same page as they accelerate their growth testing and learning process.",
    "category": "Team Collaboration",
    "title": "NorthStar by GrowthHackers Integration With Cold Email Sales Tool",
    "h1": "Integrate NorthStar by GrowthHackers with SalesBlink",
    "metaDescription": "Integrate NorthStar by GrowthHackers and other Team Collaboration Tools with SalesBlink. Two Way NorthStar by GrowthHackers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Palm.hr",
    "slug": "palmhr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f76dc9fcac8421cc62d10abbec1129a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "palm.hr is a fully automated HRMS and your path to building a streamlined and consistent employee experience. It has everything modern HR teams need to manage, grow and engage their people without compromise.",
    "category": "Human Resources",
    "title": "Palm.hr Integration With Cold Email Sales Tool",
    "h1": "Integrate Palm.hr with SalesBlink",
    "metaDescription": "Integrate Palm.hr and other Human Resources Tools with SalesBlink. Two Way Palm.hr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onro",
    "slug": "onro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a55220a69cd5e05d06e8c07c6cd3b6c9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Last mile delivery software simplifies managing, dispatching and optimization last mile deliveries and logistics.",
    "category": "Scheduling & Booking",
    "title": "Onro Integration With Cold Email Sales Tool",
    "h1": "Integrate Onro with SalesBlink",
    "metaDescription": "Integrate Onro and other Scheduling & Booking Tools with SalesBlink. Two Way Onro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Runscope",
    "slug": "runscope",
    "logo": "https://zapier-images.imgix.net/storage/developer/a6c8c73223722e8f60825577fc2b5a20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "API debugging and testing tools for app developers.",
    "category": "Server Monitoring",
    "title": "Runscope Integration With Cold Email Sales Tool",
    "h1": "Integrate Runscope with SalesBlink",
    "metaDescription": "Integrate Runscope and other Server Monitoring Tools with SalesBlink. Two Way Runscope Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Anwaltssekretariat",
    "slug": "anwaltssekretariat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef2350672cb047d687c77b76ef3de22c_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anwaltssekretariat is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business",
    "category": "Communication",
    "title": "Anwaltssekretariat Integration With Cold Email Sales Tool",
    "h1": "Integrate Anwaltssekretariat with SalesBlink",
    "metaDescription": "Integrate Anwaltssekretariat and other Communication Tools with SalesBlink. Two Way Anwaltssekretariat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BlueSnap",
    "slug": "bluesnap",
    "logo": "https://zapier-images.imgix.net/storage/developer/deb96034f492087ba07c3bf92aef3d04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BlueSnap is an All-in-one Payment Platform designed to increase sales and reduce costs for B2B and B2C businesses.",
    "category": "Payment Processing",
    "title": "BlueSnap Integration With Cold Email Sales Tool",
    "h1": "Integrate BlueSnap with SalesBlink",
    "metaDescription": "Integrate BlueSnap and other Payment Processing Tools with SalesBlink. Two Way BlueSnap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voma",
    "slug": "voma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa774dcf25f5e51139eac11f9ce8beb0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Voma is a payment platform that enables creators to sell online courses, membership sites, digital products, paid email newsletters, or monthly subscriptions.",
    "category": "Payment Processing",
    "title": "Voma Integration With Cold Email Sales Tool",
    "h1": "Integrate Voma with SalesBlink",
    "metaDescription": "Integrate Voma and other Payment Processing Tools with SalesBlink. Two Way Voma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UTM.io",
    "slug": "utmio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf92241611b128f28bfc31771e0f78f2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UTM.io: link management and tracking platform for centralized creation, organization, and management of UTM links across multiple teams.",
    "category": "Marketing Automation",
    "title": "UTM.io Integration With Cold Email Sales Tool",
    "h1": "Integrate UTM.io with SalesBlink",
    "metaDescription": "Integrate UTM.io and other Marketing Automation Tools with SalesBlink. Two Way UTM.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AdminPulse",
    "slug": "adminpulse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bea3976d0949848060ffe88a1785d6cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AdminPulse is the online office management  software for accountants, bookkeepers and auditors",
    "category": "Accounting",
    "title": "AdminPulse Integration With Cold Email Sales Tool",
    "h1": "Integrate AdminPulse with SalesBlink",
    "metaDescription": "Integrate AdminPulse and other Accounting Tools with SalesBlink. Two Way AdminPulse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChurchStamp",
    "slug": "churchstamp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5b7bdda95a395ab1a2761f4902f9f32.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatically send personalized postcards using other software as a trigger, such as online forms, databases, spreadsheets, and more.",
    "category": "Marketing Automation",
    "title": "ChurchStamp Integration With Cold Email Sales Tool",
    "h1": "Integrate ChurchStamp with SalesBlink",
    "metaDescription": "Integrate ChurchStamp and other Marketing Automation Tools with SalesBlink. Two Way ChurchStamp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HackMD",
    "slug": "hackmd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6fbde57ba378f587210146a9b1943a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HackMD is a realtime collaboration markdown editor to help developers and teams to manage their documentation and improve productivity",
    "category": "Content & Files",
    "title": "HackMD Integration With Cold Email Sales Tool",
    "h1": "Integrate HackMD with SalesBlink",
    "metaDescription": "Integrate HackMD and other Content & Files Tools with SalesBlink. Two Way HackMD Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loyalty Gator",
    "slug": "loyalty-gator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ce2179e730029e77e29bf7fdf395bab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loyalty Gator is a customer loyalty program that helps increase retention and sales while providing cross communication of transactions.",
    "category": "Customer Appreciation",
    "title": "Loyalty Gator Integration With Cold Email Sales Tool",
    "h1": "Integrate Loyalty Gator with SalesBlink",
    "metaDescription": "Integrate Loyalty Gator and other Customer Appreciation Tools with SalesBlink. Two Way Loyalty Gator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "gyfti",
    "slug": "gyfti",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce2e5e9dbaaf399a75c0e66fffc81ac5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gyfti is the 1st B2B platform that facilitates the sending of personalized gifts choosed by your prospects, customers and employees, while responding to operational, legal, ethical and administrative issues.",
    "category": "Productivity",
    "title": "gyfti Integration With Cold Email Sales Tool",
    "h1": "Integrate gyfti with SalesBlink",
    "metaDescription": "Integrate gyfti and other Productivity Tools with SalesBlink. Two Way gyfti Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iFactFind",
    "slug": "ifactfind",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ade8c269e2f297edf40f0e868f8a7b08_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iFactFind is the new standard in client data collection and management. The digital online fact find for financial advisers and their clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "iFactFind Integration With Cold Email Sales Tool",
    "h1": "Integrate iFactFind with SalesBlink",
    "metaDescription": "Integrate iFactFind and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way iFactFind Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Netcore Cloud",
    "slug": "netcore-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ad751f6d694fac1ac5379470d0474d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Netcore Cloud offers Customer Engagement & Experience, Personalization, PX, Journey Orchestration & CPaaS solutions - all in one cloud",
    "category": "Marketing Automation",
    "title": "Netcore Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Netcore Cloud with SalesBlink",
    "metaDescription": "Integrate Netcore Cloud and other Marketing Automation Tools with SalesBlink. Two Way Netcore Cloud Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Ministry Sync",
    "slug": "ministry-sync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea2a528cf29b89445f6b962c797d0102_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ministry Sync is an event management software for event coordinators and participants to promote and raise funds for their cause. AttendEasy and FundEasy are front-end applications that connect with Ministry Sync.",
    "category": "Event Management",
    "title": "Ministry Sync Integration With Cold Email Sales Tool",
    "h1": "Integrate Ministry Sync with SalesBlink",
    "metaDescription": "Integrate Ministry Sync and other Event Management Tools with SalesBlink. Two Way Ministry Sync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RLTRsync",
    "slug": "rltrsync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc7c4816fed3574840e0edbc837af912_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RLTRsync is a virtual ecosystem for real estate agents.",
    "category": "Marketing",
    "title": "RLTRsync Integration With Cold Email Sales Tool",
    "h1": "Integrate RLTRsync with SalesBlink",
    "metaDescription": "Integrate RLTRsync and other Marketing Tools with SalesBlink. Two Way RLTRsync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sales Simplify",
    "slug": "sales-simplify",
    "logo": "https://zapier-images.imgix.net/storage/services/e0bb7535d84893e05e746aa78be164c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An all-in-one sales automation and engagement platform with built-in CRM and cloud communication channels.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sales Simplify Integration With Cold Email Sales Tool",
    "h1": "Integrate Sales Simplify with SalesBlink",
    "metaDescription": "Integrate Sales Simplify and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sales Simplify Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Send Lesson",
    "slug": "send-lesson",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ac5ae3d8c370ad0025c995ac341dc72d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "B2B SaaS utilising generative AI to personalise B2B educational and information marketing - bringing web viewers into your sales funnel as warm leads.",
    "category": "Sales & CRM",
    "title": "Send Lesson Integration With Cold Email Sales Tool",
    "h1": "Integrate Send Lesson with SalesBlink",
    "metaDescription": "Integrate Send Lesson and other Sales & CRM Tools with SalesBlink. Two Way Send Lesson Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Delibr",
    "slug": "delibr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3dfdc95b27f47a68774e577446713a80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "End-to-end PM tool that supercharges discovery and validation",
    "category": "Product Management",
    "title": "Delibr Integration With Cold Email Sales Tool",
    "h1": "Integrate Delibr with SalesBlink",
    "metaDescription": "Integrate Delibr and other Product Management Tools with SalesBlink. Two Way Delibr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VERIFYiQ",
    "slug": "verifyiq",
    "logo": "https://zapier-images.imgix.net/storage/services/71ae1e89c4d8c8c9637f0007c3313a75.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The next generation of quality control for your bookkeeping. Automatically catch 80% of all errors with our help.",
    "category": "Accounting",
    "title": "VERIFYiQ Integration With Cold Email Sales Tool",
    "h1": "Integrate VERIFYiQ with SalesBlink",
    "metaDescription": "Integrate VERIFYiQ and other Accounting Tools with SalesBlink. Two Way VERIFYiQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AdviserLogic Fact Finder",
    "slug": "morningstar-adl-client-onboardin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02d1904f0f90f5877c6510aa0cdf0d95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fact Finder is an application to either fetch, create, or update Fact Finder data as per current AdviserLogic schema",
    "category": "CRM (Customer Relationship Management)",
    "title": "AdviserLogic Fact Finder Integration With Cold Email Sales Tool",
    "h1": "Integrate AdviserLogic Fact Finder with SalesBlink",
    "metaDescription": "Integrate AdviserLogic Fact Finder and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AdviserLogic Fact Finder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeskDirector",
    "slug": "deskdirector",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4139d27faf61418d0a8cc14ef287b6a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeskDirector provides top customer success platform for MSPs that provides customizable client and tech portals, centralized communication, smart tickets, automations and more.",
    "category": "Customer Support",
    "title": "DeskDirector Integration With Cold Email Sales Tool",
    "h1": "Integrate DeskDirector with SalesBlink",
    "metaDescription": "Integrate DeskDirector and other Customer Support Tools with SalesBlink. Two Way DeskDirector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyMobileAPI",
    "slug": "mymobileapi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/caf583a78e8a0df7fa3b47a1d3decca3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyMobileAPI is a business communication platform that helps organizations tap into the power of Bulk SMS for maximum reach and genuine customer engagement.",
    "category": "Communication",
    "title": "MyMobileAPI Integration With Cold Email Sales Tool",
    "h1": "Integrate MyMobileAPI with SalesBlink",
    "metaDescription": "Integrate MyMobileAPI and other Communication Tools with SalesBlink. Two Way MyMobileAPI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scale Fast",
    "slug": "scale-fast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5dd6573fdc7b687404ab9b86e6e70ded.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The All-In-One Software For Running & Scaling Your Business",
    "category": "Marketing Automation",
    "title": "Scale Fast Integration With Cold Email Sales Tool",
    "h1": "Integrate Scale Fast with SalesBlink",
    "metaDescription": "Integrate Scale Fast and other Marketing Automation Tools with SalesBlink. Two Way Scale Fast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Edgility",
    "slug": "edgility",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/52c13388ae83733fb9707e659ce73270.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Edgility is a cloud based SMS tool to connect you with the people that matter to your business.",
    "category": "Communication",
    "title": "Edgility Integration With Cold Email Sales Tool",
    "h1": "Integrate Edgility with SalesBlink",
    "metaDescription": "Integrate Edgility and other Communication Tools with SalesBlink. Two Way Edgility Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Game My Biz",
    "slug": "game-my-biz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4776d056e874dff8c307c9bac9876b4b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Game My Biz is a business-to-game converter platform that provides Key Actionable Insights to improve business operations through a game board genre of your choice.",
    "category": "Dashboards",
    "title": "Game My Biz Integration With Cold Email Sales Tool",
    "h1": "Integrate Game My Biz with SalesBlink",
    "metaDescription": "Integrate Game My Biz and other Dashboards Tools with SalesBlink. Two Way Game My Biz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Radius Agent",
    "slug": "radius-agent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e0827627e923b2b630a39aeffc1cf5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Radius Agent qualifies leads using the assist program to help you close deals.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Radius Agent Integration With Cold Email Sales Tool",
    "h1": "Integrate Radius Agent with SalesBlink",
    "metaDescription": "Integrate Radius Agent and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Radius Agent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EventHub",
    "slug": "eventhub-add-userqa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9960de98d108926e7d63d5712849a5f7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EventHub is an all-in-one event package. You can hold seminars, conferences, and other events for registered users.",
    "category": "Marketing Automation",
    "title": "EventHub Integration With Cold Email Sales Tool",
    "h1": "Integrate EventHub with SalesBlink",
    "metaDescription": "Integrate EventHub and other Marketing Automation Tools with SalesBlink. Two Way EventHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Widen Collective",
    "slug": "widen-collective",
    "logo": "https://zapier-images.imgix.net/storage/developer/5aed24145e8a393df80da9aa1ddce4ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Widen Collective is a digital asset management solution that brings together all of your image, video and creative files in one location to quickly find, access and share – anytime, anywhere, on any device.",
    "category": "Video & Audio",
    "title": "Widen Collective Integration With Cold Email Sales Tool",
    "h1": "Integrate Widen Collective with SalesBlink",
    "metaDescription": "Integrate Widen Collective and other Video & Audio Tools with SalesBlink. Two Way Widen Collective Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "My Event Cafe",
    "slug": "my-event-cafe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b3c98c2beeb5c139d9f07078071106c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "My Event Café is a virtual online classroom where you can build courses intended for social learning.",
    "category": "Online Courses",
    "title": "My Event Cafe Integration With Cold Email Sales Tool",
    "h1": "Integrate My Event Cafe with SalesBlink",
    "metaDescription": "Integrate My Event Cafe and other Online Courses Tools with SalesBlink. Two Way My Event Cafe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DMSales",
    "slug": "dmsales",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ec706b41bc1a8e35869914a21d1d9f9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DMSales platform is a tool to marketing and sales automation. Conduct campaigns, acquire customers and maintain relationships in one place.",
    "category": "Sales & CRM",
    "title": "DMSales Integration With Cold Email Sales Tool",
    "h1": "Integrate DMSales with SalesBlink",
    "metaDescription": "Integrate DMSales and other Sales & CRM Tools with SalesBlink. Two Way DMSales Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReachMail",
    "slug": "reachmail",
    "logo": "https://zapier-images.imgix.net/storage/developer/1028b304d2bb5af5f809d80e67ca4ef3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReachMail is an Email Service Provider (ESP) that offers tools for managing and delivering marketing and transactional email messages.",
    "category": "Email Newsletters",
    "title": "ReachMail Integration With Cold Email Sales Tool",
    "h1": "Integrate ReachMail with SalesBlink",
    "metaDescription": "Integrate ReachMail and other Email Newsletters Tools with SalesBlink. Two Way ReachMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GeoScreenshot",
    "slug": "geoscreenshot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4314b733e6f290b8ce5339d2fee0a68f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GeoScreenshot is a developer tool that lets you capture a screenshot of your website from many geo-locations around the world.",
    "category": "Developer Tools",
    "title": "GeoScreenshot Integration With Cold Email Sales Tool",
    "h1": "Integrate GeoScreenshot with SalesBlink",
    "metaDescription": "Integrate GeoScreenshot and other Developer Tools Tools with SalesBlink. Two Way GeoScreenshot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upstream Tech Lens",
    "slug": "upstream-tech-lens",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be2af0d0ddaf0b9dcfc501e14ec8ece6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lens gives everyone in your organization eyes on the ground with aggregated satellite, aerial and environmental data and a powerful workflow for collaborative monitoring.",
    "category": "Team Collaboration",
    "title": "Upstream Tech Lens Integration With Cold Email Sales Tool",
    "h1": "Integrate Upstream Tech Lens with SalesBlink",
    "metaDescription": "Integrate Upstream Tech Lens and other Team Collaboration Tools with SalesBlink. Two Way Upstream Tech Lens Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simpleen Translation",
    "slug": "simpleen-translation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3135af2e9a5b31533c7ca39e43294e4f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simpleen enables you to customize translations from your machine translation service.",
    "category": "Transcription",
    "title": "Simpleen Translation Integration With Cold Email Sales Tool",
    "h1": "Integrate Simpleen Translation with SalesBlink",
    "metaDescription": "Integrate Simpleen Translation and other Transcription Tools with SalesBlink. Two Way Simpleen Translation Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "idexo",
    "slug": "idexo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5dda79f52b2673d9faed61a3a8ef10b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "idexo lets you create NFTs from any web2 application",
    "category": "App Builder",
    "title": "idexo Integration With Cold Email Sales Tool",
    "h1": "Integrate idexo with SalesBlink",
    "metaDescription": "Integrate idexo and other App Builder Tools with SalesBlink. Two Way idexo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adobe Photoshop Lightroom",
    "slug": "adobe-photoshop-lightroom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61942b556e8dc3e017bbc17e0b54473e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adobe Photoshop Lightroom is the cloud-based service that gives you everything you need to edit, organize, store, and share your photos across any device.",
    "category": "Images & Design",
    "title": "Adobe Photoshop Lightroom Integration With Cold Email Sales Tool",
    "h1": "Integrate Adobe Photoshop Lightroom with SalesBlink",
    "metaDescription": "Integrate Adobe Photoshop Lightroom and other Images & Design Tools with SalesBlink. Two Way Adobe Photoshop Lightroom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YM Careers",
    "slug": "ym-careers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86ec8ce02ded6c6589f4d30920b31506.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YM Careers is a SaaS career center and professional development application.",
    "category": "HR Talent & Recruitment",
    "title": "YM Careers Integration With Cold Email Sales Tool",
    "h1": "Integrate YM Careers with SalesBlink",
    "metaDescription": "Integrate YM Careers and other HR Talent & Recruitment Tools with SalesBlink. Two Way YM Careers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Giftpack",
    "slug": "giftpack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/593be66dd522cc0b6126310623933f45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Giftpack is an AI-powered gifting platform to make your relationship easier.",
    "category": "Business Intelligence",
    "title": "Giftpack Integration With Cold Email Sales Tool",
    "h1": "Integrate Giftpack with SalesBlink",
    "metaDescription": "Integrate Giftpack and other Business Intelligence Tools with SalesBlink. Two Way Giftpack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "App4Legal",
    "slug": "app4legal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/027243e6afd0d94d3ee61be616f298d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "App4Legal is a complete practice management software for all legal practitioners.",
    "category": "Productivity",
    "title": "App4Legal Integration With Cold Email Sales Tool",
    "h1": "Integrate App4Legal with SalesBlink",
    "metaDescription": "Integrate App4Legal and other Productivity Tools with SalesBlink. Two Way App4Legal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OptiPub",
    "slug": "optipub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/35578715e9c31a2925fb6966945a4fc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "High volume, highly optimized publishing with OptiPub.",
    "category": "Marketing",
    "title": "OptiPub Integration With Cold Email Sales Tool",
    "h1": "Integrate OptiPub with SalesBlink",
    "metaDescription": "Integrate OptiPub and other Marketing Tools with SalesBlink. Two Way OptiPub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatBeacon",
    "slug": "chatbeacon",
    "logo": "https://zapier-images.imgix.net/storage/developer/97b10c161c76c27f5329fb008e5945f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatBeacon is a live chat solution for your website, allowing you to monitor your website traffic in real-time, provide customer service and support or capture sale leads. ChatBeacon offers three chat channels: website, SMS and Facebook Chat.",
    "category": "App Builder",
    "title": "ChatBeacon Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatBeacon with SalesBlink",
    "metaDescription": "Integrate ChatBeacon and other App Builder Tools with SalesBlink. Two Way ChatBeacon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HubbedIn",
    "slug": "hubbedin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/287cfd76d1964b0a0cff80bb6b68c2a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HubbedIn is a job posting & recruitment platform that allow companies to publish & manage their job, interviews & candidates in one platform",
    "category": "Business Intelligence",
    "title": "HubbedIn Integration With Cold Email Sales Tool",
    "h1": "Integrate HubbedIn with SalesBlink",
    "metaDescription": "Integrate HubbedIn and other Business Intelligence Tools with SalesBlink. Two Way HubbedIn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ucraft",
    "slug": "ucraft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c2b467ce919485298b0d911f5b8213b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ucraft is a drag and drop Website Builder that provides web designers, freelancers, marketers or entrepreneurs a set of web design tools to start their next project.",
    "category": "Website Builders",
    "title": "Ucraft Integration With Cold Email Sales Tool",
    "h1": "Integrate Ucraft with SalesBlink",
    "metaDescription": "Integrate Ucraft and other Website Builders Tools with SalesBlink. Two Way Ucraft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Q Ware CMMS",
    "slug": "q-ware-cmms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d9d2660f9a20fe555b38c328e288f09.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Q Ware CMMS is a computerized maintenance management application that provides work order, asset, and facility management tools.",
    "category": "Task Management",
    "title": "Q Ware CMMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Q Ware CMMS with SalesBlink",
    "metaDescription": "Integrate Q Ware CMMS and other Task Management Tools with SalesBlink. Two Way Q Ware CMMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnlineFundraising",
    "slug": "onlinefundraising",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e619b54cff34234aff0c937445c85986_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnlineFundraising is a integration tool between payment methods and onboarding activities, based in Denmark.",
    "category": "Payment Processing",
    "title": "OnlineFundraising Integration With Cold Email Sales Tool",
    "h1": "Integrate OnlineFundraising with SalesBlink",
    "metaDescription": "Integrate OnlineFundraising and other Payment Processing Tools with SalesBlink. Two Way OnlineFundraising Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WIZniche",
    "slug": "wizniche",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/389469df4f6b675b694f43a50363cd79.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WizNiche is a Business Service Management System tailored to small businesses. It integrates key business functions to streamline processes.",
    "category": "Scheduling & Booking",
    "title": "WIZniche Integration With Cold Email Sales Tool",
    "h1": "Integrate WIZniche with SalesBlink",
    "metaDescription": "Integrate WIZniche and other Scheduling & Booking Tools with SalesBlink. Two Way WIZniche Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tapni",
    "slug": "tapni",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cb55f392ecbf83e03a7ab97e6c6ef4b6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Discover the future of networking with Tapni. Share your Social media, Business and Contact Information in a smart, contactless, and sustainable way with a Next-generation digital Business Card.",
    "category": "Contact Management",
    "title": "Tapni Integration With Cold Email Sales Tool",
    "h1": "Integrate Tapni with SalesBlink",
    "metaDescription": "Integrate Tapni and other Contact Management Tools with SalesBlink. Two Way Tapni Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kazm",
    "slug": "kazm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/917f7c7345845da3c2e3929655f8efea_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kazm is the data-driven CRM for Web3. We plug into every community touchpoint to create unified customer profiles, then apply machine learning and other methods to label, segment, and help personalize experiences for users.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kazm Integration With Cold Email Sales Tool",
    "h1": "Integrate Kazm with SalesBlink",
    "metaDescription": "Integrate Kazm and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kazm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adact",
    "slug": "adact",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/38f1406c12a72a74292cd65d0d1313a0_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Adact to create games and interactive marketing that talk about your brand without any coding!",
    "category": "Marketing",
    "title": "Adact Integration With Cold Email Sales Tool",
    "h1": "Integrate Adact with SalesBlink",
    "metaDescription": "Integrate Adact and other Marketing Tools with SalesBlink. Two Way Adact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "tuGerente",
    "slug": "tugerente",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fbcab7f7741c2b898e46b9bad5302e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "tuGerente is an administrative, financial, and accounting software whose purpose is to make our customers' lives easier",
    "category": "Accounting",
    "title": "tuGerente Integration With Cold Email Sales Tool",
    "h1": "Integrate tuGerente with SalesBlink",
    "metaDescription": "Integrate tuGerente and other Accounting Tools with SalesBlink. Two Way tuGerente Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Exceptionless",
    "slug": "exceptionless",
    "logo": "https://zapier-images.imgix.net/storage/developer/de531284942774851b50d9b91300500d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Exceptionless provides real-time error reporting for your apps. It organizes the gathered information into simple actionable data that will help your app become exceptionless!",
    "category": "Server Monitoring",
    "title": "Exceptionless Integration With Cold Email Sales Tool",
    "h1": "Integrate Exceptionless with SalesBlink",
    "metaDescription": "Integrate Exceptionless and other Server Monitoring Tools with SalesBlink. Two Way Exceptionless Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CDLSuite",
    "slug": "cdlsuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/810b7bd8405c6f1342afc5230661d711.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CDLSuite combines the essential tools for attracting, recruiting, hiring and qualifying drivers, into a single, seamless solution.",
    "category": "HR Talent & Recruitment",
    "title": "CDLSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate CDLSuite with SalesBlink",
    "metaDescription": "Integrate CDLSuite and other HR Talent & Recruitment Tools with SalesBlink. Two Way CDLSuite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "klar.land",
    "slug": "klarland",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/04e9ebc82a319e4e09d35f1736bb27f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "klar.land is a plug & play platform that enables virtual product demonstrations, networking, meetings and events for industrial companies.",
    "category": "Marketing",
    "title": "klar.land Integration With Cold Email Sales Tool",
    "h1": "Integrate klar.land with SalesBlink",
    "metaDescription": "Integrate klar.land and other Marketing Tools with SalesBlink. Two Way klar.land Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Muster",
    "slug": "muster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76adef0b5989b486e358a43e74bdfc2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Muster is an easy to use tool for precision advocacy campaigns.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Muster Integration With Cold Email Sales Tool",
    "h1": "Integrate Muster with SalesBlink",
    "metaDescription": "Integrate Muster and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Muster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fynzo Survey",
    "slug": "fynzo-survey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00d3af74b09f94d18737b4b2262bea6b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build survey forms in different ways ranging from multiple choice, simple text, numeric data, Image, audio and geolocation all on your phone, tablet and web dashboard.",
    "category": "Forms & Surveys",
    "title": "Fynzo Survey Integration With Cold Email Sales Tool",
    "h1": "Integrate Fynzo Survey with SalesBlink",
    "metaDescription": "Integrate Fynzo Survey and other Forms & Surveys Tools with SalesBlink. Two Way Fynzo Survey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Isla",
    "slug": "isla",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c334bc458f175d3aa59e775f18dc4810.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Isla is your hub to send and receive structured requests, eliminating the hassle of back-and-forth messages, interruptions, and context-switching at the workplace.",
    "category": "Team Collaboration",
    "title": "Isla Integration With Cold Email Sales Tool",
    "h1": "Integrate Isla with SalesBlink",
    "metaDescription": "Integrate Isla and other Team Collaboration Tools with SalesBlink. Two Way Isla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clarity Stack",
    "slug": "clarity-stack",
    "logo": "https://zapier-images.imgix.net/storage/services/8c719c739deacde08d0b72298e2127a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Access millions of company records, live contracts and buying signals for more effective sales prospecting.",
    "category": "Business Intelligence",
    "title": "Clarity Stack Integration With Cold Email Sales Tool",
    "h1": "Integrate Clarity Stack with SalesBlink",
    "metaDescription": "Integrate Clarity Stack and other Business Intelligence Tools with SalesBlink. Two Way Clarity Stack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Groopit",
    "slug": "groopit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/84b6e61a353620a4609473028de4bcaa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Groopit makes it easy to collect real-time data and engage the extended team to keep them contributing.",
    "category": "Forms & Surveys",
    "title": "Groopit Integration With Cold Email Sales Tool",
    "h1": "Integrate Groopit with SalesBlink",
    "metaDescription": "Integrate Groopit and other Forms & Surveys Tools with SalesBlink. Two Way Groopit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Task",
    "slug": "task-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b94dc7a861e3ecac8bec623b30d7e81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Task is where people create, discover projects, complete tasks and get rewarded for taking part. Build micro-economies and get work done.",
    "category": "Team Collaboration",
    "title": "Task Integration With Cold Email Sales Tool",
    "h1": "Integrate Task with SalesBlink",
    "metaDescription": "Integrate Task and other Team Collaboration Tools with SalesBlink. Two Way Task Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Talexio",
    "slug": "talexio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0f0ab3afe445269ba6c4f94b2f59be5d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Talexio is a Next Generation HR, Payroll and Recruitment Software service",
    "category": "HR Talent & Recruitment",
    "title": "Talexio Integration With Cold Email Sales Tool",
    "h1": "Integrate Talexio with SalesBlink",
    "metaDescription": "Integrate Talexio and other HR Talent & Recruitment Tools with SalesBlink. Two Way Talexio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SALESENGINE.SE",
    "slug": "salesenginese",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc7a955cb9d1d198082eb20f3a127e98_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integration with the Mortgage CRM SalesEngine.SE",
    "category": "CRM (Customer Relationship Management)",
    "title": "SALESENGINE.SE Integration With Cold Email Sales Tool",
    "h1": "Integrate SALESENGINE.SE with SalesBlink",
    "metaDescription": "Integrate SALESENGINE.SE and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SALESENGINE.SE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Model Match",
    "slug": "model-match",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/073ee1f9a1b2ae15b6cb91760c38e129.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Model Match helps forward thinking mortgage lenders streamline the recruiting process and bring their team and pipeline into one central, collaborative space.",
    "category": "Contact Management",
    "title": "Model Match Integration With Cold Email Sales Tool",
    "h1": "Integrate Model Match with SalesBlink",
    "metaDescription": "Integrate Model Match and other Contact Management Tools with SalesBlink. Two Way Model Match Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Toosa",
    "slug": "toosa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/036b1a2c6e4959aa8860a5bfd5394532.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Toosa is an engaging, easy-to-use communications platform for influencers and businesses. Supercharge your brand, grow your revenue, and build 1-to-1 connections with your followers and customers.",
    "category": "Communication",
    "title": "Toosa Integration With Cold Email Sales Tool",
    "h1": "Integrate Toosa with SalesBlink",
    "metaDescription": "Integrate Toosa and other Communication Tools with SalesBlink. Two Way Toosa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coreo",
    "slug": "coreo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dece94923933a84b5e5344fb4bae5d59.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coreo is a platform that enables you to build your own data collection apps, without getting bamboozled by technical… stuff.",
    "category": "Forms & Surveys",
    "title": "Coreo Integration With Cold Email Sales Tool",
    "h1": "Integrate Coreo with SalesBlink",
    "metaDescription": "Integrate Coreo and other Forms & Surveys Tools with SalesBlink. Two Way Coreo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mohimmatech",
    "slug": "mohimmatech",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e7cdfaacbc020f5d3e688328d24508a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mohimmatech is task and project management tool that helps teams organize their work and improve their performance.",
    "category": "Project Management",
    "title": "Mohimmatech Integration With Cold Email Sales Tool",
    "h1": "Integrate Mohimmatech with SalesBlink",
    "metaDescription": "Integrate Mohimmatech and other Project Management Tools with SalesBlink. Two Way Mohimmatech Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tenzo",
    "slug": "tenzo",
    "logo": "https://zapier-images.imgix.net/storage/services/9b52369a8e13e2f8d05a5d3412c981a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tenzo is a data management platform for restaurant and retail. We aggregate all forms of data, and then help you turn it into value",
    "category": "Analytics",
    "title": "Tenzo Integration With Cold Email Sales Tool",
    "h1": "Integrate Tenzo with SalesBlink",
    "metaDescription": "Integrate Tenzo and other Analytics Tools with SalesBlink. Two Way Tenzo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TalkFurther",
    "slug": "talkfurther",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/278d6b2bc7a76ffc701dea603495efaa_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We are a lead engagement and qualification platform that uses automated conversations and actionable insights to help give you the best chance at converting leads to move-ins",
    "category": "CRM (Customer Relationship Management)",
    "title": "TalkFurther Integration With Cold Email Sales Tool",
    "h1": "Integrate TalkFurther with SalesBlink",
    "metaDescription": "Integrate TalkFurther and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way TalkFurther Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "doopoll",
    "slug": "doopoll",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b5575455c7f46aefa9f3c0f51b04480.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "doopoll helps you to engage with the people that you care about, and then impress your colleagues with impactful visual reports. Collect insights, build mailing lists, and share your new insights.",
    "category": "Forms & Surveys",
    "title": "doopoll Integration With Cold Email Sales Tool",
    "h1": "Integrate doopoll with SalesBlink",
    "metaDescription": "Integrate doopoll and other Forms & Surveys Tools with SalesBlink. Two Way doopoll Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bevy Design",
    "slug": "bevy-design",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bfec792595776fd25e6d7970bf08ed30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Enhance website conversion with Bevy Design's interactive overlays. Integrate with ease on Shopify. Choose from pop-ups, slide-ins, & bars.",
    "category": "Forms & Surveys",
    "title": "Bevy Design Integration With Cold Email Sales Tool",
    "h1": "Integrate Bevy Design with SalesBlink",
    "metaDescription": "Integrate Bevy Design and other Forms & Surveys Tools with SalesBlink. Two Way Bevy Design Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omnitrack by VinciWorks",
    "slug": "omnitrack-by-vinciworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/262d1396ce97edf36068fe348a19093c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omnitrack is a workflow and automation app for risk and compliance professionals.",
    "category": "Task Management",
    "title": "Omnitrack by VinciWorks Integration With Cold Email Sales Tool",
    "h1": "Integrate Omnitrack by VinciWorks with SalesBlink",
    "metaDescription": "Integrate Omnitrack by VinciWorks and other Task Management Tools with SalesBlink. Two Way Omnitrack by VinciWorks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SurveySensum",
    "slug": "surveysensum",
    "logo": "https://zapier-images.imgix.net/storage/services/f73b7d7832d564c0367424e0a4aad986.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveySensum offers an end to end Customer Experience Platform that measures and improves your CX metrics with CES, NPS, and CSAT surveys.\n\nIt lets you gather customer feedback at all touchpoints and turn them into actionable insights.\n\nAfter receiving feedback it enables you to prioritize actions to improve products or services and proactively reduce churn and drive growth.\n\nAlso, its Conversation Analysis platform allows you to discover new trends and insights with real-time text and sentiment analysis on qualitative voice of the customer data from sources like Chat bot conversations, app store reviews, social media etc and save hours of manual data processing with automated feedback tagging.",
    "category": "Forms & Surveys",
    "title": "SurveySensum Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveySensum with SalesBlink",
    "metaDescription": "Integrate SurveySensum and other Forms & Surveys Tools with SalesBlink. Two Way SurveySensum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tradable Bits",
    "slug": "tradable-bits",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48d43b533b859594d1be7209e723bb20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tradable Bits reinvents social media marketing by connecting brands with their fans through social media aggregation, engagement campaigns and social e-commerce integration.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tradable Bits Integration With Cold Email Sales Tool",
    "h1": "Integrate Tradable Bits with SalesBlink",
    "metaDescription": "Integrate Tradable Bits and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tradable Bits Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hatch Apps",
    "slug": "hatch-apps",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce5cf989d8d1034008993f3b0bc8b838.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hatch Apps is a no-code platform for teams to build mobile apps, APIs, databases, and more.",
    "category": "App Builder",
    "title": "Hatch Apps Integration With Cold Email Sales Tool",
    "h1": "Integrate Hatch Apps with SalesBlink",
    "metaDescription": "Integrate Hatch Apps and other App Builder Tools with SalesBlink. Two Way Hatch Apps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "docMaker",
    "slug": "docmaker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ba6552577ae174e619aeac5811c64ac_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "docMaker helps you create pixel perfect pdfs from docx templates, pdf forms or webpages.",
    "category": "Documents",
    "title": "docMaker Integration With Cold Email Sales Tool",
    "h1": "Integrate docMaker with SalesBlink",
    "metaDescription": "Integrate docMaker and other Documents Tools with SalesBlink. Two Way docMaker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cayzu",
    "slug": "cayzu",
    "logo": "https://zapier-images.imgix.net/storage/services/87c8337efe445ef58b89f805f54cc88f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cayzu is a cloud based help desk solution that will allow you to wow your customers with world-class support. Features include multi-channel support, ticket management, Facebook & Twitter integration, mobile apps, real-time reporting, instant notifications and ability to manage multiple brands from a single portal.",
    "category": "Customer Support",
    "title": "Cayzu Integration With Cold Email Sales Tool",
    "h1": "Integrate Cayzu with SalesBlink",
    "metaDescription": "Integrate Cayzu and other Customer Support Tools with SalesBlink. Two Way Cayzu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "inwise",
    "slug": "inwise",
    "logo": "https://zapier-images.imgix.net/storage/developer/3c841297b7abad124ee79db9c41e4236.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "inwise is a platform for email marketing & mobile marketing, landing pages and transactional messaging. Since 2002 we are serving thousands of customers around the globe with a powerful and easy to use web based solution.",
    "category": "Marketing Automation",
    "title": "inwise Integration With Cold Email Sales Tool",
    "h1": "Integrate inwise with SalesBlink",
    "metaDescription": "Integrate inwise and other Marketing Automation Tools with SalesBlink. Two Way inwise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Replug",
    "slug": "replug",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/357e74fa2de67f4f8635a8c88b1bb7f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shorten, track and optimize your links with catchy call-to-actions, retargeting pixels, custom domains, and powerful analytics. Get the most return from your links",
    "category": "URL Shortener",
    "title": "Replug Integration With Cold Email Sales Tool",
    "h1": "Integrate Replug with SalesBlink",
    "metaDescription": "Integrate Replug and other URL Shortener Tools with SalesBlink. Two Way Replug Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "iMuv",
    "slug": "imuv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6cf5dc93666c0d843169a047942b9cb7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iMuv is a complete work management solution. iMuv provides all the tools needed to manage the business.",
    "category": "Project Management",
    "title": "iMuv Integration With Cold Email Sales Tool",
    "h1": "Integrate iMuv with SalesBlink",
    "metaDescription": "Integrate iMuv and other Project Management Tools with SalesBlink. Two Way iMuv Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CINCEL",
    "slug": "cincel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ea5cbb075a5a4e15d7f823e788ad26a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CINCEL is the suite of multi-signature, notarization, blockchain and identity with legal compliance for companies in Mexico and LATAM.",
    "category": "Signatures",
    "title": "CINCEL Integration With Cold Email Sales Tool",
    "h1": "Integrate CINCEL with SalesBlink",
    "metaDescription": "Integrate CINCEL and other Signatures Tools with SalesBlink. Two Way CINCEL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myReach",
    "slug": "myreach",
    "logo": "https://zapier-images.imgix.net/storage/services/d463f66abe4be63ad3b14fa10aa088a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myReach is a mobile App that works like your second brain... It keeps all your things (notes, websites, documents, contacts, etc.) at your fingertips, connected & easily reachable.",
    "category": "Productivity",
    "title": "myReach Integration With Cold Email Sales Tool",
    "h1": "Integrate myReach with SalesBlink",
    "metaDescription": "Integrate myReach and other Productivity Tools with SalesBlink. Two Way myReach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ScoreCEO",
    "slug": "scoreceobeta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/780684ebdda84d5a8e66bac0c8a63502.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ScoreCEO is a Credit Repair Business CRM SAAS that helps Credit Repair companies start, run, and grow their business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ScoreCEO Integration With Cold Email Sales Tool",
    "h1": "Integrate ScoreCEO with SalesBlink",
    "metaDescription": "Integrate ScoreCEO and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ScoreCEO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GivePulse",
    "slug": "givepulse",
    "logo": "https://zapier-images.imgix.net/storage/services/43bb01951db90a29a60e74ebfb327be9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GivePulse is a community engagement platform to facilitate all forms of activity like volunteering, fundraising and their coordination efforts.",
    "category": "Fundraising",
    "title": "GivePulse Integration With Cold Email Sales Tool",
    "h1": "Integrate GivePulse with SalesBlink",
    "metaDescription": "Integrate GivePulse and other Fundraising Tools with SalesBlink. Two Way GivePulse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "emBlue",
    "slug": "emblue",
    "logo": "https://zapier-images.imgix.net/storage/services/7c9433c4a452f75ad5bf0a8b02761bdd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "emBlue omnichannel platform. Email + SMS + Webhooks + Widgets hub. Send the right message at the right time.",
    "category": "Email",
    "title": "emBlue Integration With Cold Email Sales Tool",
    "h1": "Integrate emBlue with SalesBlink",
    "metaDescription": "Integrate emBlue and other Email Tools with SalesBlink. Two Way emBlue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UserBit",
    "slug": "userbit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53794149b818544f6c5035ed33548ca0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UserBit is a UX research software platform where UX and product teams can gather, analyze, and share their research workflow.",
    "category": "Product Management",
    "title": "UserBit Integration With Cold Email Sales Tool",
    "h1": "Integrate UserBit with SalesBlink",
    "metaDescription": "Integrate UserBit and other Product Management Tools with SalesBlink. Two Way UserBit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BEEKAI",
    "slug": "beekai",
    "logo": "https://zapier-images.imgix.net/storage/services/03320059719a94ade12387ea91b9af97.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BEEKAI is a next-gen form application which allows the user to control and manage their form application's workflow.",
    "category": "Forms & Surveys",
    "title": "BEEKAI Integration With Cold Email Sales Tool",
    "h1": "Integrate BEEKAI with SalesBlink",
    "metaDescription": "Integrate BEEKAI and other Forms & Surveys Tools with SalesBlink. Two Way BEEKAI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Audome",
    "slug": "audome",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/273327ef61c4d9c3aabcc6cf7ea2280d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Audome is the all-in-one tool for faster audio revisions.",
    "category": "Video & Audio",
    "title": "Audome Integration With Cold Email Sales Tool",
    "h1": "Integrate Audome with SalesBlink",
    "metaDescription": "Integrate Audome and other Video & Audio Tools with SalesBlink. Two Way Audome Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LOOK",
    "slug": "look",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1bf3b9a5891911935dc9dc051635838c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Looks help you manage your screens fast, simple, at any time, from any place. Digital signage software has never been easier before.",
    "category": "Developer Tools",
    "title": "LOOK Integration With Cold Email Sales Tool",
    "h1": "Integrate LOOK with SalesBlink",
    "metaDescription": "Integrate LOOK and other Developer Tools Tools with SalesBlink. Two Way LOOK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flutin",
    "slug": "flutin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/35346199968cad48c5acefe8bc3e789c_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flutin is the best way to monetize and broadcast your real time and pre recorded video live streams to Instagram, Youtbe, Facebook, lnkdn and other platforms. Whether your profession/interest lies with music, dance, comedy, business, fitness, or any other niche in the world.",
    "category": "Video Conferencing",
    "title": "Flutin Integration With Cold Email Sales Tool",
    "h1": "Integrate Flutin with SalesBlink",
    "metaDescription": "Integrate Flutin and other Video Conferencing Tools with SalesBlink. Two Way Flutin Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "red-amber.green",
    "slug": "red-ambergreen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/26d1ab88117b8f9327d1f0165aefc24e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Red amber green is a service that allows you to \"monitor\"  business process health and current status.",
    "category": "Developer Tools",
    "title": "red-amber.green Integration With Cold Email Sales Tool",
    "h1": "Integrate red-amber.green with SalesBlink",
    "metaDescription": "Integrate red-amber.green and other Developer Tools Tools with SalesBlink. Two Way red-amber.green Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AeroLeads",
    "slug": "aeroleads",
    "logo": "https://zapier-images.imgix.net/storage/developer/0a2352f1b3854131877e3b0c743eee52.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AeroLeads is a prospect generation software used by thousands of businesses all over the world including SMBs, startups and Enterprise customers. It find emails and phone numbers of prospects and businesses and is perfect tool to start your sales.",
    "category": "Contact Management",
    "title": "AeroLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate AeroLeads with SalesBlink",
    "metaDescription": "Integrate AeroLeads and other Contact Management Tools with SalesBlink. Two Way AeroLeads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cacoo",
    "slug": "cacoo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/36a4618e030e0b45da9958edf13ada8f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cacoo is a diagraming and collaboration tool for teams to brainstorm, prioritize, and document ideas, from anywhere.",
    "category": "Team Collaboration",
    "title": "Cacoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Cacoo with SalesBlink",
    "metaDescription": "Integrate Cacoo and other Team Collaboration Tools with SalesBlink. Two Way Cacoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "onpreo",
    "slug": "onpreo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eabdc5ac1c99993ba3959c0cfc24f38c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "onpreo is a sales and CRM Software developed for real-estate agents and brokers to organise their leads, contacts and real-estate.",
    "category": "Sales & CRM",
    "title": "onpreo Integration With Cold Email Sales Tool",
    "h1": "Integrate onpreo with SalesBlink",
    "metaDescription": "Integrate onpreo and other Sales & CRM Tools with SalesBlink. Two Way onpreo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Circa",
    "slug": "circa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7638c3ce38463215a3a38a441f99e4c5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The B2B event marketing platform: Virtual, hybrid & in-person",
    "category": "Marketing",
    "title": "Circa Integration With Cold Email Sales Tool",
    "h1": "Integrate Circa with SalesBlink",
    "metaDescription": "Integrate Circa and other Marketing Tools with SalesBlink. Two Way Circa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Export SDK",
    "slug": "export-sdk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b82e0dc5c9fc1b79686a8c1deb20a3cc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Export SDK is an all-in-one platform for designing and rendering PDF files using dynamic data.",
    "category": "Content & Files",
    "title": "Export SDK Integration With Cold Email Sales Tool",
    "h1": "Integrate Export SDK with SalesBlink",
    "metaDescription": "Integrate Export SDK and other Content & Files Tools with SalesBlink. Two Way Export SDK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Billage",
    "slug": "billage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e34b316997e719d976e7293a50d37f41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Billage is the management software for SMEs and freelancers that unifies billing, CRM and task and project management.",
    "category": "Sales & CRM",
    "title": "Billage Integration With Cold Email Sales Tool",
    "h1": "Integrate Billage with SalesBlink",
    "metaDescription": "Integrate Billage and other Sales & CRM Tools with SalesBlink. Two Way Billage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudPDF",
    "slug": "cloudpdf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4bab75454b1aa39a3dddd9654987c0c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An easy to implement PDF viewer for your website.",
    "category": "Documents",
    "title": "CloudPDF Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudPDF with SalesBlink",
    "metaDescription": "Integrate CloudPDF and other Documents Tools with SalesBlink. Two Way CloudPDF Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eyedo",
    "slug": "eyedo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c51508cf84138138b37b0453791b79ff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eyedo is an AI powered FSM platform, that helps organizations maximize operation efficiency and minimize operational costs.",
    "category": "Business Intelligence",
    "title": "eyedo Integration With Cold Email Sales Tool",
    "h1": "Integrate eyedo with SalesBlink",
    "metaDescription": "Integrate eyedo and other Business Intelligence Tools with SalesBlink. Two Way eyedo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SWYKTools",
    "slug": "swyktools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a37d29a6f30d818aa7dd42ec3280d1c4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SWYKTools is the all-in-one software solution for coaches, consultants & course creators.",
    "category": "Productivity",
    "title": "SWYKTools Integration With Cold Email Sales Tool",
    "h1": "Integrate SWYKTools with SalesBlink",
    "metaDescription": "Integrate SWYKTools and other Productivity Tools with SalesBlink. Two Way SWYKTools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pobuca Connect",
    "slug": "pobuca",
    "logo": "https://zapier-images.imgix.net/storage/developer/bc6a3f50665aae095bc653e314ffea16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pobuca Connect is an AI digital assistant that optimizes the way your business stores and communicates with business contacts.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pobuca Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Pobuca Connect with SalesBlink",
    "metaDescription": "Integrate Pobuca Connect and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pobuca Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vism",
    "slug": "vism",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33848c4bf0f53f4aa0ac773a41c4d27a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vism is a project management & collaboration tool for print & signage companies.",
    "category": "Team Collaboration",
    "title": "Vism Integration With Cold Email Sales Tool",
    "h1": "Integrate Vism with SalesBlink",
    "metaDescription": "Integrate Vism and other Team Collaboration Tools with SalesBlink. Two Way Vism Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Likely.AI",
    "slug": "likelyai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ae98279fd543e0743df954d04de959b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Real estate predictions and data refreshes delivered to multiple target systems automatically as well as on demand.",
    "category": "Analytics",
    "title": "Likely.AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Likely.AI with SalesBlink",
    "metaDescription": "Integrate Likely.AI and other Analytics Tools with SalesBlink. Two Way Likely.AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Planday",
    "slug": "planday",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/171d897506cbd4312d79623459e212b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Planday helps you build an employee schedule faster by taking into account staff vacation, availability, payroll costs and more.",
    "category": "Scheduling & Booking",
    "title": "Planday Integration With Cold Email Sales Tool",
    "h1": "Integrate Planday with SalesBlink",
    "metaDescription": "Integrate Planday and other Scheduling & Booking Tools with SalesBlink. Two Way Planday Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gameball",
    "slug": "gameball",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee92f1e8969e6ef8d9e7c11def1c76dd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gameball is a one-stop platform to market your products and keep your customers engaged, leading to boosted conversion and increased ROI. With a wide range of customizable features and powerful insights, Gameball helps you build a data-driven plan.",
    "category": "Marketing",
    "title": "Gameball Integration With Cold Email Sales Tool",
    "h1": "Integrate Gameball with SalesBlink",
    "metaDescription": "Integrate Gameball and other Marketing Tools with SalesBlink. Two Way Gameball Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "al-Qur'an",
    "slug": "al-quran",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/903c6c21979c088d885904f5b72c3e8e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Al Quran Cloud is a simple web app that allows you to read and hear the Quran being recited by Juz, Surah or Ayah. You can also play the whole Quran in the browser. In addition, you can read a variety of translations alongside the Arabic text.",
    "category": "Lifestyle & Entertainment",
    "title": "al-Qur'an Integration With Cold Email Sales Tool",
    "h1": "Integrate al-Qur'an with SalesBlink",
    "metaDescription": "Integrate al-Qur'an and other Lifestyle & Entertainment Tools with SalesBlink. Two Way al-Qur'an Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ebby",
    "slug": "ebby",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bcc657271d760f4091d8dd3107a4a2a9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ebby is an automated transcription platform which accurately transcribes any audio or video. Supporting more than 100 languages and providing feature-rich editing tools to get your transcription or subtitling done quickly and accurately.",
    "category": "Transcription",
    "title": "Ebby Integration With Cold Email Sales Tool",
    "h1": "Integrate Ebby with SalesBlink",
    "metaDescription": "Integrate Ebby and other Transcription Tools with SalesBlink. Two Way Ebby Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unloc",
    "slug": "unloc-integrator-api",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ee1c1149b29a021710023d65ce8c9ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unloc allows managing keys across multiple lock systems.",
    "category": "Security & Identity Tools",
    "title": "Unloc Integration With Cold Email Sales Tool",
    "h1": "Integrate Unloc with SalesBlink",
    "metaDescription": "Integrate Unloc and other Security & Identity Tools Tools with SalesBlink. Two Way Unloc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Roboflow",
    "slug": "roboflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/69da41bfd8fdbad42f072248ccea4f31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Roboflow is an end to end suite of computer vision tools.",
    "category": "AI Tools",
    "title": "Roboflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Roboflow with SalesBlink",
    "metaDescription": "Integrate Roboflow and other AI Tools Tools with SalesBlink. Two Way Roboflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ambassify",
    "slug": "ambassify",
    "logo": "https://zapier-images.imgix.net/storage/services/f5acd8f2302b893a5075d1151deedb8c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ambassify helps you harness the power of positive word-of-mouth. Identify and unite your most valuable advocates and turn them into your social sales force.",
    "category": "Marketing",
    "title": "Ambassify Integration With Cold Email Sales Tool",
    "h1": "Integrate Ambassify with SalesBlink",
    "metaDescription": "Integrate Ambassify and other Marketing Tools with SalesBlink. Two Way Ambassify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Event Cadence",
    "slug": "event-cadence",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d941f7707b7c63541c7d50d2519d967_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Event Cadence is an all-in-one events platform for live, virtual, and hybrid events.",
    "category": "Event Management",
    "title": "Event Cadence Integration With Cold Email Sales Tool",
    "h1": "Integrate Event Cadence with SalesBlink",
    "metaDescription": "Integrate Event Cadence and other Event Management Tools with SalesBlink. Two Way Event Cadence Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outfy",
    "slug": "outfy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f8cde91fd2a749bc66971785d275032.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outfy is a social media tool which helps online sellers promote their products on social networks. Outfy provides powerful tools to create engaging content like Collage, Animated Gifs and VIDEOS from the user’s product images.",
    "category": "Social Media Marketing",
    "title": "Outfy Integration With Cold Email Sales Tool",
    "h1": "Integrate Outfy with SalesBlink",
    "metaDescription": "Integrate Outfy and other Social Media Marketing Tools with SalesBlink. Two Way Outfy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SigningHub",
    "slug": "signinghub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3156e9681636c91c9dddd77749347f3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SigningHub is an e-signature service with a powerful workflow engine that boosts team productivity by automating repetitive document and sign-off processes.",
    "category": "Signatures",
    "title": "SigningHub Integration With Cold Email Sales Tool",
    "h1": "Integrate SigningHub with SalesBlink",
    "metaDescription": "Integrate SigningHub and other Signatures Tools with SalesBlink. Two Way SigningHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sametrica Forms",
    "slug": "sametrica-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc9855d294ea926695c51a6393bff787.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sametrica forms is a tool that allows users to connect their data to existing forms in Sametrica",
    "category": "Forms & Surveys",
    "title": "Sametrica Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Sametrica Forms with SalesBlink",
    "metaDescription": "Integrate Sametrica Forms and other Forms & Surveys Tools with SalesBlink. Two Way Sametrica Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AI-FM",
    "slug": "ai-fm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4cb5ba8a9928b28d034412a509c35ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AI FM is a B2B company based on a new concept, namely “Intelligence as a Service” (IaaS) on demand",
    "category": "Productivity",
    "title": "AI-FM Integration With Cold Email Sales Tool",
    "h1": "Integrate AI-FM with SalesBlink",
    "metaDescription": "Integrate AI-FM and other Productivity Tools with SalesBlink. Two Way AI-FM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sugar Calendar",
    "slug": "sugar-calendar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea30f6f3cb07a7cab4c9ac36fe52fa20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sugar Calendar is a sweet, simple event calendar for WordPress",
    "category": "Event Management",
    "title": "Sugar Calendar Integration With Cold Email Sales Tool",
    "h1": "Integrate Sugar Calendar with SalesBlink",
    "metaDescription": "Integrate Sugar Calendar and other Event Management Tools with SalesBlink. Two Way Sugar Calendar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Utobo",
    "slug": "utobo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ac36d7954815938d3e9b2ce63ab16784.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Utobo is a platform that offers more than a learning management system; it has all the tools you need to create and manage content, teach online, and sell to a global audience.",
    "category": "Education",
    "title": "Utobo Integration With Cold Email Sales Tool",
    "h1": "Integrate Utobo with SalesBlink",
    "metaDescription": "Integrate Utobo and other Education Tools with SalesBlink. Two Way Utobo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pentroy",
    "slug": "pentroy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/48f918f338afc8364ce18931072e01b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reach each of your customers with postcards and handwritten letters in automated flows.",
    "category": "Marketing",
    "title": "Pentroy Integration With Cold Email Sales Tool",
    "h1": "Integrate Pentroy with SalesBlink",
    "metaDescription": "Integrate Pentroy and other Marketing Tools with SalesBlink. Two Way Pentroy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hull",
    "slug": "hull",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfaa75e3793efed679e7097ff88bbd41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hull is a customer data platform that unifies your customer data in a single place and sends that data to all of your connected tools.",
    "category": "Marketing Automation",
    "title": "Hull Integration With Cold Email Sales Tool",
    "h1": "Integrate Hull with SalesBlink",
    "metaDescription": "Integrate Hull and other Marketing Automation Tools with SalesBlink. Two Way Hull Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Neato",
    "slug": "neato",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/164ff8502369cce01f51f80af232783a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Neato helps improve everyday living through robotics.",
    "category": "Devices",
    "title": "Neato Integration With Cold Email Sales Tool",
    "h1": "Integrate Neato with SalesBlink",
    "metaDescription": "Integrate Neato and other Devices Tools with SalesBlink. Two Way Neato Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mifiel",
    "slug": "mifiel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ad6a966a63daa018cff22b35a9d9841.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mifiel is a tool that allows you to sign contracts electronically using the FIEL (e.firma) of SAT to achieve greater legal validity than with any other type of signature in Mexico.",
    "category": "Signatures",
    "title": "Mifiel Integration With Cold Email Sales Tool",
    "h1": "Integrate Mifiel with SalesBlink",
    "metaDescription": "Integrate Mifiel and other Signatures Tools with SalesBlink. Two Way Mifiel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Acadify",
    "slug": "acadify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf3a7d04dbc1efc134e57369fd5247db_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Acadify is an online learning platform where academies can register courses and initialize live sessions, while students can register for courses and/or live training sessions.",
    "category": "Education",
    "title": "Acadify Integration With Cold Email Sales Tool",
    "h1": "Integrate Acadify with SalesBlink",
    "metaDescription": "Integrate Acadify and other Education Tools with SalesBlink. Two Way Acadify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phrase",
    "slug": "phrase",
    "logo": "https://zapier-images.imgix.net/storage/services/d47271b0e857c77241d38d95b416a37f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Phrase is a collaborative translation management and automation platform.",
    "category": "Developer Tools",
    "title": "Phrase Integration With Cold Email Sales Tool",
    "h1": "Integrate Phrase with SalesBlink",
    "metaDescription": "Integrate Phrase and other Developer Tools Tools with SalesBlink. Two Way Phrase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MuxEmail",
    "slug": "muxemail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be2961c61e8a90738f94c9bb712f7a26.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MuxEmail allows you to send unlimited email newsletters and marketing campaigns to unlimited subscribers via Amazon SES.",
    "category": "Email Newsletters",
    "title": "MuxEmail Integration With Cold Email Sales Tool",
    "h1": "Integrate MuxEmail with SalesBlink",
    "metaDescription": "Integrate MuxEmail and other Email Newsletters Tools with SalesBlink. Two Way MuxEmail Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Skhokho",
    "slug": "skhokho",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9baf7664b260c7197e2b9d73344f0ac9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Skhokho Business Management Software, HRMS, Sales and CRM, OKR, Accounting Software, Project Management Software, Meeting Software, Notes Software",
    "category": "CRM (Customer Relationship Management)",
    "title": "Skhokho Integration With Cold Email Sales Tool",
    "h1": "Integrate Skhokho with SalesBlink",
    "metaDescription": "Integrate Skhokho and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Skhokho Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plerdy",
    "slug": "plerdy",
    "logo": "https://zapier-images.imgix.net/storage/services/1b0854cfe27c640a197df5a1107ca836.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use pop-ups with 25+ conditions and capabilities to collect emails, subscriptions, and more!",
    "category": "Sales & CRM",
    "title": "Plerdy Integration With Cold Email Sales Tool",
    "h1": "Integrate Plerdy with SalesBlink",
    "metaDescription": "Integrate Plerdy and other Sales & CRM Tools with SalesBlink. Two Way Plerdy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MetaLocator",
    "slug": "metalocator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b8080a4237de6ae9ec29eea266401883.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MetaLocator lets you create a store locator, dealer locator or searchable database and integrate it easily with your Website.",
    "category": "Marketing",
    "title": "MetaLocator Integration With Cold Email Sales Tool",
    "h1": "Integrate MetaLocator with SalesBlink",
    "metaDescription": "Integrate MetaLocator and other Marketing Tools with SalesBlink. Two Way MetaLocator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HeyForm",
    "slug": "heyform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c60191967ae9539f067b276b4d367a79.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Serverless Online Form Builder & Data Management Platform",
    "category": "Forms & Surveys",
    "title": "HeyForm Integration With Cold Email Sales Tool",
    "h1": "Integrate HeyForm with SalesBlink",
    "metaDescription": "Integrate HeyForm and other Forms & Surveys Tools with SalesBlink. Two Way HeyForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cronycle",
    "slug": "cronycle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd322448db1da3cd68539bd94645917f_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cronycle is an all-in-one market & content research platform for insight discovery and intelligence development, individually or as a team.",
    "category": "Marketing",
    "title": "Cronycle Integration With Cold Email Sales Tool",
    "h1": "Integrate Cronycle with SalesBlink",
    "metaDescription": "Integrate Cronycle and other Marketing Tools with SalesBlink. Two Way Cronycle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "4.events",
    "slug": "4events",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a20a8abaca6db43a374119c4bd31f9ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "4.events is an online platform to manage phisical, online and hybrid events.",
    "category": "Developer Tools",
    "title": "4.events Integration With Cold Email Sales Tool",
    "h1": "Integrate 4.events with SalesBlink",
    "metaDescription": "Integrate 4.events and other Developer Tools Tools with SalesBlink. Two Way 4.events Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialSprinters",
    "slug": "socialsprinters",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a0013bd2db994e5d2e7e482a6ce1bc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Powerful Set of Marketing Tools Designed for Websites and E-commerce",
    "category": "App Builder",
    "title": "SocialSprinters Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialSprinters with SalesBlink",
    "metaDescription": "Integrate SocialSprinters and other App Builder Tools with SalesBlink. Two Way SocialSprinters Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LevelUp Tools",
    "slug": "levelup",
    "logo": "https://zapier-images.imgix.net/storage/services/7dd1bdd963acc6331c326a5dc7679e70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LevelUp offers flexible tools for Skip Tracing Property, Business and Contact info.",
    "category": "Contact Management",
    "title": "LevelUp Tools Integration With Cold Email Sales Tool",
    "h1": "Integrate LevelUp Tools with SalesBlink",
    "metaDescription": "Integrate LevelUp Tools and other Contact Management Tools with SalesBlink. Two Way LevelUp Tools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SigParser",
    "slug": "sigparser",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/031de976f0302939343c3c973e105aa0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SigParser captures contact details from everyday email communication and match them to all your apps.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SigParser Integration With Cold Email Sales Tool",
    "h1": "Integrate SigParser with SalesBlink",
    "metaDescription": "Integrate SigParser and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SigParser Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Kanban Zone",
    "slug": "kanban-zone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/333a5998432f60d733f369c0bc4927df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get into the Kanban Zone to collaborate online using Kanban with a laser focus on the right work at the right time.",
    "category": "Project Management",
    "title": "Kanban Zone Integration With Cold Email Sales Tool",
    "h1": "Integrate Kanban Zone with SalesBlink",
    "metaDescription": "Integrate Kanban Zone and other Project Management Tools with SalesBlink. Two Way Kanban Zone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deutsche Bureau AG",
    "slug": "dbureau",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b4bcc5773d85094da1f16a8ca98657c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deutsche Bureau AG is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business.",
    "category": "Communication",
    "title": "Deutsche Bureau AG Integration With Cold Email Sales Tool",
    "h1": "Integrate Deutsche Bureau AG with SalesBlink",
    "metaDescription": "Integrate Deutsche Bureau AG and other Communication Tools with SalesBlink. Two Way Deutsche Bureau AG Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RushAnswer",
    "slug": "rushanswer",
    "logo": "https://zapier-images.imgix.net/storage/services/79e1d919e556696f3962c60f2d08acf7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RushAnswer enables user to connect to answer io notifications services.",
    "category": "Notifications",
    "title": "RushAnswer Integration With Cold Email Sales Tool",
    "h1": "Integrate RushAnswer with SalesBlink",
    "metaDescription": "Integrate RushAnswer and other Notifications Tools with SalesBlink. Two Way RushAnswer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Monitask",
    "slug": "monitask",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7dbaa3e32d85540afccba287317a33f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monitask allows employees and freelancers to manually start a clock when they begin working on an assignment for employers.",
    "category": "Time Tracking Software",
    "title": "Monitask Integration With Cold Email Sales Tool",
    "h1": "Integrate Monitask with SalesBlink",
    "metaDescription": "Integrate Monitask and other Time Tracking Software Tools with SalesBlink. Two Way Monitask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Finolog",
    "slug": "finolog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1c0aaf9dec97bacdd5a1db2e722d111_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Finolog is a money accounting app for small and medium businesses. It shows all money flows in one safe place, predicts cash gaps and much more.",
    "category": "Accounting",
    "title": "Finolog Integration With Cold Email Sales Tool",
    "h1": "Integrate Finolog with SalesBlink",
    "metaDescription": "Integrate Finolog and other Accounting Tools with SalesBlink. Two Way Finolog Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SendSquared",
    "slug": "sendsquared",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e43b6434cadaeb2104a2769110c3b05a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SendSquared is a simple, design focused email & SMS marketing platform.",
    "category": "Email Newsletters",
    "title": "SendSquared Integration With Cold Email Sales Tool",
    "h1": "Integrate SendSquared with SalesBlink",
    "metaDescription": "Integrate SendSquared and other Email Newsletters Tools with SalesBlink. Two Way SendSquared Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iList by GryphTech",
    "slug": "ilist-by-gryphtech",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2a930f916a2d9ce0c2a1ba2cdf91532_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iList is an enterprise listing and CRM platform for international real estate.",
    "category": "CRM (Customer Relationship Management)",
    "title": "iList by GryphTech Integration With Cold Email Sales Tool",
    "h1": "Integrate iList by GryphTech with SalesBlink",
    "metaDescription": "Integrate iList by GryphTech and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way iList by GryphTech Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadBoxer",
    "slug": "leadboxer",
    "logo": "https://zapier-images.imgix.net/storage/developer/794cd6117233d7734f059d70f7ac1470.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate B2B sales leads from your online data.  \nWe analyse your online data in realtime and show you the businesses who visited your site. Score, rank, filter your leads and contact them.",
    "category": "Forms & Surveys",
    "title": "LeadBoxer Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadBoxer with SalesBlink",
    "metaDescription": "Integrate LeadBoxer and other Forms & Surveys Tools with SalesBlink. Two Way LeadBoxer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BugShot",
    "slug": "bugshot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4060bf8334888044c881fbffe408cec9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BugShot is an innovative tool to track and erase bugs in any kind of software.",
    "category": "Developer Tools",
    "title": "BugShot Integration With Cold Email Sales Tool",
    "h1": "Integrate BugShot with SalesBlink",
    "metaDescription": "Integrate BugShot and other Developer Tools Tools with SalesBlink. Two Way BugShot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Radio.co",
    "slug": "radioco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6c9ae7b2179a6fd89c5ea38146b1281.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create your own radio station with Radio.co. Automate your schedule, broadcast live, and track listeners in no time.",
    "category": "Video & Audio",
    "title": "Radio.co Integration With Cold Email Sales Tool",
    "h1": "Integrate Radio.co with SalesBlink",
    "metaDescription": "Integrate Radio.co and other Video & Audio Tools with SalesBlink. Two Way Radio.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fliplet",
    "slug": "fliplet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f92b9edfa8a5bc6439b7ec57e20e9c11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fliplet empowers businesses to operate multiple apps without a large development team. Avoid the development delays, infrequent updates and spiralling maintenance costs that kill good app ideas.",
    "category": "App Builder",
    "title": "Fliplet Integration With Cold Email Sales Tool",
    "h1": "Integrate Fliplet with SalesBlink",
    "metaDescription": "Integrate Fliplet and other App Builder Tools with SalesBlink. Two Way Fliplet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "URLR",
    "slug": "urlr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/13a1d57671cf989808174db9fbabd400.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "URLR is a reliable and innovative link shortener. Our data is hosted in France and our solution is RGPD compliant.",
    "category": "URL Shortener",
    "title": "URLR Integration With Cold Email Sales Tool",
    "h1": "Integrate URLR with SalesBlink",
    "metaDescription": "Integrate URLR and other URL Shortener Tools with SalesBlink. Two Way URLR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Axelor",
    "slug": "axelor",
    "logo": "https://zapier-images.imgix.net/storage/services/14bcbba1b850473b10b57fcd7b46b7b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Axelor  is a service provider that manage online accounting and product sale.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Axelor Integration With Cold Email Sales Tool",
    "h1": "Integrate Axelor with SalesBlink",
    "metaDescription": "Integrate Axelor and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Axelor Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Acquire",
    "slug": "acquire-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf922b7c43e53842bee08681ae2b4ceb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Acquire is a customer communication platform. We help users bridge gaps between customers and sales and support teams to empower all involved.",
    "category": "Communication",
    "title": "Acquire Integration With Cold Email Sales Tool",
    "h1": "Integrate Acquire with SalesBlink",
    "metaDescription": "Integrate Acquire and other Communication Tools with SalesBlink. Two Way Acquire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snappr",
    "slug": "snappr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4354af4b9261d2bbb5d7a6ce895257af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snappr offers affordable, on-demand professional photography services.",
    "category": "Content & Files",
    "title": "Snappr Integration With Cold Email Sales Tool",
    "h1": "Integrate Snappr with SalesBlink",
    "metaDescription": "Integrate Snappr and other Content & Files Tools with SalesBlink. Two Way Snappr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Telagus",
    "slug": "telagus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2fb5e1886c2533239fba3ae8ed827363.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Telagus is a bespoke CRM system that puts lead generation first.",
    "category": "Marketing",
    "title": "Telagus Integration With Cold Email Sales Tool",
    "h1": "Integrate Telagus with SalesBlink",
    "metaDescription": "Integrate Telagus and other Marketing Tools with SalesBlink. Two Way Telagus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Web Console",
    "slug": "the-web-console",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42b9a084d4336e780c1db30128ce5639.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Web Console is a single toolbox for businesses to manage all their online marketing, including their website, database marketing and their CRM.",
    "category": "Website Builders",
    "title": "The Web Console Integration With Cold Email Sales Tool",
    "h1": "Integrate The Web Console with SalesBlink",
    "metaDescription": "Integrate The Web Console and other Website Builders Tools with SalesBlink. Two Way The Web Console Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xobin",
    "slug": "xobin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e2d70e5ac3a14c32fdb8406306a5fd48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xobin is a skill evaluation and testing platform to build winning teams. Xobin has the largest collection white collar Job Assessments including Coding Tests, Skill Tests and Psychometric Tests",
    "category": "HR Talent & Recruitment",
    "title": "Xobin Integration With Cold Email Sales Tool",
    "h1": "Integrate Xobin with SalesBlink",
    "metaDescription": "Integrate Xobin and other HR Talent & Recruitment Tools with SalesBlink. Two Way Xobin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "api.video",
    "slug": "apivideo",
    "logo": "https://zapier-images.imgix.net/storage/services/f42c7fe9cc9dbc718c769b74e8e107c3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "API to encode on the go to facilitate immediate playback, enhancing viewer experiences across multiple devices and platforms.",
    "category": "Video & Audio",
    "title": "api.video Integration With Cold Email Sales Tool",
    "h1": "Integrate api.video with SalesBlink",
    "metaDescription": "Integrate api.video and other Video & Audio Tools with SalesBlink. Two Way api.video Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Email Verify",
    "slug": "email-verify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ffc378fe64068e91e540d0a815356b8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Email Verify helps identify email authenticity and tells if it is real, fake or disposable email address",
    "category": "Email",
    "title": "Email Verify Integration With Cold Email Sales Tool",
    "h1": "Integrate Email Verify with SalesBlink",
    "metaDescription": "Integrate Email Verify and other Email Tools with SalesBlink. Two Way Email Verify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Airteach",
    "slug": "airteach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/09a116d8a92b692aed5ddc8cdbd05beb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Airteach allows you to create, host and sell online courses without any technical knowledge, on your own training academy.",
    "category": "Online Courses",
    "title": "Airteach Integration With Cold Email Sales Tool",
    "h1": "Integrate Airteach with SalesBlink",
    "metaDescription": "Integrate Airteach and other Online Courses Tools with SalesBlink. Two Way Airteach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anduin Fund Subscription",
    "slug": "anduin-fund-subscription-ca12562",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9885a6de7f563ab385d414dd6703bda9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anduin Fund Subscription lets you track investor engagement through actionable insights and streamline the subscription process from start to finish",
    "category": "Fundraising",
    "title": "Anduin Fund Subscription Integration With Cold Email Sales Tool",
    "h1": "Integrate Anduin Fund Subscription with SalesBlink",
    "metaDescription": "Integrate Anduin Fund Subscription and other Fundraising Tools with SalesBlink. Two Way Anduin Fund Subscription Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Consent Kit",
    "slug": "consent-kit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b527dd109907e3ea00ded55cadd8edf4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Consent Kit is an all-in-one research governance platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Consent Kit Integration With Cold Email Sales Tool",
    "h1": "Integrate Consent Kit with SalesBlink",
    "metaDescription": "Integrate Consent Kit and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Consent Kit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iSendPro SMS",
    "slug": "isendpro-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/33dbf9eabe256f0c6e239d2f1947a714.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send SMS easily using yout isendpro telecom account",
    "category": "Phone & SMS",
    "title": "iSendPro SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate iSendPro SMS with SalesBlink",
    "metaDescription": "Integrate iSendPro SMS and other Phone & SMS Tools with SalesBlink. Two Way iSendPro SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ediware",
    "slug": "ediware",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f46fb622c9ed2df516630c311e9cbdf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ediware is an email and SMS marketing platform. This integration enables users to pull and retrieve data and behaviors with their CRM.",
    "category": "Email Newsletters",
    "title": "Ediware Integration With Cold Email Sales Tool",
    "h1": "Integrate Ediware with SalesBlink",
    "metaDescription": "Integrate Ediware and other Email Newsletters Tools with SalesBlink. Two Way Ediware Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Counter.app",
    "slug": "counterapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/14c1103ac00d14eca7f3b01bf86ad274_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Counter.app takes care of automating few integrations of your hostel",
    "category": "Dashboards",
    "title": "Counter.app Integration With Cold Email Sales Tool",
    "h1": "Integrate Counter.app with SalesBlink",
    "metaDescription": "Integrate Counter.app and other Dashboards Tools with SalesBlink. Two Way Counter.app Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Placker",
    "slug": "placker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1a14368a66b9c20cfaa82e4d03f98a65.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Placker provides planning and tracking tools to save time, avoid mistakes and get work done.",
    "category": "Project Management",
    "title": "Placker Integration With Cold Email Sales Tool",
    "h1": "Integrate Placker with SalesBlink",
    "metaDescription": "Integrate Placker and other Project Management Tools with SalesBlink. Two Way Placker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignOnSite",
    "slug": "signonsite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/784364eed8630e5456312f2ffe3568b3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignOnSite is an in-field connected worker platform for construction.",
    "category": "Project Management",
    "title": "SignOnSite Integration With Cold Email Sales Tool",
    "h1": "Integrate SignOnSite with SalesBlink",
    "metaDescription": "Integrate SignOnSite and other Project Management Tools with SalesBlink. Two Way SignOnSite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SurveyNuts",
    "slug": "surveynuts",
    "logo": "https://zapier-images.imgix.net/storage/developer/8c96fb5b9803f4bc0c769f46e7d8f9ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveyNuts is an online survey software. Create surveys, forms and quizzes quickly, share them and analyze the responses.",
    "category": "Forms & Surveys",
    "title": "SurveyNuts Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveyNuts with SalesBlink",
    "metaDescription": "Integrate SurveyNuts and other Forms & Surveys Tools with SalesBlink. Two Way SurveyNuts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ExactBuyer Contact Enrichment",
    "slug": "exactbuyer-contact-enrichment-ca",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1d486781425f8f55dd5e21f461fe646.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ExactBuyer allows you to enrich your contacts with over 75+ unique fields. Enrich sign ups to improve your segmentation, lifecycle emails, and lead conversion rates",
    "category": "Business Intelligence",
    "title": "ExactBuyer Contact Enrichment Integration With Cold Email Sales Tool",
    "h1": "Integrate ExactBuyer Contact Enrichment with SalesBlink",
    "metaDescription": "Integrate ExactBuyer Contact Enrichment and other Business Intelligence Tools with SalesBlink. Two Way ExactBuyer Contact Enrichment Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simian",
    "slug": "simian",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/22f6b9c9e36754857865a8efc85b8e67.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simian is a video sharing and collaboration service that provides a way to share, review and present video assets.",
    "category": "Content & Files",
    "title": "Simian Integration With Cold Email Sales Tool",
    "h1": "Integrate Simian with SalesBlink",
    "metaDescription": "Integrate Simian and other Content & Files Tools with SalesBlink. Two Way Simian Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Afleet",
    "slug": "afleet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b729198c3760156dc8824c864101070.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Afleet helps you grow your business through your existing customers.",
    "category": "Marketing Automation",
    "title": "Afleet Integration With Cold Email Sales Tool",
    "h1": "Integrate Afleet with SalesBlink",
    "metaDescription": "Integrate Afleet and other Marketing Automation Tools with SalesBlink. Two Way Afleet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "lc.cx",
    "slug": "lccx",
    "logo": "https://zapier-images.imgix.net/storage/services/9ff14b14339f078e9a0c2d684204a65b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "lc.cx is a URL shortener service wich allows users to shorten URLs with our domain or with their custom brand domains.",
    "category": "URL Shortener",
    "title": "lc.cx Integration With Cold Email Sales Tool",
    "h1": "Integrate lc.cx with SalesBlink",
    "metaDescription": "Integrate lc.cx and other URL Shortener Tools with SalesBlink. Two Way lc.cx Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "WMS-Lite",
    "slug": "wms-lite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c0a15c8be815c63a04198a3732c41e26.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WMS-Lite is a Warehouse Management System to manage the inventory - Available for Logistics, 3PL companies, Industry and fulfillement center",
    "category": "Task Management",
    "title": "WMS-Lite Integration With Cold Email Sales Tool",
    "h1": "Integrate WMS-Lite with SalesBlink",
    "metaDescription": "Integrate WMS-Lite and other Task Management Tools with SalesBlink. Two Way WMS-Lite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vouchery.io",
    "slug": "voucheryio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/45ac4b782e61a2386ec487d6da408511.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vouchery is an all-in-one promotional suite to help you orchestrate & deliver the right incentive at every stage of the customer lifecycle.",
    "category": "Marketing Automation",
    "title": "Vouchery.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Vouchery.io with SalesBlink",
    "metaDescription": "Integrate Vouchery.io and other Marketing Automation Tools with SalesBlink. Two Way Vouchery.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "7Targets",
    "slug": "7targets",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b87659a70049355104e6312ef9f664ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "7Targets is an AI-based Sales Assistant who helps nurture leads and get you meetings.",
    "category": "CRM (Customer Relationship Management)",
    "title": "7Targets Integration With Cold Email Sales Tool",
    "h1": "Integrate 7Targets with SalesBlink",
    "metaDescription": "Integrate 7Targets and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 7Targets Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hello Customer",
    "slug": "hello-customer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/614d87056e5c716b685486d47f260222_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hello Customer gathers all your customer feedback across platforms, runs it through our AI language engine, and shows you where to take action to be a market leader.",
    "category": "Forms & Surveys",
    "title": "Hello Customer Integration With Cold Email Sales Tool",
    "h1": "Integrate Hello Customer with SalesBlink",
    "metaDescription": "Integrate Hello Customer and other Forms & Surveys Tools with SalesBlink. Two Way Hello Customer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wisp",
    "slug": "wisp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02e659d58f13fdf65a0b604c1fa92c66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wisp lets you create a personalized feed of notifications for all your e-commerce visitors.",
    "category": "Marketing",
    "title": "Wisp Integration With Cold Email Sales Tool",
    "h1": "Integrate Wisp with SalesBlink",
    "metaDescription": "Integrate Wisp and other Marketing Tools with SalesBlink. Two Way Wisp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Retamo",
    "slug": "retamo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/580c2be5a78351c163760947e9d29555.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Retamo is an online review and reputation management software designed to help businesses generate, monitor, and analyze customer ratings or feedback.",
    "category": "Reviews",
    "title": "Retamo Integration With Cold Email Sales Tool",
    "h1": "Integrate Retamo with SalesBlink",
    "metaDescription": "Integrate Retamo and other Reviews Tools with SalesBlink. Two Way Retamo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "to.co",
    "slug": "toco",
    "logo": "https://zapier-images.imgix.net/storage/services/baf9ccad3953ad3902bb14368ca8ac34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "to.co is the home of official information for every company on the planet",
    "category": "Marketing Automation",
    "title": "to.co Integration With Cold Email Sales Tool",
    "h1": "Integrate to.co with SalesBlink",
    "metaDescription": "Integrate to.co and other Marketing Automation Tools with SalesBlink. Two Way to.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Specter",
    "slug": "specter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2fffd08f919cb5cc3d6a19368d04a6af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Best Alternative Data on the Market. Specter empowers next generation businesses with data-driven insights.",
    "category": "Databases",
    "title": "Specter Integration With Cold Email Sales Tool",
    "h1": "Integrate Specter with SalesBlink",
    "metaDescription": "Integrate Specter and other Databases Tools with SalesBlink. Two Way Specter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digs",
    "slug": "digs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8328d4b264e14421970e88b7926bb7bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digs provides branded financial products for real estate professionals that help capture, qualify and retain prospects.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Digs Integration With Cold Email Sales Tool",
    "h1": "Integrate Digs with SalesBlink",
    "metaDescription": "Integrate Digs and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Digs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Goyzer",
    "slug": "goyzer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0fa2332d14a24e7e58f245d51d5aa69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Goyzer Test Real estate management system is designed to help streamline business processes for the real estate sector primarily for Developers, Brokers and Property Managers in a single platform",
    "category": "Sales & CRM",
    "title": "Goyzer Integration With Cold Email Sales Tool",
    "h1": "Integrate Goyzer with SalesBlink",
    "metaDescription": "Integrate Goyzer and other Sales & CRM Tools with SalesBlink. Two Way Goyzer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Order Tagger by Shop Circle",
    "slug": "order-tagger-auto-tags-flow-ca16",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3af2ae8b348ccc095afe08834cf7fdd8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Order Tagger ‑ Auto Tags Flow by Shop Circle allows you to create automated workflows to add tags based on criteria you specify.",
    "category": "eCommerce",
    "title": "Order Tagger by Shop Circle Integration With Cold Email Sales Tool",
    "h1": "Integrate Order Tagger by Shop Circle with SalesBlink",
    "metaDescription": "Integrate Order Tagger by Shop Circle and other eCommerce Tools with SalesBlink. Two Way Order Tagger by Shop Circle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Solve Data",
    "slug": "solve-data",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/921c0ea892ab85fdfef61ef9751c9af0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Solve Data is an in-house data management platform.",
    "category": "Databases",
    "title": "Solve Data Integration With Cold Email Sales Tool",
    "h1": "Integrate Solve Data with SalesBlink",
    "metaDescription": "Integrate Solve Data and other Databases Tools with SalesBlink. Two Way Solve Data Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Texta",
    "slug": "textaai",
    "logo": "https://zapier-images.imgix.net/storage/services/a8a8d7b2a7c48adad726769f4513e874.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Texta is a collaborative writing tool that allows users to generate, edit, and post text for social media platforms.",
    "category": "Marketing Automation",
    "title": "Texta Integration With Cold Email Sales Tool",
    "h1": "Integrate Texta with SalesBlink",
    "metaDescription": "Integrate Texta and other Marketing Automation Tools with SalesBlink. Two Way Texta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trackado",
    "slug": "trackado",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c13cfb2cda5ad60550daf06fe78d981d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trackado is an easy-to-use contract management platform allowing you to sign, store and monitor your contracts.",
    "category": "Documents",
    "title": "Trackado Integration With Cold Email Sales Tool",
    "h1": "Integrate Trackado with SalesBlink",
    "metaDescription": "Integrate Trackado and other Documents Tools with SalesBlink. Two Way Trackado Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loket.com",
    "slug": "loketcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/69154e6fbf12b480fa179319b2cda84b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loket is a self service event management platform, create, manage and sell your ticket event by yourself.",
    "category": "Event Management",
    "title": "Loket.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Loket.com with SalesBlink",
    "metaDescription": "Integrate Loket.com and other Event Management Tools with SalesBlink. Two Way Loket.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Barbuck",
    "slug": "barbuck-talkingforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2e66c322e297a1fd1895c4f155de03b2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Barbuck's TalkingForms is a way of automating phone calls to leads via a webform or an application.",
    "category": "Forms & Surveys",
    "title": "Barbuck Integration With Cold Email Sales Tool",
    "h1": "Integrate Barbuck with SalesBlink",
    "metaDescription": "Integrate Barbuck and other Forms & Surveys Tools with SalesBlink. Two Way Barbuck Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "canibuild",
    "slug": "canibuild",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f9fb3085311d3826b7c558c653a7281.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canibuild AI engine allows home, pool, granny flat & other builders to instantly check site feasibility, site costs, generate site plans & produce quotes with just a click.",
    "category": "Sales & CRM",
    "title": "canibuild Integration With Cold Email Sales Tool",
    "h1": "Integrate canibuild with SalesBlink",
    "metaDescription": "Integrate canibuild and other Sales & CRM Tools with SalesBlink. Two Way canibuild Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ManyReach",
    "slug": "manyreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68a6d1c32f7ed3a2515e1b01a87ef099.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cold outreach platform for agencies and sales teams",
    "category": "Drip Emails",
    "title": "ManyReach Integration With Cold Email Sales Tool",
    "h1": "Integrate ManyReach with SalesBlink",
    "metaDescription": "Integrate ManyReach and other Drip Emails Tools with SalesBlink. Two Way ManyReach Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Joblogic",
    "slug": "joblogic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/80cde922006317fcb22b1ff7e449c40a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Joblogic is a field service management software to connect your field staff to the back-office and manage all individual workflows simultaneously.",
    "category": "Sales & CRM",
    "title": "Joblogic Integration With Cold Email Sales Tool",
    "h1": "Integrate Joblogic with SalesBlink",
    "metaDescription": "Integrate Joblogic and other Sales & CRM Tools with SalesBlink. Two Way Joblogic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PaySolution Asia",
    "slug": "paysolution-asia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54d603e97a90860412681938aaec5a7e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online Payment Service Provider, The First of Thailand.",
    "category": "Payment Processing",
    "title": "PaySolution Asia Integration With Cold Email Sales Tool",
    "h1": "Integrate PaySolution Asia with SalesBlink",
    "metaDescription": "Integrate PaySolution Asia and other Payment Processing Tools with SalesBlink. Two Way PaySolution Asia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Underdog Protocol",
    "slug": "underdog-protocol",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d954f78eca36073d4d31947cdd1795b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Underdog Protocol helps you integrate web3 into your product or workflow",
    "category": "App Builder",
    "title": "Underdog Protocol Integration With Cold Email Sales Tool",
    "h1": "Integrate Underdog Protocol with SalesBlink",
    "metaDescription": "Integrate Underdog Protocol and other App Builder Tools with SalesBlink. Two Way Underdog Protocol Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hugging Face",
    "slug": "hugging-face",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfa649417b1d011234a2363e0251a164.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Hugging Face to make predictions on over 100,000 ML models",
    "category": "AI Tools",
    "title": "Hugging Face Integration With Cold Email Sales Tool",
    "h1": "Integrate Hugging Face with SalesBlink",
    "metaDescription": "Integrate Hugging Face and other AI Tools Tools with SalesBlink. Two Way Hugging Face Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Cloudsmith",
    "slug": "cloudsmith",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/402d1a9c9b42734b107446b37183074c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudsmith is the preferred software platform for securely storing and sharing packages and containers.",
    "category": "Developer Tools",
    "title": "Cloudsmith Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudsmith with SalesBlink",
    "metaDescription": "Integrate Cloudsmith and other Developer Tools Tools with SalesBlink. Two Way Cloudsmith Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apparound",
    "slug": "apparound",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11a540b2937cba0d35f044a36e95af2b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apparound is an app to digitise sales and improve sales productivity. Combines content and quoting together.",
    "category": "Proposal & Invoice Management",
    "title": "Apparound Integration With Cold Email Sales Tool",
    "h1": "Integrate Apparound with SalesBlink",
    "metaDescription": "Integrate Apparound and other Proposal & Invoice Management Tools with SalesBlink. Two Way Apparound Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Show",
    "slug": "zoho-show",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1dac6566955d148b601382b9c1e9827a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Show is a free online presentation software that lets you create, collaborate, publish, and broadcast presentations from any device, quick and easy.",
    "category": "Documents",
    "title": "Zoho Show Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Show with SalesBlink",
    "metaDescription": "Integrate Zoho Show and other Documents Tools with SalesBlink. Two Way Zoho Show Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intelagent",
    "slug": "intelagent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eedee87c29913573b75ddb80e43bbed4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intelligent automation, powered by conversational AI",
    "category": "Customer Support",
    "title": "Intelagent Integration With Cold Email Sales Tool",
    "h1": "Integrate Intelagent with SalesBlink",
    "metaDescription": "Integrate Intelagent and other Customer Support Tools with SalesBlink. Two Way Intelagent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EZICHEQ",
    "slug": "ezicheq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad9edb3e01821ca69481e82528d97ac4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Your team's most valuable asset, EZICHEQ can manage any item across every industry.\nWhere there's something worth tracking and checking - from construction to education, healthcare and more ... EZICHEQ makes work easy.",
    "category": "Productivity",
    "title": "EZICHEQ Integration With Cold Email Sales Tool",
    "h1": "Integrate EZICHEQ with SalesBlink",
    "metaDescription": "Integrate EZICHEQ and other Productivity Tools with SalesBlink. Two Way EZICHEQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Officely",
    "slug": "officely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/41564050f20bc1f5f3307ddab6e24c8c_8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Officely allows for a business virtual assistants to run the business easily directly from WhatsApp through a chatbot.",
    "category": "Team Chat",
    "title": "Officely Integration With Cold Email Sales Tool",
    "h1": "Integrate Officely with SalesBlink",
    "metaDescription": "Integrate Officely and other Team Chat Tools with SalesBlink. Two Way Officely Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Flagship",
    "slug": "flagship",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7bd23c6ae11be9bc479bf4890cc184a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flagship is a feature flagging platform that eliminates the risk of new feature releases.",
    "category": "Developer Tools",
    "title": "Flagship Integration With Cold Email Sales Tool",
    "h1": "Integrate Flagship with SalesBlink",
    "metaDescription": "Integrate Flagship and other Developer Tools Tools with SalesBlink. Two Way Flagship Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RepMove",
    "slug": "repmove",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/138aff10683c9ebbcefba02146694b17_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RepMove empowers sales reps and sales teams to become more effective in planning their daily activities with daily sales route mapping and detailed account & contact organization.",
    "category": "Sales & CRM",
    "title": "RepMove Integration With Cold Email Sales Tool",
    "h1": "Integrate RepMove with SalesBlink",
    "metaDescription": "Integrate RepMove and other Sales & CRM Tools with SalesBlink. Two Way RepMove Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PlaybookBuilder",
    "slug": "playbookbuilder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e204effba5dbb6555c9ba57ee1678688.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlaybookBuilder is a skill accelerator built to help small and medium sized companies train, scale, and grow.",
    "category": "Education",
    "title": "PlaybookBuilder Integration With Cold Email Sales Tool",
    "h1": "Integrate PlaybookBuilder with SalesBlink",
    "metaDescription": "Integrate PlaybookBuilder and other Education Tools with SalesBlink. Two Way PlaybookBuilder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mitto",
    "slug": "mitto",
    "logo": "https://zapier-images.imgix.net/storage/services/ce86e50d3fbaec4116bab8c71f953b7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Mitto integration enables apps to send enterprise grade SMS. Use it to send alerts, reminders, invites, marketing messages or any other kind of short message. We provide you a fast and reliable service which also brings you high conversion rates!",
    "category": "Notifications",
    "title": "Mitto Integration With Cold Email Sales Tool",
    "h1": "Integrate Mitto with SalesBlink",
    "metaDescription": "Integrate Mitto and other Notifications Tools with SalesBlink. Two Way Mitto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IdeaPlan",
    "slug": "ideaplan",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94c374012a6fb1ca658aeaf6273c8000.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ideaplan is a customer feedback tool that is used to gather & prioritize ideas and build awesome products.",
    "category": "Product Management",
    "title": "IdeaPlan Integration With Cold Email Sales Tool",
    "h1": "Integrate IdeaPlan with SalesBlink",
    "metaDescription": "Integrate IdeaPlan and other Product Management Tools with SalesBlink. Two Way IdeaPlan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fonn",
    "slug": "fonn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c2f779db49033786d52bdf011a0eeff9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fonn is a management software for construction teams.",
    "category": "Project Management",
    "title": "Fonn Integration With Cold Email Sales Tool",
    "h1": "Integrate Fonn with SalesBlink",
    "metaDescription": "Integrate Fonn and other Project Management Tools with SalesBlink. Two Way Fonn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bealink",
    "slug": "bealink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94dbec517bea52aa96854bd72ad9ff6b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bealink is a mobile learning application for companies.  Being contextual, it transforms user experience by providing the information in the work environment and increases your team’s engagement, knowledge retention, and productivity.",
    "category": "Online Courses",
    "title": "Bealink Integration With Cold Email Sales Tool",
    "h1": "Integrate Bealink with SalesBlink",
    "metaDescription": "Integrate Bealink and other Online Courses Tools with SalesBlink. Two Way Bealink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gloss.fm",
    "slug": "glossfm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/221649396aa43d91c86a157fbee04b49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automatically create beautiful screenshots of tweets.",
    "category": "Images & Design",
    "title": "Gloss.fm Integration With Cold Email Sales Tool",
    "h1": "Integrate Gloss.fm with SalesBlink",
    "metaDescription": "Integrate Gloss.fm and other Images & Design Tools with SalesBlink. Two Way Gloss.fm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Polly",
    "slug": "polly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1aa998879bf1f54e5e3e9baee29e18b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trigger automated feedback directly in Slack with Polly.",
    "category": "Forms & Surveys",
    "title": "Polly Integration With Cold Email Sales Tool",
    "h1": "Integrate Polly with SalesBlink",
    "metaDescription": "Integrate Polly and other Forms & Surveys Tools with SalesBlink. Two Way Polly Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Sweap",
    "slug": "sweap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2d467bd61d8c83170de0e90674c9e6c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate time-consuming tasks that remove focus from the impact of your event. For in-person & online events.",
    "category": "Event Management",
    "title": "Sweap Integration With Cold Email Sales Tool",
    "h1": "Integrate Sweap with SalesBlink",
    "metaDescription": "Integrate Sweap and other Event Management Tools with SalesBlink. Two Way Sweap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sperse",
    "slug": "sperse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd1324a59002c9f5f5f83a4443624068.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sperse is a business management and automation platform.  It's smart, sleek and customizable CRM software is simple and designed to help you maximize your customer relationships and grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sperse Integration With Cold Email Sales Tool",
    "h1": "Integrate Sperse with SalesBlink",
    "metaDescription": "Integrate Sperse and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sperse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Michigan Elections",
    "slug": "michigan-elections",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/66803f7873cffe5aaec2aa6d6ca06473.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "API for voter registration status and ballots in Michigan.",
    "category": "Developer Tools",
    "title": "Michigan Elections Integration With Cold Email Sales Tool",
    "h1": "Integrate Michigan Elections with SalesBlink",
    "metaDescription": "Integrate Michigan Elections and other Developer Tools Tools with SalesBlink. Two Way Michigan Elections Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Knowa",
    "slug": "knowa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe764b4c0889690d202434aa6b2f819f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Knowa is a board governance tool to meet, collaborate and access board information in one place",
    "category": "Team Collaboration",
    "title": "Knowa Integration With Cold Email Sales Tool",
    "h1": "Integrate Knowa with SalesBlink",
    "metaDescription": "Integrate Knowa and other Team Collaboration Tools with SalesBlink. Two Way Knowa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MindfulSMS",
    "slug": "mindfulsms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aec330b3a15eb341227ae4f370b4789f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MindfulSMS is an easy to use text message marketing software to help you and your business connect with customers 1-on-1 through SMS reminders, confirmations and broadcast marketing campaigns.",
    "category": "Phone & SMS",
    "title": "MindfulSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate MindfulSMS with SalesBlink",
    "metaDescription": "Integrate MindfulSMS and other Phone & SMS Tools with SalesBlink. Two Way MindfulSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Milestonia",
    "slug": "milestonia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c0d9c39e0d1dd05008307584f19cb93_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Milestonia is an all-in-one software solution that keeps teams aligned and agile.",
    "category": "Project Management",
    "title": "Milestonia Integration With Cold Email Sales Tool",
    "h1": "Integrate Milestonia with SalesBlink",
    "metaDescription": "Integrate Milestonia and other Project Management Tools with SalesBlink. Two Way Milestonia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parashift",
    "slug": "parashift",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1184c6c9e9dd20728436cd1162f32b96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A fundamentally different approach to Intelligent Document Processing",
    "category": "Documents",
    "title": "Parashift Integration With Cold Email Sales Tool",
    "h1": "Integrate Parashift with SalesBlink",
    "metaDescription": "Integrate Parashift and other Documents Tools with SalesBlink. Two Way Parashift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Check",
    "slug": "check",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c4971d719f8dddf2280e474f57eb40af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Check is an open source web platform for collaborative fact-checking and annotation.",
    "category": "Contact Management",
    "title": "Check Integration With Cold Email Sales Tool",
    "h1": "Integrate Check with SalesBlink",
    "metaDescription": "Integrate Check and other Contact Management Tools with SalesBlink. Two Way Check Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stackla",
    "slug": "stackla",
    "logo": "https://zapier-images.imgix.net/storage/developer/7cf930cc54727677a8203e486ce13538_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stackla is a social content marketing platform used by hundreds of global brands, agencies, media companies, and nonprofits to discover, curate and publish the best content from the social web.",
    "category": "Social Media Marketing",
    "title": "Stackla Integration With Cold Email Sales Tool",
    "h1": "Integrate Stackla with SalesBlink",
    "metaDescription": "Integrate Stackla and other Social Media Marketing Tools with SalesBlink. Two Way Stackla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Odyssee Field Service",
    "slug": "odyssee-field-service",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db24bb748fc8827a8f978fb92541afa1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Odyssee Field Service is a work order management software that helps service companies around the world achieve field service excellence.",
    "category": "Product Management",
    "title": "Odyssee Field Service Integration With Cold Email Sales Tool",
    "h1": "Integrate Odyssee Field Service with SalesBlink",
    "metaDescription": "Integrate Odyssee Field Service and other Product Management Tools with SalesBlink. Two Way Odyssee Field Service Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Steamship",
    "slug": "steamship",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39ecfed8c1aabbaf768ce8a748f10207.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Steamship is platform for AI builders to share great new capabilities.",
    "category": "Developer Tools",
    "title": "Steamship Integration With Cold Email Sales Tool",
    "h1": "Integrate Steamship with SalesBlink",
    "metaDescription": "Integrate Steamship and other Developer Tools Tools with SalesBlink. Two Way Steamship Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WikiPro",
    "slug": "wikipro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c943be870f210ec2ee857edeea4ed10.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WikiPro is a platform which allows All-in-one Business Texting",
    "category": "Communication",
    "title": "WikiPro Integration With Cold Email Sales Tool",
    "h1": "Integrate WikiPro with SalesBlink",
    "metaDescription": "Integrate WikiPro and other Communication Tools with SalesBlink. Two Way WikiPro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boomset",
    "slug": "boomset",
    "logo": "https://zapier-images.imgix.net/storage/services/d9816573d24cc6a7bdab35894acabced.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boomset is an all-in-one platform to streamline your event management. Power events with features like online and onsite registration, self-check-in, facial recognition, on-demand printing, gamification, RFID tracking, lead retrieval and more.",
    "category": "Event Management",
    "title": "Boomset Integration With Cold Email Sales Tool",
    "h1": "Integrate Boomset with SalesBlink",
    "metaDescription": "Integrate Boomset and other Event Management Tools with SalesBlink. Two Way Boomset Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yumpu",
    "slug": "yumpu",
    "logo": "https://zapier-images.imgix.net/storage/developer/b1fe53f13f69503887881f608d7fee4b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yumpu is a powerful publishing application that makes it easy to convert, share and embed all kinds of documents. \nYumpu is an E-paper technology with marketing tools which is also available for mobile platforms and devices.",
    "category": "Documents",
    "title": "Yumpu Integration With Cold Email Sales Tool",
    "h1": "Integrate Yumpu with SalesBlink",
    "metaDescription": "Integrate Yumpu and other Documents Tools with SalesBlink. Two Way Yumpu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hightail",
    "slug": "hightail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fb412dc3ebce1d89a613d4dc7e36587_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Share multimedia files, manage creative projects, and collaborate in real time.",
    "category": "Team Collaboration",
    "title": "Hightail Integration With Cold Email Sales Tool",
    "h1": "Integrate Hightail with SalesBlink",
    "metaDescription": "Integrate Hightail and other Team Collaboration Tools with SalesBlink. Two Way Hightail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudForm",
    "slug": "cloudform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dcab31403b4708486448315409a8c375_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudForm is a powerful online form builder with built-in Excel compatible functions, table and spreadsheet, conditional logic and much more.",
    "category": "Forms & Surveys",
    "title": "CloudForm Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudForm with SalesBlink",
    "metaDescription": "Integrate CloudForm and other Forms & Surveys Tools with SalesBlink. Two Way CloudForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Noora",
    "slug": "noora",
    "logo": "https://zapier-images.imgix.net/storage/services/40844808e42d43ec19a83835d3ad3ab4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Noora is a product feedback tool for collecting user feedback and ideas, so you can build a product your customers will love.",
    "category": "Customer Support",
    "title": "Noora Integration With Cold Email Sales Tool",
    "h1": "Integrate Noora with SalesBlink",
    "metaDescription": "Integrate Noora and other Customer Support Tools with SalesBlink. Two Way Noora Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProofEasy",
    "slug": "proofeasy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9d48a8d8707e786a7d53bfd2856a14a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProofEasy provides an innovative way to secure, share and verify documents using blockchain & QR code technology.",
    "category": "Documents",
    "title": "ProofEasy Integration With Cold Email Sales Tool",
    "h1": "Integrate ProofEasy with SalesBlink",
    "metaDescription": "Integrate ProofEasy and other Documents Tools with SalesBlink. Two Way ProofEasy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SEON. Fraud Fighters",
    "slug": "seon-fraud-fighters",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53a47e72459a67a33190caf016e47943.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SEON provides a complete end-to-end, fraud-fighting platform for online businesses.",
    "category": "Security & Identity Tools",
    "title": "SEON. Fraud Fighters Integration With Cold Email Sales Tool",
    "h1": "Integrate SEON. Fraud Fighters with SalesBlink",
    "metaDescription": "Integrate SEON. Fraud Fighters and other Security & Identity Tools Tools with SalesBlink. Two Way SEON. Fraud Fighters Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KatLeads CRM",
    "slug": "katleads-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a842e6a8d99236b5c82f472d769dd30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KatLeads is a marketing automation CRM software",
    "category": "CRM (Customer Relationship Management)",
    "title": "KatLeads CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate KatLeads CRM with SalesBlink",
    "metaDescription": "Integrate KatLeads CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way KatLeads CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EverAfter",
    "slug": "everafter",
    "logo": "https://zapier-images.imgix.net/storage/services/699a58e56e33432750109ebfdf9d6d15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "B2B Customer Hub Platform for Customer Onboarding & Retention.",
    "category": "Customer Appreciation",
    "title": "EverAfter Integration With Cold Email Sales Tool",
    "h1": "Integrate EverAfter with SalesBlink",
    "metaDescription": "Integrate EverAfter and other Customer Appreciation Tools with SalesBlink. Two Way EverAfter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onlive",
    "slug": "onlive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00c0797cb5ef01218e024cdd27d10829_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onlive event management system for managing your guests and checkins.",
    "category": "Event Management",
    "title": "Onlive Integration With Cold Email Sales Tool",
    "h1": "Integrate Onlive with SalesBlink",
    "metaDescription": "Integrate Onlive and other Event Management Tools with SalesBlink. Two Way Onlive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AuditCase",
    "slug": "auditcase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/22c08148073791473078d8163058955d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dé CRM en DMS oplossing voor accountants.",
    "category": "Accounting",
    "title": "AuditCase Integration With Cold Email Sales Tool",
    "h1": "Integrate AuditCase with SalesBlink",
    "metaDescription": "Integrate AuditCase and other Accounting Tools with SalesBlink. Two Way AuditCase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "autoRetouch",
    "slug": "autoretouch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e79a9d6fe494ea0fe976294168e10007.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "autoRetouch is an AI-powered image editing service by meero",
    "category": "eCommerce",
    "title": "autoRetouch Integration With Cold Email Sales Tool",
    "h1": "Integrate autoRetouch with SalesBlink",
    "metaDescription": "Integrate autoRetouch and other eCommerce Tools with SalesBlink. Two Way autoRetouch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jumpshare",
    "slug": "jumpshare",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/337720298930dd513ee89c23f7dc3970.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jumpshare is an all-in-one visual communication platform that helps you share your work and ideas using instantly shareable screen recordings, screenshots, and GIF captures, leveraging powerful sharing features and the ability to preview over 200 file formats online.",
    "category": "Content & Files",
    "title": "Jumpshare Integration With Cold Email Sales Tool",
    "h1": "Integrate Jumpshare with SalesBlink",
    "metaDescription": "Integrate Jumpshare and other Content & Files Tools with SalesBlink. Two Way Jumpshare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimpleSignCalls",
    "slug": "simplesigncalls",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb1eaf5f681a1ea5bf19566c05ff4e55.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleSignCalls is a way to automatically respond to your real estate sign's text for info.",
    "category": "Marketing",
    "title": "SimpleSignCalls Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleSignCalls with SalesBlink",
    "metaDescription": "Integrate SimpleSignCalls and other Marketing Tools with SalesBlink. Two Way SimpleSignCalls Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yeeflow",
    "slug": "yeeflow",
    "logo": "https://zapier-images.imgix.net/storage/services/be87db1fe9a14590d7860774a06673cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "No-code application development platform that lets you build custom applications in minutes.",
    "category": "App Builder",
    "title": "Yeeflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Yeeflow with SalesBlink",
    "metaDescription": "Integrate Yeeflow and other App Builder Tools with SalesBlink. Two Way Yeeflow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SmartTouch NexGen CRM",
    "slug": "smarttouch-nexgen-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03dd1b95e00092ba336696585db15070.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartTouch® NexGen is a CRM that allows for management of home buyer and broker engagement to sell more homes faster.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SmartTouch NexGen CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartTouch NexGen CRM with SalesBlink",
    "metaDescription": "Integrate SmartTouch NexGen CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SmartTouch NexGen CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Twona AMS - NeXT",
    "slug": "twona-ams-next",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/72ecd323402dc69a736bc1a2823b0a60.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twona AMS is an intuitive Workflow, Design, Communication & Version Management Software that maximizes productivity for teams of any size. Centralize workflows, proof packaging, automate processes, request internal/external approvals, make your Artwork Management foolproof.",
    "category": "Project Management",
    "title": "Twona AMS - NeXT Integration With Cold Email Sales Tool",
    "h1": "Integrate Twona AMS - NeXT with SalesBlink",
    "metaDescription": "Integrate Twona AMS - NeXT and other Project Management Tools with SalesBlink. Two Way Twona AMS - NeXT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fingbox",
    "slug": "fingbox",
    "logo": "https://zapier-images.imgix.net/storage/services/140e834cca7a58e800f4879b8d476fd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fingbox is network detection technology that plugs into your WiFi router to alert you of network threats, identify poor connectivity, control screentime, and more.",
    "category": "Devices",
    "title": "Fingbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Fingbox with SalesBlink",
    "metaDescription": "Integrate Fingbox and other Devices Tools with SalesBlink. Two Way Fingbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eartho",
    "slug": "eartho",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ffdd6e83bd520527b73e76fd214be870.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Authenticate, verify, and charge users of any platform with one line of code.",
    "category": "Developer Tools",
    "title": "Eartho Integration With Cold Email Sales Tool",
    "h1": "Integrate Eartho with SalesBlink",
    "metaDescription": "Integrate Eartho and other Developer Tools Tools with SalesBlink. Two Way Eartho Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SnapSign",
    "slug": "snapsign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f3de4a7994a45447e1cba7eb996ee6f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SnapSign is an electronic signature tool that allows you to send, sign and manage documents electronically.",
    "category": "Signatures",
    "title": "SnapSign Integration With Cold Email Sales Tool",
    "h1": "Integrate SnapSign with SalesBlink",
    "metaDescription": "Integrate SnapSign and other Signatures Tools with SalesBlink. Two Way SnapSign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatGen",
    "slug": "chatgen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91ab1d5a1298147602bbe255118c008e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chatgen is a smart conversational sales and marketing chatbot platform that drives immediate business impact with sales bot.",
    "category": "Sales & CRM",
    "title": "ChatGen Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatGen with SalesBlink",
    "metaDescription": "Integrate ChatGen and other Sales & CRM Tools with SalesBlink. Two Way ChatGen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Andpay",
    "slug": "andpay",
    "logo": "https://zapier-images.imgix.net/storage/services/faed48ec0c523af3e9dd0846ee5b8c8a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The first pure payment service provider that makes payments fast and simple, fully leveraging the Algorand blockchain.",
    "category": "Payment Processing",
    "title": "Andpay Integration With Cold Email Sales Tool",
    "h1": "Integrate Andpay with SalesBlink",
    "metaDescription": "Integrate Andpay and other Payment Processing Tools with SalesBlink. Two Way Andpay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuizTarget",
    "slug": "quiztarget",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/139502ec2b483e38a39894f9ff086d66.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QuizTarget is an app that creates quiz funnels to power your leads.",
    "category": "Marketing",
    "title": "QuizTarget Integration With Cold Email Sales Tool",
    "h1": "Integrate QuizTarget with SalesBlink",
    "metaDescription": "Integrate QuizTarget and other Marketing Tools with SalesBlink. Two Way QuizTarget Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sociabble",
    "slug": "sociabble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/98b1142f4b1e7e5167112542d7bb9670.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sociabble delivers top of the line enterprise social media solutions for employee advocacy, social selling, internal communication and Employee Engagement. Employees are better informed, improve their digital footprint.",
    "category": "Communication",
    "title": "Sociabble Integration With Cold Email Sales Tool",
    "h1": "Integrate Sociabble with SalesBlink",
    "metaDescription": "Integrate Sociabble and other Communication Tools with SalesBlink. Two Way Sociabble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MARCOM Robot",
    "slug": "marcom-robot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9ef57e2403d7e21bd2587f61aab4828b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MARCOM Robot Data Quality Engine helps B2B Marketing and Sales Operations leaders deliver better quality MQLs and convert them into bookings by arming them with data enrichment and data validation tools.",
    "category": "Databases",
    "title": "MARCOM Robot Integration With Cold Email Sales Tool",
    "h1": "Integrate MARCOM Robot with SalesBlink",
    "metaDescription": "Integrate MARCOM Robot and other Databases Tools with SalesBlink. Two Way MARCOM Robot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FlowyTeam",
    "slug": "flowyteam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99bb3c96da6741ee360220c6eaaae3ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FlowyTeam provides a one-stop centre to track and improve your team’s productivity via Task Management, Project Management, Attendance & Time-log, Client & Lead Management and effective productivity software your team needs.",
    "category": "Productivity",
    "title": "FlowyTeam Integration With Cold Email Sales Tool",
    "h1": "Integrate FlowyTeam with SalesBlink",
    "metaDescription": "Integrate FlowyTeam and other Productivity Tools with SalesBlink. Two Way FlowyTeam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wizu",
    "slug": "wizu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/291cc6239774560281e5ab20a5f08991.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wizu is a conversational survey platform that engages respondents and results in better verbatims",
    "category": "Forms & Surveys",
    "title": "Wizu Integration With Cold Email Sales Tool",
    "h1": "Integrate Wizu with SalesBlink",
    "metaDescription": "Integrate Wizu and other Forms & Surveys Tools with SalesBlink. Two Way Wizu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hexometer",
    "slug": "hexometer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd665898710d894289a488143077ac9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hexometer is a Website Health and Performance Monitoring Service",
    "category": "Dashboards",
    "title": "Hexometer Integration With Cold Email Sales Tool",
    "h1": "Integrate Hexometer with SalesBlink",
    "metaDescription": "Integrate Hexometer and other Dashboards Tools with SalesBlink. Two Way Hexometer Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "InfiniteCRM",
    "slug": "infinitecrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fad56ada4ff0cf3d3b61de330c67301e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InfiniteCRM is a CRM platform designed to meet the needs of different business areas, from sales force to marketing, customer care to call centre. InfiniteCRM boosts your business by creating winning, enduring relationships with leads, prospects, customers and partners.",
    "category": "CRM (Customer Relationship Management)",
    "title": "InfiniteCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate InfiniteCRM with SalesBlink",
    "metaDescription": "Integrate InfiniteCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way InfiniteCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Papyrs",
    "slug": "papyrs",
    "logo": "https://zapier-images.imgix.net/storage/developer/38b5eb5b6bec9a6921b633811d56dfb9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dream up your own intranet site or internal wiki with simple drag & drop. Easily share and collect information, files, discussions, online forms and much more -- work better together with colleagues and clients.",
    "category": "Notes",
    "title": "Papyrs Integration With Cold Email Sales Tool",
    "h1": "Integrate Papyrs with SalesBlink",
    "metaDescription": "Integrate Papyrs and other Notes Tools with SalesBlink. Two Way Papyrs Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "DPOrganizer",
    "slug": "dporganizer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9c68415c7b665a839ece6b7704e21de9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DPOrganizer is privacy management software that helps map, visualize, and manage processing of personal data. We turn privacy professionals into privacy heroes.",
    "category": "Notifications",
    "title": "DPOrganizer Integration With Cold Email Sales Tool",
    "h1": "Integrate DPOrganizer with SalesBlink",
    "metaDescription": "Integrate DPOrganizer and other Notifications Tools with SalesBlink. Two Way DPOrganizer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignalZen",
    "slug": "signalzen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43edf46f711f781a4f03afde515664a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SignalZen is a simple but powerful cloud SaaS live support product that focuses on Slack integration.",
    "category": "Customer Support",
    "title": "SignalZen Integration With Cold Email Sales Tool",
    "h1": "Integrate SignalZen with SalesBlink",
    "metaDescription": "Integrate SignalZen and other Customer Support Tools with SalesBlink. Two Way SignalZen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ivanti Service Manager",
    "slug": "ivanti-service-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/478d0b0c11136d66ddd98c238abb1011_8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ivanti Service Manager is a native Cloud and On-Premise Service Management solution that transforms service and support teams, help desks and service desks from tactical service providers to strategic business enablers.",
    "category": "Customer Support",
    "title": "Ivanti Service Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Ivanti Service Manager with SalesBlink",
    "metaDescription": "Integrate Ivanti Service Manager and other Customer Support Tools with SalesBlink. Two Way Ivanti Service Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EvaluAgent",
    "slug": "evaluagent",
    "logo": "https://zapier-images.imgix.net/storage/services/7496894be2099b631cd0158244e4ed03.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EvaluAgent provides everything you need to evaluate, coach and engage agents. This app allows you to view zendesk tickets that have been evaluated inside evaluation and have instant access to the results",
    "category": "Customer Support",
    "title": "EvaluAgent Integration With Cold Email Sales Tool",
    "h1": "Integrate EvaluAgent with SalesBlink",
    "metaDescription": "Integrate EvaluAgent and other Customer Support Tools with SalesBlink. Two Way EvaluAgent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Resont",
    "slug": "resont",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85ecdc479df5362255b731ff87fb5046.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powerful tools to manage and grow your social channels",
    "category": "Social Media Marketing",
    "title": "Resont Integration With Cold Email Sales Tool",
    "h1": "Integrate Resont with SalesBlink",
    "metaDescription": "Integrate Resont and other Social Media Marketing Tools with SalesBlink. Two Way Resont Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hyperia",
    "slug": "hyperia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/baa2319a1293dabcf5064846b42269f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hyperia is an AI Assistant that highlights the moments that matter from your calls and meetings so your team can focus on what’s most important",
    "category": "Transcription",
    "title": "Hyperia Integration With Cold Email Sales Tool",
    "h1": "Integrate Hyperia with SalesBlink",
    "metaDescription": "Integrate Hyperia and other Transcription Tools with SalesBlink. Two Way Hyperia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omniengage",
    "slug": "omniengage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a48a39f7386978f5b1b42558b2e4a8e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Using Omniengage you can unify channels, streamline interactions, and boost customer engagement through intelligent automation.",
    "category": "Forms & Surveys",
    "title": "Omniengage Integration With Cold Email Sales Tool",
    "h1": "Integrate Omniengage with SalesBlink",
    "metaDescription": "Integrate Omniengage and other Forms & Surveys Tools with SalesBlink. Two Way Omniengage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intely",
    "slug": "intely",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4562bfdfde2354172450a4da860b80d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The ultimate no-code data integrator, custom form builder, and workflow automation platform.",
    "category": "Forms & Surveys",
    "title": "Intely Integration With Cold Email Sales Tool",
    "h1": "Integrate Intely with SalesBlink",
    "metaDescription": "Integrate Intely and other Forms & Surveys Tools with SalesBlink. Two Way Intely Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evergiving",
    "slug": "evergiving",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f533aae452d1dba4b9ba325cb6873285.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evergiving is an end-to-end solution for regular giving fundraising.",
    "category": "Fundraising",
    "title": "Evergiving Integration With Cold Email Sales Tool",
    "h1": "Integrate Evergiving with SalesBlink",
    "metaDescription": "Integrate Evergiving and other Fundraising Tools with SalesBlink. Two Way Evergiving Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Petloop",
    "slug": "petloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3799fa738f7026db58368b9dec391fdd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pet Loop App is an all-in-one solution to easily share, manage and store client communication such as documents, photos, videos, and texts with pet trainers.",
    "category": "Productivity",
    "title": "Petloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Petloop with SalesBlink",
    "metaDescription": "Integrate Petloop and other Productivity Tools with SalesBlink. Two Way Petloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AppsForOps Timeline",
    "slug": "appsforops",
    "logo": "https://zapier-images.imgix.net/storage/services/f6cabf85dd6f476553e2e18d32f03838.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AppsForOps Timeline is a game changing Customer Success software platform. Customer Success - powered by being in the know.\n\nIt provides next level visibility on customer activity spanning your organisation's people, processes and systems.\n\nIt allows you to easily see the whole picture and act on what matters most.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AppsForOps Timeline Integration With Cold Email Sales Tool",
    "h1": "Integrate AppsForOps Timeline with SalesBlink",
    "metaDescription": "Integrate AppsForOps Timeline and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AppsForOps Timeline Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho TeamInbox",
    "slug": "zoho-teaminbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f825598490d25e04b2fa38618d891eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho TeamInbox is a shared inboxes tool that teams can use to enhance productivity, transparency and collaboration. Connect your group emails with Zoho TeamInbox to have clutter-free, organized inboxes.",
    "category": "Team Collaboration",
    "title": "Zoho TeamInbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho TeamInbox with SalesBlink",
    "metaDescription": "Integrate Zoho TeamInbox and other Team Collaboration Tools with SalesBlink. Two Way Zoho TeamInbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Helloprint API",
    "slug": "helloprint-api",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4fdd10a149ab79f73be3823976c4f391.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helloprint is a marketplace for customized print products. Connect and automate the fulfillment process of your print orders at Helloprint",
    "category": "Printing",
    "title": "Helloprint API Integration With Cold Email Sales Tool",
    "h1": "Integrate Helloprint API with SalesBlink",
    "metaDescription": "Integrate Helloprint API and other Printing Tools with SalesBlink. Two Way Helloprint API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DottedSign",
    "slug": "dottedsign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49e5bdc30faa0d2793aed76996dc2b9e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DottedSign is an E-Signature service that enables you to sign documents, assign signing tasks to multiple signers, and track signing status directly on your smartphone, tablet, and web browser.",
    "category": "Signatures",
    "title": "DottedSign Integration With Cold Email Sales Tool",
    "h1": "Integrate DottedSign with SalesBlink",
    "metaDescription": "Integrate DottedSign and other Signatures Tools with SalesBlink. Two Way DottedSign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nexkey",
    "slug": "nexkey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/26b35a083581ebc1364794669e18a6ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nexkey, enabling industry leading and cost effective access control.",
    "category": "Devices",
    "title": "Nexkey Integration With Cold Email Sales Tool",
    "h1": "Integrate Nexkey with SalesBlink",
    "metaDescription": "Integrate Nexkey and other Devices Tools with SalesBlink. Two Way Nexkey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Six Disciplines",
    "slug": "six-disciplines",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9078628bf15c97c07168282bf5c7995.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Six Disciplines helps automate excel file uploads into Six Disciplines to update your custom data sets.",
    "category": "Human Resources",
    "title": "Six Disciplines Integration With Cold Email Sales Tool",
    "h1": "Integrate Six Disciplines with SalesBlink",
    "metaDescription": "Integrate Six Disciplines and other Human Resources Tools with SalesBlink. Two Way Six Disciplines Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ApptiveGrid",
    "slug": "apptivegrid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c18c32419af17b47cb0f33fc2f107e5d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ApptiveGrid enables anyone to create digital products and workflows. ApptiveGrid is a visual spreadsheet and database hybrid, api driven and highly integratable.",
    "category": "Databases",
    "title": "ApptiveGrid Integration With Cold Email Sales Tool",
    "h1": "Integrate ApptiveGrid with SalesBlink",
    "metaDescription": "Integrate ApptiveGrid and other Databases Tools with SalesBlink. Two Way ApptiveGrid Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Craft",
    "slug": "craft",
    "logo": "https://zapier-images.imgix.net/storage/developer/76d5d308a265f2693bed31609b31f1dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Craft is a single and collaborative place for product managers to work.",
    "category": "Product Management",
    "title": "Craft Integration With Cold Email Sales Tool",
    "h1": "Integrate Craft with SalesBlink",
    "metaDescription": "Integrate Craft and other Product Management Tools with SalesBlink. Two Way Craft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HelpdeskAdvanced",
    "slug": "helpdeskadvanced",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ba4bbd150aa63586eaa04459e1cdeaf0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HelpdeskAdvanced is an ITIL v3 compliant web- and mobile-based solution governing services across all organisational areas where Service Management is a key factor in the successful achievement of your company’s objectives.",
    "category": "Call Tracking",
    "title": "HelpdeskAdvanced Integration With Cold Email Sales Tool",
    "h1": "Integrate HelpdeskAdvanced with SalesBlink",
    "metaDescription": "Integrate HelpdeskAdvanced and other Call Tracking Tools with SalesBlink. Two Way HelpdeskAdvanced Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MetaSoul",
    "slug": "metasoul",
    "logo": "https://zapier-images.imgix.net/storage/services/dfb13dcdfbcee964b117d9f0ffa4c07b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MetaSoul™ is a sentient digital entity (soul) that feels, reacts, learns, and develops a personality.",
    "category": "Dashboards",
    "title": "MetaSoul Integration With Cold Email Sales Tool",
    "h1": "Integrate MetaSoul with SalesBlink",
    "metaDescription": "Integrate MetaSoul and other Dashboards Tools with SalesBlink. Two Way MetaSoul Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dispatcher",
    "slug": "dispatcher",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae0e7a73b3ba037c014656f9e1a85503.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dispatcher is a resource management software for construction.",
    "category": "Scheduling & Booking",
    "title": "Dispatcher Integration With Cold Email Sales Tool",
    "h1": "Integrate Dispatcher with SalesBlink",
    "metaDescription": "Integrate Dispatcher and other Scheduling & Booking Tools with SalesBlink. Two Way Dispatcher Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emeezo",
    "slug": "emeezo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4db112861b655b6ce2f9e1f5fb188b23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emeezo is a video creation platform to generate videos in the cloud",
    "category": "Video & Audio",
    "title": "Emeezo Integration With Cold Email Sales Tool",
    "h1": "Integrate Emeezo with SalesBlink",
    "metaDescription": "Integrate Emeezo and other Video & Audio Tools with SalesBlink. Two Way Emeezo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Modica SMS Messaging",
    "slug": "modica-sms-messaging",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d91fd5032ef7b53e9d09853e5b684327.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Modica SMS Messaging allows you to send and schedule SMS messages in bulk.",
    "category": "Communication",
    "title": "Modica SMS Messaging Integration With Cold Email Sales Tool",
    "h1": "Integrate Modica SMS Messaging with SalesBlink",
    "metaDescription": "Integrate Modica SMS Messaging and other Communication Tools with SalesBlink. Two Way Modica SMS Messaging Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UseResponse",
    "slug": "useresponse",
    "logo": "https://zapier-images.imgix.net/storage/developer/6423ea5ed652b5f7f4b10e573548ad57.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UseResponse is all-in-one support suite to capture feedback, organize community with a self-service knowledge base, and support customers in multiple channels.",
    "category": "Customer Support",
    "title": "UseResponse Integration With Cold Email Sales Tool",
    "h1": "Integrate UseResponse with SalesBlink",
    "metaDescription": "Integrate UseResponse and other Customer Support Tools with SalesBlink. Two Way UseResponse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quickwork.ai",
    "slug": "quickworkai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6312d11dc231be869db8fee1d4d98d9d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The integrate with QuickworkAI application that provides for users a possibility of using existing QuickworkAI tools for their own purposes.",
    "category": "Ads & Conversion",
    "title": "Quickwork.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Quickwork.ai with SalesBlink",
    "metaDescription": "Integrate Quickwork.ai and other Ads & Conversion Tools with SalesBlink. Two Way Quickwork.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grabbly",
    "slug": "grabbly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4cb13ad5d9143640b433c614d21d527a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grabbly allows you to Grabb any website with a single click.  Monitor website content and be alerted of any new changes.  Create scheduled Grabbs that run periodically.",
    "category": "Content & Files",
    "title": "Grabbly Integration With Cold Email Sales Tool",
    "h1": "Integrate Grabbly with SalesBlink",
    "metaDescription": "Integrate Grabbly and other Content & Files Tools with SalesBlink. Two Way Grabbly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lennd",
    "slug": "lennd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c904d350df40f771c261eb7f3cb73037.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lennd is festival and live event management platform that helps facilitate requests and approvals around passes, meals, inventory and more.",
    "category": "Event Management",
    "title": "Lennd Integration With Cold Email Sales Tool",
    "h1": "Integrate Lennd with SalesBlink",
    "metaDescription": "Integrate Lennd and other Event Management Tools with SalesBlink. Two Way Lennd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Connectix",
    "slug": "connectix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a600512ff7ae6aa5b7769c2bd2bef902.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connectix is a messaging platform allowing you to send SMS and Viber messages.",
    "category": "Phone & SMS",
    "title": "Connectix Integration With Cold Email Sales Tool",
    "h1": "Integrate Connectix with SalesBlink",
    "metaDescription": "Integrate Connectix and other Phone & SMS Tools with SalesBlink. Two Way Connectix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trackfront",
    "slug": "trackfront",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f13360a7dff2d8bd7dfbb3b05c8ec701.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The All-In-One Solution For Project Collaboration,  Easy Quotes, Estimates and Custom Proposals. Keeping Clients Happy and Coming Back, Reducing Emails and Simplifying Communication.",
    "category": "Proposal & Invoice Management",
    "title": "Trackfront Integration With Cold Email Sales Tool",
    "h1": "Integrate Trackfront with SalesBlink",
    "metaDescription": "Integrate Trackfront and other Proposal & Invoice Management Tools with SalesBlink. Two Way Trackfront Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RTILA",
    "slug": "rtila",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5be7daa964986b09953a3c6403c910a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate the most complex tasks & processes in your web or intranet Apps & release your teams for more productive work.",
    "category": "Marketing Automation",
    "title": "RTILA Integration With Cold Email Sales Tool",
    "h1": "Integrate RTILA with SalesBlink",
    "metaDescription": "Integrate RTILA and other Marketing Automation Tools with SalesBlink. Two Way RTILA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tootix",
    "slug": "tootix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0ef3fc4fdc08eb5a11b4dd26407049c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We specialise in technology to effectively work as a team with a dedicated focus to get things done.",
    "category": "Communication",
    "title": "Tootix Integration With Cold Email Sales Tool",
    "h1": "Integrate Tootix with SalesBlink",
    "metaDescription": "Integrate Tootix and other Communication Tools with SalesBlink. Two Way Tootix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshreview",
    "slug": "freshreview",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6385ccb09a7d171cfa60076b57453b69.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshreview is an online reputation management software that helps online retailers, local area businesses, grow and manage their online customer reviews",
    "category": "Reviews",
    "title": "Freshreview Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshreview with SalesBlink",
    "metaDescription": "Integrate Freshreview and other Reviews Tools with SalesBlink. Two Way Freshreview Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easelly Design Service",
    "slug": "easelly-design-service",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/021cc8eb22c337111676f955d2c68581_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trusted by thousand of customers, Easelly Design is a professional services for fast and high quality design needs. \n\nThis application provides access to to create, retrieve status, update, request revision, get design files and approve order per customer/partner.",
    "category": "Images & Design",
    "title": "Easelly Design Service Integration With Cold Email Sales Tool",
    "h1": "Integrate Easelly Design Service with SalesBlink",
    "metaDescription": "Integrate Easelly Design Service and other Images & Design Tools with SalesBlink. Two Way Easelly Design Service Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Giving Block",
    "slug": "the-giving-block",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a8da6bbbf94edfde808112bc4cf50ad7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Giving Block is a crypto donation solution, allowing nonprofits to fundraise Bitcoin, Ethereum and many other cryptocurrencies.",
    "category": "Fundraising",
    "title": "The Giving Block Integration With Cold Email Sales Tool",
    "h1": "Integrate The Giving Block with SalesBlink",
    "metaDescription": "Integrate The Giving Block and other Fundraising Tools with SalesBlink. Two Way The Giving Block Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boost Note",
    "slug": "boost-note",
    "logo": "https://zapier-images.imgix.net/storage/services/66b85956a1ca778e5c9a92f999fefc3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boost Note is a knowledge sharing tool for your dev team.",
    "category": "Developer Tools",
    "title": "Boost Note Integration With Cold Email Sales Tool",
    "h1": "Integrate Boost Note with SalesBlink",
    "metaDescription": "Integrate Boost Note and other Developer Tools Tools with SalesBlink. Two Way Boost Note Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sorted",
    "slug": "sorted",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d6cc9be24843f7609a8de466fb80b43.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sorted is a Digital Marketing Business management application providing social media marketing, Email marketing, Professional reporting, instant lead notification and more",
    "category": "Social Media Marketing",
    "title": "Sorted Integration With Cold Email Sales Tool",
    "h1": "Integrate Sorted with SalesBlink",
    "metaDescription": "Integrate Sorted and other Social Media Marketing Tools with SalesBlink. Two Way Sorted Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailcheck",
    "slug": "mailcheck",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40253793ce32d270daac6720e9979c04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailcheck is an email verification service, that helps businesses to increase conversion in emailing and decrease bounce rates.",
    "category": "Email",
    "title": "Mailcheck Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailcheck with SalesBlink",
    "metaDescription": "Integrate Mailcheck and other Email Tools with SalesBlink. Two Way Mailcheck Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "YCloud",
    "slug": "ycloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4de4ac01316c8491fc194827aeb8f01d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simplify the global communications with our\nAll-In-One SMS service",
    "category": "Marketing",
    "title": "YCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate YCloud with SalesBlink",
    "metaDescription": "Integrate YCloud and other Marketing Tools with SalesBlink. Two Way YCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "B2B Generator",
    "slug": "b2b-generator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/90d488c25d401367f4ff2692f38a8fbf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "B2B Generator instantly repurposes product data into multiple digital sales and marketing channels while maximizing reseller interconnection",
    "category": "Product Management",
    "title": "B2B Generator Integration With Cold Email Sales Tool",
    "h1": "Integrate B2B Generator with SalesBlink",
    "metaDescription": "Integrate B2B Generator and other Product Management Tools with SalesBlink. Two Way B2B Generator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flowup",
    "slug": "flowup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7abdff3320ae05d7c5adf49054d5efaa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Flowup, you register service sales and related customer data, allowing you to control your sales in a fully integrated and online way.",
    "category": "Team Collaboration",
    "title": "Flowup Integration With Cold Email Sales Tool",
    "h1": "Integrate Flowup with SalesBlink",
    "metaDescription": "Integrate Flowup and other Team Collaboration Tools with SalesBlink. Two Way Flowup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Matajer",
    "slug": "matajer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa63d2f9e53bf0d0f013ca7b7640e317_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Matajer is the easy way to create your full-featured online store",
    "category": "eCommerce",
    "title": "Matajer Integration With Cold Email Sales Tool",
    "h1": "Integrate Matajer with SalesBlink",
    "metaDescription": "Integrate Matajer and other eCommerce Tools with SalesBlink. Two Way Matajer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HOALife",
    "slug": "hoalife",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/36b2d2332c7f998c6ef5f88068fcfe79.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HOAlife is a homeowner association management service for violations, architectural requests, assets, and communications.",
    "category": "Productivity",
    "title": "HOALife Integration With Cold Email Sales Tool",
    "h1": "Integrate HOALife with SalesBlink",
    "metaDescription": "Integrate HOALife and other Productivity Tools with SalesBlink. Two Way HOALife Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "trackdesk",
    "slug": "trackdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/262baf2804e759344b66cfd735d12fd3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trackdesk is an affiliate tool for tracking clicks, conversions from all around the world.",
    "category": "Ads & Conversion",
    "title": "trackdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate trackdesk with SalesBlink",
    "metaDescription": "Integrate trackdesk and other Ads & Conversion Tools with SalesBlink. Two Way trackdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emercury",
    "slug": "emercury",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/59980b7562a8af8ba90b79e03bb8846b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Emercury is a marketing automation solution for marketers.",
    "category": "Marketing",
    "title": "Emercury Integration With Cold Email Sales Tool",
    "h1": "Integrate Emercury with SalesBlink",
    "metaDescription": "Integrate Emercury and other Marketing Tools with SalesBlink. Two Way Emercury Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "RZQ",
    "slug": "rzq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91ae122ad8696f50e955a44fb0915180.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RZQ is an e-commerce platform that allows merchants to establish, operate, and manage their online stores with no technical hassle. It also allows them to enable or disable the connection with any logistic company and/or payment gateway with a click of a button.",
    "category": "eCommerce",
    "title": "RZQ Integration With Cold Email Sales Tool",
    "h1": "Integrate RZQ with SalesBlink",
    "metaDescription": "Integrate RZQ and other eCommerce Tools with SalesBlink. Two Way RZQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Askneo",
    "slug": "askneo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/811d7f6e707d827b786ffb68ab7beebc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn marketing into human experiences. By SMS, in seconds. Neo’s chatbot, keywords and tags help turn mass outreach into personal experiences and skyrocket returns.",
    "category": "Marketing Automation",
    "title": "Askneo Integration With Cold Email Sales Tool",
    "h1": "Integrate Askneo with SalesBlink",
    "metaDescription": "Integrate Askneo and other Marketing Automation Tools with SalesBlink. Two Way Askneo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Text Integration",
    "slug": "text-integration",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/021f301b833126600c828d980f38e527.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text Integration sends text messages globally that incorporate advanced automation capabilities.",
    "category": "Phone & SMS",
    "title": "Text Integration Integration With Cold Email Sales Tool",
    "h1": "Integrate Text Integration with SalesBlink",
    "metaDescription": "Integrate Text Integration and other Phone & SMS Tools with SalesBlink. Two Way Text Integration Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Packfleet",
    "slug": "packfleet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3b569aa814ebe10cfd8fad4ec530a5c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "At Packfleet, we’re on a mission to empower businesses across the UK to offer the best deliveries on Earth.",
    "category": "Commerce",
    "title": "Packfleet Integration With Cold Email Sales Tool",
    "h1": "Integrate Packfleet with SalesBlink",
    "metaDescription": "Integrate Packfleet and other Commerce Tools with SalesBlink. Two Way Packfleet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ubidots",
    "slug": "ubidots",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00b9a68e6b52f3bc5ef9f966d2f72bda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rapidly assemble and launch Internet of Things (IoT) applications without having to write code or hire a software development team.",
    "category": "App Builder",
    "title": "Ubidots Integration With Cold Email Sales Tool",
    "h1": "Integrate Ubidots with SalesBlink",
    "metaDescription": "Integrate Ubidots and other App Builder Tools with SalesBlink. Two Way Ubidots Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DentiMax Communicator",
    "slug": "dentimax-communicator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96b9888e81664a35da9438392bd7d087_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DentiMax Communicator is a SaaS service that facilitates communications between health providers and their patients",
    "category": "Communication",
    "title": "DentiMax Communicator Integration With Cold Email Sales Tool",
    "h1": "Integrate DentiMax Communicator with SalesBlink",
    "metaDescription": "Integrate DentiMax Communicator and other Communication Tools with SalesBlink. Two Way DentiMax Communicator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Always On Time (AOT.plus)",
    "slug": "always-on-time-aotplus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85e9d065faefde2e82bf24f90200aac6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Always On Time is a powerful scheduling solution. Use AOT to manage online bookings, memberships, services, staff, coupons, multiple locations, and more.",
    "category": "Scheduling & Booking",
    "title": "Always On Time (AOT.plus) Integration With Cold Email Sales Tool",
    "h1": "Integrate Always On Time (AOT.plus) with SalesBlink",
    "metaDescription": "Integrate Always On Time (AOT.plus) and other Scheduling & Booking Tools with SalesBlink. Two Way Always On Time (AOT.plus) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Stacks Plugin",
    "slug": "the-stacks-plugin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd3e44cf689633edbea2acef1dde2dd9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stacks Plugin is a no-code blockchain tool that allows you to connect the Stacks Network to your integrations.",
    "category": "Payment Processing",
    "title": "The Stacks Plugin Integration With Cold Email Sales Tool",
    "h1": "Integrate The Stacks Plugin with SalesBlink",
    "metaDescription": "Integrate The Stacks Plugin and other Payment Processing Tools with SalesBlink. Two Way The Stacks Plugin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quicksearch",
    "slug": "quicksearch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/462b6da04428da031cec8fc79eff566e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quicksearch is an enterprise Customer Experience (CX) and Employee Experience (EX) platform",
    "category": "Forms & Surveys",
    "title": "Quicksearch Integration With Cold Email Sales Tool",
    "h1": "Integrate Quicksearch with SalesBlink",
    "metaDescription": "Integrate Quicksearch and other Forms & Surveys Tools with SalesBlink. Two Way Quicksearch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tny",
    "slug": "tny",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f419a41dd5939e60e480cda7a9c05170.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tny is a URL shortener that gives you short links fast and easy.",
    "category": "URL Shortener",
    "title": "Tny Integration With Cold Email Sales Tool",
    "h1": "Integrate Tny with SalesBlink",
    "metaDescription": "Integrate Tny and other URL Shortener Tools with SalesBlink. Two Way Tny Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Payday",
    "slug": "payday",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2515bf6b66c546adb53675e417d93f9d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payday simplifies business administration for freelancers and small businesses.",
    "category": "Accounting",
    "title": "Payday Integration With Cold Email Sales Tool",
    "h1": "Integrate Payday with SalesBlink",
    "metaDescription": "Integrate Payday and other Accounting Tools with SalesBlink. Two Way Payday Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fogwing",
    "slug": "fogwing",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe8d8dd4a603cce9b09b21698ba91eee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fogwing is the platform for industry 4.0 solutions. Fogwing Industrial IoT Software helps to get connected with things on the factory to start the digital journey.",
    "category": "Internet of Things",
    "title": "Fogwing Integration With Cold Email Sales Tool",
    "h1": "Integrate Fogwing with SalesBlink",
    "metaDescription": "Integrate Fogwing and other Internet of Things Tools with SalesBlink. Two Way Fogwing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Twiin Workspace",
    "slug": "twiin-workspace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a14e857d74244ffa26e48c9e1bb15627.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The ultimate workspace management platform, billing solution and industry specific CRM from technologywithin",
    "category": "Accounting",
    "title": "Twiin Workspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Twiin Workspace with SalesBlink",
    "metaDescription": "Integrate Twiin Workspace and other Accounting Tools with SalesBlink. Two Way Twiin Workspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Asset Infinity",
    "slug": "asset-infinity",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/825beab075504ec15e058eb54debbdc7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Asset Infinity is a leading asset management suite that helps organizations in tracking, managing and maintaining assets, equipment and inventory using barcodes, QR codes and RFIDs",
    "category": "IT Operations",
    "title": "Asset Infinity Integration With Cold Email Sales Tool",
    "h1": "Integrate Asset Infinity with SalesBlink",
    "metaDescription": "Integrate Asset Infinity and other IT Operations Tools with SalesBlink. Two Way Asset Infinity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSup",
    "slug": "smsup",
    "logo": "https://zapier-images.imgix.net/storage/services/5716e5ee40f91fb59df45c996cc5ba12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSup is a 100% online pro sms platform. Create your sms marketing campaign, send appointment reminders or sms alerts easily.",
    "category": "Communication",
    "title": "SMSup Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSup with SalesBlink",
    "metaDescription": "Integrate SMSup and other Communication Tools with SalesBlink. Two Way SMSup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pxmo",
    "slug": "pxmo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/52400a4c686bc35de53336dc2d73c0bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pxmo is an app for creating compelling proposals.",
    "category": "Proposal & Invoice Management",
    "title": "Pxmo Integration With Cold Email Sales Tool",
    "h1": "Integrate Pxmo with SalesBlink",
    "metaDescription": "Integrate Pxmo and other Proposal & Invoice Management Tools with SalesBlink. Two Way Pxmo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Experience.com",
    "slug": "experience-dot-com",
    "logo": "https://zapier-images.imgix.net/storage/services/c32b960be10879896307da30ca6d9fe3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Experience.com is a customer experience platform that lets you collect, analyse, and act on customer feedback automatically at every meaningful moment in the customer journey. Our enterprise grade platform helps engage people and power businesses everywhere.",
    "category": "Developer Tools",
    "title": "Experience.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Experience.com with SalesBlink",
    "metaDescription": "Integrate Experience.com and other Developer Tools Tools with SalesBlink. Two Way Experience.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnlineCheckWriter",
    "slug": "onlinecheckwriter",
    "logo": "https://zapier-images.imgix.net/storage/services/5f35a2750952cfac1fb11beb3b57f6c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnlineCheckWriter is a check printing and checks mailing service provider. Send payment via physical checks without leaving your desk in a minute. Also, accept payment from your clients and save on credit card processing fees.",
    "category": "Printing",
    "title": "OnlineCheckWriter Integration With Cold Email Sales Tool",
    "h1": "Integrate OnlineCheckWriter with SalesBlink",
    "metaDescription": "Integrate OnlineCheckWriter and other Printing Tools with SalesBlink. Two Way OnlineCheckWriter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phraseanet",
    "slug": "phraseanet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ab575c50a87cc79213a6db10dc85af0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Phraseanet is an Open Source Digital Asset Management solution dedicated to professionals who need a complete system to manage, publish and share their digital media resources (pictures, videos, audio, PDF, Office documents…).",
    "category": "File Management & Storage",
    "title": "Phraseanet Integration With Cold Email Sales Tool",
    "h1": "Integrate Phraseanet with SalesBlink",
    "metaDescription": "Integrate Phraseanet and other File Management & Storage Tools with SalesBlink. Two Way Phraseanet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moblico",
    "slug": "moblico",
    "logo": "https://zapier-images.imgix.net/storage/services/f72f7f681d8d480a111f3ddf8a580ea9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moblico is a mobile communication marketing platform that helps your company communicate including via SMS.",
    "category": "Communication",
    "title": "Moblico Integration With Cold Email Sales Tool",
    "h1": "Integrate Moblico with SalesBlink",
    "metaDescription": "Integrate Moblico and other Communication Tools with SalesBlink. Two Way Moblico Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Napta",
    "slug": "napta",
    "logo": "https://zapier-images.imgix.net/storage/services/ca97db53164b2d7835a1d96b79c46fd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Napta, staffing has never been that easy.",
    "category": "Project Management",
    "title": "Napta Integration With Cold Email Sales Tool",
    "h1": "Integrate Napta with SalesBlink",
    "metaDescription": "Integrate Napta and other Project Management Tools with SalesBlink. Two Way Napta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Blue Marble",
    "slug": "the-blue-marble",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/00607165e0ecb7a13a3fa6c448d208bb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NFTs to connect brands and customers to their social and environmental missions",
    "category": "Marketing",
    "title": "The Blue Marble Integration With Cold Email Sales Tool",
    "h1": "Integrate The Blue Marble with SalesBlink",
    "metaDescription": "Integrate The Blue Marble and other Marketing Tools with SalesBlink. Two Way The Blue Marble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Design Shifu",
    "slug": "design-shifu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30931297737ae755eedc10310010a844.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Design Shifu offers flat rate, subscription-based graphic design services for high-growth start-ups, marketing teams and digital agencies",
    "category": "Images & Design",
    "title": "Design Shifu Integration With Cold Email Sales Tool",
    "h1": "Integrate Design Shifu with SalesBlink",
    "metaDescription": "Integrate Design Shifu and other Images & Design Tools with SalesBlink. Two Way Design Shifu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cedar.AI",
    "slug": "cedarai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e4af43b638db72ad029c4bc22f29596.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cedar is an AI company with a mission to improve the rail experience and flexibility as a supply chain product. We build products that make rail easier to use.",
    "category": "Business Intelligence",
    "title": "Cedar.AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Cedar.AI with SalesBlink",
    "metaDescription": "Integrate Cedar.AI and other Business Intelligence Tools with SalesBlink. Two Way Cedar.AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kyvio",
    "slug": "kyvio",
    "logo": "https://zapier-images.imgix.net/storage/developer/1a540ccc4434b55158c0fc84268de9a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kyvio uses Smart Funnels, Smart Memberships, Smart Mailer and more to helps creators build, manage and sell their products.",
    "category": "Marketing Automation",
    "title": "Kyvio Integration With Cold Email Sales Tool",
    "h1": "Integrate Kyvio with SalesBlink",
    "metaDescription": "Integrate Kyvio and other Marketing Automation Tools with SalesBlink. Two Way Kyvio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Assigned",
    "slug": "assigned",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70e341f0dfab59426f6412c1ecf66981.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Assigned\" is an assignment management tool to send more organized tasks and leads to people outside your organization.",
    "category": "Project Management",
    "title": "Assigned Integration With Cold Email Sales Tool",
    "h1": "Integrate Assigned with SalesBlink",
    "metaDescription": "Integrate Assigned and other Project Management Tools with SalesBlink. Two Way Assigned Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PickPack",
    "slug": "pickpack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/855e6aa84ccee566c3ceece3efcba7c8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PickPack provides powerful features for every delivery ecosystem.",
    "category": "Productivity",
    "title": "PickPack Integration With Cold Email Sales Tool",
    "h1": "Integrate PickPack with SalesBlink",
    "metaDescription": "Integrate PickPack and other Productivity Tools with SalesBlink. Two Way PickPack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextBetter",
    "slug": "textbetter-landline-texting-sms-",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d473cef8575aa9bcfb1888b94cf4a136.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send & receive texts from your existing landline number.  Our simple & affordable works with any kind of landline, toll-free or VoIP number – guaranteed.",
    "category": "Phone & SMS",
    "title": "TextBetter Integration With Cold Email Sales Tool",
    "h1": "Integrate TextBetter with SalesBlink",
    "metaDescription": "Integrate TextBetter and other Phone & SMS Tools with SalesBlink. Two Way TextBetter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evervault",
    "slug": "evervault",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05a36fd8d6887e31e59f9827fa49d6a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evervault provides secure encryption infrastructure for developers.",
    "category": "Developer Tools",
    "title": "Evervault Integration With Cold Email Sales Tool",
    "h1": "Integrate Evervault with SalesBlink",
    "metaDescription": "Integrate Evervault and other Developer Tools Tools with SalesBlink. Two Way Evervault Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ordermate",
    "slug": "ordermate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fc189fe12dec8f5bfc16e286c6daacc4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ordermate is an automated buying app that allows you to make automatic orders from Amazon, Walmart, and other retailers.",
    "category": "eCommerce",
    "title": "Ordermate Integration With Cold Email Sales Tool",
    "h1": "Integrate Ordermate with SalesBlink",
    "metaDescription": "Integrate Ordermate and other eCommerce Tools with SalesBlink. Two Way Ordermate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WP Popups",
    "slug": "wp-popups",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f8b52cf4003c3a3d5e27a189e503320.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WP Popups is a Wordpress popup plugin.",
    "category": "WordPress",
    "title": "WP Popups Integration With Cold Email Sales Tool",
    "h1": "Integrate WP Popups with SalesBlink",
    "metaDescription": "Integrate WP Popups and other WordPress Tools with SalesBlink. Two Way WP Popups Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TheTexting",
    "slug": "thetexting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3bb1a9a34524e584f8c06b926999184.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TheTexting.com offers best SMS API services and SMS Gateway services for OTP text messages, notification texts, or even preferred for text marketing via own Text API based solution.",
    "category": "Phone & SMS",
    "title": "TheTexting Integration With Cold Email Sales Tool",
    "h1": "Integrate TheTexting with SalesBlink",
    "metaDescription": "Integrate TheTexting and other Phone & SMS Tools with SalesBlink. Two Way TheTexting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "High Attendance",
    "slug": "highattendance",
    "logo": "https://zapier-images.imgix.net/storage/developer/c4a31b72b82f5eb87b1a117d08b1538f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "High Attendance is an enterprise event management and lead generation software.",
    "category": "Event Management",
    "title": "High Attendance Integration With Cold Email Sales Tool",
    "h1": "Integrate High Attendance with SalesBlink",
    "metaDescription": "Integrate High Attendance and other Event Management Tools with SalesBlink. Two Way High Attendance Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YAY-List",
    "slug": "yay-list",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b76d7a78f35d919bcad89496b3d17c02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YAY-List is a brandable party invitation tool, that helps event-based businesses turbo-charge their word-of-mouth marketing and collect new leads.",
    "category": "Marketing",
    "title": "YAY-List Integration With Cold Email Sales Tool",
    "h1": "Integrate YAY-List with SalesBlink",
    "metaDescription": "Integrate YAY-List and other Marketing Tools with SalesBlink. Two Way YAY-List Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fee Navigator",
    "slug": "fee-navigator-instant-merchant-s",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/285e536c9f4cf589ada563dcf10ef9bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fee Navigator provides instant AI-powered merchant statement analysis and proposals.",
    "category": "Proposal & Invoice Management",
    "title": "Fee Navigator Integration With Cold Email Sales Tool",
    "h1": "Integrate Fee Navigator with SalesBlink",
    "metaDescription": "Integrate Fee Navigator and other Proposal & Invoice Management Tools with SalesBlink. Two Way Fee Navigator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyZenTeam",
    "slug": "myzenteam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5fc4d4dcc7cde8c1de7aa92dd3aea519.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyZenTeam is an hiring tool that makes hiring process easier, shorter and more inclusive.",
    "category": "HR Talent & Recruitment",
    "title": "MyZenTeam Integration With Cold Email Sales Tool",
    "h1": "Integrate MyZenTeam with SalesBlink",
    "metaDescription": "Integrate MyZenTeam and other HR Talent & Recruitment Tools with SalesBlink. Two Way MyZenTeam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Green Doors",
    "slug": "green-doors",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/55b52420ed755887f67640b7f10458a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Green Doors is a free property management platform. Manage your properties with an easy-to-use and powerful platform.",
    "category": "Productivity",
    "title": "Green Doors Integration With Cold Email Sales Tool",
    "h1": "Integrate Green Doors with SalesBlink",
    "metaDescription": "Integrate Green Doors and other Productivity Tools with SalesBlink. Two Way Green Doors Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnePgr",
    "slug": "onepgr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f80f1ffeb7a3761c34e7ed031c6075aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnePgr offers a communication & collaboration platform to help automate your organizational workflow for business processes such as Sales, Support, IT, HR, allowing you to hold online meetings, make phone calls, share documents, and send text messages seamlessly from your tools.",
    "category": "Team Collaboration",
    "title": "OnePgr Integration With Cold Email Sales Tool",
    "h1": "Integrate OnePgr with SalesBlink",
    "metaDescription": "Integrate OnePgr and other Team Collaboration Tools with SalesBlink. Two Way OnePgr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "APITable",
    "slug": "apitable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/862fff5107b90df650e0719b55fcb1e4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "APITable is an API-oriented and easy-to-use visual database that empowers everyone to create endless software solutions on one platform.",
    "category": "Databases",
    "title": "APITable Integration With Cold Email Sales Tool",
    "h1": "Integrate APITable with SalesBlink",
    "metaDescription": "Integrate APITable and other Databases Tools with SalesBlink. Two Way APITable Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Click Connector",
    "slug": "click-connector",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0afee224b570c8413a6fb509548adfe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Click Connector is an Omni-Channel Customer Communication Platform for Sales and Support Teams",
    "category": "Communication",
    "title": "Click Connector Integration With Cold Email Sales Tool",
    "h1": "Integrate Click Connector with SalesBlink",
    "metaDescription": "Integrate Click Connector and other Communication Tools with SalesBlink. Two Way Click Connector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Feature Watch",
    "slug": "feature-watch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a33912f3f38a0ca510b2cf9cb32ec20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Feature Watch is a productivity tool to help capture and track user feedback for your SaaS application.",
    "category": "Product Management",
    "title": "Feature Watch Integration With Cold Email Sales Tool",
    "h1": "Integrate Feature Watch with SalesBlink",
    "metaDescription": "Integrate Feature Watch and other Product Management Tools with SalesBlink. Two Way Feature Watch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tandora Changelog",
    "slug": "tandora-changelog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/72bc0a42bfa7565f15ea6e8e51e9ae80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tandora Changelog - Shout loud to your customers. Let your customers know about your website updates, changelog and much more through Tandora.",
    "category": "Product Management",
    "title": "Tandora Changelog Integration With Cold Email Sales Tool",
    "h1": "Integrate Tandora Changelog with SalesBlink",
    "metaDescription": "Integrate Tandora Changelog and other Product Management Tools with SalesBlink. Two Way Tandora Changelog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clarifai",
    "slug": "clarifai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/456c8c3638cfec28991ab88ac12e4206.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gain Valuable Insights from Unstructured Data with Clarifai",
    "category": "Developer Tools",
    "title": "Clarifai Integration With Cold Email Sales Tool",
    "h1": "Integrate Clarifai with SalesBlink",
    "metaDescription": "Integrate Clarifai and other Developer Tools Tools with SalesBlink. Two Way Clarifai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtual In/Out",
    "slug": "virtual-inout-board",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/968d00006c82d41697278c8846691bcb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Virtual In/Out saves you time and money by helping you better communicate the status of your workforce and site visitors.",
    "category": "Team Collaboration",
    "title": "Virtual In/Out Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtual In/Out with SalesBlink",
    "metaDescription": "Integrate Virtual In/Out and other Team Collaboration Tools with SalesBlink. Two Way Virtual In/Out Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoodSeeker",
    "slug": "goodseeker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51d1374b4c4a62ad31d8a92dbe146475.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoodSeeker is a tool to capture and share employee success stories and promote positive work culture.",
    "category": "Team Collaboration",
    "title": "GoodSeeker Integration With Cold Email Sales Tool",
    "h1": "Integrate GoodSeeker with SalesBlink",
    "metaDescription": "Integrate GoodSeeker and other Team Collaboration Tools with SalesBlink. Two Way GoodSeeker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "nandbox",
    "slug": "nandbox",
    "logo": "https://zapier-images.imgix.net/storage/developer/d6a3d79df26cf6f353230edfb0d1e6bb_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nandbox Messenger is a free messaging app built for personal and professional use. With multiple profiles and versatile messaging options, nandbox Messenger helps you keep your chats organized and private — all in a single app. That's plus interactive channels of unlimited subscribers, you can join or create yourself. You can also have groups of up to 10,000 members. And - at any time - you can recall or edit your wrong-sent messages.",
    "category": "App Builder",
    "title": "nandbox Integration With Cold Email Sales Tool",
    "h1": "Integrate nandbox with SalesBlink",
    "metaDescription": "Integrate nandbox and other App Builder Tools with SalesBlink. Two Way nandbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onurix",
    "slug": "onurix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/071d44c5ae62fdd85927e9001f674bf6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onurix es una plataforma de comunicaciones masivas de fácil integración a otros sistemas de información.",
    "category": "Phone & SMS",
    "title": "Onurix Integration With Cold Email Sales Tool",
    "h1": "Integrate Onurix with SalesBlink",
    "metaDescription": "Integrate Onurix and other Phone & SMS Tools with SalesBlink. Two Way Onurix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClinicSoftware.com",
    "slug": "clinicsoftwarecom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c47ffa02e82f6aaa446ef9da5e930dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClinicSoftware.com is a full suite of cloud-based tools created to help appointment-based businesses to Grow Sales & Save Time.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ClinicSoftware.com Integration With Cold Email Sales Tool",
    "h1": "Integrate ClinicSoftware.com with SalesBlink",
    "metaDescription": "Integrate ClinicSoftware.com and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ClinicSoftware.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rednote",
    "slug": "rednote",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/83a259614374ae363c560097c01253d0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rednote is a Lead management tool which helps business people to manage their sales leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Rednote Integration With Cold Email Sales Tool",
    "h1": "Integrate Rednote with SalesBlink",
    "metaDescription": "Integrate Rednote and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Rednote Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadSpot",
    "slug": "leadspot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4fa4beaff423fb178a8f9806c659124.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sales professionals use LeadSpot B2B lead generation data platform to easily reveal huge amounts of untapped sales opportunities in seconds - companies, employees and decision-makers with accurate contact details.",
    "category": "Sales & CRM",
    "title": "LeadSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadSpot with SalesBlink",
    "metaDescription": "Integrate LeadSpot and other Sales & CRM Tools with SalesBlink. Two Way LeadSpot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brief",
    "slug": "brief",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e383b0009ccfd1ecf08251c129ce37ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brief is a team collaboration tool enabling team members to prioritize and focus on important tasks while minimizing distractions.",
    "category": "Team Collaboration",
    "title": "Brief Integration With Cold Email Sales Tool",
    "h1": "Integrate Brief with SalesBlink",
    "metaDescription": "Integrate Brief and other Team Collaboration Tools with SalesBlink. Two Way Brief Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chatbase",
    "slug": "chatbase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c96a682e100a38b213782a4b85d759f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Chatbase, you can create a custom GPT chatbot that knows your data. Upload a pdf or a link and get a chatbot that has the info on it.",
    "category": "AI Tools",
    "title": "Chatbase Integration With Cold Email Sales Tool",
    "h1": "Integrate Chatbase with SalesBlink",
    "metaDescription": "Integrate Chatbase and other AI Tools Tools with SalesBlink. Two Way Chatbase Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Dialed",
    "slug": "dialed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/35aabfbfa8c4c54feedad0cef3314127.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dialed is a simple but powerful business phone number app.",
    "category": "Communication",
    "title": "Dialed Integration With Cold Email Sales Tool",
    "h1": "Integrate Dialed with SalesBlink",
    "metaDescription": "Integrate Dialed and other Communication Tools with SalesBlink. Two Way Dialed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CommunityForce",
    "slug": "communityforce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b923a88a3bdef91a37c8885103bc9573_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate the tedious, repetitive tasks in your application review and workflow process. Save time and focus on maximizing your impact.",
    "category": "Forms & Surveys",
    "title": "CommunityForce Integration With Cold Email Sales Tool",
    "h1": "Integrate CommunityForce with SalesBlink",
    "metaDescription": "Integrate CommunityForce and other Forms & Surveys Tools with SalesBlink. Two Way CommunityForce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PayWhirl",
    "slug": "paywhirl",
    "logo": "https://zapier-images.imgix.net/storage/services/e6beb81a89ad29531881dfdab5623512.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PayWhirl provides powerful widgets & tools to help you manage your recurring billing like a pro - sell subscriptions, pre-orders, payment plans and much more.",
    "category": "Payment Processing",
    "title": "PayWhirl Integration With Cold Email Sales Tool",
    "h1": "Integrate PayWhirl with SalesBlink",
    "metaDescription": "Integrate PayWhirl and other Payment Processing Tools with SalesBlink. Two Way PayWhirl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMS Connexion",
    "slug": "sms-connexion",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/832af8e838b092a0b5f3d7912f2a81ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMS Connexion offers reliable, secure and cost-efficient SMS services. With this Zaper Integration you can transactional SMS, Bulk SMS, import contacts, get balance and more",
    "category": "Phone & SMS",
    "title": "SMS Connexion Integration With Cold Email Sales Tool",
    "h1": "Integrate SMS Connexion with SalesBlink",
    "metaDescription": "Integrate SMS Connexion and other Phone & SMS Tools with SalesBlink. Two Way SMS Connexion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yardi Kube",
    "slug": "yardi-kube",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f80d844c282e6442c1dc4204931ffc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yardi Kube is an all-in-one coworking management platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Yardi Kube Integration With Cold Email Sales Tool",
    "h1": "Integrate Yardi Kube with SalesBlink",
    "metaDescription": "Integrate Yardi Kube and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Yardi Kube Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snappy Gifts",
    "slug": "snappy-gifts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91a97aa51a779ca8716ee6d8a43ec365.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snappy Gifts is the all-in-one gifting platform that combines fun, personal gifting experiences with advanced technology.",
    "category": "Customer Appreciation",
    "title": "Snappy Gifts Integration With Cold Email Sales Tool",
    "h1": "Integrate Snappy Gifts with SalesBlink",
    "metaDescription": "Integrate Snappy Gifts and other Customer Appreciation Tools with SalesBlink. Two Way Snappy Gifts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "tyntec Conversations",
    "slug": "tyntec",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b699903b5708c22beddeb289fe2d7ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tyntec is a communications platform providing simple access for businesses to connect with people – via SMS, WhatsApp, and Viber",
    "category": "Communication",
    "title": "tyntec Conversations Integration With Cold Email Sales Tool",
    "h1": "Integrate tyntec Conversations with SalesBlink",
    "metaDescription": "Integrate tyntec Conversations and other Communication Tools with SalesBlink. Two Way tyntec Conversations Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vanilla",
    "slug": "vanilla-message",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ef96d49aa8f05e61270a684de24945c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vanilla helps businesses grow by increasing customer engagement with messaging campaigns.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Vanilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Vanilla with SalesBlink",
    "metaDescription": "Integrate Vanilla and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Vanilla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WorkAdventure",
    "slug": "workadventure",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/11be53bd0c5a041fdaaa4b21676ee40d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Welcome to WorkAdventure: your virtual office, your digital event and your virtual campus! Let's think outside the box and imagine a place where you can gather with your teammates wherever you are!",
    "category": "Video Conferencing",
    "title": "WorkAdventure Integration With Cold Email Sales Tool",
    "h1": "Integrate WorkAdventure with SalesBlink",
    "metaDescription": "Integrate WorkAdventure and other Video Conferencing Tools with SalesBlink. Two Way WorkAdventure Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadberry",
    "slug": "leadberry",
    "logo": "https://zapier-images.imgix.net/storage/developer/4a7e8e759381b9b45bb7e30eb86259f6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadberry is a lead generation platform that converts website visitors to sales leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Leadberry Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadberry with SalesBlink",
    "metaDescription": "Integrate Leadberry and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Leadberry Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Filestage",
    "slug": "filestage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a22ed12575f013d1b2476063b006d23.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Share, review and approve all your files in one place and enjoy a faster, more productive approval process",
    "category": "Team Collaboration",
    "title": "Filestage Integration With Cold Email Sales Tool",
    "h1": "Integrate Filestage with SalesBlink",
    "metaDescription": "Integrate Filestage and other Team Collaboration Tools with SalesBlink. Two Way Filestage Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Studeo",
    "slug": "studeo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53483e4f32e3fcc37aad52e65926e588.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The world's first interactive storytelling platform for real estate.",
    "category": "Website Builders",
    "title": "Studeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Studeo with SalesBlink",
    "metaDescription": "Integrate Studeo and other Website Builders Tools with SalesBlink. Two Way Studeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docugami",
    "slug": "docugami",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a43fcf90df7fd38d248348739b8087c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bring document content to your business workflows.",
    "category": "Documents",
    "title": "Docugami Integration With Cold Email Sales Tool",
    "h1": "Integrate Docugami with SalesBlink",
    "metaDescription": "Integrate Docugami and other Documents Tools with SalesBlink. Two Way Docugami Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "uCalc",
    "slug": "ucalc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5087983f620aa5f7bc96af43b546d790_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The builder of online calculators uCalc allows you to quickly build a calculator to estimate the costs of business services of any complexity.",
    "category": "Forms & Surveys",
    "title": "uCalc Integration With Cold Email Sales Tool",
    "h1": "Integrate uCalc with SalesBlink",
    "metaDescription": "Integrate uCalc and other Forms & Surveys Tools with SalesBlink. Two Way uCalc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TIDY",
    "slug": "tidy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff710a7be649f86a0e7cdb1a83a539bc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TIDY lets you book and manage cleaning and maintenance for homes, offices, or rental properties.",
    "category": "eCommerce",
    "title": "TIDY Integration With Cold Email Sales Tool",
    "h1": "Integrate TIDY with SalesBlink",
    "metaDescription": "Integrate TIDY and other eCommerce Tools with SalesBlink. Two Way TIDY Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Launch Control",
    "slug": "launch-control",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b083d276a8806c62f81a95096cfe1188.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Launch Control is a marketing tool that helps real estate investors reach their leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Launch Control Integration With Cold Email Sales Tool",
    "h1": "Integrate Launch Control with SalesBlink",
    "metaDescription": "Integrate Launch Control and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Launch Control Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TalentCards",
    "slug": "talentcards",
    "logo": "https://zapier-images.imgix.net/storage/services/edb0093a670a3281d8b9b295bdbf9de9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TalentCards is a mobile learning solution that lets you deliver fast and efficient training on the go.",
    "category": "Online Courses",
    "title": "TalentCards Integration With Cold Email Sales Tool",
    "h1": "Integrate TalentCards with SalesBlink",
    "metaDescription": "Integrate TalentCards and other Online Courses Tools with SalesBlink. Two Way TalentCards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voicepartner",
    "slug": "voicepartner",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08ffd6db4b57d8b30615c1116e2777d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Voicepartner allows you to send voice messages to phones",
    "category": "Phone & SMS",
    "title": "Voicepartner Integration With Cold Email Sales Tool",
    "h1": "Integrate Voicepartner with SalesBlink",
    "metaDescription": "Integrate Voicepartner and other Phone & SMS Tools with SalesBlink. Two Way Voicepartner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brafton",
    "slug": "brafton",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9c201070fbd655904ef0808cf97c52c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brafton is a team collaboration tool to manage your projects.",
    "category": "Team Collaboration",
    "title": "Brafton Integration With Cold Email Sales Tool",
    "h1": "Integrate Brafton with SalesBlink",
    "metaDescription": "Integrate Brafton and other Team Collaboration Tools with SalesBlink. Two Way Brafton Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yoobi",
    "slug": "yoobi-software",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/da3b99d7b9ad069373b6c0ea1fa782d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yoobi is Cloud software for CRM, project management, time tracking, leave, expenses, planning and invoicing in one tool. Yoobi is used by companies of all sizes.",
    "category": "Productivity",
    "title": "Yoobi Integration With Cold Email Sales Tool",
    "h1": "Integrate Yoobi with SalesBlink",
    "metaDescription": "Integrate Yoobi and other Productivity Tools with SalesBlink. Two Way Yoobi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Growthworks",
    "slug": "growthworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eac758ebf5671512e24199240f63fa5b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Growthworks is a marketing and advertising copy generation tool that generates qualified prospects and customers like clockwork in minutes.",
    "category": "Marketing Automation",
    "title": "Growthworks Integration With Cold Email Sales Tool",
    "h1": "Integrate Growthworks with SalesBlink",
    "metaDescription": "Integrate Growthworks and other Marketing Automation Tools with SalesBlink. Two Way Growthworks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BSI",
    "slug": "bsi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5468a1c47ef90c2644c5bda124e16de1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unsere Softwarelösungen vereinen das Beste von CRM und Marketing Automation und überzeugen als intelligente und benutzerfreundliche Helfer.",
    "category": "CRM (Customer Relationship Management)",
    "title": "BSI Integration With Cold Email Sales Tool",
    "h1": "Integrate BSI with SalesBlink",
    "metaDescription": "Integrate BSI and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way BSI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "StoryXpress",
    "slug": "storyxpress",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eed2cdf3fb5a220246182aa736a6e7b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoryXpress is a cloud-based video platform that accelerates businesses by creating, hosting and analyzing videos at scale.",
    "category": "Marketing",
    "title": "StoryXpress Integration With Cold Email Sales Tool",
    "h1": "Integrate StoryXpress with SalesBlink",
    "metaDescription": "Integrate StoryXpress and other Marketing Tools with SalesBlink. Two Way StoryXpress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Devi AI",
    "slug": "devi-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/789f18f2ed2b1efb0442cdc3d4a6a96b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Devi monitors social media for lead and mentions",
    "category": "Marketing",
    "title": "Devi AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Devi AI with SalesBlink",
    "metaDescription": "Integrate Devi AI and other Marketing Tools with SalesBlink. Two Way Devi AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailifier",
    "slug": "mailifier",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42e2cd8b51f74567304ceee3de0bbda0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailifier is a full-feature email verification service, which allows you to get rid of problems with email campaigns, increase the deliverability of emails and avoid blocking by the email provider.",
    "category": "Email",
    "title": "Mailifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailifier with SalesBlink",
    "metaDescription": "Integrate Mailifier and other Email Tools with SalesBlink. Two Way Mailifier Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "mProfi",
    "slug": "mprofi",
    "logo": "https://zapier-images.imgix.net/storage/services/49a2f9b4248119cfe95950800641542b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mProfi is a service that lets you send and receive SMS and more.",
    "category": "Phone & SMS",
    "title": "mProfi Integration With Cold Email Sales Tool",
    "h1": "Integrate mProfi with SalesBlink",
    "metaDescription": "Integrate mProfi and other Phone & SMS Tools with SalesBlink. Two Way mProfi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prodsmart",
    "slug": "prodsmart",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3e06b79562e96791a1a7114365d0e3b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prodsmart is your real-time information system. Get clear and actual data about your factory’s performance, reduce waste and increase efficiency. Prodsmart means better production and order management.",
    "category": "Product Management",
    "title": "Prodsmart Integration With Cold Email Sales Tool",
    "h1": "Integrate Prodsmart with SalesBlink",
    "metaDescription": "Integrate Prodsmart and other Product Management Tools with SalesBlink. Two Way Prodsmart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hudu",
    "slug": "hudu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e186050a8a63e68e76ddfbb84ce83eb_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hudu is powerful documentation software for IT professionals.",
    "category": "IT Operations",
    "title": "Hudu Integration With Cold Email Sales Tool",
    "h1": "Integrate Hudu with SalesBlink",
    "metaDescription": "Integrate Hudu and other IT Operations Tools with SalesBlink. Two Way Hudu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "once.to",
    "slug": "onceto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3bc8030c3cfab74c3a63ccd68f1d823f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "once.to is a modern, blazing-fast URL shortener with tons of features including click tracking, targeting and stats.",
    "category": "Marketing Automation",
    "title": "once.to Integration With Cold Email Sales Tool",
    "h1": "Integrate once.to with SalesBlink",
    "metaDescription": "Integrate once.to and other Marketing Automation Tools with SalesBlink. Two Way once.to Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OfficeTimer",
    "slug": "officetimer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5cba45d6f77a4093737a63a63fdc0c56_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OfficeTimer is an online office management software that helps office owners to track their employees\ntimesheet, attendance, leave and payroll systems.",
    "category": "Time Tracking Software",
    "title": "OfficeTimer Integration With Cold Email Sales Tool",
    "h1": "Integrate OfficeTimer with SalesBlink",
    "metaDescription": "Integrate OfficeTimer and other Time Tracking Software Tools with SalesBlink. Two Way OfficeTimer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fox TAS",
    "slug": "fox-tas",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f4bfb431c4afaa512a64f6493aa3130.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fox TAS is a cloud-based phone answering service that lets virtual receptionists handle calls, SMS, communicate with clients and much more.",
    "category": "Phone & SMS",
    "title": "Fox TAS Integration With Cold Email Sales Tool",
    "h1": "Integrate Fox TAS with SalesBlink",
    "metaDescription": "Integrate Fox TAS and other Phone & SMS Tools with SalesBlink. Two Way Fox TAS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Safetymails",
    "slug": "safety-mails",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30102d0ccf8576be28fdab32e6b2dece.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SafetyMails is a validation and verification of e-mails for databases and forms. Quickly know which emails you can use in your email marketing without harming your reputation.",
    "category": "Email",
    "title": "Safetymails Integration With Cold Email Sales Tool",
    "h1": "Integrate Safetymails with SalesBlink",
    "metaDescription": "Integrate Safetymails and other Email Tools with SalesBlink. Two Way Safetymails Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Personalize",
    "slug": "personalize",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c4f134eaaefe55b05ebdec709d76511b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Personalize is a tool to identify what each customer is most interested in at any given moment so you can promote the right product or service at the right time.",
    "category": "Marketing Automation",
    "title": "Personalize Integration With Cold Email Sales Tool",
    "h1": "Integrate Personalize with SalesBlink",
    "metaDescription": "Integrate Personalize and other Marketing Automation Tools with SalesBlink. Two Way Personalize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSEagle",
    "slug": "smseagle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/edeb710b44a3da52ce1055f2b2ad4e56_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSEagle is a hardware SMS gateway for sending and receiving SMS. Purchase SMSEagle device, use your own SIM and save money on SMS sending costs.",
    "category": "Phone & SMS",
    "title": "SMSEagle Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSEagle with SalesBlink",
    "metaDescription": "Integrate SMSEagle and other Phone & SMS Tools with SalesBlink. Two Way SMSEagle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Allswers",
    "slug": "allswers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/93fb1225038aac98414c40e886f90cbc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate surveys that deliver value to your company by boosting customer and employee loyalty and measuring quality processes.",
    "category": "Analytics",
    "title": "Allswers Integration With Cold Email Sales Tool",
    "h1": "Integrate Allswers with SalesBlink",
    "metaDescription": "Integrate Allswers and other Analytics Tools with SalesBlink. Two Way Allswers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nightfall DLP",
    "slug": "nightfall-dlp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/baa41ed400d76fc040ec9fcb6f6805eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Data loss prevention & data classification for security & compliance.",
    "category": "Security & Identity Tools",
    "title": "Nightfall DLP Integration With Cold Email Sales Tool",
    "h1": "Integrate Nightfall DLP with SalesBlink",
    "metaDescription": "Integrate Nightfall DLP and other Security & Identity Tools Tools with SalesBlink. Two Way Nightfall DLP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Click to Buy Services",
    "slug": "click-to-buy-services",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/66ad7eed037d635e3bc39588d9df54c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Click to Buy Services is an easy-to-use, quick-to-deploy, and flexible e-commerce platform that (finally) allows home services companies to sell their services online.",
    "category": "Sales & CRM",
    "title": "Click to Buy Services Integration With Cold Email Sales Tool",
    "h1": "Integrate Click to Buy Services with SalesBlink",
    "metaDescription": "Integrate Click to Buy Services and other Sales & CRM Tools with SalesBlink. Two Way Click to Buy Services Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CXera",
    "slug": "cxera",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/80000d5b07f04623f5d41de5129993c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CXera enables “one-click” creation of microsites that help you collaborate, track, take action and continuously improve customer success.",
    "category": "Customer Support",
    "title": "CXera Integration With Cold Email Sales Tool",
    "h1": "Integrate CXera with SalesBlink",
    "metaDescription": "Integrate CXera and other Customer Support Tools with SalesBlink. Two Way CXera Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rali",
    "slug": "rali",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/993084ecbfb10aa0ec17cd245790eea9_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rali is an application that drives engagement around video content, thereby building community, brand awareness, and insights into your content.",
    "category": "Team Collaboration",
    "title": "Rali Integration With Cold Email Sales Tool",
    "h1": "Integrate Rali with SalesBlink",
    "metaDescription": "Integrate Rali and other Team Collaboration Tools with SalesBlink. Two Way Rali Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Guestmeter",
    "slug": "guestmeter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e6115f3b3ee9b0d350591a4397372f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Guestmeter is a hotel reputation management tool to boost your positive reviews.",
    "category": "Reviews",
    "title": "Guestmeter Integration With Cold Email Sales Tool",
    "h1": "Integrate Guestmeter with SalesBlink",
    "metaDescription": "Integrate Guestmeter and other Reviews Tools with SalesBlink. Two Way Guestmeter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyDesk",
    "slug": "mydesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/361ed145db1468976352fab648d935db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyDesk: Get started quickly with a user-friendly CRM for sales.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MyDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate MyDesk with SalesBlink",
    "metaDescription": "Integrate MyDesk and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MyDesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LastPass",
    "slug": "lastpass",
    "logo": "https://zapier-images.imgix.net/storage/services/53e585151d502a36720709baa0ebc02f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LastPass (a product of LogMeIn) is a freemium password manager that stores encrypted passwords online.",
    "category": "Security & Identity Tools",
    "title": "LastPass Integration With Cold Email Sales Tool",
    "h1": "Integrate LastPass with SalesBlink",
    "metaDescription": "Integrate LastPass and other Security & Identity Tools Tools with SalesBlink. Two Way LastPass Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Intractly (Text)",
    "slug": "intractly-text",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b7c223de760ebccfeb2254c535f289d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intractly provides a service to send instant messages to users on WhatsApp.",
    "category": "Phone & SMS",
    "title": "Intractly (Text) Integration With Cold Email Sales Tool",
    "h1": "Integrate Intractly (Text) with SalesBlink",
    "metaDescription": "Integrate Intractly (Text) and other Phone & SMS Tools with SalesBlink. Two Way Intractly (Text) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hotprospector Reseller",
    "slug": "hotprospector-reseller",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18fa9deea16d06d1d61b25d3b6698195_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hotprospector Reseller is a private App use only for Reseller user to manager Manager and Members users.",
    "category": "Marketing Automation",
    "title": "Hotprospector Reseller Integration With Cold Email Sales Tool",
    "h1": "Integrate Hotprospector Reseller with SalesBlink",
    "metaDescription": "Integrate Hotprospector Reseller and other Marketing Automation Tools with SalesBlink. Two Way Hotprospector Reseller Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SendMe",
    "slug": "sendme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5124525c0dbf131d6df3d7d2b31cf89c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendme is a service for sending SMS automatically",
    "category": "Communication",
    "title": "SendMe Integration With Cold Email Sales Tool",
    "h1": "Integrate SendMe with SalesBlink",
    "metaDescription": "Integrate SendMe and other Communication Tools with SalesBlink. Two Way SendMe Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Refcome Teams",
    "slug": "refcome-teams",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e9b8c6fbfbc782d5f9b44250c82bf13_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Refcome Teams is a recruiting and culture-building tool to the team.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Refcome Teams Integration With Cold Email Sales Tool",
    "h1": "Integrate Refcome Teams with SalesBlink",
    "metaDescription": "Integrate Refcome Teams and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Refcome Teams Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Faqbot",
    "slug": "faqbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70aca216e9f097c4972d85d9dddf2845.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Faqbot is a bot that helps to improve customer engagement and overall customer experience.",
    "category": "Marketing Automation",
    "title": "Faqbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Faqbot with SalesBlink",
    "metaDescription": "Integrate Faqbot and other Marketing Automation Tools with SalesBlink. Two Way Faqbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Conversion Cloud",
    "slug": "conversion-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/baccf5c083faa980f34669b150c4e411.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Conversion Cloud is a lead generation and reporting tool for multiple industries.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Conversion Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Conversion Cloud with SalesBlink",
    "metaDescription": "Integrate Conversion Cloud and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Conversion Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quboo",
    "slug": "quboo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/63b5671d8bb548b49a673fa21bcd2448.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quboo is a gamification platform to have fun while you improve productivity in your organization.",
    "category": "Developer Tools",
    "title": "Quboo Integration With Cold Email Sales Tool",
    "h1": "Integrate Quboo with SalesBlink",
    "metaDescription": "Integrate Quboo and other Developer Tools Tools with SalesBlink. Two Way Quboo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZillaMetrics Intake Chat",
    "slug": "zillametrics-intake-chat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c0a0775fe6090b6db2852ad9b936a86.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZillaMetrics Intake Chat is a conversational chatbot and live chat platform that helps law firms generate higher-quality leads, answer common questions, and automate the intake and booking process.",
    "category": "Marketing",
    "title": "ZillaMetrics Intake Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate ZillaMetrics Intake Chat with SalesBlink",
    "metaDescription": "Integrate ZillaMetrics Intake Chat and other Marketing Tools with SalesBlink. Two Way ZillaMetrics Intake Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tyche Softwares",
    "slug": "tyche-softwares",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a7c24aab4a89562f75699cb6cc50d334.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tyche Softwares provides a variety of premium plugins for WooCommerce.",
    "category": "Commerce",
    "title": "Tyche Softwares Integration With Cold Email Sales Tool",
    "h1": "Integrate Tyche Softwares with SalesBlink",
    "metaDescription": "Integrate Tyche Softwares and other Commerce Tools with SalesBlink. Two Way Tyche Softwares Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocupletionForms",
    "slug": "docupletionforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/63363f054949d9a1bcd134ae12aaa79d_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocupletionForms is a conditional logic contact form program to build and manage your online forms.",
    "category": "Forms & Surveys",
    "title": "DocupletionForms Integration With Cold Email Sales Tool",
    "h1": "Integrate DocupletionForms with SalesBlink",
    "metaDescription": "Integrate DocupletionForms and other Forms & Surveys Tools with SalesBlink. Two Way DocupletionForms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commsor",
    "slug": "commsor",
    "logo": "https://zapier-images.imgix.net/storage/services/d5ad1b0a993b5ba2918c2874ac6774ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Commsor is a community operating system with all the tools you already use, tying your community data together and enabling you to unlock insights, measure impact, and build a community-led company.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Commsor Integration With Cold Email Sales Tool",
    "h1": "Integrate Commsor with SalesBlink",
    "metaDescription": "Integrate Commsor and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Commsor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brieferr",
    "slug": "brieferr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d2465a08feda0674bd76343c756eef86.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brieferr is a form analysis tool that gives insights about your users with various types of forms",
    "category": "Analytics",
    "title": "Brieferr Integration With Cold Email Sales Tool",
    "h1": "Integrate Brieferr with SalesBlink",
    "metaDescription": "Integrate Brieferr and other Analytics Tools with SalesBlink. Two Way Brieferr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Heyhack",
    "slug": "heyhack",
    "logo": "https://zapier-images.imgix.net/storage/services/4c2ca26fa758eb862e780a64192519db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Heyhack automatically scans web applications for common vulnerabilities such as broken access control, cross-site scripting, and SQL injection.",
    "category": "Security & Identity Tools",
    "title": "Heyhack Integration With Cold Email Sales Tool",
    "h1": "Integrate Heyhack with SalesBlink",
    "metaDescription": "Integrate Heyhack and other Security & Identity Tools Tools with SalesBlink. Two Way Heyhack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clicfans",
    "slug": "clicfans",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c614809fc1f433fb8309e1de8a79b1b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clicfans brinda un servicio de automatización (CRM) y además te permite crear páginas.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clicfans Integration With Cold Email Sales Tool",
    "h1": "Integrate Clicfans with SalesBlink",
    "metaDescription": "Integrate Clicfans and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clicfans Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Pipeless Recommendations",
    "slug": "pipeless",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/15cc79e10a245c06782c05775ad76b16.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Power real-time personalized recommendations and activity feeds using a simple API.",
    "category": "Developer Tools",
    "title": "Pipeless Recommendations Integration With Cold Email Sales Tool",
    "h1": "Integrate Pipeless Recommendations with SalesBlink",
    "metaDescription": "Integrate Pipeless Recommendations and other Developer Tools Tools with SalesBlink. Two Way Pipeless Recommendations Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trible",
    "slug": "trible",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a77fda4a8b951b728d0d0648bffeb5a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trible is an online platform for knowledge creators that allows you to sell your courses or memberships inside your own branded app.",
    "category": "App Builder",
    "title": "Trible Integration With Cold Email Sales Tool",
    "h1": "Integrate Trible with SalesBlink",
    "metaDescription": "Integrate Trible and other App Builder Tools with SalesBlink. Two Way Trible Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Survalyzer",
    "slug": "survalyzer-next-generation-ca793",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/528e90ec8a8ccd2ac8039ea80c09651c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Survalyzer is a professional survey tool.",
    "category": "Forms & Surveys",
    "title": "Survalyzer Integration With Cold Email Sales Tool",
    "h1": "Integrate Survalyzer with SalesBlink",
    "metaDescription": "Integrate Survalyzer and other Forms & Surveys Tools with SalesBlink. Two Way Survalyzer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vookmark",
    "slug": "vookmark",
    "logo": "https://zapier-images.imgix.net/storage/developer/b271d40cf1a3c56feea5642aeffbcd7c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vookmark is the easiest to bookmark videos across the web and mobile and watch it later on your browser, Apple TV, iOS or Android devices.",
    "category": "Bookmark Managers",
    "title": "Vookmark Integration With Cold Email Sales Tool",
    "h1": "Integrate Vookmark with SalesBlink",
    "metaDescription": "Integrate Vookmark and other Bookmark Managers Tools with SalesBlink. Two Way Vookmark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tree-Nation",
    "slug": "tree-nation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79047872afaa02ac04ab054dd32bec7e_2.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tree-Nation is a reforestation platform that allows citizens and companies to plant trees all around the world.",
    "category": "Fundraising",
    "title": "Tree-Nation Integration With Cold Email Sales Tool",
    "h1": "Integrate Tree-Nation with SalesBlink",
    "metaDescription": "Integrate Tree-Nation and other Fundraising Tools with SalesBlink. Two Way Tree-Nation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rooster",
    "slug": "rooster",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cffc7dd5ca10e3dcaf1ebe24f25b004b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rooster is an applicant tracking system built for businesses that care about people.",
    "category": "HR Talent & Recruitment",
    "title": "Rooster Integration With Cold Email Sales Tool",
    "h1": "Integrate Rooster with SalesBlink",
    "metaDescription": "Integrate Rooster and other HR Talent & Recruitment Tools with SalesBlink. Two Way Rooster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evolio",
    "slug": "evolio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7de6759e55deb80747ac38a10da99b98_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Software for law firms. Helps in simple and well-arranged managing of law cases without redundant administration. Free license for small law firms.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Evolio Integration With Cold Email Sales Tool",
    "h1": "Integrate Evolio with SalesBlink",
    "metaDescription": "Integrate Evolio and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Evolio Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Backtrack",
    "slug": "backtrack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2552a6967c4dc422d5cc5841732a1892.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Backtrack is a tool to help you record backward to remember things in the past.",
    "category": "Productivity",
    "title": "Backtrack Integration With Cold Email Sales Tool",
    "h1": "Integrate Backtrack with SalesBlink",
    "metaDescription": "Integrate Backtrack and other Productivity Tools with SalesBlink. Two Way Backtrack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MaxScheduler",
    "slug": "maxscheduler",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/739451517985715ad848e2b2ba1a7f22.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MaxScheduler is scheduling software often used in manufacturing. It can be useful to import job data from other systems and create a operations schedule.",
    "category": "Scheduling & Booking",
    "title": "MaxScheduler Integration With Cold Email Sales Tool",
    "h1": "Integrate MaxScheduler with SalesBlink",
    "metaDescription": "Integrate MaxScheduler and other Scheduling & Booking Tools with SalesBlink. Two Way MaxScheduler Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paperless Forms",
    "slug": "paperless-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8fd7a025bad182410463f9503dd10ebd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paperless Forms allows you to easily create and send custom forms on any device.",
    "category": "Forms & Surveys",
    "title": "Paperless Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Paperless Forms with SalesBlink",
    "metaDescription": "Integrate Paperless Forms and other Forms & Surveys Tools with SalesBlink. Two Way Paperless Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saay",
    "slug": "saay",
    "logo": "https://zapier-images.imgix.net/storage/services/17a3473f4b89d0aca0f194d0d1dcc460_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Saay is a powerful text messaging marketing tool for businesses to communicate and engage with their customers with two-way & bulk texting.",
    "category": "Phone & SMS",
    "title": "Saay Integration With Cold Email Sales Tool",
    "h1": "Integrate Saay with SalesBlink",
    "metaDescription": "Integrate Saay and other Phone & SMS Tools with SalesBlink. Two Way Saay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TransForm",
    "slug": "transform",
    "logo": "https://zapier-images.imgix.net/storage/developer/31aaa4053d83a5075c3411d8871a4fc8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transform lets you easily build mobile forms. Features include the ability to capture photos and audio recordings within your form.",
    "category": "Forms & Surveys",
    "title": "TransForm Integration With Cold Email Sales Tool",
    "h1": "Integrate TransForm with SalesBlink",
    "metaDescription": "Integrate TransForm and other Forms & Surveys Tools with SalesBlink. Two Way TransForm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Messaggio",
    "slug": "messaggio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/267196898ec744de7a13dcd39640828c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Messaggio is a multichannel messaging platform to help businesses notify customers via SMS, Viber, WhatsApp and VKontakte.",
    "category": "Phone & SMS",
    "title": "Messaggio Integration With Cold Email Sales Tool",
    "h1": "Integrate Messaggio with SalesBlink",
    "metaDescription": "Integrate Messaggio and other Phone & SMS Tools with SalesBlink. Two Way Messaggio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datelist",
    "slug": "datelist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94b43d2ce03a24531e34f08fab4d34cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datelist is an efficient online booking system.",
    "category": "Scheduling & Booking",
    "title": "Datelist Integration With Cold Email Sales Tool",
    "h1": "Integrate Datelist with SalesBlink",
    "metaDescription": "Integrate Datelist and other Scheduling & Booking Tools with SalesBlink. Two Way Datelist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Quickeast",
    "slug": "quickeast",
    "logo": "https://zapier-images.imgix.net/storage/services/57051b62ef43a8e2954f4c087b572f70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tools to better manage advertisements in China. Optimize ads, analyze data and leads CRM for multiple channels like WeChat and Douyin together in one place.",
    "category": "Ads & Conversion",
    "title": "Quickeast Integration With Cold Email Sales Tool",
    "h1": "Integrate Quickeast with SalesBlink",
    "metaDescription": "Integrate Quickeast and other Ads & Conversion Tools with SalesBlink. Two Way Quickeast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TPV SARAWORLD",
    "slug": "tpv-saraworld",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e952c85a8f122f3886fa38c5622c7e8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Saraworld's online TPV is the definitive program for daily work and management of beauty and hairdressing centers",
    "category": "Marketing Automation",
    "title": "TPV SARAWORLD Integration With Cold Email Sales Tool",
    "h1": "Integrate TPV SARAWORLD with SalesBlink",
    "metaDescription": "Integrate TPV SARAWORLD and other Marketing Automation Tools with SalesBlink. Two Way TPV SARAWORLD Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReviewPoint",
    "slug": "reviewpoint",
    "logo": "https://zapier-images.imgix.net/storage/services/0c1ab0b028000743991cf51eddad2cff.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReviewPoint is a review management tool that helps with review generation via automated SMS or emails, and review monitoring in one place.",
    "category": "Reviews",
    "title": "ReviewPoint Integration With Cold Email Sales Tool",
    "h1": "Integrate ReviewPoint with SalesBlink",
    "metaDescription": "Integrate ReviewPoint and other Reviews Tools with SalesBlink. Two Way ReviewPoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Climate Benefits",
    "slug": "climate-benefits",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e982bdb9531d3bfc2d08c6efa8219fa7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Climate Benefits enables employers to provide 'combating climate change' as a benefit to their employees.",
    "category": "Human Resources",
    "title": "Climate Benefits Integration With Cold Email Sales Tool",
    "h1": "Integrate Climate Benefits with SalesBlink",
    "metaDescription": "Integrate Climate Benefits and other Human Resources Tools with SalesBlink. Two Way Climate Benefits Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SubscriptionFlow",
    "slug": "subscriptionflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c2a8a7db279c6d73cd1c76767d9eb97.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SubscriptionFlow can help recurring businesses turn their billing into a competitive advantage. \nOur all-inclusive subscription features can boost the sales, marketing, and services of your business to its potential.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SubscriptionFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate SubscriptionFlow with SalesBlink",
    "metaDescription": "Integrate SubscriptionFlow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SubscriptionFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Creabl",
    "slug": "creabl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a7a110048fc304d1d60cc2549c579625.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Creabl helps you to perform a conversion rate optimization and helps you to understand and interact with your users better.",
    "category": "Analytics",
    "title": "Creabl Integration With Cold Email Sales Tool",
    "h1": "Integrate Creabl with SalesBlink",
    "metaDescription": "Integrate Creabl and other Analytics Tools with SalesBlink. Two Way Creabl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "100Hires",
    "slug": "100hires",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d031bfcb07121dd163fd795d903131ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "100Hires is a recruitment software that includes talent sourcing, and applicant tracking to accelerate your company's hiring process.",
    "category": "HR Talent & Recruitment",
    "title": "100Hires Integration With Cold Email Sales Tool",
    "h1": "Integrate 100Hires with SalesBlink",
    "metaDescription": "Integrate 100Hires and other HR Talent & Recruitment Tools with SalesBlink. Two Way 100Hires Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "dxeco",
    "slug": "dxeco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/515d4295d0e58ac0c7d1acbd08776556.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "dxeco is a SaaS management platform that helps companies discover, manage, and improve information about the SaaS they use.",
    "category": "Databases",
    "title": "dxeco Integration With Cold Email Sales Tool",
    "h1": "Integrate dxeco with SalesBlink",
    "metaDescription": "Integrate dxeco and other Databases Tools with SalesBlink. Two Way dxeco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SailPoint",
    "slug": "sailpoint",
    "logo": "https://zapier-images.imgix.net/storage/services/3d8ad9a98dfa1fdb780620e2f58e6c3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SailPoint provides enterprise identity governance solutions with on-premises and cloud-based identity management software for the most complex challenges.",
    "category": "Security & Identity Tools",
    "title": "SailPoint Integration With Cold Email Sales Tool",
    "h1": "Integrate SailPoint with SalesBlink",
    "metaDescription": "Integrate SailPoint and other Security & Identity Tools Tools with SalesBlink. Two Way SailPoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Testfuse",
    "slug": "testfuse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cdf0400f111aa413dff30ed066bc1935.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Testfuse is a pre-employment screening platform.",
    "category": "HR Talent & Recruitment",
    "title": "Testfuse Integration With Cold Email Sales Tool",
    "h1": "Integrate Testfuse with SalesBlink",
    "metaDescription": "Integrate Testfuse and other HR Talent & Recruitment Tools with SalesBlink. Two Way Testfuse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Building Manager",
    "slug": "building-manager",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a8fb8443e9f379eb56a00f8d74dfa29a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Building Manager is a Platform to Manage IOT Devices. Where you can add Site, Building, Door and Assign Device to Door.",
    "category": "Internet of Things",
    "title": "Building Manager Integration With Cold Email Sales Tool",
    "h1": "Integrate Building Manager with SalesBlink",
    "metaDescription": "Integrate Building Manager and other Internet of Things Tools with SalesBlink. Two Way Building Manager Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Autogrow",
    "slug": "autogrow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1505d27e97b6fd16b272dec709105caf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An app you can access from anywhere for quick communication with your team there at Autogrow and easy delegation for your digital marketing tasks in one place.",
    "category": "Developer Tools",
    "title": "Autogrow Integration With Cold Email Sales Tool",
    "h1": "Integrate Autogrow with SalesBlink",
    "metaDescription": "Integrate Autogrow and other Developer Tools Tools with SalesBlink. Two Way Autogrow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocsFold",
    "slug": "docsfold",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b01c7bb7a9a45b6961924799ff4ce106.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DocsFold allows anyone to auto-generate social media images, banners and PDF documents from reusable templates",
    "category": "Images & Design",
    "title": "DocsFold Integration With Cold Email Sales Tool",
    "h1": "Integrate DocsFold with SalesBlink",
    "metaDescription": "Integrate DocsFold and other Images & Design Tools with SalesBlink. Two Way DocsFold Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Great AEP",
    "slug": "great-aep",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/83e9362b326d3d0b3a51269a469bb42a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Great AEP is automated scheduling for insurance agents.",
    "category": "Scheduling & Booking",
    "title": "Great AEP Integration With Cold Email Sales Tool",
    "h1": "Integrate Great AEP with SalesBlink",
    "metaDescription": "Integrate Great AEP and other Scheduling & Booking Tools with SalesBlink. Two Way Great AEP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JustProtect",
    "slug": "justprotect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70e4bd65b84e6af46cdb834096d5f914.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JustProtect is a regulation and content-agnostic cloud-based platform that centralizes, automates, and simplifies the assessment process for companies with or without a GRC.",
    "category": "Security & Identity Tools",
    "title": "JustProtect Integration With Cold Email Sales Tool",
    "h1": "Integrate JustProtect with SalesBlink",
    "metaDescription": "Integrate JustProtect and other Security & Identity Tools Tools with SalesBlink. Two Way JustProtect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hypersay Events",
    "slug": "hypersay-events",
    "logo": "https://zapier-images.imgix.net/storage/services/987eb987a50767b2feea58ff10b845d1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hypersay Events is a digital events platform hosting live and recorded content to socially-engaged and inclusive audiences.",
    "category": "Event Management",
    "title": "Hypersay Events Integration With Cold Email Sales Tool",
    "h1": "Integrate Hypersay Events with SalesBlink",
    "metaDescription": "Integrate Hypersay Events and other Event Management Tools with SalesBlink. Two Way Hypersay Events Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SureSMS",
    "slug": "suresms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0b17fd8186f84ae1aceaeca4b3f664b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SureSMS is a SMS gateway. It allow our customers to integrate with many platforms and send SMS on particular events.",
    "category": "Phone & SMS",
    "title": "SureSMS Integration With Cold Email Sales Tool",
    "h1": "Integrate SureSMS with SalesBlink",
    "metaDescription": "Integrate SureSMS and other Phone & SMS Tools with SalesBlink. Two Way SureSMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IntelFinder",
    "slug": "intelfinder",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ac2edc8e169101faeaec2daa1d023a1b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IntelFinder is a fully automated service for customer-specific and actionable threat intelligence.",
    "category": "Security & Identity Tools",
    "title": "IntelFinder Integration With Cold Email Sales Tool",
    "h1": "Integrate IntelFinder with SalesBlink",
    "metaDescription": "Integrate IntelFinder and other Security & Identity Tools Tools with SalesBlink. Two Way IntelFinder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Riddlead",
    "slug": "riddlead",
    "logo": "https://zapier-images.imgix.net/storage/services/c90b0a2f951c2eb9bec4db1e41fc994a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Riddlead is a lead management tool which keeps your prospects organized and on track with ease.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Riddlead Integration With Cold Email Sales Tool",
    "h1": "Integrate Riddlead with SalesBlink",
    "metaDescription": "Integrate Riddlead and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Riddlead Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Smartlook",
    "slug": "smartlook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f9473adf41187d31c49a28ed4af9884.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smartlook is the missing puzzle in analyzing user behavior. Get real qualitative information that helps you understand in-depth what's going on in your app and website.",
    "category": "Analytics",
    "title": "Smartlook Integration With Cold Email Sales Tool",
    "h1": "Integrate Smartlook with SalesBlink",
    "metaDescription": "Integrate Smartlook and other Analytics Tools with SalesBlink. Two Way Smartlook Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Worklair",
    "slug": "worklairio",
    "logo": "https://zapier-images.imgix.net/storage/services/5cda1a2962507a74d825dbf83605e3b2.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Worklair is a team collaboration tool that organizes tasks and keeps projects on track",
    "category": "Project Management",
    "title": "Worklair Integration With Cold Email Sales Tool",
    "h1": "Integrate Worklair with SalesBlink",
    "metaDescription": "Integrate Worklair and other Project Management Tools with SalesBlink. Two Way Worklair Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Turbine",
    "slug": "turbine",
    "logo": "https://zapier-images.imgix.net/storage/developer/13d357f268e9fd1d7aebb9cb75561ffb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turbine makes it easy to create, review and manage purchase orders online or on your smartphone. Get control of your spending and streamline your approval process using our simple online order form.",
    "category": "eCommerce",
    "title": "Turbine Integration With Cold Email Sales Tool",
    "h1": "Integrate Turbine with SalesBlink",
    "metaDescription": "Integrate Turbine and other eCommerce Tools with SalesBlink. Two Way Turbine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Charles Universe",
    "slug": "charles-universe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2eea87af832867de4f77306ab3be97d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Charles allows your customers to interact with your business, where they are: in Chat with your with conversational commerce offerings.",
    "category": "Commerce",
    "title": "Charles Universe Integration With Cold Email Sales Tool",
    "h1": "Integrate Charles Universe with SalesBlink",
    "metaDescription": "Integrate Charles Universe and other Commerce Tools with SalesBlink. Two Way Charles Universe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OwlStat",
    "slug": "owlstat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cedc27f9788d5737886f58448e54f68a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OwlStat is an Instagram analytics tool to check your accounts' insights and statistics",
    "category": "Analytics",
    "title": "OwlStat Integration With Cold Email Sales Tool",
    "h1": "Integrate OwlStat with SalesBlink",
    "metaDescription": "Integrate OwlStat and other Analytics Tools with SalesBlink. Two Way OwlStat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inbound Now",
    "slug": "inbound-now",
    "logo": "https://zapier-images.imgix.net/storage/developer/45898849de336260c34736168a370233_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inbound now builds Marketing Plugins for WordPress and does Custom WordPress Development with a heavy emphasis on marketing & conversion rate optimization.",
    "category": "Marketing Automation",
    "title": "Inbound Now Integration With Cold Email Sales Tool",
    "h1": "Integrate Inbound Now with SalesBlink",
    "metaDescription": "Integrate Inbound Now and other Marketing Automation Tools with SalesBlink. Two Way Inbound Now Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StreamSend",
    "slug": "converted-da-96582",
    "logo": "https://zapier-images.imgix.net/storage/developer/d1fe5853c419a639e72426efa839e685.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Email marketing integration and automation - the path to more sales.",
    "category": "Email Newsletters",
    "title": "StreamSend Integration With Cold Email Sales Tool",
    "h1": "Integrate StreamSend with SalesBlink",
    "metaDescription": "Integrate StreamSend and other Email Newsletters Tools with SalesBlink. Two Way StreamSend Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upscale",
    "slug": "upscale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d18549eebbf898bcbebf315610d2759_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upscale is a sales engagement platform that helps SDRs maintain pipeline consistency & crush revenue goals faster with structured outreach.",
    "category": "Sales & CRM",
    "title": "Upscale Integration With Cold Email Sales Tool",
    "h1": "Integrate Upscale with SalesBlink",
    "metaDescription": "Integrate Upscale and other Sales & CRM Tools with SalesBlink. Two Way Upscale Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "UserEcho",
    "slug": "userecho",
    "logo": "https://zapier-images.imgix.net/storage/developer/15c1e78f9bdb4f5a1d3285d80b347ce2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UserEcho is an online customer support software. UserEcho allow to create helpdesk, ideas forum, livechat and knowledge base in one place that make it simple to provide great customer support.",
    "category": "Customer Support",
    "title": "UserEcho Integration With Cold Email Sales Tool",
    "h1": "Integrate UserEcho with SalesBlink",
    "metaDescription": "Integrate UserEcho and other Customer Support Tools with SalesBlink. Two Way UserEcho Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pushpad",
    "slug": "pushpad-da103892",
    "logo": "https://zapier-images.imgix.net/storage/developer/4ab2d7dc60ed115f2ecc519564440253.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pushpad is an easy-to-use service for sending web push notifications.",
    "category": "Notifications",
    "title": "Pushpad Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushpad with SalesBlink",
    "metaDescription": "Integrate Pushpad and other Notifications Tools with SalesBlink. Two Way Pushpad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PipeOne.me",
    "slug": "pipeoneme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e33db28dd77d69a9d6090844acefa4a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PipeOne.me is a CRM for mobile messaging. Let people ask, suggest, buy and talk to your company using mobile messages via your PipeOne number, any mobile messaging app, your website or even via QR code.",
    "category": "Customer Support",
    "title": "PipeOne.me Integration With Cold Email Sales Tool",
    "h1": "Integrate PipeOne.me with SalesBlink",
    "metaDescription": "Integrate PipeOne.me and other Customer Support Tools with SalesBlink. Two Way PipeOne.me Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "1st things 1st",
    "slug": "1st-things-1st",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/419bde4b8f613da5738f34ebd96e21f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "1st things 1st\" allows you to clarify your priorities based on your values and criteria.",
    "category": "Productivity",
    "title": "1st things 1st Integration With Cold Email Sales Tool",
    "h1": "Integrate 1st things 1st with SalesBlink",
    "metaDescription": "Integrate 1st things 1st and other Productivity Tools with SalesBlink. Two Way 1st things 1st Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sopro",
    "slug": "sopro",
    "logo": "https://zapier-images.imgix.net/storage/developer/8c7e376358b462c8102e1f9cec18cb34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sopro is a B2B prospecting service. Our experts research and engage prospects. Your business gets qualified leads in your sales funnel.",
    "category": "",
    "title": "Sopro Integration With Cold Email Sales Tool",
    "h1": "Integrate Sopro with SalesBlink",
    "metaDescription": "Integrate Sopro and other  Tools with SalesBlink. Two Way Sopro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zvanu Pārvaldnieks",
    "slug": "zvanu-parvaldnieks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/801d25d739dba7754065db4e24117eb0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zvanu Pārvaldnieks is a mobile virtual PBX service for LMT mobile subscribers.",
    "category": "Call Tracking",
    "title": "Zvanu Pārvaldnieks Integration With Cold Email Sales Tool",
    "h1": "Integrate Zvanu Pārvaldnieks with SalesBlink",
    "metaDescription": "Integrate Zvanu Pārvaldnieks and other Call Tracking Tools with SalesBlink. Two Way Zvanu Pārvaldnieks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yobi",
    "slug": "yobi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/747524508a79c03b82e0f49ba1491b15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Yobi you can make calls, send SMS messages and respond to Social Media messages in one app.",
    "category": "App Builder",
    "title": "Yobi Integration With Cold Email Sales Tool",
    "h1": "Integrate Yobi with SalesBlink",
    "metaDescription": "Integrate Yobi and other App Builder Tools with SalesBlink. Two Way Yobi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Raven Tools",
    "slug": "raven-tools",
    "logo": "https://zapier-images.imgix.net/storage/developer/51c9d1c5928a7376cd361d569cd58ba5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Raven Tools helps your fowl SEO skills.",
    "category": "Analytics",
    "title": "Raven Tools Integration With Cold Email Sales Tool",
    "h1": "Integrate Raven Tools with SalesBlink",
    "metaDescription": "Integrate Raven Tools and other Analytics Tools with SalesBlink. Two Way Raven Tools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PST",
    "slug": "pst",
    "logo": "https://zapier-images.imgix.net/storage/services/a468dbc24af09860d0da9e6aaf7cd294.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Process Server's Toolbox (PST) provides a complete set of power tools for process serving companies, their Clients, and their servers in the field - all designed to streamline service of process.",
    "category": "CRM (Customer Relationship Management)",
    "title": "PST Integration With Cold Email Sales Tool",
    "h1": "Integrate PST with SalesBlink",
    "metaDescription": "Integrate PST and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way PST Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AutoRepl",
    "slug": "autorepl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e7a15bdfcdc2be28fd6fd2d59706546a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AutoRepl is a powerful and easy no-code chatbot builder.",
    "category": "App Builder",
    "title": "AutoRepl Integration With Cold Email Sales Tool",
    "h1": "Integrate AutoRepl with SalesBlink",
    "metaDescription": "Integrate AutoRepl and other App Builder Tools with SalesBlink. Two Way AutoRepl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Surveybot",
    "slug": "surveybot",
    "logo": "https://zapier-images.imgix.net/storage/developer/c24db4c551c3e55e65eef9aedbbc7967.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Surveybot enables you to create chatbot surveys and distribute them on Facebook, Facebook Messenger and Workplace by Facebook.",
    "category": "Forms & Surveys",
    "title": "Surveybot Integration With Cold Email Sales Tool",
    "h1": "Integrate Surveybot with SalesBlink",
    "metaDescription": "Integrate Surveybot and other Forms & Surveys Tools with SalesBlink. Two Way Surveybot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Kameleoon",
    "slug": "kameleoon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2b96afc8e5d65cc4867114cf2824f9a1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kameleoon's powerful and easy-to-use A/B testing, full stack, and AI-powered personalization solutions help marketers, product owners, and developers maximize customer engagement and conversion - all from a single platform.",
    "category": "Ads & Conversion",
    "title": "Kameleoon Integration With Cold Email Sales Tool",
    "h1": "Integrate Kameleoon with SalesBlink",
    "metaDescription": "Integrate Kameleoon and other Ads & Conversion Tools with SalesBlink. Two Way Kameleoon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendmode",
    "slug": "sendmode",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f4bbb419645d339b26ae4d709119af41.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendmode is an SMS messaging application with fast secure delivery. \nSend alerts and notifications worldwide as SMS via our website, email, or API integration. \nReceive incoming SMS replies to virtual numbers and shotcodes as trigger events.",
    "category": "Phone & SMS",
    "title": "Sendmode Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendmode with SalesBlink",
    "metaDescription": "Integrate Sendmode and other Phone & SMS Tools with SalesBlink. Two Way Sendmode Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OfficeMaps",
    "slug": "officemaps",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb9ef107362a6b70f4424ef3ac5897c1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OfficeMaps giving employees better flexibility and control over their working environments with desk, room, and asset booking management.",
    "category": "Scheduling & Booking",
    "title": "OfficeMaps Integration With Cold Email Sales Tool",
    "h1": "Integrate OfficeMaps with SalesBlink",
    "metaDescription": "Integrate OfficeMaps and other Scheduling & Booking Tools with SalesBlink. Two Way OfficeMaps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SnackMagic",
    "slug": "snackmagic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/299ba3ef093cfd331aab799f5e448d0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The build-your-own snack box that’s super easy to give and ridiculously fun to get.",
    "category": "eCommerce",
    "title": "SnackMagic Integration With Cold Email Sales Tool",
    "h1": "Integrate SnackMagic with SalesBlink",
    "metaDescription": "Integrate SnackMagic and other eCommerce Tools with SalesBlink. Two Way SnackMagic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailfold",
    "slug": "mailfold",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a993a280bbe9f18163eb4b4e97a6465a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailfold sends an environmentally friendly direct mail campaign to customers anywhere in the United States. Delivery typically occurs within 3-5 business days.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mailfold Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailfold with SalesBlink",
    "metaDescription": "Integrate Mailfold and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mailfold Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Atom",
    "slug": "atomchat-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/612ce4a494779891c010915c431c8f87.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Atom is a web software created for doing automated tasks. Our bots will allow you to provide quick solutions for your clients, optimizing the time for your agents to focus on potential buyers.",
    "category": "Sales & CRM",
    "title": "Atom Integration With Cold Email Sales Tool",
    "h1": "Integrate Atom with SalesBlink",
    "metaDescription": "Integrate Atom and other Sales & CRM Tools with SalesBlink. Two Way Atom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MFPInTheCloud",
    "slug": "mfpinthecloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/831c35c75e8303f42be5788b2ffb6772.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MFPInTheCloud is a productivity app that allows you to manage all your MFPs with a single and simple application, create and design flows with the ability to use  OCR, document conversions, PDF signing, functions and more than 500 processing tools.",
    "category": "Productivity",
    "title": "MFPInTheCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate MFPInTheCloud with SalesBlink",
    "metaDescription": "Integrate MFPInTheCloud and other Productivity Tools with SalesBlink. Two Way MFPInTheCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "cloudlayer.io",
    "slug": "cloudlayerio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21b18698ff77e512c0eca7f95296d33a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "cloudlayer.io is a service that lets you generate dynamic PDF documents or Images from a URL or HTML templates.",
    "category": "Images & Design",
    "title": "cloudlayer.io Integration With Cold Email Sales Tool",
    "h1": "Integrate cloudlayer.io with SalesBlink",
    "metaDescription": "Integrate cloudlayer.io and other Images & Design Tools with SalesBlink. Two Way cloudlayer.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VideoGameSuite",
    "slug": "videogamesuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f7f16e3a17b8610b4ddd83048f88364.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VideoGameSuite is an app that creates various types of game funnels to collect your leads.",
    "category": "Marketing",
    "title": "VideoGameSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate VideoGameSuite with SalesBlink",
    "metaDescription": "Integrate VideoGameSuite and other Marketing Tools with SalesBlink. Two Way VideoGameSuite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CareTree",
    "slug": "caretree",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aee386f33c1453f583f18c8ccfc90870_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CareTree is used by both professionals and families to create a central healthcare record for a patient and have one place for everyone to communicate about that patient’s care needs.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CareTree Integration With Cold Email Sales Tool",
    "h1": "Integrate CareTree with SalesBlink",
    "metaDescription": "Integrate CareTree and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CareTree Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dola",
    "slug": "dola",
    "logo": "https://zapier-images.imgix.net/storage/services/b4c05bfd83d6e557a13189aef5713e11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dola's a digital wallet and 1-click checkout.",
    "category": "Payment Processing",
    "title": "Dola Integration With Cold Email Sales Tool",
    "h1": "Integrate Dola with SalesBlink",
    "metaDescription": "Integrate Dola and other Payment Processing Tools with SalesBlink. Two Way Dola Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contensis",
    "slug": "contensis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2db1134b8ecc5ea22ab6a828a5e79de8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contensis is an API-first CMS with a built-in deployment platform – allowing you to manage content and code releases from one place.",
    "category": "Website & App Building",
    "title": "Contensis Integration With Cold Email Sales Tool",
    "h1": "Integrate Contensis with SalesBlink",
    "metaDescription": "Integrate Contensis and other Website & App Building Tools with SalesBlink. Two Way Contensis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PeerBie",
    "slug": "peerbie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fb1f4e9efc4143e98ed80a514630f23f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PeerBie is your work in your pocket.",
    "category": "Productivity",
    "title": "PeerBie Integration With Cold Email Sales Tool",
    "h1": "Integrate PeerBie with SalesBlink",
    "metaDescription": "Integrate PeerBie and other Productivity Tools with SalesBlink. Two Way PeerBie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cutt.ly Team",
    "slug": "cuttly-team",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18d3830dc66ea3e503d615627cb5b75f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cutt.ly Team is a URL shortener with advanced links tracking & API. Link Management Platform. Simplify, track & manage your links like a pro.",
    "category": "URL Shortener",
    "title": "Cutt.ly Team Integration With Cold Email Sales Tool",
    "h1": "Integrate Cutt.ly Team with SalesBlink",
    "metaDescription": "Integrate Cutt.ly Team and other URL Shortener Tools with SalesBlink. Two Way Cutt.ly Team Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jepto",
    "slug": "jepto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b3a3f9e24af5d235e3939b760072c64_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jepto is a Marketing Intelligence and Automation tool that helps you get more done.",
    "category": "Marketing",
    "title": "Jepto Integration With Cold Email Sales Tool",
    "h1": "Integrate Jepto with SalesBlink",
    "metaDescription": "Integrate Jepto and other Marketing Tools with SalesBlink. Two Way Jepto Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Gloobobusiness",
    "slug": "gloobobusiness",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30c2bef3478cfb8bfb71295720928fda.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GlooboBusiness is a simple, straightforward VoIP service for business telephony that helps you cut billing costs without sacrificing quality.",
    "category": "Call Tracking",
    "title": "Gloobobusiness Integration With Cold Email Sales Tool",
    "h1": "Integrate Gloobobusiness with SalesBlink",
    "metaDescription": "Integrate Gloobobusiness and other Call Tracking Tools with SalesBlink. Two Way Gloobobusiness Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EmailOversight",
    "slug": "emailoversight",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/626c1956b88e5eb2216abf7c3b54e92f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EmailOversight is an email validation platform that empowers clients to solidify leads and verify subscribers with a 99.5% accuracy.",
    "category": "Email",
    "title": "EmailOversight Integration With Cold Email Sales Tool",
    "h1": "Integrate EmailOversight with SalesBlink",
    "metaDescription": "Integrate EmailOversight and other Email Tools with SalesBlink. Two Way EmailOversight Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "magnews",
    "slug": "magnews",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/354c317a4c83360d89f9e0afb5772395.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magnews is an omnichannel marketing automation platform for the design and management of conversion projects.",
    "category": "Communication",
    "title": "magnews Integration With Cold Email Sales Tool",
    "h1": "Integrate magnews with SalesBlink",
    "metaDescription": "Integrate magnews and other Communication Tools with SalesBlink. Two Way magnews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LoanOfficer.ai",
    "slug": "dev-loanofficerai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46ca596681733b5053e1c5eb9a47e311.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loan Officer.ai CRM will help you manage every front of your mortgage business and grow like never before - without the stress.",
    "category": "CRM (Customer Relationship Management)",
    "title": "LoanOfficer.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate LoanOfficer.ai with SalesBlink",
    "metaDescription": "Integrate LoanOfficer.ai and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way LoanOfficer.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Staatic",
    "slug": "staatic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f08c79ff01417d3e5cfa467093888c18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Staatic allows you to generate and deploy an optimized static version of your WordPress site, improving performance, SEO and security all at the same time.",
    "category": "Website Builders",
    "title": "Staatic Integration With Cold Email Sales Tool",
    "h1": "Integrate Staatic with SalesBlink",
    "metaDescription": "Integrate Staatic and other Website Builders Tools with SalesBlink. Two Way Staatic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BookRev",
    "slug": "bookrev",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe1ca05cffa27f590f1b60509554e50a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BookRev is an automated SMS text messaging app for authors to collect reader contact information.",
    "category": "Phone & SMS",
    "title": "BookRev Integration With Cold Email Sales Tool",
    "h1": "Integrate BookRev with SalesBlink",
    "metaDescription": "Integrate BookRev and other Phone & SMS Tools with SalesBlink. Two Way BookRev Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Colligso MailIn",
    "slug": "colligso-mailin",
    "logo": "https://zapier-images.imgix.net/storage/services/77bbb6e52723590cabb06887e6e071b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Colligso MailIn helps businesses to reach their customers to remarket their product/services using calendar-scheduled email campaigns. Colligso has pre-built and curated calendars for holidays, restaurants and game days.",
    "category": "Email",
    "title": "Colligso MailIn Integration With Cold Email Sales Tool",
    "h1": "Integrate Colligso MailIn with SalesBlink",
    "metaDescription": "Integrate Colligso MailIn and other Email Tools with SalesBlink. Two Way Colligso MailIn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Captisa Forms",
    "slug": "captisa-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aefeb9561b4b7595caa61fed7fe5863b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Captisa Forms is an online form builder with cloud database storage.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Captisa Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Captisa Forms with SalesBlink",
    "metaDescription": "Integrate Captisa Forms and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Captisa Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BrightDay",
    "slug": "brightday",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c13cbb787f0a4c1a68f5b0a714efe3c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrightDay is a life operating system, featuring a full-featured calendar, habit builder, life hacking tools and community templates you can copy to your calendar",
    "category": "Calendar",
    "title": "BrightDay Integration With Cold Email Sales Tool",
    "h1": "Integrate BrightDay with SalesBlink",
    "metaDescription": "Integrate BrightDay and other Calendar Tools with SalesBlink. Two Way BrightDay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Set a Time",
    "slug": "set-a-time",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc4b0a1aefe2a902f94c6a840e0e6ad5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Set a Time is an appointment scheduling software and scheduling app, that helps businesses accept and manage appointments, online.",
    "category": "Scheduling & Booking",
    "title": "Set a Time Integration With Cold Email Sales Tool",
    "h1": "Integrate Set a Time with SalesBlink",
    "metaDescription": "Integrate Set a Time and other Scheduling & Booking Tools with SalesBlink. Two Way Set a Time Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PieMatrix Pie",
    "slug": "piematrix-pie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/208f1f5545bdee13c2929cf5a78af449.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pie is a visual process and project management web application that combines agile with waterfall and made for repeatable project processes. Pie is designed for improving end-project results with team collaboration.",
    "category": "Project Management",
    "title": "PieMatrix Pie Integration With Cold Email Sales Tool",
    "h1": "Integrate PieMatrix Pie with SalesBlink",
    "metaDescription": "Integrate PieMatrix Pie and other Project Management Tools with SalesBlink. Two Way PieMatrix Pie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dashcam",
    "slug": "dashcam",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d1b08b75e3e6cdfb2005cfff3b04ea47.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Don't spend time explaining or reproducing bugs. Dashcam captures them the first time with desktop instant replay.",
    "category": "Developer Tools",
    "title": "Dashcam Integration With Cold Email Sales Tool",
    "h1": "Integrate Dashcam with SalesBlink",
    "metaDescription": "Integrate Dashcam and other Developer Tools Tools with SalesBlink. Two Way Dashcam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialLair",
    "slug": "sociallair",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/622433a398645f1df96f78193e9bdc70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocialLair is a platform that gives you power & control over your business on social media.",
    "category": "Commerce",
    "title": "SocialLair Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialLair with SalesBlink",
    "metaDescription": "Integrate SocialLair and other Commerce Tools with SalesBlink. Two Way SocialLair Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Motionbox",
    "slug": "motionbox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b05df6a37ace7ced96675cdd90448bff_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Motionbox is a service that provides blazing fast super robust video rendering. Design a template or select one from our library to get started.",
    "category": "Video & Audio",
    "title": "Motionbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Motionbox with SalesBlink",
    "metaDescription": "Integrate Motionbox and other Video & Audio Tools with SalesBlink. Two Way Motionbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hearme CXM platform",
    "slug": "hearme-cxm-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/366c6b8902f4c3af59518231003e92bb_2.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hearme is a software solution which helps enterprises collect responses from customers after they bought a product or service.",
    "category": "Forms & Surveys",
    "title": "Hearme CXM platform Integration With Cold Email Sales Tool",
    "h1": "Integrate Hearme CXM platform with SalesBlink",
    "metaDescription": "Integrate Hearme CXM platform and other Forms & Surveys Tools with SalesBlink. Two Way Hearme CXM platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notifii",
    "slug": "notifii",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ac97b7130ec4712ae1d6fcfe7c85b9b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notifii is a package management and notification tool for corporate mailrooms and apartment offices.",
    "category": "Notifications",
    "title": "Notifii Integration With Cold Email Sales Tool",
    "h1": "Integrate Notifii with SalesBlink",
    "metaDescription": "Integrate Notifii and other Notifications Tools with SalesBlink. Two Way Notifii Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Donsplus",
    "slug": "donsplus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c3c258e2c528622ef6bdcae91ec1330.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Donsplus is a powerful in-web donation widget for nonprofits designed to convert website visitors into valuable donors.",
    "category": "Fundraising",
    "title": "Donsplus Integration With Cold Email Sales Tool",
    "h1": "Integrate Donsplus with SalesBlink",
    "metaDescription": "Integrate Donsplus and other Fundraising Tools with SalesBlink. Two Way Donsplus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Authkey",
    "slug": "authkey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/69496f070b3930749ef509134fc4f960.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Authkey is a cloud platform to send sms, voice and email notifications using a single API",
    "category": "Notifications",
    "title": "Authkey Integration With Cold Email Sales Tool",
    "h1": "Integrate Authkey with SalesBlink",
    "metaDescription": "Integrate Authkey and other Notifications Tools with SalesBlink. Two Way Authkey Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Tability",
    "slug": "tability",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b669a6dfb4467f6e4925c878d6ab6864_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tability is a simple goal-tracking platform for OKRs and team goals",
    "category": "Team Collaboration",
    "title": "Tability Integration With Cold Email Sales Tool",
    "h1": "Integrate Tability with SalesBlink",
    "metaDescription": "Integrate Tability and other Team Collaboration Tools with SalesBlink. Two Way Tability Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Esponce",
    "slug": "esponce",
    "logo": "https://zapier-images.imgix.net/storage/developer/d77737a106122ea2a958202d0d6412f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Esponce lets you create, integrate and track QR Codes. Promote your real-world campaigns with rich & mobile optimized content to attract new fans and customers.",
    "category": "Phone & SMS",
    "title": "Esponce Integration With Cold Email Sales Tool",
    "h1": "Integrate Esponce with SalesBlink",
    "metaDescription": "Integrate Esponce and other Phone & SMS Tools with SalesBlink. Two Way Esponce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Learn Amp",
    "slug": "learnamp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87c8dcf5094761e9ee14ca3140fcdd39_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learn Amp is a People Development Platform. Engage with learning, connect with others in your business and track your performance.",
    "category": "Developer Tools",
    "title": "Learn Amp Integration With Cold Email Sales Tool",
    "h1": "Integrate Learn Amp with SalesBlink",
    "metaDescription": "Integrate Learn Amp and other Developer Tools Tools with SalesBlink. Two Way Learn Amp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teachlr Organizations",
    "slug": "teachlr-organizations",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e20249f16ac5ac9c16efdd35ed734663_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create courses, evaluate the progress of your users and grant certificates from the same platform.",
    "category": "Education",
    "title": "Teachlr Organizations Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachlr Organizations with SalesBlink",
    "metaDescription": "Integrate Teachlr Organizations and other Education Tools with SalesBlink. Two Way Teachlr Organizations Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "mallabe Websites",
    "slug": "mallabe-websites",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6fb8f61e77e919a7851b0287f3f15210_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "mallabe is an automation toolchain that allows you to check status of websites on the fly.",
    "category": "Communication",
    "title": "mallabe Websites Integration With Cold Email Sales Tool",
    "h1": "Integrate mallabe Websites with SalesBlink",
    "metaDescription": "Integrate mallabe Websites and other Communication Tools with SalesBlink. Two Way mallabe Websites Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SocialScrape",
    "slug": "socialscrape",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/405981618cc6ab4ff76c6aff79efefe1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We're an unofficial Instagram and TikTok API that can help you scrape publicly available data from both platforms through our easy to use and robust API.",
    "category": "Social Media Accounts",
    "title": "SocialScrape Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialScrape with SalesBlink",
    "metaDescription": "Integrate SocialScrape and other Social Media Accounts Tools with SalesBlink. Two Way SocialScrape Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DevCycle",
    "slug": "devcycle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e22e0cf67b9d3ba7e5bf95b486e6966.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DevCycle is a feature flagging platform that's easy to use, scalable and lightning fast.",
    "category": "Developer Tools",
    "title": "DevCycle Integration With Cold Email Sales Tool",
    "h1": "Integrate DevCycle with SalesBlink",
    "metaDescription": "Integrate DevCycle and other Developer Tools Tools with SalesBlink. Two Way DevCycle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SeekTable",
    "slug": "seektable",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/049456ef61f2b241f15e7debac102967.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Data exploration and reporting tool to create pivot tables, charts, tabular reports.",
    "category": "Analytics",
    "title": "SeekTable Integration With Cold Email Sales Tool",
    "h1": "Integrate SeekTable with SalesBlink",
    "metaDescription": "Integrate SeekTable and other Analytics Tools with SalesBlink. Two Way SeekTable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BrewSurvey",
    "slug": "brewsurvey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/96ec5d5b8eb5cbfd86347b25ee14c4dc_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrewSurvey is an online and offline data collection with weblinks and mobile survey app for collecting customer feedback, conducting market research & lead capture without internet connection anytime, anywhere.",
    "category": "Forms & Surveys",
    "title": "BrewSurvey Integration With Cold Email Sales Tool",
    "h1": "Integrate BrewSurvey with SalesBlink",
    "metaDescription": "Integrate BrewSurvey and other Forms & Surveys Tools with SalesBlink. Two Way BrewSurvey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GatedContent.com",
    "slug": "gatedcontentcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a46cc44846e8659325cd3fb8968cdb30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GatedContent.com is an enterprise web form and content-gating platform providing global data governance whilst scaling inbound lead capture for marketing automation.",
    "category": "Marketing Automation",
    "title": "GatedContent.com Integration With Cold Email Sales Tool",
    "h1": "Integrate GatedContent.com with SalesBlink",
    "metaDescription": "Integrate GatedContent.com and other Marketing Automation Tools with SalesBlink. Two Way GatedContent.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alloy Navigator",
    "slug": "alloy-navigator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6357ddd1738d0984afb9ec1225e337da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alloy Navigator is an IT Service Management and Asset Management solution.",
    "category": "Customer Support",
    "title": "Alloy Navigator Integration With Cold Email Sales Tool",
    "h1": "Integrate Alloy Navigator with SalesBlink",
    "metaDescription": "Integrate Alloy Navigator and other Customer Support Tools with SalesBlink. Two Way Alloy Navigator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WOXO",
    "slug": "woxo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c3cd825f1ad21ff2b766f8b3575bc6c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WOXO is a platform that enables you to effortlessly create videos from spreadsheets and display social content with widgets to level up your marketing.",
    "category": "Social Media Marketing",
    "title": "WOXO Integration With Cold Email Sales Tool",
    "h1": "Integrate WOXO with SalesBlink",
    "metaDescription": "Integrate WOXO and other Social Media Marketing Tools with SalesBlink. Two Way WOXO Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "WP SMS",
    "slug": "wp-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c1d378824f46dc6baf531cd0e53e134.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WP SMS is the most advanced SMS messaging and notification plugin for WordPress.",
    "category": "Phone & SMS",
    "title": "WP SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate WP SMS with SalesBlink",
    "metaDescription": "Integrate WP SMS and other Phone & SMS Tools with SalesBlink. Two Way WP SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Review Generator (AI)",
    "slug": "review-generator-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3cbc9ef319a9b83f601c53e4ed64bc5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate testimonial & review using AI. Quickly write candid testimonials and reviews for people and services in 30+ languages.",
    "category": "eCommerce",
    "title": "Review Generator (AI) Integration With Cold Email Sales Tool",
    "h1": "Integrate Review Generator (AI) with SalesBlink",
    "metaDescription": "Integrate Review Generator (AI) and other eCommerce Tools with SalesBlink. Two Way Review Generator (AI) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Space",
    "slug": "space",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1de7ca5b8bb0bb3ecb5a652ebc87fc1b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Space enables you to engage and convert customers with frictionless audio content that's hosted directly on your site.",
    "category": "Communication",
    "title": "Space Integration With Cold Email Sales Tool",
    "h1": "Integrate Space with SalesBlink",
    "metaDescription": "Integrate Space and other Communication Tools with SalesBlink. Two Way Space Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Apenday",
    "slug": "apenday",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f4e1895dd20b2e78f68cd1840a398afb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apenday creates a unique, highly customizable booking experience. The interface adapts to your visual identity (logo, colors) for perfect integration on all your devices (website, email, SMS, etc.).",
    "category": "Event Management",
    "title": "Apenday Integration With Cold Email Sales Tool",
    "h1": "Integrate Apenday with SalesBlink",
    "metaDescription": "Integrate Apenday and other Event Management Tools with SalesBlink. Two Way Apenday Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EASI'R",
    "slug": "easir",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7416a6fddb552309ea4a74ad70ac1181.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EASI'R is Sales Enablement Platform that helps your dealerships and salespeople to convert opportunities into sales like never before.",
    "category": "CRM (Customer Relationship Management)",
    "title": "EASI'R Integration With Cold Email Sales Tool",
    "h1": "Integrate EASI'R with SalesBlink",
    "metaDescription": "Integrate EASI'R and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way EASI'R Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Visual Lease",
    "slug": "visual-lease",
    "logo": "https://zapier-images.imgix.net/storage/developer/2d2adbf29600ea4874862515a7626b5a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Visual Lease is a comprehensive, powerful and easy-to-use real estate and lease management software solution that makes it easy to know what your leases say, how much they cost, and when they need attention. It enables companies to control, report, track, and manage owned and leased real estate and make better decisions on leases and related information.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Visual Lease Integration With Cold Email Sales Tool",
    "h1": "Integrate Visual Lease with SalesBlink",
    "metaDescription": "Integrate Visual Lease and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Visual Lease Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fusion Elements",
    "slug": "fusion-elements",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/16028c5734d036febe7ca5ed5565f865.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fusion Elements is a practice management tool helping and Advisors streamline and automate their business.",
    "category": "Task Management",
    "title": "Fusion Elements Integration With Cold Email Sales Tool",
    "h1": "Integrate Fusion Elements with SalesBlink",
    "metaDescription": "Integrate Fusion Elements and other Task Management Tools with SalesBlink. Two Way Fusion Elements Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "C-Me",
    "slug": "c-me",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a75e6c309a6ca7d76289d01656d74bd2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "C-Me, the video first hiring solution, lets organisations fill roles faster with video job posts and video interviews.",
    "category": "HR Talent & Recruitment",
    "title": "C-Me Integration With Cold Email Sales Tool",
    "h1": "Integrate C-Me with SalesBlink",
    "metaDescription": "Integrate C-Me and other HR Talent & Recruitment Tools with SalesBlink. Two Way C-Me Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Labelf AI",
    "slug": "labelf-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9dde4c12cd3f278436550af8049a023d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Labelf is an AI NLP platform which lets you train your own text classification models from scratch and deploy them in minutes.",
    "category": "App Builder",
    "title": "Labelf AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Labelf AI with SalesBlink",
    "metaDescription": "Integrate Labelf AI and other App Builder Tools with SalesBlink. Two Way Labelf AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wibbitz",
    "slug": "wibbitz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c0f226e696b148e8df2db3f5830b44da.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wibbitz is an online video creation software that gives you the freedom to create short-form videos online in minutes – no experience necessary",
    "category": "Video & Audio",
    "title": "Wibbitz Integration With Cold Email Sales Tool",
    "h1": "Integrate Wibbitz with SalesBlink",
    "metaDescription": "Integrate Wibbitz and other Video & Audio Tools with SalesBlink. Two Way Wibbitz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WholeStory",
    "slug": "wholestory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa6884793d6f4547f28cff5154d9b321.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WholeStory is a technology platform for employers that integrates the candidate's pivotal life experiences into the screening and interview process. This uncovers critical soft skills not revealed in the resume and interview.",
    "category": "HR Talent & Recruitment",
    "title": "WholeStory Integration With Cold Email Sales Tool",
    "h1": "Integrate WholeStory with SalesBlink",
    "metaDescription": "Integrate WholeStory and other HR Talent & Recruitment Tools with SalesBlink. Two Way WholeStory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evolution Forms",
    "slug": "evolution-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5004a87bdb94409177d57b6e73629c2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evolution Forms is a form designer focused on lead generation and speed.",
    "category": "Forms & Surveys",
    "title": "Evolution Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Evolution Forms with SalesBlink",
    "metaDescription": "Integrate Evolution Forms and other Forms & Surveys Tools with SalesBlink. Two Way Evolution Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AirGift",
    "slug": "airgift",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f566a13ad1470bc58dd6dab99e223115.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AirGift.io is a platform for creating and offering NFTs to businesses. In a few minutes, any company can create its own NFT collection, set up trigger events and start offering NFTs to its community.",
    "category": "Website Builders",
    "title": "AirGift Integration With Cold Email Sales Tool",
    "h1": "Integrate AirGift with SalesBlink",
    "metaDescription": "Integrate AirGift and other Website Builders Tools with SalesBlink. Two Way AirGift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gaviti",
    "slug": "gaviti",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e722742a83f695b9d2099e824613a00_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gaviti is an A/R collections management platform that is ERP agnostic and was built for collectors by collectors.",
    "category": "Accounting",
    "title": "Gaviti Integration With Cold Email Sales Tool",
    "h1": "Integrate Gaviti with SalesBlink",
    "metaDescription": "Integrate Gaviti and other Accounting Tools with SalesBlink. Two Way Gaviti Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evisort",
    "slug": "evisort",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8fe5ee3a4697c03f7f4ef39e128030c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evisort's is an AI-powered contract management software.",
    "category": "Documents",
    "title": "Evisort Integration With Cold Email Sales Tool",
    "h1": "Integrate Evisort with SalesBlink",
    "metaDescription": "Integrate Evisort and other Documents Tools with SalesBlink. Two Way Evisort Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cohere AI",
    "slug": "cohere-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5a1248d1fb74274f262f02374889ea1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cohere provides access to advanced Large Language Models and NLP tools. To try out these endpoints visit: https://dashboard.cohere.ai/playground/.",
    "category": "AI Tools",
    "title": "Cohere AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Cohere AI with SalesBlink",
    "metaDescription": "Integrate Cohere AI and other AI Tools Tools with SalesBlink. Two Way Cohere AI Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "AimFox",
    "slug": "aimfox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4bd528dbfbe2ae299d376b6a8b49842b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reach a wider audience with a powerful LinkedIn automation tool. AimFox is made for sales, marketing, HR teams and everyone else who wishes to make automated LinkedIn connections.",
    "category": "Marketing Automation",
    "title": "AimFox Integration With Cold Email Sales Tool",
    "h1": "Integrate AimFox with SalesBlink",
    "metaDescription": "Integrate AimFox and other Marketing Automation Tools with SalesBlink. Two Way AimFox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kyvio 2",
    "slug": "kyvio2",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2a3ec640b616b9dce58ede453653b3fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kyvio uses Smart Funnels, Smart Memberships, Smart Product and more to helps creators build, manage and sell their products.",
    "category": "Marketing",
    "title": "Kyvio 2 Integration With Cold Email Sales Tool",
    "h1": "Integrate Kyvio 2 with SalesBlink",
    "metaDescription": "Integrate Kyvio 2 and other Marketing Tools with SalesBlink. Two Way Kyvio 2 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brandlive",
    "slug": "brandlive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2c80766b6bd093738aaa23473efb4975.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brandlive is a video-first enterprise virtual events solution for product launches, internal communications, sales meetings, etc.",
    "category": "Webinars",
    "title": "Brandlive Integration With Cold Email Sales Tool",
    "h1": "Integrate Brandlive with SalesBlink",
    "metaDescription": "Integrate Brandlive and other Webinars Tools with SalesBlink. Two Way Brandlive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Refundme.io",
    "slug": "refundmeio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8c8dac0064e7338113189f6e3a9b473b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Refundme.io's standardised technology and processes increase speed, volume, security and efficiency of refunds and payments.",
    "category": "Customer Support",
    "title": "Refundme.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Refundme.io with SalesBlink",
    "metaDescription": "Integrate Refundme.io and other Customer Support Tools with SalesBlink. Two Way Refundme.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Third Light Chorus",
    "slug": "third-light-chorus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6c8d2cf483305b8dff1091a00ebfae79.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chorus is a digital asset management (DAM) system which helps teams to store, share, search and re-use and publish content",
    "category": "File Management & Storage",
    "title": "Third Light Chorus Integration With Cold Email Sales Tool",
    "h1": "Integrate Third Light Chorus with SalesBlink",
    "metaDescription": "Integrate Third Light Chorus and other File Management & Storage Tools with SalesBlink. Two Way Third Light Chorus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebHR",
    "slug": "webhr-da19376",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e4e027d4df9009c62ce3fff2924b682_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Everyone's Favorite All-in-One Social HR Software - trusted by over 25,000 companies in 200 countries globally",
    "category": "Human Resources",
    "title": "WebHR Integration With Cold Email Sales Tool",
    "h1": "Integrate WebHR with SalesBlink",
    "metaDescription": "Integrate WebHR and other Human Resources Tools with SalesBlink. Two Way WebHR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Circuit",
    "slug": "circuit",
    "logo": "https://zapier-images.imgix.net/storage/services/86598b6faad484534d1347495586caa9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Circuit is everything your teams need to communicate in a single app. It’s voice, video, screen share, chat, and file sharing. It’s collaboration made simple.",
    "category": "Team Collaboration",
    "title": "Circuit Integration With Cold Email Sales Tool",
    "h1": "Integrate Circuit with SalesBlink",
    "metaDescription": "Integrate Circuit and other Team Collaboration Tools with SalesBlink. Two Way Circuit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IP2Proxy",
    "slug": "ip2proxy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/22f49d7b7feecb3402ff9563ba86817e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IP2Proxy allows instant detection of anonymous proxy, VPN, TOR exit nodes, search engine robots (SES) and residential proxies (RES) by IP address.",
    "category": "Security & Identity Tools",
    "title": "IP2Proxy Integration With Cold Email Sales Tool",
    "h1": "Integrate IP2Proxy with SalesBlink",
    "metaDescription": "Integrate IP2Proxy and other Security & Identity Tools Tools with SalesBlink. Two Way IP2Proxy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TeleRetro",
    "slug": "teleretro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6229fcc2c033fce5d04f166159def017.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeleRetro is remote collaboration tool to run Agile retrospectives to help teams evaluate and improve their ways of working.",
    "category": "Team Collaboration",
    "title": "TeleRetro Integration With Cold Email Sales Tool",
    "h1": "Integrate TeleRetro with SalesBlink",
    "metaDescription": "Integrate TeleRetro and other Team Collaboration Tools with SalesBlink. Two Way TeleRetro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kotobee",
    "slug": "kotobee",
    "logo": "https://zapier-images.imgix.net/storage/services/ae43a8d1cb9547d2c9ff74c2e2c321ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kotobee is an ebook authoring platform that helps you create, host, and publish ebooks.",
    "category": "Online Courses",
    "title": "Kotobee Integration With Cold Email Sales Tool",
    "h1": "Integrate Kotobee with SalesBlink",
    "metaDescription": "Integrate Kotobee and other Online Courses Tools with SalesBlink. Two Way Kotobee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vox Me",
    "slug": "vox-me",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b39f8e63e0ac654f44303be90701fd30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoxMe is an emergency system that activate your trusted contacts immediately in case of an emergency",
    "category": "Communication",
    "title": "Vox Me Integration With Cold Email Sales Tool",
    "h1": "Integrate Vox Me with SalesBlink",
    "metaDescription": "Integrate Vox Me and other Communication Tools with SalesBlink. Two Way Vox Me Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simplify",
    "slug": "simplify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fe2fd1fefeb0588d325790e06f6ec8a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simplify is a service management solution, a seamless end-to-end solution with all the capabilities you need to help your customers.",
    "category": "Task Management",
    "title": "Simplify Integration With Cold Email Sales Tool",
    "h1": "Integrate Simplify with SalesBlink",
    "metaDescription": "Integrate Simplify and other Task Management Tools with SalesBlink. Two Way Simplify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Act! 365",
    "slug": "act-threesixfive",
    "logo": "https://zapier-images.imgix.net/storage/developer/18cc1bb83a1024d42e55a1624da3d572.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Act! 365 puts essential sales and marketing tools in your hands to close more deals, find new leads, and grow your business. The perfect Microsoft® Office 365 companion, stay productive from your inbox, the app—or wherever work takes you.",
    "category": "Contact Management",
    "title": "Act! 365 Integration With Cold Email Sales Tool",
    "h1": "Integrate Act! 365 with SalesBlink",
    "metaDescription": "Integrate Act! 365 and other Contact Management Tools with SalesBlink. Two Way Act! 365 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MaxiPublica",
    "slug": "maxipublica",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/750a4ca1d2c35c8e54c5681de03b8482.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MaxiPublica synchronizes leads and the inventory of new and used cars in over 35 classified sites and social networks.",
    "category": "Sales & CRM",
    "title": "MaxiPublica Integration With Cold Email Sales Tool",
    "h1": "Integrate MaxiPublica with SalesBlink",
    "metaDescription": "Integrate MaxiPublica and other Sales & CRM Tools with SalesBlink. Two Way MaxiPublica Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pliek",
    "slug": "pliek",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/664c4b31e529caee89c718a40135842c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pliek helps you boost the sales of your brand and products through sales notifications.",
    "category": "Notifications",
    "title": "Pliek Integration With Cold Email Sales Tool",
    "h1": "Integrate Pliek with SalesBlink",
    "metaDescription": "Integrate Pliek and other Notifications Tools with SalesBlink. Two Way Pliek Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Terminal49",
    "slug": "terminal49",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5435dfa8873636b0023477627563337e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Terminal49 is an all-in-one Shipment and Container tracking platform that lets you track your ocean shipments and containers in real-time across all major ocean carriers and terminals.",
    "category": "eCommerce",
    "title": "Terminal49 Integration With Cold Email Sales Tool",
    "h1": "Integrate Terminal49 with SalesBlink",
    "metaDescription": "Integrate Terminal49 and other eCommerce Tools with SalesBlink. Two Way Terminal49 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Client Broadcast",
    "slug": "client-broadcast",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79788b7fe992c96bef8428b55d87cfab_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Client Broadcast is a cloud based communications platform designed to save your business time and money while increasing sales!",
    "category": "Marketing Automation",
    "title": "Client Broadcast Integration With Cold Email Sales Tool",
    "h1": "Integrate Client Broadcast with SalesBlink",
    "metaDescription": "Integrate Client Broadcast and other Marketing Automation Tools with SalesBlink. Two Way Client Broadcast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EcoEmails",
    "slug": "ecoemails",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5fdf09718e7d9c3b78812ad28bb5a42.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EcoEmails is the ethical choice for email validation",
    "category": "Email",
    "title": "EcoEmails Integration With Cold Email Sales Tool",
    "h1": "Integrate EcoEmails with SalesBlink",
    "metaDescription": "Integrate EcoEmails and other Email Tools with SalesBlink. Two Way EcoEmails Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Phedone",
    "slug": "phedone",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7d16b466852d396fcfb068a9e9e3d996.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Phedone is an natural language processing tool to automate and improve your customer ticketing and knowledge base flow",
    "category": "Customer Support",
    "title": "Phedone Integration With Cold Email Sales Tool",
    "h1": "Integrate Phedone with SalesBlink",
    "metaDescription": "Integrate Phedone and other Customer Support Tools with SalesBlink. Two Way Phedone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Event Farm",
    "slug": "event-farm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/814f3976e4a4615432d1c43c147d361c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Event Farm is an enterprise event marketing platform: invitation & digital activation designed to promote and engage targeted audiences.",
    "category": "Event Management",
    "title": "Event Farm Integration With Cold Email Sales Tool",
    "h1": "Integrate Event Farm with SalesBlink",
    "metaDescription": "Integrate Event Farm and other Event Management Tools with SalesBlink. Two Way Event Farm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wet Ink",
    "slug": "wet-ink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0bf3388502b84913e15e5edbfd88ae1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create and teach classes around writing. Build deeply engaging workshops and classrooms centered on written assignments.",
    "category": "Education",
    "title": "Wet Ink Integration With Cold Email Sales Tool",
    "h1": "Integrate Wet Ink with SalesBlink",
    "metaDescription": "Integrate Wet Ink and other Education Tools with SalesBlink. Two Way Wet Ink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lobbytrack",
    "slug": "lobbytrack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61bfc316311ad5d8008673459002797f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lobbytrack visitor management software is used for registering visitors in corporate lobbies, government facilities, hospital visits, and schools.",
    "category": "Security & Identity Tools",
    "title": "Lobbytrack Integration With Cold Email Sales Tool",
    "h1": "Integrate Lobbytrack with SalesBlink",
    "metaDescription": "Integrate Lobbytrack and other Security & Identity Tools Tools with SalesBlink. Two Way Lobbytrack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoconut",
    "slug": "zoconut",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a46dee59306b0696f3bac91930644ce0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online Clinic Software for Dietitians & Health Coaches",
    "category": "Dashboards",
    "title": "Zoconut Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoconut with SalesBlink",
    "metaDescription": "Integrate Zoconut and other Dashboards Tools with SalesBlink. Two Way Zoconut Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pulse CRM",
    "slug": "pulse-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4bf3ba178e49af6203e430dc1fcb59ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gets all contacts from your Pulse CRM account. Requires Pulse Pro subscription, use token provided in account to connect.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pulse CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Pulse CRM with SalesBlink",
    "metaDescription": "Integrate Pulse CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pulse CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wing Assistant",
    "slug": "wing-assistant",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d80e77fc1243248378750986a78f0048.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wing is an assistant & receptionist service that helps businesses get tedious & time-consuming work done.",
    "category": "Productivity",
    "title": "Wing Assistant Integration With Cold Email Sales Tool",
    "h1": "Integrate Wing Assistant with SalesBlink",
    "metaDescription": "Integrate Wing Assistant and other Productivity Tools with SalesBlink. Two Way Wing Assistant Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gathr",
    "slug": "gathr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9963d7e542e2c3bc8937dcd11e578cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gathr is an all-in-one event management and talent booking platform helping creatives, performers, and speakers to get booked, and event organizers to fund, manage and promote their events.",
    "category": "Event Management",
    "title": "Gathr Integration With Cold Email Sales Tool",
    "h1": "Integrate Gathr with SalesBlink",
    "metaDescription": "Integrate Gathr and other Event Management Tools with SalesBlink. Two Way Gathr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dandy",
    "slug": "dandy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2583dd836f4b11ec85ec61e0d42be584.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn your online reputation Dandy with our all-in-one online reputation management tool.",
    "category": "Marketing",
    "title": "Dandy Integration With Cold Email Sales Tool",
    "h1": "Integrate Dandy with SalesBlink",
    "metaDescription": "Integrate Dandy and other Marketing Tools with SalesBlink. Two Way Dandy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "wise Companies",
    "slug": "wise-companies",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ba475c6e608e4ae7f7c5fcda93899c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wise Companies provide access to experts that help to startups, entrepreneurs, small businessees or professionals accelerate business or career growth.",
    "category": "Education",
    "title": "wise Companies Integration With Cold Email Sales Tool",
    "h1": "Integrate wise Companies with SalesBlink",
    "metaDescription": "Integrate wise Companies and other Education Tools with SalesBlink. Two Way wise Companies Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DoJiggy",
    "slug": "dojiggy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b22476a13e4772ba16dfd761fea7f92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Donor-Friendly Fundraising Platform for Nonprofits and Schools that Empowers Organizations to Raise More for Less.",
    "category": "Fundraising",
    "title": "DoJiggy Integration With Cold Email Sales Tool",
    "h1": "Integrate DoJiggy with SalesBlink",
    "metaDescription": "Integrate DoJiggy and other Fundraising Tools with SalesBlink. Two Way DoJiggy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dashiverse",
    "slug": "dashiverse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b6bac36ec2423d365158f3d8c7e9f3cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dashiverse is an Analytics and Reporting tool that let's you track important KPIs from multiple data sources in visually appealing dashboards.",
    "category": "Dashboards",
    "title": "Dashiverse Integration With Cold Email Sales Tool",
    "h1": "Integrate Dashiverse with SalesBlink",
    "metaDescription": "Integrate Dashiverse and other Dashboards Tools with SalesBlink. Two Way Dashiverse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReportGarden",
    "slug": "reportgarden",
    "logo": "https://zapier-images.imgix.net/storage/developer/c2d93cea8eb1cae2868a9d4d23697e4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ReportGarden helps online ad agencies create performance reports, invoices, manage campaign budgets and site audits.",
    "category": "Analytics",
    "title": "ReportGarden Integration With Cold Email Sales Tool",
    "h1": "Integrate ReportGarden with SalesBlink",
    "metaDescription": "Integrate ReportGarden and other Analytics Tools with SalesBlink. Two Way ReportGarden Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textodog",
    "slug": "textodog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/265cb737b6b26fa241fcead1b878f94a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Textodog is a two-way text messaging app that gives customers direct access to your sales team.",
    "category": "Communication",
    "title": "Textodog Integration With Cold Email Sales Tool",
    "h1": "Integrate Textodog with SalesBlink",
    "metaDescription": "Integrate Textodog and other Communication Tools with SalesBlink. Two Way Textodog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Booqi",
    "slug": "booqi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0a778acb845a887c12476294479c6ae4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Booqi is a marketing tool.",
    "category": "Sales & CRM",
    "title": "Booqi Integration With Cold Email Sales Tool",
    "h1": "Integrate Booqi with SalesBlink",
    "metaDescription": "Integrate Booqi and other Sales & CRM Tools with SalesBlink. Two Way Booqi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ideolve",
    "slug": "ideolve",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b2803f5b5da207f93374bf112614c18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ideolve is a note based team collaboration app that helps you resolve issue, and work collectively on ideas and initiatives.",
    "category": "Team Collaboration",
    "title": "Ideolve Integration With Cold Email Sales Tool",
    "h1": "Integrate Ideolve with SalesBlink",
    "metaDescription": "Integrate Ideolve and other Team Collaboration Tools with SalesBlink. Two Way Ideolve Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clever Elements",
    "slug": "clever-elements",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/118476c3feec0e638101dd5392663334.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We've built the road to email marketing. Email marketing cloud since 2003.",
    "category": "Email Newsletters",
    "title": "Clever Elements Integration With Cold Email Sales Tool",
    "h1": "Integrate Clever Elements with SalesBlink",
    "metaDescription": "Integrate Clever Elements and other Email Newsletters Tools with SalesBlink. Two Way Clever Elements Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Strategypoint",
    "slug": "strategypoint",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/feb8b1c3c33d9e3b494a7c5ae2a04c85_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Strategypoint is a simple strategy management platform",
    "category": "Productivity",
    "title": "Strategypoint Integration With Cold Email Sales Tool",
    "h1": "Integrate Strategypoint with SalesBlink",
    "metaDescription": "Integrate Strategypoint and other Productivity Tools with SalesBlink. Two Way Strategypoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bazo",
    "slug": "bazo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27d1a0e69b6561cea539e6f74a104d94.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bazo, get more Business Contacts from your Site!\nBAZO is a tool that recognizes which company entered your website and what it did during its visit. BAZO allows you to create individual prompts for your visitors, encouraging them to leave their phone number or e-mail.",
    "category": "Marketing",
    "title": "Bazo Integration With Cold Email Sales Tool",
    "h1": "Integrate Bazo with SalesBlink",
    "metaDescription": "Integrate Bazo and other Marketing Tools with SalesBlink. Two Way Bazo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rubypayeur",
    "slug": "rubypayeur",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b2956fc676e1e263b6611975bc2c01e5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rubypayeur helps you make better decisions with accurate data about your customers and suppliers.",
    "category": "Contact Management",
    "title": "Rubypayeur Integration With Cold Email Sales Tool",
    "h1": "Integrate Rubypayeur with SalesBlink",
    "metaDescription": "Integrate Rubypayeur and other Contact Management Tools with SalesBlink. Two Way Rubypayeur Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bueroservice24",
    "slug": "bueroservice24",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8ec0f265654321f4311346f9fcc01e9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bueroservice24 is your virtual assistant. Real secretaries take your calls – enabling you to focus on what really matters for your business.",
    "category": "Communication",
    "title": "Bueroservice24 Integration With Cold Email Sales Tool",
    "h1": "Integrate Bueroservice24 with SalesBlink",
    "metaDescription": "Integrate Bueroservice24 and other Communication Tools with SalesBlink. Two Way Bueroservice24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sniply",
    "slug": "sniply",
    "logo": "https://zapier-images.imgix.net/storage/developer/903b5fa0a05784ca91205734769f084f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sniply is the only link shortener that drives conversion. Convert your followers into users and customers. Embed Call-to-Actions into every page you share. Display your message on Forbes, TechCrunch, Mashable, Fast Company, BuzzFeed, anywhere. For free.",
    "category": "Ads & Conversion",
    "title": "Sniply Integration With Cold Email Sales Tool",
    "h1": "Integrate Sniply with SalesBlink",
    "metaDescription": "Integrate Sniply and other Ads & Conversion Tools with SalesBlink. Two Way Sniply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Impira",
    "slug": "impira",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ba3ef8f9b0bd249e0bc2f0d7cfffc27.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Impira is a no-code machine learning platform that automates data entry tasks for businesses of all sizes.",
    "category": "Developer Tools",
    "title": "Impira Integration With Cold Email Sales Tool",
    "h1": "Integrate Impira with SalesBlink",
    "metaDescription": "Integrate Impira and other Developer Tools Tools with SalesBlink. Two Way Impira Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octonius",
    "slug": "octonius",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/30ca7ac4251ba12b73976eb5f674c6c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octonius is a work management platform. It is the easiest and most scalable way to manage work anywhere.",
    "category": "Productivity",
    "title": "Octonius Integration With Cold Email Sales Tool",
    "h1": "Integrate Octonius with SalesBlink",
    "metaDescription": "Integrate Octonius and other Productivity Tools with SalesBlink. Two Way Octonius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Minelead",
    "slug": "minelead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ffaeb057c4de7b172b65e0a6e864f4f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Minelead is a free email finder and domain email finder. It's a free unlimited lead generation solution to find email addresses and company contact information.",
    "category": "Contact Management",
    "title": "Minelead Integration With Cold Email Sales Tool",
    "h1": "Integrate Minelead with SalesBlink",
    "metaDescription": "Integrate Minelead and other Contact Management Tools with SalesBlink. Two Way Minelead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qntrl",
    "slug": "qntrl",
    "logo": "https://zapier-images.imgix.net/storage/services/039e9b161c27a39471b04a9f56800e8f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qntrl is a business orchestration platform to design, automate, and analyze all your business processes. From everyday workflows to complex activities, Qntrl lets you plan every detail and track the progress of tasks as they move through your team.",
    "category": "Project Management",
    "title": "Qntrl Integration With Cold Email Sales Tool",
    "h1": "Integrate Qntrl with SalesBlink",
    "metaDescription": "Integrate Qntrl and other Project Management Tools with SalesBlink. Two Way Qntrl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Collaborate Space",
    "slug": "collaborate-space",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f018770a5ce81169a0f8f8734be6b057_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "COLLABORATE Space (by ClearOne) is a cloud video collaboration app that connects hundreds of users joining from any device, anytime, and anywhere for messaging, calling, and meetings.",
    "category": "Video & Audio",
    "title": "Collaborate Space Integration With Cold Email Sales Tool",
    "h1": "Integrate Collaborate Space with SalesBlink",
    "metaDescription": "Integrate Collaborate Space and other Video & Audio Tools with SalesBlink. Two Way Collaborate Space Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NachoNacho",
    "slug": "nachonacho",
    "logo": "https://zapier-images.imgix.net/storage/services/48e96d2651343d279524358fd405662c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NachoNacho's mission is to be the marketplace for SaaS - a single destination where businesses buy SaaS products, and vendors find their best customers.",
    "category": "Accounting",
    "title": "NachoNacho Integration With Cold Email Sales Tool",
    "h1": "Integrate NachoNacho with SalesBlink",
    "metaDescription": "Integrate NachoNacho and other Accounting Tools with SalesBlink. Two Way NachoNacho Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VisitUs Reception",
    "slug": "visitus-reception",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/86c06697b6fef073a21cab52143e5647_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VisitUs Reception is a visitor management system that focuses on integrations and user experience.",
    "category": "Productivity",
    "title": "VisitUs Reception Integration With Cold Email Sales Tool",
    "h1": "Integrate VisitUs Reception with SalesBlink",
    "metaDescription": "Integrate VisitUs Reception and other Productivity Tools with SalesBlink. Two Way VisitUs Reception Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Democratik",
    "slug": "democratik",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eb58d2cb8abda3d8bbd81539a66431fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Democratik is a mobilization tool for political party and organization.",
    "category": "Website Builders",
    "title": "Democratik Integration With Cold Email Sales Tool",
    "h1": "Integrate Democratik with SalesBlink",
    "metaDescription": "Integrate Democratik and other Website Builders Tools with SalesBlink. Two Way Democratik Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WHS Monitor",
    "slug": "whs-monitor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/286c197317c0615031d9c0e1dea37847.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WHS Monitor is a modular, cloud-based Work Health and Safety compliance management software which enables organisations to simply and effectively comply with their Work Health and Safety obligations.",
    "category": "Human Resources",
    "title": "WHS Monitor Integration With Cold Email Sales Tool",
    "h1": "Integrate WHS Monitor with SalesBlink",
    "metaDescription": "Integrate WHS Monitor and other Human Resources Tools with SalesBlink. Two Way WHS Monitor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dropmatix",
    "slug": "dropmatix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bfb51680009252678173c48be7a2c63d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dropmatix is an e-commerce ERP solution and automates sales, inventory, online stores and sales platforms.",
    "category": "eCommerce",
    "title": "Dropmatix Integration With Cold Email Sales Tool",
    "h1": "Integrate Dropmatix with SalesBlink",
    "metaDescription": "Integrate Dropmatix and other eCommerce Tools with SalesBlink. Two Way Dropmatix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whal3s",
    "slug": "whal3s",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51a79d9645fc674480b81e7d419c1da0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create web3 experiences, exclusively for your token holders - all integrated in your own or third-party application.",
    "category": "Developer Tools",
    "title": "Whal3s Integration With Cold Email Sales Tool",
    "h1": "Integrate Whal3s with SalesBlink",
    "metaDescription": "Integrate Whal3s and other Developer Tools Tools with SalesBlink. Two Way Whal3s Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "e-clicks",
    "slug": "e-clicks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b3f28fb73ce9b9c36491f4910779ab2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "E-clicks is a funnel builder tool to create lead and sales funnels and send emails",
    "category": "Marketing Automation",
    "title": "e-clicks Integration With Cold Email Sales Tool",
    "h1": "Integrate e-clicks with SalesBlink",
    "metaDescription": "Integrate e-clicks and other Marketing Automation Tools with SalesBlink. Two Way e-clicks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Distribuidora Eficaz",
    "slug": "distribuidora-eficaz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/297a08fce028c312175e5daa641c5045.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Distribuidora Eficaz is a specialist ERP system for order and inventory management.",
    "category": "Dashboards",
    "title": "Distribuidora Eficaz Integration With Cold Email Sales Tool",
    "h1": "Integrate Distribuidora Eficaz with SalesBlink",
    "metaDescription": "Integrate Distribuidora Eficaz and other Dashboards Tools with SalesBlink. Two Way Distribuidora Eficaz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AttendZen",
    "slug": "attendzen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/58447e3fb55efdfb03a0b0ffa2a5f6ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AttendZen lets you manage and market any kind of conference, corporate or experiential event – whether virtual, in-person or hybrid.",
    "category": "Event Management",
    "title": "AttendZen Integration With Cold Email Sales Tool",
    "h1": "Integrate AttendZen with SalesBlink",
    "metaDescription": "Integrate AttendZen and other Event Management Tools with SalesBlink. Two Way AttendZen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "bitFit Asset Management Software",
    "slug": "bitfit-asset-management-software",
    "logo": "https://zapier-images.imgix.net/storage/services/91ebaceb437dc02cb49e00475ba94aba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "bitFit is the flexible asset management platform that helps to have full control over all assets in your company.",
    "category": "Productivity",
    "title": "bitFit Asset Management Software Integration With Cold Email Sales Tool",
    "h1": "Integrate bitFit Asset Management Software with SalesBlink",
    "metaDescription": "Integrate bitFit Asset Management Software and other Productivity Tools with SalesBlink. Two Way bitFit Asset Management Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "leadtributor.cloud",
    "slug": "leadtributorcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61d8c7981b562880fd00f8c71b38a6c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadtributor is business-to-business software that helps companies to see and manage the forecast with their distribution partners.",
    "category": "Sales & CRM",
    "title": "leadtributor.cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate leadtributor.cloud with SalesBlink",
    "metaDescription": "Integrate leadtributor.cloud and other Sales & CRM Tools with SalesBlink. Two Way leadtributor.cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evolved Metrics CRM",
    "slug": "evolved-metrics-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/401492b61967636e4edc944eb11bd183.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An upgrade from a spreadsheet, CRM will help you organize your sales process so that you can focus on what matters most, building relationships with your customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Evolved Metrics CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Evolved Metrics CRM with SalesBlink",
    "metaDescription": "Integrate Evolved Metrics CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Evolved Metrics CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MightyRep",
    "slug": "mightyrep",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/613b37bcd6a1326747d727f61a611c33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MightyRep helps companies obtain better customer intelligence and buyer intent in order to drive more qualified leads.",
    "category": "Marketing",
    "title": "MightyRep Integration With Cold Email Sales Tool",
    "h1": "Integrate MightyRep with SalesBlink",
    "metaDescription": "Integrate MightyRep and other Marketing Tools with SalesBlink. Two Way MightyRep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chirps TV",
    "slug": "chirpstv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1d175b526b7d2344491bc42b18d25f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chirps TV connects the services you love to your Android TV to provide real-time push notifications.",
    "category": "Notifications",
    "title": "Chirps TV Integration With Cold Email Sales Tool",
    "h1": "Integrate Chirps TV with SalesBlink",
    "metaDescription": "Integrate Chirps TV and other Notifications Tools with SalesBlink. Two Way Chirps TV Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SMSMasivo.com.co",
    "slug": "smsmasivocomco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79e24a2b050b74778e3fb70bc1bdbb30.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SMSMasivo.com.co allows you to send SMS messages to any cellphone number in Colombia.",
    "category": "Marketing Automation",
    "title": "SMSMasivo.com.co Integration With Cold Email Sales Tool",
    "h1": "Integrate SMSMasivo.com.co with SalesBlink",
    "metaDescription": "Integrate SMSMasivo.com.co and other Marketing Automation Tools with SalesBlink. Two Way SMSMasivo.com.co Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZiftONE",
    "slug": "ziftone",
    "logo": "https://zapier-images.imgix.net/storage/services/bcf124b0fd548c6ec7cbf7a31aab8139.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZiftONE is the single source of truth to align your channel marketing, sales and operations, designed to amplify every partner’s potential.",
    "category": "Sales & CRM",
    "title": "ZiftONE Integration With Cold Email Sales Tool",
    "h1": "Integrate ZiftONE with SalesBlink",
    "metaDescription": "Integrate ZiftONE and other Sales & CRM Tools with SalesBlink. Two Way ZiftONE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cascade Strategy",
    "slug": "cascade-strategy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b842585aa59f1d2a938111081cd18b1f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cascade helps organizations plan, execute, measure, and adapt their strategies in one place so they see business results faster.",
    "category": "Business Intelligence",
    "title": "Cascade Strategy Integration With Cold Email Sales Tool",
    "h1": "Integrate Cascade Strategy with SalesBlink",
    "metaDescription": "Integrate Cascade Strategy and other Business Intelligence Tools with SalesBlink. Two Way Cascade Strategy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upwave",
    "slug": "upwave",
    "logo": "https://zapier-images.imgix.net/storage/developer/a21006676adb7007b3aec7f8d6b3d3a9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upwave is a visual collaboration platform that will increase productivity and reduce stress.",
    "category": "Project Management",
    "title": "Upwave Integration With Cold Email Sales Tool",
    "h1": "Integrate Upwave with SalesBlink",
    "metaDescription": "Integrate Upwave and other Project Management Tools with SalesBlink. Two Way Upwave Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GiveXpert",
    "slug": "givexpert",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c273d355fd306a2caa65026e677080c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GiveXpert is an online fundraising app that meets the specific needs of fundraisers.",
    "category": "Fundraising",
    "title": "GiveXpert Integration With Cold Email Sales Tool",
    "h1": "Integrate GiveXpert with SalesBlink",
    "metaDescription": "Integrate GiveXpert and other Fundraising Tools with SalesBlink. Two Way GiveXpert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "neetoChat",
    "slug": "neetochat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd80572277cef394bf008328c152e0cb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "neetoChat is an all-in-one chat solution for your business. Chat with your customers live and help them find what they're looking for.",
    "category": "Customer Support",
    "title": "neetoChat Integration With Cold Email Sales Tool",
    "h1": "Integrate neetoChat with SalesBlink",
    "metaDescription": "Integrate neetoChat and other Customer Support Tools with SalesBlink. Two Way neetoChat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Listing Booster",
    "slug": "listing-booster",
    "logo": "https://zapier-images.imgix.net/storage/developer/cb4bb8f185477a840c99b9b585f5af78.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Listing Booster is a fully automated property marketing system specialized in lead generation. The system is designed to be co-branded between an agent and loan officer so both receive the leads, and works with most US MLS systems.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Listing Booster Integration With Cold Email Sales Tool",
    "h1": "Integrate Listing Booster with SalesBlink",
    "metaDescription": "Integrate Listing Booster and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Listing Booster Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PepaDocs",
    "slug": "pepadocs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65e800844ebaf8186bf2b3aad98dd3eb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PepaDocs is a tool used to create intervention reports. A team can use PepaDocs on various missions to edit, create, manage or classify any type of report based on available or custom templates, directly on a smartphone, computer or a tablet.",
    "category": "Website & App Building",
    "title": "PepaDocs Integration With Cold Email Sales Tool",
    "h1": "Integrate PepaDocs with SalesBlink",
    "metaDescription": "Integrate PepaDocs and other Website & App Building Tools with SalesBlink. Two Way PepaDocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InviteReferrals",
    "slug": "invitereferrals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27da370d94862a022433ebc87cb419fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InviteReferrals is a referral program software that helps to grow your business and acquire new customers through Referral Marketing.",
    "category": "Marketing Automation",
    "title": "InviteReferrals Integration With Cold Email Sales Tool",
    "h1": "Integrate InviteReferrals with SalesBlink",
    "metaDescription": "Integrate InviteReferrals and other Marketing Automation Tools with SalesBlink. Two Way InviteReferrals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "vimigo",
    "slug": "vimigo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5ff5fae3b14c3fd6e8d591e78a427d4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vimigo is a software that acts as a platform for management and the staffs to communicate, collaborate, and engage in actual-time.",
    "category": "Productivity",
    "title": "vimigo Integration With Cold Email Sales Tool",
    "h1": "Integrate vimigo with SalesBlink",
    "metaDescription": "Integrate vimigo and other Productivity Tools with SalesBlink. Two Way vimigo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Liveweb",
    "slug": "liveweb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fa84a94a39706290ba26367a2c73b45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Liveweb is a communication tool for your website that lets you chat and make audio and video calls with your visitors right from your website.",
    "category": "Communication",
    "title": "Liveweb Integration With Cold Email Sales Tool",
    "h1": "Integrate Liveweb with SalesBlink",
    "metaDescription": "Integrate Liveweb and other Communication Tools with SalesBlink. Two Way Liveweb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contenu Unique",
    "slug": "contenu-unique",
    "logo": "https://zapier-images.imgix.net/storage/services/4329c39a1f6f5845670daa68de7c9938.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contenu Unique allows you to generate content automatically (or manually) to get rid of duplicate content.",
    "category": "Content & Files",
    "title": "Contenu Unique Integration With Cold Email Sales Tool",
    "h1": "Integrate Contenu Unique with SalesBlink",
    "metaDescription": "Integrate Contenu Unique and other Content & Files Tools with SalesBlink. Two Way Contenu Unique Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Convirza",
    "slug": "convirza",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1bc9854746fb2f681d6aeda2c61efd6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convirza helps you unlock more value from what happens before, during, and after your calls.",
    "category": "Call Tracking",
    "title": "Convirza Integration With Cold Email Sales Tool",
    "h1": "Integrate Convirza with SalesBlink",
    "metaDescription": "Integrate Convirza and other Call Tracking Tools with SalesBlink. Two Way Convirza Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Project Bubble",
    "slug": "project-bubble",
    "logo": "https://zapier-images.imgix.net/storage/developer/54b454f3a9c0004c7ef1b7b46825315f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The simple and easy way to manage your projects. Spend less time learning a project management system, and more time being productive.\n\nManage your projects online, track time, set deadlines, stay on budget, share files, be productive!",
    "category": "Project Management",
    "title": "Project Bubble Integration With Cold Email Sales Tool",
    "h1": "Integrate Project Bubble with SalesBlink",
    "metaDescription": "Integrate Project Bubble and other Project Management Tools with SalesBlink. Two Way Project Bubble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContactUs.com",
    "slug": "contact-us-dot-com",
    "logo": "https://zapier-images.imgix.net/storage/developer/1a235a194320f38a3ac44eedc43ba513_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContactUs.com increases the amount of website visitors that become contacts by using behavioral triggers, helps you start the conversation with those visitors with a basic CRM, tells you where your leads come from and what they do in your website.",
    "category": "Forms & Surveys",
    "title": "ContactUs.com Integration With Cold Email Sales Tool",
    "h1": "Integrate ContactUs.com with SalesBlink",
    "metaDescription": "Integrate ContactUs.com and other Forms & Surveys Tools with SalesBlink. Two Way ContactUs.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superblog",
    "slug": "superblog",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5c1c1bd9a53df502d6de9d92a620bf55_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Superblog is a fast alternative to Medium and WordPress blogs.",
    "category": "Marketing",
    "title": "Superblog Integration With Cold Email Sales Tool",
    "h1": "Integrate Superblog with SalesBlink",
    "metaDescription": "Integrate Superblog and other Marketing Tools with SalesBlink. Two Way Superblog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mediatoolkit",
    "slug": "mediatoolkit",
    "logo": "https://zapier-images.imgix.net/storage/developer/e733d6bdd31acaab0ce5db7e8d5df3c3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mediatoolkit is a media monitoring tool that tracks relevant mentions of your brand across the web and social media in real time.",
    "category": "Social Media Accounts",
    "title": "Mediatoolkit Integration With Cold Email Sales Tool",
    "h1": "Integrate Mediatoolkit with SalesBlink",
    "metaDescription": "Integrate Mediatoolkit and other Social Media Accounts Tools with SalesBlink. Two Way Mediatoolkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AccountingSuite",
    "slug": "accountingsuite",
    "logo": "https://zapier-images.imgix.net/storage/developer/c2325c184123c5e34f5d65f92ca32062.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AccountingSuite is a powerful, all-in-one business application for accounting, cloud banking, order management, inventory management, project and time tracking into one cloud-driven platform.",
    "category": "Accounting",
    "title": "AccountingSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate AccountingSuite with SalesBlink",
    "metaDescription": "Integrate AccountingSuite and other Accounting Tools with SalesBlink. Two Way AccountingSuite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FacilityBot",
    "slug": "facilitybot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4549341c270b4b5fd4a80c768f4204fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Allow staff to book desks, report faults, make service requests, book rooms, invite visitors and get automated FAQ responses (chatbot) through a single channel.",
    "category": "Customer Support",
    "title": "FacilityBot Integration With Cold Email Sales Tool",
    "h1": "Integrate FacilityBot with SalesBlink",
    "metaDescription": "Integrate FacilityBot and other Customer Support Tools with SalesBlink. Two Way FacilityBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smartloop",
    "slug": "smartloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8133f8a8ba932f35375e9f445e0fcc8c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simplicity and automation. Sell more by using conversational AI to collect leads, nurture and engage with better insights. Enjoy improved CTR by elevating your email marketing.",
    "category": "Marketing Automation",
    "title": "Smartloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Smartloop with SalesBlink",
    "metaDescription": "Integrate Smartloop and other Marketing Automation Tools with SalesBlink. Two Way Smartloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IQM Reports",
    "slug": "iqm-reports",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7920ad9045aa10b2ef34180635753595_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IQM Reports provides access to all dimensions and KPI of your ad-serving reporting data in convenient file formats.",
    "category": "Ads & Conversion",
    "title": "IQM Reports Integration With Cold Email Sales Tool",
    "h1": "Integrate IQM Reports with SalesBlink",
    "metaDescription": "Integrate IQM Reports and other Ads & Conversion Tools with SalesBlink. Two Way IQM Reports Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BrainCert",
    "slug": "braincert",
    "logo": "https://zapier-images.imgix.net/storage/services/6d5f8c92b2c81cf8124103a31cc13265.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrainCert is an all-in-one unified training platform designed to deliver any type of custom training online with integrated Virtual Classroom and over 300+ tools.",
    "category": "Education",
    "title": "BrainCert Integration With Cold Email Sales Tool",
    "h1": "Integrate BrainCert with SalesBlink",
    "metaDescription": "Integrate BrainCert and other Education Tools with SalesBlink. Two Way BrainCert Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KeyedIn Projects",
    "slug": "keyedin-projects",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef518ca4d5c98ebecd79d7546eb52e55_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KeyedIn Projects is an enterprise-class Project Portfolio Management software designed for PMO's, IT Teams and Professional Services.",
    "category": "Project Management",
    "title": "KeyedIn Projects Integration With Cold Email Sales Tool",
    "h1": "Integrate KeyedIn Projects with SalesBlink",
    "metaDescription": "Integrate KeyedIn Projects and other Project Management Tools with SalesBlink. Two Way KeyedIn Projects Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Collected Notes",
    "slug": "collected-notes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6233187a76a91b547ce5fe9fbc3289c1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collected Notes is a simple and powerful note-taking blogging platform.",
    "category": "Notes",
    "title": "Collected Notes Integration With Cold Email Sales Tool",
    "h1": "Integrate Collected Notes with SalesBlink",
    "metaDescription": "Integrate Collected Notes and other Notes Tools with SalesBlink. Two Way Collected Notes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnStrategy",
    "slug": "onstrategy",
    "logo": "https://zapier-images.imgix.net/storage/developer/dd9a18188c5a6237b1795c1a4124f1cd_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnStrategy is a strategic performance software tool you can use to build your corporate strategy and manage and implement your plan.",
    "category": "Project Management",
    "title": "OnStrategy Integration With Cold Email Sales Tool",
    "h1": "Integrate OnStrategy with SalesBlink",
    "metaDescription": "Integrate OnStrategy and other Project Management Tools with SalesBlink. Two Way OnStrategy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mindbaz",
    "slug": "mindbaz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24d8624f4583ac952b3e83ec84446b82.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Solutions for sending your Marketing Campaigns (email & SMS).",
    "category": "Email Newsletters",
    "title": "Mindbaz Integration With Cold Email Sales Tool",
    "h1": "Integrate Mindbaz with SalesBlink",
    "metaDescription": "Integrate Mindbaz and other Email Newsletters Tools with SalesBlink. Two Way Mindbaz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SiteFotos",
    "slug": "sitefotos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2da56c21b2ea8b197054ccb94079933d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SiteFotos is a simple photo-taking, project-tagging, site mapping, (even time-tracking), customer communication portal.",
    "category": "Task Management",
    "title": "SiteFotos Integration With Cold Email Sales Tool",
    "h1": "Integrate SiteFotos with SalesBlink",
    "metaDescription": "Integrate SiteFotos and other Task Management Tools with SalesBlink. Two Way SiteFotos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyCSP",
    "slug": "mycsp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d67df5b17bd9020f4bd9c60ea48477e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyCSP is a SaaS commerce platform that helps businesses and Cloud Service Providers procure and resell Microsoft Azure, Microsoft 365, Dynamics 365 and Azure Stack.",
    "category": "IT Operations",
    "title": "MyCSP Integration With Cold Email Sales Tool",
    "h1": "Integrate MyCSP with SalesBlink",
    "metaDescription": "Integrate MyCSP and other IT Operations Tools with SalesBlink. Two Way MyCSP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BOOKM",
    "slug": "bookm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/603b62ebecbf594d1d1aa705a42d5e39.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BOOKM is an online scheduling tool that makes arranging meetings and booking services easy.",
    "category": "Scheduling & Booking",
    "title": "BOOKM Integration With Cold Email Sales Tool",
    "h1": "Integrate BOOKM with SalesBlink",
    "metaDescription": "Integrate BOOKM and other Scheduling & Booking Tools with SalesBlink. Two Way BOOKM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adminja",
    "slug": "adminja",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6711e5d98a28bc0f270978401a4d96e3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adminja is a tool to help Virtual Assistants manage their clients and their business.",
    "category": "Time Tracking Software",
    "title": "Adminja Integration With Cold Email Sales Tool",
    "h1": "Integrate Adminja with SalesBlink",
    "metaDescription": "Integrate Adminja and other Time Tracking Software Tools with SalesBlink. Two Way Adminja Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lancerkit",
    "slug": "lancerkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2006226cb2dfddf5302543ebb9bc11cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lancerkit is a web application designed for freelancers and small companies to streamline their businesses.",
    "category": "Accounting",
    "title": "Lancerkit Integration With Cold Email Sales Tool",
    "h1": "Integrate Lancerkit with SalesBlink",
    "metaDescription": "Integrate Lancerkit and other Accounting Tools with SalesBlink. Two Way Lancerkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Manu Online",
    "slug": "manu-online",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/72edb0d4c7ca64a64b8a70b48d3c46ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manu Online is an ERP system for small and medium sized manufacturing companies.",
    "category": "Commerce",
    "title": "Manu Online Integration With Cold Email Sales Tool",
    "h1": "Integrate Manu Online with SalesBlink",
    "metaDescription": "Integrate Manu Online and other Commerce Tools with SalesBlink. Two Way Manu Online Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GaggleAMP",
    "slug": "gaggleamp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0083b1730cab40620b05b3e88ad894d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Increase brand reach and employee engagement through your employees' social networks.",
    "category": "Social Media Marketing",
    "title": "GaggleAMP Integration With Cold Email Sales Tool",
    "h1": "Integrate GaggleAMP with SalesBlink",
    "metaDescription": "Integrate GaggleAMP and other Social Media Marketing Tools with SalesBlink. Two Way GaggleAMP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Paygee",
    "slug": "paygee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad075504c6b156fb4c5a58ae7b673c7c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Paygee is the go-to solution for any business in need of a totally integrated PAYG (Pay-As-You-Go) platform. The hardware agnostic software suite allows for remote access and control of PAYG products, minimizes operational costs and empowers sales forces.",
    "category": "Sales & CRM",
    "title": "Paygee Integration With Cold Email Sales Tool",
    "h1": "Integrate Paygee with SalesBlink",
    "metaDescription": "Integrate Paygee and other Sales & CRM Tools with SalesBlink. Two Way Paygee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VocalCola",
    "slug": "vocalcola",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8204f3e5a53845b375c6bc76349ceb72_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VocalCola is a Call Tracking Service. Track and record your phone calls from all forms of marketing. Pick unique local and toll free numbers to use on different advertisements, and chart your most effective lead sources.",
    "category": "Call Tracking",
    "title": "VocalCola Integration With Cold Email Sales Tool",
    "h1": "Integrate VocalCola with SalesBlink",
    "metaDescription": "Integrate VocalCola and other Call Tracking Tools with SalesBlink. Two Way VocalCola Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jottacloud",
    "slug": "jottacloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/380a05b916802b4368bab9575dbec3bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jottacloud is a European cloud storage service for individuals and businesses. GDPR compliant and green storage in Norway.",
    "category": "File Management & Storage",
    "title": "Jottacloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Jottacloud with SalesBlink",
    "metaDescription": "Integrate Jottacloud and other File Management & Storage Tools with SalesBlink. Two Way Jottacloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TurfHop",
    "slug": "turfhop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cee0a50be96791fc7459c75b5b4370e1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TurfHop is a scheduling and invoicing software application that helps business owners manage their lawn and landscape companies.",
    "category": "Sales & CRM",
    "title": "TurfHop Integration With Cold Email Sales Tool",
    "h1": "Integrate TurfHop with SalesBlink",
    "metaDescription": "Integrate TurfHop and other Sales & CRM Tools with SalesBlink. Two Way TurfHop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VocalIP",
    "slug": "vocalip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/71d2654768c2ef8a9c80710d6fc9d035.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vocal IP provides a full range of managed data and cloud network and access services combined with advanced unified communications and application integration services.",
    "category": "Phone & SMS",
    "title": "VocalIP Integration With Cold Email Sales Tool",
    "h1": "Integrate VocalIP with SalesBlink",
    "metaDescription": "Integrate VocalIP and other Phone & SMS Tools with SalesBlink. Two Way VocalIP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Embedery",
    "slug": "embedery",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/151dfdd994c712e9720ad704424ef657.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Embedery is a platform to help convert visitors into valuable customers using Popups, forms and visitor analysis.",
    "category": "Marketing",
    "title": "Embedery Integration With Cold Email Sales Tool",
    "h1": "Integrate Embedery with SalesBlink",
    "metaDescription": "Integrate Embedery and other Marketing Tools with SalesBlink. Two Way Embedery Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WIP",
    "slug": "wip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/56544891382412c887d2428094e0c44d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WIP is an invite-only social network of makers.",
    "category": "Task Management",
    "title": "WIP Integration With Cold Email Sales Tool",
    "h1": "Integrate WIP with SalesBlink",
    "metaDescription": "Integrate WIP and other Task Management Tools with SalesBlink. Two Way WIP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sharpify",
    "slug": "sharpify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f9b52c409640521265c5b3d4158a2a46.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simple And Powerful Sales Tool With Automation",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sharpify Integration With Cold Email Sales Tool",
    "h1": "Integrate Sharpify with SalesBlink",
    "metaDescription": "Integrate Sharpify and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sharpify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MediaBrains BusinessChatter",
    "slug": "mediabrains-businesschatter-ca14",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/978cb0993b88c0486469d62fc3c7e98e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BusinessChatter is a reputation management platform that helps organizations be in control of how their customers are talking about them across the web.",
    "category": "Reviews",
    "title": "MediaBrains BusinessChatter Integration With Cold Email Sales Tool",
    "h1": "Integrate MediaBrains BusinessChatter with SalesBlink",
    "metaDescription": "Integrate MediaBrains BusinessChatter and other Reviews Tools with SalesBlink. Two Way MediaBrains BusinessChatter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Burq",
    "slug": "burq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ea039d5542ff11715f72d5be5911dcf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Burq provides a delivery API and dashboard to help businesses offer on-demand and same-day delivery to their customers.",
    "category": "eCommerce",
    "title": "Burq Integration With Cold Email Sales Tool",
    "h1": "Integrate Burq with SalesBlink",
    "metaDescription": "Integrate Burq and other eCommerce Tools with SalesBlink. Two Way Burq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Relay Construction Solutions",
    "slug": "relay-construction-solutions-ca1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/208302ce9d4c627ed8a1ac2bf03284b8_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Relay is a bid-leveling software for the commercial construction industry, connecting project owners, general contractors, and sub-contractors in one tool.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Relay Construction Solutions Integration With Cold Email Sales Tool",
    "h1": "Integrate Relay Construction Solutions with SalesBlink",
    "metaDescription": "Integrate Relay Construction Solutions and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Relay Construction Solutions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "360Score.me",
    "slug": "360scoreme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d2bdbe3087f14684c6449bd647ebcbd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "360Score.me is a 360 degree review and company engagement survey system that empowers organizations to collect anonymous employee feedback.",
    "category": "Reviews",
    "title": "360Score.me Integration With Cold Email Sales Tool",
    "h1": "Integrate 360Score.me with SalesBlink",
    "metaDescription": "Integrate 360Score.me and other Reviews Tools with SalesBlink. Two Way 360Score.me Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mothernode CRM",
    "slug": "mothernode-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd5639380e47303fac7a7dfcd8f372d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mothernode CRM  - manage your sales pipeline, improve customer retention and automate your business processes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mothernode CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Mothernode CRM with SalesBlink",
    "metaDescription": "Integrate Mothernode CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mothernode CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Prisme.ai",
    "slug": "prismeai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3a061bed37087437a8f047178e2b65fc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Prisme.ai is a platform combining low-code automation & chatbots",
    "category": "App Builder",
    "title": "Prisme.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Prisme.ai with SalesBlink",
    "metaDescription": "Integrate Prisme.ai and other App Builder Tools with SalesBlink. Two Way Prisme.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cvent Webinar",
    "slug": "cvent-webinar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1fcd9ba7538ce56d47a42a00aaadd8c2_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cvent Webinar is a modern online management product for Webinars.",
    "category": "Event Management",
    "title": "Cvent Webinar Integration With Cold Email Sales Tool",
    "h1": "Integrate Cvent Webinar with SalesBlink",
    "metaDescription": "Integrate Cvent Webinar and other Event Management Tools with SalesBlink. Two Way Cvent Webinar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventsforce",
    "slug": "eventsforce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/538f298296861f2b8e2f8d0c2413a23a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventsforce is a cloud-based technology platform for your virtual, hybrid and in-person events, addressing every aspect of the event lifecycle",
    "category": "Event Management",
    "title": "Eventsforce Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventsforce with SalesBlink",
    "metaDescription": "Integrate Eventsforce and other Event Management Tools with SalesBlink. Two Way Eventsforce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "M360",
    "slug": "M360",
    "logo": "https://zapier-images.imgix.net/storage/services/ac3b20abdabfca780fb3eb02e59558e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "M360 is a text message marketing solution that is easy to implement, reduces execution time, and allows for better data-based decisions.",
    "category": "Phone & SMS",
    "title": "M360 Integration With Cold Email Sales Tool",
    "h1": "Integrate M360 with SalesBlink",
    "metaDescription": "Integrate M360 and other Phone & SMS Tools with SalesBlink. Two Way M360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rewatch",
    "slug": "rewatch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40c8c33fe1d1c809626d54623ea3728a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rewatch is your company’s video hub – one place to share, manage, and search all your videos and meeting recordings.",
    "category": "Communication",
    "title": "Rewatch Integration With Cold Email Sales Tool",
    "h1": "Integrate Rewatch with SalesBlink",
    "metaDescription": "Integrate Rewatch and other Communication Tools with SalesBlink. Two Way Rewatch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ideawake",
    "slug": "ideawake",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a133a745ad2c87d419a48d20edcfa82f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ideawake is a collaborative innovation platform to help surface the best ideas at any company.",
    "category": "Team Collaboration",
    "title": "Ideawake Integration With Cold Email Sales Tool",
    "h1": "Integrate Ideawake with SalesBlink",
    "metaDescription": "Integrate Ideawake and other Team Collaboration Tools with SalesBlink. Two Way Ideawake Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TaskTrain",
    "slug": "tasktrain",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91bb54db315787aa6579a9ef28458776.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TaskTrain is a task management app that helps you turn standard operating procedures into actionable assignments.",
    "category": "Task Management",
    "title": "TaskTrain Integration With Cold Email Sales Tool",
    "h1": "Integrate TaskTrain with SalesBlink",
    "metaDescription": "Integrate TaskTrain and other Task Management Tools with SalesBlink. Two Way TaskTrain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trinsic",
    "slug": "trinsic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa57ff1e747bc3f1f17cf08fbc3dda2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trinsic is a full-stack verifiable credential and digital wallet platform based on open standards.",
    "category": "Security & Identity Tools",
    "title": "Trinsic Integration With Cold Email Sales Tool",
    "h1": "Integrate Trinsic with SalesBlink",
    "metaDescription": "Integrate Trinsic and other Security & Identity Tools Tools with SalesBlink. Two Way Trinsic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Restyaboard",
    "slug": "restyaboard",
    "logo": "https://zapier-images.imgix.net/storage/developer/2c56a16c379ea71ba92ffb8ca1a5206b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Open source. Trello-like kanban board. Based on Restya platform.",
    "category": "Project Management",
    "title": "Restyaboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Restyaboard with SalesBlink",
    "metaDescription": "Integrate Restyaboard and other Project Management Tools with SalesBlink. Two Way Restyaboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Higher Logic",
    "slug": "higher-logic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/333d62c6d1c73465f869f1c557d56b1f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Higher Logic's engagement solutions deliver powerful member experiences through online communities and marketing tools.",
    "category": "Team Collaboration",
    "title": "Higher Logic Integration With Cold Email Sales Tool",
    "h1": "Integrate Higher Logic with SalesBlink",
    "metaDescription": "Integrate Higher Logic and other Team Collaboration Tools with SalesBlink. Two Way Higher Logic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OfficeClip",
    "slug": "officeclip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b5467cc58e9ad0461a8c285861e4527d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OfficeClip is a team collaboration tool to organize CRM, HRM, Issue Tracker and keep projects on track.",
    "category": "Developer Tools",
    "title": "OfficeClip Integration With Cold Email Sales Tool",
    "h1": "Integrate OfficeClip with SalesBlink",
    "metaDescription": "Integrate OfficeClip and other Developer Tools Tools with SalesBlink. Two Way OfficeClip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sella",
    "slug": "sella",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c13e2d66a74f144b9e3721d87d9ae3d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sella delivers you a linkedIn lead specialist for all of your lead building needs at affordable prices.",
    "category": "Ads & Conversion",
    "title": "Sella Integration With Cold Email Sales Tool",
    "h1": "Integrate Sella with SalesBlink",
    "metaDescription": "Integrate Sella and other Ads & Conversion Tools with SalesBlink. Two Way Sella Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "vzaar",
    "slug": "vzaar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c2e3b5a1e7eda9f067dc412fb6fbbb8e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vzaar provides video infrastructure for video professionals.",
    "category": "Video & Audio",
    "title": "vzaar Integration With Cold Email Sales Tool",
    "h1": "Integrate vzaar with SalesBlink",
    "metaDescription": "Integrate vzaar and other Video & Audio Tools with SalesBlink. Two Way vzaar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailjoy",
    "slug": "mailjoy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3ec2191716512009417b19530917875.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailjoy is a DIY direct mail platform that makes real mail as easy and data-driven as email. Design, target, and track postcard & letter campaigns.",
    "category": "Marketing Automation",
    "title": "Mailjoy Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailjoy with SalesBlink",
    "metaDescription": "Integrate Mailjoy and other Marketing Automation Tools with SalesBlink. Two Way Mailjoy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xoxoday Plum",
    "slug": "xoxoday-plum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6b47f80554a66517149f53241746d7a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xoxoday plum provides a wide catalog of rewarding options from across the globe and for various use cases",
    "category": "Customer Appreciation",
    "title": "Xoxoday Plum Integration With Cold Email Sales Tool",
    "h1": "Integrate Xoxoday Plum with SalesBlink",
    "metaDescription": "Integrate Xoxoday Plum and other Customer Appreciation Tools with SalesBlink. Two Way Xoxoday Plum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clevero",
    "slug": "clevero",
    "logo": "https://zapier-images.imgix.net/storage/services/b65ce85b3dde496a20d75e21f505115a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clevero is a CRM that runs your daily operations in a simple to use platform.  Capture data and automate process around for your Sales, Projects, Support, Timesheets, Invoicing and much much more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clevero Integration With Cold Email Sales Tool",
    "h1": "Integrate Clevero with SalesBlink",
    "metaDescription": "Integrate Clevero and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clevero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ideta",
    "slug": "ideta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b630a4eabd00e10955feee0d93ef8671.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ideta helps you automate conversations in livechats, chatbots and phone calls.",
    "category": "Developer Tools",
    "title": "Ideta Integration With Cold Email Sales Tool",
    "h1": "Integrate Ideta with SalesBlink",
    "metaDescription": "Integrate Ideta and other Developer Tools Tools with SalesBlink. Two Way Ideta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kandio",
    "slug": "kandio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5fcda1c1aa60a507504d305d6ae47b20.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connects with kandio assessment platform",
    "category": "Education",
    "title": "Kandio Integration With Cold Email Sales Tool",
    "h1": "Integrate Kandio with SalesBlink",
    "metaDescription": "Integrate Kandio and other Education Tools with SalesBlink. Two Way Kandio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudprinter.com",
    "slug": "cloudprinter",
    "logo": "https://zapier-images.imgix.net/storage/services/8549d603f0fe61ed57bac7c0f7da2212.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This application is for sending any print job, data or content to Cloudprinter and printing it locally in more than 104 countries.\nOffering transparent pricing, real-time status updates, and 500+ product templates.",
    "category": "Printing",
    "title": "Cloudprinter.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudprinter.com with SalesBlink",
    "metaDescription": "Integrate Cloudprinter.com and other Printing Tools with SalesBlink. Two Way Cloudprinter.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lone Worker by Ok Alone",
    "slug": "lone-worker-ca16559",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53b95cc8794c0a21dc693bab082b7f50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ok Alone protects your lone workers. The solution lets workers check in on the move, automatically reminds staff when they miss a check-in, monitors their location and request help when needed.",
    "category": "Devices",
    "title": "Lone Worker by Ok Alone Integration With Cold Email Sales Tool",
    "h1": "Integrate Lone Worker by Ok Alone with SalesBlink",
    "metaDescription": "Integrate Lone Worker by Ok Alone and other Devices Tools with SalesBlink. Two Way Lone Worker by Ok Alone Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KnowledgeOwl",
    "slug": "knowledgeowl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c542e1be2415126f32978ce605b64a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KnowledgeOwl is knowledge base software that helps you get all your documentation in one place, whether for employees, teams, or customers.",
    "category": "Customer Support",
    "title": "KnowledgeOwl Integration With Cold Email Sales Tool",
    "h1": "Integrate KnowledgeOwl with SalesBlink",
    "metaDescription": "Integrate KnowledgeOwl and other Customer Support Tools with SalesBlink. Two Way KnowledgeOwl Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Worldwide Parcels Tracker",
    "slug": "worldwide-parcels-tracker-ca1785",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57638d721c395a8f42c445ae032c317d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Track Your Parcel Delivery Status With More Than 1.048 carriers worldwide!",
    "category": "eCommerce",
    "title": "Worldwide Parcels Tracker Integration With Cold Email Sales Tool",
    "h1": "Integrate Worldwide Parcels Tracker with SalesBlink",
    "metaDescription": "Integrate Worldwide Parcels Tracker and other eCommerce Tools with SalesBlink. Two Way Worldwide Parcels Tracker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "neosigna",
    "slug": "neosigna",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a5765e84eedfb6826ad0ce1527f41e2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "neosigna is a collaborative contract management platform to speed up creating contracts and getting them signed.",
    "category": "Documents",
    "title": "neosigna Integration With Cold Email Sales Tool",
    "h1": "Integrate neosigna with SalesBlink",
    "metaDescription": "Integrate neosigna and other Documents Tools with SalesBlink. Two Way neosigna Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bluejeans Events",
    "slug": "bluejeans-events",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/514cc316837030def8e1c9b43430c9ac_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Host and manage live interactive events, virtual town halls and webcasts for large audiences around the world.",
    "category": "Video Conferencing",
    "title": "Bluejeans Events Integration With Cold Email Sales Tool",
    "h1": "Integrate Bluejeans Events with SalesBlink",
    "metaDescription": "Integrate Bluejeans Events and other Video Conferencing Tools with SalesBlink. Two Way Bluejeans Events Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taggg",
    "slug": "taggg",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/935404579d95e39cb1eecf438af6aa7e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taggg uses connected contacts & calendars to easily schedule online group meetings with multiple parties no matter if you use Google or Microsoft calendar.",
    "category": "Scheduling & Booking",
    "title": "Taggg Integration With Cold Email Sales Tool",
    "h1": "Integrate Taggg with SalesBlink",
    "metaDescription": "Integrate Taggg and other Scheduling & Booking Tools with SalesBlink. Two Way Taggg Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kiwili",
    "slug": "kiwili",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1081f254216c500b616a933fad0c5bc8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ERP & online account management software for your small business",
    "category": "Project Management",
    "title": "Kiwili Integration With Cold Email Sales Tool",
    "h1": "Integrate Kiwili with SalesBlink",
    "metaDescription": "Integrate Kiwili and other Project Management Tools with SalesBlink. Two Way Kiwili Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "APPLICA",
    "slug": "applica",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/97b0f3fa4fbf9d33c3eb472e5d38541a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "APPLICA accelerates your sales by engaging prospects with personalized A.I.-generated content and multi-channel marketing automation.",
    "category": "Marketing",
    "title": "APPLICA Integration With Cold Email Sales Tool",
    "h1": "Integrate APPLICA with SalesBlink",
    "metaDescription": "Integrate APPLICA and other Marketing Tools with SalesBlink. Two Way APPLICA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "shipit Product Planning",
    "slug": "shipit-product-planning",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/999af2a2728d480f0034b0b5c0ebae31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shipit is a product roadmap planning app. It provides a quarterly roadmap framework. You can manage and prioritise roadmap items for upcoming calendar quarters and for various product lines.",
    "category": "Product Management",
    "title": "shipit Product Planning Integration With Cold Email Sales Tool",
    "h1": "Integrate shipit Product Planning with SalesBlink",
    "metaDescription": "Integrate shipit Product Planning and other Product Management Tools with SalesBlink. Two Way shipit Product Planning Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GivingX.com",
    "slug": "givingx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b32a2b79a95a388e0b796fae0285e9e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fundraising is made simpler and better for any organization! Get all of our integrations and features for no extra fees! It helps people to make their own campaign and track their event by powerful integrations",
    "category": "Fundraising",
    "title": "GivingX.com Integration With Cold Email Sales Tool",
    "h1": "Integrate GivingX.com with SalesBlink",
    "metaDescription": "Integrate GivingX.com and other Fundraising Tools with SalesBlink. Two Way GivingX.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CLG App",
    "slug": "clg-app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/64e9e9da2dad0aa81611859594adfda3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CLG App is a cloud-based software and CRM for the credit repair industry designed for credit consultants and starting a credit repair business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "CLG App Integration With Cold Email Sales Tool",
    "h1": "Integrate CLG App with SalesBlink",
    "metaDescription": "Integrate CLG App and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way CLG App Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Landing Page Robot by Marcom Robot",
    "slug": "landing-page-robot-by-marcom-rob",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e51e5952ca56f65481794f9d72f12a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Landing Page Robot is a landing page builder designed for teams creating landing pages at scale.",
    "category": "Forms & Surveys",
    "title": "Landing Page Robot by Marcom Robot Integration With Cold Email Sales Tool",
    "h1": "Integrate Landing Page Robot by Marcom Robot with SalesBlink",
    "metaDescription": "Integrate Landing Page Robot by Marcom Robot and other Forms & Surveys Tools with SalesBlink. Two Way Landing Page Robot by Marcom Robot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vista Suite",
    "slug": "vista-suite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c597d09459a0a9beb20f2e08d4e95977.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vista Suite is a Business Management Cloud ERP Software",
    "category": "Marketing Automation",
    "title": "Vista Suite Integration With Cold Email Sales Tool",
    "h1": "Integrate Vista Suite with SalesBlink",
    "metaDescription": "Integrate Vista Suite and other Marketing Automation Tools with SalesBlink. Two Way Vista Suite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smart WiFi",
    "slug": "smart-wifi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd87dfaaa85ba2d497fe795771d694e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart WiFi is a marketing CRM which helps HoReCa clients build a database of their guests and interact with them via marketing widgets.",
    "category": "Databases",
    "title": "Smart WiFi Integration With Cold Email Sales Tool",
    "h1": "Integrate Smart WiFi with SalesBlink",
    "metaDescription": "Integrate Smart WiFi and other Databases Tools with SalesBlink. Two Way Smart WiFi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Funnel Shark",
    "slug": "funnel-shark",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6574a92b5493ff2db430cd359e56c150.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funnel Shark is a CMS that is used to build landing pages.",
    "category": "Website Builders",
    "title": "Funnel Shark Integration With Cold Email Sales Tool",
    "h1": "Integrate Funnel Shark with SalesBlink",
    "metaDescription": "Integrate Funnel Shark and other Website Builders Tools with SalesBlink. Two Way Funnel Shark Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tymbl Dialer",
    "slug": "tymbl-dialer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50ebf94bff0264d3df00a714e396c6ca_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Power dialer prospecting platform built for salespeople, by salespeople.",
    "category": "Phone & SMS",
    "title": "Tymbl Dialer Integration With Cold Email Sales Tool",
    "h1": "Integrate Tymbl Dialer with SalesBlink",
    "metaDescription": "Integrate Tymbl Dialer and other Phone & SMS Tools with SalesBlink. Two Way Tymbl Dialer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digital Pigeon",
    "slug": "digital-pigeon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e9e4d012c693589691beff26485ae0b_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital Pigeon is a large file sharing service for digital media producers, creatives, and anyone wishing to send large files via the internet.",
    "category": "Video & Audio",
    "title": "Digital Pigeon Integration With Cold Email Sales Tool",
    "h1": "Integrate Digital Pigeon with SalesBlink",
    "metaDescription": "Integrate Digital Pigeon and other Video & Audio Tools with SalesBlink. Two Way Digital Pigeon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Opendate",
    "slug": "opendate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/171495f1483701d5b90f29320e72d809.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Opendate is the data driven operating system for your venue. Transform how you discover, analyze, and book the best talent for your venue through our industry-leading software and events platform for venues.",
    "category": "Event Management",
    "title": "Opendate Integration With Cold Email Sales Tool",
    "h1": "Integrate Opendate with SalesBlink",
    "metaDescription": "Integrate Opendate and other Event Management Tools with SalesBlink. Two Way Opendate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Textgrid",
    "slug": "textgrid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/14da81ea2b8c53a9d49c0ae21d19cece_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Textgrid is a super low-cost cloud PaaS for sending out SMS/MMS",
    "category": "Communication",
    "title": "Textgrid Integration With Cold Email Sales Tool",
    "h1": "Integrate Textgrid with SalesBlink",
    "metaDescription": "Integrate Textgrid and other Communication Tools with SalesBlink. Two Way Textgrid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StoryStream",
    "slug": "storystream",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/259cdd4849852336e6fef0faaa6ee00e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoryStream is a content automation platform, that delivers authentic content to your digital channels.",
    "category": "Marketing",
    "title": "StoryStream Integration With Cold Email Sales Tool",
    "h1": "Integrate StoryStream with SalesBlink",
    "metaDescription": "Integrate StoryStream and other Marketing Tools with SalesBlink. Two Way StoryStream Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "6ix",
    "slug": "6ix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ae0ea84375e4246367a3b72fd468c31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our mission is to unlock social mobility on a global scale by empowering anyone from anywhere to invest in anything.",
    "category": "Business Intelligence",
    "title": "6ix Integration With Cold Email Sales Tool",
    "h1": "Integrate 6ix with SalesBlink",
    "metaDescription": "Integrate 6ix and other Business Intelligence Tools with SalesBlink. Two Way 6ix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bouncify",
    "slug": "bouncify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1feeeb1d36f041189e6e1fcbe6bb208.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Bouncify email verification service, you'll be able to validate your emails and get rid of unwanted emails from your email database.",
    "category": "Email",
    "title": "Bouncify Integration With Cold Email Sales Tool",
    "h1": "Integrate Bouncify with SalesBlink",
    "metaDescription": "Integrate Bouncify and other Email Tools with SalesBlink. Two Way Bouncify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Opsiq",
    "slug": "opsiq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/92d6136c5f6588e4b56d39931542d10e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Opsiq is a sale engagement platform that allows company to increase sales through dialing the right lead at the right time.",
    "category": "Sales & CRM",
    "title": "Opsiq Integration With Cold Email Sales Tool",
    "h1": "Integrate Opsiq with SalesBlink",
    "metaDescription": "Integrate Opsiq and other Sales & CRM Tools with SalesBlink. Two Way Opsiq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InterCloud9 SMS",
    "slug": "intercloud9",
    "logo": "https://zapier-images.imgix.net/storage/services/69c6f348d45c5b95fa7f20df628a5149.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connect with InterCloud9 SMS' simple messaging solution for sending Texts and Automated Voice Messages. Easily integrate your CRM or Application today!",
    "category": "Marketing Automation",
    "title": "InterCloud9 SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate InterCloud9 SMS with SalesBlink",
    "metaDescription": "Integrate InterCloud9 SMS and other Marketing Automation Tools with SalesBlink. Two Way InterCloud9 SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myhm",
    "slug": "myhm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa28b4f90587e85dcb5e3e45a9e95b9b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myhm is a CRM and communication tool for residential construction companies.",
    "category": "CRM (Customer Relationship Management)",
    "title": "myhm Integration With Cold Email Sales Tool",
    "h1": "Integrate myhm with SalesBlink",
    "metaDescription": "Integrate myhm and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way myhm Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PowerTools",
    "slug": "powertools",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/898c093da0aa1ea0f2f08854d2e429b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The ultimate collection of no-code business automation plugins for any app",
    "category": "Website & App Building",
    "title": "PowerTools Integration With Cold Email Sales Tool",
    "h1": "Integrate PowerTools with SalesBlink",
    "metaDescription": "Integrate PowerTools and other Website & App Building Tools with SalesBlink. Two Way PowerTools Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "90-Day Sales",
    "slug": "90-day-sales",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/51b940368b85762328cd21cf1ce8f4f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "90-Day Sales is an easy-to-use CRM that features gamification through a Scoreboard that tracks your sales activities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "90-Day Sales Integration With Cold Email Sales Tool",
    "h1": "Integrate 90-Day Sales with SalesBlink",
    "metaDescription": "Integrate 90-Day Sales and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 90-Day Sales Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BP Pay",
    "slug": "bp-pay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/58076d8494905384f103b05b8c7f3452_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BP Pay provides blockchain payment solutions for fast, frictionless, low-cost transactions using Stellar Blockchain and Circle's USDC.",
    "category": "Payment Processing",
    "title": "BP Pay Integration With Cold Email Sales Tool",
    "h1": "Integrate BP Pay with SalesBlink",
    "metaDescription": "Integrate BP Pay and other Payment Processing Tools with SalesBlink. Two Way BP Pay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dasha",
    "slug": "dasha",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd1a7cd82cc7999f373093ae763720bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dasha is a conversational-AI-as-a-service platform that lets you embed realistic voice and text conversational capabilities into your apps or products. With a single integration, create smart conversational apps for web, desktop, mobile, IoT and call centers.",
    "category": "Developer Tools",
    "title": "Dasha Integration With Cold Email Sales Tool",
    "h1": "Integrate Dasha with SalesBlink",
    "metaDescription": "Integrate Dasha and other Developer Tools Tools with SalesBlink. Two Way Dasha Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobCloser",
    "slug": "jobcloser",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c09a869686edb4ad748eea4fe5ae0e77.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobCloser is a custom CRM made to service the field service industry, helping small businesses quote jobs, invoice for work, collect payments online, and get paid faster.",
    "category": "Project Management",
    "title": "JobCloser Integration With Cold Email Sales Tool",
    "h1": "Integrate JobCloser with SalesBlink",
    "metaDescription": "Integrate JobCloser and other Project Management Tools with SalesBlink. Two Way JobCloser Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Everact.io",
    "slug": "everactio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20a5515c8b92a8b06fc467448bd79d81.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evaract.io is LMS and a Marketing and Automation platform",
    "category": "CRM (Customer Relationship Management)",
    "title": "Everact.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Everact.io with SalesBlink",
    "metaDescription": "Integrate Everact.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Everact.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bbot",
    "slug": "bbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e196138bff7d62fa5bb8670c4d982466.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bbot lets your customers order from anywhere, on any device with touchless food ordering. Guests can use their smartphone to order and reorder what they want, when they want it. Zero Commission Fees, In-Venue Ordering, Quickly Access Data.",
    "category": "Commerce",
    "title": "Bbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Bbot with SalesBlink",
    "metaDescription": "Integrate Bbot and other Commerce Tools with SalesBlink. Two Way Bbot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Touch & Sell",
    "slug": "touch-sell",
    "logo": "https://zapier-images.imgix.net/storage/developer/f4c0d4066e8a42c90d012c7ca4e1d4fe_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Touch & Sell is a sales enablement application to improve your presentations and align your marketing and sales teams.",
    "category": "Sales & CRM",
    "title": "Touch & Sell Integration With Cold Email Sales Tool",
    "h1": "Integrate Touch & Sell with SalesBlink",
    "metaDescription": "Integrate Touch & Sell and other Sales & CRM Tools with SalesBlink. Two Way Touch & Sell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wise Pelican",
    "slug": "wise-pelican",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76c421ed8ca1ad1e96f1eb5b80ce95df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wise Pelican is a post card mailing service.",
    "category": "Images & Design",
    "title": "Wise Pelican Integration With Cold Email Sales Tool",
    "h1": "Integrate Wise Pelican with SalesBlink",
    "metaDescription": "Integrate Wise Pelican and other Images & Design Tools with SalesBlink. Two Way Wise Pelican Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmartSearch",
    "slug": "smartsearch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4949505f69a64fd6a405dfb12e320356.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmartSearch is a global platform that helps companies quickly identify, recruit, and onboard talent.",
    "category": "HR Talent & Recruitment",
    "title": "SmartSearch Integration With Cold Email Sales Tool",
    "h1": "Integrate SmartSearch with SalesBlink",
    "metaDescription": "Integrate SmartSearch and other HR Talent & Recruitment Tools with SalesBlink. Two Way SmartSearch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dashblock",
    "slug": "dashblock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/850593a3ffb17387aed41042305efdcd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dashblock is the easiest way to automate websites without coding. Put your manual tasks on autopilot to save time and focus on what really matters.",
    "category": "Productivity",
    "title": "Dashblock Integration With Cold Email Sales Tool",
    "h1": "Integrate Dashblock with SalesBlink",
    "metaDescription": "Integrate Dashblock and other Productivity Tools with SalesBlink. Two Way Dashblock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spike.sh",
    "slug": "spikesh",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c3c708bca5abcc0de356fa5dd4adea8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spike is an incident management system with unlimited alerts and on-call schedules",
    "category": "Developer Tools",
    "title": "Spike.sh Integration With Cold Email Sales Tool",
    "h1": "Integrate Spike.sh with SalesBlink",
    "metaDescription": "Integrate Spike.sh and other Developer Tools Tools with SalesBlink. Two Way Spike.sh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coffee Chats",
    "slug": "coffee-chats",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/91bd84c70b6a8f6df31fd7c823585920.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coffee Chats is a website manager, scheduling, and payment platform all-in-one app perfect for coaches and mentors.",
    "category": "Scheduling & Booking",
    "title": "Coffee Chats Integration With Cold Email Sales Tool",
    "h1": "Integrate Coffee Chats with SalesBlink",
    "metaDescription": "Integrate Coffee Chats and other Scheduling & Booking Tools with SalesBlink. Two Way Coffee Chats Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RangeFlow",
    "slug": "rangeflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57d130d198f9759bb3ae699e5acf3d4e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your email outreach without stepping out of your favorite apps — Google Sheets and Gmail!​",
    "category": "Marketing Automation",
    "title": "RangeFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate RangeFlow with SalesBlink",
    "metaDescription": "Integrate RangeFlow and other Marketing Automation Tools with SalesBlink. Two Way RangeFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ScheduleTalk",
    "slug": "scheduletalk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed76d8e7377b168d1bbe739dca540642.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ScheduleTalk is a messaging platform built to help service businesses generate leads & close sales.",
    "category": "Communication",
    "title": "ScheduleTalk Integration With Cold Email Sales Tool",
    "h1": "Integrate ScheduleTalk with SalesBlink",
    "metaDescription": "Integrate ScheduleTalk and other Communication Tools with SalesBlink. Two Way ScheduleTalk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gerar certificado",
    "slug": "gerar-certificado",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c197c5ae3310139d8141c4148a02f15.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Generate free and online certificate. With this tool you can issue certificates for free courses. The process is simple and takes just a few minutes.",
    "category": "Documents",
    "title": "Gerar certificado Integration With Cold Email Sales Tool",
    "h1": "Integrate Gerar certificado with SalesBlink",
    "metaDescription": "Integrate Gerar certificado and other Documents Tools with SalesBlink. Two Way Gerar certificado Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LOX24 SMS Gateway",
    "slug": "lox24-sms-gateway",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/32d745587f67e0a2cc90b938bc551d5f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LOX24 SMS Gateway is a paid service which let's you send text messages (SMS) to individual phone numbers or groups of phone numbers. It is also possible to receive SMS. Various inbound numbers are available for this purpose.",
    "category": "Phone & SMS",
    "title": "LOX24 SMS Gateway Integration With Cold Email Sales Tool",
    "h1": "Integrate LOX24 SMS Gateway with SalesBlink",
    "metaDescription": "Integrate LOX24 SMS Gateway and other Phone & SMS Tools with SalesBlink. Two Way LOX24 SMS Gateway Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HomeBozz",
    "slug": "homebozz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18115ab545257a7073f2b9293eca69ad_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HomeBozz for Business enables Real Estate Professionals (Property Manager, Property Developer) to streamline and automate their daily operation.",
    "category": "Commerce",
    "title": "HomeBozz Integration With Cold Email Sales Tool",
    "h1": "Integrate HomeBozz with SalesBlink",
    "metaDescription": "Integrate HomeBozz and other Commerce Tools with SalesBlink. Two Way HomeBozz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneHash",
    "slug": "onehash",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f05b5dd10697c5403ebb80898b3c4970.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneHash is a cloud-based, robust, scalable, agile, affordable, and fully featured FaaS solution with top-notch AI-automated Customer Assistance, CRM, ERP, HCM, Project Management, Helpdesk, and other SaaS that your business may need.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OneHash Integration With Cold Email Sales Tool",
    "h1": "Integrate OneHash with SalesBlink",
    "metaDescription": "Integrate OneHash and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OneHash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intelligo",
    "slug": "intelligo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d44a4ff4a04dbee017764958bdfc9aa9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intelligo is a flexible web-based platform for delivering virtual experiences online.",
    "category": "Lifestyle & Entertainment",
    "title": "Intelligo Integration With Cold Email Sales Tool",
    "h1": "Integrate Intelligo with SalesBlink",
    "metaDescription": "Integrate Intelligo and other Lifestyle & Entertainment Tools with SalesBlink. Two Way Intelligo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoiceMailTel Meet",
    "slug": "voicemailtel-meet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0280bed7b8efdcfa852a33853a9e1fef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoiceMailTel Meet is a video conferencing service that replicates your office environment with custom settings for quick conference creation.",
    "category": "Video Conferencing",
    "title": "VoiceMailTel Meet Integration With Cold Email Sales Tool",
    "h1": "Integrate VoiceMailTel Meet with SalesBlink",
    "metaDescription": "Integrate VoiceMailTel Meet and other Video Conferencing Tools with SalesBlink. Two Way VoiceMailTel Meet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tender Support",
    "slug": "tender-support",
    "logo": "https://zapier-images.imgix.net/storage/developer/d9baca3e432b71c35a1508c3f95fa34d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tender Support is an elegant help desk that organizes your customer care so you can focus on delivering the best service possible.",
    "category": "Customer Support",
    "title": "Tender Support Integration With Cold Email Sales Tool",
    "h1": "Integrate Tender Support with SalesBlink",
    "metaDescription": "Integrate Tender Support and other Customer Support Tools with SalesBlink. Two Way Tender Support Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Advision.it",
    "slug": "sendnetit",
    "logo": "https://zapier-images.imgix.net/storage/services/2d1cc3cfc76dbcba9c5ac88b33ea5a8f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Advision.it is an Italian professional email marketing platform.",
    "category": "Marketing",
    "title": "Advision.it Integration With Cold Email Sales Tool",
    "h1": "Integrate Advision.it with SalesBlink",
    "metaDescription": "Integrate Advision.it and other Marketing Tools with SalesBlink. Two Way Advision.it Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OutReachBin",
    "slug": "outreachbin-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43ffcbb86e97af25f744db0b3365f223.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outreachbin is a cold email outreach tool, that helps users warm up and send emails to inbox.",
    "category": "Marketing Automation",
    "title": "OutReachBin Integration With Cold Email Sales Tool",
    "h1": "Integrate OutReachBin with SalesBlink",
    "metaDescription": "Integrate OutReachBin and other Marketing Automation Tools with SalesBlink. Two Way OutReachBin Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "MangoApps",
    "slug": "mangoapps",
    "logo": "https://zapier-images.imgix.net/storage/services/82ff28ec4e9c1f43a4b7308b39a60fbe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MangoApps provides cloud-based intranet, collaboration, HR software to modernize, streamline and improve employee engagement & productivity.",
    "category": "Team Collaboration",
    "title": "MangoApps Integration With Cold Email Sales Tool",
    "h1": "Integrate MangoApps with SalesBlink",
    "metaDescription": "Integrate MangoApps and other Team Collaboration Tools with SalesBlink. Two Way MangoApps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KONDESK CRM",
    "slug": "kondesk-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d5b55c4e17c7682bf451c4d3f96240a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KONDESK is a customer relationship management platform that helps to organize leads (customers) and manage them.",
    "category": "CRM (Customer Relationship Management)",
    "title": "KONDESK CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate KONDESK CRM with SalesBlink",
    "metaDescription": "Integrate KONDESK CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way KONDESK CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Value Builder System™",
    "slug": "the-value-builder-systemtm-ca180",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/282f51383c2eca976e5f5535cc177796_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Value Builder is sales & marketing software purpose-built for business advisors to find, win and keep their best clients.",
    "category": "Marketing Automation",
    "title": "The Value Builder System™ Integration With Cold Email Sales Tool",
    "h1": "Integrate The Value Builder System™ with SalesBlink",
    "metaDescription": "Integrate The Value Builder System™ and other Marketing Automation Tools with SalesBlink. Two Way The Value Builder System™ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Microsoft SharePoint",
    "slug": "sharepoint",
    "logo": "https://zapier-images.imgix.net/storage/services/057cdf76061854216e940f624ce3379c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft SharePoint is a service that helps organizations share content to quickly find information and seamlessly collaborate.",
    "category": "Microsoft",
    "title": "Microsoft SharePoint Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsoft SharePoint with SalesBlink",
    "metaDescription": "Integrate Microsoft SharePoint and other Microsoft Tools with SalesBlink. Two Way Microsoft SharePoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WordPress (Legacy)",
    "slug": "wordpress-legacy",
    "logo": "https://zapier-images.imgix.net/storage/services/082ad2b5fd42bbe2319312a8ccad248d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WordPress is web software you can use to create a beautiful website or blog. Nearly 20% of the top 10 million websites and over 60 million people have chosen WordPress to power the place on the web they call \"home\".",
    "category": "Website Builders",
    "title": "WordPress (Legacy) Integration With Cold Email Sales Tool",
    "h1": "Integrate WordPress (Legacy) with SalesBlink",
    "metaDescription": "Integrate WordPress (Legacy) and other Website Builders Tools with SalesBlink. Two Way WordPress (Legacy) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Batchbook",
    "slug": "batchbook",
    "logo": "https://zapier-images.imgix.net/storage/services/815f36e884fc7b4a3f2be499385ff504.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Batchbook is a social CRM. It has all the standard features of a CRM plus it can take in Twitter streams, Facebook updates, RSS feeds and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Batchbook Integration With Cold Email Sales Tool",
    "h1": "Integrate Batchbook with SalesBlink",
    "metaDescription": "Integrate Batchbook and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Batchbook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trigger",
    "slug": "triggerapp",
    "logo": "https://zapier-images.imgix.net/storage/developer/3b53b824997c27ecb82e56bf6278c20e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trigger is cloud software for project management, collaboration, and time tracking. Closely integrated with Xero, you can import your customers, accounts and tracking categories, then send invoices. Task titles become line-items, timesheets become invoices - our workflow is coherent, and saves you time.",
    "category": "Project Management",
    "title": "Trigger Integration With Cold Email Sales Tool",
    "h1": "Integrate Trigger with SalesBlink",
    "metaDescription": "Integrate Trigger and other Project Management Tools with SalesBlink. Two Way Trigger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flowdock",
    "slug": "flowdock",
    "logo": "https://zapier-images.imgix.net/storage/services/337399808477d8900420ec37c4ac33cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flowdock is your team's inbox, but with chat. Get updates from all the apps you use, but then easily have discussions around those items.",
    "category": "Team Chat",
    "title": "Flowdock Integration With Cold Email Sales Tool",
    "h1": "Integrate Flowdock with SalesBlink",
    "metaDescription": "Integrate Flowdock and other Team Chat Tools with SalesBlink. Two Way Flowdock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Keep In Touch",
    "slug": "keep-in-touch",
    "logo": "https://zapier-images.imgix.net/storage/developer/5f1da3abeafd52d82b4285096eebf3af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keep In Touch is a cloud based CRM system for Builders looking to improve their sales process and follow up enquiries automatically using software that can be learnt in under 60 minutes… guaranteed.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Keep In Touch Integration With Cold Email Sales Tool",
    "h1": "Integrate Keep In Touch with SalesBlink",
    "metaDescription": "Integrate Keep In Touch and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Keep In Touch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SoundCloud",
    "slug": "soundcloud",
    "logo": "https://zapier-images.imgix.net/storage/developer/e833af1046e989b5d65833e955b34d4f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The world's largest community of artists, bands, podcasters and creators of music and audio.",
    "category": "Video & Audio",
    "title": "SoundCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate SoundCloud with SalesBlink",
    "metaDescription": "Integrate SoundCloud and other Video & Audio Tools with SalesBlink. Two Way SoundCloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Snappy",
    "slug": "snappy",
    "logo": "https://zapier-images.imgix.net/storage/developer/bf3a68ba5dbcfd9dfacc2e64b154b25f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snappy is a help desk application for managing your email, providing customer self service and efficiently managing all your customer service tickets.",
    "category": "Customer Support",
    "title": "Snappy Integration With Cold Email Sales Tool",
    "h1": "Integrate Snappy with SalesBlink",
    "metaDescription": "Integrate Snappy and other Customer Support Tools with SalesBlink. Two Way Snappy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "elmah.io",
    "slug": "elmah-io",
    "logo": "https://zapier-images.imgix.net/storage/developer/efa37e979cd9d0fd2a849a45b7579185_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud logging for .NET web applications using ELMAH. Powerful search, custom rules, integration with issue trackers, API and much more.",
    "category": "Developer Tools",
    "title": "elmah.io Integration With Cold Email Sales Tool",
    "h1": "Integrate elmah.io with SalesBlink",
    "metaDescription": "Integrate elmah.io and other Developer Tools Tools with SalesBlink. Two Way elmah.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Diffbot",
    "slug": "diffbot",
    "logo": "https://zapier-images.imgix.net/storage/developer/ca4278aeccb8a26fc965a200cd96dcb3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Diffbot APIs extract data from web pages: articles, blog posts, products -- or any other page using our Custom API Toolkit. Crawlbot, our intelligent spider, crawls entire sites and returns the full data in clean, readable JSON. Our Bulk API allows for the extraction of thousands or millions of URLs. Be notified whenever a Crawlbot crawl or Bulk API job completes.",
    "category": "Documents",
    "title": "Diffbot Integration With Cold Email Sales Tool",
    "h1": "Integrate Diffbot with SalesBlink",
    "metaDescription": "Integrate Diffbot and other Documents Tools with SalesBlink. Two Way Diffbot Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CallOnTheGo",
    "slug": "callonthego",
    "logo": "https://zapier-images.imgix.net/storage/developer/7a31612aa62093c0b7f6d14132f6cc82_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallOnTheGo is a mobile application that auto speed dials lists of numbers for sales and provides detailed analytics and tracking results online.",
    "category": "Phone & SMS",
    "title": "CallOnTheGo Integration With Cold Email Sales Tool",
    "h1": "Integrate CallOnTheGo with SalesBlink",
    "metaDescription": "Integrate CallOnTheGo and other Phone & SMS Tools with SalesBlink. Two Way CallOnTheGo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nexticy",
    "slug": "nexticy",
    "logo": "https://zapier-images.imgix.net/storage/developer/0089b6c18134d370d29b066eb395667c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nexticy is the most innovative form builder that helps you create paper-like forms and documents. With Nexticy you can make your work paperless and become more productive with just an iPad.",
    "category": "Forms & Surveys",
    "title": "Nexticy Integration With Cold Email Sales Tool",
    "h1": "Integrate Nexticy with SalesBlink",
    "metaDescription": "Integrate Nexticy and other Forms & Surveys Tools with SalesBlink. Two Way Nexticy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon Relational Database Services (RDS)",
    "slug": "amazon-rds",
    "logo": "https://zapier-images.imgix.net/storage/services/1bcbc3c66a978375c8df50b07ce18db7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Relational Database Services (RDS) allow you to set up, operate, and scale relational databases in the cloud.",
    "category": "Amazon",
    "title": "Amazon Relational Database Services (RDS) Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Relational Database Services (RDS) with SalesBlink",
    "metaDescription": "Integrate Amazon Relational Database Services (RDS) and other Amazon Tools with SalesBlink. Two Way Amazon Relational Database Services (RDS) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swiftype",
    "slug": "swiftype",
    "logo": "https://zapier-images.imgix.net/storage/developer/22ac0bb309430ba1e5be20a3aae02a02.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swiftype is a hosted search solution that lets site owners deliver powerful, customizable search for their website or mobile app.",
    "category": "Documents",
    "title": "Swiftype Integration With Cold Email Sales Tool",
    "h1": "Integrate Swiftype with SalesBlink",
    "metaDescription": "Integrate Swiftype and other Documents Tools with SalesBlink. Two Way Swiftype Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Biztera",
    "slug": "biztera",
    "logo": "https://zapier-images.imgix.net/storage/developer/4e4734616dd2007ffcbdeef6d8a5283e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Biztera is a service for organizations to keep track of approval requests between members. For example, CFOs use Biztera to approve big ticket items, and keep track of expenses versus budget. IT managers use Biztera to request approval for expensive services to beef up security. By streamlining the approval process, Biztera eliminates ambiguity in the workplace and speeds up decision-making.",
    "category": "Proposal & Invoice Management",
    "title": "Biztera Integration With Cold Email Sales Tool",
    "h1": "Integrate Biztera with SalesBlink",
    "metaDescription": "Integrate Biztera and other Proposal & Invoice Management Tools with SalesBlink. Two Way Biztera Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Horntell",
    "slug": "horntell",
    "logo": "https://zapier-images.imgix.net/storage/developer/062cea2782984c843074d61b4fb24a4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Horntell is a notification center which allows you to have a single feed for all your notifications. Stay updated through actionable notifications and have total control so no one can spam you.",
    "category": "Notifications",
    "title": "Horntell Integration With Cold Email Sales Tool",
    "h1": "Integrate Horntell with SalesBlink",
    "metaDescription": "Integrate Horntell and other Notifications Tools with SalesBlink. Two Way Horntell Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pronto",
    "slug": "prontoforms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1612dfc6f2e35799711ed54d30a25e83.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pronto is a partner Ecosystem management platform that enables organizations to partner together and collaborate.",
    "category": "Sales & CRM",
    "title": "Pronto Integration With Cold Email Sales Tool",
    "h1": "Integrate Pronto with SalesBlink",
    "metaDescription": "Integrate Pronto and other Sales & CRM Tools with SalesBlink. Two Way Pronto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dasheroo",
    "slug": "dasheroo",
    "logo": "https://zapier-images.imgix.net/storage/developer/81287192fc40dbce569da7199ef6d6b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Track all of your important metrics for web analytics, email marketing, social media, marketing and sales, in one great-looking free business dashboard.",
    "category": "Dashboards",
    "title": "Dasheroo Integration With Cold Email Sales Tool",
    "h1": "Integrate Dasheroo with SalesBlink",
    "metaDescription": "Integrate Dasheroo and other Dashboards Tools with SalesBlink. Two Way Dasheroo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Revamp CRM",
    "slug": "revamp-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer/19edbeec64d4061c89e1d512d0dabe00.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revamp CRM is a Sales & Marketing CRM. Make more sales whether you are using multiple sales pipelines or an eCommerce store.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Revamp CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Revamp CRM with SalesBlink",
    "metaDescription": "Integrate Revamp CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Revamp CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cflow",
    "slug": "cflow",
    "logo": "https://zapier-images.imgix.net/storage/developer/231a777b3f4ca6d26e58887e340fb7d7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cflow is a cloud-based workflow software that provides a way to create business applications that help increase productivity and reduce costs.",
    "category": "Developer Tools",
    "title": "Cflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Cflow with SalesBlink",
    "metaDescription": "Integrate Cflow and other Developer Tools Tools with SalesBlink. Two Way Cflow Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MyBizzMail",
    "slug": "mybizzmail",
    "logo": "https://zapier-images.imgix.net/storage/developer/87ce2dc729c2e75a164f712230c7a1ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mybizzmail is an email marketing service provider.\nWith it's great drag and drop editor you can easily make newsletters, magazines and forms.\nMyBizzMail also gives you a report of who has visited which pages on your site via the email.",
    "category": "Email Newsletters",
    "title": "MyBizzMail Integration With Cold Email Sales Tool",
    "h1": "Integrate MyBizzMail with SalesBlink",
    "metaDescription": "Integrate MyBizzMail and other Email Newsletters Tools with SalesBlink. Two Way MyBizzMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EasySendy",
    "slug": "easysendy-pro-da35212",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/31130f7150f42c3b818ba0d98996295f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasySendy is a hybrid marketing platform for online gig creators like, bloggers, podcasters, vlogger, business coach, YouTubers, makers, writers, course creators, consultants, freelancers, author, coaches, musicians, photographers and startups",
    "category": "Marketing Automation",
    "title": "EasySendy Integration With Cold Email Sales Tool",
    "h1": "Integrate EasySendy with SalesBlink",
    "metaDescription": "Integrate EasySendy and other Marketing Automation Tools with SalesBlink. Two Way EasySendy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "ViewPoint VISUM",
    "slug": "viewpoint-visum",
    "logo": "https://zapier-images.imgix.net/storage/developer/a75614d155d9493f52f2c6c94464c27d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ViewPoint VISUM is a great tool for Visual Portfolio Project Management. VISUM helps to deliver projects on time, in less time by streamlining execution. It simplifies managing projects by improving communication, collaboration and decision making.",
    "category": "Project Management",
    "title": "ViewPoint VISUM Integration With Cold Email Sales Tool",
    "h1": "Integrate ViewPoint VISUM with SalesBlink",
    "metaDescription": "Integrate ViewPoint VISUM and other Project Management Tools with SalesBlink. Two Way ViewPoint VISUM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boon",
    "slug": "boon",
    "logo": "https://zapier-images.imgix.net/storage/developer/303b993a02df7f1ff1c67648896a820e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boon is a social recruiting network that lets businesses engage and hire the right candidates faster through intelligent social referrals.",
    "category": "HR Talent & Recruitment",
    "title": "Boon Integration With Cold Email Sales Tool",
    "h1": "Integrate Boon with SalesBlink",
    "metaDescription": "Integrate Boon and other HR Talent & Recruitment Tools with SalesBlink. Two Way Boon Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FileCloud",
    "slug": "filecloud",
    "logo": "https://zapier-images.imgix.net/storage/services/ed3035cbc49b26d83a76ea16b07bcb92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FileCloud is an Enterprise File Access and Share solution that runs on-premise, integrated with your own IT infrastructure and using your own storage.",
    "category": "File Management & Storage",
    "title": "FileCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate FileCloud with SalesBlink",
    "metaDescription": "Integrate FileCloud and other File Management & Storage Tools with SalesBlink. Two Way FileCloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Visage",
    "slug": "visage",
    "logo": "https://zapier-images.imgix.net/storage/developer/2a457b63d4478794c419972e10a9bc19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "For content marketers who need to create a lot of visual content,  Visage is a design tool that makes on-brand content creation simple. Unlike working with slow and expensive vendors, Visage makes beautiful content creation easy.",
    "category": "Images & Design",
    "title": "Visage Integration With Cold Email Sales Tool",
    "h1": "Integrate Visage with SalesBlink",
    "metaDescription": "Integrate Visage and other Images & Design Tools with SalesBlink. Two Way Visage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TruConversion Connect",
    "slug": "truconversion-connect-da50046",
    "logo": "https://zapier-images.imgix.net/storage/developer/735784bfdc32f5daab69d576bd2ee5f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrate TruConversion and get results in your favorite App.  You get access to all the best conversion optimization and analytics tools including Heatmaps, Visitor Session Recordings, Conversion funnels, MicroSurvey, Customer Surveys and Form Analytics.",
    "category": "Analytics",
    "title": "TruConversion Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate TruConversion Connect with SalesBlink",
    "metaDescription": "Integrate TruConversion Connect and other Analytics Tools with SalesBlink. Two Way TruConversion Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pepipost",
    "slug": "pepipost",
    "logo": "https://zapier-images.imgix.net/storage/developer/b5b3e577a5d87f357e1dc15c945d815f_5.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pepipost has a highly scalable cloud based platform that is capable of delivering billions of emails every hour with email delivery expertise that ensures the best inbox rates.",
    "category": "Email",
    "title": "Pepipost Integration With Cold Email Sales Tool",
    "h1": "Integrate Pepipost with SalesBlink",
    "metaDescription": "Integrate Pepipost and other Email Tools with SalesBlink. Two Way Pepipost Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Custom Gateway OMS",
    "slug": "custom-gateway-oms",
    "logo": "https://zapier-images.imgix.net/storage/developer/e82cce8cac54bf168d89ccd38d08f9d7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Custom Gateway is a system for creating artwork and managing / distributing orders for personalized and print on demand products.",
    "category": "eCommerce",
    "title": "Custom Gateway OMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Custom Gateway OMS with SalesBlink",
    "metaDescription": "Integrate Custom Gateway OMS and other eCommerce Tools with SalesBlink. Two Way Custom Gateway OMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ITM Platform",
    "slug": "itm-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer/683d68d2a43982f6f92141056ebf9d49.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ITM Platform is a project, program, and portfolio management solution with no onboarding hassle.",
    "category": "Project Management",
    "title": "ITM Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate ITM Platform with SalesBlink",
    "metaDescription": "Integrate ITM Platform and other Project Management Tools with SalesBlink. Two Way ITM Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tubular",
    "slug": "tubular",
    "logo": "https://zapier-images.imgix.net/storage/developer/9746a5c3d436951dea4a071b83f38311.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tubular is a simple sales pipeline software, built for teams, that lets you convert more leads, close more sales, and track your deal flow.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tubular Integration With Cold Email Sales Tool",
    "h1": "Integrate Tubular with SalesBlink",
    "metaDescription": "Integrate Tubular and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tubular Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wire2Air",
    "slug": "wire2air",
    "logo": "https://zapier-images.imgix.net/storage/developer/b9f917db52d1b2267ead1bfed68e21c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wire2Air offer a mobile engagement platform and SMS Mobile Messaging services to collect contacts, send a text and MMS message to individuals or a group.",
    "category": "Phone & SMS",
    "title": "Wire2Air Integration With Cold Email Sales Tool",
    "h1": "Integrate Wire2Air with SalesBlink",
    "metaDescription": "Integrate Wire2Air and other Phone & SMS Tools with SalesBlink. Two Way Wire2Air Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudBoost",
    "slug": "cloudboost",
    "logo": "https://zapier-images.imgix.net/storage/developer/6bcfc89005cab7b7dfe6852ffd09904b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudBoost is a serverless platform that helps you build your apps & allows users to have real-time implementation in their app.",
    "category": "Developer Tools",
    "title": "CloudBoost Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudBoost with SalesBlink",
    "metaDescription": "Integrate CloudBoost and other Developer Tools Tools with SalesBlink. Two Way CloudBoost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Songkick",
    "slug": "songkick",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5040f50c6c7500ba2b2e8202e6bfad68_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Songkick is a concert discovery and ticket sales service for live music events.",
    "category": "Event Management",
    "title": "Songkick Integration With Cold Email Sales Tool",
    "h1": "Integrate Songkick with SalesBlink",
    "metaDescription": "Integrate Songkick and other Event Management Tools with SalesBlink. Two Way Songkick Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spark Chart",
    "slug": "sparkchart",
    "logo": "https://zapier-images.imgix.net/storage/developer/02a8bf1877b0e368bec05d222b5faf8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spark Chart is survey software to rapidly build and design surveys, analyze results, and share reports instantly.",
    "category": "Forms & Surveys",
    "title": "Spark Chart Integration With Cold Email Sales Tool",
    "h1": "Integrate Spark Chart with SalesBlink",
    "metaDescription": "Integrate Spark Chart and other Forms & Surveys Tools with SalesBlink. Two Way Spark Chart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docsify",
    "slug": "docsify",
    "logo": "https://zapier-images.imgix.net/storage/services/084698dc1b6f7c248286f509b9e4d73e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docsify is a chrome extension for Gmail and G Suite that tracks email opens, link clicks, attachment downloads, and per-page document and website views that you send via email. And Docsify automates and prioritizes your sales workflow based on recipient's engagement with anything you emailed them",
    "category": "Email",
    "title": "Docsify Integration With Cold Email Sales Tool",
    "h1": "Integrate Docsify with SalesBlink",
    "metaDescription": "Integrate Docsify and other Email Tools with SalesBlink. Two Way Docsify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ridehire",
    "slug": "ridehire",
    "logo": "https://zapier-images.imgix.net/storage/developer/1f4a1c1db3366d791950d027b33e5614_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ridehire is an online marketplace for vehicles for hire allowing operators to bring transparency to their fleet.",
    "category": "eCommerce",
    "title": "Ridehire Integration With Cold Email Sales Tool",
    "h1": "Integrate Ridehire with SalesBlink",
    "metaDescription": "Integrate Ridehire and other eCommerce Tools with SalesBlink. Two Way Ridehire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tortii",
    "slug": "tortii",
    "logo": "https://zapier-images.imgix.net/storage/services/8445cbbe054fbb8a0d292d808cc054b0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "It's turtles all the way down.\n\n“Turtle” by Zidney, from the Noun Project.",
    "category": "Team Chat",
    "title": "Tortii Integration With Cold Email Sales Tool",
    "h1": "Integrate Tortii with SalesBlink",
    "metaDescription": "Integrate Tortii and other Team Chat Tools with SalesBlink. Two Way Tortii Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spondyr",
    "slug": "spondyr",
    "logo": "https://zapier-images.imgix.net/storage/developer/dc4e63cb935acda038c788de54be2142.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spondyr is a templated document & correspondence platform, enabling you to effortlessly deliver template-driven content to your recipients.",
    "category": "Documents",
    "title": "Spondyr Integration With Cold Email Sales Tool",
    "h1": "Integrate Spondyr with SalesBlink",
    "metaDescription": "Integrate Spondyr and other Documents Tools with SalesBlink. Two Way Spondyr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "leadPops (Legacy)",
    "slug": "leadpops",
    "logo": "https://zapier-images.imgix.net/storage/developer/8f4fe6a9c6e5bd7a3f00fe13bb54e53b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "leadPops develops lead generation technology and marketing solutions for mortgage, real estate, and insurance pros.",
    "category": "Marketing Automation",
    "title": "leadPops (Legacy) Integration With Cold Email Sales Tool",
    "h1": "Integrate leadPops (Legacy) with SalesBlink",
    "metaDescription": "Integrate leadPops (Legacy) and other Marketing Automation Tools with SalesBlink. Two Way leadPops (Legacy) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GenieBelt",
    "slug": "geniebelt",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c0aa0a749e908f3e84acd9f903d3f5f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GenieBelt is online construction project management software that helps you to run projects with ease and save budget.",
    "category": "Project Management",
    "title": "GenieBelt Integration With Cold Email Sales Tool",
    "h1": "Integrate GenieBelt with SalesBlink",
    "metaDescription": "Integrate GenieBelt and other Project Management Tools with SalesBlink. Two Way GenieBelt Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zipzappo",
    "slug": "zipzappo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f016f1eb8a9a5be61ad8f0dee1fea655.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZipZappo allows you to send personalized emails, SMS messages, or calls to your prospects.",
    "category": "Marketing Automation",
    "title": "Zipzappo Integration With Cold Email Sales Tool",
    "h1": "Integrate Zipzappo with SalesBlink",
    "metaDescription": "Integrate Zipzappo and other Marketing Automation Tools with SalesBlink. Two Way Zipzappo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wintouch 7",
    "slug": "wintouch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f140df14c551aaf221fbd7b61a64fd40.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wintouch 7 CRM is an all-in-one software solution that is designed to help businesses of all sizes improve productivity in marketing, sales, customer service, and administration.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wintouch 7 Integration With Cold Email Sales Tool",
    "h1": "Integrate Wintouch 7 with SalesBlink",
    "metaDescription": "Integrate Wintouch 7 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wintouch 7 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evolphin Zoom",
    "slug": "evolphin-zoom",
    "logo": "https://zapier-images.imgix.net/storage/developer/c5c251acacee0ae203bdc0c09df055fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evolphin Zoom is a media management platform that powers the work-in-progress creative pipelines of companies around the globe.",
    "category": "Developer Tools",
    "title": "Evolphin Zoom Integration With Cold Email Sales Tool",
    "h1": "Integrate Evolphin Zoom with SalesBlink",
    "metaDescription": "Integrate Evolphin Zoom and other Developer Tools Tools with SalesBlink. Two Way Evolphin Zoom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Acquire (Legacy)",
    "slug": "acquire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/93a8b74f1405593b6e0e77e92c26bdf6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Acquire is a customer communication platform. We help users bridge gaps between customers and sales and support teams to empower all involved.",
    "category": "Customer Support",
    "title": "Acquire (Legacy) Integration With Cold Email Sales Tool",
    "h1": "Integrate Acquire (Legacy) with SalesBlink",
    "metaDescription": "Integrate Acquire (Legacy) and other Customer Support Tools with SalesBlink. Two Way Acquire (Legacy) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Magic Minutes",
    "slug": "magic-minutes",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28f69da6ea5f9705962e82e37f3b45b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Magic Minutes is a meeting management tool that helps you run more productive meetings and stay on top of your actions.",
    "category": "Task Management",
    "title": "Magic Minutes Integration With Cold Email Sales Tool",
    "h1": "Integrate Magic Minutes with SalesBlink",
    "metaDescription": "Integrate Magic Minutes and other Task Management Tools with SalesBlink. Two Way Magic Minutes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneUp",
    "slug": "oneup",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/08ac8f035529c3b3dcde8f9bba1de729.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneUp is a social media scheduling tool.",
    "category": "Social Media Marketing",
    "title": "OneUp Integration With Cold Email Sales Tool",
    "h1": "Integrate OneUp with SalesBlink",
    "metaDescription": "Integrate OneUp and other Social Media Marketing Tools with SalesBlink. Two Way OneUp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Coveo",
    "slug": "coveo",
    "logo": "https://zapier-images.imgix.net/storage/services/0bab211415df8e73200f86495d151928.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Coveo is a complete AI-powered search & relevance service for unified & personalized search interactions.",
    "category": "Documents",
    "title": "Coveo Integration With Cold Email Sales Tool",
    "h1": "Integrate Coveo with SalesBlink",
    "metaDescription": "Integrate Coveo and other Documents Tools with SalesBlink. Two Way Coveo Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "4screens",
    "slug": "4screens",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54e6837b23a4e132a1582dc904fb6c0b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "4screens is Interactive Content Creation Platform. Easily create every type of interactive experience — quizzes, surveys, assessments, polls, live Q&A, marketing contests.",
    "category": "Forms & Surveys",
    "title": "4screens Integration With Cold Email Sales Tool",
    "h1": "Integrate 4screens with SalesBlink",
    "metaDescription": "Integrate 4screens and other Forms & Surveys Tools with SalesBlink. Two Way 4screens Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IPerity Compass",
    "slug": "iperity-compass",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b874530c9fa3f709c6abf915123b010a.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IPerity's Compass is a PBX foundation software with its accompanying building blocks.",
    "category": "Phone & SMS",
    "title": "IPerity Compass Integration With Cold Email Sales Tool",
    "h1": "Integrate IPerity Compass with SalesBlink",
    "metaDescription": "Integrate IPerity Compass and other Phone & SMS Tools with SalesBlink. Two Way IPerity Compass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SAM.ai",
    "slug": "sam-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer/0d9411ee475a6cf9f7d68eb3bfe67451.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SAM is a sales and marketing tool that lets you organize and manage your leads, opportunities, accounts & partners with efficient features.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SAM.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate SAM.ai with SalesBlink",
    "metaDescription": "Integrate SAM.ai and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SAM.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jarja Media",
    "slug": "jarja-media",
    "logo": "https://zapier-images.imgix.net/storage/developer/31df20bda73515c85f765289d83c7ef3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jarja Media is a CRM that was built for today’s real estate professional.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Jarja Media Integration With Cold Email Sales Tool",
    "h1": "Integrate Jarja Media with SalesBlink",
    "metaDescription": "Integrate Jarja Media and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Jarja Media Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Handy",
    "slug": "handy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae382294c03307dc82dc2773e2b264ea_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Sales Force Automation app to capture sales orders, surveys and new leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Handy Integration With Cold Email Sales Tool",
    "h1": "Integrate Handy with SalesBlink",
    "metaDescription": "Integrate Handy and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Handy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zipBoard",
    "slug": "zipboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5046dcd5a4d341cfb7b3fd6202299f33_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "zipBoard is a web-based visual feedback and bug tracking tool that helps developers, testers and project managers collaborate during product development.",
    "category": "Project Management",
    "title": "zipBoard Integration With Cold Email Sales Tool",
    "h1": "Integrate zipBoard with SalesBlink",
    "metaDescription": "Integrate zipBoard and other Project Management Tools with SalesBlink. Two Way zipBoard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Demand Metric",
    "slug": "demand-metric",
    "logo": "https://zapier-images.imgix.net/storage/services/766ada6ab2c6532273fe4a14762d82bd.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Demand Metric is agile task management & marketing calendar software powered by 1,000+ tools, training, reports and other resources for marketers.",
    "category": "Project Management",
    "title": "Demand Metric Integration With Cold Email Sales Tool",
    "h1": "Integrate Demand Metric with SalesBlink",
    "metaDescription": "Integrate Demand Metric and other Project Management Tools with SalesBlink. Two Way Demand Metric Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lengow",
    "slug": "lengow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/36799e187366dcaea5a4fc2ca0553aab_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lengow is an e-commerce automation platform that helps brands and distributors to sell their products online, manage their orders coming from marketplaces and improve their online performance.",
    "category": "eCommerce",
    "title": "Lengow Integration With Cold Email Sales Tool",
    "h1": "Integrate Lengow with SalesBlink",
    "metaDescription": "Integrate Lengow and other eCommerce Tools with SalesBlink. Two Way Lengow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adinton-Technologies",
    "slug": "adinton-technologies",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/674e76f76912d4450fc0913da0c30bf0.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adinton is a marketing attribution and autobidding software that helps companies to get the real value of their marketing strategies and optimize the bid management to succeed their marketing goals.",
    "category": "Marketing Automation",
    "title": "Adinton-Technologies Integration With Cold Email Sales Tool",
    "h1": "Integrate Adinton-Technologies with SalesBlink",
    "metaDescription": "Integrate Adinton-Technologies and other Marketing Automation Tools with SalesBlink. Two Way Adinton-Technologies Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Argus Tracking",
    "slug": "argus-tracking",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/41e04c7ffa0d7ffa4007180ac00a1f06.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Argus Tracking utilises New Zealand’s most comprehensive mapping software from GeoSmart along with their own cutting edge technology, it's a one-stop-shop for all your monitoring and fleet management needs.",
    "category": "",
    "title": "Argus Tracking Integration With Cold Email Sales Tool",
    "h1": "Integrate Argus Tracking with SalesBlink",
    "metaDescription": "Integrate Argus Tracking and other  Tools with SalesBlink. Two Way Argus Tracking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unbird",
    "slug": "unbird",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b7438978d688d77460fc2ec46a8738c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unbird is a seamless way to collect, analyze, and action your customer feedback.",
    "category": "Ads & Conversion",
    "title": "Unbird Integration With Cold Email Sales Tool",
    "h1": "Integrate Unbird with SalesBlink",
    "metaDescription": "Integrate Unbird and other Ads & Conversion Tools with SalesBlink. Two Way Unbird Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verizon Media Native Ads",
    "slug": "verizon-media-native-ads",
    "logo": "https://zapier-images.imgix.net/storage/services/955a40c15bbe1e87d423044cd381f420.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verizon Media Native Ads combines the power of content, scale and data to create smarter marketing solutions that build stronger relationships with consumers.",
    "category": "Ads & Conversion",
    "title": "Verizon Media Native Ads Integration With Cold Email Sales Tool",
    "h1": "Integrate Verizon Media Native Ads with SalesBlink",
    "metaDescription": "Integrate Verizon Media Native Ads and other Ads & Conversion Tools with SalesBlink. Two Way Verizon Media Native Ads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobScore",
    "slug": "jobscore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9f8b64aeea8de9783990dfc8e3d2a27_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobScore is a powerful, modern applicant tracking system that helps employers get better at recruiting. JobScore includes a branded careers site, tools to build candidate pipeline by posting and sharing jobs and features to review, interview and hire great people.",
    "category": "HR Talent & Recruitment",
    "title": "JobScore Integration With Cold Email Sales Tool",
    "h1": "Integrate JobScore with SalesBlink",
    "metaDescription": "Integrate JobScore and other HR Talent & Recruitment Tools with SalesBlink. Two Way JobScore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "nTask",
    "slug": "ntask",
    "logo": "https://zapier-images.imgix.net/storage/developer/99b260af56ffd2102ae1d18cda2fc07b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NTask is a free task management platform smart teams rely on to get things done better and faster.",
    "category": "Task Management",
    "title": "nTask Integration With Cold Email Sales Tool",
    "h1": "Integrate nTask with SalesBlink",
    "metaDescription": "Integrate nTask and other Task Management Tools with SalesBlink. Two Way nTask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LoginRadius",
    "slug": "loginradius",
    "logo": "https://zapier-images.imgix.net/storage/services/3141ab716856b255165146fe119bab54.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LoginRadius is a Customer Identity Management (cIAM) platform that allows you to easily manage your customer data.",
    "category": "Developer Tools",
    "title": "LoginRadius Integration With Cold Email Sales Tool",
    "h1": "Integrate LoginRadius with SalesBlink",
    "metaDescription": "Integrate LoginRadius and other Developer Tools Tools with SalesBlink. Two Way LoginRadius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClearPoint Strategy",
    "slug": "clearpoint-strategy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/442d444c36f23044ad50c65f4231b0fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your strategy and create reports in less time. ClearPoint makes it simple for you to link and align goals, KPIs and projects, to integrate data from multiple sources, and to automatically generate and distribute reports.",
    "category": "Dashboards",
    "title": "ClearPoint Strategy Integration With Cold Email Sales Tool",
    "h1": "Integrate ClearPoint Strategy with SalesBlink",
    "metaDescription": "Integrate ClearPoint Strategy and other Dashboards Tools with SalesBlink. Two Way ClearPoint Strategy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boords",
    "slug": "boords",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e6d1f2b4876dd8e93dced70b223f4ab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boords is a collaborative storyboarding app which helps you create storyboards, scripts, and animatics - then gather feedback - all in one place.",
    "category": "Video & Audio",
    "title": "Boords Integration With Cold Email Sales Tool",
    "h1": "Integrate Boords with SalesBlink",
    "metaDescription": "Integrate Boords and other Video & Audio Tools with SalesBlink. Two Way Boords Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CoinGate",
    "slug": "coingate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/88ca0e6355641da252651cdca9fee5b7_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CoinGate is a payment gateway for Bitcoin, Litecoin, Ethereum and 50 other cryptocurrencies. With this integration you will be able to follow your orders and their statuses.",
    "category": "Payment Processing",
    "title": "CoinGate Integration With Cold Email Sales Tool",
    "h1": "Integrate CoinGate with SalesBlink",
    "metaDescription": "Integrate CoinGate and other Payment Processing Tools with SalesBlink. Two Way CoinGate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatBo",
    "slug": "chatbo",
    "logo": "https://zapier-images.imgix.net/storage/developer/162364f5d860725c50a54e91fac53f08.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatBo is a Facebook Messenger app that allows you to automatically communicate with your customers and prospects in Facebook Messenger.",
    "category": "Marketing",
    "title": "ChatBo Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatBo with SalesBlink",
    "metaDescription": "Integrate ChatBo and other Marketing Tools with SalesBlink. Two Way ChatBo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fing",
    "slug": "fing",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73fb468e59d5e2367ae7b82590a749a9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fing helps you get more from your internet experience and smart home set-up. Connect with Fing Internet Outage Alerts and be in the know when your internet is down.",
    "category": "Notifications",
    "title": "Fing Integration With Cold Email Sales Tool",
    "h1": "Integrate Fing with SalesBlink",
    "metaDescription": "Integrate Fing and other Notifications Tools with SalesBlink. Two Way Fing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yardman",
    "slug": "yardman",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7af25159f65358de7fedef2f7184b87b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yardman is a simple yard management system to organize bookings and keep yard visits on track.",
    "category": "Scheduling & Booking",
    "title": "Yardman Integration With Cold Email Sales Tool",
    "h1": "Integrate Yardman with SalesBlink",
    "metaDescription": "Integrate Yardman and other Scheduling & Booking Tools with SalesBlink. Two Way Yardman Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudmersive",
    "slug": "cloudmersive",
    "logo": "https://zapier-images.imgix.net/storage/services/d3584b60b32c50b0fa6b90dbc7ffb618.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudmersive is a platform for converting documents, screenshotting websites, validating email addresses, and automating other key business actions.",
    "category": "Documents",
    "title": "Cloudmersive Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudmersive with SalesBlink",
    "metaDescription": "Integrate Cloudmersive and other Documents Tools with SalesBlink. Two Way Cloudmersive Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ScrumGenius",
    "slug": "scrumgenius",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07d5d696fc07b3c7563958e44f8434e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your team status meetings. ScrumGenius runs automatic check-ins and stand-ups to help managers to track the engagement and performance of their employees.",
    "category": "Project Management",
    "title": "ScrumGenius Integration With Cold Email Sales Tool",
    "h1": "Integrate ScrumGenius with SalesBlink",
    "metaDescription": "Integrate ScrumGenius and other Project Management Tools with SalesBlink. Two Way ScrumGenius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elliot",
    "slug": "elliot",
    "logo": "https://zapier-images.imgix.net/storage/services/209b2a6b814fb5643f04ca2537520ebd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elliot creates one-tap checkout pages that increase revenue by 30-40% instantly.",
    "category": "eCommerce",
    "title": "Elliot Integration With Cold Email Sales Tool",
    "h1": "Integrate Elliot with SalesBlink",
    "metaDescription": "Integrate Elliot and other eCommerce Tools with SalesBlink. Two Way Elliot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "10xCRM",
    "slug": "10xcrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03ede434b758d7da433eb0a15a4a0b47.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "10xCRM can replace your office phone system and add the functionality of Text and Emails. All communication by your team is automatically logged for future reference and compliance.",
    "category": "CRM (Customer Relationship Management)",
    "title": "10xCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate 10xCRM with SalesBlink",
    "metaDescription": "Integrate 10xCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 10xCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Practifi",
    "slug": "practifi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c9058c93aaf37ad9f6faca2d93bf322c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Practifi empowers financial advisors by providing a business management platform that connects all the tools and information they need to grow.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Practifi Integration With Cold Email Sales Tool",
    "h1": "Integrate Practifi with SalesBlink",
    "metaDescription": "Integrate Practifi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Practifi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AssessTEAM",
    "slug": "assessteam",
    "logo": "https://zapier-images.imgix.net/storage/services/4a00b7c3a41c1fe8ca4a6f0c2ce59ef3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AssessTEAM is a cloud-based performance management & productivity analysis software. We deliver traditional annual reviews, 360-degree feedback, real-time reviews, continuous feedback, project performance evaluation and team profitability analysis.",
    "category": "HR Talent & Recruitment",
    "title": "AssessTEAM Integration With Cold Email Sales Tool",
    "h1": "Integrate AssessTEAM with SalesBlink",
    "metaDescription": "Integrate AssessTEAM and other HR Talent & Recruitment Tools with SalesBlink. Two Way AssessTEAM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simply Stakeholders",
    "slug": "simply-stakeholders",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/995f8f0c4e52c50d7dfd9a54869cbb34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simply Stakeholders is a simple and easy to use Stakeholder Management tool to manage your stakeholders and your interactions with them.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Simply Stakeholders Integration With Cold Email Sales Tool",
    "h1": "Integrate Simply Stakeholders with SalesBlink",
    "metaDescription": "Integrate Simply Stakeholders and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Simply Stakeholders Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NetLicensing",
    "slug": "netlicensing",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73207b3a37c99f65f4ee22fe2aff1bd1.jpg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NetLicensing is a first-class solution in the Licensing-as-a-Service sector enabling efficient license management for software.",
    "category": "eCommerce",
    "title": "NetLicensing Integration With Cold Email Sales Tool",
    "h1": "Integrate NetLicensing with SalesBlink",
    "metaDescription": "Integrate NetLicensing and other eCommerce Tools with SalesBlink. Two Way NetLicensing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aply2",
    "slug": "aply2",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9bd80bd67d3216e0dd015eecfab58f3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aply2 is a site that lets you connect with Applets",
    "category": "Marketing Automation",
    "title": "Aply2 Integration With Cold Email Sales Tool",
    "h1": "Integrate Aply2 with SalesBlink",
    "metaDescription": "Integrate Aply2 and other Marketing Automation Tools with SalesBlink. Two Way Aply2 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Logisav",
    "slug": "logisav",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/137a7802f126e98fa10e6d8985e4feae.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Logisav is a powerful enterprise software that helps your team being more productive.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Logisav Integration With Cold Email Sales Tool",
    "h1": "Integrate Logisav with SalesBlink",
    "metaDescription": "Integrate Logisav and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Logisav Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Knowledge Anywhere LMS",
    "slug": "knowledge-anywhere-lms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/55109f9c12a86463393aeaba295c0a48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Knowledge Anywhere's LMS helps connect your learners to Just in Time Training.",
    "category": "Online Courses",
    "title": "Knowledge Anywhere LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Knowledge Anywhere LMS with SalesBlink",
    "metaDescription": "Integrate Knowledge Anywhere LMS and other Online Courses Tools with SalesBlink. Two Way Knowledge Anywhere LMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Robolytix",
    "slug": "robolytix",
    "logo": "https://zapier-images.imgix.net/storage/developer/8289ab9e81e7bf32a3dde2f0697313db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Robolytix is the key robot analytic tool for automated processes, providing all data you need for monitoring and managing software robots.",
    "category": "Developer Tools",
    "title": "Robolytix Integration With Cold Email Sales Tool",
    "h1": "Integrate Robolytix with SalesBlink",
    "metaDescription": "Integrate Robolytix and other Developer Tools Tools with SalesBlink. Two Way Robolytix Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MOBIDI",
    "slug": "mobidi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f7862649c14095380726b770c0167ea0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MOBIDI is a mobile team management and data collection application. It allows capturing photos, audios, videos, notes in the field and generate reports based on the data.",
    "category": "Product Management",
    "title": "MOBIDI Integration With Cold Email Sales Tool",
    "h1": "Integrate MOBIDI with SalesBlink",
    "metaDescription": "Integrate MOBIDI and other Product Management Tools with SalesBlink. Two Way MOBIDI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Yardbook",
    "slug": "yardbook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf498a67b9f5f7daf6946c3cf2cbfb99_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yardbook is a comprehensive, end-to-end business management software solution designed for the landscaping industry.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Yardbook Integration With Cold Email Sales Tool",
    "h1": "Integrate Yardbook with SalesBlink",
    "metaDescription": "Integrate Yardbook and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Yardbook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ChatrHub",
    "slug": "chatrhub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1875dd7ecde126e7d81f569fd786f506.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ChatrHub is an A.I. chatbot and live-agent solution. Build AI chatbots in minutes that can schedule appointments, collect leads, and more.",
    "category": "Customer Support",
    "title": "ChatrHub Integration With Cold Email Sales Tool",
    "h1": "Integrate ChatrHub with SalesBlink",
    "metaDescription": "Integrate ChatrHub and other Customer Support Tools with SalesBlink. Two Way ChatrHub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trainly",
    "slug": "trainly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f6f73bc1702c758bec4e5a01f0aea53.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trainly helps companies achieve 100% HR compliance. It uses data to continuously produce ever-more-compelling training that drives behavior change.",
    "category": "Online Courses",
    "title": "Trainly Integration With Cold Email Sales Tool",
    "h1": "Integrate Trainly with SalesBlink",
    "metaDescription": "Integrate Trainly and other Online Courses Tools with SalesBlink. Two Way Trainly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mortgage iQ",
    "slug": "mortgage-iq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8270bac0d54964d34031a70c4695c8b5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mortgage iQ is a system for providing Mortgage CRM, Lead Management and Marketing for Retail, Wholesale, Correspondent and Consumer Direct Lenders.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Mortgage iQ Integration With Cold Email Sales Tool",
    "h1": "Integrate Mortgage iQ with SalesBlink",
    "metaDescription": "Integrate Mortgage iQ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Mortgage iQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OptiwAI",
    "slug": "optiwai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e03ee7ca65c4beefea540c683db81cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OptiwAI SaaS solution uses the power of AI algorithms to efficiently improve the quality of your images without any effort from your side.",
    "category": "Images & Design",
    "title": "OptiwAI Integration With Cold Email Sales Tool",
    "h1": "Integrate OptiwAI with SalesBlink",
    "metaDescription": "Integrate OptiwAI and other Images & Design Tools with SalesBlink. Two Way OptiwAI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alloy Navigator Express",
    "slug": "alloy-navigator-express",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd9033a01c20de5e4bfaa82407e7eb6f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Alloy Navigator Express is an IT Help Desk and Asset Management solution for small and medium business.",
    "category": "Customer Support",
    "title": "Alloy Navigator Express Integration With Cold Email Sales Tool",
    "h1": "Integrate Alloy Navigator Express with SalesBlink",
    "metaDescription": "Integrate Alloy Navigator Express and other Customer Support Tools with SalesBlink. Two Way Alloy Navigator Express Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elevatie",
    "slug": "elevatie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7798796eabbd9a71e5782ed810f64915_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elevatie is a solution which manages businesses reputation by simplifying the process of sending review invites to their customers.",
    "category": "Reviews",
    "title": "Elevatie Integration With Cold Email Sales Tool",
    "h1": "Integrate Elevatie with SalesBlink",
    "metaDescription": "Integrate Elevatie and other Reviews Tools with SalesBlink. Two Way Elevatie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fast-Weigh",
    "slug": "fast-weigh",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9fd148d42d79141d61ef83fa530810de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fast-Weigh is a truck ticketing, dispatching, and order management platform.",
    "category": "Accounting",
    "title": "Fast-Weigh Integration With Cold Email Sales Tool",
    "h1": "Integrate Fast-Weigh with SalesBlink",
    "metaDescription": "Integrate Fast-Weigh and other Accounting Tools with SalesBlink. Two Way Fast-Weigh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Disparo PRO",
    "slug": "disparo-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d64412f9ce94c0516b2568a08125bab9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Disparo PRO is a tool for sending SMS messages through intelligent integration.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Disparo PRO Integration With Cold Email Sales Tool",
    "h1": "Integrate Disparo PRO with SalesBlink",
    "metaDescription": "Integrate Disparo PRO and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Disparo PRO Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Colligso TextIn",
    "slug": "colligso-textin",
    "logo": "https://zapier-images.imgix.net/storage/services/c5fc7df7c7efd53c6c655361308100ad_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Colligso enables businesses to grow profitably by providing an easy to use and integrated platform to create and cultivate digital customer relationships. TextIn helps businesses by engaging with customers directly using text (SMS)/ picture (MMS) messaging. App-less, no app installation required by your customers. Easy opt-in and opt-out. Universal, available on every mobile phone where SMS is available.",
    "category": "Phone & SMS",
    "title": "Colligso TextIn Integration With Cold Email Sales Tool",
    "h1": "Integrate Colligso TextIn with SalesBlink",
    "metaDescription": "Integrate Colligso TextIn and other Phone & SMS Tools with SalesBlink. Two Way Colligso TextIn Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GoFileRoom",
    "slug": "gofileroom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7e46e8b7f7047cfb84bc33c3949e4dc_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoFileRoom is a cloud-based document management and workflow solution. With the GoFileRoom connector, you can perform document, user management, and FirmFlow actions.",
    "category": "Accounting",
    "title": "GoFileRoom Integration With Cold Email Sales Tool",
    "h1": "Integrate GoFileRoom with SalesBlink",
    "metaDescription": "Integrate GoFileRoom and other Accounting Tools with SalesBlink. Two Way GoFileRoom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TAP NFC Management",
    "slug": "tap-nfc-management",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd8aa31d98d61d38eea701b3ec022c9a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TAP NFC Management is the modern answer to RFID. Stick a TAP NFC Tag to anything to track your people, places and products.",
    "category": "Devices",
    "title": "TAP NFC Management Integration With Cold Email Sales Tool",
    "h1": "Integrate TAP NFC Management with SalesBlink",
    "metaDescription": "Integrate TAP NFC Management and other Devices Tools with SalesBlink. Two Way TAP NFC Management Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eshopbox",
    "slug": "eshopbox",
    "logo": "https://zapier-images.imgix.net/storage/services/987263474e0e7a934e1dc318389036c6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eshopbox offers simple, fast, and tech-enabled fulfillment for ambitious e-commerce businesses with its network of fulfillment centers across India.",
    "category": "eCommerce",
    "title": "Eshopbox Integration With Cold Email Sales Tool",
    "h1": "Integrate Eshopbox with SalesBlink",
    "metaDescription": "Integrate Eshopbox and other eCommerce Tools with SalesBlink. Two Way Eshopbox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grid",
    "slug": "grid",
    "logo": "https://zapier-images.imgix.net/storage/services/be9d0afc96601d40dfbb9dde1f0a2caa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grid is a data collection tool which enables users to increase productivity.",
    "category": "Databases",
    "title": "Grid Integration With Cold Email Sales Tool",
    "h1": "Integrate Grid with SalesBlink",
    "metaDescription": "Integrate Grid and other Databases Tools with SalesBlink. Two Way Grid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cleanetto",
    "slug": "cleanetto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/502ee0212dc76cfdc1fd692d4ec64230.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cleanetto is the smart software for the modern cleaning company.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Cleanetto Integration With Cold Email Sales Tool",
    "h1": "Integrate Cleanetto with SalesBlink",
    "metaDescription": "Integrate Cleanetto and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Cleanetto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Windsor.ai",
    "slug": "windsorai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0bc1d8d1e62324a295e9db1938ef52d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Windsor.ai helps to integrate Media, Analytics and CRM data.",
    "category": "Marketing",
    "title": "Windsor.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Windsor.ai with SalesBlink",
    "metaDescription": "Integrate Windsor.ai and other Marketing Tools with SalesBlink. Two Way Windsor.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pima",
    "slug": "pima",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/014917cf18ec1027e2df1ddb42d7c7e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pima helps businesses ingest incoming requests for security documents, sign NDAs with prospects, and share watermarked reports on demand.",
    "category": "Documents",
    "title": "Pima Integration With Cold Email Sales Tool",
    "h1": "Integrate Pima with SalesBlink",
    "metaDescription": "Integrate Pima and other Documents Tools with SalesBlink. Two Way Pima Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Email Hippo",
    "slug": "email-hippo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3eef71e55e5f751c30a3c9d80ab08925.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Email Hippos is an email intelligence platform to stop bad emails getting into your systems. MORE shields your forms from fraudsters and typos in real-time.",
    "category": "Email",
    "title": "Email Hippo Integration With Cold Email Sales Tool",
    "h1": "Integrate Email Hippo with SalesBlink",
    "metaDescription": "Integrate Email Hippo and other Email Tools with SalesBlink. Two Way Email Hippo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Colligso SpotIn",
    "slug": "colligso-spotin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b8b9c8006a54fbf0128aca77e552dee4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Colligso SpotIn is a WiFi remarketing app that helps Main Street businesses to collect customer/lead contacts 4-5x faster than other means. SpotIn also provides automated remarketing campaigns. Integrate it with apps for contact management, CRM, ad audience management, etc.",
    "category": "Marketing",
    "title": "Colligso SpotIn Integration With Cold Email Sales Tool",
    "h1": "Integrate Colligso SpotIn with SalesBlink",
    "metaDescription": "Integrate Colligso SpotIn and other Marketing Tools with SalesBlink. Two Way Colligso SpotIn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qalcwise",
    "slug": "qalcwise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e998ed2d496e7840ce4532173d7772d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build your custom app with spreadsheet based NoCode platform.",
    "category": "App Builder",
    "title": "Qalcwise Integration With Cold Email Sales Tool",
    "h1": "Integrate Qalcwise with SalesBlink",
    "metaDescription": "Integrate Qalcwise and other App Builder Tools with SalesBlink. Two Way Qalcwise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CabinPanda",
    "slug": "cabinpanda",
    "logo": "https://zapier-images.imgix.net/storage/services/4fef19a8c0e4368ba9a1dcf60d3fad32.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CabinPanda is an innovative data collection tool. It enables users to create good looking online forms which perform great across different devices. CabinPanda stands out with its solid platform and ease of use.",
    "category": "Forms & Surveys",
    "title": "CabinPanda Integration With Cold Email Sales Tool",
    "h1": "Integrate CabinPanda with SalesBlink",
    "metaDescription": "Integrate CabinPanda and other Forms & Surveys Tools with SalesBlink. Two Way CabinPanda Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Udemy for Business",
    "slug": "udemy-for-business",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/283b8fcf16c8de1dda5aca3034f60ac2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Udemy for Business API Courses & Reporting APIs: https://business.udemy.com",
    "category": "Online Courses",
    "title": "Udemy for Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Udemy for Business with SalesBlink",
    "metaDescription": "Integrate Udemy for Business and other Online Courses Tools with SalesBlink. Two Way Udemy for Business Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloud 66",
    "slug": "cloud66",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/853811eb24283cfcdc2f39f5c0ca7616.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud 66 is a DevOps as a Service, built for application developers.",
    "category": "Developer Tools",
    "title": "Cloud 66 Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloud 66 with SalesBlink",
    "metaDescription": "Integrate Cloud 66 and other Developer Tools Tools with SalesBlink. Two Way Cloud 66 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Movinghub",
    "slug": "movinghub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3be1334ce75c9e667bfee0a3faebcd01.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Movinghub is an online SAAS platform that enables partners to access everything needed to offer utility connections, energy comparisons and a vast range of essential home service.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Movinghub Integration With Cold Email Sales Tool",
    "h1": "Integrate Movinghub with SalesBlink",
    "metaDescription": "Integrate Movinghub and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Movinghub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoSchedule",
    "slug": "goschedule",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e387d79375a76c708f925fbee763b2e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoSchedule is the appointment scheduling SaaS Software for SMB and Enterprise Segment to schedule resources, book online appointments, accept payments online and seamlessly integrates with websites and apps with API first notion.",
    "category": "Scheduling & Booking",
    "title": "GoSchedule Integration With Cold Email Sales Tool",
    "h1": "Integrate GoSchedule with SalesBlink",
    "metaDescription": "Integrate GoSchedule and other Scheduling & Booking Tools with SalesBlink. Two Way GoSchedule Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EZOfficeInventory",
    "slug": "ezofficeinventory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a9d3be11ba9113390da2338a7285bbd9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EZOfficeInventory is asset tracking software that lets you track, maintain and report on your equipment so it's always available and lasts longer.",
    "category": "Project Management",
    "title": "EZOfficeInventory Integration With Cold Email Sales Tool",
    "h1": "Integrate EZOfficeInventory with SalesBlink",
    "metaDescription": "Integrate EZOfficeInventory and other Project Management Tools with SalesBlink. Two Way EZOfficeInventory Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "EZRentOut",
    "slug": "ezrentout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/679aca3c3bdd90331b6831bb0add0d08.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EZRentOut is rental software that lets you manage your items, orders and webstore so you can streamline your rentals and grow your business.",
    "category": "",
    "title": "EZRentOut Integration With Cold Email Sales Tool",
    "h1": "Integrate EZRentOut with SalesBlink",
    "metaDescription": "Integrate EZRentOut and other  Tools with SalesBlink. Two Way EZRentOut Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sales.Rocks",
    "slug": "sales-rocks",
    "logo": "https://zapier-images.imgix.net/storage/services/23e7a1f6e9f4a2fbb37d78c39e6c3a5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sales.Rocks is a sales enablement tool that helps companies fill their sales pipeline by allowing them to get quick access to full company and contact data from Europe, United States and Canada.",
    "category": "Databases",
    "title": "Sales.Rocks Integration With Cold Email Sales Tool",
    "h1": "Integrate Sales.Rocks with SalesBlink",
    "metaDescription": "Integrate Sales.Rocks and other Databases Tools with SalesBlink. Two Way Sales.Rocks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eWorkOrders",
    "slug": "eworkorders",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/488ca7fee5fe2706c9a9070c9aa4308c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eWorkOrders is a Computer Maintenance Management Software used to manage work orders, assets, preventive maintenance and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "eWorkOrders Integration With Cold Email Sales Tool",
    "h1": "Integrate eWorkOrders with SalesBlink",
    "metaDescription": "Integrate eWorkOrders and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way eWorkOrders Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Frejun",
    "slug": "frejun",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9290736cad0b4e0d01069ccc6594bb0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Frejun helps get actionable insights, transcripts, summaries, and notes of your meetings and business conversations using AI",
    "category": "Transcription",
    "title": "Frejun Integration With Cold Email Sales Tool",
    "h1": "Integrate Frejun with SalesBlink",
    "metaDescription": "Integrate Frejun and other Transcription Tools with SalesBlink. Two Way Frejun Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bizneo HR",
    "slug": "bizneo-hr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e813ad2512a36124737a338454c2379a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bizneo is a set of cloud-based web tools that makes your day-to-day HR admin tasks a breeze.",
    "category": "HR Talent & Recruitment",
    "title": "Bizneo HR Integration With Cold Email Sales Tool",
    "h1": "Integrate Bizneo HR with SalesBlink",
    "metaDescription": "Integrate Bizneo HR and other HR Talent & Recruitment Tools with SalesBlink. Two Way Bizneo HR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeleTrain",
    "slug": "deletrain",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9e23ba2f0b75612d53672c9156ffdd74.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeleTrain enables new employees to train themselves with advanced text & screen recording training manuals.",
    "category": "HR Talent & Recruitment",
    "title": "DeleTrain Integration With Cold Email Sales Tool",
    "h1": "Integrate DeleTrain with SalesBlink",
    "metaDescription": "Integrate DeleTrain and other HR Talent & Recruitment Tools with SalesBlink. Two Way DeleTrain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sterblue",
    "slug": "sterblue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/296a7b009fa601a5cabc9425b2765771.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sterblue builds software for automatic inspection of industrial assets, using drones and artificial intelligence.",
    "category": "Project Management",
    "title": "Sterblue Integration With Cold Email Sales Tool",
    "h1": "Integrate Sterblue with SalesBlink",
    "metaDescription": "Integrate Sterblue and other Project Management Tools with SalesBlink. Two Way Sterblue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "V8 Media",
    "slug": "v8-media",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/797e3c6e2bf69d8efb735369a706df2d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "It is a revolutionary, world`s very first,most powerful and complete Messenger marketing software.",
    "category": "Marketing Automation",
    "title": "V8 Media Integration With Cold Email Sales Tool",
    "h1": "Integrate V8 Media with SalesBlink",
    "metaDescription": "Integrate V8 Media and other Marketing Automation Tools with SalesBlink. Two Way V8 Media Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cognitive Invoice",
    "slug": "cognitive-invoice",
    "logo": "https://zapier-images.imgix.net/storage/developer/ca72c1ee26d9c4a3bd75abb178d47024.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cognitive Invoice is a platform built on deep learning which automates invoice processing workflows. Automate invoice data extraction, build your workflows to ingest, capture, validate and post invoices to system of your choice – no coding required.",
    "category": "Accounting",
    "title": "Cognitive Invoice Integration With Cold Email Sales Tool",
    "h1": "Integrate Cognitive Invoice with SalesBlink",
    "metaDescription": "Integrate Cognitive Invoice and other Accounting Tools with SalesBlink. Two Way Cognitive Invoice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Playbook",
    "slug": "playbook",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f19bba44449930769681bfd6e309e71c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Playbook will be a software application that will allow customers to gain more control over their\nbusinesses. They will be able to better build and track their company’s tasks and processes.",
    "category": "Task Management",
    "title": "Playbook Integration With Cold Email Sales Tool",
    "h1": "Integrate Playbook with SalesBlink",
    "metaDescription": "Integrate Playbook and other Task Management Tools with SalesBlink. Two Way Playbook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UpdaterCloud",
    "slug": "updatercloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/258cd29a333a2f222745ef8f210d80e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UpdaterCloud is a fast and flexible way to integrate web updates support with optional license key authorization to your free or premium web applications.",
    "category": "Developer Tools",
    "title": "UpdaterCloud Integration With Cold Email Sales Tool",
    "h1": "Integrate UpdaterCloud with SalesBlink",
    "metaDescription": "Integrate UpdaterCloud and other Developer Tools Tools with SalesBlink. Two Way UpdaterCloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bestomer",
    "slug": "bestomer",
    "logo": "https://zapier-images.imgix.net/storage/services/509d5129bc888d6b93e23af973521f54.jpeg?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bestomer is a platform to help you build, share, engage and act to provide great experiences for your customers. Integrate with our Zapier API to automatically engage your Bestomers and get/give help when it's needed.",
    "category": "Customer Support",
    "title": "Bestomer Integration With Cold Email Sales Tool",
    "h1": "Integrate Bestomer with SalesBlink",
    "metaDescription": "Integrate Bestomer and other Customer Support Tools with SalesBlink. Two Way Bestomer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AntEater",
    "slug": "anteater",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/323de26cdeb8518bbc852df294db20f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AntEater helps teams on Gmail, Outlook and Slack keep track of activity with prospects, customers, vendors & partners with AI.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AntEater Integration With Cold Email Sales Tool",
    "h1": "Integrate AntEater with SalesBlink",
    "metaDescription": "Integrate AntEater and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AntEater Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Motimate",
    "slug": "motimate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/42bd2e0e7b599561d0a4529fb992e93d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Motimate is a SAAS-solution making internal communication and corporate training fun and easy.",
    "category": "Communication",
    "title": "Motimate Integration With Cold Email Sales Tool",
    "h1": "Integrate Motimate with SalesBlink",
    "metaDescription": "Integrate Motimate and other Communication Tools with SalesBlink. Two Way Motimate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flyte",
    "slug": "flyte",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd485c59426a80a9bd06b4a7a9399dfb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flyte helps businesses attract, retain and communicate with their customers all from one platform.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Flyte Integration With Cold Email Sales Tool",
    "h1": "Integrate Flyte with SalesBlink",
    "metaDescription": "Integrate Flyte and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Flyte Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sunday Wardrobe",
    "slug": "sunday-wardrobe",
    "logo": "https://zapier-images.imgix.net/storage/services/ecb5afa59119f182ea2d9b4229689514.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get the right item to the right person at the right moment. In just a few clicks from your personal Wardrobe.",
    "category": "Customer Appreciation",
    "title": "Sunday Wardrobe Integration With Cold Email Sales Tool",
    "h1": "Integrate Sunday Wardrobe with SalesBlink",
    "metaDescription": "Integrate Sunday Wardrobe and other Customer Appreciation Tools with SalesBlink. Two Way Sunday Wardrobe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gprospects",
    "slug": "gprospects",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c75688ebb543837ad9841cb72be635be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gprospects is the ultimate data extraction tool for Google Maps. It integrates seamlessly with CRM and Email apps to ignite your outbound sales & marketing.",
    "category": "Marketing",
    "title": "Gprospects Integration With Cold Email Sales Tool",
    "h1": "Integrate Gprospects with SalesBlink",
    "metaDescription": "Integrate Gprospects and other Marketing Tools with SalesBlink. Two Way Gprospects Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudi-Fi",
    "slug": "cloudi-fi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5f7d15ff463cb7e089c23efe2f2bdfe7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudi-Fi enhance your Guest experience while accessing your Wifi networks.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Cloudi-Fi Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudi-Fi with SalesBlink",
    "metaDescription": "Integrate Cloudi-Fi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Cloudi-Fi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobilize US",
    "slug": "mobilize-us",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/343627bb47a83c3af1b9d7ac4a0c6c0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobilize US allows you to send text messages to your contacts, create and launch surveys, and target your communication to groups.",
    "category": "Phone & SMS",
    "title": "Mobilize US Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobilize US with SalesBlink",
    "metaDescription": "Integrate Mobilize US and other Phone & SMS Tools with SalesBlink. Two Way Mobilize US Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Microsystem Hotspot",
    "slug": "microsystem-hotspot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1983dd0d44a49dc6ab5817701ea5d961.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsystem is the first smart-WiFi platform in the Middle East,\nthat decrease marketing costs 9 times and grows sales 5 times, through smart WiFi Marketing and Automation\nwe provide loyalty, affiliate, and birthday program, WhatsApp pay through WhatsApp bot.",
    "category": "Marketing",
    "title": "Microsystem Hotspot Integration With Cold Email Sales Tool",
    "h1": "Integrate Microsystem Hotspot with SalesBlink",
    "metaDescription": "Integrate Microsystem Hotspot and other Marketing Tools with SalesBlink. Two Way Microsystem Hotspot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoucherConnect",
    "slug": "voucherconnect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a725ab1804df1075ba80217d0f5a7f19.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Voucher Connect is an app for selling, managing and maintaining gift voucher revenue.",
    "category": "eCommerce",
    "title": "VoucherConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate VoucherConnect with SalesBlink",
    "metaDescription": "Integrate VoucherConnect and other eCommerce Tools with SalesBlink. Two Way VoucherConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Valispace",
    "slug": "valispace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/264128589cc6af464ae087acc4d142c3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Valispace is a platform for engineering collaboration. Ensuring a single source-of-truth for engineering design, Valispace helps engineers build complex systems more efficiently in a browser-based platform designed for the engineers of today and tomorrow.",
    "category": "Team Collaboration",
    "title": "Valispace Integration With Cold Email Sales Tool",
    "h1": "Integrate Valispace with SalesBlink",
    "metaDescription": "Integrate Valispace and other Team Collaboration Tools with SalesBlink. Two Way Valispace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lumeer",
    "slug": "lumeer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c899098c63f4942e17227b9569b36b90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lumeer gives you a super easy visual project management so that all the team members are on the same page and the communication is streamlined like never before.",
    "category": "Project Management",
    "title": "Lumeer Integration With Cold Email Sales Tool",
    "h1": "Integrate Lumeer with SalesBlink",
    "metaDescription": "Integrate Lumeer and other Project Management Tools with SalesBlink. Two Way Lumeer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HubioMail",
    "slug": "hubiomail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73c6672f6ceb7353ca5ae55cfbb29a1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HubioMail is an email marketing platform for digital marketers or individuals who want to manage subscribers, import-export existing lists, and to manage and automate email marketing campaigns.",
    "category": "Marketing",
    "title": "HubioMail Integration With Cold Email Sales Tool",
    "h1": "Integrate HubioMail with SalesBlink",
    "metaDescription": "Integrate HubioMail and other Marketing Tools with SalesBlink. Two Way HubioMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesquared",
    "slug": "salesquared",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6204b5b6028e5867bfa4c0a3e0343921.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesquared is a leading email validation service. We provide APIs with complete SDKs and integrations, so you can seamlessly automate your email collection process. Validating email addresses in real-time is easier than ever.",
    "category": "Email Newsletters",
    "title": "Salesquared Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesquared with SalesBlink",
    "metaDescription": "Integrate Salesquared and other Email Newsletters Tools with SalesBlink. Two Way Salesquared Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pure Leads",
    "slug": "pureleads",
    "logo": "https://zapier-images.imgix.net/storage/services/1c39aa6f6046a2153adf3bf03fd2bfca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pureleads helps capture, track, distribute and manage your leads with ease. Grow faster with our scalable lead generation software .",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pure Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Pure Leads with SalesBlink",
    "metaDescription": "Integrate Pure Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pure Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tickd",
    "slug": "tickd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27b2432ce7b70969e53d88f3807116bd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A simple yet powerful tool to organise your team projects, issues and more ...",
    "category": "Project Management",
    "title": "Tickd Integration With Cold Email Sales Tool",
    "h1": "Integrate Tickd with SalesBlink",
    "metaDescription": "Integrate Tickd and other Project Management Tools with SalesBlink. Two Way Tickd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudBlue Commerce",
    "slug": "cloudblue-commerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b47adabe7d9a5e25a63666214b1c944.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudBlue is a modular, scalable, extensible and secure carrier-grade platform that enables the profitable, end-to-end delivery of services, including onboarding, automation, aggregation, distribution, go-to-market and support.",
    "category": "App Builder",
    "title": "CloudBlue Commerce Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudBlue Commerce with SalesBlink",
    "metaDescription": "Integrate CloudBlue Commerce and other App Builder Tools with SalesBlink. Two Way CloudBlue Commerce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Growth Hound",
    "slug": "growth-hound",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e184e31a38682a033957bba5ff1aa0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Growth Hound is a growth management intelligence tool to organize, implement, and measure your growth process and build your growth engine.",
    "category": "Marketing",
    "title": "Growth Hound Integration With Cold Email Sales Tool",
    "h1": "Integrate Growth Hound with SalesBlink",
    "metaDescription": "Integrate Growth Hound and other Marketing Tools with SalesBlink. Two Way Growth Hound Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Auryc",
    "slug": "auryc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b2d86065f62cf8eca7711f0e4028ffe2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Auryc allows you to surface real-time insights with powerful visual context across all of your digital eCommerce journeys.",
    "category": "Analytics",
    "title": "Auryc Integration With Cold Email Sales Tool",
    "h1": "Integrate Auryc with SalesBlink",
    "metaDescription": "Integrate Auryc and other Analytics Tools with SalesBlink. Two Way Auryc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alertdesk",
    "slug": "alertdesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9bf6310c512874e73ba34e97f2d31952_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Put your mind at ease with Alertdesk. We monitor your site 24/7 and notify you instantly if downtime or performance problems should occur.",
    "category": "Server Monitoring",
    "title": "Alertdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Alertdesk with SalesBlink",
    "metaDescription": "Integrate Alertdesk and other Server Monitoring Tools with SalesBlink. Two Way Alertdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kafkai",
    "slug": "kafkai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85f44a2b4c77d1972795784ad136a970.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kafkai is an AI article writer that uses machine-learning algorithms to write articles from scratch.",
    "category": "Documents",
    "title": "Kafkai Integration With Cold Email Sales Tool",
    "h1": "Integrate Kafkai with SalesBlink",
    "metaDescription": "Integrate Kafkai and other Documents Tools with SalesBlink. Two Way Kafkai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Uptona",
    "slug": "uptona",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5a6fbd273ea56da50d0e4c0837fe9aa7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uptona allows artist to distribute their content and make revenue at the same time.",
    "category": "Ads & Conversion",
    "title": "Uptona Integration With Cold Email Sales Tool",
    "h1": "Integrate Uptona with SalesBlink",
    "metaDescription": "Integrate Uptona and other Ads & Conversion Tools with SalesBlink. Two Way Uptona Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eoovi Consult",
    "slug": "eoovi-consult",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e24a4f804a6b581e0885d821ab7d4ea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Consultation service for professionals with long term video storage for legality and indemnity.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Eoovi Consult Integration With Cold Email Sales Tool",
    "h1": "Integrate Eoovi Consult with SalesBlink",
    "metaDescription": "Integrate Eoovi Consult and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Eoovi Consult Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ParcelSync",
    "slug": "parcelsync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/262591c4e9d0312ebff6fdd01fff5897.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Track your fleet, connect with customers and document deliveries, all in one app",
    "category": "Task Management",
    "title": "ParcelSync Integration With Cold Email Sales Tool",
    "h1": "Integrate ParcelSync with SalesBlink",
    "metaDescription": "Integrate ParcelSync and other Task Management Tools with SalesBlink. Two Way ParcelSync Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Z Workforce",
    "slug": "z-workforce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dace2a546684276e4d511011947e04ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobile field service. With a single tool companies can improve their performance of teams in the field, fulfill checklists and generate indicators on sales, products, services, customers, quality of service and even monitor competitor actions",
    "category": "CRM (Customer Relationship Management)",
    "title": "Z Workforce Integration With Cold Email Sales Tool",
    "h1": "Integrate Z Workforce with SalesBlink",
    "metaDescription": "Integrate Z Workforce and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Z Workforce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Awario",
    "slug": "awario",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6f4117e8316368b6dd39b5c52635692_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Awario is a social listening and analytics tool to track mentions of your brand, competitors, or anything else online.",
    "category": "Marketing",
    "title": "Awario Integration With Cold Email Sales Tool",
    "h1": "Integrate Awario with SalesBlink",
    "metaDescription": "Integrate Awario and other Marketing Tools with SalesBlink. Two Way Awario Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YellowletterHQ",
    "slug": "yellowletterhq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d64c9fc7cd9c8cfa9695586601266da4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YellowletterHQ is the easiest way to create your yellow letter marketing campaign.",
    "category": "Ads & Conversion",
    "title": "YellowletterHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate YellowletterHQ with SalesBlink",
    "metaDescription": "Integrate YellowletterHQ and other Ads & Conversion Tools with SalesBlink. Two Way YellowletterHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DPLYR",
    "slug": "dplyr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7acc0999b568abff36d4a950e9eb879a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DPLYR is a tool that helps developers deploy websites more easily on the cloud.",
    "category": "Developer Tools",
    "title": "DPLYR Integration With Cold Email Sales Tool",
    "h1": "Integrate DPLYR with SalesBlink",
    "metaDescription": "Integrate DPLYR and other Developer Tools Tools with SalesBlink. Two Way DPLYR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nobel SMS",
    "slug": "nobel-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1fc9d7b851dd7ec04cd29460a41ec18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nobel SMS provides international A to Z SMS termination at the most competitive rates, web based SMS applications, bulk SMS for SMS campaigns and advanced SMS enterprise solutions via API with real-time monitoring.",
    "category": "Phone & SMS",
    "title": "Nobel SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Nobel SMS with SalesBlink",
    "metaDescription": "Integrate Nobel SMS and other Phone & SMS Tools with SalesBlink. Two Way Nobel SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "follow.it",
    "slug": "followit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f2940bd4b0a0586cf8c3557b41ac0d2d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "follow.it is a news service provider platform that helps Readers to subscribe to the various news providers under the same table while providing Publishers a way to get more recurring visitors & subscribers.",
    "category": "Email Newsletters",
    "title": "follow.it Integration With Cold Email Sales Tool",
    "h1": "Integrate follow.it with SalesBlink",
    "metaDescription": "Integrate follow.it and other Email Newsletters Tools with SalesBlink. Two Way follow.it Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "hauzd",
    "slug": "hauzd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1fe47b3bc99e1d446d6c7f4c5c71fcee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hauzd is a 3D Sales Solution for Real Estate Developers & Brokers.",
    "category": "App Builder",
    "title": "hauzd Integration With Cold Email Sales Tool",
    "h1": "Integrate hauzd with SalesBlink",
    "metaDescription": "Integrate hauzd and other App Builder Tools with SalesBlink. Two Way hauzd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MTARGET",
    "slug": "mtarget",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/091485e157274fa9c1f04c4ccd3b40a5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We help your business grow bigger and get you closer to customers. Use our platform to build awareness, data acquisition & management, and customer retention. MTARGET has been proven to effectively increase revenue generation.",
    "category": "Marketing",
    "title": "MTARGET Integration With Cold Email Sales Tool",
    "h1": "Integrate MTARGET with SalesBlink",
    "metaDescription": "Integrate MTARGET and other Marketing Tools with SalesBlink. Two Way MTARGET Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pupsai",
    "slug": "pupsai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f77dd451f8643ea2176cf75ff6674f4d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pupsai Platform allows you to provide Consistent 24/7 Customer Care, Marketing and Sales",
    "category": "CRM (Customer Relationship Management)",
    "title": "Pupsai Integration With Cold Email Sales Tool",
    "h1": "Integrate Pupsai with SalesBlink",
    "metaDescription": "Integrate Pupsai and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Pupsai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DreamCampaigns",
    "slug": "dreamcampaigns",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b2ee197d193726536d7c075bc57c922a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DreamCampaigns is an email & SMS marketing platform. Create beautiful and engaging emails & texts and send them to your subscribers.",
    "category": "Email Newsletters",
    "title": "DreamCampaigns Integration With Cold Email Sales Tool",
    "h1": "Integrate DreamCampaigns with SalesBlink",
    "metaDescription": "Integrate DreamCampaigns and other Email Newsletters Tools with SalesBlink. Two Way DreamCampaigns Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Celestory",
    "slug": "celestory",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4d121fdc6ed5dbe90033b5a13ac8a24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Celestory is an app to create your no-code interactive stories.",
    "category": "App Builder",
    "title": "Celestory Integration With Cold Email Sales Tool",
    "h1": "Integrate Celestory with SalesBlink",
    "metaDescription": "Integrate Celestory and other App Builder Tools with SalesBlink. Two Way Celestory Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "HotShp",
    "slug": "hotshp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e3232e104dd1054c535d30105c3500a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HotShp is an e-commerce content writing service. We write product descriptions, titles and social media posts.",
    "category": "eCommerce",
    "title": "HotShp Integration With Cold Email Sales Tool",
    "h1": "Integrate HotShp with SalesBlink",
    "metaDescription": "Integrate HotShp and other eCommerce Tools with SalesBlink. Two Way HotShp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Karen",
    "slug": "karen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2ab7109eeed02e68b2ec132d989e9c14.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Karen is an AI enabled scheduling service that helps schedule appointment, take payment, and send reminders.",
    "category": "Calendar",
    "title": "Karen Integration With Cold Email Sales Tool",
    "h1": "Integrate Karen with SalesBlink",
    "metaDescription": "Integrate Karen and other Calendar Tools with SalesBlink. Two Way Karen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iaBilet",
    "slug": "iabilet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec299a53b26ab5bd385aeb6068e68db0_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iaBilet is a ticketing platform that helps event promoters to sell out their events.",
    "category": "Event Management",
    "title": "iaBilet Integration With Cold Email Sales Tool",
    "h1": "Integrate iaBilet with SalesBlink",
    "metaDescription": "Integrate iaBilet and other Event Management Tools with SalesBlink. Two Way iaBilet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Legalesign",
    "slug": "legalesign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e866beb50309b56f3e86787d3096a539_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Legalesign is electronic signature software where you can receive updates of contract signing events linked to your CRM database and upload or download signed documents between Legalesign and your favourite software.",
    "category": "Signatures",
    "title": "Legalesign Integration With Cold Email Sales Tool",
    "h1": "Integrate Legalesign with SalesBlink",
    "metaDescription": "Integrate Legalesign and other Signatures Tools with SalesBlink. Two Way Legalesign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnSign TV",
    "slug": "onsign-tv",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06dff9b05e7b6100bf5e1a4f74911773.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The OnSign TV app allows multiple triggers and actions related to your content.",
    "category": "File Management & Storage",
    "title": "OnSign TV Integration With Cold Email Sales Tool",
    "h1": "Integrate OnSign TV with SalesBlink",
    "metaDescription": "Integrate OnSign TV and other File Management & Storage Tools with SalesBlink. Two Way OnSign TV Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MobilePages",
    "slug": "mobilepages",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/01f3ce0421edceadafa7f19e4939e032.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MobilePages is a landing page builder to create mobile landing pages in minutes.",
    "category": "Website Builders",
    "title": "MobilePages Integration With Cold Email Sales Tool",
    "h1": "Integrate MobilePages with SalesBlink",
    "metaDescription": "Integrate MobilePages and other Website Builders Tools with SalesBlink. Two Way MobilePages Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "INTAGENT Leads",
    "slug": "intagent-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/187fea975dc0ea9d3a7082903b192d58.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intagent provides real estate web design and CRM lead management for agents, brokers, and companies.",
    "category": "Website Builders",
    "title": "INTAGENT Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate INTAGENT Leads with SalesBlink",
    "metaDescription": "Integrate INTAGENT Leads and other Website Builders Tools with SalesBlink. Two Way INTAGENT Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyRent",
    "slug": "myrent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4bbe28870765aeb5827e9f3430e02997.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyRent is a complete Car Rental Software that makes running your rental business easy, fast and profitable.",
    "category": "Product Management",
    "title": "MyRent Integration With Cold Email Sales Tool",
    "h1": "Integrate MyRent with SalesBlink",
    "metaDescription": "Integrate MyRent and other Product Management Tools with SalesBlink. Two Way MyRent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Doctastic Leads",
    "slug": "doctastic-leads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2f486bd6e754a27aae1b4f17436b9275_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Make your practice look fantastic! Websites, marketing, helpful articles and more! Setup your brand today and take control of your digital online presence. We are here as your digital partner allowing you more time to focus on your patients!",
    "category": "CRM (Customer Relationship Management)",
    "title": "Doctastic Leads Integration With Cold Email Sales Tool",
    "h1": "Integrate Doctastic Leads with SalesBlink",
    "metaDescription": "Integrate Doctastic Leads and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Doctastic Leads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LeadWeb",
    "slug": "leadweb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9c7cc398795b82c85f84a86a8ed65f38.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadWeb App is a platform that instantly delivers leads in real-time. It also uses AI to text back the leads instantly.",
    "category": "",
    "title": "LeadWeb Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadWeb with SalesBlink",
    "metaDescription": "Integrate LeadWeb and other  Tools with SalesBlink. Two Way LeadWeb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Goliath Systemer",
    "slug": "goliath-systemer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c23476749051447979844e4a76f0f22c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your hours, tasks, projects and forms on one platform.",
    "category": "Product Management",
    "title": "Goliath Systemer Integration With Cold Email Sales Tool",
    "h1": "Integrate Goliath Systemer with SalesBlink",
    "metaDescription": "Integrate Goliath Systemer and other Product Management Tools with SalesBlink. Two Way Goliath Systemer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Global IntelliSystems",
    "slug": "global-intellisystems",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0b8695c9a256c632d4112337d4f71da6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Global IntelliSystems is an email, mobile, and text marketing system.",
    "category": "Email",
    "title": "Global IntelliSystems Integration With Cold Email Sales Tool",
    "h1": "Integrate Global IntelliSystems with SalesBlink",
    "metaDescription": "Integrate Global IntelliSystems and other Email Tools with SalesBlink. Two Way Global IntelliSystems Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Momentifi",
    "slug": "momentifi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3056f90b14ac5010b4560e2cf632048.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Momentifi Integration with the Momentifi, Relationship Planner or CMPS applications",
    "category": "CRM (Customer Relationship Management)",
    "title": "Momentifi Integration With Cold Email Sales Tool",
    "h1": "Integrate Momentifi with SalesBlink",
    "metaDescription": "Integrate Momentifi and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Momentifi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContentStudio",
    "slug": "contentstudio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8217af3c110f256cd1fe5d05566b8079_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Save time by finding, managing and sharing the best content to all of your social media accounts from one place. \n\nContentStudio is a powerful content discovery and social media management app helping you to stay on top of trending content in your industry.",
    "category": "Marketing",
    "title": "ContentStudio Integration With Cold Email Sales Tool",
    "h1": "Integrate ContentStudio with SalesBlink",
    "metaDescription": "Integrate ContentStudio and other Marketing Tools with SalesBlink. Two Way ContentStudio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Survio",
    "slug": "survio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/03f07c3726de65593a8ed51118f6c9be_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Survio helps small companies as well as large organizations capture valuable customer insight, employee feedback, and other evaluation data for proper decision making, workflow refinement and business efficiency.",
    "category": "Forms & Surveys",
    "title": "Survio Integration With Cold Email Sales Tool",
    "h1": "Integrate Survio with SalesBlink",
    "metaDescription": "Integrate Survio and other Forms & Surveys Tools with SalesBlink. Two Way Survio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Safepoint",
    "slug": "safepoint",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a0f5aad102cc5fc0f0fbc688022935b0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Safepoint is a lone worker safety platform to keep your workers safe.",
    "category": "Task Management",
    "title": "Safepoint Integration With Cold Email Sales Tool",
    "h1": "Integrate Safepoint with SalesBlink",
    "metaDescription": "Integrate Safepoint and other Task Management Tools with SalesBlink. Two Way Safepoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "8xpand",
    "slug": "8xpand",
    "logo": "https://zapier-images.imgix.net/storage/services/813579a9ba2b5f55ceb9d0b466192a0d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "8xpand is a suite of HR features such as payroll management, time attendance, work documents, recruitment, AI etc, your best partner is HR management solution",
    "category": "HR Talent & Recruitment",
    "title": "8xpand Integration With Cold Email Sales Tool",
    "h1": "Integrate 8xpand with SalesBlink",
    "metaDescription": "Integrate 8xpand and other HR Talent & Recruitment Tools with SalesBlink. Two Way 8xpand Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quatrix",
    "slug": "quatrix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c37648daefcb2b1ccec77c2c7e6b4635.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Download and upload files or folders, share them with predefined security options and a lot more functionalities can be easily integrated into your application using our [Quatrix APIs](https://docs.maytech.net/display/MD/Quatrix+API).",
    "category": "File Management & Storage",
    "title": "Quatrix Integration With Cold Email Sales Tool",
    "h1": "Integrate Quatrix with SalesBlink",
    "metaDescription": "Integrate Quatrix and other File Management & Storage Tools with SalesBlink. Two Way Quatrix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wemero Online Manage",
    "slug": "wemero-online-manage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b1f305f036f87cb6ce3f9347a95321b2_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wemero Online Manage is a CRM that manages your your service, customers, products, gift-cards, and memberships whether you’re running a beauty salon or hair salon or massage parlour or tattoo salon or you are working as beauty hair stylist.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Wemero Online Manage Integration With Cold Email Sales Tool",
    "h1": "Integrate Wemero Online Manage with SalesBlink",
    "metaDescription": "Integrate Wemero Online Manage and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Wemero Online Manage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "kippy",
    "slug": "kippy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b99cee549e1354c4f8f014b20f51fce7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "kippy helps you track your strategic objectives better across your whole organisation by aligning strategy, KPIs, projects and appraisals.",
    "category": "Business Intelligence",
    "title": "kippy Integration With Cold Email Sales Tool",
    "h1": "Integrate kippy with SalesBlink",
    "metaDescription": "Integrate kippy and other Business Intelligence Tools with SalesBlink. Two Way kippy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Review Solution",
    "slug": "the-review-solution",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2c0bf980b16753b6fd80133861a5f31.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Review Solution is an online business reviews platform that offers email, text-based and a SaaS-based platform to address your needs.",
    "category": "Reviews",
    "title": "The Review Solution Integration With Cold Email Sales Tool",
    "h1": "Integrate The Review Solution with SalesBlink",
    "metaDescription": "Integrate The Review Solution and other Reviews Tools with SalesBlink. Two Way The Review Solution Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oliver",
    "slug": "oliver",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3dbe0fec13ceb65a4a8f4b9e61c020f4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Oliver is a sales automation machine that help you to contact important people by email.",
    "category": "Sales & CRM",
    "title": "Oliver Integration With Cold Email Sales Tool",
    "h1": "Integrate Oliver with SalesBlink",
    "metaDescription": "Integrate Oliver and other Sales & CRM Tools with SalesBlink. Two Way Oliver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudCulate",
    "slug": "cloudculate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3032c2275307373abf32bd8fb687cda_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudCulate is a REST API that dramatically simplifies communication with Excel and Google Sheets.",
    "category": "Spreadsheets",
    "title": "CloudCulate Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudCulate with SalesBlink",
    "metaDescription": "Integrate CloudCulate and other Spreadsheets Tools with SalesBlink. Two Way CloudCulate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Uncommon Giving",
    "slug": "uncommon-giving",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b1454c6177f6e4334adb5835c4b38531.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uncommon Giving is a community connecting givers and nonprofits to do good, differently.",
    "category": "Fundraising",
    "title": "Uncommon Giving Integration With Cold Email Sales Tool",
    "h1": "Integrate Uncommon Giving with SalesBlink",
    "metaDescription": "Integrate Uncommon Giving and other Fundraising Tools with SalesBlink. Two Way Uncommon Giving Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Take The Lead",
    "slug": "take-the-lead",
    "logo": "https://zapier-images.imgix.net/storage/services/7d1d0ece7325a357cdd4fbfa6856dd1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Take The Lead enriches leads with a machine learning algorithm that matches anonymous website visitors to a proprietary network database of thousands of companies across the world.",
    "category": "Marketing",
    "title": "Take The Lead Integration With Cold Email Sales Tool",
    "h1": "Integrate Take The Lead with SalesBlink",
    "metaDescription": "Integrate Take The Lead and other Marketing Tools with SalesBlink. Two Way Take The Lead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chekhub",
    "slug": "chekhub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d106cdd039bbaf550dddb850e9a855b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage all your tasks, procedures, assets, schedules and timelines in one easy-to-use app. Create comprehensive checklists, perform tasks confidently and complete projects with absolute accuracy and efficiency.",
    "category": "Developer Tools",
    "title": "Chekhub Integration With Cold Email Sales Tool",
    "h1": "Integrate Chekhub with SalesBlink",
    "metaDescription": "Integrate Chekhub and other Developer Tools Tools with SalesBlink. Two Way Chekhub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scheduling Suite",
    "slug": "scheduling-suite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43d98d55d2f8b6b1b1964e2a025e26df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scheduling Suite is a powerful solution for small businesses to schedule and organize their day-to-day jobs and projects.",
    "category": "Scheduling & Booking",
    "title": "Scheduling Suite Integration With Cold Email Sales Tool",
    "h1": "Integrate Scheduling Suite with SalesBlink",
    "metaDescription": "Integrate Scheduling Suite and other Scheduling & Booking Tools with SalesBlink. Two Way Scheduling Suite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BeepQuest",
    "slug": "beepquest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c735d02b4b492e2a4639e897f4471d67.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BeepQuest is a platform to improve and control supervision process",
    "category": "IT Operations",
    "title": "BeepQuest Integration With Cold Email Sales Tool",
    "h1": "Integrate BeepQuest with SalesBlink",
    "metaDescription": "Integrate BeepQuest and other IT Operations Tools with SalesBlink. Two Way BeepQuest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CentricMinds",
    "slug": "centricminds",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc7b14fcbf3066614ce851ffeb07c652.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CentricMinds is a digital platform delivering powerful Intranet, Document Management & Digital Experiences.",
    "category": "Team Collaboration",
    "title": "CentricMinds Integration With Cold Email Sales Tool",
    "h1": "Integrate CentricMinds with SalesBlink",
    "metaDescription": "Integrate CentricMinds and other Team Collaboration Tools with SalesBlink. Two Way CentricMinds Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Korreos",
    "slug": "korreos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40672ea0749292e61a34a98332d4afec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Korreos is an email marketing tool that helps you send personalized newsletters, drip email sequences and transactional email with automated followups.",
    "category": "Email Newsletters",
    "title": "Korreos Integration With Cold Email Sales Tool",
    "h1": "Integrate Korreos with SalesBlink",
    "metaDescription": "Integrate Korreos and other Email Newsletters Tools with SalesBlink. Two Way Korreos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Direct Debit",
    "slug": "direct-debit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9ae58595b4b8b36f69192c9d0f7114b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Direct Debit is a debt order collection software system in South Africa.",
    "category": "Payment Processing",
    "title": "Direct Debit Integration With Cold Email Sales Tool",
    "h1": "Integrate Direct Debit with SalesBlink",
    "metaDescription": "Integrate Direct Debit and other Payment Processing Tools with SalesBlink. Two Way Direct Debit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CleverYak",
    "slug": "cleveryak",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/240e78dda7b39281949c678258183b44.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CleverYak is an HR solution that helps you interview candidates effortlessly online and meet the strongest candidates before your competitors do. With clever features that make online interviewing work for you.",
    "category": "HR Talent & Recruitment",
    "title": "CleverYak Integration With Cold Email Sales Tool",
    "h1": "Integrate CleverYak with SalesBlink",
    "metaDescription": "Integrate CleverYak and other HR Talent & Recruitment Tools with SalesBlink. Two Way CleverYak Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AB Tasty",
    "slug": "ab-tasty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07db18dfaa716b9a4c66bc96e8a4aefb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AB Tasty is a SaaS solution simplifying the process of conversion rate optimization.",
    "category": "Ads & Conversion",
    "title": "AB Tasty Integration With Cold Email Sales Tool",
    "h1": "Integrate AB Tasty with SalesBlink",
    "metaDescription": "Integrate AB Tasty and other Ads & Conversion Tools with SalesBlink. Two Way AB Tasty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CubApp",
    "slug": "cubapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/90bc21e20ed4e4e5e86aafc9b6c3adf9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CubApp is cloud-based loyalty software for marketing strategies.",
    "category": "Marketing Automation",
    "title": "CubApp Integration With Cold Email Sales Tool",
    "h1": "Integrate CubApp with SalesBlink",
    "metaDescription": "Integrate CubApp and other Marketing Automation Tools with SalesBlink. Two Way CubApp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Presence Stars",
    "slug": "presence-stars",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89bf420445f6ee87fd54ae586381a0ac.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Presence Stars is a Whitelabel Website Manager platform for marketers and agencies.",
    "category": "Website Builders",
    "title": "Presence Stars Integration With Cold Email Sales Tool",
    "h1": "Integrate Presence Stars with SalesBlink",
    "metaDescription": "Integrate Presence Stars and other Website Builders Tools with SalesBlink. Two Way Presence Stars Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kapta",
    "slug": "kapta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/737cec26c232ccc49a341141ff63e458.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kapta is a CRM that is the tool your Account Management team needs to drive growth and retention with your existing clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Kapta Integration With Cold Email Sales Tool",
    "h1": "Integrate Kapta with SalesBlink",
    "metaDescription": "Integrate Kapta and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Kapta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VoxDirect",
    "slug": "voxdirect",
    "logo": "https://zapier-images.imgix.net/storage/services/514e5a8104a2a58656dc1c16bd69ef5d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VoxDirect is a tool for simple & free text marketing (+ Voice!) for your growing business... all in One Platform.",
    "category": "Communication",
    "title": "VoxDirect Integration With Cold Email Sales Tool",
    "h1": "Integrate VoxDirect with SalesBlink",
    "metaDescription": "Integrate VoxDirect and other Communication Tools with SalesBlink. Two Way VoxDirect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BringData.co",
    "slug": "bringdataco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e8e0e6fe613147c6a2007136a606778.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BringData is a data validation plataform that shows you what are the invalid data in your database.",
    "category": "Databases",
    "title": "BringData.co Integration With Cold Email Sales Tool",
    "h1": "Integrate BringData.co with SalesBlink",
    "metaDescription": "Integrate BringData.co and other Databases Tools with SalesBlink. Two Way BringData.co Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Inbound",
    "slug": "inbound",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f251bb8d260029ca4cb728c1bb8c219.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inbound is a service to route emails to HTTP, email forwards, S3 or Zaps.",
    "category": "Transactional Email",
    "title": "Inbound Integration With Cold Email Sales Tool",
    "h1": "Integrate Inbound with SalesBlink",
    "metaDescription": "Integrate Inbound and other Transactional Email Tools with SalesBlink. Two Way Inbound Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Panviva",
    "slug": "panviva",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db3d74f26259ff7b74955aea407bbff1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Panviva is a cloud knowledge management system that empowers your employees to provide top quality customer service. Panviva captures information from wherever it is stored in your organization, optimizes it for better understanding, and delivers it to any employee, anywhere.",
    "category": "Content & Files",
    "title": "Panviva Integration With Cold Email Sales Tool",
    "h1": "Integrate Panviva with SalesBlink",
    "metaDescription": "Integrate Panviva and other Content & Files Tools with SalesBlink. Two Way Panviva Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ThoughtTrace",
    "slug": "thoughttrace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/136e134223c9382cdbc247f8d4c40c99_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ThoughtTrace is an AI-Powered Document Understanding Platform that delivers rich, contextual insights for complex documents and contracts.",
    "category": "Documents",
    "title": "ThoughtTrace Integration With Cold Email Sales Tool",
    "h1": "Integrate ThoughtTrace with SalesBlink",
    "metaDescription": "Integrate ThoughtTrace and other Documents Tools with SalesBlink. Two Way ThoughtTrace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digimind Intelligence",
    "slug": "digimind-intelligence",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b4bc877b41dd46630f4e7aa11ba7fb2_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digimind Intelligence is a market & competitive intelligence platform that helps you monitor changes in your business environment.",
    "category": "Business Intelligence",
    "title": "Digimind Intelligence Integration With Cold Email Sales Tool",
    "h1": "Integrate Digimind Intelligence with SalesBlink",
    "metaDescription": "Integrate Digimind Intelligence and other Business Intelligence Tools with SalesBlink. Two Way Digimind Intelligence Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adaptrack",
    "slug": "adaptrack",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a8e758316ccbfb6cfb70c8f48d9984f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adaptrack helps physicians to lower practice risks and unlock balance using simple personalized nudges based on signals from day-to-day apps.",
    "category": "Business Intelligence",
    "title": "Adaptrack Integration With Cold Email Sales Tool",
    "h1": "Integrate Adaptrack with SalesBlink",
    "metaDescription": "Integrate Adaptrack and other Business Intelligence Tools with SalesBlink. Two Way Adaptrack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendmunk",
    "slug": "sendmunk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c87c6979b5d4ff0703ed93b77840d4c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendmunk is an All-In-One Marketing Automation platform which includes: Email, Push & SMS.",
    "category": "Email Newsletters",
    "title": "Sendmunk Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendmunk with SalesBlink",
    "metaDescription": "Integrate Sendmunk and other Email Newsletters Tools with SalesBlink. Two Way Sendmunk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ROOM Portal",
    "slug": "room-portal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46ecc9b0bdedd2d233c2541c98e8873d.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ROOM Portal is a service that provides real-time data from phone booths and meeting rooms.",
    "category": "Internet of Things",
    "title": "ROOM Portal Integration With Cold Email Sales Tool",
    "h1": "Integrate ROOM Portal with SalesBlink",
    "metaDescription": "Integrate ROOM Portal and other Internet of Things Tools with SalesBlink. Two Way ROOM Portal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kentico Xperience",
    "slug": "kentico-xperience",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4edcdd13da0e937d97e384e4d4a4514c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kentico Xperience is a digital experience platform for managing content, contacts, e-commerce and much more.",
    "category": "Content & Files",
    "title": "Kentico Xperience Integration With Cold Email Sales Tool",
    "h1": "Integrate Kentico Xperience with SalesBlink",
    "metaDescription": "Integrate Kentico Xperience and other Content & Files Tools with SalesBlink. Two Way Kentico Xperience Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Meetquo",
    "slug": "meetquo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a73484b5e92d0eabd9863634d776949c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meetquo is a remote meetings platform, where each teammate participates at their own time and final decisions get tracked and signed.",
    "category": "Team Collaboration",
    "title": "Meetquo Integration With Cold Email Sales Tool",
    "h1": "Integrate Meetquo with SalesBlink",
    "metaDescription": "Integrate Meetquo and other Team Collaboration Tools with SalesBlink. Two Way Meetquo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialWeaver",
    "slug": "socialweaver",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec1a7c8acc540f2f8ca46e516498d7ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocialWeaver is a social media management platform that makes it easy to schedule and publish categorized content, and engage with followers on any network all in one convenient place.",
    "category": "Social Media Marketing",
    "title": "SocialWeaver Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialWeaver with SalesBlink",
    "metaDescription": "Integrate SocialWeaver and other Social Media Marketing Tools with SalesBlink. Two Way SocialWeaver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RocketApps",
    "slug": "rocketapps",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cdd3d74617f1d243101094fad4c326a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RocketApps allows Roblox groups, teams, and game developers to collect applications on the Roblox platform.",
    "category": "Developer Tools",
    "title": "RocketApps Integration With Cold Email Sales Tool",
    "h1": "Integrate RocketApps with SalesBlink",
    "metaDescription": "Integrate RocketApps and other Developer Tools Tools with SalesBlink. Two Way RocketApps Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Njano",
    "slug": "njano",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd8b158acecebc3cff71b60e5c741292.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Njano is a cloud based management solution for workspaces.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Njano Integration With Cold Email Sales Tool",
    "h1": "Integrate Njano with SalesBlink",
    "metaDescription": "Integrate Njano and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Njano Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "We Want You",
    "slug": "we-want-you",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8fd52b4c653b02331adb08f4b9b521e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We Want You is an offer letter tool for HR teams to create, send, and track job offers for candidates. Onboard faster with benefits packages and electronic signatures.",
    "category": "HR Talent & Recruitment",
    "title": "We Want You Integration With Cold Email Sales Tool",
    "h1": "Integrate We Want You with SalesBlink",
    "metaDescription": "Integrate We Want You and other HR Talent & Recruitment Tools with SalesBlink. Two Way We Want You Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SocialGest",
    "slug": "socialgest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7b4192c1d9bcb32c450b1dae64bad977.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SocialGest is a suite of tools for social media management that allows user to schedule content, visualize and export metrics.",
    "category": "Social Media Marketing",
    "title": "SocialGest Integration With Cold Email Sales Tool",
    "h1": "Integrate SocialGest with SalesBlink",
    "metaDescription": "Integrate SocialGest and other Social Media Marketing Tools with SalesBlink. Two Way SocialGest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Innform",
    "slug": "innform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c3064c13cef697c61845ed70458e3fa9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Innform is a hassle-free online training platform.",
    "category": "HR Talent & Recruitment",
    "title": "Innform Integration With Cold Email Sales Tool",
    "h1": "Integrate Innform with SalesBlink",
    "metaDescription": "Integrate Innform and other HR Talent & Recruitment Tools with SalesBlink. Two Way Innform Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Club Management 4 U",
    "slug": "club-management-u",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/097b0a090f81a5eadfbf23b2bb562122.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Club Management 4 U is a Club Management Application to help you manage the full customer journey of all of your leads and customers no matter what type of sports club you run.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Club Management 4 U Integration With Cold Email Sales Tool",
    "h1": "Integrate Club Management 4 U with SalesBlink",
    "metaDescription": "Integrate Club Management 4 U and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Club Management 4 U Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CanaryTEXT",
    "slug": "canarytext",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b6efc08c55ad7cec28920087c0f25b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canary Text is a basic messaging app developed for interoffice communications, We register the companies with us and add contacts against every company. Contacts of the same company can communicate with each other via SMS.",
    "category": "Communication",
    "title": "CanaryTEXT Integration With Cold Email Sales Tool",
    "h1": "Integrate CanaryTEXT with SalesBlink",
    "metaDescription": "Integrate CanaryTEXT and other Communication Tools with SalesBlink. Two Way CanaryTEXT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WeSupply",
    "slug": "wesupply",
    "logo": "https://zapier-images.imgix.net/storage/services/fb58e44a89ce051a5ba49d74918ad847.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WeSupply Labs is an eCommerce platform that helps merchants provide the best post-purchase customer experience when selling online with their integrated services designed to assure a smooth post-purchase experience for end-users.",
    "category": "eCommerce",
    "title": "WeSupply Integration With Cold Email Sales Tool",
    "h1": "Integrate WeSupply with SalesBlink",
    "metaDescription": "Integrate WeSupply and other eCommerce Tools with SalesBlink. Two Way WeSupply Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Post My Link",
    "slug": "post-my-link",
    "logo": "https://zapier-images.imgix.net/storage/services/2456e596321f606e28fba3887cbc34ef_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Post My Link is an advanced URL short-link service for individuals and collaborative teams.",
    "category": "Analytics",
    "title": "Post My Link Integration With Cold Email Sales Tool",
    "h1": "Integrate Post My Link with SalesBlink",
    "metaDescription": "Integrate Post My Link and other Analytics Tools with SalesBlink. Two Way Post My Link Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Upnid",
    "slug": "upnid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aa87a968c465d9eb5fa1e5fc4692d12c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upnid is a payment platform that simplify online payment processing in Brazil.",
    "category": "Payment Processing",
    "title": "Upnid Integration With Cold Email Sales Tool",
    "h1": "Integrate Upnid with SalesBlink",
    "metaDescription": "Integrate Upnid and other Payment Processing Tools with SalesBlink. Two Way Upnid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ivanti Service Desk",
    "slug": "ivanti-service-desk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d0ffe020388a2ac212953831c71b54c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ivanti Service Desk is IT service desk software designed to be configurable and requires no coding in order to implement.",
    "category": "Customer Support",
    "title": "Ivanti Service Desk Integration With Cold Email Sales Tool",
    "h1": "Integrate Ivanti Service Desk with SalesBlink",
    "metaDescription": "Integrate Ivanti Service Desk and other Customer Support Tools with SalesBlink. Two Way Ivanti Service Desk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "JobArch",
    "slug": "jobarch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/60e1422e775564b6feda53f09f06fcbf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JobArch is a simple and powerful Applicant Ranking System (ARS) that allows effective and inexpensive recruitment and selection, within everyone’s reach.",
    "category": "Human Resources",
    "title": "JobArch Integration With Cold Email Sales Tool",
    "h1": "Integrate JobArch with SalesBlink",
    "metaDescription": "Integrate JobArch and other Human Resources Tools with SalesBlink. Two Way JobArch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iLert",
    "slug": "ilert",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7026771577c58a9e449ecc6324a09b9b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iLert is an incident and on-call management platform for DevOps teams. iLert helps you to respond to incidents faster by adding on-call schedules, SMS, and voice alerts to your existing monitoring tools.",
    "category": "Server Monitoring",
    "title": "iLert Integration With Cold Email Sales Tool",
    "h1": "Integrate iLert with SalesBlink",
    "metaDescription": "Integrate iLert and other Server Monitoring Tools with SalesBlink. Two Way iLert Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Survey Mechanics",
    "slug": "survey-mechanics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f2ec02fc74573759d3807df05052374.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This is a test app built on behalf of Survey Mechanics to integrate the data captured through surveys in their platform to other APIs through Zaps.",
    "category": "Forms & Surveys",
    "title": "Survey Mechanics Integration With Cold Email Sales Tool",
    "h1": "Integrate Survey Mechanics with SalesBlink",
    "metaDescription": "Integrate Survey Mechanics and other Forms & Surveys Tools with SalesBlink. Two Way Survey Mechanics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GhostStead",
    "slug": "ghoststead",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5451d45f4d01c35f732b9fbcc4baedb8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GhostStead is a modern content marketing built allowing users to create beautiful websites that are easy to use, fast and completely secure.",
    "category": "Website & App Building",
    "title": "GhostStead Integration With Cold Email Sales Tool",
    "h1": "Integrate GhostStead with SalesBlink",
    "metaDescription": "Integrate GhostStead and other Website & App Building Tools with SalesBlink. Two Way GhostStead Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "True Reply",
    "slug": "true-reply",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/628126fdbbc8aa2822194379006c6a48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create & launch your voice apps to Amazon Alexa and Google Assistant in minutes with True Reply, no technical experience or coding required. Manage and analyze in real-time via your personal web dashboard.",
    "category": "App Builder",
    "title": "True Reply Integration With Cold Email Sales Tool",
    "h1": "Integrate True Reply with SalesBlink",
    "metaDescription": "Integrate True Reply and other App Builder Tools with SalesBlink. Two Way True Reply Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Happierleads",
    "slug": "happierleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dbceb1e4835171726d27be6b2912e8ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Happierleads is a B2B Web Analytics platform, which turns your website visitors to hot sales leads by revealing important information about them.",
    "category": "Analytics",
    "title": "Happierleads Integration With Cold Email Sales Tool",
    "h1": "Integrate Happierleads with SalesBlink",
    "metaDescription": "Integrate Happierleads and other Analytics Tools with SalesBlink. Two Way Happierleads Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Mailcastr",
    "slug": "mailcastr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/aeb87395a9c99fc85a11d2f13fb90563.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailcastr is an email service that helps you send the right email at the right time and collect leads from your website",
    "category": "Marketing Automation",
    "title": "Mailcastr Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailcastr with SalesBlink",
    "metaDescription": "Integrate Mailcastr and other Marketing Automation Tools with SalesBlink. Two Way Mailcastr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invoice Crowd",
    "slug": "invoice-crowd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/abd5a8e187ed882c5fdfb5121380f935.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Cloud based Invoicing, Estimation and Accounting Platform for small and mid sized Business. Create and send Invoices to your clients and get paid faster.",
    "category": "Proposal & Invoice Management",
    "title": "Invoice Crowd Integration With Cold Email Sales Tool",
    "h1": "Integrate Invoice Crowd with SalesBlink",
    "metaDescription": "Integrate Invoice Crowd and other Proposal & Invoice Management Tools with SalesBlink. Two Way Invoice Crowd Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "videoremix",
    "slug": "videoremix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea2452e910b603d1c99b307b9f9daa1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Videoremix is an online video editor to create simple and personalized videos from scratch.",
    "category": "Video & Audio",
    "title": "videoremix Integration With Cold Email Sales Tool",
    "h1": "Integrate videoremix with SalesBlink",
    "metaDescription": "Integrate videoremix and other Video & Audio Tools with SalesBlink. Two Way videoremix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Call Assist",
    "slug": "call-assist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7299602961637bef9a183757028393cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Call Assist  connect your sales team with new leads instantly and follow up with aging leads automatically through the phone.",
    "category": "Phone & SMS",
    "title": "Call Assist Integration With Cold Email Sales Tool",
    "h1": "Integrate Call Assist with SalesBlink",
    "metaDescription": "Integrate Call Assist and other Phone & SMS Tools with SalesBlink. Two Way Call Assist Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "MyCalendarAlerts",
    "slug": "mycalendaralerts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76d284eeaba895e19ff75530cda9957d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MyCalendarAlerts is a calendar add-on system that lets you create extremely simple yet powerful rules to trigger actions for all your calendar events.",
    "category": "Calendar",
    "title": "MyCalendarAlerts Integration With Cold Email Sales Tool",
    "h1": "Integrate MyCalendarAlerts with SalesBlink",
    "metaDescription": "Integrate MyCalendarAlerts and other Calendar Tools with SalesBlink. Two Way MyCalendarAlerts Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "BCdiploma",
    "slug": "bcdiploma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/594eaa12f0ed20c09b31b0d6eb7c4c58.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BCdiploma is a turnkey blockchain solution for delivering tamper-proof diplomas and certificates.",
    "category": "Education",
    "title": "BCdiploma Integration With Cold Email Sales Tool",
    "h1": "Integrate BCdiploma with SalesBlink",
    "metaDescription": "Integrate BCdiploma and other Education Tools with SalesBlink. Two Way BCdiploma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SwiftEnterprise",
    "slug": "swiftenterprise",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cd61decc04ccfdf8598fd555d4aa931e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SwiftEnterprise is the next generation AI-driven Enterprise Agility platform focused on helping you plan, manage, and deliver your Digital Transformation initiatives.",
    "category": "Project Management",
    "title": "SwiftEnterprise Integration With Cold Email Sales Tool",
    "h1": "Integrate SwiftEnterprise with SalesBlink",
    "metaDescription": "Integrate SwiftEnterprise and other Project Management Tools with SalesBlink. Two Way SwiftEnterprise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Video Avatar Pro",
    "slug": "video-avatar-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5309682986e78d0dc1b981adf580e0dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video Avatar Pro is a video app that allows our users to integrate opt-in box with multiple Social Media Platforms of their choosing.",
    "category": "Marketing Automation",
    "title": "Video Avatar Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Video Avatar Pro with SalesBlink",
    "metaDescription": "Integrate Video Avatar Pro and other Marketing Automation Tools with SalesBlink. Two Way Video Avatar Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AgentBright",
    "slug": "agentbright",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/202a9163d1d4b50bc64df7617c3e0eab.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AgentBright is a relationship marketing platform for real estate agents.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AgentBright Integration With Cold Email Sales Tool",
    "h1": "Integrate AgentBright with SalesBlink",
    "metaDescription": "Integrate AgentBright and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AgentBright Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tulu",
    "slug": "tulu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d194fdab6c9e60e148cff8d085acfec0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tulu is a Workforce Management App That Empowers Cleaners with Essential Technology",
    "category": "Task Management",
    "title": "Tulu Integration With Cold Email Sales Tool",
    "h1": "Integrate Tulu with SalesBlink",
    "metaDescription": "Integrate Tulu and other Task Management Tools with SalesBlink. Two Way Tulu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BookingLive",
    "slug": "bookinglive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4856185c6d8deee35e7a84b73a9cd0d9_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BookingLive is a leading online scheduling and booking system for small businesses, powering your website, with no setup or monthly fees.",
    "category": "Scheduling & Booking",
    "title": "BookingLive Integration With Cold Email Sales Tool",
    "h1": "Integrate BookingLive with SalesBlink",
    "metaDescription": "Integrate BookingLive and other Scheduling & Booking Tools with SalesBlink. Two Way BookingLive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gigrove",
    "slug": "gigrove",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0b5168094894fa880229b45bfd7b2816_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gigrove is an eCommerce platform to get you online and selling directly to your customers within minutes with your ready-made gig store.",
    "category": "eCommerce",
    "title": "Gigrove Integration With Cold Email Sales Tool",
    "h1": "Integrate Gigrove with SalesBlink",
    "metaDescription": "Integrate Gigrove and other eCommerce Tools with SalesBlink. Two Way Gigrove Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "WordPress Website Creator",
    "slug": "wp-website-creator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8f17ccb313092d64ef8ea64b5e85eff9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "wp-website-creator allows you to install wordpress websites on your own server or our cloud server. Install websites directly from your APP",
    "category": "Website Builders",
    "title": "WordPress Website Creator Integration With Cold Email Sales Tool",
    "h1": "Integrate WordPress Website Creator with SalesBlink",
    "metaDescription": "Integrate WordPress Website Creator and other Website Builders Tools with SalesBlink. Two Way WordPress Website Creator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nomisma",
    "slug": "nomisma",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6b7d4271e2dd51cf626bcf1cc1e154e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nomisma is a Complete Accounting Software",
    "category": "Accounting",
    "title": "Nomisma Integration With Cold Email Sales Tool",
    "h1": "Integrate Nomisma with SalesBlink",
    "metaDescription": "Integrate Nomisma and other Accounting Tools with SalesBlink. Two Way Nomisma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Myna",
    "slug": "myna",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1d5bf69adcfa6abc9bdba7b87292eca0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Myna can turn your audio and video files into a SmartTranscript. Transcription, topics and editor straight to your inbox.",
    "category": "Productivity",
    "title": "Myna Integration With Cold Email Sales Tool",
    "h1": "Integrate Myna with SalesBlink",
    "metaDescription": "Integrate Myna and other Productivity Tools with SalesBlink. Two Way Myna Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Social Champ",
    "slug": "social-champ",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b414b22e23d22146fd0badc4082f094f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Social Champ is the simplest and powerful social media management tool, allowing users to publish, engage, and analyze content.",
    "category": "Social Media Accounts",
    "title": "Social Champ Integration With Cold Email Sales Tool",
    "h1": "Integrate Social Champ with SalesBlink",
    "metaDescription": "Integrate Social Champ and other Social Media Accounts Tools with SalesBlink. Two Way Social Champ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pro Scheduler",
    "slug": "pro-scheduler",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/28322a7607e29803c34ae6dec7a4fef8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pro Scheduler helps you to share your social media posts at the time you decide or the 'Best Time' to post.",
    "category": "Social Media Marketing",
    "title": "Pro Scheduler Integration With Cold Email Sales Tool",
    "h1": "Integrate Pro Scheduler with SalesBlink",
    "metaDescription": "Integrate Pro Scheduler and other Social Media Marketing Tools with SalesBlink. Two Way Pro Scheduler Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrowViral",
    "slug": "growviral",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff7371c2950008888dc0fe8b191a2d70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GrowViral is a viral marketing campaign platform.",
    "category": "Marketing",
    "title": "GrowViral Integration With Cold Email Sales Tool",
    "h1": "Integrate GrowViral with SalesBlink",
    "metaDescription": "Integrate GrowViral and other Marketing Tools with SalesBlink. Two Way GrowViral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Officexlr",
    "slug": "officexlr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9696fe0d13bea24843fc8f73542a963f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Officexlr is a low-code application development platform to create customized, secure & integrated business apps.",
    "category": "App Builder",
    "title": "Officexlr Integration With Cold Email Sales Tool",
    "h1": "Integrate Officexlr with SalesBlink",
    "metaDescription": "Integrate Officexlr and other App Builder Tools with SalesBlink. Two Way Officexlr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "timeghost",
    "slug": "timeghost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7adc0f87b014e887ea5effaabef962a6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "timeghost is your project time tracking solution especially designed for Microsoft 365. No extra registration needed, all set with your Office/Microsoft 365 account.",
    "category": "Time Tracking Software",
    "title": "timeghost Integration With Cold Email Sales Tool",
    "h1": "Integrate timeghost with SalesBlink",
    "metaDescription": "Integrate timeghost and other Time Tracking Software Tools with SalesBlink. Two Way timeghost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elevate",
    "slug": "elevate",
    "logo": "https://zapier-images.imgix.net/storage/services/8428dfe5f69c9fb2240b5df5f4a70f5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elevate is a subscription billing solution",
    "category": "Product Management",
    "title": "Elevate Integration With Cold Email Sales Tool",
    "h1": "Integrate Elevate with SalesBlink",
    "metaDescription": "Integrate Elevate and other Product Management Tools with SalesBlink. Two Way Elevate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FraudSentinel",
    "slug": "fraudsentinel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/384c8c294f3a8d107b3fefe85ba7f5ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FraudSentinel Bot & Fraud Detection allows you to evaluate the risk level of users IP addresses in order to monitor and prevent Fraud.",
    "category": "Developer Tools",
    "title": "FraudSentinel Integration With Cold Email Sales Tool",
    "h1": "Integrate FraudSentinel with SalesBlink",
    "metaDescription": "Integrate FraudSentinel and other Developer Tools Tools with SalesBlink. Two Way FraudSentinel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Setka",
    "slug": "setka",
    "logo": "https://zapier-images.imgix.net/storage/services/400787770e722646da9671b6c1b4da2d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Setka is a headless commerce ready design tool that creates interactive visual-first content to attract, engage and convert w/o coding",
    "category": "Content & Files",
    "title": "Setka Integration With Cold Email Sales Tool",
    "h1": "Integrate Setka with SalesBlink",
    "metaDescription": "Integrate Setka and other Content & Files Tools with SalesBlink. Two Way Setka Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SubSocket",
    "slug": "subsocket",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/15175ccf2c2587177949431a973e6074.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SubSocket offers a beautiful solution to easily integrate and manage your PayPal products, plans and subscriptions. All run on PayPal’s powerful billing engine. No code required.",
    "category": "Payment Processing",
    "title": "SubSocket Integration With Cold Email Sales Tool",
    "h1": "Integrate SubSocket with SalesBlink",
    "metaDescription": "Integrate SubSocket and other Payment Processing Tools with SalesBlink. Two Way SubSocket Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "21RISK",
    "slug": "21risk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5854d928b36f30d79a1f62b579eb59a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "21RISK is risk management, reinvented. Use our web-platform to perform audits, and improve safety.",
    "category": "Product Management",
    "title": "21RISK Integration With Cold Email Sales Tool",
    "h1": "Integrate 21RISK with SalesBlink",
    "metaDescription": "Integrate 21RISK and other Product Management Tools with SalesBlink. Two Way 21RISK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CloudApper",
    "slug": "cloudapper",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ad833f9d37e6dffe660c35b41b664cea_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudApper is an app builder that lets you consolidate all of your business tools under a single mobile app, giving your employees instant access to everything they need to drive success.",
    "category": "App Builder",
    "title": "CloudApper Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudApper with SalesBlink",
    "metaDescription": "Integrate CloudApper and other App Builder Tools with SalesBlink. Two Way CloudApper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Askemo",
    "slug": "askemo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1626d81dca213d0e34578ec0b9b34d96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Askemo. Check sentiments in your business relations continuously with regular intervals. Get feedback quickly, easily and user-friendly.",
    "category": "Customer Appreciation",
    "title": "Askemo Integration With Cold Email Sales Tool",
    "h1": "Integrate Askemo with SalesBlink",
    "metaDescription": "Integrate Askemo and other Customer Appreciation Tools with SalesBlink. Two Way Askemo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FlowMojo",
    "slug": "flowmojo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a52740fe44205eabb7a64d1bd6eefb8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FlowMojo is a productivity app that allows you to compose repeatable business processes for each part of your organization.",
    "category": "Productivity",
    "title": "FlowMojo Integration With Cold Email Sales Tool",
    "h1": "Integrate FlowMojo with SalesBlink",
    "metaDescription": "Integrate FlowMojo and other Productivity Tools with SalesBlink. Two Way FlowMojo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xpertly",
    "slug": "xpertly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/441e2b0ecb4dd35ef36e0e0a8acb2e7c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Xpertly is an automation platform for codifying business IT processes.",
    "category": "IT Operations",
    "title": "Xpertly Integration With Cold Email Sales Tool",
    "h1": "Integrate Xpertly with SalesBlink",
    "metaDescription": "Integrate Xpertly and other IT Operations Tools with SalesBlink. Two Way Xpertly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Macorva",
    "slug": "macorva",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4dc45b5ee8d632faacbdcda220d03325.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Macorva is a customer survey tool that allows you to easily collect and analyze customer, brand, employee, and product experiences.",
    "category": "Forms & Surveys",
    "title": "Macorva Integration With Cold Email Sales Tool",
    "h1": "Integrate Macorva with SalesBlink",
    "metaDescription": "Integrate Macorva and other Forms & Surveys Tools with SalesBlink. Two Way Macorva Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Windward Hub",
    "slug": "windward-hub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e32a9fe6a16fa559119f2aaa69721e62.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Windward Hub allows you to integrate easy to use document automation with other SaaS applications and manage templates, versions, and output.",
    "category": "Documents",
    "title": "Windward Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate Windward Hub with SalesBlink",
    "metaDescription": "Integrate Windward Hub and other Documents Tools with SalesBlink. Two Way Windward Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebTotem",
    "slug": "webtotem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/632fd4de3af761e71c84296138c4a171.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WebTotem is your personal AI-inspired web application security assistant that protects your web page from cyber threats and monitors its security",
    "category": "Security & Identity Tools",
    "title": "WebTotem Integration With Cold Email Sales Tool",
    "h1": "Integrate WebTotem with SalesBlink",
    "metaDescription": "Integrate WebTotem and other Security & Identity Tools Tools with SalesBlink. Two Way WebTotem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NHANCENOW",
    "slug": "nhancenow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f31a99b3fe6d519a4b381e1d463bf57.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nhance now  is a customer support tool, also provides solutions over all types of BOT.",
    "category": "IT Operations",
    "title": "NHANCENOW Integration With Cold Email Sales Tool",
    "h1": "Integrate NHANCENOW with SalesBlink",
    "metaDescription": "Integrate NHANCENOW and other IT Operations Tools with SalesBlink. Two Way NHANCENOW Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EyeLevel.ai",
    "slug": "eyelevelai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6f3e37fa65aac2db60224369116e092.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EyeLevel allows you to launch automated chats anywhere you advertise, including from your website, QR codes, paid social, paid search, email marketing, and more. Live chat with your customers using SMS text, Slack, or Microsoft Teams.",
    "category": "Sales & CRM",
    "title": "EyeLevel.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate EyeLevel.ai with SalesBlink",
    "metaDescription": "Integrate EyeLevel.ai and other Sales & CRM Tools with SalesBlink. Two Way EyeLevel.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Urbanise Strata",
    "slug": "urbanise-strata",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce0aff5874f0138e19641bc417380025.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Urbanise offers cloud-based solutions to service the Facilities and Strata Management industries.",
    "category": "IT Operations",
    "title": "Urbanise Strata Integration With Cold Email Sales Tool",
    "h1": "Integrate Urbanise Strata with SalesBlink",
    "metaDescription": "Integrate Urbanise Strata and other IT Operations Tools with SalesBlink. Two Way Urbanise Strata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contalink",
    "slug": "contalink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c8665676c53a3d23d7897c77aac163ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contalink is an accounting and administrative system in the cloud to make the work of the accountant more efficient through the extensive use of technology and automation techniques.",
    "category": "Accounting",
    "title": "Contalink Integration With Cold Email Sales Tool",
    "h1": "Integrate Contalink with SalesBlink",
    "metaDescription": "Integrate Contalink and other Accounting Tools with SalesBlink. Two Way Contalink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Panurgy",
    "slug": "panurgy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf10801f7aaf58824e288bc91bf041ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Panurgy is a reliability monitoring tool that detects anomalies within secure systems.",
    "category": "Security & Identity Tools",
    "title": "Panurgy Integration With Cold Email Sales Tool",
    "h1": "Integrate Panurgy with SalesBlink",
    "metaDescription": "Integrate Panurgy and other Security & Identity Tools Tools with SalesBlink. Two Way Panurgy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OCR Car Plates",
    "slug": "ocr-car-plates-by-primesoft-pols",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/798479a489f3d3f3ac06ea1885911f2c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The cutting edge OCR technology has been applied by Primesoft Polska into a useful tool that recognizes numbers from a car plate image.\nThe numbers are recognized within seconds and are ready to be used within any integrated application.",
    "category": "Documents",
    "title": "OCR Car Plates Integration With Cold Email Sales Tool",
    "h1": "Integrate OCR Car Plates with SalesBlink",
    "metaDescription": "Integrate OCR Car Plates and other Documents Tools with SalesBlink. Two Way OCR Car Plates Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Seize the Market",
    "slug": "seize-the-market",
    "logo": "https://zapier-images.imgix.net/storage/developer/75bf918ac3690855f89756bcc924e6ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seize the Market is a All in One, Lead to Close Business Solution for Real Estate Agent, Team, Brokers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Seize the Market Integration With Cold Email Sales Tool",
    "h1": "Integrate Seize the Market with SalesBlink",
    "metaDescription": "Integrate Seize the Market and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Seize the Market Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WriteWith.AI",
    "slug": "writewithai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82721091813f90e135e62f552fd533a7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Writewith.AI is an ad description generator. It uses AI and a database of high-performance PPC ads to write a copy that converts.",
    "category": "Marketing Automation",
    "title": "WriteWith.AI Integration With Cold Email Sales Tool",
    "h1": "Integrate WriteWith.AI with SalesBlink",
    "metaDescription": "Integrate WriteWith.AI and other Marketing Automation Tools with SalesBlink. Two Way WriteWith.AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Agility CMS",
    "slug": "agility-cms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50891d92dab6330072fd9c8c02c25955.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Headless CMS that gives you the best of both worlds.  Send your content wherever you need it.  A great experience for both editors and developers.",
    "category": "Content & Files",
    "title": "Agility CMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Agility CMS with SalesBlink",
    "metaDescription": "Integrate Agility CMS and other Content & Files Tools with SalesBlink. Two Way Agility CMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Whatboard",
    "slug": "whatboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d6ed77288b567551d7c239c8d4c0b56f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Whatboard is a team collaboration tool to share boards between the teammates.",
    "category": "Dashboards",
    "title": "Whatboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Whatboard with SalesBlink",
    "metaDescription": "Integrate Whatboard and other Dashboards Tools with SalesBlink. Two Way Whatboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PixieBrix",
    "slug": "pixiebrix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/467afc6e738b67ec1b4d7134918ad85e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PixieBrix is a browser extension anyone can use to customize websites and SaaS to fit the way they work.",
    "category": "Productivity",
    "title": "PixieBrix Integration With Cold Email Sales Tool",
    "h1": "Integrate PixieBrix with SalesBlink",
    "metaDescription": "Integrate PixieBrix and other Productivity Tools with SalesBlink. Two Way PixieBrix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tolq",
    "slug": "tolq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7d238d4723b7277b41f3ac511c6080e6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tolq is a translation solution built for e-commerce.",
    "category": "Project Management",
    "title": "Tolq Integration With Cold Email Sales Tool",
    "h1": "Integrate Tolq with SalesBlink",
    "metaDescription": "Integrate Tolq and other Project Management Tools with SalesBlink. Two Way Tolq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eSignAnyWhere",
    "slug": "esignanywhere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eff5afe040f94fc750cb50cfbd9081d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eSignAnyWhere is a digital transaction management that helps users to sign digital documents",
    "category": "Documents",
    "title": "eSignAnyWhere Integration With Cold Email Sales Tool",
    "h1": "Integrate eSignAnyWhere with SalesBlink",
    "metaDescription": "Integrate eSignAnyWhere and other Documents Tools with SalesBlink. Two Way eSignAnyWhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Msaaq",
    "slug": "msaaq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/75e07df15839dbf064d0b0314ad799d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The all-in-one platform to create and sell your online courses and digital products from your academy without any technical knowledge.",
    "category": "Online Courses",
    "title": "Msaaq Integration With Cold Email Sales Tool",
    "h1": "Integrate Msaaq with SalesBlink",
    "metaDescription": "Integrate Msaaq and other Online Courses Tools with SalesBlink. Two Way Msaaq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Humanloop",
    "slug": "humanloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ba96f76f429a118c8f74d7dc5d18ea8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Humanloop is a machine learning platform to automate processing and understanding of text using AI and a human in the loop.",
    "category": "AI Tools",
    "title": "Humanloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Humanloop with SalesBlink",
    "metaDescription": "Integrate Humanloop and other AI Tools Tools with SalesBlink. Two Way Humanloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BooksMatic",
    "slug": "booksmatic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3d5a1e249aac39f88fe37dfd056fa663.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BooksMatic is a workplace management system that is designed to keep the information for your customers and internal users in one place.",
    "category": "Project Management",
    "title": "BooksMatic Integration With Cold Email Sales Tool",
    "h1": "Integrate BooksMatic with SalesBlink",
    "metaDescription": "Integrate BooksMatic and other Project Management Tools with SalesBlink. Two Way BooksMatic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digital Agency Tech",
    "slug": "digital-agency-tech",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5f6fb45b83644d2911e1d244f69f86e0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DAT provides simple and easy to use technology platforms for SMEs.",
    "category": "Task Management",
    "title": "Digital Agency Tech Integration With Cold Email Sales Tool",
    "h1": "Integrate Digital Agency Tech with SalesBlink",
    "metaDescription": "Integrate Digital Agency Tech and other Task Management Tools with SalesBlink. Two Way Digital Agency Tech Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mensagia",
    "slug": "mensagia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e1c7f3bd5458566d7e58fda7bc7b246_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mensagia offer reliable SMS, Email and Voice for business automation communication.",
    "category": "Phone & SMS",
    "title": "Mensagia Integration With Cold Email Sales Tool",
    "h1": "Integrate Mensagia with SalesBlink",
    "metaDescription": "Integrate Mensagia and other Phone & SMS Tools with SalesBlink. Two Way Mensagia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Productroad",
    "slug": "productroad",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5e0705e9618288f0a5a801a7136e3293.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Productroad is a tool to collect and manage feedback from users and vote for features.",
    "category": "Customer Support",
    "title": "Productroad Integration With Cold Email Sales Tool",
    "h1": "Integrate Productroad with SalesBlink",
    "metaDescription": "Integrate Productroad and other Customer Support Tools with SalesBlink. Two Way Productroad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quanto",
    "slug": "quanto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8a0f8881a6f8dd8d0cb9644d7d8b70e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quanto Link enables users to share their financial data in exchange for better financial products",
    "category": "Security & Identity Tools",
    "title": "Quanto Integration With Cold Email Sales Tool",
    "h1": "Integrate Quanto with SalesBlink",
    "metaDescription": "Integrate Quanto and other Security & Identity Tools Tools with SalesBlink. Two Way Quanto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cloudmattr",
    "slug": "cloudmattr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a4eec640aa8a9d9aac13999f9b9cd9ad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloudmattr is a platform that helps businesses by helping them manage user feedback, roadmaps, knowledge bases, automated campaigns, and changelogs.",
    "category": "Marketing Automation",
    "title": "Cloudmattr Integration With Cold Email Sales Tool",
    "h1": "Integrate Cloudmattr with SalesBlink",
    "metaDescription": "Integrate Cloudmattr and other Marketing Automation Tools with SalesBlink. Two Way Cloudmattr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendpoint.io",
    "slug": "sendpointio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f282509e735f23f091c514db8c7820b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendpoint is a form submission endpoint that makes setting up forms and receiving submissions fast and simple.",
    "category": "Forms & Surveys",
    "title": "Sendpoint.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendpoint.io with SalesBlink",
    "metaDescription": "Integrate Sendpoint.io and other Forms & Surveys Tools with SalesBlink. Two Way Sendpoint.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BluHorn",
    "slug": "bluhorn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ee215a978f562059ee1fbbbb10e97f5e_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BluHorn is a Media Buying and Planning Platform that helps users save time, save space, save labor and save money.",
    "category": "Ads & Conversion",
    "title": "BluHorn Integration With Cold Email Sales Tool",
    "h1": "Integrate BluHorn with SalesBlink",
    "metaDescription": "Integrate BluHorn and other Ads & Conversion Tools with SalesBlink. Two Way BluHorn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ZarMoney",
    "slug": "zarmoney",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/70a80e56b391dcfa27af2707f2f1fd10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZarMoney is a complete cloud accounting platform that offers invoicing, billing, income, and expense tracking, financial reporting, inventory management, and much more.",
    "category": "Accounting",
    "title": "ZarMoney Integration With Cold Email Sales Tool",
    "h1": "Integrate ZarMoney with SalesBlink",
    "metaDescription": "Integrate ZarMoney and other Accounting Tools with SalesBlink. Two Way ZarMoney Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CrewDriver",
    "slug": "crewdriver",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b970c26c198a5443016ad049c668c7fd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CrewDriver is an automated employee shift fulfillment system for use alongside your workforce management software or as a stand-alone.",
    "category": "Scheduling & Booking",
    "title": "CrewDriver Integration With Cold Email Sales Tool",
    "h1": "Integrate CrewDriver with SalesBlink",
    "metaDescription": "Integrate CrewDriver and other Scheduling & Booking Tools with SalesBlink. Two Way CrewDriver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Creative Machine",
    "slug": "creative-machine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/203d4d1d0b7270acc1764e80fe7ebc1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Creative Machine lets you automate image generation from templates.",
    "category": "Images & Design",
    "title": "Creative Machine Integration With Cold Email Sales Tool",
    "h1": "Integrate Creative Machine with SalesBlink",
    "metaDescription": "Integrate Creative Machine and other Images & Design Tools with SalesBlink. Two Way Creative Machine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Basix",
    "slug": "basix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46b31257f7b69ee5dd61e01575d96a8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Basix.ai is a sales execution tool that uses AI to power high performing sales processes.",
    "category": "Sales & CRM",
    "title": "Basix Integration With Cold Email Sales Tool",
    "h1": "Integrate Basix with SalesBlink",
    "metaDescription": "Integrate Basix and other Sales & CRM Tools with SalesBlink. Two Way Basix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoomifier",
    "slug": "zoomifier",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3402cf8d7f9bfdd527b066bea1657e32_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoomifier is an all-in-one sales enablement and sales engagement platform that simplifies digital selling.",
    "category": "Sales & CRM",
    "title": "Zoomifier Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoomifier with SalesBlink",
    "metaDescription": "Integrate Zoomifier and other Sales & CRM Tools with SalesBlink. Two Way Zoomifier Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FleetWire.io",
    "slug": "fleetwireio",
    "logo": "https://zapier-images.imgix.net/storage/services/edecdde3596957430cfd635f6142cad8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FleetWire.io is a fleet management software that streamlines your rentals.",
    "category": "Scheduling & Booking",
    "title": "FleetWire.io Integration With Cold Email Sales Tool",
    "h1": "Integrate FleetWire.io with SalesBlink",
    "metaDescription": "Integrate FleetWire.io and other Scheduling & Booking Tools with SalesBlink. Two Way FleetWire.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Splash EU",
    "slug": "splash-eu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8cb822ca69a967f999093e3c3f571555.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Splash is an event marketing automation platform that empowers brands to efficiently scale event programs, reach and connect with target audiences in-person, and connect event-driven engagement to business results.",
    "category": "Event Management",
    "title": "Splash EU Integration With Cold Email Sales Tool",
    "h1": "Integrate Splash EU with SalesBlink",
    "metaDescription": "Integrate Splash EU and other Event Management Tools with SalesBlink. Two Way Splash EU Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ctiny",
    "slug": "ctiny",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2342a1891c3efb222339f6a17782c2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ctiny is a url shortener and re-targeting tool that helps digital marketers make informed decisions.",
    "category": "URL Shortener",
    "title": "Ctiny Integration With Cold Email Sales Tool",
    "h1": "Integrate Ctiny with SalesBlink",
    "metaDescription": "Integrate Ctiny and other URL Shortener Tools with SalesBlink. Two Way Ctiny Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SurveyLab",
    "slug": "surveylab",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/762f271ae22680cf5c49f4250b82a5f7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurveyLab is a customer feedback software that will help you to build surveys, questionnaires, forms or tests. SurveyLab allows you to collect, analyze data and react in real-time.",
    "category": "Forms & Surveys",
    "title": "SurveyLab Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveyLab with SalesBlink",
    "metaDescription": "Integrate SurveyLab and other Forms & Surveys Tools with SalesBlink. Two Way SurveyLab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LetterBot",
    "slug": "letterbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57c84f348389fe213f80d54d32cf2195.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LetterBot is a robotic handwriting service.",
    "category": "Marketing",
    "title": "LetterBot Integration With Cold Email Sales Tool",
    "h1": "Integrate LetterBot with SalesBlink",
    "metaDescription": "Integrate LetterBot and other Marketing Tools with SalesBlink. Two Way LetterBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Infuse",
    "slug": "infuse",
    "logo": "https://zapier-images.imgix.net/storage/services/540d33048aed611b032c396345c6a3c5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infuse is a Fin-Tech oriented Customer Data Platform through which you can grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Infuse Integration With Cold Email Sales Tool",
    "h1": "Integrate Infuse with SalesBlink",
    "metaDescription": "Integrate Infuse and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Infuse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Event Plan On",
    "slug": "event-plan-on",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/26c8a4b274b85c323d85afdd36c063e8_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Event Plan On is a event management tool to organize events and keep event details on track.",
    "category": "Event Management",
    "title": "Event Plan On Integration With Cold Email Sales Tool",
    "h1": "Integrate Event Plan On with SalesBlink",
    "metaDescription": "Integrate Event Plan On and other Event Management Tools with SalesBlink. Two Way Event Plan On Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Run the World",
    "slug": "run-the-world",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/526cd910a5641a66adb760f2f9103da3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Run The World enables your to organize interactive events for groups to learn and socialize.",
    "category": "Video Conferencing",
    "title": "Run the World Integration With Cold Email Sales Tool",
    "h1": "Integrate Run the World with SalesBlink",
    "metaDescription": "Integrate Run the World and other Video Conferencing Tools with SalesBlink. Two Way Run the World Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Capssion",
    "slug": "capssion",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ea5ee1f9ceed401a0f84d66d6957986d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Capssion is a marketplace connecting brands to content creators to run campaigns on social media.",
    "category": "Social Media Marketing",
    "title": "Capssion Integration With Cold Email Sales Tool",
    "h1": "Integrate Capssion with SalesBlink",
    "metaDescription": "Integrate Capssion and other Social Media Marketing Tools with SalesBlink. Two Way Capssion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "A1WebStats",
    "slug": "a1webstats",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4740048461ba6e723ed49d65cd3f6412.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A1WebStats is, on the surface, website visitors analytics software. But we prefer to look on ourselves as being mindset shifters rather than software producers.",
    "category": "Business Intelligence",
    "title": "A1WebStats Integration With Cold Email Sales Tool",
    "h1": "Integrate A1WebStats with SalesBlink",
    "metaDescription": "Integrate A1WebStats and other Business Intelligence Tools with SalesBlink. Two Way A1WebStats Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fettle",
    "slug": "fettle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3b62233db25542bb4ecdbd9a0391f68f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fettle is your all-in-one virtual studio.\nCreate a subscription service for your videos, add value with live-streams, build a loyal membership , and collect payment direct. Fettle is the super-simple way to move your fitness or wellness business online.",
    "category": "Fitness",
    "title": "Fettle Integration With Cold Email Sales Tool",
    "h1": "Integrate Fettle with SalesBlink",
    "metaDescription": "Integrate Fettle and other Fitness Tools with SalesBlink. Two Way Fettle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AdEPT AutoText",
    "slug": "adept-autotext",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/432e5a8cacbafc81d93d4006640b9563.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send TextMessages to Ad-Hoc groups within your school",
    "category": "Customer Support",
    "title": "AdEPT AutoText Integration With Cold Email Sales Tool",
    "h1": "Integrate AdEPT AutoText with SalesBlink",
    "metaDescription": "Integrate AdEPT AutoText and other Customer Support Tools with SalesBlink. Two Way AdEPT AutoText Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OmgLeads",
    "slug": "omgleads",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9909b422fdbb3c7ae705fbd0a2b7f525.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OmgLeads is a quiz and form building app.",
    "category": "Marketing",
    "title": "OmgLeads Integration With Cold Email Sales Tool",
    "h1": "Integrate OmgLeads with SalesBlink",
    "metaDescription": "Integrate OmgLeads and other Marketing Tools with SalesBlink. Two Way OmgLeads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HrFlow.ai",
    "slug": "hrflowai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2092bfb51e4c852ad7539d54bf2b10f1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HrFlow.ai is the 1st multi-layer AI powered API that brings intelligence to your HR Data and bridges the gap between all your data Sources and Destinations.",
    "category": "HR Talent & Recruitment",
    "title": "HrFlow.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate HrFlow.ai with SalesBlink",
    "metaDescription": "Integrate HrFlow.ai and other HR Talent & Recruitment Tools with SalesBlink. Two Way HrFlow.ai Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Auxilia",
    "slug": "auxilia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/78679a2c9fdde60693d65b64bd6614b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Auxilia is a user-friendly donation management system that combines social media integrations, event management, and donor outreach tools.",
    "category": "Fundraising",
    "title": "Auxilia Integration With Cold Email Sales Tool",
    "h1": "Integrate Auxilia with SalesBlink",
    "metaDescription": "Integrate Auxilia and other Fundraising Tools with SalesBlink. Two Way Auxilia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mosaic",
    "slug": "mosaic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/67d953da6b1e616e3715c9c26f6faeea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mosaic maximizes companies' efficiency and profitability by automating the planning, management, and tracking of time",
    "category": "Project Management",
    "title": "Mosaic Integration With Cold Email Sales Tool",
    "h1": "Integrate Mosaic with SalesBlink",
    "metaDescription": "Integrate Mosaic and other Project Management Tools with SalesBlink. Two Way Mosaic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Troops",
    "slug": "troops",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ddae85747fe9e018986836b53b4c129d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Troops is a HR tool for staffing agencies to organize candidates, clients and contracts.",
    "category": "HR Talent & Recruitment",
    "title": "Troops Integration With Cold Email Sales Tool",
    "h1": "Integrate Troops with SalesBlink",
    "metaDescription": "Integrate Troops and other HR Talent & Recruitment Tools with SalesBlink. Two Way Troops Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Thaïs-Hub",
    "slug": "thais-hub",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c431375e520c5bd874c8d9d9a78d0721.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thaïs to read bookings, customers info, etc, in the hotel PMS",
    "category": "Productivity",
    "title": "Thaïs-Hub Integration With Cold Email Sales Tool",
    "h1": "Integrate Thaïs-Hub with SalesBlink",
    "metaDescription": "Integrate Thaïs-Hub and other Productivity Tools with SalesBlink. Two Way Thaïs-Hub Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Virtual Badge",
    "slug": "virtual-badge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20854d5c60153db93e69c2b9c5676ebc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "For organizations who need better ways to identify and authenticate their people, Virtual Badge is the simplest identity management platform available.",
    "category": "Security & Identity Tools",
    "title": "Virtual Badge Integration With Cold Email Sales Tool",
    "h1": "Integrate Virtual Badge with SalesBlink",
    "metaDescription": "Integrate Virtual Badge and other Security & Identity Tools Tools with SalesBlink. Two Way Virtual Badge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Remindee",
    "slug": "remindee",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9f67a43d34abac0dc3f4a8b9c0663be1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Remindee is an all-in-one popup and email solution for SMBs and eCommerce sites, to capture and convert more visitors.",
    "category": "Ads & Conversion",
    "title": "Remindee Integration With Cold Email Sales Tool",
    "h1": "Integrate Remindee with SalesBlink",
    "metaDescription": "Integrate Remindee and other Ads & Conversion Tools with SalesBlink. Two Way Remindee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Doclift PDF",
    "slug": "doclift-pdf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ab51f542296e3ddbeb3a141012678c70.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Doclift PDF is PDF-as-a-service for your platform to create dynamic PDFs with the online editor or generate them through our REST API.",
    "category": "Documents",
    "title": "Doclift PDF Integration With Cold Email Sales Tool",
    "h1": "Integrate Doclift PDF with SalesBlink",
    "metaDescription": "Integrate Doclift PDF and other Documents Tools with SalesBlink. Two Way Doclift PDF Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PayRequest",
    "slug": "payrequest",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2fd4a2c99a90ce17dd724a061fdaedd7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PayRequest is a payment request platform that makes it easy to create your own branded payment page and to send payment links to all your customers.",
    "category": "Payment Processing",
    "title": "PayRequest Integration With Cold Email Sales Tool",
    "h1": "Integrate PayRequest with SalesBlink",
    "metaDescription": "Integrate PayRequest and other Payment Processing Tools with SalesBlink. Two Way PayRequest Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HRBLADE",
    "slug": "hrblade",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff448baa65801c2acc3f0d66d788d00c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "HRBLADE is a user-friendly video interview service with powerful functionality.",
    "category": "Forms & Surveys",
    "title": "HRBLADE Integration With Cold Email Sales Tool",
    "h1": "Integrate HRBLADE with SalesBlink",
    "metaDescription": "Integrate HRBLADE and other Forms & Surveys Tools with SalesBlink. Two Way HRBLADE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Simply Budget",
    "slug": "simply-budget",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8224149c6b0eb4a97038b28455ff700e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Simply Budget is an easy and secure budgeting tool to make your budget predictions and achieve all business goals.",
    "category": "Accounting",
    "title": "Simply Budget Integration With Cold Email Sales Tool",
    "h1": "Integrate Simply Budget with SalesBlink",
    "metaDescription": "Integrate Simply Budget and other Accounting Tools with SalesBlink. Two Way Simply Budget Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loystar",
    "slug": "loystar",
    "logo": "https://zapier-images.imgix.net/storage/developer/b08019de16322c7325188552a4d7cc2a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loystar is a customer and point of sale platform that helps SMEs in selling their products or services to drive repeat patronage",
    "category": "Sales & CRM",
    "title": "Loystar Integration With Cold Email Sales Tool",
    "h1": "Integrate Loystar with SalesBlink",
    "metaDescription": "Integrate Loystar and other Sales & CRM Tools with SalesBlink. Two Way Loystar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iFOLIO Cloud",
    "slug": "ifolio-cloud",
    "logo": "https://zapier-images.imgix.net/storage/services/70657e5e04b61a52232cdb017efbbab1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "iFOLIO is the all-in-one integrated marketing platform to grow your business and enhance digital presence. Make work easier!",
    "category": "Communication",
    "title": "iFOLIO Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate iFOLIO Cloud with SalesBlink",
    "metaDescription": "Integrate iFOLIO Cloud and other Communication Tools with SalesBlink. Two Way iFOLIO Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pando",
    "slug": "pando",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57d043c78c29475b520afc673cc39f3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pando is a lead distribution platform assisting marketers with data monetization.",
    "category": "Marketing",
    "title": "Pando Integration With Cold Email Sales Tool",
    "h1": "Integrate Pando with SalesBlink",
    "metaDescription": "Integrate Pando and other Marketing Tools with SalesBlink. Two Way Pando Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Crowlingo",
    "slug": "crowlingo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd8bd7bd8a22b37ba62dc37a1a6297d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Crowlingo provides NLP services to find insights and relationships in text of 100+ different languages.",
    "category": "Analytics",
    "title": "Crowlingo Integration With Cold Email Sales Tool",
    "h1": "Integrate Crowlingo with SalesBlink",
    "metaDescription": "Integrate Crowlingo and other Analytics Tools with SalesBlink. Two Way Crowlingo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Payload",
    "slug": "payload",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/17c27eadecd25b0be22adfe12a828ea2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payload is a flexible payment stack for modern platforms.",
    "category": "Payment Processing",
    "title": "Payload Integration With Cold Email Sales Tool",
    "h1": "Integrate Payload with SalesBlink",
    "metaDescription": "Integrate Payload and other Payment Processing Tools with SalesBlink. Two Way Payload Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventmix",
    "slug": "eventmix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6cf17c274d18b19315b2d44e43846169.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventmix is a whitelabel platform for virtual & hybrid events.",
    "category": "Event Management",
    "title": "Eventmix Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventmix with SalesBlink",
    "metaDescription": "Integrate Eventmix and other Event Management Tools with SalesBlink. Two Way Eventmix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ARPADENT",
    "slug": "arpadent",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cfaf8c502901efb1a3c94c2ee3bceda3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ARPADENT is a dental software accredited by EESZT. A dentist's best friend.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ARPADENT Integration With Cold Email Sales Tool",
    "h1": "Integrate ARPADENT with SalesBlink",
    "metaDescription": "Integrate ARPADENT and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ARPADENT Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Helpy Pro",
    "slug": "helpy-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4fdcfc0cf3e6542bd29bdef5663efd84_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helpy Pro is the customer support platform engineered for companies that are serious about customer data security and control.",
    "category": "Customer Support",
    "title": "Helpy Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Helpy Pro with SalesBlink",
    "metaDescription": "Integrate Helpy Pro and other Customer Support Tools with SalesBlink. Two Way Helpy Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Applysis",
    "slug": "applysis",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b9d2c242798aa79eb9668a14873b36d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Applysis is a web platform that analyses the customer feedback in bulk with the help of AI.",
    "category": "Analytics",
    "title": "Applysis Integration With Cold Email Sales Tool",
    "h1": "Integrate Applysis with SalesBlink",
    "metaDescription": "Integrate Applysis and other Analytics Tools with SalesBlink. Two Way Applysis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "go4clic",
    "slug": "go4clic",
    "logo": "https://zapier-images.imgix.net/storage/services/bb5c264a621d22fb9ce0c4fc1dcadd48.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "go4clic is a challenge-based elearning platform that generates engagement in students, making companies and institutions carry out quality training",
    "category": "Education",
    "title": "go4clic Integration With Cold Email Sales Tool",
    "h1": "Integrate go4clic with SalesBlink",
    "metaDescription": "Integrate go4clic and other Education Tools with SalesBlink. Two Way go4clic Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "4Pay",
    "slug": "4pay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4aa1e0eef5630f933267854c5e232672.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "4Pay is a payment platform, specializing on internet-acquiring",
    "category": "eCommerce",
    "title": "4Pay Integration With Cold Email Sales Tool",
    "h1": "Integrate 4Pay with SalesBlink",
    "metaDescription": "Integrate 4Pay and other eCommerce Tools with SalesBlink. Two Way 4Pay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Interwoo",
    "slug": "interwoo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4578d8d9f8e7a75989c236e308e2bd29.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Video interview service that speeds up your hiring by four times",
    "category": "Forms & Surveys",
    "title": "Interwoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Interwoo with SalesBlink",
    "metaDescription": "Integrate Interwoo and other Forms & Surveys Tools with SalesBlink. Two Way Interwoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneWayLoyalty",
    "slug": "onewayloyalty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cdae7d5bf4fd6c42a7c35e392b003790.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Customer Digital Loyalty Platform. Convert new customers into returning customers. Our platform helps you make more sales by turning one-off transactions into relationships and retaining customers using memberships and digital rewards.",
    "category": "eCommerce",
    "title": "OneWayLoyalty Integration With Cold Email Sales Tool",
    "h1": "Integrate OneWayLoyalty with SalesBlink",
    "metaDescription": "Integrate OneWayLoyalty and other eCommerce Tools with SalesBlink. Two Way OneWayLoyalty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lanteria HR",
    "slug": "lanteria-hr",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f68ee6223d7320d66aff52df7ba09696_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lanteria HR is a solution for managing HR processes, including time & attendance, recruiting, learning, compensation, and performance.",
    "category": "HR Talent & Recruitment",
    "title": "Lanteria HR Integration With Cold Email Sales Tool",
    "h1": "Integrate Lanteria HR with SalesBlink",
    "metaDescription": "Integrate Lanteria HR and other HR Talent & Recruitment Tools with SalesBlink. Two Way Lanteria HR Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Joonto",
    "slug": "joonto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b9065f556547138937b8e1879cf01278.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "JOONTO automatically tracks, manages, and monitors your business user's call activities from their mobile phone including call stats, optional call recording, call logs, and call monitoring.",
    "category": "Call Tracking",
    "title": "Joonto Integration With Cold Email Sales Tool",
    "h1": "Integrate Joonto with SalesBlink",
    "metaDescription": "Integrate Joonto and other Call Tracking Tools with SalesBlink. Two Way Joonto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Language Weaver",
    "slug": "language-weaver",
    "logo": "https://zapier-images.imgix.net/storage/services/d51c8fd40a5cebfa43cce931eed6d358.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Language Weaver is a tool used to translate content.",
    "category": "Content & Files",
    "title": "Language Weaver Integration With Cold Email Sales Tool",
    "h1": "Integrate Language Weaver with SalesBlink",
    "metaDescription": "Integrate Language Weaver and other Content & Files Tools with SalesBlink. Two Way Language Weaver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Futureworks",
    "slug": "futureworks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/937578e271e3801d921d4cb7f3c54a04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Futureworks’ OKR integration makes it easy for employees in the company to know what the goal of the company is and how we can achieve it.",
    "category": "Task Management",
    "title": "Futureworks Integration With Cold Email Sales Tool",
    "h1": "Integrate Futureworks with SalesBlink",
    "metaDescription": "Integrate Futureworks and other Task Management Tools with SalesBlink. Two Way Futureworks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adrapid",
    "slug": "adrapid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f51047bd54353e4eded5224ec715b1c8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adrapid is a banner creation tool. Create images, html5 or video banners.",
    "category": "Marketing Automation",
    "title": "Adrapid Integration With Cold Email Sales Tool",
    "h1": "Integrate Adrapid with SalesBlink",
    "metaDescription": "Integrate Adrapid and other Marketing Automation Tools with SalesBlink. Two Way Adrapid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UniLink",
    "slug": "unilink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1db312d2d94e73543cb79cf54a80b26f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UniLink helps you drive traffic from your Instagram/TikTok account to clickable, shoppable mini website.",
    "category": "Website Builders",
    "title": "UniLink Integration With Cold Email Sales Tool",
    "h1": "Integrate UniLink with SalesBlink",
    "metaDescription": "Integrate UniLink and other Website Builders Tools with SalesBlink. Two Way UniLink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SpruceJoy",
    "slug": "sprucejoy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/82ec8d0de00210159c95f16a94fb72cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SpruceJoy is a membership software for any website.",
    "category": "eCommerce",
    "title": "SpruceJoy Integration With Cold Email Sales Tool",
    "h1": "Integrate SpruceJoy with SalesBlink",
    "metaDescription": "Integrate SpruceJoy and other eCommerce Tools with SalesBlink. Two Way SpruceJoy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IDI",
    "slug": "idi",
    "logo": "https://zapier-images.imgix.net/storage/services/9c35e06bd7f1c7aa14b6f92c2d033910.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IDI is a real estate technology partner to make the control of your real estate and communication with your contacts and clients more agile and easy.",
    "category": "CRM (Customer Relationship Management)",
    "title": "IDI Integration With Cold Email Sales Tool",
    "h1": "Integrate IDI with SalesBlink",
    "metaDescription": "Integrate IDI and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way IDI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avrio",
    "slug": "avrio",
    "logo": "https://zapier-images.imgix.net/storage/services/0bd07e9aeebe9dae22890d07daf36a96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Avrio is the new way to capture and share data analysis, user research, and learnings directly in context, with your team.",
    "category": "Analytics",
    "title": "Avrio Integration With Cold Email Sales Tool",
    "h1": "Integrate Avrio with SalesBlink",
    "metaDescription": "Integrate Avrio and other Analytics Tools with SalesBlink. Two Way Avrio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pomelo Pay",
    "slug": "pomelo-pay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db6a351328556a8f1e61c818ef1219aa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pomelo Pay is a hardware-free mobile payment service provider offering a secure, simple and hassle-free way to take payments in an instant.",
    "category": "Payment Processing",
    "title": "Pomelo Pay Integration With Cold Email Sales Tool",
    "h1": "Integrate Pomelo Pay with SalesBlink",
    "metaDescription": "Integrate Pomelo Pay and other Payment Processing Tools with SalesBlink. Two Way Pomelo Pay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sitecreator",
    "slug": "sitecreator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c98fd3ffcfa3ff31f7c1e5d52aef440.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sitecreate is a website builder that lets you build a website for your startup in just a few minutes, with no technical knowledge required.",
    "category": "Developer Tools",
    "title": "Sitecreator Integration With Cold Email Sales Tool",
    "h1": "Integrate Sitecreator with SalesBlink",
    "metaDescription": "Integrate Sitecreator and other Developer Tools Tools with SalesBlink. Two Way Sitecreator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pro Crew Schedule",
    "slug": "procrew-schedule",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/455f6062cd4b066a923a7e67d76110fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pro Crew Schedule is an all-in-one construction scheduling software.",
    "category": "Project Management",
    "title": "Pro Crew Schedule Integration With Cold Email Sales Tool",
    "h1": "Integrate Pro Crew Schedule with SalesBlink",
    "metaDescription": "Integrate Pro Crew Schedule and other Project Management Tools with SalesBlink. Two Way Pro Crew Schedule Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "LeadKlozer",
    "slug": "leadklozer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/44064e6c7499e7db547b1ebd27b35088.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LeadKlozer is an ad performance, conversation & sales tool for Facebook marketers.",
    "category": "Ads & Conversion",
    "title": "LeadKlozer Integration With Cold Email Sales Tool",
    "h1": "Integrate LeadKlozer with SalesBlink",
    "metaDescription": "Integrate LeadKlozer and other Ads & Conversion Tools with SalesBlink. Two Way LeadKlozer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pushtech",
    "slug": "pushtech",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d7b499242d0d466b4e2c8e8bc3bcb0ce.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PUSHTech is a CRM, marketing automation, sales and support platform, that allows businesses to acquire, segment and engage their customers; creating better experiences and building loyalty across email, website notifications, mobile applications, sms, push and chat.",
    "category": "Sales & CRM",
    "title": "Pushtech Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushtech with SalesBlink",
    "metaDescription": "Integrate Pushtech and other Sales & CRM Tools with SalesBlink. Two Way Pushtech Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QPage",
    "slug": "qpage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f41a711dca62b898aa6ce2adadf9418d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QPage is a one-stop recruiting solution offering AI-powered tools and Analytics including content generation in JD, Emails and Interview questions, Job and Resume analytics, Psychometric assessments, Cohorts, AI sourcing, Sequences, Workflow automation and more.",
    "category": "HR Talent & Recruitment",
    "title": "QPage Integration With Cold Email Sales Tool",
    "h1": "Integrate QPage with SalesBlink",
    "metaDescription": "Integrate QPage and other HR Talent & Recruitment Tools with SalesBlink. Two Way QPage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SolveXia",
    "slug": "solvexia-australia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b71c314f88be0c5fae950a8ed63e117.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SolveXia helps Finance and Accounting teams automate their reporting, reconciliations and analytics quickly and easily with zero-coding. Location support: Australia",
    "category": "Analytics",
    "title": "SolveXia Integration With Cold Email Sales Tool",
    "h1": "Integrate SolveXia with SalesBlink",
    "metaDescription": "Integrate SolveXia and other Analytics Tools with SalesBlink. Two Way SolveXia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UnaConnect",
    "slug": "unaconnect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24eb4a1cae41e47ab5223bd585d7c1e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UnaConnect is a simple way to manage and receive data from a large fleet of IoT devices cost-efficiently via a console and an API.",
    "category": "Internet of Things",
    "title": "UnaConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate UnaConnect with SalesBlink",
    "metaDescription": "Integrate UnaConnect and other Internet of Things Tools with SalesBlink. Two Way UnaConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moovly",
    "slug": "moovly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3779b22e6b42eee5bc4f0d9f7713c4a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moovly is a cloud-based multimedia creation platform that allows you to make both videos and video templates.",
    "category": "Video & Audio",
    "title": "Moovly Integration With Cold Email Sales Tool",
    "h1": "Integrate Moovly with SalesBlink",
    "metaDescription": "Integrate Moovly and other Video & Audio Tools with SalesBlink. Two Way Moovly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myDevices",
    "slug": "mydevices",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/524e8c662ba0576933159a10503f018d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "One platform. Infinite plug-and-play IoT solutions.",
    "category": "Devices",
    "title": "myDevices Integration With Cold Email Sales Tool",
    "h1": "Integrate myDevices with SalesBlink",
    "metaDescription": "Integrate myDevices and other Devices Tools with SalesBlink. Two Way myDevices Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OffAlerts",
    "slug": "offalerts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7be50f03874df1a8d883ba14ca039b60_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uptime Monitors & Status Pages. Get Immediate Email, SMS and Push Notifications (Off Alerts) When Your Website Or App Is Offline.",
    "category": "Developer Tools",
    "title": "OffAlerts Integration With Cold Email Sales Tool",
    "h1": "Integrate OffAlerts with SalesBlink",
    "metaDescription": "Integrate OffAlerts and other Developer Tools Tools with SalesBlink. Two Way OffAlerts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Staykeepers",
    "slug": "staykeepers",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0534b6f361cf7296547341a767841764.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Staykeepers is a tech solution that amplifies the lead generation of guests, tenants & students for residential property management companies & asset owners.",
    "category": "Scheduling & Booking",
    "title": "Staykeepers Integration With Cold Email Sales Tool",
    "h1": "Integrate Staykeepers with SalesBlink",
    "metaDescription": "Integrate Staykeepers and other Scheduling & Booking Tools with SalesBlink. Two Way Staykeepers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fiddle",
    "slug": "fiddle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c06a98d02ed3e7a11f4393fb3a0314e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inventory software for fast growing businesses.",
    "category": "eCommerce",
    "title": "Fiddle Integration With Cold Email Sales Tool",
    "h1": "Integrate Fiddle with SalesBlink",
    "metaDescription": "Integrate Fiddle and other eCommerce Tools with SalesBlink. Two Way Fiddle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AutoRefs",
    "slug": "autorefs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/43ad56f97e57eeb8b7d1061039da3503_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AutoRefs is a cloud-based reference checking system that automates the communication process between a company, their applicant, and the referee, cutting out paper-based reference checking and calls from your HR process.",
    "category": "HR Talent & Recruitment",
    "title": "AutoRefs Integration With Cold Email Sales Tool",
    "h1": "Integrate AutoRefs with SalesBlink",
    "metaDescription": "Integrate AutoRefs and other HR Talent & Recruitment Tools with SalesBlink. Two Way AutoRefs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brex Partner Referrals",
    "slug": "brex-partner-referrals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8dcd3647e867db219c4efab50982d45.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Brex Partner Referrals app offers a no-code solution for partners to access Brex's onboarding API.",
    "category": "Commerce",
    "title": "Brex Partner Referrals Integration With Cold Email Sales Tool",
    "h1": "Integrate Brex Partner Referrals with SalesBlink",
    "metaDescription": "Integrate Brex Partner Referrals and other Commerce Tools with SalesBlink. Two Way Brex Partner Referrals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "K Factors",
    "slug": "k-factors",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/398be0c6ee23540181fa227946dceb04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use K Factors to create notification widgets that convert.",
    "category": "Ads & Conversion",
    "title": "K Factors Integration With Cold Email Sales Tool",
    "h1": "Integrate K Factors with SalesBlink",
    "metaDescription": "Integrate K Factors and other Ads & Conversion Tools with SalesBlink. Two Way K Factors Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easelly",
    "slug": "easelly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/021cc8eb22c337111676f955d2c68581.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easelly is a simple infographic maker that lets you visualize any kind of information.  It is a web tool that empowers anyone to create and share powerful visuals (infographics, posters)... no design experience needed!",
    "category": "Images & Design",
    "title": "Easelly Integration With Cold Email Sales Tool",
    "h1": "Integrate Easelly with SalesBlink",
    "metaDescription": "Integrate Easelly and other Images & Design Tools with SalesBlink. Two Way Easelly Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "GENIQ",
    "slug": "geniq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7a47e15db89f33e4115260d837985b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GENIQ is the the fastest and easiest cloud SMS system to use. We ensure your SMS messages are delivered to the world’s 7 billion mobile subscribers in real-time.",
    "category": "Marketing",
    "title": "GENIQ Integration With Cold Email Sales Tool",
    "h1": "Integrate GENIQ with SalesBlink",
    "metaDescription": "Integrate GENIQ and other Marketing Tools with SalesBlink. Two Way GENIQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailerrrize",
    "slug": "mailerrrize",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c95ebc46b39f95d88e879b7359fd8767.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailerrize is an email automation and engagement tool.",
    "category": "Email",
    "title": "Mailerrrize Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailerrrize with SalesBlink",
    "metaDescription": "Integrate Mailerrrize and other Email Tools with SalesBlink. Two Way Mailerrrize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Peasy Sales",
    "slug": "peasy-sales",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e6e37af7e95bc1903a6fddbcf76e584a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Peasy Sales is a communication app for sales team to connect seamlessly with customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Peasy Sales Integration With Cold Email Sales Tool",
    "h1": "Integrate Peasy Sales with SalesBlink",
    "metaDescription": "Integrate Peasy Sales and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Peasy Sales Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cobiro",
    "slug": "cobiro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0b08ba96a4a3ddf5a3669811e12ab4fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cobiro is a platform which provides all the e-commerce and marketing tools you need to build and grow your business online.",
    "category": "eCommerce",
    "title": "Cobiro Integration With Cold Email Sales Tool",
    "h1": "Integrate Cobiro with SalesBlink",
    "metaDescription": "Integrate Cobiro and other eCommerce Tools with SalesBlink. Two Way Cobiro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TeamFlow",
    "slug": "teamflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9ebe767f33133f54d042bc46733b9807.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamFlow is the process flow builder that helps you answer the \"Who, What, When and How?\" of every activity in your organization.",
    "category": "Productivity",
    "title": "TeamFlow Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamFlow with SalesBlink",
    "metaDescription": "Integrate TeamFlow and other Productivity Tools with SalesBlink. Two Way TeamFlow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Peasy AI",
    "slug": "peasy-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/462d5f39606893888dff5c278ce49d60.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Peasy AI is a SaaS marketing platform to replace digital agencies. We remove the skill and cost barriers for SMEs to participate in the digital economy.",
    "category": "Marketing",
    "title": "Peasy AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Peasy AI with SalesBlink",
    "metaDescription": "Integrate Peasy AI and other Marketing Tools with SalesBlink. Two Way Peasy AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadspin",
    "slug": "leadspin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2a95f7a62fa4137c4e1ec161ee0d578e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadspin is a gamification tool to collect leads in a fun and efficient way.",
    "category": "Ads & Conversion",
    "title": "Leadspin Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadspin with SalesBlink",
    "metaDescription": "Integrate Leadspin and other Ads & Conversion Tools with SalesBlink. Two Way Leadspin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Linguin AI",
    "slug": "linguin-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e01c5b878f4e1beeada3a5fb1bffbd85.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Linguin AI is a language detection service powered by humans and AI.",
    "category": "Developer Tools",
    "title": "Linguin AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Linguin AI with SalesBlink",
    "metaDescription": "Integrate Linguin AI and other Developer Tools Tools with SalesBlink. Two Way Linguin AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fizzpa",
    "slug": "fizzpa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7195272223715e213a2d3b252a770aa9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fizzpa is a delivery system platform to help companies manage shipments and deliveries.",
    "category": "Developer Tools",
    "title": "Fizzpa Integration With Cold Email Sales Tool",
    "h1": "Integrate Fizzpa with SalesBlink",
    "metaDescription": "Integrate Fizzpa and other Developer Tools Tools with SalesBlink. Two Way Fizzpa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SuperCat 360",
    "slug": "supercat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89be9fdf9522f0cb674eee40f89caab1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SuperCat 360 is a review collection platform. Automatically send surveys to your customers via text message and collect reviews on all the major review sites.",
    "category": "Forms & Surveys",
    "title": "SuperCat 360 Integration With Cold Email Sales Tool",
    "h1": "Integrate SuperCat 360 with SalesBlink",
    "metaDescription": "Integrate SuperCat 360 and other Forms & Surveys Tools with SalesBlink. Two Way SuperCat 360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nexuss",
    "slug": "nexuss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bbc20e6794d3cb0571351066cdbbff76_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nexuss is a platform to help monetize the content you share. Create posts from Youtube, Vimeo, Twitter, Instagram, and more. You can use your own ad accounts, we handle the automation for you.",
    "category": "Productivity",
    "title": "Nexuss Integration With Cold Email Sales Tool",
    "h1": "Integrate Nexuss with SalesBlink",
    "metaDescription": "Integrate Nexuss and other Productivity Tools with SalesBlink. Two Way Nexuss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "byDesign Workspace",
    "slug": "bydesign-workspace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/45e15854a557e69663c985ea10389869_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instantly 10x your marketing output with on-demand designers and marketing specialists, powered by a fixed-rate monthly subscription.",
    "category": "Project Management",
    "title": "byDesign Workspace Integration With Cold Email Sales Tool",
    "h1": "Integrate byDesign Workspace with SalesBlink",
    "metaDescription": "Integrate byDesign Workspace and other Project Management Tools with SalesBlink. Two Way byDesign Workspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Momindum",
    "slug": "momindum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3a28b11f704dc45e8f19a71d82a74df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Momindum provides software and services for online communication, training, learning, and collaboration with video presentations.",
    "category": "Video & Audio",
    "title": "Momindum Integration With Cold Email Sales Tool",
    "h1": "Integrate Momindum with SalesBlink",
    "metaDescription": "Integrate Momindum and other Video & Audio Tools with SalesBlink. Two Way Momindum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "novaTime",
    "slug": "novatime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d2be8d76eb025c281b0a54571e16aa64.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "novaTime allows you to manage all the activities of an organization.",
    "category": "Productivity",
    "title": "novaTime Integration With Cold Email Sales Tool",
    "h1": "Integrate novaTime with SalesBlink",
    "metaDescription": "Integrate novaTime and other Productivity Tools with SalesBlink. Two Way novaTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sales Innovator",
    "slug": "sales-innovator",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/303920e53e0ad1e0fb58c84a802924a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sales innovator is the platform, where you can create campaigns by using sales robot to send email. All types of email analytics are available on sales innovator.",
    "category": "Marketing Automation",
    "title": "Sales Innovator Integration With Cold Email Sales Tool",
    "h1": "Integrate Sales Innovator with SalesBlink",
    "metaDescription": "Integrate Sales Innovator and other Marketing Automation Tools with SalesBlink. Two Way Sales Innovator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Facebook Lead Ads (for Business admins)",
    "slug": "facebook-lead-ads-beta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f407c31b217aac6e0cd4171092d53a8c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Facebook lead ads make signing up for business information easy for people and more valuable for businesses. The Facebook lead ad app is useful for marketers who want to automate actions on their leads.",
    "category": "Ads & Conversion",
    "title": "Facebook Lead Ads (for Business admins) Integration With Cold Email Sales Tool",
    "h1": "Integrate Facebook Lead Ads (for Business admins) with SalesBlink",
    "metaDescription": "Integrate Facebook Lead Ads (for Business admins) and other Ads & Conversion Tools with SalesBlink. Two Way Facebook Lead Ads (for Business admins) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GEVME Virtual",
    "slug": "gevme-virtual",
    "logo": "https://zapier-images.imgix.net/storage/services/e3194fa5f9313c6a6b81d767c7bb3f95.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GEVME Virtual is a virtual event platform used by event professionals to build, operate and monetize their virtual events",
    "category": "Event Management",
    "title": "GEVME Virtual Integration With Cold Email Sales Tool",
    "h1": "Integrate GEVME Virtual with SalesBlink",
    "metaDescription": "Integrate GEVME Virtual and other Event Management Tools with SalesBlink. Two Way GEVME Virtual Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IMMO CRM 360",
    "slug": "immo-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3943c5609902e098efe0abae9f3b6c71.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Immo CRM 360 is a real estate technology partner to make the control of your real estate and communication with your contacts and clients more agile and easy.",
    "category": "CRM (Customer Relationship Management)",
    "title": "IMMO CRM 360 Integration With Cold Email Sales Tool",
    "h1": "Integrate IMMO CRM 360 with SalesBlink",
    "metaDescription": "Integrate IMMO CRM 360 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way IMMO CRM 360 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beau",
    "slug": "beau",
    "logo": "https://zapier-images.imgix.net/storage/services/2f8854bb19e7801a2cd831e71b177eb6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn client-facing workflows into client portals: collect form submissions, documents and files, accept payments, send messages and reminders, approve steps or request changes.",
    "category": "Forms & Surveys",
    "title": "Beau Integration With Cold Email Sales Tool",
    "h1": "Integrate Beau with SalesBlink",
    "metaDescription": "Integrate Beau and other Forms & Surveys Tools with SalesBlink. Two Way Beau Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contify",
    "slug": "contify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ab09df36fc59cc27366b70e0c58d08b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contify is an AI-enabled market and competitive intelligence platform that helps businesses track information on competitors, customers and industry segments by enabling users to collect, curate, and share actionable intelligence across their organization.",
    "category": "Business Intelligence",
    "title": "Contify Integration With Cold Email Sales Tool",
    "h1": "Integrate Contify with SalesBlink",
    "metaDescription": "Integrate Contify and other Business Intelligence Tools with SalesBlink. Two Way Contify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimpleFunnel",
    "slug": "simplefunnel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fd85c167afefd6767801edf9c8247351.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleFunnel is an embeddable payments widget you can install on any website as a no-code solution that allows you to collect payments from customers.",
    "category": "Payment Processing",
    "title": "SimpleFunnel Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleFunnel with SalesBlink",
    "metaDescription": "Integrate SimpleFunnel and other Payment Processing Tools with SalesBlink. Two Way SimpleFunnel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TurkeySMS",
    "slug": "turkeysms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a0d4ba3cab539b49d82c718520a8ec24_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TurkeySMS is an SMS service that lets you send an SMS to any number you want",
    "category": "Business Intelligence",
    "title": "TurkeySMS Integration With Cold Email Sales Tool",
    "h1": "Integrate TurkeySMS with SalesBlink",
    "metaDescription": "Integrate TurkeySMS and other Business Intelligence Tools with SalesBlink. Two Way TurkeySMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Culverdocs",
    "slug": "culverdocs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d0b463eafddb7cec1e13e167e0d30d2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Culverdocs is a fully configurable, no-code platform that allows you to convert your existing paper-based documents into digital forms to transform how you collect, process and manage business data.",
    "category": "App Builder",
    "title": "Culverdocs Integration With Cold Email Sales Tool",
    "h1": "Integrate Culverdocs with SalesBlink",
    "metaDescription": "Integrate Culverdocs and other App Builder Tools with SalesBlink. Two Way Culverdocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "webinar.net (APAC)",
    "slug": "webinarnet-apac",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ba53804b6f9bbda586e4c921f137bfde_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "webinar.net is the first online presentation platform fully hosted in the cloud. This architecture allows our customers to reach very large audiences without capacity concerns.",
    "category": "Webinars",
    "title": "webinar.net (APAC) Integration With Cold Email Sales Tool",
    "h1": "Integrate webinar.net (APAC) with SalesBlink",
    "metaDescription": "Integrate webinar.net (APAC) and other Webinars Tools with SalesBlink. Two Way webinar.net (APAC) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KeepGood",
    "slug": "keepgood",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/25845bdea1a12e35e9565e8f37af7646.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KeepGood helps you monitor all your infrastructure from a single place and improve service.",
    "category": "Dashboards",
    "title": "KeepGood Integration With Cold Email Sales Tool",
    "h1": "Integrate KeepGood with SalesBlink",
    "metaDescription": "Integrate KeepGood and other Dashboards Tools with SalesBlink. Two Way KeepGood Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smartnotation",
    "slug": "smartnotation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/21811a19f3e787b2ab62a901bd5df8bc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smartnotation is an easy to use, voice enabled meeting minutes solution.",
    "category": "Task Management",
    "title": "Smartnotation Integration With Cold Email Sales Tool",
    "h1": "Integrate Smartnotation with SalesBlink",
    "metaDescription": "Integrate Smartnotation and other Task Management Tools with SalesBlink. Two Way Smartnotation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Curatebase",
    "slug": "curatebase",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99337ff901df4d7013f9dcedb9b9a367.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Curatebase is a social selling and employee tool to help businesses amplify their content through their people.",
    "category": "Social Media Marketing",
    "title": "Curatebase Integration With Cold Email Sales Tool",
    "h1": "Integrate Curatebase with SalesBlink",
    "metaDescription": "Integrate Curatebase and other Social Media Marketing Tools with SalesBlink. Two Way Curatebase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "webautomation.io",
    "slug": "webautomationio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d1cd07bffebeb9984b4b608873cc0be_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Quickly extract data from websites and turn into APIs or Spreadsheets without writing any code using ready-made scrapers",
    "category": "Developer Tools",
    "title": "webautomation.io Integration With Cold Email Sales Tool",
    "h1": "Integrate webautomation.io with SalesBlink",
    "metaDescription": "Integrate webautomation.io and other Developer Tools Tools with SalesBlink. Two Way webautomation.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "UserVitals",
    "slug": "uservitals",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c36e18f0fa9ca497312a408a5e2471fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UserVitals helps track customer feedback and manage your roadmap",
    "category": "Product Management",
    "title": "UserVitals Integration With Cold Email Sales Tool",
    "h1": "Integrate UserVitals with SalesBlink",
    "metaDescription": "Integrate UserVitals and other Product Management Tools with SalesBlink. Two Way UserVitals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anywhere",
    "slug": "anywhere",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/756465a7fabea14da87b45ae4a15878a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anywhere helps you organize your work, automate repetitive tasks, and helps you deliver your projects on time.",
    "category": "Project Management",
    "title": "Anywhere Integration With Cold Email Sales Tool",
    "h1": "Integrate Anywhere with SalesBlink",
    "metaDescription": "Integrate Anywhere and other Project Management Tools with SalesBlink. Two Way Anywhere Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blue Social",
    "slug": "blue-social",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b1be99fe66e07e27a94a77648f964e3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blue helps professionals and businesses build and grow their networks using Bluetooth, NFC and digital business cards.",
    "category": "Contact Management",
    "title": "Blue Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Blue Social with SalesBlink",
    "metaDescription": "Integrate Blue Social and other Contact Management Tools with SalesBlink. Two Way Blue Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Burb",
    "slug": "burb",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/abac11a98d906c27dbc05afa424a2594_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Burb is a tool that helps to optimize, support, and grow communities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Burb Integration With Cold Email Sales Tool",
    "h1": "Integrate Burb with SalesBlink",
    "metaDescription": "Integrate Burb and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Burb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Expensya",
    "slug": "expensya",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0d31923b4b1d1b310066ac3e6823807d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Expensya is a Web and Mobile solution that automates the management of expense reports for professionals.",
    "category": "Accounting",
    "title": "Expensya Integration With Cold Email Sales Tool",
    "h1": "Integrate Expensya with SalesBlink",
    "metaDescription": "Integrate Expensya and other Accounting Tools with SalesBlink. Two Way Expensya Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Talenteria",
    "slug": "talenteria",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8e91b49576f33dac21da3c7bb5367598.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A New Generation Career Site Builder and Recruitment Marketing Platform",
    "category": "Website Builders",
    "title": "Talenteria Integration With Cold Email Sales Tool",
    "h1": "Integrate Talenteria with SalesBlink",
    "metaDescription": "Integrate Talenteria and other Website Builders Tools with SalesBlink. Two Way Talenteria Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vista One",
    "slug": "vista-one",
    "logo": "https://zapier-images.imgix.net/storage/services/32c840bd6b3ca9f1aadf52bdf1b988b2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vista One by Ambry Hill Technologies is Real-Time Data Resource Exchange Across Different ODBC Compliant Databases",
    "category": "Databases",
    "title": "Vista One Integration With Cold Email Sales Tool",
    "h1": "Integrate Vista One with SalesBlink",
    "metaDescription": "Integrate Vista One and other Databases Tools with SalesBlink. Two Way Vista One Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buzzvid",
    "slug": "buzzvid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c77136ed560c34b3516437f01afefcb6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Convert text into fully animated videos with graphics and voice-over.",
    "category": "Video & Audio",
    "title": "Buzzvid Integration With Cold Email Sales Tool",
    "h1": "Integrate Buzzvid with SalesBlink",
    "metaDescription": "Integrate Buzzvid and other Video & Audio Tools with SalesBlink. Two Way Buzzvid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Porterhouse",
    "slug": "porterhouse",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0ea6d6aef3d471a8e2876a64662e5ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Porterhouse is the only bulk texting solution that sends iMessages to your entire list with a single click, from your own phone number.",
    "category": "Phone & SMS",
    "title": "Porterhouse Integration With Cold Email Sales Tool",
    "h1": "Integrate Porterhouse with SalesBlink",
    "metaDescription": "Integrate Porterhouse and other Phone & SMS Tools with SalesBlink. Two Way Porterhouse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CallPaaS",
    "slug": "callpaas",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3ea8bc67a628c6891ed9a0fecfd7e8b9_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallPaaS is a cloud-based telephony solution for businesses of all sizes.",
    "category": "Customer Support",
    "title": "CallPaaS Integration With Cold Email Sales Tool",
    "h1": "Integrate CallPaaS with SalesBlink",
    "metaDescription": "Integrate CallPaaS and other Customer Support Tools with SalesBlink. Two Way CallPaaS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blipboard",
    "slug": "blipboard",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be470f9747a351b8d8ac48e129d598f8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blipboard is a unique event display that gives makes the recent past accessible at a glance.",
    "category": "Dashboards",
    "title": "Blipboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Blipboard with SalesBlink",
    "metaDescription": "Integrate Blipboard and other Dashboards Tools with SalesBlink. Two Way Blipboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tactful UCX",
    "slug": "tactful-ucx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/057adba95ad9cb7d54d45568b10fcde6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tactful Unified Cognitive Customer Experience Platform is the next generation omnichannel customer experience technology that helps your business achieve the best results across touchpoints using AI and data centric approach.",
    "category": "Customer Support",
    "title": "Tactful UCX Integration With Cold Email Sales Tool",
    "h1": "Integrate Tactful UCX with SalesBlink",
    "metaDescription": "Integrate Tactful UCX and other Customer Support Tools with SalesBlink. Two Way Tactful UCX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuoteRules",
    "slug": "quoterules",
    "logo": "https://zapier-images.imgix.net/storage/services/08d7057cba5a81f797f7e4927de785fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smart quote generator tool for Fast, Accurate, and Easy Project Quotation.",
    "category": "Proposal & Invoice Management",
    "title": "QuoteRules Integration With Cold Email Sales Tool",
    "h1": "Integrate QuoteRules with SalesBlink",
    "metaDescription": "Integrate QuoteRules and other Proposal & Invoice Management Tools with SalesBlink. Two Way QuoteRules Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KloudLearn",
    "slug": "kloudlearn",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87e21c30b87fd8e008ae47fb14a1ea21.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KloudLearn is focused at delivering organizations with NextGen learning solutions to help organizations gain strategic market advantage.",
    "category": "Education",
    "title": "KloudLearn Integration With Cold Email Sales Tool",
    "h1": "Integrate KloudLearn with SalesBlink",
    "metaDescription": "Integrate KloudLearn and other Education Tools with SalesBlink. Two Way KloudLearn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Palzin Feedback",
    "slug": "palzin-feedback",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0c836ca06d83093ae01a7d82ff48cd24.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Palzin Feedback Platform is a simple yet powerful tool to collect customer feedback and build better products.",
    "category": "Product Management",
    "title": "Palzin Feedback Integration With Cold Email Sales Tool",
    "h1": "Integrate Palzin Feedback with SalesBlink",
    "metaDescription": "Integrate Palzin Feedback and other Product Management Tools with SalesBlink. Two Way Palzin Feedback Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tabby Data",
    "slug": "tabby-data",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40dd251c9aa92f7119cbdc706ac83605.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tabby is a no-fuss data warehouse and querying tool for startups.",
    "category": "Business Intelligence",
    "title": "Tabby Data Integration With Cold Email Sales Tool",
    "h1": "Integrate Tabby Data with SalesBlink",
    "metaDescription": "Integrate Tabby Data and other Business Intelligence Tools with SalesBlink. Two Way Tabby Data Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Digit.ink",
    "slug": "digitink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ca4a7e2699113d69d0ef93f0b1bf991.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digit.ink is an easy-to-use SaaS for issuing beautiful, shareable blockchain certificates to represent skills and achievements.",
    "category": "Education",
    "title": "Digit.ink Integration With Cold Email Sales Tool",
    "h1": "Integrate Digit.ink with SalesBlink",
    "metaDescription": "Integrate Digit.ink and other Education Tools with SalesBlink. Two Way Digit.ink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LaunchSoft",
    "slug": "launchsoft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e226875d17de28c764f7226d12a3cd83_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The LaunchSoft allows integration with certain platforms by returning metrics to the user",
    "category": "Ads & Conversion",
    "title": "LaunchSoft Integration With Cold Email Sales Tool",
    "h1": "Integrate LaunchSoft with SalesBlink",
    "metaDescription": "Integrate LaunchSoft and other Ads & Conversion Tools with SalesBlink. Two Way LaunchSoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GlowLocal",
    "slug": "glowlocal",
    "logo": "https://zapier-images.imgix.net/storage/services/83a4310aaadcf112d414d88ab39ef54e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GlowLocal helps law firms get the reviews they deserve through review automation and monitoring.",
    "category": "Social Media Marketing",
    "title": "GlowLocal Integration With Cold Email Sales Tool",
    "h1": "Integrate GlowLocal with SalesBlink",
    "metaDescription": "Integrate GlowLocal and other Social Media Marketing Tools with SalesBlink. Two Way GlowLocal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Service Objects",
    "slug": "service-objects-dots-address-val",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e8abac18fb208c05e55a05d1c35270a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Service Objects is a contact and data validation company.",
    "category": "Developer Tools",
    "title": "Service Objects Integration With Cold Email Sales Tool",
    "h1": "Integrate Service Objects with SalesBlink",
    "metaDescription": "Integrate Service Objects and other Developer Tools Tools with SalesBlink. Two Way Service Objects Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swing Viral",
    "slug": "swing-viral",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5ef452ce82966069c2315a671db30886.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swing Viral is a lead generation app that powers simple, viral campaigns.",
    "category": "Marketing",
    "title": "Swing Viral Integration With Cold Email Sales Tool",
    "h1": "Integrate Swing Viral with SalesBlink",
    "metaDescription": "Integrate Swing Viral and other Marketing Tools with SalesBlink. Two Way Swing Viral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Product List Genie",
    "slug": "product-list-genie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2e71482a6c6836b0c74a4ccce8ca7706_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Product List Genie is a Leads integration to connect on a vast library of connected apps to automate workflow.",
    "category": "eCommerce",
    "title": "Product List Genie Integration With Cold Email Sales Tool",
    "h1": "Integrate Product List Genie with SalesBlink",
    "metaDescription": "Integrate Product List Genie and other eCommerce Tools with SalesBlink. Two Way Product List Genie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProspectReach",
    "slug": "prospectreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9d5c5822dbe7799b2b72e67b6dcd9895.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProspectReach is a saas-based cold email marketing application.",
    "category": "Business Intelligence",
    "title": "ProspectReach Integration With Cold Email Sales Tool",
    "h1": "Integrate ProspectReach with SalesBlink",
    "metaDescription": "Integrate ProspectReach and other Business Intelligence Tools with SalesBlink. Two Way ProspectReach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Graphy",
    "slug": "graphy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a1828d4535cdc71478696cd8df34943f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Graphy helps you grow your audience, monetize your skills, and host live cohort-based courses.",
    "category": "Education",
    "title": "Graphy Integration With Cold Email Sales Tool",
    "h1": "Integrate Graphy with SalesBlink",
    "metaDescription": "Integrate Graphy and other Education Tools with SalesBlink. Two Way Graphy Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "BugReplay",
    "slug": "bugreplay",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/29f628461b448e43cce7191ef7dd6ba4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BugReplay gives you all the data you need to diagnose and fix issues.",
    "category": "Developer Tools",
    "title": "BugReplay Integration With Cold Email Sales Tool",
    "h1": "Integrate BugReplay with SalesBlink",
    "metaDescription": "Integrate BugReplay and other Developer Tools Tools with SalesBlink. Two Way BugReplay Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AB.GL",
    "slug": "abgl",
    "logo": "https://zapier-images.imgix.net/storage/services/4b63793ee5d5d53ff181957d0ea6498e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AB.GL is a link shortener platform that allows custom overlays and domains.",
    "category": "URL Shortener",
    "title": "AB.GL Integration With Cold Email Sales Tool",
    "h1": "Integrate AB.GL with SalesBlink",
    "metaDescription": "Integrate AB.GL and other URL Shortener Tools with SalesBlink. Two Way AB.GL Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TwentyThree Personal",
    "slug": "twentythree-personal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd638a50d0b03243a7c99eef00ba311e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TwentyThree Personal is a video marketing platform with comprehensive capabilities to engage your audience, create an on-brand experience, scale to fit you as you grow and provide analytics to help you drive better results.",
    "category": "Video & Audio",
    "title": "TwentyThree Personal Integration With Cold Email Sales Tool",
    "h1": "Integrate TwentyThree Personal with SalesBlink",
    "metaDescription": "Integrate TwentyThree Personal and other Video & Audio Tools with SalesBlink. Two Way TwentyThree Personal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "innos",
    "slug": "innos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53eba366bbb779ae95a765fa14417d77.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Innos Note is a knowledge garden that empowers creators to express thoughts and discover new knowledge. It equips curious minds with the right content creation tools to inspire and connect ideas.",
    "category": "Team Collaboration",
    "title": "innos Integration With Cold Email Sales Tool",
    "h1": "Integrate innos with SalesBlink",
    "metaDescription": "Integrate innos and other Team Collaboration Tools with SalesBlink. Two Way innos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Audiogram",
    "slug": "audiogram",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0549a7c2187109f4d1cfac4d8cbd79c7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Audiogram converts your audio files into beautiful videos. It's popular with podcasters, marketers, and anyone who wants their audio to get more reach.",
    "category": "Video & Audio",
    "title": "Audiogram Integration With Cold Email Sales Tool",
    "h1": "Integrate Audiogram with SalesBlink",
    "metaDescription": "Integrate Audiogram and other Video & Audio Tools with SalesBlink. Two Way Audiogram Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Create",
    "slug": "create-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/314a1b26b9097c2ea3d3dee9e7c40c49_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create is a simple and effective page editor you can make beautiful pages in minutes. Various tools and integrations also can help you provide the best experience to your page visitors.",
    "category": "Website Builders",
    "title": "Create Integration With Cold Email Sales Tool",
    "h1": "Integrate Create with SalesBlink",
    "metaDescription": "Integrate Create and other Website Builders Tools with SalesBlink. Two Way Create Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grit Seed",
    "slug": "grit-seed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ddb509bd3520b2b081cac2610a1237a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grit Seed is a conversational texting engine.",
    "category": "Phone & SMS",
    "title": "Grit Seed Integration With Cold Email Sales Tool",
    "h1": "Integrate Grit Seed with SalesBlink",
    "metaDescription": "Integrate Grit Seed and other Phone & SMS Tools with SalesBlink. Two Way Grit Seed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Accompa",
    "slug": "accompa-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f825d7f2e8ed9c1242ede2cba0bee308_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Accompa is a leading cloud-based Requirements Management tool used by hundreds of companies.",
    "category": "Product Management",
    "title": "Accompa Integration With Cold Email Sales Tool",
    "h1": "Integrate Accompa with SalesBlink",
    "metaDescription": "Integrate Accompa and other Product Management Tools with SalesBlink. Two Way Accompa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WinSMS International",
    "slug": "winsms-international",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/68c9de332c36c51402dbf18e7122cf34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WinSMS is a B2B bulk SMS messaging gateway which makes it quick and easy for businesses wishing to integrate SMS messaging into their workflow and business processes.",
    "category": "Phone & SMS",
    "title": "WinSMS International Integration With Cold Email Sales Tool",
    "h1": "Integrate WinSMS International with SalesBlink",
    "metaDescription": "Integrate WinSMS International and other Phone & SMS Tools with SalesBlink. Two Way WinSMS International Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrackZero",
    "slug": "trackzero",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9c1cdf6e05b4d9f0e74be0034c5028b7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TrackZero is an analytics platform that allows you to analyze data and gain insights on your workloads without complex setup and time consuming development and management.",
    "category": "Analytics",
    "title": "TrackZero Integration With Cold Email Sales Tool",
    "h1": "Integrate TrackZero with SalesBlink",
    "metaDescription": "Integrate TrackZero and other Analytics Tools with SalesBlink. Two Way TrackZero Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Winoffice Prime",
    "slug": "winoffice-prime",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4eea5d1a83a606b221ba6f3d2d290116.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Winoffice Prime is a comprehensive software platform for the automation and digitalization of small and medium-sized enterprises in the fields of facility services, project services and trade.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Winoffice Prime Integration With Cold Email Sales Tool",
    "h1": "Integrate Winoffice Prime with SalesBlink",
    "metaDescription": "Integrate Winoffice Prime and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Winoffice Prime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Authvia",
    "slug": "authvia",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c91cca22d7e899106495efc348c1e16c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Authvia allows you to send and receive payments through simple text requests.",
    "category": "Payment Processing",
    "title": "Authvia Integration With Cold Email Sales Tool",
    "h1": "Integrate Authvia with SalesBlink",
    "metaDescription": "Integrate Authvia and other Payment Processing Tools with SalesBlink. Two Way Authvia Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "converts.online",
    "slug": "convertsonline",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3a76378272acc6fd9c7a2f768885737.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "converts.online is an easy online API convertor that is able to convert files, URLs and raw HTML pages to multiple formats like PDF, PNG, JPEG and many more.",
    "category": "Content & Files",
    "title": "converts.online Integration With Cold Email Sales Tool",
    "h1": "Integrate converts.online with SalesBlink",
    "metaDescription": "Integrate converts.online and other Content & Files Tools with SalesBlink. Two Way converts.online Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "V-Unite",
    "slug": "v-unite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7158511c383dd63d7f76d0083174c3e4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "V-Unite is an interactive, self-service, virtual event and experience platform.",
    "category": "Event Management",
    "title": "V-Unite Integration With Cold Email Sales Tool",
    "h1": "Integrate V-Unite with SalesBlink",
    "metaDescription": "Integrate V-Unite and other Event Management Tools with SalesBlink. Two Way V-Unite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CommercioNetwork",
    "slug": "commercionetwork",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/493e01f222488e264b520ce21e7e32b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eIDAS Compliant Legally Binding Blockchain. 100% Open-source! SDK availble in Java, C#, Dart & GO. Alternatively fully managed APIs to let you develop any blockchain project in a fraction of time with €0.01 Stable Gas Fees for any transaction.",
    "category": "Content & Files",
    "title": "CommercioNetwork Integration With Cold Email Sales Tool",
    "h1": "Integrate CommercioNetwork with SalesBlink",
    "metaDescription": "Integrate CommercioNetwork and other Content & Files Tools with SalesBlink. Two Way CommercioNetwork Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ThreadKore",
    "slug": "threadkore",
    "logo": "https://zapier-images.imgix.net/storage/services/fd9e39e77397c4df885fa590af10cfea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ThreadKore is a centralized collection of applications intended for the management and facilitation of numerous business operations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ThreadKore Integration With Cold Email Sales Tool",
    "h1": "Integrate ThreadKore with SalesBlink",
    "metaDescription": "Integrate ThreadKore and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ThreadKore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Merci facteur Pro",
    "slug": "merci-facteur-pro",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8bde42da668febf7eeda293b05e96f60.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send mail using La Poste! With us you can also send postcards, pictures, registered packages and much more!",
    "category": "Printing",
    "title": "Merci facteur Pro Integration With Cold Email Sales Tool",
    "h1": "Integrate Merci facteur Pro with SalesBlink",
    "metaDescription": "Integrate Merci facteur Pro and other Printing Tools with SalesBlink. Two Way Merci facteur Pro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Schedule To Call",
    "slug": "schedule-to-call",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0478997c1e0cff5219c22bba272b2e83.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Schedule To Call provides an automatic call scheduling solution for companies.",
    "category": "Sales & CRM",
    "title": "Schedule To Call Integration With Cold Email Sales Tool",
    "h1": "Integrate Schedule To Call with SalesBlink",
    "metaDescription": "Integrate Schedule To Call and other Sales & CRM Tools with SalesBlink. Two Way Schedule To Call Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Redtie",
    "slug": "redtie",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e09a0273c78c4a4b45a0ec1abbf5f134.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Redtie is an advanced, feature rich text messaging solution that lets you send attachments just like you would in an email. Businesses can share any type of files over a simple text message to their customers or contacts over a text message.",
    "category": "Phone & SMS",
    "title": "Redtie Integration With Cold Email Sales Tool",
    "h1": "Integrate Redtie with SalesBlink",
    "metaDescription": "Integrate Redtie and other Phone & SMS Tools with SalesBlink. Two Way Redtie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadiful",
    "slug": "leadiful",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e8689621d85792ade188e26fcc6edf3d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Leadiful  helps you increase your sales and turn your leads into customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Leadiful Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadiful with SalesBlink",
    "metaDescription": "Integrate Leadiful and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Leadiful Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QVALON",
    "slug": "qvalon",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b7e033167b891a6f2795351f99ef58b8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QVALON is a cloud-based solution to manage, monitor and magnify your retail business.",
    "category": "Forms & Surveys",
    "title": "QVALON Integration With Cold Email Sales Tool",
    "h1": "Integrate QVALON with SalesBlink",
    "metaDescription": "Integrate QVALON and other Forms & Surveys Tools with SalesBlink. Two Way QVALON Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Thinkspace",
    "slug": "thinkspace-integration",
    "logo": "https://zapier-images.imgix.net/storage/services/174e50ddec17f5be1ff24458e4db8b34.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thinkspace is a cloud-based innovation platform to collect your team's best ideas anytime, anywhere.",
    "category": "Communication",
    "title": "Thinkspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Thinkspace with SalesBlink",
    "metaDescription": "Integrate Thinkspace and other Communication Tools with SalesBlink. Two Way Thinkspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orangescrum",
    "slug": "orangescrum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e4197dd610feba394cff8ea349948780_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Orangescrum is a project management tool to organize tasks projects and users and keep everything on track.",
    "category": "Project Management",
    "title": "Orangescrum Integration With Cold Email Sales Tool",
    "h1": "Integrate Orangescrum with SalesBlink",
    "metaDescription": "Integrate Orangescrum and other Project Management Tools with SalesBlink. Two Way Orangescrum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Copyfactory",
    "slug": "copyfactory",
    "logo": "https://zapier-images.imgix.net/storage/services/15e1ff2411dac5d290003473de0a5c33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Copyfactory helps sales teams spend less time writing cold email sequences and personalized first lines to increase response rates.",
    "category": "Sales & CRM",
    "title": "Copyfactory Integration With Cold Email Sales Tool",
    "h1": "Integrate Copyfactory with SalesBlink",
    "metaDescription": "Integrate Copyfactory and other Sales & CRM Tools with SalesBlink. Two Way Copyfactory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Locale",
    "slug": "locale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f1198c5abf915b4659e7cfc4b9fce863.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Locale is a tool that helps make your source code compatible and translate your content.",
    "category": "Transcription",
    "title": "Locale Integration With Cold Email Sales Tool",
    "h1": "Integrate Locale with SalesBlink",
    "metaDescription": "Integrate Locale and other Transcription Tools with SalesBlink. Two Way Locale Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wisyl",
    "slug": "wisyl",
    "logo": "https://zapier-images.imgix.net/storage/services/dfe90c1b9d72611298aa7de7e9cd0e85.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wisyl is a communication tool that lets people choose where and when they want to get important messages. When information needs to get to people who rely on you to run their businesses, distribute your product, organize, or do hundreds of other things, Wisyl delivers.",
    "category": "Communication",
    "title": "Wisyl Integration With Cold Email Sales Tool",
    "h1": "Integrate Wisyl with SalesBlink",
    "metaDescription": "Integrate Wisyl and other Communication Tools with SalesBlink. Two Way Wisyl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Introist",
    "slug": "introist",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c215aff81d850a7b351eb93da1031670.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Introist is a tool to create onboarding workflows to communicate and automate the checklists and busy work in employee onboarding.",
    "category": "HR Talent & Recruitment",
    "title": "Introist Integration With Cold Email Sales Tool",
    "h1": "Integrate Introist with SalesBlink",
    "metaDescription": "Integrate Introist and other HR Talent & Recruitment Tools with SalesBlink. Two Way Introist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swizio",
    "slug": "swizio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6cb7040ebed64c1c35d5e5d43ba29fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swizio is a smart networking platform that allows users to build a network using Swizio Profiles and Smart Devices.",
    "category": "Marketing",
    "title": "Swizio Integration With Cold Email Sales Tool",
    "h1": "Integrate Swizio with SalesBlink",
    "metaDescription": "Integrate Swizio and other Marketing Tools with SalesBlink. Two Way Swizio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inkit",
    "slug": "inkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e9e89a81d1005d8e8d6d5aa78bea6f92.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inkit is a reach enablement platform. It autocompletes and verifies addresses, automates customer campaigns, and manages paperless documents securely at scale.",
    "category": "Documents",
    "title": "Inkit Integration With Cold Email Sales Tool",
    "h1": "Integrate Inkit with SalesBlink",
    "metaDescription": "Integrate Inkit and other Documents Tools with SalesBlink. Two Way Inkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workflowsoft",
    "slug": "workflowsoft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1ec017de3257a3ce4d6d832d5d62612a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workflowsoft is a work management solution to automate, manage, and track your business processes.",
    "category": "Task Management",
    "title": "Workflowsoft Integration With Cold Email Sales Tool",
    "h1": "Integrate Workflowsoft with SalesBlink",
    "metaDescription": "Integrate Workflowsoft and other Task Management Tools with SalesBlink. Two Way Workflowsoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MemberSuite",
    "slug": "membersuite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99069bacc64d7b31858d59e494590ff6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MemberSuite provides a CRM platform to keep the contact information of all your valuable members, donors, and prospects in one place. MemberSuite makes it easy to keep track of the people that allow your organization to shine.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MemberSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate MemberSuite with SalesBlink",
    "metaDescription": "Integrate MemberSuite and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MemberSuite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ONBORD",
    "slug": "onbord-demo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ecc593a2496ce514340413861b280ddb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital onboarding flow",
    "category": "CRM (Customer Relationship Management)",
    "title": "ONBORD Integration With Cold Email Sales Tool",
    "h1": "Integrate ONBORD with SalesBlink",
    "metaDescription": "Integrate ONBORD and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ONBORD Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Filerobot",
    "slug": "filerobot-dam",
    "logo": "https://zapier-images.imgix.net/storage/services/0e6acc75d59e4faabe07bd7ce0d2af18.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Filerobot is a Digital Asset Management platform that acts as source-of-truth for all media assets within your company.",
    "category": "Content & Files",
    "title": "Filerobot Integration With Cold Email Sales Tool",
    "h1": "Integrate Filerobot with SalesBlink",
    "metaDescription": "Integrate Filerobot and other Content & Files Tools with SalesBlink. Two Way Filerobot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Twayobiz",
    "slug": "twayobiz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/de13abc54eb4e530f00ec903bac580ef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twayobiz is your file storage base meant for business.",
    "category": "File Management & Storage",
    "title": "Twayobiz Integration With Cold Email Sales Tool",
    "h1": "Integrate Twayobiz with SalesBlink",
    "metaDescription": "Integrate Twayobiz and other File Management & Storage Tools with SalesBlink. Two Way Twayobiz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CXL Playbooks",
    "slug": "cxl-playbooks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/95ae16be292bcdaa090024dd54dcd3d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Learn in 10 minutes, implement in the next 10 mins.",
    "category": "Marketing",
    "title": "CXL Playbooks Integration With Cold Email Sales Tool",
    "h1": "Integrate CXL Playbooks with SalesBlink",
    "metaDescription": "Integrate CXL Playbooks and other Marketing Tools with SalesBlink. Two Way CXL Playbooks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Review Judge",
    "slug": "review-judge",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b49659c63c0f9ef27aef441702799c04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Getting clients to leave 5-star reviews for your law firm has never been easier. Take the work out of following up with your clients with friendly automatic reminders. And don’t worry, Review Judge prevents negative reviews, too.",
    "category": "Reviews",
    "title": "Review Judge Integration With Cold Email Sales Tool",
    "h1": "Integrate Review Judge with SalesBlink",
    "metaDescription": "Integrate Review Judge and other Reviews Tools with SalesBlink. Two Way Review Judge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boltrends",
    "slug": "boltrends",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/17a863d7f55492895aa03aab789424ec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boltrends is a bol.com analytics tool which helps sellers to grow their business.",
    "category": "Analytics",
    "title": "Boltrends Integration With Cold Email Sales Tool",
    "h1": "Integrate Boltrends with SalesBlink",
    "metaDescription": "Integrate Boltrends and other Analytics Tools with SalesBlink. Two Way Boltrends Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OppBot",
    "slug": "oppbot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6819ec6dd75fa16ab01bf4efcef794fe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OppBot is an AI assistant for demand generation teams that converts your underserved leads into sales-ready opportunities for your sales team.",
    "category": "Marketing Automation",
    "title": "OppBot Integration With Cold Email Sales Tool",
    "h1": "Integrate OppBot with SalesBlink",
    "metaDescription": "Integrate OppBot and other Marketing Automation Tools with SalesBlink. Two Way OppBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Miho",
    "slug": "miho",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7dcac1aec9db4513152edeac6d915145.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Miho helps knowledge entrepreneurs to create, grow & manage their online businesses.",
    "category": "Education",
    "title": "Miho Integration With Cold Email Sales Tool",
    "h1": "Integrate Miho with SalesBlink",
    "metaDescription": "Integrate Miho and other Education Tools with SalesBlink. Two Way Miho Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Eventleaf",
    "slug": "eventleaf",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61bfc316311ad5d8008673459002797f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventleaf is a simple, modern and affordable online event management platform with online event registration and mobile event apps for supporting in-person, hybrid and virtual events.",
    "category": "Event Management",
    "title": "Eventleaf Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventleaf with SalesBlink",
    "metaDescription": "Integrate Eventleaf and other Event Management Tools with SalesBlink. Two Way Eventleaf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verifyd",
    "slug": "verifyd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c6a4e890f31a1da4b33d7e84c1106d56_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verify your documents with ease and check its validity in matter of seconds",
    "category": "Documents",
    "title": "Verifyd Integration With Cold Email Sales Tool",
    "h1": "Integrate Verifyd with SalesBlink",
    "metaDescription": "Integrate Verifyd and other Documents Tools with SalesBlink. Two Way Verifyd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swarmtix",
    "slug": "swarmtix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fdfe535897834fa654790bb1a7f4352_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The simplest event ticketing platform. Create, promote and sell your events in 10 minutes.",
    "category": "Event Management",
    "title": "Swarmtix Integration With Cold Email Sales Tool",
    "h1": "Integrate Swarmtix with SalesBlink",
    "metaDescription": "Integrate Swarmtix and other Event Management Tools with SalesBlink. Two Way Swarmtix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superlative",
    "slug": "superlative",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4f6ace5f0481c73a4780f20755374f2a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lead Generating Real Estate Websites for Realtors and Brokers.",
    "category": "App Builder",
    "title": "Superlative Integration With Cold Email Sales Tool",
    "h1": "Integrate Superlative with SalesBlink",
    "metaDescription": "Integrate Superlative and other App Builder Tools with SalesBlink. Two Way Superlative Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EH CRM",
    "slug": "eh-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d5eabb2e1d7bbdcf141d6b8131986846.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EH CRM is a lead CRM made proudly in Mexico. It helps you improve sales pipelines",
    "category": "CRM (Customer Relationship Management)",
    "title": "EH CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate EH CRM with SalesBlink",
    "metaDescription": "Integrate EH CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way EH CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brevy",
    "slug": "brevy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b1cfca2470ddbc5263f3f6444a701d2e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brevy is a Chrome extension that allows your team to easily report bugs and feedback directly on your product.",
    "category": "Developer Tools",
    "title": "Brevy Integration With Cold Email Sales Tool",
    "h1": "Integrate Brevy with SalesBlink",
    "metaDescription": "Integrate Brevy and other Developer Tools Tools with SalesBlink. Two Way Brevy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Centiment",
    "slug": "centiment",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/441035a86aa5743083c0a2f2c50176f1_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Centiment is purpose-built to simplify feedback. Build a survey in minutes to gather responses from your customers and employees or target respondents in 100+ countries using Centiment's Audience Panel.",
    "category": "Forms & Surveys",
    "title": "Centiment Integration With Cold Email Sales Tool",
    "h1": "Integrate Centiment with SalesBlink",
    "metaDescription": "Integrate Centiment and other Forms & Surveys Tools with SalesBlink. Two Way Centiment Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CR Self Booking Reminder",
    "slug": "self-book",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53984ee061e6ee2eabef8f05c71e3b14_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CR Self Booking Reminder is part of the Contact Reach software.",
    "category": "Scheduling & Booking",
    "title": "CR Self Booking Reminder Integration With Cold Email Sales Tool",
    "h1": "Integrate CR Self Booking Reminder with SalesBlink",
    "metaDescription": "Integrate CR Self Booking Reminder and other Scheduling & Booking Tools with SalesBlink. Two Way CR Self Booking Reminder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zone5app",
    "slug": "zone5app",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/690b5c7a10821e054fc46e770ede08a2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "This app is used for handling the team like we can handle the team or get the Analytics of the team with the help of the zone5app.",
    "category": "Analytics",
    "title": "zone5app Integration With Cold Email Sales Tool",
    "h1": "Integrate zone5app with SalesBlink",
    "metaDescription": "Integrate zone5app and other Analytics Tools with SalesBlink. Two Way zone5app Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailazy",
    "slug": "mailazy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a8187e1a0b6e4828c77ff48d218ec939.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailazy is an email service provider focused exclusively on transactional email delivery. When you connect to Mailazy, you can automatically send emails for payments confirmation, purchase confirmation, onboarding, and more.",
    "category": "Email",
    "title": "Mailazy Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailazy with SalesBlink",
    "metaDescription": "Integrate Mailazy and other Email Tools with SalesBlink. Two Way Mailazy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smally Link",
    "slug": "smally-link",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a67f5d847298c51c31166eec3528fa7f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A Free Link Shortening and Management system that caters to businesses on a budget. Don’t leave your lengthy URLs like that, #SmallyIt! and transform your user reach with us!",
    "category": "URL Shortener",
    "title": "Smally Link Integration With Cold Email Sales Tool",
    "h1": "Integrate Smally Link with SalesBlink",
    "metaDescription": "Integrate Smally Link and other URL Shortener Tools with SalesBlink. Two Way Smally Link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ella Interactive",
    "slug": "ella-interactive",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a199ffefdb6153cb4f38928b83d97f6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ella Interactive is a software tool for business owners, freelancers, ad agencies, and enterprises looking to skyrocket their digital marketing results.",
    "category": "Marketing",
    "title": "Ella Interactive Integration With Cold Email Sales Tool",
    "h1": "Integrate Ella Interactive with SalesBlink",
    "metaDescription": "Integrate Ella Interactive and other Marketing Tools with SalesBlink. Two Way Ella Interactive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TenAnts",
    "slug": "tenants",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79d902a4b27fb1ecd6ab61c000b0764b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TenAnts is an online SAAS platform that enables partners to access everything needed to offer utility connections.",
    "category": "CRM (Customer Relationship Management)",
    "title": "TenAnts Integration With Cold Email Sales Tool",
    "h1": "Integrate TenAnts with SalesBlink",
    "metaDescription": "Integrate TenAnts and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way TenAnts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Enki",
    "slug": "enki",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5aa661c399d6701ca38973cdb2b42477.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Persona Intelligence to improve the conversion rate and accelerate the sales cycle",
    "category": "Business Intelligence",
    "title": "Enki Integration With Cold Email Sales Tool",
    "h1": "Integrate Enki with SalesBlink",
    "metaDescription": "Integrate Enki and other Business Intelligence Tools with SalesBlink. Two Way Enki Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WebAsk",
    "slug": "webask",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3c44cc5fea226da82348981209a7f1df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create your own survey or form, get feedback from clients and teammates. It's free!",
    "category": "Forms & Surveys",
    "title": "WebAsk Integration With Cold Email Sales Tool",
    "h1": "Integrate WebAsk with SalesBlink",
    "metaDescription": "Integrate WebAsk and other Forms & Surveys Tools with SalesBlink. Two Way WebAsk Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "TextKit",
    "slug": "textkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bceb09ca0ff7991d1a807184f5bdebd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextKit provides ready-to-use APIs for Natural Language Processing and Text Analysis without the hassles of running your own models",
    "category": "Developer Tools",
    "title": "TextKit Integration With Cold Email Sales Tool",
    "h1": "Integrate TextKit with SalesBlink",
    "metaDescription": "Integrate TextKit and other Developer Tools Tools with SalesBlink. Two Way TextKit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sensei Labs Conductor",
    "slug": "sensei-labs-conductor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94c3dc547fdb21116394f8c43f94da3e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sensei Labs Conductor provides project/portfolio, collaborative work, data/KPI, and knowledge management in an intuitive, easy to use solution for orchestrating your most critical initiatives",
    "category": "Productivity",
    "title": "Sensei Labs Conductor Integration With Cold Email Sales Tool",
    "h1": "Integrate Sensei Labs Conductor with SalesBlink",
    "metaDescription": "Integrate Sensei Labs Conductor and other Productivity Tools with SalesBlink. Two Way Sensei Labs Conductor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shindig",
    "slug": "shindig",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8a57688f6bf85db166f561d7b85a7b72.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shindig is a virtual events app, with \"work the room\" networking for attendees to mingle or for staff to confer privately with participants, a virtual backstage for preparing guests, and more.",
    "category": "Video Conferencing",
    "title": "Shindig Integration With Cold Email Sales Tool",
    "h1": "Integrate Shindig with SalesBlink",
    "metaDescription": "Integrate Shindig and other Video Conferencing Tools with SalesBlink. Two Way Shindig Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mattermix",
    "slug": "mattermix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e0580481f20960baf9a2217c4be0db0d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mattermix automates image creation with a drag-and-drop design editor and API.",
    "category": "Images & Design",
    "title": "Mattermix Integration With Cold Email Sales Tool",
    "h1": "Integrate Mattermix with SalesBlink",
    "metaDescription": "Integrate Mattermix and other Images & Design Tools with SalesBlink. Two Way Mattermix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "cube-usa",
    "slug": "cube-usa",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7e84832af201cf2f8a919cfad42373bf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cube is comprehensive business management software for the building automation controls (BAC) industry. Developed by controls contractors for controls contractors, Cube reduces paperwork and costs while streamlining efficiency and boosting revenue.",
    "category": "Proposal & Invoice Management",
    "title": "cube-usa Integration With Cold Email Sales Tool",
    "h1": "Integrate cube-usa with SalesBlink",
    "metaDescription": "Integrate cube-usa and other Proposal & Invoice Management Tools with SalesBlink. Two Way cube-usa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShinyStat",
    "slug": "shinystat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f879ac6a00e6647dc7505c08c42654d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShinyStat provides marketing automation and data management products.",
    "category": "Analytics",
    "title": "ShinyStat Integration With Cold Email Sales Tool",
    "h1": "Integrate ShinyStat with SalesBlink",
    "metaDescription": "Integrate ShinyStat and other Analytics Tools with SalesBlink. Two Way ShinyStat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lender Launchpad",
    "slug": "lender-launchpad",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c490391262d117c5904c401e0bb61656.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lender Launchpad offers modern marketing tools for mortgage professionals.",
    "category": "Marketing",
    "title": "Lender Launchpad Integration With Cold Email Sales Tool",
    "h1": "Integrate Lender Launchpad with SalesBlink",
    "metaDescription": "Integrate Lender Launchpad and other Marketing Tools with SalesBlink. Two Way Lender Launchpad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dastra",
    "slug": "dastra",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5877fa054e5523ca0a36cf9b1036764.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dastra is a simple and guided data protection solution to make your entire organization accountable for risks around personal data. Simplify tasks and make sure they meet compliance standards.",
    "category": "Project Management",
    "title": "Dastra Integration With Cold Email Sales Tool",
    "h1": "Integrate Dastra with SalesBlink",
    "metaDescription": "Integrate Dastra and other Project Management Tools with SalesBlink. Two Way Dastra Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Talkative",
    "slug": "talkative",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1f97d963e2a17ba486a7807c574d246d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Talkative lets your customers contact you on the channel of their choice: video chat, live chat, chatbot, cobrowse and social media.",
    "category": "Customer Support",
    "title": "Talkative Integration With Cold Email Sales Tool",
    "h1": "Integrate Talkative with SalesBlink",
    "metaDescription": "Integrate Talkative and other Customer Support Tools with SalesBlink. Two Way Talkative Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClassDo",
    "slug": "classdo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/204039fb2328bd155ae323b82f3fa892.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClassDo is every educator's assistant for personalised learning, with online classrooms, tools and workflows for any environment.",
    "category": "Education",
    "title": "ClassDo Integration With Cold Email Sales Tool",
    "h1": "Integrate ClassDo with SalesBlink",
    "metaDescription": "Integrate ClassDo and other Education Tools with SalesBlink. Two Way ClassDo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KlimAPI",
    "slug": "klimapi",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cc4c49ab49cc078990a234cb5ef12fa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KlimAPI is a platform that enables you and your customers to offset the unavoidable CO2 emissions of the service or product you sell.",
    "category": "eCommerce",
    "title": "KlimAPI Integration With Cold Email Sales Tool",
    "h1": "Integrate KlimAPI with SalesBlink",
    "metaDescription": "Integrate KlimAPI and other eCommerce Tools with SalesBlink. Two Way KlimAPI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smarty Accounting",
    "slug": "smarty-accounting",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec992c669d03a8759838a00d3e0ff4b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Smarty Accounting is a comprehensive accounting and invoicing software for SMEs. The platform can monitor your ban account and remind clients about overdue payments, minimising the amount of accounting work you do.",
    "category": "Accounting",
    "title": "Smarty Accounting Integration With Cold Email Sales Tool",
    "h1": "Integrate Smarty Accounting with SalesBlink",
    "metaDescription": "Integrate Smarty Accounting and other Accounting Tools with SalesBlink. Two Way Smarty Accounting Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Endless Fairs",
    "slug": "endless-fairs",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4812d6bc0368444bad46a3d79fac5e17_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Endless Fairs is a platform which offers tailor-made solutions for expectations. Organize online creative activities to bring together visitors and participants from all over the world.",
    "category": "Event Management",
    "title": "Endless Fairs Integration With Cold Email Sales Tool",
    "h1": "Integrate Endless Fairs with SalesBlink",
    "metaDescription": "Integrate Endless Fairs and other Event Management Tools with SalesBlink. Two Way Endless Fairs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unifyy CRM",
    "slug": "unifyy-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/99e0c3989cd963c034209bada583e503.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unifyy is a sales CRM with a built-in dialer that unifies your sales communication, automates email and SMS followup, and much more!",
    "category": "CRM (Customer Relationship Management)",
    "title": "Unifyy CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Unifyy CRM with SalesBlink",
    "metaDescription": "Integrate Unifyy CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Unifyy CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Camhdk",
    "slug": "camhdk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c6d0631263b3e14c6e0b1d318c5323d6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Camhdk is a digital marketing tool to collect email addresses and post marketing messages using email/social media.",
    "category": "Email Newsletters",
    "title": "Camhdk Integration With Cold Email Sales Tool",
    "h1": "Integrate Camhdk with SalesBlink",
    "metaDescription": "Integrate Camhdk and other Email Newsletters Tools with SalesBlink. Two Way Camhdk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grorapid",
    "slug": "grorapid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/af0d657662d89395e94b316307c44ec6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grorapid is a B2B SAAS platform that helps businesses in marketing and growth using pre-made recipes and automation.",
    "category": "Marketing Automation",
    "title": "Grorapid Integration With Cold Email Sales Tool",
    "h1": "Integrate Grorapid with SalesBlink",
    "metaDescription": "Integrate Grorapid and other Marketing Automation Tools with SalesBlink. Two Way Grorapid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WarmProspect",
    "slug": "warmprospect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0e7caba230dd44c32eeb5afc60846f4b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WarmProspect is a digital marketing agency with an established track record of increasing small business growth.",
    "category": "CRM (Customer Relationship Management)",
    "title": "WarmProspect Integration With Cold Email Sales Tool",
    "h1": "Integrate WarmProspect with SalesBlink",
    "metaDescription": "Integrate WarmProspect and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way WarmProspect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pareto Security",
    "slug": "pareto-security",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/efb5afb3315a992ea2fa151cc05ca86c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pareto Security is an app that regularly checks your Mac's security configuration. It helps you take care of 20% of security tasks that prevent 80% of problems.",
    "category": "Devices",
    "title": "Pareto Security Integration With Cold Email Sales Tool",
    "h1": "Integrate Pareto Security with SalesBlink",
    "metaDescription": "Integrate Pareto Security and other Devices Tools with SalesBlink. Two Way Pareto Security Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clanica",
    "slug": "clanica",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/05901f92111a3aea156f8e4a8cfcb77b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clanica is a robust email delivery service, equipped with an easy to use SMTP service and Email API, enabling you and your business to send all kinds of emails, from transactional to newsletter and marketing emails.",
    "category": "Transactional Email",
    "title": "Clanica Integration With Cold Email Sales Tool",
    "h1": "Integrate Clanica with SalesBlink",
    "metaDescription": "Integrate Clanica and other Transactional Email Tools with SalesBlink. Two Way Clanica Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hey Boss",
    "slug": "hey-boss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a066d92c25739d2e294097d2b5cbde32.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Remote monitoring service for employees. Allows you to conveniently track the location of employees and receive real-time updates when changing locations.",
    "category": "Time Tracking Software",
    "title": "Hey Boss Integration With Cold Email Sales Tool",
    "h1": "Integrate Hey Boss with SalesBlink",
    "metaDescription": "Integrate Hey Boss and other Time Tracking Software Tools with SalesBlink. Two Way Hey Boss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "legodesk",
    "slug": "legodesk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2cd93d7ee54bc98eab3dd7bbc7c4078e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Legodesk enables lawyers and law firms to work faster and more efficiently. We streamline workflows, boost profits, and increase client satisfaction with our technology. We make practicing law effortless and swift.",
    "category": "Accounting",
    "title": "legodesk Integration With Cold Email Sales Tool",
    "h1": "Integrate legodesk with SalesBlink",
    "metaDescription": "Integrate legodesk and other Accounting Tools with SalesBlink. Two Way legodesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scope",
    "slug": "scope",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a5cbc3f40cd14cf51963b589eef94f90_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scope makes it easy to find and manage influencers.",
    "category": "Social Media Marketing",
    "title": "Scope Integration With Cold Email Sales Tool",
    "h1": "Integrate Scope with SalesBlink",
    "metaDescription": "Integrate Scope and other Social Media Marketing Tools with SalesBlink. Two Way Scope Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Encantrics",
    "slug": "encantrics",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5965b533a8b1ac8f59b9d8cdca636f8b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transforme milhares de dados não estruturados em insights para a empresa.\nTransform raw data into insights for your business.",
    "category": "Analytics",
    "title": "Encantrics Integration With Cold Email Sales Tool",
    "h1": "Integrate Encantrics with SalesBlink",
    "metaDescription": "Integrate Encantrics and other Analytics Tools with SalesBlink. Two Way Encantrics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DrivePilot",
    "slug": "drivepilot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ef74ae59aed6eb591929d174a2bc2a2a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Drivepilot app allows others to connect to the Drivepilot API and extract available teams. Furthermore, this app allows new users to be added to those teams.",
    "category": "Education",
    "title": "DrivePilot Integration With Cold Email Sales Tool",
    "h1": "Integrate DrivePilot with SalesBlink",
    "metaDescription": "Integrate DrivePilot and other Education Tools with SalesBlink. Two Way DrivePilot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CViewSurvey",
    "slug": "cviewsurvey",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19e36e0ba9d2ba068f732098a45de8b5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CViewSurvey is a SaaS-based Web & Mobile application that provides digital transformation to traditional paper surveys and feedback for customer & employee experience, field & market research that helps you evaluate your customer's as well as employee's loyalty.",
    "category": "Developer Tools",
    "title": "CViewSurvey Integration With Cold Email Sales Tool",
    "h1": "Integrate CViewSurvey with SalesBlink",
    "metaDescription": "Integrate CViewSurvey and other Developer Tools Tools with SalesBlink. Two Way CViewSurvey Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contact Boss",
    "slug": "contact-boss",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d8348719d1a05b390ddf62e5f4465635.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contact Boss is one of your best options for client management software, offering a better way to track your clients. Contact Boss is the best client management software for Small to Medium Size Businesses, Entrepreneurs, Volunteer or Service Organizations etc.",
    "category": "Contact Management",
    "title": "Contact Boss Integration With Cold Email Sales Tool",
    "h1": "Integrate Contact Boss with SalesBlink",
    "metaDescription": "Integrate Contact Boss and other Contact Management Tools with SalesBlink. Two Way Contact Boss Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Talk Hiring",
    "slug": "talk-hiring",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fa2773569ed73fbe4fa6179227df5ca2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Talk Hiring is an automated mock interviewing tool for career readiness programs.",
    "category": "Education",
    "title": "Talk Hiring Integration With Cold Email Sales Tool",
    "h1": "Integrate Talk Hiring with SalesBlink",
    "metaDescription": "Integrate Talk Hiring and other Education Tools with SalesBlink. Two Way Talk Hiring Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Surf for Brands",
    "slug": "surf-for-brands",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c74674ee8dbfc750b44fcd467436cef.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Surf for Brands provides the ability to collect, segment, and activate your first-party audience data",
    "category": "Marketing Automation",
    "title": "Surf for Brands Integration With Cold Email Sales Tool",
    "h1": "Integrate Surf for Brands with SalesBlink",
    "metaDescription": "Integrate Surf for Brands and other Marketing Automation Tools with SalesBlink. Two Way Surf for Brands Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ContactLink",
    "slug": "contactlink",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/405194ccb2cd8150b4e4d0000af68ec2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ContactLink puts all your communication options at one link: video call, video message, calendar, call, text, email.",
    "category": "Calendar",
    "title": "ContactLink Integration With Cold Email Sales Tool",
    "h1": "Integrate ContactLink with SalesBlink",
    "metaDescription": "Integrate ContactLink and other Calendar Tools with SalesBlink. Two Way ContactLink Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appointible",
    "slug": "appointible",
    "logo": "https://zapier-images.imgix.net/storage/services/4452830532e91db9e21b188c56380bc3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appointments scheduling made easy. Appointible supports your service business with a simple to use appointment and booking management system.",
    "category": "Scheduling & Booking",
    "title": "Appointible Integration With Cold Email Sales Tool",
    "h1": "Integrate Appointible with SalesBlink",
    "metaDescription": "Integrate Appointible and other Scheduling & Booking Tools with SalesBlink. Two Way Appointible Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Screenfeed Connect",
    "slug": "screenfeed-connect",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/07d23d1e45e482d5db5ed490d0dd8d9f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Screenfeed Connect is a digital signage content workflow tool for users to create custom content faster and easier than ever.",
    "category": "Communication",
    "title": "Screenfeed Connect Integration With Cold Email Sales Tool",
    "h1": "Integrate Screenfeed Connect with SalesBlink",
    "metaDescription": "Integrate Screenfeed Connect and other Communication Tools with SalesBlink. Two Way Screenfeed Connect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QApp",
    "slug": "qapp",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf5545e8725a0aba5298c9bfed46ad63.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "QApp is an all-in-one management system for businesses",
    "category": "CRM (Customer Relationship Management)",
    "title": "QApp Integration With Cold Email Sales Tool",
    "h1": "Integrate QApp with SalesBlink",
    "metaDescription": "Integrate QApp and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way QApp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tabasher",
    "slug": "tabasher",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1854d76e9fe9609e985d8d9e2df1b266.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tabasher is a Saas platform that enables creators to easily create and sell their content online in minutes.",
    "category": "Commerce",
    "title": "Tabasher Integration With Cold Email Sales Tool",
    "h1": "Integrate Tabasher with SalesBlink",
    "metaDescription": "Integrate Tabasher and other Commerce Tools with SalesBlink. Two Way Tabasher Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GainKnowHow",
    "slug": "gainknowhow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a4bc9f0709b071015ea69788355a8cd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GainKnowHow is an employee onboarding and skill training platform. It's like \"An org-chart for your skills\".",
    "category": "HR Talent & Recruitment",
    "title": "GainKnowHow Integration With Cold Email Sales Tool",
    "h1": "Integrate GainKnowHow with SalesBlink",
    "metaDescription": "Integrate GainKnowHow and other HR Talent & Recruitment Tools with SalesBlink. Two Way GainKnowHow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "isLucid",
    "slug": "islucid",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e20f0bfba81cb8f724fabd5b58fb1f9e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "isLucid enables real time tasks capturing from verbal conversations on Microsoft Teams",
    "category": "Productivity",
    "title": "isLucid Integration With Cold Email Sales Tool",
    "h1": "Integrate isLucid with SalesBlink",
    "metaDescription": "Integrate isLucid and other Productivity Tools with SalesBlink. Two Way isLucid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Maildrip",
    "slug": "maildrip",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/090244eedb1a2c14c17527b814543700.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Maildrip is a marketing tool that helps you handle drip mails easily.",
    "category": "Drip Emails",
    "title": "Maildrip Integration With Cold Email Sales Tool",
    "h1": "Integrate Maildrip with SalesBlink",
    "metaDescription": "Integrate Maildrip and other Drip Emails Tools with SalesBlink. Two Way Maildrip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zavitac",
    "slug": "zavitac",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d79851078d1022c76d7890671e579d33.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zavitac is an easy-to-use yet amazingly capable CRM platform that engages your whole team to win deals faster.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Zavitac Integration With Cold Email Sales Tool",
    "h1": "Integrate Zavitac with SalesBlink",
    "metaDescription": "Integrate Zavitac and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Zavitac Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Like Magic",
    "slug": "likemagic",
    "logo": "https://zapier-images.imgix.net/storage/services/ec19f06d4cf9315b6cff21586f4a5137.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our email and webhook parsing system extracts data from emails, attachments, and more allowing you to send it to other Zapier-connected systems.",
    "category": "Documents",
    "title": "Like Magic Integration With Cold Email Sales Tool",
    "h1": "Integrate Like Magic with SalesBlink",
    "metaDescription": "Integrate Like Magic and other Documents Tools with SalesBlink. Two Way Like Magic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiver bot",
    "slug": "hiver-bot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/493297e2065a03fd0197cc976917c433.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hiver is a conversational chat bot and forms service.",
    "category": "Scheduling & Booking",
    "title": "Hiver bot Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiver bot with SalesBlink",
    "metaDescription": "Integrate Hiver bot and other Scheduling & Booking Tools with SalesBlink. Two Way Hiver bot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SpewHub Unlimited SMS API",
    "slug": "spewhub-unlimited-sms-api-ca1554",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7601e16eb3e9c63538490ffc04816b59.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The SpewHub SMS API Platform allows for UNLIMITED SMS to be SENT and RECEIVED GLOBALLY, to and from your application or website.",
    "category": "Phone & SMS",
    "title": "SpewHub Unlimited SMS API Integration With Cold Email Sales Tool",
    "h1": "Integrate SpewHub Unlimited SMS API with SalesBlink",
    "metaDescription": "Integrate SpewHub Unlimited SMS API and other Phone & SMS Tools with SalesBlink. Two Way SpewHub Unlimited SMS API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SaleDrum",
    "slug": "saledrum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0827661fd22dfe66499fc6f73ccee895.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SaleDrum is an automated repricing tool for Amazon marketplace sellers",
    "category": "eCommerce",
    "title": "SaleDrum Integration With Cold Email Sales Tool",
    "h1": "Integrate SaleDrum with SalesBlink",
    "metaDescription": "Integrate SaleDrum and other eCommerce Tools with SalesBlink. Two Way SaleDrum Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Connectly.ai",
    "slug": "connectlyai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54cc7a5d6435980cfc6b6ee6d553a773.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Connectly uses AI to simplify how you talk to your customers across WhatsApp, SMS, Facebook Messenger and Instagram. Drive engagement, increase conversions and make customers happy — all from one place.",
    "category": "Communication",
    "title": "Connectly.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Connectly.ai with SalesBlink",
    "metaDescription": "Integrate Connectly.ai and other Communication Tools with SalesBlink. Two Way Connectly.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Drafter AI",
    "slug": "drafter-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a3827dbb6ebaca84ffc3e2e143d5cc6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Drafter AI enables marketing teams to scale AI-powered tasks like content generation, data acquisition and analytics.",
    "category": "Marketing Automation",
    "title": "Drafter AI Integration With Cold Email Sales Tool",
    "h1": "Integrate Drafter AI with SalesBlink",
    "metaDescription": "Integrate Drafter AI and other Marketing Automation Tools with SalesBlink. Two Way Drafter AI Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Databrush",
    "slug": "databrush",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd296052bb5594ea6b19c15ea6da92b4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Databrush is the best way to generate lots of image with variations",
    "category": "Images & Design",
    "title": "Databrush Integration With Cold Email Sales Tool",
    "h1": "Integrate Databrush with SalesBlink",
    "metaDescription": "Integrate Databrush and other Images & Design Tools with SalesBlink. Two Way Databrush Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Makezu Beta",
    "slug": "makezu-beta",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ca4bbbb2fcd86cec5a18e9fbdc3a25c5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Makezu is a tool to use Twitter for Growth & Prospection.",
    "category": "Marketing Automation",
    "title": "Makezu Beta Integration With Cold Email Sales Tool",
    "h1": "Integrate Makezu Beta with SalesBlink",
    "metaDescription": "Integrate Makezu Beta and other Marketing Automation Tools with SalesBlink. Two Way Makezu Beta Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Menaflow",
    "slug": "menaflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/317636899d9138e378bd9139f15534a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Menaflow is an omnichannel customer engagement platform built exclusively for businesses in the Middle East.",
    "category": "eCommerce",
    "title": "Menaflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Menaflow with SalesBlink",
    "metaDescription": "Integrate Menaflow and other eCommerce Tools with SalesBlink. Two Way Menaflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "otris solution platform",
    "slug": "documents",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/61f1e5e3ddbcb79bea626b5b781943fb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The otris solution platform (Documents) is an ECM platform for managing documents and business processes.",
    "category": "Content & Files",
    "title": "otris solution platform Integration With Cold Email Sales Tool",
    "h1": "Integrate otris solution platform with SalesBlink",
    "metaDescription": "Integrate otris solution platform and other Content & Files Tools with SalesBlink. Two Way otris solution platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PlaintiffHQ Dev",
    "slug": "plaintiffhq-dev",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/812ab1644c73e3765f014f8f87c0f35d_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlaintiffHQ Dev is the QA site website of PlaintiffHQ, a marketing service for lawyers, that is only used by developers",
    "category": "Marketing Automation",
    "title": "PlaintiffHQ Dev Integration With Cold Email Sales Tool",
    "h1": "Integrate PlaintiffHQ Dev with SalesBlink",
    "metaDescription": "Integrate PlaintiffHQ Dev and other Marketing Automation Tools with SalesBlink. Two Way PlaintiffHQ Dev Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Runsensible",
    "slug": "runsensible",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7fb899ad1ef3f58dc7082f934a44c670.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RunSensible is a CRM, sales, and marketing tool to help you get more done with less work so you can grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Runsensible Integration With Cold Email Sales Tool",
    "h1": "Integrate Runsensible with SalesBlink",
    "metaDescription": "Integrate Runsensible and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Runsensible Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BlinkSwag",
    "slug": "blinkswag",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5b7c88464d54d140c50b2d81cef61344.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BlinkSwag is your all-in one swag platform. Shop, store, and ship the highest quality swag around  the world with the click of a button.",
    "category": "Marketing Automation",
    "title": "BlinkSwag Integration With Cold Email Sales Tool",
    "h1": "Integrate BlinkSwag with SalesBlink",
    "metaDescription": "Integrate BlinkSwag and other Marketing Automation Tools with SalesBlink. Two Way BlinkSwag Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Poppins",
    "slug": "poppins",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/47aac8e568ff25b87b4c7dc900f2e7ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Poppins is a team collaboration tool for all your ad hoc tasks that doesn't make it into any project management tool",
    "category": "Productivity",
    "title": "Poppins Integration With Cold Email Sales Tool",
    "h1": "Integrate Poppins with SalesBlink",
    "metaDescription": "Integrate Poppins and other Productivity Tools with SalesBlink. Two Way Poppins Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KEA",
    "slug": "kea",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f5a2a769284a319ebb752142fb8f5239.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kea is a digital legal workspace that helps individual lawyers and entire legal teams to create and deliver better legal services, together.",
    "category": "Project Management",
    "title": "KEA Integration With Cold Email Sales Tool",
    "h1": "Integrate KEA with SalesBlink",
    "metaDescription": "Integrate KEA and other Project Management Tools with SalesBlink. Two Way KEA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Friday",
    "slug": "friday",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/012f512a116ef4cc7db5f0d882fb95e9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Friday is a Digital HQ for working from anywhere.",
    "category": "Team Collaboration",
    "title": "Friday Integration With Cold Email Sales Tool",
    "h1": "Integrate Friday with SalesBlink",
    "metaDescription": "Integrate Friday and other Team Collaboration Tools with SalesBlink. Two Way Friday Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kape",
    "slug": "kape",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a034bf31cbd1081e1e67d5f0e58a159.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kape is a Sales Conversion App that helps Sales Representatives to follow-up leads and convert them into clients quickly with responsive actions, to increase conversion rate, and improve profits.",
    "category": "Sales & CRM",
    "title": "Kape Integration With Cold Email Sales Tool",
    "h1": "Integrate Kape with SalesBlink",
    "metaDescription": "Integrate Kape and other Sales & CRM Tools with SalesBlink. Two Way Kape Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Freshflows",
    "slug": "freshflows",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b65ae7fa3838867bb9381e13c48b0b96.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Freshflows is a product management suite",
    "category": "Product Management",
    "title": "Freshflows Integration With Cold Email Sales Tool",
    "h1": "Integrate Freshflows with SalesBlink",
    "metaDescription": "Integrate Freshflows and other Product Management Tools with SalesBlink. Two Way Freshflows Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DocuSoft",
    "slug": "docusoft",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fa72853494c7286c9eb3074d0ee8ef3_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Docusoft is a document management, workflow processes and document delivery tool to organize and keep clients documents in one central cloud storage which makes it easy to access and review.",
    "category": "IT Operations",
    "title": "DocuSoft Integration With Cold Email Sales Tool",
    "h1": "Integrate DocuSoft with SalesBlink",
    "metaDescription": "Integrate DocuSoft and other IT Operations Tools with SalesBlink. Two Way DocuSoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Keltehue",
    "slug": "keltehue",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f6bcd9638fd8ebebb2ce83d253e3d46e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keltehue is a project management software that enables CEOs and managers to flying through the projects, tracks and manages goals.",
    "category": "Project Management",
    "title": "Keltehue Integration With Cold Email Sales Tool",
    "h1": "Integrate Keltehue with SalesBlink",
    "metaDescription": "Integrate Keltehue and other Project Management Tools with SalesBlink. Two Way Keltehue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Incontrol",
    "slug": "incontrol",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b3d9b13ca23f497709781254583eb5e8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With the Incontrol apps you can fill in digital forms anytime, anywhere.",
    "category": "Forms & Surveys",
    "title": "Incontrol Integration With Cold Email Sales Tool",
    "h1": "Integrate Incontrol with SalesBlink",
    "metaDescription": "Integrate Incontrol and other Forms & Surveys Tools with SalesBlink. Two Way Incontrol Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TuxMailer",
    "slug": "tuxmailer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/828b26e10679568ec7c1f4f7f733c6eb_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TuxMailer is an Email Address Validation Service",
    "category": "Marketing",
    "title": "TuxMailer Integration With Cold Email Sales Tool",
    "h1": "Integrate TuxMailer with SalesBlink",
    "metaDescription": "Integrate TuxMailer and other Marketing Tools with SalesBlink. Two Way TuxMailer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SKILL-LYNC Sales CRM",
    "slug": "sales-crm-sklync",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e4cd5597fa2d97e936cf51986509d7b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SKILL-LYNC Sales CRM helps to capture leads from different sources.",
    "category": "Online Courses",
    "title": "SKILL-LYNC Sales CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate SKILL-LYNC Sales CRM with SalesBlink",
    "metaDescription": "Integrate SKILL-LYNC Sales CRM and other Online Courses Tools with SalesBlink. Two Way SKILL-LYNC Sales CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BirthdayBot",
    "slug": "birthdaybot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7767419ef76d78728ca750dacce87431_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BirthdayBot is the app to keep track of office birthdays and anniversaries. BirthdayBot keeps everyone informed about upcoming birthdays. It also ensures all the members of your team get a proper b-day celebration whether they are working in the office or remote!",
    "category": "HR Talent & Recruitment",
    "title": "BirthdayBot Integration With Cold Email Sales Tool",
    "h1": "Integrate BirthdayBot with SalesBlink",
    "metaDescription": "Integrate BirthdayBot and other HR Talent & Recruitment Tools with SalesBlink. Two Way BirthdayBot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reshape API",
    "slug": "reshape-api",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d693ccc1b0714279dcc510e028628d1a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Reshape API transforms data in real time to make your no-code builder and automation tools work together smoothly.",
    "category": "Developer Tools",
    "title": "Reshape API Integration With Cold Email Sales Tool",
    "h1": "Integrate Reshape API with SalesBlink",
    "metaDescription": "Integrate Reshape API and other Developer Tools Tools with SalesBlink. Two Way Reshape API Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Upper Route Planner",
    "slug": "upper-route-planner",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bc9a5eb3dae5dc5f37abd2863b7083a3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Upper Route Planner is a service that optimizes Route very efficiently.",
    "category": "Productivity",
    "title": "Upper Route Planner Integration With Cold Email Sales Tool",
    "h1": "Integrate Upper Route Planner with SalesBlink",
    "metaDescription": "Integrate Upper Route Planner and other Productivity Tools with SalesBlink. Two Way Upper Route Planner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Obeatow",
    "slug": "obeatow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e81af710ae421046f71adc1d18d7d4d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Obeatow is a visual feedback and bug-reporting software.",
    "category": "Forms & Surveys",
    "title": "Obeatow Integration With Cold Email Sales Tool",
    "h1": "Integrate Obeatow with SalesBlink",
    "metaDescription": "Integrate Obeatow and other Forms & Surveys Tools with SalesBlink. Two Way Obeatow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Carismar Commerce",
    "slug": "carismar-commerce",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/65d314fb8f2b288df2fa22b63a643199.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We’re re-thinking composable commerce and making enterprise technology available for everyone.",
    "category": "eCommerce",
    "title": "Carismar Commerce Integration With Cold Email Sales Tool",
    "h1": "Integrate Carismar Commerce with SalesBlink",
    "metaDescription": "Integrate Carismar Commerce and other eCommerce Tools with SalesBlink. Two Way Carismar Commerce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PlaintiffHQ",
    "slug": "plaintiffhq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ff975da662efc7f471cea36bad1faa37_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlaintiffHQ is a web application used for marketing services for lawyers. It contains functionalities like  \n'Create Landing Page', 'Create Campaign Websites', 'Broadcast Email Campaigns', 'Create Email Sequence'...etc.",
    "category": "Marketing Automation",
    "title": "PlaintiffHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate PlaintiffHQ with SalesBlink",
    "metaDescription": "Integrate PlaintiffHQ and other Marketing Automation Tools with SalesBlink. Two Way PlaintiffHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Viqeo",
    "slug": "viqeo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1301d77acfbb216086122316ac6a6d22.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Viqeo is an interactive video platform to create, automate and distribute videos.",
    "category": "Video & Audio",
    "title": "Viqeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Viqeo with SalesBlink",
    "metaDescription": "Integrate Viqeo and other Video & Audio Tools with SalesBlink. Two Way Viqeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "sproof",
    "slug": "sproof",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6d58ef1dcefd23ee5d8297f378bcf4b1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sproof is a digital documents platform able to generate documents that are 100% GDPR-compliant and guaranteed legally-binding.",
    "category": "Signatures",
    "title": "sproof Integration With Cold Email Sales Tool",
    "h1": "Integrate sproof with SalesBlink",
    "metaDescription": "Integrate sproof and other Signatures Tools with SalesBlink. Two Way sproof Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shopper.com",
    "slug": "shopperdotcom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5213eb6622eaffa60cd7c11c3d962bfb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shopper.com is a platform that helps to manage, monetise and measure all your shopping recommendations in one place and share wherever your audience are.",
    "category": "Social Media Marketing",
    "title": "Shopper.com Integration With Cold Email Sales Tool",
    "h1": "Integrate Shopper.com with SalesBlink",
    "metaDescription": "Integrate Shopper.com and other Social Media Marketing Tools with SalesBlink. Two Way Shopper.com Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Cryotos",
    "slug": "cryotos",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/40ac39ebd4ec933083393214e015e5a5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cryotos is a cloud-based maintenance management software platform to plan, track and optimize your facilities and maintenance management.",
    "category": "IT Operations",
    "title": "Cryotos Integration With Cold Email Sales Tool",
    "h1": "Integrate Cryotos with SalesBlink",
    "metaDescription": "Integrate Cryotos and other IT Operations Tools with SalesBlink. Two Way Cryotos Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AIRe Link",
    "slug": "aire-link",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/207803900844570545b68aa44502d5d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AIRe Link is a professional browser-based visual remote support tool to help reduce customer visits and improve first-time fix rates.",
    "category": "Communication",
    "title": "AIRe Link Integration With Cold Email Sales Tool",
    "h1": "Integrate AIRe Link with SalesBlink",
    "metaDescription": "Integrate AIRe Link and other Communication Tools with SalesBlink. Two Way AIRe Link Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pluspoint",
    "slug": "pluspoint",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/56644801e2e229e44a1d72ceb885998e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pluspoint is customer reviews software that helps you to automate review generation, manage reputation and get more customers.",
    "category": "Reviews",
    "title": "Pluspoint Integration With Cold Email Sales Tool",
    "h1": "Integrate Pluspoint with SalesBlink",
    "metaDescription": "Integrate Pluspoint and other Reviews Tools with SalesBlink. Two Way Pluspoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Edmingle",
    "slug": "edmingle",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d881297337dbe2b29b193796a61c0d50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A unified platform for Edupreneurs to boost your coaching and training business.",
    "category": "Education",
    "title": "Edmingle Integration With Cold Email Sales Tool",
    "h1": "Integrate Edmingle with SalesBlink",
    "metaDescription": "Integrate Edmingle and other Education Tools with SalesBlink. Two Way Edmingle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flomill",
    "slug": "flomill",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/87ca7b6d96558b59a6c068afbed967af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flomill filters inbound leads and sets up instant / scheduled appointments with target clients for your sales team.",
    "category": "Sales & CRM",
    "title": "Flomill Integration With Cold Email Sales Tool",
    "h1": "Integrate Flomill with SalesBlink",
    "metaDescription": "Integrate Flomill and other Sales & CRM Tools with SalesBlink. Two Way Flomill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fill",
    "slug": "fill",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae668fce7d6246f2481a8dbd149d23f6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fill is the effortless way to fill, sign and send documents and forms.",
    "category": "Documents",
    "title": "Fill Integration With Cold Email Sales Tool",
    "h1": "Integrate Fill with SalesBlink",
    "metaDescription": "Integrate Fill and other Documents Tools with SalesBlink. Two Way Fill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rewix",
    "slug": "rewix",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2bb1e0c9bde1465ffdf06b9aa614e29a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rewix is a b2b e-commerce platform that offers all the features necessary to manage your online store.",
    "category": "eCommerce",
    "title": "Rewix Integration With Cold Email Sales Tool",
    "h1": "Integrate Rewix with SalesBlink",
    "metaDescription": "Integrate Rewix and other eCommerce Tools with SalesBlink. Two Way Rewix Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "4iiz",
    "slug": "4iiz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f0dace643f8033541c961c01016f3a3b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "4iiz is a system that optimizes every vertical within a law firm.",
    "category": "CRM (Customer Relationship Management)",
    "title": "4iiz Integration With Cold Email Sales Tool",
    "h1": "Integrate 4iiz with SalesBlink",
    "metaDescription": "Integrate 4iiz and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way 4iiz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RanknRole",
    "slug": "ranknrole",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39ab3f2665f154919b1374850578f4cd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RanknRole is an assessment tool that helps recruiters test candidates for job roles.",
    "category": "Human Resources",
    "title": "RanknRole Integration With Cold Email Sales Tool",
    "h1": "Integrate RanknRole with SalesBlink",
    "metaDescription": "Integrate RanknRole and other Human Resources Tools with SalesBlink. Two Way RanknRole Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gridfox",
    "slug": "gridfox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24354d09e119c0ebcc27553b40bf0143.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your work, your way with Gridfox. The tool for team collaboration that helps you build custom applications in minutes.",
    "category": "Team Collaboration",
    "title": "Gridfox Integration With Cold Email Sales Tool",
    "h1": "Integrate Gridfox with SalesBlink",
    "metaDescription": "Integrate Gridfox and other Team Collaboration Tools with SalesBlink. Two Way Gridfox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmarterDemo",
    "slug": "smarterdemo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ceb20719cdca250ea1b7ef2d853a2591.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmarterDemo transforms phone calls into rich, visual experiences. Show, tell, sell.",
    "category": "Sales & CRM",
    "title": "SmarterDemo Integration With Cold Email Sales Tool",
    "h1": "Integrate SmarterDemo with SalesBlink",
    "metaDescription": "Integrate SmarterDemo and other Sales & CRM Tools with SalesBlink. Two Way SmarterDemo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Highercred",
    "slug": "highercred",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bbeadf1adec65e8b3f99fb31779a9658.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Frictionless e-commerce solutions for higher education",
    "category": "Education",
    "title": "Highercred Integration With Cold Email Sales Tool",
    "h1": "Integrate Highercred with SalesBlink",
    "metaDescription": "Integrate Highercred and other Education Tools with SalesBlink. Two Way Highercred Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Puntual",
    "slug": "puntual",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/44fbe2caee1e6482e5d38860cee9da1d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Puntual is a scheduling platform to connect with professionals like lawyers and consultants.",
    "category": "Scheduling & Booking",
    "title": "Puntual Integration With Cold Email Sales Tool",
    "h1": "Integrate Puntual with SalesBlink",
    "metaDescription": "Integrate Puntual and other Scheduling & Booking Tools with SalesBlink. Two Way Puntual Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Flash Lead",
    "slug": "flash-lead",
    "logo": "https://zapier-images.imgix.net/storage/services/daafaa8c606073bfefa41ddd11e04936.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Flash lead is a sales management and a Revenue growth software on a mission to help sales people close more deals in less time; its features varies from lead management, VoIP, Omni channel conversations and more all available in Mobile and Web App.",
    "category": "Contact Management",
    "title": "Flash Lead Integration With Cold Email Sales Tool",
    "h1": "Integrate Flash Lead with SalesBlink",
    "metaDescription": "Integrate Flash Lead and other Contact Management Tools with SalesBlink. Two Way Flash Lead Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Yeti Snow",
    "slug": "yeti-snow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5f43aae4babc4ffee4f3a9140d4be9dc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snow management software to control and grow your snow management business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Yeti Snow Integration With Cold Email Sales Tool",
    "h1": "Integrate Yeti Snow with SalesBlink",
    "metaDescription": "Integrate Yeti Snow and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Yeti Snow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Certif-ID",
    "slug": "certif-id",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d97e0a699ca093c394ec0770b3b224e4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Certif-ID is a blockchain-based credentialing platform, enabling organisations to securely issue, track and verify digital credentials and digital badges.",
    "category": "Online Courses",
    "title": "Certif-ID Integration With Cold Email Sales Tool",
    "h1": "Integrate Certif-ID with SalesBlink",
    "metaDescription": "Integrate Certif-ID and other Online Courses Tools with SalesBlink. Two Way Certif-ID Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IP2WHOIS",
    "slug": "ip2whois",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5816f4773d54cd5e09432fe1d7856128.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IP2WHOIS WHOIS Lookup API helps users to obtain domain information, WHOIS record, by using a domain name.",
    "category": "IT Operations",
    "title": "IP2WHOIS Integration With Cold Email Sales Tool",
    "h1": "Integrate IP2WHOIS with SalesBlink",
    "metaDescription": "Integrate IP2WHOIS and other IT Operations Tools with SalesBlink. Two Way IP2WHOIS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Etendo",
    "slug": "etendo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/da1d40ed89fbfe4ec57f3ceaba76b01c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Etendo is a team collaboration tool to organize tasks and keep projects on track.",
    "category": "Product Management",
    "title": "Etendo Integration With Cold Email Sales Tool",
    "h1": "Integrate Etendo with SalesBlink",
    "metaDescription": "Integrate Etendo and other Product Management Tools with SalesBlink. Two Way Etendo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "myEZcare",
    "slug": "myezcare",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/780b7c291b49444d72c7076135c86a0f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myEZcare is a complete one-stop solution for Home Health care, Adult Daycare , Residential Assisted living and other businesses providing senior care and working with Medicaid and Medicare.",
    "category": "HR Talent & Recruitment",
    "title": "myEZcare Integration With Cold Email Sales Tool",
    "h1": "Integrate myEZcare with SalesBlink",
    "metaDescription": "Integrate myEZcare and other HR Talent & Recruitment Tools with SalesBlink. Two Way myEZcare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Billed",
    "slug": "billed",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ce3cf9519674cef1992b1afee4db1efa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online Invoicing Made Easy. With our clean, simple design, creating an invoice couldn’t be easier. Choose from our ready-made templates or start with a blank canvas to create something unique.",
    "category": "Accounting",
    "title": "Billed Integration With Cold Email Sales Tool",
    "h1": "Integrate Billed with SalesBlink",
    "metaDescription": "Integrate Billed and other Accounting Tools with SalesBlink. Two Way Billed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Krozu",
    "slug": "krozu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/94a47cd2c0071904aa9a03d4fb79eda9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Krozu is a hierarchical project/business management tool to collaborate, track and organize projects on a business scale",
    "category": "Project Management",
    "title": "Krozu Integration With Cold Email Sales Tool",
    "h1": "Integrate Krozu with SalesBlink",
    "metaDescription": "Integrate Krozu and other Project Management Tools with SalesBlink. Two Way Krozu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimpleX",
    "slug": "simplex",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7afd7cfef41b86075fae43b68cd52bf0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SimpleX analyse qualitative data from any survey with a smart console that truly understands words and languages. Never again with a spreadsheet.",
    "category": "Forms & Surveys",
    "title": "SimpleX Integration With Cold Email Sales Tool",
    "h1": "Integrate SimpleX with SalesBlink",
    "metaDescription": "Integrate SimpleX and other Forms & Surveys Tools with SalesBlink. Two Way SimpleX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Browserlify",
    "slug": "browserlify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b29bcb29b0559f4ef23da61e9ef4fbea.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Browserlify is Headless chrome service for PDF generation and web scraping.",
    "category": "Developer Tools",
    "title": "Browserlify Integration With Cold Email Sales Tool",
    "h1": "Integrate Browserlify with SalesBlink",
    "metaDescription": "Integrate Browserlify and other Developer Tools Tools with SalesBlink. Two Way Browserlify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cronly",
    "slug": "cronly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/eaf001ce6f144030ca7a517dfdeb6ede.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cronly helps you manage your cron jobs in the cloud with built-in monitoring.",
    "category": "Developer Tools",
    "title": "Cronly Integration With Cold Email Sales Tool",
    "h1": "Integrate Cronly with SalesBlink",
    "metaDescription": "Integrate Cronly and other Developer Tools Tools with SalesBlink. Two Way Cronly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NerdyData.com",
    "slug": "nerdydatacom",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/54cd54b39d556e0317653c364845d742_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lookup a domain and return a list of technologies they use, tech spend, and other company data",
    "category": "Sales & CRM",
    "title": "NerdyData.com Integration With Cold Email Sales Tool",
    "h1": "Integrate NerdyData.com with SalesBlink",
    "metaDescription": "Integrate NerdyData.com and other Sales & CRM Tools with SalesBlink. Two Way NerdyData.com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DoorKnock",
    "slug": "doorknock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f04c048f24d60aeec9ae7491230b7538.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DoorKnock is a mobile canvassing app that helps progressive candidates record data on the doorstep.",
    "category": "Contact Management",
    "title": "DoorKnock Integration With Cold Email Sales Tool",
    "h1": "Integrate DoorKnock with SalesBlink",
    "metaDescription": "Integrate DoorKnock and other Contact Management Tools with SalesBlink. Two Way DoorKnock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Moja",
    "slug": "moja",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0741add0d2590f64278987d45ddcf8ee.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Moja is a communication platform that allows businesses to interact with their clients with multiple channels under one platform.",
    "category": "Communication",
    "title": "Moja Integration With Cold Email Sales Tool",
    "h1": "Integrate Moja with SalesBlink",
    "metaDescription": "Integrate Moja and other Communication Tools with SalesBlink. Two Way Moja Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CommissionCrowd",
    "slug": "commissioncrowd",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9cbdf2e5750a021bf833c0d9c576b127.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CommissionCrowd is the easiest way for companies and B2B sales freelancers to connect and manage successful working partnerships globally.",
    "category": "Sales & CRM",
    "title": "CommissionCrowd Integration With Cold Email Sales Tool",
    "h1": "Integrate CommissionCrowd with SalesBlink",
    "metaDescription": "Integrate CommissionCrowd and other Sales & CRM Tools with SalesBlink. Two Way CommissionCrowd Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dots.eco",
    "slug": "dotseco",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/deefa2497b026cb816775d450bcd9b6d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Seamless daily actions, major eco-impact. The Dots-Economy offers over 150 micro environmental incentives that boost your brand's conversion rate, engagement, retention, user acquisition, and improve the world, dot by dot.",
    "category": "Ads & Conversion",
    "title": "Dots.eco Integration With Cold Email Sales Tool",
    "h1": "Integrate Dots.eco with SalesBlink",
    "metaDescription": "Integrate Dots.eco and other Ads & Conversion Tools with SalesBlink. Two Way Dots.eco Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blaze",
    "slug": "blaze",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/2d2bc8c5277de1143d35a3aed06b260c_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blaze is the smarter no-code tool for teams that we've all been waiting for! We give you everything you need to create apps and internal tools that supercharge your team's processes.",
    "category": "App Builder",
    "title": "Blaze Integration With Cold Email Sales Tool",
    "h1": "Integrate Blaze with SalesBlink",
    "metaDescription": "Integrate Blaze and other App Builder Tools with SalesBlink. Two Way Blaze Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Subamplify",
    "slug": "subamplify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e411b1620d042f5ad539bd654f5e3b6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Subamplify is a subscription model driven ecommerce solution for businesses looking to sell their products online.",
    "category": "eCommerce",
    "title": "Subamplify Integration With Cold Email Sales Tool",
    "h1": "Integrate Subamplify with SalesBlink",
    "metaDescription": "Integrate Subamplify and other eCommerce Tools with SalesBlink. Two Way Subamplify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jumppl",
    "slug": "jumppl",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9a76b502e8ba6396756c4eb2b6c19ebb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jumppl is a project management platform with features including separate client portals, staff monitoring, task monitoring, invoicing, team chat and file storage.",
    "category": "Website Builders",
    "title": "Jumppl Integration With Cold Email Sales Tool",
    "h1": "Integrate Jumppl with SalesBlink",
    "metaDescription": "Integrate Jumppl and other Website Builders Tools with SalesBlink. Two Way Jumppl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "EVO Appraisal Management Software",
    "slug": "evo-appraisal-management-softwar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/47bf474caae4f10c5c42cc549116624a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EVO Appraisal Management Software is a workflow management tool for streamlining the real estate appraisal process.",
    "category": "Task Management",
    "title": "EVO Appraisal Management Software Integration With Cold Email Sales Tool",
    "h1": "Integrate EVO Appraisal Management Software with SalesBlink",
    "metaDescription": "Integrate EVO Appraisal Management Software and other Task Management Tools with SalesBlink. Two Way EVO Appraisal Management Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mobili Stotelė",
    "slug": "mobili-stotele",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/39f17d305e2c6c6eb69ec4d742478fb9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mobili stotelė is a mobile virtual PBX service for Tele 2 mobile subscribers.",
    "category": "Call Tracking",
    "title": "Mobili Stotelė Integration With Cold Email Sales Tool",
    "h1": "Integrate Mobili Stotelė with SalesBlink",
    "metaDescription": "Integrate Mobili Stotelė and other Call Tracking Tools with SalesBlink. Two Way Mobili Stotelė Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nelio Forms",
    "slug": "nelio-forms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f529f5f006e393e576a2168bd2781c5e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Nelio Forms is an online form builder for WordPress to create contact forms to collect information, make content interactive, and generate conversions.",
    "category": "Forms & Surveys",
    "title": "Nelio Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Nelio Forms with SalesBlink",
    "metaDescription": "Integrate Nelio Forms and other Forms & Surveys Tools with SalesBlink. Two Way Nelio Forms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Storeplum",
    "slug": "storeplum",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/202bf0db90d64acb4756e405ff5cb5f5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Storeplum is an e-commerce platform for SMBs and agencies to sell physical and digital products while maximizing on profits.",
    "category": "eCommerce",
    "title": "Storeplum Integration With Cold Email Sales Tool",
    "h1": "Integrate Storeplum with SalesBlink",
    "metaDescription": "Integrate Storeplum and other eCommerce Tools with SalesBlink. Two Way Storeplum Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "GEVME Registration",
    "slug": "gevme-registration",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fbbf988db89d1b18f8633005cecee239.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gevme provides event and marketing teams with tools to manage in-person, virtual or hybrid events from start to finish.",
    "category": "CRM (Customer Relationship Management)",
    "title": "GEVME Registration Integration With Cold Email Sales Tool",
    "h1": "Integrate GEVME Registration with SalesBlink",
    "metaDescription": "Integrate GEVME Registration and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way GEVME Registration Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "uxpertise LMS",
    "slug": "uxpertise-lms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0fd997c9d8745a4107cd3a123eb4696d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "uxpertise LMS enables you to optimize and automate learning management for companies training employees and organizations selling training.",
    "category": "Education",
    "title": "uxpertise LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate uxpertise LMS with SalesBlink",
    "metaDescription": "Integrate uxpertise LMS and other Education Tools with SalesBlink. Two Way uxpertise LMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SamuraiChat",
    "slug": "samuraichat",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cce0c58c6f904ae2a1b266b916bc6d60_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DIY chatbot builder for any small business conversation",
    "category": "Marketing Automation",
    "title": "SamuraiChat Integration With Cold Email Sales Tool",
    "h1": "Integrate SamuraiChat with SalesBlink",
    "metaDescription": "Integrate SamuraiChat and other Marketing Automation Tools with SalesBlink. Two Way SamuraiChat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tinq.ai",
    "slug": "tinqai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/50ca48146abff1ee01b7566b5f40542f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tinq AI's text analysis tool allows you to examine the attitude on social media, in reviews, or in consumer satisfaction surveys, and much more.",
    "category": "Business Intelligence",
    "title": "Tinq.ai Integration With Cold Email Sales Tool",
    "h1": "Integrate Tinq.ai with SalesBlink",
    "metaDescription": "Integrate Tinq.ai and other Business Intelligence Tools with SalesBlink. Two Way Tinq.ai Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SimplyGest Cloud",
    "slug": "simplygest-cloud",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/64b5b291c1c171ea2e74429e71aa886c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ERP and POS online software for your business",
    "category": "Sales & CRM",
    "title": "SimplyGest Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate SimplyGest Cloud with SalesBlink",
    "metaDescription": "Integrate SimplyGest Cloud and other Sales & CRM Tools with SalesBlink. Two Way SimplyGest Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Legislate",
    "slug": "legislate",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a7bf03ffc75d19af7d1bb4f3d4abf683.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Legislate is a contract management platform built for non-lawyers. Create and electronically sign all your agreements from one place.",
    "category": "Documents",
    "title": "Legislate Integration With Cold Email Sales Tool",
    "h1": "Integrate Legislate with SalesBlink",
    "metaDescription": "Integrate Legislate and other Documents Tools with SalesBlink. Two Way Legislate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rebump",
    "slug": "rebump",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5f66644d7ff7adbb1f365cbf6e99ebcf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rebump is a Gmail extension that automates email follow-up.",
    "category": "Email",
    "title": "Rebump Integration With Cold Email Sales Tool",
    "h1": "Integrate Rebump with SalesBlink",
    "metaDescription": "Integrate Rebump and other Email Tools with SalesBlink. Two Way Rebump Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WinMan Cloud",
    "slug": "winman-cloud-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a14727fee7fc620f88bd0a84b4aad6a9_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WinMan is a companion tool for users of the WinMan ERP software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "WinMan Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate WinMan Cloud with SalesBlink",
    "metaDescription": "Integrate WinMan Cloud and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way WinMan Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WinMan V8",
    "slug": "winman-v8",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1bdd0a037151478b2fb340c8f2fdf165_8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A companion tool for users of the WinMan ERP software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "WinMan V8 Integration With Cold Email Sales Tool",
    "h1": "Integrate WinMan V8 with SalesBlink",
    "metaDescription": "Integrate WinMan V8 and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way WinMan V8 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cartloop",
    "slug": "cartloop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a56c1f4974a5ae870fa7171e51b3a50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Delight customers and turn real-time conversations into sales using the power of text marketing.",
    "category": "Phone & SMS",
    "title": "Cartloop Integration With Cold Email Sales Tool",
    "h1": "Integrate Cartloop with SalesBlink",
    "metaDescription": "Integrate Cartloop and other Phone & SMS Tools with SalesBlink. Two Way Cartloop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zomentum PartnerAlign",
    "slug": "zomentum-partneralign",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4768781cad0b3dc784a330d2ee076fed_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zomentum PartnerAlign makes it easier for SaaS companies to support their Partners, grow their channel programs, and scale revenue.",
    "category": "Sales & CRM",
    "title": "Zomentum PartnerAlign Integration With Cold Email Sales Tool",
    "h1": "Integrate Zomentum PartnerAlign with SalesBlink",
    "metaDescription": "Integrate Zomentum PartnerAlign and other Sales & CRM Tools with SalesBlink. Two Way Zomentum PartnerAlign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Versify",
    "slug": "versify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/694e4e83ad0cae16c3fd5fed4fbc2cf4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Versify is an NFT platform that enables businesses to start creating, selling, and sharing digital collectibles with their communities.",
    "category": "Sales & CRM",
    "title": "Versify Integration With Cold Email Sales Tool",
    "h1": "Integrate Versify with SalesBlink",
    "metaDescription": "Integrate Versify and other Sales & CRM Tools with SalesBlink. Two Way Versify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oappso Loyalty",
    "slug": "oappso-loyalty",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/301e4f864d86dcea32ddec3e4e614196.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Digital Loyalty Stamp Cards for your business, allowing you to automatically stamp and reward from many applications. Requires an Oappso Loyalty account.",
    "category": "Marketing Automation",
    "title": "Oappso Loyalty Integration With Cold Email Sales Tool",
    "h1": "Integrate Oappso Loyalty with SalesBlink",
    "metaDescription": "Integrate Oappso Loyalty and other Marketing Automation Tools with SalesBlink. Two Way Oappso Loyalty Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Angel Match",
    "slug": "angel-match",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f22336a150445b805fa662e8081f2e80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Angel Match frees you from the time-consuming, grueling process of searching for investors by matching you with over 90,000+ angels and venture capitalists in one place.",
    "category": "Website & App Building",
    "title": "Angel Match Integration With Cold Email Sales Tool",
    "h1": "Integrate Angel Match with SalesBlink",
    "metaDescription": "Integrate Angel Match and other Website & App Building Tools with SalesBlink. Two Way Angel Match Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "POWR My Contacts",
    "slug": "powr-my-contacts",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5d5e3b5fae241cb3f591189cc757f189_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect and store all your contacts in one app to improve lead generation tactics",
    "category": "Contact Management",
    "title": "POWR My Contacts Integration With Cold Email Sales Tool",
    "h1": "Integrate POWR My Contacts with SalesBlink",
    "metaDescription": "Integrate POWR My Contacts and other Contact Management Tools with SalesBlink. Two Way POWR My Contacts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OClass",
    "slug": "oclass",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f308dd2520e0793e64805219f57ed299.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OClass is a all-in-one class management software system made for dance studios, tuition centers and health clubs of all sizes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OClass Integration With Cold Email Sales Tool",
    "h1": "Integrate OClass with SalesBlink",
    "metaDescription": "Integrate OClass and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OClass Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pie Forms",
    "slug": "pie-forms-ca162932",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/79bd728a6c5afdeeb8354850ad1fda1e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pie Forms are fast, flexible, and 100% responsive. It lets you create multiple interactive types of WordPress forms with no prior coding knowledge, offering you to take your form building experience to the next level.",
    "category": "Forms & Surveys",
    "title": "Pie Forms Integration With Cold Email Sales Tool",
    "h1": "Integrate Pie Forms with SalesBlink",
    "metaDescription": "Integrate Pie Forms and other Forms & Surveys Tools with SalesBlink. Two Way Pie Forms Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Codelia",
    "slug": "codelia",
    "logo": "https://zapier-images.imgix.net/storage/services/47662141c07988057f9ad3593e99689f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Codelia is an all in one content platform to ideate, create, and distribute content.",
    "category": "Content & Files",
    "title": "Codelia Integration With Cold Email Sales Tool",
    "h1": "Integrate Codelia with SalesBlink",
    "metaDescription": "Integrate Codelia and other Content & Files Tools with SalesBlink. Two Way Codelia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tenancy - Rental Property CRM",
    "slug": "tenancy",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/678eb194048d71a2852e34aa331648b9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Integrated, intelligent automated lead management to select the right renter faster than ever before.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Tenancy - Rental Property CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Tenancy - Rental Property CRM with SalesBlink",
    "metaDescription": "Integrate Tenancy - Rental Property CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Tenancy - Rental Property CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PingSailor SMS",
    "slug": "pingsailor-sms",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f8d94c30645aeac49ff85ae3a6bee911.PNG?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PingSailor is an SMS messaging tool that allows you to send text messages globally.",
    "category": "Communication",
    "title": "PingSailor SMS Integration With Cold Email Sales Tool",
    "h1": "Integrate PingSailor SMS with SalesBlink",
    "metaDescription": "Integrate PingSailor SMS and other Communication Tools with SalesBlink. Two Way PingSailor SMS Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Truvelop",
    "slug": "truvelop",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/913c23920ba74ae49ffb4aa9caef7c64_7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Truvelop is a best in breed HR technology solution that specializes in performance management and development.",
    "category": "Website & App Building",
    "title": "Truvelop Integration With Cold Email Sales Tool",
    "h1": "Integrate Truvelop with SalesBlink",
    "metaDescription": "Integrate Truvelop and other Website & App Building Tools with SalesBlink. Two Way Truvelop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omnivery",
    "slug": "omnivery",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4fccd0b654a85f0383446d47d703c4e5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omnivery is a message delivery platform focused on deliverability and compliance",
    "category": "Email",
    "title": "Omnivery Integration With Cold Email Sales Tool",
    "h1": "Integrate Omnivery with SalesBlink",
    "metaDescription": "Integrate Omnivery and other Email Tools with SalesBlink. Two Way Omnivery Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CB Insights",
    "slug": "cb-insights",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/327363ed025f96cba1e969c32639cfe6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The CB Insights market intelligence platform analyzes data points on venture capital, startups, patents, partnerships and tech news.",
    "category": "Business Intelligence",
    "title": "CB Insights Integration With Cold Email Sales Tool",
    "h1": "Integrate CB Insights with SalesBlink",
    "metaDescription": "Integrate CB Insights and other Business Intelligence Tools with SalesBlink. Two Way CB Insights Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ehminem",
    "slug": "ehminem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4be7c4e278137e449d67615a76ee74ba.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ehminem is an app that cuts filler words from your podcast. Currently available for German.",
    "category": "Video & Audio",
    "title": "Ehminem Integration With Cold Email Sales Tool",
    "h1": "Integrate Ehminem with SalesBlink",
    "metaDescription": "Integrate Ehminem and other Video & Audio Tools with SalesBlink. Two Way Ehminem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Superpictor",
    "slug": "superpictor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ec192be4f0c59cb4a446338d10cc1a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A true interface between the customer and production, Superpictor facilitates and accelerates the sales process of a personalized product, from customer acquisition to after-sales service.\n\nWe have developed a unique, simple and automated SaaS software application that offers digital tools adapted to all the different trades of personalization companies (marketing, sales, graphics, production, after-sales service, etc.).",
    "category": "File Management & Storage",
    "title": "Superpictor Integration With Cold Email Sales Tool",
    "h1": "Integrate Superpictor with SalesBlink",
    "metaDescription": "Integrate Superpictor and other File Management & Storage Tools with SalesBlink. Two Way Superpictor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tisane",
    "slug": "tisane",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ed2ab57904732433ed4c91f135dc43b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tisane is NLU with a focus on abusive content & lawtech / regtech needs. We detect cyberbullying / personal attacks, hate speech, sexual advances, obfuscated profanities, criminal activity, and more.",
    "category": "Human Resources",
    "title": "Tisane Integration With Cold Email Sales Tool",
    "h1": "Integrate Tisane with SalesBlink",
    "metaDescription": "Integrate Tisane and other Human Resources Tools with SalesBlink. Two Way Tisane Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Infomail",
    "slug": "infomail",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6a8828903cbe7369044d47230ae5d278_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Infomail is an email marketing tool. It makes it easy to manage lists and subscribers, send newsletters and transactional emails. Grow your business with the tools you need to send marketing emails.",
    "category": "Email Newsletters",
    "title": "Infomail Integration With Cold Email Sales Tool",
    "h1": "Integrate Infomail with SalesBlink",
    "metaDescription": "Integrate Infomail and other Email Newsletters Tools with SalesBlink. Two Way Infomail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parque meet",
    "slug": "parquemeet",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7ddd46e827b6c0fa81eee14d5308747b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parque meet is a meeting application service that allows you to talk all the time for free. This integration generates the meeting URL.",
    "category": "Communication",
    "title": "Parque meet Integration With Cold Email Sales Tool",
    "h1": "Integrate Parque meet with SalesBlink",
    "metaDescription": "Integrate Parque meet and other Communication Tools with SalesBlink. Two Way Parque meet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loggify",
    "slug": "loggify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cae4b56cf47cf05d932ce33f7fa239af.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A central release note repository enabling automatic communication of app updates. Build target-group specific communication pipelines now!",
    "category": "Product Management",
    "title": "Loggify Integration With Cold Email Sales Tool",
    "h1": "Integrate Loggify with SalesBlink",
    "metaDescription": "Integrate Loggify and other Product Management Tools with SalesBlink. Two Way Loggify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Journalize.io",
    "slug": "journalizeio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b217fc4351a86dc6323194965d4cdcb7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Journalize is a tool that turns financial transaction data into GL entries",
    "category": "Accounting",
    "title": "Journalize.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Journalize.io with SalesBlink",
    "metaDescription": "Integrate Journalize.io and other Accounting Tools with SalesBlink. Two Way Journalize.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Customer Tags & Segments by Shop Circle",
    "slug": "customer-tags-and-segments-ca166",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cb42b2c279299a05080e24681e632ada_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Customer Tags and Segments by Shop Circle allows you to create automated workflows to add tags based on criteria you specify.",
    "category": "eCommerce",
    "title": "Customer Tags & Segments by Shop Circle Integration With Cold Email Sales Tool",
    "h1": "Integrate Customer Tags & Segments by Shop Circle with SalesBlink",
    "metaDescription": "Integrate Customer Tags & Segments by Shop Circle and other eCommerce Tools with SalesBlink. Two Way Customer Tags & Segments by Shop Circle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Beyonk",
    "slug": "beyonk",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c108dd8a40f77e2a379ea743bb265bfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beyonk is the Easier Way to Sell Tickets Online - Event and Activity Booking System",
    "category": "Commerce",
    "title": "Beyonk Integration With Cold Email Sales Tool",
    "h1": "Integrate Beyonk with SalesBlink",
    "metaDescription": "Integrate Beyonk and other Commerce Tools with SalesBlink. Two Way Beyonk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bricks",
    "slug": "bricks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5fc5f4c12beba4034eaf00edd090ea10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Agile BIM collaboration platform for Architecture & construction",
    "category": "Project Management",
    "title": "Bricks Integration With Cold Email Sales Tool",
    "h1": "Integrate Bricks with SalesBlink",
    "metaDescription": "Integrate Bricks and other Project Management Tools with SalesBlink. Two Way Bricks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "REACH Ecosystem",
    "slug": "reach-ecosystem",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/90dc819452190f2447642e7256e54185.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The REACH Ecosystem is a capability and culture platform that uniquely provides all the resources to analyse, train, coach, develop, and measure growth of team members, leaders and the culture.",
    "category": "Human Resources",
    "title": "REACH Ecosystem Integration With Cold Email Sales Tool",
    "h1": "Integrate REACH Ecosystem with SalesBlink",
    "metaDescription": "Integrate REACH Ecosystem and other Human Resources Tools with SalesBlink. Two Way REACH Ecosystem Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Responsly",
    "slug": "responsly",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9210e07da30d67d53b73a892bcc5e2cf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Responsly is a digital feedback platform that get you the right answers.",
    "category": "Customer Support",
    "title": "Responsly Integration With Cold Email Sales Tool",
    "h1": "Integrate Responsly with SalesBlink",
    "metaDescription": "Integrate Responsly and other Customer Support Tools with SalesBlink. Two Way Responsly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Load and Go",
    "slug": "load-and-go",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c2a8cd2009b8b675349543806bfc9535.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Load and Go is a delivery system that helps power deliveries and transporters.",
    "category": "Commerce",
    "title": "Load and Go Integration With Cold Email Sales Tool",
    "h1": "Integrate Load and Go with SalesBlink",
    "metaDescription": "Integrate Load and Go and other Commerce Tools with SalesBlink. Two Way Load and Go Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DefiScout24",
    "slug": "defiscout24",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/69c620d0bb04be3bbb09c2484d88b9d9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DefiScout24 is a trading platform for automated external defibrillators.",
    "category": "Commerce",
    "title": "DefiScout24 Integration With Cold Email Sales Tool",
    "h1": "Integrate DefiScout24 with SalesBlink",
    "metaDescription": "Integrate DefiScout24 and other Commerce Tools with SalesBlink. Two Way DefiScout24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RAPID Platform",
    "slug": "rapid-platform",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f37f5b770573513114b632d8c3faa300.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RAPID Platform is a versatile foundation to build your business solutions without having to dive deep into technical implementation. Powering everything from custom data storage, workflow & business automation and light app development for tailored to your businesses needs.",
    "category": "Website & App Building",
    "title": "RAPID Platform Integration With Cold Email Sales Tool",
    "h1": "Integrate RAPID Platform with SalesBlink",
    "metaDescription": "Integrate RAPID Platform and other Website & App Building Tools with SalesBlink. Two Way RAPID Platform Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boombirds",
    "slug": "boombirds",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8228ea516b17f7e77a9815aa6a575bfb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boombirds helps you to digitally transform, scale & optimise your manual operations into compliant, repeatable, step-based procedures.",
    "category": "Task Management",
    "title": "Boombirds Integration With Cold Email Sales Tool",
    "h1": "Integrate Boombirds with SalesBlink",
    "metaDescription": "Integrate Boombirds and other Task Management Tools with SalesBlink. Two Way Boombirds Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AmtechCRM",
    "slug": "amtechcrm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19e99db1dd94f3713f0fa7c5f0ff0b12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amtech CRM is for handling leads, products and invoices.",
    "category": "CRM (Customer Relationship Management)",
    "title": "AmtechCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate AmtechCRM with SalesBlink",
    "metaDescription": "Integrate AmtechCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way AmtechCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Raytio",
    "slug": "raytio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/76b02cdd281240dcc4b5082715467bd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Raytio provides verified customers for growing digital businesses. Reduce fraud, chargebacks, spam and scams.",
    "category": "Security & Identity Tools",
    "title": "Raytio Integration With Cold Email Sales Tool",
    "h1": "Integrate Raytio with SalesBlink",
    "metaDescription": "Integrate Raytio and other Security & Identity Tools Tools with SalesBlink. Two Way Raytio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SAI360 GRC",
    "slug": "sai360-grc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d5f4d2073d0937a43b818ff39340fd0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The SAI360 platform covers many use cases in the Environmental, Social, Governance, Risk and Compliance Space.",
    "category": "Accounting",
    "title": "SAI360 GRC Integration With Cold Email Sales Tool",
    "h1": "Integrate SAI360 GRC with SalesBlink",
    "metaDescription": "Integrate SAI360 GRC and other Accounting Tools with SalesBlink. Two Way SAI360 GRC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Texta Autopilot",
    "slug": "texta-autopilot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a8a8d7b2a7c48adad726769f4513e874.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Texta Autopilot helps you generate posts for your social media accounts automatically.",
    "category": "Social Media Marketing",
    "title": "Texta Autopilot Integration With Cold Email Sales Tool",
    "h1": "Integrate Texta Autopilot with SalesBlink",
    "metaDescription": "Integrate Texta Autopilot and other Social Media Marketing Tools with SalesBlink. Two Way Texta Autopilot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ServGrow",
    "slug": "servgrow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c91f961ad7ad08516d23e7c936b303f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Servgrow is an all-in-one solution for service pros that handles everything from CRM, to scheduling, estimates, job tracking, invoicing, payments, and so much more.",
    "category": "Scheduling & Booking",
    "title": "ServGrow Integration With Cold Email Sales Tool",
    "h1": "Integrate ServGrow with SalesBlink",
    "metaDescription": "Integrate ServGrow and other Scheduling & Booking Tools with SalesBlink. Two Way ServGrow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cronit",
    "slug": "cronit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bf0d7f3b526abf360760c11f373960df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plan, schedule and run recurring scripts with Cronit: The online cronjob platform",
    "category": "Developer Tools",
    "title": "Cronit Integration With Cold Email Sales Tool",
    "h1": "Integrate Cronit with SalesBlink",
    "metaDescription": "Integrate Cronit and other Developer Tools Tools with SalesBlink. Two Way Cronit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Realcrux",
    "slug": "realcrux",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be1470cc9c974c026d4ac816d5c79b6f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unstoppable Cold Emailing Tool Built For Responses. Generate B2B leads, Create unlimited Sequences, Use Auto Warmup and advanced Scheduling.",
    "category": "Marketing",
    "title": "Realcrux Integration With Cold Email Sales Tool",
    "h1": "Integrate Realcrux with SalesBlink",
    "metaDescription": "Integrate Realcrux and other Marketing Tools with SalesBlink. Two Way Realcrux Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Guru Jobs",
    "slug": "guru-job",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/9b273b10825777a70aec112db48ad719_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GURU JOBS SRL is the company of the OSM - Open Source Management Group specialized in training, research and selection of personnel, which selects the best talents for companies in terms of aptitudes and experience.",
    "category": "Human Resources",
    "title": "Guru Jobs Integration With Cold Email Sales Tool",
    "h1": "Integrate Guru Jobs with SalesBlink",
    "metaDescription": "Integrate Guru Jobs and other Human Resources Tools with SalesBlink. Two Way Guru Jobs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mtalkz",
    "slug": "mtalkz",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/18fa80e1ffa78a6b64e20c41001407de.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mtalkz is a cloud-based communication platform that lets you transform your customer sms campaigns with predictive analysis using AI/ML.",
    "category": "Communication",
    "title": "Mtalkz Integration With Cold Email Sales Tool",
    "h1": "Integrate Mtalkz with SalesBlink",
    "metaDescription": "Integrate Mtalkz and other Communication Tools with SalesBlink. Two Way Mtalkz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendcrux",
    "slug": "sendcrux",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/be1470cc9c974c026d4ac816d5c79b6f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send unlimited marketing, transactional, and OTP emails. Collect visitors from dynamic forms. Connect with Shopify abandoned cart, and other eCommerce SAAS stores.",
    "category": "Marketing",
    "title": "Sendcrux Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendcrux with SalesBlink",
    "metaDescription": "Integrate Sendcrux and other Marketing Tools with SalesBlink. Two Way Sendcrux Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SurveyRock",
    "slug": "surveyrock",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c0f0b98fb94dfad82430194b8f449b04.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SurvyeRock is a survey creation tool to help you gather feedback from anyone at anytime.",
    "category": "Forms & Surveys",
    "title": "SurveyRock Integration With Cold Email Sales Tool",
    "h1": "Integrate SurveyRock with SalesBlink",
    "metaDescription": "Integrate SurveyRock and other Forms & Surveys Tools with SalesBlink. Two Way SurveyRock Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VDX",
    "slug": "vdx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4febe133772108d62b2a7dd60a17df8f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plug & Play API empowering companies & developers to easily turn any portal, application or web interface into a blockchain powerhouse.",
    "category": "File Management & Storage",
    "title": "VDX Integration With Cold Email Sales Tool",
    "h1": "Integrate VDX with SalesBlink",
    "metaDescription": "Integrate VDX and other File Management & Storage Tools with SalesBlink. Two Way VDX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Docsndata",
    "slug": "docsndata",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d06ad5c4456a084018bba02d3f07d802.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate anything with Docsndata, increase daily efficiency and get better results.",
    "category": "Marketing Automation",
    "title": "Docsndata Integration With Cold Email Sales Tool",
    "h1": "Integrate Docsndata with SalesBlink",
    "metaDescription": "Integrate Docsndata and other Marketing Automation Tools with SalesBlink. Two Way Docsndata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rétine",
    "slug": "retine",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c046402f3a08040f78375aa31a4a599d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rétine is a SaaS platform allowing you to create, diffuse and analyse state of the art polls.",
    "category": "Forms & Surveys",
    "title": "Rétine Integration With Cold Email Sales Tool",
    "h1": "Integrate Rétine with SalesBlink",
    "metaDescription": "Integrate Rétine and other Forms & Surveys Tools with SalesBlink. Two Way Rétine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Webbymize",
    "slug": "webbymize",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4b022ca5cabce335a24a69bd65d6dadc_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webbymize helps you to build opt-in forms for webinar registration",
    "category": "Forms & Surveys",
    "title": "Webbymize Integration With Cold Email Sales Tool",
    "h1": "Integrate Webbymize with SalesBlink",
    "metaDescription": "Integrate Webbymize and other Forms & Surveys Tools with SalesBlink. Two Way Webbymize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Chisel",
    "slug": "chisel",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0ea6b0c03858887ca8bbd1c2b4aa4911.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Chisel is a product management tool for roadmaps, team alignment and user research.",
    "category": "Product Management",
    "title": "Chisel Integration With Cold Email Sales Tool",
    "h1": "Integrate Chisel with SalesBlink",
    "metaDescription": "Integrate Chisel and other Product Management Tools with SalesBlink. Two Way Chisel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hello Moe",
    "slug": "hello-moe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8820fac56f91dcaf51294e5f1516bb5b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build conversational bot flows using our visual chatbot builder and templates - no coding is required to automate customer support.",
    "category": "App Builder",
    "title": "Hello Moe Integration With Cold Email Sales Tool",
    "h1": "Integrate Hello Moe with SalesBlink",
    "metaDescription": "Integrate Hello Moe and other App Builder Tools with SalesBlink. Two Way Hello Moe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ReplyCX",
    "slug": "replycx",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e27af2e3ed96d1c8f36007da246a8309.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "It's easy to build bots, but hard to get the desired results. This is where ReplyCX steps in, providing a done-for-you service. We build a pipeline of qualified leads, automate your support, without increasing headcount with our no-code chatbot platform.",
    "category": "App Builder",
    "title": "ReplyCX Integration With Cold Email Sales Tool",
    "h1": "Integrate ReplyCX with SalesBlink",
    "metaDescription": "Integrate ReplyCX and other App Builder Tools with SalesBlink. Two Way ReplyCX Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apizeal",
    "slug": "apizeal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/5837108ba21475b0a44c4a4ea737f97f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apizeal is the Insurance Management System that helps you keep insurance policies, commissions, and clients organized.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Apizeal Integration With Cold Email Sales Tool",
    "h1": "Integrate Apizeal with SalesBlink",
    "metaDescription": "Integrate Apizeal and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Apizeal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GETMIND",
    "slug": "getmind",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e38823558287f1340f0ee413617b6233.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GETMIND is the all-in-one cloud software for successful agencies.",
    "category": "Sales & CRM",
    "title": "GETMIND Integration With Cold Email Sales Tool",
    "h1": "Integrate GETMIND with SalesBlink",
    "metaDescription": "Integrate GETMIND and other Sales & CRM Tools with SalesBlink. Two Way GETMIND Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Advantage Orientation",
    "slug": "advantage-orientation",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ec51765d6acf13789e6c0e1e2f19494d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Advantage Orientation is a SaaS platform providing orientation content delivery in fun interactive ways. \nThis integration provides access to completion information.",
    "category": "Online Courses",
    "title": "Advantage Orientation Integration With Cold Email Sales Tool",
    "h1": "Integrate Advantage Orientation with SalesBlink",
    "metaDescription": "Integrate Advantage Orientation and other Online Courses Tools with SalesBlink. Two Way Advantage Orientation Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amberscript",
    "slug": "amberscript",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2ec8be4f2bd9500a57391155c9b5371.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our services allow you to create text and subtitles from audio or video, either automatically and perfected by you or made by our language experts and professional subtitlers. Simply upload your file and start.",
    "category": "Video & Audio",
    "title": "Amberscript Integration With Cold Email Sales Tool",
    "h1": "Integrate Amberscript with SalesBlink",
    "metaDescription": "Integrate Amberscript and other Video & Audio Tools with SalesBlink. Two Way Amberscript Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TriSys Recruitment Software",
    "slug": "trisys-recruitment-software-ca17",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/288bf85dcce1315c716e03b519421454_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TriSys Recruitment Software manages your client/candidate relationships, placements, timesheets and website on any device.",
    "category": "HR Talent & Recruitment",
    "title": "TriSys Recruitment Software Integration With Cold Email Sales Tool",
    "h1": "Integrate TriSys Recruitment Software with SalesBlink",
    "metaDescription": "Integrate TriSys Recruitment Software and other HR Talent & Recruitment Tools with SalesBlink. Two Way TriSys Recruitment Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Twig Business",
    "slug": "twig-business",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d086c49c8849147142c0077a6312966d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Twig Business offers sustainable business cards that offer cash back and automate expense reporting.",
    "category": "Accounting",
    "title": "Twig Business Integration With Cold Email Sales Tool",
    "h1": "Integrate Twig Business with SalesBlink",
    "metaDescription": "Integrate Twig Business and other Accounting Tools with SalesBlink. Two Way Twig Business Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qigu",
    "slug": "qigu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b677c2011e592d9c2af2e084063bc013.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Qigu, give your training a boost with digital technology to gain in efficiency and design your best digital learning experiences",
    "category": "Online Courses",
    "title": "Qigu Integration With Cold Email Sales Tool",
    "h1": "Integrate Qigu with SalesBlink",
    "metaDescription": "Integrate Qigu and other Online Courses Tools with SalesBlink. Two Way Qigu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Howspace",
    "slug": "howspace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/edf928c3eec28536cd2b63b17dd28867.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Howspace brings people together to collaborate and learn. All in one platform.",
    "category": "Team Collaboration",
    "title": "Howspace Integration With Cold Email Sales Tool",
    "h1": "Integrate Howspace with SalesBlink",
    "metaDescription": "Integrate Howspace and other Team Collaboration Tools with SalesBlink. Two Way Howspace Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "validTo",
    "slug": "validto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6f3df45bf7fa6d925d7e20fb48f1c15d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "You may validate your emails and remove spam from your email database by using validTo email verification service.",
    "category": "Email",
    "title": "validTo Integration With Cold Email Sales Tool",
    "h1": "Integrate validTo with SalesBlink",
    "metaDescription": "Integrate validTo and other Email Tools with SalesBlink. Two Way validTo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CallDNA",
    "slug": "calldna",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6e2a410763c9935950e35af16f4b3807.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CallsDNA is a speech analytics tool with an easy-to-use API integration for audio file uploading",
    "category": "Analytics",
    "title": "CallDNA Integration With Cold Email Sales Tool",
    "h1": "Integrate CallDNA with SalesBlink",
    "metaDescription": "Integrate CallDNA and other Analytics Tools with SalesBlink. Two Way CallDNA Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Meetaway",
    "slug": "meetaway",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/24ef53faf39bed362c39004640e2c10e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meetaway is a virtual networking software for companies and organizations",
    "category": "Event Management",
    "title": "Meetaway Integration With Cold Email Sales Tool",
    "h1": "Integrate Meetaway with SalesBlink",
    "metaDescription": "Integrate Meetaway and other Event Management Tools with SalesBlink. Two Way Meetaway Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FireBox",
    "slug": "firebox",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0971f131d73039ddb8bb567b91215093.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FireBox is a WordPress Popup Builder Plugin that helps you convert your visitors into subscribers and customers with eye-catching popups.",
    "category": "eCommerce",
    "title": "FireBox Integration With Cold Email Sales Tool",
    "h1": "Integrate FireBox with SalesBlink",
    "metaDescription": "Integrate FireBox and other eCommerce Tools with SalesBlink. Two Way FireBox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avena",
    "slug": "avena",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/344245bade1a41198abccec5691688f2.ico?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The global talent search tool for IT recruitment.\nDiscover tech talent to grow your business.",
    "category": "HR Talent & Recruitment",
    "title": "Avena Integration With Cold Email Sales Tool",
    "h1": "Integrate Avena with SalesBlink",
    "metaDescription": "Integrate Avena and other HR Talent & Recruitment Tools with SalesBlink. Two Way Avena Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShoutOut",
    "slug": "shoutout",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/619bc066b68d77d964ee85b08bbfe903_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShoutOut is a collaborative content creation tool for generating branded video directly from your supporters and influencers.",
    "category": "Marketing Automation",
    "title": "ShoutOut Integration With Cold Email Sales Tool",
    "h1": "Integrate ShoutOut with SalesBlink",
    "metaDescription": "Integrate ShoutOut and other Marketing Automation Tools with SalesBlink. Two Way ShoutOut Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BIK",
    "slug": "bik",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/89682e220eb299c3e04c89bda6c99cca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BIK is a powerful marketing software that lets you manage and automate your WhatsApp chats. Create intelligent cohorts, send broadcasts, reply to customer queries, add a WhatsApp widget to your store, all via BIK.",
    "category": "Marketing",
    "title": "BIK Integration With Cold Email Sales Tool",
    "h1": "Integrate BIK with SalesBlink",
    "metaDescription": "Integrate BIK and other Marketing Tools with SalesBlink. Two Way BIK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Viterbit",
    "slug": "viterbit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/90342b613f13cce06f3e7a1c66845a1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Viterbit is a technology start-up that offers a Recruitment and Selection Software (ATS). Just what your company needs to go one step further with the talent it hires.",
    "category": "HR Talent & Recruitment",
    "title": "Viterbit Integration With Cold Email Sales Tool",
    "h1": "Integrate Viterbit with SalesBlink",
    "metaDescription": "Integrate Viterbit and other HR Talent & Recruitment Tools with SalesBlink. Two Way Viterbit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SiteStatus",
    "slug": "sitestatus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1a12f7e6191516678f8f5a5cc147c88c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SiteStatus is an easy-to-use platform to enable real-time monitoring of your backend applications critical to your business. SiteStatus will notify you and your team when your backend goes down via email.",
    "category": "IT Operations",
    "title": "SiteStatus Integration With Cold Email Sales Tool",
    "h1": "Integrate SiteStatus with SalesBlink",
    "metaDescription": "Integrate SiteStatus and other IT Operations Tools with SalesBlink. Two Way SiteStatus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiflow",
    "slug": "hiflow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/49fd407d6f2e202bf9e958ddb070bea4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hiflow allows you to manage your projects, encode your services, create your invoices and analyse your activity online.",
    "category": "Proposal & Invoice Management",
    "title": "Hiflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiflow with SalesBlink",
    "metaDescription": "Integrate Hiflow and other Proposal & Invoice Management Tools with SalesBlink. Two Way Hiflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Switch",
    "slug": "switch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/19b4c6d82f81e8f94d32e128b84efc1c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easily and securely send business mail online. Switch eliminates your mail preparation time and helps you avoid trips to the Post Office.",
    "category": "Documents",
    "title": "Switch Integration With Cold Email Sales Tool",
    "h1": "Integrate Switch with SalesBlink",
    "metaDescription": "Integrate Switch and other Documents Tools with SalesBlink. Two Way Switch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kelloo",
    "slug": "kelloo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a61dc5f822935b4fbe27260275dcb9ed.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kelloo is a resource planning and portfolio management platform. Plan, forecast and deliver smarter. We help you get the most from your projects and people.",
    "category": "Project Management",
    "title": "Kelloo Integration With Cold Email Sales Tool",
    "h1": "Integrate Kelloo with SalesBlink",
    "metaDescription": "Integrate Kelloo and other Project Management Tools with SalesBlink. Two Way Kelloo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Life Peaks",
    "slug": "life-peaks",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6ad73ee176d15da2cd73bc7b65b111d3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Life Peaks is an online software allowing companies to sell gift cards and tickets to events.",
    "category": "eCommerce",
    "title": "Life Peaks Integration With Cold Email Sales Tool",
    "h1": "Integrate Life Peaks with SalesBlink",
    "metaDescription": "Integrate Life Peaks and other eCommerce Tools with SalesBlink. Two Way Life Peaks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Webinar Connecter",
    "slug": "webinar-connecter",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0771db7261f62a954ef470669b020dfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webinar Connecter is a tool that can be used to connect webinar software to thousands of other applications.",
    "category": "Webinars",
    "title": "Webinar Connecter Integration With Cold Email Sales Tool",
    "h1": "Integrate Webinar Connecter with SalesBlink",
    "metaDescription": "Integrate Webinar Connecter and other Webinars Tools with SalesBlink. Two Way Webinar Connecter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "businesscards.io",
    "slug": "businesscardsio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/281241baeac4f924a1da3318d0cb4fd6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Minimalist digital business cards that integrate seamlessly with your Apple wallet.",
    "category": "Contact Management",
    "title": "businesscards.io Integration With Cold Email Sales Tool",
    "h1": "Integrate businesscards.io with SalesBlink",
    "metaDescription": "Integrate businesscards.io and other Contact Management Tools with SalesBlink. Two Way businesscards.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Law Dash",
    "slug": "law-dash",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6bbe7d80a98e1834674c05d977bc9fd1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Law Dash is a very simple to use lead tracking dashboard for law firms that even lawyers can understand.",
    "category": "Marketing Automation",
    "title": "Law Dash Integration With Cold Email Sales Tool",
    "h1": "Integrate Law Dash with SalesBlink",
    "metaDescription": "Integrate Law Dash and other Marketing Automation Tools with SalesBlink. Two Way Law Dash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stencil",
    "slug": "stencil",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c869823661db1e8490eaf6bccae30ca.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stencil is an image generation API that automatically creates share images for your content from custom templates.",
    "category": "Marketing",
    "title": "Stencil Integration With Cold Email Sales Tool",
    "h1": "Integrate Stencil with SalesBlink",
    "metaDescription": "Integrate Stencil and other Marketing Tools with SalesBlink. Two Way Stencil Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TemplateTo",
    "slug": "templateto",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4cf3651cf03b7cb9206e5c369cad4573.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TemplateTo allows you to easily create Liquid-based templates for your documents. Utilise our drag-and-drop editor or provide your HTML.",
    "category": "Documents",
    "title": "TemplateTo Integration With Cold Email Sales Tool",
    "h1": "Integrate TemplateTo with SalesBlink",
    "metaDescription": "Integrate TemplateTo and other Documents Tools with SalesBlink. Two Way TemplateTo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OptinMagic",
    "slug": "optinmagic",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/27987fe5741e21be6db979674612cfdf.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your mailing list. Turn traffics into sales and keeps them coming back to you with new offers and promotional emails.",
    "category": "Marketing Automation",
    "title": "OptinMagic Integration With Cold Email Sales Tool",
    "h1": "Integrate OptinMagic with SalesBlink",
    "metaDescription": "Integrate OptinMagic and other Marketing Automation Tools with SalesBlink. Two Way OptinMagic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Booking Radar",
    "slug": "booking-radar",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/adcec471a2be8c9e65facc5aa1bf2348.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Booking Radar is an all-in-one SaaS solution offering online reservation, accounting, marketing, CRM and analytic to grow your business.",
    "category": "Scheduling & Booking",
    "title": "Booking Radar Integration With Cold Email Sales Tool",
    "h1": "Integrate Booking Radar with SalesBlink",
    "metaDescription": "Integrate Booking Radar and other Scheduling & Booking Tools with SalesBlink. Two Way Booking Radar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RapidoReach",
    "slug": "rapidoreach",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/744b17cad1f92ba592c0fba3f7ed1126.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Advanced video and audio based survey tool with text sentiment analysis.",
    "category": "Forms & Surveys",
    "title": "RapidoReach Integration With Cold Email Sales Tool",
    "h1": "Integrate RapidoReach with SalesBlink",
    "metaDescription": "Integrate RapidoReach and other Forms & Surveys Tools with SalesBlink. Two Way RapidoReach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Perfleek",
    "slug": "perfleek",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/46f5e0369ed382c831a524bc140498db_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Perfleek is a CRM for real estate agent and brokers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Perfleek Integration With Cold Email Sales Tool",
    "h1": "Integrate Perfleek with SalesBlink",
    "metaDescription": "Integrate Perfleek and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Perfleek Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Basesnap",
    "slug": "basesnap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/02b07a46b1ec02da9ab8605f02061d9f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Basesnap is a B2B data search engine combining over 1.9 billion company records with key decision maker contacts",
    "category": "Business Intelligence",
    "title": "Basesnap Integration With Cold Email Sales Tool",
    "h1": "Integrate Basesnap with SalesBlink",
    "metaDescription": "Integrate Basesnap and other Business Intelligence Tools with SalesBlink. Two Way Basesnap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blinksale",
    "slug": "blinksale",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/0722fcc2edff118e2878950e8d15f189.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blinksale makes it easy to send invoices and track payments. Every Blinksale invoice and estimate tracks its own history, reminders, comments, payments and more.",
    "category": "Proposal & Invoice Management",
    "title": "Blinksale Integration With Cold Email Sales Tool",
    "h1": "Integrate Blinksale with SalesBlink",
    "metaDescription": "Integrate Blinksale and other Proposal & Invoice Management Tools with SalesBlink. Two Way Blinksale Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Production Flow",
    "slug": "production-flow",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dd71de671b637d19ac34488a73aca88d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Production Flow is a VFX project management app that streamlines production, tracks tasks, manages budgets, and automates outreach and follow-up.",
    "category": "Marketing",
    "title": "Production Flow Integration With Cold Email Sales Tool",
    "h1": "Integrate Production Flow with SalesBlink",
    "metaDescription": "Integrate Production Flow and other Marketing Tools with SalesBlink. Two Way Production Flow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RICOH360 Tours",
    "slug": "ricoh360-tours",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d818c5fc363fcfc5d67013f27091bf43.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RICOH360 Tours is a cloud-based platform to create interactive 360° Virtual Tours for homes and commercial properties.",
    "category": "Video & Audio",
    "title": "RICOH360 Tours Integration With Cold Email Sales Tool",
    "h1": "Integrate RICOH360 Tours with SalesBlink",
    "metaDescription": "Integrate RICOH360 Tours and other Video & Audio Tools with SalesBlink. Two Way RICOH360 Tours Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ocoya",
    "slug": "ocoya",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c705d8e648c1ce87a314533da8193898_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ocoya allows you to create and schedule social media content quicker using AI.",
    "category": "Social Media Marketing",
    "title": "Ocoya Integration With Cold Email Sales Tool",
    "h1": "Integrate Ocoya with SalesBlink",
    "metaDescription": "Integrate Ocoya and other Social Media Marketing Tools with SalesBlink. Two Way Ocoya Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cosiall",
    "slug": "cosiall",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a3e5e13a4841dfa2c7bcb7cbc30bc485.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cosiall offers online personalized advertising for SMEs. We help businesses jump-start their growth, boost their brand awareness, and find qualified leads.",
    "category": "Marketing",
    "title": "Cosiall Integration With Cold Email Sales Tool",
    "h1": "Integrate Cosiall with SalesBlink",
    "metaDescription": "Integrate Cosiall and other Marketing Tools with SalesBlink. Two Way Cosiall Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "scale up",
    "slug": "scale-up",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cef6f648b5cc17b7356f4a7d5b65dd87_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The scale up app is a tool that enables entrepreneurs to learn the scale up framework and to implement it with a digital toolset in their company.",
    "category": "Business Intelligence",
    "title": "scale up Integration With Cold Email Sales Tool",
    "h1": "Integrate scale up with SalesBlink",
    "metaDescription": "Integrate scale up and other Business Intelligence Tools with SalesBlink. Two Way scale up Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YepCode",
    "slug": "yepcode",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bb3117f80dd724913c5b80efa4dcdea6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "YepCode is a platform that connects your services and APIs using code, allowing to skip limitations that you could face with no-code tools.",
    "category": "Developer Tools",
    "title": "YepCode Integration With Cold Email Sales Tool",
    "h1": "Integrate YepCode with SalesBlink",
    "metaDescription": "Integrate YepCode and other Developer Tools Tools with SalesBlink. Two Way YepCode Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "VOXO",
    "slug": "voxo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/71d2fabe2028b961cc48647a2e642b90.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VOXO is a cloud communications company helping teams simply communicate with their customers.",
    "category": "Communication",
    "title": "VOXO Integration With Cold Email Sales Tool",
    "h1": "Integrate VOXO with SalesBlink",
    "metaDescription": "Integrate VOXO and other Communication Tools with SalesBlink. Two Way VOXO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Doerkit",
    "slug": "doerkit",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f33376f23e40044dfa40d124b0b3053c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Doerkit is everything you need to build a better business.",
    "category": "Project Management",
    "title": "Doerkit Integration With Cold Email Sales Tool",
    "h1": "Integrate Doerkit with SalesBlink",
    "metaDescription": "Integrate Doerkit and other Project Management Tools with SalesBlink. Two Way Doerkit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Baton",
    "slug": "baton",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7c84e01cdfbe4b6da3aba7aa05e5e77a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Baton is the leading platform for managing software implementations and customer onboarding.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Baton Integration With Cold Email Sales Tool",
    "h1": "Integrate Baton with SalesBlink",
    "metaDescription": "Integrate Baton and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Baton Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Check In Pointe",
    "slug": "check-in-pointe",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1653c03481151647f0b420904e238f60_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Backstage security is an important part of your dance recital. Check In Pointe makes checking in and out dancers simple!",
    "category": "Education",
    "title": "Check In Pointe Integration With Cold Email Sales Tool",
    "h1": "Integrate Check In Pointe with SalesBlink",
    "metaDescription": "Integrate Check In Pointe and other Education Tools with SalesBlink. Two Way Check In Pointe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneDeck",
    "slug": "onedeck",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/674c479380b7890b9e21560ab661515a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneDeck is a smart and automated platform for all business management needs. Workflow, collaboration, data management and communication.",
    "category": "CRM (Customer Relationship Management)",
    "title": "OneDeck Integration With Cold Email Sales Tool",
    "h1": "Integrate OneDeck with SalesBlink",
    "metaDescription": "Integrate OneDeck and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way OneDeck Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DaySchedule",
    "slug": "dayschedule",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/06f34cc928d56a6f8f02871644aeb6e7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DaySchedule is an online appointment software that helps businesses and professionals manage their appointments and meetings online.",
    "category": "Scheduling & Booking",
    "title": "DaySchedule Integration With Cold Email Sales Tool",
    "h1": "Integrate DaySchedule with SalesBlink",
    "metaDescription": "Integrate DaySchedule and other Scheduling & Booking Tools with SalesBlink. Two Way DaySchedule Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Outpost",
    "slug": "outpost",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/7f7cf3c07fba956262970c6bf57ff48b_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Outpost is a Deal Flow Management Platform",
    "category": "CRM (Customer Relationship Management)",
    "title": "Outpost Integration With Cold Email Sales Tool",
    "h1": "Integrate Outpost with SalesBlink",
    "metaDescription": "Integrate Outpost and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Outpost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Event Penguin",
    "slug": "event-penguin",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d60a65b595d326625d91f14d905aebd6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Event Penguin is an all-in-one event management platform that helps businesses and individuals plan, promote, and manage successful events.",
    "category": "Event Management",
    "title": "Event Penguin Integration With Cold Email Sales Tool",
    "h1": "Integrate Event Penguin with SalesBlink",
    "metaDescription": "Integrate Event Penguin and other Event Management Tools with SalesBlink. Two Way Event Penguin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GoBrunch",
    "slug": "gobrunch",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3eb33d7ac48bc629a2815f11d31b74df.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "GoBrunch is a video collaboration platform that makes you feel inside a real environment.",
    "category": "Video Conferencing",
    "title": "GoBrunch Integration With Cold Email Sales Tool",
    "h1": "Integrate GoBrunch with SalesBlink",
    "metaDescription": "Integrate GoBrunch and other Video Conferencing Tools with SalesBlink. Two Way GoBrunch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hirevire",
    "slug": "hirevire",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e5265afd0a5f092a1fb75ea119e7a234.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hirevire is a screening automation tool with a stupid-simple interface to collect video, audio, text and file responses from applicants.",
    "category": "HR Talent & Recruitment",
    "title": "Hirevire Integration With Cold Email Sales Tool",
    "h1": "Integrate Hirevire with SalesBlink",
    "metaDescription": "Integrate Hirevire and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hirevire Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Allocadence",
    "slug": "allocadence",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/38b7072d9c576aa748e4ce117299aa20_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Allocadence is an enterprise warehouse management system built to help agencies, institutions, and ecommerce companies manage inventory and fulfill orders accurately.",
    "category": "Task Management",
    "title": "Allocadence Integration With Cold Email Sales Tool",
    "h1": "Integrate Allocadence with SalesBlink",
    "metaDescription": "Integrate Allocadence and other Task Management Tools with SalesBlink. Two Way Allocadence Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Epsilo",
    "slug": "epsilo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ae4b7e7c6996b7ccb57942209db94941.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Epsilo is a closed-loop operating hub to unleash the potential of on-platform advertising",
    "category": "Marketing Automation",
    "title": "Epsilo Integration With Cold Email Sales Tool",
    "h1": "Integrate Epsilo with SalesBlink",
    "metaDescription": "Integrate Epsilo and other Marketing Automation Tools with SalesBlink. Two Way Epsilo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "LinkAce",
    "slug": "linkace",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e785cc42f7c07762ae044deb560ee2ad_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LinkAce is a self-hosted bookmark archive to help you store and later search through the websites you love.",
    "category": "Bookmark Managers",
    "title": "LinkAce Integration With Cold Email Sales Tool",
    "h1": "Integrate LinkAce with SalesBlink",
    "metaDescription": "Integrate LinkAce and other Bookmark Managers Tools with SalesBlink. Two Way LinkAce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Palette",
    "slug": "palette",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b0dc3073829be780a493f3102c33bf5d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Palette's AI-powered colorization service turns a black and white image into one or many colorized variations.",
    "category": "AI Tools",
    "title": "Palette Integration With Cold Email Sales Tool",
    "h1": "Integrate Palette with SalesBlink",
    "metaDescription": "Integrate Palette and other AI Tools Tools with SalesBlink. Two Way Palette Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taim",
    "slug": "taim",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c100988340c796db2eecbdd10de728da_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taim is a virtual assistant that helps you confirm appointments, keeping you and your clients on top of your schedule.",
    "category": "Scheduling & Booking",
    "title": "Taim Integration With Cold Email Sales Tool",
    "h1": "Integrate Taim with SalesBlink",
    "metaDescription": "Integrate Taim and other Scheduling & Booking Tools with SalesBlink. Two Way Taim Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contractor+",
    "slug": "contractor-plus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/438d600f5978daf0f32ae09b7e004620.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contractor+ facilitates contractors relationships with their Clients, REI’s & Property Managers. Scheduling, Estimating, Invoicing & more.",
    "category": "Scheduling & Booking",
    "title": "Contractor+ Integration With Cold Email Sales Tool",
    "h1": "Integrate Contractor+ with SalesBlink",
    "metaDescription": "Integrate Contractor+ and other Scheduling & Booking Tools with SalesBlink. Two Way Contractor+ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CampaignHQ",
    "slug": "campaignhq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/393dce1658fa68348aa1e4f34b98acfa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CampaignHQ is your affordable email marketing partner. Email marketing done right.",
    "category": "Email Newsletters",
    "title": "CampaignHQ Integration With Cold Email Sales Tool",
    "h1": "Integrate CampaignHQ with SalesBlink",
    "metaDescription": "Integrate CampaignHQ and other Email Newsletters Tools with SalesBlink. Two Way CampaignHQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Webbosaurus",
    "slug": "webbosaurus",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fcfda1609592451d9bbbd5444f7582a4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webbosaurus allows you to monitor and analyse thousands of online reviews",
    "category": "Marketing Automation",
    "title": "Webbosaurus Integration With Cold Email Sales Tool",
    "h1": "Integrate Webbosaurus with SalesBlink",
    "metaDescription": "Integrate Webbosaurus and other Marketing Automation Tools with SalesBlink. Two Way Webbosaurus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MoneyTool.io",
    "slug": "moneytoolio",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d4dd521a76123038d5db0a0bdba84faa.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MoneyTool.io is a simple and speedy tool for tracking how much you spend on things. This is great for starting, and sticking to a budget.",
    "category": "Accounting",
    "title": "MoneyTool.io Integration With Cold Email Sales Tool",
    "h1": "Integrate MoneyTool.io with SalesBlink",
    "metaDescription": "Integrate MoneyTool.io and other Accounting Tools with SalesBlink. Two Way MoneyTool.io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Practice",
    "slug": "practice",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4a06d887b2284a470cfb6ea653dd9442.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Practice is the easiest way to manage your entire business. Scheduling, payments, client management, and everything for your business under one roof.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Practice Integration With Cold Email Sales Tool",
    "h1": "Integrate Practice with SalesBlink",
    "metaDescription": "Integrate Practice and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Practice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cheddar Up",
    "slug": "cheddar-up",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/fddf52c961627d1e64f1974b6fd05442.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Collect money online in minutes. Cheddar Up helps you collect and track payments and information from your group or community.",
    "category": "Payment Processing",
    "title": "Cheddar Up Integration With Cold Email Sales Tool",
    "h1": "Integrate Cheddar Up with SalesBlink",
    "metaDescription": "Integrate Cheddar Up and other Payment Processing Tools with SalesBlink. Two Way Cheddar Up Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adventure Office",
    "slug": "adventure-office",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/172413b09ff0b69664930d39404c3937_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adventure Office Software provides reservation management, online bookings, operations management, payment processing and more for the outdoor industry.",
    "category": "Scheduling & Booking",
    "title": "Adventure Office Integration With Cold Email Sales Tool",
    "h1": "Integrate Adventure Office with SalesBlink",
    "metaDescription": "Integrate Adventure Office and other Scheduling & Booking Tools with SalesBlink. Two Way Adventure Office Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plezi (Plus & Star)",
    "slug": "plezi-plus-star",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8b36a5b1494d0870ff01a7a14893a613_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Plezi is the perfect marketing automation software for overwhelmed marketers to launch and develop your B2B marketing strategy.",
    "category": "Marketing",
    "title": "Plezi (Plus & Star) Integration With Cold Email Sales Tool",
    "h1": "Integrate Plezi (Plus & Star) with SalesBlink",
    "metaDescription": "Integrate Plezi (Plus & Star) and other Marketing Tools with SalesBlink. Two Way Plezi (Plus & Star) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SignMore",
    "slug": "signmore",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/bd9d908928020eec21300a403ef9bd1f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grow your business with SignMore’s 24/7 property focused call answering and lead qualification services.",
    "category": "Communication",
    "title": "SignMore Integration With Cold Email Sales Tool",
    "h1": "Integrate SignMore with SalesBlink",
    "metaDescription": "Integrate SignMore and other Communication Tools with SalesBlink. Two Way SignMore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Club Systems",
    "slug": "club-systems",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/8d93a2841679dc8fd1ede14f19ba81e4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Club Systems is a fitness management system.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Club Systems Integration With Cold Email Sales Tool",
    "h1": "Integrate Club Systems with SalesBlink",
    "metaDescription": "Integrate Club Systems and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Club Systems Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "HeyGen",
    "slug": "heygen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/60a77ace7f7a411c8f6ee6480ca62c26.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meet HeyGen - The best AI video generation platform for your team.",
    "category": "AI Tools",
    "title": "HeyGen Integration With Cold Email Sales Tool",
    "h1": "Integrate HeyGen with SalesBlink",
    "metaDescription": "Integrate HeyGen and other AI Tools Tools with SalesBlink. Two Way HeyGen Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "AudioPen",
    "slug": "audiopen",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/037cb93e4aa3ad3ebf45aa670b4b3973.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AudioPen enables you to convert unstructured voice notes into clearly summarized text.",
    "category": "Notes",
    "title": "AudioPen Integration With Cold Email Sales Tool",
    "h1": "Integrate AudioPen with SalesBlink",
    "metaDescription": "Integrate AudioPen and other Notes Tools with SalesBlink. Two Way AudioPen Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Splite",
    "slug": "splite",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4e96d5ef08cce96befcd15b0cabc83e2_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With Splite connected business cards, you'll be able to exchange contact information quickly and easily with a single touch.",
    "category": "Forms & Surveys",
    "title": "Splite Integration With Cold Email Sales Tool",
    "h1": "Integrate Splite with SalesBlink",
    "metaDescription": "Integrate Splite and other Forms & Surveys Tools with SalesBlink. Two Way Splite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TaxPlanIQ",
    "slug": "taxplaniq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/c1b55a1f300089183c22a8e2572b306f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TaxPlanIQ is an easy-to-use tax planning SaaS platform designed to make Tax Planning quick, easy, and profitable for tax planners.",
    "category": "",
    "title": "TaxPlanIQ Integration With Cold Email Sales Tool",
    "h1": "Integrate TaxPlanIQ with SalesBlink",
    "metaDescription": "Integrate TaxPlanIQ and other  Tools with SalesBlink. Two Way TaxPlanIQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "crowd.dev",
    "slug": "crowddev",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/3f392b054e4c28f5085712f98a92fcfc.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "crowd.dev is an open-source suite of community and data tools built to unlock community-led growth for your organization.",
    "category": "CRM (Customer Relationship Management)",
    "title": "crowd.dev Integration With Cold Email Sales Tool",
    "h1": "Integrate crowd.dev with SalesBlink",
    "metaDescription": "Integrate crowd.dev and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way crowd.dev Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elements Financial Monitoring System",
    "slug": "elements-financial-monitoring",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d9f709a1ba6ca58f652db99c8a4532c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Elements is designed to help financial advisors quickly demonstrate their value, so they can get new clients.",
    "category": "Forms & Surveys",
    "title": "Elements Financial Monitoring System Integration With Cold Email Sales Tool",
    "h1": "Integrate Elements Financial Monitoring System with SalesBlink",
    "metaDescription": "Integrate Elements Financial Monitoring System and other Forms & Surveys Tools with SalesBlink. Two Way Elements Financial Monitoring System Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trail Blazer IQ",
    "slug": "trail-blazer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/6486b03462c2839fd75ce1695e0f8af3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Trail Blazer IQ is software for nonprofit donor management, political campaigns, and PACs.",
    "category": "Contact Management",
    "title": "Trail Blazer IQ Integration With Cold Email Sales Tool",
    "h1": "Integrate Trail Blazer IQ with SalesBlink",
    "metaDescription": "Integrate Trail Blazer IQ and other Contact Management Tools with SalesBlink. Two Way Trail Blazer IQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FlashInfo",
    "slug": "flashinfo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/67bb504bc61a3cbd44deebdef32781be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FlashInfo is a GTM Intelligence company. FlashInfo helps marketing and sales teams improve their overall GTM strategy and build ICP.",
    "category": "Sales & CRM",
    "title": "FlashInfo Integration With Cold Email Sales Tool",
    "h1": "Integrate FlashInfo with SalesBlink",
    "metaDescription": "Integrate FlashInfo and other Sales & CRM Tools with SalesBlink. Two Way FlashInfo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IP2Location IO",
    "slug": "ip2location-io",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/959a1d26d30a6c546866bf9a9a056115.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IP2Location.io provides a fast and accurate IP Geolocation API tool to determine a user's location and use the geolocation information in different use cases.",
    "category": "Developer Tools",
    "title": "IP2Location IO Integration With Cold Email Sales Tool",
    "h1": "Integrate IP2Location IO with SalesBlink",
    "metaDescription": "Integrate IP2Location IO and other Developer Tools Tools with SalesBlink. Two Way IP2Location IO Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tactyc",
    "slug": "tactyc",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/517c4ee288cada5489844775b46513f4_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tactyc is a software for venture funds, enabling portfolio construction, management, forecasting and reporting in one platform. Zero spreadsheets involved.",
    "category": "Analytics",
    "title": "Tactyc Integration With Cold Email Sales Tool",
    "h1": "Integrate Tactyc with SalesBlink",
    "metaDescription": "Integrate Tactyc and other Analytics Tools with SalesBlink. Two Way Tactyc Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instant Roofer",
    "slug": "instant-roofer",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e26847522cafe7cd69a51f8f49ce272b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send your online roof replacement leads straight from Instant Roofer to your favorite CRM, Google Sheets, Email, and more.",
    "category": "Business Intelligence",
    "title": "Instant Roofer Integration With Cold Email Sales Tool",
    "h1": "Integrate Instant Roofer with SalesBlink",
    "metaDescription": "Integrate Instant Roofer and other Business Intelligence Tools with SalesBlink. Two Way Instant Roofer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FUGO Digital Signage",
    "slug": "fugo-digital-signage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/d3f9257ac96fc98163ba234fa91fab4c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fugo is digital signage software that allows yuo display business dashboards, social media walls, weather, news, and more on any screen.",
    "category": "Marketing",
    "title": "FUGO Digital Signage Integration With Cold Email Sales Tool",
    "h1": "Integrate FUGO Digital Signage with SalesBlink",
    "metaDescription": "Integrate FUGO Digital Signage and other Marketing Tools with SalesBlink. Two Way FUGO Digital Signage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WooDelivery",
    "slug": "woodelivery",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/520f83d7bbcf497aae1c68cc98ae7b13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WooDelivery is an innovative delivery management app for your local delivery business. Take your delivery operations to the next level with advanced route optimization, proof-of-delivery, real-time tracking and powerful analytics all packed into one platform.",
    "category": "eCommerce",
    "title": "WooDelivery Integration With Cold Email Sales Tool",
    "h1": "Integrate WooDelivery with SalesBlink",
    "metaDescription": "Integrate WooDelivery and other eCommerce Tools with SalesBlink. Two Way WooDelivery Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Postal Methods",
    "slug": "postal-methods",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/ed22dd4b8d7f504d26e14100b635e51a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Postal Methods offers the opportunity for simple, affordable mail services. Our goal is to provide the mailing solution that fulfills countless possibilities for all.",
    "category": "Communication",
    "title": "Postal Methods Integration With Cold Email Sales Tool",
    "h1": "Integrate Postal Methods with SalesBlink",
    "metaDescription": "Integrate Postal Methods and other Communication Tools with SalesBlink. Two Way Postal Methods Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesmap",
    "slug": "salesmap",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/cf8cd8e419627b0a458e73fd31c8550b.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesMap is a data-driven CRM for sales growth.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Salesmap Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesmap with SalesBlink",
    "metaDescription": "Integrate Salesmap and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Salesmap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Teachify",
    "slug": "teachify",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/db089d8df28e75b7edef6048b9eafc80.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Teachify is a website builder for content creators to create and sell online courses.",
    "category": "Website Builders",
    "title": "Teachify Integration With Cold Email Sales Tool",
    "h1": "Integrate Teachify with SalesBlink",
    "metaDescription": "Integrate Teachify and other Website Builders Tools with SalesBlink. Two Way Teachify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Desk-Net",
    "slug": "desk-net",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b439c500c2f9b4b2727e391eddda1a05.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Desk-Net is a content calendar tool for newsrooms, publishers and communications teams.",
    "category": "Content & Files",
    "title": "Desk-Net Integration With Cold Email Sales Tool",
    "h1": "Integrate Desk-Net with SalesBlink",
    "metaDescription": "Integrate Desk-Net and other Content & Files Tools with SalesBlink. Two Way Desk-Net Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rackoot",
    "slug": "rackoot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4ecfcc872d7b57e09c5f99802df0325a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rackoot is the starting point for everything you have in the cloud and much more!",
    "category": "Productivity",
    "title": "Rackoot Integration With Cold Email Sales Tool",
    "h1": "Integrate Rackoot with SalesBlink",
    "metaDescription": "Integrate Rackoot and other Productivity Tools with SalesBlink. Two Way Rackoot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NextStage",
    "slug": "nextstage",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/e065554b8007debb0421d33ea1c3f302_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NextStage is a CRM for Government Contractors",
    "category": "Sales & CRM",
    "title": "NextStage Integration With Cold Email Sales Tool",
    "h1": "Integrate NextStage with SalesBlink",
    "metaDescription": "Integrate NextStage and other Sales & CRM Tools with SalesBlink. Two Way NextStage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Diffchecker",
    "slug": "diffchecker",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f21db466abf4208761dd1464e302c737.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Diffchecker is a file comparison tool that will compare text to find the difference between two pieces of text.",
    "category": "Developer Tools",
    "title": "Diffchecker Integration With Cold Email Sales Tool",
    "h1": "Integrate Diffchecker with SalesBlink",
    "metaDescription": "Integrate Diffchecker and other Developer Tools Tools with SalesBlink. Two Way Diffchecker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Revi.io Reviews",
    "slug": "reviio-reviews",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/53f49d04343c33c3d7dc6a28f56edc25.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Revi is an online review system that helps companies of all sizes collect and showcase reviews from their customers.",
    "category": "eCommerce",
    "title": "Revi.io Reviews Integration With Cold Email Sales Tool",
    "h1": "Integrate Revi.io Reviews with SalesBlink",
    "metaDescription": "Integrate Revi.io Reviews and other eCommerce Tools with SalesBlink. Two Way Revi.io Reviews Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Linework",
    "slug": "linework",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/562e70ea65fbcba6eea2f880bec1fe50.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your tattoo business with ease using Linework: schedule appointments, handle cancellations and no-shows, and streamline daily operations.",
    "category": "Scheduling & Booking",
    "title": "Linework Integration With Cold Email Sales Tool",
    "h1": "Integrate Linework with SalesBlink",
    "metaDescription": "Integrate Linework and other Scheduling & Booking Tools with SalesBlink. Two Way Linework Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DeftShip",
    "slug": "deftship",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/4c70491e2e907c352147a5675e3e7c1a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DeftShip is a custom-built, cloud-based logistics solution designed to save customers time, effort, and money while building your businesses.",
    "category": "eCommerce",
    "title": "DeftShip Integration With Cold Email Sales Tool",
    "h1": "Integrate DeftShip with SalesBlink",
    "metaDescription": "Integrate DeftShip and other eCommerce Tools with SalesBlink. Two Way DeftShip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fidelimax",
    "slug": "fidelimax",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/47ac448e9f6df5b3d95fa887cccf1cad.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fidelimax is a loyalty program designed to automate relationship marketing and manage the entire loyalty program",
    "category": "CRM (Customer Relationship Management)",
    "title": "Fidelimax Integration With Cold Email Sales Tool",
    "h1": "Integrate Fidelimax with SalesBlink",
    "metaDescription": "Integrate Fidelimax and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Fidelimax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onalu",
    "slug": "onalu",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/57d9892ffeb6814011751a1a43e548f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Onalu is a new kind of spreadsheet with the power to automate the human side of your business process.",
    "category": "Website & App Building",
    "title": "Onalu Integration With Cold Email Sales Tool",
    "h1": "Integrate Onalu with SalesBlink",
    "metaDescription": "Integrate Onalu and other Website & App Building Tools with SalesBlink. Two Way Onalu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tremendous",
    "slug": "tremendous",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/dc46cffd144804dc4474ed63aeac8bfe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tremendous allows teams to send digital payments internationally at scale. Instant delivery, frustration-free redemption, hundreds of reward options.",
    "category": "Payment Processing",
    "title": "Tremendous Integration With Cold Email Sales Tool",
    "h1": "Integrate Tremendous with SalesBlink",
    "metaDescription": "Integrate Tremendous and other Payment Processing Tools with SalesBlink. Two Way Tremendous Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Toket",
    "slug": "toket",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1b04fe1fa3e056daacec27b4e0e09651.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Toket provides tools that allow developers to easily issue & manage NFTs in their applications.",
    "category": "Developer Tools",
    "title": "Toket Integration With Cold Email Sales Tool",
    "h1": "Integrate Toket with SalesBlink",
    "metaDescription": "Integrate Toket and other Developer Tools Tools with SalesBlink. Two Way Toket Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Classmarker",
    "slug": "classmarker-1",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1e6870064678c8452ab316b10c460d87.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClassMarker is a professional online exam creator that allows you to streamline the way you build custom assessments and tests for business and education.",
    "category": "Forms & Surveys",
    "title": "Classmarker Integration With Cold Email Sales Tool",
    "h1": "Integrate Classmarker with SalesBlink",
    "metaDescription": "Integrate Classmarker and other Forms & Surveys Tools with SalesBlink. Two Way Classmarker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gatheroo",
    "slug": "gatheroo",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/a2e6d8cbc18af5c8449e137d1e42e52f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gatheroo is a team collaboration tool to collect and collate information and documents efficiently.",
    "category": "App Builder",
    "title": "Gatheroo Integration With Cold Email Sales Tool",
    "h1": "Integrate Gatheroo with SalesBlink",
    "metaDescription": "Integrate Gatheroo and other App Builder Tools with SalesBlink. Two Way Gatheroo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextCortex AI",
    "slug": "textcortex-ai",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/1c6eca8a6d1027f38455859c8bc63945.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextCortex AI offers NLP capabilities, enabling developers to efficiently integrate language-based features into their applications.",
    "category": "AI Tools",
    "title": "TextCortex AI Integration With Cold Email Sales Tool",
    "h1": "Integrate TextCortex AI with SalesBlink",
    "metaDescription": "Integrate TextCortex AI and other AI Tools Tools with SalesBlink. Two Way TextCortex AI Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Taktikal",
    "slug": "taktikal",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/b8e0bb27f072673602cd764ca68077be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monitor activity on your Taktikal signing processes. Collect all data for various events.",
    "category": "Content & Files",
    "title": "Taktikal Integration With Cold Email Sales Tool",
    "h1": "Integrate Taktikal with SalesBlink",
    "metaDescription": "Integrate Taktikal and other Content & Files Tools with SalesBlink. Two Way Taktikal Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Copilot CRM",
    "slug": "copilot-crm",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/20dbe663012ed09c984321c79829e6d5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Copilot CRM automates business processes and workflows by scheduling, routing, automatic invoicing, payment processing, and online booking.",
    "category": "Sales & CRM",
    "title": "Copilot CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Copilot CRM with SalesBlink",
    "metaDescription": "Integrate Copilot CRM and other Sales & CRM Tools with SalesBlink. Two Way Copilot CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Understand.me",
    "slug": "understandme",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/62d4dcdeb2d9f31e9615bd1d939ee5a0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Understand.me is a tool to organize personality assessments",
    "category": "Marketing",
    "title": "Understand.me Integration With Cold Email Sales Tool",
    "h1": "Integrate Understand.me with SalesBlink",
    "metaDescription": "Integrate Understand.me and other Marketing Tools with SalesBlink. Two Way Understand.me Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BrokerSpot",
    "slug": "brokerspot",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/85e7af9db63227227cf9745e578d878f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BrokerSpot: Your ultimate real estate lead generation solution. Connect with high-quality leads and close deals faster. Maximize your success!",
    "category": "Ads & Conversion",
    "title": "BrokerSpot Integration With Cold Email Sales Tool",
    "h1": "Integrate BrokerSpot with SalesBlink",
    "metaDescription": "Integrate BrokerSpot and other Ads & Conversion Tools with SalesBlink. Two Way BrokerSpot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taptiq",
    "slug": "taptiq",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/73e53375f9d7c8bf4215c2bfd64ec6ad_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taptiq provides an affordable tool for non-tech people to launch their own personalized engagement web and mobile platform in minutes",
    "category": "App Builder",
    "title": "Taptiq Integration With Cold Email Sales Tool",
    "h1": "Integrate Taptiq with SalesBlink",
    "metaDescription": "Integrate Taptiq and other App Builder Tools with SalesBlink. Two Way Taptiq Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailmeteor",
    "slug": "mailmeteor",
    "logo": "https://zapier-images.imgix.net/storage/developer_cli/f87a1d223ffdb11f584b6b7cb8940c31_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailmeteor is a trusted email marketing solution to send personalized mass emails.",
    "category": "Communication",
    "title": "Mailmeteor Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailmeteor with SalesBlink",
    "metaDescription": "Integrate Mailmeteor and other Communication Tools with SalesBlink. Two Way Mailmeteor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spreedly",
    "slug": "spreedly",
    "logo": "https://zapier-images.imgix.net/storage/services/2f6421857dd5bce4362512b4e6d625c7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spreedly is cloud-based payments infrastructure that allows businesses to work with multiple payment gateways simultaneously.",
    "category": "Payment Processing",
    "title": "Spreedly Integration With Cold Email Sales Tool",
    "h1": "Integrate Spreedly with SalesBlink",
    "metaDescription": "Integrate Spreedly and other Payment Processing Tools with SalesBlink. Two Way Spreedly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Get Satisfaction",
    "slug": "get-satisfaction",
    "logo": "https://zapier-images.imgix.net/storage/services/a77b09bac441c1fd521e00eb31affc10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Get Satisfaction is a simple way add support forums to your site. Get Satisfaction lets you create a forum for users to give feedback, make suggestions on new features, and lets you update users on development.",
    "category": "Customer Appreciation",
    "title": "Get Satisfaction Integration With Cold Email Sales Tool",
    "h1": "Integrate Get Satisfaction with SalesBlink",
    "metaDescription": "Integrate Get Satisfaction and other Customer Appreciation Tools with SalesBlink. Two Way Get Satisfaction Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zuora",
    "slug": "zuora",
    "logo": "https://zapier-images.imgix.net/storage/services/9d838069ffcba71e813755c0ca1072fd.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zuora is a world class subscription service management system used by hundreds of companies across the world.",
    "category": "Payment Processing",
    "title": "Zuora Integration With Cold Email Sales Tool",
    "h1": "Integrate Zuora with SalesBlink",
    "metaDescription": "Integrate Zuora and other Payment Processing Tools with SalesBlink. Two Way Zuora Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taleo",
    "slug": "taleo",
    "logo": "https://zapier-images.imgix.net/storage/services/8379031f29c5471b71c0b70aa9e482ee_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taleo Corporation is a provider of cloud-based talent management solutions, enabling businesses to recruit eligible candidates.",
    "category": "HR Talent & Recruitment",
    "title": "Taleo Integration With Cold Email Sales Tool",
    "h1": "Integrate Taleo with SalesBlink",
    "metaDescription": "Integrate Taleo and other HR Talent & Recruitment Tools with SalesBlink. Two Way Taleo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NetSuite",
    "slug": "netsuite",
    "logo": "https://zapier-images.imgix.net/storage/services/738c1145baf81042337c6bf4c321f6a8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NetSuite offers a range of cloud based software solutions that include customer service management, enterprise resource planning and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "NetSuite Integration With Cold Email Sales Tool",
    "h1": "Integrate NetSuite with SalesBlink",
    "metaDescription": "Integrate NetSuite and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way NetSuite Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Dwolla",
    "slug": "dwolla",
    "logo": "https://zapier-images.imgix.net/storage/services/999a364f0d98c8393da278bf9f5cf810.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Say goodbye to plastic cards, ATM fees, hidden charges and fine print. Dwolla empowers anyone with an internet connection to safely send money to friends or businesses.",
    "category": "Payment Processing",
    "title": "Dwolla Integration With Cold Email Sales Tool",
    "h1": "Integrate Dwolla with SalesBlink",
    "metaDescription": "Integrate Dwolla and other Payment Processing Tools with SalesBlink. Two Way Dwolla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Authorize.net",
    "slug": "authorize-net",
    "logo": "https://zapier-images.imgix.net/storage/services/c4e89aa7df12c6ae38b426a09482d964.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Authorize.net is a world leading payment system.",
    "category": "Payment Processing",
    "title": "Authorize.net Integration With Cold Email Sales Tool",
    "h1": "Integrate Authorize.net with SalesBlink",
    "metaDescription": "Integrate Authorize.net and other Payment Processing Tools with SalesBlink. Two Way Authorize.net Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Unleashed Software",
    "slug": "unleashed-software",
    "logo": "https://zapier-images.imgix.net/storage/services/7b5773683d9109a452ec62d1e419f428.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UnleashedSoftware is a world class online inventory management software for small, medium and large business which integrates closely with Xero.",
    "category": "eCommerce",
    "title": "Unleashed Software Integration With Cold Email Sales Tool",
    "h1": "Integrate Unleashed Software with SalesBlink",
    "metaDescription": "Integrate Unleashed Software and other eCommerce Tools with SalesBlink. Two Way Unleashed Software Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "TaskRabbit",
    "slug": "taskrabbit",
    "logo": "https://zapier-images.imgix.net/storage/services/baf175e973e9b9fdaa9318b72aea3694_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TaskRabbit is an online and mobile marketplace that matches freelance labor with local demand.",
    "category": "eCommerce",
    "title": "TaskRabbit Integration With Cold Email Sales Tool",
    "h1": "Integrate TaskRabbit with SalesBlink",
    "metaDescription": "Integrate TaskRabbit and other eCommerce Tools with SalesBlink. Two Way TaskRabbit Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spree",
    "slug": "spree",
    "logo": "https://zapier-images.imgix.net/storage/services/71804ad49874341e5ecf9bfc0235b578_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Modular & API-driven open source eCommerce platform for Ruby on Rails",
    "category": "eCommerce",
    "title": "Spree Integration With Cold Email Sales Tool",
    "h1": "Integrate Spree with SalesBlink",
    "metaDescription": "Integrate Spree and other eCommerce Tools with SalesBlink. Two Way Spree Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Feng Office",
    "slug": "feng-office",
    "logo": "https://zapier-images.imgix.net/storage/services/fd7e036fc7f0034bd1a67cfa0157ed89_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A collaborative Platform that includes Project Management and CRM. It integrates tasks, documents, emails, calendars, notes and web links.",
    "category": "Team Collaboration",
    "title": "Feng Office Integration With Cold Email Sales Tool",
    "h1": "Integrate Feng Office with SalesBlink",
    "metaDescription": "Integrate Feng Office and other Team Collaboration Tools with SalesBlink. Two Way Feng Office Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Joomla!",
    "slug": "joomla",
    "logo": "https://zapier-images.imgix.net/storage/services/5999f1c3c994ccf3e29ab59bbe89fda8.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Joomla! is a popular open source CMS that is powerful and easy to use. Combined with extensions like RSform and Proforms, its a powerful tool for creating a web presence.",
    "category": "Website Builders",
    "title": "Joomla! Integration With Cold Email Sales Tool",
    "h1": "Integrate Joomla! with SalesBlink",
    "metaDescription": "Integrate Joomla! and other Website Builders Tools with SalesBlink. Two Way Joomla! Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Foursquare",
    "slug": "foursquare",
    "logo": "https://zapier-images.imgix.net/storage/services/0d8fb6d29172bd6afd9bcce5545d99db.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swarm by Foursquare is a popular geographical location-based social network that incorporates gaming elements.",
    "category": "Social Media Accounts",
    "title": "Foursquare Integration With Cold Email Sales Tool",
    "h1": "Integrate Foursquare with SalesBlink",
    "metaDescription": "Integrate Foursquare and other Social Media Accounts Tools with SalesBlink. Two Way Foursquare Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "ExactTarget",
    "slug": "exacttarget",
    "logo": "https://zapier-images.imgix.net/storage/services/06dbd87777cf4ebe505650a53ab3679a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ExactTarget is a suite of marketing tools that helps marketers deliver email marketing, mobile marketing, and social media marketing in realtime across multiple channels to drive customer engagement, automate marketing, increase sales, and improve return on marketing investment.",
    "category": "Marketing Automation",
    "title": "ExactTarget Integration With Cold Email Sales Tool",
    "h1": "Integrate ExactTarget with SalesBlink",
    "metaDescription": "Integrate ExactTarget and other Marketing Automation Tools with SalesBlink. Two Way ExactTarget Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parse",
    "slug": "parse",
    "logo": "https://zapier-images.imgix.net/storage/services/b82427e4d308edc3771ca1b317dab74d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parse makes it easy to add a backend to mobile apps and much, much more. It talks simple JSON but is extremely powerful and therefore very flexible.",
    "category": "Developer Tools",
    "title": "Parse Integration With Cold Email Sales Tool",
    "h1": "Integrate Parse with SalesBlink",
    "metaDescription": "Integrate Parse and other Developer Tools Tools with SalesBlink. Two Way Parse Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shipwire",
    "slug": "shipwire",
    "logo": "https://zapier-images.imgix.net/storage/services/050a44593fc7158ef6ebbde23de5040e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shipwire makes it simple to outsource ecommerce order fulfillment and logistics services for businesses. They have warehouses located in the US, Canada, UK, and Hong Kong.",
    "category": "eCommerce",
    "title": "Shipwire Integration With Cold Email Sales Tool",
    "h1": "Integrate Shipwire with SalesBlink",
    "metaDescription": "Integrate Shipwire and other eCommerce Tools with SalesBlink. Two Way Shipwire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Android",
    "slug": "android",
    "logo": "https://zapier-images.imgix.net/storage/services/970a5c092aed2a090acffa649489682f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Android is a mobile phone operating system used extensively by dozens of carriers. Zapier can make it easier than ever to leverage the hundreds of thousands of apps out there with Android.",
    "category": "Phone & SMS",
    "title": "Android Integration With Cold Email Sales Tool",
    "h1": "Integrate Android with SalesBlink",
    "metaDescription": "Integrate Android and other Phone & SMS Tools with SalesBlink. Two Way Android Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Foxycart",
    "slug": "foxycart",
    "logo": "https://zapier-images.imgix.net/storage/services/3e3f3392c4d578d86cc27c9a52afa0a8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Custom ecommerce, simplified. Foxy's hosted cart & payment page allow you to sell anything, using your existing website or platform.",
    "category": "eCommerce",
    "title": "Foxycart Integration With Cold Email Sales Tool",
    "h1": "Integrate Foxycart with SalesBlink",
    "metaDescription": "Integrate Foxycart and other eCommerce Tools with SalesBlink. Two Way Foxycart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MINDBODY",
    "slug": "mindbody",
    "logo": "https://zapier-images.imgix.net/storage/services/8dfbd8b8f64307f304f487a32a27278e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MINDBODY is a powerful business management software that combines your scheduling, task automation, reporting tools and more into a single online platform.",
    "category": "Project Management",
    "title": "MINDBODY Integration With Cold Email Sales Tool",
    "h1": "Integrate MINDBODY with SalesBlink",
    "metaDescription": "Integrate MINDBODY and other Project Management Tools with SalesBlink. Two Way MINDBODY Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Sina Weibo",
    "slug": "sina-weibo",
    "logo": "https://zapier-images.imgix.net/storage/services/1a5e429c51b373e606e094a71dafc702.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sina Weibo is a popular social and microblogging service in China.",
    "category": "Social Media Accounts",
    "title": "Sina Weibo Integration With Cold Email Sales Tool",
    "h1": "Integrate Sina Weibo with SalesBlink",
    "metaDescription": "Integrate Sina Weibo and other Social Media Accounts Tools with SalesBlink. Two Way Sina Weibo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IRC",
    "slug": "irc",
    "logo": "https://zapier-images.imgix.net/storage/services/0aa2296c98a333da9dca0083e5853ab7.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Internet Relay Chat is a popular protocol for real time messaging and is in use by millions of users every day. Great for team collaboration, IRC has lots of good client and server software options.",
    "category": "Team Chat",
    "title": "IRC Integration With Cold Email Sales Tool",
    "h1": "Integrate IRC with SalesBlink",
    "metaDescription": "Integrate IRC and other Team Chat Tools with SalesBlink. Two Way IRC Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BizTalk",
    "slug": "biztalk",
    "logo": "https://zapier-images.imgix.net/storage/services/e64426cb6848ecbaaecf98bacd45f234.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft BizTalk gives companies the ability to automate business processes through the use of \"adapters\". Adapters are custom tailored to communicate with different software systems used in an enterprise, and now Zapier can be a powerful adapter!",
    "category": "Developer Tools",
    "title": "BizTalk Integration With Cold Email Sales Tool",
    "h1": "Integrate BizTalk with SalesBlink",
    "metaDescription": "Integrate BizTalk and other Developer Tools Tools with SalesBlink. Two Way BizTalk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Silverpop",
    "slug": "silverpop",
    "logo": "https://zapier-images.imgix.net/storage/services/58162439eef4851bc120d8f9238d0c93.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Silverpop is a web based, on-demand digital marketing technology provider that brings marketing automation, mobile, email, social and more under one roof! Silverpop handles everything from lead nurturing to campaign automation.",
    "category": "Marketing Automation",
    "title": "Silverpop Integration With Cold Email Sales Tool",
    "h1": "Integrate Silverpop with SalesBlink",
    "metaDescription": "Integrate Silverpop and other Marketing Automation Tools with SalesBlink. Two Way Silverpop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Blogger",
    "slug": "blogger",
    "logo": "https://zapier-images.imgix.net/storage/services/15998add6c1e4cb7518be29f7d908399.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Blogger is an easy-to-use, popular and free web blog publishing service provided by Google. There are millions of blogs available on Blogger today and creating your own is simple!",
    "category": "Website Builders",
    "title": "Blogger Integration With Cold Email Sales Tool",
    "h1": "Integrate Blogger with SalesBlink",
    "metaDescription": "Integrate Blogger and other Website Builders Tools with SalesBlink. Two Way Blogger Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Etsy",
    "slug": "etsy",
    "logo": "https://zapier-images.imgix.net/storage/developer/2e1f50866857bc6f566dfdf4636c8322.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Etsy is a store that makes it easy to buy and sell handmade, vintage or uniquely manufactured goods online!",
    "category": "eCommerce",
    "title": "Etsy Integration With Cold Email Sales Tool",
    "h1": "Integrate Etsy with SalesBlink",
    "metaDescription": "Integrate Etsy and other eCommerce Tools with SalesBlink. Two Way Etsy Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Echosign",
    "slug": "echosign",
    "logo": "https://zapier-images.imgix.net/storage/services/7f0b16c6064dbd326342692f7f2a2de0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adobe's EchoSign offers a secure and trusted electronic signature software online. With Echosign you can send, e-sign, track, and file documents and contracts very simply!",
    "category": "Signatures",
    "title": "Echosign Integration With Cold Email Sales Tool",
    "h1": "Integrate Echosign with SalesBlink",
    "metaDescription": "Integrate Echosign and other Signatures Tools with SalesBlink. Two Way Echosign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FormAssembly",
    "slug": "formassembly",
    "logo": "https://zapier-images.imgix.net/storage/services/1c4ac52d0d6f790ff0a5fa160524c7dc_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FormAssembly is a web-based surveying platform that helps businesses create web forms and collect data.",
    "category": "Forms & Surveys",
    "title": "FormAssembly Integration With Cold Email Sales Tool",
    "h1": "Integrate FormAssembly with SalesBlink",
    "metaDescription": "Integrate FormAssembly and other Forms & Surveys Tools with SalesBlink. Two Way FormAssembly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Weebly",
    "slug": "weebly",
    "logo": "https://zapier-images.imgix.net/storage/services/38b547f67a462019dee79554d775faa6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Weebly is an easy to use way to make high-quality websites like blogs or online stores.",
    "category": "Website Builders",
    "title": "Weebly Integration With Cold Email Sales Tool",
    "h1": "Integrate Weebly with SalesBlink",
    "metaDescription": "Integrate Weebly and other Website Builders Tools with SalesBlink. Two Way Weebly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Active Directory",
    "slug": "active-directory",
    "logo": "https://zapier-images.imgix.net/storage/services/dcd2562a52a02c38e113a5109d91c262.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Microsoft's Active Directory is a directory service often bundled with Microsoft Windows Server operating systems or as a part of Azure hosting. It is an interface for user authentication and identity as well as a device (like computers or printers) registration.",
    "category": "Databases",
    "title": "Active Directory Integration With Cold Email Sales Tool",
    "h1": "Integrate Active Directory with SalesBlink",
    "metaDescription": "Integrate Active Directory and other Databases Tools with SalesBlink. Two Way Active Directory Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Airbnb",
    "slug": "airbnb",
    "logo": "https://zapier-images.imgix.net/storage/services/58e701abe326014cb21d63e77d53f379.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Airbnb is a unique marketplace for renting amazing lodging in over 190 different countries. Use Zapier's Email Parser to take confirmation emails and send them to hundreds of different SaaS apps!",
    "category": "eCommerce",
    "title": "Airbnb Integration With Cold Email Sales Tool",
    "h1": "Integrate Airbnb with SalesBlink",
    "metaDescription": "Integrate Airbnb and other eCommerce Tools with SalesBlink. Two Way Airbnb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oracle Database",
    "slug": "oracle-database",
    "logo": "https://zapier-images.imgix.net/storage/services/775e3d0651a5ba2ab7f4c4bb8ebc5cfe.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Oracle Database is a commercial relational or SQL database offering from Oracle. This database backs many critical operations and is in wide use amongst the enterprise.",
    "category": "Databases",
    "title": "Oracle Database Integration With Cold Email Sales Tool",
    "h1": "Integrate Oracle Database with SalesBlink",
    "metaDescription": "Integrate Oracle Database and other Databases Tools with SalesBlink. Two Way Oracle Database Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WorkflowMax",
    "slug": "workflowmax",
    "logo": "https://zapier-images.imgix.net/storage/services/5cbc43eea8b9a3ccc021c521dfe3e0eb_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Over 10,000 service business trust WorkflowMax for their quoting, time sheeting, job management, invoicing and workflow management.",
    "category": "Product Management",
    "title": "WorkflowMax Integration With Cold Email Sales Tool",
    "h1": "Integrate WorkflowMax with SalesBlink",
    "metaDescription": "Integrate WorkflowMax and other Product Management Tools with SalesBlink. Two Way WorkflowMax Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FileMaker",
    "slug": "filemaker",
    "logo": "https://zapier-images.imgix.net/storage/services/451a3329e5c7833cc5a51921de1f2a8c_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FileMaker integrates a database engine with a graphical user interface (GUI) and security features, allowing users to modify the database by dragging new elements into layouts, screens, or forms.",
    "category": "Databases",
    "title": "FileMaker Integration With Cold Email Sales Tool",
    "h1": "Integrate FileMaker with SalesBlink",
    "metaDescription": "Integrate FileMaker and other Databases Tools with SalesBlink. Two Way FileMaker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Telegram",
    "slug": "telegram",
    "logo": "https://zapier-images.imgix.net/storage/services/279dfee5aebe5a046e4544a04c777988_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Telegram is a cloud-based instant messaging and voice over IP service developed by Telegram Messenger LLP.",
    "category": "Phone & SMS",
    "title": "Telegram Integration With Cold Email Sales Tool",
    "h1": "Integrate Telegram with SalesBlink",
    "metaDescription": "Integrate Telegram and other Phone & SMS Tools with SalesBlink. Two Way Telegram Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eloqua",
    "slug": "eloqua",
    "logo": "https://zapier-images.imgix.net/storage/services/35b76834ef57d075957b0adc6ced1f04_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eloqua is a SaaS platform for marketing automation offered by Oracle that aims to help B2B marketers and organizations manage marketing campaigns and sales lead generation.",
    "category": "Marketing Automation",
    "title": "Eloqua Integration With Cold Email Sales Tool",
    "h1": "Integrate Eloqua with SalesBlink",
    "metaDescription": "Integrate Eloqua and other Marketing Automation Tools with SalesBlink. Two Way Eloqua Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Velocify",
    "slug": "velocify",
    "logo": "https://zapier-images.imgix.net/storage/services/91638ede2205676e4f7e9833b7dc531f_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Velocify, Inc. is a cloud computing company that provides cloud-based intelligent sales automation software designed for fast-paced sales environments.",
    "category": "Developer Tools",
    "title": "Velocify Integration With Cold Email Sales Tool",
    "h1": "Integrate Velocify with SalesBlink",
    "metaDescription": "Integrate Velocify and other Developer Tools Tools with SalesBlink. Two Way Velocify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Linkedin Sales Navigator",
    "slug": "linkedin-sales-navigator",
    "logo": "https://zapier-images.imgix.net/storage/services/b0c692d2f52e175eaf0de0106d71f604_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "LinkedIn Sales Navigator is an advanced sales tool that aids sales teams, organizations, and individuals to build and nurture customer relationships on the network.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Linkedin Sales Navigator Integration With Cold Email Sales Tool",
    "h1": "Integrate Linkedin Sales Navigator with SalesBlink",
    "metaDescription": "Integrate Linkedin Sales Navigator and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Linkedin Sales Navigator Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adobe Business Catalyst",
    "slug": "adobe-business-catalyst",
    "logo": "https://zapier-images.imgix.net/storage/services/3792143cc46bfaaa0ca97180f226caa7_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Business Catalyst is a hosted all-in-one solution for building and managing business websites.",
    "category": "Website Builders",
    "title": "Adobe Business Catalyst Integration With Cold Email Sales Tool",
    "h1": "Integrate Adobe Business Catalyst with SalesBlink",
    "metaDescription": "Integrate Adobe Business Catalyst and other Website Builders Tools with SalesBlink. Two Way Adobe Business Catalyst Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SuiteCRM",
    "slug": "suitecrm",
    "logo": "https://zapier-images.imgix.net/storage/services/e478e93c3c2b775067b29aca39254628_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SuiteCRM is a free and open source Customer Relationship Management application. Open source CRM is often used as an alternative to proprietary CRM software from major corporations.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SuiteCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate SuiteCRM with SalesBlink",
    "metaDescription": "Integrate SuiteCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SuiteCRM Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Power Bi",
    "slug": "power-bi",
    "logo": "https://zapier-images.imgix.net/storage/services/4dd4e1a08335d9a65222cdcbea13404c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Power BI aims to provide interactive visualizations and business intelligence capabilities with an interface simple enough for end users to create their own reports and dashboards.",
    "category": "Analytics",
    "title": "Power Bi Integration With Cold Email Sales Tool",
    "h1": "Integrate Power Bi with SalesBlink",
    "metaDescription": "Integrate Power Bi and other Analytics Tools with SalesBlink. Two Way Power Bi Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Contact Form 7",
    "slug": "contact-form",
    "logo": "https://zapier-images.imgix.net/storage/services/aa04007454c1c0961b581904f4f9d834_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Contact Form 7 can manage multiple contact forms, plus you can customise the form and the mail contents flexibly with simple markup.",
    "category": "Forms & Surveys",
    "title": "Contact Form 7 Integration With Cold Email Sales Tool",
    "h1": "Integrate Contact Form 7 with SalesBlink",
    "metaDescription": "Integrate Contact Form 7 and other Forms & Surveys Tools with SalesBlink. Two Way Contact Form 7 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Myob",
    "slug": "myob",
    "logo": "https://zapier-images.imgix.net/storage/services/e35cfc45ac2c37e7f354f3f66622c68e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MYOB is an Australian multinational corporation that provides tax, accounting and other business services software to small and medium businesses.",
    "category": "Accounting",
    "title": "Myob Integration With Cold Email Sales Tool",
    "h1": "Integrate Myob with SalesBlink",
    "metaDescription": "Integrate Myob and other Accounting Tools with SalesBlink. Two Way Myob Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Google Keep",
    "slug": "google-keep",
    "logo": "https://zapier-images.imgix.net/storage/services/579de5242325d1154f0b47977b4cbbec_12.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Keep offers a variety of tools for taking notes, including text, lists, images, and audio.",
    "category": "Notes",
    "title": "Google Keep Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Keep with SalesBlink",
    "metaDescription": "Integrate Google Keep and other Notes Tools with SalesBlink. Two Way Google Keep Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "1Shoppingcart",
    "slug": "1shoppingcart",
    "logo": "https://zapier-images.imgix.net/storage/services/2aba447c98baa28dbf2bb8d167ed9204_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build a powerful, secure ecommerce storefront with our Online Store Software. Sell, promote, and grow with the 1ShoppingCart.com Online Store Builder.",
    "category": "eCommerce",
    "title": "1Shoppingcart Integration With Cold Email Sales Tool",
    "h1": "Integrate 1Shoppingcart with SalesBlink",
    "metaDescription": "Integrate 1Shoppingcart and other eCommerce Tools with SalesBlink. Two Way 1Shoppingcart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lightspeed",
    "slug": "lightspeed",
    "logo": "https://zapier-images.imgix.net/storage/services/391d09037e8460d19a7253c1bf8dacac_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lightspeed is a point-of-sale and e-commerce software providing small and medium sized retail and restaurant businesses with point of sale solutions.",
    "category": "Payment Processing",
    "title": "Lightspeed Integration With Cold Email Sales Tool",
    "h1": "Integrate Lightspeed with SalesBlink",
    "metaDescription": "Integrate Lightspeed and other Payment Processing Tools with SalesBlink. Two Way Lightspeed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Everwebinar",
    "slug": "everwebinar",
    "logo": "https://zapier-images.imgix.net/storage/services/45be285ffbb080f5093b2e3f958c6b00_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EverWebinar simulates the live webinar experience for customers and operates completely automated.",
    "category": "Webinars",
    "title": "Everwebinar Integration With Cold Email Sales Tool",
    "h1": "Integrate Everwebinar with SalesBlink",
    "metaDescription": "Integrate Everwebinar and other Webinars Tools with SalesBlink. Two Way Everwebinar Integration for all your data related to sales & cold email.",
    "zapier": true,

    "make": true,
    "pabblyConnect": true
  },
  {
    "name": "Tableau",
    "slug": "tableau",
    "logo": "https://zapier-images.imgix.net/storage/services/7bd91b97a727d5073e8823982e3e1a8f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tableau helps global organizations unleash the power of their most valuable assets: their data and their people.",
    "category": "Ads & Conversion",
    "title": "Tableau Integration With Cold Email Sales Tool",
    "h1": "Integrate Tableau with SalesBlink",
    "metaDescription": "Integrate Tableau and other Ads & Conversion Tools with SalesBlink. Two Way Tableau Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SAP Business One",
    "slug": "sap-business-one",
    "logo": "https://zapier-images.imgix.net/storage/services/89c8eac5e0c07aebd3774531308fd65c_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SAP Business One is business management software designed for small and medium-sized enterprises.",
    "category": "Product Management",
    "title": "SAP Business One Integration With Cold Email Sales Tool",
    "h1": "Integrate SAP Business One with SalesBlink",
    "metaDescription": "Integrate SAP Business One and other Product Management Tools with SalesBlink. Two Way SAP Business One Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unleashed",
    "slug": "unleashed",
    "logo": "https://zapier-images.imgix.net/storage/services/fafa039b278edd02906fadde33e58bfa_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Through its 3 mobile brands Mobile Vikings, JIM Mobile and Stievie, the goal at Unleashed is to provide an answer to all your mobile needs.",
    "category": "Phone & SMS",
    "title": "Unleashed Integration With Cold Email Sales Tool",
    "h1": "Integrate Unleashed with SalesBlink",
    "metaDescription": "Integrate Unleashed and other Phone & SMS Tools with SalesBlink. Two Way Unleashed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Google Data Studio",
    "slug": "google-data-studio",
    "logo": "https://zapier-images.imgix.net/storage/services/9d3c755b8862faca803a5101c8a76956_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Data Studio allows you to create branded reports with data visualizations to share with your clients.",
    "category": "Dashboards",
    "title": "Google Data Studio Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Data Studio with SalesBlink",
    "metaDescription": "Integrate Google Data Studio and other Dashboards Tools with SalesBlink. Two Way Google Data Studio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QuickBooks Time (TSheets)",
    "slug": "tsheets",
    "logo": "https://zapier-images.imgix.net/storage/services/486b3e84d7d2fa8d40a997705e3c9331_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TSheets is a web-based and mobile time tracking and employee scheduling app for companies needing to track, manage, and report time.",
    "category": "Time Tracking Software",
    "title": "QuickBooks Time (TSheets) Integration With Cold Email Sales Tool",
    "h1": "Integrate QuickBooks Time (TSheets) with SalesBlink",
    "metaDescription": "Integrate QuickBooks Time (TSheets) and other Time Tracking Software Tools with SalesBlink. Two Way QuickBooks Time (TSheets) Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Google Voice",
    "slug": "google-voice",
    "logo": "https://zapier-images.imgix.net/storage/services/317cc9680db5dfb2d5f4da5b134208c2_11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Voice is a telephone service that provides call forwarding and voicemail services, voice and text messaging.",
    "category": "Phone & SMS",
    "title": "Google Voice Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Voice with SalesBlink",
    "metaDescription": "Integrate Google Voice and other Phone & SMS Tools with SalesBlink. Two Way Google Voice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sage CRM",
    "slug": "sage-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/ebd6ea0455bf00a6250ad74cc0c7a38d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helps businesses improve customer relationships by organising and automating communications and activities across all customer-facing departments.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sage CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Sage CRM with SalesBlink",
    "metaDescription": "Integrate Sage CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sage CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CamCard",
    "slug": "camcard",
    "logo": "https://zapier-images.imgix.net/storage/services/9d7a67b61c81c407610c9d32c9755db3_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CamCard is the easiest app to manage and exchange business cards, the perfect fit for sales people, entrepreneurs, business developers or marketing experts.",
    "category": "Contact Management",
    "title": "CamCard Integration With Cold Email Sales Tool",
    "h1": "Integrate CamCard with SalesBlink",
    "metaDescription": "Integrate CamCard and other Contact Management Tools with SalesBlink. Two Way CamCard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Big Purple Dot",
    "slug": "big-purple-dot",
    "logo": "https://zapier-images.imgix.net/storage/services/f4d89c440283aa28e1ec175e1e06199a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Big Purple Dot is a Customer Relationship Management (CRM) solution for real estate agents and mortgage lenders.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Big Purple Dot Integration With Cold Email Sales Tool",
    "h1": "Integrate Big Purple Dot with SalesBlink",
    "metaDescription": "Integrate Big Purple Dot and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Big Purple Dot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workday",
    "slug": "workday",
    "logo": "https://zapier-images.imgix.net/storage/services/7083ce98dc495bb28b54adec510be713_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workday, Inc. is an on‑demand financial management and human capital management software vendor.",
    "category": "HR Talent & Recruitment",
    "title": "Workday Integration With Cold Email Sales Tool",
    "h1": "Integrate Workday with SalesBlink",
    "metaDescription": "Integrate Workday and other HR Talent & Recruitment Tools with SalesBlink. Two Way Workday Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bronto",
    "slug": "bronto",
    "logo": "https://zapier-images.imgix.net/storage/services/27850ce67da5fe083166bb652e56cfb6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bronto Software provides a cloud-based marketing platform that enables retailers to drive revenue via email, mobile and social campaigns.",
    "category": "Marketing Automation",
    "title": "Bronto Integration With Cold Email Sales Tool",
    "h1": "Integrate Bronto with SalesBlink",
    "metaDescription": "Integrate Bronto and other Marketing Automation Tools with SalesBlink. Two Way Bronto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AliExpress",
    "slug": "aliexpress",
    "logo": "https://zapier-images.imgix.net/storage/services/72cc2ac62234bdd38db5b49f8a9b9f02_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Launched in 2010, AliExpress is made up of small businesses in China and other locations, such as Singapore, that offer products to international online buyers.",
    "category": "eCommerce",
    "title": "AliExpress Integration With Cold Email Sales Tool",
    "h1": "Integrate AliExpress with SalesBlink",
    "metaDescription": "Integrate AliExpress and other eCommerce Tools with SalesBlink. Two Way AliExpress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Timetrade",
    "slug": "timetrade",
    "logo": "https://zapier-images.imgix.net/storage/services/f233a3d9df2ade8b5bb171d5e7d76331_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intelligent online appointment scheduling by TimeTrade. Join the 500+ enterprises and 30k+ business that schedule appointments every day.",
    "category": "Scheduling & Booking",
    "title": "Timetrade Integration With Cold Email Sales Tool",
    "h1": "Integrate Timetrade with SalesBlink",
    "metaDescription": "Integrate Timetrade and other Scheduling & Booking Tools with SalesBlink. Two Way Timetrade Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lexicata",
    "slug": "lexicata",
    "logo": "https://zapier-images.imgix.net/storage/services/aa3df277802388f4cdc46c56a433b988_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lexicata is a law firm CRM and client intake software featuring a legal CRM, online intake forms, e-signature, referral tracking, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Lexicata Integration With Cold Email Sales Tool",
    "h1": "Integrate Lexicata with SalesBlink",
    "metaDescription": "Integrate Lexicata and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Lexicata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "VK",
    "slug": "vk",
    "logo": "https://zapier-images.imgix.net/storage/services/a8e83f53429eee3c82ce758b243be7b3_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VK is a Russian online social media and social networking service. It is available in several languages but is especially used by Russian-speakers.",
    "category": "Social Media Accounts",
    "title": "VK Integration With Cold Email Sales Tool",
    "h1": "Integrate VK with SalesBlink",
    "metaDescription": "Integrate VK and other Social Media Accounts Tools with SalesBlink. Two Way VK Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Interspire",
    "slug": "interspire",
    "logo": "https://zapier-images.imgix.net/storage/services/2afc21b7e16c4e82593f4bb63713170f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our all-in-one email marketing software includes everything you need to create, send, track and profit from email marketing.",
    "category": "Marketing Automation",
    "title": "Interspire Integration With Cold Email Sales Tool",
    "h1": "Integrate Interspire with SalesBlink",
    "metaDescription": "Integrate Interspire and other Marketing Automation Tools with SalesBlink. Two Way Interspire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mojo Dialer",
    "slug": "mojo-dialer",
    "logo": "https://zapier-images.imgix.net/storage/services/fea27e34cd53176906b73d614291dc83_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mojo Dialer is a real estate prospecting software that allows users to find and manage leads, automate lead follow up and transactional tasks, and more.",
    "category": "Phone & SMS",
    "title": "Mojo Dialer Integration With Cold Email Sales Tool",
    "h1": "Integrate Mojo Dialer with SalesBlink",
    "metaDescription": "Integrate Mojo Dialer and other Phone & SMS Tools with SalesBlink. Two Way Mojo Dialer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Five9",
    "slug": "five9",
    "logo": "https://zapier-images.imgix.net/storage/services/51264f6b1b2305cfa2842df351ebda6a_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Five9 is the leading cloud call center software. Trusted by 2000+ clients to accelerate sales and power customer service.",
    "category": "Phone & SMS",
    "title": "Five9 Integration With Cold Email Sales Tool",
    "h1": "Integrate Five9 with SalesBlink",
    "metaDescription": "Integrate Five9 and other Phone & SMS Tools with SalesBlink. Two Way Five9 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sprout Social",
    "slug": "sprout-social",
    "logo": "https://zapier-images.imgix.net/storage/services/4a18b49ce6afe6246d42f3f77f20aeba_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sprout Social is a social media management and optimization platform for brands and agencies.",
    "category": "Social Media Marketing",
    "title": "Sprout Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Sprout Social with SalesBlink",
    "metaDescription": "Integrate Sprout Social and other Social Media Marketing Tools with SalesBlink. Two Way Sprout Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Floify",
    "slug": "floify",
    "logo": "https://zapier-images.imgix.net/storage/services/c87eff6a4e4efaf31a5a4fae5924281e_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Floify's mortgage automation and point-of-sale software solution streamlines and organizes the loan origination process for lenders and borrowers alike.",
    "category": "Accounting",
    "title": "Floify Integration With Cold Email Sales Tool",
    "h1": "Integrate Floify with SalesBlink",
    "metaDescription": "Integrate Floify and other Accounting Tools with SalesBlink. Two Way Floify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Huddle",
    "slug": "huddle",
    "logo": "https://zapier-images.imgix.net/storage/services/4090d129edabf0f6eb75a6154c1ad389_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Huddle is the ideal e-learning and community software for all your digital training programs, membership and community building.",
    "category": "Online Courses",
    "title": "Huddle Integration With Cold Email Sales Tool",
    "h1": "Integrate Huddle with SalesBlink",
    "metaDescription": "Integrate Huddle and other Online Courses Tools with SalesBlink. Two Way Huddle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Atera",
    "slug": "atera",
    "logo": "https://zapier-images.imgix.net/storage/services/39c803529afdad3ebedbf7819b18cfd7_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Atera - The world's first RMM software, built with PSA and Remote Access capabilities, specifically designed for MSPs in all shapes and sizes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Atera Integration With Cold Email Sales Tool",
    "h1": "Integrate Atera with SalesBlink",
    "metaDescription": "Integrate Atera and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Atera Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Sendoutcards",
    "slug": "sendoutcards",
    "logo": "https://zapier-images.imgix.net/storage/services/ca6f7d3667cf911461c5a4a8d5a9a4d3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An online greeting card and gift company with over 80 million cards sent, making us the largest first-class mailing company in the U.S.",
    "category": "Website Builders",
    "title": "Sendoutcards Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendoutcards with SalesBlink",
    "metaDescription": "Integrate Sendoutcards and other Website Builders Tools with SalesBlink. Two Way Sendoutcards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apsis",
    "slug": "apsis",
    "logo": "https://zapier-images.imgix.net/storage/services/afb707e105bad74ec63264105e29bd47_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "From email marketing and e-commerce to lead generation, automation and profile-based marketing.",
    "category": "Marketing Automation",
    "title": "Apsis Integration With Cold Email Sales Tool",
    "h1": "Integrate Apsis with SalesBlink",
    "metaDescription": "Integrate Apsis and other Marketing Automation Tools with SalesBlink. Two Way Apsis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Udemy",
    "slug": "udemy",
    "logo": "https://zapier-images.imgix.net/storage/services/a2cf5863a0d457ec4445438feab5c783_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Udemy.com is an online learning platform. It is aimed at professional adults. Udemy uses content from online content creators to sell for profit.",
    "category": "Online Courses",
    "title": "Udemy Integration With Cold Email Sales Tool",
    "h1": "Integrate Udemy with SalesBlink",
    "metaDescription": "Integrate Udemy and other Online Courses Tools with SalesBlink. Two Way Udemy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Localytics",
    "slug": "localytics",
    "logo": "https://zapier-images.imgix.net/storage/services/acefdeca4955eff3d0f214cd7e8b96d6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Used on more than 3 billion devices worldwide, Localytics is a mobile engagement platform for mobile and web apps.",
    "category": "Devices",
    "title": "Localytics Integration With Cold Email Sales Tool",
    "h1": "Integrate Localytics with SalesBlink",
    "metaDescription": "Integrate Localytics and other Devices Tools with SalesBlink. Two Way Localytics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesLoft",
    "slug": "salesloft",
    "logo": "https://zapier-images.imgix.net/storage/services/bb30e55c2c3ebe92a42153355af30135_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesLoft is the leading sales engagement platform, helping sales organizations to deliver a better sales experience for their customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesLoft Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesLoft with SalesBlink",
    "metaDescription": "Integrate SalesLoft and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesLoft Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "EasyPost",
    "slug": "easypost",
    "logo": "https://zapier-images.imgix.net/storage/services/545ca1c1a639f95042529ba6bcaffcff_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "EasyPost provides a simple postage API that allows developers to quickly and painlessly integrate shipping into any e-commerce application.",
    "category": "eCommerce",
    "title": "EasyPost Integration With Cold Email Sales Tool",
    "h1": "Integrate EasyPost with SalesBlink",
    "metaDescription": "Integrate EasyPost and other eCommerce Tools with SalesBlink. Two Way EasyPost Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Fishbowl",
    "slug": "fishbowl",
    "logo": "https://zapier-images.imgix.net/storage/services/42e68d24c712ea34569ccbdf67f6893e_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fishbowl, the #1 inventory management software for QuickBooks, offers multi-location tracking, manufacturing, barcoding and more.",
    "category": "Product Management",
    "title": "Fishbowl Integration With Cold Email Sales Tool",
    "h1": "Integrate Fishbowl with SalesBlink",
    "metaDescription": "Integrate Fishbowl and other Product Management Tools with SalesBlink. Two Way Fishbowl Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mailrelay",
    "slug": "mailrelay",
    "logo": "https://zapier-images.imgix.net/storage/services/791b58f658e3cd0952259ba6ff3a9820_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mailrelay is an advanced email marketing platform with powerful features. It allows you to create, send and manage your newsletters providing complete subscriber behaviour statistics.",
    "category": "Email Newsletters",
    "title": "Mailrelay Integration With Cold Email Sales Tool",
    "h1": "Integrate Mailrelay with SalesBlink",
    "metaDescription": "Integrate Mailrelay and other Email Newsletters Tools with SalesBlink. Two Way Mailrelay Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Idomoo",
    "slug": "idomoo",
    "logo": "https://zapier-images.imgix.net/storage/services/45c636ed21ce040a06e9cc9653fabc8e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Idomoo’s Personalized Marketing Videos enable you to easily engage with your customers on a truly personal level, driving loyalty and revenue growth.",
    "category": "Marketing Automation",
    "title": "Idomoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Idomoo with SalesBlink",
    "metaDescription": "Integrate Idomoo and other Marketing Automation Tools with SalesBlink. Two Way Idomoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Radius",
    "slug": "radius",
    "logo": "https://zapier-images.imgix.net/storage/services/236d906307eb345d2a82bb15f458d2ee_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Radius is built specifically for Insurance Agents and Agencies, but every agency does their day to day differently which is why Radius allows for unique customization for each account.",
    "category": "Project Management",
    "title": "Radius Integration With Cold Email Sales Tool",
    "h1": "Integrate Radius with SalesBlink",
    "metaDescription": "Integrate Radius and other Project Management Tools with SalesBlink. Two Way Radius Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Active Network",
    "slug": "active-network",
    "logo": "https://zapier-images.imgix.net/storage/services/fa304135d2398a9ddd3cd5b9e34b49a9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Software management solutions for the full scope of event planning needs.",
    "category": "Event Management",
    "title": "Active Network Integration With Cold Email Sales Tool",
    "h1": "Integrate Active Network with SalesBlink",
    "metaDescription": "Integrate Active Network and other Event Management Tools with SalesBlink. Two Way Active Network Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Code Climate",
    "slug": "code-climate",
    "logo": "https://zapier-images.imgix.net/storage/services/c5da75d3aae403299ea10449c31acc89_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Code Climate consolidates the results from a suite of static analysis tools into a single, real-time report, giving your team the information it needs.",
    "category": "Developer Tools",
    "title": "Code Climate Integration With Cold Email Sales Tool",
    "h1": "Integrate Code Climate with SalesBlink",
    "metaDescription": "Integrate Code Climate and other Developer Tools Tools with SalesBlink. Two Way Code Climate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Workfront",
    "slug": "workfront",
    "logo": "https://zapier-images.imgix.net/storage/services/71f0af21e5b730bdf82aeb048977a07b_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Workfront is a software company that develops web-based work management and project management software that features enterprise work management, issue tracking, document management time tracking and portfolio management.",
    "category": "Product Management",
    "title": "Workfront Integration With Cold Email Sales Tool",
    "h1": "Integrate Workfront with SalesBlink",
    "metaDescription": "Integrate Workfront and other Product Management Tools with SalesBlink. Two Way Workfront Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ecomdash",
    "slug": "ecomdash",
    "logo": "https://zapier-images.imgix.net/storage/services/5b43b3425658368762edf497d0f000f4_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ecomdash is multichannel inventory management software for ecommerce sellers. Simplify order management, inventory, shipping, and more.",
    "category": "eCommerce",
    "title": "Ecomdash Integration With Cold Email Sales Tool",
    "h1": "Integrate Ecomdash with SalesBlink",
    "metaDescription": "Integrate Ecomdash and other eCommerce Tools with SalesBlink. Two Way Ecomdash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PocketSmith",
    "slug": "pocketsmith",
    "logo": "https://zapier-images.imgix.net/storage/services/8bc7352d37f26a8436af2a42fae1b6b7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Manage your budget and forecast your finances with PocketSmith, the best personal finance software on the web. Connect to your bank accounts securely.",
    "category": "Accounting",
    "title": "PocketSmith Integration With Cold Email Sales Tool",
    "h1": "Integrate PocketSmith with SalesBlink",
    "metaDescription": "Integrate PocketSmith and other Accounting Tools with SalesBlink. Two Way PocketSmith Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CDK Global",
    "slug": "cdk-global",
    "logo": "https://zapier-images.imgix.net/storage/services/5a7a151b40994875569e5285bd2ea520_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CDK Global provides auto dealer software as well as solutions for truck, motorcycle, marine and RV dealers throughout North America and beyond.",
    "category": "eCommerce",
    "title": "CDK Global Integration With Cold Email Sales Tool",
    "h1": "Integrate CDK Global with SalesBlink",
    "metaDescription": "Integrate CDK Global and other eCommerce Tools with SalesBlink. Two Way CDK Global Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FranConnect",
    "slug": "franconnect",
    "logo": "https://zapier-images.imgix.net/storage/services/64e0a795b7ee8d5f4bf92847a97ee383_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FranConnect helps franchises increase unit growth, reduce opening time, track and improve unit performance, automate financial reporting & more",
    "category": "Accounting",
    "title": "FranConnect Integration With Cold Email Sales Tool",
    "h1": "Integrate FranConnect with SalesBlink",
    "metaDescription": "Integrate FranConnect and other Accounting Tools with SalesBlink. Two Way FranConnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amazon Drive",
    "slug": "amazon-drive",
    "logo": "https://zapier-images.imgix.net/storage/services/bfc4cb6baef0b32fbbc038a507c59797_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amazon Drive is a cloud storage application managed by Amazon. The service offers secure cloud storage, file backup, file sharing, and Photo printing.",
    "category": "Databases",
    "title": "Amazon Drive Integration With Cold Email Sales Tool",
    "h1": "Integrate Amazon Drive with SalesBlink",
    "metaDescription": "Integrate Amazon Drive and other Databases Tools with SalesBlink. Two Way Amazon Drive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cardconnect",
    "slug": "cardconnect",
    "logo": "https://zapier-images.imgix.net/storage/services/6c2f45ed62cbc2f24099125b1846a6b6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CardConnect provides simple payment processing, seamless integrations and powerful security solutions for SMBs, software companies and enterprise businesses.",
    "category": "Payment Processing",
    "title": "Cardconnect Integration With Cold Email Sales Tool",
    "h1": "Integrate Cardconnect with SalesBlink",
    "metaDescription": "Integrate Cardconnect and other Payment Processing Tools with SalesBlink. Two Way Cardconnect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fit3D",
    "slug": "fit3d",
    "logo": "https://zapier-images.imgix.net/storage/services/33b561fafa0e2ec177da80182c821459_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fit3D designs, manufacturers, and distributes 3D body scanners to business within the wellness, fitness, medical, and apparel retail industries on 5 continents.",
    "category": "Fitness",
    "title": "Fit3D Integration With Cold Email Sales Tool",
    "h1": "Integrate Fit3D with SalesBlink",
    "metaDescription": "Integrate Fit3D and other Fitness Tools with SalesBlink. Two Way Fit3D Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Social",
    "slug": "zoho-social",
    "logo": "https://zapier-images.imgix.net/storage/services/1457793a60dd37a9fb6b76350790e120_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZOHO Social is a smart tool that helps businesses grow their presence on the social media by reaching the right audience at the right time.",
    "category": "Social Media Marketing",
    "title": "Zoho Social Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Social with SalesBlink",
    "metaDescription": "Integrate Zoho Social and other Social Media Marketing Tools with SalesBlink. Two Way Zoho Social Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bandwidth",
    "slug": "bandwidth",
    "logo": "https://zapier-images.imgix.net/storage/services/7fb5caa38d5edbc23b155b1d57ff46a1_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "With a nationwide all-IP Voice network, Bandwidth powers leading brands that are changing the way people communicate, connect and do business.",
    "category": "Phone & SMS",
    "title": "Bandwidth Integration With Cold Email Sales Tool",
    "h1": "Integrate Bandwidth with SalesBlink",
    "metaDescription": "Integrate Bandwidth and other Phone & SMS Tools with SalesBlink. Two Way Bandwidth Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gear Launch",
    "slug": "gear-launch",
    "logo": "https://zapier-images.imgix.net/storage/services/e96c0eec1b229e71b693d3368035bff1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A sophisticated platform for E2E commerce, a rapidly emerging category that focuses on the full e-commerce value chain.",
    "category": "eCommerce",
    "title": "Gear Launch Integration With Cold Email Sales Tool",
    "h1": "Integrate Gear Launch with SalesBlink",
    "metaDescription": "Integrate Gear Launch and other eCommerce Tools with SalesBlink. Two Way Gear Launch Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Octadesk",
    "slug": "octadesk",
    "logo": "https://zapier-images.imgix.net/storage/services/898bbb54a502cd951c0e102a13f6fb2a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Octadesk is a chat management platform to help companies in lead generation, sales, and customer service, connecting consumers to companies through digital channels.",
    "category": "Commerce",
    "title": "Octadesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Octadesk with SalesBlink",
    "metaDescription": "Integrate Octadesk and other Commerce Tools with SalesBlink. Two Way Octadesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gingr",
    "slug": "gingr",
    "logo": "https://zapier-images.imgix.net/storage/services/a1c6c961f74062c045a0c000bb748edc_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Gingr is easy-to-learn software for dog daycare, pet boarding kennel management, and dog grooming business.",
    "category": "Task Management",
    "title": "Gingr Integration With Cold Email Sales Tool",
    "h1": "Integrate Gingr with SalesBlink",
    "metaDescription": "Integrate Gingr and other Task Management Tools with SalesBlink. Two Way Gingr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bear",
    "slug": "bear",
    "logo": "https://zapier-images.imgix.net/storage/services/896bf78132fc049febfde2ddb30d8ceb_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bear is a focused, flexible writing app used by everyone from bloggers and web developers to aspiring authors and students.",
    "category": "Notes",
    "title": "Bear Integration With Cold Email Sales Tool",
    "h1": "Integrate Bear with SalesBlink",
    "metaDescription": "Integrate Bear and other Notes Tools with SalesBlink. Two Way Bear Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Plusthis",
    "slug": "plusthis",
    "logo": "https://zapier-images.imgix.net/storage/services/af170437b8896e6a5f1cfd6d211905e4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PlusThis is a library of 50+ add-ons and integrations for Infusionsoft, Drip and Ontraport.",
    "category": "Developer Tools",
    "title": "Plusthis Integration With Cold Email Sales Tool",
    "h1": "Integrate Plusthis with SalesBlink",
    "metaDescription": "Integrate Plusthis and other Developer Tools Tools with SalesBlink. Two Way Plusthis Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendcloud",
    "slug": "sendcloud",
    "logo": "https://zapier-images.imgix.net/storage/services/edf8138d01538610f5678331df0a15ca_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Provides international clients fast, stable and efficient email and SMS delivery solutions in China.",
    "category": "Phone & SMS",
    "title": "Sendcloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendcloud with SalesBlink",
    "metaDescription": "Integrate Sendcloud and other Phone & SMS Tools with SalesBlink. Two Way Sendcloud Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Tenstreet",
    "slug": "tenstreet",
    "logo": "https://zapier-images.imgix.net/storage/services/1d10cae431223e75eac51ea3c0658bde_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "World-class integrated driver recruiting software & solutions focused 100% on helping trucking companies market to, recruit, hire & manage truck drivers.",
    "category": "HR Talent & Recruitment",
    "title": "Tenstreet Integration With Cold Email Sales Tool",
    "h1": "Integrate Tenstreet with SalesBlink",
    "metaDescription": "Integrate Tenstreet and other HR Talent & Recruitment Tools with SalesBlink. Two Way Tenstreet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cielo",
    "slug": "cielo",
    "logo": "https://zapier-images.imgix.net/storage/services/33d9ebce164510d102b44c92767d234f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cielo SA is the leader in electronic payment solutions in Latin America.",
    "category": "Payment Processing",
    "title": "Cielo Integration With Cold Email Sales Tool",
    "h1": "Integrate Cielo with SalesBlink",
    "metaDescription": "Integrate Cielo and other Payment Processing Tools with SalesBlink. Two Way Cielo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tokeet",
    "slug": "tokeet",
    "logo": "https://zapier-images.imgix.net/storage/services/c649ace85331081368c0ad27becb052c_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tokeet allows you to easily manage your vacation rentals and manage all your reservations with our all-in-one solution.",
    "category": "eCommerce",
    "title": "Tokeet Integration With Cold Email Sales Tool",
    "h1": "Integrate Tokeet with SalesBlink",
    "metaDescription": "Integrate Tokeet and other eCommerce Tools with SalesBlink. Two Way Tokeet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Payfirma",
    "slug": "payfirma",
    "logo": "https://zapier-images.imgix.net/storage/services/bd43caea6aedff5a320a8bd6320029ce_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Payfirma is your one-stop merchant services partner for secure credit card processing. They offer one solution that lets you take payments any way your customers want to pay.",
    "category": "Payment Processing",
    "title": "Payfirma Integration With Cold Email Sales Tool",
    "h1": "Integrate Payfirma with SalesBlink",
    "metaDescription": "Integrate Payfirma and other Payment Processing Tools with SalesBlink. Two Way Payfirma Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Codebase",
    "slug": "codebase",
    "logo": "https://zapier-images.imgix.net/storage/services/2be751b89bf92cc61cdfbb58a59a101c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Codebase is a software project management tool with Git, Mercurial & Subversion hosting, and an array of tools for software development teams.",
    "category": "Project Management",
    "title": "Codebase Integration With Cold Email Sales Tool",
    "h1": "Integrate Codebase with SalesBlink",
    "metaDescription": "Integrate Codebase and other Project Management Tools with SalesBlink. Two Way Codebase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appsflyer",
    "slug": "appsflyer",
    "logo": "https://zapier-images.imgix.net/storage/services/3af147f6a51a2ee2758a5b1a63e1279e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AppsFlyer is the global leading platform for Mobile Attribution & Marketing Analytics.",
    "category": "Ads & Conversion",
    "title": "Appsflyer Integration With Cold Email Sales Tool",
    "h1": "Integrate Appsflyer with SalesBlink",
    "metaDescription": "Integrate Appsflyer and other Ads & Conversion Tools with SalesBlink. Two Way Appsflyer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gravatar",
    "slug": "gravatar",
    "logo": "https://zapier-images.imgix.net/storage/services/43568498c9a853505b502e446e0849fc_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Your Gravatar is an image that follows you from site to site appearing beside your name when you do things like comment or post on a blog.",
    "category": "Website Builders",
    "title": "Gravatar Integration With Cold Email Sales Tool",
    "h1": "Integrate Gravatar with SalesBlink",
    "metaDescription": "Integrate Gravatar and other Website Builders Tools with SalesBlink. Two Way Gravatar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ultracart",
    "slug": "ultracart",
    "logo": "https://zapier-images.imgix.net/storage/services/5f748908a6f7682870e3ff8adb8a17d1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "UltraCart is a full-featured e-commerce site builder that allows you to integrate ecommerce storeping features into your website or blog in multiple ways.",
    "category": "eCommerce",
    "title": "Ultracart Integration With Cold Email Sales Tool",
    "h1": "Integrate Ultracart with SalesBlink",
    "metaDescription": "Integrate Ultracart and other eCommerce Tools with SalesBlink. Two Way Ultracart Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cratejoy",
    "slug": "cratejoy",
    "logo": "https://zapier-images.imgix.net/storage/services/56d63d4b933286f117737f31c6cf0c3e_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cratejoy Inc. operates an online platform that enables users to build their own subscription business.",
    "category": "eCommerce",
    "title": "Cratejoy Integration With Cold Email Sales Tool",
    "h1": "Integrate Cratejoy with SalesBlink",
    "metaDescription": "Integrate Cratejoy and other eCommerce Tools with SalesBlink. Two Way Cratejoy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShowingTime",
    "slug": "showingtime",
    "logo": "https://zapier-images.imgix.net/storage/services/8bcbf95eb35289c8f53a1860bc993694_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShowingTime is the real estate industry's leading showing management and market stats technology provider.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ShowingTime Integration With Cold Email Sales Tool",
    "h1": "Integrate ShowingTime with SalesBlink",
    "metaDescription": "Integrate ShowingTime and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ShowingTime Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bokun",
    "slug": "bokun",
    "logo": "https://zapier-images.imgix.net/storage/services/293a752b186e49a2a2c9d3b89e494516_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bokun is creating the largest global B2B marketplace for tours and activity products.",
    "category": "Scheduling & Booking",
    "title": "Bokun Integration With Cold Email Sales Tool",
    "h1": "Integrate Bokun with SalesBlink",
    "metaDescription": "Integrate Bokun and other Scheduling & Booking Tools with SalesBlink. Two Way Bokun Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amitree",
    "slug": "amitree",
    "logo": "https://zapier-images.imgix.net/storage/services/0ec1276866fcfb643320d3aa149da0e4_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amitree is an online platform that helps home buyers navigate the process of buying a house.",
    "category": "Transactional Email",
    "title": "Amitree Integration With Cold Email Sales Tool",
    "h1": "Integrate Amitree with SalesBlink",
    "metaDescription": "Integrate Amitree and other Transactional Email Tools with SalesBlink. Two Way Amitree Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Datalot",
    "slug": "datalot",
    "logo": "https://zapier-images.imgix.net/storage/services/19d3032df7e14f5c0df220e26eded8cc_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Datalot is the industry leader in pay-per-call advertising. We make your phone ring with qualified customers.",
    "category": "Phone & SMS",
    "title": "Datalot Integration With Cold Email Sales Tool",
    "h1": "Integrate Datalot with SalesBlink",
    "metaDescription": "Integrate Datalot and other Phone & SMS Tools with SalesBlink. Two Way Datalot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pixieset",
    "slug": "pixieset",
    "logo": "https://zapier-images.imgix.net/storage/services/f1ce43398b432ef1b3fa8276af9c1b97_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Beautiful client photo gallery designed for professional photographers to share, deliver, proof and sell online.",
    "category": "Images & Design",
    "title": "Pixieset Integration With Cold Email Sales Tool",
    "h1": "Integrate Pixieset with SalesBlink",
    "metaDescription": "Integrate Pixieset and other Images & Design Tools with SalesBlink. Two Way Pixieset Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesPype",
    "slug": "salespype",
    "logo": "https://zapier-images.imgix.net/storage/services/61619a8cd5cd7d564600e7c5b11b4ca5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automate your sales pipeline and take your sales to new heights. SalesPype is a CRM with built-in automation. 2 Way Text Messages, Ringless Vmail and Email.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesPype Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesPype with SalesBlink",
    "metaDescription": "Integrate SalesPype and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesPype Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Allclients",
    "slug": "allclients",
    "logo": "https://zapier-images.imgix.net/storage/services/d7013367a00af444b99032a1b4f96865_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "AllClients customer database software built to give you the email marketing and CRM functionality you’ll need to successfully manage and grow your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Allclients Integration With Cold Email Sales Tool",
    "h1": "Integrate Allclients with SalesBlink",
    "metaDescription": "Integrate Allclients and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Allclients Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tinypng",
    "slug": "tinypng",
    "logo": "https://zapier-images.imgix.net/storage/services/0a0069ffca39b8157a6cbd8ccae5b1c7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TinyPNG optimizes your PNG images by 50-80% while preserving full transparency!",
    "category": "Images & Design",
    "title": "Tinypng Integration With Cold Email Sales Tool",
    "h1": "Integrate Tinypng with SalesBlink",
    "metaDescription": "Integrate Tinypng and other Images & Design Tools with SalesBlink. Two Way Tinypng Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aventri",
    "slug": "aventri",
    "logo": "https://zapier-images.imgix.net/storage/services/32c62b7fb55197f5fde1fec1d75c4e68_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aventri is a global leader in cloud based data-driven event management and venue sourcing solutions.",
    "category": "Event Management",
    "title": "Aventri Integration With Cold Email Sales Tool",
    "h1": "Integrate Aventri with SalesBlink",
    "metaDescription": "Integrate Aventri and other Event Management Tools with SalesBlink. Two Way Aventri Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boberdoo",
    "slug": "boberdoo",
    "logo": "https://zapier-images.imgix.net/storage/services/e391cf83d8abee022a86733dd402004e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A SaaS company that offers lead capture software for companies that sell leads. Track, route and bill for all of your web leads & calls in one platform regardless of vertical.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Boberdoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Boberdoo with SalesBlink",
    "metaDescription": "Integrate Boberdoo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Boberdoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Canvas LMS",
    "slug": "canvas-lms",
    "logo": "https://zapier-images.imgix.net/storage/services/64da4da88837efa7e21989079889f464_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Canvas is the trusted, open-source learning management system (LMS) that's revolutionizing the way we educate.",
    "category": "Online Courses",
    "title": "Canvas LMS Integration With Cold Email Sales Tool",
    "h1": "Integrate Canvas LMS with SalesBlink",
    "metaDescription": "Integrate Canvas LMS and other Online Courses Tools with SalesBlink. Two Way Canvas LMS Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Shoplo",
    "slug": "shoplo",
    "logo": "https://zapier-images.imgix.net/storage/services/d8e766542ce00974870de4d7a476d82d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sell your products via your own online store and multiple sales channels. Beautiful store themes and multichannel selling all in one place.",
    "category": "eCommerce",
    "title": "Shoplo Integration With Cold Email Sales Tool",
    "h1": "Integrate Shoplo with SalesBlink",
    "metaDescription": "Integrate Shoplo and other eCommerce Tools with SalesBlink. Two Way Shoplo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intervals",
    "slug": "intervals",
    "logo": "https://zapier-images.imgix.net/storage/services/d02f6e0f10c9e1ab27674d56444f3b31_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intervals is cloud-based time tracking and task management with powerful reporting. Designed for the unique needs of small business.",
    "category": "Time Tracking Software",
    "title": "Intervals Integration With Cold Email Sales Tool",
    "h1": "Integrate Intervals with SalesBlink",
    "metaDescription": "Integrate Intervals and other Time Tracking Software Tools with SalesBlink. Two Way Intervals Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zoho Expense",
    "slug": "zoho-expense",
    "logo": "https://zapier-images.imgix.net/storage/services/0ef791654606d3af258a629deb402942_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zoho Expense is a platform which transforms expense reporting and monitoring, and turns it into an enjoyable experience.",
    "category": "Accounting",
    "title": "Zoho Expense Integration With Cold Email Sales Tool",
    "h1": "Integrate Zoho Expense with SalesBlink",
    "metaDescription": "Integrate Zoho Expense and other Accounting Tools with SalesBlink. Two Way Zoho Expense Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Figma",
    "slug": "figma",
    "logo": "https://zapier-images.imgix.net/storage/services/55ac634d5767c9dd6c29c6cf5be0de43_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Turn Ideas into Products Faster. Design, prototype, and gather feedback all in one place with Figma.",
    "category": "Product Management",
    "title": "Figma Integration With Cold Email Sales Tool",
    "h1": "Integrate Figma with SalesBlink",
    "metaDescription": "Integrate Figma and other Product Management Tools with SalesBlink. Two Way Figma Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "myCRM",
    "slug": "mycrm",
    "logo": "https://zapier-images.imgix.net/storage/services/26178b8f10549beee6b700989df94198_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "myCRM is a one stop CRM platform for real estate professionals in the Gulf and MENA Regions.",
    "category": "CRM (Customer Relationship Management)",
    "title": "myCRM Integration With Cold Email Sales Tool",
    "h1": "Integrate myCRM with SalesBlink",
    "metaDescription": "Integrate myCRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way myCRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TimeTap",
    "slug": "timetap",
    "logo": "https://zapier-images.imgix.net/storage/services/ea1a9c1ba2d21662bc4f82746b5ff6a1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Online Appointment Scheduling Software, TimeTap makes online booking an easy process for small businesses & their clients.",
    "category": "Scheduling & Booking",
    "title": "TimeTap Integration With Cold Email Sales Tool",
    "h1": "Integrate TimeTap with SalesBlink",
    "metaDescription": "Integrate TimeTap and other Scheduling & Booking Tools with SalesBlink. Two Way TimeTap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amplitude",
    "slug": "amplitude",
    "logo": "https://zapier-images.imgix.net/storage/services/dbd2650c87787df4b535ce0089866284_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amplitude is a product analytics service that makes it easier for companies to understand user behavior, ship the right features and improve business outcomes.",
    "category": "Ads & Conversion",
    "title": "Amplitude Integration With Cold Email Sales Tool",
    "h1": "Integrate Amplitude with SalesBlink",
    "metaDescription": "Integrate Amplitude and other Ads & Conversion Tools with SalesBlink. Two Way Amplitude Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Pingboard",
    "slug": "pingboard",
    "logo": "https://zapier-images.imgix.net/storage/services/b28832854b4912f888c409e609c489a2_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pingboard is real-time org chart software that makes it easy to build professional looking org charts.",
    "category": "HR Talent & Recruitment",
    "title": "Pingboard Integration With Cold Email Sales Tool",
    "h1": "Integrate Pingboard with SalesBlink",
    "metaDescription": "Integrate Pingboard and other HR Talent & Recruitment Tools with SalesBlink. Two Way Pingboard Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Easybill",
    "slug": "easybill",
    "logo": "https://zapier-images.imgix.net/storage/services/8cd45e15dd98eeb0129b06c8d5178cf3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Create invoices, quotations and delivery notes with Easybill's cloud-based accounting software.",
    "category": "Accounting",
    "title": "Easybill Integration With Cold Email Sales Tool",
    "h1": "Integrate Easybill with SalesBlink",
    "metaDescription": "Integrate Easybill and other Accounting Tools with SalesBlink. Two Way Easybill Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Aircall",
    "slug": "aircall",
    "logo": "https://zapier-images.imgix.net/storage/services/46109bdf3890708cdd0268a94cecc36f_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud based phone system and call center software all-in-one with Cloud Telephony Integrations (CTI) with your favorite CRM & Helpdesk software.",
    "category": "Phone & SMS",
    "title": "Aircall Integration With Cold Email Sales Tool",
    "h1": "Integrate Aircall with SalesBlink",
    "metaDescription": "Integrate Aircall and other Phone & SMS Tools with SalesBlink. Two Way Aircall Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "ProPet",
    "slug": "propet",
    "logo": "https://zapier-images.imgix.net/storage/services/9d8aa71df3166c2e6a2b0d3e937903c2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProPet Software is full-service kennel software that streamlines the day-to-day operations of a dog boarding kennel, daycare, training and/or grooming business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "ProPet Integration With Cold Email Sales Tool",
    "h1": "Integrate ProPet with SalesBlink",
    "metaDescription": "Integrate ProPet and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way ProPet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "vulcan",
    "slug": "vulcan",
    "logo": "https://zapier-images.imgix.net/storage/services/33c6190f2551a4325f75d949f8a9b162_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vulcan 7 delivers higher quality leads and innovative ways to contact homeowners.",
    "category": "Product Management",
    "title": "vulcan Integration With Cold Email Sales Tool",
    "h1": "Integrate vulcan with SalesBlink",
    "metaDescription": "Integrate vulcan and other Product Management Tools with SalesBlink. Two Way vulcan Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nowcerts",
    "slug": "nowcerts",
    "logo": "https://zapier-images.imgix.net/storage/services/dc698487cf75e1521a55e45663d7bb34_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The best and most affordable insurance agency management system software.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Nowcerts Integration With Cold Email Sales Tool",
    "h1": "Integrate Nowcerts with SalesBlink",
    "metaDescription": "Integrate Nowcerts and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Nowcerts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SiteDocs",
    "slug": "sitedocs",
    "logo": "https://zapier-images.imgix.net/storage/services/b2c3a64bb3665fb6f9e4015d27f932e8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SiteDocs is a digital safety management system that allows you to move your entire safety program from paper, clipboards and filing cabinets to the iPad and web.",
    "category": "Security & Identity Tools",
    "title": "SiteDocs Integration With Cold Email Sales Tool",
    "h1": "Integrate SiteDocs with SalesBlink",
    "metaDescription": "Integrate SiteDocs and other Security & Identity Tools Tools with SalesBlink. Two Way SiteDocs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kapost",
    "slug": "kapost",
    "logo": "https://zapier-images.imgix.net/storage/services/459ad1548e80b61d6320af04436a3555_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kapost provides content marketing software that enables companies to manage their content marketing as a structured business process.",
    "category": "Marketing Automation",
    "title": "Kapost Integration With Cold Email Sales Tool",
    "h1": "Integrate Kapost with SalesBlink",
    "metaDescription": "Integrate Kapost and other Marketing Automation Tools with SalesBlink. Two Way Kapost Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ADP",
    "slug": "adp",
    "logo": "https://zapier-images.imgix.net/storage/services/50561c01663f1005c52d7be1c2d672ed_11.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ADP is an American provider of human resources management software and services.",
    "category": "HR Talent & Recruitment",
    "title": "ADP Integration With Cold Email Sales Tool",
    "h1": "Integrate ADP with SalesBlink",
    "metaDescription": "Integrate ADP and other HR Talent & Recruitment Tools with SalesBlink. Two Way ADP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Google Apps For Work",
    "slug": "google-apps-for-work",
    "logo": "https://zapier-images.imgix.net/storage/services/f087156931fdfb48831de1aaf3f5905c_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Google Apps for Work is a brand of cloud computing, productivity and collaboration tools, software and products developed by Google.",
    "category": "Team Collaboration",
    "title": "Google Apps For Work Integration With Cold Email Sales Tool",
    "h1": "Integrate Google Apps For Work with SalesBlink",
    "metaDescription": "Integrate Google Apps For Work and other Team Collaboration Tools with SalesBlink. Two Way Google Apps For Work Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Protonmail",
    "slug": "protonmail",
    "logo": "https://zapier-images.imgix.net/storage/services/1e494df1e48eb48f2763f6e059ab6519_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProtonMail is an end-to-end encrypted email service. ProtonMail uses client-side encryption to protect email contents and user data before they are sent to ProtonMail servers.",
    "category": "Email",
    "title": "Protonmail Integration With Cold Email Sales Tool",
    "h1": "Integrate Protonmail with SalesBlink",
    "metaDescription": "Integrate Protonmail and other Email Tools with SalesBlink. Two Way Protonmail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WhatsApp Messenger",
    "slug": "whatsapp-messenger",
    "logo": "https://zapier-images.imgix.net/storage/services/704b06004a3879651f5a4002c606115d_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WhatsApp Messenger is a freeware and cross-platform messaging service. The application allows the sending of text messages and voice calls, as well as video calls, images and other files.",
    "category": "Phone & SMS",
    "title": "WhatsApp Messenger Integration With Cold Email Sales Tool",
    "h1": "Integrate WhatsApp Messenger with SalesBlink",
    "metaDescription": "Integrate WhatsApp Messenger and other Phone & SMS Tools with SalesBlink. Two Way WhatsApp Messenger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instructure",
    "slug": "instructure",
    "logo": "https://zapier-images.imgix.net/storage/services/b95de84e33a55e92024d753f0b276500_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instructure, Inc. is an educational technology company. It is the developer of the Canvas learning management system, a comprehensive cloud-native software package.",
    "category": "Online Courses",
    "title": "Instructure Integration With Cold Email Sales Tool",
    "h1": "Integrate Instructure with SalesBlink",
    "metaDescription": "Integrate Instructure and other Online Courses Tools with SalesBlink. Two Way Instructure Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Domo",
    "slug": "domo",
    "logo": "https://zapier-images.imgix.net/storage/services/1a2a548567f774e02da0312e219e937b_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Domo, Inc. is a computer software company specializeing in business intelligence tools and data visualization.",
    "category": "Databases",
    "title": "Domo Integration With Cold Email Sales Tool",
    "h1": "Integrate Domo with SalesBlink",
    "metaDescription": "Integrate Domo and other Databases Tools with SalesBlink. Two Way Domo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eTapestry",
    "slug": "etapestry",
    "logo": "https://zapier-images.imgix.net/storage/services/a4fc80ceda5bbf9847805e7b27f2afef_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eTapestry is the leading web-based, or on-demand, database software for fundraising, donor management, church management, and communication with over 5000 customers worldwide.",
    "category": "Fundraising",
    "title": "eTapestry Integration With Cold Email Sales Tool",
    "h1": "Integrate eTapestry with SalesBlink",
    "metaDescription": "Integrate eTapestry and other Fundraising Tools with SalesBlink. Two Way eTapestry Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FastMail",
    "slug": "fastmail",
    "logo": "https://zapier-images.imgix.net/storage/services/3b330b07c433fb9118e3b268d36709e6_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FastMail is an email service offering paid email accounts for individuals and organisations. It is provided in 36 languages to customers worldwide.",
    "category": "Email",
    "title": "FastMail Integration With Cold Email Sales Tool",
    "h1": "Integrate FastMail with SalesBlink",
    "metaDescription": "Integrate FastMail and other Email Tools with SalesBlink. Two Way FastMail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Segment",
    "slug": "segment",
    "logo": "https://zapier-images.imgix.net/storage/services/673eb6d4c7059cc954ca60cdcb2eef9b_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Segment is a customer data management and analytics solution that helps you make sense of customer data coming from multiple various sources.",
    "category": "Ads & Conversion",
    "title": "Segment Integration With Cold Email Sales Tool",
    "h1": "Integrate Segment with SalesBlink",
    "metaDescription": "Integrate Segment and other Ads & Conversion Tools with SalesBlink. Two Way Segment Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Yext",
    "slug": "yext",
    "logo": "https://zapier-images.imgix.net/storage/services/448c2b32a881e45cc829b76e1eeeb16a_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yext is a data management tool that is designed to keep track of your business' location-related information on multiple directories.",
    "category": "Product Management",
    "title": "Yext Integration With Cold Email Sales Tool",
    "h1": "Integrate Yext with SalesBlink",
    "metaDescription": "Integrate Yext and other Product Management Tools with SalesBlink. Two Way Yext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avalara",
    "slug": "avalara",
    "logo": "https://zapier-images.imgix.net/storage/services/a371b2500b58603a752d01c1c88bb778_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Avalara is software for automated tax compliance. Whether you are selling online, starting a new business, or need help filing internationally Avalara can help.",
    "category": "Accounting",
    "title": "Avalara Integration With Cold Email Sales Tool",
    "h1": "Integrate Avalara with SalesBlink",
    "metaDescription": "Integrate Avalara and other Accounting Tools with SalesBlink. Two Way Avalara Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gleam",
    "slug": "gleam",
    "logo": "https://zapier-images.imgix.net/storage/services/18ddf65ea7d8619a1882275ea719d0a9_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build powerful competitions and sweepstakes for your business or clients. Our huge range of action combinations, integrations and widget features help you build a wide variety of campaigns.",
    "category": "Website Builders",
    "title": "Gleam Integration With Cold Email Sales Tool",
    "h1": "Integrate Gleam with SalesBlink",
    "metaDescription": "Integrate Gleam and other Website Builders Tools with SalesBlink. Two Way Gleam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bluebeam",
    "slug": "bluebeam",
    "logo": "https://zapier-images.imgix.net/storage/services/a4ea8f63a1177bb7c7d225946923da2c_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bluebeam develops innovative solutions that set the standard for collaboration and workflow efficiency for design and construction professionals.",
    "category": "Team Collaboration",
    "title": "Bluebeam Integration With Cold Email Sales Tool",
    "h1": "Integrate Bluebeam with SalesBlink",
    "metaDescription": "Integrate Bluebeam and other Team Collaboration Tools with SalesBlink. Two Way Bluebeam Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Meethue",
    "slug": "meethue",
    "logo": "https://zapier-images.imgix.net/storage/services/f6b7145d8a30f3e909bbdec66a218c7a_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Your personal wireless lighting that lets you easily control your light from your device and create the right ambiance for your every moments.",
    "category": "Devices",
    "title": "Meethue Integration With Cold Email Sales Tool",
    "h1": "Integrate Meethue with SalesBlink",
    "metaDescription": "Integrate Meethue and other Devices Tools with SalesBlink. Two Way Meethue Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MileIQ",
    "slug": "mileiq",
    "logo": "https://zapier-images.imgix.net/storage/services/a09c4c2639282e57d954fef443aad077_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MileIQ is an American-based technology company that develops a mileage tracking and logging app.",
    "category": "Analytics",
    "title": "MileIQ Integration With Cold Email Sales Tool",
    "h1": "Integrate MileIQ with SalesBlink",
    "metaDescription": "Integrate MileIQ and other Analytics Tools with SalesBlink. Two Way MileIQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Optimizely",
    "slug": "optimizely",
    "logo": "https://zapier-images.imgix.net/storage/services/19a7d03d999519827b4674e2549970c8_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Optimizely platform technology provides A/B testing tools, in which two versions of a web page can be compared for performance, and multivariate testing.",
    "category": "Developer Tools",
    "title": "Optimizely Integration With Cold Email Sales Tool",
    "h1": "Integrate Optimizely with SalesBlink",
    "metaDescription": "Integrate Optimizely and other Developer Tools Tools with SalesBlink. Two Way Optimizely Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Como",
    "slug": "como",
    "logo": "https://zapier-images.imgix.net/storage/services/f237c2202beebeab61871f797454b373_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Como Sense constantly senses who your customers are and what they buy.They empower POS companies with next-generation personalization for their business clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Como Integration With Cold Email Sales Tool",
    "h1": "Integrate Como with SalesBlink",
    "metaDescription": "Integrate Como and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Como Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fountain",
    "slug": "fountain",
    "logo": "https://zapier-images.imgix.net/storage/services/492639a397e5846875ff94318f2853a9_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fountain brings hiring into one place so you can hire faster, whether your company is gig economy, franchise, or a small business.",
    "category": "HR Talent & Recruitment",
    "title": "Fountain Integration With Cold Email Sales Tool",
    "h1": "Integrate Fountain with SalesBlink",
    "metaDescription": "Integrate Fountain and other HR Talent & Recruitment Tools with SalesBlink. Two Way Fountain Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DojoMojo",
    "slug": "dojomojo",
    "logo": "https://zapier-images.imgix.net/storage/services/4b2d71d20d50bb29f5934f857c460da0_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DojoMojo is an online platform that helps its users grow their email list, increase social engagement, and generate sales by running sweepstakes along with performance analytics.",
    "category": "Marketing Automation",
    "title": "DojoMojo Integration With Cold Email Sales Tool",
    "h1": "Integrate DojoMojo with SalesBlink",
    "metaDescription": "Integrate DojoMojo and other Marketing Automation Tools with SalesBlink. Two Way DojoMojo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Spredfast",
    "slug": "spredfast",
    "logo": "https://zapier-images.imgix.net/storage/services/3324b06a41edcbec84134b12d12a7552_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Spredfast provides community management, social media marketing, content management, social care, and social media analytics tools to enterprise brands and agencies.",
    "category": "Social Media Marketing",
    "title": "Spredfast Integration With Cold Email Sales Tool",
    "h1": "Integrate Spredfast with SalesBlink",
    "metaDescription": "Integrate Spredfast and other Social Media Marketing Tools with SalesBlink. Two Way Spredfast Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sisense",
    "slug": "sisense",
    "logo": "https://zapier-images.imgix.net/storage/services/92dc006194605ca2d05e3df976733306_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Business Intelligence software by Sisense - easily prepare, analyze & explore growing data from multiple sources.",
    "category": "Analytics",
    "title": "Sisense Integration With Cold Email Sales Tool",
    "h1": "Integrate Sisense with SalesBlink",
    "metaDescription": "Integrate Sisense and other Analytics Tools with SalesBlink. Two Way Sisense Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mixmax",
    "slug": "mixmax",
    "logo": "https://zapier-images.imgix.net/storage/services/53ea897fcf187bbe2d0782123ebff324_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mixmax’s mission is to do the impossible with email including scheduling meetings, completing surveys, making purchases, and more.",
    "category": "Email",
    "title": "Mixmax Integration With Cold Email Sales Tool",
    "h1": "Integrate Mixmax with SalesBlink",
    "metaDescription": "Integrate Mixmax and other Email Tools with SalesBlink. Two Way Mixmax Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Salsify",
    "slug": "salsify",
    "logo": "https://zapier-images.imgix.net/storage/services/636da862e3c9c5bdbeea57223c963939_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salsify's Product Experience Management (PXM) platform enables brands to deliver the product experiences consumers demand.",
    "category": "Product Management",
    "title": "Salsify Integration With Cold Email Sales Tool",
    "h1": "Integrate Salsify with SalesBlink",
    "metaDescription": "Integrate Salsify and other Product Management Tools with SalesBlink. Two Way Salsify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vertafore",
    "slug": "vertafore",
    "logo": "https://zapier-images.imgix.net/storage/services/2922216c69549014fbba6d13d92a6cdd_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vertafore provides insurance software solutions for independent agencies and brokers, MGAs, and carriers, helping them better manage their businesses.",
    "category": "Project Management",
    "title": "Vertafore Integration With Cold Email Sales Tool",
    "h1": "Integrate Vertafore with SalesBlink",
    "metaDescription": "Integrate Vertafore and other Project Management Tools with SalesBlink. Two Way Vertafore Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Outreach",
    "slug": "outreach",
    "logo": "https://zapier-images.imgix.net/storage/services/d3355715cc7e7b58e462be0a94d7e456_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Outreach Sales Engagement Platform helps efficiently and effectively engage prospects to drive more pipeline and close more deals.",
    "category": "Marketing Automation",
    "title": "Outreach Integration With Cold Email Sales Tool",
    "h1": "Integrate Outreach with SalesBlink",
    "metaDescription": "Integrate Outreach and other Marketing Automation Tools with SalesBlink. Two Way Outreach Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Jamf",
    "slug": "jamf",
    "logo": "https://zapier-images.imgix.net/storage/services/42df56d10ddcda76bd23f4b073d92783_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jamf is a comprehensive management system for Apple macOS computers and iOS devices.",
    "category": "Developer Tools",
    "title": "Jamf Integration With Cold Email Sales Tool",
    "h1": "Integrate Jamf with SalesBlink",
    "metaDescription": "Integrate Jamf and other Developer Tools Tools with SalesBlink. Two Way Jamf Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ShopKeep",
    "slug": "shopkeep",
    "logo": "https://zapier-images.imgix.net/storage/services/b839054db0610176f2cb884cc970f530_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ShopKeep is a cloud-based iPad point of sale system used by more than 23,000 small businesses in the United States and Canada.",
    "category": "Payment Processing",
    "title": "ShopKeep Integration With Cold Email Sales Tool",
    "h1": "Integrate ShopKeep with SalesBlink",
    "metaDescription": "Integrate ShopKeep and other Payment Processing Tools with SalesBlink. Two Way ShopKeep Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Celery",
    "slug": "celery",
    "logo": "https://zapier-images.imgix.net/storage/services/788393505df8413f7b3946ae5e93ad0b_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Celery is a pre-order platform that brings your products to life. Use Celery for your pre-order, custom crowdfunding, and e-commerce needs.",
    "category": "eCommerce",
    "title": "Celery Integration With Cold Email Sales Tool",
    "h1": "Integrate Celery with SalesBlink",
    "metaDescription": "Integrate Celery and other eCommerce Tools with SalesBlink. Two Way Celery Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sendible",
    "slug": "sendible",
    "logo": "https://zapier-images.imgix.net/storage/services/12773f123e6864491da6a7a35b85a0af_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sendible is an elegant software that provides social media management to businesses of all sizes across all industries.",
    "category": "Social Media Marketing",
    "title": "Sendible Integration With Cold Email Sales Tool",
    "h1": "Integrate Sendible with SalesBlink",
    "metaDescription": "Integrate Sendible and other Social Media Marketing Tools with SalesBlink. Two Way Sendible Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Function Point",
    "slug": "function-point",
    "logo": "https://zapier-images.imgix.net/storage/services/ba25c9c96d332cebdc7eb999f7662243_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Productivity tools built for creative agencies. Function Point combines project management, time tracking, budgeting, resourcing, reporting, and much more.",
    "category": "Project Management",
    "title": "Function Point Integration With Cold Email Sales Tool",
    "h1": "Integrate Function Point with SalesBlink",
    "metaDescription": "Integrate Function Point and other Project Management Tools with SalesBlink. Two Way Function Point Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OnSIP",
    "slug": "onsip",
    "logo": "https://zapier-images.imgix.net/storage/services/391176eeddbc9d7afde5b70ff3bfddaa_9.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OnSIP is a powerfully simple cloud phone system and CPaaS that starts with free.",
    "category": "Phone & SMS",
    "title": "OnSIP Integration With Cold Email Sales Tool",
    "h1": "Integrate OnSIP with SalesBlink",
    "metaDescription": "Integrate OnSIP and other Phone & SMS Tools with SalesBlink. Two Way OnSIP Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Move",
    "slug": "move",
    "logo": "https://zapier-images.imgix.net/storage/services/fc2c93f9815331904508ff1f7064bdcc_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Move, Inc. is a real estate listing company based in Santa Clara, California.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Move Integration With Cold Email Sales Tool",
    "h1": "Integrate Move with SalesBlink",
    "metaDescription": "Integrate Move and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Move Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deltek",
    "slug": "deltek",
    "logo": "https://zapier-images.imgix.net/storage/services/36a6f28ac2c72817ed582a121dff9238_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deltek Vision is a cloud-based project-based solution for professional services firms that is available for cloud or on-premise deployment.",
    "category": "Project Management",
    "title": "Deltek Integration With Cold Email Sales Tool",
    "h1": "Integrate Deltek with SalesBlink",
    "metaDescription": "Integrate Deltek and other Project Management Tools with SalesBlink. Two Way Deltek Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TrueCoach",
    "slug": "truecoach",
    "logo": "https://zapier-images.imgix.net/storage/services/e76ca45e07054066546600067ebe18f1_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The only personal training platform built to save you time and grow your business. Trusted by over 5000 coaches and gym owners worldwide.",
    "category": "Fitness",
    "title": "TrueCoach Integration With Cold Email Sales Tool",
    "h1": "Integrate TrueCoach with SalesBlink",
    "metaDescription": "Integrate TrueCoach and other Fitness Tools with SalesBlink. Two Way TrueCoach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bloomfire",
    "slug": "bloomfire",
    "logo": "https://zapier-images.imgix.net/storage/services/fedededaf2fa212fe7fa9fa53bf6301a_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bloomfire creates web-based software applications that aim to increase virtual knowledge-and-information-sharing in the workplace.",
    "category": "Team Collaboration",
    "title": "Bloomfire Integration With Cold Email Sales Tool",
    "h1": "Integrate Bloomfire with SalesBlink",
    "metaDescription": "Integrate Bloomfire and other Team Collaboration Tools with SalesBlink. Two Way Bloomfire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClickDimensions",
    "slug": "clickdimensions",
    "logo": "https://zapier-images.imgix.net/storage/services/b8c0e845515affa7668c7ae0917f09ca_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClickDimensions provides email marketing, web intelligence, lead scoring, nurture marketing, social discovery, form capture, surveys and more.",
    "category": "Marketing Automation",
    "title": "ClickDimensions Integration With Cold Email Sales Tool",
    "h1": "Integrate ClickDimensions with SalesBlink",
    "metaDescription": "Integrate ClickDimensions and other Marketing Automation Tools with SalesBlink. Two Way ClickDimensions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NewsBlur",
    "slug": "newsblur",
    "logo": "https://zapier-images.imgix.net/storage/services/425caa7550efad3595630765104db83f_10.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NewsBlur runs an online RSS news reader service accessible both online and via a free open-source mobile app for offline reading.",
    "category": "News & Lifestyle",
    "title": "NewsBlur Integration With Cold Email Sales Tool",
    "h1": "Integrate NewsBlur with SalesBlink",
    "metaDescription": "Integrate NewsBlur and other News & Lifestyle Tools with SalesBlink. Two Way NewsBlur Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Urban Airship",
    "slug": "urban-airship",
    "logo": "https://zapier-images.imgix.net/storage/services/3cb6253e66ec5eb3b2b5f1663859ea72_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A market-leading mobile app engagement, mobile analytics, mobile data integration and mobile wallet marketing solution.",
    "category": "Developer Tools",
    "title": "Urban Airship Integration With Cold Email Sales Tool",
    "h1": "Integrate Urban Airship with SalesBlink",
    "metaDescription": "Integrate Urban Airship and other Developer Tools Tools with SalesBlink. Two Way Urban Airship Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextUs",
    "slug": "textus",
    "logo": "https://zapier-images.imgix.net/storage/services/50145c5599c88e603b52e53dbbb2569b_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TextUs is the leading business-class text messaging platform built specifically for inside sales, recruiting and customer service teams.",
    "category": "Phone & SMS",
    "title": "TextUs Integration With Cold Email Sales Tool",
    "h1": "Integrate TextUs with SalesBlink",
    "metaDescription": "Integrate TextUs and other Phone & SMS Tools with SalesBlink. Two Way TextUs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ximble",
    "slug": "ximble",
    "logo": "https://zapier-images.imgix.net/storage/services/118ab78cc422f0ca62789eff386de472_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ximble is a powerful, cloud-based employee scheduling app with precise time tracking for retailers, restaurants and small businesses.",
    "category": "Scheduling & Booking",
    "title": "Ximble Integration With Cold Email Sales Tool",
    "h1": "Integrate Ximble with SalesBlink",
    "metaDescription": "Integrate Ximble and other Scheduling & Booking Tools with SalesBlink. Two Way Ximble Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swell Rewards",
    "slug": "swell-rewards",
    "logo": "https://zapier-images.imgix.net/storage/services/202e724524446eb6d4774fc2f1c4e6ec_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swell is a SaaS-based platform designed to help omnichannel merchants better understand, engage, and retain their existing customers through a variety of automated and proven-effective marketing campaigns.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Swell Rewards Integration With Cold Email Sales Tool",
    "h1": "Integrate Swell Rewards with SalesBlink",
    "metaDescription": "Integrate Swell Rewards and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Swell Rewards Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rollbar",
    "slug": "rollbar",
    "logo": "https://zapier-images.imgix.net/storage/services/62fe822f443cfcecf36ec903b4ef2409.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rollbar provides real-time error alerting & debugging tools for developers.  Ruby, Python, PHP, Node.js, JavaScript, Android, iOS & more languages supported.",
    "category": "Developer Tools",
    "title": "Rollbar Integration With Cold Email Sales Tool",
    "h1": "Integrate Rollbar with SalesBlink",
    "metaDescription": "Integrate Rollbar and other Developer Tools Tools with SalesBlink. Two Way Rollbar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Redash",
    "slug": "redash",
    "logo": "https://zapier-images.imgix.net/storage/services/967798bf7a2ae7d14a10f080d671c113_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Redash is an open source SaaS application to query your data sources, visualize the results, build dashboards and share data with your team.",
    "category": "Developer Tools",
    "title": "Redash Integration With Cold Email Sales Tool",
    "h1": "Integrate Redash with SalesBlink",
    "metaDescription": "Integrate Redash and other Developer Tools Tools with SalesBlink. Two Way Redash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inside Real Estate",
    "slug": "inside-real-estate",
    "logo": "https://zapier-images.imgix.net/storage/services/b4deb1ff8d654e4be4b793707b3c6137_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inside Real Estate Lead Generation, Websites, Brokerage CRM and Reporting System. Manage Leads from any source, and drive quality real estate leads.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Inside Real Estate Integration With Cold Email Sales Tool",
    "h1": "Integrate Inside Real Estate with SalesBlink",
    "metaDescription": "Integrate Inside Real Estate and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Inside Real Estate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IntakeQ",
    "slug": "intakeq",
    "logo": "https://zapier-images.imgix.net/storage/services/fb7a1ac9b587bb6aad71b069df598bdc_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IntakeQ is a tool that sends private electronic intake forms to your clients. We are HIPAA compliant and support e-signatures.",
    "category": "Developer Tools",
    "title": "IntakeQ Integration With Cold Email Sales Tool",
    "h1": "Integrate IntakeQ with SalesBlink",
    "metaDescription": "Integrate IntakeQ and other Developer Tools Tools with SalesBlink. Two Way IntakeQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Webconnex",
    "slug": "webconnex",
    "logo": "https://zapier-images.imgix.net/storage/services/66e9fd097150c90f0e8cd5db9c5a3deb_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Thousands of the world's biggest and most trusted brands use Webconnex products for their fundraising and events.",
    "category": "Fundraising",
    "title": "Webconnex Integration With Cold Email Sales Tool",
    "h1": "Integrate Webconnex with SalesBlink",
    "metaDescription": "Integrate Webconnex and other Fundraising Tools with SalesBlink. Two Way Webconnex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vyper",
    "slug": "vyper",
    "logo": "https://zapier-images.imgix.net/storage/services/ee7ad8577b5430e865aca5c2f150911e_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "VYPER is a viral marketing software created to help business, organizations, sales and marketing teams to harness the power viral contests.",
    "category": "Marketing Automation",
    "title": "Vyper Integration With Cold Email Sales Tool",
    "h1": "Integrate Vyper with SalesBlink",
    "metaDescription": "Integrate Vyper and other Marketing Automation Tools with SalesBlink. Two Way Vyper Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Landvoice",
    "slug": "landvoice",
    "logo": "https://zapier-images.imgix.net/storage/services/d7afe65275076ed21f8a66e99b5a9780_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Landvoice is the premier lead provider for the real estate industry. Landvoice finds the most accurate phone numbers for your leads.",
    "category": "Phone & SMS",
    "title": "Landvoice Integration With Cold Email Sales Tool",
    "h1": "Integrate Landvoice with SalesBlink",
    "metaDescription": "Integrate Landvoice and other Phone & SMS Tools with SalesBlink. Two Way Landvoice Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IBM Watson Marketing",
    "slug": "ibm-watson-marketing",
    "logo": "https://zapier-images.imgix.net/storage/services/502ea9d1dea724efafd10ff24c2b7329_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IBM Watson Marketing, an AI-powered digital marketing platform designed to help you engage and understand your customers at scale, wherever they are.",
    "category": "Marketing Automation",
    "title": "IBM Watson Marketing Integration With Cold Email Sales Tool",
    "h1": "Integrate IBM Watson Marketing with SalesBlink",
    "metaDescription": "Integrate IBM Watson Marketing and other Marketing Automation Tools with SalesBlink. Two Way IBM Watson Marketing Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "AppointmentPlus",
    "slug": "appointmentplus",
    "logo": "https://zapier-images.imgix.net/storage/services/4ce8908f7fc3cdfae19cb8fedf9f281b_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Discover an online appointment scheduling software flexible for any volume of your business scheduling & security needs. Scheduling ahead of its time.",
    "category": "Scheduling & Booking",
    "title": "AppointmentPlus Integration With Cold Email Sales Tool",
    "h1": "Integrate AppointmentPlus with SalesBlink",
    "metaDescription": "Integrate AppointmentPlus and other Scheduling & Booking Tools with SalesBlink. Two Way AppointmentPlus Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Akeneo",
    "slug": "akeneo",
    "logo": "https://zapier-images.imgix.net/storage/services/7964c54b180941185d123dfdc0cba031_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Akeneo is a Product Information Management software that helps more than 40000 marketing teams worldwide to take care of their product data.",
    "category": "Product Management",
    "title": "Akeneo Integration With Cold Email Sales Tool",
    "h1": "Integrate Akeneo with SalesBlink",
    "metaDescription": "Integrate Akeneo and other Product Management Tools with SalesBlink. Two Way Akeneo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qzzr",
    "slug": "qzzr",
    "logo": "https://zapier-images.imgix.net/storage/services/329c7d24ab34a802d8ecce8369852fad_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qzzr is a simple online quiz tool that allows get better leads, improved engagement, and more revenue.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Qzzr Integration With Cold Email Sales Tool",
    "h1": "Integrate Qzzr with SalesBlink",
    "metaDescription": "Integrate Qzzr and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Qzzr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salesforce Marketing Cloud",
    "slug": "salesforce-marketing-cloud",
    "logo": "https://zapier-images.imgix.net/storage/services/a96b1fa3bfefafe53b95500f03b0f5df_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Salesforce Marketing Cloud is a provider of digital marketing automation and analytics software and services.",
    "category": "Ads & Conversion",
    "title": "Salesforce Marketing Cloud Integration With Cold Email Sales Tool",
    "h1": "Integrate Salesforce Marketing Cloud with SalesBlink",
    "metaDescription": "Integrate Salesforce Marketing Cloud and other Ads & Conversion Tools with SalesBlink. Two Way Salesforce Marketing Cloud Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cosential",
    "slug": "cosential",
    "logo": "https://zapier-images.imgix.net/storage/services/59bec37a507b9beb54d15e959ba46768_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cosential is a CRM and Proposal Database solution that allows AEC firms the ability to access and act upon data specific to their role.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Cosential Integration With Cold Email Sales Tool",
    "h1": "Integrate Cosential with SalesBlink",
    "metaDescription": "Integrate Cosential and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Cosential Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Invanto",
    "slug": "invanto",
    "logo": "https://zapier-images.imgix.net/storage/services/37fa0a5c38d6d610ed695c3246427570_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Invanto business centralization suite is designed to provide an all-in-one solution to build & automate your business.",
    "category": "Project Management",
    "title": "Invanto Integration With Cold Email Sales Tool",
    "h1": "Integrate Invanto with SalesBlink",
    "metaDescription": "Integrate Invanto and other Project Management Tools with SalesBlink. Two Way Invanto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Usabilla",
    "slug": "usabilla",
    "logo": "https://zapier-images.imgix.net/storage/services/287e267db1bfb323a7feb61bb6feb89f_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Headquartered in Amsterdam, Usabilla was founded in 2009 with the belief that continuous user feedback is the key to any successful website, product or service.",
    "category": "Customer Appreciation",
    "title": "Usabilla Integration With Cold Email Sales Tool",
    "h1": "Integrate Usabilla with SalesBlink",
    "metaDescription": "Integrate Usabilla and other Customer Appreciation Tools with SalesBlink. Two Way Usabilla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adobe Sign",
    "slug": "adobe-sign",
    "logo": "https://zapier-images.imgix.net/storage/services/1a5f4a2b06e318a896efb4906332093a_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adobe Sign, an Adobe Document Cloud solution is a cloud-based, enterprise-class e-signature service that lets you replace paper and ink signature processes with fully automated electronic signature workflows.",
    "category": "Documents",
    "title": "Adobe Sign Integration With Cold Email Sales Tool",
    "h1": "Integrate Adobe Sign with SalesBlink",
    "metaDescription": "Integrate Adobe Sign and other Documents Tools with SalesBlink. Two Way Adobe Sign Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Subbly",
    "slug": "subbly",
    "logo": "https://zapier-images.imgix.net/storage/services/ebca3e219888051c880443fef5a283d0_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Subbly is an all-in-one subscription eCommerce solution that provides vendors with everything they need to set up a subscription box business on one simple platform.",
    "category": "eCommerce",
    "title": "Subbly Integration With Cold Email Sales Tool",
    "h1": "Integrate Subbly with SalesBlink",
    "metaDescription": "Integrate Subbly and other eCommerce Tools with SalesBlink. Two Way Subbly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FunnelDash",
    "slug": "funneldash",
    "logo": "https://zapier-images.imgix.net/storage/services/623a3cf88b6b4e71549bc727cd5113a6_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Funneldash is the ultimate agency growth funnel software as it combines data from multiple CRM, Payment and Advertising solutions to show key metrics about your funnels.",
    "category": "Analytics",
    "title": "FunnelDash Integration With Cold Email Sales Tool",
    "h1": "Integrate FunnelDash with SalesBlink",
    "metaDescription": "Integrate FunnelDash and other Analytics Tools with SalesBlink. Two Way FunnelDash Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Indeed",
    "slug": "indeed",
    "logo": "https://zapier-images.imgix.net/storage/services/e6c535821fa858174399c8b20b1a28d4_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Indeed is an American worldwide employment-related search engine for job listings launched in November 2004.",
    "category": "HR Talent & Recruitment",
    "title": "Indeed Integration With Cold Email Sales Tool",
    "h1": "Integrate Indeed with SalesBlink",
    "metaDescription": "Integrate Indeed and other HR Talent & Recruitment Tools with SalesBlink. Two Way Indeed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scale",
    "slug": "scale",
    "logo": "https://zapier-images.imgix.net/storage/services/9bd40bf12366907d9ab1959078cf18f2_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scale accelerates the development of AI applications by helping computer vision teams generate high-quality ground truth data.",
    "category": "Developer Tools",
    "title": "Scale Integration With Cold Email Sales Tool",
    "h1": "Integrate Scale with SalesBlink",
    "metaDescription": "Integrate Scale and other Developer Tools Tools with SalesBlink. Two Way Scale Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Instabug",
    "slug": "instabug",
    "logo": "https://zapier-images.imgix.net/storage/services/cab1fedf9c0c0c2b47797fe03ad4223a_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Instabug is a software company that provides bug reporting, crash reporting, in-app chats, and user surveys for mobile apps.",
    "category": "Developer Tools",
    "title": "Instabug Integration With Cold Email Sales Tool",
    "h1": "Integrate Instabug with SalesBlink",
    "metaDescription": "Integrate Instabug and other Developer Tools Tools with SalesBlink. Two Way Instabug Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Jama",
    "slug": "jama",
    "logo": "https://zapier-images.imgix.net/storage/services/455f008a13ad3250d9a80112c4ea5f79_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jama Software is a leading product development platform provider for companies building complex products and integrated systems.",
    "category": "Developer Tools",
    "title": "Jama Integration With Cold Email Sales Tool",
    "h1": "Integrate Jama with SalesBlink",
    "metaDescription": "Integrate Jama and other Developer Tools Tools with SalesBlink. Two Way Jama Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Snipcart",
    "slug": "snipcart",
    "logo": "https://zapier-images.imgix.net/storage/services/6e0330acbc44758f914df61abbcba7a9_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Snipcart is a comprehensive e-commerce platform that has been designed to be easy to incorporate e-commerce into any custom website.",
    "category": "eCommerce",
    "title": "Snipcart Integration With Cold Email Sales Tool",
    "h1": "Integrate Snipcart with SalesBlink",
    "metaDescription": "Integrate Snipcart and other eCommerce Tools with SalesBlink. Two Way Snipcart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Yandex",
    "slug": "yandex",
    "logo": "https://zapier-images.imgix.net/storage/services/ee65c54b95c569204306447fbbcd1ff8_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Yandex specializes in Internet-related products and services, including search and information services, eCommerce, transportation, navigation, mobile applications, and online advertising.",
    "category": "Product Management",
    "title": "Yandex Integration With Cold Email Sales Tool",
    "h1": "Integrate Yandex with SalesBlink",
    "metaDescription": "Integrate Yandex and other Product Management Tools with SalesBlink. Two Way Yandex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Actsoft",
    "slug": "actsoft",
    "logo": "https://zapier-images.imgix.net/storage/services/4acc3b98809c9a573f81eda54dbb5c12_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Actsoft is the leading provider of mobile applications for businesses with workers in the field. Scalable and Affordable, with Award Winning Customer Care!",
    "category": "Task Management",
    "title": "Actsoft Integration With Cold Email Sales Tool",
    "h1": "Integrate Actsoft with SalesBlink",
    "metaDescription": "Integrate Actsoft and other Task Management Tools with SalesBlink. Two Way Actsoft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zendesk Chat",
    "slug": "zendesk-chat",
    "logo": "https://zapier-images.imgix.net/storage/services/a04d9349153453c7945ee360d0a68a8b_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Zendesk platform lets agents switch between serving chats, phone calls, emails, or social media messages.",
    "category": "Customer Support",
    "title": "Zendesk Chat Integration With Cold Email Sales Tool",
    "h1": "Integrate Zendesk Chat with SalesBlink",
    "metaDescription": "Integrate Zendesk Chat and other Customer Support Tools with SalesBlink. Two Way Zendesk Chat Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Openpath",
    "slug": "openpath",
    "logo": "https://zapier-images.imgix.net/storage/services/6efd8192365855bd79c8a267951f2f8f_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Openpath provides mobile enabled cloud-based access control system solutions that support mobile phone, badge, and key card credentials, and PIN codes.",
    "category": "Security & Identity Tools",
    "title": "Openpath Integration With Cold Email Sales Tool",
    "h1": "Integrate Openpath with SalesBlink",
    "metaDescription": "Integrate Openpath and other Security & Identity Tools Tools with SalesBlink. Two Way Openpath Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ParseHub",
    "slug": "parsehub",
    "logo": "https://zapier-images.imgix.net/storage/services/9a88c75f37cb68d3093c8f54657c0a1e_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ParseHub is a free web scraping tool. With our advanced web scraper, extracting data is as easy as clicking the data you need.",
    "category": "Developer Tools",
    "title": "ParseHub Integration With Cold Email Sales Tool",
    "h1": "Integrate ParseHub with SalesBlink",
    "metaDescription": "Integrate ParseHub and other Developer Tools Tools with SalesBlink. Two Way ParseHub Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "CartHook",
    "slug": "carthook",
    "logo": "https://zapier-images.imgix.net/storage/services/056aab37828431302e3f19c9146b6915_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CartHook Inc. operates a website that automatically recovers abandoned carts for ecommerce sites.",
    "category": "eCommerce",
    "title": "CartHook Integration With Cold Email Sales Tool",
    "h1": "Integrate CartHook with SalesBlink",
    "metaDescription": "Integrate CartHook and other eCommerce Tools with SalesBlink. Two Way CartHook Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amity",
    "slug": "amity",
    "logo": "https://zapier-images.imgix.net/storage/services/1af3611ea7245a7852262d30da94968d_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Amity provides the world’s most powerful Customer Success software. Amity, senses changes in customer health or rhythms, recommends the right actions and measures effectiveness.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Amity Integration With Cold Email Sales Tool",
    "h1": "Integrate Amity with SalesBlink",
    "metaDescription": "Integrate Amity and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Amity Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Stamped.io",
    "slug": "stamped-io",
    "logo": "https://zapier-images.imgix.net/storage/services/9e8a3b94a509ab63c85bf57ec6084095_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The user-content generation app to gather and leverage on product reviews, questions & answers & feedbacks from your users & customers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Stamped.io Integration With Cold Email Sales Tool",
    "h1": "Integrate Stamped.io with SalesBlink",
    "metaDescription": "Integrate Stamped.io and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Stamped.io Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "CloudShare",
    "slug": "cloudshare",
    "logo": "https://zapier-images.imgix.net/storage/services/5557ac9c9b7cd577e5947a8a8bc5cb47_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CloudShare provides secure, specialized virtual IT labs for training, sales, sandboxing, and support.",
    "category": "Developer Tools",
    "title": "CloudShare Integration With Cold Email Sales Tool",
    "h1": "Integrate CloudShare with SalesBlink",
    "metaDescription": "Integrate CloudShare and other Developer Tools Tools with SalesBlink. Two Way CloudShare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Opencart",
    "slug": "opencart",
    "logo": "https://zapier-images.imgix.net/storage/services/009dc9265a6db4d9bbefb4610bafb241_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OpenCart is an online store management system. It is PHP-based, using a MySQL database and HTML components",
    "category": "eCommerce",
    "title": "Opencart Integration With Cold Email Sales Tool",
    "h1": "Integrate Opencart with SalesBlink",
    "metaDescription": "Integrate Opencart and other eCommerce Tools with SalesBlink. Two Way Opencart Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Msn",
    "slug": "msn",
    "logo": "https://zapier-images.imgix.net/storage/services/f83bb9e09c1c65d3329faa19255ec41b_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MSN is a web portal and related collection of Internet services and apps for Windows and mobile devices.",
    "category": "Website Builders",
    "title": "Msn Integration With Cold Email Sales Tool",
    "h1": "Integrate Msn with SalesBlink",
    "metaDescription": "Integrate Msn and other Website Builders Tools with SalesBlink. Two Way Msn Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vooplayer",
    "slug": "vooplayer",
    "logo": "https://zapier-images.imgix.net/storage/services/7d5ebea08b8e89970139b533cc7dd0ec_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "vooPlayer is a professional video hosting solution with a sophisticated suite of video marketing tools that helps businesses grow with video.",
    "category": "Video & Audio",
    "title": "Vooplayer Integration With Cold Email Sales Tool",
    "h1": "Integrate Vooplayer with SalesBlink",
    "metaDescription": "Integrate Vooplayer and other Video & Audio Tools with SalesBlink. Two Way Vooplayer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Adobe",
    "slug": "adobe",
    "logo": "https://zapier-images.imgix.net/storage/services/b5ac5c726200875a19327019ea7a3b36_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adobe Inc., commonly known as Adobe, is an American multinational computer software company.",
    "category": "Developer Tools",
    "title": "Adobe Integration With Cold Email Sales Tool",
    "h1": "Integrate Adobe with SalesBlink",
    "metaDescription": "Integrate Adobe and other Developer Tools Tools with SalesBlink. Two Way Adobe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Salespad",
    "slug": "salespad",
    "logo": "https://zapier-images.imgix.net/storage/services/ab156d3fa8926e21ef1b5b98b0da98bc_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesPad Cloud brings enterprise-level functionality and visibility to QuickBooks Online for your whole distribution business.",
    "category": "Accounting",
    "title": "Salespad Integration With Cold Email Sales Tool",
    "h1": "Integrate Salespad with SalesBlink",
    "metaDescription": "Integrate Salespad and other Accounting Tools with SalesBlink. Two Way Salespad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Viber",
    "slug": "viber",
    "logo": "https://zapier-images.imgix.net/storage/services/2a26c977b3f3bde02bb5934300ea4d22_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Viber allows people to connect through individual messaging, video calls, group chats or following brands and celebrities on Public Chats.",
    "category": "Phone & SMS",
    "title": "Viber Integration With Cold Email Sales Tool",
    "h1": "Integrate Viber with SalesBlink",
    "metaDescription": "Integrate Viber and other Phone & SMS Tools with SalesBlink. Two Way Viber Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Amplience",
    "slug": "amplience",
    "logo": "https://zapier-images.imgix.net/storage/services/cfc89aa76a1abe70c5cda08d4473c8b7_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The Amplience platform supports online retailers with a combination of content management, dynamic media transcoding and content delivery.",
    "category": "eCommerce",
    "title": "Amplience Integration With Cold Email Sales Tool",
    "h1": "Integrate Amplience with SalesBlink",
    "metaDescription": "Integrate Amplience and other eCommerce Tools with SalesBlink. Two Way Amplience Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orca",
    "slug": "orca",
    "logo": "https://zapier-images.imgix.net/storage/services/5a0cd4c0ff0179661bb89c0d7e52a61d_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Orca enables B2B sales teams to efficiently and effectively engage prospects to drive more pipeline and close more deals through automated Linkedin engagements.",
    "category": "Marketing Automation",
    "title": "Orca Integration With Cold Email Sales Tool",
    "h1": "Integrate Orca with SalesBlink",
    "metaDescription": "Integrate Orca and other Marketing Automation Tools with SalesBlink. Two Way Orca Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Appfigures",
    "slug": "appfigures",
    "logo": "https://zapier-images.imgix.net/storage/services/f97bd038bed7477b5bde820815bbd4f6_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Appfigures provides actionable insights and analytics to hundreds of thousands of app publishers, marketers, analysts, and mobile service providers world wide.",
    "category": "Ads & Conversion",
    "title": "Appfigures Integration With Cold Email Sales Tool",
    "h1": "Integrate Appfigures with SalesBlink",
    "metaDescription": "Integrate Appfigures and other Ads & Conversion Tools with SalesBlink. Two Way Appfigures Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "99Designs",
    "slug": "99designs",
    "logo": "https://zapier-images.imgix.net/storage/services/1b6536cf642c0591b04d8a242239e5a2_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The top creative platform for custom graphic design: logos, websites and more. Hire a talented designer or start a design contest.",
    "category": "Website Builders",
    "title": "99Designs Integration With Cold Email Sales Tool",
    "h1": "Integrate 99Designs with SalesBlink",
    "metaDescription": "Integrate 99Designs and other Website Builders Tools with SalesBlink. Two Way 99Designs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Saasoptics",
    "slug": "saasoptics",
    "logo": "https://zapier-images.imgix.net/storage/services/6c907f27aa54cacebce4fea586bceac0_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "B2B subscription management platform that delivers subscription invoicing and payments, GAAP revenue recognition, subscription management and robust subscription metrics and analytics.",
    "category": "Accounting",
    "title": "Saasoptics Integration With Cold Email Sales Tool",
    "h1": "Integrate Saasoptics with SalesBlink",
    "metaDescription": "Integrate Saasoptics and other Accounting Tools with SalesBlink. Two Way Saasoptics Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Betwext",
    "slug": "betwext",
    "logo": "https://zapier-images.imgix.net/storage/services/7a07a4938c36a54b4200c9ed3461225a_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Betwext creates and manages web-based applications centered around providing various SMS marketing services to consumers and businesses.",
    "category": "Phone & SMS",
    "title": "Betwext Integration With Cold Email Sales Tool",
    "h1": "Integrate Betwext with SalesBlink",
    "metaDescription": "Integrate Betwext and other Phone & SMS Tools with SalesBlink. Two Way Betwext Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Qminder",
    "slug": "qminder",
    "logo": "https://zapier-images.imgix.net/storage/services/b75a91b4d39303ab27a44b5f46744e3f_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Qminder is a cloud-based queue management system helping retail locations manage their sales floor, and enables customers to shop instead of waiting in a line.",
    "category": "Scheduling & Booking",
    "title": "Qminder Integration With Cold Email Sales Tool",
    "h1": "Integrate Qminder with SalesBlink",
    "metaDescription": "Integrate Qminder and other Scheduling & Booking Tools with SalesBlink. Two Way Qminder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Oanda",
    "slug": "oanda",
    "logo": "https://zapier-images.imgix.net/storage/services/2d7ee95043cae0a6e7cb19e6b837b582_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OANDA Corporation is a foreign exchange company that provides internet-based forex trading and currency information services.",
    "category": "Accounting",
    "title": "Oanda Integration With Cold Email Sales Tool",
    "h1": "Integrate Oanda with SalesBlink",
    "metaDescription": "Integrate Oanda and other Accounting Tools with SalesBlink. Two Way Oanda Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Intellect",
    "slug": "intellect",
    "logo": "https://cdn.zapier.com/zapier/images/logomark250.png",
    "appDescription": "Intellect is a leader in Enterprise SaaS software providing an electronic Quality Management System (eQMS) and Business Process Management (BPM) product suite.",
    "category": "Project Management",
    "title": "Intellect Integration With Cold Email Sales Tool",
    "h1": "Integrate Intellect with SalesBlink",
    "metaDescription": "Integrate Intellect and other Project Management Tools with SalesBlink. Two Way Intellect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Unbabel",
    "slug": "unbabel",
    "logo": "https://zapier-images.imgix.net/storage/services/fc0a49396f133e0aa9afc815175366ca_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unbabel’s “Translation as a Service” platform allows modern enterprises to understand and be understood by their customers in dozens of languages.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Unbabel Integration With Cold Email Sales Tool",
    "h1": "Integrate Unbabel with SalesBlink",
    "metaDescription": "Integrate Unbabel and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Unbabel Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vonigo",
    "slug": "vonigo",
    "logo": "https://zapier-images.imgix.net/storage/services/05926ea3d56962c6a6af830273f8737c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vonigo provides SaaS business management software for mobile services companies.",
    "category": "Product Management",
    "title": "Vonigo Integration With Cold Email Sales Tool",
    "h1": "Integrate Vonigo with SalesBlink",
    "metaDescription": "Integrate Vonigo and other Product Management Tools with SalesBlink. Two Way Vonigo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swydo",
    "slug": "swydo",
    "logo": "https://zapier-images.imgix.net/storage/services/6a79d72c92fbf83d0c439a1e506e69bc_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Adwords, PPC and Analytics reporting, monitoring and workflow tool for online marketers to save time and show their impact.",
    "category": "Ads & Conversion",
    "title": "Swydo Integration With Cold Email Sales Tool",
    "h1": "Integrate Swydo with SalesBlink",
    "metaDescription": "Integrate Swydo and other Ads & Conversion Tools with SalesBlink. Two Way Swydo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Evercontact",
    "slug": "evercontact",
    "logo": "https://zapier-images.imgix.net/storage/services/5f5315edfcbbedfef1cd1cf83bd44828_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Evercontact turns email signatures into address book contacts, automatically adding new contacts for you and auto-updating existing ones.",
    "category": "Contact Management",
    "title": "Evercontact Integration With Cold Email Sales Tool",
    "h1": "Integrate Evercontact with SalesBlink",
    "metaDescription": "Integrate Evercontact and other Contact Management Tools with SalesBlink. Two Way Evercontact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Deezer",
    "slug": "deezer",
    "logo": "https://zapier-images.imgix.net/storage/services/fcc63d14eae504954cfdd9ee87ebd0ea_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Deezer is the world’s most diverse, dynamic and personal global music streaming company and includes a unique personalisation model, Flow.",
    "category": "Fitness",
    "title": "Deezer Integration With Cold Email Sales Tool",
    "h1": "Integrate Deezer with SalesBlink",
    "metaDescription": "Integrate Deezer and other Fitness Tools with SalesBlink. Two Way Deezer Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Unify",
    "slug": "unify",
    "logo": "https://zapier-images.imgix.net/storage/services/bb7ef89d509ca62928033fc802688bdb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Unify is one of the world’s leading communications software and services brands, providing integrated communications and collaboration solutions worldwide.",
    "category": "Team Collaboration",
    "title": "Unify Integration With Cold Email Sales Tool",
    "h1": "Integrate Unify with SalesBlink",
    "metaDescription": "Integrate Unify and other Team Collaboration Tools with SalesBlink. Two Way Unify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Onereach",
    "slug": "onereach",
    "logo": "https://zapier-images.imgix.net/storage/services/a8661de37d25101c88a12fa844c6d26a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneReach is an award-winning cloud communication platform where companies can create custom voice and text solutions.",
    "category": "Phone & SMS",
    "title": "Onereach Integration With Cold Email Sales Tool",
    "h1": "Integrate Onereach with SalesBlink",
    "metaDescription": "Integrate Onereach and other Phone & SMS Tools with SalesBlink. Two Way Onereach Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Abbyy",
    "slug": "abbyy",
    "logo": "https://zapier-images.imgix.net/storage/services/279b2191d4887f16cf6d556fa1195896_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ABBYY provides document conversion, optical character recognition, data capture, and linguistic software and services.",
    "category": "Documents",
    "title": "Abbyy Integration With Cold Email Sales Tool",
    "h1": "Integrate Abbyy with SalesBlink",
    "metaDescription": "Integrate Abbyy and other Documents Tools with SalesBlink. Two Way Abbyy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Naver",
    "slug": "naver",
    "logo": "https://zapier-images.imgix.net/storage/services/1ec8cdacd9218c036c1484b38adb9a6b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Naver Corporation runs South Korea's #1 search engine and offers a large variety of online services for Asian users.",
    "category": "Website Builders",
    "title": "Naver Integration With Cold Email Sales Tool",
    "h1": "Integrate Naver with SalesBlink",
    "metaDescription": "Integrate Naver and other Website Builders Tools with SalesBlink. Two Way Naver Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Avvo",
    "slug": "avvo",
    "logo": "https://zapier-images.imgix.net/storage/services/39645913df8ca80a7dd317cb59b249e3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "An online marketplace for legal services, that provides lawyer referrals and access to a database of legal information consisting primarily of previously answered questions.",
    "category": "Website Builders",
    "title": "Avvo Integration With Cold Email Sales Tool",
    "h1": "Integrate Avvo with SalesBlink",
    "metaDescription": "Integrate Avvo and other Website Builders Tools with SalesBlink. Two Way Avvo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Particle",
    "slug": "particle",
    "logo": "https://zapier-images.imgix.net/storage/services/d16e6c0e1e5019263a5cc872260d68d3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Particle is an Internet of Things (IoT) device platform that enables businesses to easily add the power of connectivity to any product.",
    "category": "Devices",
    "title": "Particle Integration With Cold Email Sales Tool",
    "h1": "Integrate Particle with SalesBlink",
    "metaDescription": "Integrate Particle and other Devices Tools with SalesBlink. Two Way Particle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elvanto",
    "slug": "elvanto",
    "logo": "https://zapier-images.imgix.net/storage/services/cacac0ca9f4effb7e49ec6ef118f5b04_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Experience all-inclusive, church management software that includes full worship planning.",
    "category": "Project Management",
    "title": "Elvanto Integration With Cold Email Sales Tool",
    "h1": "Integrate Elvanto with SalesBlink",
    "metaDescription": "Integrate Elvanto and other Project Management Tools with SalesBlink. Two Way Elvanto Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Grafana",
    "slug": "grafana",
    "logo": "https://zapier-images.imgix.net/storage/services/da03d4ff21450d899810303acd71f3f5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Grafana offers application analytics visualization services and features graphing, styling, template variables, panels, and themes.",
    "category": "Ads & Conversion",
    "title": "Grafana Integration With Cold Email Sales Tool",
    "h1": "Integrate Grafana with SalesBlink",
    "metaDescription": "Integrate Grafana and other Ads & Conversion Tools with SalesBlink. Two Way Grafana Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Lulu.Com",
    "slug": "lulu-com",
    "logo": "https://zapier-images.imgix.net/storage/services/6daf2db111214bd2da2cd8f2687aba13_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Lulu Press operates as a web-based electronic publishing company that allows customers to publish and sell online content.",
    "category": "Online Courses",
    "title": "Lulu.Com Integration With Cold Email Sales Tool",
    "h1": "Integrate Lulu.Com with SalesBlink",
    "metaDescription": "Integrate Lulu.Com and other Online Courses Tools with SalesBlink. Two Way Lulu.Com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sb",
    "slug": "sb",
    "logo": "https://zapier-images.imgix.net/storage/services/5703aa84aa98c53bf6f2f886a46819f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Best Award-Winning customer relationship management web based CRM software solutions, the most user friendly & affordable hosted crm software solution.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Sb Integration With Cold Email Sales Tool",
    "h1": "Integrate Sb with SalesBlink",
    "metaDescription": "Integrate Sb and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Sb Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Barricade",
    "slug": "barricade",
    "logo": "https://zapier-images.imgix.net/storage/services/1a235053483a054c6ca3bae6adb3f4bf_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Barricade works like an early warning system against any attempts at breaching the security of servers. It can also identify any security threats",
    "category": "Server Monitoring",
    "title": "Barricade Integration With Cold Email Sales Tool",
    "h1": "Integrate Barricade with SalesBlink",
    "metaDescription": "Integrate Barricade and other Server Monitoring Tools with SalesBlink. Two Way Barricade Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Boomr",
    "slug": "boomr",
    "logo": "https://zapier-images.imgix.net/storage/services/f89c2d0409100fd5951a7ff2fdbf40bc_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Boomr's employee time tracking, timesheets, and time clock app makes it easy to accurately track & manage your workforce, payroll & more.",
    "category": "Time Tracking Software",
    "title": "Boomr Integration With Cold Email Sales Tool",
    "h1": "Integrate Boomr with SalesBlink",
    "metaDescription": "Integrate Boomr and other Time Tracking Software Tools with SalesBlink. Two Way Boomr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Genesys",
    "slug": "genesys",
    "logo": "https://zapier-images.imgix.net/storage/services/b3f09c7b1282dba2b5e213e32ed4b006_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Genesys is an omnichannel customer experience (CX) and contact center solutions in the cloud and on-premises.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Genesys Integration With Cold Email Sales Tool",
    "h1": "Integrate Genesys with SalesBlink",
    "metaDescription": "Integrate Genesys and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Genesys Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "QQ",
    "slug": "qq",
    "logo": "https://zapier-images.imgix.net/storage/services/4e00f77bb6e3d2705e027c2bb9242868_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tencent QQ , also known as QQ, is an instant messaging software service developed by the Chinese company Shenzhen Tencent Computer System Co.,",
    "category": "Phone & SMS",
    "title": "QQ Integration With Cold Email Sales Tool",
    "h1": "Integrate QQ with SalesBlink",
    "metaDescription": "Integrate QQ and other Phone & SMS Tools with SalesBlink. Two Way QQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rafflecopter",
    "slug": "rafflecopter",
    "logo": "https://zapier-images.imgix.net/storage/services/1b2238aa51c1216bb03eed8a9b4776f7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rafflecopter allows you to customize and embed an entry form on your site that incentivizes your audience to perform tasks in exchange for entries into a sweepstakes.",
    "category": "Website Builders",
    "title": "Rafflecopter Integration With Cold Email Sales Tool",
    "h1": "Integrate Rafflecopter with SalesBlink",
    "metaDescription": "Integrate Rafflecopter and other Website Builders Tools with SalesBlink. Two Way Rafflecopter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Reputation Loop",
    "slug": "reputation-loop",
    "logo": "https://zapier-images.imgix.net/storage/services/d3702951733e9baa01800214e1a7829d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We promote small business growth online through our automated reputation marketing software",
    "category": "Marketing Automation",
    "title": "Reputation Loop Integration With Cold Email Sales Tool",
    "h1": "Integrate Reputation Loop with SalesBlink",
    "metaDescription": "Integrate Reputation Loop and other Marketing Automation Tools with SalesBlink. Two Way Reputation Loop Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sonar",
    "slug": "sonar",
    "logo": "https://zapier-images.imgix.net/storage/services/07e73ec123c633e63b7c4b8f9f19e9ae_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sonar is a platform that enables companies to communicate with customers over 2-way text messages such as SMS and mobile chat channels.",
    "category": "Customer Support",
    "title": "Sonar Integration With Cold Email Sales Tool",
    "h1": "Integrate Sonar with SalesBlink",
    "metaDescription": "Integrate Sonar and other Customer Support Tools with SalesBlink. Two Way Sonar Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Paycor",
    "slug": "paycor",
    "logo": "https://zapier-images.imgix.net/storage/services/3ce35b7a65615ed99eaff2eb5c943fb2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Intuitive onboarding, HR, payroll and timekeeping software supported by personalized support.",
    "category": "HR Talent & Recruitment",
    "title": "Paycor Integration With Cold Email Sales Tool",
    "h1": "Integrate Paycor with SalesBlink",
    "metaDescription": "Integrate Paycor and other HR Talent & Recruitment Tools with SalesBlink. Two Way Paycor Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gmx",
    "slug": "gmx",
    "logo": "https://zapier-images.imgix.net/storage/services/cae12e0b67da2ffb8b0e48618769698c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Advanced, Savvy, Different. With 13 million customers strong, GMX is the ideal email solution for you.",
    "category": "Email",
    "title": "Gmx Integration With Cold Email Sales Tool",
    "h1": "Integrate Gmx with SalesBlink",
    "metaDescription": "Integrate Gmx and other Email Tools with SalesBlink. Two Way Gmx Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Great Agent",
    "slug": "great-agent",
    "logo": "https://zapier-images.imgix.net/storage/services/42abdcf9c60ee76e1bed2453dde1e7cd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Great Agent is an End-to-End Sales & Marketing Solution.",
    "category": "Marketing Automation",
    "title": "Great Agent Integration With Cold Email Sales Tool",
    "h1": "Integrate Great Agent with SalesBlink",
    "metaDescription": "Integrate Great Agent and other Marketing Automation Tools with SalesBlink. Two Way Great Agent Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Uberflip",
    "slug": "uberflip",
    "logo": "https://zapier-images.imgix.net/storage/services/1a44f555bed8644baa713760bf8c3467_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Uberflip is a cloud-based Content Experience Platform that lets businesses create remarkable content experiences for every stage of the buyer journey.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Uberflip Integration With Cold Email Sales Tool",
    "h1": "Integrate Uberflip with SalesBlink",
    "metaDescription": "Integrate Uberflip and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Uberflip Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loomio",
    "slug": "loomio",
    "logo": "https://zapier-images.imgix.net/storage/services/0ab3ef3c90a87c291b597f9a81258e1b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loomio reduces the cost of participation in decision-making, making it easy for any group to translate online communication into real-world collective action.",
    "category": "Team Collaboration",
    "title": "Loomio Integration With Cold Email Sales Tool",
    "h1": "Integrate Loomio with SalesBlink",
    "metaDescription": "Integrate Loomio and other Team Collaboration Tools with SalesBlink. Two Way Loomio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Openhab",
    "slug": "openhab",
    "logo": "https://zapier-images.imgix.net/storage/services/6bb105a431d3d5624ef01f72ea8ee117_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "openHAB - a vendor and technology agnostic open source automation software for your home",
    "category": "Devices",
    "title": "Openhab Integration With Cold Email Sales Tool",
    "h1": "Integrate Openhab with SalesBlink",
    "metaDescription": "Integrate Openhab and other Devices Tools with SalesBlink. Two Way Openhab Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Habitica",
    "slug": "habitica",
    "logo": "https://zapier-images.imgix.net/storage/services/48946d34736655cc1725bccf259bd0c7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Habitica is a free habit and productivity app that treats your real life like a game. Habitica can help you achieve your goals to become healthy and happy.",
    "category": "Devices",
    "title": "Habitica Integration With Cold Email Sales Tool",
    "h1": "Integrate Habitica with SalesBlink",
    "metaDescription": "Integrate Habitica and other Devices Tools with SalesBlink. Two Way Habitica Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Kaltura",
    "slug": "kaltura",
    "logo": "https://zapier-images.imgix.net/storage/services/39b74b282c18553de205e02cc8df363f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kaltura is the leading video platform powering any video experience for any organization.",
    "category": "Video Conferencing",
    "title": "Kaltura Integration With Cold Email Sales Tool",
    "h1": "Integrate Kaltura with SalesBlink",
    "metaDescription": "Integrate Kaltura and other Video Conferencing Tools with SalesBlink. Two Way Kaltura Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voicebase",
    "slug": "voicebase",
    "logo": "https://zapier-images.imgix.net/storage/services/eb13fbe8f47c7a27e1ffa7d4da7d9cef_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Provides simple APIs for automatic speech-to-text, speech analytics and predictive insights.",
    "category": "Ads & Conversion",
    "title": "Voicebase Integration With Cold Email Sales Tool",
    "h1": "Integrate Voicebase with SalesBlink",
    "metaDescription": "Integrate Voicebase and other Ads & Conversion Tools with SalesBlink. Two Way Voicebase Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Anyleads",
    "slug": "anyleads",
    "logo": "https://zapier-images.imgix.net/storage/services/b190bd01040ee42f92c274b3d0d1097e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Anyleads is a marketing agency that provides marketing automation solutions to small and larger businesses.",
    "category": "Marketing Automation",
    "title": "Anyleads Integration With Cold Email Sales Tool",
    "h1": "Integrate Anyleads with SalesBlink",
    "metaDescription": "Integrate Anyleads and other Marketing Automation Tools with SalesBlink. Two Way Anyleads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cmap",
    "slug": "cmap",
    "logo": "https://zapier-images.imgix.net/storage/services/5d55d21d944b6e09acdf3159e846a099_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud platform for creating and embedding Location Analytics into enterprise apps and Business Intelligence.",
    "category": "Ads & Conversion",
    "title": "Cmap Integration With Cold Email Sales Tool",
    "h1": "Integrate Cmap with SalesBlink",
    "metaDescription": "Integrate Cmap and other Ads & Conversion Tools with SalesBlink. Two Way Cmap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ezcater",
    "slug": "ezcater",
    "logo": "https://zapier-images.imgix.net/storage/services/ffe6818ab7bac76b73428f55eabfa3a3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ezCater is an online catering marketplace that allows individuals to order food from local caterers in the U.S.",
    "category": "Website Builders",
    "title": "Ezcater Integration With Cold Email Sales Tool",
    "h1": "Integrate Ezcater with SalesBlink",
    "metaDescription": "Integrate Ezcater and other Website Builders Tools with SalesBlink. Two Way Ezcater Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Circleci",
    "slug": "circleci",
    "logo": "https://zapier-images.imgix.net/storage/services/b4b00707a81592e8573139ecac43b1c7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CircleCI’s continuous integration and delivery platform makes it easy for teams of all sizes to rapidly build and release quality software at scale.",
    "category": "Developer Tools",
    "title": "Circleci Integration With Cold Email Sales Tool",
    "h1": "Integrate Circleci with SalesBlink",
    "metaDescription": "Integrate Circleci and other Developer Tools Tools with SalesBlink. Two Way Circleci Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Easy Digital Downloads",
    "slug": "easy-digital-downloads",
    "logo": "https://zapier-images.imgix.net/storage/services/747d4c1616c3e4d3ee36e0cee2c4396f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Easy Digital Downloads is a complete eCommerce solution for WordPress. Use it right out of the box or tailor it to your business with add-ons.",
    "category": "eCommerce",
    "title": "Easy Digital Downloads Integration With Cold Email Sales Tool",
    "h1": "Integrate Easy Digital Downloads with SalesBlink",
    "metaDescription": "Integrate Easy Digital Downloads and other eCommerce Tools with SalesBlink. Two Way Easy Digital Downloads Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ongage",
    "slug": "ongage",
    "logo": "https://zapier-images.imgix.net/storage/services/13d0aa1b1383589161f0c56fac2aa8cb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Powered by a feature-rich dashboard that hosts advanced segmentation tools and a full suite of analytics, ultimately helping marketers drive measurable growth and increase deliverability.",
    "category": "Dashboards",
    "title": "Ongage Integration With Cold Email Sales Tool",
    "h1": "Integrate Ongage with SalesBlink",
    "metaDescription": "Integrate Ongage and other Dashboards Tools with SalesBlink. Two Way Ongage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Smarp",
    "slug": "smarp",
    "logo": "https://zapier-images.imgix.net/storage/services/a45edf42473fd89eb9ecd7e0c9ff7d54_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SaaS solution with which companies can empower their employees to discover share content created by the company such as blogs, events, and open positions to their social media networks.",
    "category": "Social Media Marketing",
    "title": "Smarp Integration With Cold Email Sales Tool",
    "h1": "Integrate Smarp with SalesBlink",
    "metaDescription": "Integrate Smarp and other Social Media Marketing Tools with SalesBlink. Two Way Smarp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tierion",
    "slug": "tierion",
    "logo": "https://zapier-images.imgix.net/storage/services/cba7d55e660b02913ea19f93752ebc53_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Tierion turns the blockchain into a global platform for verifying any data, file, or business process.",
    "category": "Developer Tools",
    "title": "Tierion Integration With Cold Email Sales Tool",
    "h1": "Integrate Tierion with SalesBlink",
    "metaDescription": "Integrate Tierion and other Developer Tools Tools with SalesBlink. Two Way Tierion Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emerge",
    "slug": "emerge",
    "logo": "https://zapier-images.imgix.net/storage/services/eeb530b2c562bc0c6eba5dd892311391_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eMerge is a marketing automation technology and marketing services provider to the real estate industry.",
    "category": "Marketing Automation",
    "title": "Emerge Integration With Cold Email Sales Tool",
    "h1": "Integrate Emerge with SalesBlink",
    "metaDescription": "Integrate Emerge and other Marketing Automation Tools with SalesBlink. Two Way Emerge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Later.Com",
    "slug": "later-com",
    "logo": "https://zapier-images.imgix.net/storage/services/c2f26b33529d86e46409e7a7026ea5ad_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A visual content marketing-based application that enables its users to plan, schedule, and manage content for Instagram.",
    "category": "Social Media Marketing",
    "title": "Later.Com Integration With Cold Email Sales Tool",
    "h1": "Integrate Later.Com with SalesBlink",
    "metaDescription": "Integrate Later.Com and other Social Media Marketing Tools with SalesBlink. Two Way Later.Com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Room",
    "slug": "room",
    "logo": "https://zapier-images.imgix.net/storage/services/7effd8b3dde82487b01389da902bddc2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Stress-free video meeting and screen sharing, right within your browser and on mobile devices.",
    "category": "Video Conferencing",
    "title": "Room Integration With Cold Email Sales Tool",
    "h1": "Integrate Room with SalesBlink",
    "metaDescription": "Integrate Room and other Video Conferencing Tools with SalesBlink. Two Way Room Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Polycom",
    "slug": "polycom",
    "logo": "https://zapier-images.imgix.net/storage/services/e51df8e5c6df8c890cd78ecc2ec2482a_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Polycom provides open standards-based unified communications solutions for telepresence video and voice.",
    "category": "Video Conferencing",
    "title": "Polycom Integration With Cold Email Sales Tool",
    "h1": "Integrate Polycom with SalesBlink",
    "metaDescription": "Integrate Polycom and other Video Conferencing Tools with SalesBlink. Two Way Polycom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buildfire",
    "slug": "buildfire",
    "logo": "https://zapier-images.imgix.net/storage/services/9774d11331829621cea714effb37780f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BuildFire provides users with a seamless way to build mobile apps with no coding required.",
    "category": "App Builder",
    "title": "Buildfire Integration With Cold Email Sales Tool",
    "h1": "Integrate Buildfire with SalesBlink",
    "metaDescription": "Integrate Buildfire and other App Builder Tools with SalesBlink. Two Way Buildfire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Voucherify",
    "slug": "voucherify",
    "logo": "https://zapier-images.imgix.net/storage/services/9bc313b11c3d394c243a5cc4bda62fbd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Build promotional campaigns faster. Programmable building blocks for coupon, referral, and loyalty programs.",
    "category": "Marketing Automation",
    "title": "Voucherify Integration With Cold Email Sales Tool",
    "h1": "Integrate Voucherify with SalesBlink",
    "metaDescription": "Integrate Voucherify and other Marketing Automation Tools with SalesBlink. Two Way Voucherify Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Quriobot",
    "slug": "quriobot",
    "logo": "https://zapier-images.imgix.net/storage/services/65841e1074f2dce929f58f02efc7f5ee_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A new way of asking for feedback while creating a better experience for everyone involved",
    "category": "Customer Support",
    "title": "Quriobot Integration With Cold Email Sales Tool",
    "h1": "Integrate Quriobot with SalesBlink",
    "metaDescription": "Integrate Quriobot and other Customer Support Tools with SalesBlink. Two Way Quriobot Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Cin7",
    "slug": "cin7",
    "logo": "https://zapier-images.imgix.net/storage/services/e80893fdacc176346240381aa5984fe4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud-based solution with fully integrated inventory management, POS, EDI and 3PL functions in a single system.",
    "category": "Product Management",
    "title": "Cin7 Integration With Cold Email Sales Tool",
    "h1": "Integrate Cin7 with SalesBlink",
    "metaDescription": "Integrate Cin7 and other Product Management Tools with SalesBlink. Two Way Cin7 Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Shopvox",
    "slug": "shopvox",
    "logo": "https://zapier-images.imgix.net/storage/services/f358520b92537bc0f254a95c98cc1916_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "shopVOX is a web-based job management, CRM, and POS system built for its users’ custom shops and industries.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Shopvox Integration With Cold Email Sales Tool",
    "h1": "Integrate Shopvox with SalesBlink",
    "metaDescription": "Integrate Shopvox and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Shopvox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fabric",
    "slug": "fabric",
    "logo": "https://zapier-images.imgix.net/storage/services/7069471d3d6add5e419858484b49c29a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fabric is a smart new app that allows its community to build want-to lists for entertainment",
    "category": "Fitness",
    "title": "Fabric Integration With Cold Email Sales Tool",
    "h1": "Integrate Fabric with SalesBlink",
    "metaDescription": "Integrate Fabric and other Fitness Tools with SalesBlink. Two Way Fabric Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Enjoy HQ",
    "slug": "enjoy-hq",
    "logo": "https://zapier-images.imgix.net/storage/services/cfd3ec4e175589748bdd48273931ac66_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Centralise all your research data and collaborate on insights easily.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Enjoy HQ Integration With Cold Email Sales Tool",
    "h1": "Integrate Enjoy HQ with SalesBlink",
    "metaDescription": "Integrate Enjoy HQ and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Enjoy HQ Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sift Science",
    "slug": "sift-science",
    "logo": "https://zapier-images.imgix.net/storage/services/6162633fc22490889187232633ba48ef_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sift Science applies insights from a global network of data to detect fraud and increase positive user experience.",
    "category": "Ads & Conversion",
    "title": "Sift Science Integration With Cold Email Sales Tool",
    "h1": "Integrate Sift Science with SalesBlink",
    "metaDescription": "Integrate Sift Science and other Ads & Conversion Tools with SalesBlink. Two Way Sift Science Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "10Minutemail",
    "slug": "10minutemail",
    "logo": "https://zapier-images.imgix.net/storage/services/3c9a7729f1d45c1d5d4787d4cf4144f6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Temporary disposable e-mail service to beat spam.  Avoid spam with a free secure e-mail address.",
    "category": "Email",
    "title": "10Minutemail Integration With Cold Email Sales Tool",
    "h1": "Integrate 10Minutemail with SalesBlink",
    "metaDescription": "Integrate 10Minutemail and other Email Tools with SalesBlink. Two Way 10Minutemail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wefunder",
    "slug": "wefunder",
    "logo": "https://zapier-images.imgix.net/storage/services/e9ab63158aaa4935dc985ec0db12f8dd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wefunder is a crowdfunding service which connects startups with investors online.",
    "category": "Fundraising",
    "title": "Wefunder Integration With Cold Email Sales Tool",
    "h1": "Integrate Wefunder with SalesBlink",
    "metaDescription": "Integrate Wefunder and other Fundraising Tools with SalesBlink. Two Way Wefunder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Scribe",
    "slug": "scribe",
    "logo": "https://zapier-images.imgix.net/storage/services/f412fc09812d56c9a950cc36adbeed2f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Scribe lets you convert all your favorite apps into completely customizable Slack Bots, within 5 minutes.",
    "category": "Developer Tools",
    "title": "Scribe Integration With Cold Email Sales Tool",
    "h1": "Integrate Scribe with SalesBlink",
    "metaDescription": "Integrate Scribe and other Developer Tools Tools with SalesBlink. Two Way Scribe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CA Technologies",
    "slug": "ca-technologies",
    "logo": "https://zapier-images.imgix.net/storage/services/3e7ba5d90cbe949dba90c43ebf8a514b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CA Technologies creates software that fuels transformation for companies.",
    "category": "Developer Tools",
    "title": "CA Technologies Integration With Cold Email Sales Tool",
    "h1": "Integrate CA Technologies with SalesBlink",
    "metaDescription": "Integrate CA Technologies and other Developer Tools Tools with SalesBlink. Two Way CA Technologies Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Builtwith",
    "slug": "builtwith",
    "logo": "https://zapier-images.imgix.net/storage/services/9248e6fd8b8fc8dc018ce729492525dd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BuiltWith is a website profiling, lead generation, competitive analysis and BI tool providing technology adoption and usage analytics.",
    "category": "Ads & Conversion",
    "title": "Builtwith Integration With Cold Email Sales Tool",
    "h1": "Integrate Builtwith with SalesBlink",
    "metaDescription": "Integrate Builtwith and other Ads & Conversion Tools with SalesBlink. Two Way Builtwith Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Web.Com",
    "slug": "web-com",
    "logo": "https://zapier-images.imgix.net/storage/services/828889272b4e77db821b9013e2a35471_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Web.com provides internet services and online marketing solutions for small and medium businesses.",
    "category": "Website Builders",
    "title": "Web.Com Integration With Cold Email Sales Tool",
    "h1": "Integrate Web.Com with SalesBlink",
    "metaDescription": "Integrate Web.Com and other Website Builders Tools with SalesBlink. Two Way Web.Com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Commercient",
    "slug": "commercient",
    "logo": "https://zapier-images.imgix.net/storage/services/05722dc89b54c4ee482bd2f3b3bea69e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Commercient provides businesses with fast and flexible ERP & CRM integration apps that provide a 360-degree view of your business.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Commercient Integration With Cold Email Sales Tool",
    "h1": "Integrate Commercient with SalesBlink",
    "metaDescription": "Integrate Commercient and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Commercient Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Riley",
    "slug": "riley",
    "logo": "https://zapier-images.imgix.net/storage/services/fdca65ed213bf1d4200a507de4e0708f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send personalized texts and schedule text campaigns to engage your leads",
    "category": "Phone & SMS",
    "title": "Riley Integration With Cold Email Sales Tool",
    "h1": "Integrate Riley with SalesBlink",
    "metaDescription": "Integrate Riley and other Phone & SMS Tools with SalesBlink. Two Way Riley Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Balanced",
    "slug": "balanced",
    "logo": "https://zapier-images.imgix.net/storage/services/b9e98954620e4f58550af3d9db331cc3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Balanced is an advocacy organization focused on improving the healthfulness of menus in schools and hospitals.",
    "category": "Website Builders",
    "title": "Balanced Integration With Cold Email Sales Tool",
    "h1": "Integrate Balanced with SalesBlink",
    "metaDescription": "Integrate Balanced and other Website Builders Tools with SalesBlink. Two Way Balanced Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Vend",
    "slug": "vend",
    "logo": "https://zapier-images.imgix.net/storage/services/8bd30626fc66ceb240ee62a8bd85c1e5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Vend is cloud-based point-of-sale and retail management software that lets retailers run their business in-store, online, and via mobile.",
    "category": "Payment Processing",
    "title": "Vend Integration With Cold Email Sales Tool",
    "h1": "Integrate Vend with SalesBlink",
    "metaDescription": "Integrate Vend and other Payment Processing Tools with SalesBlink. Two Way Vend Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Net-Results",
    "slug": "net-results",
    "logo": "https://zapier-images.imgix.net/storage/services/eeffa5553673373e51ea5eb7cd40bd88_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Net-Results helps companies increase revenue growth and improve sales efficiency with powerful lead management solutions for marketing and sales.",
    "category": "Marketing Automation",
    "title": "Net-Results Integration With Cold Email Sales Tool",
    "h1": "Integrate Net-Results with SalesBlink",
    "metaDescription": "Integrate Net-Results and other Marketing Automation Tools with SalesBlink. Two Way Net-Results Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zipbooks",
    "slug": "zipbooks",
    "logo": "https://zapier-images.imgix.net/storage/services/7870e8a8ca77daacd4b6198be0db20ef_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ZipBooks is free accounting software that sends invoices, tracks time and expenses, integrates with your bank & lets you process credit cards.",
    "category": "Accounting",
    "title": "Zipbooks Integration With Cold Email Sales Tool",
    "h1": "Integrate Zipbooks with SalesBlink",
    "metaDescription": "Integrate Zipbooks and other Accounting Tools with SalesBlink. Two Way Zipbooks Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Brand24",
    "slug": "brand24",
    "logo": "https://zapier-images.imgix.net/storage/services/4ebbd842309ff2ac6d518a3f61ef97de_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Brand24 gives you instant access to mentions about your brand across the web.",
    "category": "Website Builders",
    "title": "Brand24 Integration With Cold Email Sales Tool",
    "h1": "Integrate Brand24 with SalesBlink",
    "metaDescription": "Integrate Brand24 and other Website Builders Tools with SalesBlink. Two Way Brand24 Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Subitup",
    "slug": "subitup",
    "logo": "https://zapier-images.imgix.net/storage/services/3beeb230da35a18a37325f999cbe5d55_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SubItUp is an employee management solution designed to streamline staff scheduling, timekeeping, attendance tracking and more.",
    "category": "HR Talent & Recruitment",
    "title": "Subitup Integration With Cold Email Sales Tool",
    "h1": "Integrate Subitup with SalesBlink",
    "metaDescription": "Integrate Subitup and other HR Talent & Recruitment Tools with SalesBlink. Two Way Subitup Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inmoment",
    "slug": "inmoment",
    "logo": "https://zapier-images.imgix.net/storage/services/16472e5a1f0a3cc113ac96d4c9f5162d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InMoment provides SaaS-based customer survey and enterprise feedback management solutions for data collection activities.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Inmoment Integration With Cold Email Sales Tool",
    "h1": "Integrate Inmoment with SalesBlink",
    "metaDescription": "Integrate Inmoment and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Inmoment Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Fieldboom",
    "slug": "fieldboom",
    "logo": "https://zapier-images.imgix.net/storage/services/9479651ab94659a49e691c2110b97e17_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Fieldboom to create conversion quizzes, lead forms and surveys that effortlessly build your email list and generate more sales.",
    "category": "Marketing Automation",
    "title": "Fieldboom Integration With Cold Email Sales Tool",
    "h1": "Integrate Fieldboom with SalesBlink",
    "metaDescription": "Integrate Fieldboom and other Marketing Automation Tools with SalesBlink. Two Way Fieldboom Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Apiary",
    "slug": "apiary",
    "logo": "https://zapier-images.imgix.net/storage/services/acc948cd9676b9ee6a73976e42d3df7e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Apiary provides the leading API-First platform designed specifically to help companies accelerate and control the design, development, and documentation of APIs.",
    "category": "Developer Tools",
    "title": "Apiary Integration With Cold Email Sales Tool",
    "h1": "Integrate Apiary with SalesBlink",
    "metaDescription": "Integrate Apiary and other Developer Tools Tools with SalesBlink. Two Way Apiary Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cliently",
    "slug": "cliently",
    "logo": "https://zapier-images.imgix.net/storage/services/3a62b9ffcad13036a2afe0471ab818e6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our web-based app helps freelancers find new relevant gigs automatically in seconds,  scouring millions of opportunities around the web posted by real companies.",
    "category": "Website Builders",
    "title": "Cliently Integration With Cold Email Sales Tool",
    "h1": "Integrate Cliently with SalesBlink",
    "metaDescription": "Integrate Cliently and other Website Builders Tools with SalesBlink. Two Way Cliently Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Distill",
    "slug": "distill",
    "logo": "https://zapier-images.imgix.net/storage/services/a19783aacdc068f3153162d7af767070_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Distill monitors websites and feeds to track changes. It can send notifications in form of sound, popup, email, sms and more!",
    "category": "Marketing Automation",
    "title": "Distill Integration With Cold Email Sales Tool",
    "h1": "Integrate Distill with SalesBlink",
    "metaDescription": "Integrate Distill and other Marketing Automation Tools with SalesBlink. Two Way Distill Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nvite",
    "slug": "nvite",
    "logo": "https://zapier-images.imgix.net/storage/services/94313a901f084f2062e92180c2a75171_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "nvite is an attendee-focused, online event ticketing and registration platform that helps easily organize and manage events.",
    "category": "Event Management",
    "title": "Nvite Integration With Cold Email Sales Tool",
    "h1": "Integrate Nvite with SalesBlink",
    "metaDescription": "Integrate Nvite and other Event Management Tools with SalesBlink. Two Way Nvite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Autochartist",
    "slug": "autochartist",
    "logo": "https://zapier-images.imgix.net/storage/services/6013dd835143ce10a2baddd1704f4dac_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Autochartist is a powerful pattern recognition tool that simplifies Technical Analysis for traders by identifying 100’s of trading opportunities based on key support and resistance levels.",
    "category": "Marketing Automation",
    "title": "Autochartist Integration With Cold Email Sales Tool",
    "h1": "Integrate Autochartist with SalesBlink",
    "metaDescription": "Integrate Autochartist and other Marketing Automation Tools with SalesBlink. Two Way Autochartist Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gorgias.Io",
    "slug": "gorgias-io",
    "logo": "https://zapier-images.imgix.net/storage/services/0ee23324d0c5f90a38985f3bb52fa999_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Helpdesk designed for Shopify stores. Provide multichannel customer service from a single app. Gain productivity with automated responses to common requests.",
    "category": "Customer Support",
    "title": "Gorgias.Io Integration With Cold Email Sales Tool",
    "h1": "Integrate Gorgias.Io with SalesBlink",
    "metaDescription": "Integrate Gorgias.Io and other Customer Support Tools with SalesBlink. Two Way Gorgias.Io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trackingdesk",
    "slug": "trackingdesk",
    "logo": "https://zapier-images.imgix.net/storage/services/bcd342690164e0f4c454bd3dc866a081_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The most advanced traffic management platform for affiliate marketers and media buyers. Reach positive ROI faster with better tracking and control over your marketing campaigns.",
    "category": "Ads & Conversion",
    "title": "Trackingdesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Trackingdesk with SalesBlink",
    "metaDescription": "Integrate Trackingdesk and other Ads & Conversion Tools with SalesBlink. Two Way Trackingdesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aleyant",
    "slug": "aleyant",
    "logo": "https://zapier-images.imgix.net/storage/services/ab661524a8398f912f0d889b5b26873d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aleyant creates web to print, estimating & production, and prepress automation workflow software for graphic arts professionals.",
    "category": "Project Management",
    "title": "Aleyant Integration With Cold Email Sales Tool",
    "h1": "Integrate Aleyant with SalesBlink",
    "metaDescription": "Integrate Aleyant and other Project Management Tools with SalesBlink. Two Way Aleyant Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Swiftpage",
    "slug": "swiftpage",
    "logo": "https://zapier-images.imgix.net/storage/services/1e2251975970071d248145cbd13e18aa_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Swiftpage is a leader in the small business market, helping to drive customer acquisition and retention using innovative, purpose built software solutions.",
    "category": "Marketing Automation",
    "title": "Swiftpage Integration With Cold Email Sales Tool",
    "h1": "Integrate Swiftpage with SalesBlink",
    "metaDescription": "Integrate Swiftpage and other Marketing Automation Tools with SalesBlink. Two Way Swiftpage Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hoopla",
    "slug": "hoopla",
    "logo": "https://zapier-images.imgix.net/storage/services/af2f11d51f9888c5ffaadc5e71e7d7e1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hoopla is a Sales Motivation Platform. Create an engaged culture with real-timegoal progress, friendly competition, news,and recognition.",
    "category": "HR Talent & Recruitment",
    "title": "Hoopla Integration With Cold Email Sales Tool",
    "h1": "Integrate Hoopla with SalesBlink",
    "metaDescription": "Integrate Hoopla and other HR Talent & Recruitment Tools with SalesBlink. Two Way Hoopla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Faithlife",
    "slug": "faithlife",
    "logo": "https://zapier-images.imgix.net/storage/services/d358d523a2b0af0bf52e565277a7c12d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Faithlife Corporation publishes and creates electronic tools and resources for Bible study.",
    "category": "Online Courses",
    "title": "Faithlife Integration With Cold Email Sales Tool",
    "h1": "Integrate Faithlife with SalesBlink",
    "metaDescription": "Integrate Faithlife and other Online Courses Tools with SalesBlink. Two Way Faithlife Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Realsavvy",
    "slug": "realsavvy",
    "logo": "https://zapier-images.imgix.net/storage/services/92355a85d302b59f72807dcc17018fd9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-1 real estate platform with custom websites, Pinterest-style IDX, branded mobile apps and a powerful CRM.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Realsavvy Integration With Cold Email Sales Tool",
    "h1": "Integrate Realsavvy with SalesBlink",
    "metaDescription": "Integrate Realsavvy and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Realsavvy Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jazz",
    "slug": "jazz",
    "logo": "https://zapier-images.imgix.net/storage/services/c95d1369ee390aa56e3442ae027ea7e1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Optimize your hiring process with JazzHR's award winning recruiting software and applicant tracking system.",
    "category": "HR Talent & Recruitment",
    "title": "Jazz Integration With Cold Email Sales Tool",
    "h1": "Integrate Jazz with SalesBlink",
    "metaDescription": "Integrate Jazz and other HR Talent & Recruitment Tools with SalesBlink. Two Way Jazz Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Consumeraffairs",
    "slug": "consumeraffairs",
    "logo": "https://zapier-images.imgix.net/storage/services/8348fc198a618aeabaffc51263dee1f4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ConsumerAffairs is a consumer news and advocacy organization providing consumer news, recall information, and consumer reviews.",
    "category": "News & Lifestyle",
    "title": "Consumeraffairs Integration With Cold Email Sales Tool",
    "h1": "Integrate Consumeraffairs with SalesBlink",
    "metaDescription": "Integrate Consumeraffairs and other News & Lifestyle Tools with SalesBlink. Two Way Consumeraffairs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dialog",
    "slug": "dialog",
    "logo": "https://zapier-images.imgix.net/storage/services/6df4c0268a8f821ca609e19a208b874b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Handy and feature-rich enterprise multi-device messenger available for server or cloud.",
    "category": "Phone & SMS",
    "title": "Dialog Integration With Cold Email Sales Tool",
    "h1": "Integrate Dialog with SalesBlink",
    "metaDescription": "Integrate Dialog and other Phone & SMS Tools with SalesBlink. Two Way Dialog Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Slaask",
    "slug": "slaask",
    "logo": "https://zapier-images.imgix.net/storage/services/0159ebaa85f0d1b72b93969b26e2eb50_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Slaask is a customer service chat app for Slack that allows you to bring all your teams, clients and communications together in one place.",
    "category": "Team Chat",
    "title": "Slaask Integration With Cold Email Sales Tool",
    "h1": "Integrate Slaask with SalesBlink",
    "metaDescription": "Integrate Slaask and other Team Chat Tools with SalesBlink. Two Way Slaask Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Conflux",
    "slug": "conflux",
    "logo": "https://zapier-images.imgix.net/storage/services/87749e0d28fbc5daf388af6e297946f4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Conflux is an online application that lets you collect feedback, organize it into ideas and communicate those ideas back to your customers so you can collect more feedback.",
    "category": "Product Management",
    "title": "Conflux Integration With Cold Email Sales Tool",
    "h1": "Integrate Conflux with SalesBlink",
    "metaDescription": "Integrate Conflux and other Product Management Tools with SalesBlink. Two Way Conflux Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Nextgen Healthcare",
    "slug": "nextgen-healthcare",
    "logo": "https://zapier-images.imgix.net/storage/services/1e745d42093c1e6ddf417a7ab752b137_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NextGen Healthcare Information Systems provides electronic practice management and medical record systems for physician practices.",
    "category": "Product Management",
    "title": "Nextgen Healthcare Integration With Cold Email Sales Tool",
    "h1": "Integrate Nextgen Healthcare with SalesBlink",
    "metaDescription": "Integrate Nextgen Healthcare and other Product Management Tools with SalesBlink. Two Way Nextgen Healthcare Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cypress",
    "slug": "cypress",
    "logo": "https://zapier-images.imgix.net/storage/services/7f3428d505d782db916feede43dd557e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Fast, easy and reliable testing for anything that runs in a browser. Install Cypress in seconds and take the pain out of front-end testing.",
    "category": "Website Builders",
    "title": "Cypress Integration With Cold Email Sales Tool",
    "h1": "Integrate Cypress with SalesBlink",
    "metaDescription": "Integrate Cypress and other Website Builders Tools with SalesBlink. Two Way Cypress Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TextMe",
    "slug": "textme",
    "logo": "https://zapier-images.imgix.net/storage/services/09261cf9652cb0a624ec7cae48054c3e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Free unlimited texts, calling, and pic messaging to any phone in US, Canada and 40 countries in the World.",
    "category": "Phone & SMS",
    "title": "TextMe Integration With Cold Email Sales Tool",
    "h1": "Integrate TextMe with SalesBlink",
    "metaDescription": "Integrate TextMe and other Phone & SMS Tools with SalesBlink. Two Way TextMe Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kalo",
    "slug": "kalo",
    "logo": "https://zapier-images.imgix.net/storage/services/5c9a1da7d9b488fc2d6643bc5e67bb5f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kalo's Freelance Management System enables the world's biggest companies to organize, onboard, manage, and pay their freelance workforce.",
    "category": "HR Talent & Recruitment",
    "title": "Kalo Integration With Cold Email Sales Tool",
    "h1": "Integrate Kalo with SalesBlink",
    "metaDescription": "Integrate Kalo and other HR Talent & Recruitment Tools with SalesBlink. Two Way Kalo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Automattic",
    "slug": "automattic",
    "logo": "https://zapier-images.imgix.net/storage/services/b9ff0d7ccca01a724ce46be2b1d381cb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automattic Inc. is a web development corporation founded in August 2005. It is most notable for WordPress.com, as well as its contributions to WordPress.",
    "category": "Website Builders",
    "title": "Automattic Integration With Cold Email Sales Tool",
    "h1": "Integrate Automattic with SalesBlink",
    "metaDescription": "Integrate Automattic and other Website Builders Tools with SalesBlink. Two Way Automattic Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sipgate",
    "slug": "sipgate",
    "logo": "https://zapier-images.imgix.net/storage/services/ce8bf208116b0a5374c100d6be8ce11b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "sipgate is one of Europe's leading Business and Residential VoIP Providers, offering high quality pay as you go phone services.",
    "category": "Phone & SMS",
    "title": "Sipgate Integration With Cold Email Sales Tool",
    "h1": "Integrate Sipgate with SalesBlink",
    "metaDescription": "Integrate Sipgate and other Phone & SMS Tools with SalesBlink. Two Way Sipgate Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Trackops",
    "slug": "trackops",
    "logo": "https://zapier-images.imgix.net/storage/services/8db4c27159c5a777f8291685933b89e7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Streamline the process of managing an investigation from start to finish. Trackops will assist your company in becoming more organized, efficient, and profitable with less effort.",
    "category": "Analytics",
    "title": "Trackops Integration With Cold Email Sales Tool",
    "h1": "Integrate Trackops with SalesBlink",
    "metaDescription": "Integrate Trackops and other Analytics Tools with SalesBlink. Two Way Trackops Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Tibbr",
    "slug": "tibbr",
    "logo": "https://zapier-images.imgix.net/storage/services/bd930bdfdd169f2d28647c884f43f0b0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "tibbr is the social network for work. We are a leading platform for enterprise-wide social networking to help connect people, discussions, apps, tasks, & content.",
    "category": "Team Collaboration",
    "title": "Tibbr Integration With Cold Email Sales Tool",
    "h1": "Integrate Tibbr with SalesBlink",
    "metaDescription": "Integrate Tibbr and other Team Collaboration Tools with SalesBlink. Two Way Tibbr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pushed",
    "slug": "pushed",
    "logo": "https://zapier-images.imgix.net/storage/services/2e7fc99ea810cc56d59ecbd7c5ac9f7c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send Push Notifications Without Developing Your Own App. No coding skills required.",
    "category": "App Builder",
    "title": "Pushed Integration With Cold Email Sales Tool",
    "h1": "Integrate Pushed with SalesBlink",
    "metaDescription": "Integrate Pushed and other App Builder Tools with SalesBlink. Two Way Pushed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Orion Advisor Services",
    "slug": "orion-advisor-services",
    "logo": "https://zapier-images.imgix.net/storage/services/128daef8d3455877c4f0a5ab18e5ea27_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Orion was built by advisors, for advisors. Orion helps advisors experience integrated independence and enjoy their business again.",
    "category": "Project Management",
    "title": "Orion Advisor Services Integration With Cold Email Sales Tool",
    "h1": "Integrate Orion Advisor Services with SalesBlink",
    "metaDescription": "Integrate Orion Advisor Services and other Project Management Tools with SalesBlink. Two Way Orion Advisor Services Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Xref",
    "slug": "xref",
    "logo": "https://zapier-images.imgix.net/storage/services/5ec47d97cd7b4127438464271cf1272c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Use Xref's online reference checking platform to gather valuable candidate insights quickly and with ease.",
    "category": "HR Talent & Recruitment",
    "title": "Xref Integration With Cold Email Sales Tool",
    "h1": "Integrate Xref with SalesBlink",
    "metaDescription": "Integrate Xref and other HR Talent & Recruitment Tools with SalesBlink. Two Way Xref Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Signable",
    "slug": "signable",
    "logo": "https://zapier-images.imgix.net/storage/services/eef94920963edb04ed829169224504eb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Signable's electronic signature software sends documents straight to your clients’ inbox in seconds. Sign legally binding & secure documents on any device.",
    "category": "Documents",
    "title": "Signable Integration With Cold Email Sales Tool",
    "h1": "Integrate Signable with SalesBlink",
    "metaDescription": "Integrate Signable and other Documents Tools with SalesBlink. Two Way Signable Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Exponea",
    "slug": "exponea",
    "logo": "https://zapier-images.imgix.net/storage/services/c6023dffea8bd82dac2a2e53de61af72_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Exponea empowers B2C marketers to raise conversion rates, improve acquisition ROI, and maximize customer lifetime value.",
    "category": "Marketing Automation",
    "title": "Exponea Integration With Cold Email Sales Tool",
    "h1": "Integrate Exponea with SalesBlink",
    "metaDescription": "Integrate Exponea and other Marketing Automation Tools with SalesBlink. Two Way Exponea Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mouseflow",
    "slug": "mouseflow",
    "logo": "https://zapier-images.imgix.net/storage/services/bc17ea47fc20f1acb4c0d5316d795d3a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mouseflow is the most popular session replay / recording and website heatmap tool.",
    "category": "Developer Tools",
    "title": "Mouseflow Integration With Cold Email Sales Tool",
    "h1": "Integrate Mouseflow with SalesBlink",
    "metaDescription": "Integrate Mouseflow and other Developer Tools Tools with SalesBlink. Two Way Mouseflow Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dentally",
    "slug": "dentally",
    "logo": "https://zapier-images.imgix.net/storage/services/817235a76368a182249c379ea16b5002_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dentally is amazing dental software for practice management created for private and NHS dental practices.",
    "category": "Project Management",
    "title": "Dentally Integration With Cold Email Sales Tool",
    "h1": "Integrate Dentally with SalesBlink",
    "metaDescription": "Integrate Dentally and other Project Management Tools with SalesBlink. Two Way Dentally Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zingle",
    "slug": "zingle",
    "logo": "https://zapier-images.imgix.net/storage/services/74d1b5ce27d5cd5b62291a69437b3a07_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zingle provides real-time business text messaging solutions in both SMS and MMS to increase customer engagement and loyalty.",
    "category": "Phone & SMS",
    "title": "Zingle Integration With Cold Email Sales Tool",
    "h1": "Integrate Zingle with SalesBlink",
    "metaDescription": "Integrate Zingle and other Phone & SMS Tools with SalesBlink. Two Way Zingle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Taxamo",
    "slug": "taxamo",
    "logo": "https://zapier-images.imgix.net/storage/services/db1d524c49505e0651807355133fff0c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Taxamo offers global VAT/GST compliance designed to grow your business. With our Taxamo Advantage service you can manage your cross-border digital VAT/GST compliance globally, in real-time.",
    "category": "Accounting",
    "title": "Taxamo Integration With Cold Email Sales Tool",
    "h1": "Integrate Taxamo with SalesBlink",
    "metaDescription": "Integrate Taxamo and other Accounting Tools with SalesBlink. Two Way Taxamo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Emailjs",
    "slug": "emailjs",
    "logo": "https://zapier-images.imgix.net/storage/services/26d243dc09050befcbefe7e174c094dc_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Send email directly from your client-side Javascript code – no server side code required.",
    "category": "Email",
    "title": "Emailjs Integration With Cold Email Sales Tool",
    "h1": "Integrate Emailjs with SalesBlink",
    "metaDescription": "Integrate Emailjs and other Email Tools with SalesBlink. Two Way Emailjs Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Monde",
    "slug": "monde",
    "logo": "https://zapier-images.imgix.net/storage/services/439909fd5dc5662f2dd91943c0534352_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Monde offers a complete platform to improve the results of your tourism agency.",
    "category": "Scheduling & Booking",
    "title": "Monde Integration With Cold Email Sales Tool",
    "h1": "Integrate Monde with SalesBlink",
    "metaDescription": "Integrate Monde and other Scheduling & Booking Tools with SalesBlink. Two Way Monde Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Sprout Invoices",
    "slug": "sprout-invoices",
    "logo": "https://zapier-images.imgix.net/storage/services/36fc01eeea2877411748c3050765a887_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sprout Invoices is a WordPress plugin that allows you to take full control of your invoicing. It's fully customizable with no limits and features you can't get anywhere else.",
    "category": "Accounting",
    "title": "Sprout Invoices Integration With Cold Email Sales Tool",
    "h1": "Integrate Sprout Invoices with SalesBlink",
    "metaDescription": "Integrate Sprout Invoices and other Accounting Tools with SalesBlink. Two Way Sprout Invoices Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Elevio",
    "slug": "elevio",
    "logo": "https://zapier-images.imgix.net/storage/services/8c480ff0d900009cb9fce959ff035739_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Used by Fortune 500 companies right through to small SaaS sites, elevio helps you increase user engagement and loyalty, while dropping support costs.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Elevio Integration With Cold Email Sales Tool",
    "h1": "Integrate Elevio with SalesBlink",
    "metaDescription": "Integrate Elevio and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Elevio Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "RoofSnap",
    "slug": "roofsnap",
    "logo": "https://zapier-images.imgix.net/storage/services/d1961469c3bf7ffc4ec6533cb8dca036_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "RoofSnap offers aerial roof measurement and estimating apps that make your roof measurements more accurate and your roofing estimators more efficient.",
    "category": "Project Management",
    "title": "RoofSnap Integration With Cold Email Sales Tool",
    "h1": "Integrate RoofSnap with SalesBlink",
    "metaDescription": "Integrate RoofSnap and other Project Management Tools with SalesBlink. Two Way RoofSnap Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Loyalzoo",
    "slug": "loyalzoo",
    "logo": "https://zapier-images.imgix.net/storage/services/08c444f20377f004abcd6831db826e02_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Loyalzoo provides independent retailers an affordable digital loyalty system to run their own in-store loyalty program.",
    "category": "Customer Appreciation",
    "title": "Loyalzoo Integration With Cold Email Sales Tool",
    "h1": "Integrate Loyalzoo with SalesBlink",
    "metaDescription": "Integrate Loyalzoo and other Customer Appreciation Tools with SalesBlink. Two Way Loyalzoo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pagewiz",
    "slug": "pagewiz",
    "logo": "https://zapier-images.imgix.net/storage/services/3e6c619435dc4160df255f6304be2039_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pagewiz was designed to enable professional marketers, business owners and affiliates launch landing page campaigns from scratch.",
    "category": "Website Builders",
    "title": "Pagewiz Integration With Cold Email Sales Tool",
    "h1": "Integrate Pagewiz with SalesBlink",
    "metaDescription": "Integrate Pagewiz and other Website Builders Tools with SalesBlink. Two Way Pagewiz Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Smileback",
    "slug": "smileback",
    "logo": "https://zapier-images.imgix.net/storage/services/cff1ac46004df6515d212abcc930564b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Increase your customer satisfaction and build brand loyalty with genuine feedback and actionable data analytics.",
    "category": "Ads & Conversion",
    "title": "Smileback Integration With Cold Email Sales Tool",
    "h1": "Integrate Smileback with SalesBlink",
    "metaDescription": "Integrate Smileback and other Ads & Conversion Tools with SalesBlink. Two Way Smileback Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Leadlovers",
    "slug": "leadlovers",
    "logo": "https://zapier-images.imgix.net/storage/services/c9d00e3301970ed8f0f1eadde0f85a7c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Increase your online sales with leadlovers digital marketing automation.",
    "category": "Marketing Automation",
    "title": "Leadlovers Integration With Cold Email Sales Tool",
    "h1": "Integrate Leadlovers with SalesBlink",
    "metaDescription": "Integrate Leadlovers and other Marketing Automation Tools with SalesBlink. Two Way Leadlovers Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Malartu",
    "slug": "malartu",
    "logo": "https://zapier-images.imgix.net/storage/services/820f123bca9ee9130f3847f329fe90d1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Malartu automates business advisory reports. Understand where the business has been, where it’s going, and how that compares to peers.",
    "category": "Analytics",
    "title": "Malartu Integration With Cold Email Sales Tool",
    "h1": "Integrate Malartu with SalesBlink",
    "metaDescription": "Integrate Malartu and other Analytics Tools with SalesBlink. Two Way Malartu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Weclapp",
    "slug": "weclapp",
    "logo": "https://zapier-images.imgix.net/storage/services/d17a90560109c2648e62dc3906f6ab3f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Small and middle-sized companies are using weclapp successfully to manage their customers, projects, orders, invoices, accounting, etc. in one software.",
    "category": "Product Management",
    "title": "Weclapp Integration With Cold Email Sales Tool",
    "h1": "Integrate Weclapp with SalesBlink",
    "metaDescription": "Integrate Weclapp and other Product Management Tools with SalesBlink. Two Way Weclapp Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Whispir",
    "slug": "whispir",
    "logo": "https://zapier-images.imgix.net/storage/services/b4deb58b22d2fe06af2628bd2985134b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bring together your SMS, voice messaging, email, social and more to communicate with your audiences from a single platform.",
    "category": "Product Management",
    "title": "Whispir Integration With Cold Email Sales Tool",
    "h1": "Integrate Whispir with SalesBlink",
    "metaDescription": "Integrate Whispir and other Product Management Tools with SalesBlink. Two Way Whispir Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesWings",
    "slug": "saleswings",
    "logo": "https://zapier-images.imgix.net/storage/services/3006c88d843dfdb22a82cbf7ebe70889_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesWings enables your team to constantly engage into more timely, and fully personal conversations thanks to real-time insights into your leads'​ interest, priorities, and profile.",
    "category": "Ads & Conversion",
    "title": "SalesWings Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesWings with SalesBlink",
    "metaDescription": "Integrate SalesWings and other Ads & Conversion Tools with SalesBlink. Two Way SalesWings Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Priava",
    "slug": "priava",
    "logo": "https://zapier-images.imgix.net/storage/services/3d27ca6f10841b366ff1f595c0716728_5.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Priava is a purpose-built, web based venue & event management software. Powerful, easy to use and suitable for any industry.",
    "category": "Event Management",
    "title": "Priava Integration With Cold Email Sales Tool",
    "h1": "Integrate Priava with SalesBlink",
    "metaDescription": "Integrate Priava and other Event Management Tools with SalesBlink. Two Way Priava Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "IceWarp",
    "slug": "icewarp",
    "logo": "https://zapier-images.imgix.net/storage/services/3d27ca6f10841b366ff1f595c0716728_6.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "IceWarp, Inc. is a software company that provides messaging, voice and collaboration solutions for small, medium and enterprise level businesses.",
    "category": "Team Collaboration",
    "title": "IceWarp Integration With Cold Email Sales Tool",
    "h1": "Integrate IceWarp with SalesBlink",
    "metaDescription": "Integrate IceWarp and other Team Collaboration Tools with SalesBlink. Two Way IceWarp Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aivo",
    "slug": "aivo",
    "logo": "https://zapier-images.imgix.net/storage/services/fc8fa2cfd16d6a12a7336bc0abaddc3a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aivo is a company that develops artificial intelligence solutions with the aim of transforming the way companies and customers interact.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Aivo Integration With Cold Email Sales Tool",
    "h1": "Integrate Aivo with SalesBlink",
    "metaDescription": "Integrate Aivo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Aivo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Iugu",
    "slug": "iugu",
    "logo": "https://zapier-images.imgix.net/storage/services/660da2815184bddac4c815b0ff7c7068_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Iugu offers an online platform for financial automation, signature management and marketplace.",
    "category": "Accounting",
    "title": "Iugu Integration With Cold Email Sales Tool",
    "h1": "Integrate Iugu with SalesBlink",
    "metaDescription": "Integrate Iugu and other Accounting Tools with SalesBlink. Two Way Iugu Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Fundstack",
    "slug": "fundstack",
    "logo": "https://zapier-images.imgix.net/storage/services/0026ab8e45d87fc908a0de0fc37722b3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A complete pipeline and contact management software package enhanced with proprietary data enrichment and communication analysis algorithms.",
    "category": "Contact Management",
    "title": "Fundstack Integration With Cold Email Sales Tool",
    "h1": "Integrate Fundstack with SalesBlink",
    "metaDescription": "Integrate Fundstack and other Contact Management Tools with SalesBlink. Two Way Fundstack Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rindle",
    "slug": "rindle",
    "logo": "https://zapier-images.imgix.net/storage/services/caf9a6aaa7e257ec0ff14b057c438e7a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rindle is a task and project management automation application for business that easily tracks projects and tasks in software your team will want to use.",
    "category": "Project Management",
    "title": "Rindle Integration With Cold Email Sales Tool",
    "h1": "Integrate Rindle with SalesBlink",
    "metaDescription": "Integrate Rindle and other Project Management Tools with SalesBlink. Two Way Rindle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Webpower",
    "slug": "webpower",
    "logo": "https://zapier-images.imgix.net/storage/services/6a59d53cdd0f1c35d95d3f4bac262202_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Webpower is the largest international e-mail specialist in the Netherlands, with a leading position in the Chinese market.",
    "category": "Email",
    "title": "Webpower Integration With Cold Email Sales Tool",
    "h1": "Integrate Webpower with SalesBlink",
    "metaDescription": "Integrate Webpower and other Email Tools with SalesBlink. Two Way Webpower Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Akkroo",
    "slug": "akkroo",
    "logo": "https://zapier-images.imgix.net/storage/services/53dea78f4fe555b81bf82bf314fe20fb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A universal, customisable lead capture solution that helps businesses maximise their return on exhibiting at events.",
    "category": "Forms & Surveys",
    "title": "Akkroo Integration With Cold Email Sales Tool",
    "h1": "Integrate Akkroo with SalesBlink",
    "metaDescription": "Integrate Akkroo and other Forms & Surveys Tools with SalesBlink. Two Way Akkroo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Gorendezvous",
    "slug": "gorendezvous",
    "logo": "https://zapier-images.imgix.net/storage/services/6899a067c7ae9f763845cbba7a91c7e3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "All-in-one Online Scheduling Platform for the Health, Wellness, Fitness and Beauty Industries!",
    "category": "Scheduling & Booking",
    "title": "Gorendezvous Integration With Cold Email Sales Tool",
    "h1": "Integrate Gorendezvous with SalesBlink",
    "metaDescription": "Integrate Gorendezvous and other Scheduling & Booking Tools with SalesBlink. Two Way Gorendezvous Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kantree",
    "slug": "kantree",
    "logo": "https://zapier-images.imgix.net/storage/services/9a5a96d94887bf9437b023d4fac50596_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kantree allows teams to work together in real-time and visually manage any kind of activities. Self-hosted and cloud versions available.",
    "category": "Team Collaboration",
    "title": "Kantree Integration With Cold Email Sales Tool",
    "h1": "Integrate Kantree with SalesBlink",
    "metaDescription": "Integrate Kantree and other Team Collaboration Tools with SalesBlink. Two Way Kantree Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "SalesSeek",
    "slug": "salesseek",
    "logo": "https://zapier-images.imgix.net/storage/services/5a6276f4c031ff09d9fd27288749fac7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesSeek is an all-in-one CRM system and hub for your customer data. We help organisations around the globe scale their revenue by providing context to relationships.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesSeek Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesSeek with SalesBlink",
    "metaDescription": "Integrate SalesSeek and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesSeek Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zingtree",
    "slug": "zingtree",
    "logo": "https://zapier-images.imgix.net/storage/services/ada170fd2e3bff71df39cee5ba4b7b6d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zingtree makes it easy to build and maintain interactive troubleshooters and process flows with ZERO coding.",
    "category": "Customer Support",
    "title": "Zingtree Integration With Cold Email Sales Tool",
    "h1": "Integrate Zingtree with SalesBlink",
    "metaDescription": "Integrate Zingtree and other Customer Support Tools with SalesBlink. Two Way Zingtree Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "sevDesk",
    "slug": "sevdesk",
    "logo": "https://zapier-images.imgix.net/storage/services/ec354150565c1fb9f90ca8f5263e1635_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "sevDesk is the personal CFO for small businesses. It will help you to do accounting, banking, invoicing and will give you real time insights",
    "category": "Accounting",
    "title": "sevDesk Integration With Cold Email Sales Tool",
    "h1": "Integrate sevDesk with SalesBlink",
    "metaDescription": "Integrate sevDesk and other Accounting Tools with SalesBlink. Two Way sevDesk Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true,

    "pabblyConnect": true
  },
  {
    "name": "Engati",
    "slug": "engati",
    "logo": "https://zapier-images.imgix.net/storage/services/1a934b1bac930902555ca39c8992a9a7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Engati is a chatbot platform that allows you to build, manage, integrate, train, analyse and publish your personalized bot in a matter of minutes.",
    "category": "Developer Tools",
    "title": "Engati Integration With Cold Email Sales Tool",
    "h1": "Integrate Engati with SalesBlink",
    "metaDescription": "Integrate Engati and other Developer Tools Tools with SalesBlink. Two Way Engati Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "eMarketeer",
    "slug": "emarketeer",
    "logo": "https://zapier-images.imgix.net/storage/services/67ee4ecdef48d407e6a348767c3e1348_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "eMarketeer's All-in-One Online Marketing Software helps small businesses and marketing agencies to deliver relevant marketing material to their customers.",
    "category": "Marketing Automation",
    "title": "eMarketeer Integration With Cold Email Sales Tool",
    "h1": "Integrate eMarketeer with SalesBlink",
    "metaDescription": "Integrate eMarketeer and other Marketing Automation Tools with SalesBlink. Two Way eMarketeer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Billomat",
    "slug": "billomat",
    "logo": "https://zapier-images.imgix.net/storage/services/b5019cc709991552bda7c900e731aee5_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Billomat is a simple and efficient cloud accounting software and invoicing program for startups, freelancers and small businesses.",
    "category": "Accounting",
    "title": "Billomat Integration With Cold Email Sales Tool",
    "h1": "Integrate Billomat with SalesBlink",
    "metaDescription": "Integrate Billomat and other Accounting Tools with SalesBlink. Two Way Billomat Integration for all your data related to sales & cold email.",
    "zapier": true,
    "make": true
  },
  {
    "name": "Anapro",
    "slug": "anapro",
    "logo": "https://zapier-images.imgix.net/storage/services/2ab3fd09af4cf0ee192a10e68a8f3356_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "From the customer service to the signing of the contract., Anapro offers complete commercial management for small, medium and large businesses.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Anapro Integration With Cold Email Sales Tool",
    "h1": "Integrate Anapro with SalesBlink",
    "metaDescription": "Integrate Anapro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Anapro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Papershift",
    "slug": "papershift",
    "logo": "https://zapier-images.imgix.net/storage/services/60406821e7312658cede6a395ef9e9a1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Looking for an online tool for easy and fast service planning? Roster creation, vacation planning and time tracking - all in one application and in real time.",
    "category": "HR Talent & Recruitment",
    "title": "Papershift Integration With Cold Email Sales Tool",
    "h1": "Integrate Papershift with SalesBlink",
    "metaDescription": "Integrate Papershift and other HR Talent & Recruitment Tools with SalesBlink. Two Way Papershift Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Foxy.Io",
    "slug": "foxy-io",
    "logo": "https://zapier-images.imgix.net/storage/services/9c3cc666e285a74f23c2180a82f5d610_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Custom ecommerce, simplified. Foxy’s hosted cart & payment page allow you to sell anything, using your existing website or platform.",
    "category": "eCommerce",
    "title": "Foxy.Io Integration With Cold Email Sales Tool",
    "h1": "Integrate Foxy.Io with SalesBlink",
    "metaDescription": "Integrate Foxy.Io and other eCommerce Tools with SalesBlink. Two Way Foxy.Io Integration for all your data related to sales & cold email.",
    "zapier": true,

    "pabblyConnect": true
  },
  {
    "name": "Triggerbee",
    "slug": "triggerbee",
    "logo": "https://zapier-images.imgix.net/storage/services/d6a4a2a820a0b570c0858c26d10cf857_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Triggerbee's software will help you turn clicks, pageviews and digital footprints on your website into profit.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Triggerbee Integration With Cold Email Sales Tool",
    "h1": "Integrate Triggerbee with SalesBlink",
    "metaDescription": "Integrate Triggerbee and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Triggerbee Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FIVE CRM",
    "slug": "five-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/974aa572303406559953ef0f17999d46_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FIVE CRM gives a range of options for contact management CRM, outbound calling, email marketing, lead generation.",
    "category": "CRM (Customer Relationship Management)",
    "title": "FIVE CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate FIVE CRM with SalesBlink",
    "metaDescription": "Integrate FIVE CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way FIVE CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Botmother",
    "slug": "botmother",
    "logo": "https://zapier-images.imgix.net/storage/services/824202d67ae9ef4bb0820a9c774c2b09_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Wordpress for chat bots. Botmother helps to collect useful business bots for 1 day for several instant messengers without programming in the visual editor.",
    "category": "Customer Support",
    "title": "Botmother Integration With Cold Email Sales Tool",
    "h1": "Integrate Botmother with SalesBlink",
    "metaDescription": "Integrate Botmother and other Customer Support Tools with SalesBlink. Two Way Botmother Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ploomes",
    "slug": "ploomes",
    "logo": "https://zapier-images.imgix.net/storage/services/1fd67c8baaee55b8fd49193892aa624b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Sales force automation system that links CRM, Sales Funnel, Proposal Automation and Sales Orders.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Ploomes Integration With Cold Email Sales Tool",
    "h1": "Integrate Ploomes with SalesBlink",
    "metaDescription": "Integrate Ploomes and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Ploomes Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "snapADDY",
    "slug": "snapaddy",
    "logo": "https://zapier-images.imgix.net/storage/services/8d235606d519694c62de667d30183051_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "snapADDY is a sales support software for finding B2B contacts and directly transferring them to CRM and ERP systems.",
    "category": "CRM (Customer Relationship Management)",
    "title": "snapADDY Integration With Cold Email Sales Tool",
    "h1": "Integrate snapADDY with SalesBlink",
    "metaDescription": "Integrate snapADDY and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way snapADDY Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ProgressionLIVE",
    "slug": "progressionlive",
    "logo": "https://zapier-images.imgix.net/storage/services/135e52bb29072c19ef3b347c1c298c8e_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ProgressionLIVE is a dispatch software and a mobile task management application for your employees on the road.",
    "category": "HR Talent & Recruitment",
    "title": "ProgressionLIVE Integration With Cold Email Sales Tool",
    "h1": "Integrate ProgressionLIVE with SalesBlink",
    "metaDescription": "Integrate ProgressionLIVE and other HR Talent & Recruitment Tools with SalesBlink. Two Way ProgressionLIVE Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "iCapture",
    "slug": "icapture",
    "logo": "https://zapier-images.imgix.net/storage/services/8a8e7f9d388b673f3cf8fd924c606dd7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Application with business card capture, badge and bar/QR code scanning, kiosk capture, online/offline functionality, CSV data export, real-time reporting, branching, skip logic.",
    "category": "Event Management",
    "title": "iCapture Integration With Cold Email Sales Tool",
    "h1": "Integrate iCapture with SalesBlink",
    "metaDescription": "Integrate iCapture and other Event Management Tools with SalesBlink. Two Way iCapture Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "GrooveJar",
    "slug": "groovejar",
    "logo": "https://zapier-images.imgix.net/storage/services/9985a85a9f5620240e2451397135e53f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Awesome lead capture and customer conversion rate crushing popup and triggered email apps for any website and e-commerce store.",
    "category": "Website Builders",
    "title": "GrooveJar Integration With Cold Email Sales Tool",
    "h1": "Integrate GrooveJar with SalesBlink",
    "metaDescription": "Integrate GrooveJar and other Website Builders Tools with SalesBlink. Two Way GrooveJar Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Jungo",
    "slug": "jungo",
    "logo": "https://zapier-images.imgix.net/storage/services/19cec239f5f067b14d2be84c4b8b2de8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Jungo is the premier mortgage app for Salesforce, offering a comprehensive solution with workflow management, referral tracking, and more.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Jungo Integration With Cold Email Sales Tool",
    "h1": "Integrate Jungo with SalesBlink",
    "metaDescription": "Integrate Jungo and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Jungo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bookinglayer",
    "slug": "bookinglayer",
    "logo": "https://zapier-images.imgix.net/storage/services/d60f537da4608c985556a8e0e5ea13e0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Bookinglayer is an all-in-one reservation and workflow management software that helps businesses save time and increase revenue.",
    "category": "Event Management",
    "title": "Bookinglayer Integration With Cold Email Sales Tool",
    "h1": "Integrate Bookinglayer with SalesBlink",
    "metaDescription": "Integrate Bookinglayer and other Event Management Tools with SalesBlink. Two Way Bookinglayer Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SalesPositive",
    "slug": "salespositive",
    "logo": "https://zapier-images.imgix.net/storage/services/fbb041315dce58ef9095aca8890e9ec3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SalesPositive.com is a leading sales acceleration platform, giving our users the ability to generate more revenue through automated sales processes.",
    "category": "CRM (Customer Relationship Management)",
    "title": "SalesPositive Integration With Cold Email Sales Tool",
    "h1": "Integrate SalesPositive with SalesBlink",
    "metaDescription": "Integrate SalesPositive and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way SalesPositive Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Passslot",
    "slug": "passslot",
    "logo": "https://zapier-images.imgix.net/storage/services/79daa822320dc498a5a6a9f8279057dd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PassSlot helps you build your own mobile wallet using Apple Wallet (Passbook), Android Pay (Google Wallet) and HTML5 to distribute mobile passes to all major platforms.",
    "category": "Developer Tools",
    "title": "Passslot Integration With Cold Email Sales Tool",
    "h1": "Integrate Passslot with SalesBlink",
    "metaDescription": "Integrate Passslot and other Developer Tools Tools with SalesBlink. Two Way Passslot Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zippykind",
    "slug": "zippykind",
    "logo": "https://zapier-images.imgix.net/storage/services/0071206a8e1c64999e069c166d34d7a0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Zippykind is a SaaS Delivery Management Software that combines automation, communication & effective dispatching into one powerful platform",
    "category": "Product Management",
    "title": "Zippykind Integration With Cold Email Sales Tool",
    "h1": "Integrate Zippykind with SalesBlink",
    "metaDescription": "Integrate Zippykind and other Product Management Tools with SalesBlink. Two Way Zippykind Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Actito",
    "slug": "actito",
    "logo": "https://zapier-images.imgix.net/storage/services/ce77c312b91463568120f26db6773502_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Actito offers a suite of relationship marketing tools to create a meaningful dialogue with prospects and clients.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Actito Integration With Cold Email Sales Tool",
    "h1": "Integrate Actito with SalesBlink",
    "metaDescription": "Integrate Actito and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Actito Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ubivox",
    "slug": "ubivox",
    "logo": "https://zapier-images.imgix.net/storage/services/e9e938adec779afc1be2f49637999555_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ubivox is a tool for designing, writing and sending newsletters for companies and organisations.",
    "category": "Email Newsletters",
    "title": "Ubivox Integration With Cold Email Sales Tool",
    "h1": "Integrate Ubivox with SalesBlink",
    "metaDescription": "Integrate Ubivox and other Email Newsletters Tools with SalesBlink. Two Way Ubivox Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hiboutik",
    "slug": "hiboutik",
    "logo": "https://zapier-images.imgix.net/storage/services/70d9871208bb6092293fe07407ba8c94_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Free POS software for all retail shops. Easy to set up, you just need a web browser to create your own POS system.",
    "category": "Payment Processing",
    "title": "Hiboutik Integration With Cold Email Sales Tool",
    "h1": "Integrate Hiboutik with SalesBlink",
    "metaDescription": "Integrate Hiboutik and other Payment Processing Tools with SalesBlink. Two Way Hiboutik Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dengro",
    "slug": "dengro",
    "logo": "https://zapier-images.imgix.net/storage/services/795d22906a39b8b83475f227a8277969_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DenGro is a CRM and lead management tool built specifically for the dental industry. Track, nurture and convert more high-value leads to treatment with DenGro.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Dengro Integration With Cold Email Sales Tool",
    "h1": "Integrate Dengro with SalesBlink",
    "metaDescription": "Integrate Dengro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Dengro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "TeamGrid",
    "slug": "teamgrid",
    "logo": "https://zapier-images.imgix.net/storage/services/82a891d47a37f2392aeb013b962e9650_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "TeamGrid is a streamlined task & project management platform with integrated telephony and smart-office functions.",
    "category": "Project Management",
    "title": "TeamGrid Integration With Cold Email Sales Tool",
    "h1": "Integrate TeamGrid with SalesBlink",
    "metaDescription": "Integrate TeamGrid and other Project Management Tools with SalesBlink. Two Way TeamGrid Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cryptlex",
    "slug": "cryptlex",
    "logo": "https://zapier-images.imgix.net/storage/services/26643afecb7efd9ba4b7ebde5212da9a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cryptlex provides a complete software licensing solution to help you license and distribute your apps and software.",
    "category": "Security & Identity Tools",
    "title": "Cryptlex Integration With Cold Email Sales Tool",
    "h1": "Integrate Cryptlex with SalesBlink",
    "metaDescription": "Integrate Cryptlex and other Security & Identity Tools Tools with SalesBlink. Two Way Cryptlex Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Zapla",
    "slug": "zapla",
    "logo": "https://zapier-images.imgix.net/storage/services/3a87c506d05a38d70b6af20abb6bfbfa_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Accept bookings directly on your website with the Zapla embeddable booking widget. Installs with one line of code on any site.",
    "category": "Website Builders",
    "title": "Zapla Integration With Cold Email Sales Tool",
    "h1": "Integrate Zapla with SalesBlink",
    "metaDescription": "Integrate Zapla and other Website Builders Tools with SalesBlink. Two Way Zapla Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Dude Solutions",
    "slug": "dude-solutions",
    "logo": "https://zapier-images.imgix.net/storage/services/569b50e197d53573f7a84f6508b926a3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Dude Solutions is a leading SaaS provider of operations management solutions to education, government, healthcare, manufacturing and membership-based organizations.",
    "category": "Project Management",
    "title": "Dude Solutions Integration With Cold Email Sales Tool",
    "h1": "Integrate Dude Solutions with SalesBlink",
    "metaDescription": "Integrate Dude Solutions and other Project Management Tools with SalesBlink. Two Way Dude Solutions Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Papertrail",
    "slug": "papertrail",
    "logo": "https://zapier-images.imgix.net/storage/services/cbaac81759eea83a508c3ce52f8eece0_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Papertrail offers a software solution that is able to record and manage logs from different locations.",
    "category": "Project Management",
    "title": "Papertrail Integration With Cold Email Sales Tool",
    "h1": "Integrate Papertrail with SalesBlink",
    "metaDescription": "Integrate Papertrail and other Project Management Tools with SalesBlink. Two Way Papertrail Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Codeship",
    "slug": "codeship",
    "logo": "https://zapier-images.imgix.net/storage/services/42fd8ae90f9ec8b5930e912aa017633c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Codeship is a fast and secure hosted Continuous Integration service that scales with your needs. It supports GitHub, Bitbucket, and Gitlab projects.",
    "category": "Developer Tools",
    "title": "Codeship Integration With Cold Email Sales Tool",
    "h1": "Integrate Codeship with SalesBlink",
    "metaDescription": "Integrate Codeship and other Developer Tools Tools with SalesBlink. Two Way Codeship Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Exposure",
    "slug": "exposure",
    "logo": "https://zapier-images.imgix.net/storage/services/a47a320ca0e2c56d6b3809ae5796c351_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Exposure is a web tool and a community of passionate photographers and storytellers that allows users to create photo narratives.",
    "category": "Website Builders",
    "title": "Exposure Integration With Cold Email Sales Tool",
    "h1": "Integrate Exposure with SalesBlink",
    "metaDescription": "Integrate Exposure and other Website Builders Tools with SalesBlink. Two Way Exposure Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Cerego",
    "slug": "cerego",
    "logo": "https://zapier-images.imgix.net/storage/services/4a6554205bbcfc8c59894fc120606caa_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cerego is an adaptive learning platform that uses artificial intelligence and machine learning to scale proven cognitive science and make learning possible for anyone.",
    "category": "Online Courses",
    "title": "Cerego Integration With Cold Email Sales Tool",
    "h1": "Integrate Cerego with SalesBlink",
    "metaDescription": "Integrate Cerego and other Online Courses Tools with SalesBlink. Two Way Cerego Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "OneAll",
    "slug": "oneall",
    "logo": "https://zapier-images.imgix.net/storage/services/f188b911d3ce36c114c50274db834eaf_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "OneAll Social Login is used by more than 250,000 websites worldwide, including small blogs, growing startups and large corporate enterprises.",
    "category": "Social Media Marketing",
    "title": "OneAll Integration With Cold Email Sales Tool",
    "h1": "Integrate OneAll with SalesBlink",
    "metaDescription": "Integrate OneAll and other Social Media Marketing Tools with SalesBlink. Two Way OneAll Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Socrata",
    "slug": "socrata",
    "logo": "https://zapier-images.imgix.net/storage/services/17653a8a8b5faae2e76512a5acbfc405_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Socrata delivers data-as-a-service solutions to accelerate digital transformation in government and foster the open data ecosystem.",
    "category": "Ads & Conversion",
    "title": "Socrata Integration With Cold Email Sales Tool",
    "h1": "Integrate Socrata with SalesBlink",
    "metaDescription": "Integrate Socrata and other Ads & Conversion Tools with SalesBlink. Two Way Socrata Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Parse.ly",
    "slug": "parse-ly",
    "logo": "https://zapier-images.imgix.net/storage/services/29d261e1f8d66cfd43364d9bf7445850_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Parse.ly partners with digital publishers to provide clear audience insights through an intuitive analytics platform.",
    "category": "Ads & Conversion",
    "title": "Parse.ly Integration With Cold Email Sales Tool",
    "h1": "Integrate Parse.ly with SalesBlink",
    "metaDescription": "Integrate Parse.ly and other Ads & Conversion Tools with SalesBlink. Two Way Parse.ly Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Verint",
    "slug": "verint",
    "logo": "https://zapier-images.imgix.net/storage/services/eb303836c1bf8b03f69c24f97b3a6414_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Verint Systems is a provider of Actionable Intelligence solutions for enterprise workforce optimization and security intelligence.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Verint Integration With Cold Email Sales Tool",
    "h1": "Integrate Verint with SalesBlink",
    "metaDescription": "Integrate Verint and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Verint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "WhatCounts",
    "slug": "whatcounts",
    "logo": "https://zapier-images.imgix.net/storage/services/6ce8074b810b955b915c8234b2cecd5f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "WhatCounts is an email marketing company that provides data management, software, and services to marketing professionals.",
    "category": "Email",
    "title": "WhatCounts Integration With Cold Email Sales Tool",
    "h1": "Integrate WhatCounts with SalesBlink",
    "metaDescription": "Integrate WhatCounts and other Email Tools with SalesBlink. Two Way WhatCounts Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FundRazr",
    "slug": "fundrazr",
    "logo": "https://zapier-images.imgix.net/storage/services/73ffc147319165f491583feecfeb4f67_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "FundRazr is a Canadian crowdfunding site and Facebook app first released in 2009.",
    "category": "Fundraising",
    "title": "FundRazr Integration With Cold Email Sales Tool",
    "h1": "Integrate FundRazr with SalesBlink",
    "metaDescription": "Integrate FundRazr and other Fundraising Tools with SalesBlink. Two Way FundRazr Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Interviewed",
    "slug": "interviewed",
    "logo": "https://zapier-images.imgix.net/storage/services/bc8444fa667365abd7f102b061d0c8cd_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Interviewed lets hiring managers get to see candidates in action as they demonstrate their real-world skills.",
    "category": "HR Talent & Recruitment",
    "title": "Interviewed Integration With Cold Email Sales Tool",
    "h1": "Integrate Interviewed with SalesBlink",
    "metaDescription": "Integrate Interviewed and other HR Talent & Recruitment Tools with SalesBlink. Two Way Interviewed Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Omie",
    "slug": "omie",
    "logo": "https://zapier-images.imgix.net/storage/services/903db38baabab6de2b6a8ea27e31b21f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Omie is a complete small business management system with CRM, Finance, Cash Flow, NF-e, Stocks and Store Front.",
    "category": "Project Management",
    "title": "Omie Integration With Cold Email Sales Tool",
    "h1": "Integrate Omie with SalesBlink",
    "metaDescription": "Integrate Omie and other Project Management Tools with SalesBlink. Two Way Omie Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "MyLeadSystemPro",
    "slug": "myleadsystempro",
    "logo": "https://zapier-images.imgix.net/storage/services/27b061d36cd6cf2ad2b8339981e50ef1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "MLSP is a trusted solution to help you attract fresh leads daily, get sales and sign-ups, and grow your business by leveraging the power of the internet.",
    "category": "CRM (Customer Relationship Management)",
    "title": "MyLeadSystemPro Integration With Cold Email Sales Tool",
    "h1": "Integrate MyLeadSystemPro with SalesBlink",
    "metaDescription": "Integrate MyLeadSystemPro and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way MyLeadSystemPro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "ClubRunner",
    "slug": "clubrunner",
    "logo": "https://zapier-images.imgix.net/storage/services/55af9aee860ebb7b114335eaf712de71_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "ClubRunner is the award-winning membership management and communication platform for clubs and districts.",
    "category": "Fitness",
    "title": "ClubRunner Integration With Cold Email Sales Tool",
    "h1": "Integrate ClubRunner with SalesBlink",
    "metaDescription": "Integrate ClubRunner and other Fitness Tools with SalesBlink. Two Way ClubRunner Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Inspectlet",
    "slug": "inspectlet",
    "logo": "https://zapier-images.imgix.net/storage/services/273e9b87b06e71855e57fb5f9c117d47_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Inspectlet records videos of your site visitors as they use your site, allowing you to see everything they do.",
    "category": "Ads & Conversion",
    "title": "Inspectlet Integration With Cold Email Sales Tool",
    "h1": "Integrate Inspectlet with SalesBlink",
    "metaDescription": "Integrate Inspectlet and other Ads & Conversion Tools with SalesBlink. Two Way Inspectlet Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Notarize",
    "slug": "notarize",
    "logo": "https://zapier-images.imgix.net/storage/services/fec09becdb28aa05571aeec401135417_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Notarize is an on-demand remote electronic notary service that allows anyone to legally notarize a document from their iPhone 24x7.",
    "category": "Documents",
    "title": "Notarize Integration With Cold Email Sales Tool",
    "h1": "Integrate Notarize with SalesBlink",
    "metaDescription": "Integrate Notarize and other Documents Tools with SalesBlink. Two Way Notarize Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "SmarterSelect",
    "slug": "smarterselect",
    "logo": "https://zapier-images.imgix.net/storage/services/f73189e7aca91b14b05514ca8c7a7e27_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "SmarterSelect provides a comprehensive, yet easy-to-use, online application management solution for scholarships, grants, awards, fellowships, data collection, etc.",
    "category": "Online Courses",
    "title": "SmarterSelect Integration With Cold Email Sales Tool",
    "h1": "Integrate SmarterSelect with SalesBlink",
    "metaDescription": "Integrate SmarterSelect and other Online Courses Tools with SalesBlink. Two Way SmarterSelect Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "El Toro",
    "slug": "el-toro",
    "logo": "https://zapier-images.imgix.net/storage/services/b8319b7e13997aa87dca703f5e7b3e03_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "El Toro uses patent-pending technology for IP Targeting of digital advertising to homes, schools and businesses.",
    "category": "Marketing Automation",
    "title": "El Toro Integration With Cold Email Sales Tool",
    "h1": "Integrate El Toro with SalesBlink",
    "metaDescription": "Integrate El Toro and other Marketing Automation Tools with SalesBlink. Two Way El Toro Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Riskified",
    "slug": "riskified",
    "logo": "https://zapier-images.imgix.net/storage/services/1b2285fde6bc2466e53319e0c5eb54e4_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Riskified is an all-in-one eCommerce fraud prevention solution and chargeback protection service for high volume and enterprise merchants.",
    "category": "eCommerce",
    "title": "Riskified Integration With Cold Email Sales Tool",
    "h1": "Integrate Riskified with SalesBlink",
    "metaDescription": "Integrate Riskified and other eCommerce Tools with SalesBlink. Two Way Riskified Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Hustle",
    "slug": "hustle",
    "logo": "https://zapier-images.imgix.net/storage/services/6810c954bfe20d98f0491c0b50d80969_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Hustle is a peer-to-peer text messaging platform that provides organizations across the country with an affordable, efficient, and effective tool to reach their target supporters and customers.",
    "category": "Phone & SMS",
    "title": "Hustle Integration With Cold Email Sales Tool",
    "h1": "Integrate Hustle with SalesBlink",
    "metaDescription": "Integrate Hustle and other Phone & SMS Tools with SalesBlink. Two Way Hustle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Showpad",
    "slug": "showpad",
    "logo": "https://zapier-images.imgix.net/storage/services/b5091cdeba6dbe3de7940ea3af02bb4c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Showpad empowers sales and marketing teams to meet the demands of modern buyers, integrating industry leading training with innovative sales enablement content solutions.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Showpad Integration With Cold Email Sales Tool",
    "h1": "Integrate Showpad with SalesBlink",
    "metaDescription": "Integrate Showpad and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Showpad Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Bottle",
    "slug": "bottle",
    "logo": "https://zapier-images.imgix.net/storage/services/7759fc401c870a04fdfd77003a9bf034_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Text your customers to sell more stuff. Hundreds of local delivery businesses use Bottle to get, keep and grow their customers.",
    "category": "Phone & SMS",
    "title": "Bottle Integration With Cold Email Sales Tool",
    "h1": "Integrate Bottle with SalesBlink",
    "metaDescription": "Integrate Bottle and other Phone & SMS Tools with SalesBlink. Two Way Bottle Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FanBridge",
    "slug": "fanbridge",
    "logo": "https://zapier-images.imgix.net/storage/services/5e4110d5fb27efae0bfc85da445a22e6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Email marketing and newsletter solutions for musicians, comedians, influencers, and anyone with a fanbase.",
    "category": "Email Newsletters",
    "title": "FanBridge Integration With Cold Email Sales Tool",
    "h1": "Integrate FanBridge with SalesBlink",
    "metaDescription": "Integrate FanBridge and other Email Newsletters Tools with SalesBlink. Two Way FanBridge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Firespring",
    "slug": "firespring",
    "logo": "https://zapier-images.imgix.net/storage/services/192debc8b9cfafbb8248c065f1761c3c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We provide an unmatched range of integrated print, creative and technology solutions in addition to subscription-based software, cloud IT services and turnkey marketing tools for nonprofits and small to midsize businesses.",
    "category": "Printing",
    "title": "Firespring Integration With Cold Email Sales Tool",
    "h1": "Integrate Firespring with SalesBlink",
    "metaDescription": "Integrate Firespring and other Printing Tools with SalesBlink. Two Way Firespring Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Major League Hacking",
    "slug": "major-league-hacking",
    "logo": "https://zapier-images.imgix.net/storage/services/feecc2c2ce61c82dd84d345161989ed5_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Major League Hacking (MLH) is the official student hackathon league. Each year MLH supports over 65.000 students around the world.",
    "category": "Online Courses",
    "title": "Major League Hacking Integration With Cold Email Sales Tool",
    "h1": "Integrate Major League Hacking with SalesBlink",
    "metaDescription": "Integrate Major League Hacking and other Online Courses Tools with SalesBlink. Two Way Major League Hacking Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Transpose",
    "slug": "transpose",
    "logo": "https://zapier-images.imgix.net/storage/services/f3403918e642c692c3bc19081b9160ac_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Transpose is the simplest platform for structuring data, enabling users to leverage enterprise-level technology in a user-friendly format.",
    "category": "Analytics",
    "title": "Transpose Integration With Cold Email Sales Tool",
    "h1": "Integrate Transpose with SalesBlink",
    "metaDescription": "Integrate Transpose and other Analytics Tools with SalesBlink. Two Way Transpose Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BigTime Software",
    "slug": "bigtime-software",
    "logo": "https://zapier-images.imgix.net/storage/services/bc1b61763eb4267f08de2d49c26fa022_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BigTime Software develops integrated time and billing tools that help growing professional services firms track and manage their time.",
    "category": "Time Tracking Software",
    "title": "BigTime Software Integration With Cold Email Sales Tool",
    "h1": "Integrate BigTime Software with SalesBlink",
    "metaDescription": "Integrate BigTime Software and other Time Tracking Software Tools with SalesBlink. Two Way BigTime Software Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "KickFire",
    "slug": "kickfire",
    "logo": "https://zapier-images.imgix.net/storage/services/46703c774c023ef5beab6e1261910ce6_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "KickFire, the leader in IP address intelligence, provides B2B firmographics based on IP address through its LIVE Leads, API and Prospector",
    "category": "CRM (Customer Relationship Management)",
    "title": "KickFire Integration With Cold Email Sales Tool",
    "h1": "Integrate KickFire with SalesBlink",
    "metaDescription": "Integrate KickFire and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way KickFire Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "PriceWaiter",
    "slug": "pricewaiter",
    "logo": "https://zapier-images.imgix.net/storage/services/f6b2451fb77f64d45aa60ee48636b0bb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "PriceWaiter makes buying and selling better, through fast, simple, private negotiation - anywhere on the web.",
    "category": "eCommerce",
    "title": "PriceWaiter Integration With Cold Email Sales Tool",
    "h1": "Integrate PriceWaiter with SalesBlink",
    "metaDescription": "Integrate PriceWaiter and other eCommerce Tools with SalesBlink. Two Way PriceWaiter Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "StoreYa",
    "slug": "storeya",
    "logo": "https://zapier-images.imgix.net/storage/services/113ce57aafad1d7d1169d364c8bfb0d3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "StoreYa offers a suite of marketing and advertising apps which help SMBs increase sales, leads and social following.",
    "category": "Marketing Automation",
    "title": "StoreYa Integration With Cold Email Sales Tool",
    "h1": "Integrate StoreYa with SalesBlink",
    "metaDescription": "Integrate StoreYa and other Marketing Automation Tools with SalesBlink. Two Way StoreYa Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Catchpoint",
    "slug": "catchpoint",
    "logo": "https://zapier-images.imgix.net/storage/services/3179b5efa356c109960006d37aab4bd1_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Catchpoint is a leading digital performance intelligence company that provides unparalleled insights to help you consistently deliver amazing digital experiences.",
    "category": "Ads & Conversion",
    "title": "Catchpoint Integration With Cold Email Sales Tool",
    "h1": "Integrate Catchpoint with SalesBlink",
    "metaDescription": "Integrate Catchpoint and other Ads & Conversion Tools with SalesBlink. Two Way Catchpoint Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Auvo",
    "slug": "auvo",
    "logo": "https://zapier-images.imgix.net/storage/services/4bf5b30ad6ee2720851a80f300b3fec9_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Auvo is an external teams management software that allows anyone to know real-time the location of your team, schedule tasks, have an online system, and much more.",
    "category": "Team Collaboration",
    "title": "Auvo Integration With Cold Email Sales Tool",
    "h1": "Integrate Auvo with SalesBlink",
    "metaDescription": "Integrate Auvo and other Team Collaboration Tools with SalesBlink. Two Way Auvo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Edgewise",
    "slug": "edgewise",
    "logo": "https://zapier-images.imgix.net/storage/services/0f470e7360d1798df245c699bb1fd950_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Edgewise Realty is a Philadelphia-based startup that transforms how developers build and sell homes in cities across the U.S.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Edgewise Integration With Cold Email Sales Tool",
    "h1": "Integrate Edgewise with SalesBlink",
    "metaDescription": "Integrate Edgewise and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Edgewise Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Kimbia",
    "slug": "kimbia",
    "logo": "https://zapier-images.imgix.net/storage/services/acd75836d7d6c5a659cdba6f34a4e8aa_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Kimbia provides an online fundraising, crowdfunding, and event platform for nonprofits, higher education, and community foundations.",
    "category": "Fundraising",
    "title": "Kimbia Integration With Cold Email Sales Tool",
    "h1": "Integrate Kimbia with SalesBlink",
    "metaDescription": "Integrate Kimbia and other Fundraising Tools with SalesBlink. Two Way Kimbia Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Clearlogin",
    "slug": "clearlogin",
    "logo": "https://zapier-images.imgix.net/storage/services/0a6f20689c4c1cc6795ea8f05f0b6ab7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Clearlogin's Identity Management Platform and Application Dashboard make secure access to the cloud easy for users, IT administrators, and developers.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Clearlogin Integration With Cold Email Sales Tool",
    "h1": "Integrate Clearlogin with SalesBlink",
    "metaDescription": "Integrate Clearlogin and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Clearlogin Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Falcon.Io",
    "slug": "falcon-io",
    "logo": "https://zapier-images.imgix.net/storage/services/2445e01a3c2b02e33e8639469c022097.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Falcon is your social media marketing platform built on social analytics, community engagement and governance for Facebook, Twitter, LinkedIn, and Instagram.",
    "category": "Social Media Marketing",
    "title": "Falcon.Io Integration With Cold Email Sales Tool",
    "h1": "Integrate Falcon.Io with SalesBlink",
    "metaDescription": "Integrate Falcon.Io and other Social Media Marketing Tools with SalesBlink. Two Way Falcon.Io Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Codefresh",
    "slug": "codefresh",
    "logo": "https://zapier-images.imgix.net/storage/services/23a00971891f17e8b18aa8638f646a00_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Codefresh is a continuous delivery and collaboration platform for Docker. Codefresh helps teams improve quality and increase speed-to-market by facilitating rapid feedback and testing cycles.",
    "category": "Developer Tools",
    "title": "Codefresh Integration With Cold Email Sales Tool",
    "h1": "Integrate Codefresh with SalesBlink",
    "metaDescription": "Integrate Codefresh and other Developer Tools Tools with SalesBlink. Two Way Codefresh Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "YArooms",
    "slug": "yarooms",
    "logo": "https://zapier-images.imgix.net/storage/services/028a8ef1ed928b9778d1163a1b2936c2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meeting room booking system that manages in the most efficient manner meeting rooms, conference rooms, resources and staff.",
    "category": "Scheduling & Booking",
    "title": "YArooms Integration With Cold Email Sales Tool",
    "h1": "Integrate YArooms with SalesBlink",
    "metaDescription": "Integrate YArooms and other Scheduling & Booking Tools with SalesBlink. Two Way YArooms Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Ministry Centered Technologies",
    "slug": "ministry-centered-technologies",
    "logo": "https://zapier-images.imgix.net/storage/services/3e6bebf42c37164c76d07e6968c29e53_13.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Ministry Centred Technologies is a mobile apps developer company that develops various business apps for the Android and iPhone users.",
    "category": "App Builder",
    "title": "Ministry Centered Technologies Integration With Cold Email Sales Tool",
    "h1": "Integrate Ministry Centered Technologies with SalesBlink",
    "metaDescription": "Integrate Ministry Centered Technologies and other App Builder Tools with SalesBlink. Two Way Ministry Centered Technologies Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Mozeo",
    "slug": "mozeo",
    "logo": "https://zapier-images.imgix.net/storage/services/555e65e996996bd750241542f9e1d7e2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Mozeo offers the easiest mobile marketing services for anyone to blast SMS text messages through an easy & affordable text message marketing software.",
    "category": "Phone & SMS",
    "title": "Mozeo Integration With Cold Email Sales Tool",
    "h1": "Integrate Mozeo with SalesBlink",
    "metaDescription": "Integrate Mozeo and other Phone & SMS Tools with SalesBlink. Two Way Mozeo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rezgo",
    "slug": "rezgo",
    "logo": "https://zapier-images.imgix.net/storage/services/d356b6b4137af98164bc3fd8ec907f4f_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Rezgo is tour operator software that allows tourism agencies to integrate a real-time online booking system into their websites.",
    "category": "Scheduling & Booking",
    "title": "Rezgo Integration With Cold Email Sales Tool",
    "h1": "Integrate Rezgo with SalesBlink",
    "metaDescription": "Integrate Rezgo and other Scheduling & Booking Tools with SalesBlink. Two Way Rezgo Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Logicbroker",
    "slug": "logicbroker",
    "logo": "https://zapier-images.imgix.net/storage/services/66dda04b8efeb990b271643438f9d87c_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Cloud-based EDI to API technology connecting brands, retailers and the systems they rely on. We craft the connections to enable digital commerce.",
    "category": "eCommerce",
    "title": "Logicbroker Integration With Cold Email Sales Tool",
    "h1": "Integrate Logicbroker with SalesBlink",
    "metaDescription": "Integrate Logicbroker and other eCommerce Tools with SalesBlink. Two Way Logicbroker Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "NetLine",
    "slug": "netline",
    "logo": "https://zapier-images.imgix.net/storage/services/ce7c9ec7f8f32ae55b2405cd16460b2d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "NetLine Corporation empowers B2B Marketers with the reach, technology, and expertise required to drive scalable lead generation results and accelerate the sales funnel.",
    "category": "Marketing Automation",
    "title": "NetLine Integration With Cold Email Sales Tool",
    "h1": "Integrate NetLine with SalesBlink",
    "metaDescription": "Integrate NetLine and other Marketing Automation Tools with SalesBlink. Two Way NetLine Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Do.Com",
    "slug": "do-com",
    "logo": "https://zapier-images.imgix.net/storage/services/ee6101421ff0cd670ba59cba4b3f8699_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Meetings, participants, and the important info all at your fingertips. One place for all the notes, presentations, docs, and all other collateral to live together.",
    "category": "Documents",
    "title": "Do.Com Integration With Cold Email Sales Tool",
    "h1": "Integrate Do.Com with SalesBlink",
    "metaDescription": "Integrate Do.Com and other Documents Tools with SalesBlink. Two Way Do.Com Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Shipedge",
    "slug": "shipedge",
    "logo": "https://zapier-images.imgix.net/storage/services/6707ca0f106e4ab651a2f5f5a5f27e37_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Shipedge is a cloud-based ecommerce order fulfillment solution that is great for third party logistics companies and ecommerce sellers alike.",
    "category": "eCommerce",
    "title": "Shipedge Integration With Cold Email Sales Tool",
    "h1": "Integrate Shipedge with SalesBlink",
    "metaDescription": "Integrate Shipedge and other eCommerce Tools with SalesBlink. Two Way Shipedge Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "DistributorCentral",
    "slug": "distributorcentral",
    "logo": "https://zapier-images.imgix.net/storage/services/91c66151fa836fde40c21de41836e848_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "DistributorCentral is a suite of technology tools built specifically for the promotional products industry.",
    "category": "Product Management",
    "title": "DistributorCentral Integration With Cold Email Sales Tool",
    "h1": "Integrate DistributorCentral with SalesBlink",
    "metaDescription": "Integrate DistributorCentral and other Product Management Tools with SalesBlink. Two Way DistributorCentral Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Pollfish",
    "slug": "pollfish",
    "logo": "https://zapier-images.imgix.net/storage/services/fab03b20427c43835b6f3c039d1da6c8_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Pollfish is a modern survey platform providing authentic responses in real-time, leading to better quality insights.",
    "category": "Forms & Surveys",
    "title": "Pollfish Integration With Cold Email Sales Tool",
    "h1": "Integrate Pollfish with SalesBlink",
    "metaDescription": "Integrate Pollfish and other Forms & Surveys Tools with SalesBlink. Two Way Pollfish Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Aptivada",
    "slug": "aptivada",
    "logo": "https://zapier-images.imgix.net/storage/services/228e43924baecae5ef28010bdd10b331_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Aptivada provides an integrated digital marketing solution for media companies of all sizes.",
    "category": "Marketing Automation",
    "title": "Aptivada Integration With Cold Email Sales Tool",
    "h1": "Integrate Aptivada with SalesBlink",
    "metaDescription": "Integrate Aptivada and other Marketing Automation Tools with SalesBlink. Two Way Aptivada Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Alyce",
    "slug": "alyce",
    "logo": "https://zapier-images.imgix.net/storage/services/735aceec14fd81e39148fd0206caaa78_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A.I. platform for gifting superpowers to send the perfect 1-to-1 gift to engage your most important prospects, customers or employees.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Alyce Integration With Cold Email Sales Tool",
    "h1": "Integrate Alyce with SalesBlink",
    "metaDescription": "Integrate Alyce and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Alyce Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "commonsku",
    "slug": "commonsku",
    "logo": "https://zapier-images.imgix.net/storage/services/8a80cd3bef8f1c33f8ba98e4373b1ef2_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "commonsku was created to empower your business: to streamline your workflow, connect your team, impress your clients, and allow you to close deals faster.",
    "category": "Project Management",
    "title": "commonsku Integration With Cold Email Sales Tool",
    "h1": "Integrate commonsku with SalesBlink",
    "metaDescription": "Integrate commonsku and other Project Management Tools with SalesBlink. Two Way commonsku Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Buildkite",
    "slug": "buildkite",
    "logo": "https://zapier-images.imgix.net/storage/services/ebf0a99bc6e85c4fa688b99f16c76441_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Buildkite is a platform for running fast, secure, and scalable continuous integration pipelines on your own infrastructure.",
    "category": "Developer Tools",
    "title": "Buildkite Integration With Cold Email Sales Tool",
    "h1": "Integrate Buildkite with SalesBlink",
    "metaDescription": "Integrate Buildkite and other Developer Tools Tools with SalesBlink. Two Way Buildkite Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "zInspector",
    "slug": "zinspector",
    "logo": "https://zapier-images.imgix.net/storage/services/1cce4c9322a121fc8b333cfb8011bb8a_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Complete property inspection software and apps for Android and iOS users. For real estate professionals including contractors, agents, and property managers.",
    "category": "Product Management",
    "title": "zInspector Integration With Cold Email Sales Tool",
    "h1": "Integrate zInspector with SalesBlink",
    "metaDescription": "Integrate zInspector and other Product Management Tools with SalesBlink. Two Way zInspector Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Rank Ranger",
    "slug": "rank-ranger",
    "logo": "https://zapier-images.imgix.net/storage/services/16846065431d1238d64e235f76248186_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Worldclass SEO software powered by big data allowing you to monitor, analyze and improve your SEO ranking and optimize marketing & social media campaigns.",
    "category": "Ads & Conversion",
    "title": "Rank Ranger Integration With Cold Email Sales Tool",
    "h1": "Integrate Rank Ranger with SalesBlink",
    "metaDescription": "Integrate Rank Ranger and other Ads & Conversion Tools with SalesBlink. Two Way Rank Ranger Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CourseCraft",
    "slug": "coursecraft",
    "logo": "https://zapier-images.imgix.net/storage/services/1a3ca6625e26d948ddeb5c1947cb6d27_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Our easy-to-use tool makes creating an online class a fun experience. Add photos, videos or any other type of media. We host it all for you.",
    "category": "Online Courses",
    "title": "CourseCraft Integration With Cold Email Sales Tool",
    "h1": "Integrate CourseCraft with SalesBlink",
    "metaDescription": "Integrate CourseCraft and other Online Courses Tools with SalesBlink. Two Way CourseCraft Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "CORESense",
    "slug": "coresense",
    "logo": "https://zapier-images.imgix.net/storage/services/45f9caa47b3dbbba1c50892868fe1bcb_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "CORESense is a cloud-based point of sale and retail management solution that is designed for merchants.",
    "category": "Payment Processing",
    "title": "CORESense Integration With Cold Email Sales Tool",
    "h1": "Integrate CORESense with SalesBlink",
    "metaDescription": "Integrate CORESense and other Payment Processing Tools with SalesBlink. Two Way CORESense Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Genesis Digital",
    "slug": "genesis-digital",
    "logo": "https://zapier-images.imgix.net/storage/services/2b6e810b2d5669755a6b91cb241246f3_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Genesis Digital is a rapidly growing leader in Info-Product creation, Marketing & SaaS applications.",
    "category": "Ads & Conversion",
    "title": "Genesis Digital Integration With Cold Email Sales Tool",
    "h1": "Integrate Genesis Digital with SalesBlink",
    "metaDescription": "Integrate Genesis Digital and other Ads & Conversion Tools with SalesBlink. Two Way Genesis Digital Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "The Studio Director",
    "slug": "the-studio-director",
    "logo": "https://zapier-images.imgix.net/storage/services/887b3743aa426995af08b341f0fdae7d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "The most complete dance studio software online. We make running your studio simple, easy, and rewarding by catering our software to your exact business needs.",
    "category": "Product Management",
    "title": "The Studio Director Integration With Cold Email Sales Tool",
    "h1": "Integrate The Studio Director with SalesBlink",
    "metaDescription": "Integrate The Studio Director and other Product Management Tools with SalesBlink. Two Way The Studio Director Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Wickedreports",
    "slug": "wickedreports",
    "logo": "https://zapier-images.imgix.net/storage/services/b70585321c79b6ca55a4326a413b269b_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Track and measure the performance of your marketing and advertising. Get accurate attribution data for your paid ads, email marketing, social media activity, and more.",
    "category": "Ads & Conversion",
    "title": "Wickedreports Integration With Cold Email Sales Tool",
    "h1": "Integrate Wickedreports with SalesBlink",
    "metaDescription": "Integrate Wickedreports and other Ads & Conversion Tools with SalesBlink. Two Way Wickedreports Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Critical Impact",
    "slug": "critical-impact",
    "logo": "https://zapier-images.imgix.net/storage/services/09c9640fcdd051371cc30fe2426aa8e7_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Critical Impact is the easiest all-in-one email marketing platform that includes automated email messaging, A/B testing, and SMS messaging.",
    "category": "Marketing Automation",
    "title": "Critical Impact Integration With Cold Email Sales Tool",
    "h1": "Integrate Critical Impact with SalesBlink",
    "metaDescription": "Integrate Critical Impact and other Marketing Automation Tools with SalesBlink. Two Way Critical Impact Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Eventrebels",
    "slug": "eventrebels",
    "logo": "https://zapier-images.imgix.net/storage/services/2d895e5a4ddc1861fcb60065e759d6ff_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Eventrebels software makes event and conference registration efficient for everything from a small hundred person event to a full conference with thousands of attendees.",
    "category": "Event Management",
    "title": "Eventrebels Integration With Cold Email Sales Tool",
    "h1": "Integrate Eventrebels with SalesBlink",
    "metaDescription": "Integrate Eventrebels and other Event Management Tools with SalesBlink. Two Way Eventrebels Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "BlueFolder",
    "slug": "bluefolder",
    "logo": "https://zapier-images.imgix.net/storage/services/977418befbde6674a99545afe5ccb913_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "BlueFolder provides easy and professional field service management and work order software.",
    "category": "Task Management",
    "title": "BlueFolder Integration With Cold Email Sales Tool",
    "h1": "Integrate BlueFolder with SalesBlink",
    "metaDescription": "Integrate BlueFolder and other Task Management Tools with SalesBlink. Two Way BlueFolder Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "FocusVision",
    "slug": "focusvision",
    "logo": "https://zapier-images.imgix.net/storage/services/b8d0cb2c3bb81800544f0e1e9f90371d_3.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "We are in the business of customer truth, looking beyond the voice of the customer to understand their hearts & mind through our integrated research solutions.",
    "category": "Reviews",
    "title": "FocusVision Integration With Cold Email Sales Tool",
    "h1": "Integrate FocusVision with SalesBlink",
    "metaDescription": "Integrate FocusVision and other Reviews Tools with SalesBlink. Two Way FocusVision Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Big Tickets",
    "slug": "big-tickets",
    "logo": "https://zapier-images.imgix.net/storage/services/3c1b791f834da1dab1609ee12a6ce343_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Big Tickets is an online ticketing solution for events of all sizes. Big Tickets empowers event organizers to manage, execute, and grow their events.",
    "category": "Event Management",
    "title": "Big Tickets Integration With Cold Email Sales Tool",
    "h1": "Integrate Big Tickets with SalesBlink",
    "metaDescription": "Integrate Big Tickets and other Event Management Tools with SalesBlink. Two Way Big Tickets Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Usedesk",
    "slug": "usedesk",
    "logo": "https://zapier-images.imgix.net/storage/services/578612fbb538e0730e97a2a20504a0ac_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "Automates client support; combines mail, messengers, social networks and uses database of templates, helps analyzing customer appeals.",
    "category": "Customer Support",
    "title": "Usedesk Integration With Cold Email Sales Tool",
    "h1": "Integrate Usedesk with SalesBlink",
    "metaDescription": "Integrate Usedesk and other Customer Support Tools with SalesBlink. Two Way Usedesk Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "Propeller CRM",
    "slug": "propeller-crm",
    "logo": "https://zapier-images.imgix.net/storage/services/2fc0e17ec3d1262d72741f791a25e0ea_4.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "A powerful, minimalist CRM tool designed for sales and marketing teams to work directly inside Gmail and Google Suite. Manage emails and contacts directly inside your inbox.",
    "category": "CRM (Customer Relationship Management)",
    "title": "Propeller CRM Integration With Cold Email Sales Tool",
    "h1": "Integrate Propeller CRM with SalesBlink",
    "metaDescription": "Integrate Propeller CRM and other CRM (Customer Relationship Management) Tools with SalesBlink. Two Way Propeller CRM Integration for all your data related to sales & cold email.",
    "zapier": true
  },
  {
    "name": "InVision",
    "slug": "invision",
    "logo": "https://zapier-images.imgix.net/storage/services/ac3713864d9474069e108d7ffc7d1cec.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50",
    "appDescription": "InVision is a digital product design platform powering the world’s best user experiences.",
    "category": "Developer Tools",
    "title": "InVision Integration With Cold Email Sales Tool",
    "h1": "Integrate InVision with SalesBlink",
    "metaDescription": "Integrate InVision and other Developer Tools Tools with SalesBlink. Two Way InVision Integration for all your data related to sales & cold email.",
    "zapier": true
  }
];
const categories = [
  {
    id: 'a2cb9bd8-7900-4bea-9d88-37280bb0056d',
    category: 'All Integrations',
    slug: '/integrations',
    title: 'All Integrations With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect more than 2308 Apps & Website with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'All Integrations with SalesBlink',
    subCategory: [],
  },
  {
    id: '68cba4e3-52cf-49c4-92cf-a67f74ff187d',
    category: 'AI Tools',
    slug: '/integrations/category/ai-tools',
    title: 'Integrate AI Tools Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect AI Tools with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for AI Tools with SalesBlink',
    subCategory: [],
  },
  {
    id: 'd2a9bff6-c757-4822-801e-90b29af6b625',
    category: 'Business Intelligence',
    slug: '/integrations/category/business-intelligence',
    title: 'Integrate Business Intelligence Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Business Intelligence with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Business Intelligence with SalesBlink',
    subCategory: [
      {
        category: 'Business Intelligence',
        name: 'Analytics',
        slug: '/integrations/category/analytics',
        title: 'Integrate Analytics Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Analytics with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Analytics with SalesBlink',
      },
      {
        category: 'Business Intelligence',
        name: 'Dashboards',
        slug: '/integrations/category/dashboards',
        title: 'Integrate Dashboards Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Dashboards with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Dashboards with SalesBlink',
      },
      {
        category: 'Business Intelligence',
        name: 'Reviews',
        slug: '/integrations/category/reviews',
        title: 'Integrate Reviews Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Reviews with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Reviews with SalesBlink',
      },
    ],
  },
  {
    id: '568996d1-2036-4d5d-81f2-a787fc6cfd86',
    category: 'Commerce',
    slug: '/integrations/category/commerce',
    title: 'Integrate Commerce Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Commerce with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Commerce with SalesBlink',
    subCategory: [
      {
        category: 'Commerce',
        name: 'Accounting',
        slug: '/integrations/category/accounting',
        title: 'Integrate Accounting Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Accounting with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Accounting with SalesBlink',
      },
      {
        category: 'Commerce',
        name: 'eCommerce',
        slug: '/integrations/category/ecommerce',
        title: 'Integrate eCommerce Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect eCommerce with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for eCommerce with SalesBlink',
      },
      {
        category: 'Commerce',
        name: 'Fundraising',
        slug: '/integrations/category/fundraising',
        title: 'Integrate Fundraising Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Fundraising with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Fundraising with SalesBlink',
      },
      {
        category: 'Commerce',
        name: 'Payment Processing',
        slug: '/integrations/category/payment-processing',
        title:
          'Integrate Payment Processing Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Payment Processing with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Payment Processing with SalesBlink',
      },
      {
        category: 'Commerce',
        name: 'Proposal & Invoice Management',
        slug: '/integrations/category/invoices',
        title:
          'Integrate Proposal & Invoice Management Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Proposal & Invoice Management with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading:
          'Integrations for Proposal & Invoice Management with SalesBlink',
      },
    ],
  },
  {
    id: '786fb844-4597-4bcf-97d5-3b295013d8a4',
    category: 'Communication',
    slug: '/integrations/category/communication',
    title: 'Integrate Communication Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Communication with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Communication with SalesBlink',
    subCategory: [
      {
        category: 'Communication',
        name: 'Call Tracking',
        slug: '/integrations/category/call-tracking',
        title: 'Integrate Call Tracking Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Call Tracking with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Call Tracking with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Email',
        slug: '/integrations/category/email',
        title: 'Integrate Email Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Email with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Email with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Fax',
        slug: '/integrations/category/fax',
        title: 'Integrate Fax Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Fax with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Fax with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Notifications',
        slug: '/integrations/category/notifications',
        title: 'Integrate Notifications Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Notifications with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Notifications with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Phone & SMS',
        slug: '/integrations/category/phone',
        title: 'Integrate Phone & SMS Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Phone & SMS with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Phone & SMS with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Team Chat',
        slug: '/integrations/category/team-chat',
        title: 'Integrate Team Chat Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Team Chat with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Team Chat with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Team Collaboration',
        slug: '/integrations/category/team-collaboration',
        title:
          'Integrate Team Collaboration Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Team Collaboration with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Team Collaboration with SalesBlink',
      },
      {
        category: 'Communication',
        name: 'Video Conferencing',
        slug: '/integrations/category/video-calls',
        title:
          'Integrate Video Conferencing Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Video Conferencing with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Video Conferencing with SalesBlink',
      },
    ],
  },
  {
    id: '4006d7a0-281e-4dfb-98b9-5c7131af4fc5',
    category: 'Content & Files',
    slug: '/integrations/category/content-files',
    title: 'Integrate Content & Files Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Content & Files with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Content & Files with SalesBlink',
    subCategory: [
      {
        category: 'Content & Files',
        name: 'Documents',
        slug: '/integrations/category/documents',
        title: 'Integrate Documents Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Documents with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Documents with SalesBlink',
      },
      {
        category: 'Content & Files',
        name: 'File Management & Storage',
        slug: '/integrations/category/files',
        title:
          'Integrate File Management & Storage Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect File Management & Storage with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for File Management & Storage with SalesBlink',
      },
      {
        category: 'Content & Files',
        name: 'Images & Design',
        slug: '/integrations/category/images',
        title: 'Integrate Images & Design Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Images & Design with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Images & Design with SalesBlink',
      },
      {
        category: 'Content & Files',
        name: 'Notes',
        slug: '/integrations/category/notes',
        title: 'Integrate Notes Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Notes with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Notes with SalesBlink',
      },
      {
        category: 'Content & Files',
        name: 'Transcription',
        slug: '/integrations/category/transcription',
        title: 'Integrate Transcription Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Transcription with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Transcription with SalesBlink',
      },
      {
        category: 'Content & Files',
        name: 'Video & Audio',
        slug: '/integrations/category/video',
        title: 'Integrate Video & Audio Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Video & Audio with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Video & Audio with SalesBlink',
      },
    ],
  },
  {
    id: '7a5e2020-2924-48b7-a79b-dd6d21c7de47',
    category: 'Human Resources',
    slug: '/integrations/category/human-resources',
    title: 'Integrate Human Resources Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Human Resources with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Human Resources with SalesBlink',
    subCategory: [
      {
        category: 'Human Resources',
        name: 'HR Talent & Recruitment',
        slug: '/integrations/category/hr',
        title:
          'Integrate HR Talent & Recruitment Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect HR Talent & Recruitment with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for HR Talent & Recruitment with SalesBlink',
      },
    ],
  },
  {
    id: 'a37f56c6-7a80-4b35-b44c-886a97324309',
    category: 'Internet of Things',
    slug: '/integrations/category/internet-of-things',
    title: 'Integrate Internet of Things Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Internet of Things with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Internet of Things with SalesBlink',
    subCategory: [
      {
        category: 'Internet of Things',
        name: 'Devices',
        slug: '/integrations/category/devices',
        title: 'Integrate Devices Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Devices with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Devices with SalesBlink',
      },
      {
        category: 'Internet of Things',
        name: 'Printing',
        slug: '/integrations/category/printing',
        title: 'Integrate Printing Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Printing with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Printing with SalesBlink',
      },
    ],
  },
  {
    id: 'e656cf8b-8bfb-4ffc-99b1-6cada50fa113',
    category: 'IT Operations',
    slug: '/integrations/category/it-operations',
    title: 'Integrate IT Operations Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect IT Operations with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for IT Operations with SalesBlink',
    subCategory: [
      {
        category: 'IT Operations',
        name: 'Databases',
        slug: '/integrations/category/databases',
        title: 'Integrate Databases Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Databases with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Databases with SalesBlink',
      },
      {
        category: 'IT Operations',
        name: 'Developer Tools',
        slug: '/integrations/category/developer-tools',
        title: 'Integrate Developer Tools Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Developer Tools with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Developer Tools with SalesBlink',
      },
      {
        category: 'IT Operations',
        name: 'Online Courses',
        slug: '/integrations/category/education',
        title: 'Integrate Online Courses Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Online Courses with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Online Courses with SalesBlink',
      },
      {
        category: 'IT Operations',
        name: 'Security & Identity Tools',
        slug: '/integrations/category/security-identity',
        title:
          'Integrate Security & Identity Tools Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Security & Identity Tools with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Security & Identity Tools with SalesBlink',
      },
      {
        category: 'IT Operations',
        name: 'Server Monitoring',
        slug: '/integrations/category/server-monitoring',
        title: 'Integrate Server Monitoring Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Server Monitoring with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Server Monitoring with SalesBlink',
      },
    ],
  },
  {
    id: 'c7985c50-b40d-4b77-82b7-cd026f1485d9',
    category: 'Lifestyle & Entertainment',
    slug: '/integrations/category/lifestyle-entertainment',
    title:
      'Integrate Lifestyle & Entertainment Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Lifestyle & Entertainment with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Lifestyle & Entertainment with SalesBlink',
    subCategory: [
      {
        category: 'Lifestyle & Entertainment',
        name: 'Fitness',
        slug: '/integrations/category/fitness',
        title: 'Integrate Fitness Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Fitness with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Fitness with SalesBlink',
      },
      {
        category: 'Lifestyle & Entertainment',
        name: 'Gaming',
        slug: '/integrations/category/gaming',
        title: 'Integrate Gaming Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Gaming with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Gaming with SalesBlink',
      },
      {
        category: 'Lifestyle & Entertainment',
        name: 'News & Lifestyle',
        slug: '/integrations/category/news',
        title: 'Integrate News & Lifestyle Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect News & Lifestyle with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for News & Lifestyle with SalesBlink',
      },
    ],
  },
  {
    id: '276080e2-14b6-43e0-853c-44d98fc814e6',
    category: 'Marketing',
    slug: '/integrations/category/marketing',
    title: 'Integrate Marketing Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Marketing with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Marketing with SalesBlink',
    subCategory: [
      {
        category: 'Marketing',
        name: 'Ads & Conversion',
        slug: '/integrations/category/ads-conversion',
        title: 'Integrate Ads & Conversion Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Ads & Conversion with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Ads & Conversion with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Drip Emails',
        slug: '/integrations/category/drip-emails',
        title: 'Integrate Drip Emails Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Drip Emails with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Drip Emails with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Email Newsletters',
        slug: '/integrations/category/email-newsletters',
        title: 'Integrate Email Newsletters Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Email Newsletters with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Email Newsletters with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Event Management',
        slug: '/integrations/category/event-management',
        title: 'Integrate Event Management Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Event Management with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Event Management with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Marketing Automation',
        slug: '/integrations/category/marketing-automation',
        title:
          'Integrate Marketing Automation Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Marketing Automation with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Marketing Automation with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Social Media Accounts',
        slug: '/integrations/category/social',
        title:
          'Integrate Social Media Accounts Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Social Media Accounts with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Social Media Accounts with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Social Media Marketing',
        slug: '/integrations/category/social-marketing',
        title:
          'Integrate Social Media Marketing Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Social Media Marketing with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Social Media Marketing with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Transactional Email',
        slug: '/integrations/category/transactional-email',
        title:
          'Integrate Transactional Email Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Transactional Email with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Transactional Email with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'URL Shortener',
        slug: '/integrations/category/url-shorten',
        title: 'Integrate URL Shortener Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect URL Shortener with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for URL Shortener with SalesBlink',
      },
      {
        category: 'Marketing',
        name: 'Webinars',
        slug: '/integrations/category/webinars',
        title: 'Integrate Webinars Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Webinars with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Webinars with SalesBlink',
      },
    ],
  },
  {
    id: 'e9403530-9ece-49eb-87cf-b932515fe76f',
    category: 'Productivity',
    slug: '/integrations/category/productivity',
    title: 'Integrate Productivity Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Productivity with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Productivity with SalesBlink',
    subCategory: [
      {
        category: 'Productivity',
        name: 'Bookmark Managers',
        slug: '/integrations/category/bookmarks',
        title: 'Integrate Bookmark Managers Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Bookmark Managers with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Bookmark Managers with SalesBlink',
      },
      {
        category: 'Productivity',
        name: 'Calendar',
        slug: '/integrations/category/calendar',
        title: 'Integrate Calendar Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Calendar with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Calendar with SalesBlink',
      },
      {
        category: 'Productivity',
        name: 'Product Management',
        slug: '/integrations/category/product-management',
        title:
          'Integrate Product Management Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Product Management with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Product Management with SalesBlink',
      },
      {
        category: 'Productivity',
        name: 'Project Management',
        slug: '/integrations/category/project-management',
        title:
          'Integrate Project Management Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Project Management with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Project Management with SalesBlink',
      },
      {
        category: 'Productivity',
        name: 'Spreadsheets',
        slug: '/integrations/category/spreadsheets',
        title: 'Integrate Spreadsheets Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Spreadsheets with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Spreadsheets with SalesBlink',
      },
      {
        category: 'Productivity',
        name: 'Task Management',
        slug: '/integrations/category/todo-lists',
        title: 'Integrate Task Management Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Task Management with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Task Management with SalesBlink',
      },
      {
        category: 'Productivity',
        name: 'Time Tracking Software',
        slug: '/integrations/category/time-tracking',
        title:
          'Integrate Time Tracking Software Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Time Tracking Software with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Time Tracking Software with SalesBlink',
      },
    ],
  },
  {
    id: '44752e95-f279-4d5f-8952-dd3e9a763a73',
    category: 'Sales & CRM',
    slug: '/integrations/category/sales-crm',
    title: 'Integrate Sales & CRM Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Sales & CRM with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Sales & CRM with SalesBlink',
    subCategory: [
      {
        category: 'Sales & CRM',
        name: 'Contact Management',
        slug: '/integrations/category/contacts',
        title:
          'Integrate Contact Management Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Contact Management with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Contact Management with SalesBlink',
      },
      {
        category: 'Sales & CRM',
        name: 'CRM (Customer Relationship Management)',
        slug: '/integrations/category/crm',
        title:
          'Integrate CRM (Customer Relationship Management) Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect CRM (Customer Relationship Management) with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading:
          'Integrations for CRM (Customer Relationship Management) with SalesBlink',
      },
      {
        category: 'Sales & CRM',
        name: 'Forms & Surveys',
        slug: '/integrations/category/forms',
        title: 'Integrate Forms & Surveys Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Forms & Surveys with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Forms & Surveys with SalesBlink',
      },
    ],
  },
  {
    id: 'f209018d-e461-46c0-8984-58602b007b31',
    category: 'Scheduling & Booking',
    slug: '/integrations/category/scheduling',
    title: 'Integrate Scheduling & Booking Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Scheduling & Booking with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Scheduling & Booking with SalesBlink',
    subCategory: [],
  },
  {
    id: '0d271291-60ce-4ca9-9ce8-cbd8b8f222c4',
    category: 'Signatures',
    slug: '/integrations/category/signatures',
    title: 'Integrate Signatures Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Signatures with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Signatures with SalesBlink',
    subCategory: [],
  },
  {
    id: '2bf69932-4dd6-4a60-ba03-c517d21ae200',
    category: 'Support',
    slug: '/integrations/category/support',
    title: 'Integrate Support Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Support with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Support with SalesBlink',
    subCategory: [
      {
        category: 'Support',
        name: 'Customer Appreciation',
        slug: '/integrations/category/customer-appreciation',
        title:
          'Integrate Customer Appreciation Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Customer Appreciation with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Customer Appreciation with SalesBlink',
      },
      {
        category: 'Support',
        name: 'Customer Support',
        slug: '/integrations/category/customer-support',
        title: 'Integrate Customer Support Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Customer Support with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Customer Support with SalesBlink',
      },
    ],
  },
  {
    id: '4c758e38-5af4-4812-a357-dac94f6b1632',
    category: 'Website & App Building',
    slug: '/integrations/category/website-app-building',
    title:
      'Integrate Website & App Building Apps With Cold Email Outreach Tool',
    metaDescription:
      'Instantly connect Website & App Building with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
    heading: 'Integrations for Website & App Building with SalesBlink',
    subCategory: [
      {
        category: 'Website & App Building',
        name: 'App Builder',
        slug: '/integrations/category/app-builder',
        title: 'Integrate App Builder Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect App Builder with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for App Builder with SalesBlink',
      },
      {
        category: 'Website & App Building',
        name: 'Website Builders',
        slug: '/integrations/category/cms',
        title: 'Integrate Website Builders Apps With Cold Email Outreach Tool',
        metaDescription:
          'Instantly connect Website Builders with SalesBlink. There are more than 5000+ integrations available with SalesBlink - easiest way to find B2B leads and automate your sales outreach.',
        heading: 'Integrations for Website Builders with SalesBlink',
      },
    ],
  },
];

module.exports = { categories, integrationsData };
