import { Tooltip } from 'react-tooltip';
import { getBezierPath } from 'reactflow';
import { useFlowchartData } from './FlowchartContext';
import { UilPlus } from '@iconscout/react-unicons';
import Swal from 'sweetalert2';

const foreignObjectSize = 40;

export default function CustomEdge({
  id,
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) {
  const flowchartData = useFlowchartData();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const executeFunction = () => {
    flowchartData?.showEdgeAddDialog({
      id,
      source,
      sourceX,
      sourceY,
      sourcePosition,
      target,
      targetX,
      targetY,
      targetPosition,
    });
  }

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          <div
            id="add-node-between"
            className='foreign-object-button'
            style={{
              background: '#f2f2f2',
              width: 40,
              border: '3px solid #03A6FF',
              color: '#03A6FF',
              borderRadius: 5,
              cursor: 'pointer',
            }}
            onClick={(event) => {
              if (flowchartData?.checkRunningSequenceFirstEdit()) {
                return Swal.fire({
                  icon: 'warning',
                  title: '<h5>Are you sure?</h5>',
                  html: `<div>
                    <ol style="text-align: left">
                      <li>You're trying to edit a step in a launched sequence.</li>
                      <li style="margin-top: 10px">This might result in leads who have not replied to your email (and are safe to contact) to go through the new step(s) if they have not passed the current step yet.</li>
                      <li style="margin-top: 10px">Also, it may mess up sequence stats.</li>
                    </ol>
                  </div>`,
                  showCancelButton: true,
                  confirmButtonText: 'Proceed Anyway',
                  cancelButtonText: 'Cancel',
                  confirmButtonColor: '#0066ff',
                }).then((result) => {
                  if (result.isConfirmed) {
                    flowchartData?.setIsFirstEdit(false);
                    executeFunction();
                  }
                });
              } else executeFunction();
            }}
          >
            <UilPlus style={{ cursor: 'pointer' }} size={24} />
          </div>
          <Tooltip anchorSelect="#add-node-between">
            Add Block in Between
          </Tooltip>
        </div>
      </foreignObject>
    </>
  );
}
