/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext, Form } from 'components/lib';
import { Link, useNavigate } from 'react-router-dom';
import accentureLogo from '../../../assets/accenture_logo.png';
import nintendoLogo from '../../../assets/nintendo_logo.png';
import sonyLogo from '../../../assets/sony_logo.png';
import ReCAPTCHA from 'react-google-recaptcha';
import BadgeOne from "../../../assets/badge-1.png"
import BadgeTwo from "../../../assets/badge-2.png"
import BadgeThree from "../../../assets/badge-3.png"
import axios from 'axios';
import defaultLogo from '../../../assets/logo-small.png';
import defaultFavicon from '../../../assets/salesblink-icon-only-logo.png';
import { Helmet } from 'react-helmet';
import { Favicon } from '../Favicon';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

const jsonToQueryString = (json) =>
  Object.entries(json)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

const PROVIDERS = {
  google: {
    baseURL: 'https://accounts.google.com/o/oauth2/v2/auth?',
    params: jsonToQueryString({
      client_id: process.env.REACT_APP_OAUTH_GOOGLE_CLIENTID,
      redirect_uri: `${window.location.origin}/signup/oauth-redirect`,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ].join(' '),
      state: 'google oauth',
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      include_granted_scopes: true,
    }),
  },
  outlook: {
    baseURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?',
    params: jsonToQueryString({
      client_id: process.env.REACT_APP_OAUTH_OUTLOOK_CLIENTID,
      redirect_uri: `${window.location.origin}/signup/oauth-redirect`,
      scope: ['offline_access', 'https://graph.microsoft.com/user.read'].join(
        ' '
      ),
      response_type: 'code',
      response_mode: 'query',
      state: 'outlook oauth',
    }),
  },
  linkedin: {
    baseURL: 'https://www.linkedin.com/oauth/v2/authorization?',
    params: jsonToQueryString({
      client_id: process.env.REACT_APP_OAUTH_LINKEDIN_CLIENTID,
      redirect_uri: `${window.location.origin}/signup/oauth-redirect`,
      scope: ['r_emailaddress', 'r_liteprofile'].join(' '),
      state: 'linkedin oauth',
      response_type: 'code',
      include_granted_scopes: true,
    }),
  },
};

export default function SignUp(props) {
  const [haveCoupons, setHaveCoupons] = useState(false)
  const context = useContext(AuthContext);
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const [isLocalOrSalesBlink, setIsLocalOrSalesBlink] = useState(true);
  const [whiteLabelData, setWhiteLabelData] = useState({});
  const [customFaviconPresent, setCustomFaviconPresent] = useState(false);
  const deviceWidth = useWindowResize();
  const formState = Object.assign(
    {
      name: {
        label: 'Full Name',
        type: 'text',
        required: true,
        placeholder: 'Enter your name',
        errorMessage: 'Please enter your name',
        maxLength: 150,
      },
      email: {
        label: 'Work Email',
        type: 'email',
        placeholder: 'Enter work email',
        required: true,
        maxLength: 150,
      },
    },
    // !window.location.search.includes('coupons') && {
    //   phone: {
    //     label: 'Phone Number',
    //     type: 'phone',
    //     placeholder: 'Enter phone number',
    //     required: true,
    //     maxLength: 20,
    //   },
    // },
    {
      password: {
        label: 'Password',
        type: 'password',
        required: true,
        placeholder: '********',
        complexPassword: true,
        maxLength: 50,
      },
      confirm_password: {
        type: 'hidden',
        value: null,
      },
    },
    window.location.search.includes('coupons') && {
      coupons: {
        label: 'Enter Coupon Codes seperated by comma',
        type: 'text',
        placeholder: 'Code1,Code2,Code3,...',
        value: null,
      },
    }
  );

  function redirectToOauth(providerName) {
    window.location.href = `${PROVIDERS[providerName].baseURL}${PROVIDERS[providerName].params}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = window.location.origin.split('//')[1];
        const response = await axios.get('/api/whitelabel', { params: { query } });
        const data = response.data.data;
        setWhiteLabelData({
          agencyName: data?.agencyName || 'SalesBlink',
          headerLogo: data?.headerLogo || defaultLogo,
          favicon: data?.favicon || defaultFavicon,
        });
        if (data?.headerLogo) setCustomFaviconPresent(true);
      } catch (error) {
        console.log('error while fetching whitelabel data', error);
      }
    }
    if(window.location.search.includes('coupons')) setHaveCoupons(true);
    if(!window.location.href.includes('salesblink') && !window.location.href.includes('localhost')) {
      navigate('/signin');
      setIsLocalOrSalesBlink(false);
      fetchData();
    }
  }, [])

  useEffect(() => {
    if(context.user) navigate('/')
  }, [context.user])

  return (
    <div className="auth-signup">
      {customFaviconPresent && <Favicon url={whiteLabelData.favicon} />}
      {whiteLabelData?.agencyName && <Helmet><title>SignUp | {whiteLabelData?.agencyName}</title></Helmet>}
      <div className="main">
        <div className="header">
          <div className="logo">
            {!isLocalOrSalesBlink && whiteLabelData?.headerLogo && <img src={whiteLabelData?.headerLogo} alt="Salesblink Logo" width={198} height={44} />}
            {isLocalOrSalesBlink && <img src={defaultLogo} alt="Salesblink Logo" width={198} height={44} />}
          </div>
          <h1>
            Get <strong>Booked with Meetings</strong> today
          </h1>
          <p style={{fontSize: "24px", fontWeight: 500, marginTop: "1rem"}}>{haveCoupons ? "Redeem Coupon Codes" : "Sign Up for Free"}</p>
          <p style={{fontSize: "14px"}}>{haveCoupons ? "SignUp & Redeem Coupon Codes" : "7 Day Trial, No CC required."}</p>
        </div>
        <div className="signup-form">
          {!haveCoupons && isLocalOrSalesBlink && <div className="oauth">
            <button class="gsi-material-button" onClick={() => redirectToOauth('google')}>
                <div class="gsi-material-button-state"></div>
                <div class="gsi-material-button-content-wrapper">
                  <div class="gsi-material-button-icon">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      style={{display: "block"}}
                    >
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span class="gsi-material-button-contents">
                    Sign up with Google
                  </span>
                  <span style={{display: "none"}}>Sign up with Google</span>
                </div>
              </button>
            <button
              className="button outlook"
              onClick={() => redirectToOauth('outlook')}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icons8-microsoft 2">
                  <path
                    id="Vector"
                    d="M11.4583 11.4583L3.12501 11.4583L3.12501 3.12492L11.4583 3.12492L11.4583 11.4583Z"
                    fill="#FF5722"
                  />
                  <path
                    id="Vector_2"
                    d="M21.875 11.4583L13.5417 11.4583L13.5417 3.12492L21.875 3.12492V11.4583Z"
                    fill="#4CAF50"
                  />
                  <path
                    id="Vector_3"
                    d="M21.875 21.875H13.5417L13.5417 13.5417L21.875 13.5417V21.875Z"
                    fill="#FFC107"
                  />
                  <path
                    id="Vector_4"
                    d="M11.4583 21.875H3.12501L3.12501 13.5417L11.4583 13.5417L11.4583 21.875Z"
                    fill="#03A9F4"
                  />
                </g>
              </svg>
              Sign up with Outlook
            </button>
            <button
              className="button linkedin"
              onClick={() => redirectToOauth('linkedin')}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icons8-linkedin 1">
                  <path
                    id="Vector"
                    d="M21.875 19.2708C21.875 20.7094 20.7094 21.875 19.2708 21.875H5.72917C4.29115 21.875 3.125 20.7094 3.125 19.2708V5.72917C3.125 4.29062 4.29115 3.125 5.72917 3.125H19.2708C20.7094 3.125 21.875 4.29062 21.875 5.72917V19.2708Z"
                    fill="#0288D1"
                  />
                  <path
                    id="Vector_2"
                    d="M6.25 9.89583H8.85417V18.75H6.25V9.89583ZM7.54427 8.85417H7.52969C6.7526 8.85417 6.25 8.275 6.25 7.55156C6.25 6.8125 6.76823 6.25 7.55937 6.25C8.35156 6.25 8.83958 6.8125 8.85417 7.55156C8.85417 8.27448 8.35156 8.85417 7.54427 8.85417ZM18.75 18.75H16.1458V14.0109C16.1458 12.8661 15.5078 12.0849 14.4833 12.0849C13.7016 12.0849 13.2786 12.612 13.0734 13.1214C12.9984 13.3036 13.0208 13.8078 13.0208 14.0625V18.75H10.4167V9.89583H13.0208V11.2583C13.3964 10.6771 13.9844 9.89583 15.4885 9.89583C17.3521 9.89583 18.7495 11.0677 18.7495 13.6844L18.75 18.75Z"
                    fill="#F9F9F9"
                  />
                </g>
              </svg>
              Sign up with LinkedIn
            </button>
          </div>}

          {!haveCoupons && isLocalOrSalesBlink && <div className="separator">
            <span>OR</span>
          </div>}

          <div className="form">
            {formState && (
              <Form
                data={formState}
                url="/api/account"
                method="POST"
                buttonText="Create Account"
                tnc={!window.location.search.includes('coupons')}
                callback={context.signin}
                type="auth"
                recaptchaRef={recaptchaRef}
                agencyName={whiteLabelData?.agencyName}
              />
            )}
          </div>
          {!haveCoupons && <div className="redirect-to-signin">
            Have an account? <Link to="/signin">Sign In</Link>
          </div>}
        </div>
        {isLocalOrSalesBlink && <p className="footer">
          Copyright © {whiteLabelData?.agencyName || 'SalesBlink'} {new Date().getFullYear()}, All Rights Reserved
        </p>}
      </div>
      {isLocalOrSalesBlink && deviceWidth > 991 && <div className="details">
        <div className="background">
          <div className="hover-thingy left"></div>
          <div className="hover-thingy top-right"></div>
          <div className="hover-thingy right"></div>
          <div className="hover-thingy bottom"></div>
          <div className="hover-thingy bottom-left"></div>
        </div>
        <div className="content">
          <div className="header text-center">
            <h2>AI powered Cold Email Outreach from 2030.</h2>
          </div>

          <div className="images">
            <div>
              <img
                loading="lazy"
                src={BadgeOne}
                alt="G2 Award for High Performer Winter 2024"
                title="G2 Award for High Performer Winter 2024"
              />
              <img
                loading="lazy"
                src={BadgeTwo}
                alt="G2 Award for High Performer Europe Winter 2024"
                title="G2 Award for High Performer Europe Winter 2024"
              />
              <img
                loading="lazy"
                src={BadgeThree}
                alt="G2 Award for Best Est. ROI Winter 2024"
                title="G2 Award for Best Est. ROI Winter 2024"
              />
            </div>
            <div>
              <img loading="lazy" src={sonyLogo} alt="Sony" />
              <img loading="lazy" src={nintendoLogo} alt="Nintendo" />
              <img loading="lazy" src={accentureLogo} alt="Accenture" />
            </div>
          </div>
        </div>
      </div>}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ||
          '6Lckd18nAAAAAPxOvLjxlM-q_1uMbmAxdM4ivuq8'
        }
        size="invisible"
      />
    </div>
  );
}
